import * as React from "react";
import { IconButton } from "office-ui-fabric-react";
import { connect } from "react-redux";
import SpoConnectionSelect from "./SpoConnectionSelect";
import SpoMappingPanel from "./SpoMappingPanel";
import { ISourceInfo, Dictionary } from "../../../entities/common";
import { ApplicationState } from "../../../store/index";
import { actionCreators, ISpoConnectionState, ISpoProject } from "../../../store/integration/SpoStore";
import { IListStore, validateConnection, loadDataAfterConnectionValidation, ILinkDto } from "../../../store/integration/common";
import ConnectControl from '../ConnectControl';
import { Integrations } from "../../../store/Tenant";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import SwitchPrimaryScheduleConfirmation from "../SwitchPrimaryScheduleConfirmation";

type OwnProps = {
    readonly: boolean;
    allowNewItems: boolean;
    actions: Dictionary<any>;
    dismissPanel: () => void;
    sourceInfo?: ISourceInfo;
    allowMultipleConnectionsToOneProject?: boolean;
    disableConfigureMapping?: boolean;
    showSwitchPrimaryDialog?: boolean;
    setAsPrimary?: boolean;
}

type StateProps = { 
    projects: IListStore<ISpoProject>;
    integrations: Integrations;
    connections: ISpoConnectionState
};
type Props = StateProps & typeof actionCreators & OwnProps;

type State = {
    isMapping: boolean;
    selectedConnectionId?: string;
    showSwitchPrimaryScheduleConfirm: boolean;
    linkData?: {
        dto?: ILinkDto<ISpoProject>;
        connectionId?: string;
        itemName?: string;
    }
}

class SpoConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isMapping: false,
            showSwitchPrimaryScheduleConfirm: false,
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        loadDataAfterConnectionValidation(
            this.props.connections,
            nextProps.connections,
            this.state.selectedConnectionId,
            this.props.loadProjects
        )
    }

    public render() {
        const { sourceInfo, dismissPanel, readonly, allowNewItems, disableConfigureMapping, allowMultipleConnectionsToOneProject, actions } = this.props;
        const { showSwitchPrimaryScheduleConfirm } = this.state;
        return <>
            <ConnectControl<ISpoProject>
                itemTypeLabel="project"
                readonly={readonly}
                isConnectionValid={(connectionId) => validateConnection(this.props.connections, connectionId)}
                allowNewItems={allowNewItems}
                allowMultipleConnectionsToOneItem={allowMultipleConnectionsToOneProject}
                items={this.props.projects}
                actions={{
                    createItemAndLink: (connectionId: string, itemName: string) => {
                        if (this.props.showSwitchPrimaryDialog) {
                            this.setState({ linkData: { connectionId, itemName }, showSwitchPrimaryScheduleConfirm: true });
                            return;
                        }
                
                        actions.createPoProjectAndLink(connectionId, itemName, this.props.setAsPrimary);
                        this.props.dismissPanel();
                    },
                    linkToItem: (linkDto: ILinkDto<ISpoProject>) => {
                        if (this.props.showSwitchPrimaryDialog) {
                            this.setState({ linkData: { dto: linkDto }, showSwitchPrimaryScheduleConfirm: true });
                            return;
                        }

                        this.props.actions.linkToPoProject({ ...linkDto, setAsPrimary: !!this.props.setAsPrimary });
                        this.props.dismissPanel();
                    },
                    deleteLink: this.props.actions.deleteLink,
                }}
                notCloseAfterLink
                dismissPanel={dismissPanel}
                sourceInfo={sourceInfo}
                itemId={sourceInfo && sourceInfo.sourceData && sourceInfo.sourceData.projectId}
                itemName={sourceInfo && sourceInfo.sourceData.projectName}
                renderConnectControl={(connectionId, disabled, onChange) =>
                    <SpoConnectionSelect
                        connectionId={connectionId}
                        disabled={disabled}
                        onChange={_ => {
                            onChange(_);
                            this.setState({ selectedConnectionId: _ });
                        }} />}
                renderExistingConnectedItemOptions={!disableConfigureMapping ? this._renderConnectOptions : undefined}
            />
            {showSwitchPrimaryScheduleConfirm && <SwitchPrimaryScheduleConfirmation
                from={SourceType.Ppmx} to={SourceType.Spo}
                onDismiss={() => this.setState({ showSwitchPrimaryScheduleConfirm: false, linkData: undefined })}
                onClick={(isPrimary) => {
                    if (this.state.linkData?.dto) {
                        actions.linkToPoProject({ ...this.state.linkData.dto, setAsPrimary: isPrimary });
                    } else {
                        actions.createPoProjectAndLink(this.state.linkData?.connectionId, this.state.linkData?.itemName, isPrimary);
                    }
                    this.props.dismissPanel();
                }} />
            }
        </>;
    }

    private _renderConnectOptions = (connectionId?: string, projectId?: string) => {
        return <>
            <IconButton
                disabled={!projectId || this.props.integrations.isDisabled(SourceType.Spo)}
                title="Configure mapping"
                iconProps={{ iconName: "PPMXMapping" }}
                onClick={() => this.setState({ isMapping: true })}
                className="mapping-button" />
            {this.state.isMapping && <SpoMappingPanel connectionId={connectionId!} onDismiss={() => this.setState({ isMapping: false })} />}
        </>;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { 
        projects: state.spo.projects,
        integrations: new Integrations(state.tenant.subscription.integrations),
        connections: state.spo.connections
    };
}

export default connect(mapStateToProps, actionCreators)(SpoConnectControl);