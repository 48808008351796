import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { SourceInfo, ISourceInfo } from "../../../../../entities/common";
import { Field } from '../../../../../entities/Metadata';
import { FormatDateTime, toDateTime } from '../../../../utils/common';

export default class SyncDate extends React.Component<IListViewFieldColumn<ProjectInfo>, { lastSyncDate: string | null }> {
    constructor(props: IListViewFieldColumn<ProjectInfo>) {
        super(props);
        this.state = { lastSyncDate: null };
    }

    componentWillMount() {
        this.setState({ lastSyncDate: SyncDate.getLastSyncDate(this.props.entity.sourceInfos) });
    }

    componentWillReceiveProps(nextProps: IListViewFieldColumn<ProjectInfo>) {
        if (this.props.entity.sourceInfos !== nextProps.entity.sourceInfos) {
            this.setState({ lastSyncDate: SyncDate.getLastSyncDate(nextProps.entity.sourceInfos) });
        }
    }

    public render() {
        const field = this.props.field;
        if (!field) {
            return <span>Invalid column configuration</span>;
        }

        return (
            <span>{this.state.lastSyncDate}</span>
        );
    }

    private static getLastSyncDate(dataSources: ISourceInfo[]): string | null {
        const sources = SourceInfo.getDataConnections(SourceInfo.getSyncableConnections(dataSources));
        if (!sources.length) {
            return null;
        }

        const lastSyncDate = sources[0].syncStatus.lastSyncDate;

        return lastSyncDate
            ? FormatDateTime(lastSyncDate, true, 
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                })!
            : "";
    }

    public static getValue(entity: ProjectInfo, field: Field): Date | undefined {
        const sources = SourceInfo.getSources(entity.sourceInfos);
        if (!sources.length) {
            return undefined;
        }

        const lastSyncDate = sources[0].syncStatus.lastSyncDate;

        return lastSyncDate ? toDateTime(lastSyncDate)! : undefined;
    }

    public static getSearchValue(entity: ProjectInfo, field: Field): string | null {
        return SyncDate.getLastSyncDate(entity.sourceInfos)
    }
}