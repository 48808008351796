import { DefaultButton, TextField, DialogType, DialogFooter, Dialog, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import * as React from 'react';
import { Validator } from '../../validation';

type OwnProps = {
    title: string;
    description: string;
    warning?: string;
    passphrase: string;
    onConfirm: () => void;
    onDismiss: () => void;
}

const PromptConfirmationDialog = (props: OwnProps) => {
    const [passphrase, setPassphrase] = React.useState<string>();
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const passphraseValidator = Validator.new().required().equals(props.passphrase).build()

    return <Dialog
        maxWidth={416}
        hidden={false}
        dialogContentProps={{ type: DialogType.close, title: props.title }}
        modalProps={{
            isBlocking: true,
            containerClassName: 'ms-dialogMainOverride'
        }}
        onDismiss={props.onDismiss}>
        <div>{props.description}</div>
        <MessageBar className='with-top-margin' messageBarType={MessageBarType.warning}>
            {props.warning}
        </MessageBar>
        <TextField
            className='with-top-margin'
            label={`Please type “${props.passphrase}” to confirm`}
            errorMessage={errorMessage}
            value={passphrase}
            onChange={(e, _) => {
                setPassphrase(_);
                setErrorMessage(undefined);
            }}
        />
        <DialogFooter>
            <DefaultButton
                primary={true}
                onClick={() => {
                    const error = passphraseValidator.getErrorMessage(passphrase);
                    if (!error) {
                        props.onConfirm();
                        props.onDismiss();
                    } else {
                        setErrorMessage(error);
                    }
                }}>Confirm</DefaultButton>
            <DefaultButton
                onClick={props.onDismiss}>Cancel</DefaultButton>
        </DialogFooter>
    </Dialog>
}

export default PromptConfirmationDialog;