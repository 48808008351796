import * as React from 'react';
import { Link } from "office-ui-fabric-react";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { ConnectionType, IPlannerConnectionInfo, IPlannerConnectionState } from "../../../store/integration/Office365Store";
import { PostMessageContext } from '../PostMessageReceiver';

export interface PlannerPostMessageContext extends PostMessageContext {
    scopeChanged: boolean;
}

export class Office365Utils {

    public static ScopeChangedMessage = "Admin Consent is already granted for this account. The regular connection was added.";

    public static canBeRestrictedScope(sourceType: SourceType): boolean {
        return (sourceType == SourceType.O365Planner || sourceType == SourceType.O365User || sourceType == SourceType.O365TeamsChannel);
    }

    public static GetConnectionType(connections: IPlannerConnectionState, connectionId: string | undefined): ConnectionType | undefined {
        const connection = connections?.data.find(_ => _.id === connectionId);
        return connection?.connectionType;
    }

    public static getConnectionsList(connections: IPlannerConnectionState, sourceType: SourceType): IPlannerConnectionInfo[] {
        const result = Office365Utils.canBeRestrictedScope(sourceType)
            ? connections.data
            : connections.data.filter(_ => _.connectionType === ConnectionType.RestrictedPlannerSync);
        return result;
    }

    public static getNewRestrictedConnectionUrl(sourceType: SourceType){
        return Office365Utils.canBeRestrictedScope(sourceType)
            ? `/api/integration/office365/auth?connectionType=${ConnectionType.RestrictedPlannerSync}`
            : undefined;
    }

    public static getNewRestrictedConnectionTooltip() {
        return <span>Allows to create a connection to an existing Planner plan and connect to Microsoft Teams. Does not require Global Admin consent.
            &nbsp;<Link href={'https://help.ppm.express/89253-ppm-express-permission-prerequisites/ppm-express-permissions-prerequisites#section-1'} target="_blank">
                Learn more
            </Link>
        </span>
    }
    
    public static getNewAzureADConnectionUrl(sourceType: SourceType){
        return Office365Utils.canBeRestrictedScope(sourceType)
            ? `/api/integration/office365/auth?connectionType=${ConnectionType.AzureADSync}`
            : undefined;
    }

    public static getNewAzureADConnectionTooltip() {
        return <span>
            Allows to create a Office 365 connection for Azure AD User and Resource synchronization only. Requires access to read all user's profiles and group memberships only.
            &nbsp;<Link href={'https://help.ppm.express/89253-ppm-express-permission-prerequisites/ppm-express-permissions-prerequisites#section-2'} target="_blank">
                Learn more
            </Link>
        </span>
    }

    public static getNewConnectionTooltip() {
        return <span>Allows to create a connection to an existing or new Planner plan and Office 365 group, and connect to Microsoft Teams. Requires Global Admin consent.
            &nbsp;<Link href={'https://help.ppm.express/89253-ppm-express-permission-prerequisites/ppm-express-permissions-prerequisites#section-0'} target="_blank">
                Learn more
            </Link>
        </span>
    }

    public static getUpgradeConnectionUrl(connections: IPlannerConnectionState, connectionId: string) {
        return Office365Utils.GetConnectionType(connections, connectionId) !== ConnectionType.Full
            ? `/api/integration/office365/auth/connection/${connectionId}?connectionType=${ConnectionType.Full}`
            : undefined;
    }

    public static getRefreshConnectionUrl(connections: IPlannerConnectionState, connectionId: string) {
        const connectionType = Office365Utils.GetConnectionType(connections, connectionId);
        return `/api/integration/office365/auth/connection/${connectionId}?connectionType=${connectionType}`;
    }
}