import * as React from "react";
import * as analytics from '../../../analytics';
import { connect } from "react-redux";
import { ISmartsheetSheet, actionCreators, ISmartsheetConnectionState } from "../../../store/integration/SmartsheetStore";
import { IImportProjectMap } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import { useDataLoadingAfterConnectionValidationEffect, IImportEntity, IImportEntityMap, getValidationError } from "./common";
import { ProjectsImportProps } from "./ProjectsImportPanel";
import { UserState } from "../../../store/User";

type OwnProps = {
    connectionId: string;
    onRender: (props: ProjectsImportProps) => JSX.Element;
}

type StateProps = {
    user: UserState;
    sheets: ISmartsheetSheet[];
    isLoading: boolean;
    error: string | null;
    isImporting: boolean;
    connections: ISmartsheetConnectionState;
}

type Props = OwnProps & StateProps & typeof actionCreators;

const SmartsheetProjectImport = (props: Props) => {
    const { connectionId } = props;
    const entities = React.useMemo(() => props.sheets.map<IImportEntity>(_ => ({
        id: `${_.sheetId}`,
        name: _.sheetName,
        fullName: `${_.sheetName} (${_.sheetId})`,
        isAlreadyLinked: _.isAlreadyLinked
    })), [props.sheets]);
    const map = React.useMemo(() => props.sheets.reduce((m, _) => ({ ...m, [`${_.sheetId}`]: _ }), {}), [props.sheets]);

    const _onImport = React.useCallback((connectionId: string, maps: IImportEntityMap[]) => {
        props.importSheets(connectionId, maps.map<IImportProjectMap<ISmartsheetSheet>>(_ => ({
            linkingData: map[_.entityId],
            projectId: _.projectId,
            projectName: _.projectName
        })));
        analytics.trackImport('Import Sheets from Smartsheet', props.user, { count: maps.length });
    }, [map, props.user]);

    const connectionValidation = useDataLoadingAfterConnectionValidationEffect(
        connectionId, 
        props.connections, 
        props.isImporting, 
        props.verifyConnection,
        props.loadSheets
    );

    return props.onRender({
        label: "Sheets",
        connectionId: props.connectionId,
        isLoading: connectionValidation?.isValidating || props.isLoading,
        error: getValidationError(connectionValidation) ?? props.error,
        onImport: _onImport,
        oldImportGridProps: {
            entities: entities,
            getSourcesInfo: __ => __.sourceInfos.map(_ => ({
                sourceId: _.sourceData.sheetId,
                connectionId: _.connectionId,
                data: _
            }))
        }
    });
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        sheets: state.smartsheet.sheets.entities,
        isLoading: state.smartsheet.sheets.isLoading,
        error: state.smartsheet.sheets.error,
        isImporting: state.import.projects.isImporting,
        connections: state.smartsheet.connections
    }
}

export default connect(mapStateToProps, actionCreators)(SmartsheetProjectImport);