import React from 'react';
import { MyWork, MyWorkType, Status_ } from '../../store/MyWorkStore';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import { EntityType, StatusCategory } from '../../entities/common';
import { Field } from '../../entities/Metadata';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import ColoredStatusView from '../views/list/columns/ColoredStatusView';

type OwnProps = {
    work: MyWork;
};

type StateProps = {
    taskFields: Field[];
};

type Props = OwnProps & StateProps;

const MyWorkStatus = (props: Props) => {
    const { work, taskFields } = props;

    if (work.source.type === MyWorkType.Task) {
        const taskStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Task, taskFields)!;
        const statusOption = taskStatusDescriptor.getOptionOrDefault(work.attributes.Status, StatusCategory.NA);

        return <CategoryColorStatusView statusOption={statusOption} />
    }

    return work.attributes.Status !== null 
        ? <ColoredStatusView value={{ name: work.attributes.Status }} className={Status_.getCssClass(work.attributes.Status)} />
        : <></>
};

const mapStateToProps = (state: ApplicationState): StateProps => {
    const taskFields = state.fields[EntityType.Task];
    return {
        taskFields: taskFields.allIds.map(_ => taskFields.byId[_]),
    };
};

export default connect(mapStateToProps)(MyWorkStatus);
