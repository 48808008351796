import { ContextualMenuItemType, DefaultButton, DirectionalHint, IContextualMenuItem, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { CopyIcon } from "../../../utils/copy";
import { AiDigest, TextStyles, TextStylesMap } from "../common";
import { AiWarning } from "../../../common/ai/warning";

type Props = {
    aiDigest: AiDigest;
    isDigestLoading: boolean;
    selectedTextStyle: TextStyles;
    onNextClick: () => void;
    onBackClick: () => void;
    onTextStyleChanged: (textStyle: TextStyles) => void;
 }

const AIDigestReviewFooter = (props: Props) => {
    const { aiDigest, isDigestLoading, selectedTextStyle, onNextClick, onBackClick, onTextStyleChanged } = props;

    const adjustOptions: IContextualMenuItem[] = [
        {
            key: TextStylesMap[TextStyles.None],
            name: 'Default',
            onClick: () => onTextStyleChanged(TextStyles.None)
        },
        {
            key: TextStylesMap[TextStyles.Brief],
            name: 'Brief',
            onClick: () => onTextStyleChanged(TextStyles.Brief)
        },
        {
            key: TextStylesMap[TextStyles.Expanded],
            name: 'Expanded',
            onClick: () => onTextStyleChanged(TextStyles.Expanded)
        },
        {
            key: TextStylesMap[TextStyles.Bullets],
            name: 'Bullets',
            onClick: () => onTextStyleChanged(TextStyles.Bullets)
        }
    ];

    const adjustMenuItems = [
        { key: 'text-style', text: 'Text style', itemType: ContextualMenuItemType.Header },
        ...adjustOptions.map<IContextualMenuItem>(_ => ({
            key: _.key,
            name: _.name,
            iconProps: { iconName: _.key === TextStylesMap[selectedTextStyle] ? 'CheckMark' : undefined },
            onClick: _.onClick
        })),
    ];

    return <>
        <div className="panel-buttons">
            <PrimaryButton
                text="Next"
                disabled={!!aiDigest.errorMessage || isDigestLoading || !aiDigest.digest}
                onClick={onNextClick} />
            <DefaultButton
                text="Adjust"
                disabled={isDigestLoading}
                menuProps={{
                    items: adjustMenuItems,
                    directionalHint: DirectionalHint.topLeftEdge,
                    gapSpace: 10,
                    useTargetAsMinWidth: true,
                    calloutProps: { className: "header-callout" }
                }}
            />
            <DefaultButton
                text='Back'
                onClick={onBackClick}
            />
            <CopyIcon
                className="align-right" 
                disabled={isDigestLoading || !!aiDigest.errorMessage} 
                text={aiDigest.digest} 
                withFormat />
        </div>
        <AiWarning />
    </>
}

export default AIDigestReviewFooter;