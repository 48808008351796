import React, { useState } from 'react'
import { TimeTrackingGlobalSettings, actionCreators as tenantActionCreators  } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import { bindActionCreators, Dispatch } from 'redux';
import { EnableCalculatingResourcePlanActualsBasedOnReportedTime } from '../../timeTracking/common';
import { connect } from 'react-redux';
import LabellableComponent from '../../common/LabellableComponent';
import FlagInput from '../../common/inputs/FlagInput';
import { ActionButton } from 'office-ui-fabric-react';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';


type OwnProps = {
    saveSettings: (settings: Partial<TimeTrackingGlobalSettings>, callback?: () => void) => void;
    settings: TimeTrackingGlobalSettings;
    canManage: boolean;
}

type StateProps = {
    enableCalculatingResourcePlanActualsBasedOnReportedTime: boolean;
};

type ActionProps = {
    tenantActions: typeof tenantActionCreators;
}

type Props = OwnProps & StateProps & ActionProps;

function ReportedTimeSynchronizationSettings(props: Props) {

    const { settings, saveSettings, canManage, tenantActions, enableCalculatingResourcePlanActualsBasedOnReportedTime } = props;

    const [showCompletedWorkConfirmation, setShowCompletedWorkConfirmation] = useState(false);
    const [showResourcePlanActualsConfirmation, setShowResourcePlanActualsConfirmation] = useState(false);
    const [resourcePlanSyncInProgress, setResourcePlanSyncInProgress] = useState(false);

    const runResourcePlanSync = () => {
        setResourcePlanSyncInProgress(true);
        tenantActions.runResourcePlanActualsRecalculation();
    };

    return (
        <div className="settings-fields-holder time-tracking-settings">
            <div className="settings-row">
                <LabellableComponent className="field-container" label="Record Reported Time to Completed Work"
                    description={`Please define if user-reported time should be added to the tasks' Completed Work. This setting is global for the tenant, but it can be disabled at the Project level.`}>
                    <FlagInput value={settings.calculateCompletedWorkBasedOnReportedTime}
                        onChanged={_ => {

                            if (_) {
                                setShowCompletedWorkConfirmation(true);
                            }
                            else {
                                saveSettings({
                                    calculateCompletedWorkBasedOnReportedTime: _
                                });
                            }
                        }}
                        readOnly={!canManage}
                    />
                </LabellableComponent>

                {enableCalculatingResourcePlanActualsBasedOnReportedTime &&
                        
                    <LabellableComponent className="field-container" label="Record Reported Time to Resource Plan Actuals"
                        description={`Please define if user-reported time should be written to the projects’ Resource Plan Actuals`}>
                    
                        <FlagInput value={settings.calculateResourcePlanActualsBasedOnReportedTime}
                            onChanged={_ => {

                                if (_) {
                                    setShowResourcePlanActualsConfirmation(true);
                                }
                                else {
                                    saveSettings({
                                        calculateResourcePlanActualsBasedOnReportedTime: _
                                    });
                                }
                            }}
                            readOnly={!canManage}
                        />

                        <ActionButton disabled={!canManage || resourcePlanSyncInProgress || !settings.calculateResourcePlanActualsBasedOnReportedTime} iconProps={{ iconName: "Sync" }} onClick={runResourcePlanSync}>
                            Synchronize now
                        </ActionButton>
                    </LabellableComponent>
                }
            </div>

            {showCompletedWorkConfirmation &&
                <ConfirmationDialog
                    dialogContentProps={{
                        isMultiline: true,
                        title: "Confirm this action",
                        subText: `Tasks Completed Work will be recalculated immediately using the time reported in Users Timesheets.
                        
                    Are you sure you want to enable recording of the Reported Time to Tasks Completed Work in all Projects of your Tenant?`
                    
                    }}
                    onNo={() => { }}
                    onDismiss={() => setShowCompletedWorkConfirmation(false)}
                    
                    onYes={() => {
                        saveSettings({
                            calculateCompletedWorkBasedOnReportedTime: true
                        }, () => {
                            tenantActions.runTaskCompletedWorkRecalculation();
                        });
                    }}
                />}
            
            {showResourcePlanActualsConfirmation &&
                <ConfirmationDialog
                    dialogContentProps={{
                        isMultiline: true,
                        title: "Confirm this action",
                        subText: `Once the Feature is enabled, Resource Plan Actuals will be recalculated immediately using the time reported by Users in Timesheets. It will affect all Projects where the time was reported and overwrite all values in Actuals.  
                        
Are you sure you want to enable recording of the Reported Time to Resource Plan Actuals for all Resources of your Tenant and recalculate all Actuals? `
                    
                    }}
                    onNo={() => { }}
                    onDismiss={() => setShowResourcePlanActualsConfirmation(false)}
                    
                    onYes={() => {
                        saveSettings({
                            calculateResourcePlanActualsBasedOnReportedTime: true
                        }, runResourcePlanSync);
                    }}
                />}
        </div>
    );
}

const mapStateToProps = (_: ApplicationState): StateProps => ({
    enableCalculatingResourcePlanActualsBasedOnReportedTime: EnableCalculatingResourcePlanActualsBasedOnReportedTime(_.tenant)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportedTimeSynchronizationSettings);
