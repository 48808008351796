import * as React from 'react';
import { SearchBox, Callout, DirectionalHint, DefaultButton, SpinnerSize, Spinner, ISearchBox } from 'office-ui-fabric-react';
import { waitForFinalEvent, FormatDate } from '../utils/common';

export interface IExternalTaskSuggestion {
    id: string;
    name: string;
    date: string;
    icon?: string;
    type: string;
    url?: string;
}

type Props = {
    onItemSelected: (item: IExternalTaskSuggestion) => void;
    onChange: (newValue: string) => Promise<IExternalTaskSuggestion[] | undefined>;
    disabled?: boolean;
}
type State = { suggestions: IExternalTaskSuggestion[]; showSuggestions: boolean; isLoading: boolean; searchText: string; }
const timeDelay = 600;

export default class ExternalTaskSearch extends React.Component<Props, State> {
    private _searchBoxRoot?: HTMLDivElement;
    private _searchBoxInput?: HTMLInputElement;
    private _debouncedOnSearch: () => void;

    constructor(props: Props) {
        super(props);

        this.state = { suggestions: [], searchText: '', showSuggestions: false, isLoading: false };
        this._debouncedOnSearch = waitForFinalEvent(this._onChange, timeDelay, 'external-task-search');
    }

    render() {
        return (
            <div className="external-task-search">
                <SearchBox
                    placeholder="Search item by Name, Type or Id"
                    disabled={this.props.disabled}
                    value={this.state.searchText}
                    componentRef={this._componentRef}
                    onChange={(e: any, v: string) =>
                        this.setState({ searchText: v, isLoading: true, showSuggestions: true }, this._debouncedOnSearch)}
                    onClear={this._clear}
                    onEscape={() => this._dismiss()}
                    onFocus={() => !this.state.showSuggestions && this._onChange()}
                />
                {this.state.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                {
                    this.state.showSuggestions &&
                    <Callout
                        className="external-task-suggestions"
                        target={this._searchBoxRoot}
                        calloutWidth={this._searchBoxRoot && this._searchBoxRoot.clientWidth}
                        isBeakVisible={false}
                        gapSpace={5}
                        preventDismissOnScroll={true}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        directionalHintForRTL={DirectionalHint.bottomRightEdge}
                        onDismiss={() => this.setState({ showSuggestions: false, suggestions: [] })}>
                        {this._renderSuggestions(this.state.suggestions)}
                    </Callout>
                }
            </div>
        );
    }

    private _renderSuggestions = (suggestions: IExternalTaskSuggestion[]): JSX.Element | JSX.Element[] => {
        if (suggestions.length === 0) {
            return <div className="no-items">No matching items</div>;
        }

        return suggestions.map(_ => (
            <div key={_.id} className="external-task-suggestion">
                <DefaultButton onClick={() => {
                    this.props.onItemSelected(_);
                    this._dismiss();
                }}
                    styles={{
                        root: { border: "none", width: "100%" },
                        flexContainer: { justifyContent: "unset" }
                    }}>
                    {_.icon && <span className="icon" style={{ backgroundImage: `url(${_.icon})` }} title={_.type} />}
                    <span className="overflow-text external-task-suggestion-title">{_.name}</span>
                    <span className="suggestion-date">{FormatDate(_.date)}</span>
                </DefaultButton>
            </div>
        ));
    }

    private _onChange = () => {
        const { searchText } = this.state;
        this.setState({ searchText: searchText, isLoading: true, showSuggestions: true });
        const promise = this.props.onChange(searchText);

        promise
            .then(_ => {
                if (!_)
                    return;

                this.setState({
                    suggestions: _,
                    isLoading: false
                });
            })
            .catch(() => this.setState({ suggestions: [], isLoading: false }));
    }

    private _componentRef = (searchBox: ISearchBox | null) => {
        const search = searchBox as any;
        if (search && search._rootElement) {
            this._searchBoxRoot = search._rootElement.current;
            this._searchBoxInput = search._inputElement.current;
            this.forceUpdate();
        }
    }

    private _clear = () => {
        this.setState({
            suggestions: [],
            searchText: '',
            showSuggestions: false
        });
    }

    private _dismiss = () => {
        this._clear();
        this._searchBoxInput && this._searchBoxInput.blur();
    }
}