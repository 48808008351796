import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import EntityName from '../EntityName';
import { EntityType, SourceInfo } from '../../../../../entities/common';

export default class Name extends React.Component<IListViewFieldColumn<ProjectInfo>> {
    public render() {
        const { entity, isTimelineView, className, field, disableNavigation } = this.props;
        const settings = field?.settings?.views?.list;
        return <EntityName
            name={entity.attributes.Name}
            startDate={entity.attributes.StartDate}
            finishDate={entity.attributes.FinishDate}
            withDates={true}
            isTimelineView={isTimelineView}
            url={!disableNavigation ? `/project/${entity.id}` : undefined}
            className={className}
            imageId={entity.imageId}
            imageClassName='proj-logo'
            warnings={entity.warnings}
            isPrivate={entity.isPrivate}
            multipleItemsMessage="There are warnings related to this project. Please review project details for more information"
            sourceType={settings?.showLinkedSystems && entity.sourceInfos.map(_ => ({ type: _.type, url: SourceInfo.getUrl(_, EntityType.Project) }))}
            openNewPage={settings?.openNewPage}
        />;
    }
}