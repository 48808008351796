import * as React from 'react';
import { IPickerItemProps, IBasePickerSuggestionsProps } from 'office-ui-fabric-react/lib/Pickers';
import { getId } from 'office-ui-fabric-react';
import { IconButton } from 'office-ui-fabric-react/lib/components/Button';
import PPMXPicker, { PPMXPickerProps } from "./PPMXPicker";
import Link from '../Link';

export type Option = {
    key: string | number;
    text: string;
    icon?: string;
    url?: string;
}

export interface OptionsPickerProps extends PPMXPickerProps<Option> {
}

const defaultProps: Partial<OptionsPickerProps> = {
    inputProps: {size: 10},
    onRenderItem: (props: IPickerItemProps<Option>) => <SelectedItemDefault {...props} />,
    onRenderSuggestionsItem: (item: Option, itemProps?: IBasePickerSuggestionsProps) => (
        <div className="picker-suggestion-item">
            {_renderBody(item)}
        </div>
    ),
    pickerSuggestionsProps: { noResultsFoundText: "No options", loadingText: 'Loading', resultsMaximumNumber: 10 } as IBasePickerSuggestionsProps,
    getTextFromItem: (item: Option) => item.text
};

export class DebouncedOptionsPicker extends PPMXPicker<Option> {
    public static defaultProps: Partial<OptionsPickerProps> = { ...defaultProps, resolveDelay: 500 };
}

export default class OptionsPicker extends PPMXPicker<Option> {
    public static defaultProps: Partial<OptionsPickerProps> = defaultProps;
}

const SelectedItemDefault: (props: IPickerItemProps<Option>) => JSX.Element = (projectPickerItemProps: IPickerItemProps<Option>) => {
    const { item, onRemoveItem, disabled } = projectPickerItemProps;

    const itemId = getId();
    const onClickIconButton = (removeItem: (() => void) | undefined): () => void => (): void => removeItem?.();

    return (
        <div className={`picker-item ${disabled ? 'disabled' : ''}`}
            role='listitem'
            aria-labelledby={'selectedItemOption-' + itemId}>
            {item.url ? <Link href={item.url} title="Navigate" className="link">{_renderBody(item)}</Link> : _renderBody(item)}
            {!disabled && <IconButton onClick={onClickIconButton(onRemoveItem)} iconProps={{ iconName: 'Cancel', style: { fontSize: '12px' } }} />}
        </div>
    );
};
const _renderBody = (item: Option) => <>
    {item.icon && <span className="icon" style={{ backgroundImage: `url(${item.icon})` }} />}
    <span title={item.text}>{item.text}</span>
</>;