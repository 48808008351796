import * as React from "react";
import { DefaultButton } from "office-ui-fabric-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { UserState } from "../../store/User";
import { PPMFeatures, Subscription, showDemo } from "../../store/Tenant";
import { CommonOperations, contains } from "../../store/permissions";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";

type StateProps = {
    subscription: Subscription;
    user: UserState;
};

type Props = StateProps & RouteComponentProps<{}>;

const BookFreeOnboardingButton = (props: Props) => {
    const { history, user, subscription } = props;
    
    const isPartnersSupportAvailable = Subscription.contains(subscription, PPMFeatures.PartnersSupport);
    const hasAdministrate = user.permissions && contains(user.permissions.common, CommonOperations.Administrate);
    const showFreeOnboardingButton = subscription && subscription.isTrial && !isPartnersSupportAvailable && hasAdministrate && showDemo(subscription.productType);

    if (!showFreeOnboardingButton) {
        return null;
    }

    return (
        <DefaultButton
            className="nav-header-button with-background allow-shrink-title"
            text="Book Free Onboarding"
            iconProps={{ iconName: "Onboarding" }}
            onClick={() => history.push("/setup/demo")}
        />
    );
};

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
    };
}

export default withRouter<{}>(connect(mapStateToProps)(BookFreeOnboardingButton));
