import * as React from 'react';
import { Link } from 'office-ui-fabric-react';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import PostMessageReceiver, { PostMessageData } from './PostMessageReceiver';

type Props = {
    disabled: boolean;
    sourceType: SourceType,
    newConnectionUrl: string;
    onConnectionCreated: () => void;
}

const OAuthConnectButton = (props: React.PropsWithChildren<Props>) => {
    const target = `connectionDataReceived_${props.sourceType}`;
    return <>
        <PostMessageReceiver<PostMessageData>
            from={target}
            onMessageReceived={(_, data) => props.onConnectionCreated()} />
        <Link
            disabled={props.disabled}
            onClick={() => PostMessageReceiver.openPopupWindow(props.newConnectionUrl, target)}>
            {props.children}
        </Link>
    </>;
}

export default OAuthConnectButton;
