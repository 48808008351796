import * as React from 'react';
import { connect } from 'react-redux';
import { post } from '../../../fetch-interceptor';
import { bindActionCreators } from 'redux';
import * as NotificationsStore from "../../../store/NotificationsStore";
import { Dictionary } from '../../../entities/common';
import { ExtensionInfo } from '../../../store/ExtensionStore';

type ActionProps = {
    notificationActions: typeof NotificationsStore.actionCreators;
};

export type OwnProps = {
    onDismiss: () => void;
    info: ExtensionInfo;
    context: Dictionary<any>;
};

type Props = OwnProps & ActionProps;

const ExtensionTrigger = (props: Props) => {
    const { onDismiss, context, notificationActions, info } = props;
    const urlStr = info.url;

    React.useEffect(() => {
        if (!urlStr) {
            notificationActions.pushNotification({ message: `Extension is not configured well.`, type: NotificationsStore.NotificationType.Error });
            onDismiss();
            return;
        }

        post(`api/metadata/extensions/${info.id}/trigger`, context)
            .then(() => {
                onDismiss();
                notificationActions.pushNotification({ message: `External action is triggered!`, type: NotificationsStore.NotificationType.Success });
            })
            .catch(_ => onDismiss());
    }, [urlStr, context, notificationActions, onDismiss]);

    return null;
};

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        notificationActions: bindActionCreators(NotificationsStore.actionCreators, dispatch)
    };
}

export default connect(undefined, mergeActionCreators)(ExtensionTrigger);
