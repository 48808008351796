import React from "react";
import { CategoryStageOption } from "../../../../entities/StageDescriptor";
import ColorStageView, { ColorStageViewProps } from "./ColorStageView";

type Props = Omit<ColorStageViewProps, 'value'> & {
    stageOption: CategoryStageOption | undefined;
    withBorder?: boolean;
};

const CategoryColorStageView = (props: Props) => {
    const { stageOption, withBorder } = props;

    if (!stageOption) {
        return null;
    }

    const style = withBorder
        ? { color: stageOption.color, border: `2px solid ${stageOption.color}` }
        : { color: stageOption.color };

    return <ColorStageView {...props} title={stageOption.name} style={style} />
}

export default CategoryColorStageView;
