import * as React from "react";
import * as Metadata from '../../entities/Metadata';
import { DisplayFieldService } from "./DisplayFieldService";
import { Dictionary, EntityType } from "../../entities/common";

type Props = {
    field: Metadata.Field;
    entity: Dictionary<any>;
    entityType?: EntityType;
    viewType?: "List" | 'Card';
    disableNavigation?: boolean;
}

export const DisplayField: React.FunctionComponent<Props> = props => {
    const field = props.field;
    if (!field) {
        return null;
    }

    const fieldValue = props.entity.attributes[field.name];
    const Formatter = props.viewType === 'Card'
        ? DisplayFieldService.getCardViewFieldFormatter(field, !props.disableNavigation, props.entityType)
        : DisplayFieldService.getFieldFormatter(field, !props.disableNavigation, props.entityType);

    if (Formatter) {
        return <Formatter value={fieldValue} />;
    }

    return <>{fieldValue !== undefined && fieldValue !== null ? `${fieldValue}` : ''}</>;
}