import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import ButtonBox from "../ButtonBox";

type OwnProps = {
    big?: boolean;
}
type StateProps = {
    intercomAppId: string | null;
};
type Props = OwnProps & StateProps;
export const ContactUs = (props: Props) => <ButtonBox
    iconName="PPMXQuickStartContactUs"
    name="Have any questions? Drop us a line!"
    buttonText="Contact us"
    className="live_chat"
    big={props.big}
    href={`mailto:${props.intercomAppId}@incoming.intercom.io`} />

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        intercomAppId: state.analytics.intercomAppId
    };
}

export default connect(mapStateToProps)(ContactUs);