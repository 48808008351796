import React, { useMemo } from 'react';
import { buildRoadmapItemIconName, IRoadmapItem } from '../../entities/Subentities';
import { distinct, FormatDate, formatFieldValue, formatValue, notUndefined } from '../utils/common';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import ResourceFormatter from '../common/formatters/ResourceFormatter';
import { estimateRoadmapItemDuration, estimateRoadmapItemWorkDuration, isMilestone } from './map';
import { Field, FieldType, FormatType, getLabel } from '../../entities/Metadata';
import { TagFormatter } from '../common/formatters/TagFormatter';
import { StatusCategory, Dictionary, EntityType, PROGRESS_COMPLETED } from '../../entities/common';
import { UserState } from '../../store/User';
import { RoadmapItemOrigin } from './RoadmapItemOrigin';
import EntityName from '../views/list/columns/EntityName';
import { CalendarDataSet } from '../../store/CalendarStore';
import { TooltipFakeField, TooltipFieldSettings } from './ConfigureRoadmapTooltipPanel';
import * as Metadata from "../../entities/Metadata";
import { DisplayField } from '../common/DisplayField';
import TooltipField from './TooltipField';
import RoadmapItemAttrsTooltipField from './RoadmapItemAttrsTooltipField';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

export const barFakeFields: TooltipFakeField[] = [
    {
        id: "098e465d-579d-4813-9a3b-29f6b8f8db53",
        name: 'Period',
        label: 'Period',
        type: FieldType.Date,
    },
    {
        id: "eba4a41f-d2f0-4b3a-974c-70babf2d8840",
        name: 'Duration',
        label: 'Duration',
        type: FieldType.Integer,
        settings: { format: FormatType.Duration },
    },
];

export const keyDateFakeFields: TooltipFakeField[] = [
    {
        id: "4d9fffa7-7114-446e-b565-03b53040092a",
        name: 'Date',
        label: 'Date',
        type: FieldType.Date,
    },
];

const requiredFields = [
    'Source',
];

const defaultViewFields = [
    'Date',
    'Period',
    'Duration',
    'Progress',
    'StoryPoints',
    'EstimatedEffort',
    'EstimatedCost',
    'EstimatedBenefit',
    'AssignedTo',
    'Tags',
];

type Props = {
    entity: IRoadmapItem;
    user: UserState;
    calendar: CalendarDataSet;
    onEdit: (entity: IRoadmapItem) => void;
    fields: Field[];
    showWarnings: boolean;
    settings?: TooltipFieldSettings;
};

export default function RoadmapItemTooltipContent(props: Props) {
    const { entity, showWarnings, calendar, settings, fields, onEdit } = props;
    
    const startDate = entity.attributes.StartDate;
    const finishDate = entity.attributes.FinishDate;
    const assignees = entity.attributes.AssignedTo ?? [];
    const duration = estimateRoadmapItemDuration(entity);
    const workDaysDuration = estimateRoadmapItemWorkDuration(entity, calendar);

    const fieldsByNames: Dictionary<{ hidden?: boolean; render: (key: string) => JSX.Element | null; }> = {
        Source: {
            hidden: !entity.externalData?.["ImportedFromName"],
            render: key => (
                <TooltipField key={key} label="Source">
                    <RoadmapItemOrigin entity={entity} />
                </TooltipField>
            )
        },
        Date: {
            hidden: !isMilestone(entity),
            render: key => <TooltipField key={key} label="Date">{FormatDate(finishDate)}</TooltipField>
        },
        Period: {
            hidden: isMilestone(entity),
            render: key => (
                <TooltipField key={key} label="Period">
                    {FormatDate(startDate)} - {FormatDate(finishDate)}
                </TooltipField>
            )
        },
        Duration: {
            hidden: isMilestone(entity),
            render: key => (
                <TooltipField key={key} label="Duration">
                    <div title="Work days / Calendar days">
                        {formatValue(workDaysDuration, FormatType.Days)} / {formatValue(duration, FormatType.Days)}
                    </div>
                </TooltipField>
            )
        },
        Progress: {
            render: key => (
                <RoadmapItemAttrsTooltipField key={key} name="Progress">
                    <div className="completed">
                        {formatValue(entity.attributes.Progress, FormatType.Percent)}
                    </div>
                </RoadmapItemAttrsTooltipField>
            )
        },
        StoryPoints: {
            hidden: isMilestone(entity),
            render: key => (
                <RoadmapItemAttrsTooltipField key={key} name="StoryPoints">
                    <div className="align-center">
                        {formatValue(entity.attributes.StoryPoints)}
                    </div>
                </RoadmapItemAttrsTooltipField>
            )
        },
        EstimatedEffort: {
            hidden: isMilestone(entity),
            render: key => (
                <RoadmapItemAttrsTooltipField key={key} name="EstimatedEffort">
                    <div className="align-center">
                        {formatFieldValue(entity.attributes.EstimatedEffort, FormatType.Duration, props.user, EntityType.RoadmapItem)}
                    </div>
                </RoadmapItemAttrsTooltipField>
            )
        },
        EstimatedCost: {
            hidden: isMilestone(entity),
            render: key => (
                <RoadmapItemAttrsTooltipField key={key} name="EstimatedCost">
                    <div className="align-center">
                        {formatFieldValue(entity.attributes.EstimatedCost, FormatType.Cost, props.user, EntityType.RoadmapItem)}
                    </div>
                </RoadmapItemAttrsTooltipField>
            )
        },
        EstimatedBenefit: {
            hidden: isMilestone(entity),
            render: key => (
                <RoadmapItemAttrsTooltipField key={key} name="EstimatedBenefit">
                    <div className="align-center">
                        {formatFieldValue(entity.attributes.EstimatedBenefit, FormatType.Cost, props.user, EntityType.RoadmapItem)}
                    </div>
                </RoadmapItemAttrsTooltipField>
            )
        },
        AssignedTo: {
            render: key => (
                <RoadmapItemAttrsTooltipField key={key} name="AssignedTo">
                    {
                        assignees.length > 0
                            ? <div className="align-center">
                                <ResourceFormatter resource={assignees} withNavigation={false} />
                            </div>
                            : <div className="align-center">
                                <span>(No one is assigned yet)</span>
                            </div>
                    }
                </RoadmapItemAttrsTooltipField>
            )
        },
        Tags: {
            render: key => (
                <RoadmapItemAttrsTooltipField key={key} name="Tags">
                    <TagFormatter
                        entityType={EntityType.RoadmapItem}
                        withNavigation={true}
                        value={entity.attributes.Tags}
                    />
                </RoadmapItemAttrsTooltipField>
            )
        },
    };

    const defaultFields = useMemo(() => requiredFields.concat(settings?.fieldNames?.length ? settings.fieldNames : defaultViewFields)
        .filter(_ => fieldsByNames[_] && !fieldsByNames[_].hidden).filter(distinct),
    [settings?.fieldNames, fieldsByNames]);
    
    const otherSettingsFields = useMemo(() => settings?.fieldNames.filter(_ => !fieldsByNames[_])
        .map(_ => fields.find(__ => __.name === _)).filter(notUndefined) ?? [],
    [settings?.fieldNames, fields]);
    
    const roadmapItemStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.RoadmapItem, fields)!;
    const statusOption = entity.attributes.Status
        ? roadmapItemStatusDescriptor.getOptionOrDefault(entity.attributes.Status, StatusCategory.NA)
        : roadmapItemStatusDescriptor.getCategoryDefaultOption(StatusCategory.NA);

    return (
        <div className="timeline-tooltip clickable" onClick={() => onEdit(entity)}>
            <div className="header">
                <EntityName
                    name={entity.attributes.Name}
                    isTimelineView
                    replaceCurrentUrl
                    className={`${entity.attributes.Progress === PROGRESS_COMPLETED ? 'crossed-out' : ''}`}
                    iconName={buildRoadmapItemIconName(entity)}
                    sourceType={EntityName._buildSourceType(entity, "Roadmap Item")} />
                <CategoryColorStatusView
                    statusOption={statusOption}
                />
            </div>
            <div className="content">
                {
                    defaultFields.map(_ => fieldsByNames[_].render(_))
                }
                {
                    otherSettingsFields.map(_ => (
                        <TooltipField key={_.id} label={Metadata.getLabel(_)}>
                            <DisplayField field={_} entity={entity} entityType={EntityType.RoadmapItem} />
                        </TooltipField>
                    ))
                }
                {showWarnings && renderWarnings()}
            </div>
        </div>
    );

    function renderWarnings(): JSX.Element[] {
        return entity.warnings?.map((_, index) => (
            <MessageBar messageBarType={MessageBarType.warning} isMultiline key={`warning${index}`}
                messageBarIconProps={{ iconName: "Warning", className: "warning-icon" }}>
                {_.text}
            </MessageBar>
        ));
    }
}
