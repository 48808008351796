import * as React from 'react';
import { DefaultButton } from "office-ui-fabric-react";

const AddNewFieldButtonStyle = { width: "100%" };
export const AddNewFieldButton = React.memo(({ allowManageFields, onClick }: { allowManageFields: boolean, onClick: () => void }) => {
    return <DefaultButton
        iconProps={{ iconName: 'Add' }}
        text="New Field"
        className="new-button"
        disabled={!allowManageFields}
        onClick={onClick}
        style={AddNewFieldButtonStyle} />;
});