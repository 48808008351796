import * as React from 'react';
import { ICalculationSettings, StatusCalculationTypes } from '../../store/Tenant';
import { Toggle, MessageBar, MessageBarType, Checkbox, DirectionalHint, Icon, TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import { contains } from '../../store/permissions';
import Link from '../common/Link';

type Props = {
    hasML: boolean;
    entityTitle: string,
    calculation: ICalculationSettings,
    onChange?: (calculation: ICalculationSettings) => void
}

export class StatusAutoCalculationSettings extends React.Component<Props> {
    public render() {
        const { hasML, calculation, onChange } = this.props;
        const statusCalculation = calculation.statusCalculation;
        const disabled = !onChange;
        const isAuto = contains(calculation.statusCalculation, StatusCalculationTypes.Auto);
        const showManual = statusCalculation !== StatusCalculationTypes.Manual;
        const statusesDisabled = disabled || (statusCalculation === StatusCalculationTypes.Auto && calculation.manualDescription);
        const descriptionDisabled = disabled || statusCalculation !== StatusCalculationTypes.Auto;

        return <>
            <div className="status-calculation-toggle align-center">
                <span>
                    <span className="check-label">Calculate statuses automatically</span>
                    <TooltipHost content={`Turn this option on to enable automatic calculations for statuses and descriptions. Turn this option off to manage all ${this.props.entityTitle} statuses and their descriptions manually.`} delay={TooltipDelay.zero} hostClassName='tooltip' className='text-hint' calloutProps={{ directionalHint: DirectionalHint.rightCenter }}>
                        <Icon iconName="Info" />
                    </TooltipHost>
                </span>
                <Toggle className="toggle"
                    disabled={disabled}
                    checked={isAuto}
                    onChange={(e, checked: boolean) =>
                        this.props.onChange!({ statusCalculation: checked ? StatusCalculationTypes.Auto : StatusCalculationTypes.Manual, manualDescription: !checked })}
                    onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
            </div>
            {showManual && <>
                <div className="status-calculation-toggle">
                    <Checkbox
                        className="inline-block"
                        disabled={statusesDisabled}
                        checked={statusCalculation === StatusCalculationTypes.AutoManual}
                        onChange={(e, checked: boolean) =>
                            this.props.onChange!({
                                statusCalculation: checked ? StatusCalculationTypes.AutoManual : StatusCalculationTypes.Auto,
                                manualDescription: checked
                            })}
                    />
                    <span className={`check-label check-box-label ${statusesDisabled ? 'is-disabled' : ''}`}>Allow to override status and description manually</span>
                    <TooltipHost content={`Turn this option on to maintain automatic calculations for ${this.props.entityTitle} statuses and descriptions while allowing you to override the calculated values and set the required status or description manually for a specified period.`} delay={TooltipDelay.zero} hostClassName='tooltip' className='text-hint' calloutProps={{ directionalHint: DirectionalHint.rightCenter }}>
                        <Icon iconName="Info" />
                    </TooltipHost>
                </div>
                <div className="status-calculation-toggle">
                    <Checkbox
                        className="inline-block"
                        disabled={descriptionDisabled}
                        checked={calculation.manualDescription && statusCalculation === StatusCalculationTypes.Auto}
                        onChange={(e, checked: boolean) =>
                            this.props.onChange!({
                                statusCalculation: StatusCalculationTypes.Auto,
                                manualDescription: checked
                            })}
                    />
                    <span className={`check-label check-box-label ${descriptionDisabled ? 'is-disabled' : ''}`}>Define description manually</span>
                    <TooltipHost content={`Turn this option on to maintain automatic calculations for ${this.props.entityTitle} statuses and manual definition of status descriptions.`} delay={TooltipDelay.zero} hostClassName='tooltip' className='text-hint' calloutProps={{ directionalHint: DirectionalHint.rightCenter }}>
                        <Icon iconName="Info" />
                    </TooltipHost>
                </div>
            </>
            }

            {!hasML && <MessageBar messageBarType={MessageBarType.warning}>
                Automated status calculation feature is not available in your PPM Express plan. Please
                <Link className='contact-us-link' href="https://ppm.express/contact-us" target="_blank">contact us</Link> to request a demo or get trial.
            </MessageBar>}
        </>;
    }
}