import { SortDirection } from "../../entities/common";
import { StoreHelper } from "../services/storeHelper";
import { IViewsState, ICardViewState } from "../views";

const DEFAULT_SORT = {
    fieldName: "Name",
    direction: SortDirection.ASC
}

export const VIEWS_DEFAULTS: IViewsState = {
    activeViewType: 'timeline',
    card: undefined as any as ICardViewState,
    list: {
        fakeFields: [],
        selectedByDefault: [],
        subViews: StoreHelper.create([]),
        sortBy: DEFAULT_SORT
    },
    timeline: {
        subViews: StoreHelper.create([]),
        selectedByDefault: [],
        sortBy: DEFAULT_SORT
    }
}