import * as React from 'react';
import { IButtonProps, CommandBar, ICommandBarItemProps, CommandBarButton } from "office-ui-fabric-react";
import { quantizationConfigMap } from "./TimelineScale";
import { Quantization } from '../../../entities/common';

export const quantizationOptions = [Quantization.days, Quantization.weeks, Quantization.months, Quantization.quarters, Quantization.years];

type Props = { value: Quantization, onChange: (_: Quantization) => void; options?: Quantization[] }
export default class QuantizationSelector extends React.PureComponent<Props>{
    render() {
        const { value, onChange, options } = this.props;
        return <CommandBar
            className="quantization-selector"
            styles={{ root: { height: 15, paddingRight: 0, paddingLeft: 0 } }}
            items={(options || quantizationOptions).map<ICommandBarItemProps>(_ => ({
                key: _,
                text: quantizationConfigMap[_].title,
                canCheck: true,
                checked: value == _,
                onClick: () => value != _ && onChange(_),
                title: quantizationConfigMap[_].title,
            }))}
            buttonAs={this._renderCommandBarButton} />;
    }

    private _renderCommandBarButton(props: IButtonProps) {
        return <CommandBarButton {...props}
            styles={{
                label: { fontSize: 12, color: "white", padding: "unset", margin: "unset", marginTop: -1 },
                root: { background: "lightgrey", borderRadius: 14, minWidth: "unset", width: 32, paddingLeft: 0, paddingRight: 0, paddingTop: 1, paddingBottom: 1, marginLeft: 4 },
                rootChecked: { background: "green" },
                flexContainer: { padding: "unset" },
                rootHovered: { background: "grey" },
            }}
            text={props.title?.[0]} />;
    }
}
