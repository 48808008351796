import "./TaskPredecessors.css";
import * as React from 'react';
import { Icon, IconButton } from 'office-ui-fabric-react';
import AbbreviationIcon from '../AbbreviationIcon';
import { IPredecessorInfo, PredecessorTypeMetadata } from '../../../entities/Subentities';
import { FormatType } from "../../../entities/Metadata";
import { formatValue } from "../../utils/common";

interface Props {
    readonly: boolean;
    onEdit: (predecessor: IPredecessorInfo) => void;
    onRemove: (predecessor: IPredecessorInfo) => void;
    predecessor: IPredecessorInfo;
    allowEdit: boolean;
}

const TaskPredecessor = (props: Props) => {
    const { readonly, predecessor, onRemove, allowEdit, onEdit } = props;
    const lagLabel = React.useMemo(() => `${formatValue(predecessor.lag, FormatType.Days)}`, [predecessor.lag])
    const lagTitle = React.useMemo(() => `${Math.abs(predecessor.lag)} ${Math.abs(predecessor.lag) !== 1 ? "days" : "day"} ${predecessor.lag < 0 ? "lead" : "lag"} time`,
        [predecessor.lag])
    return <div className="predecessor-box align-center">
        <AbbreviationIcon abbreviation={PredecessorTypeMetadata[predecessor.type].abbreviation}
            title={`${PredecessorTypeMetadata[predecessor.type].label} dependency type`} />
        <div className="predecessor-name ellipsis">{predecessor.name}</div>
        <div className="predecessor-lag" title={lagTitle}>
            <Icon iconName={"PublicCalendar"} />{lagLabel}
        </div>
        {!readonly && <IconButton disabled={!allowEdit} menuIconProps={{ iconName: 'Edit', style: { fontSize: '16px' } }} title="Edit" onClick={() => onEdit(predecessor)} />}
        {!readonly && <IconButton menuIconProps={{ iconName: 'Delete', style: { fontSize: '16px' } }} title="Delete" onClick={() => onRemove(predecessor)} />}
    </div>;
}
export default TaskPredecessor;
