import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from "../../../store/index";
import { IIdeaInfo } from '../../../store/ProjectsListStore';
import { Dictionary, EntityType } from '../../../entities/common';
import { FormatterLogoItems } from './PortfolioFormatter';
import { Idea } from '../../../store/IdeasListStore';
import { PPMFeatures, Subscription } from '../../../store/Tenant';

type OwnProps = {
    idea?: IIdeaInfo | null;
    withNavigation?: boolean;
    target?: string;
};
type StateProps = {
    ideasById: Dictionary<Idea>;
    subscription: Subscription;
}
type Props = OwnProps & StateProps;

const IdeaFormatter = (props: Props) => {
    const { idea, withNavigation, ideasById, target, subscription } = props;

    if (!idea) {
        return null;
    }

    const entities = [idea].map(_ => ({ ..._, imageId: ideasById[_.id]?.imageId }));

    return <FormatterLogoItems
        entityType={EntityType.Idea}
        entities={entities}
        withNavigation={withNavigation && Subscription.contains(subscription, PPMFeatures.Ideation)}
        target={target} cssClass="idea-logo" />;
}

function mapStateToProps(state: ApplicationState, _ownProps?: OwnProps): StateProps {
    return {
        ideasById: state.ideas.byId,
        subscription: state.tenant.subscription
    }
}

export default connect(mapStateToProps, {})(IdeaFormatter);