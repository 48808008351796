import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import OdataTokensList from "./reporting/OdataTokensList";
import ReportsList from "./reporting/ReportsList";
import SettingsPage from "./settings/SettingsPage";
import { ApplicationState } from "../store";
import { UserState } from "../store/User";
import { connect } from "react-redux";
import { Subscription } from "../store/Tenant";
import { notUndefined } from "./utils/common";

type OwnProps = RouteComponentProps<{}>;
type StateProps = {
    user: UserState;
}
type Props = OwnProps & StateProps;

const ReportSettings = (props: Props) => {

    return <SettingsPage title="Report Settings"
        sections={[
            {
                key: "report",
                name: "Reports",
                icon: "GroupedList",
                className: "with-grid",
                onRenderBody: () => <ReportsList />
            },
            Subscription.canODataView(props.user) ?
            {
                key: "token",
                name: "OData Tokens",
                icon: "GroupedList",
                className: "with-grid",
                    onRenderBody: () => <OdataTokensList />
                } : undefined]
            .filter(notUndefined)
        }>
    </SettingsPage>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ReportSettings);