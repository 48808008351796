import * as React from 'react';
import "./LabellableComponent.css";
import { Icon, IObjectWithKey, ActionButton, IButtonProps, IconButton, IContextualMenuProps, TooltipHost, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react';
import EntityWarning from "../warnings/EntityWarning";
import { IWarning } from "../../../entities/common";
import { TextFormatter } from '../formatters/TextFormatter';

export type LabelAction = IButtonProps & IObjectWithKey;

type LabellableComponentProps = {
    className?: string;
    label?: string;
    labelActions?: LabelAction[];
    description?: string | JSX.Element | JSX.Element[];
    required?: boolean;
    onClick?: () => void;
    isConfigureMode?: boolean;
    moreMenuProps?: IContextualMenuProps;
    warnings?: IWarning[];
}

const Description = (props: {value?: string | JSX.Element | JSX.Element[]}) => {
    return <div className='callout-content'>
        {typeof props.value === 'string'
            ? <TextFormatter 
                value={(props.value as string).trim()}
                hideTitle={!!props.value}
                isMultiline
                withNavigation
                urlTextMaxLen={50} />
            : <>{props.value}</>
        }</div>;
}

const LabellableComponent = (props: React.PropsWithChildren<LabellableComponentProps>) => {
    const { label, description, labelActions, warnings, moreMenuProps } = props;

    if (!label) {
        return <>{props.children}</>;
    }

    return (
        <div onClick={props.onClick} className={props.className}>
            <div className="field-label-container">
                <div className={`field-label ${props.required ? " required" : ""}`} title={label}>
                    {label}
                </div>
                {
                    description &&
                    <TooltipHost
                        calloutProps={{ directionalHint: DirectionalHint.topLeftEdge }}
                        tooltipProps={{ onRenderContent: () => <Description value={description}/> }}
                        hostClassName="align-center"
                        className='field-hint'
                        delay={TooltipDelay.zero}
                        id='customID'
                    >
                        <Icon
                            onClick={(e) => e.stopPropagation()}
                            iconName="Info"
                            className='callout-icon'
                        />
                    </TooltipHost>
                }
                {warnings && <EntityWarning warnings={warnings} />}
                {
                    moreMenuProps !== undefined &&
                    <ActionButton menuIconProps={{ iconName: 'More' }}
                        tabIndex={-1}
                        menuProps={moreMenuProps}
                    />
                }
                {labelActions && <div className='align-right'>
                    {labelActions.map(_ => <IconButton {..._}/>)}
                </div>}
            </div>
            {props.children}
        </div>
    );
}
export default LabellableComponent;