import React from 'react'
import { connect } from 'react-redux';
import { DisplayField } from '../../../../components/common/DisplayField';
import { FormatDate, toDictionaryByName } from '../../../../components/utils/common';
import { Field } from '../../../../entities/Metadata';
import { ITaskAttrs } from '../../../../entities/Subentities';
import { Dictionary, EntityType, IExtensibleEntity } from '../../../../entities/common';
import { ApplicationState } from '../../../../store';
import { nameof } from '../../../../store/services/metadataService';
import { isFieldHasValue, renderTooltipField } from '../cell/TimeTrackingCellTooltip';
import { TimeTrackingTaskDetails } from '../../../../store/TimeTrackingStore';
import Title from '../../../views/list/columns/task/Title';
import { NameField } from '../../common';

type OwnProps = {
    task: TimeTrackingTaskDetails;
}

type StateProps = {
    fields: Field[];
}

type Props = OwnProps & StateProps;

function TimeTrackingTaskTooltip(props: Props) {
    const { task, fields } = props;

    const taskFields = toDictionaryByName(fields);
    
    const fieldsByNames: Dictionary<{ visible?: boolean; render?: () => JSX.Element | null; }> = {

        Date: {
            visible: isMilestone(task),
            render: () => renderTooltipField("Date", FormatDate(task.attributes[nameof<ITaskAttrs>("DueDate")]) ?? '')
        },
        Period: {
            visible: !isMilestone(task) && hasDates(task),
            render: () => renderTooltipField("Period", `${FormatDate(task.attributes[nameof<ITaskAttrs>("StartDate")]) ?? ''} - ${FormatDate(task.attributes[nameof<ITaskAttrs>("DueDate")]) ?? ''}`)            
        },
        [nameof<ITaskAttrs>("Duration")]: {
            visible: !isMilestone(task) && hasDates(task)         
        },
        [nameof<ITaskAttrs>("Progress")]: {},
        [nameof<ITaskAttrs>("CompletedWork")]: {},

        [nameof<ITaskAttrs>("Effort")]: {},
        [nameof<ITaskAttrs>("AssignedTo")]: {},

        [nameof<ITaskAttrs>("Description")]: {visible: true}
    };

    const defaultRender = (fieldName: string) => {
        const field = taskFields[fieldName];
        return renderTooltipField(field.label || field.name, <DisplayField entity={task} field={field} viewType="List" />, field.name.toLowerCase());
        
    };

    const renderedFields = Object.keys(fieldsByNames)
        .filter(field => fieldsByNames[field].visible ?? isFieldHasValue(task.attributes[field]))
        .map(field => {
            return fieldsByNames[field].render?.() || defaultRender(field);
        });

    return (
        <div className='timeline-tooltip time-tracking-tooltip'>
            <div className="header">
                <Title 
                    name={task.attributes.Name}
                    entity={task as any}
                    field={NameField}
                    disableNavigation                    
                />

                {task.isArchived
                    ? <>(Archived)</>
                    : task.isDeleted
                        ? <>(Deleted)</>
                        : <></>
                }
            </div>
            <div className="content">
                {renderedFields}
            </div>
        </div>
    );
}

const isMilestone = (task: IExtensibleEntity): boolean => {
    return task.attributes[nameof<ITaskAttrs>("IsMilestone")];
}

const hasDates = (task: IExtensibleEntity): boolean => {
    return task.attributes[nameof<ITaskAttrs>("StartDate")] || task.attributes[nameof<ITaskAttrs>("DueDate")];
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps { 
    const fields = state.fields[EntityType.Task];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
    };
}

export default connect(mapStateToProps)(TimeTrackingTaskTooltip);