import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import ErrorPage from './components/ErrorPage';
import LoginPage, { LoginAppProps } from './LoginPage'
import AuthErrorApp from './components/AuthErrorApp'

export const LoginApp = (props: LoginAppProps) => {
    return (
        <Switch>
            <Route exact path="/login" render={() => <LoginPage {...props} />} />
            <Route exact path="/signup" render={() => props.disableSignUp 
                ? <Redirect to={{ pathname: "/login" }} />
                : <LoginPage {...props} isSignUp />
            } />
            <Route exact path="/Error" render={() => <ErrorPage title="Internal Error" />} />
            <Route exact path="/AuthError" component={AuthErrorApp} />
            <Route path="/" render={(_: RouteComponentProps<{}>) => {
                let search: string | undefined = undefined;
                if (_.location.pathname != "/") {
                    const param = new URLSearchParams();
                    param.append("redirectUri", `${_.location.pathname}${_.location.search}`);
                    search = param.toString();
                }
                return <Redirect to={{ pathname: "/login", search }} />;
            }} />
        </Switch>
    );
};