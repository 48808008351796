import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import { CommandBar, CheckboxVisibility, DetailsListLayoutMode, Selection, IColumn, IContextualMenuItem, MessageBar, Link, MessageBarType } from 'office-ui-fabric-react';
import { actionCreators, Report, ReportInfo, ReportMetadata, } from "../../store/ReportsListStore";
import { IReportingInfo } from "../../store/Tenant";
import { UserState } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import DetailsListWrapper from "../common/DetailsListWrapper";
import RemoveDialog from "../common/RemoveDialog";
import ReportEdit from "./ReportEdit";
import EntityName from "../views/list/columns/EntityName";
import { IsActive } from "../views/list/columns/strategicPriority/IsActive";
import { Dictionary } from "../../entities/common";
import { ControlSpiner } from "../common/Spinner";

type StateProps = {
    reports: Report[];
    reportsMetadata: Dictionary<ReportMetadata>;
    user: UserState;
    reporting: IReportingInfo,
    isLoading: boolean
}

type OwnProps = {}

type Props = StateProps & OwnProps & typeof actionCreators;

const ReportsList = (props: Props) => {

    const getCommands = (): IContextualMenuItem[] => {

        return [
            {
                key: 'addRow',
                name: `New Custom Report`,
                iconProps: { iconName: 'Add' },
                disabled: !canManage,
                onClick: () => { setShowPanel(true); setReport(undefined); }
            },
            {
                key: 'updateRow',
                name: `Edit Report`,
                iconProps: { iconName: 'Edit' },
                disabled: !canManage || selectedCount !== 1,
                onClick: () => { setShowPanel(true); setReport(selection.getSelection()[0]); }
            },
            {
                key: 'deleteRow',
                name: `Delete Report`,
                iconProps: { iconName: 'Delete' },
                disabled: !canManage || selection.getSelection().filter(_ => _.isCustom).length === 0,
                onClick: () => { setShowRemoveDialog(true) }
            }
        ];
    }

    const buildColumns = (): IColumn[] => {
        return [
            {
                key: "title",
                fieldName: "title",
                name: "Title",
                minWidth: 100,
                maxWidth: 300,
                isResizable: true,
                onRender: (report: Report) => <EntityName
                    className='hover-underline'
                    iconName="PPMXReport"
                    imageClassName={`report ${!report.isActive ? "inactive" : ""}`}
                    name={report.title}
                    onClick={() => { setShowPanel(true); setReport(report); }} />
            },
            {
                key: "template",
                fieldName: "template",
                name: "Report Template",
                minWidth: 100,
                maxWidth: 450,
                isResizable: true,
                onRender: (report: Report) => props.reportsMetadata[report.id]?.downloadUrl &&
                    <Link className="text-link" href={props.reportsMetadata[report.id].downloadUrl} target="_blank">{resolveReportTemplateText(report.id)}</Link>
            },
            {
                key: "url",
                fieldName: "url",
                name: "Report URL",
                minWidth: 100,
                maxWidth: 300,
                isResizable: true
            },
            {
                key: "scope",
                fieldName: "scope",
                name: "Scope",
                minWidth: 100,
                maxWidth: 400,
                isResizable: true,
                onRender: (report: Report) => <span className="overflow-text">
                    {report.scope?.join(", ")}
                </span>
            },
            {
                key: "status",
                fieldName: "status",
                name: "Status",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onRender: (report: Report) => <IsActive active={!!report.isActive} />
            },
        ]
    }

    const resolveReportTemplateText = (reportId: string): string => {
        const reportMetadata = props.reportsMetadata[reportId];
        const templateText = `Download ${reportMetadata!.title} Template`;
        if (reportMetadata?.version) {
            return `${templateText} v.${reportMetadata.version}`
        }
        return templateText;
    }

    const canManage = contains(props.user.permissions.common, CommonOperations.ODataManage);
    const columns = buildColumns();

    const [selectedCount, setSelectedCount] = React.useState(0);
    const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>();
    const [showPanel, setShowPanel] = React.useState<boolean>();
    const [report, setReport] = React.useState<ReportInfo>();

    const selection = React.useMemo(() => {
        const selection: Selection<Report> = new Selection<Report>({
            getKey: _ => _.id,
            onSelectionChanged: () => setSelectedCount(selection.getSelectedCount()),
        });
        return selection;
    }, []);

    React.useEffect(() => {
        props.getReports();
        props.getReportMetadata();
    }, []);

    return <ControlSpiner isLoading={!!props.isLoading} className="show-over">
        <div className="report-management">
            {showPanel && <ReportEdit
                report={report}
                onSave={props.saveReport}
                onDismiss={() => setShowPanel(false)}
            />}
            <div className="entities-list">
                <CommandBar className="header-command-bar" items={getCommands()} />
                <DetailsListWrapper
                    layoutMode={DetailsListLayoutMode.justified}
                    items={props.reports}
                    columns={columns}
                    selection={selection as Selection}
                    checkboxVisibility={canManage ? CheckboxVisibility.always : CheckboxVisibility.hidden} />
            </div>
            {showRemoveDialog && <RemoveDialog
                onClose={() => setShowRemoveDialog(false)}
                onComplete={() => {
                    props.removeReport(selection.getSelection().filter(_ => _.isCustom).map(_ => _.id));
                    setShowRemoveDialog(false);
                }}
                dialogContentProps={{
                    title: `Delete Report${selectedCount > 1 ? "s" : ""}`,
                    subText: selectedCount === 1
                        ? `Are you sure you want to delete Report "${selection.getSelection()[0].title}"?`
                        : `Are you sure you want to delete ${selectedCount} Reports?`
                }}
                confirmButtonProps={{ text: "Delete" }} >
                {selection.getSelection().filter(_ => !_.isCustom).length > 0 && <MessageBar messageBarType={MessageBarType.warning}>
                    Please note that PPM Express standard Report Packs will not be deleted.
                </MessageBar>}
            </RemoveDialog>
            }
        </div>
    </ControlSpiner>;
}

function mapStateToProps(state: ApplicationState, _ownProp: OwnProps) {
    return {
        user: state.user,
        reporting: state.tenant.reporting,
        reports: state.reports.reports,
        reportsMetadata: state.reports.metadata,
        isLoading: state.reports.isLoading || state.reports.isLoadingMetadata
    };
}

export default connect(mapStateToProps, actionCreators)(ReportsList);

