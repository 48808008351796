import React from "react";
import { IPanelProps, Panel, PanelType } from "office-ui-fabric-react";
import './AiPanel.css';
import ExpandablePanel from "../../ExpandablePanel";


const AiPanel = (props: IPanelProps & { isExpandable?: boolean }) => {
    const panelProps: IPanelProps = {
        ...props,
        isOpen: true,
        className: `${props.className} ai-panel`,
        onOuterClick: () => {},
        type: PanelType.custom,
        customWidth: props.customWidth ?? '600px'
    };

    return props.isExpandable
        ? <ExpandablePanel {...panelProps} />
        : <Panel {...panelProps} />
}

export default AiPanel;