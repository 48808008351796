import { Action, Reducer } from 'redux';
import { AppThunkAction } from "./index";
import { post, get } from "../fetch-interceptor";
import * as analytics from '../analytics';
import { FinancialsState, ActionTypes as FinancialsActionTypes, FinancialsActions } from "./FinancialsStore";
import { TimeState, ActionTypes as TimeActionTypes, TimeActions } from "./TimeStore";
import { defaultCatch, catchApiError } from "./utils";
import { RouterAction, replace } from 'react-router-redux';
import { LocationState } from 'history';
import * as Notifications from "./NotificationsStore";
import { Resource } from './ResourcesListStore';
import { IReportingInfo, ActionTypes as TenantActionTypes, TenantActions, TenantState } from './Tenant';
import { CommonOperations, IEntityPermissions, LicenseType, ResourceOperations } from './permissions';
import { AuthProvidersMap, Dictionary, EntityType } from '../entities/common';
import { Tracker } from './PreferencesTracker';

export function isAdminViewer(user: UserState): boolean {
    return user.id === null;
}

export function isInReadonlyMode(user: UserState, tenant: TenantState, entity?: { isArchived?: boolean }): boolean {
    return isAdminViewer(user) || tenant.isReadOnly || !!entity?.isArchived;
}

export interface ITenantViewState {
    id: string;
    name: string | null;
    companyName: string;
    region: string;
    url: string;
}

export enum UserStatus {
    Active = 0,
    Inactive = 1,
    PendingInvite = 2
}

export interface AIDigestState {
    aiDigestItems: string[];
    isLoading: boolean;
}

export interface PreferencesState {
    dailyEmailNotifications: boolean;
    weeklyEmailNotifications: boolean;
    entityEmailNotifications: boolean;
    expandSideBar: boolean;
    dontShowQuickStartOnStartup: boolean;
    expandSecondSidebar: Dictionary<any>;
    aiDigestSettings: AIDigestState;
}

export type PreferencesUpdate = Partial<PreferencesState>;

export interface UserPermissions {
    common: CommonOperations;
    portfolio: IEntityPermissions;
    project: IEntityPermissions;
    program: IEntityPermissions;
    objective: IEntityPermissions;
    roadmap: IEntityPermissions;
    challenge: IEntityPermissions;

    projectProfileLayoutId?: string;
    portfolioProfileLayoutId?: string;
    programProfileLayoutId?: string;
    roadmapProfileLayoutId?: string;
    challengeProfileLayoutId?: string;
    layoutIdByProjectIdMap?: Dictionary<string>;
    layoutIdByPortfoilioIdMap?: Dictionary<string>;
    layoutIdByProgramIdMap?: Dictionary<string>;
    layoutIdByRoadmapIdMap?: Dictionary<string>;
    layoutIdByChallengeIdMap?: Dictionary<string>;
}

export interface UserState {
    id: string | null;
    isSignedUp: boolean;
    signInAuthProvider: string;
    name: string;
    email: string;
    photo?: string;
    imageId?: string;
    status: UserStatus;
    license: LicenseType;
    permissions: UserPermissions;
    resource?: Resource;
    hasResource?: boolean;
    preferences: PreferencesState;

    availableTenants?: ITenantViewState[];

    isInProcessing?: boolean;
}

const INIT_STATE: UserState = {
    id: '',
    isSignedUp: false,
    signInAuthProvider: '',
    name: '',
    email: '',
    status: UserStatus.Inactive,
    license: LicenseType.None,
    permissions: {
        common: CommonOperations.None,
        portfolio: { global: ResourceOperations.None, entities: ResourceOperations.None },
        project: { global: ResourceOperations.None, entities: ResourceOperations.None },
        program: { global: ResourceOperations.None, entities: ResourceOperations.None },
        objective: { global: ResourceOperations.None, entities: ResourceOperations.None },
        roadmap: { global: ResourceOperations.None, entities: ResourceOperations.None },
        challenge: { global: ResourceOperations.None, entities: ResourceOperations.None }
    },
    resource: undefined,
    preferences: {
        dailyEmailNotifications: false,
        weeklyEmailNotifications: false,
        entityEmailNotifications: false,
        expandSideBar: true,
        dontShowQuickStartOnStartup: false,
        expandSecondSidebar: {},
        aiDigestSettings: {
            isLoading: false,
            aiDigestItems: []
        }
    }
}

export enum ActionTypes {
    UPDATE_USER = 'UPDATE_USER',
    PROCESSING_STARTED = "USER_PROCESSING_STARTED",
    UPDATE_USER_RESOURCE = 'UPDATE_USER_RESOURCE',
    UPDATED_PREFERENCES = "UPDATED_PREFERENCES",
    LOAD_DIGEST = 'LOAD_AI_DIGEST_DATA',
    LOADED_DIGEST = 'LOADED_AI_DIGEST_DATA',
    SAVE_DIGEST = 'SAVE_AI_DIGEST_DATA'
}

type UpdateUserAction = { type: ActionTypes.UPDATE_USER; user: UserState; };
type ProcessingStartedAction = { type: ActionTypes.PROCESSING_STARTED; };
type UpdateUserResourceAction = { type: ActionTypes.UPDATE_USER_RESOURCE; resource: Resource | null; };
type UpdatePreferencesAction = { type: ActionTypes.UPDATED_PREFERENCES; preferencesUpdate: PreferencesUpdate; };

type RequestDigestDataAction = { type: ActionTypes.LOAD_DIGEST }
type ReceiveDigestData = { type: ActionTypes.LOADED_DIGEST; aiDigestItems: string[]; }
type SaveDigestData = { type: ActionTypes.SAVE_DIGEST; aiDigestItems: string[]; }

type AIDigestActions = RequestDigestDataAction
    | ReceiveDigestData
    | SaveDigestData;

export type UserActions = UpdateUserAction
    | ProcessingStartedAction
    | UpdateUserResourceAction
    | UpdatePreferencesAction
    | AIDigestActions;

export const aiDisgestActionCreators = {
    getAiDigestSettings: (): AppThunkAction<AIDigestActions> => (dispatch, getState) => {
        get<string[]>('api/user/preferences/aiDigestSettings')
            .then(data => dispatch({ type: ActionTypes.LOADED_DIGEST, aiDigestItems: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: ActionTypes.LOAD_DIGEST });
    },
    saveAiDigestSettings: (digestItems: string[]): AppThunkAction<AIDigestActions> => (dispatch, getState) =>{
        post<string[]>('api/user/preferences/saveAiDigestSettings', { items: digestItems })
            .then(() => dispatch({ type: ActionTypes.SAVE_DIGEST, aiDigestItems: digestItems }))
            .catch(defaultCatch(dispatch));
    },
}

export const actionCreators = {
    signUp: (state?: LocationState): AppThunkAction<UserActions | FinancialsActions | TimeActions | RouterAction | TenantActions> => (dispatch, getState) => {
        post<{ user: UserState, financials: FinancialsState, time: TimeState, reporting: IReportingInfo }>(`api/user/signup`, {})
            .then(data => {
                const applicationState = getState();
                analytics.identifyAndGroup(data.user, applicationState.tenant);
                dispatch({ type: ActionTypes.UPDATE_USER, user: data.user });
                dispatch({ type: FinancialsActionTypes.UPDATE_FINANCIALS, financials: data.financials });
                dispatch({ type: TimeActionTypes.UPDATE_TIME, time: data.time });
                dispatch({ type: TenantActionTypes.RECEIVED_REPORTING_INFO, info: data.reporting });
                dispatch(replace("/quickstart"));
                analytics.gtag('event', 'user_signed_up', { userSignInSystem: AuthProvidersMap[data.user.signInAuthProvider].friendlyName });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.PROCESSING_STARTED });
    },
    requestLicense: (): AppThunkAction<UserActions | Notifications.KnownAction> => (dispatch, getState) => {
        post(`api/user/license/request`, {})
            .then(_ => dispatch(Notifications.actionCreators.pushNotification({ message: `Thank you for your request! Your license will be activated as soon as possible.` })))
            .catch(catchApiError(_ => {
                dispatch(Notifications.actionCreators
                    .pushNotification({ message: `Failed to send request. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
            }));

        dispatch({ type: ActionTypes.PROCESSING_STARTED });
    },
    loadResource: (): AppThunkAction<UserActions | Notifications.KnownAction> => (dispatch, getState) => {
        get<{ resource: Resource | null }>(`api/user/resource`)
            .then(_ => dispatch({ type: ActionTypes.UPDATE_USER_RESOURCE, resource: _.resource }))
            .catch(catchApiError(_ => {
                dispatch(Notifications.actionCreators
                    .pushNotification({ message: `Failed to send request. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
            }));

        dispatch({ type: ActionTypes.PROCESSING_STARTED });
    },
    updateNotificationPreferences: (preferencesUpdate: PreferencesUpdate): AppThunkAction<UserActions> => (dispatch, getState) => {
        post<PreferencesUpdate>(`api/user/preferences`, preferencesUpdate)
            .then(data => {
                dispatch({ type: ActionTypes.UPDATED_PREFERENCES, preferencesUpdate: data });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.PROCESSING_STARTED });
    },
    updateViewPreferences: (preferencesUpdate: PreferencesUpdate): AppThunkAction<UserActions> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.UPDATED_PREFERENCES, preferencesUpdate });
        Tracker.updateViewPreferences(dispatch, getState, preferencesUpdate);
    },
    updateSecondSidebarPreferences: (entityType: EntityType, expanded: boolean): AppThunkAction<UserActions> => (dispatch, getState) => {
        const preferencesUpdate: PreferencesUpdate = { expandSecondSidebar: { [entityType]: expanded } };
        post<PreferencesUpdate>(`api/user/preferences`, preferencesUpdate)
            .then(data => {
                dispatch({ type: ActionTypes.UPDATED_PREFERENCES, preferencesUpdate: data });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.PROCESSING_STARTED });
    },
    ping: (): AppThunkAction<UserActions> => (dispatch, getState) => {
        get<{ version: string }>(`api/home/ping`)
            .then(data => {
                if (getState().enviroment.version !== data.version) {
                    window.location.reload();
                }
            })
            .catch(defaultCatch(dispatch));
    },
    ...aiDisgestActionCreators
}

export const reducer: Reducer<UserState> = (state: UserState = INIT_STATE, incomingAction: Action) => {
    const action = incomingAction as UserActions;
    switch (action.type) {
        case ActionTypes.UPDATE_USER:
            return action.user;
        case ActionTypes.PROCESSING_STARTED:
            return {
                ...state,
                isInProcessing: true
            };
        case ActionTypes.UPDATE_USER_RESOURCE:
            return {
                ...state,
                isInProcessing: false,
                hasResource: !!action.resource,
                resource: action.resource ?? undefined
            };
        case ActionTypes.UPDATED_PREFERENCES:
            return {
                ...state,
                isInProcessing: false,
                preferences: {
                    ...state.preferences,
                    dailyEmailNotifications: action.preferencesUpdate.dailyEmailNotifications ?? state.preferences.dailyEmailNotifications,
                    weeklyEmailNotifications: action.preferencesUpdate.weeklyEmailNotifications ?? state.preferences.weeklyEmailNotifications,
                    entityEmailNotifications: action.preferencesUpdate.entityEmailNotifications ?? state.preferences.entityEmailNotifications,
                    expandSideBar: action.preferencesUpdate.expandSideBar ?? state.preferences.expandSideBar,
                    dontShowQuickStartOnStartup: action.preferencesUpdate.dontShowQuickStartOnStartup ?? state.preferences.dontShowQuickStartOnStartup,
                    expandSecondSidebar: action.preferencesUpdate.expandSecondSidebar
                        ? {
                            ...state.preferences.expandSecondSidebar,
                            ...action.preferencesUpdate.expandSecondSidebar
                        }
                        : state.preferences.expandSecondSidebar
                }
            };
        case ActionTypes.LOAD_DIGEST:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    aiDigestSettings: {
                        ...state.preferences.aiDigestSettings,
                        isLoading: true
                    }
                }
            };
        case ActionTypes.LOADED_DIGEST:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    aiDigestSettings: {
                        isLoading: false,
                        aiDigestItems: action.aiDigestItems
                    }
                }
            };
        case ActionTypes.SAVE_DIGEST:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    aiDigestSettings: {
                        ...state.preferences.aiDigestSettings,
                        aiDigestItems: action.aiDigestItems
                    }
                }
            };
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
};