import * as React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { SourceType } from "../../store/ExternalEpmConnectStore";
import Office365ConnectionsList from '../integration/Office365/Office365ConnectionsList';
import JiraConnectionsList from '../integration/Jira/JiraConnectionsList';
import VSTSConnectionsList from '../integration/Vsts/VSTSConnectionsList';
import SpoConnectionsList from '../integration/Spo/SpoConnectionsList';
import SystemsList from '../integration/SystemsList';
import { IConnectionInfo } from '../../entities/Metadata';
import MondayComConnectionsList from '../integration/MondayCom/MondayComConnectionsList';
import SmartsheetConnectionsList from '../integration/Smartsheet/SmartsheetConnectionsList';
import P4WConnectionsList from '../integration/P4W/P4WConnectionsList';

type Props = {
    entitiesName: string;
    width: string;
    system: SourceType;
    systems: SourceType[];
    className: string;
    onSelected: (system: SourceType, connection?: IConnectionInfo) => void;
    onDismiss: () => void;
    connectionId?: string;
}

export const SystemChoisePanel = (props: Props) => {
    const [system, setSystem] = React.useState<SourceType>(props.system);
    const selectProps = {
        onSelect: (_: IConnectionInfo) => props.onSelected(system, _)
    }
    const systems = React.useMemo(() => props.systems.map(_ => ({ type: _ })), [props.systems]);
    return <Panel
        className={`system-choice ${props.className}`}
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth={props.width}
        onRenderHeader={() => <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Import {props.entitiesName} to PPM Express</p>
            <div className='ms-Panel-secondaryText'>Choose where would you like to import from.</div>
        </div>}
        onDismiss={props.onDismiss}
        isOpen={true}>
        <SystemsList selectedSystem={system} systems={systems} onSelect={setSystem} />
        {system === SourceType.O365User && <Office365ConnectionsList {...selectProps} sourceType={SourceType.O365User} />}
        {system === SourceType.O365Planner && <Office365ConnectionsList {...selectProps} sourceType={SourceType.O365Planner} />}
        {system === SourceType.Jira && <JiraConnectionsList {...selectProps} />}
        {system === SourceType.VSTS && <VSTSConnectionsList {...selectProps} />}
        {system === SourceType.Spo && <SpoConnectionsList {...selectProps} />}
        {system === SourceType.MondayCom && <MondayComConnectionsList {...selectProps} />}
        {system === SourceType.Smartsheet && <SmartsheetConnectionsList {...selectProps} sourceType={SourceType.Smartsheet} />}
        {system === SourceType.P4W && <P4WConnectionsList {...selectProps} />}
    </Panel>;
}