import * as React from 'react';
import { IListViewFieldColumn } from "../../../../services/ViewService";
import { Dictionary, IWithInsights, IInsightsStatusData, StatusCategory} from "../../../../entities/common";
import { FormatDateTime, toDateTime } from '../../../utils/common';
import { TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import { getLabel } from '../../../../entities/Metadata';
import CategoryColorStatusView from './CategoryColorStatusView';
import * as StatusDescriptorFactory from '../../../../entities/StatusDescriptorFactory';

type ColorStatusProps = IListViewFieldColumn<{
    attributes: Dictionary<any>;
    onClick?: () => void;
} & Partial<IWithInsights>>;

const ColorStatus = (props: ColorStatusProps) => {
    const { field, entity } = props;
    
    const insights = props.entity.insights?.statuses[props.field.name]
    const statusValue = entity.attributes[field.name];
    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(field);
    const statusOption = statusDescriptor.getOptionOrDefault(statusValue, StatusCategory.NA);

    const onRenderContent = () => {
        const description = entity.attributes[buildStatusDescriptionAttributeName(field.name)];
        
        return <div className="timeline-tooltip color-status-tooltip status-card">
            <div className="header">
                <div className="title">
                    <div className="overflow-text" title={getLabel(field)}>
                        {getLabel(field)}
                    </div>
                </div>
                <CategoryColorStatusView statusOption={statusOption} trend={insights?.trend} onClick={entity.onClick} />
            </div>
            {
                description && <div className="content description-wrapper" style={{ borderLeftColor: statusOption.color }}>
                    <div className="description">
                        {description}
                    </div>
                </div>
            }
            <div className="color-status-info">
                {
                    !!insights?.manualValueDueDate && toDateTime(insights.manualValueDueDate)! > new Date() && <span className="sub">
                        {`Applies till ${FormatDateTime(insights.manualValueDueDate, true, { hour: "2-digit", minute: "2-digit" })}`}
                    </span>
                }
                {
                    !!insights?.autoCalcDate && <span className="sub">
                        {`Last verified on ${FormatDateTime(insights.autoCalcDate)}`}
                    </span>
                }
            </div>
        </div>;
    }

    if (!field) {
        return <span className="overflow-text">
            Invalid column configuration
        </span>;
    }

    if (!field.settings) {
        return <span className={"bold-font overflow-text" + (props.className ? ` ${props.className}` : "")}>{statusValue}</span>;
    }

    return (
        <div className="color-status-wrapper">
            {
                insights
                    ? <TooltipHost
                        tooltipProps={{ onRenderContent, maxWidth: "454px" }}
                        closeDelay={10}
                        delay={TooltipDelay.long}>
                        <CategoryColorStatusView statusOption={statusOption} trend={insights.trend} onClick={entity.onClick} />
                    </TooltipHost>
                    : <CategoryColorStatusView statusOption={statusOption} onClick={entity.onClick} />
            }
        </div>
    );
}

export default ColorStatus;

function buildStatusDescriptionAttributeName(name: string) {
    return `${name}Description`;
}

export const StatusInsights = (props: { insights?: IInsightsStatusData }) => {
    const { insights } = props;
    return <>
        {
            !!insights?.manualValueDueDate && toDateTime(insights.manualValueDueDate)! > new Date() && <span className="sub">
                {`Applies till ${FormatDateTime(insights.manualValueDueDate, true, { hour: "2-digit", minute: "2-digit" })}`}
            </span>
        }
        {
            !!insights?.autoCalcDate && <span className="sub">
                {`Last verified on ${FormatDateTime(insights.autoCalcDate)}`}
            </span>
        }
    </>
}
