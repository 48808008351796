import * as React from 'react';
import * as analytics from '../../analytics';
import { bindActionCreators } from 'redux';
import { Panel, PanelType, TextField, DefaultButton, MessageBar, MessageBarType, Dropdown } from 'office-ui-fabric-react';
import { actionCreators, LicensesUtilization } from "../../store/UsersListStore";
import { actionCreators as tenantActionCreators, IDefaultPermissions, getDefaultPermissions } from "../../store/Tenant";
import PermissionsEdit, { getLicences } from './PermissionsEdit';
import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { Validator } from "../../validation";
import { UserState, UserStatus } from '../../store/User';
import { LicenseType, contains, CommonOperations, IPermissions, LicenseUtils } from '../../store/permissions';
import { post } from '../../fetch-interceptor';
import { AuthProvider } from '../../entities/common';
import AuthProviderSelect from './AuthProviderSelect';

type OwnProps = {
    onDismiss: () => void;
}
type State = {
    canManage: boolean;
    email: string;
    emails: string[];
    permissions?: IPermissions;
    license: LicenseType;
    authProvider?: AuthProvider;
    validator: Validator;
}
type StateProps = {
    defaultPermissions?: IDefaultPermissions<IPermissions>;
    licensesUtilization: LicensesUtilization;
    user: UserState;
    viewersLimit: number;
    allowedAuthProviders?: AuthProvider[];
    defaultAuthProvider?: AuthProvider;
}
type ActionProps = {
    userActions: typeof actionCreators;
    tenantActions: typeof tenantActionCreators;
}
type InviteUserPanelProps = OwnProps & StateProps & ActionProps;

const SEPARATORS_REG = new RegExp('[;, ]', 'g');
const emptyPermissions: Partial<IPermissions> = {};

class InviteUserPanel extends React.Component<InviteUserPanelProps, State> {
    constructor(props: InviteUserPanelProps) {
        super(props);
        const canManage = contains(props.user.permissions.common, CommonOperations.UserManage);
        const license = canManage
            ? !!props.viewersLimit
                ? LicenseType.Viewer
                : LicenseType.Regular
            : LicenseType.None;
        this.state = {
            canManage: canManage,
            email: '',
            emails: [],
            license,
            permissions: getDefaultPermissions(license, props.defaultPermissions),
            validator: Validator.new().required().email().build(),
            authProvider: props.defaultAuthProvider
        };
    }

    public componentWillMount() {
        post<{ email: string }[]>('api/user/find', { statuses: [UserStatus.Active, UserStatus.Inactive], includeUnlicensed: true })
            .then(_ => this.setState({ validator: Validator.new().required().email().unique(_.map(__ => __.email), "email is already invited").build() }));

        if (this.state.canManage) {
            this.props.userActions.loadLicensesUtilization();
            !this.props.defaultPermissions && this.props.tenantActions.loadDefaultPermissions();
        }
    }

    componentWillReceiveProps(props: InviteUserPanelProps) {
        if (props.defaultPermissions && !this.props.defaultPermissions) {
            this.setState({ permissions: getDefaultPermissions(this.state.license, props.defaultPermissions) });
        }
        if (props.defaultAuthProvider !== this.props.defaultAuthProvider) {
            this.setState({ authProvider: props.defaultAuthProvider });
        }
    }

    private _getEmailFieldErrorMessage = () => {
        const { emails, email, validator } = this.state;

        if (emails.length === 0) {
            const trimmed = email.trim();
            return validator.getErrorMessage(SEPARATORS_REG.test(trimmed[trimmed.length - 1]) ? trimmed.slice(0, -1) : trimmed);
        }

        for (const emailItem of emails) {
            const error = validator.getErrorMessage(emailItem);
            if (error) {
                return error;
            }
        }

        return "";
    }

    public render() {
        const { canManage, license, email, permissions, authProvider } = this.state;
        const { allowedAuthProviders } = this.props;

        return <Panel
            className="user-invite"
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth="400px"
            isOpen={true}
            onRenderHeader={this._onRenderHeader}
            onDismiss={this.props.onDismiss}>
            <MessageBar messageBarType={MessageBarType.info}>Please use semicolon, comma or space as a separator to send multiple invitations at once</MessageBar>
            <TextField
                label="Email"
                placeholder="Invite by email..."
                required={true}
                value={email}
                errorMessage={this._getEmailFieldErrorMessage()}
                onChange={this._onEmailChange} />

            {canManage && allowedAuthProviders && <AuthProviderSelect
                label="Required Authentication Provider"
                authProvider={authProvider}
                onChange={_ => this.setState({ authProvider: _ })}
            />}
            {canManage && permissions && <PermissionsEdit
                license={license}
                permissions={permissions}
                onChange={this._onPermissionsChange}
                errorMessage={this._getLicenseError()}
            />}
            {canManage && !permissions && <Dropdown
                label="License"
                selectedKey={license}
                options={getLicences(this.props.viewersLimit)}
                onChange={(e, _) => {
                    const licenseType = _!.key as LicenseType;
                    this._onPermissionsChange(licenseType, emptyPermissions);
                }}
                errorMessage={this._getLicenseError()}
            />}

            <DefaultButton
                className="btn-invite"
                disabled={!this._isFormValid()}
                primary={true}
                text="Send Invitation"
                onClick={this._save}
            />
        </Panel>;
    }

    private _onRenderHeader = (): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Invite People</p>
            <div className='ms-Panel-secondaryText'>Invite people to PPM Express {this.state.canManage && "and define permission level"}</div>
        </div>;
    }

    private _onEmailChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string = "") => {
        this.setState({
            email: value,
            emails: value.split(SEPARATORS_REG).map(_ => _.trim()).filter(_ => _),
        })
    }

    private _getLicenseError = (): string | undefined => {
        const { license, emails } = this.state;
        return LicenseUtils.getLicenseError(this.props.licensesUtilization, license, emails.length);
    }

    private _onPermissionsChange = (license: LicenseType, changes: Partial<IPermissions>) => {
        const permissions = license !== this.state.license
            ? getDefaultPermissions(license, this.props.defaultPermissions)!
            : { ...this.state.permissions!, ...changes };
        this.setState({ license, permissions });
    }

    private _save = () => {
        const { user } = this.props;
        const { license, permissions, emails, authProvider } = this.state;

        this.props.userActions.invite(emails, license, LicenseUtils.cutPermissions(license, permissions!), authProvider);
        analytics.trackEvent("Invite people", user, { licenseType: license });

        this.props.onDismiss();
    }

    private _isFormValid(): boolean {
        return !this._getEmailFieldErrorMessage() && !this._getLicenseError();
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        defaultPermissions: state.tenant.defaultPermissions,
        allowedAuthProviders: state.tenant.security?.allowedAuthProviders,
        defaultAuthProvider: state.tenant.security?.defaultAuthProvider,
        licensesUtilization: state.users.licensesUtilization,
        user: state.user,
        viewersLimit: state.tenant.subscription.viewersLimit
    }
}
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(actionCreators, dispatch),
        tenantActions: bindActionCreators(tenantActionCreators, dispatch)
    }
}
export default connect(mapStateToProps, mergeActionCreators)(InviteUserPanel)