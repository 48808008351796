import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { SourceType, SourceType_ } from '../../../store/ExternalEpmConnectStore';
import Link from '../../common/Link';
import { IConnectionInfo } from '../../../entities/Metadata';

export const ImportHeader = React.memo((props: { entityName: string; connection?: IConnectionInfo, system: SourceType, articleUrl: string }) => {
    return <div className="ms-Panel-header">
        <p className="ms-Panel-headerText">Import {props.entityName} from {SourceType_.getName(props.system)}</p>
        {
            props.connection && <div className="align-center">
                <div className="connection-icon">
                    <Icon iconName="PPMXLinkProject" />
                </div>
                <div className="connection-label">Connection: </div>
                <div className="overflow-text">{props.connection.name}</div>
            </div>
        }
        <div className="sub-text">Please refer to <Link target="_blank" href={props.articleUrl}>the article</Link> for more details</div>
    </div >;
});