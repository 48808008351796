import "./ShowMoreComponent.css"
import * as React from 'react';
import { ActionButton } from "office-ui-fabric-react";
import { useResizeObject } from "../../utils/effects";

export type ShowMoreTextProps = {
    containerClassName?: string;
    containerMaxHeight?: number;
};

const ContainerMaxHeight = 100;

const ShowMoreComponent = (props: React.PropsWithChildren<ShowMoreTextProps>) => {
    const [showMoreClicked, setShowMoreClicked] = React.useState(false);
    const [containerHeight, setContainerHeight] = React.useState(0);
    const [isFocused, setIsFocus] = React.useState(false);
    const containerMaxHeight = props.containerMaxHeight ?? ContainerMaxHeight;
    const containerMaxHeightstyle = !showMoreClicked && !isFocused ? { maxHeight: containerMaxHeight } : undefined;

    const isShowMoreBtnPanelVisible = () => (!isFocused || showMoreClicked) && containerHeight > containerMaxHeight;
    const isShowMoreBtnVisible = isShowMoreBtnPanelVisible() && !showMoreClicked;
    const isShowLessBtnVisible = isShowMoreBtnPanelVisible() && showMoreClicked;

    const ref = useResizeObject<HTMLDivElement>((offsetWidth: number, offsetHeight: number) => setContainerHeight(offsetHeight))

    const _onFocus = (event: React.FocusEvent<HTMLDivElement>) => {
        setIsFocus(true);
    };

    const _onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        setTimeout(() => {
            setIsFocus(false);
        }, 200)
    };

    return <>
        <div className={`show-more-container ${props.containerClassName} ${isShowMoreBtnVisible ? 'show-more-on' : ''}`}>
            <div style={containerMaxHeightstyle}>
                <div ref={ref} onFocus={_onFocus} onBlur={_onBlur}>
                    {props.children}
                </div>
            </div>
        </div>
        <div className={`show-more-btn-panel ${isShowMoreBtnVisible ? 'show-more-on' : ''}`}>
            {isShowMoreBtnVisible && <ActionButton className="show-more-btn hyperlink-color" text="Show more"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowMoreClicked(true);
                }} />}
            {isShowLessBtnVisible && <ActionButton className="show-more-btn hyperlink-color" text="Show less"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowMoreClicked(false);
                }} />}
        </div>
    </>
}

export default ShowMoreComponent;