import * as React from "react";
import LinkBox from "../LinkBox";

type Props = {
    fixed?: boolean;
    path: string;
    text: string;
    disabled: boolean;
};

const ViewEntities = (props: Props) => <LinkBox text={props.text}
    iconName="PPMXQuickStartViewProjects"
    disabled={props.disabled}
    fixed={props.fixed}
    path={props.path} />

export default ViewEntities;