import * as React from 'react';
import { connect } from 'react-redux';
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { actionCreators } from "../../../store/integration/ImportStore";
import { actionCreators as ProjectActionCreators } from "../../../store/ProjectsListStore";
import { SystemChoisePanel } from "../SystemChoisePanel";
import ProjectsImportPanel, { ProjectsImportProps } from "./ProjectsImportPanel";
import PlannerProjectImport from './PlannerProjectImport';
import JiraProjectImport from './JiraProjectImport';
import VSTSProjectImport from './VSTSProjectImport';
import SpoProjectImport from './SpoProjectImport';
import { ApplicationState } from "../../../store/index";
import { bindActionCreators } from 'redux';
import { IConnectionInfo } from '../../../entities/Metadata';
import MondayComProjectImport from './MondayComProjectImport';
import SmartsheetProjectImport from './SmartsheetProjectImport';
import P4WProjectImport from './P4WProjectImport';
import { EXTRA_LARGE_PANEL_WIDTH } from '../../../entities/common';

type OwnProps = { onDismiss: () => void; integrations: SourceType[]; connectionId?: string; }
type StateProps = { isImporting: boolean; }
type Props = OwnProps & StateProps & {
    actions: typeof actionCreators,
    projectActions: typeof ProjectActionCreators
};

type State = { step: Steps; system: SourceType; connectionId?: string; }

export const supported = [SourceType.O365Planner, SourceType.Jira, SourceType.VSTS, SourceType.Spo, SourceType.MondayCom, SourceType.Smartsheet, SourceType.P4W];  

class ImportPanel extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            step: props.isImporting || !!props.connectionId ? Steps.Import : Steps.SystemChoise,
            system: props.integrations[0],
            connectionId: props.connectionId
        };
    }

    public render() {
        const { onDismiss, integrations } = this.props;
        const { system, connectionId, step } = this.state;
        return [
            this.state.step === Steps.SystemChoise && <SystemChoisePanel
                key="system-choise"
                entitiesName="Projects"
                width="420px"
                className="import-panel"
                system={system}
                systems={integrations}
                connectionId={connectionId}
                onSelected={this._onSelected}
                onDismiss={onDismiss} />,
            step === Steps.Import && connectionId && this._renderImport(system, connectionId)
        ];
    }
    componentWillMount() {
        this.props.actions.toggleProjectImportDialog(true);
    }
    componentWillUnmount() {
        this.props.actions.toggleProjectImportDialog(false);
        this.props.projectActions.requestProjects();
    }

    private _onSelected = (system: SourceType, connection: IConnectionInfo) => {
        this.setState({ step: Steps.Import, system: system, connectionId: connection.id });
    }

    private _renderImport(system: SourceType, connectionId: string) {
        const { onDismiss } = this.props;
        if (system === SourceType.O365Planner) {
            return <PlannerProjectImport key="planner" connectionId={connectionId} onRender={this._renderProjectImport} />;
        }

        if (system === SourceType.MondayCom) {
            return <MondayComProjectImport key="mondaycom" connectionId={connectionId} onRender={this._renderProjectImport} />;
        }

        if (system === SourceType.Smartsheet) {
            return <SmartsheetProjectImport key="smartsheet" connectionId={connectionId} onRender={this._renderProjectImport} />;
        }

        if (system === SourceType.Jira) {
            return <JiraProjectImport key="jira" connectionId={connectionId} onRender={this._renderProjectImport} />;
        }

        if (system === SourceType.VSTS) {
            return <VSTSProjectImport key="vsts" connectionId={connectionId} onRender={this._renderProjectImport} onDismiss={onDismiss} />;
        }

        if (system === SourceType.Spo) {
            return <SpoProjectImport key="spo" connectionId={connectionId} onRender={this._renderProjectImport} />;
        }

        if (system === SourceType.P4W) {
            return <P4WProjectImport key="p4w" connectionId={connectionId} onRender={this._renderProjectImport} />;
        }

        return null;
    }

    private _renderProjectImport = (props: ProjectsImportProps) => {
        const { system } = this.state;
        const width = system === SourceType.Jira
            ? "800px"
            : system === SourceType.VSTS
                ? EXTRA_LARGE_PANEL_WIDTH
                : "600px";
        return <ProjectsImportPanel {...props} system={system} onBack={this._onBack} width={width} />;
    }

    private _onBack = () => {
        if (this.props.isImporting || !!this.props.connectionId) {
            this.props.onDismiss();
        } else {
            this.setState({ step: Steps.SystemChoise });
        }
    }
}

enum Steps {
    SystemChoise,
    Import
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { isImporting: state.import.projects.isImporting };
}

function mergeActionCreators(dispatch: any) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        projectActions: bindActionCreators(ProjectActionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ImportPanel);