import { Dictionary } from "../../entities/common";

export class RankService<T> {
    private readonly RANK_INCREMENT: number = 0x1000;
    constructor(getRank: (item: T) => number, setRank: (item: T, rank: number) => void, getGroupKey: (item: T) => string) {
        this._getRank = getRank;
        this._setRank = setRank;
        this._getGroupKey = getGroupKey;
    }
    private _getRank: (item: T) => number;
    private _setRank: (item: T, rank: number) => void;
    private _getGroupKey: (item: T) => string;

    public GetRankAfter = (items: T[], entity: T) => {
        const HALVE: number = 2;
        const groupKey = this._getGroupKey(entity);
        const rank = this._getRank(entity) ?? 0;
        const nextRanks = items.filter(_ => this._getGroupKey(_) === groupKey && this._getRank(_) > rank).map(_ => this._getRank(_))
        if (!nextRanks.length) {
            return rank + this.RANK_INCREMENT;
        } else {
            return (rank + Math.min.apply(Math, nextRanks)) / HALVE;
        }
    }

    public GetNewRankInGroup = (items: T[], entity: T) => {
        const groupKey = this._getGroupKey(entity);
        const ranksInGroup = items.filter(_ => this._getGroupKey(_) === groupKey).map(_ => this._getRank(_))
        if (!!ranksInGroup.length) {
            return Math.max.apply(Math, ranksInGroup) + this.RANK_INCREMENT;
        } else {
            return this.RANK_INCREMENT;
        }
    }

    public RecalculateRanksInGroup(items: T[], entity: T): T[] {
        const groupKey = this._getGroupKey(entity);
        const itemsToChange = items
            .filter(_ => this._getGroupKey(_) === groupKey)
            .sort((a, b) => this._getRank(a) - this._getRank(b));

        const newRankMap: Dictionary<number> = {};
        let newRank = this.RANK_INCREMENT;
        for (const item of itemsToChange) {
            const oldRank = this._getRank(item);
            if (!newRankMap[oldRank]) {
                newRankMap[oldRank] = newRank;
                newRank += this.RANK_INCREMENT;
            }
            this._setRank(item, newRankMap[oldRank]);
        }
        return itemsToChange;
    }
}