import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'office-ui-fabric-react';
import { post } from '../../../../fetch-interceptor';
import { ApplicationState } from '../../../../store';
import { UserState } from '../../../../store/User';
import * as NotificationsStore from "../../../../store/NotificationsStore";
import FeedbackDialog from '../../FeedbackDialog';
import LeaveFeedbackLink from '../../LeaveFeedbackLink';
import OverlayComponent from '../../OverlayComponent';
import { Dictionary } from '../../../../entities/common';
import './LeaveFeedback.css'

type StateProps = {
    user: UserState;
};

export type OwnProps = {
    url: string;
    disabled: boolean;
    user: UserState;
    subject: string;
    data: Dictionary<any>;
}

type ActionProps = {
    notificationActions: typeof NotificationsStore.actionCreators;
}

export type Props = OwnProps & StateProps & ActionProps;

const LeaveFeedback = (props: Props) => {
    const { subject, disabled, url, user, data, notificationActions } = props;
    const [showFeedbackDialog, setShowFeedbackDialog] = React.useState<boolean>();
    const [liked, setLiked] = React.useState<boolean>();
    const [disliked, setDisliked] = React.useState<boolean>();

    const onFeedback = (message: string | undefined, isLiked?: boolean, isDisliked?: boolean) => {
        const feedbackData = {
            ...data,
            aiFeedBack: {
                feedback: {
                    userId: user.id,
                    userName: user.name,
                    userEmail: user.email,
                    comment: message,
                    isLiked: isLiked,
                    isDisliked: isDisliked
                }
            }
        };
        post(url, feedbackData)
            .then(() => {
                setShowFeedbackDialog(false);
                notificationActions.pushNotification({ message: `Thank you for providing your feedback!`, type: NotificationsStore.NotificationType.Success });
            })
            .catch(() => setShowFeedbackDialog(false));
    };

    return <>
        <OverlayComponent isOverlayed={disabled}>
            <div className='feedback-container'>
                <LeaveFeedbackLink subject={subject} />
                <div className="align-center">
                    <Icon iconName={liked ? 'LikeSolid' : 'Like'} 
                        title="Send positive feedback to PPM Express Team" 
                        onClick={(e) => {
                            e.stopPropagation();
                            setLiked(true);
                            setDisliked(false);
                            onFeedback(undefined, true);
                        }} />
                    <Icon iconName={disliked ? 'DislikeSolid' : 'Dislike'} 
                        title="Send your comments and suggestions to PPM Express Team" 
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowFeedbackDialog(true);
                        }} />
                </div>
            </div>
        </OverlayComponent>
        {showFeedbackDialog && <FeedbackDialog
            subject={subject}
            onDismiss={() => setShowFeedbackDialog(false)}
            allowEmptyComment={true}
            showfollowUp={false}
            onSave={comment => {
                setLiked(false);
                setDisliked(true);
                onFeedback(comment, false, true);
            }} />}
    </>;
};

function mapStateToProps(state: ApplicationState): StateProps {
    return { user: state.user };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        notificationActions: bindActionCreators(NotificationsStore.actionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(LeaveFeedback)