import { IHistoryRow, Dictionary, EntityType } from "../entities/common";
import { AppThunkAction } from ".";
import { get, post } from "../fetch-interceptor";
import { IEntityStore, StoreHelper, partialUpdate } from "./services/storeHelper";
import { Reducer, Action } from "redux";
import { FieldGroup, FieldType } from "../entities/Metadata";
import { KeyDateType } from "../entities/Subentities";
import { defaultCatch } from "./utils";
import { SourceType } from "./ExternalEpmConnectStore";
import { ProjectInfo } from "./ProjectsListStore";
import * as Notifications from "./NotificationsStore";
import { ActionsBuilder } from './services/metadataService';
import { PersonInfo } from "../components/common/inputs/PersonPickerInput";

export type InsightsData = {
    id: string;

    isOutdated: boolean;
    isTurnedCritical: boolean;
    isDueThisWeek: boolean;
    isDueToday: boolean;
    isDueNextWeek: boolean;
    isCompletedThisWeek: boolean;
    isLate: boolean;

    keyDates: InsightsTotals<KeyDateItem>;
    iterations: InsightsTotals<InsightsItem>;
    tasks: InsightsTotals<InsightsItem>;

    alerts: InsightsAlert[];
    changeHistory: IHistoryRow[];
}

type InsightsTotals<T> = {
    overdue: T[];
    successful: T[];
    upcoming: T[];
    dueThisWeek: T[];
    dueToday: T[];
}

export type Insights = { project: ProjectInfo } & InsightsData

export enum AlertType {
    LateKeyDate = 1,
    ProjectStatusDegradation = 2,
    IterationLate = 3,
    RisksLate = 4,
    IssuesLate = 5,
    TaskLate = 6
}

export enum ItemType {
    None = 0,
    Project = 1,
    Portfolio = 2,
    Challenge = 3,
    Idea = 4,
    KeyDate = 5,
    Risk = 6,
    Issue = 7,
    Task = 8,
    Iteration = 9
}

export namespace AlertType_ {
    export function getEntityType(alert: InsightsAlert): ItemType {
        const alertType = alert.type;
        switch (alertType) {
            case AlertType.LateKeyDate: return ItemType.KeyDate;
            case AlertType.ProjectStatusDegradation: return ItemType.Project;
            case AlertType.IterationLate: return ItemType.Iteration;
            case AlertType.RisksLate: return ItemType.Risk;
            case AlertType.IssuesLate: return ItemType.Issue;
            case AlertType.TaskLate: return ItemType.Task;
        }

        return ItemType.None;
    }
}

type LateKeyDateAlert = AlertInfo & { type: AlertType.LateKeyDate, data: AlertData & { type: KeyDateType } }
type IterationLateAlert = AlertInfo & { type: AlertType.IterationLate, data: AlertData }
type ProjectStatusDegradationAlert = AlertInfo & { type: AlertType.ProjectStatusDegradation, data: AlertData & { value: string } }
type LateRiskIssueAlert = AlertInfo & { type: AlertType.RisksLate | AlertType.IssuesLate, data: AlertData }
type LateTaskAlert = AlertInfo & { type: AlertType.TaskLate, data: AlertData }

export type InsightsAlert = LateKeyDateAlert | IterationLateAlert | ProjectStatusDegradationAlert | LateRiskIssueAlert | LateTaskAlert;

type AlertData = {
    name: string;
    url?: string;
    date: string;
    sourceUrl?: string;
    sourceType?: SourceType;
    assignedTo?: PersonInfo[];
}

type AlertInfo = {
    id: string;
    isPriority: boolean;
    createdDate: string;
}

export type InsightsItem = {
    id: string;
    url?: string;
    name: string;
    dueDate: string;
    sourceUrl?: string;
    sourceType?: SourceType;
    isAutoMode?: boolean;
    assignedTo: PersonInfo[];
}

export type KeyDateItem = { type: KeyDateType; } & InsightsItem;

export enum InsightsActivityType {
    Overdue = 1,
    Upcoming = 2,
    Successful = 3,
    DueThisWeek = 4,
    DueToday = 5
}

interface InsightsLoaded {
    type: 'INSIGHTS_LOADED';
    insights: InsightsData[];
}

interface InsightsHandlingAction {
    type: 'INSIGHTS_HANDLING';
}

//check if these should be passed to updateSections
interface InsightsSectionsUpdatingAction {
    type: 'INSIGHTS_SECTIONS_UPDATING';
}

interface InsightsSectionsUpdatedAction {
    type: 'INSIGHTS_SECTIONS_UPDATED';
}

interface AlertPrioritized {
    type: "INSIGHTS_ALERT_PRIORITIZED";
    id: string;
    alertId: string;
    isPriority: boolean;
}

type KnownAction = InsightsLoaded | InsightsHandlingAction | AlertPrioritized | InsightsSectionsUpdatingAction | InsightsSectionsUpdatedAction;

export const actionCreators = {
    load: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<InsightsData[]>("api/insights")
            .then(_ => dispatch({ type: 'INSIGHTS_LOADED', insights: _ }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'INSIGHTS_HANDLING' });
    },
    updateUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Insights),
    updateUIControlOnClient: ActionsBuilder.buildLayoutUpdateUIControlOnClient(EntityType.Insights),
    updateSections: ActionsBuilder.buildLayoutUpdateSections(EntityType.Insights),
    updateSectionsOnClient: ActionsBuilder.buildLayoutUpdateSectionsOnClient(EntityType.Insights),
    prioritizeAlert: (insightsId: string, alertId: string, isPriority: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post(`api/insights/${insightsId}/prioritizeAlert/${alertId}`, { isPriority })
            .then(_ => dispatch({ type: 'INSIGHTS_ALERT_PRIORITIZED', id: insightsId, alertId: alertId, isPriority: isPriority }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: 'INSIGHTS_HANDLING' });
    },
    requestUpdate: (data: Dictionary<string[]>): AppThunkAction<Notifications.KnownAction> => (dispatch, getState) => {
        post("api/insights/requestUpdate", data)
            .then(_ => dispatch(Notifications.actionCreators.pushNotification({ message: "An update request was sent successfully." })))
            .catch(_ => dispatch(Notifications.actionCreators.pushNotification({ message: "Sorry, an update request was not sent. Please try again later." })));
    }
}

export interface InsightsState extends IEntityStore<InsightsData> {
    isHandling: boolean;
    isUpdatingSections: boolean;
}

const unloadedState: InsightsState = {
    byId: {},
    allIds: [],
    isHandling: false,
    isUpdatingSections: false
};

export const reducer: Reducer<InsightsState> = (state: InsightsState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "INSIGHTS_HANDLING": return {
            ...state,
            isHandling: true,
            isUpdatingSections: false
        }
        case "INSIGHTS_LOADED": return {
            ...StoreHelper.create(action.insights),
            isHandling: false,
            isUpdatingSections: false
        }
        case 'INSIGHTS_ALERT_PRIORITIZED': return {
            ...StoreHelper.applyHandler(state, action.id, (_: InsightsData) => partialUpdate(_, {
                alerts: _.alerts.map(a => a.id === action.alertId ? { ...a, isPriority: action.isPriority } : a)
            })),
            isHandling: false,
        }
        case "INSIGHTS_SECTIONS_UPDATING": return {
            ...state,
            isUpdatingSections: true
        }
        case "INSIGHTS_SECTIONS_UPDATED": return {
            ...state,
            isUpdatingSections: false
        }
        default: const exhaustiveCheck: never = action;
    }

    return state;
}

export const Fields = {
    DueDate: {
        id: "ff3ca554-6840-46d1-9323-82a40a0c58c1",
        label: "Due Date",
        name: "DueDate",
        type: FieldType.Date,
        settings: { views: { list: { minWidth: 120, maxWidth: 120 } } },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true,
        group: FieldGroup.Details,
    },
    Manager: {
        id: "8f209866-16fb-4823-b2fb-379783f3caab",
        label: "Manager",
        name: "Manager",
        type: FieldType.User,
        settings: { multichoice: true, views: { list: { maxWidth: 300, minWidth: 220 } } },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true,
        group: FieldGroup.Details,
    },
    AssignedTo: {
        id: "9ce8fb95-2845-44e6-827d-af3eaebbc192",
        label: "Assigned to",
        name: "AssignedTo",
        type: FieldType.User,
        settings: { multichoice: true, views: { list: { maxWidth: 300, minWidth: 220 } } },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true,
        group: FieldGroup.Details,
    },
    ProjectName: {
        id: "b5824b1d-1aba-441d-90e2-fc6bfb614787",
        label: "Project Name",
        name: "ProjectName",
        type: FieldType.Text,
        settings: {
            views: {
                list: {
                    componentPath: "insights/ProjectName",
                    iconName: "PPMXProject",
                    minWidth: 430,
                    maxWidth: 430
                }
            }
        },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true,
        group: FieldGroup.Details,
    },
    Details: {
        id: "040a0d2e-6d52-4fa5-917d-c25ae0873653",
        label: "Details",
        name: "Details",
        type: FieldType.Text,
        settings: {
            views: { list: { minWidth: 300, maxWidth: 1200 } },
            multiline: true,
        },
        isNative: true,
        isCustom: false,
        isReadonly: true,
        isSystem: true,
        group: FieldGroup.Details,
    },
    LateKeyDates: {
        id: "7e0690ce-9492-4560-b547-ccae2fd05da7",
        label: "Late Key Dates",
        name: "LateKeyDates",
        type: FieldType.Integer,
        isNative: true,
        isCustom: false,
        isReadonly: true,
        settings: {
            views: {
                list: {
                    componentPath: "insights/Late",
                    minWidth: 100,
                    maxWidth: 100,
                    itemIconName: "PPMXLateKeyDate"
                }
            }
        },
        isSystem: true,
        group: FieldGroup.Details,
    },
    LateTasks: {
        id: "9e8ff6bf-de5c-4c38-bb78-bb78a722a606",
        label: "Late Tasks",
        name: "LateTasks",
        type: FieldType.Integer,
        isNative: true,
        isCustom: false,
        isReadonly: true,
        settings: {
            views: {
                list: {
                    componentPath: "insights/Late",
                    minWidth: 100,
                    maxWidth: 100,
                    itemIconName: "PPMXLateTask"
                }
            }
        },
        isSystem: true,
        group: FieldGroup.Details,
    }
}

export const GetRequestUpdateCommand = (withAssignee?: boolean) => {
    return {
        key: "key",
            name: "Request Update",
                iconProps: { iconName: "PPMXRequestUpdate" },
        title: `An update request for the selected items will be sent by email to ${withAssignee ? "assignee and " : ""}project manager`
    }
};
