import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Resource, ResourceTypeConfigMap, ResourceType } from "../../../../../store/ResourcesListStore";

export default class Type extends React.Component<IListViewFieldColumn<Resource>> {
    public render() {
        return <ResourceTypeViewView type={this.props.entity.attributes.ResourceType} />;
    }
}

export class ResourceTypeViewView extends React.Component<{ type?: ResourceType }> {
    render() {
        const config = this.props.type != undefined ? ResourceTypeConfigMap[this.props.type] : undefined;
        return (
            <div className="resource-type">
                <div className={`align-center status ${config ? config.cssClassName : ''}`}>
                    {config ? config.title : this.props.type}
                </div>
            </div>
        );
    }
}