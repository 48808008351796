import './Baseline.css';
import * as React from 'react';
import { FormatDateTime } from '../../utils/common';
import { MaybeDate } from '../../../entities/common';

type OwnProps = {
    children: React.ReactNode;
    date?: MaybeDate;
}
type Props = OwnProps;

const Baseline = (props: Props) => {
    const { children, date } = props;
    return <div className="entity-baseline">
        <div className="item header align-center">
            <div className="label">Name</div>
            <div className="value">Baseline</div>
            <div className="value">Current</div>
            <div className="value">Variance</div>
        </div>
        {children}
        <div className="item summary align-center">
            <div className="label">Baseline Set Date</div>
            <div className="value" title={FormatDateTime(date, true) }>{FormatDateTime(date) ?? '-'}</div>
        </div>
    </div>;
}

export default Baseline;
