import * as React from 'react';
import { ResourceOperations, contains, LicenseType, IResourcePermissions } from "../../store/permissions";
import EntityPickerInput from "../common/inputs/EntityPickerInput";
import { Checkbox } from 'office-ui-fabric-react';
import { LayoutsState } from '../../store/layouts';
import { Layout } from '../../entities/Metadata';
import Collapsible from '../common/Collapsible';
import { EntityViewSelector } from '../common/EntityViewSelector';

interface Props {
    license: LicenseType;
    readOnly?: boolean;
    searchUrl: string;
    title: string;
    icon: string;
    expanded?: boolean;
    perms: IResourcePermissions;
    layouts?: LayoutsState;
    extraLayoutOption?: { key: string, text: string; title: string };
    onChange: (changes: IResourcePermissions) => void;
    hideView?: boolean;
    hideCollaborate?: boolean;
    hideGranularEntityPermissions?: boolean;
    disabled?: (operation: ResourceOperations) => boolean | undefined;
    indeterminate?: (operation: ResourceOperations) => boolean | undefined;
}

export const ResourcePermsEdit = (props: Props) => {
    const { icon, title, expanded, readOnly, license, perms, layouts, extraLayoutOption,
        hideView, hideCollaborate, hideGranularEntityPermissions, disabled, indeterminate } = props;
    const [collapse, setCollapse] = React.useState<boolean | undefined>(false);
    React.useEffect(() => setCollapse(collapse === false ? undefined : false), [license]);

    const global = perms.global;
    const entities = perms.entities;

    const globalOperations = getResourcePermissionsChecked(perms, true);

    return <Collapsible iconName={icon} title={title} expanded={expanded ?? collapse}>
        {layouts && <EntityViewSelector
            readOnly={readOnly}
            layoutsState={layouts}
            onChange={(layout?: Layout) => props.onChange({ ...perms, layoutId: layout?.id })}
            selectedKey={perms.layoutId}
            entityLayout={extraLayoutOption} />}
        {
            license == LicenseType.Regular && <Checkbox
                disabled={readOnly || disabled?.(ResourceOperations.Create)}
                label="Create"
                indeterminate={indeterminate?.(ResourceOperations.Create)}
                checked={globalOperations[ResourceOperations.Create]}
                onChange={(ev, c) => _onCheckChange(!!c, ResourceOperations.Create)} />
        }

        {!hideView && <>
            <Checkbox
                disabled={readOnly || disabled?.(ResourceOperations.Read) || globalOperations[ResourceOperations.Collaborate]}
                label="View All"
                indeterminate={indeterminate?.(ResourceOperations.Read)}
                checked={globalOperations[ResourceOperations.Read]}
                onChange={(ev, c) => _onCheckChange(!!c, ResourceOperations.Read)} />
            {!hideGranularEntityPermissions && !globalOperations[ResourceOperations.Read] && <EntityPickerInput
                readOnly={readOnly}
                searchUrl={props.searchUrl}
                value={props.perms.entities.read}
                onEditComplete={items => props.onChange({ global, entities: { ...entities, read: items || [] } })} />}
        </>}
        {!hideCollaborate && <>
            <Checkbox
                disabled={readOnly || disabled?.(ResourceOperations.Collaborate) || globalOperations[ResourceOperations.Update]}
                label="Collaborate on All"
                indeterminate={indeterminate?.(ResourceOperations.Collaborate)}
                checked={globalOperations[ResourceOperations.Collaborate]}
                onChange={(ev, c) => _onCheckChange(!!c, ResourceOperations.Collaborate,
                    hideView ? ResourceOperations.Read : undefined)} />
            {!hideGranularEntityPermissions && !globalOperations[ResourceOperations.Collaborate] && <EntityPickerInput
                readOnly={readOnly}
                searchUrl={props.searchUrl}
                value={props.perms.entities.collaborate}
                onEditComplete={items => props.onChange({ global, entities: { ...entities, collaborate: items || [] } })} />}
        </>}
        {
            license == LicenseType.Regular && <div>
                <Checkbox
                    disabled={readOnly || disabled?.(ResourceOperations.Update)}
                    label="Edit All"
                    indeterminate={indeterminate?.(ResourceOperations.Update)}
                    checked={globalOperations[ResourceOperations.Update]}
                    onChange={(ev, c) => _onCheckChange(!!c, ResourceOperations.Update,
                        hideCollaborate ? (hideView ? ResourceOperations.Collaborate | ResourceOperations.Read : ResourceOperations.Collaborate) : undefined)} />
                {!hideGranularEntityPermissions && !globalOperations[ResourceOperations.Update] && <EntityPickerInput
                    readOnly={readOnly}
                    searchUrl={props.searchUrl}
                    value={props.perms.entities.update}
                    onEditComplete={items => props.onChange({ global, entities: { ...entities, update: items || [] } })} />}
            </div>}
    </Collapsible>;

    function _onCheckChange(checked: boolean, operation: ResourceOperations, extra: ResourceOperations = ResourceOperations.None) {
        let newGlobal = global;
        if (checked || !contains(global, operation)) {
            newGlobal |= (operation | extra);
        }
        else {
            newGlobal &= ~(operation | extra);
        }
        props.onChange({ ...props.perms, global: newGlobal });
    }
}

export function getResourcePermissionsChecked(perms: IResourcePermissions, cascade?: boolean) {
    const global = perms.global;
    const hasCreate = contains(global, ResourceOperations.Create);
    const hasGlobalUpdate = contains(global, ResourceOperations.Update);
    const hasGlobalCollaborate = contains(global, ResourceOperations.Collaborate) || cascade && hasGlobalUpdate;
    const hasGlobalRead = contains(global, ResourceOperations.Read) || cascade && hasGlobalCollaborate;

    return {
        [ResourceOperations.Create]: hasCreate,
        [ResourceOperations.Update]: hasGlobalUpdate,
        [ResourceOperations.Collaborate]: hasGlobalCollaborate,
        [ResourceOperations.Read]: hasGlobalRead
    };
}
