import * as React from 'react';
import { IContextualMenuItem, Stack, IconButton, IButtonProps } from "office-ui-fabric-react";

type Props = {
    x?: number;
    y?: number;
    commands?: IContextualMenuItem[];
    parentWidth?: number | string;
    timelineWidth?: number;
    className?: string;
};

export const TimelineElementCommandsPanel = (props: Props) => {
    const { timelineWidth } = props;

    if (!props.commands?.length) {
        return null;
    }

    const panelWidth = calcTimelineElementCommandsPanelWidth(props.commands.length);
    const left = calculateLeft();

    return <Stack horizontal className={`item-menu ${props.className}`} style={{ width: panelWidth, left, top: props.y }} >
        {props.commands.map((_, index) => [
            <IconButton key={`btn${index}`}
                style={{
                    height: 24,
                    width: getButtonWidth(props.commands!.length),
                    borderTopLeftRadius: index === 0 ? 2 : 0,
                    borderBottomLeftRadius: index === 0 ? 2 : 0,
                    borderTopRightRadius: index === props.commands!.length - 1? 2 : 0,
                    borderBottomRightRadius: index === props.commands!.length - 1 ? 2 : 0
                }}
                {..._ as IButtonProps}
                onClick={(ev) => { ev.stopPropagation(); _.onClick?.(); }}
            />,
            index < props.commands!.length - 1 ? <div key={`sep${index}`} className="separator" /> : undefined
        ])}
    </Stack>;

    function calculateLeft(): number {
        const parentWidth = +(props.parentWidth ?? 0);
        const x = props.x ?? 0;

        if (timelineWidth && (x + panelWidth) > timelineWidth) {
            return timelineWidth - panelWidth;
        }
        if (parentWidth && parentWidth > panelWidth) {
            return x + (parentWidth - panelWidth);
        }
        return x;
    }
}

const multipleButtonWidth = 32;
const singleButtonWidth = 24;
const getButtonWidth = (buttonsCount: number): number => buttonsCount === 1 ? singleButtonWidth : multipleButtonWidth;
export const calcTimelineElementCommandsPanelWidth = (buttonsCount: number): number => getButtonWidth(buttonsCount) * buttonsCount + (buttonsCount - 1);
