import * as React from 'react';
import * as Metadata from "../../../entities/Metadata";
import { ungroupedGroup } from '../inputs/GroupDropdown';

export const GroupFormatter = (props: { value?: Metadata.IGroupInfo, markDefault?: boolean }) =>
    <UniversalGroupFormatter
        value={props.value || { id: ungroupedGroup.key, name: ungroupedGroup.name, color: ungroupedGroup.color }}
        markDefault={props.markDefault} />

export const GroupFormatterWithoutUngrouped = (props: { value?: Metadata.IGroupInfo, markDefault?: boolean }) =>
    <UniversalGroupFormatter {...props} />

const UniversalGroupFormatter = (props: { value?: Metadata.IGroupInfo, markDefault?: boolean }) =>
    props.value
        ? <div className="group align-center">
            {props.value.color && <div className="group-color" style={{ backgroundColor: props.value.color }} />}
            <div className="group-name overflow-text" title={props.value.name}>
                {props.value.name}{props.markDefault && ' (default)'}
            </div>
        </div>
        : null;