import * as React from 'react';
import { connect } from 'react-redux';
import ConnectControl from '../../ConnectControl';
import { ApplicationState } from "../../../../store/index";
import { ISourceInfo } from "../../../../entities/common";
import { GroupInfo, actionCreators, IPlannerConnectionState, ConnectionType } from "../../../../store/integration/Office365Store";
import Office365ConnectionSelect from '../Office365ConnectionSelect';
import { Pivot, PivotLinkFormat, PivotLinkSize, PivotItem, TextField, PrimaryButton, SpinnerSize, Spinner, Checkbox, MessageBarType, MessageBar } from "office-ui-fabric-react";
import { O365GroupLinkInfo } from "../../../../store/ProjectsListStore";
import { ILinkDto, IListStore, validateConnection, loadDataAfterConnectionValidation } from '../../../../store/integration/common';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import { Office365Utils } from '../Office365Utils';
import { InfoMessages } from '../../../utils/common';

export type O365ConnectActions = {
    deleteLink: (connectionId: string) => void;
    linkToO365Group: (settings: ILinkDto<O365GroupLinkInfo>) => void;
    showLinkManagerOption?: boolean;
    showLinkEngagementsOption?: boolean;
}

export type O365GroupContext = {
    connectionId: string;
    groupId?: string;
    groupName?: string;
}

type OwnProps = {
    readonly: boolean;
    allowNewItems: boolean;
    actions: O365ConnectActions;
    dismissPanel: () => void;
    sourceInfo?: ISourceInfo;
    context: O365GroupContext;
}
type StateProps = {
    groups: IListStore<GroupInfo>;
    connections: IPlannerConnectionState;
}
type Props = OwnProps & StateProps & typeof actionCreators;

type State = {
    addProjectOwnerToGroup: boolean;
    addEngagementsAsMembers: boolean;
    selectedConnectionId?: string;
}

class O365GroupConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            addProjectOwnerToGroup: props.sourceInfo && props.sourceInfo.sourceData.addProjectOwnerToGroup,
            addEngagementsAsMembers: props.sourceInfo && props.sourceInfo.sourceData.addEngagementsAsMembers
        }
    }

    componentWillMount() {
        this.props.context && this.props.loadMyGroups(this.props.context.connectionId);
    }

    componentWillReceiveProps(nextProps: Props) {
        loadDataAfterConnectionValidation(
            this.props.connections, 
            nextProps.connections, 
            this.state.selectedConnectionId,
            (connectionId) => this._loadloadMyGroups(connectionId),
            (connections, connectionId) => this._validateConnection(connections, connectionId)
        );
    }

    private _loadloadMyGroups(connectionId: string) {
        const connectionType = Office365Utils.GetConnectionType(this.props.connections, connectionId);
        if (connectionType === ConnectionType.Full) {
            this.props.loadMyGroups(connectionId);
        }
    }

    public render() {
        const { sourceInfo, context, readonly, allowNewItems } = this.props;
        if (context) {
            const title = this._getGroupTitle(context);
            return <div>
                <div className="with-top-margin">
                    <Office365ConnectionSelect
                        sourceType={SourceType.O365Group} 
                        connectionId={context.connectionId}
                        disabled={readonly}
                        onChange={() => { }}
                        validateConnection={(connections, connectionId) => this._validateConnection(connections, connectionId)}
                    />
                </div>
                <div className="with-top-margin">
                    <Pivot
                        linkFormat={PivotLinkFormat.links}
                        linkSize={PivotLinkSize.normal}
                        initialSelectedIndex={0}>
                        <PivotItem headerText="Select Group">
                            <TextField value={title} disabled={readonly} />
                            {this.props.groups.isLoading && !title && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                            {this._renderGroupConnectOptions(readonly, sourceInfo)}
                            <PrimaryButton
                                text={`Link group`}
                                className="with-top-margin"
                                disabled={readonly}
                                onClick={() => this._linkToO365PlanGroup(context)} />
                        </PivotItem>
                    </Pivot>
                </div>
            </div>;
        }

        return <ConnectControl<GroupInfo>
            itemTypeLabel="group"
            readonly={readonly}
            isConnectionValid={(connectionId) => this._validateConnection(this.props.connections, connectionId)}
            allowNewItems={allowNewItems}
            items={this.props.groups}
            actions={{
                createItemAndLink: this._createO365GroupAndLink,
                linkToItem: this._linkToO365Group,
                deleteLink: this.props.actions.deleteLink
            }}
            dismissPanel={this.props.dismissPanel}
            sourceInfo={sourceInfo}
            itemId={sourceInfo && sourceInfo.sourceData.groupId}
            itemName={sourceInfo && sourceInfo.sourceData.groupName}
            renderConnectControl={(connectionId, disabled, onChange) => 
                <Office365ConnectionSelect 
                    sourceType={SourceType.O365Group}
                    connectionId={connectionId}
                    disabled={disabled}
                    onChange={_ => {
                        onChange(_);
                        this.setState({ selectedConnectionId: _ });
                    }}
                    validateConnection={(connections, cId) => this._validateConnection(connections, cId)}
                />}
            renderExistingConnectedItemOptions={(selectedConnectionId?: string) => this._renderGroupConnectOptions(readonly, sourceInfo, selectedConnectionId)}
            renderNewConnectedItemOptions={(selectedConnectionId?: string) => this._renderGroupConnectOptions(readonly, sourceInfo, selectedConnectionId)}
        />;
    }

    private _validateConnection(connections: IPlannerConnectionState, connectionId: string | undefined): { isValidating?: boolean; validationError?: string | React.ReactNode | null; } | undefined {
        const connectionType = Office365Utils.GetConnectionType(this.props.connections, connectionId);
        if (connectionType != null && [ConnectionType.AzureADSync, ConnectionType.RestrictedPlannerSync ].includes(connectionType)) {
            return {
                isValidating: undefined,
                validationError:
                    <MessageBar messageBarType={MessageBarType.warning}>
                        {InfoMessages.GroupsBasicConnectionPermissions}
                    </MessageBar>
            };
        }
        return validateConnection(connections, connectionId);
    }

    private _renderGroupConnectOptions(readonly: boolean, sourceInfo?: ISourceInfo, selectedConnectionId?: string) {
        const isDisabled = readonly || !!sourceInfo;
        return [
            this.props.actions.showLinkManagerOption !== false && <Checkbox
                key="pm"
                styles={{ root: { marginTop: 6 } }}
                checked={this.state.addProjectOwnerToGroup}
                disabled={isDisabled}
                onChange={(e: any, c?: boolean) => this.setState({ addProjectOwnerToGroup: !!c })}
                label="Add Manager as an owner to O365 group" />,
            this.props.actions.showLinkEngagementsOption !== false && <Checkbox
                key="engagements"
                styles={{ root: { marginTop: 6 } }}
                checked={this.state.addEngagementsAsMembers}
                disabled={isDisabled}
                onChange={(e: any, c?: boolean) => this.setState({ addEngagementsAsMembers: !!c })}
                title="Resources from Resource plan section will be added as members to the Office 365 group"
                label="Add Resources as members to O365 group" />
        ];
    }

    private _getGroupTitle(context: O365GroupContext) {
        if (context.groupId) {
            const group = this.props.groups.entities?.find(_ => _.id === context.groupId);
            return group && group.title;
        }

        return context.groupName;
    }

    private _linkToO365Group = (linkData: ILinkDto<GroupInfo>) => {
        const settings: ILinkDto<O365GroupLinkInfo> = {
            connectionId: linkData.connectionId,
            data: {
                ...this.state,
                groupId: linkData.data.id,
                groupName: linkData.data.title
            }
        };

        this.props.actions.linkToO365Group(settings);
    }

    private _createO365GroupAndLink = (connectionId: string, groupName: string) => {
        const settings: ILinkDto<O365GroupLinkInfo> = {
            connectionId,
            data: {
                ...this.state,
                groupName: groupName
            }
        };

        this.props.actions.linkToO365Group(settings);
    }

    private _linkToO365PlanGroup(context: O365GroupContext) {
        const { actions, dismissPanel } = this.props;
        const settings: ILinkDto<O365GroupLinkInfo> = {
            connectionId: context.connectionId,
            data: {
                ...this.state,
                groupId: '00000000-0000-0000-0000-000000000000',
                groupName: this._getGroupTitle(context)
            }
        };
        actions.linkToO365Group(settings);
        dismissPanel();
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps) {
    return { 
        groups: state.office365.myGroups,
        connections: state.office365.connections
    };
}

export default connect(mapStateToProps, actionCreators)(O365GroupConnectControl);