import * as React from "react";
import "./CommandBarWithDnd.css";
import { CommandBar, CommandBarButton, IButtonProps, ICommandBarItemProps, Separator, VerticalDivider } from 'office-ui-fabric-react';
import { arraysEqual, reorder } from "../../utils/common";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export type ICommandBarWithDndItem = ICommandBarItemProps & {
    dndDisabled?: boolean;
    isSelected?: boolean;
}

export type CommandBarWithDndProps = {
    dndDisabled: boolean;
    fixedItems: ICommandBarItemProps[];
    items: ICommandBarWithDndItem[];
    overflowItems?: ICommandBarItemProps[];
    onReorder: (key: string, targetIndex: number) => void;
}

const overflowButtonProps: IButtonProps = {
    role: 'menuitem',
    text: 'More',
    styles: { menuIcon: { fontSize: '12px' } },
    menuIconProps: { iconName: 'ChevronDown' },
    menuProps: {
        calloutProps: {
            className: "header-callout"
        },
        items: []
    }
};

export const CommandBarWithDnd = (props: CommandBarWithDndProps) => {
    const { dndDisabled, fixedItems, items, overflowItems, onReorder } = props;

    const [renderedTabs, setRenderedTabs] = React.useState<ICommandBarWithDndItem[]>();

    const [tabsToDisplay, setTabsToDisplay] = React.useState<ICommandBarWithDndItem[]>(items);
    const [otherTabs, setOtherTabs] = React.useState<ICommandBarWithDndItem[]>([]);
    const updateRenderedTabs = React.useCallback((newRenderedTabs) => {
        if (!arraysEqual(renderedTabs, newRenderedTabs)) {
            setRenderedTabs(newRenderedTabs);
            setTabsToDisplay(items.filter(_ => newRenderedTabs.indexOf(_) > -1))
            setOtherTabs(items.filter(_ => newRenderedTabs.indexOf(_) === -1))
        }
    }, [renderedTabs, items]);

    const tabsToRender = React.useMemo(() => [
        ...fixedItems,
        ...items.filter(_ => _.isSelected),
        ...items.filter(_ => !_.isSelected),
    ], [fixedItems, items]);

    const prerenderComponent = React.useMemo(() => <CommandBar
        items={tabsToRender}
        overflowItems={overflowItems}
        overflowButtonProps={overflowButtonProps}
        dataDidRender={_r => updateRenderedTabs(_r.primaryItems)}
    />, [tabsToRender, overflowItems, updateRenderedTabs]);

    const onDragEnd = React.useCallback((result: any) => {
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const targetIndex = result.destination.index;

        const tab = tabsToDisplay[sourceIndex];
        tab.isPinned = true;
        props.onReorder(tab.key, targetIndex)

        const reorderedTabs = reorder(
            tabsToDisplay,
            sourceIndex,
            targetIndex
        );
        setTabsToDisplay(reorderedTabs);
    }, [onReorder, tabsToDisplay]);

    const dndComponent = React.useMemo(() => {
        const allOverflowItems = [...otherTabs, ...(overflowItems ?? [])];
        const pinnedTabs = tabsToDisplay.filter(_ => _.isPinned);
        const notPinnedTabs = tabsToDisplay.filter(_ => !_.isPinned);
        const pinnedTabsCount = pinnedTabs.length;
        return <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-views" direction="horizontal" isDropDisabled={dndDisabled}>
                {(provided) => (
                    <div className="tabs-list" ref={provided.innerRef} {...provided.droppableProps}>
                        {fixedItems?.map(_ => <div key={_.key}>
                            {_.onRender!(_, () => undefined)}
                        </div>)}
                        {pinnedTabs?.map((item: ICommandBarWithDndItem, index) => <Draggable
                            key={item.key}
                            index={index}
                            isDragDisabled={dndDisabled || item.dndDisabled}
                            draggableId={`${item.key}`}
                        >
                            {(itemProvided, snapshot) => <div ref={itemProvided.innerRef} {...itemProvided.draggableProps}>
                                {item.onRender?.({ ...item, dragHandleProps: itemProvided.dragHandleProps, isDragging: snapshot.isDragging }, () => undefined)}
                            </div>}
                        </Draggable>)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {!!allOverflowItems.length && <Droppable droppableId="undroppable-views" direction="horizontal" isDropDisabled={true}>
                {(provided) => (
                    <div className="tabs-list" ref={provided.innerRef} {...provided.droppableProps}>
                        <Separator vertical className="divider" />
                        {notPinnedTabs.map((item: ICommandBarWithDndItem, index) => <Draggable
                            key={item.key}
                            index={index + pinnedTabsCount}
                            isDragDisabled={dndDisabled || item.dndDisabled}
                            draggableId={`${item.key}`}
                        >
                            {(itemProvided) => <div ref={itemProvided.innerRef} {...itemProvided.draggableProps}>
                                {item.onRender?.({ ...item, dragHandleProps: itemProvided.dragHandleProps }, () => undefined)}
                            </div>}
                        </Draggable>)}
                        {provided.placeholder}
                        {!!allOverflowItems.length && <CommandBarButton {...overflowButtonProps} menuProps={{ ...overflowButtonProps.menuProps, items: allOverflowItems }} />}
                    </div>
                )}
            </Droppable>}
        </DragDropContext>;
    }, [onDragEnd, dndDisabled, tabsToDisplay, fixedItems, otherTabs, overflowItems]);

    return <div className="command-bar-with-dnd-control">
        <div className="hidden-prerender-component">
            {prerenderComponent}
        </div>
        <div className="list-wrapper">
            {dndComponent}
        </div>
    </div>
}