import * as React from 'react';
import { ISubentity } from '../../../entities/Subentities';
import { IHeader } from "../ItemCreation";

export type SecondaryMessage<TEntity> = ((entity: TEntity) => string | JSX.Element) | string | JSX.Element;

export type Props<TEntity> = {
    entity: TEntity;
    readOnly: boolean;
    header?: Partial<IHeader>;
    subentityTypeLabel: string;
    secondaryMessage?: SecondaryMessage<TEntity>
}

const HeaderSecondaryText = React.memo(<TEntity extends ISubentity>({ entity, secondaryMessage, readOnly, header, subentityTypeLabel }: Props<TEntity>) => {
    const message = secondaryMessage instanceof Function ? secondaryMessage(entity) : secondaryMessage;
    return <>
        <div>
            {header?.secondaryText
                ?? (readOnly
                    ? `Check fields below to view the ${subentityTypeLabel}`
                    : `Fill in the fields below to edit the ${subentityTypeLabel}`)}
        </div>
        {message && <div className="message-bar">{message}</div>}
    </>;
});

export default HeaderSecondaryText;