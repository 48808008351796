import * as React from 'react';
import { TextField, TooltipHost, Icon, TooltipDelay, DirectionalHint } from 'office-ui-fabric-react';
import { Validator } from "../../validation";
import { MinMax } from '../../store/Tenant';
import { debounce } from '../utils/common';
import { FormatType } from '../../entities/Metadata';

type Props = {
    format: FormatType;
    title: string;
    tooltip?: string;
    minLabel?: string;
    maxLabel?: string;
    range?: MinMax<number>;
    value: MinMax<number | null>,
    onChange?: (tuple: MinMax<number | null>) => void
}
type ThresholdsRangeState = MinMax<string> & { errors: MinMax<string[]> }

const pctRange: MinMax<number> = { min: 0, max: 100 };

type Errors = { min: string[], max: string[] }

export class ThresholdsRange extends React.Component<Props, ThresholdsRangeState>{
    private validator: Validator;
    private numberValidator: Validator;
    private debouncedOnChange = debounce<MinMax<number>>(500);

    constructor(props: Props) {
        super(props);

        this.state = { min: "", max: "", errors: { min: [], max: [] } };
        this.numberValidator = Validator.new().int32().build();
        const range = props.range || pctRange;
        this.validator = Validator.new().int32().min(range.min).max(range.max).build();
    }

    componentWillMount() {
        this.setState(this._buildState(this.props));
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.value.min != nextProps.value.min || this.props.value.max != nextProps.value.max) {
            this.setState(this._buildState(nextProps));
        }
    }

    public render() {
        const { format, title, tooltip, minLabel, maxLabel, onChange } = this.props;
        const { min, max, errors } = this.state;
        const suffix = { [FormatType.Percent]: '%', [FormatType.Duration]: 'hrs', [FormatType.Days]: 'd', [FormatType.Cost]: '$' }[format];

        return (
            <div className={"card status-calculation-card" +
                (errors.min.length > 0 || errors.max.length > 0 ? " error" : "")}>
                {tooltip && <TooltipHost content={tooltip} delay={TooltipDelay.zero} hostClassName="tooltip" calloutProps={{ directionalHint: DirectionalHint.rightCenter }}>
                    <Icon iconName="Info" />
                </TooltipHost>}
                <div className="title">{title}</div>
                <div className="align-center">
                    <TextField suffix={suffix}
                        label={minLabel || "Minimum"}
                        value={min}
                        disabled={!onChange}
                        className={errors.min.length > 0 ? "error" : undefined}
                        styles={{ root: { paddingRight: 10 }, errorMessage: { display: "none" } }}
                        onChange={onChange ? this._setMin : undefined} />
                    <TextField suffix={suffix}
                        label={maxLabel || "Maximum"}
                        value={max}
                        disabled={!onChange}
                        className={errors.max.length > 0 ? "error" : undefined}
                        styles={{ errorMessage: { display: "none" } }}
                        onChange={onChange ? this._setMax : undefined} />
                </div>
                <div className={`description ${this.anyErrors(errors) ? 'error' : ''}`}>{errors.min[0] || errors.max[0]}</div>
            </div>
        );
    }

    private _setMin = (e: any, value: string) => {
        const errors = this._calculateErrors(value, this.state.max);
        this.setState({ min: value, errors });
        this._onChange(value, this.state.max, errors);
    }

    private _setMax = (e: any, value: string) => {
        const errors = this._calculateErrors(this.state.min, value);
        this.setState({ max: value, errors });
        this._onChange(this.state.min, value, errors);
    }

    private _onChange = (minValue: string | null, maxValue: string | null, errors: Errors) => {
        const min = this._parse(minValue);
        const max = this._parse(maxValue);

        const value = min !== null && max !== null
            ? {
                min: this.props.format == FormatType.Percent ? min / 100 : min,
                max: this.props.format == FormatType.Percent ? max / 100 : max
            }
            : { min: 0, max: 0 };

        this.debouncedOnChange(value, _ => {
            if (!this.anyErrors(errors)) this.props.onChange!(value);
        })
    }

    private _parse = (v: string | null) => {
        if (v === null) {
            return null;
        }
        const value = Number.parseFloat(v);
        return Number.isNaN(value) ? null : value;
    }

    private anyErrors(errors: Errors): boolean {
        return errors.min.length > 0 || errors.max.length > 0;
    }

    private _calculateErrors = (min: string | null, max: string | null) => {
        const errors: Errors = { min: [], max: [] };

        if (!!min && !!max) {
            const minMessage = this.validator.getErrorMessage(min);
            if (minMessage) {
                errors.min.push(minMessage);
            }
            const maxMessage = this.validator.getErrorMessage(max);
            if (maxMessage) {
                errors.max.push(maxMessage);
            }
            if (this.numberValidator.isValid(min) && this.numberValidator.isValid(max) && Number.parseFloat(min) > Number.parseFloat(max)) {
                errors.min.push("Minimum value must be less than maximum value");
            }

        } else if (!min && max) {
            const maxMessage = this.validator.getErrorMessage(max);
            if (maxMessage) {
                errors.max.push(maxMessage);
            }
            errors.min.push("Minimum value must be filled");
        } else if (min && !max) {
            const minMessage = this.numberValidator.getErrorMessage(min);
            if (minMessage) {
                errors.min.push(minMessage);
            }
            errors.max.push("Maximum value must be filled");
        }
        return errors;
    }

    private _buildState = (props: Props) => {
        const min: string = props.value.min !== null ? (props.format == FormatType.Percent ? Math.round(props.value.min * 100) : props.value.min).toString() : "";
        const max: string = props.value.max !== null ? (props.format == FormatType.Percent ? Math.round(props.value.max * 100) : props.value.max).toString() : "";
        const errors = this._calculateErrors(min, max);
        return { min, max, errors };
    }
}