import "./EditableCell.css";
import * as React from "react";
import { IFormInputComponent } from "../../../common/interfaces/IFormInputComponent";
import { IValidator } from "../../../../validation";

export type FormatterProps<TImportMap, TValue> = { item: TImportMap, value: TValue };
export type EditorProps<TImportMap, TValue> = {
    item: TImportMap,
    value: TValue,
    onChange: (value: TValue) => void,
    onEditComplete: (value?: TValue) => void,
    inputRef?: (_: IFormInputComponent) => void;
};

type Props<TImportMap, TValue> = {
    item: TImportMap,
    value: TValue,
    validator?: IValidator,
    onEditComplete: (value: TValue) => void,

    formatter: React.FunctionComponent<FormatterProps<TImportMap, TValue>> | React.ComponentClass<FormatterProps<TImportMap, TValue>>;
    editor: React.FunctionComponent<EditorProps<TImportMap, TValue>> | React.ComponentClass<EditorProps<TImportMap, TValue>>;

    allowOutsideClick?: boolean;
    readonly?: boolean;
};

type State<TValue> = { isEditing?: boolean, isEditorFocused?: boolean, value: TValue };

const rootElementSelector = ".import-panel .ms-Panel-main";

export class EditableCell<TImportMap, TValue> extends React.Component<Props<TImportMap, TValue>, State<TValue>> {
    private _editorInput: any;
    constructor(props: Props<TImportMap, TValue>) {
        super(props);
        this.state = { value: props.value };
        this.focus = this.focus.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props<TImportMap, TValue>) {
        if (!this.state.isEditing && this.state.value !== nextProps.value) {
            this.setState({ value: nextProps.value });
        }
    }

    UNSAFE_componentWillUnmount() {
        document?.querySelector(rootElementSelector)?.removeEventListener('click', () => this._onEditComplete());
    }

    render() {
        const { item, allowOutsideClick, readonly } = this.props;
        const { isEditing, value } = this.state;
        const Formatter = this.props.formatter;
        const Editor = this.props.editor;

        return <div className="import-editable-cell">
            {isEditing
                ? <div className="blurable" onBlur={allowOutsideClick ? undefined: () => this._onEditComplete()} onClick={e => e.stopPropagation()} data-selection-disabled>
                    <Editor
                        value={value}
                        item={item}
                        onEditComplete={this._onEditComplete}
                        onChange={(newValue: TValue) => this.setState({ value: newValue })}
                        inputRef={_ => this._editorInput = _} />
                </div>
                : <div data-selection-disabled
                    className="formatter align-center"
                    onClick={readonly ? undefined : this._turnOnEditMode}>
                    <Formatter item={item} value={value} />
                </div>}
        </div>;
    }

    private _turnOnEditMode = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ isEditing: true, isEditorFocused: true }, this.focus);
        document?.querySelector(rootElementSelector)
            ?.addEventListener('click', () => this._onEditComplete());
    }

    private _onEditComplete = (newValue?: TValue) => {
        let { value } = this.state;

        this.setState({ isEditing: false, isEditorFocused: false });
        document?.querySelector(rootElementSelector)?.removeEventListener('click', () => this._onEditComplete());

        if (newValue != null) {
            this.setState({ value: newValue });
            value = newValue;
        }
        const { validator, onEditComplete } = this.props;
        if (!validator || validator.isValid(value)) {
            onEditComplete(value!);
        } else {
            this.setState({ value: this.props.value })
        }
    }

    public focus = () => {
        if (this.state.isEditorFocused) {
            this._editorInput.focus();
        }
    }
}