import * as React from 'react';
import { Selection } from 'office-ui-fabric-react';
import { Dictionary, IBaseEntity } from '../../entities/common';
import { notUndefined } from './common';

export interface IEntityMapHelper<T extends IBaseEntity> {
    getItems(ids: string[]): T[];
    getMap(): Dictionary<T>;
}

export const useEntityMapHelper = <T extends IBaseEntity>(itemsById: Dictionary<T>): IEntityMapHelper<T> => {
    return {
        getItems: (ids: string[]) => ids.map(_ => itemsById[_]).filter(notUndefined),
        getMap: () => itemsById
    }
}
