import * as React from 'react';
import { buildRoadmapItemIconName, DependencyType, IRoadmapItem, IRoadmapItemDependency } from '../../entities/Subentities';
import { DirectionalHint, Dropdown, Icon, ISelectableOption } from 'office-ui-fabric-react';
import { DependencyTypeMetadata } from './RoadmapItemDependencies';
import OptionsPicker from '../common/inputs/OptionsPicker';

interface RoadmapItemDependencyCreateBoxProps {
    dependencyType: DependencyType
    roadmapItemId?: string;
    roadmapItems: IRoadmapItem[];
    dependencies: IRoadmapItemDependency[];
    onCreate: (type: DependencyType, id: string) => void;
}

const RoadmapItemDependencyTypeOption = (props: { option: ISelectableOption }) => <>
    {props.option.data && props.option.data.icon && <Icon iconName={props.option.data.icon} style={{ marginRight: '8px' }} aria-hidden='true' title={props.option.text} />}
    <span className="overflow-text">{props.option.text}</span>
</>;

const RoadmapItemDependencyOption = (props: { option: ISelectableOption }) => <div className="suggested-dependency-box">
    {props.option.data && props.option.data.icon && <Icon iconName={props.option.data.icon} className="dependency-type" aria-hidden='true' title={props.option.text} />}
    <div className="overflow-text">{props.option.text}</div>
</div>;

const RoadmapItemDependencyCreateBox = (props: RoadmapItemDependencyCreateBoxProps) => {
    const [dependencyType, setDependencyType] = React.useState(props.dependencyType);
    const dependenciesMap = props.dependencies.reduce((map, _) => ({ ...map, [_.sourceId]: true, [_.targetId]: true }), {});
    const options = props.roadmapItems
        .filter(_ => _.id !== props.roadmapItemId && !dependenciesMap[_.id])
        .map(_ => ({
            key: _.id,
            text: _.attributes.Name,
            data: { icon: buildRoadmapItemIconName(_, true) }
        }));
    return <div className="create-dependency-box">
        <Dropdown
            className="dependency-type"
            selectedKey={dependencyType}
            options={[DependencyType.DependsOn, DependencyType.DeliversTo]
                .map(_ => ({ key: _, text: DependencyTypeMetadata[_].label, data: { icon: DependencyTypeMetadata[_].iconName } }))}
            onRenderOption={(_?: ISelectableOption) => _ ? <RoadmapItemDependencyTypeOption option={_} /> : null}
            onRenderTitle={(_?: ISelectableOption[]) => _ ? <span className="align-center"><RoadmapItemDependencyTypeOption option={_[0]} /></span> : null}
            onChange={(e, _) => _ && setDependencyType(_.key as DependencyType)}
        />
        <OptionsPicker
            onChange={(option: ISelectableOption[]) => props.onCreate(dependencyType!, option[0].key as string)}
            onResolveSuggestions={(filter: string) => {
                return options.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
            }}
            onRenderSuggestionsItem={(item: ISelectableOption) => <RoadmapItemDependencyOption option={item} />}
            pickerCalloutProps={{
                calloutWidth: 334,
                directionalHint: DirectionalHint.bottomRightEdge
            }}
            adjustOptionsListWidth={false}
        />
    </div>;
};

export default RoadmapItemDependencyCreateBox;
