import * as React from 'react';
import { IFormInputProps } from '../interfaces/IFormInputProps';
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import TextInput, { MULTILINE_MAX_LENGTH } from './TextInput';
import { css, getId } from 'office-ui-fabric-react';
import Link from '../Link';
import { ensureHttpsSchema } from '../../utils/common';
import { Validator } from '../../../validation';

export type Hyperlink = {
    url: string;
    text?: string;
};

type HyperlinkComponentProps = IFormInputProps<Hyperlink, HTMLInputElement> & { onlyUrlEditor?: boolean };

type HyperlinkComponentState = {
    url: string;
    text: string;
    isEditing: boolean;
    isUrlInputFocused: boolean;
    isTextInputFocused: boolean;
    urlErrorMessage: string;
};

export default class HyperlinkInput extends React.Component<HyperlinkComponentProps, HyperlinkComponentState> implements IFormInputComponent {
    private _urlInput: any;
    private _textInput: any;
    private _hyperlinkField: any;

    private readonly _urlInputId = getId();
    private readonly _textInputId = getId();

    constructor(props: HyperlinkComponentProps) {
        super(props);
        this.state = {
            url: props.value?.url ?? "",
            text: props.value?.text ?? "",

            urlErrorMessage: "",
            isEditing: false,

            isUrlInputFocused: false,
            isTextInputFocused: false
        };
        this.focus = this.focus.bind(this);
    }

    componentWillReceiveProps(nextProps: HyperlinkComponentProps) {
        if (!nextProps.value) {
            this.setState({ url: "" });
        } else if (this.props.value?.url !== nextProps.value?.url || this.props.value?.text !== nextProps.value?.text) {
            this.setState({ url: nextProps.value?.url ?? "", text: nextProps.value?.text ?? "" });
        }
    }

    render() {
        const { url, text, isEditing, urlErrorMessage } = this.state;

        return !isEditing
            ? <HyperlinkField
                    {...this.props}
                    onFocus={() => this.setState({ isEditing: true, isUrlInputFocused: true }, this.focus)}
                    inputRef={_ => this._hyperlinkField = _}
                    url={url}
                    text={text}
                />
            : <div className="hyperlink-edit">
                <TextInput
                    id={this._urlInputId}
                    inputProps={{ placeholder: "Enter a URL" }}
                    value={url}
                    maxLength={MULTILINE_MAX_LENGTH}
                    inputRef={_ => this._urlInput = _}
                    onFocus={() => this.setState({ isUrlInputFocused: true })}
                    onChanged={this._onUrlChanged}
                    onEditComplete={this._onUrlEditComplete}
                    onBlur={this._onUrlInputBlur}
                    errorMessage={urlErrorMessage}
                    onErrorRender={this.props.onErrorRender}
                />
                {!this.props.onlyUrlEditor && <div className="with-top-margin">
                    <TextInput
                        id={this._textInputId}
                        inputProps={{ placeholder: "Alternative text" }}
                        value={text}
                        inputRef={_ => this._textInput = _}
                        onFocus={() => this.setState({ isTextInputFocused: true })}
                        onChanged={this._onTextChanged}
                        onEditComplete={this._onTextEditComplete}
                        onBlur={this._onTextInputBlur}
                        onErrorRender={this.props.onErrorRender}
                    />
                </div>}
            </div>
    }

    componentDidMount() {
        this.props.inputRef?.(this);
    }

    private _onUrlChanged = (url: string) => {
        const ensuredHttpsSchemaUrl = ensureHttpsSchema(url);
        this.setState({ url: ensuredHttpsSchemaUrl, urlErrorMessage: "" });
        this._onChanged(ensuredHttpsSchemaUrl, this.state.text);
    }

    private _onTextChanged = (text: string) => {
        this.setState({ text });
        if (text === "") {
            this.setState({ urlErrorMessage: "" });
        }
        this._onChanged(this.state.url, text);
    }

    private _onChanged = (url: string, text: string) => {
        const hyperlink = url !== "" ? { url, text } : null;
        this.props.onChanged?.(hyperlink);
    }

    private _onUrlEditComplete = (url: string) => {
        if (url !== this.props.value?.url) {
            this._onEditComplete();
        }
    }

    private _onTextEditComplete = (text: string) => {
        if (text !== this.props.value?.text) {
            this._onEditComplete();
        }
    }

    private _onEditComplete = () => {
        const { url, text } = this.state;

        const urlErrorMessage = this._getUrlErrorMessage(url, text);
        this.setState({ urlErrorMessage });

        if (!!urlErrorMessage) {
            return;
        }

        const hyperlink = url !== "" ? { url, text } : null;
        this.props.onEditComplete?.(hyperlink);
    }
    
    private _onUrlInputBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this._onInputBlur(e, this._textInputId);
        this.setState({ isUrlInputFocused: false });
    }
    
    private _onTextInputBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this._onInputBlur(e, this._urlInputId);
        this.setState({ isTextInputFocused: false });
    }

    private _onInputBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>, inputId: string) => {
        const isEditing = this._getIsEditing(e, inputId);
        this.setState({ isEditing });
    }

    private _getIsEditing(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, inputId: string) {
        const { url, text } = this.state;

        const urlErrorMessage = this._getUrlErrorMessage(url, text);
        this.setState({ urlErrorMessage });

        if (!!urlErrorMessage) {
            return true;
        }

        const relatedTarget = !!e.relatedTarget && e.relatedTarget as Element;
        return relatedTarget !== false && relatedTarget.id === inputId;
    }
    
    private _getUrlErrorMessage = (url: string, text: string) => {
        return url === "" && text !== "" 
            ? Validator.new().required().build().getErrorMessage(url) ?? ""
            : this.props.validator?.getErrorMessage(url) ?? "";
    }
    
    public focus() {
        const { disabled, readOnly } = this.props;
        const { isUrlInputFocused, isTextInputFocused } = this.state;

        if(disabled || readOnly){
            return;
        }

        if (isUrlInputFocused) {
            this._urlInput.focus();
        } else if (isTextInputFocused) {
            this._textInput.focus();
        } else {
            this._hyperlinkField.focus();
        }
    }
}

type HyperlinkFieldProps = {
    url: string;
    text: string;
    onFocus: () => void;
    inputRef?: (_: IFormInputComponent) => void;
    disabled?: boolean;
    readOnly?: boolean;
};

class HyperlinkField extends React.Component<HyperlinkFieldProps> implements IFormInputComponent {
    render() {
        const { url, text, disabled, readOnly } = this.props;
        const title = text !== "" ? text : url;

        return (
            <div className={css("hyperlink-input", disabled && "disabled")}
                onFocus={!!disabled || !!readOnly ? undefined : this.props.onFocus}
                onClick={!!disabled || !!readOnly ? undefined : this.props.onFocus}>
                <div className="link-wrapper overflow-text">
                    <Link className="overflow-text" href={url} target="_blank" title={title} onFocus={e => e.stopPropagation()}>
                        {title}
                    </Link>
                </div>
            </div>
        );
    }
    
    componentDidMount() {
        this.props.inputRef?.(this);
    }

    focus(): void {
        this.props.onFocus();
    }
}
