import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StatusCategory, statusCategoryMap, Dictionary } from "../../../entities/common";
import ColorStatusView from "../list/columns/ColorStatusView";

type OwnProps = {
    statuses: Dictionary<StatusCategory>;
    goTo: (category: StatusCategory) => void;
};

type Props = OwnProps & RouteComponentProps<{}>;

const CardCalculationStatuses = (props: Props) => {
    const renderStatus = (key: string, count: number) => {
        const status: any = StatusCategory[key as any];
        return (
            <ColorStatusView
                key={key}
                className="pf-pg-calculation"
                value={status}
                itemsCount={count}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.goTo(status);
                }}
            />
        );
    };

    return (
        <>
            {Object.keys(props.statuses)
                .filter((_) => [StatusCategory.Red, StatusCategory.Amber, StatusCategory.Green].includes(StatusCategory[_ as any] as any as StatusCategory))
                .map((key) => {
                    const num: any = StatusCategory[key as any];
                    const config = statusCategoryMap[num as number];
                    return { key, order: config.order };
                })
                .sort((a, b) => b.order - a.order)
                .map((_) => renderStatus(_.key, props.statuses[_.key]))}
        </>
    );
};

export default withRouter<OwnProps>(CardCalculationStatuses);
