import * as React from 'react';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { Dictionary, ISourceInfo, PANEL_CUSTOM_WIDTH } from "../../entities/common";
import ExternalSystemChoice from '../common/ExternalSystemChoice';
import O365ConnectControl from '../integration/resource/O365ConnectControl';
import JiraConnectControl from '../integration/resource/JiraConnectControl';
import VSTSConnectControl from '../integration/resource/VSTSConnectControl';
import SpoConnectControl from '../integration/resource/SpoConnectControl';
import { Integrations } from '../../store/Tenant';
import { IPanel, IPanelProps, Panel, PanelType, PrimaryButton } from 'office-ui-fabric-react';
import NotAllowedMessage from '../integration/NotAllowedMessage';
import MondayComConnectControl from '../integration/resource/MondayComConnectControl';
import SmartsheetConnectControl from '../integration/resource/SmartsheetConnectControl';
import MPPFileConnectControl from '../integration/resource/MPPFileConnectControl';
import P4WConnectControl from '../integration/resource/P4WConnectControl';

interface State {
    selectedConnector: SourceType;
}

type Props = {
    actions: Dictionary<any>;
    initialConnector?: SourceType;
    sourceInfos: ISourceInfo[];
    onDismiss: () => void;
    sources: SourceType[];
    integrations: Integrations;
}

type ConnectProps = {
    actions: any;
    dismissPanel?: () => void;
    connectionId?: string;
    sourceInfo?: ISourceInfo;
}

type RendersMap = { [key in SourceType]?: (props: ConnectProps) => JSX.Element } & { types: SourceType[] }
export const rendersMap: RendersMap = {
    [SourceType.O365User]: _ => <O365ConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    [SourceType.Jira]: _ => <JiraConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    [SourceType.VSTS]: _ => <VSTSConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    [SourceType.Spo]: _ => <SpoConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    [SourceType.MondayCom]: _ => <MondayComConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    [SourceType.Smartsheet]: _ => <SmartsheetConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    [SourceType.MPPFile]: _ => <MPPFileConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    [SourceType.P4W]: _ => <P4WConnectControl {..._} sourceData={_.sourceInfo?.sourceData} />,
    types: [SourceType.O365User, SourceType.Jira, SourceType.VSTS, SourceType.Spo, SourceType.MondayCom, SourceType.Smartsheet,
        SourceType.MPPFile, SourceType.P4W]
}

export default class ExternalEpmResourceConnectControl extends React.Component<Props, State> {
    private _panel?: IPanel;

    constructor(props: Props) {
        super(props);
        this.state = { selectedConnector: props.initialConnector || props.sourceInfos[0]?.type || props.sources[0]! };
    }

    public render() {
        const { selectedConnector } = this.state;
        return <Panel
            componentRef={_ => _ && (this._panel = _)}
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth={PANEL_CUSTOM_WIDTH}
            className="connect-panel"
            isOpen={true}
            onDismiss={this.props.onDismiss}
            onRenderHeader={this._onRenderHeader}>
            <ExternalSystemChoice
                sources={this.props.sources}
                selected={selectedConnector!}
                onChanged={_ => this.setState({ selectedConnector: _ })}
                sourceInfos={this.props.sourceInfos}
                integrations={this.props.integrations}
            />
            {selectedConnector != undefined && this._renderConnectControl(selectedConnector)}
        </Panel>;
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Resource Connections</p>
            <div className='ms-Panel-secondaryText'>Connect to users and resources in external systems to properly aggregate all related activities.</div>
        </div>;
    }

    private dismissPanel = () => {
        this._panel!.dismiss();
    }

    private _renderConnectControl(connector: SourceType) {
        const sourceInfos = this.props.sourceInfos.filter(_ => _.type === connector);
        const sourceInfo = sourceInfos.length ? sourceInfos[0] : undefined;
        const render = rendersMap[connector];

        if (this.props.integrations.isDisabled(connector) && sourceInfo != undefined) {
            return <>
                <NotAllowedMessage connector={connector} />
                <PrimaryButton
                    text="Delete resource link"
                    className="with-top-margin"
                    onClick={() => {
                        this.props.actions[connector].deleteUserLink(sourceInfo?.connectionId);
                        this.dismissPanel?.();
                    }} />
            </>
        }

        return render?.({
            actions: this.props.actions[connector],
            dismissPanel: this.dismissPanel,
            connectionId: sourceInfo && sourceInfo.connectionId,
            sourceInfo: sourceInfo
        });
    }
}