import * as React from 'react';
import { Toggle, TextField } from 'office-ui-fabric-react';
import { Validator } from '../../../validation';
import { ISectionInfo, IUpdateSectionInfo } from '../../../entities/Metadata';
import { ItemCreation } from '../ItemCreation';

export interface ISectionPanelProps {
    section: ISectionInfo;
    onDismiss: () => void;
    updateSection?: (sectionId: string, update: IUpdateSectionInfo) => void;
}

export interface ISectionPanelState {
    label?: string;
    description?: string;
    isOpen: boolean;
    isDirty?: boolean;
}

export default class SectionPanel extends React.Component<ISectionPanelProps, ISectionPanelState> {
    private _labelValidator = Validator.new().required().build();
    constructor(props: ISectionPanelProps) {
        super(props);
        this.state = {
            label: props.section?.label,
            isOpen: props.section?.isOpen ?? false,
            description: props.section?.description
        }
    }

    public render() {
        const { label, description, isOpen } = this.state;
        return (
            <ItemCreation onDismiss={this.props.onDismiss}
                header={{
                    text: 'Configure Section',
                    secondaryText: 'Configure section display options',
                    onChanged: () => { },
                    validator: Validator.new().build(),
                    showNameEditor: false
                }}
                isDirty={this.state.isDirty}
                commands={[
                    {
                        primary: true,
                        text: 'Save Section',
                        disabled: (!!label && !this._labelValidator.isValid(label)) || !this.props.updateSection,
                        onClick: this._saveClick,
                    }, {
                        text: 'Cancel',
                        onClick: this.props.onDismiss
                    }
                ]}>
                <TextField label='Label'
                    onChange={(e, value) => this.setState({ isDirty: true, label: value })}
                    value={label}
                    disabled={!this.props.updateSection}
                    placeholder={this.props.section.name}
                    onGetErrorMessage={_ => label ? this._labelValidator.getErrorMessage(_) : undefined} />
                <TextField label='Description'
                    onChange={(e, value) => this.setState({ isDirty: true, description: value })}
                    value={description}
                    disabled={!this.props.updateSection}
                    resizable={false}
                    multiline
                    rows={6} />
                <Toggle label='Is Open by Default'
                    checked={isOpen}
                    onChange={(e, checked) => this.setState({ isDirty: true, isOpen: !!checked })}
                    disabled={!this.props.updateSection}
                    onText='Yes'
                    offText='No' />
            </ItemCreation>
        );
    }

    private _saveClick = () => {
        const { section, onDismiss, updateSection } = this.props;
        const { label, description, isOpen } = this.state;

        updateSection?.(section.id, { label, description, isOpen});
        onDismiss();
    }
}
