import "./HierarchyImportGrid.css";
import * as React from "react";
import {
    IColumn, Overlay, IDetailsRowProps,
    SelectionMode, Selection, SearchBox, Label, MessageBar, MessageBarType
} from "office-ui-fabric-react";
import Spinner from "../../common/Spinner";
import { waitForFinalEvent } from '../../utils/common';
import { StickyDetailsList } from "../../common/StickyDetailsList";
import { Dictionary } from "../../../entities/common";

const timeDelay = 600;

export type Filter = Dictionary<string[]>;
type ImportMap = {};
export type HierarchyImportGridProps = {
    itemsCount: number;
    selectedItemsCount?: number;
    maps: ImportMap[];
    getKey: (_: ImportMap) => string;
    columns: IColumn[],
    selection: Selection<any>,
    isImportEnabled: boolean,
    searchPlaceholder: string,
    onSearch: (search?: string) => void;
    search?: string;
    onImport: () => void;
    message?: { text: string, type: MessageBarType },
    onMessageDismiss: () => void,
    className: string,
    onRenderFilter?: () => JSX.Element | null
}
type OwnProps = {
    connectionId: string,
    isLoading: boolean;
    isProcessing?: boolean;
    error: string | null;
} & HierarchyImportGridProps;

type Props = OwnProps;
type State = { prevPropsSearch?: string, search?: string }

export default class HierarchyImportGrid extends React.Component<Props, State> {
    private debouncedOnSearch: () => void;

    constructor(props: Props) {
        super(props);

        this.state = { search: props.search };
        this.debouncedOnSearch = waitForFinalEvent(() => props.onSearch(this.state.search), timeDelay, 'items-import-search');
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.search !== state.prevPropsSearch) {
            return { ...state, search: props.search, prevPropsSearch: props.search };
        }

        return null;
    }

    render() {
        const {
            error, isLoading, isProcessing, columns, maps, selection, selectedItemsCount, itemsCount, message, onMessageDismiss,
            onRenderFilter, searchPlaceholder, getKey
        } = this.props;
        const { search } = this.state;
        return <div className="hierarchy-import">
            {error
                ? <div className="error-message">{error}</div>
                : isLoading
                    ? <Spinner />
                    : <>
                        {message && <MessageBar className="notification" messageBarType={message.type} onDismiss={onMessageDismiss}>{message.text}</MessageBar>}
                        <SearchBox placeholder={searchPlaceholder}
                            onChange={(e: any, v: string) => this.setState({ search: v }, this.debouncedOnSearch)}
                            onClear={() => this.setState({ search: undefined }, this.debouncedOnSearch)}
                            onEscape={() => this.setState({ search: undefined }, this.debouncedOnSearch)}
                            value={search || ''}
                        />
                        <div className="filter-row">
                            <Label className="items-counter">{selectedItemsCount
                                ? `Selected ${selectedItemsCount} from ${itemsCount} item${pluralSuffix(itemsCount)}`
                                : `${itemsCount} item${pluralSuffix(itemsCount)} found`}</Label>
                            {onRenderFilter?.()}
                        </div>
                        <StickyDetailsList
                            className={`project-import-grid high-row ${this.props.className}`}
                            columns={columns}
                            items={maps}
                            onRenderRow={onRenderRow}
                            selectionPreservedOnEmptyClick
                            selectionMode={SelectionMode.multiple}
                            selection={selection}
                            selectionZoneProps={{ selection: selection, disableAutoSelectOnInputElements: true }}
                            setKey='set'
                            getKey={getKey}
                        />
                    </>}
            {isProcessing && <Overlay><Spinner /></Overlay>}
        </div>;
    }
}

const onRenderRow = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null) => {
    const element = defaultRender?.(props);
    return element ? React.cloneElement(element, { "data-selection-toggle": true }) : null;
}

const pluralSuffix = (count: number) => count === 1 ? "" : "s";
