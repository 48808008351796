import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { get } from '../../fetch-interceptor';
import { urlParamsBuilder } from '../../entities/Subentities';
import { DetailsSpinner } from '../common/Spinner';
import { defaultCatch } from '../../store/utils';

type ActionProps = {
    defaultCatch: (error: any) => void;
}

type Props = ActionProps & RouteComponentProps<{}>;

const NavigateToTask = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const uniqueId = props.match.params[urlParamsBuilder.uniqueId];
        get<string>(`api/project/task/${uniqueId}/internalUrl`)
            .then(data => {
                window.location.href = data;
                setIsLoading(false);
            })
            .catch(props.defaultCatch);;
    }, []);

    return <DetailsSpinner isLoading={isLoading} />;
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        defaultCatch: defaultCatch(dispatch)
    }
}

export default connect(undefined, mergeActionCreators)(NavigateToTask);