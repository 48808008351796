import * as React from 'react';
import TimelineCanvas, { IStretch } from "./TimelineCanvas";
import * as utils from './utils';
import { getOffset, IScaleOffset } from './TimelineScale';
import { FormatDate } from '../../utils/common';
import { ITimeframe } from '../../../entities/common';

interface ITimelineHeaderProps extends IStretch {
    displayToday: boolean;
    onScaleItemClick?: (_: ITimeframe) => void;
    isDayOff?: (date: Date) => boolean;
}

type State = { pieceWidth: number, todayOffset: IScaleOffset };

export default class TimelineHeader extends React.Component<ITimelineHeaderProps, State> {
    constructor(props: ITimelineHeaderProps) {
        super(props);
        this.state = this._buildState(props);
    }

    public render() {
        return <TimelineCanvas {...this.props} className="timeline-header">
            <ul style={{ maxWidth: this.props.timelineWidth }}>
                {this.props.scale.dates.map((_, i) => (
                    <li key={_.start.toString()}
                        className={`overflow-text ${this.props.isDayOff?.(_.start) ? 'off': ''} ${!this.props.isDayOff && _.start < new Date() ? 'past' : ''}`}
                        title={this.props.onScaleItemClick ? `${FormatDate(_.start)} - ${FormatDate(_.finish)}` : FormatDate(_.start)}
                        style={{ width: Math.floor((i + 1) * this.state.pieceWidth) - Math.floor(i * this.state.pieceWidth) }}>
                        <a className={this.props.onScaleItemClick ? 'clickable': undefined} onClick={this.props.onScaleItemClick ? () => this.props.onScaleItemClick!({ start: _.start, end: _.finish.getBeginOfDay() }) : undefined}>
                            {_.dateFormatted}
                        </a>
                    </li>))}
            </ul>
            {this.props.displayToday && !this.state.todayOffset.isOutOfScale &&
                <span className="today-wrap" style={{ left: this.state.todayOffset.left }}>
                    <span className={`today ${this.state.todayOffset.left < 30 ? 'at-start' : ((this.props.timelineWidth - this.state.todayOffset.left) < 30) ? 'at-finish' : ''}`} title="Today">
                        {utils.formatDate('M dd', new Date())}
                    </span>
                </span>}
        </TimelineCanvas>;
    }

    componentWillReceiveProps(props: ITimelineHeaderProps) {
        if (props.scale != this.props.scale || props.timelineWidth != this.props.timelineWidth) {
            this.setState(this._buildState(props));
        }
    }

    private _buildState(props: ITimelineHeaderProps) : State {
        let coef = props.timelineWidth / props.scale.totalWidthUnits;
        let offset = getOffset(props.scale, new Date().getBeginOfDay(), props.timelineWidth);
        return {
            pieceWidth: props.scale.pieceWidthUnits * coef,
            todayOffset: offset
        };
    }
}