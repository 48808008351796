import * as React from 'react';
import { TextField, Toggle } from 'office-ui-fabric-react';
import { actionCreators, StrategicPriority } from "../../store/StrategicPrioritiesListStore";
import { connect } from 'react-redux';
import { Validator } from '../../validation';
import { EntityType, Impact } from '../../entities/common';
import ImportanceSlider from '../common/ImportanceSlider';
import { ItemCreation } from '../common/ItemCreation';
import { notUndefined } from '../utils/common';
import { getDeleteButtonCommand } from '../common/SubentityPanel';
import * as analytics from '../../analytics';
import { ApplicationState } from '../../store';
import { UserState } from '../../store/User';

type OwnProps = {
    strategicPriority?: StrategicPriority,
    onDismiss: () => void,
    readOnly?: boolean,
    onDelete?: (id: string) => void
}
type StateProps = {
    user: UserState;
}
type State = { name: string; description: string, importance: Impact, isActive: boolean, isDirty?: boolean }
type StrategicPriorityCreateProps = StateProps & OwnProps & typeof actionCreators;

const validators: { [key: string]: Validator } = {
    name: Validator.new().required().build()
}

class StrategicPriorityEdit extends React.Component<StrategicPriorityCreateProps, State> {
    constructor(props: StrategicPriorityCreateProps) {
        super(props);
        this.state = {
            name: props.strategicPriority ? props.strategicPriority.name : "",
            description: props.strategicPriority ? props.strategicPriority.description : "",
            importance: props.strategicPriority ? props.strategicPriority.importance : Impact.NA,
            isActive: props.strategicPriority ? props.strategicPriority.isActive : true
        };
    }

    public render() {
        const { readOnly, onDelete, onDismiss, strategicPriority } = this.props;
        return <ItemCreation
            className="strategic-priority-panel"
            onDismiss={onDismiss}
            header={{
                text: `${strategicPriority ? (readOnly ? "View" : "Edit") : "New"} Strategic Priority`,
                secondaryText: `${strategicPriority ? (readOnly ? "View" : "Edit") : "Create and define"} strategic priority ${strategicPriority ? "of" : "for"} the organization`,
                nameEditorLabel: "Priority Name",
                value: this.state.name,
                disableNameEditor: readOnly,
                onChanged: _ => this.setState({ isDirty: true, name: _ }),
                validator: validators.name
            }}
            isDirty={this.state.isDirty}
            commands={[
                readOnly ? undefined : {
                    primary: true,
                    text: `${strategicPriority ? "Save" : "Create"} Strategic Priority`,
                    onClick: this._save,
                    disabled: !this._isFormValid()
                },
                {
                    text: readOnly ? 'Close' : 'Cancel',
                    onClick: onDismiss
                },
                !readOnly && strategicPriority && onDelete
                    ? getDeleteButtonCommand(() => onDelete(strategicPriority.id))
                    : undefined
            ].filter(notUndefined)}>
            <div>
                <div className="label">Importance</div>
                <div className="label-description">Move right or left in priority to fine-tune the priority</div>
                <ImportanceSlider
                    disabled={readOnly}
                    value={this.state.importance}
                    onChange={_ => this.setState({ isDirty: true, importance: _ })}
                />
            </div>
            <TextField
                label="Description"
                disabled={readOnly}
                value={this.state.description}
                multiline={true}
                onChange={(e, _) => this.setState({ isDirty: true, description: _! })} />
            <div className="inline-toggle-wrap">
                <span className="label">Active</span>
                <Toggle
                    checked={this.state.isActive}
                    onChanged={_ => this.setState({ isDirty: true, isActive: _ })}
                    disabled={readOnly}
                    onText='Yes'
                    offText='No'
                />
            </div>
            {this.props.children}
        </ItemCreation>;
    }

    private _isFormValid(): boolean {
        return Validator.isValid(validators, this.state);
    }

    private _save = () => {
        const strategicPriority = {
            name: this.state.name,
            description: this.state.description,
            importance: this.state.importance,
            isActive: this.state.isActive,
        } as StrategicPriority
        if (this.props.strategicPriority) {
            strategicPriority.id = this.props.strategicPriority.id;
        } else {
            analytics.trackCreate(this.props.user, {
                itemTitle: strategicPriority.name,
                itemType: EntityType.StrategicPriority
            });
        }
        this.props.saveStrategicPriority(strategicPriority);
        this.props.onDismiss();
    }
}
const mapStateToProps = (state: ApplicationState, OwnProps: OwnProps) => ({ user: state.user });
export default connect(mapStateToProps, actionCreators)(StrategicPriorityEdit)