import React, { useMemo, useRef } from "react";
import { UpdatePanel } from "../common/SubentityPanel";
import { CostType, EntityType } from "../../entities/common";
import { ISubentity } from "../../entities/Subentities";
import { Field } from "../../entities/Metadata";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { DefaultCostType, IsEnterpriseTimeTracker, TimeTrackingEntry, TimeTrackingEntryAttr } from "../../store/TimeTrackingStore";
import { nameof } from "../../store/services/metadataService";
import { TimeTrackingAdministrativeCategory, TimeTrackingGlobalSettings } from "../../store/Tenant";
import { notUndefined } from "../utils/common";
import { rendersBuilder, validators } from "./Fields";

type OwnProps = {
    entry: { id?: string, attributes: Partial<TimeTrackingEntryAttr> } | null;
    onComplete: (id: string | null, attributes: TimeTrackingEntryAttr) => void;
    onDismiss: () => void;
    timeType: TimeType;
    onChangeProject?: () => void;
};

export enum TimeType {
    ProjectTask = 1,
    CustomEvent,
    Administrative,
    O365
}

type StateProps = {
    fields: Field[];
    administrativeCategories: TimeTrackingAdministrativeCategory[];
    timeTrackingSettings: TimeTrackingGlobalSettings;
    isEnterpriseTimeTracker: boolean;
};

type Props = OwnProps & StateProps;

const entityType = EntityType.TimeTrackingEntry;
const hoursInWorkingDay = 8;

const EditTimeTrackingEntry = (props: Props) => {
    const { fields, timeType, administrativeCategories, onComplete, onDismiss, timeTrackingSettings, onChangeProject, isEnterpriseTimeTracker } = props;
    const isAdministrative = timeType === TimeType.Administrative;
    const isCustomTask = timeType === TimeType.CustomEvent;

    const entry = useRef<TimeTrackingEntry>({
        id: props.entry?.id ?? "",
        attributes: {
            Date: new Date().toDateOnlyString(),
            Duration: isAdministrative ? hoursInWorkingDay : undefined,
            CostType: isAdministrative || !isEnterpriseTimeTracker || props.entry?.id
                ? undefined
                : DefaultCostType,
            IsBillable: isAdministrative ? undefined : false,
            ...props.entry?.attributes
        }
    }).current;

    const operation = entry.id ? "Edit" : "Report";
    const timeTypeLabel = isAdministrative
        ? "Administrative Time"
        : isCustomTask
            ? "Custom Time"
            : "Time";
    
    const title = `${operation} ${timeTypeLabel}`;
    
    const secondaryText = entry.id ? "Modify information about the  Time entry"
        : isAdministrative
            ? "Fill in the Fields below to report Administrative Time"
            : isCustomTask
                ? "Fill in the Fields to report time on a Custom Non-Task Activity. This activity will not be added to the Project Schedule"
                : "Fill in the Fields below to report Time";

    const displayFields = useMemo(() => {
        const hiddenFields: string[] = [nameof<TimeTrackingEntryAttr>("Resource"), nameof<TimeTrackingEntryAttr>("Task")];

        if (!isCustomTask || entry.id) {
            hiddenFields.push(nameof<TimeTrackingEntryAttr>("CustomTimeEntryName"));
            hiddenFields.push(nameof<TimeTrackingEntryAttr>("Project"));
        }

        if (isAdministrative) {
            hiddenFields.push(nameof<TimeTrackingEntryAttr>("IsBillable"));
            hiddenFields.push(nameof<TimeTrackingEntryAttr>("CostType"));

            if (entry.id) {
                hiddenFields.push(nameof<TimeTrackingEntryAttr>("AdministrativeCategory"));
            }
        }
        else {
            hiddenFields.push(nameof<TimeTrackingEntryAttr>("AdministrativeCategory"));
        }

        if (!isEnterpriseTimeTracker) {
            hiddenFields.push(nameof<TimeTrackingEntryAttr>("CostType"));
        }

        return fields.filter(_ => !hiddenFields.includes(_.name));
    }, [isAdministrative, isCustomTask]);

    const readonlyFields = useMemo(() =>
        [
            nameof<TimeTrackingEntryAttr>("Project"),
            entry.id ? nameof<TimeTrackingEntryAttr>("Date") : undefined,
            isEnterpriseTimeTracker && !timeTrackingSettings.enableEditingBillable
                ? nameof<TimeTrackingEntryAttr>("IsBillable")
                : undefined,
            timeTrackingSettings.enableEditingCostType || entry.attributes.CostType === undefined
                ? undefined
                : nameof<TimeTrackingEntryAttr>("CostType")
        ].filter(notUndefined),
        []);

    const customCommands = onChangeProject
        ? [
            {
                text: "Back",
                onClick: onChangeProject
            }
        ]
        : [];

    return (
        <UpdatePanel
            subentityTypeLabel={"Time"}
            subentityType={entityType}
            fields={displayFields}
            displayFields={displayFields.map(_ => _.name)}
            readOnlyFields={readonlyFields}
            readOnly={false}
            isDirty={isAdministrative && !entry.id}
            customCommands={customCommands}
            onComplete={(id, changes) => {
                if (id) {
                    onComplete(id, changes as TimeTrackingEntryAttr);
                } else {
                    const newEntry = {
                        ...(entry.attributes),
                        ...changes
                    };

                    onComplete(id, newEntry as TimeTrackingEntryAttr);
                }
            }}
            header={{
                showNameEditor: false,
                disableNameEditor: false,
                secondaryText: secondaryText,
                text: title
            }}
            onDismiss={onDismiss}
            operation={entry.id ? undefined : operation}
            entity={(entry) as ISubentity & TimeTrackingEntry}
            customFieldValidatorBuilder={validators(timeTrackingSettings)}
            uiControlElementsCustomRender={rendersBuilder(entry, timeTrackingSettings, administrativeCategories)}
        />
    );
};

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[entityType];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        administrativeCategories: state.tenant.timeTracking.administrativeCategories,
        timeTrackingSettings: state.tenant.timeTracking.globalSettings,
        isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription)
    };
}

export default connect(mapStateToProps)(EditTimeTrackingEntry);
