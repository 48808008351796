import React from 'react';
import { Dictionary } from '../../../entities/common';
import OKRImage from '../../../../src/images/sections/okr.png';
import BudgetImage from '../../../../src/images/sections/budget.png';
import BenefitsImage from '../../../../src/images/sections/benefits.png';
import TimeImage from '../../../../src/images/sections/time.png';
import DecisionMatrixImage from '../../../../src/images/sections/decision-matrix.png';
import StrategicAlignmentImage from '../../../../src/images/sections/strategic-alignment.png';
import ResourcePlanImage from '../../../../src/images/sections/resource-plan.png';
import UtilizationImage from '../../../../src/images/sections/utilization.png';
import WorkWeekImage from '../../../../src/images/sections/work-week.png';
import WorkloadImage from '../../../../src/images/sections/workload.png';
import CalendarExceptionsImage from '../../../../src/images/sections/calendar-exceptions.png';
import PPMInsightsAIImage from '../../../../src/images/sections/ppm-insights-ai.png';
import RoadmapImage from '../../../../src/images/sections/roadmap.png';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CommonOperations, contains } from '../../../store/permissions';
import { History } from 'history';

type InaccessibleSectionSettings = {
    image: string;
    description?: string;
    onRenderButtons?: (permissions: CommonOperations, history: History) => JSX.Element;
}

const defaultSectionDescription = "This section is not accessible for viewing as it is not included in your current subscription plan.\
    To gain access to this section, please upgrade your plan.";

const inaccessibleSectionImageMap: Dictionary<InaccessibleSectionSettings> = {
    ["OKR"]: { image: OKRImage },
    ["Budget"]: { image: BudgetImage },
    ["Benefits"]: { image: BenefitsImage },
    ["Time"]: { image: TimeImage },
    ["Decision Matrix"]: { image: DecisionMatrixImage },
    ["Strategic Alignment"]: { image: StrategicAlignmentImage },
    ["Resource plan"]: { image: ResourcePlanImage },
    ["Utilization"]: { image: UtilizationImage },
    ["Work Week"]: { image: WorkWeekImage },
    ["Workload"]: { image: WorkloadImage },
    ["Calendar Exceptions"]: { image: CalendarExceptionsImage },
    ["Roadmap"]: { image: RoadmapImage },
    ["PPM Insights AI"]: { 
        image: PPMInsightsAIImage,
        description: "This section is not accessible for viewing as the feature PPM Insights AI is not enabled. To gain access to this section, please enable PPM Insights AI.",
        onRenderButtons: (permissions, history) => onRenderAiInsightsButtons(permissions, history)
    }
};

type Props = { 
    sectionName: string;
    permissions: CommonOperations
};

const InaccessibleSection = (props: Props & RouteComponentProps<{}>) => { 
    const { sectionName, permissions, history } = props;

    return <div className='inaccessible-section'>
        <img src={inaccessibleSectionImageMap[sectionName].image} alt={sectionName} />
        <div className='description'>{inaccessibleSectionImageMap[sectionName].description ?? defaultSectionDescription}</div>
        <div className='buttons'>{
            inaccessibleSectionImageMap[sectionName].onRenderButtons
                ? inaccessibleSectionImageMap[sectionName].onRenderButtons!(permissions, history) 
                : <>
                    { contains(permissions, CommonOperations.BillingManage) && <PrimaryButton onClick={() => history.push('/billing')} key="upgrade-plan" text="Upgrade plan" /> }
                    <DefaultButton href='https://help.ppm.express/99995-ppm-express-subscription-plans-and-licensing/ppm-express-subscription-plans' target='_blank' key="learn-more" text="Learn more" />
                </>
        }</div>
    </div>
}

const onRenderAiInsightsButtons = (permissions: CommonOperations, history: History) => {
    return <>
        { contains(permissions, CommonOperations.Administrate) && <PrimaryButton onClick={() => history.push('/statusSettings')} key="open-ai-settings" text="Open AI Settings" /> }
        <DefaultButton href='https://help.ppm.express/ppm-insights-ai/2131404' target='_blank' key="learn-more" text="Learn more" />
    </>
}

export default withRouter<Props>(InaccessibleSection);