import * as React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { IWithActiveSubViewId } from "../../entities/common";
import { IWithViewsActions } from './extensibleEntity/ListMenu';
import * as Metadata from '../../entities/Metadata';
import { IUrlHelper, SubentityInfo, useUrlHelper } from "../../entities/Subentities";
import * as FieldsStore from '../../store/fields';
import * as ViewsStore from '../../store/views';
import { contains, CommonOperations } from '../../store/permissions';
import EditListSubView from '../views/list/EditListSubView';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { OnSaveSettings, PreferenceUpdates, Views } from '../../store/services/viewSaver';
import { IEntityStore } from '../../store/services/storeHelper';
import { useEffectTillTrue, useIsOpen } from '../utils/effects';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators } from 'redux';

type IViewConfig<TSubView extends Metadata.IListSubView = Metadata.IListSubView> = {
    subViews: IEntityStore<TSubView>;
    activeSubViewId?: string;
    selectedByDefault: string[];
    actions: {
        addSubView: (subView: TSubView) => void;
        removeSubView: (subViewId: string) => void;
        saveSubView: (subView: TSubView) => void;
        updateSubView: (subViewId: string, changes: Partial<TSubView>) => void;
    }
}

type ControlSettings = {
    viewType: Views;
    details?: IWithActiveSubViewId;
    timeline?: IWithActiveSubViewId;
}

const buildViewConfig = (viewType: string,
    views: ViewsStore.IViewsState,
    controlSettings: ControlSettings,
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>,
    onSaveSubViewRedirect: (view: Metadata.ISubView) => void): IViewConfig => viewType === Views.List
        ? {
            subViews: views.list.subViews,
            activeSubViewId: controlSettings.details?.activeSubViewId,
            selectedByDefault: views.list.selectedByDefault,
            actions: {
                addSubView: viewsActions.addListSubView,
                removeSubView: viewsActions.removeListSubView,
                saveSubView: (view: Metadata.IListSubView) =>
                    viewsActions.saveListSubView(view, undefined, onSaveSubViewRedirect),
                updateSubView: viewsActions.updateListSubView
            }
        }
        : {
            subViews: views.timeline.subViews,
            activeSubViewId: controlSettings.timeline?.activeSubViewId,
            selectedByDefault: views.timeline.selectedByDefault,
            actions: {
                addSubView: viewsActions.addTimelineSubView,
                removeSubView: viewsActions.removeTimelineSubView,
                saveSubView: (view: Metadata.ITimelineSubView) =>
                    viewsActions.saveTimelineSubView(view, undefined, onSaveSubViewRedirect),
                updateSubView: viewsActions.updateTimelineSubView
            }
        }

const Helper = {
    getSubViews: (store: IEntityStore<Metadata.IListSubView>, sourceType: SourceType | undefined) =>
        store.allIds
            .map(_ => store.byId[_])
            .filter(_ => _ && (sourceType === undefined || _.type === sourceType)),
    getSubView: (subViewId: string | null | undefined, subViews: Metadata.IListSubView[]) => subViewId
        ? subViews.find(_ => _.id == subViewId)
        : undefined,
    getDefault: (subViews: Metadata.IListSubView[]) => subViews.find(_ => _.isBuiltIn)
}

const buildViewsActions = (viewConfig: IViewConfig,
    sourceType: SourceType | undefined,
    toggleEditPanel: () => void,
    onSaveSettings: (subViewId: string, persist?: boolean) => void
): IWithViewsActions => ({
    onActiveViewChanged: onSaveSettings,
    onAddSubViewClick: () => {
        const subView = Metadata.SubView.empty(undefined, sourceType);
        viewConfig.actions.addSubView(subView);
        onSaveSettings(subView.id, false);
        toggleEditPanel();
    },
    onEditSubViewClick: subViewId => {
        onSaveSettings(subViewId);
        toggleEditPanel();
    },
    onCopySubViewClick: view => {
        const newSubView = Metadata.SubView.copy(view);
        viewConfig.actions.saveSubView(newSubView)
        toggleEditPanel();
    },
    onRemoveSubViewClick: viewConfig.actions.removeSubView,
})

type ComponentProps = {
    useViews?: boolean;
    subentityInfo: SubentityInfo;
    controlSettings: ControlSettings;
    onSaveSettings?: OnSaveSettings;
    sourceType?: SourceType;
    fakeFields?: Metadata.Field[];
    fieldsActions: ReturnType<typeof FieldsStore.actionCreators.forEntity>;
} & RouteComponentProps<{}>;

type StateProps = {
    fields: Metadata.Field[];
    views?: ViewsStore.IViewsState;
    canManageConfiguration: boolean;
}

type ActionsProps = {
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
}

type onSetActiveSubView = (activeSubViewId: string, persiste?: boolean) => void;

export type IWithSubViews = {
    view?: {
        subViews: Metadata.IListSubView[];
        activeSubView?: Metadata.IListSubView;
        actions: IWithViewsActions;
    }
}

export const withSubView = <TProps extends ComponentProps>(Component: React.ComponentType<TProps & IWithSubViews>) => {
    const ComponentWrapper = (props: ComponentProps & StateProps & ActionsProps) => {
        const urlHelper = useUrlHelper(props.history, props.location, props.subentityInfo.subentityType);
        const [isOpen, onOpen, onClose] = useIsOpen(false);
        const viewType = props.controlSettings.viewType ?? Views.List;

        const setActiveSubView = React.useCallback<onSetActiveSubView>((activeSubViewId, persiste) => {
            props.onSaveSettings?.(PreferenceUpdates.viewType(viewType, PreferenceUpdates.activeSubViewId(activeSubViewId)), persiste);
        }, [viewType, props.onSaveSettings]);

        const onSaveSubViewRedirect = (saved: Metadata.IListSubView) => {
            urlHelper.openSubView(saved.id);
            setActiveSubView(saved.id);
        }

        const { views = ViewsStore.VIEWS_EMPTY, viewsActions, fieldsActions, controlSettings, sourceType, canManageConfiguration, fakeFields } = props;
        const viewConfig = buildViewConfig(viewType, views, controlSettings, viewsActions, onSaveSubViewRedirect);
        const actions = buildViewsActions(viewConfig, sourceType, onOpen, setActiveSubView);

        const allFields = props.fields.concat(fakeFields || []);

        const subViews = Helper.getSubViews(viewConfig.subViews, sourceType);
        const activeSubView = Helper.getSubView(viewConfig.activeSubViewId, subViews)
            ?? Helper.getDefault(subViews);

        usePreferencesAndUrlSync(activeSubView,
            subViews,
            urlHelper,
            setActiveSubView,
            props.onSaveSettings);

        return <>
            {<Component
                {...props as any as TProps}
                view={{
                    actions: actions,
                    subViews: subViews,
                    activeSubView: activeSubView
                }}
            />}
            {isOpen && activeSubView && <EditListSubView
                key={`create-view-${activeSubView.id}`}
                subView={activeSubView}
                entityType={props.subentityInfo.subentityType}
                selectedByDefault={viewConfig.selectedByDefault}
                fields={allFields}
                allowManageFields={canManageConfiguration}
                fieldActions={fieldsActions}
                onChange={changes => viewConfig.actions.updateSubView(activeSubView.id, changes)}
                onSave={() => {
                    viewConfig.actions.saveSubView(activeSubView);
                    onClose()
                }}
                onCopy={() => {
                    const newSubView = Metadata.SubView.copy(activeSubView);
                    viewConfig.actions.saveSubView(newSubView);
                }}
                onDismiss={onClose}
            />
            }
        </>
    }

    const WithSubViews = connect(mapStateToProps, mapDispatchToProps)(ComponentWrapper);
    return (props: TProps) => {
        if (props.useViews) {
            return <WithSubViews {...props} />;
        }

        return <Component {...props} />;
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: ComponentProps): StateProps => {
    const subentityType = ownProps.subentityInfo.subentityType;
    const fields = state.fields[subentityType];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        views: ownProps.useViews ? state.views[subentityType] : undefined,
        canManageConfiguration: contains(state.user.permissions.common, CommonOperations.ConfigurationManage)
    }
}

const mapDispatchToProps = (dispatch: any, ownProps: ComponentProps): ActionsProps => ({
    viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(ownProps.subentityInfo.subentityType), dispatch)
})

const usePreferencesAndUrlSync = (activeSubView: Metadata.IListSubView | undefined,
    subViews: Metadata.IListSubView[],
    urlHelper: IUrlHelper,
    setActiveSubView: onSetActiveSubView,
    onSaveSettings: OnSaveSettings | undefined
) => {
    const urlActiveSubView = Helper.getSubView(urlHelper.getSubViewId(), subViews);
    const isLoading = !onSaveSettings;

    useEffectTillTrue(() => {
        if (isLoading) {
            return;
        }

        if (!urlActiveSubView) {
            return true;
        }

        if (urlActiveSubView.id !== activeSubView?.id) {
            setActiveSubView(urlActiveSubView.id);
        }

        return true;
    }, [isLoading, urlActiveSubView?.id, activeSubView?.id]);

    React.useEffect(() => {
         if (isLoading) {
            return;
        }

        if (activeSubView?.id !== urlActiveSubView?.id) {
            activeSubView?.id && urlHelper.openSubView(activeSubView?.id);
        }
    }, [isLoading, activeSubView?.id, urlActiveSubView?.id, urlHelper]);
}