import * as React from "react";
import CalendlyPanel from "../../common/CalendlyPanel";
import ButtonBox from "../ButtonBox";

type OwnProps = {
    big?: boolean;
}

export const RequestDemo = (props: OwnProps) => {
    const [showRequestDemo, setShowRequestDemo] = React.useState<boolean>();
    const onDismiss = React.useCallback(() => setShowRequestDemo(undefined), []);
    return <>
        <ButtonBox iconName="PPMXQuickStartCalendar"
            name="Schedule a demo"
            buttonText="Select a date"
            description="Rather have us show you how it would work for your company"
            big={props.big}
            action={() => setShowRequestDemo(true)} />
        {showRequestDemo && <CalendlyPanel onDismiss={onDismiss}
            headerText={<>Schedule a demo</>}
            headerDescription={<b>Just book a free call with our team here 👇</b>}
        />}
    </>;
}
export default RequestDemo;