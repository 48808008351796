import * as React from 'react';
import NumberInput from "../inputs/NumberInput";
import { IRangeFilterProps, IRangeFilterValue } from '../interfaces/IRangeFilter';

export type NumberFilterValue = IRangeFilterValue<number>;

export default class NumberFilter extends React.Component<IRangeFilterProps<NumberFilterValue>> {
    public render() {
        const { field, value = {}, onEditComplete, validator } = this.props;
        return <div className="number-filter">
            <NumberInput
                inputProps={{ placeholder: 'From' }}
                format={field.settings && field.settings.format}
                value={value.from}
                onEditComplete={_ => {
                    const from = _ !== null ? _ : undefined;
                    onEditComplete({ from: from, to: from !== undefined && value.to !== undefined && from > value.to ? from : value.to });
                }}
                validator={validator}
            />
            <NumberInput
                inputProps={{ placeholder: 'To' }}
                format={field.settings && field.settings.format}
                value={value.to}
                onEditComplete={_ => {
                    const to = _ !== null ? _ : undefined;
                    onEditComplete({ from: to !== undefined && value.from !== undefined && to < value.from ? to : value.from, to: to });
                }}
                validator={validator}
            />
        </div>;
    }
}