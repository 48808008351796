import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from '../../../store';
import Link from "../../common/Link";
import { SectionControlPlaceholder } from "../../common/sectionsControl/SectionPlaceholder";
import { CopyIcon } from "../../utils/copy";

type Props = {
    url?: string;
    version?: string;
    tenantUrl: string;
}

type State = {
    links: {
        url: string;
        title: string;
    }[];
}

export class ApiInfo extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { links: [] };
    }

    static getDerivedStateFromProps(props: Props) {
        const version = `v${props.version || "1.0"}`;
        const links = props.url
            ? [
                {
                    url: new URL(`@${props.tenantUrl}/${version}/`, props.url).toString(),
                    title: "API root URL"
                },
                {
                    url: props.url,
                    title: "Swagger UI"
                },
                {
                    url: new URL(`/${version}/swagger.json`, props.url).toString(),
                    title: "OpenAPI 3.0 Specification"
                }
            ]
            : [];

        return { links };
    }

    public render() {
        const { links } = this.state;
        if (!links.length) {
            return <SectionControlPlaceholder
                key="no-data"
                title="Public API"
                description="Configuration data isn't provided.">
            </SectionControlPlaceholder>
        }

        return <div className="api-info-links">
            {
                links.map((_, idx) => <div key={idx}>
                    <label>{_.title}:</label>
                    <div><Link href={_.url} target="_blank">{_.url}</Link> <CopyIcon text={_.url} /></div>
                </div>)
            }
        </div>
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        url: state.publicApi?.url,
        version: state.publicApi.version,
        tenantUrl: state.tenant.url
    };
}

export default connect(mapStateToProps)(ApiInfo);