import './FieldCard.css';
import * as React from 'react';
import { Icon, IIconProps, Toggle, TooltipHost } from 'office-ui-fabric-react';
import * as Metadata from '../../../../entities/Metadata';
import { getIconName } from '../../FieldOption';

type Props = {
    field: Metadata.Field;
    isNew?: boolean;
    allowManage?: boolean;
    selected: boolean;
    id?: string;
    onEditClick?: (field: Metadata.Field) => void;
    onRemoveClick?: (field: Metadata.Field) => void;
    onToggle?: (field: Metadata.Field, selected: boolean) => void;
    hideToggle?: boolean;
}

const TooltipContent = React.memo(({ label, description }: { label: string, description?: string }) => {
    return <span className='card-field-tooltip-content'>
        <span>{label}</span>
        <span>{description}</span>
    </span>;
})

export const FieldCard = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { id, field, isNew, allowManage, selected, onEditClick, onRemoveClick, onToggle } = props;
    const label = Metadata.getLabel(field);
    return <div id={id} ref={ref} className="card-field">
        <div className='card-field-selection-mark'><Icon iconName='PPMXFieldDragMark' /></div>
        <div className='card-field-content'>
            <div className='top-row'>
                <div className='card-field-header'>
                    <Icon iconName={getIconName(field)} className={`${field.isCustom ? 'custom-field-icon' : ''}`} />
                    <TooltipHost hostClassName='ellipsis' content={<TooltipContent label={label} description={field.description} />}>
                        <span className='field-label'>{label}</span>
                    </TooltipHost>
                    {isNew && <span className='green-label'>New</span>}
                </div>
                {!props.hideToggle && <Toggle
                    checked={selected}
                    onChange={(e, c) => { e.stopPropagation(); e.preventDefault(); onToggle?.(field, !!c); }}
                    title={selected ? 'On' : 'Off'}
                    disabled={!onToggle}
                />}
            </div>
            <div className='bottom-row'>
                <div className='field-properties'>{getDescription(field)}</div>
                {
                    allowManage !== undefined && !field.isFake && <div className='field-manage-buttons'>
                        {
                            onEditClick && <IconButton
                                iconName='Edit'
                                title='Edit'
                                className='menu'
                                onClick={() => onEditClick(field)}
                                disabled={field.isFake || !allowManage}
                            />
                        }
                        {
                            field.isCustom && onRemoveClick && <IconButton
                                iconName='Delete'
                                title='Remove'
                                className='menu'
                                onClick={() => onRemoveClick(field)}
                                disabled={!allowManage}
                            />
                        }
                    </div>
                }
            </div>
        </div>
    </div>
});

const IconButton = (props: IIconProps & { disabled: boolean }) => {
    return <Icon
        {...props}
        className={`${props.className} ${props.disabled ? 'disabled' : ''}`}
        onClick={props.disabled ? undefined : props.onClick}
    />
}

const getDescription = (field: Metadata.Field) => {
    const descriptions = [];

    if (field.isCustom) {
        descriptions.push('Custom');
    } else {
        descriptions.push('System');
    }

    if (Metadata.isRequired(field)) {
        descriptions.push('required');
    }

    return descriptions.join(', ');
}