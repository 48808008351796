import { IValidator, ValidatorType } from "../../../validation";

export class PSSTokenValidator implements IValidator {
    private _errorMessage: string;

    constructor(message?: string) {
        this._errorMessage = message == undefined ? 'Invalid token' : message;
        this.isValid = this.isValid.bind(this);
        this.getErrorMessage = this.getErrorMessage.bind(this);
    }

    type = ValidatorType.pssToken;

    has(validatorType: ValidatorType) {
        return this.type === validatorType;
    }

    isValid(value: any): boolean {
        if (value) {
            try {
                const valueDecoded = atob(value.toString());

                if (valueDecoded.indexOf("UserInfo") > -1) {
                    return true;
                }
            }
            catch {
            }
            return false;
        }
        return true;
    }

    getErrorMessage(value: any): string | undefined {
        return this.isValid(value) ? '' : this._errorMessage;
    }
}
