import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Metadata from "../../entities/Metadata";
import { UserState, isInReadonlyMode } from '../../store/User';
import { contains, canCreate, canUpdate } from '../../store/permissions';
import { IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import { nameof } from '../../store/services/metadataService';
import { ILayoutActions, LayoutService } from '../utils/LayoutService';
import { IInsightsData, EntityType, StatusCategory, IWarning, Dictionary } from '../../entities/common';
import { Insights, StatusCalculationTypes, TenantState } from '../../store/Tenant';
import { getCheckUncheckLabel, notUndefined } from '../utils/common';
import { LayoutsState } from '../../store/layouts';
import PersistEntityViewMenu from '../common/PersistEntityViewMenu';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import EntityHeader from '../common/EntityHeader';
import { SummaryEntityWarning } from '../common/warnings/SummaryEntityWarning';
import LinkedSystemIcons from '../common/sectionsControl/uiControls/summaryControl/LinkedSystemIcons';
import RemoveDialog from '../common/RemoveDialog';
import Logo from '../common/Logo';
import ContextualMenuToggle from '../common/ContextualMenuToggle';
import { Portfolio, PortfolioAttrs } from '../../store/PortfoliosListStore';
import ExternalEpmConnectControl from './ExternalEpmConnectControl';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import EntityHeaderMoreMenu from '../common/EntityHeaderMoreMenu';

interface IActions {
    updateImage?: (logo: File) => void;
    removeImage?: () => void;
    layoutActions?: ILayoutActions;
    removePortfolio: () => void;
    updateInsights: (data: Partial<IInsightsData>) => void;
    ExternalEpmConnectControl: Dictionary<any>;
}

type OwnProps = {
    entity: Portfolio;
    warnings?: IWarning[];
    warningsTypeMap?: string[];
    actions: IActions;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
    portfolioFields: Metadata.Field[];
    insights: Insights;
    layouts: LayoutsState;
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

const trackProps = { entityType: EntityType.Portfolio };

const PortfolioHeader = (props: Props) => {
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    const [isCofigurationPanelOpen, setIsCofigurationPanelOpen] = React.useState(false);
    const [layoutToApply, setLayoutToApply] = React.useState<Metadata.Layout>();

    const { entity, tenant, warnings, layouts, user, portfolioFields, actions, insights } = props;

    const statusAuto = contains(insights.portfolio.statusCalculation, StatusCalculationTypes.Auto);
    const keyDateStatusAuto = contains(insights.keyDate.statusCalculation, StatusCalculationTypes.Auto);
    const warningsGloballyOn = !insights.portfolioWarningsCalculationDisabled;

    const moreMenuItems: IContextualMenuItem[] = [
        entity.canConfigure && actions.layoutActions?.applyLayout
            ? {
                ...LayoutService.buildApplyLayoutMenuItem(layouts, (layout) => setLayoutToApply(layout), entity.layoutId),
                disabled: !entity.isEditable
            }
            : undefined,
        {
            key: 'autocalc',
            name: 'Calculated automatically',
            iconProps: { iconName: 'Processing' },
            subMenuProps: {
                items: [{
                    key: 'status',
                    name: 'Portfolio Statuses',
                    title: getCheckUncheckLabel(statusAuto && !entity.insights.statusCalculationDisabled),
                    disabled: !entity.isEditable || !statusAuto,
                    onRender: (item: any) => <ContextualMenuToggle
                        label={item.name}
                        disabled={item.disabled}
                        defaultChecked={!entity.insights.statusCalculationDisabled}
                        onChange={(e, checked: boolean) => {
                            actions.updateInsights({ statusCalculationDisabled: !checked });
                            analytics.trackToggle("Calculated automatically - 'Portfolio statuses' updated", user, checked, trackProps);
                        }}
                    />
                }, {
                    key: 'keydatestatus',
                    name: 'Key Dates Statuses',
                    title: getCheckUncheckLabel(keyDateStatusAuto && !entity.insights.keyDateStatusCalculationDisabled),
                    disabled: !entity.isEditable || !keyDateStatusAuto,
                    onRender: (item: any) => <ContextualMenuToggle
                        label={item.name}
                        disabled={item.disabled}
                        defaultChecked={!entity.insights.keyDateStatusCalculationDisabled}
                        onChange={(e, checked: boolean) => {
                            actions.updateInsights({ keyDateStatusCalculationDisabled: !checked });
                            analytics.trackToggle("Calculated automatically - 'Key dates statuses' updated", user, checked, trackProps);
                        }}
                    />
                }, {
                    key: 'warnings',
                    name: 'Warnings',
                    title: getCheckUncheckLabel(warningsGloballyOn && !entity.insights.warningsCalculationDisabled),
                    disabled: !entity.isEditable || !warningsGloballyOn,
                    onRender: (item: any) => <ContextualMenuToggle
                        label={item.name}
                        disabled={item.disabled}
                        defaultChecked={!entity.insights.warningsCalculationDisabled}
                        onChange={(e, checked: boolean) => {
                            actions.updateInsights({ warningsCalculationDisabled: !checked });
                            analytics.trackToggle("Calculated automatically - 'Warnings' updated", user, checked, trackProps);
                        }}
                    />
                }]
            }
        },
        {
            key: 'deletePortfolio',
            iconProps: { iconName: 'Delete' },
            name: 'Delete Portfolio',
            className: 'more-deleteButton',
            disabled: !entity.isEditable,
            onClick: () => setIsRemoveDialogOpen(true)
        }
    ].filter(notUndefined);

    const readonlyMode = isInReadonlyMode(user, tenant);
    const isConnectPanelAvailable = entity.isEditable || entity.sourceInfos.length && readonlyMode;
    const overallStatusField = portfolioFields.find(_ => _.name === nameof<PortfolioAttrs>("OverallStatus"))!;
    const overallStatusDescriptor = StatusDescriptorFactory.createStatusDescriptor(overallStatusField);
    const statusOption = overallStatusDescriptor.getOptionOrDefault(entity.attributes.OverallStatus, StatusCategory.NA);

    const _isMoreMenuDisabled = () => {
        if (!props.entity.isEditable) {
            return false;
        }
        return !(canCreate(props.user.permissions.portfolio) || canUpdate(props.user.permissions.portfolio));
    }

    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className="pf-logo" imageId={entity.imageId}
                onChanged={!entity.isEditable ? undefined : actions.updateImage}
                onRemove={!entity.isEditable ? undefined : actions.removeImage} />}
            leftSide={<>
                <CategoryColorStatusView
                    statusOption={statusOption}
                    trend={entity.insights.statuses[nameof<PortfolioAttrs>("OverallStatus")]?.trend}
                    title={overallStatusField?.label}
                />
                {props.warningsTypeMap && <SummaryEntityWarning
                    warnings={warnings ?? []}
                    message="There might be some data inconsistency in your portfolio. For more details please review items marked with warning icons below."
                    commonWarningTypes={props.warningsTypeMap}
                />}
            </>}
            buttons={<>
                <LinkedSystemIcons
                    sourceInfos={entity.sourceInfos}
                    allowNavigate
                    showTitle
                    entityType={EntityType.Portfolio} />
                <IconButton iconProps={{ iconName: 'PPMXLink' }}
                    className="connect-launcher"
                    title="Connect"
                    disabled={!isConnectPanelAvailable}
                    onClick={() => setIsCofigurationPanelOpen(true)} />
                <EntityHeaderMoreMenu
                    entity={entity}
                    entityType={EntityType.Portfolio}
                    items={moreMenuItems}
                    disabled={_isMoreMenuDisabled()} />
            </>}
            views={actions.layoutActions
                ? <PersistEntityViewMenu
                    layoutsState={layouts}
                    entity={entity}
                    entityType={EntityType.Portfolio}
                    readonlyMode={readonlyMode}
                    user={user}
                    viewLayout={actions.layoutActions?.viewLayout}
                    saveLayout={entity.isEditable ? actions.layoutActions?.saveLayout : undefined}
                    savePinnedViews={entity.canConfigure
                        ? actions.layoutActions?.updateEntityPinnedViews
                        : undefined}
                    saveEntityLayout={entity.canConfigure || readonlyMode
                        ? actions.layoutActions?.updateEntityLayout
                        : undefined}
                    deleteLayout={actions.layoutActions?.deleteLayout}
                    profileLayoutId={user.permissions.portfolioProfileLayoutId}
                    layoutIdByEntityId={user.permissions.layoutIdByPortfoilioIdMap?.[entity.id]}
                />
                : undefined}
        ></EntityHeader>
        {
            isRemoveDialogOpen &&
            <RemoveDialog
                onClose={() => setIsRemoveDialogOpen(false)}
                onComplete={() => { actions.removePortfolio(); }}
                dialogContentProps={{
                    title: "Delete portfolio",
                    subText: `Are you sure you want to delete portfolio "${entity!.attributes.Name}" ?`
                }}
                confirmButtonProps={{ text: "Delete" }} />
        }
        {
            isCofigurationPanelOpen &&
            <ExternalEpmConnectControl
                readonly={!entity.isEditable}
                sourceInfos={entity.sourceInfos}
                actions={actions.ExternalEpmConnectControl}
                onDismiss={() => setIsCofigurationPanelOpen(false)}
            />
        }
        {
            layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => actions.layoutActions!.applyLayout!(layoutToApply)}
                onDismiss={() => setLayoutToApply(undefined)}
                entityType={EntityType.Portfolio}
                layoutName={layoutToApply!.name}
            />
        }
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    const programFields = state.fields[EntityType.Portfolio];
    return {
        user: state.user,
        tenant: state.tenant,
        portfolioFields: programFields.allIds.map(_ => programFields.byId[_]),
        insights: state.tenant.insights,
        layouts: state.layouts[EntityType.Portfolio]
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(PortfolioHeader));