import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import { FilterAttribute } from './FilterAttribute';
import { Dictionary } from '../../../entities/common';

type OwnProps = {
    permissions: string[];
    title: string;
    value: Dictionary<boolean>;
    onEditComplete: (data: Dictionary<boolean>) => void;
};

export const PermissionsFilterAttribute = (props: OwnProps) => {
    const { title } = props;
    const value = props.value ?? [];

    const permissionOptions = React.useMemo(() => {
        const options: Dictionary<{ Permission: string, Title: string, Value: boolean }> = {};
        props.permissions.forEach(permission => {
            options[`y_${permission}`] = { Permission: permission, Title: `${permission} - Yes`, Value: true };
            options[`n_${permission}`] = { Permission: permission, Title: `${permission} - No`, Value: false };
        });
        return options;
    }, [props.permissions]);

    const fakeField = React.useMemo<Metadata.Field>(() => {
        const options = Object.keys(permissionOptions).reduce<Dictionary<string>>((acc, _) => {
            acc[_] = permissionOptions[_].Title;
            return acc;
        }, {});

        return {
            id: title, name: title, type: Metadata.FieldType.Text, label: title,
            settings: {
                editControl: "Dropdown",
                multichoice: true,
                options: options
            },
            isNative: false, isCustom: false, isSystem: false, isReadonly: false, isFake: true, group: Metadata.FieldGroup.SystemFields,
        }
    }, [permissionOptions]);

    const _getDisplayValues = () => {
        const values = Object.keys(value).map(key => value[key] === true ? `y_${key}` : `n_${key}`);
        return values.length === 0 ? undefined : values;
    }

    const _getResultVlaues = (newValue: unknown): Dictionary<boolean> => {
        if (!newValue) {
            return {};
        };

        const values = (newValue as string[]).reduce<Dictionary<boolean>>((acc, _) => {
            const option = permissionOptions[_];
            acc[option.Permission] = option.Value;
            return acc
            }, {});

        return values;
    }

    return <FilterAttribute
        field={fakeField}
        value={_getDisplayValues()}
        onEditComplete={(newValue) => {
            const values = _getResultVlaues(newValue);
            props.onEditComplete(values);
        }} />
}
