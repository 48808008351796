import * as React from 'react';
import { Icon, TooltipHost, TooltipDelay, getId, DirectionalHint } from "office-ui-fabric-react";
import { abbreviateNumber } from "../../utils/common";
import { IWarning } from "../../../entities/common";

type Props = {
    className?: string,
    asButton?: boolean,
    warnings: IWarning[],
    tooltipTitle?: string,
    multipleItemsMessage?: string,
    onRenderBody?: () => JSX.Element | JSX.Element[] | null,
}

export default class EntityWarning extends React.Component<Props> {
    private markerClassName: string = `tooltip-corner-marker-${getId()}`;

    render() {
        const { warnings, className, asButton } = this.props;

        if (warnings.length === 0) {
            return null;
        }

        return (
            <TooltipHost hostClassName={`warning-tooltip-host ${className}`}
                tooltipProps={{
                    onRenderContent: this._renderTooltipContent,
                    maxWidth: "328px"
                }}
                calloutProps={{
                    directionalHint: DirectionalHint.topLeftEdge,
                    alignTargetEdge: true,
                    className: "entity-warning-tooltip",
                    coverTarget: true,
                    target: `.${this.markerClassName}`,
                    isBeakVisible: false,
                    gapSpace: 0,
                    calloutMaxHeight: 400
                }}
                closeDelay={10}
                delay={TooltipDelay.zero}>
                {
                    asButton
                        ? this._renderButton()
                        : this._renderIcon()
                }
            </TooltipHost>
        );
    }

    private _renderTooltipContent = () => {
        const { warnings, tooltipTitle, onRenderBody, multipleItemsMessage } = this.props;
        return (
            <div>
                <div className="title align-center">
                    {this._renderIcon()}
                    <span>
                        {tooltipTitle || (warnings.length > 1 ? "Warnings" : "Warning")}
                    </span>
                </div>
                <div className="body">
                    {
                        onRenderBody
                            ? onRenderBody()
                            : (warnings.length === 1
                                ? warnings[0].text
                                : (
                                    multipleItemsMessage 
                                        ? multipleItemsMessage
                                        : (
                                            <ul className="list">
                                                {warnings.map(_ => <li key={`${_.type}_${getId()}`}>{_.text}</li>)}
                                            </ul>
                                        )
                                )
                            )
                    }
                </div>
            </div>
        );
    }

    private _renderButton() {
        return (
            <div className="warning-button">
                {this._renderIcon()}
                <span className="warning-label">Warnings</span>
            </div>
        );
    }

    private _renderIcon = () => {
        const { warnings } = this.props;
        return (
            <div className="entity-warning" onClick={_ => _.stopPropagation()}>
                <div className={`tooltip-position-marker ${this.markerClassName}`} />
                {
                    warnings.length > 1 ?
                        <div>
                            <Icon iconName="Warning" className="warning-icon" />
                            <div className="metric">{abbreviateNumber(warnings.length)}</div>
                        </div> :
                        <>
                            <div className={`tooltip-position-marker ${this.markerClassName}`} />
                            <Icon iconName="Warning" className="warning-icon" />
                        </>
                }
            </div>
        );
    }
}