import * as React from 'react';
import { Icon, IconButton, ActionButton, Overlay, ComboBox, ISelectableOption, TextField, Panel, IPanelProps, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { MappingType } from '../../store/integration/common';
import { Field, FieldType } from "../../entities/Metadata";
import Spinner from "../common/Spinner";
import { DisplayFieldService, FieldMappingService } from "../common/DisplayFieldService";
import { FieldsService } from "../common/FieldsService";
import { nameof } from '../../store/services/metadataService';
import { isBlank } from '../utils/common';
import { IDropdownFormatterOption } from '../common/formatters/DropdownFormatter';
import * as Metadata from "../../entities/Metadata";

export type Row = { key: string, source: string | number | undefined, target: string | number |undefined };

export type Props = {
    value?: string;
    externalFieldValuesSuggesions?: Promise<number[] | string[] | null>,
    ppmxField: Field,
    mappingType: MappingType
    onOk: (value?: string) => void
    onDismiss: () => void
};

export type State = {
    isLoading: boolean;
    sourceOptions?: IDropdownFormatterOption[];
    targetOptions?: IDropdownFormatterOption[];
    sourceLabel: string,
    targetLabel: string,
    rows: Row[];
}

export class TransformationPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let ppmxFieldOptions: IDropdownFormatterOption[] | undefined = undefined;

        if (props.ppmxField.type == Metadata.FieldType.Flag) {
            const yesValue = "True";
            const noValue = "False";
            ppmxFieldOptions = [{ key: noValue, text: "No" }, { key: yesValue, text: "Yes" }];
        }

        if (FieldsService.isDropDown(props.ppmxField) || FieldsService.isSlider(props.ppmxField, true)) {
            ppmxFieldOptions = Metadata.getOptions(props.ppmxField).map(_ => ({ key: _.text, text: _.text }));
        }

        const value = props.value && JSON.parse(props.value);
        this.state = {
            isLoading: !!props.externalFieldValuesSuggesions,
            [props.mappingType === MappingType.ToPpmx ? nameof<State>("targetOptions") : nameof<State>("sourceOptions")]: ppmxFieldOptions,
            rows: value ? Object.keys(value).map(_ => ({ key: FieldMappingService.getNewKey(), source: _, target: value[_] })) : [],
            sourceLabel: this.props.mappingType === MappingType.ToPpmx ? "External value" : "PPM Express value",
            targetLabel: this.props.mappingType === MappingType.ToPpmx ? "PPM Express value" : "External value"
        };

        props.externalFieldValuesSuggesions?.then(
            _ => this.setState({
                isLoading: false,
                [props.mappingType === MappingType.ToPpmx
                    ? nameof<State>("sourceOptions")
                    : nameof<State>("targetOptions")]: _ ? _.map(__ => ({ key: __, text: __ })) : undefined
            } as Pick<State, "isLoading" | "sourceOptions" | "targetOptions">),
            _ => this.setState({ isLoading: false }));
    }
    render() {
        const { rows, sourceOptions, sourceLabel, targetLabel } = this.state;
        return (
            <Panel isOpen isLightDismiss
                onRenderHeader={this._onRenderHeader}
                onRenderFooterContent={this._onRenderFooterContent}
                onDismiss={this.props.onDismiss}>
                {this.state.isLoading && <Overlay><Spinner /></Overlay>}
                <div className="mapping">
                    <div className="header align-center">
                        {this.props.mappingType === MappingType.ToPpmx
                            ? <>
                                <div className="mapping-value">{sourceLabel}</div>
                                <div className="mapping-direction"></div>
                                <div>{targetLabel}</div>
                            </>
                            : <>
                                <div className="mapping-value">{targetLabel}</div>
                                <div></div>
                                <div>{sourceLabel}</div>
                            </>}
                    </div>
                    {rows.map(this._renderRow)}
                    {(!sourceOptions || sourceOptions.length === 0 || sourceOptions.length > rows.length) &&
                        <ActionButton iconProps={{ iconName: 'Add' }}
                            text="Add"
                            className="new-button"
                            onClick={() => this.setState({ rows: [...this.state.rows, { key: FieldMappingService.getNewKey(), source: undefined, target: undefined }] })} />}
                </div>
            </Panel>
        );
    }

    private _renderRow = (row: Row) => {
        const { sourceOptions, targetOptions, rows } = this.state;
        const { errorMessage } = this._validateRow(row);
        return (
            <div key={row.key} className="row">
                <div className="align-center">
                    {this._renderInput(row.source, 
                        this._onChangeValue("source", row.key),
                        sourceOptions?.filter(_ => _.key === row.source || !rows.find(__ => __.source === _.key)))}
                    <div className="mapping-direction">
                        <Icon iconName="ChromeBackMirrored" />
                    </div>
                    {this._renderInput(row.target, this._onChangeValue("target", row.key), targetOptions)}
                    <IconButton
                        key="remove"
                        menuIconProps={{ iconName: 'Delete' }}
                        title="Remove"
                        className='menu'
                        onClick={() => this.removeRow(row.key)} />
                </div>
                <div className="error-message">
                    {errorMessage}
                </div>
            </div>
        );
    };

    private _onChangeValue = (propName: "source" | "target", key: string) => (v: string | number | undefined) => {
        const rows = this.state.rows;
        const row = rows.find(_ => _.key === key);
        if (row) {
            row[propName] = v;
        }
        this.setState({ rows });
    };

    private _renderInput(value: string | number | undefined, onChange: (v: string | number | undefined) => void, options?: ISelectableOption[]) {
        return options?.length
            ? <ComboBox
                options={options}
                selectedKey={value}
                allowFreeform={false}
                placeholder="Select field"
                className={"mapping-value"}
                onChange={(e, o) => onChange(o?.key)} />
            : <TextField value={value?.toString()}
                onChange={(e, v) => onChange(v)}
                className={"mapping-value"} />;
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure Rules</p>
            <div className='ms-Panel-secondaryText'>Set up values for transformation rules</div>
        </div>;
    };

    private _onRenderFooterContent = (): JSX.Element => {
        const { onDismiss } = this.props;
        const { rows } = this.state;

        return <div className="commands">
            <PrimaryButton
                disabled={!rows.reduce((ac, row) => ac && (this._validateRow(row)).isValid, true) || !this._isRulesChanged()}
                text="Ok"
                onClick={this._onOk} />
            <DefaultButton text="Cancel" onClick={onDismiss} />
        </div>;
    }

    private _isRulesChanged = (): boolean => {
        const { value } = this.props;
        const { rows } = this.state;
        const propsValue = value && JSON.parse(value);
        return propsValue
            ? !(rows.length === Object.keys(propsValue).length && rows.reduce((ac, row) => ac && row.source && propsValue[row.source] === row.target, true))
            : rows.length > 0;
    }

    private removeRow = (key: string) => {
        const { rows } = this.state;
        const rowNumber = rows.findIndex(_ => _.key === key);
        if (rowNumber !== -1) {
            rows.splice(rowNumber, 1);
            this.setState({ rows });
        }
    };

    private _validateRow(row: Row): { isValid: boolean; errorMessage?: string; } {
        const { mappingType, ppmxField } = this.props;
        const { targetOptions, sourceOptions, sourceLabel, targetLabel, rows } = this.state;
        const ppmxValue = mappingType === MappingType.ToPpmx ? row.target : row.source;
        const options = mappingType === MappingType.ToPpmx ? targetOptions : sourceOptions;

        if (row.source === null || (row.source !== 0 && typeof (row.source) === 'string' && isBlank(row.source))) {
            return { isValid: false, errorMessage: `Invalid rule. ${sourceLabel} is empty.` };
        }
        if (row.target === null || (row.target !== 0 && typeof (row.target) === 'string' && isBlank(row.target))) {
            return { isValid: false, errorMessage: `Invalid rule. ${targetLabel} is empty.` };
        }
        if (rows.find(_ => _.key !== row.key && _.source === row.source)) {
            return { isValid: false, errorMessage: `Duplicate rule.` };
        }
        const validator = DisplayFieldService.buildValidator(ppmxField, true);
        if (!options) {
            if (ppmxField.type === FieldType.Integer) {
                const number = Number(ppmxValue);
                if (Number.isNaN(number) || !Number.isInteger(number)) {
                    return { isValid: false, errorMessage: "Unable to convert PPM Express value to Integer" };
                }

                return { isValid: validator.isValid(number), errorMessage: validator.getErrorMessage(number) };
            }
            if (ppmxField.type === FieldType.Decimal) {
                const number = Number(ppmxValue);
                if (Number.isNaN(number)) {
                    return { isValid: false, errorMessage: "Unable to convert PPM Express value to Decimal" };
                }

                return { isValid: validator.isValid(number), errorMessage: validator.getErrorMessage(number) };
            }
        }
        return { isValid: true, errorMessage: "" };
    }

    private _onOk = () => {
        const { mappingType, ppmxField } = this.props;
        const ppmxPropName = mappingType === MappingType.ToPpmx ? nameof<Row>("target") : nameof<Row>("source");

        const transformation = this.state.rows.reduce((ac: any, row: Row) => {
            if (!FieldsService.isDropDown(ppmxField) && !FieldsService.isSlider(ppmxField, true)) {
                if (ppmxField.type === FieldType.Integer) {
                    row[ppmxPropName] = Number.parseInt(row[ppmxPropName]);
                } else if (ppmxField.type === FieldType.Decimal) {
                    row[ppmxPropName] = Number.parseFloat(row[ppmxPropName]);
                }

            }

            return row.source !== undefined && row.target !== undefined ? { ...ac, [row.source]: row.target } : ac;
        }, undefined);

        this.props.onOk(JSON.stringify(transformation));
    };
}
