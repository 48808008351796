import "./ProjectsImportPanel.css";
import * as React from 'react';
import { connect } from "react-redux";
import { Panel, PanelType, Icon, DefaultButton, PrimaryButton, ProgressIndicator, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import ProjectImportGrid from './ProjectImportGrid';
import { IImportProjectState, actionCreators } from "../../../store/integration/ImportStore";
import { ApplicationState } from "../../../store/index";
import { IGridBaseProps, IImportEntityMap } from "./common";
import { SourceType, SourceTypeMap } from '../../../store/ExternalEpmConnectStore';
import HierarchyImportGrid, { HierarchyImportGridProps } from './HierarchyImportGrid'
import { IConnectionInfo } from '../../../entities/Metadata';
import Link from "../../common/Link";

type State = {
    selected: IImportEntityMap[],
    connection?: IConnectionInfo
}
export type ProjectsImportProps = {
    connectionId: string;
    isLoading: boolean;
    isProcessing?: boolean;
    error: string | null;
    label: string;

    onImport: (connectionId: string, maps: IImportEntityMap[]) => void;
    warning?: string;

    newImportGridProps?: HierarchyImportGridProps;
    oldImportGridProps?: IGridBaseProps
}
export type OwnProps = {
    onBack: () => void;
    width: string;
    system: SourceType;
} & ProjectsImportProps;

type StateProps = {
    maps: IImportProjectState[];
    total: number;
    processed: number;
    isImporting: boolean;
    connections: IConnectionInfo[]
}
type ActionProps = typeof actionCreators;
type Props = StateProps & OwnProps & ActionProps;

class ProjectsImportPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { selected: [], connection: props.connections.find(_ => _.id === props.connectionId) };
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.isImporting && this.props.isImporting !== prevProps.isImporting) {
            this.props.clearProjectsImportInfo();
        }
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.connectionId !== state.connection?.id) {
            return { ...state, connection: props.connections.find(_ => _.id === props.connectionId) };
        }

        return null;
    }

    public render() {
        return <Panel
            className="import import-panel"
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth={this.props.width}
            onRenderHeader={this._onRenderHeader}
            onRenderFooterContent={this._onRenderFooter}
            onDismiss={this.props.onBack}
            isOpen={true}>
            {
                this.props.warning && <MessageBar messageBarType={MessageBarType.warning}>{this.props.warning}</MessageBar>
            }
            {
                !this.props.error && this.props.oldImportGridProps &&
                <div className="import-grid-header">
                    {this._renderStatusLine()}
                </div>
            }
            {this.props.newImportGridProps &&
                <HierarchyImportGrid
                    {...this.props.newImportGridProps}
                    error={this.props.error}
                    connectionId={this.props.connectionId}
                    isLoading={this.props.isLoading}
                    isProcessing={this.props.isProcessing}
                />}
            {this.props.oldImportGridProps &&
                <ProjectImportGrid
                    {...this.props.oldImportGridProps}
                    error={this.props.error}
                    connectionId={this.props.connectionId}
                    system={this.props.system}
                    isLoading={this.props.isLoading}
                    isProcessing={this.props.isProcessing}
                    onSelectionChanged={_ => this.setState({ selected: _ })}
                />}
        </Panel>;
    }

    private _onRenderHeader = (): JSX.Element | null => {
        const { system, label } = this.props;
        const { connection } = this.state;
        const articleUrl = system === SourceType.VSTS
            ? "https://help.ppm.express/89488-azure-devops-connection/538917-how-to-link-ppm-express-project-to-azure-devops-team-epic-iteration-or-and-area#section-1"
            : "https://help.ppm.express/94162-portfolio-and-project-management/importing-a-project";
        const description = system === SourceType.VSTS
            ? "Import projects and work items as PPM Express Programs/Projects hierarchy. "
            : "";
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Import {label} from {SourceTypeMap[system]}</p>
            {connection && <div className="align-center">
                <div className="connection-icon">
                    <Icon iconName="PPMXLinkProject" />
                </div>
                <div className="connection-label">Connection: </div>
                <div className="overflow-text" title={connection.name}>{connection.name}</div>
            </div>}
            <div className="sub-text">{description}Please refer to <Link target="_blank" href={articleUrl}>the article</Link> for more details</div>
        </div>;
    }

    private _onRenderFooter = () => {
        const { newImportGridProps, onBack } = this.props;
        if (newImportGridProps) {
            return <div className="commands">
                <PrimaryButton text="Import" onClick={newImportGridProps.onImport} disabled={!newImportGridProps.isImportEnabled} />
                <DefaultButton text="Cancel" onClick={onBack} />
            </div>
        }

        return null;
    }

    private _renderStatusLine() {
        if (this.state.selected.length == 0) {
            return <span>{this.props.maps.length} total projects found</span>;
        }

        const { isImporting, processed, total } = this.props;
        if (isImporting) {
            const inImport = this.props.maps.find(_ => _.isInProcessing);
            return <div className="progress">
                <div className="import-description">
                    <span>Import {inImport ? `“${inImport.project.name}”` : ""}</span>
                    <span>{`${processed}/${total} projects`}</span>
                </div>
                {
                    total > 0 && <ProgressIndicator
                        key="progress"
                        percentComplete={processed / total} />
                }
            </div>;
        }

        return <div className="buttons-wrapper highlighted">
            <span>{this.state.selected.length} projects selected</span>
            <DefaultButton onClick={this._import} iconProps={{ iconName: "PPMXImport" }} text="Import" />
        </div>;
    }

    private _import = () => {
        const { connectionId } = this.props;
        if (!connectionId) return;
        this.props.onImport(connectionId, this.state.selected);
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        maps: state.import.projects.data,
        total: state.import.projects.total,
        processed: state.import.projects.processed,
        isImporting: state.import.projects.isImporting,
        connections: ownProps?.system === SourceType.VSTS
            ? state.vsts.connections.data
            : ownProps?.system === SourceType.Spo
                ? state.spo.connections.data
                : ownProps?.system === SourceType.O365Planner
                    ? state.office365.connections.data
                    : ownProps?.system === SourceType.Jira
                        ? state.jira.connections.data
                        : ownProps?.system === SourceType.MondayCom
                            ? state.mondayCom.connections.data
                            : ownProps?.system === SourceType.Smartsheet
                                ? state.smartsheet.connections.data
                                : ownProps?.system === SourceType.P4W
                                    ? state.p4w.connections.data
                                    : []
    }
}

export default connect(mapStateToProps, actionCreators)(ProjectsImportPanel);