import * as React from "react";
import "./UpgradeTrial.css";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Icon } from "office-ui-fabric-react";
import { ApplicationState } from "../../../store/index";
import { UserState } from "../../../store/User";
import { contains, CommonOperations } from "../../../store/permissions";
import { Subscription } from "../../../store/Tenant";
import { remainingDaysToString, remainDays } from "../../common/timeline/utils";

type StateProps = {
    subscription: Subscription;
    user: UserState;
};

type Props =  StateProps;

const TRIAL_MESSAGE_DAYS = 14;

const UpgradeTrial = ({ user, subscription }: Props) => {
    const showFull = user.preferences.expandSideBar;
    const hasBillingManage = user.permissions && contains(user.permissions.common, CommonOperations.BillingManage);
    const canUpgradeTrial = subscription?.isTrial && hasBillingManage;
    const showRemainingDays = subscription?.isTrial && remainDays(subscription.endDate)! <= TRIAL_MESSAGE_DAYS;
    const remainingDaysString = remainingDaysToString(subscription.endDate, subscription?.isTrial);

    if (!subscription.isTrial || !hasBillingManage) {
        return null;
    }

    const modeActionProps: ModeActionProps = {
        canUpgradeTrial,
        showRemainingDays,
        remainingDaysString,
    };

    if (showFull) {
        return <FullUpgradeTrialAction {...modeActionProps} />;
    }

    return <SmallUpgradeTrialAction {...modeActionProps} />;
};

type ModeActionProps = {
    canUpgradeTrial: boolean;
    showRemainingDays: boolean;
    remainingDaysString: string;
};

const FullUpgradeTrialAction = (props: ModeActionProps) => {
    const { remainingDaysString, canUpgradeTrial, showRemainingDays } = props;
    
    return (
        <BillingNavLink className="full-upgrade-trial" canUpgradeTrial={canUpgradeTrial}>
            <Icon iconName="PPMXUpgradeTrial" />
            <div className="upgrade-text">
                <span className="upgrade-title">{getTrialText(canUpgradeTrial)}</span>
                {showRemainingDays && <span className="days-left">{remainingDaysString}</span>}
            </div>
        </BillingNavLink>
    );
};

const SmallUpgradeTrialAction = (props: ModeActionProps) => {
    const { canUpgradeTrial } = props;

    return (
        <BillingNavLink className="small-upgrade-trial" canUpgradeTrial={canUpgradeTrial}>
            <Icon iconName="PPMXUpgradeTrial" />
            <span className="upgrade-title">{getTrialText(canUpgradeTrial)}</span>
        </BillingNavLink>
    );
};

type BillingNavLinkProps = {
    canUpgradeTrial: boolean;
    className?: string;
};

const BillingNavLink = (props: React.PropsWithChildren<BillingNavLinkProps>) => {
    const { canUpgradeTrial, children } = props;
    const title = getTrialText(canUpgradeTrial);
    const className = `upgrade-trial-link ${props.className}`;

    if (!canUpgradeTrial) {
        return (
            <span title={title} className={`readonly ${className}`}>
                {children}
            </span>
        );
    }

    return (
        <NavLink to="/billing" title={title} className={className}>
            {children}
        </NavLink>
    );
};

const getTrialText = (canUpgradeTrial: boolean) => canUpgradeTrial ? "Upgrade Trial" : "Trial";

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
    };
}

export default connect(mapStateToProps)(UpgradeTrial);
