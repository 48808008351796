import './EntityViewMenu.css';
import * as React from 'react';
import { ContextualMenuItemType, IButtonProps, IContextualMenuItem, Icon } from 'office-ui-fabric-react';
import { LayoutsState } from '../../../store/layouts';
import { DEFAULT_ID, IUpdateLayoutInfo, IUpdateSectionInfo, Layout, NEW_ID, Section } from '../../../entities/Metadata';
import RemoveDialog from '../RemoveDialog';
import { EditLayoutPanel, EditType } from '../EditLayoutPanel';
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { notUndefined, reorder, sortByName } from '../../utils/common';
import { LayoutService } from '../../utils/LayoutService';
import { Dictionary, EntityType } from '../../../entities/common';
import { SelectableItem } from '../ListMenuItemSelector';
import { CommandBarWithDnd, ICommandBarWithDndItem, DraggableCommandBarButton } from '../CommandBarWithDnd';

export type EntityViewMenuProps = {
    user: UserState;
    layoutsState: LayoutsState;
    entityLayout: Layout | undefined;
    entityType: EntityType;
    entityIsEditable?: boolean;
    readonlyMode?: boolean;
    disableManageConfiguration?: boolean;
    pinnedViews: string[];
    viewLayout?: (layout: Layout) => void;
    saveLayout?: (layoutId: string | undefined, sections: Section[], update: IUpdateLayoutInfo, callback?: (layoutId: string) => void) => void;
    saveEntityLayout?: (sectionUpdates: Dictionary<IUpdateSectionInfo>) => void;
    deleteLayout?: (layout: Layout) => void;
    savePinnedViews?: (pinnedViews: string[]) => void;
};

export const EntityViewMenu = (props: EntityViewMenuProps) => !props.viewLayout
    ? <ViewTabControl layout={props.layoutsState.activeEntity ?? props.entityLayout!} isSelected={true} />
    : <EditableEntityViewMenu {...props} />;


const EditableEntityViewMenu = (props: EntityViewMenuProps) => {
    const [layoutToEditPanel, setLayoutToEditPanel] = React.useState<{ layout: Layout; editType: EditType; }>();
    const setLayoutToCreate = React.useCallback((layout: Layout) => setLayoutToEditPanel({ layout, editType: EditType.Create }), []);
    const setLayoutToRename = React.useCallback((layout: Layout) => setLayoutToEditPanel({ layout, editType: EditType.Rename }), []);
    const setLayoutToConfigure = React.useCallback((layout: Layout) => setLayoutToEditPanel({ layout, editType: EditType.Configure }), []);
    const [layoutToDelete, setLayoutToDelete] = React.useState<Layout>();

    const { entityLayout, pinnedViews, entityIsEditable, readonlyMode } = props;
    const activeLayout = props.layoutsState.activeEntity ?? entityLayout ?? LayoutService.getDefault(props.layoutsState);

    const canManageConfiguration = entityIsEditable && !props.disableManageConfiguration && contains(props.user.permissions.common, CommonOperations.ConfigurationManage);
    const canViewConfiguration = contains(props.user.permissions.common, CommonOperations.ConfigurationView);

    const pinView = React.useCallback((layout: Layout) => {
        if (layout.isView) {
            if (pinnedViews.indexOf(layout.id) === -1) {
                props.savePinnedViews?.([...pinnedViews, layout.id]);
            } else {
                props.savePinnedViews?.(pinnedViews.filter(_ => _ !== layout.id));
            }
        }
    }, [props.savePinnedViews, pinnedViews]);

    const cloneLayout = React.useCallback((layout: Layout) => {
        const newLayout = {
            name: `Copy of ${layout.name}`,
            isView: layout.isView,
            isDefault: false,
        };
        props.saveLayout?.(undefined, activeLayout?.sections.map(_ => ({ ..._ })) ?? [], newLayout);
    }, [props.saveLayout, activeLayout]);

    const saveAsLayout = React.useCallback((layout: Layout) => {
        if (!layout.isView) {
            return;
        }

        const newLayout = {
            name: layout.name,
            isView: false,
            isDefault: false,
        };
        props.saveLayout?.(undefined, activeLayout?.sections.map(_ => ({ ..._ })) ?? [], newLayout);
    }, [props.saveLayout, activeLayout]);

    const layoutActions = React.useMemo(() => {
        const buildNewLayout = (isView: boolean): Layout => ({
            id: NEW_ID,
            isView,
            isDefault: false,
            name: '',
            sections: activeLayout?.sections.map(_ => ({ ..._ })) ?? []
        });

        return [
            { key: 'splitter0', itemType: ContextualMenuItemType.Divider },
            {
                key: 'new-view',
                text: 'Add New View',
                iconProps: { iconName: 'Add' },
                onClick: () => setLayoutToCreate(buildNewLayout(true))
            },
            {
                key: 'new-layout',
                text: 'Add New Layout',
                iconProps: { iconName: 'Add' },
                onClick: () => setLayoutToCreate(buildNewLayout(false))
            }
        ];
    }, [activeLayout]);

    function buildItem(layout: Layout): ICommandBarWithDndItem {
        const isEntityLayout = layout.id === entityLayout?.id;
        const isSelected = layout.id === activeLayout.id;
        const isPinned = pinnedViews.indexOf(layout.id) > -1;

        const onClick = props.viewLayout ? () => props.viewLayout!(layout) : undefined;
        const onConfigureClick = isEntityLayout && props.saveEntityLayout
            || !isEntityLayout && canManageConfiguration && !layout.readonly ? () => setLayoutToConfigure(layout) : undefined;
        const onRenameClick = !isEntityLayout && canManageConfiguration && !layout.readonly ? () => setLayoutToRename(layout) : undefined;
        const onDeleteClick = !isEntityLayout && canManageConfiguration && !layout.isDefault && !layout.readonly ? () => setLayoutToDelete(layout) : undefined;
        const onPinClick = !isEntityLayout && props.savePinnedViews && layout.isView ? () => pinView(layout) : undefined;
        const onSaveAsLayoutClick = !isEntityLayout && canManageConfiguration && layout.isView ? () => saveAsLayout(layout) : undefined;
        const onCloneClick = !isEntityLayout && canManageConfiguration ? () => cloneLayout(layout) : undefined;
        return {
            layout: layout,
            key: layout.id,
            text: layout.name,
            onClick: onClick,
            onRender: (itemProps, dismissMenu) => itemProps.hasOwnProperty("aria-posinset")
                ? <SelectableItem {...itemProps}
                    dismissMenu={dismissMenu}>{itemProps.isPinned ? <Icon className="icon-pinned-view" iconName="PinnedSolid"></Icon> : undefined}</SelectableItem>
                : <ViewTabControl {...itemProps} layout={layout} onRenameClick={onRenameClick} onConfigureClick={onConfigureClick} onDeleteClick={onDeleteClick}
                    onPinClick={onPinClick} onCloneClick={onCloneClick} onSaveAsLayoutClick={onSaveAsLayoutClick} />,
            isSelected,
            isPinned,
            dndDisabled: !layout.isView
        }
    }

    const { views, unpinnedViews, layouts } = React.useMemo(() => splitLayouts(props.layoutsState, pinnedViews), [props.layoutsState, pinnedViews]);

    const fixedItems = React.useMemo(() => entityLayout ? [entityLayout].map(buildItem) : [], [entityLayout, activeLayout]);

    const overflowItems = React.useMemo(() => !readonlyMode && !entityIsEditable ? undefined : [
        { key: 'views-header', iconProps: { }, itemType: ContextualMenuItemType.Header, text: 'Views' },
        !unpinnedViews.length ? { key: 'views-empty-state', disabled: true, className: "views-empty-state", text: 'No views' } : undefined,
        ...unpinnedViews.map(buildItem),
        canViewConfiguration && layouts.length ? { key: 'layouts-header', itemType: ContextualMenuItemType.Header, text: 'Layouts' } : undefined,
        ...(canViewConfiguration ? layouts.map(buildItem) : []),
        ...(canManageConfiguration ? layoutActions : [])
    ].filter(notUndefined), [readonlyMode, entityIsEditable, activeLayout, unpinnedViews, layouts, layoutActions, canViewConfiguration, canManageConfiguration]);

    const draggableItems = React.useMemo(() => [
        ...views,
        pinnedViews.indexOf(activeLayout.id) === -1 && activeLayout !== entityLayout ? activeLayout : undefined,
    ].filter(notUndefined).map(buildItem), [entityLayout, activeLayout, views, pinnedViews]);

    const onReorder = React.useCallback((key: string, targetIndex: number) => {
        const sourceIndex = pinnedViews.indexOf(key);
        if (sourceIndex === -1) {
            const toInsert = [...pinnedViews];
            toInsert.splice(targetIndex, 0, key);
            props.savePinnedViews?.(toInsert);
        } else {
            props.savePinnedViews?.(reorder(
                pinnedViews,
                sourceIndex,
                targetIndex
            ));
        }
    }, [props.savePinnedViews, pinnedViews]);

    return <>
        <CommandBarWithDnd
            fixedItems={fixedItems}
            items={draggableItems}
            overflowItems={overflowItems}
            onReorder={onReorder}
            dndDisabled={!props.savePinnedViews}
        />

        {!!layoutToEditPanel && <EditLayoutPanel
            layoutsState={props.layoutsState}
            layout={layoutToEditPanel.layout}
            editType={layoutToEditPanel.editType}
            allowEditSectionDetails={canManageConfiguration}
            onSave={update => {
                if (layoutToEditPanel.layout.id === entityLayout?.id) {
                    props.saveEntityLayout!(update.sections!);
                }
                else {
                    props.saveLayout!(
                        layoutToEditPanel.layout.id === NEW_ID || layoutToEditPanel.layout.id === DEFAULT_ID ? undefined : layoutToEditPanel.layout.id,
                        activeLayout.sections,
                        update);
                }

            }}
            onDismiss={() => setLayoutToEditPanel(undefined)}
            entityType={props.entityType} />}
        {!!layoutToDelete && <RemoveDialog
            dialogContentProps={{
                title: `Delete ${layoutToDelete.isView ? 'View' : 'Layout'}`,
                subText: `Are you sure you want to delete ${layoutToDelete.isView ? 'view' : 'layout'} "${layoutToDelete.name}"? This action can't be undone.`
            }}
            confirmButtonProps={{ text: "Delete" }}
            onComplete={() => {
                props.deleteLayout!(layoutToDelete);
                const deletedViewIndex = pinnedViews.indexOf(layoutToDelete.id);
                if (deletedViewIndex > -1) {
                    pinnedViews.splice(deletedViewIndex, 1);
                }
            }}
            onClose={() => setLayoutToDelete(undefined)} />}
    </>;
}

function splitLayouts(layoutsState: LayoutsState, pinnedViewIds: string[]): { views: Layout[], unpinnedViews: Layout[], layouts: Layout[] } {
    const layouts: Layout[] = [];
    const pinnedViews: Layout[] = [];
    const unpinnedViews: Layout[] = [];
    for (const id of layoutsState.allIds) {
        if (layoutsState.byId[id].isView) {
            if (pinnedViewIds.indexOf(id) > -1) {
                pinnedViews.push(layoutsState.byId[id]);
            } else {
                unpinnedViews.push(layoutsState.byId[id]);
            }
        } else {
            layouts.push(layoutsState.byId[id]);
        }
    }
    pinnedViews.sort((a, b) => pinnedViewIds.indexOf(a.id) - pinnedViewIds.indexOf(b.id));
    unpinnedViews.sort(sortByName);
    layouts.sort(sortByName);
    return {
        views: pinnedViews,
        unpinnedViews,
        layouts
    };
}

type ViewTabControlProps = IButtonProps & {
    dragHandleProps?: any;
    isSelected?: any;
    isPinned?: any;
    layout: Layout;
    onRenameClick?: () => void;
    onConfigureClick?: () => void;
    onCloneClick?: () => void;
    onSaveAsLayoutClick?: () => void;
    onPinClick?: () => void;
    onDeleteClick?: () => void;
}

export const ViewTabControl = (props: ViewTabControlProps) => {
    const { isSelected, isPinned, layout, onRenameClick, onConfigureClick, onCloneClick, onPinClick, onSaveAsLayoutClick, onDeleteClick, dragHandleProps, ...otherProps } = props;

    const commands: IContextualMenuItem[] = [
        onRenameClick && {
            key: 'rename',
            iconProps: { iconName: 'Rename' },
            text: "Rename",
            onClick: onRenameClick
        },
        onConfigureClick && {
            key: 'configure',
            iconProps: { iconName: 'Settings' },
            text: layout.isView ? "Configure Sections" : "Configure",
            onClick: onConfigureClick
        },
        onCloneClick && {
            key: 'clone',
            iconProps: { iconName: 'Copy' },
            text: "Clone",
            onClick: onCloneClick
        },
        ...(onPinClick ? [
            { key: 'splitter1', itemType: ContextualMenuItemType.Divider },
            {
                key: 'delete',
                iconProps: { iconName: !isPinned ? "Pinned" : "Unpin" },
                text: !isPinned ? "Pin" : "Unpin",
                onClick: onPinClick
            }] : []),
        ...(onSaveAsLayoutClick ? [
            { key: 'splitter2', itemType: ContextualMenuItemType.Divider },
            {
                key: 'saveAsLayout',
                iconProps: { iconName: "ActivateOrders" },
                text: "Save as Layout",
                onClick: onSaveAsLayoutClick
            }] : []),
        ...(onDeleteClick ? [
            { key: 'splitter3', itemType: ContextualMenuItemType.Divider },
            {
                key: 'delete',
                style: { color: 'red' },
                iconProps: { iconName: 'Delete', style: { color: 'red' } },
                text: "Delete",
                onClick: onDeleteClick
            }] : [])
    ].filter(notUndefined);
    const text = !layout.isView ? `${layout.name} [LAYOUT]` : layout.name;
    return <DraggableCommandBarButton
        {...otherProps}
        dragHandleProps={dragHandleProps}
        isSelected={isSelected}
        text={text}
        commands={commands}
    />
}
