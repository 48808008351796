import "./OrganizationAuthenticationSettings.css";
import * as React from 'react';
import { Link } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { hasLinkedOrganization } from "./OrganizationAuthenticationSettings";

type StateProps = {
    isLinkedToOrganization: boolean;
}
type Props = StateProps;

const OrganizationAuthenticationSettingsTooltip = (props: Props) => <div>
    Define the access settings for your PPM Express tenant.
    {!props.isLinkedToOrganization && <><br />If you wish to use the auto-registration option in your tenant, set up Office 365 as the default authentication option.</>}
    <br />
    Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/522610" target="_blank">article</Link> for more details.
</div>;

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isLinkedToOrganization: hasLinkedOrganization(state.tenant)
    };
}

export default connect(mapStateToProps)(OrganizationAuthenticationSettingsTooltip);