import * as React from "react";
import { IConnectionInfo } from "../../entities/Metadata";
import RemoveDialog from "../common/RemoveDialog";
import { SourceType, SourceType_ } from "../../store/ExternalEpmConnectStore";
import { MessageBar, MessageBarType, TextField } from 'office-ui-fabric-react';
import { Validator } from '../../validation';

type Props = {
    sourceType: SourceType;
    connection: IConnectionInfo;
    onClose: () => void;
    onRemove: (connectionId: string) => void;
}
const DELETE_PASSPHRASE = 'DELETE';
const validators = {
    deletePassphrase: Validator.new().required().equals(DELETE_PASSPHRASE).build(),
    deleteReason: Validator.new().required().build()
}

const ConnectionRemoveDialog = (props: Props) => {
    const isO365 = SourceType_.isO365(props.sourceType);
    const [deletePassphrase, setDeletePassphrase] = React.useState('');
    return <RemoveDialog
        onClose={props.onClose}
        onComplete={() => props.onRemove(props.connection.id)}
        dialogContentProps={{
            title: "Delete connection",
            subText: `Are you sure you want to delete connection "${props.connection.title}"?`
        }}
        confirmButtonProps={{ text: "Delete", disabled: !validators.deletePassphrase.isValid(deletePassphrase) }}>
        <MessageBar className='with-top-margin' messageBarType={MessageBarType.warning}>
            {
                isO365 &&
                <div>
                    If you delete the connection ALL projects and resources that used the selected connection will be unlinked.
                    All related data will also be deleted (iterations, risks, issues, tasks, etc. imported from the linked connection).
                    <br />
                    <br />
                    Office 365 and Planner connections include MS Teams, Office 365 group, and Planner systems. All of them will be unlinked in case of connection deletion.
                </div>
            }
            {
                !isO365 &&
                <div>
                    If you delete the connection ALL projects and resources that used the selected connection will be unlinked.
                    All related data will also be deleted (iterations, risks, issues, tasks, etc. imported from the linked connection).
                </div>
            }
        </MessageBar>
        <TextField
            className='with-top-margin'
            label={`Please type “${DELETE_PASSPHRASE}” to confirm`}
            onGetErrorMessage={validators.deletePassphrase.getErrorMessage}
            deferredValidationTime={1}
            value={deletePassphrase}
            onChange={(e, _) => setDeletePassphrase(_ || '')}
        />
    </RemoveDialog>;
}
export default ConnectionRemoveDialog;