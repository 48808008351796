import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { JiraConnectionInfo, actionCreators } from "../../../store/integration/JiraStore";
import { ApplicationState } from "../../../store/index";
import { IConnectionsState } from "../../../store/integration/common";
import ConnectionSelect, { ConnectionVerifier } from "../ConnectionSelect";
import JiraConnectionEdit from './JiraConnectionEdit';
import { SourceType } from '../../../store/ExternalEpmConnectStore';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onChange: (connectionId?: string) => void;
};

type StateProps = { connections: IConnectionsState<JiraConnectionInfo>; };
type Props = StateProps & typeof actionCreators & OwnProps;

const HelpUrl = 'https://help.ppm.express/89253-ppm-express-permission-prerequisites/ppm-express-permissions-prerequisites?_ga=2.123273931.1505724952.1710691687-27179004.1707729713#section-4';

const newConnectionTooltip = <span>Allows to create a connection to Jira Cloud or Jira Server. Jira account used as a connection should be a member of the default Jira Administrators group.
    &nbsp;<Link href={HelpUrl} target="_blank">
        Learn more
    </Link>
</span>;

const newRestrictedConnectionTooltip = <span>Allows to create a connection to Jira Cloud or Jira Server with limited access to Issue type schemes. Does not require a Jira account to be added to the Jira Administrators group.
    &nbsp;<Link href={HelpUrl} target="_blank">
        Learn more
    </Link>
</span>;

const JiraConnectionSelect = (props: Props) => {
    React.useEffect(() => {
        props.loadConnections();
    }, []);

    const [isAdd, setIsAdd] = React.useState(false);
    const [isRestricted, setIsRestricted] = React.useState(false);
    const [refreshId, setRefreshId] = React.useState<string | undefined>();
    const { connectionId, disabled, connections, onChange, verifyConnection, removeConnection, renameConnection } = props;
    const onNewRestrictedConnectionClick = React.useCallback(() => {
        setIsAdd(true);
        setIsRestricted(true);
    }, []);
    return <>
        <ConnectionVerifier
            connections={connections}
            connectionId={connectionId}
            verifyConnection={verifyConnection}>
            <ConnectionSelect
                key="connection-select"
                disabled={connections.isLoading || !!disabled}
                sourceType={SourceType.Jira}
                connectionId={connectionId}
                connections={connections.data}
                onConnectionChange={_ => {
                    if (_?.id) {
                        verifyConnection(_.id);
                    }
                    onChange(_?.id);
                }}
                onConnectionRemove={_ => removeConnection(_, onChange)}
                onConnectionRename={renameConnection}
                onRefreshClick={setRefreshId}
                onNewConnectionClick={() => setIsAdd(true)}
                newConnectionTooltip={newConnectionTooltip}
                onNewRestrictedConnectionClick={onNewRestrictedConnectionClick}
                newRestrictedConnectionTooltip={newRestrictedConnectionTooltip}
            />
        </ConnectionVerifier>
        {connections.isLoading && <Spinner key="spinner" size={SpinnerSize.small} className="connection-select-spinner" />}
        {isAdd &&
            <JiraConnectionEdit
                key="connection-adding"
                isRestricted={isRestricted}
                onCreated={onChange}
                onDismiss={() => setIsAdd(false)} />}
        {refreshId &&
            <JiraConnectionEdit
                key="connection-refreshing"
                connectionId={refreshId}
                onDismiss={() => setRefreshId(undefined)} />}
    </>
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.jira.connections };
}

export default connect(mapStateToProps, actionCreators)(JiraConnectionSelect);