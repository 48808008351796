import React from "react";
import { ConnectionField } from "../../../ConnectionField";
import { ComboBox, IComboBoxProps, Spinner, SpinnerSize } from "office-ui-fabric-react";

type Props = {
    isLinked: boolean;
    fieldLabel: string | JSX.Element;
    value: string | undefined;
    isLoading: boolean;
}

const PlanGroupSelection = (props: Props & IComboBoxProps) => {
    const { isLinked, fieldLabel, value, isLoading } = props;
    return <>
        <ConnectionField
            isLinked={isLinked}
            label={fieldLabel}
            value={value}>
            <ComboBox {...props}
                useComboBoxAsMenuWidth={props.useComboBoxAsMenuWidth ?? true}
                allowFreeform={props.allowFreeform ?? true}/>
        </ConnectionField>
        {isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
    </>
}

export default PlanGroupSelection;