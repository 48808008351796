import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EntityType, Dictionary, StatusCategory } from '../../entities/common';
import { CreatePanel } from '../common/SubentityPanel';
import { ApplicationState } from '../../store';
import * as Metadata from '../../entities/Metadata';
import { namesof, nameof } from '../../store/services/metadataService';
import { ISubentity } from '../../entities/Subentities';
import { validators } from '../field/Fields';
import { rendersBuilder } from '../objective/Fields';
import { HUNDRED_PCT, notUndefined } from '../utils/common';
import { actionCreators, IObjectiveAttrs, Objective, ObjectiveCalculationType, OKRDirection, OKRValueType } from '../../store/ObjectivesListStore';
import { UserState } from '../../store/User';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

export interface ObjectiveSubentityWrapper extends ISubentity {
    id: string;
    attributes: IObjectiveAttrs & Dictionary<any>;
}

interface OwnProps {
    onDismiss: () => void;
    parent?: Objective;
}

type StoreProps = {
    fields: Metadata.Field[];
    user: UserState;
};

type Props = {
    objectivesActions: typeof actionCreators
} & StoreProps & OwnProps;

const ObjectiveCreation = (props: Props) => {
    const objectiveStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Objective, props.fields)!;

    return <CreatePanel<ObjectiveSubentityWrapper>
        subentityTypeLabel="Objective"
        subentityType={EntityType.Objective}
        displayFields={namesof<IObjectiveAttrs>(['Name', 'Description', 'Scope', 'StartDate', 'FinishDate',
            'CalculationType', 'ValueType', 'Direction', 'StartValue', 'TargetValue', 'CurrentValue'])}
        fields={props.fields}
        customFieldValidatorBuilder={validators}
        uiControlElementsCustomRender={rendersBuilder()}
        buildNewEntity={() => {
            const valueType = props.parent ? props.parent.attributes.ValueType : OKRValueType.Number;
            return {
                attributes: {
                    CalculationType: props.parent ? props.parent.attributes.CalculationType : ObjectiveCalculationType.Manual,
                    ValueType: valueType,
                    StartValue: 0,
                    CurrentValue: 0,
                    Direction: props.parent ? props.parent.attributes.Direction : OKRDirection.Growth,
                    TargetValue: valueType === OKRValueType.Percent ? HUNDRED_PCT : valueType === OKRValueType.Flag ? 1 : 0,
                    Scope: props.parent ? props.parent.attributes.Scope : props.fields.find(_ => _.name == nameof<IObjectiveAttrs>("Scope"))?.defaultValue,
                    StartDate: props.parent ? props.parent.attributes.StartDate : undefined,
                    FinishDate: props.parent ? props.parent.attributes.FinishDate : undefined,
                    Manager: [{ id: props.user.id, fullName: props.user.name, imageId: props.user.imageId }],
                    Progress: 0,
                    Status: objectiveStatusDescriptor.getCategoryDefaultStatusValue(StatusCategory.NA)
                }
            } as ObjectiveSubentityWrapper;
        }}
        readOnlyFields={getReadOnlyFields}
        onComplete={(entity: ObjectiveSubentityWrapper) => props.objectivesActions.createObjective({ attributes: entity.attributes }, props.parent?.id)}
        onDismiss={props.onDismiss}
    />;
}

const getReadOnlyFields = ({ attributes }: ObjectiveSubentityWrapper) => {
    return [
        attributes.CalculationType === ObjectiveCalculationType.Manual ? undefined : nameof<IObjectiveAttrs>("Progress"),
        attributes.CalculationType === ObjectiveCalculationType.KR_Rollup ? nameof<IObjectiveAttrs>("CurrentValue") : undefined,
        attributes.ValueType === OKRValueType.Flag ? nameof<IObjectiveAttrs>("StartValue") : undefined,
        attributes.ValueType === OKRValueType.Flag ? nameof<IObjectiveAttrs>("TargetValue") : undefined,
    ].filter(notUndefined);
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Objective];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]).filter(_ => !_.isReadonly && _.name != nameof<IObjectiveAttrs>("Parent")),
        user: state.user
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        objectivesActions: bindActionCreators(actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ObjectiveCreation);