import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as PortfoliosListStore from '../../../store/PortfoliosListStore';
import { ActiveFilter as PortfolioActiveFilter } from '../../../store/portfolio/filters';
import * as PortfoliosUtils from "../../../store/portfolio/utils";
import { IEntityCardProps } from '../../common/interfaces/IEntity';
import Logo from "../../common/Logo";
import { formatFieldValue, toPercentString } from "../../utils/common";
import { StatusCategory, Dictionary, EntityType, IUserInfo } from "../../../entities/common";
import * as Metadata from "../../../entities/Metadata";
import CardMetric, { CardMetricConfig } from "./CardMetric";
import { ActiveFilter as ProjectActiveFilter } from '../../../store/project/filters';
import { ActiveFilter as ProgramActiveFilter } from '../../../store/program/filters';
import EntityWarning from "../../common/warnings/EntityWarning";
import { nameof } from '../../../store/services/metadataService';
import ResourceFormatter from '../../common/formatters/ResourceFormatter';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/User';
import CardCalculationStatuses from './CardCalculationStatuses';
import { CostsKPI, ProjectsWorkKPI } from './KPICard';
import CategoryColorStatusView from '../list/columns/CategoryColorStatusView';
import * as StatusDescriptorFactory from '../../../entities/StatusDescriptorFactory';

export type PortfolioCardState = {};
type OwnProps = {
    showWork?: boolean;
    showCosts?: boolean;
    onMenuRender: (entity: PortfoliosListStore.Portfolio) => JSX.Element | null;
    fields: Dictionary<Metadata.Field>;
} & IEntityCardProps<PortfoliosListStore.Portfolio, PortfolioCardState>;

type StateProps = {
    user: UserState;
    programFields: Metadata.Field[];
    projectFields: Metadata.Field[];
};

type PortfolioCardProps = OwnProps & StateProps & RouteComponentProps<{}>;

const PortfolioCard = (props: PortfolioCardProps) => {
    const { entity, showCosts, showWork, fields } = props;
    const attrs = entity.attributes;
    const stage = attrs.Stage;
    const overallStatusField = fields[nameof<PortfoliosListStore.PortfolioAttrs>("OverallStatus")];
    const overallStatusDescriptor = StatusDescriptorFactory.createStatusDescriptor(overallStatusField);
    const statusOption = overallStatusDescriptor.getOptionOrDefault(attrs.OverallStatus, StatusCategory.NA);

    const renderMetrics = (): JSX.Element[] => {
        const stageConfig = PortfoliosListStore.portfolioStagesMap[entity.attributes.Stage];
        const { completed, total } = PortfoliosUtils.getProjectsMetrics(entity.calculation);
        let progressValue: string | undefined;
        if (total) {
            progressValue = `${completed}/${total}`;
        }

        const metrics: { config?: CardMetricConfig, key: string, renderItem?: () => JSX.Element | null, className?: string }[] = [
            {
                config: {
                    title: "Budget",
                    value: formatFieldValue(entity.attributes.Budget, Metadata.FormatType.Cost, props.user)
                },
                key: "budget"
            },
            {
                config: {
                    title: "Progress",
                    value: progressValue || '-'
                },
                key: "progress"
            },
            {
                config: {
                    title: "Stage",
                    value: stageConfig.title,
                    valueClassName: `colorfull ${stageConfig.cssClassName}`
                },
                key: "stage"
            },
            {
                config: {
                    title: "ID",
                    value: entity.attributes.Identifier
                },
                key: "id"
            }
        ];

        if (props.showCosts) {
            const { actual, plan } = entity.calculation.costs;
            metrics.splice(0,
                0,
                {
                    config: {
                        title: "Cost Completed",               
                        value: plan ? toPercentString(actual / plan) : "N/A" 
                    },
                    key: "cost-completed"
                });
        } else {
            const ratio = completed / total;
            metrics.splice(0,
                0,
                {
                    config: {
                        title: "Work Completed",
                        value: toPercentString(ratio)
                    },
                    key: "work-completed"
                });
        }
        return metrics.map(_ => <CardMetric config={_.config} renderItem={_.renderItem} className={_.className} key={_.key} />);
    }

    const goToProjects = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        props.history.push(`/projects`, new ProjectActiveFilter("Custom").withPortfolio(props.entity.id).buildLocationState());
    }

    const goToPrograms = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        props.history.push(`/programs`, new ProgramActiveFilter("Custom").withPortfolio(props.entity.id).build());
    }

    const goToPortfolios = (e: any, resource: IUserInfo) => {
        e.stopPropagation();
        e.preventDefault();
        props.history.push(`/portfolios`, new PortfolioActiveFilter("Custom").withManager(resource).build());
    }

    return (
        <div className="card flex-card pf-card" onClick={(e) => {
            e.ctrlKey ? window.open(`/portfolio/${entity.id}`, '_blank') : props.history.push(`/portfolio/${entity.id}`);
        }}>
            <div className={"content" + ([PortfoliosListStore.PortfolioStage.Closed, PortfoliosListStore.PortfolioStage.Inactive].indexOf(stage) !== -1 ? " closed" : "")}>
                <div className="first-row">
                    <div style={{ "float": "left" }}>
                        <Logo imageId={entity.imageId} className="pf-logo" />
                    </div>
                    <div className="info">
                        <div className="title overflow-text" title={attrs.Name}>{attrs.Name}</div>
                        <div className="align-center">
                            <CategoryColorStatusView
                                statusOption={statusOption}
                                trend={entity.insights.statuses[nameof<PortfoliosListStore.PortfolioAttrs>("OverallStatus")]?.trend}
                                title={overallStatusField?.label} />
                        </div>
                    </div>
                </div>

                <div className="metrics">
                    {
                        renderMetrics()
                    }
                </div>
                <div className="separator"></div>

                {showCosts && <CostsKPI costs={entity.calculation.costs} />}
                {showWork && <ProjectsWorkKPI {...PortfoliosUtils.getProjectsMetrics(entity.calculation)} />}

                <div className="row">
                    <div className="align-center link" onClick={goToPrograms}>
                        <Icon iconName="PPMXProgram" className="card-icon" />
                        <span className="title">Programs</span>
                    </div>
                    {Object.keys(entity.calculation.programStatuses).length === 0
                        ? <div className="align-center">
                            <span>(No programs included yet)</span>
                        </div>
                        : <div className="align-center">
                            <CardCalculationStatuses
                                statuses={entity.calculation.programStatuses}
                                goTo={(category: StatusCategory) => {
                                    props.history.push(`/programs`, new ProgramActiveFilter("Custom")
                                        .withPortfolio(entity.id).withStatusesOfCategory(category, props.projectFields).build());
                                }}
                            />
                        </div>}
                </div>

                <div className="row">
                    <div className="align-center link" onClick={goToProjects}>
                        <Icon iconName="PPMXProject" className="card-icon" />
                        <span className="title">Projects</span>
                    </div>
                    {Object.keys(entity.calculation.projectStatuses).length === 0
                        ? <div className="align-center">
                            <span>(No projects included yet)</span>
                        </div>
                        : <div className="align-center">
                            <CardCalculationStatuses
                                statuses={entity.calculation.projectStatuses}
                                goTo={(category: StatusCategory) => {
                                    const state = new ProjectActiveFilter("Custom")
                                        .withPortfolio(entity.id).withStatusesOfCategory(category, props.projectFields).buildLocationState();
                                    props.history.push(`/projects`, state);
                                }} />
                        </div>}
                </div>

                <div className="row">
                    <div className="align-center">
                        <Icon iconName="PPMXCardManager" className="card-icon" />
                        <span className="title">Manager</span>
                    </div>
                    {
                        attrs.Manager && attrs.Manager.length > 0
                            ? <div className="align-center">
                                <ResourceFormatter resource={attrs.Manager} onClick={goToPortfolios} />
                            </div>
                            : <div className="align-center">
                                <span>(Manager is not assigned yet)</span>
                            </div>
                    }
                </div>
                <div className="separator"></div>
                <div className="actions">
                    {props.onMenuRender(entity)}
                    <EntityWarning
                        warnings={entity.warnings}
                        multipleItemsMessage="There are warnings related to this portfolio. Please review portfolio details for more information"
                    />
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): StateProps {
    const programFields = state.fields[EntityType.Program];
    const projectFields = state.fields[EntityType.Project];
    return {
        user: state.user,
        programFields: programFields.allIds.map(_ => programFields.byId[_]),
        projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps)(PortfolioCard));
