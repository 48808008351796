import * as React from "react";
import { HealthReportingSchedule } from "../../store/Tenant";
import { printDaysOfWeek } from "../common/timeline/utils";

type Props = {
    schedule: HealthReportingSchedule;
};

export default class ScheduleDisplay extends React.Component<Props> {

    public render() {
        const { schedule } = this.props;
        if (!schedule || schedule.times.length === 0 || schedule.days.length === 0) {
            return null;
        }

        return <div className="schedule-display">
            <span className="schedule-display-hours">{this._formatTime(schedule.times[0])}</span>
            <span className="schedule-display-days">{printDaysOfWeek(schedule.days)}</span>
        </div>
    }

    private _formatTime = (val: string): string => {
        const components = val.split(":");
        const date = new Date(new Date().setUTCHours(parseInt(components[0]), parseInt(components[1]), 0, 0));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours == 0 ? 12 : hours > 12 ? (hours - 12) : hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`
    }
}
