import * as React from 'react';
import { PrimaryButton, Overlay, DefaultButton, Label } from 'office-ui-fabric-react';
import { IFieldMapping, ExternalFieldInfo, MappingType, IFieldMappingKeyMap } from '../../../store/integration/common';
import { vstsFieldToPpmxFieldsMap } from '../../../store/integration/VSTSStore';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import MappingControl from '../MappingControl/MappingControl';
import Spinner from "../../common/Spinner";
import { FieldMappingService } from "../../common/DisplayFieldService";
import { Field } from "../../../entities/Metadata";
import { EntityType } from '../../../entities/common';

type OwnProps = {
    mapping: IFieldMapping[];
    ppmxfields: Field[];
    vstsFields: ExternalFieldInfo[],
    readOnly: boolean;
    error: string | null,
    isLoading: boolean,
    isProcessing: boolean;
    onDismiss: () => void;
    onSave: (mapping: IFieldMapping[]) => void,
}
type Props = OwnProps;
type State = {
    mapping: IFieldMappingKeyMap[];
}

const mappingTypes = [MappingType.ToPpmx];

export default class VSTSMappingControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this._buildState(props);
    }

    componentWillReceiveProps(nextProps: Props) {
        if ((this.props.isLoading && !nextProps.isLoading) || (this.props.isProcessing && !nextProps.isProcessing)) {
            this.setState(this._buildState(nextProps));
        }
    }

    public render() {
        const { isLoading, isProcessing, vstsFields, ppmxfields, readOnly, mapping, onDismiss } = this.props;

        if (isLoading) {
            return <Overlay><Spinner /></Overlay>;
        }

        if (readOnly) {
            return (
                <>
                    {this._renderMappingControl()}
                    <Overlay></Overlay>
                </>
            );
        }
        return (
            <div>
                <Label>Select fields for mapping in Project Details section</Label>
                {this._renderMappingControl()}
                <div className="commands with-top-margin">
                    <PrimaryButton text="Save"
                        disabled={!FieldMappingService.validateMapping(this.state.mapping, vstsFields, ppmxfields, vstsFieldToPpmxFieldsMap)
                            || FieldMappingService.isSame(mapping, this.state.mapping)}
                        onClick={this._saveMapping} />
                    <DefaultButton text={readOnly ? "Close" : "Cancel"} onClick={onDismiss} />
                </div>
                {isProcessing && <Overlay><Spinner/></Overlay>}
            </div>
        );
    }

    private _renderMappingControl = () => {
        const { isLoading, error, vstsFields, ppmxfields } = this.props;
        const { mapping } = this.state;
        return (
            <MappingControl
                isLoading={isLoading}
                error={error}
                connector={SourceType.VSTS}
                entityType={EntityType.Project}
                externalFields={vstsFields}
                typesMap={vstsFieldToPpmxFieldsMap}
                mapping={mapping}
                ppmxfields={ppmxfields}
                mappingTypes={mappingTypes}
                fieldPanelWidth="600px"
                onMappingUpdated={(mappingKeyMap: IFieldMappingKeyMap[]) => this.setState({ mapping: mappingKeyMap })}
                showTransformationScript/>
        );
    }

    private _saveMapping = () => {
        const { vstsFields, ppmxfields } = this.props;
        const mapping: IFieldMapping[] = [];
        if (FieldMappingService.validateMapping(this.state.mapping,
            vstsFields,
            ppmxfields,
            vstsFieldToPpmxFieldsMap,
            mapping)) {
            this.props.onSave(mapping);
        }
    }

    private _buildState(props: Props): State {
        return { mapping: FieldMappingService.buildIdMap(props.mapping, FieldMappingService.getDefaultMapping(mappingTypes)) };
    }
}