import * as React from 'react';

type Props = {};
type State = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error(error);
        console.log(errorInfo);
    }

    render() {
        return this.state.hasError
            ? <span>Something went wrong.</span>
            : this.props.children;
    }
}

function createLazyContainer<P>(loader: () => Promise<any>) {
    const Component = React.lazy(loader);
    return (p: P) => (
        <ErrorBoundary>
            <React.Suspense fallback={<div className='fallback'></div>}>
                <Component {...p} />
            </React.Suspense>
        </ErrorBoundary>
    );
}

export default createLazyContainer;