import * as React from 'react';
import { Field, FieldGroup } from '../../../entities/Metadata';
import { EntityType } from '../../../entities/common';
import Collapsible, { ChevronDirection } from '../../common/Collapsible';
import { groupByKey } from '../../utils/common';
import EmptyGroupMessage from './EmptyGroupMessage';
import { FieldsList } from './FieldsList';
import { IFieldActions } from '../FieldPanel';

const FIELD_GROUPS_ORDER: FieldGroup[] = [
    FieldGroup.SystemFields,
    FieldGroup.Details,
    FieldGroup.Timeline,
    FieldGroup.BudgetsAndBenefits,
    FieldGroup.Prioritization,
    FieldGroup.ResourcePlanning,
    FieldGroup.Statuses,
    FieldGroup.CustomFields,
    FieldGroup.ScheduleSummary,
    FieldGroup.PPMXTasks,
    FieldGroup.VSTS,
    FieldGroup.Jira,
    FieldGroup.O365Planner,
    FieldGroup.Spo,
    FieldGroup.MondayCom,
    FieldGroup.Smartsheet,
    FieldGroup.MPPFile,
    FieldGroup.P4W,
];

const MANDATORY_GROUPS: FieldGroup[] = [
    FieldGroup.CustomFields,
];

export const resultsNotFoundMessage = 'Results not found';

type DisabledGroupedFieldsProps = {
    fields: Field[];
    disabledFields: Field[];
    entityType: EntityType;
    newFieldNames: string[];
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    groupsExpanded: boolean;
    hideEmptyGroups: boolean;
    onChange?: (fields: Field[]) => void;
    onEmptyGroupMessageRender: (group: FieldGroup) => JSX.Element;
};

const GroupedDisabledFields = (props: DisabledGroupedFieldsProps) => {
    const { fields, disabledFields, groupsExpanded, hideEmptyGroups, entityType, newFieldNames, onChange, onEmptyGroupMessageRender } = props;

    const groups = React.useMemo(() => {
        const groupedFields = groupByKey(fields, 'group');
        const groupedDisabledFields = groupByKey(disabledFields, 'group');
        return FIELD_GROUPS_ORDER
            .map(_ => ({
                group: _,
                fields: groupedFields[_] ?? (MANDATORY_GROUPS.includes(_) ? [] : undefined),
                disabledFields: groupedDisabledFields[_] ?? [],
            }))
            .filter(_ => _.fields !== undefined && (!hideEmptyGroups || _.disabledFields.length > 0));
    }, [fields, disabledFields, hideEmptyGroups]);

    const groupsConfig = React.useMemo(() => buildGroupsConfigFor(entityType), [entityType]);

    if (groups.length === 0) {
        return <EmptyGroupMessage message={resultsNotFoundMessage} />;
    }
    
    return <div className="grouped-disabled-fields">
        {groups.map(({ group, disabledFields }) => {
            const groupConfig = groupsConfig[group];
            return (
                <Collapsible
                    key={group}
                    expanded={groupsExpanded}
                    iconName={groupConfig.groupIcon}
                    title={groupConfig.groupName}
                    collapsedChevronDirection={ChevronDirection.Up}
                >
                    {disabledFields.length > 0 ? <FieldsList
                        {...props}
                        selected={[]}
                        fields={disabledFields}
                        newFields={newFieldNames}
                        onChange={onChange}
                        nonDraggable
                        showShadowOnNonDraggableHover
                    />
                    : onEmptyGroupMessageRender(group)}
                </Collapsible>
            )
        })}
    </div>
}

const buildGroupsConfigFor = (entity: EntityType): Record<FieldGroup, { groupIcon: string; groupName: string; }> => ({
    [FieldGroup.SystemFields]: { groupIcon: 'System', groupName: 'System fields' },
    [FieldGroup.CustomFields]: { groupIcon: 'SingleColumnEdit', groupName: 'Custom fields' },
    [FieldGroup.Details]: { groupIcon: getDetailsGroupIcon(entity), groupName: 'Details' },
    [FieldGroup.Timeline]: { groupIcon: 'PPMXRoadmap', groupName: 'Timeline' },
    [FieldGroup.BudgetsAndBenefits]: { groupIcon: 'PPMXSectionBudget', groupName: 'Budgets & Benefits' },
    [FieldGroup.Prioritization]: { groupIcon: 'PPMXPrioritizationFieldsGroup', groupName: 'Prioritization' },
    [FieldGroup.ResourcePlanning]: { groupIcon: 'Contact', groupName: 'Resource Planning' },
    [FieldGroup.Statuses]: { groupIcon: 'PPMXSectionStatuses', groupName: 'Statuses' },
    [FieldGroup.ScheduleSummary]: { groupIcon: 'Globe', groupName: 'Schedule Summary' },
    [FieldGroup.PPMXTasks]: { groupIcon: 'PPMXLogo', groupName: 'PPM Express Tasks' },
    [FieldGroup.VSTS]: { groupIcon: 'VSTSLogo', groupName: 'Azure DevOps' },
    [FieldGroup.Jira]: { groupIcon: 'JiraLogo', groupName: 'Jira' },
    [FieldGroup.O365Planner]: { groupIcon: 'PlannerNewLogo', groupName: 'Microsoft Planner' },
    [FieldGroup.Spo]: { groupIcon: 'SpoLogo', groupName: 'Microsoft Project Online' },
    [FieldGroup.MondayCom]: { groupIcon: 'MondayComLogo', groupName: 'Monday.com' },
    [FieldGroup.Smartsheet]: { groupIcon: 'SmartsheetLogo', groupName: 'Smartsheet' },
    [FieldGroup.MPPFile]: { groupIcon: 'MPPLogo', groupName: 'Microsoft Project Desktop' },
    [FieldGroup.P4W]: { groupIcon: 'P4WLogo', groupName: 'Project for the Web' },
});

const getDetailsGroupIcon = (entity: EntityType): string => {
    switch (entity) {
        case EntityType.Project:
            return 'PPMXProject';
        case EntityType.Program:
            return 'PPMXProgram';
        case EntityType.Portfolio:
            return 'PPMXPortfolio';    
        default:
            return 'PPMXProject';
    }
}

export default GroupedDisabledFields;
