import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { useState } from 'react';
import { Toggle } from 'office-ui-fabric-react';
import './ProjectSetBaselineConfirmation.css';

type Props = {
    onConfirm: (setTasksBaseline: boolean, setKeyDatesBaseline: boolean) => void;
    onDismiss: () => void;
}

const ProjectSetBaselineConfirmation = (props: Props) => {
    const [updateTasksBaseline, setUpdateTasksBaseline] = useState<boolean>(false);
    const [updateKeyDatesBasline, setUpdateKeyDatesBaseline] = useState<boolean>(false);

    return <ConfirmationDialog
        onYes={() => props.onConfirm(updateTasksBaseline, updateKeyDatesBasline)}
        onDismiss={props.onDismiss}
        yesButtonProps={{ text: "Confirm" }}
        dialogContentProps={{
            title: 'Set Project Baseline',
            subText: 'The baseline will be set for the project’s Start Date and Finish Date fields.'
        }}
    >
        <div className="subenity-baseline-toggle">
            <div className="inline-toggle-wrap">
                <div>
                    <div className="label">Set baselines for all tasks in PPM Express schedule</div>
                    <p>Baseline will be created for the following fields in all tasks in PPM Express schedule: Start Date, Due Date, Duration, Effort, Original Estimate.</p>
                </div>
                <Toggle checked={updateTasksBaseline} onChange={(e, checked: boolean) => setUpdateTasksBaseline(checked)} />
            </div>
        </div>
        <div className="subenity-baseline-toggle">
            <div className="inline-toggle-wrap">
                <div>
                    <div className="label">Set baselines for all key dates</div>
                    <p>Baseline will be created for the Date field in all Key Dates.</p>
                </div>
                <Toggle checked={updateKeyDatesBasline} onChange={(e, checked: boolean) => setUpdateKeyDatesBaseline(checked)} />
            </div>
        </div>
    </ConfirmationDialog>
}

export default ProjectSetBaselineConfirmation;