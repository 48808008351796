import * as React from 'react';
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import { FormatType } from "../../entities/Metadata";
import { formatValue } from "./common";

export class TimePortalService {
    public static buildReportTimeMenuItem(
        timePortalUrl: string,
        reportTime: (seconds: number, date: Date) => void,
        disabled?: boolean): IContextualMenuItem {
        const timeReportHours = [0.25, 0.5, 0.75, 1, 2, 3, 4, 5, 6, 7, 8];
        const secondsInHour = 3600;
        return {
            key: 'reportTime',
            name: 'Report Time',
            disabled: disabled,
            iconProps: { iconName: "PPMXTimeLogo" },
            subMenuProps: {
                items: [
                    {
                        key: 'openTimePortal',
                        iconProps: { iconName: "PPMXTimeLogo" },
                        name: "Open PPM Express Time",
                        target: "_blank",
                        href: timePortalUrl
                    },
                    { key: 'hint', itemType: ContextualMenuItemType.Header, text: "Report Today:", },
                    ...timeReportHours.map(_ => (
                        {
                            key: _.toString(),
                            disabled: disabled,
                            name: formatValue(_, FormatType.Duration),
                            className: "progress-formatter",
                            onClick: () => reportTime(_ * secondsInHour, new Date())
                        }))
                ]
            }
        };
    }
}