import * as React from "react";
import * as Metadata from '../../../entities/Metadata';
import { ActionButton, MessageBar, MessageBarType, Toggle } from "office-ui-fabric-react";
import { ISettingsEditComponent, FieldActionTypes } from "../FieldPanel";
import { DraggableList } from '../../common/DraggableList';
import OptionEdit, { DraggableOption } from "./OptionEdit";
import { useDidMountEffect } from "../../utils/effects";

type IDropDownSettings = {
    multichoice: boolean;
    options: Metadata.Option[];
}

type Props = {
    settings: IDropDownSettings;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
}

const SelectSettingsEdit = React.forwardRef<ISettingsEditComponent, Props>((props, ref) => {
    const { actionType } = props;

    const [wasRemoved, setWasRemoved] = React.useState(false);
    const [wasEdited, setWasEdited] = React.useState(false);
    const [draggableOptions, setDraggableOptions] = React.useState<DraggableOption[]>(props.settings.options
        ? props.settings.options.map((_: any, index: number) => ({ id: 'id_ ' + index, key: _.name, name: _.name, color: _.color }))
        : [{ id: 'id_0', name: "" }]);
    const [multichoice, setMultichoice] = React.useState(props.settings?.multichoice ?? false);

    const isValid = areSelectOptionsValid(draggableOptions);
    useDidMountEffect(() => props.onChange(isValid), [isValid, draggableOptions]);
    React.useImperativeHandle(ref, (): ISettingsEditComponent => ({
        save: (fieldInfo) => {
            fieldInfo.type = Metadata.FieldType.Text;
            fieldInfo.settings = {
                ...fieldInfo.settings,
                multichoice,
                editControl: "Dropdown",
                options: buildOptions(draggableOptions, buildOption)
            }
        },
        update: (fieldInfo) => ({
            ...fieldInfo,
            optionsUpdate: buildOptionsUpdate(props.settings.options || [], draggableOptions, buildOption)
        })
    }));

    const onRemove = (optionId: string) => {
        setDraggableOptions(draggableOptions.filter(_ => _.id !== optionId));
        setWasRemoved(true);
    };
    
    return <div className="create-dropdown-field-settings">
        {actionType === FieldActionTypes.Create && <MessageBar
            messageBarType={MessageBarType.warning}>
            Note, Multiple Choice can be enabled only during field creation. You won't be able to change it later.
        </MessageBar>}
        <Toggle label='Multiple Choice'
            checked={multichoice}
            disabled={actionType === FieldActionTypes.Edit}
            onChange={(e, checked) => {
                setMultichoice(!!checked);
                props.onChange(isValid);
            }}
            onText='Yes'
            offText='No' />
        {actionType === FieldActionTypes.Edit && wasRemoved && <MessageBar messageBarType={MessageBarType.warning}>Note, if you delete an option which is currently used,
            {multichoice
                ? ' it will be also removed from the field values'
                : ' the field value will be set empty'}
            .</MessageBar>}
        {actionType === FieldActionTypes.Edit && wasEdited && (
            <MessageBar messageBarType={MessageBarType.warning}>
                Note, that editing an option currently in use will also change its value in your custom field.
            </MessageBar>
        )}
        <div className="options">
            <div className="label">Options</div>
            <DraggableList
                items={draggableOptions}
                onItemRender={(item, index) => <OptionEdit
                    option={item}
                    index={index}
                    autoFocus
                    isUnique={draggableOptions.filter(_ => _.name === item.name).length === 1}
                    onChange={option => {
                        setDraggableOptions(draggableOptions.map(_ => _.id === option.id ? option : _));
                        setWasEdited(true);
                    }}
                    onRemove={draggableOptions.length > 1 ? onRemove : undefined}
                />}
                onChanged={setDraggableOptions}
            />
        </div>
        <ActionButton
            iconProps={{ iconName: 'Add' }}
            text="New Option"
            className="new-button"
            onClick={() => setDraggableOptions(draggableOptions.concat([{ name: "", id: 'new_' + Date.now() }]))}
        />
    </div>
})

const isEqual = (option1: Metadata.Option, option2: Metadata.Option) => {
    return option1.name === option2.name && option1.color === option2.color;
}

const buildOption = (draggableOption: DraggableOption) => ({ name: draggableOption.name, color: draggableOption.color });

export const buildOptions = <T, >(
    draggableOptions: DraggableOption<T>[],
    optionBuilder: (draggableOption: DraggableOption<T>) => any): Metadata.Option[] => draggableOptions.map(optionBuilder);

export function areSelectOptionsValid(options: DraggableOption[]) {
    return areOptionsValid(options, _ => _.name);
}

export function areOptionsValid<T extends Metadata.Option>(options: DraggableOption<T>[], setSelector: (option: DraggableOption<T>) => string) {
    return options.every(_ => _.name.trim()) && areOptionsUnique(options, setSelector);
}

export function areOptionsUnique<T extends Metadata.Option>(draggableOptions: DraggableOption<T>[], setSelector: (option: DraggableOption<T>) => string) {
    return new Set(draggableOptions.map(setSelector)).size === draggableOptions.length;
}

export function buildOptionsUpdate<T extends Metadata.Option>(
    old: T[],
    options: DraggableOption<T>[],
    optionBuilder: (draggableOption: DraggableOption<T>) => any,
    isEqualCustom?: (option1: T, option2: T) => boolean
) {
    const changes: Metadata.OptionUpdate<T>[] = [];

    options.forEach((option, newIndex) => {
        if (!option.key) {
            changes.push({ operation: Metadata.Operation.Add, value: optionBuilder(option), index: newIndex });
        } else {
            const index = old.findIndex(_ => _.name === option.key);
            if (~index && !(isEqualCustom ?? isEqual)(old[index], option)) {
                changes.push({
                    operation: Metadata.Operation.Replace,
                    path: option.key,
                    value: optionBuilder(option),
                    index: newIndex
                });
            } else if (index !== newIndex) {
                changes.push({
                    operation: Metadata.Operation.Move,
                    path: option.key,
                    index: newIndex
                });
            }
        }
    });
    
    const removed = old.filter(_ => options.every(__ => __.key !== _.name));
    return removed.map<Metadata.OptionUpdate<T>>(_ => ({
        operation: Metadata.Operation.Remove,
        path: _.name
    })).concat(changes)
}

export default SelectSettingsEdit;