import * as React from "react";
import { TextField, Link } from 'office-ui-fabric-react';
import { ReportInfo, ReportMetadata, ReportScope } from "../../store/ReportsListStore";
import { ItemCreation } from "../common/ItemCreation";
import { Validator } from "../../validation";
import OptionsPicker, { Option } from "../../components/common/inputs/OptionsPicker";
import LabellableComponent from "../common/LabellableComponent";
import { ApplicationState } from "../../store";
import { PPMFeatures, Subscription } from "../../store/Tenant";
import { connect } from "react-redux";
import { CommonOperations, contains } from "../../store/permissions";
import { Dictionary } from "../../entities/common";
import { UserState } from "../../store/User";
import { notBoolean } from "../utils/common";

type OwnProps = {
    report?: ReportInfo;
    onSave: (report: ReportInfo) => void;
    onDismiss: () => void;
}
type StateProps = {
    user: UserState;
    subscription: Subscription;
    reportsMetadata: Dictionary<ReportMetadata>;
};
type Props = OwnProps & StateProps;

type State = {
    report: ReportInfo;
    isDirty?: boolean;
    scopeOptions: Option[];
    canManage: boolean;
}

const validator = Validator.new().required().build();

class ReportEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const options = [
            contains(props.subscription.features, PPMFeatures.ProjectManagement) && ReportScope.PROJECTS,
            ReportScope.RESOURCES,
            contains(props.subscription.features, PPMFeatures.PortfolioManagement) && ReportScope.PROGRAMS,
            contains(props.subscription.features, PPMFeatures.PortfolioManagement) && ReportScope.PORTFOLIOS,
            contains(props.subscription.features, PPMFeatures.OKR) && ReportScope.OBJECTIVES,
            contains(props.subscription.features, PPMFeatures.Ideation) && ReportScope.CHALLENGES
        ].filter(notBoolean);

        this.state = {
            report: props.report
                ? { ...props.report }
                : {
                    title: "",
                    url: "",
                    scope: [],
                    isCustom: true,
                },
            scopeOptions: options.map(_ => ({ key: _, text: _ })),
            canManage: contains(props.user.permissions.common, CommonOperations.ODataManage),
        };
    }

    render() {
        const { report, scopeOptions, canManage } = this.state;
        const reportMetadata = report.id ? this.props.reportsMetadata[report.id] : undefined;

        return <ItemCreation
            onDismiss={this.props.onDismiss}
            header={{
                text: this.state.report.id ? "Edit Report" : "New Report",
                secondaryText: reportMetadata
                    ? <div>Please refer to <Link target="_blank" href={reportMetadata.helpUrl}>this page</Link> for configuration guide</div>
                    : <div>Please refer
                        to <Link target="_blank" href="https://help.ppm.express/89509-ppm-express-power-bi-reports/custom-power-bi-report-on-ppm-express-data">this page</Link> on
                        how to configure Custom Power BI reports</div>,
                nameEditorLabel: "Title",
                value: this.state.report.title,
                onChanged: _ => this.setState({ isDirty: true, report: { ... this.state.report, title: _ } }),
                disableNameEditor: !this.state.report.isCustom,
                validator: validator
            }}
            isDirty={this.state.isDirty}
            commands={[
                {
                    primary: true,
                    disabled: !canManage || !validator.isValid(this.state.report.title),
                    text: this.state.report.id ? "Save Report" : "Create Custom Report",
                    onClick: () => {
                        this.props.onSave(this.state.report);
                        this.props.onDismiss();
                    },
                },
                {
                    text: "Cancel",
                    onClick: this.props.onDismiss,
                }
            ]}>
            <div className="panel-area">
                <div className="grid-item">
                    <LabellableComponent
                        className="field-container"
                        label="Report URL"
                        description={"Copy the Home Page URL of the published PPM Express report in Power BI, excluding the /ReportSection part. " +
                            "Paste this URL here to navigate to your reports from PPM Express pages directly."}
                    >
                        <TextField
                            value={this.state.report.url}
                            onGetErrorMessage={_ => _ ? (this.validate(_) ? "" : "Please enter report url") : ""}
                            onChange={(e, _) => this.setState({ isDirty: true, report: { ...this.state.report, url: _ } })}
                            disabled={!canManage}
                        />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent
                        className="field-container"
                        label="Scope"
                        description="Select the PPM Express pages where this report will be available by clicking on the Reports button."
                    >
                        <OptionsPicker
                            onChange={(scope: Option[]) => this.setState({ isDirty: true, report: { ...this.state.report, scope: scope.map(_ => _.text) } })}
                            selectedItems={scopeOptions.filter(_ => report.scope?.find(__ => __ === _.key))}
                            onResolveSuggestions={(filter: string, selected?: Option[]) =>
                                scopeOptions.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1 && !selected?.find(__ => __.key === _.key))}
                            disabled={!canManage}
                        />
                    </LabellableComponent>
                </div>
            </div>
        </ItemCreation>;
    }

    private validate(url: string) {
        return url.toLowerCase().startsWith("https://app.powerbi.com/") || !url.length;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        subscription: state.tenant.subscription,
        reportsMetadata: state.reports.metadata,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ReportEdit);
