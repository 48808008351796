import * as React from 'react';
import { FormatType, Field, getLabel } from '../../entities/Metadata';
import { FormatDate, formatValue } from '../utils/common';
import { ProgressIcon } from "../common/ProgressFormatter";
import { ITaskAttrs } from '../../entities/Subentities';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { EntityType } from '../../entities/common';

export type GroupSummary = {
    startDate: Date | undefined;
    dueDate: Date | undefined;
    tasksCounts: {
        total: number;
        new: number;
        inProgress: number;
        complete: number;
    }
    effort: number | string;
    storyPoints: number | string;
    progress: number;
    duration: number;
};
type OwnProps = {
    group: {
        name: string;
        color: string;
    };
    summary: GroupSummary;
};

type StoreProps = { fields: Field[] }
type Props = OwnProps & StoreProps;

class TaskGroupTooltipContent extends React.Component<Props> {
    render() {
        const { group, summary } = this.props;
        return (
            <div className="timeline-tooltip">
                <div className="header">
                    <div style={{ backgroundColor: group.color, minWidth: 32, width: 32, height: 32, marginRight: 8, borderRadius:3 }}></div>
                    <div className="title">
                        <div className="overflow-text" title={group.name}>
                            {group.name}
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="item align-center">
                        <div className="label">Start Date</div>
                        <div>{FormatDate(summary.startDate)}</div>
                    </div>
                    <div className="item align-center">
                        <div className="label">Due Date</div>
                        <div>{FormatDate(summary.dueDate)}</div>
                    </div>
                    {this.renderField("Duration",
                        <div className="align-center">
                            <div>{formatValue(summary.duration, FormatType.Days)}</div>
                        </div>)}
                    {this.renderField("Effort",
                        <div className="align-center">
                            <div>{formatValue(summary.effort, FormatType.Duration)}</div>
                        </div>)}
                    {this.renderField("StoryPoints",
                        <div className="align-center">
                            <div>{summary.storyPoints}</div>
                        </div>)}
                    {this.renderField("Progress",
                        <div className="align-center">
                            <div className="completed">{formatValue(summary.progress, FormatType.Percent)}</div>
                        </div>)}
                    <div className="item align-center">
                        <div className="label">Tasks</div>
                        <div className="progress-formatter align-center">
                            <ProgressIcon progress={0} /><span className="value">{summary.tasksCounts.new}</span>
                            <span className="space"></span> 
                            <ProgressIcon progress={50} /><span className="value">{summary.tasksCounts.inProgress}</span>
                            <span className="space"></span> 
                            <ProgressIcon progress={100} /><span className="value">{summary.tasksCounts.complete}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderField = (name: keyof ITaskAttrs, valueElement: JSX.Element): JSX.Element | null => {
        const field = this.props.fields.find(_ => _.name === name);
        if (!field) {
            return null;
        }

        return (
            <div className="item align-center">
                <div className="label">{getLabel(field)}</div>
                {valueElement}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Task];
    return {
        fields: fields.allIds.map(_ => fields.byId[_])
    };
}

export default connect(mapStateToProps)(TaskGroupTooltipContent);