import { Action, Reducer } from 'redux';

export enum FinancialsProjectType {
    Budget = 0,
    Benefits = 1,
} 

export interface FinancialsState {
    token: string;
    embedUrl: string;
    currency: string | null;
}

const INIT_STATE: FinancialsState = {
    token: '',
    embedUrl: '',
    currency: null
}

export enum ActionTypes {
    UPDATE_FINANCIALS = 'UPDATE_FINANCIALS'
}

type UpdateFinancialsAction = {
    type: ActionTypes.UPDATE_FINANCIALS,
    financials: FinancialsState
}

export type FinancialsActions = UpdateFinancialsAction;

export const reducer: Reducer<FinancialsState> = (state: FinancialsState = INIT_STATE, incomingAction: Action) => {
    const action = incomingAction as FinancialsActions;
    switch (action.type) {
        case ActionTypes.UPDATE_FINANCIALS: return action.financials;
        //default:
        //    const exhaustiveCheck: never = action;
    }
    return state;
};