import * as React from 'react';
import EntityWarning from "./EntityWarning";
import { getId } from 'office-ui-fabric-react';
import { IWarning } from "../../../entities/common";

type Props = { warnings: IWarning[], commonWarningTypes: string[], message: string }

export class SummaryEntityWarning extends React.Component<Props> {
    render() {
        return (
            <EntityWarning className="warning-button-wrapper"
                           asButton={true}
                           warnings={this.props.warnings}
                           onRenderBody={this._onRenderWarnings}
                           multipleItemsMessage={this.props.message}/>
        );
    }

    private _onRenderWarnings = (): JSX.Element | JSX.Element[] | null => {
        const { warnings, commonWarningTypes } = this.props;
        const commonWarnings = warnings.filter(_ => commonWarningTypes.find(__ => __ === _.type));
        return (
            <div>
                {
                    commonWarnings.length > 0 &&
                        <ul className="list">
                            {commonWarnings.map(_ => <li key={`${_.type}_${getId()}`}>{_.text}</li>)}
                        </ul>
                }
                {
                    warnings.length !== commonWarnings.length &&
                        <div style={{ paddingLeft: 20 }}>
                            {this.props.message}
                        </div>
                }
            </div>
        );
    }
}