import * as React from 'react';
import * as Metadata from "../../../entities/Metadata";
import { ITask, ITaskAttrs } from '../../../entities/Subentities';
import { Dictionary, EntityType } from '../../../entities/common';
import { nameof } from '../../../store/services/metadataService';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Baseline, BaselineDateRow, BaselineNumberRow } from '../../common/baseLine';

type OwnProps = {
    task: ITask;
    variance: TaskVariance | undefined;
}
type StoreProps = {
    fieldsMap: Dictionary<Metadata.Field>;
}
type Props = OwnProps & StoreProps;

export type TaskVariance = {
    startDateVariance?: number | null;
    dueDateVariance?: number | null;
    durationVariance?: number | null;
    effortVariance?: number | null;
    originalEstimateVariance?: number | null;
}

const TaskBaseline = (props: Props) => {
    const { task, fieldsMap, variance } = props;
    return <Baseline date={task.baseline?.date}>
        <BaselineDateRow 
            field={fieldsMap[nameof<ITaskAttrs>("StartDate")]}
            date={task.attributes.StartDate}
            baseline={task.baseline?.startDate}
            variance={variance?.startDateVariance} />
        <BaselineDateRow 
            field={fieldsMap[nameof<ITaskAttrs>("DueDate")]}
            date={task.attributes.DueDate}
            baseline={task.baseline?.dueDate}
            variance={variance?.dueDateVariance} />
        <BaselineNumberRow 
            field={fieldsMap[nameof<ITaskAttrs>("Duration")]} 
            number={task.attributes.Duration} 
            baseline={task.baseline?.duration}
            variance={variance?.durationVariance} />
        <BaselineNumberRow 
            field={fieldsMap[nameof<ITaskAttrs>("Effort")]} 
            number={task.attributes.Effort} 
            baseline={task.baseline?.effort}
            variance={variance?.effortVariance} />
        <BaselineNumberRow 
            field={fieldsMap[nameof<ITaskAttrs>("OriginalEstimate")]} 
            number={task.attributes.OriginalEstimate}
            baseline={task.baseline?.originalEstimate}
            variance={variance?.originalEstimateVariance} />
    </Baseline>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Task];
    return {
        fieldsMap: Metadata.toMap(fields.allIds.map(_ => fields.byId[_])),
    };
}

export default connect(mapStateToProps)(TaskBaseline);
