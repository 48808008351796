import { MaybeDate } from "../../entities/common";
import { CalendarDataSet } from "../../store/CalendarStore";
import { calculateDateVariance } from "../../store/project/utils";

// the same is in TaskVarianceCalculator.cs on server side
export class TaskVarianceCalculator {
    public static CalculateDateVariance(taskDate: MaybeDate, taskBaselineDate: MaybeDate, calendar: CalendarDataSet): number | undefined {
        return calculateDateVariance(taskDate, taskBaselineDate, calendar);
    }

    public static CalculateVariance(value: number | undefined | null, baselineValue: number | null | undefined): number | undefined {
        if (value == null || baselineValue == null) {
            return undefined;
        }

        return value - baselineValue;
    }
}