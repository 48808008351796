import * as React from 'react';
import { TextField, PrimaryButton } from 'office-ui-fabric-react';
import OverlayComponent from "../common/OverlayComponent";
import { Validator } from "../../validation";
import { Dictionary, ISourceInfo } from "../../entities/common";

type Props = {
    readonly: boolean;
    actions: Dictionary<any>;
    sourceInfo?: ISourceInfo;
    dismissPanel?: () => void;
}

type State = {
    fileUrl?: string;
}

const validator = {
    fileUrl: Validator.new().required('Please enter file URL').build()
}

export default class FileConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const sourceData = props.sourceInfo && props.sourceInfo.sourceData;
        this.state = {
            fileUrl: sourceData && sourceData.fileUrl
        };
    }

    public render() {
        const { sourceInfo, readonly } = this.props;
        const isLinked = !!sourceInfo;
        return <div>
            <TextField label="File URL"
                className='file-link'
                disabled={readonly || isLinked}
                placeholder="File URL"
                value={this.state.fileUrl}
                title={this.state.fileUrl}
                validateOnFocusOut={true}
                onGetErrorMessage={validator.fileUrl.getErrorMessage}
                onChange={(e, _) => this.setState({ fileUrl: _ })} />
            <OverlayComponent isOverlayed={!this.state.fileUrl}>
                {
                    !isLinked && <PrimaryButton text={`Link file`}
                        disabled={readonly || !Validator.isValid(validator, this.state)}
                        className="with-top-margin"
                        onClick={this._linkToProject} />
                }
                {
                    isLinked && <PrimaryButton text="Delete link"
                        className="with-top-margin"
                        disabled={readonly || (sourceInfo && sourceInfo.syncStatus.inProgress)}
                        title={!!(sourceInfo && sourceInfo.syncStatus.inProgress) ? "Sync in progress." : undefined}
                        onClick={this._deleteLink} />
                }
            </OverlayComponent>
        </div>;
    }

    private _linkToProject = () => {
        const { dismissPanel } = this.props;
        const { fileUrl } = this.state;
        if (fileUrl) {
            this.props.actions.linkToFile(fileUrl.trim());
            dismissPanel && dismissPanel();
        }
    }

    private _deleteLink = () => {
        const { dismissPanel, sourceInfo } = this.props;
        this.props.actions.deleteLink(sourceInfo?.connectionId);
        dismissPanel && dismissPanel();
    }
}