import * as React from 'react';
import {
    PivotLinkSize, PivotLinkFormat, PivotItem, Pivot, IComboBoxOption, Spinner, SpinnerSize,
    TextField, PrimaryButton, ISelectableOption, ComboBox
} from 'office-ui-fabric-react';
import { ISourceInfo } from "../../entities/common";
import OverlayComponent from "../common/OverlayComponent";
import { ILinkDto, IListStore } from '../../store/integration/common';
import { ConnectionField } from './ConnectionField';

type OwnProps<T extends IConnectableItem> = {
    itemTypeLabel: string;
    readonly: boolean;
    allowNewItems: boolean;
    defaultConnectionId?: string;
    allowMultipleConnectionsToOneItem?: boolean;
    notCloseAfterLink?: boolean;
    actions: {
        linkToItem: (linkData: ILinkDto<T>) => void;
        createItemAndLink?: (connectionId: string, itemName: string) => void;
        deleteLink: (connectionId: string) => void;
    };
    dismissPanel: () => void;
    sourceInfo?: ISourceInfo;
    itemId?: string;
    itemName?: string;
    items: IListStore<T>;
    isConnectionValid?: (connectionId?: string) => { isValidating?: boolean, validationError?: string | React.ReactNode | null } | undefined;
    renderConnectControl?: (selectedConnectionId: string | undefined, disabled: boolean, onChange: (connectionId?: string) => void)
        => React.ReactNode | React.ReactNode[] | undefined;
    renderExistingConnectedItemPreOptions?: (selectedConnectionId?: string, selectedItemId?: string) => React.ReactNode | React.ReactNode[] | undefined;
    renderExistingConnectedItemOptions?: (selectedConnectionId?: string, selectedItemId?: string) => React.ReactNode | React.ReactNode[] | undefined;
    renderNewConnectedItemOptions?: (selectedConnectionId?: string, newItemName?: string) => React.ReactNode | React.ReactNode[] | undefined;
    renderNewItemDisabledMessage?: (selectedConnectionId?: string) => React.ReactNode | React.ReactNode[] | undefined;
    onTabCahnged?: (tabType: TabType) => void;
}

export enum TabType {
    SelectItem = "existing_item",
    CreateItem = "new_item"
}

interface IWithTitle { title: string; }
interface IWithName { name: string; }
type IConnectableItem = { id: string; isAlreadyLinked: boolean; } & (IWithTitle | IWithName);
type Props<T extends IConnectableItem> = OwnProps<T>;

type State = {
    isLinked: boolean;
    selectedConnectionId?: string;
    selectedItemId?: string;
    newItemName?: string;
}

export default class ConnectControl<T extends IConnectableItem> extends React.Component<Props<T>, State> {
    constructor(props: Props<T>) {
        super(props);

        const connectionId = props.sourceInfo && props.sourceInfo.connectionId || props.defaultConnectionId;
        this.state = {
            isLinked: !!props.sourceInfo,
            selectedConnectionId: connectionId,
            selectedItemId: props.itemId
        };
    }

    componentWillReceiveProps(nextProps: Props<T>) {
        if (this.props.itemId !== nextProps.itemId) {
            this.setState({ selectedItemId: nextProps.itemId });
        }
    }

    render() {
        const { selectedConnectionId, isLinked } = this.state;
        const { sourceInfo, readonly, renderConnectControl, children, allowNewItems, isConnectionValid } = this.props;
        //needed for PPMXTimeConnectControl
        const validation = isConnectionValid
            ? selectedConnectionId
                ? isConnectionValid?.(selectedConnectionId)
                : undefined
            : { isValidating: false };
        const isValid = validation?.isValidating === false && !validation?.validationError;

        return (
            <div>
                <div className="with-top-margin">
                    {renderConnectControl?.(selectedConnectionId, readonly || !!(sourceInfo && sourceInfo.connectionId), this.onConnectionChange)}
                </div>
                <div className="with-top-margin">
                    <OverlayComponent isOverlayed={!selectedConnectionId || validation?.isValidating !== false || !!validation?.validationError}>
                        {
                            !allowNewItems || isLinked
                                ? this._renderItemSelection(isValid)
                                : <Pivot
                                    linkFormat={PivotLinkFormat.links}
                                    linkSize={PivotLinkSize.normal}
                                    onLinkClick={this.onChangePivot}>
                                    <PivotItem headerText={`Existing ${this.props.itemTypeLabel}`} itemKey={TabType.SelectItem} key="existing_item">
                                        {this._renderItemSelection(isValid)}
                                    </PivotItem>
                                    <PivotItem headerText={`New ${this.props.itemTypeLabel}`} itemKey={TabType.CreateItem} key="new_item">
                                        {this._renderItemCreation(isValid)}
                                    </PivotItem>
                                </Pivot>
                        }
                        {children}
                    </OverlayComponent>
                </div>
            </div>
        );
    }

    private _renderItemSelection = (isConnectionValid: boolean) => {
        const { readonly, sourceInfo, items, itemName, itemTypeLabel, allowMultipleConnectionsToOneItem } = this.props;
        const { isLinked, selectedConnectionId, selectedItemId } = this.state;
        const disabled = !selectedConnectionId || isLinked || readonly || items.isLoading;
        const options = items.entities?.map((_) => ({
            key: _.id,
            text: (_ as IWithTitle).title || (_ as IWithName).name,
            disabled: !allowMultipleConnectionsToOneItem && _.isAlreadyLinked
        }));
        if (allowMultipleConnectionsToOneItem) {
            options?.sort((a, b) => a.text.localeCompare(b.text));
        }

        const selectedValue = options?.find(_ => _.key === selectedItemId)?.text ?? this.props.itemName;

        return <div style={{ position: "relative" }}>
        {this.props.renderExistingConnectedItemPreOptions?.(selectedConnectionId, selectedItemId)}
        <ConnectionField
            isLinked={isLinked}
            label={itemTypeLabel}
            value={selectedValue}>
            <ComboBox
                placeholder={disabled ? undefined : `Select ${itemTypeLabel}`}
                styles={{ label: { textTransform: 'capitalize' } }}
                disabled={disabled}
                useComboBoxAsMenuWidth
                allowFreeform
                title={selectedValue}
                options={options}
                errorMessage={isConnectionValid ? this.getComboboxValidationMessage() : undefined}
                selectedKey={selectedItemId}
                onRenderItem={this.onRenderItem}
                onChange={(e: any, option?: IComboBoxOption) => this.setState({ selectedItemId: option && option.key as string })}
                text={itemName}
            />
        </ConnectionField>
        {items.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -23, width: 30, position: 'absolute' }} />}
        {this.props.renderExistingConnectedItemOptions?.(selectedConnectionId, selectedItemId)}
        {
            !isLinked && <PrimaryButton text={`Link ${itemTypeLabel}`}
                disabled={readonly || selectedItemId === undefined}
                className="with-top-margin"
                onClick={this.linkToItem} />
        }
        {
            isLinked && <PrimaryButton text={`Delete ${itemTypeLabel} link`}
                className="with-top-margin"
                disabled={readonly || (sourceInfo && sourceInfo.syncStatus.inProgress)}
                title={!!(sourceInfo && sourceInfo.syncStatus.inProgress) ? "Sync in progress." : undefined}
                onClick={() => this.deleteLink()} />
        }
    </div>;
}

    private _renderItemCreation = (isConnectionValid: boolean) => {
        const { readonly, itemTypeLabel, renderNewItemDisabledMessage } = this.props;
        const { newItemName, selectedConnectionId } = this.state;
        const newItemDisabledMessage = renderNewItemDisabledMessage?.(selectedConnectionId);
        const disabled = readonly || !selectedConnectionId;

        return <div>
            {newItemDisabledMessage}
            <OverlayComponent isOverlayed={!!newItemDisabledMessage}>
                <TextField label={`New ${itemTypeLabel} name`}
                    placeholder={disabled ? undefined : `Type in new ${itemTypeLabel} name`}
                    disabled={disabled}
                    onChange={(e, val) => { this.setState({ newItemName: val }); }}
                    errorMessage={isConnectionValid && !newItemDisabledMessage ? this.getTextFieldValidationMessage() : undefined} />
                {this.props.renderNewConnectedItemOptions?.(selectedConnectionId, newItemName)}
                <PrimaryButton text={`Create ${itemTypeLabel}`} className="with-top-margin"
                    disabled={!newItemName}
                    onClick={this.createItemAndLink} />
            </OverlayComponent>
        </div>;
    }

    private onRenderItem = (props?: ISelectableOption, defaultRender?: (props?: ISelectableOption) => JSX.Element | null): JSX.Element | null => {
        if (!defaultRender) {
            return null;
        }

        const element = defaultRender(props);
        if (!props || !props.disabled) {
            return element;
        }
        return React.createElement("div", { title: "Already linked", key: props?.key }, element);
    }

    private onChangePivot = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (!item) {
            return;
        }

        this.setState({
            selectedItemId: item.props.itemKey === TabType.CreateItem
                ? this.state.selectedItemId
                : undefined,
            newItemName: item.props.itemKey === TabType.SelectItem
                ? this.state.newItemName
                : undefined
        });
        this.props.onTabCahnged?.(item.props.itemKey === TabType.CreateItem ? TabType.CreateItem : TabType.SelectItem);
    }

    private onConnectionChange = (connectionId?: string) => {
        if (this.state.selectedConnectionId !== connectionId) {
            this.setState({ selectedConnectionId: connectionId, selectedItemId: undefined });
        }
    }

    private linkToItem = () => {
        const { selectedConnectionId, selectedItemId } = this.state;
        if (!selectedConnectionId || !selectedItemId) {
            return;
        }

        const item = this.props.items.entities.find(_ => _.id === selectedItemId);
        if (!item) {
            return;
        }

        const data = { connectionId: selectedConnectionId, data: item };

        this.props.actions.linkToItem(data);
        !this.props.notCloseAfterLink && this.props.dismissPanel();
    }

    private deleteLink = () => {
        if (this.props.sourceInfo != null) {
            this.props.actions.deleteLink(this.props.sourceInfo.connectionId);
        }
        this.props.dismissPanel();
    }

    private createItemAndLink = () => {
        const { selectedConnectionId, newItemName } = this.state;

        if (selectedConnectionId && newItemName) {
            this.props.actions.createItemAndLink!(selectedConnectionId, newItemName);
        }

        !this.props.notCloseAfterLink && this.props.dismissPanel();
    }

    private getComboboxValidationMessage = () => {
        if (!this.state.selectedConnectionId || this.props.items.isLoading) {
            return undefined;
        }

        return this.props.items.error
            ? this.props.items.error
            : !this.state.selectedItemId
                ? (this.state.isLinked ? `Selected ${this.props.itemTypeLabel} is not in the list yet` : `Please select ${this.props.itemTypeLabel} from list`)
                : undefined;
    }

    private getTextFieldValidationMessage = () => {
        if (!this.state.selectedConnectionId) {
            return undefined;
        }

        return !this.state.newItemName
            ? `Please type in new ${this.props.itemTypeLabel} name`
            : undefined;
    }
}