import * as React from "react";
import Logo, { ILogoProps } from "../common/Logo";

export default class ProjectLogo extends React.Component<ILogoProps> {
    public render() {
        return <Logo
            className={"proj-logo"}
            imageId={this.props.imageId}
            onChanged={this.props.onChanged}
            onRemove={this.props.onRemove} />;
    }
} 