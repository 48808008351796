import * as React from 'react';
import { PanelType, DefaultButton, PrimaryButton, Checkbox } from 'office-ui-fabric-react';
import { IRoadmapItem, IRoadmapItemAttrs } from '../../entities/Subentities';
import * as analytics from '../../analytics';
import { UserState } from '../../store/User';
import ExpandablePanel from '../common/ExpandablePanel';
import { FieldsState } from '../../store/fields';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { Dictionary, EntityType } from '../../entities/common';
import { matchRoadmapItemAndOriginStatuses } from './Fields';
import { Field } from '../../entities/Metadata';
import { nameof } from '../../store/services/metadataService';

type OwnProps = {
    user: UserState;
    items: IRoadmapItem[];
    onDismiss: () => void;
    onUpdate: (updatedItems: IRoadmapItem[]) => void;
}

type StateProps = {
    roadmapItemFields: Field[];
    fieldsStateMap: Dictionary<FieldsState>;
}

type Props = OwnProps & StateProps;

type WarningHandler = {
    label: string;
    update: (item: IRoadmapItem) => void;
    canUpdate?: (item: IRoadmapItem, roadmapItemFields: Field[], fieldsStateMap: Dictionary<FieldsState>) => boolean;
};

const warningHandlers: Record<string, WarningHandler> = {
    ["DatesInOriginEntityIsDiffer"]: {
        label: "Dates",
        update: _ => ({
            ..._,
            attributes: {
                ..._.attributes,
                StartDate: _.externalData?.OriginAttributes?.["StartDate"],
                FinishDate: _.externalData?.OriginAttributes?.["FinishDate"],
            },
        }),
    },
    ["ProgressInOriginEntityIsDiffer"]: {
        label: "Progress",
        update: _ => ({ ..._, attributes: { ..._.attributes, Progress: _.externalData?.OriginAttributes?.["Progress"] } }),
    },
    ["StatusInOriginEntityIsDiffer"]: {
        label: "Status",
        update: _ => ({ ..._, attributes: { ..._.attributes, Status: _.externalData?.OriginAttributes?.["Status"] } }),
        canUpdate: (item, roadmapItemFields, fieldsStateMap) =>
            matchRoadmapItemAndOriginStatuses(roadmapItemFields.find(_ => _.name === nameof<IRoadmapItemAttrs>("Status"))!, item, fieldsStateMap)
    },
};

const RoadmapItemUpdatePanel = (props: Props) => {
    const options = Object.keys(warningHandlers).map(_ => {
        const handler = warningHandlers[_];
        const itemsWithWarning = props.items.filter(item => item.warnings.some(__ => __.type === _));
        const canUpdateItemsCount = (handler.canUpdate
            ? itemsWithWarning.filter(item => handler.canUpdate!(item, props.roadmapItemFields, props.fieldsStateMap))
            : itemsWithWarning).length;
        return ({
            type: _,
            label: handler.label,
            counts: {
                canUpdate: canUpdateItemsCount,
                cannotUpdate: itemsWithWarning.length - canUpdateItemsCount
            }
        });
    });
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

    return <ExpandablePanel
        isOpen={true}
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth="400px"
        focusTrapZoneProps={{ disableFirstFocus: true }}
        onRenderHeader={() => <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Update Roadmap Items</p>
            <div className='ms-Panel-secondaryText'>Select from the following options</div>
        </div>}
        onRenderFooterContent={() => <div className="commands">
            <PrimaryButton
                text="Update"
                disabled={selectedOptions.length === 0}
                onClick={() => {
                    const selectedOptionsMap = selectedOptions.reduce((map, _) => ({ ...map, [_]: warningHandlers[_] }), {});
                    const updatedItems = props.items
                        .filter(item => item.warnings.some(_ => !!selectedOptionsMap[_.type]))
                        .map(item => {
                            let updatedItem = { ...item };
                            const warnings = updatedItem.warnings.filter(_ => !!selectedOptionsMap[_.type]);
                            warnings.forEach(_ => {
                                updatedItem = selectedOptionsMap[_.type].update(updatedItem);
                            });
                            updatedItem.warnings = updatedItem.warnings.filter(_ => !selectedOptionsMap[_.type])
                            return updatedItem;
                        });
                    props.onUpdate(updatedItems);
                    props.onDismiss();

                    analytics.trackEvent("Update roadmap items", props.user, { selectedOptions: selectedOptions });
                }} />
            <DefaultButton
                text="Cancel"
                onClick={props.onDismiss} />
        </div>}
        onDismiss={props.onDismiss}>
        {options.map(option => {
            const optionName = option.label.toLowerCase();
            return <div key={option.type} className="update-from-source-box">
                <Checkbox label={`Update ${option.label} from Source`} disabled={option.counts.canUpdate === 0} onChange={(ev: any, checked?: boolean) => {
                    const array = [...selectedOptions];
                    const idx = array.indexOf(option.type);
                    if (checked && idx === -1) {
                        array.push(option.type);
                    }
                    else if (!checked && idx !== -1) {
                        array.splice(idx, 1);
                    }
                    setSelectedOptions(array);
                } } />
                <div className={`description ${option.counts.canUpdate === 0 ? 'disabled' : ''}`}>
                    Update <span className="counter">{getItemsCountString(option.counts.canUpdate)}</span> with
                    the current {optionName} of their source element
                    {option.counts.cannotUpdate > 0
                        ? `. The ${optionName} for the remaining ${getItemsCountString(option.counts.cannotUpdate)} can't be updated due to mismatched values`
                        : ''}
                </div>
            </div>;
        })}
    </ExpandablePanel>;

    function getItemsCountString(count: number) {
        const itemString = count === 1 ? 'item' : 'items';
        return `${count} ${itemString}`;
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    const fields = state.fields[EntityType.RoadmapItem];

    return {
        roadmapItemFields: fields.allIds.map(_ => fields.byId[_]),
        fieldsStateMap: state.fields,
    };
}

export default connect(mapStateToProps)(RoadmapItemUpdatePanel);