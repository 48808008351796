import "./ConnectionSelect.css";
import * as React from "react";
import { Dropdown, DropdownMenuItemType, Icon, IDropdownOption, IconButton, IRenderFunction, IDropdownProps, ISelectableOption, Label, TooltipHost, TooltipDelay, DirectionalHint } from "office-ui-fabric-react";
import { IConnectionInfo } from "../../../entities/Metadata";
import { ApplicationState } from "../../../store";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { connect } from "react-redux";
import { Integrations } from "../../../store/Tenant";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { inIframe } from '../../utils/common';
import ConnectionRemoveDialog from '../ConnectionRemoveDialog';
import ConnectionBox from "../ConnectionBox";

type OwnProps = {
    sourceType: SourceType,
    connectionId?: string;
    connections: IConnectionInfo[];
    onConnectionChange: (connection?: IConnectionInfo) => void;
    onConnectionRemove?: (connectionId: string) => void;
    onConnectionRename: (connectionId: string, title: string) => void;
    disabled: boolean;

    onRefreshClick: (connectionId: string) => void;

    hasUpgradePremissions?: (connectionId: string) => boolean;
    onUpgradePremissionsClick?: (connectionId: string) => void;

    grantPermissionsText?: string;
    onGrantPermissionsClick?: () => void;

    newConnectionTooltip?: JSX.Element;
    onNewConnectionClick: () => void;

    newRestrictedConnectionTooltip?: JSX.Element;
    onNewRestrictedConnectionClick?: () => void;
}

type StoreProps = {
    user: UserState;
    integrations: Integrations;
};
type Props = OwnProps & StoreProps;

const ConnectionSelect = (props: Props) => {
    const newAzureADConnectionKey: string = 'newAD';
    const newRestrictedConnectionKey: string = 'newRestricted';
    const newConnectionKey: string = 'new';
    const placeholderKey: string = 'placeholder';
    const grantPermissionsKey: string = 'grantPermissions';
    const dividerKey: string = 'divider';

    const [connectionId, setConnectionId] = React.useState(props.connectionId);
    const [connectionToRemove, setConnectionToRemove] = React.useState<IConnectionInfo>();
    const disableManage = !contains(props.user.permissions.common, CommonOperations.ConnectionManage);

    React.useEffect(() => {
        setConnectionId(props.connectionId);
    }, [props.connectionId]);

    const { onConnectionRemove, sourceType } = props;
    const options = Array.of<IDropdownOption>({ key: placeholderKey, text: "Select connection" })
        .concat(props.connections.map(_ => ({ key: _.id, text: _.title, data: { secondaryText: _.name, title: _.name } })));
    if (!inIframe()) {

        options.push({ key: dividerKey, text: '-', itemType: DropdownMenuItemType.Divider });
        if (props.onNewRestrictedConnectionClick) {
            options.push({ key: newRestrictedConnectionKey, text: 'Add new restricted connection', data: { icon: 'Add', title: props.newRestrictedConnectionTooltip }, disabled: disableManage });
        }
        options.push({ key: newConnectionKey, text: 'Add new connection', data: { icon: 'Add', title: props.newConnectionTooltip }, disabled: disableManage });
        if (props.onGrantPermissionsClick) {
            options.push({ key: grantPermissionsKey, text: props.grantPermissionsText ?? 'Grant Permissions', data: { icon: 'Unlock' }, disabled: disableManage });
        }
    }

    //hide default tooltips, use TooltipHost
    options.forEach(_ => _.title = '');

    if (connectionId) {
        const selectedOption = options.find(_ => _.key === connectionId);
        if (selectedOption) {
            selectedOption.selected = true;
        }
    }

    if (props.disabled && connectionId && props.connections.length) {
        return <>
            <Label>Connection</Label>
            <ConnectionBox
                connection={props.connections.find(_ => _.id === connectionId)!}
                onUpdrade={props.hasUpgradePremissions?.(connectionId) ? () => props.onUpgradePremissionsClick?.(connectionId) : undefined}
                onEdit={() => props.onRefreshClick(connectionId)}
                onRename={(newName: string) => props.onConnectionRename(connectionId, newName)}
                canManage={!disableManage}
            />
        </>;
    }

    const _onRenderTitle = (props?: IDropdownOption[], defaultRender?: (props?: IDropdownOption[]) => JSX.Element | null): JSX.Element | null => {
        if (!props || !Array.isArray(props)) {
            return <span />;
        }

        return renderConnectionOption(props[0]);
    }

    const _onRefreshClick = (e: React.MouseEvent<HTMLButtonElement>, cId: string) => {
        props.onRefreshClick(cId);
        e.stopPropagation();
    }

    const _onUpgradeClick = (e: React.MouseEvent<HTMLButtonElement>, cId: string) => {
        props.onUpgradePremissionsClick?.(cId);
        e.stopPropagation();
    }

    const _onRemoveClick = (e: React.MouseEvent<HTMLButtonElement>, cId: string) => {
        const connection = props.connections.find(_ => _.id === cId);
        if (connection) {
            setConnectionToRemove(connection);
        }
        e.stopPropagation();
    }

    const onChangeConnection = (e: React.FormEvent<HTMLDivElement>, option: IDropdownOption, index?: number) => {
        if (option.key === placeholderKey) {
            setConnectionId(undefined);
            props.onConnectionChange();
            return;
        }

        if (option.key === newRestrictedConnectionKey) {
            props.onNewRestrictedConnectionClick!();
            setConnectionId(undefined);
            return;
        }

        if (option.key === newConnectionKey) {
            props.onNewConnectionClick();
            setConnectionId(undefined);
            return;
        }

        if (option.key === grantPermissionsKey) {
            props.onGrantPermissionsClick!();
            setConnectionId(undefined);
            return;
        }

        const connectionId = option.key;
        const connection = props.connections.find(_ => _.id === connectionId)!;
        props.onConnectionChange(connection);
        setConnectionId(connectionId as string);
    }

    const renderConnectionOption = (option?: IDropdownOption): JSX.Element | null => {
        if (!option) {
            return null;
        }

        return <div className="option align-center">
            {
                option.data?.icon &&
                <Icon
                    style={{ marginRight: '8px' }}
                    iconName={option.data.icon}
                    aria-hidden='true'
                    title={option.data.icon} />
            }
            <TooltipHost
                content={option.data?.title ?? option.text}
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter, isBeakVisible: false, gapSpace: 10 }}
                className='border shadow-none'>
                <div className="option-details">
                    <div className="option-label">{option.text}</div>
                    {option.data?.secondaryText && <div className="option-description overflow-text">{option.data.secondaryText}</div>}
                </div>
            </TooltipHost>
        </div>;
    }

    const _renderConnectionItem = (option?: ISelectableOption, defaultRender?: (option: ISelectableOption) => JSX.Element | null): JSX.Element | null => {
        if (!option) {
            return null;
        }

        const backgroundColor = option.selected ? "rgb(237, 235, 233)" : "transparent";
        const keys = [dividerKey, placeholderKey, newConnectionKey, grantPermissionsKey, newRestrictedConnectionKey, newAzureADConnectionKey];
        const skipIcons = !keys.includes(option.key as string);

        return <div key={option.key} className={`connection-option align-center ${option.data?.secondaryText ? "big" : ""}`}>
            {defaultRender?.(option)}
            {
                skipIcons &&
                <IconButton
                    title="Refresh connection"
                    iconProps={{ iconName: "Refresh" }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => _onRefreshClick(e, option.key as string)}
                    disabled={disableManage}
                    styles={{ root: { backgroundColor, height: 36 }, rootDisabled: { backgroundColor: backgroundColor || "white" } }} as="blabla" />
            }
            {
                skipIcons && props.hasUpgradePremissions?.(option.key as string) &&
                <IconButton
                    title="Request full access"
                    iconProps={{ iconName: "Unlock" }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => _onUpgradeClick(e, option.key as string)}
                    disabled={disableManage}
                    styles={{ root: { backgroundColor, height: 36 }, rootDisabled: { backgroundColor: backgroundColor || "white" } }} as="blabla" />
            }
            {
                props.onConnectionRemove && skipIcons &&
                <IconButton title="Remove connection"
                    iconProps={{ iconName: "Delete" }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => _onRemoveClick(e, option.key as string)}
                    disabled={disableManage}
                    styles={{ root: { backgroundColor, height: 36 }, rootDisabled: { backgroundColor: backgroundColor || "white" } }} as="blabla" />
            }
        </div>;
    }


    return (
        <>
            <Dropdown
                className="select-connection"
                styles={{ dropdownItem: { backgroundColor: "transparent" } }}
                disabled={props.disabled}
                label='Connection'
                selectedKey={connectionId ? connectionId : placeholderKey}
                options={options}
                onRenderTitle={_onRenderTitle}
                onRenderOption={renderConnectionOption}
                onRenderItem={_renderConnectionItem}
                onChange={onChangeConnection} />
            {
                connectionToRemove && onConnectionRemove &&
                <ConnectionRemoveDialog
                    connection={connectionToRemove}
                    sourceType={sourceType}
                    onClose={() => setConnectionToRemove(undefined)}
                    onRemove={() => onConnectionRemove(connectionToRemove.id)} />
            }
        </>
    );
}

function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        user: state.user,
        integrations: new Integrations(state.tenant.subscription.integrations)
    };
}

export default connect(mapStateToProps)(ConnectionSelect);