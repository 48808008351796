import * as React from "react";
import { PrimaryButton, Dialog, IModalProps, DialogType, DialogFooter, IDialogContentProps } from 'office-ui-fabric-react';

export const MultiActionConfirmationDialog: React.FunctionComponent<{
    onDismiss: () => void,
    dialogContentProps?: IDialogContentProps,
    modalProps?: IModalProps,
    actionButtonProps?: { onClick: () => void, text: string, disabled?: boolean }[]
}> = props => <Dialog
    maxWidth={600}
    hidden={false}
    onDismiss={props.onDismiss}
    dialogContentProps={{
        type: DialogType.close,
        ...props.dialogContentProps
    }}
    modalProps={{
        isBlocking: false,
        containerClassName: 'ms-dialogMainOverride',
        ...props.modalProps
    }}>
    {props.children}
    <DialogFooter>
        {props.actionButtonProps?.map((_, index) => <PrimaryButton
            key={`action${index}`}
            onClick={() => {
                _.onClick?.();
                props.onDismiss();
            }}
            text={_.text}
            disabled={_.disabled} />)}
    </DialogFooter>
</Dialog>
