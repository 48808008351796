import "./SharePanel.css"
import * as React from "react";
import * as analytics from '../../../analytics';
import { DefaultButton, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { UserState } from "../../../store/User";
import { CommonOperations, contains, ResourceOperations } from "../../../store/permissions";
import { get, post } from "../../../fetch-interceptor";
import { ItemCreation } from "../ItemCreation";
import { defaultCatch } from "../../../store/utils";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import { Dictionary, EntityType, entityTypeLabelMap } from "../../../entities/common";
import { Portfolio } from "../../../store/PortfoliosListStore";
import { Program } from "../../../store/ProgramsListStore";
import { ProjectInfo } from "../../../store/ProjectsListStore";
import { IUser } from "../../../store/UsersListStore";
import { LayoutsState } from "../../../store/layouts";
import { ApplicationState } from '../../../store';
import { checkIfADUsersSyncEnabled, TenantState } from '../../../store/Tenant';
import { Roadmap } from "../../../store/roadmap/common";
import { Challenge } from "../../../store/ChallengesListStore";
import UsersForListPicker from "../inputs/UsersForListPicker";
import ShareFilters from "./ShareFilters";
import ShareUserList, { SharedWith } from "./ShareUserList";
import PrivateProjectLabel from "../PrivateProjectLabel";

type OwnProps = {
    entity: ProjectInfo | Portfolio | Program | Roadmap | Challenge;
    entityType: EntityType;
    onDismiss: () => void;
    layouts?: LayoutsState;
    hideView?: boolean;
    hideCollaborate?: boolean;
}
type ActionProps = {
    defaultCatch: (error: any) => void;
}

type StateProps = {
    user: UserState,
    tenant: TenantState,
    isADUsersSyncEnabled: boolean
};
type Props = OwnProps & StateProps & ActionProps;

const SharePanel = (props: Props) => {
    const shareUrl = `/api/${props.entityType}/${props.entity.id}/share`;
    const manager = props.entity.attributes.Manager;
    const [items, setItems] = React.useState<SharedWith[]>([]);
    const [modifiedUsers, setModifiedUsers] = React.useState<Dictionary<IUser>>(new Dictionary<IUser>());
    const [initialUsers, setInitialUsers] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showFilter, setShowFilter] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<Dictionary<unknown>>({});

    const isDirty = Object.keys(modifiedUsers).length > 0;
    const permissionEdit = "Edit";
    const permissionCollaborate = "Collaborate";

    React.useEffect(() => {
        setIsLoading(true);
        get<SharedWith[]>(shareUrl)
            .then(_ => {
                setItems(_.sort((a, b) => a.user.fullName === b.user.fullName
                    ? a.user.id > b.user.id ? 1 : -1
                    : a.user.fullName.localeCompare(b.user.fullName)));
                setInitialUsers(_.map(_ => _.user.id));
                setIsLoading(false);
            })
            .catch(props.defaultCatch);

    }, []);

    const { entityType, entity, layouts, tenant, hideView, hideCollaborate } = props;
    const isPrivateProject = entityType === EntityType.Project ? (entity as ProjectInfo)?.isPrivate : false;
    const entityTypeLabel = entityTypeLabelMap[entityType].singular;
        
    const _setModified = (user: IUser, isRemove: boolean = false) => {
        if (isRemove && !user.id) {
            delete modifiedUsers[user.email];
        }
        else {
            modifiedUsers[user.id ?? user.email] = user;
        }
        setModifiedUsers(modifiedUsers);
    }

    const onFilterChanged = React.useCallback((newFilter: Dictionary<unknown>) => {
        setFilter(newFilter);
    }, [filter]);

    const onChange = React.useCallback((item: SharedWith, delta: Partial<SharedWith>) => {
        _setModified(item.user);
        setItems(items.map(_ => item === _ ? { ..._, ...delta } : _));
    }, [items]);

    const onDelete = React.useCallback((item: SharedWith) => {
        _setModified(item.user, true);
        setItems(items.filter(u => u !== item))
    }, [items]);

    const _onConfirm = () => {

        const { hideCollaborate, hideView } = props;

        const data = Object.keys(modifiedUsers).map(id => {

            const modifiedUser = modifiedUsers[id];
            const item = modifiedUser.id ? items.find(_ => _.user.id == modifiedUser.id) : items.find(_ => !_.user.id && _.user.email == modifiedUser.email);
            //if item == null it was deleted in items list
            const viewChecked = item && !item.view.isInherited && !item.view.isGlobal && item.view.isAvailable;
            const collaborateChecked = item && !item.collaborate.isInherited && !item.collaborate.isGlobal && item.collaborate.isAvailable;
            const editChecked = item && !item.edit.isInherited && !item.edit.isGlobal && item.edit.isAvailable;

            const operations = ((viewChecked || collaborateChecked || editChecked) && !hideView ? ResourceOperations.Read : ResourceOperations.None)
                | ((collaborateChecked || editChecked) && !hideCollaborate ? ResourceOperations.Collaborate : ResourceOperations.None)
                | (editChecked ? ResourceOperations.Update : ResourceOperations.None);

            return { userId: !!item ? item.user.id : modifiedUser.id, email: modifiedUser.email, operations, layoutId: item?.layoutId };
        });

        post(shareUrl, data)
            .then(() => props.onDismiss())
            .catch(props.defaultCatch);

        analytics.trackEvent("Share entity", props.user, { itemType: props.entityType, itemTitle: props.entity.attributes.Name });
    }

    const _filterMap = {
        user: (filterValue: IUser[], item: SharedWith) => filterValue.some(_ => _ == item.user),
        permissions: (filterValue: Dictionary<boolean>, item: SharedWith) => {
            if (filterValue.hasOwnProperty(permissionEdit) && filterValue[permissionEdit] != !!item.edit.isAvailable) 
            {
                return false;
            }
            if (filterValue.hasOwnProperty(permissionCollaborate))
            {
                const collaborateValue = filterValue[permissionCollaborate];
                return collaborateValue == (!!item.collaborate.isAvailable || !!item.edit.isAvailable);
            }
            return true;
        },
    }

    const _isFilterApplied = (item: SharedWith): boolean => {
        for (let attrName of Object.keys(filter)) {
            const filterValue = filter[attrName];
            if (!_filterMap[attrName](filterValue, item))
                return false;
        }
        return true
    }

    const _isFilterEmpty = (): boolean => !Object.keys(filter).length;

    const _onFilterClick = () => {
        if (_isFilterEmpty()) {
            setShowFilter(!showFilter);
        } else {
            setFilter({});
        }
    }

    const _renderContent = () => {
        const canInvite = !props.isADUsersSyncEnabled
            && (!!tenant.security?.allowEveryoneToInviteUsers || contains(props.user.permissions?.common, CommonOperations.Administrate));
        const filteredItems = items.filter(_ => _isFilterApplied(_));

        return <div className="share-panel-container">
            <UsersForListPicker
                alreadyAddedIds={items.filter(_ => !!_.user.id).map(_ => _.user.id)}
                alreadyAddedText="Already shared with"
                onSelected={_ => {
                    _setModified(_);
                    setItems([{
                        user: _,
                        edit: {},
                        collaborate: {},
                        view: { isAvailable: true }
                    },
                    ...items])
                }}
                canInvite={canInvite} />
            <div className="share-panel-filter flex-space-between">
                <div className="shared-count">
                    {items.length} people in this {entityTypeLabel.toLowerCase()}
                </div>
                <div>
                    <DefaultButton className="filter-button"
                        onClick={_onFilterClick}
                        iconProps={{ iconName: _isFilterEmpty() ? "Filter" : "ClearFilter" }}>
                        {_isFilterEmpty() ? "Filter" : "Clear Filter"}
                    </DefaultButton>
                </div>
            </div>
            {showFilter && <ShareFilters hideCollaborate={props.hideCollaborate} filter={filter} users={items.map(_ => _.user)} onFilterChanged={onFilterChanged} />}
            {!!filteredItems.length && <ShareUserList
                isPrivate={isPrivateProject}
                hideView={hideView} hideCollaborate={hideCollaborate} layouts={layouts}
                entityType={entityType} entityTypeLabel={entityTypeLabel} filteredItems={filteredItems}
                manager={manager} initialUsers={initialUsers} onChange={onChange} onDelete={onDelete} />}
        </div>
    }

    return <ItemCreation
        className="share-panel"
        onDismiss={props.onDismiss}
        customWidth={hideCollaborate || hideView ? "550px" : "600px"}
        header={{
            showNameEditor: false,
            text: <>Share {entityTypeLabel}{isPrivateProject && <PrivateProjectLabel />}</>,
            secondaryText: <div>
                <div>
                    Search PPM Express users to share the {entityTypeLabel.toLowerCase()} "{entity.attributes.Name}" and grant permissions according to their license type or invite new users by email.
                </div>
                {props.isADUsersSyncEnabled && <div className="pt-2"><MessageBar messageBarType={MessageBarType.warning}>
                    As Azure Active Directory user synchronization is enabled, the invitations via {entityTypeLabel} sharing are disabled in PPM Express.
                    Users can join PPM Express only through Azure AD synchronization.
                    Please contact your PPM Express Administrator for details.
                </MessageBar></div>}
            </div>
        }}
        isDirty={isDirty}
        commands={isLoading ? [] : [{
            text: "Confirm",
            primary: true,
            onClick: _onConfirm,
            disabled: !isDirty
        },
        {
            text: "Cancel",
            onClick: props.onDismiss
        }]}>
        {
            isLoading
                ? <Spinner />
                : _renderContent()
        }
    </ItemCreation>;
}

function mergeActionCreators(dispatch: any) {
    return { defaultCatch: defaultCatch(dispatch) }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(SharePanel);

