import * as React from "react";
import {
    DefaultButton, DetailsListLayoutMode, ScrollablePane, ScrollbarVisibility, IColumn, Selection, IObjectWithKey,
    IDetailsHeaderProps, IRenderFunction, Sticky, StickyPositionType, ITooltipHostProps, TooltipHost, arraysEqual, CommandBar, IContextualMenuItem
} from 'office-ui-fabric-react';
import DetailsListWrapper from "../../common/DetailsListWrapper";
import RemoveDialog from "../../common/RemoveDialog";
import { SortService } from "../../../services/SortService";
import { CalendarException, CalendarUpdateModel } from "../../../store/CalendarStore";
import { CalendarExceptionEdit } from "./CalendarExceptionEdit";
import { nameof } from "../../../store/services/metadataService";
import EntityName from "../../views/list/columns/EntityName";
import { formatValue, notUndefined } from "../../utils/common";
import { SectionControlPlaceholder } from "../../common/sectionsControl/SectionPlaceholder";
import { DateFormatter } from "../../common/formatters/DateFormatter";
import { printDaysOfWeek } from "../../common/timeline/utils";
import { ControlSpiner } from "../../common/Spinner";
import { SortDirection } from "../../../entities/common";
import { TextFormatter } from "../../common/formatters/TextFormatter";
import { RowMenuColumn } from "../../../components/common/extensibleEntity/RowMenuColumn";
import { RowMenu } from "../../../components/common/extensibleEntity/RowMenu";
import { buildActionsMenuItemWithItems, csvImportExportIconProps } from '../../../components/common/headerMenuItemBuilders';
import SelectionModeSwitchableCommandBar from "../../common/SelectionModeSwitchableCommandBar";
import { MenuTitleBuilder } from "../../MenuTitleBuilder";

type Props = {
    isResource?: boolean;
    scrollable?: boolean;
    disabled?: boolean;
    isUpdating: boolean;
    exceptions: CalendarException[];
    save: (updates: Partial<CalendarUpdateModel>) => void;
    onExportToFile?: (fields: string[], ids: string[]) => void;
    onImportFromFile?: (file: File) => void;
};

type State = {
    sortBy: SortBy;
    exceptions: CalendarException[];
    selectedCount: number;
    selected: CalendarException[];
    itemsToRemove?: string[];
    isEditPanelOpen: boolean;
    editException?: CalendarException;
}

type SortBy = {
    fieldName: keyof CalendarException;
    direction: SortDirection;
}

interface ICalendarExceptionColumn extends IColumn { fieldName: keyof CalendarException; }

export class CalendarExceptionsList extends React.Component<Props, State> {
    private _selection: Selection;
    private _fileUpload: HTMLInputElement | null | undefined;

    constructor(props: Props) {
        super(props);

        const sortBy: SortBy = {
            fieldName: "start",
            direction: SortDirection.DESC
        };

        this.state = {
            sortBy: sortBy,
            exceptions: props.exceptions.sort(SortService.getSimpleComparer(sortBy)),
            selectedCount: 0,
            selected: [],
            isEditPanelOpen: false
        }

        this._selection = new Selection({
            onSelectionChanged: () => this.setState({
                selectedCount: this._selection.getSelectedCount(),
                selected: this._selection.getSelection().map(_ => _ as CalendarException)
            }),
            getKey: (_: IObjectWithKey) => (_ as CalendarException).id!
        });
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (!arraysEqual(this.props.exceptions, nextProps.exceptions)) {
            this.setState({ exceptions: nextProps.exceptions.sort(SortService.getSimpleComparer(this.state.sortBy)) })
        }
    }

    render() {
        const { isResource, isUpdating, disabled, scrollable } = this.props;
        const { selectedCount, selected, exceptions, editException, isEditPanelOpen, sortBy } = this.state;

        const list = <DetailsListWrapper
            layoutMode={DetailsListLayoutMode.justified}
            items={exceptions}
            columns={this._buildColumns(sortBy)}
            selection={this._selection}
            onColumnHeaderClick={this._onColumnHeaderClick}
            onRenderDetailsHeader={this._onRenderDetailsHeader} />;
        
        const commands = this._getCommands();
        const selectionModeCommands = this._getSelectionModeCommands()

        return <>
            {!exceptions.length
                ? <SectionControlPlaceholder
                    iconName="PPMXCalendarException"
                    title="Calendar Exceptions section is empty"
                    description="Calendar Exceptions have not been defined yet"
                >
                    {
                        !disabled && <>
                            <DefaultButton
                                text="Add Exception"
                                onClick={() => this.setState({ isEditPanelOpen: true })}
                            />
                            <DefaultButton
                                text="Import from File"
                                onClick={() => this._fileUpload?.click()}
                            />
                        </>
                    }
                </SectionControlPlaceholder>
                : <ControlSpiner isLoading={isUpdating} className="show-over">
                    {exceptions.length !== 0 && <div className="entities-list">
                    <SelectionModeSwitchableCommandBar
                        items={commands}
                        selectionMode={{
                            enabled: selectedCount > 0,
                            items: selectionModeCommands,
                            onCancel: () => this._selection.setAllSelected(false),
                        }}
                    />
                        <div data-is-scrollable={scrollable} className="entities-list-body">
                            {scrollable ? <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{list}</ScrollablePane> : list}
                        </div>
                    </div>}
                </ControlSpiner>}
            {!this.state.isEditPanelOpen && this.state.itemsToRemove && this._renderRemoveDialog()}
            {isEditPanelOpen && <CalendarExceptionEdit
                isResource={isResource}
                exception={editException}
                exceptions={exceptions}
                readOnly={this.props.disabled}
                onDismiss={() => this.setState({ isEditPanelOpen: false, editException: undefined })}
                onEditComplete={(exception: CalendarException) => this.addOrUpdateException(exception)}
                onDelete={(id: string) => this.setState({ itemsToRemove: [id] })}
            >
                {this.state.itemsToRemove && this._renderRemoveDialog()}
            </CalendarExceptionEdit>}
            <input ref={(ref) => this._fileUpload = ref} style={{ display: "none" }} type="file" accept=".csv"
                onChange={(e) => {
                    if (e.target && e.target.files && e.target.files[0]) {
                        this.props.onImportFromFile?.(e.target.files[0]);
                    }
                    e.target.value = '';
                }} />
        </>;
    }

    private _onEdit = (exception: CalendarException) => {
        this.setState({ isEditPanelOpen: true, editException: exception })
    }

    private _onDelete = (ids: string[]) => {
        this.setState({ itemsToRemove: ids })
    }

    private _getItemCommands = (item: CalendarException) => {
        const { selected } = this.state;
        return [
            {
                key: 'updateRow',
                name: "Edit",
                iconProps: { iconName: 'Edit' },
                disabled: selected.length !== 1,
                onClick: () => this._onEdit(item)
            },
            {
                key: 'deleteRow',
                name: "Delete",
                iconProps: { iconName: "Delete", style: { color: 'red' } },
                disabled: selected.length < 1,
                onClick: () => this._onDelete(selected.map(_ => _.id!))
            },
        ];
    }

    private _onExport = () => {
        const fields = this._buildColumns(this.state.sortBy).map(_ => _.fieldName).filter(notUndefined);
        const selectedIds = this._getSelectedIds();
        
        if (selectedIds.length) {
            this.props.onExportToFile?.(fields, selectedIds);
        }

        this._selection.setAllSelected(false);
    }

    private _getSelectedIds = () => this.state.selected.map(_ => _.id).filter(notUndefined);

    private _getCommands(): IContextualMenuItem[] {
        return [
            {
                key: 'addRow',
                name: "New Exception",
                iconProps: { iconName: 'Add' },
                disabled: this.props.disabled,
                onClick: () => this.setState({ isEditPanelOpen: true })
            },
            buildActionsMenuItemWithItems([
                {
                    key: 'importRows',
                    name: 'Import from CSV',
                    title: MenuTitleBuilder.importFromCSVTitle("Calendar Exceptions"),
                    disabled: this.props.disabled,
                    iconProps: csvImportExportIconProps,
                    onClick: () => this._fileUpload?.click()
                },
            ])
        ];
    }
    
    private _getSelectionModeCommands(): IContextualMenuItem[] {
        const { disabled } = this.props;
        const { selected } = this.state;
        return [
            {
                key: 'exportRows',
                name: 'Export to CSV',
                title: MenuTitleBuilder.exportSelectedToCSVTitle("Calendar Exceptions"),
                iconProps: csvImportExportIconProps,
                onClick: () => this._onExport()
            },
            {
                key: 'deleteRow',
                name: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle("Calendar Exceptions"),
                iconProps: { iconName: 'Delete' },
                disabled: disabled,
                onClick: () => this._onDelete(selected.map(_ => _.id!))
            },
        ];
    }
    
    private _renderRemoveDialog() {
        const { selected } = this.state;
        return <RemoveDialog
            onClose={() => this.setState({ itemsToRemove: undefined })}
            onComplete={() => {
                this.removeExceptions(selected.map(_ => _.id!));
                this.setState({ itemsToRemove: undefined, isEditPanelOpen: false, editException: undefined });
            }}
            confirmButtonProps={{ text: "Delete" }}
            dialogContentProps={{
                title: `Delete exception${selected.length > 1 ? "s" : ""}`,
                subText: selected.length === 1
                    ? `Are you sure you want to delete '${selected[0].name}' exception?`
                    : `Are you sure you want to delete ${selected.length} exceptions?`
            }} />;
    }

    private addOrUpdateException(exception: CalendarException) {
        const { exceptions } = this.state;
        const index = exceptions.findIndex(_ => _.id == exception.id)
        if (~index) {
            exceptions[index] = exception;
            this.setState({ exceptions });
            this.props.save({ toUpdate: [exception] });
        } else {
            exceptions.push(exception);
            this.setState({ exceptions });
            this.props.save({ toCreate: [exception] });
        }
    }

    private removeExceptions(ids: string[]) {
        let exceptions = this.state.exceptions.filter(_ => ids.indexOf(_.id!) === -1);
        this.setState({ exceptions });
        this.props.save({ toDelete: ids });
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private _buildColumns = (sortBy: SortBy): IColumn[] => {
        return [
            {
                key: "name",
                fieldName: nameof<CalendarException>("name"),
                name: "Name",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: CalendarException) => <RowMenuColumn
                    onItemMenuRender={this.props.disabled ? undefined : () => <RowMenu item={item} commands={this._getItemCommands(item)} />} >
                    <EntityName
                        className='hover-underline'
                        iconName="PPMXCalendarException"
                        name={item.name}
                        withDates={item.start.getTime() != item.end.getTime()}
                        withSingleDate={item.start.getTime() == item.end.getTime()}
                        isTimelineView
                        startDate={item.start}
                        finishDate={item.end}
                        singleDate={item.start}
                        onClick={() => this.setState({ isEditPanelOpen: true, editException: item })} />
                </RowMenuColumn>
            },
            {
                key: "expectedHrs",
                fieldName: nameof<CalendarException>("expectedHrs"),
                name: "Expected Hours",
                minWidth: 120,
                maxWidth: 120,
                onRender: (item: CalendarException) => <>{formatValue(item.expectedHrs)} hrs</>
            },
            {
                key: "start",
                fieldName: nameof<CalendarException>("start"),
                name: "Start",
                minWidth: 100,
                maxWidth: 100,
                onRender: (item: CalendarException) => <DateFormatter value={item.start} />
            },
            {
                key: "end",
                fieldName: nameof<CalendarException>("end"),
                name: "End",
                minWidth: 100,
                maxWidth: 100,
                onRender: (item: CalendarException) => <DateFormatter value={item.end} />
            },
            {
                key: "days",
                fieldName: nameof<CalendarException>("days"),
                name: "Days of Week",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: CalendarException) => {
                    const val = printDaysOfWeek(item.days);
                    return <span className="ellipsis" title={val}>{val}</span>;
                }
            },
            {
                key: "description",
                fieldName: nameof<CalendarException>("description"),
                name: "Description",
                minWidth: 300,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: CalendarException) => {
                    return <span className="calendar-exception-description" title={item.description}>
                        <TextFormatter isMultiline value={item.description} />
                    </span>
                }
            }
        ].map(_ => ({
            ..._,
            isSorted: _.fieldName == sortBy.fieldName,
            isSortedDescending: _.fieldName == sortBy.fieldName && sortBy.direction == SortDirection.DESC
        }));
    }

    private _onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: ICalendarExceptionColumn) => {
        if (!column) return;
        const { fieldName, direction } = this.state.sortBy;
        const sortBy = column.fieldName === fieldName
            ? {
                fieldName: fieldName,
                direction: direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
            }
            : {
                fieldName: column.fieldName,
                direction: SortDirection.ASC
            }

        this.setState({
            sortBy: sortBy,
            exceptions: this.state.exceptions.map(_ => _).sort(SortService.getSimpleComparer(sortBy))
        });
    }
}