import { arraysEqual } from 'office-ui-fabric-react';
import * as React from 'react';
import { Dictionary } from '../../../entities/common';
import { LIST_ROW_HEIGHT } from '../extensibleEntity/EntityDetailsList';
import { RelationsAlignmentManager } from "./RelationsAlignmentManager";
import { ICanvasInnerComponentProps } from "./TimelineBody";
import { calcTimelineElementCommandsPanelWidth, TimelineElementCommandsPanel } from './TimelineElementCommandsPanel';
import { RowPositionMap } from './TimelineList';
import { ITimelineMarker, largeModeRhombTopShift, rhombWidth, TimelineMarker } from "./TimelineMarker";
import { IScale } from './TimelineScale';
import { ITimelineSegment, TimelineSegment } from "./TimelineSegment";

export enum TimelineRelationType {
    RightToLeft = 0,
    RightToRight = 1,
    LeftToRight = 2,
    LeftToLeft = 3,
}
export interface RelationItemSegment extends ITimelineSegment {
    type: TimelineEntityType.Segment;
}
export interface RelationItemMarker extends ITimelineMarker {
    type: TimelineEntityType.Marker;
}
export interface ITimelineRelation {
    key: string;
    entity?: any;
    isReverse?: boolean;
    type: TimelineRelationType;
    parent: RelationItemSegment | RelationItemMarker;
    child: RelationItemSegment | RelationItemMarker;
}

export enum TimelineEntityType {
    Marker,
    Segment
}

export interface IPoint {
    x: number;
    y: number;
}

export interface ILine {
    topOffset: number;
    start: IPoint;
    end: IPoint;
}

export enum AnchorSide {
    Right,
    Left,
    Top,
    Bottom,
    TopLeft,
    BottomLeft
}

interface ITimelineRelationState {
    lines: ILine[];
    selected?: boolean;
}

type ITimelineRelationProps = {
    rowPositionMap: RowPositionMap;
    currentRowPosition: number;
    relationsAlignmentManager: RelationsAlignmentManager;
    availableSpaceMap?: Dictionary<Dictionary<number>>;
} & ICanvasInnerComponentProps<ITimelineRelation>;

const arrowAngleRadius = 5;

export class TimelineRelation extends React.Component<ITimelineRelationProps, ITimelineRelationState> {

    constructor(props: ITimelineRelationProps) {
        super(props);
        this.state = this._buildState(props);
    }

    public render() {
        const { relationsAlignmentManager, hideCommandPanel, data } = this.props;
        const { lines } = this.state;
        const selected = this._isSelected();
        if (lines.length === 0) {
            return null;
        }
        const commands = this.props.buildCommandsMenu?.(data);
        return <>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                className={`timeline-relation ${selected ? 'selected' : ''}`}>
                <defs>
                    <marker id="arrowhead" markerWidth="6" markerHeight="10" refX="6" refY="5" orient="auto">
                        <polygon points="0 0, 6 5, 0 10" />
                    </marker>
                    <marker id="arrowhead-selected" markerWidth="6" markerHeight="10" refX="6" refY="5" orient="auto" >
                        <polygon points="0 0, 6 5, 0 10" />
                    </marker>
                </defs>
                {
                    lines.map((_, index) => this._drawRelation(_, relationsAlignmentManager, this.props.data, index))
                }
            </svg>
            {selected && !hideCommandPanel && !!commands?.length &&
                <TimelineElementCommandsPanel
                    commands={commands}
                    timelineWidth={this.props.timelineWidth}
                    {...this._calcCommandsBarPosition(lines[0], relationsAlignmentManager, this.props.data, commands.length)} />}
        </>;
    }

    private _isSelected = () => this.props.isSelected === undefined ? this.state.selected : this.props.isSelected;

    private _onClick = (ev: React.MouseEvent) => {
        ev.stopPropagation();
        if (!this.props.onClick) {
            this.setState({ selected: !this.state.selected });
        } else {
            this.props.onClick(this.props.data, ev);
        }
    }

    private _drawRelation(line: ILine, relationsAlignmentManager: RelationsAlignmentManager, relation: ITimelineRelation, index: number) {
        const p = this._calcLineParams(line, relationsAlignmentManager, relation);
        const selected = this._isSelected();

        let result = `M${p.start.x} ${p.start.y}`
        if (p.arrowStartHorisontalOffset) {
            result += `${this._buildLineWithAngle('H', p.start.x, p.start.y, p.arrowStartHorisontalOffset, p.arrowVerticalOffset ?? p.end.y)}`;
        }
        if (p.arrowVerticalOffset) {
            result += `${this._buildLineWithAngle('V', p.arrowStartHorisontalOffset ?? p.start.x, p.start.y, p.arrowEndHorisontalOffset ?? p.end.x, p.arrowVerticalOffset)}`;
        }
        if (p.arrowEndHorisontalOffset) {
            result += `${this._buildLineWithAngle('H', p.arrowStartHorisontalOffset ?? p.start.x, p.arrowVerticalOffset ?? p.start.y, p.arrowEndHorisontalOffset, p.end.y)}`;
            result += `${this._buildLineWithAngle('V', p.arrowEndHorisontalOffset ?? p.arrowStartHorisontalOffset ?? p.start.x, p.arrowVerticalOffset ?? p.start.y,
                p.end.x, p.end.y)}`;
        }

        result += `H ${p.end.x}`;

        const className = `relation-arrow ${selected ? 'selected' : ''}`
        const markerEnd = selected ? "url(#arrowhead-selected)" : "url(#arrowhead)";
        return <g key={index}>
            <path className={className} markerEnd={markerEnd}
                d={result} />
            <path onClick={this._onClick}
                className="arrow-background" fill="none" stroke="transparent" strokeWidth="5px"
                d={result} />
        </g>;
    }

    private _calcLineParams(line: ILine, relationsAlignmentManager: RelationsAlignmentManager, relation: ITimelineRelation) {
        const arrowStartHorisontalOffsetPixels = 8;
        const arrowEndHorisontalOffsetPixels = 16;
        const arrowVerticalOffsetUpFromMiddlePixels = -28;
        const arrowVerticalOffsetDownFromMiddlePixels = 27;
        let start = { x: line.start.x, y: line.start.y };
        let end = { x: line.end.x, y: line.end.y };
        let arrowStartHorisontalOffset: number | undefined;
        let arrowVerticalOffset: number | undefined;
        let arrowEndHorisontalOffset: number | undefined;

        const parentUniqKey = !relation.isReverse ? relation.parent.key : relation.child.key;
        const childUniqKey = !relation.isReverse ? relation.child.key : relation.parent.key;
        const shift = this.props.rowPositionMap.shift;
        if (!!shift?.x || !!shift?.y) {
            if (shift.key === parentUniqKey && (shift.type === 'both' ||
                relation.type === TimelineRelationType.RightToRight && shift.type === 'right' ||
                relation.type === TimelineRelationType.RightToLeft && shift.type === 'right' ||
                relation.type === TimelineRelationType.LeftToRight && shift.type === 'left' ||
                relation.type === TimelineRelationType.LeftToLeft && shift.type === 'left')) {
                start.x += shift.x ?? 0;
                start.y += shift.y ?? 0;
            } else if (shift.key === childUniqKey && (shift.type === 'both' ||
                relation.type === TimelineRelationType.RightToRight && shift.type === 'right' ||
                relation.type === TimelineRelationType.RightToLeft && shift.type === 'left' ||
                relation.type === TimelineRelationType.LeftToRight && shift.type === 'right' ||
                relation.type === TimelineRelationType.LeftToLeft && shift.type === 'left')) {
                end.x += shift.x ?? 0;
                end.y += shift.y ?? 0;
            }
        }

        if (relation.type === TimelineRelationType.RightToLeft) {
            if (start.y === end.y && end.x < start.x
                || start.y !== end.y && end.x - arrowEndHorisontalOffsetPixels < start.x + arrowStartHorisontalOffsetPixels ) {
                arrowStartHorisontalOffset = start.x + arrowStartHorisontalOffsetPixels;
                arrowVerticalOffset = start.y + (start.y <= end.y ? arrowVerticalOffsetDownFromMiddlePixels : arrowVerticalOffsetUpFromMiddlePixels);
                arrowEndHorisontalOffset = end.x - arrowEndHorisontalOffsetPixels;
            }
            else if (start.y !== end.y) {
                arrowEndHorisontalOffset = end.x - arrowEndHorisontalOffsetPixels;
            }
        } else if (relation.type === TimelineRelationType.RightToRight) {
            if (end.x + arrowEndHorisontalOffsetPixels < start.x + arrowStartHorisontalOffsetPixels) {
                arrowEndHorisontalOffset = start.x + arrowStartHorisontalOffsetPixels;
            } else {
                arrowEndHorisontalOffset = end.x + arrowEndHorisontalOffsetPixels;
            }
        } else if (relation.type === TimelineRelationType.LeftToRight) {
            if (start.y === end.y && end.x > start.x
                || start.y !== end.y && end.x + arrowEndHorisontalOffsetPixels > start.x - arrowStartHorisontalOffsetPixels) {
                arrowStartHorisontalOffset = start.x - arrowStartHorisontalOffsetPixels;
                arrowVerticalOffset = start.y + (start.y <= end.y ? arrowVerticalOffsetDownFromMiddlePixels : arrowVerticalOffsetUpFromMiddlePixels);
                arrowEndHorisontalOffset = end.x + arrowEndHorisontalOffsetPixels;
            }
            else if (start.y !== end.y) {
                arrowEndHorisontalOffset = end.x + arrowEndHorisontalOffsetPixels;
            }
        } else if (relation.type === TimelineRelationType.LeftToLeft) {
            if (end.x - arrowEndHorisontalOffsetPixels > start.x - arrowStartHorisontalOffsetPixels) {
                arrowEndHorisontalOffset = start.x - arrowStartHorisontalOffsetPixels;
            } else {
                arrowEndHorisontalOffset = end.x - arrowEndHorisontalOffsetPixels;
            }
        }

        if (arrowStartHorisontalOffset) {
            const arrowStartOffsetGapPixels = 4;
            arrowStartHorisontalOffset = relationsAlignmentManager.applyHorisontalOffset(arrowStartHorisontalOffset, line.topOffset,
                start.y, arrowVerticalOffset ?? end.y, parentUniqKey, arrowStartOffsetGapPixels);
        }

        if (arrowEndHorisontalOffset) {
            const arrowEndGapOffsetPixels = -12;
            arrowEndHorisontalOffset = relationsAlignmentManager.applyHorisontalOffset(arrowEndHorisontalOffset, line.topOffset,
                arrowVerticalOffset ?? start.y, end.y, parentUniqKey, arrowEndGapOffsetPixels);
        }

        return {
            start,
            end,
            arrowStartHorisontalOffset,
            arrowVerticalOffset,
            arrowEndHorisontalOffset,
        };
    }

    private _calcCommandsBarPosition = (line: ILine, relationsAlignmentManager: RelationsAlignmentManager, relation: ITimelineRelation,
        commandsCount: number): { x: number, y: number } => {
        const commandsBarWidth = calcTimelineElementCommandsPanelWidth(commandsCount);
        const commndsBarVOffset = 1;
        const halfSizeDivider = 2;
        const sizeDiff = 10;
        const p = this._calcLineParams(line, relationsAlignmentManager, relation);
        const calcWidth = (from: number, to: number) => Math.abs(to - from) - sizeDiff;

        if (p.arrowEndHorisontalOffset) {
            if (calcWidth(p.arrowStartHorisontalOffset ?? p.start.x, p.arrowEndHorisontalOffset) > commandsBarWidth) {
                return {
                    x: (p.arrowStartHorisontalOffset ?? p.start.x) + (p.arrowEndHorisontalOffset - (p.arrowStartHorisontalOffset ?? p.start.x)) / halfSizeDivider
                        - commandsBarWidth / halfSizeDivider,
                    y: (p.arrowVerticalOffset ?? p.start.y) + commndsBarVOffset
                };
            }
            return {
                x: (p.arrowEndHorisontalOffset ?? p.arrowStartHorisontalOffset ?? p.start.x) - commandsBarWidth / halfSizeDivider,
                y: (p.arrowVerticalOffset ?? p.start.y) + (p.end.y - (p.arrowVerticalOffset ?? p.start.y)) / halfSizeDivider + commndsBarVOffset
            };
        }

        return {
            x: p.start.x + (p.end.x - p.start.x) / halfSizeDivider - commandsBarWidth / halfSizeDivider,
            y: p.start.y + commndsBarVOffset
        };
    }

    private _buildLineWithAngle(vh: 'V' | 'H', fromX: number, fromY: number, toX: number, toY: number) {
        const numberOfAnglesOnSection = 2;
        const radius = Math.min(arrowAngleRadius, Math.abs(fromY - toY) / numberOfAnglesOnSection, Math.abs(fromX - toX) / numberOfAnglesOnSection)
        const down = toY > fromY;
        const right = toX > fromX;
        if (vh === 'V') {
            return `V ${down ? toY - radius : toY + radius} A${radius} ${radius} 0 0 ${down !== right ? 1 : 0} ${right ? fromX + radius : fromX - radius} ${toY}`;
        }
        return `H ${right ? toX - radius : toX + radius} A${radius} ${radius} 0 0 ${down === right ? 1 : 0} ${toX} ${down ? fromY + radius : fromY - radius}`
    }

    componentWillReceiveProps(props: ITimelineRelationProps) {
        if (props.scale != this.props.scale
            || props.timelineWidth != this.props.timelineWidth
            || props.largeMode !== this.props.largeMode
            || this.isNotEqual(props.data.parent, this.props.data.parent)
            || this.isNotEqual(props.data.child, this.props.data.child)
            || props.currentRowPosition !== this.props.currentRowPosition
            || props.rowPositionMap.lineByEntityIdMap[props.data.child.key] === undefined
            || this.props.rowPositionMap.lineByEntityIdMap[props.data.child.key] === undefined
            || !arraysEqual(props.rowPositionMap.lineByEntityIdMap[props.data.child.key], this.props.rowPositionMap.lineByEntityIdMap[props.data.child.key])) {
            this.setState(this._buildState(props));
        }
    }

    private isNotEqual(newValue: RelationItemSegment | RelationItemMarker, oldValue: RelationItemSegment | RelationItemMarker) {
        return newValue.type !== oldValue.type
            || newValue.type === TimelineEntityType.Marker && oldValue.type === TimelineEntityType.Marker && newValue.date.getTime() !== oldValue.date.getTime()
            || newValue.type === TimelineEntityType.Segment && oldValue.type === TimelineEntityType.Segment
            && (newValue.startDate.getTime() !== oldValue.startDate.getTime() || newValue.finishDate.getTime() !== oldValue.finishDate.getTime())
    }

    private _buildState(props: ITimelineRelationProps): ITimelineRelationState {
        const state: ITimelineRelationState = {
            lines: []
        };
        if (props.currentRowPosition === undefined || props.rowPositionMap.lineByEntityIdMap[props.data.child.key] === undefined) {
            return state;
        }
        for (const rowPositionMapChild of props.rowPositionMap.lineByEntityIdMap[props.data.child.key]) {
            const rowsOffset = (rowPositionMapChild - props.currentRowPosition) * LIST_ROW_HEIGHT;

            let line;
            if (!props.data.isReverse) {
                line = this._calculateArrow(props.data.parent, props.data.child, props.data.type, props, rowsOffset);
            } else {
                line = this._calculateArrow(props.data.child, props.data.parent, props.data.type, props, -1 * rowsOffset);
                if (line) {
                    line.start.y += rowsOffset;
                    line.end.y += rowsOffset;
                }
            }

            if (line) {
                state.lines.push({ ...line, topOffset: props.currentRowPosition * LIST_ROW_HEIGHT })
            }
        }
        return state;
    }

    private _getAnchor(item: RelationItemSegment | RelationItemMarker, side: AnchorSide, scale: IScale, timelineWidth: number, largeMode?: boolean): IPoint | undefined {
        const rhombTopOffset = 3.5;
        const halve = 2;

        if (item.type === TimelineEntityType.Segment) {
            const segment = TimelineSegment.calculateSegment(scale, item, timelineWidth);
            if (segment.isOutOfScale) {
                return undefined;
            }
            const largeModeSegmentShift = 12;
            const largeModeTopOffset = largeMode ? largeModeSegmentShift : 0;
            switch (side) {
                case AnchorSide.Right:
                    return {
                        x: segment.left + segment.width,
                        y: TimelineSegment.minSegmentWidth / halve
                    };
                case AnchorSide.Left:
                    return {
                        x: segment.left,
                        y: TimelineSegment.minSegmentWidth / halve
                    };
                case AnchorSide.TopLeft:
                    return {
                        x: segment.left + TimelineSegment.minSegmentWidth / halve,
                        y: 0 - largeModeTopOffset
                    };
                case AnchorSide.BottomLeft:
                    return {
                        x: segment.left + TimelineSegment.minSegmentWidth / halve,
                        y: TimelineSegment.minSegmentWidth + largeModeTopOffset
                    };
                case AnchorSide.Top:
                    return {
                        x: segment.left + segment.width / halve,
                        y: 0 - largeModeTopOffset
                    };
                case AnchorSide.Bottom:
                    return {
                        x: segment.left + segment.width / halve,
                        y: TimelineSegment.minSegmentWidth + largeModeTopOffset
                    };
                default:
                    return undefined;
            }

        } else {
            if (item.availableSpace === undefined) {
                item.availableSpace = this.props.availableSpaceMap?.[this.props.rowPositionMap?.lineByEntityIdMap[item.key]?.[0]]?.[item.key]
            }
            const marker = TimelineMarker.calculateMarker(scale, item, timelineWidth);
            if (marker.isOutOfScale) {
                return undefined;
            }

            const largeModeTopOffset = largeMode && item.datesVisibility !== undefined ? largeModeRhombTopShift : 0;
            switch (side) {
                case AnchorSide.Right:
                    return {
                        x: marker.left + marker.width,
                        y: rhombWidth / halve + rhombTopOffset + largeModeTopOffset
                    };
                case AnchorSide.Left:
                    return {
                        x: marker.left,
                        y: rhombWidth / halve + rhombTopOffset + largeModeTopOffset
                    };
                case AnchorSide.TopLeft:
                    return {
                        x: marker.left + rhombWidth / halve,
                        y: rhombTopOffset + largeModeTopOffset
                    };
                case AnchorSide.BottomLeft:
                    return {
                        x: marker.left + rhombWidth / halve,
                        y: rhombWidth + rhombTopOffset + largeModeTopOffset
                    };
                case AnchorSide.Top:
                    return {
                        x: marker.left + marker.width / halve,
                        y: rhombTopOffset + largeModeTopOffset
                    };
                case AnchorSide.Bottom:
                    return {
                        x: marker.left + marker.width / halve,
                        y: rhombWidth + rhombTopOffset + largeModeTopOffset
                    };
                default:
                    return undefined;
            }
        }
    }

    private _calculateArrow(from: RelationItemSegment | RelationItemMarker, to: RelationItemSegment | RelationItemMarker,
        type: TimelineRelationType, props: ITimelineRelationProps, rowsOffset: number): { start: IPoint, end: IPoint } | undefined {
        const start = this._getAnchor(from,
            (type === TimelineRelationType.RightToLeft || type === TimelineRelationType.RightToRight) ? AnchorSide.Right : AnchorSide.Left,
            props.scale, props.timelineWidth, props.largeMode);
        const end = this._getAnchor(to,
            (type === TimelineRelationType.RightToLeft || type === TimelineRelationType.LeftToLeft) ? AnchorSide.Left : AnchorSide.Right,
            props.scale, props.timelineWidth, props.largeMode);

        if (!start || !end) {
            return undefined;
        }

        return {
            start: start,
            end: {
                x: end.x,
                y: end.y + rowsOffset
            }
        };
    }
}