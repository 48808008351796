import * as Metadata from '../../entities/Metadata';
import { post } from '../../fetch-interceptor';
import * as Index from "../index";
import { Dictionary, EntityType } from "../../entities/common";
import { defaultCatch } from "../utils";
import { LayoutSectionsUpdatedAction, UIControlSettingsUpdatedAction } from '../layouts';
import { LayoutService } from '../../components/utils/LayoutService';

export interface ControlSettingsUpdate {
    propertyPath?: string[];
    value: any;
}

export class MetadataService {
    public static addField<T extends ExtensibleEntity>(entity: T, fieldInfo: SectionFieldInfo): T {
        const newEntity: T = Object.assign({}, entity);
        const section = newEntity.sections.find((value: Metadata.Section) => {
            return value.id === fieldInfo.sectionId;
        });
        if (!section) {
            throw new DOMException();
        }
        const uiControl = section.uiControls.find(_ => _.id === fieldInfo.uiControlId);
        if (!uiControl) {
            throw new DOMException();
        }

        const fields = uiControl.settings.fields as string[];
        const currentIndex = fields.findIndex((_) => _ === fieldInfo.field.name);
        if (~currentIndex) {
            fields.splice(currentIndex, 1);
        }

        fields.splice(fieldInfo.index, 0, fieldInfo.field.name);
        return newEntity;
    }

    public static removeField<T extends ExtensibleEntity>(entity: T, removeSectionFieldInfo: SectionRemoveFieldInfo, fieldsDictionary: Dictionary<Metadata.Field>): T {
        const newEntity: T = Object.assign({}, entity);
        const section = newEntity.sections.find((value: Metadata.Section) => {
            return value.id === removeSectionFieldInfo.sectionId;
        });
        if (!section) {
            throw new DOMException();
        }
        const uiControl = section.uiControls.find(_ => _.id === removeSectionFieldInfo.uiControlId);
        if (!uiControl) {
            throw new DOMException();
        }

        const removedField: Metadata.Field = fieldsDictionary[removeSectionFieldInfo.fieldId]

        const fields: string[] = uiControl.settings.fields;
        const fieldIdx = fields.findIndex((_) => _ === removedField.name);
        if (~fieldIdx) {
            fields.splice(fieldIdx, 1);
        }

        return newEntity;
    }

    public static UpdateUIControlSettings<T extends ExtensibleEntity>(entity: T, uiControlInfo: UpdateUIControlInfo): T {
        const newEntity: T = Object.assign({}, entity);
        const section = entity.sections.find((value: Metadata.Section) => {
            return value.id === uiControlInfo.sectionId;
        });
        if (!section) {
            throw new DOMException();
        }
        const uiControl = section.uiControls.find(_ => _.id === uiControlInfo.uiControlId);
        if (!uiControl) {
            throw new DOMException();
        }
        uiControl.settings = { ...uiControl.settings, ...uiControlInfo.settings };
        return newEntity;
    }
}

export const ActionsBuilder = {
    buildCreateField: <TAction>(
        entityName: string,
        handler: (field: Metadata.Field, dispatch: (action: TAction) => void) => void,
        taskExecutionHandler?: (dispatch: (action: TAction) => void) => void) => (
            fieldModel: Dictionary<any>): Index.AppThunkAction<TAction> => (dispatch, getState) => {
                post<Metadata.Field>(`api/metadata/${entityName}/field`, fieldModel)
                    .then(data => handler(data, dispatch))
                    .catch(defaultCatch(dispatch));

                taskExecutionHandler && taskExecutionHandler(dispatch);
            },
    buildEntityUpdateSections: <TAction>(baseUrl: string, handler: (entityId: string, sections: Metadata.Section[], dispatch: (action: TAction) => void) => void) => (
        entityId: string,
        updates: Dictionary<Metadata.IUpdateSectionInfo>): Index.AppThunkAction<TAction> => (dispatch, getState) => {
            post<Metadata.Section[]>(`${baseUrl}/${entityId}/metadata/sections`, updates)
                .then(data => handler(entityId, data, dispatch))
                .catch(defaultCatch(dispatch));
        },
    buildEntityUpdatePinnedViews: <TAction>(baseUrl: string, handler: (entityId: string, pinnedViews: string[], dispatch: (action: TAction) => void) => void) => (
        entityId: string,
        pinnedViews: string[]): Index.AppThunkAction<TAction> => (dispatch, getState) => {
            post<string[]>(`${baseUrl}/${entityId}/metadata/pinnedViews`, { ids: pinnedViews })
                .then(data => handler(entityId, data, dispatch))
                .catch(defaultCatch(dispatch));
        },
    buildEntityUpdateUIControl: <TAction>(baseUrl: string, handler: (uiControlInfo: UpdateUIControlInfo, dispatch: (action: TAction) => void) => void) => (
        entityId: string,
        sectionId: string,
        uiControlId: string,
        settings: Dictionary<any>): Index.AppThunkAction<TAction> => (dispatch, getState) => {
            const updates = buildControlSettingsUpdatesFromSettings(settings);
            post<UpdateUIControlInfo>(`${baseUrl}/${entityId}/metadata/section/${sectionId}/uicontrol/${uiControlId}`, updates)
                .then(data => handler(data, dispatch))
                .catch(defaultCatch(dispatch));
        },
    buildEntityPartialUpdateUIControl: <TAction>(baseUrl: string, handler: (uiControlInfo: UpdateUIControlInfo, dispatch: (action: TAction) => void) => void) => (
        entityId: string,
        sectionId: string,
        uiControlId: string,
        update: ControlSettingsUpdate): Index.AppThunkAction<TAction> => (dispatch, getState) => {
            post<UpdateUIControlInfo>(`${baseUrl}/${entityId}/metadata/section/${sectionId}/uicontrol/${uiControlId}`, [update])
                .then(data => handler(data, dispatch))
                .catch(defaultCatch(dispatch));
        },
    buildEntityUpdateSectionsOnClient: <TAction>(handler: (entityId: string, sections: Metadata.Section[], dispatch: (action: TAction) => void) => void) => (
        entity: Metadata.IWithSections & { id: string; },
        updates: Dictionary<Metadata.IUpdateSectionInfo>): Index.AppThunkAction<TAction> => (dispatch, getState) => {
            LayoutService.updateSections(entity, updates);
            handler(entity.id, entity.sections, dispatch);
        },
    buildEntityUpdateUIControlOnClient: <TAction>(handler: (uiControlInfo: UpdateUIControlInfo, dispatch: (action: TAction) => void) => void) => (
        entityId: string,
        sectionId: string,
        uiControlId: string,
        settings: Dictionary<any>): Index.AppThunkAction<TAction> => (dispatch, getState) => {
            const uiControlInfo = { entityId, sectionId, uiControlId, settings };
            handler(uiControlInfo, dispatch);
        },
    buildEntityPartialUpdateUIControlOnClient: <TAction>(handler: (uiControlInfo: UpdateUIControlInfo, dispatch: (action: TAction) => void) => void) => (
        entityId: string,
        sectionId: string,
        uiControlId: string,
        update: ControlSettingsUpdate): Index.AppThunkAction<TAction> => (dispatch, getState) => {
            const uiControlInfo = {
                entityId, sectionId, uiControlId,
                //Cureently this used only in ScheduleControl, for VSTS cards configuration. 
                settings: { [update.propertyPath![0]]: { [update.propertyPath![1].toLowerCase()]: update.value } }
            };
            handler(uiControlInfo, dispatch);
        },
    buildLayoutUpdateUIControl: <TAction>(entityType: EntityType) => (
        sectionId: string,
        uiControlId: string,
        settings: Dictionary<any>,
        layoutId?: string): Index.AppThunkAction<TAction | UIControlSettingsUpdatedAction> => (dispatch, getState) => {
            const layouts = getState().layouts[entityType];
            const layout = layouts.allIds.map(__ => layouts.byId[__]).find(__ => layoutId ? __.id === layoutId : __.isDefault)!;
            const updates = buildControlSettingsUpdatesFromSettings(settings);
            post<UpdateUIControlInfo>(`api/metadata/${entityType}/layout/${layout.id}/section/${sectionId}/uicontrol/${uiControlId}`, updates)
                .then(_ => {
                    dispatch({ type: 'UICONTROL_SETTINGS_UPDATED', entity: entityType, layoutId: layout.id, sectionId, uiControlId, settings: _ });
                })
                .catch(defaultCatch(dispatch));
        },
    buildLayoutUpdateUIControlOnClient: <TAction>(entityType: EntityType) => (
        sectionId: string,
        uiControlId: string,
        settings: Dictionary<any>,
        layoutId?: string): Index.AppThunkAction<TAction | UIControlSettingsUpdatedAction> => (dispatch, getState) => {
            const layouts = getState().layouts[entityType];
            const layout = layouts.allIds.map(__ => layouts.byId[__]).find(__ => layoutId ? __.id === layoutId : __.isDefault)!;
            dispatch({ type: 'UICONTROL_SETTINGS_UPDATED', entity: entityType, layoutId: layout.id, sectionId, uiControlId, settings });
        },
    buildLayoutPartialUpdateUIControl: <TAction>(entityType: EntityType) => (
        sectionId: string,
        uiControlId: string,
        update: ControlSettingsUpdate,
        layoutId?: string): Index.AppThunkAction<TAction | UIControlSettingsUpdatedAction> => (dispatch, getState) => {
            const layouts = getState().layouts[entityType];
            const layout = layouts.allIds.map(__ => layouts.byId[__]).find(__ => layoutId ? __.id === layoutId : __.isDefault)!;
            post<UpdateUIControlInfo>(`api/metadata/${entityType}/layout/${layout.id}/section/${sectionId}/uicontrol/${uiControlId}`, [update])
                .then(_ => {
                    dispatch({ type: 'UICONTROL_SETTINGS_UPDATED', entity: entityType, layoutId: layout.id, sectionId, uiControlId, settings: _ });
                })
                .catch(defaultCatch(dispatch));
        },
    buildLayoutUpdateSections: <TAction>(entityType: EntityType, layoutId?: string) => (
        updates: Dictionary<Metadata.IUpdateSectionInfo>): Index.AppThunkAction<TAction | LayoutSectionsUpdatedAction> => (dispatch, getState) => {
            post<Metadata.Section[]>(`api/metadata/${entityType}/sections`, updates)
                .then(_ => {
                    const layouts = getState().layouts[entityType];
                    const layout = layouts.allIds.map(__ => layouts.byId[__]).find(__ => layoutId ? __.id === layoutId : __.isDefault);
                    dispatch({ type: 'LAYOUT_SECTIONS_UPDATED', entity: entityType, layoutId: layout!.id, sections: _ });
                })
                .catch(defaultCatch(dispatch));
        },
    buildLayoutUpdateSectionsOnClient: <TAction>(entityType: EntityType, layoutId?: string) => (
        updates: Dictionary<Metadata.IUpdateSectionInfo>): Index.AppThunkAction<TAction | LayoutSectionsUpdatedAction> => (dispatch, getState) => {
            const layouts = getState().layouts[entityType];
            const layout = layouts.allIds.map(__ => layouts.byId[__]).find(__ => layoutId ? __.id === layoutId : __.isDefault);
            LayoutService.updateSections(layout!, updates);
            dispatch({ type: 'LAYOUT_SECTIONS_UPDATED', entity: entityType, layoutId: layout!.id, sections: layout!.sections });
        }
}

function buildControlSettingsUpdatesFromSettings(settings: Dictionary<any>): ControlSettingsUpdate[] {
    return Object.keys(settings).map<ControlSettingsUpdate>(_ => ({ propertyPath: [_], value: settings[_] }));
}

export function buildSettingsFromControlSettingsUpdates(updates: ControlSettingsUpdate[]): Dictionary<any> {
    return updates.reduce((_, __) => intendValue(_, __.propertyPath, __.value), {});
}

function intendValue(obj: Dictionary<any>, keys: string[] | undefined, value: any): Dictionary<any> {
    if (keys === undefined || keys.length === 0) {
        return value;
    }
    return { ...obj, [keys[0]]: intendValue({}, keys.slice(1), value) };
}

interface ExtensibleEntity {
    attributes: Dictionary<any>;
    sections: Metadata.Section[];
}

export type SectionFieldInfo = { sectionId: string, uiControlId: string, field: Metadata.Field, index: number }
export type SectionRemoveFieldInfo = { sectionId: string, uiControlId: string, fieldId: string }
export type UpdateUIControlInfo = { entityId: string, sectionId: string, uiControlId: string, settings: Dictionary<any> }

export const namesof = <T>(names: Extract<keyof T, string>[]): string[] => names;
export const nameof = <T>(name: Extract<keyof T, string>, entity?: T): string => name;

export function toLowerCaseFirstLetter(str: string): string {
    return (str && str[0].toLowerCase() + str.slice(1)) || "";
}