import * as React from 'react';
import { Resource, filterUsage } from "../../../store/ResourcesListStore";
import { Toggle } from 'office-ui-fabric-react';
import LabellableComponent from '../../common/LabellableComponent';
import DatePickerInput from '../../common/inputs/DatePickerInput';
import { EntityType, IExtensibleEntity, IWithResourcePlan, MaybeDate } from '../../../entities/common';
import { toDate } from '../../utils/common';
import { diffDays, minMax } from '../../common/timeline/utils';
import { Validator } from '../../../validation';
import NumberInput from '../../common/inputs/NumberInput';
import { AllocationInfoPanel } from './AllocationInfoPanel';

type Props = {
    entityType: EntityType;
    byResource?: boolean;
    rows: { resource: Resource, entity: IExtensibleEntity & IWithResourcePlan }[];
    onComplete: (startDate: Date, daysShift: number, useCalendar: boolean) => void;
    onDismiss: () => void;
}

const validators: { [key: string]: Validator } = {
    earliestDate: Validator.new().required().build(),
    shift: Validator.new().required().int32().build()
}

export const ShiftAllocationPanel = (props: Props) => {
    const map = React.useMemo(() => (props.rows.reduce((cum, cur) => ({
        entities: { ...cum.entities, [cur.entity.id]: cur.entity },
        resources: { ...cum.resources, [cur.resource.id]: cur.resource }
    }), {
        entities: {},
        resources: {}
    })), [props.rows]);

    const allocationRange = React.useMemo(() => (minMax(props.rows
        .reduce((cum, cur) => [...cum, ... filterUsage(cur.resource.usage, cur.entity.id, _ => _.plannedHours !== 0).map(_ => _.date)], []))),
        [props.rows]);
    const [earliestDate, setEarliestDate] = React.useState<MaybeDate>(allocationRange.minDate);
    const [date, setDate] = React.useState<MaybeDate>();
    const [shift, setShift] = React.useState<number | null>(null);
    const [useCalendar, setUseCalendar] = React.useState<boolean>(true);

    const description = React.useMemo(() => {
        const lineLabel = props.byResource ? props.entityType : 'resource';
        const count = Object.keys(props.byResource ? map.entities : map.resources).length;
        return `Select a period to move the resource allocation (planned time) for ${count} selected ${lineLabel}${count ? '' : 's'}`
    }, [props.entityType, map]);

    const onConfirm = React.useCallback(() => {
        props.onComplete(toDate(earliestDate)!, shift!, useCalendar);
        props.onDismiss();
    }, [props.onDismiss, props.onComplete, earliestDate, shift, useCalendar]);

    const confirmButtonIsDisabled = React.useMemo(() => !Validator.isValid(validators, { earliestDate, shift }), [validators, earliestDate, shift])

    return <AllocationInfoPanel
        entityType={props.entityType}
        onConfirm={onConfirm}
        onDismiss={props.onDismiss}
        title="Shift Resource Allocation"
        description={description}
        confirmButtonText="Shift"
        confirmButtonIconName="Save"
        confirmButtonIsDisabled={confirmButtonIsDisabled}
        rows={props.rows}>
            <div className="grid-item">
                <LabellableComponent label="Shift From" className="field-container">
                <DatePickerInput
                    value={earliestDate instanceof Date ? earliestDate.toDateOnlyString() : earliestDate as string | undefined}
                        validator={validators.date}
                        onChanged={(value) => {
                            value = toDate(value);
                            setEarliestDate(value);
                            if (date) {
                                setShift(value ? Math.round(diffDays(value, toDate(date)!, true)) : null);
                            }
                        }} />
                </LabellableComponent>
            </div>
            <div className="grid-item half-width odd">
                <LabellableComponent label="Days" className="field-container">
                    <NumberInput
                        value={shift ?? undefined}
                        validator={validators.shift}
                        onChanged={(value) => {
                            setShift(value ?? null);
                            earliestDate && setDate(typeof value === 'number' ? toDate(earliestDate)!.clone().addDays(value) : undefined);
                        }} />
                </LabellableComponent>
            </div>
            <div className="grid-item half-width">
                <LabellableComponent label="Shift to Date" className="field-container">
                    <DatePickerInput
                        value={date instanceof Date ? date.toDateOnlyString() : date as string | undefined}
                        onChanged={(value) => {
                            value = toDate(value);
                            setDate(value);
                            earliestDate && setShift(value ? Math.round(diffDays(toDate(earliestDate)!, value, true)) : null);
                        }} />
                </LabellableComponent>
            </div>
            <div className="grid-item">
                <LabellableComponent label="Use Calendar" className="field-container"
                description='Consider work week and calendar exceptions when shifting planned hours'>
                <Toggle
                    checked={useCalendar}
                    onChange={(_, checked) => setUseCalendar(!!checked)}
                    onText='Yes'
                    offText='No' />
                </LabellableComponent>
            </div>
    </AllocationInfoPanel>;
}