import * as ReactDOM from "react-dom";
import { IBasePicker } from "office-ui-fabric-react";

export default class FabricCrutches {
    /** @description in order to work proper must be placed in onFocus of picker inputProps */
    public static fixPickerWidth(picker?: IBasePicker<any> | null) {
        if (!picker) return;
        const _picker = picker as any;
        setTimeout(() => {
            if (_picker.state.suggestionsVisible) {
                let suggestion = ReactDOM.findDOMNode(_picker.suggestionElement.current) as HTMLElement;
                if (suggestion) {
                    let input = _picker.input.current._inputElement.current;
                    // 4 - borders of suggestion containers
                    suggestion.style.width = suggestion.style.minWidth = input.offsetWidth - 4 + "px";
                    // suggestion is ms-Suggestions and target element is ms-Callout
                    suggestion.parentElement!.parentElement!.style.width = input.offsetWidth - 4 + "px";
                }
            }
        }, 0);
    }
}