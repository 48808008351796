import { IFilter, BaseFilterValue, IFilterHelper, FilterAttribute, Field, IActiveFilter, getLabel } from "../../entities/Metadata";
import { DisplayFieldService, InputsService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { SourceType } from "../ExternalEpmConnectStore";
import { ITask } from "../../entities/Subentities";
import { ActiveFilter, FilterHelperProps, FilterHelper as SubentityFilterHelper } from '../subentities/filters';
import { BaselineFakeFields } from "../../components/views/list/columns/task/Baseline";
import { Dictionary } from "../../entities/common";
import { Option, OptionsPickerProps } from "../../components/common/inputs/OptionsPicker";
import { CalculationModeFakeField, TaskCalculationModeTypes } from "../../components/views/list/columns/task/Mode";
import { IsSummaryFakeField } from "../../components/views/list/columns/task/IsSummary";

export interface TaskFilterValue extends BaseFilterValue {
    baseline?: { [k: string]: any },
    [key: string]: any;
}

const isAutoTypesMap: Dictionary<{label : string, value: boolean}> = {
    "auto": {label : TaskCalculationModeTypes.Auto, value: true},
    "manual": {label : TaskCalculationModeTypes.Manual, value: false}
}

const YesNoMap: Dictionary<{label : string, value: boolean}> = {
    "yes": {label : "Yes", value: true},
    "no": {label : "No", value: false}
}

export class FilterHelper extends SubentityFilterHelper {
    constructor(props: FilterHelperProps){
        super(props);
    }
    public getFilterAttributes = (fields: Field[], nonFilterableFields?: string[] | undefined): FilterAttribute<TaskFilterValue>[] => {
        const attrs = fields
            .filter(_ => !nonFilterableFields || !~nonFilterableFields.indexOf(_.name))
            .map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<TaskFilterValue>);

        const baselineFakeAttrs = BaselineFakeFields.map(_ => ({ type: "baseline", name: _.name, displayName: getLabel(_), value: _ }));

        return [
            ...attrs,
            { type: CalculationModeFakeField.id, name: CalculationModeFakeField.name, displayName: CalculationModeFakeField.label, value: undefined },
            ...baselineFakeAttrs,
            { type: IsSummaryFakeField.id, name: IsSummaryFakeField.name, displayName: IsSummaryFakeField.label, value: undefined }
        ];
    }

    private baseline: IFilterHelper<BaseFilterValue, ITask> = {
        buildFilterElement: (attr: FilterAttribute<TaskFilterValue>,
            filter: IFilter<TaskFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void): JSX.Element | null => {
            const field: Field = attr.value;
            const value = filter.value?.baseline?.[field.name];

            return DisplayFieldService.buildFieldMultiSelectInput(field, value, changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            const newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            const tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => {
            const values: string[] = [];
            Object.keys(value).forEach(attrName => {
                const field = BaselineFakeFields.find(_ => _.name === attrName);
                if (field) {
                    values.push(FieldsService.getFieldDisplayValues(field, value[attrName]))
                }
            });
            return values;
        },
        validateItem: (item: ITask, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] === undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }
                    const field = BaselineFakeFields.find(_ => _.name === key);
                    if (!field) {
                        return false;
                    }
                    const value = item.baseline?.[key];
                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    private isAutoMode: IFilterHelper<BaseFilterValue, ITask> = {
        buildFilterElement: (
            attr: FilterAttribute<TaskFilterValue>,
            filter: IFilter<TaskFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const typeOptions: Option[] = Object.entries(isAutoTypesMap).map(_ => ({key: _[0], text: _[1].label}));
            const selectedItems: Option[] = [];

            filter.value[CalculationModeFakeField.id]?.forEach((id: string) => {
                const opt = typeOptions.find(_ => _.key === id);
                if (opt !== undefined) {
                    selectedItems.push(opt);
                }
            });

            const itemsProps: Partial<OptionsPickerProps> = {
                onChange: (value?: Option[]) => {
                    onFilterEditComplete(attr.type,
                        CalculationModeFakeField.id,
                        value && value.map(v => v.key));
                },
                onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                    let res: Option[] = typeOptions.filter(_ => _.text.toLowerCase().includes(_filter.toLowerCase()));
                    if (_selectedItems) {
                        res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                    }
                    return Promise.resolve(res);
                },
                selectedItems: selectedItems
            }

            return InputsService.buildOptionsPicker(itemsProps);
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            return [];
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            return value;
        },
        getAttributeValues: (value: any): string[] => {
            const values: string[] = [];
            (value as string[]).forEach(system => {
                values.push(isAutoTypesMap[system].label);
            });
            return values;
        },
        validateItem: (item: ITask, filterValue: any): boolean => {
            if (!filterValue || (<string[]>filterValue).length === 0) {
                return true;
            }
            return (filterValue as string[]).map(_ => isAutoTypesMap[_].value).includes(item.isAutoMode);
        }
    }
    private isSummary: IFilterHelper<BaseFilterValue, ITask> = {
        buildFilterElement: (
            attr: FilterAttribute<TaskFilterValue>,
            filter: IFilter<TaskFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const typeOptions: Option[] = Object.entries(YesNoMap).map(_ => ({key: _[0], text: _[1].label}));
            const selectedItems: Option[] = [];

            filter.value[IsSummaryFakeField.id]?.forEach((id: string) => {
                const opt = typeOptions.find(_ => _.key === id);
                if (opt !== undefined) {
                    selectedItems.push(opt);
                }
            });

            const itemsProps: Partial<OptionsPickerProps> = {
                onChange: (value?: Option[]) => {
                    onFilterEditComplete(attr.type,
                        IsSummaryFakeField.id,
                        value && value.map(v => v.key));
                },
                onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                    let res: Option[] = typeOptions.filter(_ => _.text.toLowerCase().includes(_filter.toLowerCase()));
                    if (_selectedItems) {
                        res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                    }
                    return Promise.resolve(res);
                },
                selectedItems: selectedItems
            }

            return InputsService.buildOptionsPicker(itemsProps);
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => [],
        setAttributeValue: (attrName: string, value: any, oldValue: any) => value,
        getAttributeValues: (value: any): string[] => {
            const values: string[] = [];
            (value as string[]).forEach(system => {
                values.push(YesNoMap[system].label);
            });
            return values;
        },
        validateItem: (item: ITask, filterValue: any): boolean => {
            if (!filterValue || (<string[]>filterValue).length === 0) {
                return true;
            }
            return (filterValue as string[]).map(_ => YesNoMap[_].value).includes(item.hierarchy.isParent);
        }
    }

    public newFilter = (name: string, sourceType?: SourceType): IActiveFilter => new ActiveFilter(name, sourceType);
    public helpersMap: { [K in keyof TaskFilterValue]: IFilterHelper<TaskFilterValue, ITask> } =
        {
            ...this.baseHelpersMap,
            "baseline": this.baseline,
            [CalculationModeFakeField.id]: this.isAutoMode,
            [IsSummaryFakeField.id]: this.isSummary,
        }
}