import { Action, Reducer } from 'redux';
import { replace, RouterAction } from 'react-router-redux';
import { AppThunkAction } from "./index";
import { post, remove } from "../fetch-interceptor";
import { defaultCatch } from "./utils";
import { IEntityStore, StoreHelper } from "./services/storeHelper";
import { notUndefined, toDate, toDateTime } from "../components/utils/common";
import { IListSubView, IServerViews, ViewTypes, IServerSubView, viewsMappings, ITimelineSubView, ICardSubView, Field, toMap, NEW_ID } from "../entities/Metadata";
import { EntityType, ISortState, ITimeframe, OriginScale, Quantization, SortDirection } from '../entities/common';
import { isSubentity } from '../entities/Subentities';
import { Tracker } from "./PreferencesTracker";

export const DEFAULT_LIST_SUBVIEW: IListSubView = {
    id: '',
    name: '',
    isBuiltIn: true,
    isNotSaved: false,
    columns: [],
    isPublic: true
};

export interface IViewsState {
    card: ICardViewState;
    list: IListViewState;
    timeline: ITimelineViewState;
    activeViewType?: string;
}

export interface ICardViewState {
    subViews: ICardSubView[];
    sortBy: ICardSortState;
    activeSubViewId?: string;
}

export interface IListViewState {
    fakeFields: Field[];
    selectedByDefault: string[];
    subViews: IEntityStore<IListSubView>;
    sortBy: ISortState;
    activeSubViewId?: string;
}

export interface ITimelineViewState {
    selectedByDefault: string[];
    subViews: IEntityStore<ITimelineSubView>;
    sortBy: IOrderBy;
    activeSubViewId?: string;
    quantization?: Quantization;
    timeframe?: Partial<ITimeframe>;
}

export interface ICardSortState {
    active: ICardSortOption;
    options: ICardSortOption[];
}

export type IOrderBy = {
    fieldName: string;
    direction: SortDirection;
    isProperty?: boolean;
};

export interface ICardSortOption {
    id: string;
    orderBy: IOrderBy[] | IOrderBy;
    label: string;
    iconName: string;
}

export type UpdateListSubViewData = {
    subViewId: string;
    changes: Partial<IListSubView>;
};

export type UpdateTimelineSubViewData = {
    subViewId: string;
    changes: Partial<ITimelineSubView>;
};

export function getColumnIds(fieldNames: string[], fields: Field[]): string[] {
    const map = toMap(fields);
    return fieldNames.map(_ => map[_] && map[_].id).filter(notUndefined);
}

type SetActiveViewAction = {
    entity: string;
    type: "SET_ACTIVE_VIEW";
    viewType: string;
};

type AddListSubViewAction = {
    entity: string;
    type: 'ADD_LIST_SUBVIEW';
    subView: IListSubView;
};

type UpdateListSubViewAction = {
    entity: string;
    type: 'UPDATE_LIST_SUBVIEW';
} & UpdateListSubViewData;

type SaveListSubViewAction = {
    entity: string;
    type: "SAVE_LIST_SUBVIEW";
    subView: IServerSubView;
};

type RemoveListSubViewAction = {
    entity: string;
    type: "REMOVE_LIST_SUBVIEW";
    subViewId: string;
};

type ChangeListActiveSortAction = {
    entity: string;
    type: 'CHANGE_LIST_ACTIVE_SORT';
    sortBy: ISortState;
};

type SetListActiveSubViewAction = {
    entity: string;
    type: "SET_LIST_ACTIVE_SUBVIEW";
    subViewId: string;
};

type SetListColumnWidthAction = {
    entity: string;
    type: "SET_LIST_COLUMN_WIDTH";
    subViewId: string;
    fieldId: string;
    width: number;
};

type ChangeCardActiveSortAction = {
    entity: string;
    type: 'CHANGE_CARD_ACTIVE_SORT';
    sortBy: ICardSortOption;
};

type SetCardActiveSubViewAction = {
    entity: string;
    type: "SET_CARD_ACTIVE_SUBVIEW";
    subViewId: string;
};

type AddTimelineSubViewAction = {
    entity: string;
    type: 'ADD_TIMELINE_SUBVIEW';
    subView: ITimelineSubView;
};

type UpdateTimelineSubViewAction = {
    entity: string;
    type: 'UPDATE_TIMELINE_SUBVIEW';
} & UpdateTimelineSubViewData;

type SaveTimelineSubViewAction = {
    entity: string;
    type: "SAVE_TIMELINE_SUBVIEW";
    subView: IServerSubView;
};

type RemoveTimelineSubViewAction = {
    entity: string;
    type: "REMOVE_TIMELINE_SUBVIEW";
    subViewId: string;
};

type ChangeTimelineActiveSortAction = {
    entity: string;
    type: 'CHANGE_TIMELINE_ACTIVE_SORT';
    sortBy: ISortState;
};

type SetTimelineActiveSubViewAction = {
    entity: string;
    type: "SET_TIMELINE_ACTIVE_SUBVIEW";
    subViewId: string;
};

type SetTimelineScaleAction = {
    entity: string;
    type: "SET_TIMELINE_SCALE";
    quantization?: Quantization;
    timeframe?: Partial<ITimeframe>;
};

type SetTimelineColumnWidth = {
    entity: string;
    type: "SET_TIMELINE_COLUMN_WIDTH";
    subViewId: string;
    fieldId: string;
    width: number;
};

type CardViewAction = ChangeCardActiveSortAction | SetCardActiveSubViewAction;

type ListViewAction = AddListSubViewAction
    | UpdateListSubViewAction
    | SaveListSubViewAction
    | RemoveListSubViewAction
    | ChangeListActiveSortAction
    | SetListActiveSubViewAction
    | SetListColumnWidthAction;

type TimelineViewAction = AddTimelineSubViewAction
    | UpdateTimelineSubViewAction
    | SaveTimelineSubViewAction
    | RemoveTimelineSubViewAction
    | ChangeTimelineActiveSortAction
    | SetTimelineActiveSubViewAction
    | SetTimelineScaleAction
    | SetTimelineColumnWidth;

type ViewsKnownAction = SetActiveViewAction | CardViewAction | ListViewAction | TimelineViewAction;

export const actionCreators = {
    forEntity: (entity: EntityType) => {
        return {
            setActiveView: (viewType: string): AppThunkAction<ViewsKnownAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'SET_ACTIVE_VIEW', viewType });
                Tracker.setActiveView(dispatch, getState, entity, viewType);
            },
            addListSubView: (subView: IListSubView): AppThunkAction<ListViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'ADD_LIST_SUBVIEW', subView });
            },
            updateListSubView: (subViewId: string, changes: Partial<IListSubView>): AppThunkAction<ListViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'UPDATE_LIST_SUBVIEW', changes, subViewId });
            },
            saveListSubView: (subView: IListSubView, routeBasePath?: string, redirect?: (view: IListSubView) => void, originId?: string): AppThunkAction<ListViewAction | RouterAction> => (dispatch, getState) => {
                const url = `api/metadata/${entity}/view/${ViewTypes.list}/subview` + (originId ? `?originId=${originId}` : '');
                post<IServerSubView>(url, viewsMappings.toServerSubView(subView))
                    .then(_ => {
                        dispatch({ entity, type: 'SAVE_LIST_SUBVIEW', subView: _ });
                        if (subView.id !== _.id) {
                            dispatch({ entity, type: 'REMOVE_LIST_SUBVIEW', subViewId: subView.id });
                            if (isSubentity(entity)) {
                                redirect?.(viewsMappings.toSubView(_));
                            } else if (routeBasePath) {
                                dispatch(replace(`/${routeBasePath}/list/${_.id}`));
                            } else {
                                dispatch({ entity, type: 'SET_LIST_ACTIVE_SUBVIEW', subViewId: _.id });
                                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.list, { activeSubViewId: _.id });
                            }
                        }
                    })
                    .catch(defaultCatch(dispatch));
            },
            removeListSubView: (subViewId: string): AppThunkAction<ListViewAction> => (dispatch, getState) => {
                if (subViewId && subViewId !== NEW_ID) {
                    remove<IListSubView>(`api/metadata/${entity}/view/${ViewTypes.list}/subview/${subViewId}`)
                        .then(_ => dispatch({ entity, type: 'REMOVE_LIST_SUBVIEW', subViewId }))
                        .catch(defaultCatch(dispatch));
                } else {
                    dispatch({ entity, type: 'REMOVE_LIST_SUBVIEW', subViewId });
                }
            },
            changeListViewSort: (sortBy: ISortState): AppThunkAction<ListViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'CHANGE_LIST_ACTIVE_SORT', sortBy });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.list, { sortBy });
            },
            setListActiveSubView: (subViewId: string): AppThunkAction<ListViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'SET_LIST_ACTIVE_SUBVIEW', subViewId });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.list, { activeSubViewId: subViewId });
            },
            onListColumnResized: (subViewId: string, fieldId: string, width: number): AppThunkAction<ListViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'SET_LIST_COLUMN_WIDTH', subViewId, fieldId, width });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.list, { columnResize: { subViewId, fieldId, width } });
            },
            changeCardViewSort: (sortBy: ICardSortOption): AppThunkAction<CardViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'CHANGE_CARD_ACTIVE_SORT', sortBy });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.card, { SortById: sortBy.id });
            },
            setCardActiveSubView: (subViewId: string): AppThunkAction<CardViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'SET_CARD_ACTIVE_SUBVIEW', subViewId });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.card, { activeSubViewId: subViewId });
            },
            addTimelineSubView: (subView: IListSubView): AppThunkAction<TimelineViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'ADD_TIMELINE_SUBVIEW', subView });
            },
            updateTimelineSubView: (subViewId: string, changes: Partial<ITimelineSubView>): AppThunkAction<TimelineViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'UPDATE_TIMELINE_SUBVIEW', subViewId, changes });
            },
            saveTimelineSubView: (subView: ITimelineSubView, routeBasePath?: string, redirect?: (view: IListSubView) => void, originId?: string): AppThunkAction<TimelineViewAction | RouterAction> => (dispatch, getState) => {
                const url = `api/metadata/${entity}/view/${ViewTypes.timeline}/subview` + (originId ? `?originId=${originId}` : '');
                post<IServerSubView>(url, viewsMappings.toServerSubView(subView))
                    .then(_ => {
                        dispatch({ entity, type: 'SAVE_TIMELINE_SUBVIEW', subView: _ })
                        if (subView.id !== _.id) {
                            dispatch({ entity, type: 'REMOVE_TIMELINE_SUBVIEW', subViewId: subView.id });
                            if (isSubentity(entity)) {
                                redirect?.(viewsMappings.toSubView(_));
                            } else if (routeBasePath) {
                                dispatch(replace(`/${routeBasePath}/timeline/${_.id}`));
                            } else {
                                dispatch({ entity, type: 'SET_TIMELINE_ACTIVE_SUBVIEW', subViewId: _.id });
                                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.timeline, { activeSubViewId: _.id });
                            }
                        }
                    })
                    .catch(defaultCatch(dispatch));
            },
            removeTimelineSubView: (subViewId: string): AppThunkAction<TimelineViewAction> => (dispatch, getState) => {
                if (subViewId && subViewId !== NEW_ID) {
                    remove<ITimelineSubView>(`api/metadata/${entity}/view/${ViewTypes.timeline}/subview/${subViewId}`)
                        .then(_ => dispatch({ entity, type: 'REMOVE_TIMELINE_SUBVIEW', subViewId }))
                        .catch(defaultCatch(dispatch));
                } else {
                    dispatch({ entity, type: 'REMOVE_TIMELINE_SUBVIEW', subViewId });
                }
            },
            changeTimelineViewSort: (sortBy: ISortState): AppThunkAction<TimelineViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'CHANGE_TIMELINE_ACTIVE_SORT', sortBy });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.timeline, { sortBy });
            },
            setTimelineActiveSubView: (subViewId: string): AppThunkAction<TimelineViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'SET_TIMELINE_ACTIVE_SUBVIEW', subViewId: subViewId });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.timeline, { activeSubViewId: subViewId });
            },
            setTimelineScale: (scale: OriginScale): AppThunkAction<TimelineViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'SET_TIMELINE_SCALE', ...scale });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.timeline, { scale });
            },
            onTimelineColumnResized: (subViewId: string, fieldId: string, width: number): AppThunkAction<TimelineViewAction> => (dispatch, getState) => {
                dispatch({ entity, type: 'SET_TIMELINE_COLUMN_WIDTH', subViewId, fieldId, width });
                Tracker.setViewSettings(dispatch, getState, entity, ViewTypes.timeline, { columnResize: { subViewId, fieldId, width } });
            },
        }
    }
};

const _reducer = {
    card: {
        changeActiveSort: (state: ICardViewState, sortBy: ICardSortOption): ICardViewState => ({
            ...state,
            sortBy: {
                active: sortBy,
                options: state.sortBy.options
            }
        }),
        setActiveSubView: (state: ICardViewState, subViewId: string): ICardViewState => ({
            ...state,
            activeSubViewId: subViewId
        })
    },
    list: {
        addSubView: (state: IListViewState, subView: IListSubView): IListViewState => ({
            ...state,
            subViews: StoreHelper.addOrUpdate(state.subViews, subView),
        }),
        updateSubView: (state: IListViewState, data: UpdateListSubViewData): IListViewState => {
            const subView = Object.assign({}, state.subViews.byId[data.subViewId], { isNotSaved: true }, data.changes);
            return {
                ...state,
                subViews: StoreHelper.addOrUpdate(state.subViews, subView),
            };
        },
        saveSubView: (state: IListViewState, dto: IServerSubView): IListViewState => {
            const subView = viewsMappings.toSubView(dto);
            return {
                ...state,
                subViews: StoreHelper.addOrUpdate(state.subViews, subView)
            };
        },
        removeSubView: (state: IListViewState, subViewId: string): IListViewState => ({
            ...state,
            subViews: StoreHelper.remove(state.subViews, subViewId),
            activeSubViewId: state.activeSubViewId === subViewId ? undefined : state.activeSubViewId
        }),
        changeActiveSort: (state: IListViewState, sortBy: IOrderBy): IListViewState => ({
            ...state,
            sortBy
        }),
        setActiveSubView: (state: IListViewState, subViewId: string): IListViewState => ({
            ...state,
            activeSubViewId: subViewId
        }),
        setColumnWidth: <T extends IListViewState>(state: T, subViewId: string, fieldId: string, width: number): T => ({
            ...state,
            subViews: StoreHelper.applyHandler(state.subViews,
                subViewId,
                (_: IListSubView) => ({
                    ..._,
                    columns: _.columns.map(c => c.id === fieldId ? { ...c, width } : c)
                }))
        })
    },
    timeline: {
        addSubView: <T extends ITimelineViewState>(state: T, subView: ITimelineSubView): T => ({
            ...state,
            ...<ITimelineViewState>{ subViews: StoreHelper.addOrUpdate(state.subViews, subView) },
        }),
        updateSubView: <T extends ITimelineViewState>(state: T, data: UpdateTimelineSubViewData): T => {
            const subView = Object.assign({}, state.subViews.byId[data.subViewId], { isNotSaved: true }, data.changes);
            return {
                ...state,
                ...<ITimelineViewState>{ subViews: StoreHelper.addOrUpdate(state.subViews, subView) },
            };
        },
        saveSubView: <T extends ITimelineViewState>(state: T, dto: IServerSubView): T => {
            const subView = viewsMappings.toSubView(dto);
            return {
                ...state,
                ...<ITimelineViewState>{ subViews: StoreHelper.addOrUpdate(state.subViews, subView) }
            };
        },
        removeSubView: <T extends ITimelineViewState>(state: T, subViewId: string): T => ({
            ...state,
            ...<ITimelineViewState>{
                subViews: StoreHelper.remove(state.subViews, subViewId),
                activeSubViewId: subViewId == state.activeSubViewId ? undefined : state.activeSubViewId
            }
        }),
        changeActiveSort: <T extends ITimelineViewState>(state: T, sortBy: IOrderBy): T => ({
            ...state,
            sortBy
        }),
        setActiveSubView: <T extends ITimelineViewState>(state: T, subViewId: string): T => ({
            ...state,
            ...<ITimelineViewState>{ activeSubViewId: subViewId }
        }),
        setScale: <T extends ITimelineViewState>(state: T, quantization?: Quantization, timeframe?: Partial<ITimeframe>): T => ({
            ...state,
            quantization,
            timeframe
        }),
        setColumnWidth: <T extends ITimelineViewState>(state: T, subViewId: string, fieldId: string, width: number): T => {
            return ({
                ...state,
                subViews: StoreHelper.applyHandler(state.subViews,
                    subViewId,
                    (_: ITimelineSubView) => ({
                        ..._,
                        columns: _.columns.map(c => c.id === fieldId ? { ...c, width } : c)
                    }))
            });
        }
    },
    setActiveView: <T extends IViewsState>(state: T, viewType: string): T => ({
        ...state,
        ...<IViewsState>{ activeViewType: viewType }
    })
};

const cardViewReducer = (state: ICardViewState, incomingAction: Action) => {
    const action = incomingAction as CardViewAction;
    switch (action.type) {
        case 'CHANGE_CARD_ACTIVE_SORT': return _reducer.card.changeActiveSort(state, action.sortBy);
        case 'SET_CARD_ACTIVE_SUBVIEW': return _reducer.card.setActiveSubView(state, action.subViewId);
        default: const exhaustiveCheck: never = action;
    }

    return state;
}

const listViewReducer = (state: IListViewState, incomingAction: Action): IListViewState => {
    const action = incomingAction as ListViewAction;
    switch (action.type) {
        case 'ADD_LIST_SUBVIEW': return _reducer.list.addSubView(state, action.subView);
        case 'UPDATE_LIST_SUBVIEW': return _reducer.list.updateSubView(state, action);
        case 'SAVE_LIST_SUBVIEW': return _reducer.list.saveSubView(state, action.subView);
        case 'REMOVE_LIST_SUBVIEW': return _reducer.list.removeSubView(state, action.subViewId);
        case 'CHANGE_LIST_ACTIVE_SORT': return _reducer.list.changeActiveSort(state, action.sortBy);
        case 'SET_LIST_ACTIVE_SUBVIEW': return _reducer.list.setActiveSubView(state, action.subViewId);
        case 'SET_LIST_COLUMN_WIDTH': return _reducer.list.setColumnWidth(state, action.subViewId, action.fieldId, action.width);
        default: const exhaustiveCheck: never = action;
    }

    return state;
}

const timelineViewReducer = (state: ITimelineViewState, incomingAction: Action) => {
    const action = incomingAction as TimelineViewAction;
    switch (action.type) {
        case 'ADD_TIMELINE_SUBVIEW': return _reducer.timeline.addSubView(state, action.subView);
        case 'UPDATE_TIMELINE_SUBVIEW': return _reducer.timeline.updateSubView(state, action);
        case 'SAVE_TIMELINE_SUBVIEW': return _reducer.timeline.saveSubView(state, action.subView);
        case 'REMOVE_TIMELINE_SUBVIEW': return _reducer.timeline.removeSubView(state, action.subViewId);
        case 'CHANGE_TIMELINE_ACTIVE_SORT': return _reducer.timeline.changeActiveSort(state, action.sortBy);
        case 'SET_TIMELINE_ACTIVE_SUBVIEW': return _reducer.timeline.setActiveSubView(state, action.subViewId);
        case 'SET_TIMELINE_SCALE': return _reducer.timeline.setScale(state, action.quantization, action.timeframe);
        case 'SET_TIMELINE_COLUMN_WIDTH': return _reducer.timeline.setColumnWidth(state, action.subViewId, action.fieldId, action.width);
        default: const exhaustiveCheck: never = action;
    }

    return state;
}

export const VIEWS_EMPTY: IViewsState = {
    card: {
        sortBy: { options: [], active: { id: '', iconName: '', label: '', orderBy: { direction: SortDirection.ASC, fieldName: '' } } },
        subViews: []
    },
    list: {
        fakeFields: [],
        selectedByDefault: [],
        sortBy: { direction: SortDirection.ASC, fieldName: '' },
        subViews: StoreHelper.create([])
    },
    timeline: {
        selectedByDefault: [],
        sortBy: { isProperty: false, direction: SortDirection.ASC, fieldName: '' },
        subViews: StoreHelper.create([])
    }
};

export const reducer: Reducer<IViewsState> = (state: IViewsState, incomingAction: Action) => {
    const action = incomingAction as ViewsKnownAction;

    if (!state) {
        return state;
    }

    switch (action.type) {
        case 'SET_ACTIVE_VIEW': return _reducer.setActiveView(state, action.viewType);
        default: return {
            ...state,
            card: cardViewReducer(state.card, incomingAction),
            list: listViewReducer(state.list, incomingAction),
            timeline: timelineViewReducer(state.timeline, incomingAction)
        };
    }
};

export const reduceCardState = (state: ICardViewState, views: IServerViews): ICardViewState => {
    const data = views.card?.data;
    return {
        ...state,
        activeSubViewId: data?.activeSubViewId || state.activeSubViewId,
        sortBy: data.sortById
            ? {
                ...state.sortBy,
                active: state.sortBy.options.find(_ => _.id == data.sortById) || state.sortBy.active
            }
            : state.sortBy
    }
};

export const reduceListState = (state: IListViewState, views: IServerViews): IListViewState => {
    const subViews = StoreHelper.create(views.list.subViews.map(_ => viewsMappings.toSubView(_)));
    return {
        ...state,
        sortBy: views.list.data.sortBy || state.sortBy,
        fakeFields: views.list.data.fakeFields,
        selectedByDefault: views.list.data.selectedByDefault,
        subViews,
        activeSubViewId: views.list.data.activeSubViewId && subViews.byId[views.list.data.activeSubViewId]
            ? views.list.data.activeSubViewId
            : state.activeSubViewId && subViews.byId[state.activeSubViewId] ? state.activeSubViewId : undefined
    };
};

export const reduceTimelineState = (state: ITimelineViewState, views: IServerViews): ITimelineViewState => {
    const subViews = StoreHelper.create(views.timeline.subViews.map(_ => viewsMappings.toSubView(_)));
    return {
        ...state,
        sortBy: views.timeline.data.sortBy || state.sortBy,
        quantization: views.timeline.data.scale?.quantization || state.quantization,
        timeframe: toTimeframe(views.timeline.data.scale?.timeframe) || state.timeframe,
        selectedByDefault: views.timeline.data.selectedByDefault,
        subViews: StoreHelper.create(views.timeline.subViews.map(_ => viewsMappings.toSubView(_))),
        activeSubViewId: views.timeline.data.activeSubViewId && subViews.byId[views.timeline.data.activeSubViewId]
            ? views.timeline.data.activeSubViewId
            : state.activeSubViewId && subViews.byId[state.activeSubViewId] ? state.activeSubViewId : undefined
    }
};

const toTimeframe = (timeframe?: Partial<ITimeframe>) => {
    if (!timeframe || !timeframe.start && !timeframe.end) {
        return undefined;
    }

    const data: Partial<ITimeframe> = {};
    if (timeframe.start) {
        data.start = toDateTime(timeframe.start);
    }

    if (timeframe.end) {
        data.end = toDateTime(timeframe.end);
    }

    return data;
}    

export const ViewNames  = {
    SYNC_STATUS: "Sync Status"
}