import "./CalendlyRequest.css";
import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Link } from 'office-ui-fabric-react';
import * as analytics from '../../../analytics';
import { InlineWidget, CalendlyEventListener } from "react-calendly";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/User';

type OwnProps = {
    title: JSX.Element;
    description: JSX.Element;
    details: JSX.Element;
    skipText: string;

    calendlyEventPath: string;
    calendlyEventName: string;
}
type StateProps = {
    user: UserState
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

const EVENT_SCHEDULED_TIMEOUT = 3000;

const CalendlyRequest = (props: Props) => {

    const onBack = React.useCallback(() => {
        props.history.push(props.location.state && props.location.state.source ? props.location.state.source : "/");
    }, []);

    const onSkip = React.useCallback(() => {
        analytics.track('RequestDemo', { success: false });
        onBack();
    }, []);

    const onEventScheduled = () => {
        analytics.track('RequestDemo', { success: true });
        setTimeout(() => onBack(), EVENT_SCHEDULED_TIMEOUT)
    }

    return <div className='calendly-request'>
        <div className="full-width">
            <div className="content full-width">
                <div className="title">
                    {props.title}
                </div>
                <div className="description">{props.description}</div>
                <div className="details">{props.details}</div>
                <div className="navigation">
                    <Link onClick={onSkip} className="skip">
                        {props.skipText}
                    </Link>
                </div>
            </div>
        </div>
        <div className="embed">
            <div>
                <CalendlyEventListener onEventScheduled={onEventScheduled}>
                    <InlineWidget
                        pageSettings={{
                            hideEventTypeDetails: true,
                            hideLandingPageDetails: true
                        }}
                        url={props.calendlyEventPath}
                        prefill={{
                            email: props.user.email,
                            name: props.user.name
                        }} />
                </CalendlyEventListener>
                <div className="hint">
                    You’ll be redirected to the app after you book a call anyway
                </div>
            </div>
        </div>
    </div>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(CalendlyRequest));