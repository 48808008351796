import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import AddEntitiesDialog from '../common/sectionsControl/uiControls/projectsControl/AddEntitiesDialog';
import { UserState } from '../../store/User';
import { chargeRateField } from './usage/ResourceUsageGrid';
import * as ResourcesListStore from '../../store/ResourcesListStore';
import PersonPickerInput, { PersonInfo } from '../common/inputs/PersonPickerInput';
import { MessageBar, MessageBarType, Toggle } from 'office-ui-fabric-react';
import { formatFieldValue } from '../utils/common';
import * as Metadata from '../../entities/Metadata';
import { EntityType } from '../../entities/common';

type OwnProps = {
    entityid: string;
    entityType: EntityType | string;
    resource: ResourcesListStore.Resource;
    /// entity resourceIds
    resourceIds: string[];
    onReplaceResource: (newResourceIds: string[], keepRateOnReplace: boolean, sumUpPlanHours: boolean, sumUpActualHours: boolean) => void;
    onDismiss: () => void;
}
type StoreProps = {
    user: UserState;
}

type Props = OwnProps & StoreProps;

const ReplaceResourceDialog = (props: Props) => {

    const [keepRateOnReplace, setKeepRateOnReplace] = React.useState(true);
    const [sumUpPlanHours, setSumUpPlanHours] = React.useState(true);
    const [sumUpActualHours, setSumUpActualHours] = React.useState(false);
    const [isAlreadyAdded, setIsAlreadyAdded] = React.useState(false);

    const _renderEntitiesSelector = (onChanged: (selected?: string[]) => void) => {
        const { user, resource, resourceIds, entityType, entityid } = props;
        const bookingRateValue = chargeRateField.calculate([resource], [entityid]);

        return <>
            <PersonPickerInput
                multichoice={false}
                onChanged={(value) => {
                    setIsAlreadyAdded(value && resourceIds.indexOf((value as PersonInfo).id) > -1);
                    if (value) {
                        onChanged([(value as PersonInfo).id]);
                    }
                }}
                searchUrl="api/resource/find"
                filter={{ exceptIds: [resource.id] }}
                inputProps={{ placeholder: "Type to search", autoFocus: true }}
            />
            {
                isAlreadyAdded &&
                <MessageBar messageBarType={MessageBarType.warning}>
                    Selected resource is already added to the {entityType}.
                </MessageBar>
            }
            <Toggle label={`Keep Charge Rate ${formatFieldValue(bookingRateValue, Metadata.FormatType.Cost, user)}`}
                checked={keepRateOnReplace}
                onChange={(e, checked) => setKeepRateOnReplace(!!checked)}
                onText='Yes'
                offText='No' />
            <Toggle label={`${isAlreadyAdded ? 'Sum up' : 'Keep'} Planned Hours`}
                checked={sumUpPlanHours}
                onChange={(e, checked) => setSumUpPlanHours(!!checked)}
                onText='Yes'
                offText='No' />
            {entityType !== EntityType.Idea && <Toggle label={`${isAlreadyAdded ? 'Sum up' : 'Keep'} Actual Hours`}
                checked={sumUpActualHours}
                onChange={(e, checked) => setSumUpActualHours(!!checked)}
                onText='Yes'
                offText='No' />}
        </>;
    }

    const _onComplete = (newResourceIds: string[]) => {
        props.onReplaceResource(newResourceIds, keepRateOnReplace, sumUpPlanHours, sumUpActualHours);
        props.onDismiss();
    }

    return <AddEntitiesDialog
        confirmButtonText="Replace"
        dialogContentProps={{
            title: 'Replace Resource',
            subText: 'Select resource for the replacement'
        }}
        onDismiss={props.onDismiss}
        onComplete={(newResourceIds) => _onComplete(newResourceIds)}
        renderEntitySelector={_renderEntitiesSelector}
    />;
}
function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ReplaceResourceDialog);
