import * as React from "react";
import "./Collapsible.css";
import { Icon } from "office-ui-fabric-react";

type Props = {
    title: string;
    iconName: string;
    expanded?: boolean;
    disabled?: boolean;
    collapsedChevronDirection?: ChevronDirection;
    classNames?: {
        head?: string;
        body?: string;
    };
};

export enum ChevronDirection {
    Up,
    Right,
}

export default function Collapsible(props: React.PropsWithChildren<Props>) {
    const { iconName, title, disabled, classNames, children, collapsedChevronDirection = ChevronDirection.Right } = props;
    
    const [expanded, setExpanded] = React.useState(props.expanded ?? false);
    React.useEffect(() => {
        setExpanded(props.expanded ?? false);
    }, [props.expanded]);

    const chevronDirectionClass = getChevronDirectionClass(collapsedChevronDirection);

    return (
        <div className={`accordion ${disabled ? "readonly" : ""} ${expanded ? "open" : ""}`}>
            <div className={`accordion-head ${classNames?.head ? classNames.head : ""}`} onClick={() => setExpanded(!expanded)}>
                <span className={`chevron ${chevronDirectionClass}`}></span>
                <Icon iconName={iconName} />
                {title}
            </div>
            {expanded && <div className={`accordion-body ${classNames?.body ? classNames.body : ""}`}>{children}</div>}
        </div>
    );
}

function getChevronDirectionClass(direction: ChevronDirection): string {
    switch (direction) {
        case ChevronDirection.Up:
            return "chevron-up";
        case ChevronDirection.Right:
            return "chevron-right";
        default:
            return "chevron-right";
    }
}
