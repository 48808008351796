import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import InviteUserPanel from '../user/InviteUserPanel';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { checkIfADUsersSyncEnabled } from '../../store/Tenant';
import { isAdminViewer } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';

type OwnProps = {
    className?: string;
};

type StoreProps = {
    isTenantReadOnly: boolean;
    canInvite: boolean;
    isADUsersSyncEnabled: boolean;
};

type Props = OwnProps & StoreProps;

const InvitePeople = (props: Props) => {
    const [isInvitePanelOpen, setInvitePanelOpen] = React.useState(false);

    return <>
        <DefaultButton
            className={props.className}
            text="Invite People"
            disabled={props.isTenantReadOnly || !props.canInvite || props.isADUsersSyncEnabled}
            iconProps={{ iconName: "PPMXPeopleInvite" }}
            onClick={() =>
                setInvitePanelOpen(true)
            } />
        {
            isInvitePanelOpen && <InviteUserPanel onDismiss={() =>
                setInvitePanelOpen(false)
            } />
        }
    </>;
}

function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        isTenantReadOnly: state.tenant.isReadOnly,
        canInvite: !isAdminViewer(state.user) && (contains(state.user.permissions.common, CommonOperations.Administrate) || !!state.tenant.security?.allowEveryoneToInviteUsers),
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
    };
}

export default connect(mapStateToProps)(InvitePeople);
