import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { EntityType } from "../../entities/common";
import * as Metadata from '../../entities/Metadata';
import * as FiltersStore from '../../store/filters';
import FilterPanel from './FilterPanel';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { ApplicationState } from '../../store';

type ActionProps = { filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity> };
type OwnProps<T> = {
    activeFilter: Metadata.IFilter<Metadata.BaseFilterValue> | undefined;
    subentityType: EntityType;
    fields?: Metadata.Field[];
    canManageConfiguration: boolean;
    nonFilterableFields?: string[];
    togglePanel: (isOpen: boolean) => void;
    redirectToFilter: (filter: Metadata.IFilter<Metadata.BaseFilterValue>) => void;
    entityFilterHelper: Metadata.IEntityFilterHelper<T>

    onSetActiveFilter?: (activeFilter: FiltersStore.ActiveFilter, sourceType?: SourceType) => void;
    onFilterChanged?: (filter: Metadata.IFilter<Metadata.BaseFilterValue>) => void;
    onSave?: (filter: Metadata.IFilter<Metadata.BaseFilterValue>, redirectToFilter: (filter: Metadata.IFilter<Metadata.BaseFilterValue>, openPanel?: boolean) => void) => void;
}

type StateProps = {
    fields: Metadata.Field[];
}

type Props<T> = OwnProps<T> & StateProps & ActionProps;

export type FiltersActions = Omit<ReturnType<typeof FiltersStore.actionCreators.forEntity>, "setActiveFilter"> & {
    setActiveFilter: (id?: string, preFilterId?: string, sourceType?: SourceType) => void;
    setActivePreFilter?: (id?: string) => void;
};

class SubentityFilterPanel<T> extends React.Component<Props<T>> {
    public render() {
        const { activeFilter, togglePanel, nonFilterableFields, entityFilterHelper, canManageConfiguration, filtersActions, fields } = this.props;
        if (!activeFilter) {
            return null;
        }
        return (
            <FilterPanel
                filter={activeFilter}
                onFilterChanged={this._updateFilter}
                onSave={this._saveFilter}
                onCopy={filter => filtersActions.saveFilter(filter, _ => this._redirectToFilter(_, true))}
                onDismiss={() => togglePanel(false)}
                canManageConfiguration={canManageConfiguration}
                fields={fields}
                nonFilterableFields={nonFilterableFields}
                entityFilterHelper={entityFilterHelper}
            />
        );
    }

    private _updateFilter = (filter: Metadata.IFilter<Metadata.BaseFilterValue>) => {
        if (this.props.onFilterChanged) {
            this.props.onFilterChanged(filter);
        } else {
            this.props.filtersActions.updateFilter(filter);
        }
    }

    private _saveFilter = () => {
        const { activeFilter, onSave, filtersActions } = this.props;
        if (onSave) {
            onSave(activeFilter!, this._redirectToFilter);
        } else {
            filtersActions.saveFilter(activeFilter!, this._redirectToFilter);
        }
    }

    private _redirectToFilter = (filter: Metadata.IFilter<Metadata.BaseFilterValue>, openPanel?: boolean) => {
        this.props.redirectToFilter(filter);
        if (openPanel) {
            this.props.togglePanel(true);
        }
    }
}

export default function <T>(filterKey: string = FiltersStore.FilterKeys.Main) {
    function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps<T>): ActionProps {
        return {
            filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(ownProps.subentityType, filterKey), dispatch)
        };
    }

    function mapStateToProps(state: ApplicationState, ownProps: OwnProps<T>): StateProps {
        const fields = state.fields[ownProps.subentityType];
        return {
            fields: ownProps.fields ? ownProps.fields : fields.allIds.map(_ => fields.byId[_]),
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(SubentityFilterPanel as new (props: Props<T>) => SubentityFilterPanel<T>);
}