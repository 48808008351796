import * as React from 'react';
import { Slider } from "office-ui-fabric-react";
import { Impact } from '../../entities/common';
import { ImpactView } from '../views/list/columns/Impact';
import { debounce, suppressMouseEventsBubble } from '../utils/common';

type Props = {
    onChange?: (importance: Impact) => void;
    value: Impact;
    disabled?: boolean;
};

type State = {
    value: Impact;
};

export default class ImportanceSlider extends React.Component<Props, State> {
    private debouncedSaveAttribute = debounce<Impact>(500);

    constructor(props: Props) {
        super(props);
        this.state = {
            value: this.props.value
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.value !== nextProps.value) {
            this.setState({ value: nextProps.value });
        }
    }

    public render() {
        return (
            <div className="enum-slider" {...suppressMouseEventsBubble}>
                <Slider
                    min={Impact.NA}
                    max={Impact.Extreme}
                    className="impact"
                    showValue={false}
                    disabled={this.props.disabled}
                    value={this.state.value}
                    onChange={this.props.onChange && !this.props.disabled ? this.onChange : undefined}
                />
                <ImpactView value={this.props.value} />
            </div>
        );
    }

    private onChange = (value: Impact): void => {
        this.setState({ value });
        this.debouncedSaveAttribute(value, this.props.onChange!);
    }
}