import * as React from "react";
import { ProjectInfo } from "../../../store/ProjectsListStore";
import { IImportProjectState } from "../../../store/integration/ImportStore";
import { IContextualMenuItem } from "office-ui-fabric-react";
import { Column } from "react-data-grid";
import { IConnectionsState, validateConnection } from "../../../store/integration/common";
import { usePrevious } from "../../utils/effects";

export interface IGridBaseProps {
    entities: IImportEntity[];
    getSourcesInfo: (project: ProjectInfo) => ISourceInfo[];
    getMenuActions?: (value: IImportProjectState) => IContextualMenuItem[] | undefined;
    
    extraColumns?: Column<any>[],
    populateState?: (entity: IImportEntity, defaultState: IImportProjectState) => IImportProjectState;
    populateMap?: (state: IImportProjectState, defaultMap: IImportEntityMap) => IImportEntityMap;

    onRenderName?: (dependentValues: IImportProjectState, value?: string ) => JSX.Element
}

export interface ISourceProps extends IGridBaseProps {
    onSelectionChanged?: (selected: IImportEntityMap[]) => IImportEntityMap[];
    onImport: (connectionId: string, maps: IImportEntityMap[]) => void;
    warning?: string;
}

export interface IImportEntity {
    id: string;
    name: string;
    fullName: string;
    subItems?: IImportEntity[];
    isAlreadyLinked: boolean;
}

export interface IImportEntityMap {
    entityId: string;

    projectId?: string;
    projectName?: string;
}

export interface ISourceInfo {
    sourceId: string;
    connectionId: string;
    data: any;
}

export function getValidationError(connectionValidation: { isValidating?: boolean | undefined, validationError?: string | null | undefined } | undefined) {
    return connectionValidation?.isValidating === false && !!connectionValidation.validationError ? connectionValidation.validationError : undefined;
}

export function useDataLoadingAfterConnectionValidationEffect(
    connectionId: string, 
    connectionsState: IConnectionsState, 
    isImporting: boolean, 
    verifyConnection: (cid: string) => void,
    loadData: (cid: string) => void) {
    React.useEffect(() => {
        const validationInfo = validateConnection(connectionsState, connectionId);
        connectionId && validationInfo?.isValidating !== false && validationInfo?.validationError !== null && verifyConnection(connectionId);
    }, []);

    const previousConnectionsVerification = usePrevious(connectionsState.connectionsVerification);
    React.useEffect(() => {
        const validationInfo = validateConnection(connectionsState, connectionId);
        if (!isImporting && previousConnectionsVerification?.[connectionId]?.isValidating !== false
            && validationInfo?.isValidating === false && !validationInfo.validationError) {
            loadData(connectionId);
        }
    }, [connectionId, connectionId && connectionsState.connectionsVerification[connectionId]]);

    return validateConnection(connectionsState, connectionId);
}