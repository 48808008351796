import { Calendar, Callout, DirectionalHint, Icon } from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import { DayPickerStrings } from "../common/inputs/DatePickerInput";
import { FormatDate } from "../utils/common";
import QuantizationSelector from "../common/timeline/QuantizationSelector";
import { ITimeframe, Quantization } from "../../entities/common";
import { TimeTrackingGridPeriod } from "../../store/TimeTrackingStore";

type Props = {
    period: TimeTrackingGridPeriod;
    timeFrame: ITimeframe;

    onTimeFrameChange: (period: TimeTrackingGridPeriod, timeFrame: TimeFrame) => void;
};

type TimeFrame = { start: Date; end: Date };
const daysInWeek = 7;

export default function TimeTrackingTimeFrameSelector(props: Props) {
    const { period, timeFrame, onTimeFrameChange } = props;
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect(() => {

        const today = new Date().getBeginOfDay();
        const newSelectedDay = timeFrame.start <= today && timeFrame.end >= today
            ? today
            : timeFrame.start;

        setSelectedDay(newSelectedDay);

    }, [timeFrame.start.toDateOnlyString(), timeFrame.end.toDateOnlyString()]);

    const calendarLabelRef = useRef<HTMLElement>();

    const changeDateAndTimeFrame = (newDate: Date, selectedPeriod: TimeTrackingGridPeriod) => {
        const result = calculateDateAndTimeFrame(newDate, selectedPeriod);
        setSelectedDay(new Date(result.date));
        
        onTimeFrameChange(selectedPeriod, result.timeFrame);
    };

    const calculateNewDate = (coefficient: number, selectedPeriod: TimeTrackingGridPeriod) => {
        let result = new Date(selectedDay);

        if (selectedPeriod === Quantization.weeks) {
            result = result.addDays(daysInWeek * coefficient);
        } else if (selectedPeriod === Quantization.months) {
            result = new Date(result.setMonth(result.getMonth() + coefficient));
        }
        else {
            result = result.addDays(coefficient);
        }

        return result;
    };

    return (
        <>
            <div className="time-tracking align-center">
                <div className="time-tracking time-frame align-center">
                    <Icon
                        className="chevron-icon clickable"
                        iconName={"ChevronLeft"}
                        data-selection-disabled
                        onClick={() => {
                            changeDateAndTimeFrame(calculateNewDate(-1, period), period);
                        }}
                    />

                    <span className="calendar clickable" onClick={() => setShowCalendar(true)} ref={_ => (calendarLabelRef.current = _!)}>
                        <span className="clickable underline">{FormatDate(timeFrame.start)}</span> -{" "}
                        <span className="clickable underline">{FormatDate(timeFrame.end)}</span>
                    </span>

                    <Icon
                        className="chevron-icon clickable"
                        iconName={"ChevronRight"}
                        data-selection-disabled
                        onClick={() => {
                            changeDateAndTimeFrame(calculateNewDate(1, period), period);
                        }}
                    />
                </div>
                <div
                    className="today"
                    onClick={() => {
                        changeDateAndTimeFrame(new Date().getBeginOfDay(), period);
                    }}>
                    Today
                </div>
                {showCalendar && (
                    <Callout
                        isBeakVisible={false}
                        className="ms-DatePicker-callout"
                        doNotLayer={false}
                        target={calendarLabelRef.current}
                        gapSpace={5}
                        preventDismissOnScroll={true}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        onDismiss={() => setShowCalendar(false)}>
                        <Calendar
                            onSelectDate={_ => {
                                changeDateAndTimeFrame(_, period);
                                setShowCalendar(false);
                            }}
                            onDismiss={() => setShowCalendar(false)}
                            value={selectedDay}
                            strings={DayPickerStrings}
                            showGoToToday
                        />
                    </Callout>
                )}
            </div>

            <QuantizationSelector
                value={period}
                onChange={_ => {
                    changeDateAndTimeFrame(selectedDay, _ as TimeTrackingGridPeriod);
                }}
                options={[Quantization.days, Quantization.weeks, Quantization.months]}
            />
        </>
    );
}

export const calculateDateAndTimeFrame = (newDate: Date, period: TimeTrackingGridPeriod): { date: Date, timeFrame: ITimeframe } => {
    
    let timeFrame: ITimeframe = {
        start: newDate,
        end: newDate
    };

    if (period === Quantization.weeks) {
        timeFrame = newDate.getThisWeek();
    } else if (period === Quantization.months) {
        timeFrame = newDate.getThisMonth();
    }

    return {
        date: new Date(newDate),
        timeFrame
    };
};
