import React from "react";
import { IWarning } from "../../../../../entities/common";
import LabellableComponent from "../../../LabellableComponent";
import { LabelAction } from "../../../LabellableComponent/LabellableComponent";

type Props = {
    label?: string;
    labelActions?: LabelAction[];
    description?: string | JSX.Element | JSX.Element[];
    required?: boolean;
    readOnly?: boolean;
    fieldReadonly?: boolean;
    isConfigureMode?: boolean;
    warnings?: IWarning[];
    onClick?: () => void;
}

const LabellableFieldComponent = (props: React.PropsWithChildren<Props>) => {
    const { label, labelActions, description, required, readOnly, isConfigureMode, fieldReadonly,
        warnings, children, onClick } = props;

    return <LabellableComponent 
        label={label}
        labelActions={labelActions}
        description={description}
        required={required}
        className={`field-container align-center ${readOnly || fieldReadonly ? "readonly" : ""}`}
        onClick={onClick}
        isConfigureMode={isConfigureMode}
        warnings={warnings}>
        <div className={`field-value ${readOnly ? "readonly" : ""}`}>
            {children}
        </div>
    </LabellableComponent>
}

export default LabellableFieldComponent;