import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react';
import Notifications from './Notifications';
import NavigationRouter from "./navigation/NavigationRouter";
import { IRoute } from "./navigation/common";
import { History } from "history";
import KeepAliveUserToken from './user/KeepAliveUserToken';

export class App extends React.Component<{ routes: IRoute[], history: History }> {
    render() {
        return <Fabric className="App">
            <NavigationRouter {...this.props} />
            <Notifications />
            <KeepAliveUserToken />
            {/*<div className="footer"></div>*/}
        </Fabric>;
    }
}