import * as React from 'react';
import { ISourceInfo } from '../../entities/common';
import { PrimaryButton, TextField } from 'office-ui-fabric-react';
import OverlayComponent from '../common/OverlayComponent';

type MPPFileConnectActions = {
    deleteLink: (connectionId?: string) => void;
}

type Props = {
    readonly: boolean;
    actions: MPPFileConnectActions;
    sourceInfo?: ISourceInfo<IMPPFileSourceData>;
    dismissPanel?: () => void;
}

export interface IMPPFileSourceData {
    fileName: string;
}

export const MPPFileConnectControl = (props: Props) => {
    const { sourceInfo, readonly } = props;
    const fileName = sourceInfo?.sourceData.fileName;
    const isLinked = !!sourceInfo;

    if(!isLinked)
        return null;

    return <div>
        <TextField label="MPP File Name"
            disabled
            title={fileName}
            value={fileName} />
        <OverlayComponent isOverlayed={readonly || !fileName}>
        <PrimaryButton text="Delete link"
                    className="with-top-margin"
                    disabled={readonly || (sourceInfo && sourceInfo.syncStatus.inProgress)}
                    title={!!(sourceInfo && sourceInfo.syncStatus.inProgress) ? "Sync in progress." : undefined}
                    onClick={() => deleteLink(props, sourceInfo?.connectionId)} />
        </OverlayComponent>
    </div>
}

const deleteLink = (props: Props, connectionId?: string) => {
    const { actions, dismissPanel } = props;
    actions.deleteLink(connectionId);
    dismissPanel?.();
}