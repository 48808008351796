import { Label } from 'office-ui-fabric-react';
import React from 'react';
import './AIDigestDataSelector.css';
import Spinner from '../../../common/Spinner';
import { DigestDataItem } from '../common';
import AIDigestDataItem from '../AIDigestDataItem';

type Props = {
    items: DigestDataItem[];
    onItemChecked: (item: DigestDataItem) => void;
    isLoading: boolean;
}

const AIDigestDataSelector = (props: Props) => {
    const { items, isLoading, onItemChecked } = props;

    return <>
        {isLoading
            ? <Spinner />
            : <div className='ai-digest-data-selector'>
                <Label className='ai-digest-items-header'>Data included in Project Digest</Label>
                <div className='ai-digest-items'>
                    {items.map(item => <AIDigestDataItem key={item.key} item={item} onItemChecked={onItemChecked} />)}
                </div>
            </div>
        }
    </>
}

export default AIDigestDataSelector;