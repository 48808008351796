import * as React from 'react';
import { IListViewColumn } from "../../../../../services/ViewService";
import { Portfolio } from "../../../../../store/PortfoliosListStore";
import { toPercentString } from "../../../../utils/common";

export default class PercentCostCompleted extends React.Component<IListViewColumn<Portfolio>> {
    public render() {
        return <span className="font-14">
            {toPercentString(PercentCostCompleted.getValue(this.props.entity))}
        </span>;
    }

    public static getValue(entity: Portfolio): number {
        const costs = entity.calculation.costs;
        return costs.plan !== 0 ? costs.actual / costs.plan : 0;
    }

    public static getSearchValue(entity: Portfolio): string {
        return toPercentString(PercentCostCompleted.getValue(entity));
    }
}