import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

export interface IErrorProps {
    title: string;
    description?: string;
}

export type ErrorState = {
    title?: string;
    description?: string;
};

export enum ActionTypes {
    UPDATE_ERROR_INFO = 'UPDATE_ERROR_INFO'
}

export type UpdateErrorInfoAction = { type: ActionTypes.UPDATE_ERROR_INFO; error: ErrorState; };
export type KnownAction = UpdateErrorInfoAction;

export const actionCreators = {
    resetError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.UPDATE_ERROR_INFO, error: {} });
    },
    setError: (error: IErrorProps): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.UPDATE_ERROR_INFO, error });
    }
};

export const reducer: Reducer<ErrorState> = (state: ErrorState = {}, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UPDATE_ERROR_INFO: return action.error;
    }

    return state;
};