import { Slider } from 'office-ui-fabric-react';
import * as React from 'react';
import { FormatType } from '../../../entities/Metadata';
import { OKRDirection, OKRValueType } from '../../../store/ObjectivesListStore';
import { FormatDate, formatValue, HUNDRED_PCT } from '../../utils/common';
import { Dictionary } from '../../../entities/common';

export const PeriodFormatter = (props: { start?: string | Date, finish?: string | Date, style?: React.CSSProperties }) => {
    const { start, finish, style } = props;
    return <div style={style}>
        {start && finish && <>{FormatDate(start)} - {FormatDate(finish)}</>}
        {start && !finish && <>{FormatDate(start)}</>}
        {!start && finish && <>{FormatDate(finish)}</>}
        {!start && !finish && <>-</>}
    </div>;
}

export const ProgressFormatter = (props: { value?: number, label?: string }) => {
    let { value } = props;
    if (value && value < 0) {
        value = 0;
    }
    
    return (
        <Slider
            {...props}
            disabled
            styles={{
                root: { minWidth: 200 },
                thumb: { display: 'none' },
                activeSection: { backgroundColor: '#008272' },
                lineContainer: { height: 12 },
                slideBox: { padding: 0 }
            }}
            showValue
            value={value}
            valueFormat={(value: number) => formatValue(value, FormatType.Percent)}
            min={0}
            max={props.value! <= HUNDRED_PCT ? HUNDRED_PCT : props.value}
        />
    );
}

export const formatOkrProgress = (direction: OKRDirection, currentValue: number, targetValue: number, startValue?: number): number => {
    startValue = startValue ?? 0;
    if (direction === OKRDirection.AboveThreshold) {
        return currentValue >= targetValue ? HUNDRED_PCT : 0;
    }
    if (direction === OKRDirection.BelowThreshold) {
        return currentValue <= targetValue ? HUNDRED_PCT : 0;
    }
    const progress = targetValue !== startValue
        ? (currentValue - startValue) * HUNDRED_PCT / (targetValue - startValue)
        : 0;
    return Number.isNaN(progress) ? 0 : progress;
}

export const OkrProgressFormatter = (props: { direction: OKRDirection, currentValue: number, targetValue: number, startValue?: number, label?: string }) =>
    <ProgressFormatter {...props} value={formatOkrProgress(props.direction, props.currentValue, props.targetValue, props.startValue)} />

export const formatsMap: Dictionary<FormatType> = {
    [OKRValueType.Money]: FormatType.Cost,
    [OKRValueType.Percent]: FormatType.Percent
}

const formatsOKRFlagMap: { [i: number]: string } = {
    [0]: "No",
    [1]: "Yes"
};

export const formatOKRValue = (value: number | undefined, type?: OKRValueType): string | null =>
    type === OKRValueType.Flag
        ? formatsOKRFlagMap[value!]
        : formatValue(value, type !== undefined ? formatsMap[type] : undefined);

export const OKRValueFormatter = (props: { value?: number, type?: OKRValueType, hidden?: boolean }) =>
    props.hidden
        ? null
        : <>{formatOKRValue(props.value, props.type)}</>

export const propsOKRFlagLabels = {
    offText: formatsOKRFlagMap[0],
    onText: formatsOKRFlagMap[1]
};