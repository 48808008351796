import * as React from 'react';
import { withRouter } from 'react-router';
import { getProjectTasksPath, ProjectInfo } from '../../../../../store/ProjectsListStore';
import { IconSize, SystemIcon } from '../summaryControl/LinkedSystemIcons';
import { SourceType_ } from '../../../../../store/ExternalEpmConnectStore';

type OwnProps = { entity: ProjectInfo }
type Props = OwnProps;

const TasksButton = (props: Props) => {
    const { entity } = props;
    const { primarySchedule } = entity.settings;

    return <SystemIcon
        iconName={SourceType_.getIconName(primarySchedule)}
        iconSize={IconSize.small}
        label='View tasks'
        title='View primary schedule'
        url={getProjectTasksPath(entity, entity.sourceInfos.find(_ => _.type === primarySchedule)?.connectionId)} />;
}

export default withRouter<OwnProps>(TasksButton);