import * as React from "react";
import { Dictionary } from "../../entities/common";
import { Toggle, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { FieldInfo } from "../../entities/Metadata";
import { ISettingsEditComponent, IBaseFieldInfo, FieldActionTypes } from "./FieldPanel";

type Props = {
    settings?: Dictionary<any>;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
}

type State = {
    multichoice: boolean;
}

export class ResourceSettingsEdit extends React.Component<Props, State> implements ISettingsEditComponent {
    constructor(props: Props) {
        super(props);
        this.state = {
            multichoice: props.settings ? props.settings['multichoice'] == true : false
        };
    }

    public render() {
        const { multichoice } = this.state;
        const { actionType } = this.props;

        return <>
            {actionType == FieldActionTypes.Create && <MessageBar key="msgbar" messageBarType={MessageBarType.warning}>Note, Multiple Choice can be enabled only during field creation. You won't be able to change it later.</MessageBar>}
            <Toggle key='toggle'
                label='Multiple Choice'
                checked={multichoice}
                disabled={actionType == FieldActionTypes.Edit}
                onChange={(e, checked) => { this.setState({ multichoice: !!checked }); this.props.onChange(true); }}
                onText='Yes'
                offText='No' />
        </>;
    }

    public save(fieldInfo: FieldInfo) {
        this._applyChanges(fieldInfo);
    }

    public update(fieldInfo: IBaseFieldInfo) {
        //change is not supported
    }

    private _applyChanges(fieldInfo: IBaseFieldInfo | FieldInfo) {
        fieldInfo.settings = {
            ...fieldInfo.settings,
            multichoice: this.state.multichoice
        };
    }
}
