import { AppThunkAction } from '.';
import { Dictionary, EntityType } from '../entities/common';
import { post, remove } from './../fetch-interceptor';
import { defaultCatch } from './utils';

export interface BaseSubentitiesUpdateAction<T> {
    entityId: string;
    addOrUpdate?: T[] | T;
    remove?: string[];
    set?: T[];
}

export type ICreationData<IAttrs = Dictionary<any>> = {
    id?: string,
    attributes: IAttrs,
    externalData: Dictionary<any>,
    insertBeforeId?: string,
    parentId?: string
};

export type Actions = {
    create: (entityId: string, subentity: ICreationData) => void;
    update: (enitityId: string, subentityId: string, changes: Dictionary<unknown>) => void;
    remove: (entityId: string, ids: string[]) => void;
}

export type StoreActions<T extends string> = {
    [K in keyof Actions as `${K}${T}`]: Actions[K]
}

export const renameActionsFor = <TSuffix extends string>(key: TSuffix, obj: Actions): StoreActions<TSuffix> => 
    Object.keys(obj).reduce((a, b) => ({ ...a, [`${b}${key}`]: obj[b] }), {} as any);

export const actionsForBuilder = <KnownAction>(entityType: EntityType) => <T extends {}>() => ({
    create: <Suffix extends string>(subentityType: Suffix, action: (payload: BaseSubentitiesUpdateAction<T>) => KnownAction) => renameActionsFor(subentityType, {
        remove: (entityId, ids): AppThunkAction<KnownAction> => (dispatch, getState) => {
            remove(`api/${entityType}/${entityId}/${subentityType}`, { ids })
                .then(_ => dispatch(action({ entityId, remove: ids })))
                .catch(defaultCatch(dispatch));
        },
        create: (entityId, entity): AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<T>(`api/${entityType}/${entityId}/${subentityType}`, entity)
                .then(data => dispatch(action({ entityId, addOrUpdate: data })))
                .catch(defaultCatch(dispatch));
        },
        update: (entityId, subentityId, changes): AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<T>(`api/${entityType}/${entityId}/${subentityType}/${subentityId}`, changes)
                .then(data => dispatch(action({ entityId, addOrUpdate: data })))
                .catch(defaultCatch(dispatch));
        }
    })
});