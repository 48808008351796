import "./AzureADUsersSyncSettings.css";
import * as React from 'react';
import { Dropdown, IDropdownOption, MessageBar, MessageBarType, ActionButton, ComboBox, Spinner,
    SpinnerSize, Toggle, ChoiceGroup, IChoiceGroupOption, TooltipHost, Icon } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { UserState } from '../../../../store/User';
import { CommonOperations, contains } from '../../../../store/permissions';
import { actionCreators, AzureADUsersSyncSettings as AzureADUsersSyncSettingsType, PPMFeatures, AdUsersSyncType } from '../../../../store/Tenant';
import { ApplicationState } from '../../../../store';
import TimeSelector from '../../../common/TimeSelector';
import DayOfWeekSelector from '../DayOfWeekSelector';
import LabellableComponent from '../../../common/LabellableComponent';
import { FormatDateTime } from '../../../utils/common';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import { GroupInfo, OrganizationInfo } from '../../../../store/integration/Office365Store';
import { actionCreators as Office365StoreActionCreators } from "../../../../store/integration/Office365Store";
import Office365ConnectButton from "../../../integration/Office365/Office365ConnectButton";
import { IListStore } from "../../../../store/integration/common";
import Link from "../../../common/Link";

type OwnProps = {};
type StateProps = {
    user: UserState;
    adUsersSyncSettings: AzureADUsersSyncSettingsType;
    organizations: IListStore<OrganizationInfo>;
    groups: IListStore<GroupInfo>;
    isLoading: boolean;
    isFeatureAvaiable: boolean;
}

type Props = StateProps & OwnProps & {
    tenantActions: typeof actionCreators;
    office365StoreActions: typeof Office365StoreActionCreators;
};

const adUsersSyncTypeOptions: IChoiceGroupOption[] = [
    {
        text: 'New Users/Team Members are created with Pending Invite status, but invitation emails are not sent',
        value: AdUsersSyncType.DoNotSendInvites,
        key: AdUsersSyncType.DoNotSendInvites.toString(),
        onRenderLabel: (p, render) => <div className="align-center">
            {render!(p)}
            <TooltipHost 
                hostClassName="align-center field-item-tooltip"
                content="If enabled, all new users/team members added to Azure AD groups for User and Team Member licenses will be added to the People Managment page
                    with Pending Invite status, but invitation emails will not be automatically sent to them. The invitation emails can be sent manually from People Managment
                    page to all or selected users.">
                <Icon iconName="Info" />
            </TooltipHost>
        </div>
    },
    {
        text: 'New Users/Team Members are created with Pending Invite status, and invitation emails are sent',
        value: AdUsersSyncType.SendInvites,
        key: AdUsersSyncType.SendInvites.toString(),
        onRenderLabel: (p, render) => <div className="align-center">
            {render!(p)}
            <TooltipHost 
                hostClassName="align-center field-item-tooltip"
                content="If enabled, all new users/team members added to Azure AD groups for User and Team Member licenses will be added to the People Managment page
                    with Pending Invite status, and invitation emails will be automatically sent to them.">
                <Icon iconName="Info" />
            </TooltipHost>
        </div>
    },
    {
        text: 'New Users/Team Members are created with Active status',
        value: AdUsersSyncType.AutoActivation,
        key: AdUsersSyncType.AutoActivation.toString(),
        onRenderLabel: (p, render) => <div className="align-center">
            {render!(p)}
            <TooltipHost 
                hostClassName="align-center field-item-tooltip"
                content="If enabled, all new users/team members added to Azure AD groups for User and Team Member licenses will be automatically activated
                    upon synchronization with Azure AD. They will appear on the People Management page with an Active status and will be able to join the tenant
                    without receiving an invitation email. Exception: added as Guest Users to the Azure AD will appear on the People Management page with a Pending Invite
                    status, and the invitation emails will be automatically sent to them.">
                <Icon iconName="Info" />
            </TooltipHost>
        </div>
    }
];

const AzureADUsersSyncSettings = (props: Props) => {
    const [state, setState] = React.useState(props.adUsersSyncSettings!);
    const [syncRunned, setSyncRunned] = React.useState<boolean>();
    React.useEffect(() => { setState(props.adUsersSyncSettings) }, [props.adUsersSyncSettings]);
    const { organization, lastSyncTime, lastSyncErrorMessage, schedule, usersGroup, teamMembersGroup, isADUsersSyncEnabled, syncType } = state;
    const { isFeatureAvaiable, isLoading, organizations, groups, user } = props;

    const saveSettings = React.useCallback((settings) => {
        setState(settings);
        props.tenantActions.updateADUsersSyncSettings(settings.schedule, settings.organization, settings.usersGroup, settings.teamMembersGroup,
            settings.isADUsersSyncEnabled, settings.syncType);
    }, []);
    const onChangeDayOfWeek = React.useCallback(days => saveSettings({ ...state, schedule: { ...state.schedule, days } }), [organization, schedule]);
    const onChangeTime = React.useCallback(times => saveSettings({ ...state, schedule: { ...state.schedule, times } }), [organization, schedule]);
    const onChangeOrganization = React.useCallback(_ => state.organization?.name !== _.name
        && saveSettings({ ...state, organization: _, usersGroup: undefined, teamMembersGroup: undefined, isADUsersSyncEnabled: false }), [organization, schedule]);
    const onChangeUsersGroup = React.useCallback(_ => saveSettings({ ...state, usersGroup: _ }), [usersGroup, schedule]);
    const onChangeTeamMembersGroup = React.useCallback(_ => saveSettings({ ...state, teamMembersGroup: _ }), [teamMembersGroup, schedule]);
    const onChangeIsADUsersSyncEnabled = React.useCallback((e, _) => saveSettings({ ...state, isADUsersSyncEnabled: _ }), [isADUsersSyncEnabled, schedule]);
    const onChangedADUsersSyncType = React.useCallback((_) => saveSettings({ ...state, syncType: _ }), [syncType, schedule]);

    const runADUsersSync = React.useCallback(() => {
        props.tenantActions.runADUsersSync();
        setSyncRunned(true);
    }, []);

    const organizationOptions = React.useMemo(() => {
        const options = organizations.entities.map((_) => ({ key: _.name, text: _.name } as IDropdownOption));
        if (organization?.name && !options.find(_ => _.key === organization.name)) {
            options.push({ key: organization.name, text: `${organization.name} <Connection not found>`, disabled: true })
        }
        return options;
    }, [organizations]);

    const groupsOptions = React.useMemo(() => {
        return groups.entities?.map(_ => ({ key: _.id, text: !_.email ? _.title : `${_.title} (${_.email})` }));
    }, [groups]);

    React.useEffect(() => {
        if (organization?.name) {
            props.office365StoreActions.loadGroups(organization.name);
        }
    }, [organization?.name]);

    React.useEffect(() => {
        props.tenantActions.loadADUsersSyncSettings();
        props.office365StoreActions.loadOrganizations();
    }, []);

    const canManage = contains(user.permissions.common, CommonOperations.ScheduleManage);
    const noOrganizationsAvailable = !organizations?.entities?.length;
    const organizationNotFound = !organizations?.entities?.find(_ => _.name === organization?.name);
    const isSectionDisabled = !canManage || noOrganizationsAvailable || !isFeatureAvaiable;
    const noGroupsAvailable = !groups?.entities?.length;
    const isConfigurationComplete = organization && !organizationNotFound && usersGroup && teamMembersGroup;

    return <div className="sync-settings settings-fields-holder ad-users-sync-settings">
        {!isLoading && !isFeatureAvaiable
            ? <MessageBar messageBarType={MessageBarType.warning}>
                Azure Active Directory users synchronization feature is not available in your PPM Express plan. Please
                <Link href="https://ppm.express/contact-us" target="_blank">contact us</Link> to request a demo or get trial.
            </MessageBar>
            : !organizations.isLoading && noOrganizationsAvailable
                ? <MessageBar messageBarType={MessageBarType.warning}>
                    To start data synchronization from Azure Active Directory to PPM Express People Management page,
                    please <Office365ConnectButton sourceType={SourceType.O365User} onCreated={() => props.office365StoreActions.loadOrganizations()}>
                        add an Office 365 connection
                    </Office365ConnectButton>
                </MessageBar>
                : <MessageBar messageBarType={MessageBarType.warning}>
                    When Azure Active Directory users synchronization is enabled, all existing users within your tenant on the People Management page will be deactivated,
                    unless they are members of the Users or Team Members group.
                </MessageBar>}
        <Dropdown
            label="Organization"
            options={organizationOptions}
            disabled={isLoading || organizations.isLoading || isSectionDisabled}
            selectedKey={organization?.name}
            onChange={(e, option) => option && onChangeOrganization(organizations.entities.find(_ => _.name === option.key))}
        ></Dropdown>
        {groups.error && <MessageBar messageBarType={MessageBarType.error}>{groups.error}</MessageBar>}
        <ComboBox
            label="Azure AD group for User license"
            placeholder="Select Azure AD group"
            disabled={groups.isLoading || noGroupsAvailable || isSectionDisabled}
            useComboBoxAsMenuWidth
            allowFreeform
            options={groupsOptions}
            selectedKey={usersGroup?.id}
            onChange={(e, option) => option && onChangeUsersGroup(groups.entities.find(_ => _.id === option.key))}
        />
        {groups.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -32, marginBottom: 5, width: 32 }} />}
        <ComboBox
            label="Azure AD group for Team Member license"
            placeholder="Select Azure AD group"
            disabled={groups.isLoading || noGroupsAvailable || isSectionDisabled}
            useComboBoxAsMenuWidth
            allowFreeform
            options={groupsOptions}
            selectedKey={teamMembersGroup?.id}
            onChange={(e, option) => option && onChangeTeamMembersGroup(groups.entities.find(_ => _.id === option.key))}
        />
        {groups.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -32, marginBottom: 5, width: 32 }} />}
        <TimeSelector
            disabled={isSectionDisabled}
            label="Daily synchronization schedule"
            times={schedule.times}
            onChange={onChangeTime}
        />
        <DayOfWeekSelector readonly={isSectionDisabled} value={schedule.days} onChange={onChangeDayOfWeek} />
        <ChoiceGroup
            defaultSelectedKey={syncType.toString()}
            options={adUsersSyncTypeOptions}
            disabled={isSectionDisabled}
            onChange={(_, option) => onChangedADUsersSyncType(option!.value)}
        />
        <LabellableComponent className={`field-container ${isSectionDisabled || !isConfigurationComplete ? "readonly" : ""}`}
            label="Enable Synchronization with Azure AD"
            description={`If enabled:

- All existing users within your tenant on the People Management page will be deactivated, unless they are members of the Users or Team Members group from Azure AD. 
- The 'Invite users', 'Auto registration', and invitations via Project sharing are disabled in PPM Express. Users can join PPM Express only through Azure AD synchronization. 
- All users added to Azure AD groups for User and Team Member license will be synchronized to PPM Express according to Daily synchronization schedule \
or manually using 'Synchronize now' option.`}>
            <Toggle
                disabled={isSectionDisabled || !isConfigurationComplete}
                checked={isADUsersSyncEnabled}
                onChange={onChangeIsADUsersSyncEnabled}
            />
        </LabellableComponent>
        <LabellableComponent className={`field-container ${isSectionDisabled ? "readonly" : ""}`} label="Last Sync">
            {FormatDateTime(lastSyncTime, true) || "-"}
            {lastSyncErrorMessage && <MessageBar messageBarType={MessageBarType.error}>{lastSyncErrorMessage}</MessageBar>}
        </LabellableComponent>
        <ActionButton
            disabled={isSectionDisabled || !isConfigurationComplete || !isADUsersSyncEnabled || syncRunned}
            iconProps={{ iconName: "Sync" }}
            onClick={runADUsersSync}>Synchronize now</ActionButton>
    </div>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        isLoading: state.tenant.isLoading,
        isFeatureAvaiable: contains(state.tenant.subscription.features, PPMFeatures.AzureADUsersSync),
        adUsersSyncSettings: state.tenant.adUsersSyncSettings!,
        organizations: state.office365.organizations,
        groups: state.office365.groups
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        tenantActions: bindActionCreators(actionCreators, dispatch),
        office365StoreActions: bindActionCreators(Office365StoreActionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(AzureADUsersSyncSettings);