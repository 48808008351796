import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { UserState as IUserState } from "../../store/User";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { Subscription, PPMFeatures } from "../../store/Tenant";

type StateProps = {
    subscription: Subscription;
    user: IUserState;
    intercomAppId: string | null;
};

type Props = StateProps & RouteComponentProps<{}>;

const LiveChat = (props: Props) => {
    const { user, subscription, intercomAppId } = props;

    const showFull = user.preferences.expandSideBar;
    const isPartnersSupportAvailable = Subscription.contains(subscription, PPMFeatures.PartnersSupport);
    
    if (isPartnersSupportAvailable || !intercomAppId) {
        return null;
    }

    return (
        <a className="nav-link live_chat" title="Live Chat" href={`mailto:${intercomAppId}@incoming.intercom.io`}>
            <div className="icon-container">
                <Icon iconName="FeedbackRequestSolid" style={{ color: "#ffb900" }} />
                {showFull && <span className="title">Live Chat</span>}
            </div>
        </a>
    );
};

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
        intercomAppId: state.analytics.intercomAppId,
    };
}

export default withRouter<{}>(connect(mapStateToProps)(LiveChat));
