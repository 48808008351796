import { ActionButton, IContextualMenuItem } from 'office-ui-fabric-react';
import React, { useRef, useState } from 'react'
import { formatValue, toDateTime } from '../utils/common';
import { FormatType } from '../../entities/Metadata';
import DatePickerInput from '../common/inputs/DatePickerInput';
import { IFormInputComponent } from '../common/interfaces/IFormInputComponent';
import { TimeTrackingGlobalSettings } from '../../store/Tenant';

type Props = {
    reportTime: (hours: number, date: Date) => void,
    settings: TimeTrackingGlobalSettings;
}

const today = new Date();
const timeReportHours = [0.25, 0.5, 0.75, 1, 2, 3, 4, 5, 6, 7, 8];

function TimeTrackingReportTimeMenu(props: Props) {

    const { reportTime, settings } = props;
    
    const [date, setDate] = useState(today);
    const datePickerRef = useRef<IFormInputComponent | null>();

    return (
        <div className='time-tracking-menu'>            
            <div className='ms-ContextualMenu-link'>
                <DatePickerInput
                    className='ms-ContextualMenu-linkContent full-width'
                    inputRef={_ => datePickerRef.current = _}
                    value={date.toDateOnlyString()}
                    onChanged={_ => setDate(toDateTime(_) || today)}
                    minDate={toDateTime(settings.timeReportingLock)}/>
            </div>           

            {timeReportHours.filter(_ => !settings.minReportingDurationMinutes || settings.minReportingDurationMinutes <= _ * 60 ).map(_ => (
                <div className='ms-ContextualMenu-link' key={_}>
                    <ActionButton
                        className='ms-ContextualMenu-linkContent full-width'
                        text={formatValue(_, FormatType.Duration)}
                        onClick={() => {
                            reportTime(_, date);
                        }}
                    />
                </div>
            ))}
        </div>);
}

export function buildReportTimeMenuItem(
    reportTime: (hours: number, date: Date) => void,
    disabled: boolean,
    settings: TimeTrackingGlobalSettings): IContextualMenuItem {
    
    const items: IContextualMenuItem[] = disabled ? []
        : [
            {
                key: "timetracking-report-time",
                onRender: (item: any, dismiss: (ev?: any, dismissAll?: boolean) => void) =>
                    <TimeTrackingReportTimeMenu
                        reportTime={(hours, date) => {
                            reportTime(hours, date);
                            dismiss(null, true);
                        }}
                        settings={settings} />
            }
        ];
    
    return {
        key: 'reportTime',
        name: 'Report Time',
        disabled: disabled,
        iconProps: { iconName: "Clock" },
        subMenuProps: {
            items: items
        }        
    };
}