import * as React from 'react';
import EntityCreation from "../common/extensibleEntity/EntityCreation";
import { connect } from 'react-redux';
import { actionCreators } from "../../store/PortfoliosListStore";
import { EntityType } from '../../entities/common';
import { Toggle } from 'office-ui-fabric-react';
import LabellableComponent from '../common/LabellableComponent';

interface OwnProps {
    onDismiss: () => void;
    openOnComplete: boolean;
}

type PortfolioCreationProps = typeof actionCreators & OwnProps;

const PortfolioCreation = (props: PortfolioCreationProps) => {
    const [createFilter, setCreateFilter] = React.useState(false);
    return <EntityCreation entityType={EntityType.Portfolio}
        onDismiss={props.onDismiss}
        onSave={(name, layoutId) => props.createPortfolio(name, layoutId, createFilter, props.openOnComplete)}>
        <div className="grid-item">
            <LabellableComponent label='' className="field-container">
                <Toggle className="two-column"
                    label={<span title="A new filter option will be added to the Projects Page, enabling you to filter projects by this Portfolio.">Create Projects Filter</span>}
                    title="A new filter option will be added to the Projects Page, enabling you to filter projects by this Portfolio."
                    checked={createFilter}
                    onChange={(e, checked: boolean) => setCreateFilter(checked)}
                    onText='On'
                    offText='Off' />
            </LabellableComponent>
        </div>
    </EntityCreation>;
}

export default connect(undefined, actionCreators)(PortfolioCreation)