import * as React from "react";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import DatePickerInput from "../../common/inputs/DatePickerInput";

type Props = {
    date: Date | undefined;
    onChange: (date?: Date) => void;
};

type State = {
    selectedKey: string;
};

interface IExpirationOption extends IDropdownOption {
    key: string;
    data: {
        months?: number;
        years?: number;
    }
}

export default class ExpirationDate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { selectedKey: defaultExpiration.key };
    }

    public render() {
        const { date } = this.props;
        const { selectedKey } = this.state;
        return <>
            <Dropdown
                selectedKey={selectedKey}
                options={expirationOptions}
                onChange={this._onDropdownChange} />
            <DatePickerInput
                minDate={new Date().getBeginOfDay()}
                value={date?.toString()}
                isRequired
                onChanged={this._onChanged} />
        </>
    }

    private _onDropdownChange = (e: any, o?: IExpirationOption) => {
        if (!o) {
            return;
        }

        this.props.onChange(getDate(o));
        this.setState({ selectedKey: o.key });
    }

    private _onChanged = (date?: Date) => {
        this.props.onChange(date);
        this.setState({ selectedKey: customOption.key });
    }
}

export const getDate = (option: IExpirationOption) => {
    const { data } = option;
    const date = new Date().getBeginOfDay();
    if (data.months) {
        date.setMonth(date.getMonth() + data.months);
    }

    if (data.years) {
        date.setFullYear(date.getFullYear() + data.years);
    }

    return date;
}

export const defaultExpiration: IExpirationOption = {
    key: "1-year",
    text: "1 year",
    data: { years: 1 }
};

const customOption: IExpirationOption = {
    key: 'custom',
    text: "Custom defined",
    data: {}
}

const expirationOptions: IExpirationOption[] = [
    {
        key: "1-months",
        text: "1 months",
        data: { months: 1 }
    },
    {
        key: "3-months",
        text: "3 months",
        data: { months: 3 }
    },
    {
        key: "6-months",
        text: "6 months",
        data: { months: 6 }
    },
    defaultExpiration,
    customOption
]