import './SignUpPlans.css';
import * as React from 'react';
import { ProductType } from '../../../store/Tenant';
import { PlanCard } from '../../settings/billing/PlanCard/PlanCard';
import { ChoiseList } from '../../common/ChoiseList';
import { GetProductTypeTitle } from '../../settings/billing/common';
import { SignUpPlan } from '../../../store';

type Props = {
    isLocked: boolean;
    availablePlans: SignUpPlan[];
    selectedProductType?: ProductType;
    onPlanClick: (productType: ProductType) => void;
}

const Descriptions = [
    {
        productType: ProductType.Ideation,
        name: 'Demand and Innovation Management',
        description: 'Minimize the chance of missing important ideas within your organization.',
        features: [
            'Capture Innovative Ideas',
            'Standardize Business Case Management',
            'Plan Resources and Budgets',
            'Plan Benefit Realization',
            'Evaluate and Prioritize Ideas',
            'Drive Strategic Alignment',
            'Use 200+ Microsoft Power BI Reports'
        ]
    },
    {
        productType: ProductType.Resource,
        name: GetProductTypeTitle(ProductType.Resource),
        description: 'Minimize the time it takes to match project requirements with available resources, avoiding overallocation and conflicts.',
        features: [
            'Manage Resource Capacity and Skills',
            'Plan Resource Allocation on Projects',
            'Minimize and Resolve Resource Conflicts',
            'Unlimited Project Management',
            'Use 200+ Microsoft Power BI Reports'
        ]
    },
    {
        productType: ProductType.Work,
        name: GetProductTypeTitle(ProductType.Work),
        description: 'Simple to use, AI-enabled Modern Work and Project Management Platform, Integrated with Microsoft Project Desktop.',
        features: [
            'Project Management AI',
            'Single Portfolio',
            'Unlimited Project Management',
            'Unlimited Task Management',
            'Unlimited Collaboration',
            'Integration with Microsoft Project Desktop',
            '100+ Microsoft Power BI Reports'
        ]
    },
    {
        productType: ProductType.Portfolio,
        name: GetProductTypeTitle(ProductType.Portfolio),
        description: 'One Platform to integrate all you PM tools and manage portfolios of projects.',
        features: [
            'Everything in Project Management plan',
            'Integrate Azure DevOps, Jira, Microsoft Planner, Microsoft Project, Monday.com and Smartsheet',
            'Manage Programs and Portfolios',
            'Capture and Prioritize Demand and Ideas',
            'Strategically Prioritize Projects and Portfolios',
            'Plan Resource Allocation',
            'Plan Budgets and Manage Benefit Realization',
            'Use 200+ Power BI Reports to support Decisions.'
        ]
    },
    {
        productType: ProductType.Product,
        name: GetProductTypeTitle(ProductType.Product),
        description: 'Strategy, Planning and Management of Product Portfolios, Product Lines and Product Roadmaps, Agile way.',
        features: [
            'Everything in Portfolio Plan',
            'Build Roadmaps and Visualize the Strategy',
            'Manage Product Backlogs',
            'Prioritize using Moscow, ICE, RICE, WSJF',
            'Capture, Evaluate and Prioritize Product Feedback'
        ]
    }
]

export const getPlans = (availablePlans: SignUpPlan[]) => {
    return availablePlans.map(_ => _.features
        ? { ..._, name: _.title, description: _.description!, features: _.features.map(f => ({ ...f, marked: false })) }
        : { ...Descriptions.find(d => d.productType == _.productType)!, isPopular: false, iconName: undefined }
    )
}

export const SignUpPlans = (props: Props) => {
    const { selectedProductType, availablePlans, isLocked } = props;
    const plans = getPlans(availablePlans);
    const selectedPlan = plans.find(_ => _.productType === selectedProductType);
    return <div className='signup-plans'>
        <h1>Available Plans</h1>
        <div className='signup-plans-container'>
            {
                !isLocked && <ChoiseList
                    items={plans.map(_ => ({
                        ..._,
                        selected: _.productType === selectedProductType,
                        iconName: ProductType.getIcoName(_.productType)
                    }))}
                    onClick={_ => props.onPlanClick(_.productType)}
                />
            }
            {
                selectedPlan && <PlanCard
                    isCurrent={true}
                    name={selectedPlan.name}
                    description={selectedPlan.description}
                    features={selectedPlan.features}
                    hideComparePlans={true}
                    onClick={() => props.onPlanClick(selectedPlan.productType)}
                    badgeProps={selectedPlan.isPopular ? { isPopular: true } : undefined}
                    productType={selectedPlan.productType}
                />
            }
        </div>
    </div>
}