import React from "react";
import { Dictionary } from "../../entities/common";
import * as Metadata from "../../entities/Metadata";

type ConnectionsVerification = Dictionary<{ isValidating?: boolean, validationError: string | null }>;
export interface IConnectionsState<TData = Metadata.IConnectionInfo> {
    isLoading: boolean;
    data: TData[];
    connectionsVerification: ConnectionsVerification;
    isProcessing: boolean;
    error: string | null;
}

export class ConnectionsState {
    public static setVerificationStarting(state: IConnectionsState, connectionId: string): ConnectionsVerification {
        return { ...state.connectionsVerification, [connectionId]: { isValidating: true, validationError: null } };
    }
    public static setVerificationFinished(state: IConnectionsState, connectionId: string): ConnectionsVerification {
        return { ...state.connectionsVerification, [connectionId]: { isValidating: false, validationError: null } }
    }
    public static setVerificationFailed(state: IConnectionsState, connectionId: string, error: string | null): ConnectionsVerification {
        return { ...state.connectionsVerification, [connectionId]: { isValidating: false, validationError: error } }
    }
    public static remove(state: IConnectionsState, connectionId?: string): ConnectionsVerification {
        return Object.keys(state.connectionsVerification)
            .filter(_ => _ !== connectionId)
            .reduce<ConnectionsVerification>((ac, key) => {
                ac[key] = state.connectionsVerification[key];
                return ac;
            }, {});
    }
}

export interface IImportResult {
    uniqueId: string;
    entityId?: string;
    isSuccessful: boolean;
    error: string;
}

export interface IExternalProject {
    externalId: string;
}

export interface IImportProjectMap<T> {
    linkingData: T;

    projectId?: string;
    projectName?: string;
}

export interface IResourceSourceData {
    displayName: string;
}

export interface IImportResourceMap<T> {
    linkingData: T;

    resourceId?: string;
    resourceName?: string;
}

export interface ILinkDto<T> {
    data: T;
    connectionId: string;
}

export interface IApiResult<T> {
    data: T;
    errorMessage: string;
}

export interface IListStore<T> {
    entities: T[];
    isLoading: boolean;
    error: string | null;
}

export interface IEntityStore<T> {
    isLoading: boolean;
    isProcessing: boolean;
    entities: T;
    error: string | null;
}

export enum MappingType {
    ToPpmx = 0,
    FromPpmx = 1
}

export interface IFieldMapping {
    ppmxField: string;
    externalField: string;
    mappingType: MappingType;
    transform: string;
    isReadonly: boolean;
}

export type IFieldMappingDto = {
    ppmxField?: string;
    externalField?: string;
    mappingType: MappingType;
    transform?: string;
    isReadonly?: boolean;
}

export type IFieldMappingKeyMap = {
    key: string;
    mapping: IFieldMappingDto;
}

export interface ExternalFieldInfo {
    name: string,
    internalName: string,
    type: string,
    isCustomField: boolean,
    isReadOnly: boolean,
    isRequired: boolean,
}

export interface IExternalStatus {
    name: string;
    categoryKey: string;
}

export interface IExternalTypeWithStatus {
    id: string;
    name: string;
    iconUrl?: string;
    statuses: IExternalStatus[];
}

export type TaskImportSettings = Dictionary<IFieldMapping[]>;

export enum StateType {
    NotStarted,
    InProgress,
    Completed,
    //Ignored
}

export const StateTypeMap = [
    {
        id: StateType[StateType.NotStarted],
        label: "Not Started"
    },
    {
        id: StateType[StateType.InProgress],
        label: "In Progress"
    },
    {
        id: StateType[StateType.Completed],
        label: "Completed"
    }
]

export type StateTypeKeys = keyof typeof StateType;

export type MapConfig = {
    types: Metadata.FieldType[];
    label: string;
    validateField?: (ppmxField: Metadata.Field, ppmxFieldType: Metadata.FieldType, label: string) =>
        { isValid: boolean, message?: string };
};

export const validateConnection = (state: IConnectionsState, connectionId?: string): { isValidating?: boolean, validationError?: string | null } | undefined => {
    if (!connectionId) {
        return undefined;
    }
    const connection = state.data.find(_ => _.id === connectionId);
    if (!connection || state.isLoading) {
        return { isValidating: undefined, validationError: undefined };
    }
    const verification = state.connectionsVerification[connectionId];
    return { isValidating: verification?.isValidating, validationError: verification?.validationError };
};

export function loadDataAfterConnectionValidation(
    connectionsState: IConnectionsState,
    nextConnectionsState: IConnectionsState,
    selectedConnectionId: string | undefined,
    loadData: (connectionId: string) => void,
    validateConnectionFun?: (state: IConnectionsState, connectionId?: string) => { isValidating?: boolean, validationError?: string | React.ReactNode | null } | undefined) {

    const prevConnection = validateConnectionFun ? validateConnectionFun(connectionsState, selectedConnectionId) : validateConnection(connectionsState, selectedConnectionId);
    const nextConnection = validateConnectionFun ? validateConnectionFun(nextConnectionsState, selectedConnectionId) : validateConnection(nextConnectionsState, selectedConnectionId);
    const isConnectionValid = nextConnection?.isValidating === false && !nextConnection.validationError;
    if (selectedConnectionId && isConnectionValid && (prevConnection?.isValidating !== false)) {
        loadData(selectedConnectionId);
    }
}

export interface Statistic {
    done: number;
    open: number;
}

export interface IBacklogOverview {
    statistics: Dictionary<Statistic>;
}

export interface CardIteration {
    name: string;
    startDate?: Date;
    finishDate?: Date;
}

export interface CurrentIterationBacklog extends IBacklogOverview, IWithCurrentIteration { }

export interface Progress {
    done: number | null;
    total: number | null;
}

export interface IWithCurrentIteration {
    currentIteration: CardIteration | null;
}

export interface CurrentIterationProgress extends IWithCurrentIteration {
    items: Progress;
    storyPoints: Progress;
    effort: Progress;
}

export interface Iterations extends IWithCurrentIteration {
    futureIterations: CardIteration[];
}

export interface ItemsIterationsScheduleData {
    backlogOverview: IBacklogOverview;
    currentIterationBacklog: CurrentIterationBacklog;
    currentIterationProgress: CurrentIterationProgress;
    iterations: Iterations;
}
