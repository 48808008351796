import * as React from 'react';
import { IconButton, Callout, DirectionalHint, Calendar } from 'office-ui-fabric-react';
import { FormatDate } from "../../utils/common";
import { DayPickerStrings } from "../inputs/DatePickerInput";
import { ITimeframe } from '../../../entities/common';
import { IScale } from './TimelineScale';

type Props = {
    onChangeTimeframe: (_: Partial<ITimeframe> | undefined) => void;
    scale: IScale;
    timeline: ITimeframe;
    userTimeframe: Partial<ITimeframe> | undefined;
};
type State = {
    showStartCalendar?: boolean;
    showEndCalendar?: boolean;
}

export default class TimeframeSelector extends React.Component<Props, State> {
    private startCalendarButtonElement?: HTMLElement;
    private endCalendarButtonElement?: HTMLElement;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const { userTimeframe } = this.props;
        const timeline = this._getTimeline();

        return <>
            <div className='time-frame'>
                <span className="clickable underline" onClick={this.toggleStartCalendar} ref={_ => (this.startCalendarButtonElement = _!)}>
                    {FormatDate(timeline.start)}
                </span> - <span className="clickable underline" onClick={this.toggleEndCalendar} ref={_ => (this.endCalendarButtonElement = _!)}>
                    {FormatDate(timeline.end)}
                </span>
                {
                    userTimeframe && <IconButton title="Reset"
                        style={{ height: "15px", verticalAlign: "top", background: "none" }}
                        iconProps={{ iconName: "Refresh" }}
                        onClick={this.resetCalendars}
                    />
                }
            </div>
            {this.state.showStartCalendar && this._renderCalendar(true, timeline)}
            {this.state.showEndCalendar && this._renderCalendar(false, timeline)}
        </>;
    }

    private _renderCalendar = (editStartDate: boolean, timeline: ITimeframe) => {
        return <Callout
            isBeakVisible={false}
            className="ms-DatePicker-callout"
            doNotLayer={false}
            target={editStartDate ? this.startCalendarButtonElement : this.endCalendarButtonElement}
            gapSpace={5}
            preventDismissOnScroll={true}
            directionalHint={DirectionalHint.bottomLeftEdge}
            onDismiss={editStartDate ? this.toggleStartCalendar : this.toggleEndCalendar}
        >
            <Calendar
                onSelectDate={editStartDate ? this.setTimeframeStartDate : this.setTimeframeEndDate}
                onDismiss={editStartDate ? this.toggleStartCalendar : this.toggleEndCalendar}
                value={editStartDate ? timeline.start : timeline.end}
                strings={DayPickerStrings}
                showGoToToday
            />
        </Callout>
    }

    private _getTimeline = (): ITimeframe => {
        const { scale, userTimeframe } = this.props;
        return {
            start: userTimeframe?.start || scale.dates[0].start,
            end: userTimeframe?.end || scale.dates[scale.dates.length - 1].finish
        }
    }

    private resetCalendars = () => {
        this.props.onChangeTimeframe(undefined);
    }

    private setTimeframeStartDate = (date: Date) => {
        this.toggleStartCalendar();

        let timeframe: Partial<ITimeframe> = { start: date };
        if (date > this._getTimeline().end) {
            timeframe.end = date;
        }
        this.props.onChangeTimeframe(timeframe);
    }

    private setTimeframeEndDate = (date: Date) => {
        this.toggleEndCalendar();

        let timeframe: Partial<ITimeframe> = { end: date };
        if (date < this._getTimeline().start) {
            timeframe.start = date;
        }
        this.props.onChangeTimeframe(timeframe);
    }

    private toggleStartCalendar = () => {
        this.setState({ showStartCalendar: !this.state.showStartCalendar })
    }

    private toggleEndCalendar = () => {
        this.setState({ showEndCalendar: !this.state.showEndCalendar })
    }
}
