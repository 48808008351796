import * as React from 'react';
import { InputsService } from '../DisplayFieldService';
import { SliderProps } from '../inputs/SliderInput';
import { IRangeFilterProps, IRangeFilterValue } from '../interfaces/IRangeFilter';

type State = {
    min?: number;
    max?: number;
    step?: number;
}

type SliderFilterProps = IRangeFilterProps<IRangeFilterValue<number>>;

export default class SliderFilter extends React.Component<SliderFilterProps, State> {
    constructor(props: SliderFilterProps) {
        super(props);
        const { field } = props;
        this.state = {
            min: field.settings ? field.settings.minValue : undefined,
            max: field.settings ? field.settings.maxValue : undefined,
            step: field.settings ? field.settings.step : undefined
        }
    }

    public render() {
        const { value = {}, onEditComplete } = this.props;
        const fromProps: SliderProps = {
            ...this.state,
            value: value.from,
            onEditComplete: _ => {
                const from = _ !== null ? _ : undefined;
                onEditComplete({ from: from, to: from !== undefined && value.to !== undefined && from > value.to ? from : value.to });
            }
        }
        const toProps: SliderProps = {
            ...this.state,
            value: value.to,
            onEditComplete: _ => {
                const to = _ !== null ? _ : undefined;
                onEditComplete({ from: to !== undefined && value.from !== undefined && to < value.from ? to : value.from, to: to });
            }
        }
        return <div>
            {InputsService.buildSlider(fromProps)}
            {InputsService.buildSlider(toProps)}
        </div>;
    }

    componentDidMount() {
        const { value, onEditComplete } = this.props;
        const { min, max } = this.state;
        !value && onEditComplete({ from: min, to: max })
    }
}