
import * as React from 'react';
import { PPMFeatures } from '../../../../store/Tenant';
import "./SwitchTrialDialog.css";

export const FeaturesList = (props: { features: number, isNew: boolean }) => {
    return <ul className='switch-trial-features-list'>
        {
            PPMFeatures
                .getFeaturesNumbers()
                .filter(_ => props.features & _)
                .map(_ => <li key={_} className={`${props.isNew ? "new" : "lost"}`}>
                    <span>{PPMFeatures.getTitle(_)}</span>
                </li>)
        }
    </ul>
}