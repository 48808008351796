import * as React from 'react';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { RouteComponentProps, withRouter } from "react-router-dom";

type OwnProps = {
    onDismiss: () => void;
}

type Props = OwnProps & RouteComponentProps<{}>;
const DemoDataConfirmationDialog = (props: Props) => <ConfirmationDialog
    onDismiss={props.onDismiss}
    onNo={props.onDismiss}
    onYes={() => props.history.push("/projects/timeline")}
    yesButtonProps={{ text: "View Projects" }}
    noButtonProps={{ text: "I’ll do it later" }}
    dialogContentProps={{
        title: "Demonstration data is deployed",
        subText: `The demonstration data is added. To start exploring PPM Express, go to Projects.`
    }}>
</ConfirmationDialog>;
export default withRouter<OwnProps>(DemoDataConfirmationDialog);