import * as React from 'react';
import { Field } from '../../../../entities/Metadata';
import { GroupedFields, GroupedSelectedFieldsList } from '../GroupedSelectedFieldsList';
import { EntityType } from '../../../../entities/common';
import EmptyGroupMessage from '../EmptyGroupMessage';
import SectionTitle from '../SectionTitle';
import { FieldsList } from '../FieldsList';
import ColumnFieldsCollapsible, { COLUMN_GROUP_NAMES } from '../ColumnFieldsCollapsible';
import { IFieldActions } from '../../FieldPanel';
import { resultsNotFoundMessage } from '../GroupedDisabledFields';
import { GroupedDraggableItems } from '../../../common/GroupedDraggableList';

export type ColumnGroupedFieldNames = {
    column1: string[];
    column2: string[];
};

export type GroupedFieldsConfigurationSection = {
    entityType: EntityType;
    newFields: string[];
    filter?: string;
    flattenGroups?: boolean;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    onChange?: (fields: ColumnGroupedFieldNames) => void;
};

type Props = GroupedFieldsConfigurationSection & {
    selected: ColumnGroupedFieldNames;
    selectedFields: Field[];
};

const FieldsInThisViewSection = (props: Props) => {
    const { selected, selectedFields, newFields, filter, flattenGroups, onChange } = props;
    const flattenedSelectedFieldNames = useFlattenColumnGroupedFieldNames(selected);

    const onGroupRender = React.useCallback((groupedItems: GroupedDraggableItems<Field>, renderGroupedItems: () => JSX.Element[]) => (
        <ColumnFieldsCollapsible key={groupedItems.group} group={groupedItems.group}>
            {renderGroupedItems()}
        </ColumnFieldsCollapsible>
    ), []);

    const onEmptyGroupItemsRender = React.useCallback((group: string) => (
        <EmptyGroupMessage
            className="empty-column-group"
            message={`No fields have been added yet` + (group === COLUMN_GROUP_NAMES.COLUMN_2 ? ". Drag and drop fields from Column 1 here" : "")}
        />
    ), []);

    const onGroupedFieldsListChange = React.useCallback((groupedFields: GroupedFields[]) => {
        onChange?.({
            column1: mapGroupFieldNames(groupedFields, 'column1'),
            column2: mapGroupFieldNames(groupedFields, 'column2'),
        });
    }, [onChange]);

    const onFlattenedFieldsListChange = React.useCallback((fields: Field[]) => {
        const selectedFields = fields.map(_ => _.name);
        const filterFields = (columnFields: string[]) => selectedFields.filter(_ => columnFields.includes(_));
        onChange?.({
            column1: filterFields(selected.column1),
            column2: filterFields(selected.column2),
        })
    }, [selected, onChange]);

    return <>
        <SectionTitle title="Fields in this View" />
        {!filter && !flattenGroups ? (
            <GroupedSelectedFieldsList
                {...props}
                className='fields-in-this-view'
                selected={[
                    {
                        group: COLUMN_GROUP_NAMES.COLUMN_1,
                        fields: selected.column1,
                    },
                    {
                        group: COLUMN_GROUP_NAMES.COLUMN_2,
                        fields: selected.column2,
                    },
                ]}
                fields={selectedFields}
                newFields={newFields}
                onChange={onChange ? onGroupedFieldsListChange : undefined}
                onGroupRender={onGroupRender}
                onEmptyGroupItemsRender={onEmptyGroupItemsRender}
            />
        ) : (
            <FieldsList
                {...props}
                className='fields-in-this-view'
                fields={selectedFields}
                selected={flattenedSelectedFieldNames}
                newFields={newFields}
                onChange={onChange ? onFlattenedFieldsListChange : undefined}
                filter={filter}
                emptyMessages={{
                    notFound: <EmptyGroupMessage message={resultsNotFoundMessage} />,
                    noFields: onEmptyGroupItemsRender(COLUMN_GROUP_NAMES.COLUMN_1)
                }}
            />
        )}
    </>;
}

export const useFlattenColumnGroupedFieldNames = (grouped: ColumnGroupedFieldNames) => React.useMemo(() => [...grouped.column1, ...grouped.column2], [grouped]);

const mapGroupFieldNames = (groupedFields: GroupedFields[], groupName: keyof ColumnGroupedFieldNames): string[] => {
    return groupedFields.find(_ => _.group === groupName)!.fields.map(_ => _.name);
}

export default FieldsInThisViewSection;
