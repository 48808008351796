import * as React from "react";
import './ConfigureConnectionPanel.css'
import { Panel, PanelType, Pivot } from "office-ui-fabric-react";
import { SourceType, SourceType_ } from "../../store/ExternalEpmConnectStore";

type Props = {
    onDismiss: () => void;
    initialTab?: string;
    pivots: JSX.Element[];
    sourceType: SourceType;
    onRenderHeader?: () => JSX.Element;
}

const PANEL_WIDTH = "600px";

const ConfigureConnectionPanel = (props: Props) => {
    const { sourceType } = props;

    const onRenderHeader = React.useCallback(() => (
            <div className="ms-Panel-header">
                <p className="ms-Panel-headerText">Configure Connection</p>
                <div className="ms-Panel-secondaryText">
                    Set up Fields mapping, Progress Calculation and import rules
                    for the data exchange with project in {SourceType_.getName(sourceType)}.
                </div>
            </div>
        ),
        [sourceType]
    );

    return (
        <Panel
            isLightDismiss
            type={PanelType.custom}
            customWidth={PANEL_WIDTH}
            isOpen
            onRenderHeader={props.onRenderHeader ?? onRenderHeader}
            onDismiss={props.onDismiss}
            className="configure-connection-panel mapping-panel">
            <Pivot className="pivot" initialSelectedKey={props.initialTab}>
                {props.pivots}
            </Pivot>
        </Panel>
    );
}

export default ConfigureConnectionPanel;