import React from 'react';
import { FormatType } from '../../entities/Metadata';
import { formatValue } from '../utils/common';
import TooltipField from './TooltipField';

type Props = {
    label: string;
    value: string | number | undefined;
    format?: FormatType;
};

const EstimatedTooltipField = (props: Props) => {
    const { label, value, format } = props;
    return (
        <TooltipField label={label}>
            <div>{formatValue(value, format)}</div>
        </TooltipField>
    );
};

export default EstimatedTooltipField;
