import * as React from 'react';
import { ProjectInfo } from '../../store/ProjectsListStore';
import { IRow, } from "../common/timeline/TimelineList";
import { ITimelineSegment, Visibility } from '../common/timeline/TimelineSegment';
import { ITimelineMarker } from '../common/timeline/TimelineMarker';
import { StatusCategoryClassMap, DependencyType, RiskIssueStatus } from '../../entities/Subentities';
import { min, minMax } from '../common/timeline/utils';
import ProjectLogo from './ProjectLogo';
import ProjectTooltipContent from './ProjectTooltipContent';
import IterationTooltipContent from '../common/timeline/IterationTooltipContent';
import { StatusCategory, statusCategoryMap, Dictionary, EntityType, ITimeframe } from '../../entities/common';
import { distinctByKey, toDate } from '../utils/common';
import { ITimelineRelation, TimelineEntityType, TimelineRelationType } from '../common/timeline/TimelineRelation';
import { KeyDateTypeToMarkerTypeMap } from '../keyDate/timeline';
import { Field } from '../../entities/Metadata';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import PrivateProjectIcon from '../common/PrivateProjectIcon';

export const iterationClassName = 'iteration';

export function renderSegmentContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    if (!segment.addContent) {
        return undefined;
    }
    const project = row.entity as ProjectInfo;
    return project.isPrivate
        ? <PrivateProjectIcon solid />
        : <ProjectLogo imageId={project.imageId} />;
}

export function renderSegmentTooltipContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    return <ProjectTooltipContent project={row.entity as ProjectInfo} />;
}
export function renderIterationSegmentTooltipContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    if (segment.className != iterationClassName)
        return undefined;
    let project = row.entity as ProjectInfo;
    let iteration = project.iterations.find(_ => _.id == segment.key);
    return iteration ? <IterationTooltipContent iteration={iteration} /> : undefined;
}

export function getMinimalTimeframe(start?: Date, finish?: Date): ITimeframe {
    return {
        start: start || (finish
            ? finish.clone().addDays(-90)
            : new Date().getBeginOfDay().addDays(-30)),
        end: finish || (start
            ? start.clone().addDays(90)
            : new Date().getBeginOfDay().addDays(60))
    };
}

export function buildTimelineItem(
    project: ProjectInfo,
    projectFields: Field[],
    keyDateFields: Field[],
    datesVisibility?: Visibility,
    displayIterations?: true,
    projectsMap?: Dictionary<ProjectInfo>
): IRow {    
    const startDate = toDate(project.attributes.StartDate);
    const finishDate = toDate(project.attributes.FinishDate);
    const keyDateStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.KeyDate, keyDateFields)!;
    
    const markers = project.keyDates
        .filter(__ => __.attributes.ShowOnTimeline)
        .map<ITimelineMarker>(__ => {
            const statusCategory = keyDateStatusDescriptor.getCategoryOrDefault(__.attributes.Status, StatusCategory.NA);
            return {
                key: __.id,
                date: toDate(__.attributes.Date)?.getBeginOfDay()!,
                className: StatusCategoryClassMap[statusCategory],
                markerType: KeyDateTypeToMarkerTypeMap[__.attributes.Type]
            }
        });

    let iterationSegments: ITimelineSegment[] = [];
    if (displayIterations && project.iterations) {
        iterationSegments = project.iterations
            .filter(_ => _.attributes.StartDate && _.attributes.FinishDate)
            .map<ITimelineSegment>(_ => ({
                key: _.id,
                startDate: toDate(_.attributes.StartDate)?.getBeginOfDay()!,
                finishDate: toDate(_.attributes.FinishDate)?.getEndOfDay()!,
                className: iterationClassName
            }));
    }

    const timeframe = getMinimalTimeframe(startDate, finishDate);
    const allDates = [
        timeframe.start,
        timeframe.end,
        ...markers.map(_ => _.date),
        ...iterationSegments.map(_ => ([_.startDate, _.finishDate])).reduce((a, b) => a.concat(b), [])
    ];
    const { minDate, maxDate } = minMax(allDates);
    let segments: ITimelineSegment[] = [];
    const className = getProjectClassName(project, projectFields);
    if (minDate != startDate || maxDate != finishDate) {
        segments = [...segments, {
            key: 'min-to-max',
            startDate: minDate?.getBeginOfDay()!,
            finishDate: maxDate?.getEndOfDay()!,
            className: `min-to-max ${className}`
        }];
    }

    if (startDate && finishDate) {
        segments = [...segments, {
            key: 'start-to-finish',
            startDate: startDate.getBeginOfDay(),
            finishDate: finishDate.getEndOfDay(),
            className: `start-to-finish ${className}`,
            datesVisibility: datesVisibility
        }];
        const today = new Date();
        if (startDate < today) {
            segments = [...segments, {
                key: 'start-to-progress',
                startDate: startDate.getBeginOfDay(),
                finishDate: min([finishDate, today])?.getEndOfDay()!,
                className: `start-to-progress ${className}`
            }];
        }
    }

    segments[segments.length - 1].addContent = true;

    if (iterationSegments.length) {
        segments = [...segments, ...iterationSegments];
    }

    const relations = buildTimelineRelations(project, segments[0], projectFields, keyDateFields, projectsMap);
    return {
        key: project.id,
        entity: project,
        segments,
        markers,
        relations
    };
}

function buildTimelineRelations(
    project: ProjectInfo,
    projectSegment: ITimelineSegment,
    projectFields: Field[],
    keyDateFields: Field[],
    projectsMap?: Dictionary<ProjectInfo>
): ITimelineRelation[] {
    const relations: ITimelineRelation[] = [];
    if (!projectsMap) {
        return relations;
    }
    const dependencies = project.dependencies?.filter(_ => !!projectsMap[_.attributes.Item.id]
        && (_.attributes.Type === DependencyType.DeliversTo || _.attributes.Type === DependencyType.DependsOn)
        && _.attributes.Status !== RiskIssueStatus.Closed);

    for (const dependency of dependencies) {
        const dependencyTimeline = buildTimelineItem(projectsMap[dependency.attributes.Item.id], projectFields, keyDateFields);
        const dependencyType = dependency.attributes.Type
        if (dependencyTimeline.segments[0]) {
            relations.push({
                key: dependencyType === DependencyType.DeliversTo ? `${project.id}_${dependencyTimeline.key}` : `${dependencyTimeline.key}_${project.id}`,
                isReverse: dependencyType !== DependencyType.DeliversTo,
                type: TimelineRelationType.RightToLeft,
                parent: { ...projectSegment, type: TimelineEntityType.Segment, key: project.id },
                child: { ...dependencyTimeline.segments[0], type: TimelineEntityType.Segment, key: dependencyTimeline.key }
            });
        }
    }

    for (const key in projectsMap) {
        if (key === project.id) {
            continue;
        }

        const projectDependencies = projectsMap[key].dependencies?.filter(_ => _.attributes.Item.id === project.id
            && (_.attributes.Type === DependencyType.DeliversTo || _.attributes.Type === DependencyType.DependsOn)
            && _.attributes.Status !== RiskIssueStatus.Closed);
            
        for (const dependency of projectDependencies) {
            const dependencyTimeline = buildTimelineItem(projectsMap[key], projectFields, keyDateFields);
            const dependencyType = dependency.attributes.Type;
            if (dependencyTimeline.segments[0]) {
                relations.push({
                    key: dependencyType === DependencyType.DependsOn ? `${project.id}_${dependencyTimeline.key}` : `${dependencyTimeline.key}_${project.id}`,
                    isReverse: dependencyType !== DependencyType.DependsOn,
                    type: TimelineRelationType.RightToLeft,
                    parent: { ...projectSegment, type: TimelineEntityType.Segment, key: project.id },
                    child: { ...dependencyTimeline.segments[0], type: TimelineEntityType.Segment, key: dependencyTimeline.key }
                });
            }
        }
    }

    return distinctByKey(relations, "key");
}

function getProjectClassName(project: ProjectInfo, projectFields: Field[]): string {
    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Project, projectFields)!;
    const statusOption = statusDescriptor.getOptionOrDefault(project.attributes.OverallStatus, StatusCategory.NA);
    const status = statusCategoryMap[statusOption.category];
    return `project ${status ? status.cssClassName : ''}`;
}
