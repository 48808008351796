import * as React from "react";
import PATList from "./PATList";
import ApiInfo from "./ApiInfo";
import Zapier from "./Zapier";
import { isAdminViewer, UserState } from "../../../store/User";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import SettingsPage, { Section } from "../SettingsPage";
import { Link } from "office-ui-fabric-react";
import { PPMFeatures, Subscription } from "../../../store/Tenant";
import { PublicApiType } from "./PATCreationPanel";
import { notUndefined } from "../../utils/common";

const apiSection: Section = {
    key: "api",
    name: "Public API",
    icon: "AzureAPIManagement",
    onRenderBody: () => <ApiInfo />,
    tooltip: <div>
        Please refer to these <Link href="https://help.ppm.express/public-api" target="_blank">articles</Link> for more details.
    </div>
};
const getPatSection = (types: PublicApiType[]): Section => ({
    key: "pat",
    name: "Personal access tokens",
    icon: "AzureKeyVault",
    onRenderBody: () => <PATList availableApiTypes={types} />
});
const zapierSection: Section = {
    key: "zapier",
    name: "Zapier",
    icon: "AzureKeyVault",
    onRenderBody: () => <Zapier />
};

type Props = { user: UserState, subscription: Subscription };
const Automation = (props: Props) => <SettingsPage title="Automation" sections={getSections(props)} />;

const getSections = (props: Props) => {
    const availableApiTypes = getApiTypes(props);
    const sections: Section[] = [];

    if (availableApiTypes.includes(PublicApiType.general)) {
        sections.push(apiSection);
    }
    if (availableApiTypes.length) {
        sections.push(getPatSection(availableApiTypes));
    }
    if (availableApiTypes.includes(PublicApiType.general)) {
        sections.push(zapierSection);
    }
    return sections;
}

const getApiTypes = (props: Props): PublicApiType[] => [
    Subscription.contains(props.subscription, PPMFeatures.API) && !isAdminViewer(props.user)
        ? PublicApiType.general
        : undefined,
    Subscription.contains(props.subscription, PPMFeatures.IntegrationAPI)
        ? PublicApiType.integration
        : undefined]
    .filter(notUndefined);
function mapStateToProps(state: ApplicationState): Props {
    return {
        user: state.user,
        subscription: state.tenant.subscription
    };
}

export default connect(mapStateToProps)(Automation);