import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IButtonProps, DefaultButton } from 'office-ui-fabric-react';

type OwnProps = IButtonProps
type Props = OwnProps & RouteComponentProps<{}>;

class BackButton extends React.Component<Props>  {
    public render() {
        return <DefaultButton text="Back" iconProps={{ iconName: "Back" }}  {...this.props} onClick={this._onClick} />
    }


    private _onClick = (e: any) => {
        const { onClick } = this.props;
        onClick?.(e);
        this.props.history.goBack();
    }
}

export default withRouter<OwnProps>(BackButton);