import './ImportGrid.css';
import * as React from 'react';
import { Overlay } from 'office-ui-fabric-react';
import { ImportStatus, IBaseEntityImportState } from "../../../store/integration/ImportStore";
import Spinner from "../../common/Spinner";
import { Column, RowsChangeData, SelectColumn, SortColumn, valueFormatter } from 'react-data-grid';
import DataGrid from '../../common/DataGrid';
import { SortService } from '../../../services/SortService';
import { SortDirection } from '../../../entities/Metadata';
import { SelectAllFormatter, SelectFormatter } from './SelectFormatter';

type Props<T> = {
    isLoading: boolean;
    isProcessing?: boolean;
    columns: Column<T>[];
    maps: T[];
    selectedIds: string[];
    onMapsChanged: (maps: any[]) => void;
    onSelectionChanged: (selectedIndexes: string[], selectedMaps: IBaseEntityImportState[]) => void;
}

const rowKeyGetter = (row: IBaseEntityImportState) => row.externalId;
const SelectRowColumn: Column<any> = {
    ...SelectColumn,
    formatter: SelectFormatter,
    headerRenderer: SelectAllFormatter,
    minWidth: 60,
    cellClass: 'select-cell',
    headerCellClass: 'select-cell'
}

const ImportGrid = <T extends IBaseEntityImportState>(props: Props<T>) => {
    const columns = React.useMemo(
        () => [SelectRowColumn].concat(props.columns).map<Column<T>>(_ => ({
            ..._,
            formatter: props => <div className={`cell-content ${!ImportStatus.isLinked(props.row.status) ? 'selected-cell-content' : ''}`}>{(_.formatter ?? valueFormatter)(props)}</div>
        })),
        [props.columns]);
    const onSortChange = (sortColumns: SortColumn[]) => {
        const sortColumn = sortColumns.length
            ? sortColumns[0]
            : ({ columnKey: sortBy[0].columnKey, direction: sortBy[0].direction === "ASC" ? "DESC" : "ASC" }) as SortColumn;

        const comparer = SortService.getSimpleComparer({
            direction: sortColumn.direction === 'ASC' ? SortDirection.ASC : SortDirection.DESC,
            fieldName: sortColumn.columnKey,
        });
        const orderedMaps = props.maps.sort(comparer);
        props.onMapsChanged(orderedMaps);
        setSortBy([sortColumn]);
    }
    const [sortBy, setSortBy] = React.useState([{ columnKey: props.columns[0].key, direction: 'ASC' } as SortColumn]);

    const selectedRows = React.useMemo(() => {
        const linkedItems = props.maps.filter(_ => ImportStatus.isLinked(_.status)).map(r => r.externalId);
        return new Set(props.selectedIds.concat(linkedItems))
    }, [props.selectedIds]);
    const onRowsSelected = (set: Set<string>) => {
        const rows = props.maps.filter(_ => set.has(_.externalId));
        const selectedIds = rows.filter(_ => !ImportStatus.isLinked(_.status)).map(r => r.externalId);
        props.onSelectionChanged(selectedIds, rows);
    }

    const onRowsChange = (rows: T[], data: RowsChangeData<T>) => props.onMapsChanged(rows);

    return <>
        <DataGrid<T>
            className='import-grid'
            rowHeight={56}
            headerRowHeight={48}
            rowKeyGetter={rowKeyGetter}
            columns={columns}
            rows={props.maps}
            onRowsChange={onRowsChange}
            selectedRows={selectedRows}
            onSelectedRowsChange={onRowsSelected}
            sortColumns={sortBy}
            onSortColumnsChange={onSortChange}
            renderers={{ noRowsFallback: props.isLoading ? <Overlay><Spinner /></Overlay> : null }}
        />
        {
            props.isProcessing && <Overlay><Spinner /></Overlay>
        }
    </>;
}

export default ImportGrid;