import { IFilter, BaseFilterValue, IFilterHelper, FilterAttribute, Field, IActiveFilter, getLabel, Group } from "../../entities/Metadata";
import { InputsService } from "../../components/common/DisplayFieldService";
import { SourceType } from "../ExternalEpmConnectStore";
import { Option, OptionsPickerProps } from "../../components/common/inputs/OptionsPicker";
import { IRoadmapItem, IRoadmapItemAttrs } from "../../entities/Subentities";
import { nameof, namesof } from "../services/metadataService";
import { ActiveFilter, FilterHelper as SubentityFilterHelper } from '../subentities/filters';

export type FilterHelperProps = {
    entities: IRoadmapItem[];
    fields: Field[];
    lanes: Group[];
    labels: Group[];
}

export class FilterHelper extends SubentityFilterHelper {
    private readonly _extraProps: FilterHelperProps;
    constructor(props: FilterHelperProps) {
        super(props);
        this._extraProps = props;
    }

    public getFilterAttributes = (fields: Field[], nonFilterableFields?: string[] | undefined): FilterAttribute<BaseFilterValue>[] => {
        let attrs = fields
            .filter(_ => (!nonFilterableFields || !~nonFilterableFields.indexOf(_.name))
                && !(!_.isCustom && _.name === nameof<IRoadmapItemAttrs>("Lane"))
                && !(!_.isCustom && _.name === nameof<IRoadmapItemAttrs>("Label")))
            .map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<BaseFilterValue>);

        attrs.push({ type: "lane", name: "lane", displayName: "Lane", value: undefined });
        attrs.push({ type: "label", name: "label", displayName: "Label", value: undefined });

        return attrs;
    }

    private lane: IFilterHelper<BaseFilterValue, IRoadmapItem> = {
        buildFilterElement: (
            attr: FilterAttribute<BaseFilterValue>,
            filter: IFilter<BaseFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const typeOptions: Option[] = this._extraProps.lanes.map(_ => ({ key: _.name, text: _.name }));
            const selectedItems: Option[] = [];

            filter.value?.lane?.forEach((id: string) => {
                let opt = typeOptions.find(_ => _.key === id);
                if (opt !== undefined) {
                    selectedItems.push(opt);
                }
            });

            const itemsProps: Partial<OptionsPickerProps> = {
                onChange: (value?: Option[]) => {
                    onFilterEditComplete(attr.type,
                        "lane",
                        value && value.map(v => v.key));
                },
                onResolveSuggestions: (filter: string, selectedItems?: Option[]) => {
                    let res: Option[] =
                        typeOptions.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                    if (selectedItems) {
                        res = res.filter((opt: Option) => selectedItems.find(_ => _.key === opt.key) === undefined);
                    }
                    return Promise.resolve(res);
                },
                selectedItems: selectedItems
            }

            return InputsService.buildOptionsPicker(itemsProps);
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => [],
        setAttributeValue: (attrName: string, value: any, oldValue: any) => value,
        getAttributeValues: (value: any): string[] => value as string[] || [],
        validateItem: (item: IRoadmapItem, filterValue: any): boolean => {
            if (!filterValue || (filterValue as string[]).length === 0) {
                return true;
            }
            return !!~(filterValue as string[]).indexOf(item.attributes.Lane.name);
        }
    }
    private label: IFilterHelper<BaseFilterValue, IRoadmapItem> = {
        buildFilterElement: (
            attr: FilterAttribute<BaseFilterValue>,
            filter: IFilter<BaseFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const typeOptions: Option[] = this._extraProps.labels.map(_ => ({ key: _.name, text: _.name }));
            const selectedItems: Option[] = [];

            filter.value?.label?.forEach((id: string) => {
                let opt = typeOptions.find(_ => _.key === id);
                if (opt !== undefined) {
                    selectedItems.push(opt);
                }
            });

            const itemsProps: Partial<OptionsPickerProps> = {
                onChange: (value?: Option[]) => {
                    onFilterEditComplete(attr.type,
                        "label",
                        value && value.map(v => v.key));
                },
                onResolveSuggestions: (filter: string, selectedItems?: Option[]) => {
                    let res: Option[] =
                        typeOptions.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                    if (selectedItems) {
                        res = res.filter((opt: Option) => selectedItems.find(_ => _.key === opt.key) === undefined);
                    }
                    return Promise.resolve(res);
                },
                selectedItems: selectedItems
            }

            return InputsService.buildOptionsPicker(itemsProps);
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => [],
        setAttributeValue: (attrName: string, value: any, oldValue: any) => value,
        getAttributeValues: (value: any): string[] => value as string[] || [],
        validateItem: (item: IRoadmapItem, filterValue: any): boolean => {
            if (!filterValue || (filterValue as string[]).length === 0) {
                return true;
            }
            return !!item.attributes.Label && !!~(filterValue as string[]).indexOf(item.attributes.Label.name);
        }
    }

    public newFilter = (name: string, sourceType?: SourceType): IActiveFilter =>
        new ActiveFilter(name, sourceType).withAttributes(namesof<IRoadmapItemAttrs>(["Name", "Type", "Tags"]));
    public helpersMap: { [K in keyof BaseFilterValue]: IFilterHelper<BaseFilterValue, IRoadmapItem> } =
        {
            ...this.baseHelpersMap,
            "lane": this.lane,
            "label": this.label,
        }
}