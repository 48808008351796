import * as React from "react";
import { IconButton } from "office-ui-fabric-react";
import { connect } from "react-redux";
import P4WConnectionSelect from "./P4WConnectionSelect";
import P4WMappingPanel from "./P4WMappingPanel/P4WMappingPanel";
import { ISourceInfo, Dictionary } from "../../../entities/common";
import { ApplicationState } from "../../../store/index";
import { actionCreators, IP4WConnectionState, IP4WProject } from "../../../store/integration/P4WStore";
import { IListStore, validateConnection, loadDataAfterConnectionValidation, ILinkDto } from "../../../store/integration/common";
import ConnectControl from '../ConnectControl';
import { Integrations } from "../../../store/Tenant";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import SwitchPrimaryScheduleConfirmation from "../SwitchPrimaryScheduleConfirmation";

type OwnProps = {
    readonly: boolean;
    allowNewItems: boolean;
    actions: Dictionary<any>;
    dismissPanel: () => void;
    sourceInfo?: ISourceInfo;
    allowMultipleConnectionsToOneProject?: boolean;
    disableConfigureMapping?: boolean;
    showSwitchPrimaryDialog?: boolean;
    setAsPrimary?: boolean;
}

type StateProps = { 
    projects: IListStore<IP4WProject>;
    integrations: Integrations;
    connections: IP4WConnectionState
};
type Props = StateProps & typeof actionCreators & OwnProps;

type State = {
    isMapping: boolean;
    selectedConnectionId?: string;
    showSwitchPrimaryScheduleConfirm: boolean;
    linkData?: ILinkDto<IP4WProject>
}

class P4WConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isMapping: false,
            showSwitchPrimaryScheduleConfirm: false
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        loadDataAfterConnectionValidation(
            this.props.connections,
            nextProps.connections,
            this.state.selectedConnectionId,
            this.props.loadProjects
        );
    }

    public render() {
        const { sourceInfo, dismissPanel, readonly, allowNewItems, disableConfigureMapping, allowMultipleConnectionsToOneProject, actions } = this.props;
        const { showSwitchPrimaryScheduleConfirm } = this.state;

        return <>
            <ConnectControl<IP4WProject>
                itemTypeLabel="project"
                readonly={readonly}
                isConnectionValid={(connectionId) => validateConnection(this.props.connections, connectionId)}
                allowNewItems={allowNewItems}
                allowMultipleConnectionsToOneItem={allowMultipleConnectionsToOneProject}
                items={this.props.projects}
                actions={{
                    linkToItem: (linkDto: ILinkDto<IP4WProject>) => {
                        if (this.props.showSwitchPrimaryDialog) {
                            this.setState({ linkData: linkDto, showSwitchPrimaryScheduleConfirm: true });
                            return;
                        }
                
                        this.props.actions.linkToP4WProject({ ...linkDto, setAsPrimary: !!this.props.setAsPrimary });
                        this.props.dismissPanel();
                    },
                    deleteLink: this.props.actions.deleteLink
                }}
                dismissPanel={dismissPanel}
                notCloseAfterLink={this.props.showSwitchPrimaryDialog}
                sourceInfo={sourceInfo}
                itemId={sourceInfo && sourceInfo.sourceData && sourceInfo.sourceData.projectId}
                itemName={sourceInfo && sourceInfo.sourceData.projectName}
                renderConnectControl={(connectionId, disabled, onChange) => <P4WConnectionSelect connectionId={connectionId} disabled={disabled}
                    onChange={_ => {
                        onChange(_);
                        this.setState({ selectedConnectionId: _ });
                    }} />}
                renderExistingConnectedItemOptions={!disableConfigureMapping ? this._renderConnectOptions : undefined}
            />
            {showSwitchPrimaryScheduleConfirm && <SwitchPrimaryScheduleConfirmation
                from={SourceType.Ppmx} to={SourceType.P4W}
                onDismiss={() => this.setState({ showSwitchPrimaryScheduleConfirm: false, linkData: undefined })}
                onClick={(isPrimary) => {
                    actions.linkToP4WProject({ ...this.state.linkData, setAsPrimary: isPrimary });
                    this.props.dismissPanel();
                }} />
            }
        </>;
    }

    private _renderConnectOptions = (connectionId: string, projectId: string) => {
        return <>
            <IconButton
                disabled={!projectId || this.props.integrations.isDisabled(SourceType.P4W)}
                title="Configure mapping"
                iconProps={{ iconName: "PPMXMapping" }}
                onClick={() => this.setState({ isMapping: true })}
                className="mapping-button" />
            {this.state.isMapping && <P4WMappingPanel 
                connectionId={connectionId}
                itemProjectId={projectId}
                onDismiss={() => this.setState({ isMapping: false })} 
            />}
        </>;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { 
        projects: state.p4w.projects,
        integrations: new Integrations(state.tenant.subscription.integrations),
        connections: state.p4w.connections
    };
}

export default connect(mapStateToProps, actionCreators)(P4WConnectControl);