import { Checkbox } from "office-ui-fabric-react";
import React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from "../../../../store";
import { UserState, actionCreators } from "../../../../store/User";
import './Header.css';

type StateProps = {
    user: UserState;
};
type Props = StateProps & typeof actionCreators;

const Header = (props: Props) => {
    const { user, updateViewPreferences } = props;
    return <div className="quick-start-header">
        <h1>Quick start</h1>
        <Checkbox
            label="Don’t show this page on startup"
            checked={user.preferences.dontShowQuickStartOnStartup}
            onChange={(e, checked) => updateViewPreferences({ dontShowQuickStartOnStartup: checked })}
        />
    </div>
}

function mapStateToProps(state: ApplicationState): StateProps {
    return { user: state.user };
}
export default connect(mapStateToProps, actionCreators)(Header);