import React from "react";
import { GroupedFieldsConfigurationProps } from "./GroupedFieldsConfiguration";
import ConfigureColumnGroupedFieldsPanel from "./ConfigureColumnGroupedFieldsPanel";

type Props = Omit<GroupedFieldsConfigurationProps, "selected" | "onChange"> & {
    selected: string[];
    onChange?: (fields: string[]) => void;
};

const ConfigureFieldsPanel = (props: Props) => (
    <ConfigureColumnGroupedFieldsPanel
        {...props}
        flattenFieldsInThisViewGroups
        selected={{ column1: props.selected, column2: [] }}
        onChange={props.onChange ? _ => props.onChange!(_.column1) : undefined}
    />
);

export default ConfigureFieldsPanel;
