import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/integration/P4WStore";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { IConnectionsState } from "../../../store/integration/common";
import P4WConnectionEdit from "../../integration/P4W/P4WConnectionEdit/P4WConnectionEdit";
import BasicAuthConnectionCard from './BasicAuthConnectionCard';

type OwnProps = {}
type StateProps = { connections: IConnectionsState };
type Props = StateProps & OwnProps & typeof actionCreators;
const P4WConnectionCard = (props: Props) => <BasicAuthConnectionCard
    system={SourceType.P4W}
    connections={props.connections.data}
    renderConnect={(onCreated, onDismiss) => <P4WConnectionEdit onDismiss={onDismiss}
        onCreated={(connectionId: string) => {
            props.loadConnections();
            onCreated(connectionId);
        }} />}
/>;
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.p4w.connections }
}
export default connect(mapStateToProps, actionCreators)(P4WConnectionCard);