import * as React from "react";
import * as analytics from '../../../analytics';
import { connect } from "react-redux";
import { IPlanInfo, actionCreators, IPlannerConnectionState, ConnectionType } from "../../../store/integration/Office365Store";
import { IImportProjectMap } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import { useDataLoadingAfterConnectionValidationEffect, IImportEntity, IImportEntityMap, getValidationError } from "./common";
import { InfoMessages, toDictionaryById } from "../../utils/common";
import { ProjectsImportProps } from "./ProjectsImportPanel";
import { UserState } from "../../../store/User";
import { Office365Utils } from "../../integration/Office365/Office365Utils";

type OwnProps = {
    connectionId: string;
    onRender: (props: ProjectsImportProps) => JSX.Element;
}

type StateProps = {
    user: UserState;
    plans: IPlanInfo[];
    isLoading: boolean;
    error: string | null;
    isImporting: boolean;
    connections: IPlannerConnectionState;
}

type Props = OwnProps & StateProps & typeof actionCreators;

const PlannerProjectImport = (props: Props) => {
    const { connectionId } = props;
    const entities = React.useMemo(() => props.plans.map<IImportEntity>(_ => ({
        id: _.id,
        name: _.title,
        fullName: `${_.title} (${_.id})`,
        isAlreadyLinked: _.isAlreadyLinked
    })), [props.plans]);
    const map = React.useMemo(() => toDictionaryById(props.plans), [props.plans]);
    const _onImport = React.useCallback((connectionId: string, maps: IImportEntityMap[]) => {
        props.importProjects(connectionId, maps.map<IImportProjectMap<IPlanInfo>>(_ => ({
            linkingData: map[_.entityId],
            projectId: _.projectId,
            projectName: _.projectName
        })));
        analytics.trackImport('Import Plans from Planner', props.user, { count: maps.length });
    }, [map]);
    
    const connectionValidation = Office365Utils.GetConnectionType(props.connections, connectionId) === ConnectionType.AzureADSync
        ? { isValidating: false, validationError: InfoMessages.importPlansByAzureAdConnection }
        : useDataLoadingAfterConnectionValidationEffect(
            connectionId, 
            props.connections, 
            props.isImporting, 
            props.verifyConnection,
            props.loadPlans
        );

    return props.onRender({
        label: "Plans",
        connectionId: props.connectionId,
        isLoading: connectionValidation?.isValidating || props.isLoading,
        error: getValidationError(connectionValidation) ?? props.error,
        onImport: _onImport,
        oldImportGridProps: {
            entities: entities,
            getSourcesInfo: __ => __.sourceInfos.map(_ => ({
                sourceId: _.sourceData.planId,
                connectionId: _.connectionId,
                data: _
            }))
        }
    });
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        plans: state.office365.plans.entities,
        isLoading: state.office365.plans.isLoading,
        error: state.office365.plans.error,
        isImporting: state.import.projects.isImporting,
        connections: state.office365.connections
    }
}

export default connect(mapStateToProps, actionCreators)(PlannerProjectImport);