import * as React from 'react';
import './SearchableFieldsPanel.css';
import { PrimaryButton, PanelType, SearchBox, Overlay } from 'office-ui-fabric-react';
import FieldPanel, { IFieldActions } from '../../FieldPanel';
import Spinner from '../../../common/Spinner';
import ExpandablePanel from '../../../common/ExpandablePanel';
import { GroupedFieldsConfigurationProps } from '../GroupedFieldsConfiguration';

type Props = Omit<GroupedFieldsConfigurationProps, 'fields' | 'selected' | 'mandatoryFields' | 'flattenSelectedFieldGroups' | 'flattenAvailableFieldGroups'> & {
    children: (filter: string) => React.ReactNode;
};

const SearchableFieldsPanel = (props: Props) => {
    const [showFieldPanel, setShowFieldPanel] = React.useState(false);
    const onAddFieldClick = React.useCallback(() => setShowFieldPanel(true), []);
    const hideFieldPanel = React.useCallback(() => setShowFieldPanel(false), []);

    const [filter, setFilter] = React.useState('');
    const onFilterChange = React.useCallback((e: any, value?: string) => setFilter((value || '')), []);
    const clearFilter = React.useCallback(() => setFilter(''), []);

    const onDismiss = React.useCallback(() => !showFieldPanel && props.onDismiss(), [showFieldPanel]);

    const onRenderHeader = React.useCallback((): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">{props.headerText || 'Configure Columns'}</p>
            <div className='ms-Panel-secondaryText'>{props.secondaryText || 'Select the columns to be displayed in the view. Drag and drop to reorder.'}</div>
            <SearchBox
                autoFocus
                value={filter}
                placeholder='Search'
                onChange={onFilterChange}
                onClear={clearFilter}
                onEscape={clearFilter}
            />
        </div>;
    }, [props.headerText, props.secondaryText, filter]);

    const onRenderFooter = React.useCallback(() => !props.hideNewFieldButton && props.allowManageFields !== undefined
        ? (
            <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                text="New Field"
                className="new-button"
                disabled={props.showSpinner || !props.allowManageFields}
                onClick={onAddFieldClick} />
        ) : null,
        [props.hideNewFieldButton, props.allowManageFields, props.showSpinner]);

    const fieldActions: IFieldActions = React.useMemo(() => ({
        ...props.fieldActions,
        saveField: props.fieldActions
            ? _ => {
                clearFilter();
                props.fieldActions!.saveField?.(_);
            }
            : undefined
    }), [props.fieldActions]);

    return <>
        <ExpandablePanel
            className='searchable-fields-panel'
            isOpen
            isLightDismiss={!props.showSpinner}
            type={PanelType.custom}
            customWidth="600px"
            onRenderHeader={onRenderHeader}
            onRenderFooterContent={onRenderFooter}
            focusTrapZoneProps={{ disabled: true }}
            onDismiss={onDismiss}
        >
            {props.children(filter)}
            {props.showSpinner && <Overlay><Spinner /></Overlay>}
        </ExpandablePanel>
        {
            showFieldPanel && props.fieldActions && <FieldPanel
                allowManageFields={props.allowManageFields}
                actions={fieldActions}
                onDismiss={hideFieldPanel}
                entityType={props.entityType}
            />
        }
    </>
}

export default SearchableFieldsPanel;
