import * as React from "react";
import { connect } from "react-redux";
import { actionCreators, TenantState } from "../../../store/Tenant";
import { ApplicationState } from "../../../store";
import DemoDataDeployDialog from "./DemoDataDeployDialog";
import DemoDataConfirmationDialog from "./DemoDataConfirmationDialog";
import { UserState } from "../../../store/User";
import { CommonOperations, contains } from "../../../store/permissions";
import LinkBox from "../LinkBox";

type OwnProps = {}
type StateProps = {
    tenant: TenantState;
    user: UserState;
}
type Props = OwnProps & StateProps & typeof actionCreators

const DemoData = (props: Props) => {
    const [showDeployDialog, setDeployDialog] = React.useState<boolean>();
    const onDismissDeployDialog = React.useCallback(() => setDeployDialog(undefined), []);
    const [showConfirmation, setShowConfirmation] = React.useState<boolean>();
    const onDismissConfirmation = React.useCallback(() => setShowConfirmation(undefined), []);
    if (!contains(props.user.permissions.common, CommonOperations.Administrate)) {
        return null;
    }
    if (props.tenant.isProcessing) {
        return null;
    }
    if (props.tenant.isDemoDataApplied) {
        return showConfirmation ? <DemoDataConfirmationDialog onDismiss={onDismissConfirmation} /> : null;
    }
    return <><LinkBox text="Use demonstration data"
        iconName="PPMXQuickStartDemoData"
        action={() => setDeployDialog(true)} />
        {showDeployDialog && <DemoDataDeployDialog
            onConfirm={() => {
                props.applyDemoData();
                setShowConfirmation(true);
            }}
            onDismiss={onDismissDeployDialog} />}
    </>;
}
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        tenant: state.tenant,
        user: state.user
    };
}
export default connect(mapStateToProps, actionCreators)(DemoData);