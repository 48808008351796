import * as React from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType } from "office-ui-fabric-react";

type Props = {onDismiss: () => void, onEnable: () => void}

export default class DragWarningDialog extends React.Component<Props> {
    render() {
        return (
            <Dialog className="reset-dialog"
                maxWidth={500}
                hidden={false}
                onDismiss={this.props.onDismiss}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Drag-and-drop feature is not available for the sorted list.',
                    subText: 'Do you want to reset sorting to default to enable drag-and-drop?'
                }}
                modalProps={{
                    isBlocking: false,
                    containerClassName: 'ms-dialogMainOverride'
                }}>
                <DialogFooter>
                    <PrimaryButton text={"Yes"} onClick={this.props.onEnable} />
                    <DefaultButton text={"No"} onClick={this.props.onDismiss} />
                </DialogFooter>
            </Dialog>
        );
    }
}