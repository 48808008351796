import { getOffset, IScale } from "./TimelineScale";
import * as React from 'react';
import { ICanvasInnerComponentProps } from "./TimelineBody";
import { IBaseTimelineElement } from "./TimelineSegment";

export interface ITimelineBaseline extends IBaseTimelineElement {
    startDate?: Date;
    finishDate: Date;
    isMilestone?: boolean;
}

export const TimelineBaseline = (props: ICanvasInnerComponentProps<ITimelineBaseline>) => {
    const baseline = React.useMemo(() => calculateBaseline(props.scale, props.data, props.timelineWidth), [props.scale, props.data, props.timelineWidth]);
    if (baseline.isOutOfScale) {
        return null;
    }
    const className = `timeline segment baseline ${props.data.className || ''}`
        + `${baseline.trimmedStart ? 'trim-left' : ''} ${baseline.trimmedEnd ? 'trim-right' : ''}`;
    return <div className={className} style={{ ...props.data.style, left: baseline.left, width: baseline.width }}></div>;
}

const calculateBaseline = (scale: IScale, baseline: ITimelineBaseline, timelineWidth: number) => {
    const markerBaselineWidth = 18;
    if (baseline.isMilestone) {
        const date = getOffset(scale, baseline.finishDate, timelineWidth);
        return {
            left: date.left,
            width: markerBaselineWidth,
            isOutOfScale: date.isOutOfScale
        };
    }
    const start = getOffset(scale, baseline.startDate!, timelineWidth);
    const end = getOffset(scale, baseline.finishDate, timelineWidth);
    return {
        left: start.left,
        width: end.left - start.left,
        trimmedStart: start.isOutOfScale,
        trimmedEnd: end.isOutOfScale,
        isOutOfScale: start.isOutOfScale && end.isOutOfScale && start.left === end.left
    };
}