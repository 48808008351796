import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Link } from 'office-ui-fabric-react';
import { EntityType, IWithTagsAttrs } from '../../entities/common';
import { ActiveFilter as ProjectActiveFilter } from '../../store/project/filters';
import { ActiveFilter as ProgramActiveFilter } from '../../store/program/filters';
import { ActiveFilter as PortfolioActiveFilter } from '../../store/portfolio/filters';
import { ActiveFilter as ResourceActiveFilter } from '../../store/resource/filters';
import { ActiveFilter as ChallengeActiveFilter } from '../../store/challenge/filters';
import { ActiveFilter as SubentityActiveFilter, LocationState } from '../../store/subentities/filters';
import { ActiveFilter as ObjectiveActiveFilter } from '../../store/objective/filters';
import { ActiveFilter as RoadmapActiveFilter } from '../../store/roadmap/filters';
import { nameof } from '../../store/services/metadataService';
import * as H from 'history';

interface ITagLinkProps {
    className?: string;
    value: string;
    withoutNavigation?: boolean;
    entityType?: EntityType;
}

class TagLink extends React.Component<ITagLinkProps & RouteComponentProps<{}>> {
    public render() {
        const { withoutNavigation, entityType, value } = this.props;
        return !withoutNavigation && entityType && this._isSupportNavigation(entityType) ?
            <Link
                className="label link"
                onMouseDown={(e) => {
                    //prevent row select on list view
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    //prevent row select on timeline view
                    e.preventDefault();
                    e.stopPropagation();
                    this._navigate(entityType, value);
                }}>
                {value}
            </Link>
            : <span className="label">{value}</span>;
    }

    private _isSupportNavigation = (entityType: EntityType) => {
        return Object.keys(navigationMap).indexOf(entityType) > -1;
    }

    private _navigate = (entityType: EntityType, value: string) => {
        navigationMap[entityType]?.(this.props.history, value, this.props.location);
    }
}

type NavigationMap = { [entityType: string]: (history: H.History, value: string, location: H.Location) => void };

function navigationMapBuilder() {
    const map: NavigationMap = {
        [EntityType.Project]: (history, value) => {
            history.push(`/projects`, new ProjectActiveFilter("Custom").withTag(value).buildLocationState());
        },
        [EntityType.Program]: (history, value) => {
            history.push(`/programs`, new ProgramActiveFilter("Custom").withTag(value).build());
        },
        [EntityType.Portfolio]: (history, value) => {
            history.push(`/portfolios`, new PortfolioActiveFilter("Custom").withTag(value).build());
        },
        [EntityType.Resource]: (history, value) => {
            history.push(`/resources`, new ResourceActiveFilter("Custom").withTag(value).build());
        },
        [EntityType.Challenge]: (history, value) => {
            history.push(`/challenges`, new ChallengeActiveFilter("Custom").withTag(value).build());
        },
        [EntityType.Objective]: (history, value) => {
            history.push(`/objectives`, new ObjectiveActiveFilter("Custom").withTag(value).build());
        },
        [EntityType.Roadmap]: (history, value) => {
            history.push(`/roadmaps`, new RoadmapActiveFilter("Custom").withTag(value).build());
        }
    }

    const withTagFilter = [EntityType.Task, EntityType.KeyDate, EntityType.KeyDecision, EntityType.ActionItem,
        EntityType.Risk, EntityType.Iteration, EntityType.ChangeRequest, EntityType.Issue,
        EntityType.LessonLearned, EntityType.SteeringCommittee, EntityType.Dependency,
        EntityType.RoadmapItem, EntityType.Idea, EntityType.PurchaseOrder, EntityType.Invoice, EntityType.Deliverable
    ];

    withTagFilter.forEach(entityType => {
        map[entityType] = (history, value, location) => {
            const filterState: LocationState =
            {
                filters: {
                    [entityType]: {
                        filter: new SubentityActiveFilter("Custom")
                            .withValues([{
                                name: nameof<IWithTagsAttrs>("Tags"),
                                value: [value]
                            }])
                            .build()
                    }
                }
            };
            history.push({
                ...location,
                state: {
                    ...location.state,
                    ...filterState
                }
            });
        }
    })

    return map;
}

const navigationMap = navigationMapBuilder();

export default withRouter<ITagLinkProps>(TagLink);