import * as React from 'react';
import { connect } from 'react-redux';
import { MessageBar, MessageBarType, PrimaryButton } from 'office-ui-fabric-react';
import { ApplicationState } from "../../../../store/index";
import { actionCreators, ConnectionType, IPlannerConnectionState } from "../../../../store/integration/Office365Store";
import { validateConnection } from "../../../../store/integration/common";
import Office365ConnectionSelect from '../../Office365/Office365ConnectionSelect';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import PromptConfirmationDialog from '../../../common/PromptConfirmationDialog';
import { InfoMessages } from '../../../utils/common';
import { Office365Utils } from '../Office365Utils';

type OwnProps = {
    actions: {
        link: (connectionId: string) => void;
    };
    dismissPanel?: () => void;
}

type StateProps = {
    connections: IPlannerConnectionState;
}
type Props = OwnProps & StateProps & typeof actionCreators;

const O365OrganizationConnectControl = (props: Props) => {
    const [selectedConnectionId, setSelectedConnectionId] = React.useState<string>();
    const [showConfirmation, setShowConfirmation] = React.useState<boolean>();
    const toggleShowConfirmation = React.useCallback(() => setShowConfirmation(!showConfirmation), [showConfirmation]);

    const linkToOrganization = React.useCallback(() => {
        if (!selectedConnectionId) {
            return;
        }
        props.actions.link(selectedConnectionId);
        props.dismissPanel?.();
    }, [selectedConnectionId, props.actions]);

    const _validateConnection = function (connections: IPlannerConnectionState, connectionId: string | undefined) {
        const connectionType = Office365Utils.GetConnectionType(props.connections, connectionId);
        if (connectionType === ConnectionType.RestrictedPlannerSync) {
            return {
                isValidating: undefined,
                validationError:
                    <MessageBar messageBarType={MessageBarType.warning}>
                        {InfoMessages.OrganizationBasicConnectionPermissions}
                    </MessageBar>
            };
        }
        return validateConnection(connections, connectionId);
    }

    const isValid = _validateConnection(props.connections, selectedConnectionId);
    return <>
        <div className="with-top-margin">
            <Office365ConnectionSelect
                sourceType={SourceType.O365User}
                connectionId={selectedConnectionId}
                onChange={setSelectedConnectionId}
                hideRestricted={true}
                validateConnection={(connections, cId) => _validateConnection(connections, cId)}
            />
        </div>
        <div className="with-top-margin">
            <PrimaryButton text="Link"
                disabled={!selectedConnectionId || isValid?.isValidating !== false || !!isValid?.validationError}
                className="with-top-margin"
                onClick={toggleShowConfirmation} />
        </div>
        {showConfirmation && <PromptConfirmationDialog
            title="Link Organization"
            description="Are you sure you want to link your PPM Express tenant to selected Office 365 organization as default authentication option?"
            warning="Please note that this action is irreversible, and it will not be possible to unlink organization once the change is made."
            passphrase="CONFIRM"
            onConfirm={linkToOrganization}
            onDismiss={toggleShowConfirmation} />}
    </>
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        connections: state.office365.connections
    };
}

export default connect(mapStateToProps, actionCreators)(O365OrganizationConnectControl);