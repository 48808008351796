import { DefaultButton, Icon } from "office-ui-fabric-react";
import * as React from "react";
import { SourceType, SourceTypeMap, SourceType_ } from "../../../store/ExternalEpmConnectStore";

const MPPFileConnectionCard = () => {
    return <>
        <div className="connection-card">
            <div className="connection-body">
                <Icon className={`connection-logo }`}
                    title={undefined}
                    iconName={SourceType_.getIconName(SourceType.MPPFile)} />
                <div className="connection-details">
                    <div className="connection-name">{SourceTypeMap[SourceType.MPPFile]}</div>
                </div>
            </div>
            <DefaultButton
                text="How to connect"
                title="Help"
                className="connection-action connection-help-link hyperlink-color"
                styles={{
                    root: { padding: "0 4px", textAlign: "left" },
                    rootHovered: { backgroundColor: "unset" },
                    rootPressed: { backgroundColor: "unset" }
                }}
                iconProps={{ iconName: "PPMXHelp" }}
                onClick={() => { window.open('https://help.ppm.express/ppm-express-project-publisher') }}
            />
        </div>
    </>
}

export default MPPFileConnectionCard;