import React from 'react';
import Logo from "../../common/Logo";
import { ServerEntityType, entityLogoConfig, mapServerEntityType } from '../../../entities/common';
import { KeyDateType } from '../../../entities/Subentities';
import { EntityLinkDescriptor } from '../../../store/RoadmapsListStore';

export type ButtonOptions = {
    entityType: ServerEntityType;
    keyDateType?: KeyDateType;
    label?: string;
    cssClass?: string;
    canCreate?: () => boolean;
    renderParamsControl?: (props: ParamsControlProps) => JSX.Element;
}

export type ParamsControlProps = {
    exceptIds?: string[];
    onComplete: (descriptor?: Partial<EntityLinkDescriptor>) => void;
}

export const EntityTypeButton = (props: {
    options: ButtonOptions,
    selected?: boolean,
    disabled?: boolean,
    onClick?: () => void
}) => {
    const { options } = props;
    const entityLogoCfg = entityLogoConfig[mapServerEntityType[options.entityType]!];
    const canCreate = props.options.canCreate?.() ?? true;
    const disabled = props.disabled || !canCreate;
    const label = options.label ?? entityLogoCfg?.label;

    return <div 
        className={`entity-type-button ${disabled ? 'disabled' : 'clickable'} ${props.selected ? 'selected' : ''}`}
        title={canCreate ? undefined : `You do not have permissions to create ${label}.`}
        onClick={disabled ? undefined : props.onClick}>
        <div className="label">
            <Logo className={options.cssClass ?? entityLogoCfg?.cssClass} />
            <div className="text">{label}</div>
        </div>
    </div>;
}
