import React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { TimeTrackingAdministrativeCategory } from '../../../..//store/Tenant';
import TimeTrackingAdministrativeCategoryEntityName from '../../TimeTrackingAdministrativeCategoryEntityName';
import { renderTooltipField } from '../cell/TimeTrackingCellTooltip';

type OwnProps = {
    categoryId: string;
}

type StateProps = {
    category?: TimeTrackingAdministrativeCategory;
}

type Props = OwnProps & StateProps;

function TimeTrackingAdminCategoryTooltip(props: Props) {

    const { category, categoryId } = props;

    return (
        <div className='timeline-tooltip time-tracking-tooltip'>
            <div className="header">
                <TimeTrackingAdministrativeCategoryEntityName
                    categoryId={categoryId} />
                
                {
                    category && !category.isEnabled
                    ? <>(Inactive)</>
                    : <></>
                }
            </div>
            <div className="content">
                {renderTooltipField("Description", category?.description || "")}
            </div>
        </div>
    );
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps { 
    return {
        category: state.tenant.timeTracking.administrativeCategories.find(_ => _.id === ownProps.categoryId),
    };
}

export default connect(mapStateToProps)(TimeTrackingAdminCategoryTooltip);