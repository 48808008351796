import * as React from 'react';
import { Resource, filterUsage } from "../../../store/ResourcesListStore";
import { DefaultButton, PanelType, PrimaryButton } from 'office-ui-fabric-react';
import LabellableComponent from '../../common/LabellableComponent';
import { entityLogoConfig, EntityType, IExtensibleEntity, IWithResourcePlan } from '../../../entities/common';
import { FormatDate, toDate } from '../../utils/common';
import { IMinMax, minMax } from '../../common/timeline/utils';
import ResourceFormatter from '../../common/formatters/ResourceFormatter';
import ExpandablePanel from '../../common/ExpandablePanel';

type Props = {
    entityType: EntityType;
    title: string;
    description: string;
    rows: { resource: Resource, entity: IExtensibleEntity & IWithResourcePlan }[];
    onConfirm: () => void;
    onDismiss: () => void;
    confirmButtonIsDisabled?: boolean;
    confirmButtonText?: string;
    confirmButtonIconName?: string;
}

export const AllocationInfoPanel = (props: React.PropsWithChildren<Props>) => {
    const map = React.useMemo(() => (props.rows.reduce((cum, cur) => ({
        projects: { ...cum.projects, [cur.entity.id]: cur.entity },
        resources: { ...cum.resources, [cur.resource.id]: cur.resource }
    }), {
        projects: {},
        resources: {}
    })), [props.rows]);
    const projectRange: IMinMax | undefined = React.useMemo(() => (Object.keys(map.projects).length === 1
        ? {
            minDate: toDate(props.rows[0].entity?.attributes.StartDate),
            maxDate: toDate(props.rows[0].entity?.attributes.FinishDate)
        } : undefined), [map.projects, props.rows]);

    const allocationRange = React.useMemo(() => (minMax(props.rows
        .reduce((cum, cur) => [...cum, ...filterUsage(cur.resource.usage, cur.entity.id, _ => _.plannedHours !== 0).map(_ => _.date)], []))),
        [props.rows]);

    const onRenderHeader = React.useCallback(() => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">{props.title}</p>
            <div className='ms-Panel-secondaryText'>{props.description}</div>
        </div>;
    }, [props.title, props.description]);

    const onRenderFooterContent = React.useCallback(() => {
        return <div className="commands">
            <PrimaryButton
                text={props.confirmButtonText}
                iconProps={{ iconName: props.confirmButtonIconName }}
                disabled={props.confirmButtonIsDisabled}
                onClick={props.onConfirm} />
            <DefaultButton
                text="Cancel"
                onClick={props.onDismiss} />
        </div>;
    }, [props.onDismiss, props.onConfirm, props.confirmButtonText, props.confirmButtonIsDisabled, props.confirmButtonIconName]);

    return <ExpandablePanel
        isOpen
        isLightDismiss
        type={PanelType.custom}
        customWidth="400px"
        focusTrapZoneProps={{ disableFirstFocus: true }}
        onRenderHeader={onRenderHeader}
        onRenderFooterContent={onRenderFooterContent}
        onDismiss={props.onDismiss}>
        <div className="panel-area">
            <div className="grid-item">
                <LabellableComponent label="Resource" className="field-container">
                    <ResourceFormatter
                        resource={Object.keys(map.resources).map(_ => ({ id: _, fullName: map.resources[_].name, imageId: map.resources[_].imageId }))}
                        withNavigation={false} />
                </LabellableComponent>
            </div>
            {!!projectRange && <div className="grid-item">
                <LabellableComponent label={`${entityLogoConfig[props.entityType]?.label} Dates`} className="field-container">
                    {renderRange(projectRange)}
                </LabellableComponent>
            </div>}
            <div className="grid-item">
                <LabellableComponent label="Current Allocation Range" className="field-container">
                    {renderRange(allocationRange)}
                </LabellableComponent>
            </div>
            {props.children}
        </div>
    </ExpandablePanel>;

    function renderRange(range: IMinMax) {
        return <span>{!range.minDate && !range.maxDate
            ? 'N/A'
            : (`${FormatDate(range.minDate) ?? 'N/A'} - ${FormatDate(range.maxDate) ?? 'N/A'}`)}</span>;
    }
}