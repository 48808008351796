import * as React from "react";
import { IHistoryRow, IStatusHistoryRow, IWithCategory, StatusCategory, statusCategoryMap } from "../../../../../entities/common";
import { TooltipHost, TooltipDelay, Icon, DefaultButton, PersonaCoin, PersonaSize, IconButton, ActionButton } from "office-ui-fabric-react";
import { FormatDateTime, getPersonInfoImageUrl, sortByDateTime } from "../../../../utils/common";
import RemoveDialog from "../../../RemoveDialog";
import CategoryColorStatusView from "../../../../views/list/columns/CategoryColorStatusView";
import { CategoryStatusOption } from "../../../../../entities/StatusDescriptor";

type StatusHistoryValue = IWithCategory<StatusCategory> & {
    value: string;
};

export type Props = {
    label: string;
    changeHistory: IHistoryRow[];
    deleteHistory?: (rowId: string | undefined) => void;
    loadHistory: () => void;
}

const displayRowsIncrement = 10;
const defaultDisplayRows = 10;

const StatusHistory = (props: Props) => {
    const { deleteHistory, loadHistory } = props;
    const [displayRows, setDisplayRows] = React.useState(defaultDisplayRows);
    const [removeRowId, setRemoveRowId] = React.useState<string | undefined>();
    const [removeHistory, setRemoveHistory] = React.useState(false);
    const [sortedHistory, setSortedHistory] = React.useState<IHistoryRow[]>([]);

    React.useEffect(() => loadHistory(), []);
    React.useEffect(() => {
        const sorted = [...props.changeHistory].sort((a, b) => sortByDateTime(a.date, b.date, false));
        setSortedHistory(sorted);
    }, [props.changeHistory]);
    
    const renderHistoryRow = (row: IStatusHistoryRow) => {
        const statusOption = buildCategoryStatusOptionFromRow(row);
        return (
            <div key={row.date} className="history-row">
                <div className="main-info">
                    <div className="title">{FormatDateTime(row.date)}</div>
                    <div className="sub">by {row.setBy?.fullName ?? "PPM Express"}</div>
                </div>
                {
                    deleteHistory && (sortedHistory[0].id === row.id
                        ? <IconButton
                            menuIconProps={{ iconName: 'Delete' }}
                            disabled={true}
                            title="The last status entry cannot be deleted."
                            className='btn-delete' />
                        : <IconButton
                            menuIconProps={{ iconName: 'Delete' }}
                            title="Remove history record"
                            className='btn-delete'
                            onClick={() => setRemoveRowId(row.id)} />)
                }
                <div className="tooltip-wrapper">
                    <TooltipHost delay={TooltipDelay.zero} tooltipProps={{
                        onRenderContent: () => renderHistoryTooltipContent(row, props.label),
                        maxWidth: "454px"
                    }}>
                        <Icon iconName="Info" className='info' />
                    </TooltipHost>
                </div>
                <CategoryColorStatusView statusOption={statusOption} trend={row.trend} />
            </div>
        );
    }

    const renderHistoryLoader = (): JSX.Element => {
        const count = props.changeHistory.length >= displayRows + displayRowsIncrement
            ? displayRowsIncrement
            : props.changeHistory.length - displayRows;

        return <DefaultButton
            iconProps={{ iconName: "sortdown" }}
            text={`Load ${count} More Record${count > 1 ? 's' : ''}`}
            onClick={() => setDisplayRows(displayRows + displayRowsIncrement)}
        />;
    }

    return <>
        <div className="history-header align-center">
            <h2><Icon iconName="History" className="card-icon" /> History</h2>
            {deleteHistory && sortedHistory.length > 1 && <ActionButton text="Delete History" onClick={() => setRemoveHistory(true)} />}
        </div>
        <div className="change-history">
            {!sortedHistory.length && <span>Status history is empty</span>}
            {sortedHistory.slice(0, displayRows).map(renderHistoryRow)}
            {
                displayRows < sortedHistory.length &&
                <div className="align-center">
                    {renderHistoryLoader()}
                </div>
            }
        </div>
        { deleteHistory && (removeRowId || removeHistory) && <RemoveDialog
            confirmButtonProps={{ text: "Delete" }}
            onClose={() => {
                setRemoveRowId(undefined);
                setRemoveHistory(false);
            }}
            onComplete={() => deleteHistory(removeRowId)}
            dialogContentProps={{
                title: "Delete status history",
                subText: removeHistory
                    ? `Are you sure you want to delete the status history records? Please note that the last status entry will not be deleted`
                    : `Are you sure you want to delete the status history record?`
            }} />}
    </>;
}

export default StatusHistory;

export function renderHistoryTooltipContent(row: IStatusHistoryRow, fieldLabel: string): JSX.Element {
    const statusOption = buildCategoryStatusOptionFromRow(row);
    return <div className="timeline-tooltip">
        <div className="header">
            <Icon className="logo info-logo" iconName="Info2" />
            <div className="title"><div className="overflow-text">{fieldLabel}</div></div>
        </div>

        <div className="content">
            <div className="item align-center">
                <div className="label">Status</div>
                <div>
                    <CategoryColorStatusView statusOption={statusOption} trend={row.trend} />
                </div>
            </div>
            <div className="item align-center">
                <div className="label">Date</div>
                <div>{FormatDateTime(row.date, true)}</div>
            </div>
            <div className="item align-center">
                <div className="label">Set by</div>
                <div className="align-center">
                    {row.setBy && <PersonaCoin size={PersonaSize.size24} text={row.setBy.fullName} imageUrl={getPersonInfoImageUrl(row.setBy)} />}
                    <span className="overflow-text">{row.setBy?.fullName ?? 'PPM Express'}</span>
                </div>
            </div>
            {row.description && <div className="item align-center">
                <div className="label">Description</div>
                <div>{row.description}</div>
            </div>}
        </div>
    </div>
}

function buildCategoryStatusOptionFromRow(row: IStatusHistoryRow): CategoryStatusOption {
    const statusHistoryRowValue = row.value as StatusHistoryValue;
    return {
        name: statusHistoryRowValue.value,
        category: statusHistoryRowValue.category,
        color: statusCategoryMap[statusHistoryRowValue.category].color,
    };
}
