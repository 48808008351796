import * as React from "react";
import './UsersForListPicker.css';
import { IPersonaProps, Icon } from "office-ui-fabric-react";
import { UserStatus } from "../../../../store/User";
import { LicenseType } from "../../../../store/permissions";
import { post } from "../../../../fetch-interceptor";
import { getPersonInfoImageUrl } from "../../../utils/common";
import { Validator } from "../../../../validation";
import PPMXPeoplePicker from "../PPMXPeoplePicker";
import { IUser } from "../../../../store/UsersListStore";

const validators = {
    email: Validator.new().required().email().build()
}

type UsersPickerProps = {
    excludeIds?: string[],
    excludeEmails?: string[],
    alreadyAddedIds?: string[],
    alreadyAddedText?: string,
    onSelected: (item: IUser) => void,
    canInvite: boolean,
}

type UsersState = {
    foundInactiveUserEmail?: string,
    foundUnlicensedUserEmail?: string,
    showKeepTyping?: boolean
}

const UsersForListPicker = (props: UsersPickerProps) => {

    const [state, setState] = React.useState<UsersState>({});

    const _onFilterChanged = (filterText: string, currentPersonas?: IPersonaProps[], limitResults?: number): Promise<IPersonaProps[]> => {
        return post<IUser[]>('api/user/find',
            { name: filterText, exclude: props.excludeIds, searchByEmail: true, includeUnlicensed: true })
            .then(data => {
                let suggestions = data.filter(_ => _.status === UserStatus.Active && _.license !== LicenseType.None || _.status === UserStatus.PendingInvite)
                    .map(_getPersona);
                let foundInactiveUserEmail: string | undefined = undefined;
                let foundUnlicensedUserEmail: string | undefined = undefined;
                let showKeepTyping: boolean | undefined = undefined;

                if (!suggestions.length && data.find(_ => _.status === UserStatus.Inactive && _.email === filterText)) {
                    foundInactiveUserEmail = filterText;
                }
                else if (!suggestions.length && data.find(_ => _.status === UserStatus.Active && _.license === LicenseType.None && _.email === filterText)) {
                    foundUnlicensedUserEmail = filterText;
                }
                else if (props.canInvite && !suggestions.length && validators.email.isValid(filterText) && (!props.excludeEmails || !props.excludeEmails.indexOf(filterText.toLowerCase()))) {
                    suggestions = [{
                        initialsColor: '#128B10',
                        onRenderInitials: () => { return <Icon iconName="PPMXPeopleInvite" />; },
                        text: 'Invite ' + filterText,
                        origin: { fullName: filterText, email: filterText.toLowerCase() } as IUser
                    }]
                }
                else if (!suggestions.length && filterText && filterText.indexOf('@') > 0 && !validators.email.isValid(filterText)) {
                    showKeepTyping = true;
                }

                setState({ foundInactiveUserEmail, foundUnlicensedUserEmail, showKeepTyping });

                //split suggestions
                const notAdded: IPersonaProps[] = [];
                const added: IPersonaProps[] = [];
                suggestions
                    .sort((a, b) => (a.origin?.fullName ?? a.origin?.email)?.toLowerCase() > (b.origin?.fullName ?? b.origin?.email)?.toLowerCase() ? 1 : -1)
                    .forEach((x) => (props.alreadyAddedIds?.some(_ => _ === x.origin.id) ? added : notAdded).push(x));
                if (!added.length) {
                    return notAdded;
                }
                const addedSeparator: IPersonaProps = {
                    className: 'suggestions-item-separator',
                    onRenderPersonaCoin: () => <></>,
                    onRenderPrimaryText: () => <small><b>{props.alreadyAddedText}</b></small>,
                };
                added.forEach(_ => _.className = 'already-added');
                const result = [...notAdded, addedSeparator, ...added];
                return result;
            });
    }

    const _getPersona = (info: IUser): IUserProps => {
        return { id: info.id, text: info.fullName, secondaryText: info.email !== info.fullName ? info.email : undefined, imageUrl: getPersonInfoImageUrl(info), origin: info };
    }

    const _onChange = (items?: IUserProps[]): void => {
        items?.length && items[0].origin && !props.alreadyAddedIds?.some(_ => _ === items[0].origin.id) && props.onSelected(items[0].origin);
    }

    return <div className="person-picker">
        {<PPMXPeoplePicker
            inputProps={{ placeholder: 'To share, search by name/email or invite a new user by email' }}
            selectedItems={[]}
            onResolveSuggestions={_onFilterChanged}
            pickerSuggestionsProps={{
                noResultsFoundText: state?.foundInactiveUserEmail
                    ? `User ${state.foundInactiveUserEmail} is inactive`
                    : state?.foundUnlicensedUserEmail
                        ? `User ${state.foundUnlicensedUserEmail} has no license assigned`
                        : state?.showKeepTyping ? "Keep typing a full email" : "No people found. Enter Email to invite"
            }}
            className='ms-PeoplePicker'
            onChange={_onChange} />}
    </div>
}

interface IUserProps extends IPersonaProps { origin: IUser; }

export default UsersForListPicker;