import React from "react";
import LabellableComponent from "../../../common/LabellableComponent";
import { ChoiceGroup, Link, Toggle } from "office-ui-fabric-react";
import { bindActionCreators } from "redux";
import * as TenantStore from '../../../../store/Tenant';
import * as ProjectsListStore from '../../../../store/ProjectsListStore';
import { ApplicationState } from "../../../../store";
import { connect } from "react-redux";
import { progressCalculationTypeOptions } from "../../../project/ProjectSettingsPanel/ProjectCalculationSection";
import { ProgressCalculationType } from "../../../../entities/common";
import { IChoiceGroupOptionExt, onRenderField } from "../../../project/ProjectSettingsPanel/PrimaryScheduleSection";
import './DefaultProjectSettings.css';
import { CommonOperations, contains } from "../../../../store/permissions";

type OwnProps = {};
type StateProps = {
    defaultProjectSettings: TenantStore.DefaultProjectSettings;
    canManage: boolean;
}

type ActionProps = {
    tenantActions: typeof TenantStore.actionCreators;
    projectActions: typeof ProjectsListStore.actionCreators;
}

type Props = OwnProps & StateProps & ActionProps;

const progressCalculationTypeDescriptionsMap = {
    [ProgressCalculationType.Effort]: <>Progress is calculated by the formula: (Completed Work / Effort) * 100%</>,
    [ProgressCalculationType.StoryPoints]: <>Progress is calculated by the formula: (Completed Story Points / Story Points) * 100%</>,
    [ProgressCalculationType.AverageProgress]: <>Progress is calculated as average of Progress, % from “leaf” tasks</>
};

const options: IChoiceGroupOptionExt[] = progressCalculationTypeOptions.map(opt => ({
    ...opt,
    description: progressCalculationTypeDescriptionsMap[opt.value as ProgressCalculationType]
}) as IChoiceGroupOptionExt).map(opt => ({ ...opt, onRenderField }));

const DefaultProjectSettings = (props: Props) => {
    const [state, setState] = React.useState(props.defaultProjectSettings);
    React.useEffect(() => { setState(props.defaultProjectSettings) }, [props.defaultProjectSettings]);
    const { progressCalculationType } = state;

    React.useEffect(() => {
        props.tenantActions.loadDefaultProjectSettings();
        props.projectActions.requestProjects();
    }, []);

    const saveSettings = React.useCallback(settings => {
        setState(settings);
        props.tenantActions.updateDefaultProjectSettings(settings);
    }, []);

    const onChangeProgressCalculationType = React.useCallback(type => {
        saveSettings({ ...state, progressCalculationType: type });
    }, [progressCalculationType]);

    return <div className="sync-settings settings-fields-holder default-project-settings">
        <LabellableComponent
            className="field-container"
            description={<>Define the default Progress Calculation rule that will be pre-selected in the Project Settings in the % Progress Calculation for PPM Express Tasks section when a new project is being created. Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/522610" target="_blank">article</Link> for more details. </>}
            label="Project Progress Calculation">
            <div className="field-container-description">
                Select the default % Progress Calculation rule for PPM Express tasks, task groups and rolled up project progress.
            </div>
            <ChoiceGroup
                selectedKey={progressCalculationType.toString()}
                onChange={(e, option) => onChangeProgressCalculationType(option!.value)}
                options={options} />
        </LabellableComponent>
    </div>
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const projects = state.projectsList.allIds.map(_ => state.projectsList.byId[_]);
    return {
        defaultProjectSettings: state.tenant.defaultProjectSettings!,
        canManage: contains(state.user.permissions.common, CommonOperations.Administrate)
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        tenantActions: bindActionCreators(TenantStore.actionCreators, dispatch),
        projectActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(DefaultProjectSettings);