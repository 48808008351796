import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "office-ui-fabric-react";
import * as analytics from '../../../analytics';
import { CancellablePromiseMap, cancellablePost } from "../../../fetch-interceptor";
import { AIScheduleTasksPreview, AIScheduleDataInput } from "./AIScheduleSteps";
import { Field, Group } from "../../../entities/Metadata";
import { ApplicationState } from "../../../store";
import { UserState } from '../../../store/User';
import * as ProjectsListStore from '../../../store/ProjectsListStore';
import { EntityType, ppmxTaskConnectionId } from "../../../entities/common";
import { AIScheduleDataInputFooter, AIScheduleTasksPreviewFooter } from "./AISchedulePanelFooters";
import { UpdatedSettings, defaultHierarchyLevel } from "./AIScheduleSteps/AIScheduleDataInput";
import { ScheduleTasksCreationData } from "../../../store/tasks";
import './AISchedulePanel.css';
import { AISchedule } from "./AISchedulePanelFooters/AIScheduleTasksPreviewFooter";
import { AiPanelHeader } from "../../common/ai/panelHeader";
import { toDate } from "../../utils/common";
import { AiPanel } from "../../common/ai/panel";

enum AIScheduleSteps {
    DataInput,
    Preview
}

type OwnProps = {
    projectId: string;
    startDate?: string;
    finishDate?: string;
    groups: Group[];
    group?: Group;
    projectDescription?: string;
    onDismiss: () => void;
}

type StateProps = {
    user: UserState;
    fields: Field[];
};

type ActionProps = {
    projectsActions: typeof ProjectsListStore.actionCreators
}

type Props = OwnProps & StateProps & ActionProps;

const AISchedulePanel = (props: Props) => {
    const { projectId, startDate, finishDate, group, groups, user, projectsActions, projectDescription, onDismiss } = props;
    const defaultGroup = group ?? groups.find(_ => _.isDefault)!;
    const defaultStartDate = new Date().toISOString();

    const [selectedStep, setSelectedStep] = React.useState<AIScheduleSteps>(AIScheduleSteps.DataInput);
    const [settingsInfo, setSettingsInfo] = React.useState<UpdatedSettings>({
        settings: {
            context: "",
            hierarchyLevel: defaultHierarchyLevel,
            group: defaultGroup,
            startDate: startDate ?? defaultStartDate,
            finishDate: finishDate
        },
        isValid: false
    });

    const [showHasChangesWarning, setShowHasChangesWarning] = React.useState(false);
    const [cancellableRequests, setCancellableRequests] = React.useState<CancellablePromiseMap<AISchedule>>({});
    const [isScheduleLoading, setIsScheduleLoading] = React.useState<boolean>(false);
    const [scheduleResponse, setScheduleResponse] = React.useState<AISchedule>({tasks: []});
    const [selectedTaskIds, setSelectedTaskIds] = React.useState<string[]>([]);

    const isHierarchyLevelChanged = () => settingsInfo.settings.hierarchyLevel !== defaultHierarchyLevel;
    const isStartDateChanged = () => {
        if (!startDate) {
            return toDate(settingsInfo.settings.startDate)?.getTime() !== toDate(defaultStartDate)!.getTime();
        }
        return toDate(settingsInfo.settings.startDate)?.getTime() !== toDate(startDate)!.getTime();
    };
    const isFinishDateChanged = () => {
        return toDate(settingsInfo.settings.finishDate)?.getTime() !== toDate(finishDate)?.getTime();
    };
    const isGroupChanged = () => settingsInfo.settings.group !== defaultGroup;
    const isContextChanged = () => !!settingsInfo.settings.context;

    const isDataChanged = selectedStep === AIScheduleSteps.DataInput && 
        (isHierarchyLevelChanged() || isStartDateChanged() || isFinishDateChanged() || isGroupChanged() || isContextChanged());

    React.useEffect(() => {
        !isDataChanged && setShowHasChangesWarning(false);
    }, [isDataChanged, setShowHasChangesWarning]);

    const _onDismiss = React.useCallback((ev: React.SyntheticEvent<HTMLElement> | undefined) => {
        if (isDataChanged && ev?.currentTarget?.classList?.contains("ms-Overlay")) {
            setShowHasChangesWarning(true);
            ev.preventDefault();
            return;
        }
        onDismiss();
    }, [isDataChanged, onDismiss, setShowHasChangesWarning]);

    const onGenerateSchedule = () => {
        analytics.trackEvent("Generate Tasks with AI", user);
        setIsScheduleLoading(true);
        setSelectedTaskIds([]);
        const request = cancellablePost<AISchedule>(`/api/ai/project/${projectId}/schedule`, settingsInfo.settings);
        setCancellableRequests({ ...cancellableRequests, ['ai-schedule']: request });
        return request.promise
            .then(response => setScheduleResponse(response))
            .catch(ex  => setScheduleResponse({errorMessage: ex?.response?.data?.errorMessage, tasks: [], prompt: undefined}))
            .finally(() => setIsScheduleLoading(false));
    }

    const onTasksCreate = () => {
        const tasks = scheduleResponse.tasks.filter(_ => selectedTaskIds.includes(_.id))
            .sort((a, b) => a.rank! > b.rank! ? 1 : -1)
            .map<ScheduleTasksCreationData>(_ => ({
                id: _.id,
                name: _.name,
                duration: _.duration,
                parentId: _.parentIds.filter(__ => selectedTaskIds.includes(__)).pop(),
                rank: _.rank
            }));
            
        analytics.trackEvent("Create Tasks with AI", user);
        projectsActions.createSchedule(projectId, ppmxTaskConnectionId, settingsInfo.settings.startDate, settingsInfo.settings.group.id, tasks);
        onDismiss();
    }

    const cancelAllRequests = () => {
        Object.values(cancellableRequests).forEach(request => {
          request?.cancelTokenSource?.cancel();
        });
        setCancellableRequests({});
    };
    
    const onRenderFooter = (): JSX.Element => {
        return <div className="panel-footer">
            {selectedStep === AIScheduleSteps.DataInput 
                && <AIScheduleDataInputFooter
                        isGenerateDisabled={!settingsInfo.isValid}
                        showHasChangesWarning={showHasChangesWarning}
                        onGenerateClick={() => {
                            setSelectedStep(AIScheduleSteps.Preview);
                            onGenerateSchedule();
                        }}
                        onDismiss={onDismiss} />}
            {selectedStep === AIScheduleSteps.Preview
                && <AIScheduleTasksPreviewFooter
                        projectId={projectId}
                        scheduleResponse={scheduleResponse}
                        isScheduleLoading={isScheduleLoading}
                        tasksSelected={!!selectedTaskIds.length}
                        onTasksCreate={onTasksCreate}
                        onCancel={() => {
                            cancelAllRequests();
                            onDismiss();
                        }}
                        onBack={() => {
                            cancellableRequests['ai-schedule']?.cancelTokenSource?.cancel();
                            setSelectedStep(selectedStep - 1);
                        }} />}
        </div>
    }

    return <AiPanel
        className="ai-schedule-panel"
        isExpandable
        isLightDismiss={selectedStep === AIScheduleSteps.DataInput}
        customWidth={selectedStep === AIScheduleSteps.DataInput ? "400px" : undefined}
        onRenderHeader={() => <AiPanelHeader 
            headerText='Generate Tasks' 
            secondaryText={<>Generate project tasks with AI. Please refer to the <Link target="_blank" href="https://help.ppm.express/ppm-insights-ai/2373939">article</Link> for more details.</>} />}
        onRenderFooter={onRenderFooter}
        onDismiss={(ev) => {
            cancelAllRequests();
            _onDismiss(ev);
        }}>
            {selectedStep === AIScheduleSteps.DataInput 
                && <AIScheduleDataInput
                        settings={settingsInfo.settings}
                        onSettingsChange={newSettings => setSettingsInfo(newSettings)}
                        projectDescription={projectDescription}
                        groups={groups} />}
            {selectedStep === AIScheduleSteps.Preview
                && <AIScheduleTasksPreview 
                        isLoading={isScheduleLoading} 
                        errorMessage={scheduleResponse.errorMessage}
                        fields={props.fields}
                        aiTasks={scheduleResponse.tasks}
                        onSelectionChanged={setSelectedTaskIds} />}
    </AiPanel>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[EntityType.Task];
    return { user: state.user, fields: fields.allIds.map(_ => fields.byId[_]) };
}

export default connect(mapStateToProps, (dispatch): ActionProps => ({
    projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch)
}))(AISchedulePanel)