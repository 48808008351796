import * as React from 'react';
import { Overlay, PrimaryButton, Label, Link, DefaultButton, ChoiceGroup, IChoiceGroupOption, TooltipHost, Icon, TooltipDelay } from 'office-ui-fabric-react';
import { IWorkItemType, IProgressCalculationSettings } from "../../../store/integration/VSTSStore";
import OptionsPicker, { Option, OptionsPickerProps } from "../../common/inputs/OptionsPicker";
import Spinner from "../../common/Spinner";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { PPMFeatures, Subscription } from '../../../store/Tenant';
import { nameof } from '../../../store/services/metadataService';
import { ITaskAttrs } from '../../../entities/Subentities';

type OwnProps = {
    connectionId: string;
    projectId: string;
    workItemTypes: IWorkItemType[];
    progressCalculationSettings: IProgressCalculationSettings;
    readOnly: boolean;
    error: string | null,
    isLoading: boolean,
    isProcessing: boolean;
    onDismiss: () => void;
    onSave: (settings: IProgressCalculationSettings) => void,
}
type StateProps = {
    subscription: Subscription;
}
type Props = OwnProps & StateProps;
type State = {
    types: Option[];
    selectedTypes: Option[];
    selectedStatusAutoCalculationTypes: Option[];
    calculateProgressBy?: string;
}

const stubCountFieldName = 'Count';
const optionsCalculateProgressBy: IChoiceGroupOption[] = [
    { text: 'Elements Count', value: stubCountFieldName, key: stubCountFieldName },
    { text: 'Sum of Story points', value: nameof<ITaskAttrs>('StoryPoints'), key: 'StoryPoints' },
    { text: 'Sum of Efforts', value: nameof<ITaskAttrs>('Effort'), key: 'Effort' },
];

class VSTSProgressCalculationSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this._buildState(props);
    }

    componentWillReceiveProps(nextProps: Props) {
        if ((this.props.isLoading && !nextProps.isLoading) || (this.props.isProcessing && !nextProps.isProcessing)) {
            this.setState(this._buildState(nextProps));
        };
    }

    render() {
        const { isLoading, isProcessing, error, readOnly } = this.props;
        if (isLoading) {
            return <Overlay><Spinner /></Overlay>;
        }
        if (error) {
            return <div className="error-message">{error}</div>;
        }

        return (
            <div className="connection-settings">
                <div className="scrollable-content">
                    <div className="item">
                        <Label>
                            Work item types included in Project Progress calculation
                            <TooltipHost
                                tooltipProps={{
                                    onRenderContent: () => <>
                                        By default, project progress is calculated by User Stories for projects created under Agile process
                                        and by Tasks for projects created under Basic, Scrum and CMMI processes.
                                        For progress calculation it is recommended to use work items of the same hierarchy level.<br />
                                        Please refer to the {this._articleLink("selectedTypes")} for more details.
                                    </>
                                }}
                                delay={TooltipDelay.zero} >
                                <Icon iconName="Info" onClick={(e) => e.stopPropagation()} className="message-icon" />
                            </TooltipHost>
                        </Label>
                        <OptionsPicker {...this._getCalculationProps("selectedTypes")} />
                    </div>
                    <div className="item">

                        <ChoiceGroup label='Calculate Project Progress by' className="horizontal"
                            defaultSelectedKey={this.state.calculateProgressBy} options={optionsCalculateProgressBy}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) =>
                                this.setState({ calculateProgressBy: option?.value?.toString() })}
                        />
                    </div>
                    <div className="item">
                        {Subscription.contains(this.props.subscription, PPMFeatures.ML) && <>
                            <Label>
                                Work item types included in Project Status auto calculation
                                <TooltipHost
                                    tooltipProps={{
                                        onRenderContent: () => <>
                                            Project Schedule and Resources Statuses will be calculated based on the work items selected below.<br />
                                            Please refer to the {this._articleLink("selectedStatusAutoCalculationTypes")} for more details.
                                        </>
                                    }}
                                    delay={TooltipDelay.zero} >
                                    <Icon iconName="Info" onClick={(e) => e.stopPropagation()} className="message-icon" />
                                </TooltipHost>
                            </Label>
                            <OptionsPicker {...this._getCalculationProps("selectedStatusAutoCalculationTypes")} />
                        </>}
                    </div>                    
                    {readOnly && <Overlay />}
                </div>
                <div className="commands">
                    {!readOnly && <PrimaryButton text="Save"
                            disabled={!this._isSettingsChanged()}
                            onClick={this._saveMapping} />}
                    <DefaultButton text={readOnly ? "Close" : "Cancel"} onClick={this.props.onDismiss} />
                </div>
                {isProcessing && <Overlay><Spinner /></Overlay>}
            </div>
        );
    }

    private _getCalculationProps = (propName: "selectedTypes" | "selectedStatusAutoCalculationTypes"): Partial<OptionsPickerProps> => (
        {
            selectedItems: this.state[propName],
            onChange: (value?: Option[]) => this.setState({ [propName]: value || [] } as Pick<State, "selectedTypes" | "selectedStatusAutoCalculationTypes">),
            onResolveSuggestions: (filter: string) => {
                const selectedTypes = this.state[propName];
                let res: Option[] =
                    this.state.types.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                if (selectedTypes.length > 0) {
                    res = res.filter((opt: Option) => selectedTypes.find(_ => _.key === opt.key) === undefined);
                }
                return Promise.resolve(res);
            },
        });

    private _articleLink(propName: "selectedTypes" | "selectedStatusAutoCalculationTypes") {
        const href = propName === "selectedTypes"
            ? " https://help.ppm.express/89488-azure-devops-connection/522585"
            : propName === "selectedStatusAutoCalculationTypes"
                ? "https://help.ppm.express/94162-portfolio-and-project-management/563277-autocalculation-logic"
                : undefined;

        return (
            <Link styles={() => ({ root: { paddingLeft: "unset!important" } })}
                href={href}
                target="_blank">
                article
            </Link>
        );
    }

    private _saveMapping = () => {
        const { connectionId, projectId } = this.props;
        const { selectedTypes, selectedStatusAutoCalculationTypes, calculateProgressBy } = this.state;

        if (!connectionId || !projectId) {
            return;
        }

        const workItemTypes = selectedTypes.map(_ => _.key as string);
        const autoStatusCalculationWorkItemTypes = selectedStatusAutoCalculationTypes.map(_ => _.key as string);

        this.props.onSave({
            workItemTypes,
            autoStatusCalculationWorkItemTypes,
            calculateProgressBy: calculateProgressBy === stubCountFieldName ? undefined : calculateProgressBy
        });
    }

    private _buildState(props: Props): State {
        const progressCalculationSettings = props.progressCalculationSettings;

        const types: Option[] = props.workItemTypes.map(_ => ({
            text: _.name,
            key: _.name
        }));

        const selectedTypes = types.filter(_ => progressCalculationSettings.workItemTypes.indexOf(_.key as string) !== -1);
        const selectedStatusAutoCalculationTypes = types.filter(_ => progressCalculationSettings.autoStatusCalculationWorkItemTypes.indexOf(_.key as string) !== -1);

        return { 
            types, 
            selectedTypes, 
            selectedStatusAutoCalculationTypes,
            calculateProgressBy: props.progressCalculationSettings?.calculateProgressBy ?? stubCountFieldName
         };
    }

    private _isSettingsChanged = (): boolean => {
        const propsSettings = this.props.progressCalculationSettings;
        const { types, selectedTypes: newSelectedTypes, selectedStatusAutoCalculationTypes: newSelectedStatusAutoCalculationTypes, 
            calculateProgressBy: newCalculateProgressBy } = this.state;

        if (newCalculateProgressBy !== (propsSettings.calculateProgressBy ?? stubCountFieldName)) {
            return true;
        }

        const oldSelectedTypes = types.filter(_ => propsSettings.workItemTypes.indexOf(_.key as string) !== -1);
        const oldSelectedStatusAutoCalculationTypes = types.filter(_ => propsSettings.autoStatusCalculationWorkItemTypes.indexOf(_.key as string) !== -1);

        const pairs = [
            { old: oldSelectedTypes, new: newSelectedTypes },
            { old: oldSelectedStatusAutoCalculationTypes, new: newSelectedStatusAutoCalculationTypes }
        ];

        for (let i = 0; i < pairs.length; i++) {
            const oldSettings = pairs[i].old;
            const newSettings = pairs[i].new;

            if (newSettings.length !== oldSettings.length) {
                return true;
            }

            for (let j = 0; j < oldSettings.length; j++) {
                const oldSelectedType = oldSettings[j];
                if (newSettings.indexOf(oldSelectedType) === -1) {
                    return true;
                }
            }
        }

        return false;
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription
    };
}

export default connect(mapStateToProps)(VSTSProgressCalculationSettings);