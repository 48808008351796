import * as React from 'react';
import { Dropdown, Icon, IDropdownOption, IRenderFunction, ISelectableOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { LayoutsState } from '../../store/layouts';
import { Layout } from '../../entities/Metadata';
import { notUndefined } from '../utils/common';
import { LayoutService } from '../utils/LayoutService';

const NotSetLayoutId = '';

type Props = {
    readOnly: boolean | undefined,
    layoutsState: LayoutsState,
    onChange: (layout?: Layout) => void,
    selectedKey?: string,
    hideLabel?: boolean,
    entityLayout?: { key: string, title: string, text: string },
    profileLayoutId?: string
};

export const EntityViewSelector = (props: Props) => {
    return <Dropdown
        label={props.hideLabel ? "" : "Default View"}
        selectedKey={props.selectedKey || NotSetLayoutId}
        disabled={props.readOnly}
        dropdownWidth={300}
        title={props.entityLayout
            ? props.profileLayoutId && props.layoutsState.byId[props.profileLayoutId]
                ? `Profile View: ${props.layoutsState.byId[props.profileLayoutId].name}`
                : 'Profile View: N/A'
            : ''}
        options={[
            {
                key: NotSetLayoutId,
                text: 'Not set'
            },
            ...props.entityLayout ? [props.entityLayout, { key: 'divider', text: '', itemType: SelectableOptionMenuItemType.Divider }] : [],
            ...props.layoutsState.allIds.map<IDropdownOption | undefined>(id => (props.layoutsState.byId[id].isView ? {
                key: id,
                data: { isProfileView: props.entityLayout && props.profileLayoutId === id },
                text: props.layoutsState.byId[id].name
            } : undefined)).filter(notUndefined)
        ]}
        onRenderItem={(_props?: ISelectableOption, defaultRender?: IRenderFunction<ISelectableOption>) => {
            if (!_props || !defaultRender) {
                return null;
            }
            return _props?.data?.isProfileView
                ? <div key={_props.key} className="profile-view-box">
                    {defaultRender(_props)}
                    <Icon iconName='View' title='This view is specified for a user in People Management' />
                </div>
                : defaultRender(_props);
        }}
        onChange={(e, _) => props.onChange((_ && _.key) ? _.key === LayoutService.DefaultLayoutId ? { id: _.key } as Layout : props.layoutsState.byId[_.key] : undefined)}
    />;
}