import * as React from 'react';
import { connect } from 'react-redux';
import { ComboBox, IComboBoxOption, Spinner, SpinnerSize, PrimaryButton, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ApplicationState } from "../../store/index";
import * as Office365Store from "../../store/integration/Office365Store";
import { ISourceInfo } from "../../entities/common";
import Office365ConnectionSelect from './Office365/Office365ConnectionSelect';
import OverlayComponent from "../common/OverlayComponent";
import { IListStore, ILinkDto, loadDataAfterConnectionValidation, validateConnection } from '../../store/integration/common';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { ConnectionField } from './ConnectionField';
import { Office365Utils } from './Office365/Office365Utils';
import { InfoMessages } from '../utils/common';

export type TeamsChannelLink = {
    connectionId: string;
    teamId: string;
    teamName: string;
    channelId: string;
    channelName: string;
}

export type TeamsChannelActions = {
    linkToTeamsChannel: (linkData: ILinkDto<TeamsChannelLink>) => void;
    deleteLink: (connectionId?: string) => void;
}

type OwnProps = {
    actions: TeamsChannelActions;
    dismissPanel: () => void;
    sourceInfo?: ISourceInfo;
    readonly: boolean;
    teamsAppId?: string;
}

type StateProps = {
    teams: IListStore<Office365Store.GroupInfo>;
    channels: IListStore<Office365Store.ChannelInfo>;
    tabs: IListStore<Office365Store.ChannelTabInfo>;
    app: Office365Store.TeamsAppInfo;
    connections: Office365Store.IPlannerConnectionState;
}

type Props = OwnProps & StateProps & typeof Office365Store.actionCreators & RouteComponentProps<{ id: string }>;

type State = {
    connectionId?: string;
    teamId?: string;
    teamName?: string;
    channelId?: string;
    channelName?: string;
}

class TeamsChannelConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = props.sourceInfo ? {
            connectionId: props.sourceInfo.connectionId,
            teamId: props.sourceInfo.sourceData.teamId,
            teamName: props.sourceInfo.sourceData.teamName,
            channelId: props.sourceInfo.sourceData.channelId,
            channelName: props.sourceInfo.sourceData.channelName
        } : {};
    }

    componentWillMount() {
        this.props.loadConnections();
    }

    componentWillReceiveProps(nextProps: Props) {
        const { connectionId, teamId } = this.state;
        if (connectionId) {
            loadDataAfterConnectionValidation(
                this.props.connections,
                nextProps.connections,
                connectionId,
                (_) => {
                    this.props.loadTeams(_);
                    if (teamId) {
                        this.props.loadChannels(_, teamId);
                    }
                }

            )
        }
    }

    render() {
        const { sourceInfo, readonly } = this.props;
        const { connectionId } = this.state; 
        const connectionValidation = this._validateConnection(this.props.connections, connectionId);
        const isValid = connectionValidation?.isValidating === false && !connectionValidation?.validationError;
        
        return (
            <div>
                <div className="with-top-margin">
                    <Office365ConnectionSelect
                        sourceType={SourceType.O365TeamsChannel}
                        validateConnection={this._validateConnection}
                        connectionId={connectionId}
                        disabled={readonly || !!(sourceInfo && sourceInfo.connectionId)}
                        onChange={this.onConnectionChange} />
                </div>
                <div className="with-top-margin">
                    <OverlayComponent isOverlayed={!connectionId || connectionValidation?.isValidating || !!connectionValidation?.validationError}>
                        {this._renderGroupSelection(isValid)}
                    </OverlayComponent>
                </div>
            </div>
        );
    }

    private _validateConnection(
        connections: Office365Store.IPlannerConnectionState, 
        connectionId: string | undefined
    ): { isValidating?: boolean; validationError?: string | React.ReactNode | null; } | undefined {
        const connectionType = Office365Utils.GetConnectionType(connections, connectionId);
        if (connectionType === Office365Store.ConnectionType.AzureADSync) {
            return {
                isValidating: undefined,
                validationError:
                    <MessageBar messageBarType={MessageBarType.warning}>
                        {InfoMessages.linkTEamsByAzureAdConnection}
                    </MessageBar>
            };
        }
        return validateConnection(connections, connectionId);
    }
    private _renderGroupSelection(isValid: boolean) {
        const { readonly, sourceInfo, connections } = this.props;
        const { teamId } = this.state;
        const isLinked = !!sourceInfo;
        const teamOptions = this.props.teams.entities.map((_) => ({ key: _.id, text: _.title }));
        const channelOptions = this.state.teamId ? this.props.channels.entities.map((_) => ({ key: _.id, text: _.title })) : [];
        const teamName = teamOptions.find(_ => _.key === teamId)?.text ?? sourceInfo?.sourceData.teamName;
        const channelName = channelOptions.find(_ => _.key === this.state.channelId)?.text ?? sourceInfo?.sourceData.channelName;
        const isRestrictedConnection = Office365Utils.GetConnectionType(connections, this.state.connectionId) === Office365Store.ConnectionType.RestrictedPlannerSync;

        return <div>
            <ConnectionField
                isLinked={isLinked}
                value={teamName}
                label="Team">
                    <ComboBox
                        className="with-top-margin"
                        placeholder='Select team'
                        disabled={readonly || isLinked || !this.state.connectionId || this.props.teams.isLoading}
                        options={teamOptions}
                        allowFreeform
                        useComboBoxAsMenuWidth
                        errorMessage={isValid ? this.getTeamComboboxValidationMessage() : undefined}
                        selectedKey={this.state.teamId}
                        onChange={(e: any, option?: IComboBoxOption, index?: number, value?: string) => {
                            const newTeamId = option?.key as string;
                            const newTeamName = option?.text as string;
                            this.setState({ teamId: newTeamId, teamName: newTeamName, channelId: undefined });
                            if (this.state.connectionId && newTeamId) {
                                this.props.loadChannels(this.state.connectionId, newTeamId);
                            }
                        }}
                    />
            </ConnectionField>
            {this.props.teams.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
            <ConnectionField
                isLinked={isLinked}
                label='Channel'
                value={channelName}>
                    <ComboBox className="with-top-margin"
                        placeholder='Select channel'
                        disabled={readonly || isLinked || !this.state.connectionId || this.props.teams.isLoading || this.props.channels.isLoading || !teamId}
                        allowFreeform
                        useComboBoxAsMenuWidth
                        options={channelOptions}
                        errorMessage={this.getChannelComboboxValidationMessage()}
                        selectedKey={this.state.channelId}
                        onChange={(e: any, option?: IComboBoxOption, index?: number, value?: string) => {
                            const newChannelId = option?.key as string;
                            const newChannelName = option?.text as string;
                            this.setState({ channelId: newChannelId, channelName: newChannelName });
                            if (this.state.connectionId && this.state.teamId && newChannelId && !isRestrictedConnection) {
                                this.props.loadChannelTabs(this.state.connectionId, this.state.teamId, newChannelId);
                            }
                        }}
                    />
            </ConnectionField>
            {this.props.channels.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
            {
                !isLinked && <PrimaryButton text="Link channel"
                    title="Send updates to Teams channel conversation"
                    disabled={readonly || this.state.channelId === undefined}
                    className="with-top-margin"
                    onClick={this.linkToTeamsChannel} />
            }
            {
                isLinked && <PrimaryButton text="Delete link"
                    className="with-top-margin"
                    disabled={readonly || (sourceInfo && sourceInfo.syncStatus.inProgress)}
                    title={!!(sourceInfo && sourceInfo.syncStatus.inProgress) ? "Sync in progress." : "Stop sending updates to Teams channel conversation"}
                    onClick={this.deleteLink} />
            }
            <br/>
            {
                this.props.teamsAppId && <PrimaryButton text='Create tab in Teams'
                className="with-top-margin"
                disabled={!this.state.connectionId
                           || this.props.teams.isLoading
                           || this.props.channels.isLoading
                           || !this.state.channelId
                           || this.props.tabs.isLoading
                           || this.tabExists()
                           || this.props.app.inProgress
                           || isRestrictedConnection}
                onClick={this.addTeamsTab} />
            }
            {(this.props.tabs.isLoading || this.props.app.inProgress) && <Spinner size={SpinnerSize.small} style={{ marginTop: -23, marginLeft: -3, width: 30, position: 'absolute' }} />}
            {this.props.app.status && !this.props.app.inProgress &&
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    truncated={true}
                    overflowButtonAriaLabel="See more">
                {this.props.app.status}
                </MessageBar>
            }
        </div>;
    }

    private onConnectionChange = (connectionId: string | undefined) => {
        if (this.state.connectionId !== connectionId) {
            this.setState({
                connectionId: connectionId,
                teamId: undefined,
                teamName: undefined,
                channelId: undefined,
                channelName: undefined
            });
        }
    }

    private tabExists = () => {
        if (!this.props.tabs.isLoading) {
            return this.props.tabs.entities.find(_ => _.url != null && _.url.toLowerCase().trim().endsWith(this.props.match.params.id)) != null;
        }
        return false;
    }

    private addTeamsTab = () => {
        if (!this.state.connectionId || !this.state.teamId || !this.state.channelId) return;
        const settings: Office365Store.TeamsTabCreationSettings = {
            connectionId: this.state.connectionId,
            contentUrl: window.location.href,
            tabDisplayName: "PPM Express",
            teamId: this.state.teamId,
            channelId: this.state.channelId,
            channelName: this.state.channelName
        };
        this.props.addTeamsTab(settings, () => !this.props.app.status && !this.props.app.inProgress && this.props.dismissPanel());
    }

    private getTeamComboboxValidationMessage = () => {
        if (!this.state.connectionId || this.props.teams.isLoading) {
            return undefined;
        }
        return this.props.teams.error
            ? this.props.teams.error
            : !this.state.teamId
                ? 'Please select a team from the list'
                : undefined;
    }

    private getChannelComboboxValidationMessage = () => {
        if (!this.state.connectionId || this.props.teams.isLoading || !this.state.teamId || this.props.channels.isLoading) {
            return undefined;
        }
        return this.props.channels.error
            ? this.props.channels.error
            : !this.state.channelId
                ? 'Please select a channel from the list'
                : undefined;
    }

    private linkToTeamsChannel = () => {
        const { connectionId, teamId, teamName, channelId, channelName } = this.state;
        if (!connectionId || !teamId || !teamName || !channelId || !channelName) {
            return;
        }
        this.props.actions.linkToTeamsChannel({ connectionId: connectionId, data: { connectionId, teamId, teamName, channelId, channelName } });
        this.props.dismissPanel();
    }

    private deleteLink = () => {
        this.props.actions.deleteLink?.(this.state.connectionId);
        this.props.dismissPanel();
    }
}

function mapStateToProps(state: ApplicationState, ownProps: Props) {
    return {
        teams: state.office365.teams,
        channels: state.office365.channels,
        tabs: state.office365.tabs,
        app: state.office365.teamsApp,
        teamsAppId: state.office365.teamsApp.id,
        connections: state.office365.connections
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps, Office365Store.actionCreators)(TeamsChannelConnectControl));