import * as React from 'react';
import { Autofill, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { IFormInputProps } from '../interfaces/IFormInputProps';
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { EntityType, IRefInfo, ServerEntityType } from '../../../entities/common';
import EntityPicker, { EntityInfo } from './EntityPicker';
import TextInput from './TextInput';

type Props = IFormInputProps<IRefInfo | IRefInfo[], Autofill> &
{ multichoice?: boolean; exceptIds?: string[]; autoExpand?: boolean; hasIdeation?: boolean };

type State = {
    entityType?: EntityType;
    value?: IRefInfo | IRefInfo[];
}

const EXTERNAL_KEY = '_external_' as EntityType;

export default class EntityRefInput extends React.Component<Props, State> implements IFormInputComponent {
    constructor(props: Props) {
        super(props);

        this.state = {
            entityType: this.getEntityType(props.value),
            value: props.value
        };
    }

    public render() {
        const { inputProps, disabled, readOnly, value, hasIdeation } = this.props;
        const { entityType } = this.state;
        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;

        return <div>
            <Dropdown
                options={this.getRefOptions()}
                disabled={isDisabled}
                onKeyDown={e => { if (isDisabled || isReadOnly) { e.preventDefault(); } }}
                selectedKey={this.state.entityType ?? EXTERNAL_KEY}
                onChange={isDisabled || isReadOnly ? undefined : this.onEntityTypeChanged} />
            {
                entityType !== EXTERNAL_KEY
                    ? <EntityPicker {...this.props}
                        onChanged={this.onChanged}
                        inputProps={inputProps}
                        entityViewPath={this._canManage(entityType) ? `/${entityType}` : undefined}
                        filter={{ exceptIds: this.props.exceptIds }}
                        validator={this.props.validator}
                        searchPath={`api/${entityType}/find`}
                        readOnly={!this._canManage(entityType)}
                    />
                    : <TextInput
                        inputProps={inputProps}
                        value={this.getCustomValue(value)}
                        onChanged={this.onCustomChanged}
                        onEditComplete={this.onCustomChanged}
                        validator={this.props.validator}
                        disabled={isDisabled}
                        readOnly={isReadOnly}
                    />
            }
        </div>;
    }

    private _canManage = (entityType?: EntityType) => {
        const { hasIdeation } = this.props;
        return entityType !== EntityType.Idea || hasIdeation;
    }

    focus(): void {
        //
    }

    private getRefOptions(): IDropdownOption[] {
        const refOptions = [
            {
                key: EntityType.Project,
                text: "Project"
            }, {
                key: EXTERNAL_KEY,
                text: "External"
            }
        ];

        if (this.props.hasIdeation !== false || this.state.entityType == EntityType.Idea) {
            refOptions.splice(1, 0, { key: EntityType.Idea, text: "Idea" });
        }

        return refOptions;
    }

    private getEntityType(value?: IRefInfo | IRefInfo[]) {
        return !value || Array.isArray(value) && !value.length
            ? EntityType.Project
            : Array.isArray(value)
                ? this.toEntityType(value[0].entityType) ?? EXTERNAL_KEY
                : this.toEntityType(value.entityType) ?? EXTERNAL_KEY;
    }

    private toEntityType(entityType: any): EntityType | undefined {
        return entityType && typeof entityType === 'number' ? EntityType[ServerEntityType[entityType]] : entityType;
    }

    private onEntityTypeChanged = (e: any, option?: IDropdownOption) => {
        const entityType = option?.key as EntityType;
        this.setState({ entityType })
        this.props.onChanged?.(entityType === this.getEntityType(this.state.value) ? this.state.value : undefined)
    }

    private onChanged = (value?: EntityInfo | EntityInfo[]) => {
        this.props.onChanged?.(value
            ? Array.isArray(value)
                ? value.map(_ => ({ id: _.id, name: _.name, entityType: this.state.entityType }))
                : { id: value.id, name: value.name, entityType: this.state.entityType }
            : value)
    }

    private getCustomValue(value?: IRefInfo | IRefInfo[]) {
        return this.getEntityType(value) === EXTERNAL_KEY
            ? Array.isArray(value)
                ? value[0].name
                : value?.name
            : undefined;
    }

    private onCustomChanged = (value: string) => {
        this.props.onChanged?.(!!(value && value.trim()) ? { name: value } : undefined);
    }
}