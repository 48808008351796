import * as React from 'react';
import * as analytics from '../../analytics';
import { Toggle } from 'office-ui-fabric-react';
import { PPMFeatures, Subscription, TenantState, actionCreators } from '../../store/Tenant';
import * as Notifications from "../../store/NotificationsStore";
import { UserState } from '../../store/User';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

type OwnProps = {
    canManage: boolean;
    tenant: TenantState;
    user: UserState;
    tenantActions: typeof actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
}
type StateProps = {
    subscription: Subscription;
}
type Props = OwnProps & StateProps;

const WarningsSettings = (props: Props) => {
    const { canManage, tenant, tenantActions, notificationsActions, subscription } = props;
    return <>
        {Subscription.contains(subscription, PPMFeatures.PortfolioManagement) && <>
            <div className="status-calculation-toggle align-center">
                <span className="label">Calculate portfolios warnings</span>
                <Toggle className="toggle"
                    disabled={!canManage}
                    checked={!tenant.insights.portfolioWarningsCalculationDisabled}
                    onChange={canManage
                        ? (e, c) => {
                            tenantActions.updateInsights({ ...tenant.insights, portfolioWarningsCalculationDisabled: !c });
                            notificationsActions.pushNotification({
                                message: `Portfolio warnings settings have been changed. 
Please allow some time for settings apply.` });
                            analytics.trackToggle("Calculate portfolios warnings updated", props.user, !!c);
                        }
                        : undefined}
                    onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
            </div>
            <div className="status-calculation-toggle align-center">
                <span className="label">Calculate programs warnings</span>
                <Toggle className="toggle"
                    disabled={!canManage}
                    checked={!tenant.insights.programWarningsCalculationDisabled}
                    onChange={canManage
                        ? (e, c) => {
                            tenantActions.updateInsights({ ...tenant.insights, programWarningsCalculationDisabled: !c });
                            notificationsActions.pushNotification({
                                message: `Program warnings settings have been changed. 
Please allow some time for settings apply.` });
                            analytics.trackToggle("Calculate programs warnings updated", props.user, !!c);
                        }
                        : undefined}
                    onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
            </div>
        </>
        }
        {
            Subscription.contains(subscription, PPMFeatures.ProjectManagement) && <div className="status-calculation-toggle align-center">
                <span className="label">Calculate projects warnings</span>
                <Toggle className="toggle"
                    disabled={!canManage}
                    checked={!tenant.insights.projectWarningsCalculationDisabled}
                    onChange={canManage
                        ? (e, c) => {
                            tenantActions.updateInsights({ ...tenant.insights, projectWarningsCalculationDisabled: !c })
                            notificationsActions.pushNotification({
                                message: `Project warnings settings have been changed. 
Please allow some time for settings apply` });
                            analytics.trackToggle("Calculate projects warnings updated", props.user, !!c);
                        }
                        : undefined}
                    onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
            </div>
        }
    </>;
}

const mapStateToProps = (state: ApplicationState): StateProps => ({ subscription: state.tenant.subscription });
export default connect(mapStateToProps)(WarningsSettings);