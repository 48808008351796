import * as React from "react";
import { Spinner, ComboBox, SpinnerSize, PrimaryButton, IComboBoxOption, ISelectableOption, IconButton } from "office-ui-fabric-react";
import { connect } from "react-redux";
import MondayComConnectionSelect from "./MondayComConnectionSelect";
import { Dictionary, ISourceInfo } from "../../../entities/common";
import OverlayComponent from "../../common/OverlayComponent";
import { ApplicationState } from "../../../store/index";
import {
    actionCreators, IMondayComBaseSourceData, IMondayComBoard, IMondayComConnectionState,
    IMondayComSourceData, IMondayComWorkspace
} from "../../../store/integration/MondayComStore";
import { get } from "../../../fetch-interceptor";
import MondayComConfigureConnectionPanel from "./MondayComConfigureConnectionPanel";
import { catchApiError } from "../../../store/utils";
import { ConnectionField } from "../ConnectionField";
import { validateConnection } from "../../../store/integration/common";
import { usePrevious } from './../../utils/effects';
import SwitchPrimaryScheduleConfirmation from "../SwitchPrimaryScheduleConfirmation";
import { SourceType } from "../../../store/ExternalEpmConnectStore";

export type MondayComConnectActions = {
    deleteLink: (connectionId: string) => void,
    linkToMondayComBoard: (obj: { connectionId: string, data: IMondayComBaseSourceData, setAsPrimary?: boolean }) => void
}

type OwnProps = {
    readonly: boolean;
    actions: MondayComConnectActions;
    allowMultipleConnectionsToOneProject?: boolean;
    disableConfigureConnection?: boolean;
    expandAdditionalFilter?: boolean;
    sourceInfo?: ISourceInfo<IMondayComSourceData>;
    dismissPanel?: () => void;
    showSwitchPrimaryDialog?: boolean;
    setAsPrimary?: boolean;
}

type StateProps = {
    boardsByWorkspaceMap: Dictionary<IMondayComBoard[]>;
    workspaces: IMondayComWorkspace[];
    isLoading: boolean;
    error: string | null;
    connections: IMondayComConnectionState;
};
type Props = OwnProps & StateProps & typeof actionCreators;

const boardsToOptions = (o: Dictionary<IMondayComBoard[]>): Dictionary<ISelectableOption[]> =>
    Object.keys(o).reduce<Dictionary<ISelectableOption[]>>((acc, key) => {
        acc[key] = o[key].map(_ => ({ key: _.boardId, text: _.boardName, disabled: _.isAlreadyLinked }))
            .sort((a, b) => a.text.localeCompare(b.text));
        return acc;
    }, {});

const workspacesToOptions = (o: IMondayComWorkspace[]): ISelectableOption[] =>
    o.map(_ => ({ key: _.id, text: _.name }))
        .sort((a, b) => a.text.localeCompare(b.text));

const onRenderItem = (props?: IComboBoxOption, defaultRender?: (props?: IComboBoxOption) => JSX.Element | null): JSX.Element | null => {
    if (!defaultRender) {
        return null;
    }

    const element = defaultRender(props);
    if (!props || !props.disabled) {
        return element;
    }
    return React.createElement("div", { title: "Already linked" }, element);
}

const linkToProject = (props: Props, connectionId: string, workspaceId: number, boardId: number, subItemsBoardId?: number, setAsPrimary?: boolean) => {
    const { boardsByWorkspaceMap, actions, dismissPanel } = props;
    const itemsBoard = boardsByWorkspaceMap[workspaceId].find(_ => _.boardId === boardId);
    const subItemsBord = subItemsBoardId ? boardsByWorkspaceMap[workspaceId].find(_ => _.boardId === subItemsBoardId) : undefined;

    if (!itemsBoard || itemsBoard.workspaceId !== workspaceId) {
        return;
    }

    actions.linkToMondayComBoard({
        connectionId,
        data: {
            workspaceId: itemsBoard.workspaceId,
            workspaceName: itemsBoard.workspaceName,
            boardId: itemsBoard.boardId,
            boardName: itemsBoard.boardName,
            subItemsBoardId: subItemsBord?.boardId,
            subItemsBoardName: subItemsBord?.boardName,
        },
        setAsPrimary: !!setAsPrimary
    });
    dismissPanel?.();
}

const deleteLink = (props: Props, connectionId: string) => {
    const { actions, dismissPanel } = props;

    actions.deleteLink(connectionId);
    dismissPanel?.();
}

type SubboardInfo = { id?: number, name?: string, inProgress?: boolean, error?: string };

const loadSubBoard = (connectionId: string, boardId: number, setSubItemsBoard: (_: SubboardInfo) => void) => {
    setSubItemsBoard({ id: undefined, inProgress: true, error: undefined });

    get<{ board?: { id: number, name: string } }>('api/integration/mondaycom/subitemsboard', { connectionId, boardId })
        .then(_ => setSubItemsBoard({ id: _.board?.id, name: _.board?.name, inProgress: false, error: undefined }))
        .catch(catchApiError(_ => setSubItemsBoard({
            id: undefined,
            name: undefined,
            inProgress: false,
            error: `Unable to load Monday.com subitems board ${_}`})));
}

const MondayComConnectControl = (props: Props) => {
    const { readonly, boardsByWorkspaceMap, isLoading, error, sourceInfo, allowMultipleConnectionsToOneProject, disableConfigureConnection, 
        connections, showSwitchPrimaryDialog } = props;
    const [connectionId, setConnectionId] = React.useState<string | undefined>(sourceInfo?.connectionId);
    const [boardId, setBoardId] = React.useState<number | undefined>(sourceInfo?.sourceData.boardId);
    const [subItemBoardInfo, setSubItemsBoardInfo] = React.useState<SubboardInfo>({ id: sourceInfo?.sourceData.subItemsBoardId, name: sourceInfo?.sourceData.subItemsBoardName });
    const [workspaceId, setWorkspaceId] = React.useState<number | undefined>(sourceInfo?.sourceData.workspaceId);
    const [isConfigureConnection, setIsConfigureConnection] = React.useState<boolean | undefined>();
    const [showSwitchPrimaryScheduleConfirm, setShowSwitchPrimaryScheduleConfirm] = React.useState<boolean>(false);

    const isSelectedConnectionValid = () => {
        const selectedConnection = validateConnection(connections, connectionId);
        return selectedConnection?.isValidating === false && !selectedConnection.validationError;
    }

    const previousConnectionsVerification = usePrevious(props.connections.connectionsVerification);

    React.useEffect(() => {
        connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false
        && isSelectedConnectionValid() && props.loadBoards(connectionId!)
    }, [connectionId && connections.connectionsVerification[connectionId]]);

    React.useEffect(() => {
        connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false
        && isSelectedConnectionValid() && boardId && loadSubBoard(connectionId!, boardId, setSubItemsBoardInfo)
    }, [boardId, connectionId && connections.connectionsVerification[connectionId]]);

    const boardOptionsMap = React.useMemo(() => boardsToOptions(props.boardsByWorkspaceMap), [props.boardsByWorkspaceMap]);
    const workspaceOptionsMap = React.useMemo(() => workspacesToOptions(props.workspaces), [props.workspaces]);
    const isBoardAlreadyLinked = React.useMemo(() => {
        const board = (workspaceId && boardsByWorkspaceMap.hasOwnProperty(workspaceId) ? boardsByWorkspaceMap[workspaceId] : []).find(_ => _.boardId === boardId)
        return !allowMultipleConnectionsToOneProject && board?.isAlreadyLinked;
    }, [workspaceId, boardId, boardsByWorkspaceMap]);
    const isLinked = !!sourceInfo;

    const onConnectionChange = React.useCallback((_: string | undefined) => {
        if (!isLinked) {
            setConnectionId(_);
            setWorkspaceId(undefined);
            setBoardId(undefined);
        }
    }, []);

    const onWorkspaceChange = React.useCallback((e: React.FormEvent<any>, _?: IComboBoxOption) => {
        setWorkspaceId(_ ? (_.key as number) : undefined)
        setBoardId(undefined);
        setSubItemsBoardInfo({});
    }, []);

    const isBoardSelectionDisabled = readonly || isLinked || !connectionId || isLoading || !workspaceId;
    const boardOptions = workspaceId ? boardOptionsMap[workspaceId] : [];
    const workspaceName = workspaceId ? (workspaceOptionsMap.find(_ => _.key === workspaceId)?.text ?? sourceInfo?.sourceData.workspaceName) : '';
    const boardName = boardId ? (boardOptions?.find(_ => _.key === boardId)?.text ?? sourceInfo?.sourceData.boardName) : '';
    const isValid = validateConnection(props.connections, connectionId);

    return <>
        <div key="select-connection-container" className="with-top-margin">
            <MondayComConnectionSelect
                disabled={readonly || isLinked}
                connectionId={connectionId}
                onChange={onConnectionChange} />
        </div>
        <div key="select-project-container" className="with-top-margin">
            <OverlayComponent isOverlayed={!connectionId || !!subItemBoardInfo.inProgress || isValid?.isValidating !== false || !!isValid?.validationError}>
                <ConnectionField
                    isLinked={isLinked}
                    label="Workspace"
                    value={workspaceName}>
                    <ComboBox
                        disabled={readonly || isLinked || !connectionId || isLoading}
                        allowFreeform
                        useComboBoxAsMenuWidth
                        placeholder="Select workspace"
                        selectedKey={workspaceId}
                        options={workspaceOptionsMap}
                        onChange={onWorkspaceChange}
                        errorMessage={!isLoading && connectionId && isValid?.isValidating === false && !isValid?.validationError
                            ? error
                                ? error
                                : !workspaceId
                                    ? `Please select workspace from list`
                                    : undefined
                            : undefined} />
                </ConnectionField>
                {isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                <div style={{ position: "relative" }}>
                    <ConnectionField
                        isLinked={isLinked}
                        value={boardName}
                        label="Board">
                        <ComboBox
                            disabled={isBoardSelectionDisabled}
                            placeholder="Select board"
                            allowFreeform
                            useComboBoxAsMenuWidth
                            selectedKey={boardId}
                            options={boardOptions}
                            onRenderItem={onRenderItem}
                            onChange={(e, _) => { 
                                const id = _ ? (_.key as number) : undefined;
                                setBoardId(id); 
                                if (id && connectionId) {
                                    loadSubBoard(connectionId, id, setSubItemsBoardInfo);
                                }}}
                            errorMessage={!isBoardSelectionDisabled
                                ? error
                                    ? error
                                    : !boardId
                                        ? `Please select board from list`
                                        : isBoardAlreadyLinked
                                            ? 'Board already linked'
                                            : undefined
                                : undefined} />
                    </ConnectionField>
                    {subItemBoardInfo.error && <div className="error-message">{subItemBoardInfo.error}</div>}
                    {!!subItemBoardInfo.inProgress && <Spinner size={SpinnerSize.small} className="mapping-button" />}
                    {!subItemBoardInfo.inProgress && !disableConfigureConnection && <IconButton
                        disabled={!boardId}
                        title="Configure mapping"
                        iconProps={{ iconName: "PPMXMapping" }}
                        onClick={() => setIsConfigureConnection(true)}
                        className="mapping-button" />}
                </div>
                {isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
            </OverlayComponent>
            {
                !disableConfigureConnection &&
                isConfigureConnection &&
                !!boardId &&
                !!connectionId &&
                <MondayComConfigureConnectionPanel
                    connectionId={connectionId}
                    itemBoardId={boardId}
                    subItemBoardId={subItemBoardInfo.id}
                    onDismiss={() => setIsConfigureConnection(false)} />
            }
            {
                !isLinked && <PrimaryButton text={`Link board`}
                    disabled={readonly || isBoardAlreadyLinked || subItemBoardInfo.inProgress || workspaceId === undefined || boardId === undefined}
                    className="with-top-margin"
                    onClick={() => {
                        if (showSwitchPrimaryDialog) {
                            setShowSwitchPrimaryScheduleConfirm(true);
                            return;
                        }
                        linkToProject(props, connectionId!, workspaceId!, boardId!, subItemBoardInfo.id, props.setAsPrimary);
                    }} />
            }
            {
                isLinked && <PrimaryButton text="Delete board link"
                    className="with-top-margin"
                    disabled={readonly || sourceInfo?.syncStatus.inProgress}
                    title={!!sourceInfo?.syncStatus.inProgress ? "Sync in progress." : undefined}
                    onClick={() => deleteLink(props, connectionId!)} />
            }
            {showSwitchPrimaryScheduleConfirm && <SwitchPrimaryScheduleConfirmation
                from={SourceType.Ppmx} to={SourceType.MondayCom}
                onDismiss={() => setShowSwitchPrimaryScheduleConfirm(false)}
                onClick={(isPrimary) => linkToProject(props, connectionId!, workspaceId!, boardId!, subItemBoardInfo.id, isPrimary)} />
            }
        </div>
    </>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        isLoading: state.mondayCom.boards.isLoading,
        error: state.mondayCom.boards.error,
        boardsByWorkspaceMap: state.mondayCom.boardsByWorkspaceMap,
        workspaces: state.mondayCom.workspaces,
        connections: state.mondayCom.connections
    };
}

export default connect(mapStateToProps, actionCreators)(MondayComConnectControl);