import { get } from './../fetch-interceptor';
import { RouterAction } from "react-router-redux";
import { AppThunkAction } from ".";
import { Reducer, Action } from "redux";
import { Tracker } from "./PreferencesTracker";
import { Dictionary, UserPreferencesSettingsUpdate } from "../entities/common";
import { defaultCatch } from './utils';

export interface SectionsState {
    isLoading: Dictionary<boolean>;
    settings: Dictionary<any>;
}

interface RequestSettingsAction {
    type: 'LOAD_SECTION_SETTINGS';
    entityId: string;
}

interface ReceiveSettingsAction {
    type: 'LOADED_SECTION_SETTINGS';
    entityId: string;
    settings: any;
}

interface SaveSettingsAction {
    type: 'SAVE_SECTION_SETTINGS';
    update: UserPreferencesSettingsUpdate;
}

type KnownAction = RequestSettingsAction
    | ReceiveSettingsAction
    | SaveSettingsAction;

export const actionCreators = {
    loadSettings: (entityId: string): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        get<Dictionary<any>[]>(`api/user/preferences/${entityId}/sectionsSettings`)
            .then(data => dispatch({ type: 'LOADED_SECTION_SETTINGS', entityId, settings: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_SECTION_SETTINGS', entityId });
    },
    saveSettings: (update: UserPreferencesSettingsUpdate, sendToServer: boolean = true): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        dispatch({ type: 'SAVE_SECTION_SETTINGS', update });
        if (sendToServer) {
            Tracker.setSectionSettings(dispatch, getState, update);
        }
    }
};

const INIT_STATE: SectionsState = {
    isLoading: {},
    settings: {}
};

export const reducer: Reducer<SectionsState> = (state: SectionsState = INIT_STATE, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SECTION_SETTINGS':
            {
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        [action.entityId]: true
                    },
                };
            }
        case 'LOADED_SECTION_SETTINGS':
            {
                return {
                    ...state,
                    isLoading: {
                        ...state.isLoading,
                        [action.entityId]: false
                    },
                    settings: {
                        ...state.settings,
                        [action.entityId]: action.settings || {}
                    }
                };
            }
        case 'SAVE_SECTION_SETTINGS': return saveSettings(state, action.update);
        default:
            const exhaustiveCheck: never = action;
    }
    return state;
};

type IWithSettings = {
    settings: any;
}

export function saveSettings<T extends IWithSettings>(state: T, update: UserPreferencesSettingsUpdate) {
    const stateCopy: T = { ...state, settings: { ...state.settings } };
    const parentSettingNode = getEnsuredParentSettingsNodeCopy(stateCopy, update.parentSettingsPathKeys);

    for (const settingName of Object.keys(update.valueBySettingNameMap)) {
        parentSettingNode[settingName] = update.valueBySettingNameMap[settingName];
    }

    return stateCopy;
}

export function getEnsuredParentSettingsNodeCopy<T extends IWithSettings>(state: T, pathKeys: string[]): Dictionary<any> {
    let node = state.settings;
    for (const key of pathKeys) {
        if (!node[key]) {
            node[key] = new Dictionary<any>();
        }
        node[key] = { ...node[key] };
        node = node[key];
    }

    return node;
}
