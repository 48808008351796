import * as React from "react";
import { IDialogContentProps, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { actionCreators, KeyResult, Objective, OKRState } from "../../store/ObjectivesListStore";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import RemoveDialog from "../common/RemoveDialog";
import { IDeletionResult } from "../../store/services/storeHelper";
import OKRSelectionPanel from "./OKRSelectionPanel";
import { EntityType } from "../../entities/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export enum OKRStateChangeAction {
    Reopen,
    Close,
    Archive,
    ActivateToOpen,
    ActivateToClosed
}

export type OKRStateChangeProps = {
    entityId: string;
    objectiveId?: string;
    actionType: OKRStateChangeAction;
}

type ActionProps = {
    objectivesActions: typeof actionCreators;
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        objectivesActions: bindActionCreators(actionCreators, dispatch)
    };
}

const ObjectiveStateDialogsImpl = (props: {
    obectiveStateChangeProps: OKRStateChangeProps,
    onDismiss: () => void
} & ActionProps): JSX.Element => {
    const actionConfirmPropsMap = {
        [OKRStateChangeAction.Close]: {
            dialogContentProps: {
                title: 'Close objective',
                subText: 'Are you sure you want to close selected objective?'
            },
            yesButtonProps: { text: "Close" },
            onYes: () => props.objectivesActions.updateObjectiveState(props.obectiveStateChangeProps.entityId, OKRState.Closed),
            message: 'All open child key results and sub-objectives will also be closed.'
        },
        [OKRStateChangeAction.Reopen]: {
            dialogContentProps: {
                title: 'Reopen objective',
                subText: 'Are you sure you want to reopen objective?'
            },
            yesButtonProps: { text: "Reopen" },
            onYes: () => props.objectivesActions.updateObjectiveState(props.obectiveStateChangeProps.entityId, OKRState.Open),
            message: 'All closed child key results and sub-objectives will also be reopened.'
        },
        [OKRStateChangeAction.Archive]: {
            dialogContentProps: {
                title: 'Archive objective',
                subText: 'Are you sure you want to archive objective?'
            },
            yesButtonProps: { text: "Archive" },
            onYes: () => props.objectivesActions.updateObjectiveState(props.obectiveStateChangeProps.entityId, OKRState.Archived),
            message: 'All child sub-objectives and key results will also be archived.'
        },
        [OKRStateChangeAction.ActivateToOpen]: {
            dialogContentProps: {
                title: 'Activate to open objective',
                subText: 'Are you sure you want to activate objective?'
            },
            yesButtonProps: { text: "Activate to Open" },
            onYes: () => props.objectivesActions.updateObjectiveState(props.obectiveStateChangeProps.entityId, OKRState.Open),
            message: 'All child sub-objectives and key results will also be activated.'
        },
        [OKRStateChangeAction.ActivateToClosed]: {
            dialogContentProps: {
                title: 'Activate to Closed objective',
                subText: 'Are you sure you want to activate objective?'
            },
            yesButtonProps: { text: "Activate to Closed" },
            onYes: () => props.objectivesActions.updateObjectiveState(props.obectiveStateChangeProps.entityId, OKRState.Closed),
            message: 'All child sub-objectives and key results will also be activated.'
        }
    };

    const confirmProps = actionConfirmPropsMap[props.obectiveStateChangeProps.actionType];

    return <ConfirmationDialog
        onDismiss={props.onDismiss}
        yesButtonProps={confirmProps.yesButtonProps}
        onYes={confirmProps.onYes}
        dialogContentProps={confirmProps.dialogContentProps}>
        <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
            {confirmProps.message}
        </MessageBar>
    </ConfirmationDialog>
}
export const ObjectiveStateDialogs = connect(undefined, mergeActionCreators)(ObjectiveStateDialogsImpl);

const KeyResultStateDialogsImpl = (props: {
    krStateChangeProps: OKRStateChangeProps,
    onDismiss: () => void
} & ActionProps): JSX.Element => {
    const actionConfirmPropsMap = {
        [OKRStateChangeAction.Close]: {
            dialogContentProps: {
                title: 'Close key result',
                subText: 'Are you sure you want to close selected key result?'
            },
            yesButtonProps: { text: "Close" },
            onYes: () => props.objectivesActions.updateKeyResultState(props.krStateChangeProps.objectiveId!, props.krStateChangeProps.entityId, OKRState.Closed),
        },
        [OKRStateChangeAction.Reopen]: {
            dialogContentProps: {
                title: 'Reopen key result',
                subText: 'Are you sure you want to reopen key result?'
            },
            yesButtonProps: { text: "Reopen" },
            onYes: () => props.objectivesActions.updateKeyResultState(props.krStateChangeProps.objectiveId!, props.krStateChangeProps.entityId, OKRState.Open),
            message: 'All child sub-objectives and key results will also be reopened.'
        },
        [OKRStateChangeAction.Archive]: {
            dialogContentProps: {
                title: 'Archive key result',
                subText: 'Are you sure you want to archive key result?'
            },
            yesButtonProps: { text: "Archive" },
            onYes: () => props.objectivesActions.updateKeyResultState(props.krStateChangeProps.objectiveId!, props.krStateChangeProps.entityId, OKRState.Archived),
            message: 'All child sub-objectives and key results will also be archived.'
        },
        [OKRStateChangeAction.ActivateToOpen]: {
            dialogContentProps: {
                title: 'Activate to open key result',
                subText: 'Are you sure you want to activate key result?'
            },
            yesButtonProps: { text: "Activate to Open" },
            onYes: () => props.objectivesActions.updateKeyResultState(props.krStateChangeProps.objectiveId!, props.krStateChangeProps.entityId, OKRState.Open),
            message: 'All child sub-objectives and key results will also be activated.'
        },
        [OKRStateChangeAction.ActivateToClosed]: {
            dialogContentProps: {
                title: 'Activate to closed key result',
                subText: 'Are you sure you want to activate key result?'
            },
            yesButtonProps: { text: "Activate to Closed" },
            onYes: () => props.objectivesActions.updateKeyResultState(props.krStateChangeProps.objectiveId!, props.krStateChangeProps.entityId, OKRState.Closed),
            message: 'All child sub-objectives and key results will also be activated.'
        }
    };

    const confirmProps = actionConfirmPropsMap[props.krStateChangeProps.actionType];

    return <ConfirmationDialog
        onDismiss={props.onDismiss}
        yesButtonProps={confirmProps.yesButtonProps}
        onYes={confirmProps.onYes}
        dialogContentProps={confirmProps.dialogContentProps} />
}
export const KeyResultStateDialogs = connect(undefined, mergeActionCreators)(KeyResultStateDialogsImpl);

const ObjectivesToRemoveRemoveDialogImpl = (props: {
    objectivesActions: typeof actionCreators;
    objectivesToRemove: Objective[];
    onClose: () => void;
    redirectBack?: boolean;
} & ActionProps): JSX.Element => <RemoveDialog
    onClose={props.onClose}
    onComplete={() => {
        props.objectivesActions.removeObjectives(props.objectivesToRemove.filter(_ => _.isEditable).map(_ => _.id), props.redirectBack);
    }}
        dialogContentProps={{
            title: `Delete objective${props.objectivesToRemove.length == 1 ? '' : 's'}`,
            subText: props.objectivesToRemove.length == 1
                ? `Are you sure you want to delete objective "${props.objectivesToRemove[0].attributes.Name}"?`
                : `Are you sure you want to delete selected objectives (${props.objectivesToRemove.length} items)?`
        }}
        confirmButtonProps={{ text: "Delete", disabled: props.objectivesToRemove.filter(_ => _.isEditable).length == 0 }}>
        {props.objectivesToRemove.some(_ => !_.isEditable) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
            You don't have permissions to delete the following objectives:
            <ul>
                {props.objectivesToRemove.filter(_ => !_.isEditable).map(_ => <li key={_.id}>{_.attributes.Name}</li>)}
            </ul>
        </MessageBar>}
    </RemoveDialog>;
export const ObjectivesToRemoveRemoveDialog = connect(undefined, mergeActionCreators)(ObjectivesToRemoveRemoveDialogImpl);

const DeletionResultRemoveDialogImpl = (props: { deletionResult: IDeletionResult[] } & ActionProps) => 
    <RemoveDialog
        onClose={() => props.objectivesActions.dismissDeletionResult()}
        confirmButtonProps={{ text: "Got it" }}
        modalProps={{ styles: { main: { minWidth: 500 } } }}
        dialogContentProps={getDeletionResultDialogContent(props.deletionResult)}>
        {props.deletionResult.length > 1 && props.deletionResult.some(_ => !_.isDeleted)
            && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                Failed to delete the following objectives:
                <ul>
                    {props.deletionResult.filter(_ => !_.isDeleted).map(_ => <li key={_.id}>{_.name}</li>)}
                </ul>
            </MessageBar>}
    </RemoveDialog>;
export const DeletionResultRemoveDialog = connect(undefined, mergeActionCreators)(DeletionResultRemoveDialogImpl);

function getDeletionResultDialogContent(deletionResults: IDeletionResult[]): IDialogContentProps {
    const notHiddenResults = deletionResults.filter(_ => !_.hidden);
    if (notHiddenResults.length == 1) {
        return notHiddenResults[0].isDeleted
            ? {
                title: "Objective deletion is complete",
                subText: `Objective "${notHiddenResults[0].name}" was deleted successfully.`
            }
            : {
                title: "Unable to delete objective",
                subText: "The selected objective cannot be deleted."
            }
    }

    const deleted = notHiddenResults.filter(_ => _.isDeleted);
    return {
        title: "Objectives deletion is complete",
        subText: deleted.length
            ? `Selected objectives (${deleted.length} items) were deleted successfully.`
            : undefined
    };
}

export const KeyResultRemoveDialog = (props: {
    toRemove: KeyResult[];
    onClose: () => void;
    onComplete: () => void;
}) => <RemoveDialog
    onClose={props.onClose}
    onComplete={props.onComplete}
    confirmButtonProps={{ text: "Delete" }}
    dialogContentProps={{
        title: `Delete Key Result${props.toRemove.length == 1 ? "" : "s"}`,
        subText: `Are you sure you want to delete ${props.toRemove.length == 1
            ? ("key result " + props.toRemove[0].name)
            : props.toRemove.length + " key results"} ?`
    }}>
        {(props.toRemove.filter(_ => _.projectIds.length > 0).length > 0)
            && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                The selected Key Result{(props.toRemove.length === 1 ? " is" : "s are")} used in the project(s).
                If {props.toRemove.length === 1 ? "this Key Result is deleted it" : "these Key Results are deleted they"} will be removed from all projects.
                Also, the related Objective will be recalculated accordingly.
            </MessageBar>}
    </RemoveDialog>;

const SubObjectivesImportDialogImpl = (props: {
    objective: Objective;
    objectives?: Objective[];
    onClose: () => void;
} & ActionProps) => <OKRSelectionPanel
        multichoice
        objectives={props.objectives}
        selectableEntity={EntityType.Objective}
        header={{
            title: 'Import Sub-Objective',
            description: 'Select one or more objectives that will be copied into current Objective as Sub-Objecitves'
        }}
        onDismiss={props.onClose}
        onSelectionComplete={(selected) => {
            props.objectivesActions.importObjectives(props.objective.id, selected.map(_ => _.objectiveId));
            props.onClose();
        }}
    />
export const SubObjectivesImportDialog = connect(undefined, mergeActionCreators)(SubObjectivesImportDialogImpl);

const ObjectiveMoveDialogImpl = (props: {
    objective: Objective;
    objectives?: Objective[];
    onClose: () => void;
} & ActionProps) => <OKRSelectionPanel
        selectableEntity={EntityType.Objective}
        objectives={props.objectives}
        filters={{
            states: [OKRState.Open],
            excludeIds: [props.objective.id],
            disabledIds: props.objective.attributes.Parent ? [props.objective.attributes.Parent.id] : undefined
        }}
        header={{
            title: 'Move Objective',
            description: 'Select an objective current objective will be moved to'
        }}
        onDismiss={props.onClose}
        onSelectionComplete={(selected) => {
            props.objectivesActions.moveObjective(props.objective.id, { id: selected[0].objectiveId, name: selected[0].name });
            props.onClose();
        }}
    />
export const ObjectiveMoveDialog = connect(undefined, mergeActionCreators)(ObjectiveMoveDialogImpl);

const KeyResultsImportDialogImpl = (props: {
    objective: Objective;
    objectives?: Objective[];
    onClose: () => void;
} & ActionProps) => <OKRSelectionPanel
        multichoice
        objectives={props.objectives}
        selectableEntity='keyresult'
        header={{
            title: 'Import Key Result',
            description: 'Select one or more key results that will be copied into current Objective'
        }}
        onDismiss={props.onClose}
        onSelectionComplete={(selected) => {
            props.objectivesActions.importKeyResults(props.objective.id, selected.map(_ => _.keyResultId!));
            props.onClose();
        }}
    />
export const KeyResultsImportDialog = connect(undefined, mergeActionCreators)(KeyResultsImportDialogImpl);

const KeyResultMoveDialogImpl = (props: {
    objectiveId: string;
    keyresult: KeyResult;
    objectives?: Objective[];
    onClose: () => void;
} & ActionProps) => <OKRSelectionPanel
        selectableEntity={EntityType.Objective}
        objectives={props.objectives}
        filters={{
            states: [OKRState.Open],
            disabledIds: (objectives) => [props.objectiveId, ...objectives.filter(_ => !_.isEditable).map(_ => _.id)]
        }}
        header={{
            title: 'Move Key Result',
            description: 'Select an objective current key result will be moved to'
        }}
        onDismiss={props.onClose}
        onSelectionComplete={(selected) => {
            props.objectivesActions.moveKeyResult(props.keyresult.id, props.objectiveId, selected[0].objectiveId);
            props.onClose();
        }}
    />
export const KeyResultMoveDialog = connect(undefined, mergeActionCreators)(KeyResultMoveDialogImpl);