import { IFilter, BaseFilterValue, Filter, IFilterHelper, FilterAttribute, Field, getLabel, IActiveFilter, IEntityFilterHelper, Layout } from "../../entities/Metadata";
import { IUserInfo, Dictionary } from "../../entities/common";
import { DisplayFieldService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { Challenge } from "../ChallengesListStore";
import { buildLayoutFilterHelper } from "../project/filters";

export class ActiveFilter {
    private readonly _filter: IFilter<BaseFilterValue>;

    constructor(name?: string) {
        this._filter = Filter.empty(name);
    }

    public withManager(userInfo: IUserInfo): ActiveFilter {
        if (!this._filter.value.attributes) {
            this._filter.value!.attributes = new Dictionary<any[]>();
        }
        this._filter.value.attributes!['Manager'] = [userInfo];
        this._filter.attributeNames.push({ name: 'Manager', type: "attributes" });
        return this;
    }

    public withTag(tag: string): ActiveFilter {
        if (!this._filter.value.attributes) {
            this._filter.value!.attributes = new Dictionary<any[]>();
        }
        const attrName = 'Tags';
        this._filter.value.attributes![attrName] = [tag];
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public withAttributes(attrNames: string[]) {
        let attributes = this._filter.attributeNames.filter(_ => _.type === "attributes");
        attrNames.forEach(name => {
            let attribute = attributes.find(attr => attr.name === name);
            if (!attribute) {
                this._filter.attributeNames.push({ name: name, type: "attributes" });
            }
        });
        return this;
    }

    public build(): IFilter<BaseFilterValue> {
        return this._filter;
    }
}

export type FilterHelperProps = {
    challenges: Challenge[];
    fields: Field[];
    layouts: Layout[];
};

export class FilterHelper implements IEntityFilterHelper<Challenge> {
    public getFilterAttributes = (fields: Field[]): FilterAttribute<BaseFilterValue>[] => {
        const attrs = fields.map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<BaseFilterValue>);
        attrs.push({ type: "layoutId", name: "layoutId", displayName: "Layout", value: undefined });
        return attrs;
    }
    private attributes: IFilterHelper<BaseFilterValue, Challenge> = {
        buildFilterElement: (attr: FilterAttribute<BaseFilterValue>,
            filter: IFilter<BaseFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const field: Field = attr.value;
            const value = filter.value?.attributes?.[field.name];

            return DisplayFieldService.buildFieldMultiSelectInput(field, value, changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            let newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            let tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.fields, value),
        validateItem: (item: Challenge, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] === undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find(_ => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    const value = item.attributes[key];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }

    }

    private layoutId = buildLayoutFilterHelper<BaseFilterValue, Challenge>(() => this._props.layouts)

    private readonly _props: FilterHelperProps
    constructor(props: FilterHelperProps) {
        this._props = props;
    }
    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name);

    public helpersMap: { [K in keyof BaseFilterValue]: IFilterHelper<BaseFilterValue, Challenge> } =
        {
            "attributes": this.attributes,
            "layoutId": this.layoutId
        }
}
