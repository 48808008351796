import { IExtensibleEntity, Dictionary, IEntityInfo, IUserInfo, SortDirection, ISortState } from "../entities/common";
import { ViewService } from "./ViewService";
import * as Metadata from "../entities/Metadata";
import { IOrderBy } from "../store/views";

export namespace SortService {
    function getComparerImpl(a: any, b: any, isDesc: boolean, field?: Metadata.Field) {
        if (field) {
            switch (field.type) {
                case Metadata.FieldType.Text:
                    {
                        return compareWithUndefined(a, b, isDesc,
                            () => defaultComparer(
                                typeof a === "string" ? a.toLowerCase() : a,
                                typeof b === "string" ? b.toLowerCase() : b,
                                isDesc));
                    }
                case Metadata.FieldType.Integer:
                case Metadata.FieldType.Decimal:
                    {
                        return compareWithUndefined(a, b, isDesc, defaultComparer);
                    }
                case Metadata.FieldType.Date:
                case Metadata.FieldType.DateTime:
                    {
                        return compareWithUndefined(a, b, isDesc,
                            () => defaultComparer(new Date(a), new Date(b), isDesc));
                    }
                case Metadata.FieldType.Resource:
                case Metadata.FieldType.User:
                    {
                        return compareWithUndefined(a, b, isDesc,
                            (v1: IUserInfo | IUserInfo[], v2: IUserInfo | IUserInfo[]) => {
                                const _a = !Array.isArray(v1) ? v1.fullName?.toLowerCase() : v1.map(_ => _.fullName?.toLowerCase()).join(', ');
                                const _b = !Array.isArray(v2) ? v2.fullName?.toLowerCase() : v2.map(_ => _.fullName?.toLowerCase()).join(', ');
                                return defaultComparer(_a, _b, isDesc);
                            });
                    }
                case Metadata.FieldType.Portfolio:
                case Metadata.FieldType.Program:
                case Metadata.FieldType.Project:
                case Metadata.FieldType.Idea:
                case Metadata.FieldType.Task:
                case Metadata.FieldType.Predecessor:
                case Metadata.FieldType.Ref:
                case Metadata.FieldType.Group:
                    {
                        return compareWithUndefined(a, b, isDesc,
                            (v1: IEntityInfo, v2: IEntityInfo) => {
                                const name1 = !Array.isArray(v1) ? v1.name?.toLowerCase() : v1.map(_ => _.name?.toLowerCase()).join(', ');
                                const name2 = !Array.isArray(v2) ? v2.name?.toLowerCase() : v2.map(_ => _.name?.toLowerCase()).join(', ');
                                return defaultComparer(name1, name2, isDesc);
                            });
                    }
                case Metadata.FieldType.Flag:
                    {
                        return compareWithUndefined(a, b, isDesc, defaultComparer);
                    }
            }
        }

        return compareWithUndefined(a, b, isDesc, defaultComparer);
    }

    export function defaultComparer(a: any, b: any, isDesc: boolean) {
        return a === b
            ? 0
            : ((isDesc ? (a > b) : (a < b))
                ? -1
                : 1);
    }

    export function getComparer(
        fields: Dictionary<Metadata.Field>,
        orderBy: IOrderBy[] | IOrderBy,
        isFieldFake?: (field: Metadata.Field) => boolean,
        extractor?: (item: IExtensibleEntity, field: Metadata.Field) => any
    ): (a: IExtensibleEntity, b: IExtensibleEntity) => number {
        if (Array.isArray(orderBy)) {
            return (aEntity: IExtensibleEntity, bEntity: IExtensibleEntity) => {
                for (let i = 0; i < orderBy.length; ++i) {
                    const comparationResult = getOrderByComparer(fields, orderBy[i], isFieldFake, extractor)(aEntity, bEntity);
                    if (comparationResult === 0) {
                        continue;
                    }
                    return comparationResult;
                }

                return 0;
            }
        }

        return getOrderByComparer(fields, orderBy, isFieldFake, extractor);
    }

    export function getOrderByComparer(
        fields: Dictionary<Metadata.Field>,
        orderBy: IOrderBy,
        isFieldFake?: (field: Metadata.Field) => boolean,
        extractor?: (item: IExtensibleEntity, field: Metadata.Field) => any
    ): (a: IExtensibleEntity, b: IExtensibleEntity) => number {
        if (orderBy.isProperty) {
            return (aEntity: any, bEntity: any) => getComparerImpl(aEntity[orderBy.fieldName], bEntity[orderBy.fieldName], orderBy.direction === SortDirection.DESC);
        }

        return (aEntity: IExtensibleEntity, bEntity: IExtensibleEntity) => {
            const field = fields[orderBy.fieldName];

            const a = field && aEntity && getFieldValue(field, aEntity, isFieldFake, extractor);
            const b = field && bEntity && getFieldValue(field, bEntity, isFieldFake, extractor);

            return getComparerImpl(a, b, orderBy.direction === SortDirection.DESC, field);
        }
    }

    export function isSortable(field: Metadata.Field, isFieldFake?: (field: Metadata.Field) => boolean) {
        return (isFieldFake ? !isFieldFake(field) : false) || ViewService.getHandler(field);
    }

    function getFieldValue(
        field: Metadata.Field,
        item: IExtensibleEntity,
        isFieldFake?: (field: Metadata.Field) => boolean,
        extractor?: (item: IExtensibleEntity, field: Metadata.Field) => any
    ): any {
        if (extractor) {
            return extractor(item, field);
        }
        return getFieldValueBaseImpl(field, item, isFieldFake);
    }

    export function getFieldValueBaseImpl(
        field: Metadata.Field,
        item: IExtensibleEntity,
        isFieldFake?: (field: Metadata.Field) => boolean
    ): any {
        const columnSettings: Metadata.IFieldColumn | undefined = field.settings && field.settings.views && field.settings.views.list;
        if (isFieldFake && isFieldFake(field) && columnSettings && columnSettings.componentPath) {
            return ViewService.getFakeFieldColumnValue(item, field);
        }
        return item.attributes[field.name];
    }

    export function compareWithUndefined(a: any, b: any, isDesc: boolean, comparator: (a: any, b: any, isDesc: boolean) => number): number {
        const isDescNumber = (isDesc ? -1 : 1);
        return a === b ? 0 : a == null ? -isDescNumber : (b == null ? isDescNumber : comparator(a, b, isDesc));
    }

    export function areSortsEqual(a?: ISortState | ISortState[], b?: ISortState | ISortState[]): boolean {
        return a === b || (!!a && !!b && isEqual(a, b));
    }


    export function isEqual(a: ISortState | ISortState[], b: ISortState | ISortState[]): boolean {
        const arrA = Array.isArray(a) ? a : [a];
        const arrB = Array.isArray(b) ? b : [b];
        if (arrA.length !== arrB.length) {
            return false;
        }

        for (let i = 0; i < arrA.length; ++i) {
            const aEl = arrA[i];
            const bEl = arrB[i];
            if (aEl.fieldName !== bEl.fieldName || aEl.direction !== bEl.direction) {
                return false;
            }
        }

        return true;
    }

    export function getSimpleComparer(orderBy: IOrderBy | IOrderBy[], valueExtractor: ((item: any, field: string) => any) | undefined = undefined) {
        if (!Array.isArray(orderBy)) {
            return getSimpleOrderByComparer(orderBy, valueExtractor);
        }
        
        return (aEntity: IExtensibleEntity, bEntity: IExtensibleEntity) => {
            for (let i = 0; i < orderBy.length; ++i) {
                const comparisonResult = getSimpleOrderByComparer(orderBy[i], valueExtractor)(aEntity, bEntity);
                if (comparisonResult === 0) {
                    continue;
                }
                return comparisonResult;
            }

            return 0;
        }
    }

    export function getSimpleOrderByComparer(sortBy: IOrderBy, valueExtractor: ((item: any, field: string) => any) | undefined = undefined) {
        const { fieldName, direction } = sortBy;
        const isDesc = direction === SortDirection.DESC;
        return (a: any, b: any) => {
            valueExtractor ??= defaultValueExtractor;
            const aValue = valueExtractor(a, fieldName);
            const bValue = valueExtractor(b, fieldName);
            return SortService.compareWithUndefined(aValue, bValue, isDesc, defaultComparer);
        }
    }

    function defaultValueExtractor(item: any, field: string) {
        const value = item[field];
        return typeof value === 'string'
            ? value.toLowerCase()
            : value;
    }

    export function getColumnOrderBy(column: { fieldName?: string }, orderBy: IOrderBy) {
        return column.fieldName === orderBy.fieldName
            ? {
                fieldName: orderBy.fieldName,
                direction: orderBy.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
            }
            : {
                fieldName: column.fieldName!,
                direction: SortDirection.ASC
            }
    }
}