import React from "react";
import "./ContextualMenuToggle.css";
import { Toggle, IToggleProps, IconButton } from "office-ui-fabric-react";

type Props = IToggleProps & {
    configureTitle?: string;
    disableConfigure?: boolean;
    onConfigure?: () => void;
};

const ContextualMenuToggle = (props: Props) => {
    const { configureTitle, disableConfigure, onConfigure } = props;
    return (
        <div style={{ position: 'relative' }}>
            <Toggle
                className="contextual-menu-toggle"
                inlineLabel
                onText="Yes"
                offText="No"
                styles={{
                    label: { flex: 1, fontWeight: "normal" },
                    root: { marginBottom: "unset" },
                }}
                {...props}
            />
            {onConfigure && (
                <IconButton
                    className="configure-button"
                    menuIconProps={{ iconName: 'Settings' }}
                    style={{ fontSize: '16px' }}
                    title={configureTitle}
                    onClick={onConfigure}
                    disabled={disableConfigure}
                />
            )}
        </div>
    );
};

export default ContextualMenuToggle;
