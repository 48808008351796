import { AppThunkAction } from '../';
import { Action, Reducer } from 'redux';
import { IImportResult } from "./common";
import { Dictionary } from "../../entities/common";
import { Validator } from "../../validation";
import { SourceType } from '../ExternalEpmConnectStore';

interface IImportState<T> {
    data: T[];

    imported: number;
    failed: number;

    isDialogOpen: boolean;
    isImporting: boolean;
    total: number;
    processed: number;
}

type ProjectImportState = IImportState<IImportProjectState>;
type ResourceImportState = {
    data: IImportResourceState[];
    isDialogOpen: boolean;
    isImporting: boolean;

    isFinished: boolean;
    failedCount: number;
    totalCount: number;
};

export interface ImportState {
    projects: ProjectImportState;
    resources: ResourceImportState
}

export interface IMappedProject {
    id?: string;
    name: string;
}

export interface IProjectInfo {
    id: string;
    name: string;
    isPrivate?: boolean;
}

export interface IBaseEntityImportState {
    externalId: string;
    status: ImportStatus;
    error?: string;
    isInProcessing: boolean;
    valid?: boolean;
}

export interface IImportProjectState extends IBaseEntityImportState {
    externalName: string;
    project: IMappedProject | IProjectInfo;
    sourceInfo?: any;
    projects: IProjectInfo[];
}

export interface IMappedResource {
    id?: string;
    name: string;
}

export interface IImportResourceState extends IBaseEntityImportState {
    key?: string;
    externalName: string;
    externalEmail: string;
    resource: IMappedResource;
}

export enum ImportStatus {
    None = 0,
    Matched = 1,
    New = 2,
    Linked = 3,
    Unaccessible = 4
}

export namespace ImportStatus {
    export function isEditable(status: ImportStatus){
        return status !== ImportStatus.Linked && status !== ImportStatus.Unaccessible;
    }

    export function isLinked(status?: ImportStatus){
        return status == ImportStatus.Linked || status == ImportStatus.Unaccessible;
    }
}

type ImportDialogToggle = {
    type: 'IMPORT_DIALOG_TOGGLED',
    entity: 'project' | 'resource'
    open: boolean;
};

// projects actions
type ProjectsImportStarted = {
    type: "IMPORT_PROJECTS_STARTED";
    entityIds: string[];
}

type ProjectsBatchImportFinished = {
    type: "IMPORT_PROJECTS_BATCH_FINISHED";
    results: IImportResult[];
}

type ProjectsImportFinished = {
    type: "IMPORT_PROJECTS_FINISHED";
}

type SetProjectsImportMaps = {
    type: "IMPORT_SET_PROJECTS_MAPS";
    maps: IImportProjectState[];
}

type SetProjectsConnectionInfo = {
    type: "IMPORT_SET_PROJECTS_CONNECTION_INFO";
    system: SourceType;
    connectionId?: string;
}

type ClearProjectsImportInfo = {
    type: "IMPORT_CLEAR_PROJECTS_IMPORT_INFO";
}

// resources actions
type ResourcesImportStarted = {
    type: "IMPORT_RESOURCES_STARTED";
}

type ResourcesImportFinished = {
    type: "IMPORT_RESOURCES_FINISHED";
    results: IImportResult[];
}

type SetResourcesImportMaps = {
    type: "IMPORT_SET_RESOURCES_MAPS";
    maps: IImportResourceState[];
}

type SetResourcesConnectionInfo = {
    type: "IMPORT_SET_RESOURCES_CONNECTION_INFO";
    system: SourceType;
    connectionId?: string;
}

type ClearResourcesImportInfo = {
    type: "IMPORT_CLEAR_RESOURCES_IMPORT_INFO";
}

export type ProjectsImportActions = ProjectsImportStarted
    | ProjectsBatchImportFinished
    | ProjectsImportFinished
    | SetProjectsImportMaps
    | SetProjectsConnectionInfo
    | ClearProjectsImportInfo;

export type ResourcesImportActions = ResourcesImportStarted
    | ResourcesImportFinished
    | SetResourcesImportMaps
    | SetResourcesConnectionInfo
    | ClearResourcesImportInfo;

type KnownAction = ProjectsImportActions | ResourcesImportActions | ImportDialogToggle;

const validators = {
    project: Validator.new().required('"Name in PPM Express" is required').build(),
    resource: Validator.new().required('"Name in PPM Express" is required').build()
}

export const actionCreators = {
    toggleProjectImportDialog: (open: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "IMPORT_DIALOG_TOGGLED", entity: "project", open });
    },
    toggleResourceImportDialog: (open: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "IMPORT_DIALOG_TOGGLED", entity: "resource", open });
    },
    setProjectsImportMaps: (maps: IImportProjectState[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        maps.forEach(_ => _.valid = !(_.error = validators.project.getErrorMessage(_.project.name)));
        dispatch({ type: "IMPORT_SET_PROJECTS_MAPS", maps });
    },
    clearProjectsImportInfo: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "IMPORT_CLEAR_PROJECTS_IMPORT_INFO" });
    },
    setResourcesImportMaps: (maps: IImportResourceState[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "IMPORT_SET_RESOURCES_MAPS", maps });
    },
    clearResourcesImportInfo: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "IMPORT_CLEAR_RESOURCES_IMPORT_INFO" });
    }
}

const initState: ImportState = {
    projects: {
        data: [],
        imported: 0,
        failed: 0,
        total: 0,
        processed: 0,
        isImporting: false,
        isDialogOpen: false
    },
    resources: {
        data: [],
        isImporting: false,
        isDialogOpen: false,

        isFinished: false,
        failedCount: 0,
        totalCount: 0,
    }
}

export const projectReducer: Reducer<ProjectImportState> = (state: ProjectImportState, incomingAction: Action) => {
    const action = incomingAction as ProjectsImportActions | ImportDialogToggle;
    switch (action.type) {
        case "IMPORT_DIALOG_TOGGLED":
            {
                return {
                    ...state,
                    isDialogOpen: action.entity == 'project' ? action.open : state.isDialogOpen
                }
            }
        case "IMPORT_PROJECTS_STARTED":
            {
                const maps = state.data.map(_ => {
                    if (~action.entityIds.indexOf(_.externalId)) {
                        return { ..._, isInProcessing: true };
                    }

                    return _;
                });

                return {
                    ...state,
                    total: action.entityIds.length,
                    processed: 0,
                    isImporting: true,
                    data: maps
                }
            }
        case "IMPORT_PROJECTS_BATCH_FINISHED":
            {
                const imported = action.results.filter(_ => _.isSuccessful).length;
                let processed: number | undefined = state.processed! + action.results.length;
                return {
                    ...state,
                    processed: processed,
                    imported: state.imported + imported,
                    failed: state.failed + action.results.length - imported,
                    data: Utils.handleImportResult(state.data, action.results)
                }
            }
        case "IMPORT_PROJECTS_FINISHED":
            {
                return {
                    ...state,
                    total: 0,
                    processed: 0,
                    isImporting: false
                }
            }
        case 'IMPORT_SET_PROJECTS_MAPS':
            {
                return {
                    ...state,
                    data: action.maps
                }
            }
        case 'IMPORT_SET_PROJECTS_CONNECTION_INFO':
            {
                return {
                    ...state,
                    system: action.system,
                    connectionId: action.connectionId
                }
            }
        case 'IMPORT_CLEAR_PROJECTS_IMPORT_INFO':
            {
                return {
                    ...state,
                    imported: 0,
                    failed: 0
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

export const resourceReducer: Reducer<ResourceImportState> = (state: ResourceImportState, incomingAction: Action) => {
    const action = incomingAction as ResourcesImportActions | ImportDialogToggle;
    switch (action.type) {
        case "IMPORT_DIALOG_TOGGLED": return {
            ...state,
            isDialogOpen: action.entity == 'resource' ? action.open : state.isDialogOpen
        }
        case "IMPORT_RESOURCES_STARTED": return {
            ...state,
            isImporting: true
        }
        case "IMPORT_RESOURCES_FINISHED": return {
            ...state,
            data: Utils.handleImportResult(state.data, action.results, (m, r) => ({ resource: { ...m.resource, id: r.entityId } })),
            isImporting: false,

            isFinished: true,
            failedCount: action.results.filter(_ => !_.isSuccessful).length,
            totalCount: action.results.length
        }
        case 'IMPORT_SET_RESOURCES_MAPS': return {
            ...state,
            data: action.maps
        }
        case 'IMPORT_SET_RESOURCES_CONNECTION_INFO': return {
            ...state,
            system: action.system,
            connectionId: action.connectionId
        }
        case 'IMPORT_CLEAR_RESOURCES_IMPORT_INFO': return {
            ...state,
            isFinished: false,
            failedCount: 0,
            totalCount: 0
        }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

export const reducer: Reducer<ImportState> = (state: ImportState = initState, action: Action) => {
    return {
        projects: projectReducer(state.projects, action),
        resources: resourceReducer(state.resources, action),
    }
}

export namespace Utils {
    const MAX_BATCH_SIZE = 50;
    const MAX_CHUNK_SIZE = 100;

    export function sendByChunks<TIn, TOut>(maps: TIn[], sender: (batch: TIn[]) => Promise<TOut[]>) {
        let promise = Promise.resolve<TOut[]>([]);
        while (maps.length > 0) {
            const chunk = maps.splice(0, MAX_CHUNK_SIZE);
            promise = promise.then(accumulator => sender(chunk).then(_ => accumulator.concat(_)));
        }

        return promise;
    }

    export function getBatchSize(count: number) {
        if (count < 10) {
            return 1;
        }

        const size = Math.round(count / 10);
        return size > MAX_BATCH_SIZE ? MAX_BATCH_SIZE : size;
    }

    export function batchSend<T>(maps: T[], sender: (batch: T[]) => Promise<any>) {
        const batches: T[][] = [];
        const batchSize = getBatchSize(maps.length);
        while (maps.length > 0) {
            const entities = maps.splice(0, batchSize);
            batches.push(entities);
        }

        let promise = Promise.resolve();
        batches.forEach(batch => {
            promise = promise.then(() => sender(batch));
        });

        return promise;
    }

    export function toDictionary<T extends { uniqueId: string }>(entities: T[]): Dictionary<T> {
        const dictionary = new Dictionary<T>();
        entities.forEach(_ => dictionary[_.uniqueId] = _);
        return dictionary;
    }

    export function handleImportResult<T extends IBaseEntityImportState>(maps: T[],
        results: IImportResult[],
        mapper?: (map: T, result: IImportResult) => Partial<T>): T[] {

        const imports = Utils.toDictionary(results);
        return maps.map(_ => {
            const imported = imports[_.externalId];
            if (!imported) return _;

            if (imported.isSuccessful) {
                return Object.assign({}, _, { ...mapper?.(_, imported), isInProcessing: false, error: undefined, status: ImportStatus.Linked });
            }

            return Object.assign({}, _, { isInProcessing: false, error: imported.error || "" });
        });
    }
}