import * as React from 'react';
import * as Metadata from '../../entities/Metadata';
import { Panel, PanelType, IPanelProps, IconButton, ActionButton, Toggle } from 'office-ui-fabric-react';
import FieldPanel, { IFieldActions } from './FieldPanel';
import { DraggableList } from "../common/DraggableList";
import { arraysEqual, orderNotSelected } from "../utils/common";
import RemoveDialog from '../common/RemoveDialog';
import { EntityType } from '../../entities/common';

export interface IColumnsPanelProps {
    headerText?: string;
    secondaryText?: string;
    entityType: EntityType;
    fields: Metadata.Field[];
    selected: string[];
    onDismiss: () => void;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    onChange?: (field: Metadata.Field, checked: boolean, selectedIndex?: number) => void;
    onSelectedOrderChanged?: (selected: string[]) => void;
}

interface IColumnsPanelState {
    showFieldPanel?: boolean;
    editField?: Metadata.Field;
    removeField?: Metadata.Field;
    orderedFields: Metadata.Field[];
}

export default class ColumnsPanel extends React.Component<IColumnsPanelProps, IColumnsPanelState> {
    componentWillMount() {
        this._orderFields(this.props.fields, this.props.selected);
    }

    componentWillReceiveProps(newProps: IColumnsPanelProps) {
        if (this.props.fields.length !== newProps.fields.length) {
            this._orderFields(newProps.fields, newProps.selected);
        }

        if (!arraysEqual(this.props.selected, newProps.selected)) {
            this.setState({ orderedFields: this.state.orderedFields.map(_ => newProps.fields.find(f => f.name === _.name)!) });
        }
    }

    private _orderFields = (fields: Metadata.Field[], selected: string[]) => {
        this.setState({ orderedFields: orderNotSelected(fields, f => selected.indexOf(f.name), a => Metadata.getLabel(a), true) });
    }

    private _getSelectedIndex = (f: Metadata.Field) => {
        let index = 0;
        for (const field of this.state.orderedFields) {
            if (field.id === f.id) {
                break;
            }
            if (this.props.selected && ~this.props.selected.indexOf(field.name)) {
                index++;
            }
        }
        return index;
    }

    public render() {
        const { removeField } = this.state;
        return this.state.showFieldPanel && this.props.fieldActions
            ? <FieldPanel
                entityType={this.props.entityType}
                field={this.state.editField}
                allowManageFields={this.props.allowManageFields}
                actions={this.props.fieldActions}
                onDismiss={() => this.setState({ showFieldPanel: false })} />
            : <Panel
                isOpen={true}
                isLightDismiss={true}
                type={PanelType.custom}
                customWidth="400px"
                focusTrapZoneProps={{ disableFirstFocus: true }}
                onRenderHeader={this._onRenderHeader}
                onDismiss={this.props.onDismiss}>
                <DraggableList items={this.state.orderedFields} onItemRender={this._onItemRender} onChanged={this.props.onSelectedOrderChanged ? this._onOrderChanged : undefined} />
                {this.props.allowManageFields !== undefined && <ActionButton iconProps={{ iconName: 'Add' }}
                    text="New Field"
                    className="new-button"
                    disabled={!this.props.allowManageFields}
                    onClick={() => this.setState({ showFieldPanel: true, editField: undefined })} />}
                {!!removeField && <RemoveDialog
                    onClose={() => this.setState({ removeField: undefined })}
                    onComplete={() => this.props.fieldActions!.removeField!(removeField.id)}
                    confirmButtonProps={{ text: "Delete" }}
                    dialogContentProps={{
                        title: "Delete field",
                        subText: `Are you sure you want to delete field "${Metadata.getLabel(removeField)}" and field data in all related entities? This action can't be undone.`
                    }} />}
            </Panel>;
    }

    private _onItemRender = (_: Metadata.Field) => {
        const label = Metadata.getLabel(_);
        const checked = !!~this.props.selected.indexOf(_.name);
        return (
            <div className="cb-wrap">
                <div className='title bold' title={label}>{label}</div>
                {this.props.allowManageFields !== undefined
                    && <div className="align-center">
                        <IconButton
                            menuIconProps={{ iconName: 'Edit' }}
                            title="Edit"
                            className='menu'
                            disabled={_.isFake || !this.props.allowManageFields}
                            onClick={() => this.setState({ showFieldPanel: true, editField: _ })} />
                    </div>}
                {this.props.allowManageFields !== undefined
                    && <div className="align-center">
                        <IconButton
                            menuIconProps={{ iconName: 'Delete' }}
                            title="Remove"
                            className='menu'
                            disabled={!_.isCustom || !this.props.allowManageFields}
                            onClick={() => this.setState({ removeField: _ })} />
                    </div>}
                {<Toggle
                    checked={checked}
                    onChange={(e, c) => this.props.onChange!(_, !!c, !!c ? this._getSelectedIndex(_) : undefined)}
                    title={checked ? 'On' : 'Off'}
                    disabled={!this.props.onChange} />}
            </div>);
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">{this.props.headerText || 'Configure Columns'}</p>
            <div className='ms-Panel-secondaryText'>{this.props.secondaryText || 'Select the columns to be displayed in the view. Drag and drop to reorder.'}</div>
        </div>;
    }

    private _onOrderChanged = (orderedFields: Metadata.Field[]) => {
        const { selected } = this.props;
        this.setState({ orderedFields: orderedFields }, () => {
            this.props.onSelectedOrderChanged?.(orderedFields.filter(_ => selected.includes(_.name)).map(_ => _.name))
        });
    }
}