import { nameof } from "../store/services/metadataService";
import { Dictionary, EntityType } from "./common";
import * as Metadata from "./Metadata";
import * as ProjectsListStore from "../store/ProjectsListStore";
import StageDescriptor from "./StageDescriptor";
import { IKeyDateAttrs, ILessonLearnedAttrs, ITaskAttrs } from "./Subentities";

// the same is in StageDescriptorFactory.cs on server side
function buildStageFieldNameByEntityTypeMap(): Partial<Record<EntityType, string>> {
    return {
        [EntityType.Project]: nameof<ProjectsListStore.ProjectAttrs>("Stage"),
        [EntityType.LessonLearned]: nameof<ILessonLearnedAttrs>("ProjectStage"),
        [EntityType.KeyDate]: nameof<IKeyDateAttrs>("Stage"),
        [EntityType.Task]: nameof<ITaskAttrs>("Stage"),
    };
};

export function createStageDescriptorFor(entityType: EntityType, entityFields: Metadata.Field[]): StageDescriptor | undefined {
    const stageFieldNameByEntityTypeMap = buildStageFieldNameByEntityTypeMap();
    const stageFieldName = stageFieldNameByEntityTypeMap[entityType];
    if (!stageFieldName) {
        return undefined;
    }
    return createStageDescriptorForImpl(stageFieldName, entityFields);
}

export function createStageDescriptor(stageField: Metadata.Field): StageDescriptor {
    return createStageDescriptorForImpl(stageField.name, [stageField]);
}

function createStageDescriptorForImpl(stageFieldName: string, entityFields: Metadata.Field[]): StageDescriptor {
    return createStageDescriptorByFieldNameMapForImpl([stageFieldName], entityFields)[stageFieldName];
}

function createStageDescriptorByFieldNameMapForImpl(stageFieldNames: string[], entityFields: Metadata.Field[]): Dictionary<StageDescriptor> {
    const stageDescriptorByFieldNameMap = new Dictionary<StageDescriptor>();
    for (const field of entityFields) {
        if (stageFieldNames.includes(field.name)) {
            stageDescriptorByFieldNameMap[field.name] = new StageDescriptor(field);
        }
    }
    return stageDescriptorByFieldNameMap;
}
