import React from 'react';
import {
    CheckboxVisibility, DefaultButton, DetailsListLayoutMode, IColumn, IDetailsHeaderProps, IObjectWithKey, IPanelProps,
    IRenderFunction, PanelType, ScrollablePane, ScrollbarVisibility, Selection, SelectionMode, Sticky, StickyPositionType
} from 'office-ui-fabric-react';
import { Dictionary } from '../../entities/common';
import { distinct, notUndefined, toDictionaryById } from '../utils/common';
import DetailsListWrapper from './DetailsListWrapper';
import { ControlSpiner } from './Spinner';
import ExpandablePanel from './ExpandablePanel';

export enum SelectionType {
    Entity = 0,
    Subentity = 1
}

type TreeNodeInfo = { item: IViewItem, disabled?: boolean, outlineLevel: number };
type TreeNode<T> = { item: T, children: TreeNode<T>[] }

export interface IViewItem {
    id: string;
    name: string;
    parentId?: string;
    isSubentity: boolean;
}

type OwnProps = {
    isLoading?: boolean;
    multichoice?: boolean;
    selectionType: SelectionType;
    header: {
        title: string;
        description: string;
    };
    onDismiss: () => void;
    onSelectionComplete: (selected: IViewItem[]) => void;
    nameColumnIconName:string;
    onRenderNameColumn: (item: IViewItem) => JSX.Element;
    extraColumns?: IColumn[];
    onRenderFilters?: (filterValue: Dictionary<any>, saveFilter: (key: string, value: any) => void) => JSX.Element | null;
    applyFilters?: (item: IViewItem, filterValues: Dictionary<any>) => boolean;
    filters?: {
        excludeIds?: string[];
        disabledIds?: string[];
    };
    sortItems?: (items: IViewItem[]) => IViewItem[];
    allItems: IViewItem[];
}
type Props = OwnProps;

type State = {
    columns: IColumn[];
    selectedIds: string[];
    filterValue: Dictionary<any>;
}

export default class SelectionPanel extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIds: [],
            filterValue: {},
            columns: this._buildColumns()
        };

        this._selection = new Selection({
            selectionMode: this.props.multichoice ? SelectionMode.multiple : SelectionMode.single,
            getKey: (_: IObjectWithKey) => (_ as TreeNodeInfo).item.id,
            onSelectionChanged: () => {
                this.setState({ selectedIds: this._selection.getSelection().map(_ => (_ as TreeNodeInfo).item.id) });
            },
            canSelectItem: (item) =>
                (item as TreeNodeInfo).item.isSubentity === (this.props.selectionType === SelectionType.Subentity)
                && !(item as TreeNodeInfo).disabled
        });
    }

    public render() {
        return <ExpandablePanel
            className="selection-panel"
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth='600px'
            onRenderHeader={this._onRenderHeader}
            onRenderFooterContent={this._onRenderFooterContent}
            onDismiss={this.props.onDismiss}
            isOpen={true}>
            <div className="list-container">
                <ControlSpiner isLoading={!!this.props.isLoading} className="show-over">
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <DetailsListWrapper
                            onRenderDetailsHeader={this._onRenderDetailsHeader}
                            layoutMode={DetailsListLayoutMode.justified}
                            setKey='set'
                            items={this._buildTreeNodes()}
                            columns={this._buildColumns()}
                            selection={this._selection}
                            checkboxVisibility={CheckboxVisibility.always}
                        />
                    </ScrollablePane>
                </ControlSpiner>
            </div>
        </ExpandablePanel>;
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!(props)}
            </Sticky>
        );
    }

    private _onRenderHeader = (): JSX.Element | null => {
        const { filterValue } = this.state;

        return <div className="ms-Panel-header" style={{ width: "100%" }}>
            <p className="ms-Panel-headerText">{this.props.header.title}</p>
            <div className='ms-Panel-secondaryText'>{this.props.header.description}</div>
            <div className="panel-area">
                {
                    this.props.onRenderFilters?.(filterValue, (key, value) => this.setState({ filterValue: { ...filterValue, [key]: value } }))
                }
            </div>
        </div>;
    }

    private _onRenderFooterContent = (props?: IPanelProps): JSX.Element | null => {
        const { selectedIds } = this.state;
        const { onSelectionComplete, onDismiss, allItems } = this.props;
        return <div className="commands">
            <DefaultButton primary disabled={!selectedIds.length} text="Confirm"
                onClick={() => {
                    const items = allItems
                        .filter(_ => selectedIds.indexOf(_.id) !== -1);
                    onSelectionComplete(items);
                }} />
            <DefaultButton text="Cancel" onClick={() => onDismiss()} />
        </div>;
    }

    private _buildColumns = (): IColumn[] => {
        const fieldName = "Name";

        return [
            {
                key: fieldName,
                iconName: this.props.nameColumnIconName,
                fieldName: fieldName,
                name: fieldName,
                headerClassName: "with-icon",
                minWidth: 100,
                maxWidth: 500,
                isResizable: false,
                onRender: (node: TreeNodeInfo) => {
                    const outlineLevelPadding = 29;
                    return <div style={{ width: '100%', paddingLeft: node.outlineLevel * outlineLevelPadding }}>
                        {this.props.onRenderNameColumn(node.item)}
                    </div>
                }
            },
            ...(this.props.extraColumns?.map(_ => ({ ..._, onRender: (node: TreeNodeInfo) => _.onRender?.(node.item)} )) ?? [])
        ].filter(notUndefined);
    }

    private _buildTreeNodes(): TreeNodeInfo[] {
        const { applyFilters, filters, selectionType, allItems, sortItems } = this.props;

        const getAllChildIds = (ids: string[] | undefined, viewItems: IViewItem[]): string[] =>
            ids?.length ? [...ids, ...getAllChildIds(viewItems.filter(_ => _.parentId && ids.indexOf(_.parentId) !== -1).map(_ => _.id), viewItems)] : [];

        const excludeIds = getAllChildIds(filters?.excludeIds, allItems);

        const items = this._getTopLevel(
            allItems.filter(item => (excludeIds.indexOf(item.id) === -1)
                && (item.isSubentity === (selectionType === SelectionType.Subentity))
                && (!applyFilters || applyFilters(item, this.state.filterValue))),
            allItems).all;

        const disabledIds = filters?.disabledIds;
        return this._getHierarchy(sortItems ? sortItems(items): items)
            .reduce((cum, cur) => ([
                ...cum,
                ...this._buildTree(cur, 0, disabledIds
                    ? node => disabledIds.indexOf(node.item.id) !== -1
                    : undefined)
            ]), []);
    }

    private _getTopLevel = (items: IViewItem[], allItems: IViewItem[]): { top: IViewItem[]; all: IViewItem[] } => {
        const map = toDictionaryById(allItems);
        const { top, all } = items
            .map(_ => {
                const _all = [_];
                let _top = _;
                while (_top.parentId && map[_top.parentId]) {
                    _top = map[_top.parentId];
                    _all.push(_top);
                }
                return { top: _top, all: _all };
            })
            .reduce((cum, cur) => ({ top: [...cum.top, cur.top], all: [...cum.all, ...cur.all] }), { top: [], all: [] });
        return {
            top: top.filter(distinct),
            all: all.filter(distinct)
        };
    }

    private _getHierarchy = (items: IViewItem[]): TreeNode<IViewItem>[] => {
        const allNodes: Dictionary<TreeNode<IViewItem>> = {};
        const topLevel: TreeNode<IViewItem>[] = [];
        for (const item of items) {
            const node: TreeNode<IViewItem> = allNodes[item.id] ?? { item: item, children: [] };
            node.item = item;
            allNodes[item.id] = node;
            if (!item.parentId) {
                topLevel.push(node);
            } else {
                const parentNode = allNodes[item.parentId] ?? { item: undefined, children: [] };
                allNodes[item.parentId] = parentNode;
                parentNode.children.push(node);
            }
        }
        return topLevel;
    }

    private _buildTree = (node: TreeNode<IViewItem>, outlineLevel: number, disabled?: (node: TreeNode<IViewItem>) => boolean): TreeNodeInfo[] => {
        if (!node.item) {
            return [];
        }

        let result: TreeNodeInfo[] = [{ item: node.item, disabled: disabled?.(node), outlineLevel }];
        node.children.forEach(_ => { result = [...result, ...this._buildTree(_, outlineLevel + 1, disabled)]; });

        return result;
    }
}