import { IFilter, BaseFilterValue, Filter, IFilterHelper, FilterAttribute, Field, getLabel, IEntityFilterHelper, IActiveFilter, Layout } from "../../entities/Metadata";
import { IUserInfo, Dictionary, StatusCategory, statusCategoryMap, EntityType } from "../../entities/common";
import { Program, ProgramAttrs } from "../ProgramsListStore";
import { DisplayFieldService, InputsService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import * as React from "react";
import { Option, OptionsPickerProps } from "../../components/common/inputs/OptionsPicker";
import * as ProgramUtils from "./utils";
import { nameof, namesof } from '../services/metadataService';
import { Portfolio } from "../PortfoliosListStore";
import { SourceType, SourceTypeMap } from "../ExternalEpmConnectStore";
import { buildLayoutFilterHelper } from "../project/filters";
import { HUNDRED_PCT } from "../../components/utils/common";
import * as StatusDescriptorFactory from "../../entities/StatusDescriptorFactory";

export interface ProgramFilterValue extends BaseFilterValue {
    percentComplete?: { from: number, to: number };
    projectStatuses?: string[];
    portfolioId?: string[];
    linkedSystem?: string[];
    layoutId?: string[];
}

export class ActiveFilter {
    private readonly _filter: IFilter<ProgramFilterValue>;

    constructor(name?: string) {
        this._filter = Filter.empty(name);
    }

    public withManager(userInfo: IUserInfo): this {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        this._filter.value.attributes['Manager'] = [userInfo];
        this._filter.attributeNames.push({ name: 'Manager', type: "attributes" });
        return this;
    }

    public withTag(tag: string): this {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        const attrName = 'Tags';
        this._filter.value.attributes[attrName] = [tag];
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public withAttributes(attrNames: string[]) {
        const attributes = this._filter.attributeNames.filter(_ => _.type === "attributes");
        attrNames.forEach(name => {
            const attribute = attributes.find(attr => attr.name === name);
            if (!attribute) {
                this._filter.attributeNames.push({ name: name, type: "attributes" });
            }
        });
        return this;
    }

    public withPortfolio(id: string): this {
        this._filter.value.portfolioId = [id];
        this._filter.attributeNames.push({ name: 'portfolioId', type: "portfolioId" });
        return this;
    }

    public withStatusesOfCategory(category: StatusCategory, programFields: Field[]): this {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        const attrName = nameof<ProgramAttrs>('OverallStatus');
        const projectStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Program, programFields);
        const categoryStatuses = projectStatusDescriptor?.getCategoryOptions(category).map(_ => _.name);
        this._filter.value.attributes[attrName] = categoryStatuses;
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public build(): IFilter<ProgramFilterValue> {
        return this._filter;
    }
}

export type FilterHelperProps = {
    fields: Field[];
    portfolios: Portfolio[];
    programs: Program[];
    layouts: Layout[];
}

export class FilterHelper implements IEntityFilterHelper<Program> {
    public getFilterAttributes = (fields: Field[]): FilterAttribute<ProgramFilterValue>[] => {
        const attrs = fields
            .filter(_ => !(_.isNative && _.name === nameof<ProgramAttrs>("Portfolio")))
            .map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<ProgramFilterValue>);
        attrs.push({ type: "portfolioId", name: "portfolioId", displayName: "Portfolio", value: undefined });
        attrs.push({ type: "percentComplete", name: "percentComplete", displayName: "% Complete", value: undefined });
        attrs.push({ type: "projectStatuses", name: "projectStatuses", displayName: "Project Statuses", value: undefined });
        attrs.push({ type: "linkedSystem", name: "linkedSystem", displayName: "Linked System", value: undefined });
        attrs.push({ type: "layoutId", name: "layoutId", displayName: "Layout", value: undefined });
        return attrs;
    }

    private attributes: IFilterHelper<ProgramFilterValue, Program> = {
        buildFilterElement: (attr: FilterAttribute<ProgramFilterValue>,
            filter: IFilter<ProgramFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const field: Field = attr.value;

            return DisplayFieldService.buildFieldMultiSelectInput(field,
                filter.value?.attributes?.[field.name],
                changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            const newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            const tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.fields, value),
        validateItem: (item: Program, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] === undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find(_ => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    const value = item.attributes[key];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    private linkedSystem: IFilterHelper<ProgramFilterValue, Program> = {
        buildFilterElement: (
            attr: FilterAttribute<ProgramFilterValue>,
            filter: IFilter<ProgramFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const types: SourceType[] = [];
            this._props.programs.forEach(program => {
                program.sourceInfos.forEach(sourceInfo => {
                    if (types.indexOf(sourceInfo.type) === -1) {
                        types.push(sourceInfo.type);
                    }
                });
            });

            const typeOptions: Option[] = types.map(_ => ({ key: _, text: SourceTypeMap[_] }));
            const selectedItems: Option[] = [];

            filter.value &&
                filter.value.linkedSystem &&
                filter.value.linkedSystem.forEach((id: string) => {
                    const opt = typeOptions.find(_ => _.key === id);
                    if (opt !== undefined) {
                        selectedItems.push(opt);
                    }
                });

            const itemsProps: Partial<OptionsPickerProps> = {
                onChange: (value?: Option[]) => {
                    onFilterEditComplete(attr.type,
                        "linkedSystem",
                        value && value.map(v => v.key));
                },
                onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                    let res: Option[] =
                        typeOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                    if (_selectedItems) {
                        res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                    }
                    return Promise.resolve(res);
                },
                selectedItems: selectedItems
            }

            return InputsService.buildOptionsPicker(itemsProps);
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            return [];
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            return value;
        },
        getAttributeValues: (value: any): string[] => {
            const values: string[] = [];
            (value as SourceType[]).forEach(system => {
                values.push(SourceTypeMap[system]);
            });
            return values;
        },
        validateItem: (item: Program, filterValue: any): boolean => {
            if (!filterValue || (<string[]>filterValue).length === 0) {
                return true;
            }
            return item.sourceInfos.map(_ => _.type).filter(_ => (filterValue as SourceType[]).indexOf(_) !== -1).length > 0;
        }
    }
    private percentComplete: IFilterHelper<ProgramFilterValue, Program> =
        {
            buildFilterElement: (
                attr: FilterAttribute<ProgramFilterValue>,
                filter: IFilter<ProgramFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const percentCompleteValue: { from: number, to: number } = filter.value.percentComplete || { from: 0, to: 0 };
                const propsFrom = {
                    key: "from-slider",
                    onEditComplete: (value: number) => {
                        const newValue = Object.assign({}, percentCompleteValue);
                        newValue.from = value;
                        if (newValue.from > newValue.to) {
                            newValue.to = newValue.from;
                        }
                        onFilterEditComplete(attr.type, "percentComplete", newValue);
                    },
                    value: percentCompleteValue.from,
                    min: 0,
                    max: 100,
                    step: 10,
                    label: "From"
                };

                const propsTo = {
                    key: "to-slider",
                    onEditComplete: (value: number) => {
                        const newValue = Object.assign({}, percentCompleteValue);
                        newValue.to = value;
                        if (newValue.to < newValue.from) {
                            newValue.from = newValue.to;
                        }
                        onFilterEditComplete(attr.type, "percentComplete", newValue);
                    },
                    value: percentCompleteValue.to,
                    min: 0,
                    max: 100,
                    step: 10,
                    label: "To"
                }
                return React.createElement("div",
                    undefined,
                    [InputsService.buildSlider(propsFrom), InputsService.buildSlider(propsTo)]);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return undefined;
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                if (value) {
                    return [value.from.toString(), value.to.toString()];
                }

                return [];
            },
            validateItem: (item: Program, filterValue: any): boolean => {
                if (!filterValue) {
                    return true;
                }

                const { total, completed } = ProgramUtils.getProjectsMetrics(item.calculation);
                const percentCompleteAttr = total ? completed / total * HUNDRED_PCT : 0;
                const { from, to } = filterValue;

                return from <= percentCompleteAttr && to >= percentCompleteAttr;
            }
        }
    private projectStatuses: IFilterHelper<ProgramFilterValue, Program> =
        {
            buildFilterElement: (
                attr: FilterAttribute<ProgramFilterValue>,
                filter: IFilter<ProgramFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const statusOptions: Option[] = Object.keys(StatusCategory)
                    .filter(_ => !Number.isNaN(Number(_)))
                    .map(_ => ({ key: _, text: statusCategoryMap[_].title }));

                const selectedItems: Option[] = [];

                const value = <string[]>filter.value.projectStatuses || [];

                value.forEach(_ => {
                    const opt = statusOptions.find(__ => __.key === _);
                    if (opt !== undefined) {
                        selectedItems.push(opt);
                    }
                });

                const inputProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "projectStatuses",
                            opts ? opts.map(_ => _.key) : undefined);
                    },
                    onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                        let res: Option[] =
                            statusOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                        if (_selectedItems) {
                            res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selectedItems
                }
                return InputsService.buildOptionsPicker(inputProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                return value ? (<number[]>value).map((_: number) => statusCategoryMap[_].title) : [];
            },
            validateItem: (item: Program, filterValue: any): boolean => {
                const value = <number[]>filterValue || [];
                if (value.length === 0) {
                    return true;
                }
                for (const val of value) {
                    const color = StatusCategory[val];
                    if (item.calculation.projectStatuses[color]) {
                        return true;
                    }
                }

                return false;
            }
        }
    private portfolioId: IFilterHelper<ProgramFilterValue, Program> =
        {
            buildFilterElement: (attr: FilterAttribute<ProgramFilterValue>,
                filter: IFilter<ProgramFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const portfolioOptions: Option[] = this._props.portfolios.map(_ => ({ key: _.id, text: _.attributes.Name }));
                const selected: Option[] = [];

                filter.value?.portfolioId?.forEach((id: string) => {
                    const opt = portfolioOptions.find(_ => _.key === id);
                    if (opt !== undefined) {
                        selected.push(opt);
                    }
                });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "portfolioId",
                            opts ? opts.map(_ => _.key) : undefined);
                    },
                    onResolveSuggestions: (_filter: string, selectedItems?: Option[]) => {
                        let res: Option[] =
                            portfolioOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                        if (selectedItems) {
                            res = res.filter((opt: Option) => selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selected
                };

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                const values: string[] = [];
                const array: string[] = value;
                array.forEach(id => {
                    const portfolio = this._props.portfolios.find(_ => _.id === id);
                    if (portfolio) {
                        values.push(portfolio.attributes.Name);
                    }
                });
                return values;
            },
            validateItem: (item: Program, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }

                const portfolioIds = filterValue as string[];
                return item.attributes.Portfolio.map(_ => _.id).filter(_ => portfolioIds.indexOf(_) !== -1).length > 0;
            }
        }
    private layoutId = buildLayoutFilterHelper<ProgramFilterValue, Program>(() => this._props.layouts)

    private readonly _props: FilterHelperProps
    constructor(props: FilterHelperProps) {
        this._props = props;
    }

    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name).withAttributes(namesof<ProgramAttrs>(["OverallStatus", "Manager"]));
    public helpersMap: { [K: string]: IFilterHelper<BaseFilterValue, Program> } =
        {
            "attributes": this.attributes,
            "percentComplete": this.percentComplete,
            "projectStatuses": this.projectStatuses,
            "portfolioId": this.portfolioId,
            "linkedSystem": this.linkedSystem,
            "layoutId": this.layoutId
        }
}
