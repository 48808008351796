import { Action, Reducer } from 'redux';
import { get, post, remove } from "../fetch-interceptor";
import { AppThunkAction } from "./";
import { Field, FieldInfo, IUpdateFieldInfo, isFullEntityRefreshRequired } from "../entities/Metadata";
import { IEntityStore, StoreHelper } from "./services/storeHelper";
import { defaultCatch } from "./utils";
import { ActionsBuilder } from './services/metadataService';

export interface FieldsState extends IEntityStore<Field> {
    isLoading: boolean;
    fieldCreationInfo?: FieldCreationInfo;
}

export interface FieldCreationInfo {
    entity?: string;
    createdFieldId?: string;
    isProcessing: boolean;
    error: string | null;
}

type LoadFieldsAction = {
    type: "LOAD_FIELDS";
    entity: string;
};

type FieldsLoadedAction = {
    type: "FIELDS_LOADED";
    entity: string;
    fields: Field[];
};

type CreateFieldAction = {
    type: "CREATE_FIELD";
    entity: string;
    field: Field;
};

type CreateFieldSuccessAction = {
    type: "CREATE_FIELD_SUCCESS";
    entity: string;
    field: Field;
};

type DeleteFieldSuccessAction = {
    type: "DELETE_FIELD_SUCCESS";
    entity: string;
    fieldId: string;
};

type KnownAction =
    | LoadFieldsAction
    | FieldsLoadedAction
    | CreateFieldSuccessAction
    | CreateFieldAction
    | DeleteFieldSuccessAction;

export const actionCreators = {
    forEntity: (entity: string, entityId?: string, refreshEntity?: () => void) => ({
        saveField: (fieldInfo: FieldInfo, callback?: (newField: Field) => void) => ActionsBuilder.buildCreateField(entity,
            (field, dispatch) => {
                dispatch({ type: 'CREATE_FIELD_SUCCESS', entity: entity, field: field });
                callback?.(field);
            },
            (dispatch) => dispatch({ type: 'CREATE_FIELD', entity: entity }))(fieldInfo),
        updateField: (fieldId: string, fieldInfo: IUpdateFieldInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Field[]>(`api/metadata/${entity}/field/${fieldId}`, { openedEntityId: entityId, fieldInfo })
                .then(_ => {
                    dispatch({ type: 'FIELDS_LOADED', entity, fields: _ });
                    if (isFullEntityRefreshRequired(fieldInfo)) {
                        refreshEntity?.();
                    }
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: 'LOAD_FIELDS', entity });
        },
        removeField: (fieldId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
            remove<void>(`api/metadata/${entity}/field/${fieldId}`)
                .then(_ => {
                    dispatch({ type: 'DELETE_FIELD_SUCCESS', entity, fieldId });
                    refreshEntity?.();
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: 'LOAD_FIELDS', entity });
        },
        loadFields: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
            get<Field[]>(`api/metadata/${entity}/fields`)
                .then(_ => dispatch({ type: 'FIELDS_LOADED', entity, fields: _ }))
                .catch(defaultCatch(dispatch));

            dispatch({ type: 'LOAD_FIELDS', entity });
        }
    })
}

export const reducer: Reducer<FieldsState> = (state: FieldsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_FIELDS':
            return {
                ...state,
                isLoading: true
            }
        case 'FIELDS_LOADED':
            return {
                ...state,
                ...StoreHelper.create(action.fields),
                isLoading: false
            };
        case 'CREATE_FIELD':
            return {
                ...state,
                isLoading: true,
                fieldCreationInfo: { createdFieldId: undefined, isProcessing: true, error: null }
            }
        case 'CREATE_FIELD_SUCCESS':
            {
                let newState = {
                    ...state,
                    isLoading: false,
                    fieldCreationInfo: { entity: action.entity, createdFieldId: action.field.id, isProcessing: false, error: null }
                };
                StoreHelper.addOrUpdate(newState, action.field);
                return newState;
            }
        case 'DELETE_FIELD_SUCCESS':
            {
                let newState = {
                    ...state,
                    isLoading: false
                };
                StoreHelper.remove(newState, action.fieldId);
                return newState;
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}
