import * as React from 'react';
import './StyleSettings.css';
import CustomLogoSection from './CustomLogoSection';
import StyleSection from './StyleSection';

const StyleSettings = () => (
    <div className='style-settings sync-settings'>
        <CustomLogoSection />
        <StyleSection />
    </div>
)

export default StyleSettings;
