import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';
import Link from '../common/Link';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { CommonOperations, contains } from '../../store/permissions';

export class NotAllowedMessage extends React.Component<{ connector: SourceType, permissions: CommonOperations }> {
    public render() {
        const { connector, permissions } = this.props;
        return <MessageBar
            messageBarType={MessageBarType.info}>{SourceType_.getName(connector)} linking is not allowed by your{contains(permissions, CommonOperations.BillingManage)
                ? <Link href="/billing">license plan</Link>
                : <span>license plan</span>}</MessageBar>
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        permissions: state.user.permissions.common
    }
}

export default connect(mapStateToProps, {})(NotAllowedMessage);