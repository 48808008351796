import React from "react";
import { bindActionCreators } from 'redux';
import { Field, Section } from '../../entities/Metadata';
import { DetailsSpinner } from "../common/Spinner";
import { SectionsContainer } from "../common/sectionsControl/SectionsContainer";
import { DueDateGroup, Groups, _buildFields } from "../../store/MyWorkStore";
import { connect } from "react-redux";
import { Dictionary, EntityType } from "../../entities/common";
import { ViewTypeViews, buildViewTypeSelect } from "../common/ViewTypeSelect";
import { ApplicationState } from "../../store/index";
import { actionCreators, Resource } from '../../store/ResourcesListStore';
import { RouteComponentProps } from "react-router-dom";
import * as WorkloadControl from "../common/sectionsControl/uiControls/WorkloadControl";
import { arraysEqual } from "office-ui-fabric-react";
import * as FiltersStore from "../../store/filters";
import { IControlConfiguration } from "../common/interfaces/ISectionUIControlProps";
import ResourceHeader from "../resource/ResourceHeader";
import * as ResourceWorkloadControl from "../common/sectionsControl/uiControls/ResourceWorkloadControl";

enum UIControls {
    Summary = "ResourceWorkloadControl",
    Workload = "WorkloadControl"
}

const summarySectionSettings: ResourceWorkloadControl.ISettings = {
    showDashboardNavButton: true
};

const workloadControlSettings: WorkloadControl.IWorkloadControlSettings = {
    defaultFilterName: "All",
    defaultPreFilterKey: DueDateGroup.ThisMonth,
    defaultGroupping: Groups.Parent,
    isMyAssignments: false
};

const sections: Section[] = [
    {
        id: "032950f2-796b-49fa-b5f9-aaab671bc9ee",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "Summary",
        settings: { "iconName": "PPMXSectionSummary" },
        uiControls: [{
            id: "9614b177-97b5-4aea-b220-fef09e1ce4cf",
            type: UIControls.Summary,
            settings: summarySectionSettings
        }]
    },
    {
        id: "757173e9-bb79-4274-a52b-122df42693b7",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "Assignments",
        settings: { "iconName": "AccountActivity" },
        uiControls: [{
            id: "90bcf85b-c300-4abd-9e75-73fcac9646e8",
            type: UIControls.Workload,
            settings: workloadControlSettings
        }]
    }
];

type StateProps = {
    entity?: Resource;
    isLoading: boolean;
}
type ActionProps = {
    resourceActions: typeof actionCreators;
}
type OwnProps = {}
type Props = StateProps & ActionProps & OwnProps & RouteComponentProps<{ id: string }>;

type State = {
    fields: Field[];
}

class ResourceWorkload extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { fields: [] }
    }

    componentWillMount() {
        if (!this.props.entity?.work || this.props.entity.id !== this.props.match.params.id) {
            this.props.resourceActions.loadResource(this.props.match.params.id);
        } else if (this.props.entity?.work) {
            this.setState(_buildFields(this.props.entity.work));
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.entity?.work && (!this.props.entity?.work || !arraysEqual(this.props.entity?.work, nextProps.entity?.work))) {
            this.setState(_buildFields(nextProps.entity.work));
        }
    }

    public render() {
        const { isLoading, entity } = this.props;

        return <DetailsSpinner isLoading={isLoading}>
            {entity && <ResourceHeader
                entity={entity}
                actions={{}}
            />}
            {entity?.work && <SectionsContainer
                key='sections'
                hideSideBar
                entity={entity}
                getSections={() => sections}
                controlsConfig={this._buildControlsConfigurations()}
            />}
        </DetailsSpinner>;
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { fields } = this.state;
        return {
            [UIControls.Summary]: {
                datacontext: {},
                actions: {}
            },
            [UIControls.Workload]: {
                ...buildViewTypeSelect(ViewTypeViews.Workload),
                datacontext:  { fields },
                actions: {
                    onMyWorkUpdated: () => { this.props.resourceActions.loadResource(this.props.match.params.id) }
                }
            }
        }
    }
}

interface IConfiguration extends Dictionary<IControlConfiguration> {
    [UIControls.Summary]: {};
    [UIControls.Workload]: WorkloadControl.IConfiguration;
}

function mapStateToProps(state: ApplicationState, ownProp: RouteComponentProps<{ id: string }>) {
    const filters = FiltersStore.getFilter(state.filters, EntityType.MyWork);
    const entity = state.resources.activeEntity && state.resources.activeEntity.id === ownProp.match.params.id
        ? state.resources.activeEntity
        : undefined;
    return {
        filters: filters.all,
        activeFilter: filters.active.filter,
        entity,
        isLoading: state.resources.isLoading
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        resourceActions: bindActionCreators(actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ResourceWorkload);