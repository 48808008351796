import * as React from 'react';
import { IListViewColumn } from "../../../../../services/ViewService";
import { StrategicPriority } from '../../../../../store/StrategicPrioritiesListStore';

const IsActiveField = (props: IListViewColumn<StrategicPriority>) => <IsActive active={props.entity.isActive} />
export default IsActiveField;

export const IsActive = (props: { active: boolean }) => <div className="strategic-priority-isactive">
    <div className={`align-center isactive ${props.active ? "Active" : "Inactive"}`}>
        {props.active ? "Active" : "Inactive"}
    </div>
</div>;