import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from "../../../store/integration/MondayComStore";
import { ApplicationState } from "../../../store/index";
import { IConnectionsState } from "../../../store/integration/common";
import MondayComConnectionEdit from './MondayComConnectionEdit';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import BasicAuthConnectionsList from '../BasicAuthConnectionsList';
import { IConnectionInfo } from '../../../entities/Metadata';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onSelect: (connection: IConnectionInfo) => void;
};

type StateProps = { connections: IConnectionsState };
type Props = StateProps & typeof actionCreators & OwnProps;

const MondayComConnectionsList = (props: Props) => {
    React.useEffect(() => {
        props.loadConnections();
    }, []);
    return <BasicAuthConnectionsList
        disabled={props.connections.isLoading || !!props.disabled}
        sourceType={SourceType.MondayCom}
        connections={props.connections.data}
        onConnectionSelect={props.onSelect}
        onConnectionRemove={props.removeConnection}
        onConnectionRename={props.renameConnection}
        renderAdd={(onCreated: (connectionId: string) => void, onDismiss: () => void) =>
            <MondayComConnectionEdit onCreated={onCreated} onDismiss={onDismiss} />}
        renderEdit={(connectionId: string, onDismiss: () => void) =>
            <MondayComConnectionEdit connectionId={connectionId} onDismiss={onDismiss} />}
    />;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.mondayCom.connections };
}

export default connect(mapStateToProps, actionCreators)(MondayComConnectionsList);
