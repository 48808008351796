import "./LinkBox.css";
import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NotEnoughPermissionsMessage } from "../../../store/permissions";

type OwnProps = {
    iconName: string,
    text: string,
    big?: boolean,
    fixed?: boolean,
    disabled?: boolean,
    href?: string;
    path?: string;
    action?: () => void;
}

type Props = OwnProps & RouteComponentProps<{}>

const LinkBox = (props: Props) => <div
    className={`box link-box ${props.disabled ? 'is-disabled' : ''} ${props.big ? 'big' : ''} ${props.fixed ? 'fixed' : ''}`}
    title={props.disabled ? NotEnoughPermissionsMessage : undefined}
    onClick={props.disabled
        ? undefined
        : (e) => {
            if (props.action) {
                props.action();
            }
            else if (props.path && !e.ctrlKey) {
                props.history.push(props.path);
            } else {
                window.open(props.href ?? props.path, '_blank');
            }
        }}>
    <div className="text">{props.text}</div>
    <Icon iconName={props.iconName} />
</div>
export default withRouter<OwnProps>(LinkBox);