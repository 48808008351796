import React from 'react';
import { Icon } from "office-ui-fabric-react"
import './AiLogo.css'

const AiLogo = () => 
    <div className="ai-asistant">
        <div className="ai-robot">
            <Icon iconName="D365CustomerInsights" />
            <span>PPM Insights AI</span>
            <span className="beta">BETA</span>
        </div>
    </div>

export default AiLogo;