import * as React from 'react';
import { RefObject } from 'react';

export const useDidMountEffect = (effect: React.EffectCallback, deps: React.DependencyList) => {
    const didMount = useDidMount();
    React.useEffect(() => {
        if (didMount) {
            effect();
        }
    }, deps);
};

export const useDidMount = () => {
    const didMount = React.useRef(false);
    React.useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
        }
    }, []);
    return didMount.current;
};

export const usePrevious = <T>(value: T) => {
    const ref = React.useRef<T>();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useDebounce = <TFunc extends (...args: any[]) => void>(callback: TFunc | undefined, delay: number) => {
    const argsRef = React.useRef<Parameters<TFunc>>();
    const timeout = React.useRef<ReturnType<typeof setTimeout>>();
    const callbackRef = React.useRef(callback);

    const clear = () => { 
        timeout.current && clearTimeout(timeout.current) 
    };

    React.useEffect(() => clear, []);
    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    return React.useCallback((...args: Parameters<TFunc>) => {
        argsRef.current = args;

        clear();

        timeout.current = setTimeout(() => argsRef.current && callbackRef.current?.(...argsRef.current), delay);
    }, []);
};

export const useEventListener = <
    KH extends keyof HTMLElementEventMap
>(
    eventName: keyof HTMLElementEventMap,
    handler: (event: HTMLElementEventMap[KH]) => void,
    options?: boolean | AddEventListenerOptions,
) => {
    const savedHandler = React.useRef(handler)

    React.useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    React.useEffect(() => {
        const listener: typeof handler = event => savedHandler.current(event);
        document.addEventListener(eventName, listener, options);
        return () => {
            document.removeEventListener(eventName, listener, options)
        }
    }, [eventName, options])
}

export const useIsOpen = (initialState: boolean): [boolean, () => void, () => void, (isOopen: boolean) => void] => {
    const [isOpen, setIsOpen] = React.useState(initialState);
    const onOpen = React.useCallback(() => setIsOpen(true), []);
    const onClose = React.useCallback(() => setIsOpen(false), []);
    return [isOpen, onOpen, onClose, setIsOpen];
}

export const useResizeObject = <T extends HTMLElement>(onResize: (offsetWidth: number, offsetHeight: number) => void): RefObject<T> => {
    const ref = React.useRef<T>(null);
    React.useLayoutEffect(() => {

        const resizeObserver = new ResizeObserver((entries) => {
            if (ref.current) {
                onResize(ref.current.offsetWidth, ref.current.offsetHeight);
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
            onResize(ref.current.offsetWidth, ref.current.offsetHeight);
        }

        return () => resizeObserver.disconnect();
    }, []);
    return ref;
}

export const useEffectTillTrue = (callback: () => boolean | undefined, deps: React.DependencyList) => {
    const wasSuccessfullyExecuted = React.useRef(false);
    React.useEffect(() => {
        if (wasSuccessfullyExecuted.current) {
            return;
        }

        wasSuccessfullyExecuted.current = callback() === true;
    }, [deps]);
}

export const useLoader = (ids: string[], loader: (ids: string[]) => void) => {
    const loaded = usePrevious(ids) || [];
    const notLoaded = ids.filter(_ => !~loaded.indexOf(_));
    React.useEffect(() => {
        if (notLoaded.length) {
            loader(notLoaded);
        }
    }, [notLoaded]);
}

const isClassContainsInParents = (element: HTMLElement | null, classnames: string[]): boolean => {
    if (!element) {
        return false;
    }
    
    if (classnames.some(_ => element.classList.contains(_))) {
        return true;
    }
    return isClassContainsInParents(element.parentElement, classnames);
};

const useOuterClickIgnoreClasses: string[] = ["ms-Callout-container", "ms-DatePicker-monthPicker", "ms-DatePicker-yearPicker", "ms-PickerPersona-container", "ms-BasePicker"];

export const useOuterClick = <T extends HTMLElement>(callback: (ev: MouseEvent) => void, extraClasses?: string[]) => {
    const callbackRef = React.useRef<(ev: MouseEvent) => void>();
    const innerRef = React.useRef<T>(null);
    React.useEffect(() => { callbackRef.current = callback; }, [callback]);

    React.useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => { document.removeEventListener("click", handleClick); }
        function handleClick(e: MouseEvent) {
            if (isClassContainsInParents(e.target as HTMLElement, useOuterClickIgnoreClasses.concat(extraClasses ?? []))) {
                return;
            }
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target as Node)) {
                callbackRef.current(e);
            }
        }
    }, []);

    return innerRef;
}