import "./MoreResources.css";
import * as React from "react";
import ResourceCard from './ResourceCard';

type LetsStartAction = {
    name: string;
    iconName: string;
    href: string;
}

const actions = [
    {
        name: 'Documentation',
        iconName: 'PPMXQuickStartDocumentation',
        href: 'https://help.ppm.express/89154-ppm-express'
    }, {
        name: 'Blogs',
        iconName: 'PPMXQuickStartBlogs',
        href: 'https://ppm.express/articles/'
    }, {
        name: 'Videos',
        iconName: 'PPMXQuickStartVideos',
        href: 'https://www.youtube.com/c/PPMExpress'
    }, {
        name: 'Webinars',
        iconName: 'PPMXQuickStartWebinars',
        href: 'https://ppm.express/webinars/'
    },
]

const MoreResources = () => <div className="box more-resources">
    <h2>More Resources</h2>
    <div className="more-resources-list">
        {
            actions.map((_: LetsStartAction, i: number) => <ResourceCard key={i}
                iconName={_.iconName}
                name={_.name}
                href={_.href}
            />)
        }
    </div>
</div>
export default MoreResources;