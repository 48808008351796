import * as React from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ViewTypes } from "../../entities/Metadata";
import * as Notifications from "../../store/NotificationsStore";
import html2canvas from 'html2canvas';
import fileDownload from 'js-file-download';
import { ConfirmationDialog } from './ConfirmationDialog';
import { Overlay } from 'office-ui-fabric-react';
import Spinner from './Spinner';
import { PngExportControlDetails, actionCreators as PngExportActionCreators } from '../../store/PngExporterStore';
import { MenuTitleBuilder } from '../MenuTitleBuilder';

type OwnProps = { details?: PngExportControlDetails, getConfig: () => PngExportConfig };
type ActionProps = {
    pngExporterActions: typeof PngExportActionCreators,
    notificationsActions: typeof Notifications.actionCreators
}

type Props = OwnProps & ActionProps;
class PngExporter extends React.Component<Props> {
    render() {
        const { details, getConfig } = this.props;
        const config = getConfig();

        return <>
            <PngExportedContent
                config={config}
                isInProgress={this.props.details?.isInProgress}
                onExportSuccess={this._finishExport}
                onExportFail={this._onExportFail}>
                {this.props.children}
            </PngExportedContent>
            {details?.disabledWithWarning &&
                <ConfirmationDialog
                    onDismiss={this._finishExport}
                    onYes={this._finishExport}
                    yesButtonProps={{ text: "Got it" }}
                    dialogContentProps={{
                        title: 'Export disabled',
                        subText: `The exported element contains ${config.rowsCount} rows and exceeds the limit of ${maxListRowsCount} rows. Please apply additional filters.`
                    }} />}
            {details?.isInProgress &&
                <Overlay styles={{ root: { position: "fixed", zIndex: 3 } }}><Spinner /></Overlay>}
        </>;
    }

    private _finishExport = () => this.props.pngExporterActions.finishExport(this.props.getConfig().controlId);
    private _onExportFail = (reason?: string) => {
        this._finishExport();
        this.props.notificationsActions.pushNotification({ type: Notifications.NotificationType.Error, message: reason || "Failed to export to PNG" });
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        pngExporterActions: bindActionCreators(PngExportActionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch)
    }
}

export default connect(undefined, mergeActionCreators)(PngExporter);

type ContentProps = {
    isInProgress?: boolean,
    config: PngExportConfig, 
    onExportSuccess: () => void,
    onExportFail: (reason?: string) => void
}
class PngExportedContent extends React.Component<ContentProps> {
    render() {
        return this.props.children;
    }

    componentDidUpdate(prevProps: ContentProps) {
        if (!prevProps.isInProgress && this.props.isInProgress) {
            this._exportNodeToPng();
        }
    }

    private _exportNodeToPng = () => {
        const { activeView, elementSelector, name } = this.props.config;
        const selector = activeView === ViewTypes.timeline
            ? '.TimelineList .table-view .chart-table'
            : activeView === ViewTypes.list
                ? elementSelector
                : null;

        let node = ReactDOM.findDOMNode(this) as HTMLElement;
        if (selector) {
            node = node?.querySelector(selector) as HTMLElement;
        }
        if (!node) {
            this.props.onExportSuccess();
            return;
        }

        //todo clone element and render in separate element with separate print css styles and after export result
        waitImagesLoading(node).finally(() => {
            const fileName = `${name}_${activeView}`;
            printToFile(node, fileName, this._updateCss).then(this.props.onExportSuccess, this.props.onExportFail);
        });
    }

    private _updateCss = (node: HTMLElement): void => {
        const { activeView, scrollableElementSelector } = this.props.config;
        if (activeView === ViewTypes.timeline) {
            node.className += " png-export-flag";
            //for some reason style .png-export-flag .timeline-relation{width: 100%; height: unset; margin-top: -8px; position: unset;} not working
            node.querySelectorAll(".timeline-relation").forEach(_ => {
                const element = _ as HTMLElement;
                element.style.width = "100%";
                element.style.height = `${node.scrollHeight}px`;
                element.style.top = "0";
            });
            const selector = node.querySelector(".quantization-selector") as HTMLElement;
            if (selector) {
                selector.style.position = "unset";
            }
        } else if (activeView === ViewTypes.list) {
            const scrollableContainer = scrollableElementSelector ? node.querySelector(scrollableElementSelector) as HTMLElement : node;

            if (scrollableContainer) {
                node.style.width = `${scrollableContainer.scrollWidth}px`;
                node.style.height = `${scrollableContainer.scrollHeight}px`;
            }
        }
    }
}

const maxListRowsCount = 300;
export type PngExportConfig = { name: string, controlId: string, activeView?: ViewTypes, scrollableElementSelector?: string, elementSelector?: string, rowsCount: number };
export const buildExportToPngMenuItem = (getConfig: () => PngExportConfig, actions: typeof PngExportActionCreators, disabled: boolean) => {
    return {
        key: 'screenshot',
        text: 'Export to PNG',
        title: MenuTitleBuilder.exportToPngTitle(getConfig().name),
        iconProps: { iconName: 'Photo2' },
        disabled,
        onClick: () => {
            const { controlId, rowsCount } = getConfig();
            actions.startExport(controlId, rowsCount, maxListRowsCount);
        }
    };
}

const MAX_CANVAS_SIZE = 268435456; //16Kpx*16Kpx
function printToFile(node: HTMLElement, fileName: string, onClone?: (e: HTMLElement) => void): Promise<void> {
    if (node.scrollWidth * node.scrollHeight > MAX_CANVAS_SIZE) {
        return Promise.reject(`Unable to export element with sizes ${node.scrollWidth}*${node.scrollHeight} because it is exceed max size`);
    }
    const saveBlob = (blob: Blob) => {
        if (blob !== null) {
            fileDownload(blob, `${fileName}.png`);
        }
    }

    return html2canvas(node, { onclone: (d, e) => onClone?.(e) }).then(canvas => canvas.toBlob(saveBlob, "image/png", 1));
}

function isImagesLoaded(node: HTMLElement): Promise<void>{
    const images = node.getElementsByTagName("img");    
    for (let i = 0; i < images.length; i++) {
        if (!images[i].complete || (images[i].classList.contains("ms-Image-image") && images[i].classList.contains("is-notLoaded"))) {
            return Promise.reject();
        }
    }
    return Promise.resolve();
}

function waitImagesLoading(node: HTMLElement, retriesLeft = 20, interval = 500): Promise<void> {
    return new Promise((resolve, reject) => {
        return isImagesLoaded(node)
            .then(resolve)
            .catch((error) => {
                if (retriesLeft === 1) {
                    reject(error)
                    return;
                }

                setTimeout(() => {
                    waitImagesLoading(node, retriesLeft - 1, interval).then(resolve, reject)
                }, interval)
            })
    })
}