import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { contains, CommonOperations, ResourceOperations, IPermissions, LicenseType } from "../../store/permissions";
import { Checkbox } from 'office-ui-fabric-react';
import { Subscription, PPMFeatures, TimeTrackingGlobalSettings } from '../../store/Tenant';
import Collapsible from '../common/Collapsible';
import { notUndefined } from '../utils/common';

export type OperationArg = {
    operation?: CommonOperations;
    objectives?: ObjectivesAccessLevel;
};
export enum ObjectivesAccessLevel {
    Manage = 'manage',
    View = 'view',
}
type OwnProps = {
    disabled?: (arg: OperationArg) => boolean | undefined;
    indeterminate?: (arg: OperationArg) => boolean | undefined;
    checked: (arg: OperationArg) => boolean;
    onChange: (arg: OperationArg, checked: boolean | undefined) => void;
    licenseType: LicenseType;
};
type StateProps = {
    subscription: Subscription;
    timeTrackingSettings: TimeTrackingGlobalSettings;
};

type Props = OwnProps & StateProps;

const CommonPermissionsImpl = (props: Props) => {
    const { subscription, disabled, indeterminate, checked, onChange, licenseType, timeTrackingSettings } = props;
    const viewObjectivesOption = Subscription.contains(subscription, PPMFeatures.OKR)
        ? { label: licenseType === LicenseType.Viewer ? "View objectives" : "Collaborate on objectives", arg: { objectives: ObjectivesAccessLevel.View } }
        : undefined;
    const perms: { label: string, arg: OperationArg }[] = licenseType === LicenseType.Regular
        ? [
            { label: "Administrate app", arg: { operation: CommonOperations.Administrate, objectives: ObjectivesAccessLevel.Manage } },
            { label: "Manage resources", arg: { operation: CommonOperations.ResourceManage } },
            Subscription.contains(subscription, PPMFeatures.Prioritization) ? { label: "Manage prioritization", arg: { operation: CommonOperations.PrioritizationManage } } : undefined,
            Subscription.contains(subscription, PPMFeatures.OKR) ? { label: "Manage objectives", arg: { objectives: ObjectivesAccessLevel.Manage } } : undefined,
            viewObjectivesOption,
            { label: "Manage financial details", arg: { operation: CommonOperations.BudgetManage } },
            { label: "Manage configuration", arg: { operation: CommonOperations.ConfigurationManage } },
            { label: "Manage billing", arg: { operation: CommonOperations.BillingManage } },
            { label: "Manage integrations", arg: { operation: CommonOperations.ConnectionManage } },
            Subscription.contains(subscription, PPMFeatures.Insights) ? { label: "PPM Insights", arg: { operation: CommonOperations.InsightsView } } : undefined,
            Subscription.contains(subscription, PPMFeatures.TimeTracking) && timeTrackingSettings.newTimeTrackingEnabled
                ? { label: "Manage Time Tracking", arg: { operation: CommonOperations.TimeTrackingManage  } }
                : undefined
        ].filter(notUndefined)
        : [
            viewObjectivesOption
        ].filter(notUndefined);

    if (!perms.length) {
        return null;
    }

    return <Collapsible
        expanded
        iconName="CheckList"
        title="General">
        <div>
            {perms
                .map(_ => <Checkbox
                    key={_.label}
                    label={_.label}
                    disabled={disabled?.(_.arg)}
                    indeterminate={indeterminate?.(_.arg)}
                    checked={checked(_.arg)}
                    onChange={(e, c) => onChange(_.arg, c)}
                />)}
        </div>
    </Collapsible>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        timeTrackingSettings: state.tenant.timeTracking.globalSettings
    }
}
export const CommonPermissions = connect(mapStateToProps)(CommonPermissionsImpl);

export function isCommonPermissionChecked(arg: OperationArg, permissions: IPermissions) {
    if (arg.objectives === ObjectivesAccessLevel.Manage && arg.operation === undefined) {
        return contains(permissions.objective.global, ResourceOperations.Update);
    }
    if (arg.objectives === ObjectivesAccessLevel.View && arg.operation === undefined) {
        return contains(permissions.objective.global, ResourceOperations.Read);
    }

    return contains(permissions.common, arg.operation);
}