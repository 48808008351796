import * as React from 'react';
import ConnectionsList from "./ConnectionsList";
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { IConnectionInfo } from '../../entities/Metadata';
import PostMessageReceiver, { PostMessageData } from './PostMessageReceiver';

type Props = {
    disabled: boolean;
    sourceType: SourceType,
    connections: IConnectionInfo[];
    onConnectionSelect: (connection: IConnectionInfo) => void;
    onConnectionRemove: (connectionId: string) => void;
    onConnectionRename: (connectionId: string, title: string) => void;
    onReceivedConnectionData: (data: PostMessageData) => void;
    grantPermissionsText?: string;
    onGrantPermissionsClick?: () => void;

    newConnectionUrl: string;
    newConnectionTooltip?: JSX.Element;
    newRestrictedConnectionUrl?: string;
    newRestrictedConnectionTooltip?: JSX.Element;
    newAzureADConnectionUrl?: string;
    newAzureADConnectionTooltip?: JSX.Element;

    getRefreshConnectionUrl: (connectionId: string) => string;
    getUpgradeConnectionUrl?: (connectionId: string) => string | undefined;
    grantPermissionsUrl?: string;
}

const OAuthConnectionsList = (props: Props) => {

    const { newConnectionUrl, newRestrictedConnectionUrl, newAzureADConnectionUrl, grantPermissionsUrl } = props;
    const target = `connectionDataReceived_${props.sourceType}`;

    const _refreshConnectionUrlClick = (connectionId: string) => {
        const refreshConnectionUrl = props.getRefreshConnectionUrl(connectionId);
        PostMessageReceiver.openPopupWindow(refreshConnectionUrl, target);
    }

    const _upgradeConnectionUrlClick = (connectionId: string) => {
        const upgradeConnectionUrl = props.getUpgradeConnectionUrl?.(connectionId);
        if (upgradeConnectionUrl) {
            PostMessageReceiver.openPopupWindow(upgradeConnectionUrl, target);
        }
    }

    return <>
        <PostMessageReceiver<PostMessageData>
            from={target}
            onMessageReceived={(_, data) => {
                props.onReceivedConnectionData(data);
                const connection = props.connections.find(_ => _.id == data.context?.connectionId);
                connection && props.onConnectionSelect(connection);
            }} />
        <ConnectionsList
            {...props}
            onNewConnectionClick={() => PostMessageReceiver.openPopupWindow(newConnectionUrl, target)}
            onNewRestrictedConnectionClick={newRestrictedConnectionUrl ? () => PostMessageReceiver.openPopupWindow(newRestrictedConnectionUrl, target) : undefined}
            onNewAzureADConnectionClick={newAzureADConnectionUrl ? () => PostMessageReceiver.openPopupWindow(newAzureADConnectionUrl, target) : undefined}
            onEditClick={(connectionId) => _refreshConnectionUrlClick(connectionId)}
            hasUpgradePremissions={(connectionId) => !!props.getUpgradeConnectionUrl?.(connectionId)}
            onUpgradePremissionsClick={(connectionId) => _upgradeConnectionUrlClick(connectionId)}
            onGrantPermissionsClick={props.grantPermissionsUrl ? () => PostMessageReceiver.openPopupWindow(grantPermissionsUrl!, target) : undefined}
        />
    </>;
}

export default OAuthConnectionsList;
