import * as React from "react";

const Zapier = () => <zapier-full-experience
    client-id="EVBkotHPmlygIr6phyDv9N3WggQS54K2LAuNkSMy"
    theme="light"
    app-search-bar-display="show"
    app-limit="4"
    zap-call-to-action-display="show"
    zap-create-from-scratch-display="hide"
/>

export default Zapier;
declare global {
    namespace JSX {
      interface IntrinsicElements {
        "zapier-full-experience": any;
      }
    }
  }