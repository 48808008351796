import * as React from 'react';
import { Spinner, SpinnerSize, TextField, DefaultButton, Link } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { ApplicationState, SignUpPlan } from '../../store';
import { TenantState, Tenant, actionCreators, ProductType } from '../../store/Tenant';
import { UserState } from '../../store/User';
import { Validator } from '../../validation';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getCookie, inIframe } from '../utils/common';
import { SignUpPlans } from './SignUpPlans'
import { SignUpParams } from '../../LoginPage';
import { DateTime } from 'luxon';

const getCurrentBrowserLocale = () => window.navigator.language || (window.navigator as any).userLanguage; //(userLanguage - IE nail)

type OwnProps = {};
type StateProps = {
    user: UserState;
    tenant: TenantState;
    plans: SignUpPlan[];
};
type Props = StateProps & typeof actionCreators & OwnProps & RouteComponentProps<{}>;

type State = {
    tenant: Tenant;

    selectedProductType?: ProductType;
    disableReferralCode: boolean;
    isLocked: boolean;
}

const validator: { [key: string]: Validator } = {
    companyName: Validator.new().required().build(),
    companyWebSite: Validator.new().url().build(),
    personName: Validator.new().required().build(),
    personEmail: Validator.new().required().email().build(),
    personPhoneNumber: Validator.new().required().phone().build(),
}

class TenantSetup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const queryParams = new URLSearchParams(this.props.location.search);
        const planKey = queryParams.get(SignUpParams.Plan) || getCookie(SignUpParams.Plan);
        const isLocked = (queryParams.get(SignUpParams.Type) || getCookie(SignUpParams.Type)) === 'locked';
        const plan = this.props.location.state?.source?.toLowerCase() === '/teamsapp/config' && this.props.plans.find(_ => _.isDefaultFromTeams)
            || this.props.plans.find(_ => _.key === planKey)
            || this.props.plans.find(_ => _.isDefault);

        const tenant = new Tenant();
        tenant.companyName = this.props.tenant.name;
        tenant.personName = this.props.user.name;
        tenant.personEmail = this.props.user.email;
        tenant.timezone = DateTime.local().zoneName;
        tenant.plan = plan?.key || '';
        tenant.referralCode = getCookie(SignUpParams.ReferralCode) || '';
        tenant.companyName = getCookie(SignUpParams.CompanyName) || '';
        tenant.personPhoneNumber = getCookie(SignUpParams.PersonPhone) || '';
        tenant.cultureName = getCurrentBrowserLocale();

        this.state = {
            tenant,
            selectedProductType: plan?.productType || ProductType.Product,
            disableReferralCode: !!tenant.referralCode,
            isLocked: !!plan && isLocked
        };
    }

    public render() {
        if (this.props.tenant.id)
            return null;

        const { selectedProductType, disableReferralCode, isLocked } = this.state;
        return (
            <div className='tenant-setup'>
                <div className='profile'>
                    <div className={`info ${inIframe() ? 'info-wide' : ''}`}>
                        <h1>Registration</h1>
                        <div className="tenant-setup-description">Create your account and get a 30-day Trial for FREE. No credit card required.</div>
                        <TextField
                            label='Company'
                            placeholder='Company Name'
                            required
                            value={this.state.tenant.companyName}
                            onGetErrorMessage={validator.companyName.getErrorMessage}
                            onChange={(e, _) => this.setState({ tenant: { ...this.state.tenant, companyName: _! } })} />
                        <TextField
                            label='Website'
                            inputMode='url'
                            placeholder='company.com'
                            onGetErrorMessage={validator.companyWebSite.getErrorMessage}
                            onChange={(e, _) => this.setState({ tenant: { ...this.state.tenant, companyWebSite: _! } })} />

                        <TextField
                            label='Job Title'
                            placeholder='Position'
                            onChange={(e, _) => this.setState({ tenant: { ...this.state.tenant, personJobTitle: _! } })} />
                        <TextField
                            label='Name'
                            placeholder='Full Name'
                            required
                            value={this.state.tenant.personName}
                            onGetErrorMessage={validator.personName.getErrorMessage}
                            onChange={(e, _) => this.setState({ tenant: { ...this.state.tenant, personName: _! } })} />
                        <TextField
                            label='Email'
                            inputMode='email'
                            placeholder='Email Address'
                            required
                            value={this.state.tenant.personEmail}
                            onGetErrorMessage={validator.personEmail.getErrorMessage}
                            onChange={(e, _) => this.setState({ tenant: { ...this.state.tenant, personEmail: _! } })} />
                        <TextField
                            label='Phone Number'
                            inputMode='tel'
                            placeholder='+1 (999)-999-9999'
                            onChange={(e, _) => this.setState({ tenant: { ...this.state.tenant, personPhoneNumber: _! } })}
                            required
                            value={this.state.tenant.personPhoneNumber}
                            onGetErrorMessage={validator.personPhoneNumber.getErrorMessage} />
                        <TextField
                            label='Referral Code (if you have it)'
                            placeholder='Enter code'
                            maxLength={99}
                            disabled={disableReferralCode}
                            value={this.state.tenant.referralCode}
                            onChange={(e, _) => this.setState({ tenant: { ...this.state.tenant, referralCode: _! } })} />
                        <div className='register'>
                            <DefaultButton
                                disabled={this.props.tenant.isProcessing || !this._isFormValid()}
                                primary
                                text={this.props.tenant.isProcessing ? 'Registration' : 'Register'}
                                onClick={this._registerTenant}>
                                {this.props.tenant.isProcessing && <Spinner size={SpinnerSize.medium} />}
                            </DefaultButton>
                        </div>
                        <p className='signup-agreement'>
                            By registering, you agree to our <Link href='https://ppm.express/terms-of-use' target='_blank'>Terms of Use</Link> &nbsp;
                            and <Link href='https://ppm.express/privacy-policy' target='_blank'>Privacy</Link>
                        </p>
                        <p className='pricing-info'>
                            <Link href='https://ppm.express/ppm-express-pricing' target='_blank'>Pricing and Plans</Link>
                        </p>
                    </div>
                </div>
                <div className='wallpaper-container'>
                    {
                        !!this.props.plans.length && <SignUpPlans
                            availablePlans={this.props.plans}
                            selectedProductType={selectedProductType}
                            onPlanClick={productType => {
                                const plan = this.props.plans.find(_ => _.productType === productType);
                                plan && this._setSelectedPlan(plan);
                            }}
                            isLocked={isLocked}
                        />
                    }
                    <div className='wallpaper'>
                    </div>
                </div>
            </div>);
    }

    private _setSelectedPlan = (plan: SignUpPlan) => {
        this.setState({
            selectedProductType: plan.productType,
            tenant: { ...this.state.tenant, plan: plan.key }
        })
    }

    private _registerTenant = () => {
        if (!this._isFormValid()) {
            return;
        }

        const { tenant } = this.state;
        this.props.registerTenant(tenant);
    }

    private _isFormValid(): boolean {
        return Validator.isValid(validator, this.state.tenant);
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        plans: state.chargebee.plans
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps, actionCreators)(TenantSetup));