import * as React from 'react';
import { connect } from 'react-redux';
import { UserState, UserStatus } from "../../store/User";
import { ApplicationState } from "../../store/index";
import ErrorPage from '../ErrorPage';
import { Redirect, RouteComponentProps } from "react-router-dom";

type OwnProps = RouteComponentProps<{}>;
type StateProps = { user: UserState };
type Props = OwnProps & StateProps;

class InactiveUser extends React.Component<Props>  {
    public render() {
        if (this.props.user.status === UserStatus.Active) {
            return <Redirect to="/" />;
        }

        return <ErrorPage
            title="Access Denied"
            description="Sorry, you don't have the permission to access the PPM Express. Contact your administrator for necessary permission or please try to Log in the system with different account. " />
    }
}

function mapStateToProps(state: ApplicationState, onwProps?:OwnProps): StateProps {
    return { user: state.user };
}

export default connect(mapStateToProps, {})(InactiveUser);