import * as React from 'react';
import { Portfolio, portfolioStagesMap, PortfolioAttrs } from "../../store/PortfoliosListStore";
import Logo from "../common/Logo";
import * as Utils from "../../store/portfolio/utils";
import { FormatDate, formatFieldValue } from "../utils/common";
import StageView from '../views/list/columns/StageView';
import { FormatType, Field, getLabel } from '../../entities/Metadata';
import { connect } from 'react-redux';
import { nameof } from '../../store/services/metadataService';
import { ApplicationState } from '../../store';
import { EntityType, StatusCategory } from '../../entities/common';
import ResourceFormatter from '../common/formatters/ResourceFormatter';
import { UserState } from '../../store/User';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

type OwnProps = { portfolio: Portfolio  };
type StoreProps = {
    fields: Field[];
    user: UserState;
}
type Props = OwnProps & StoreProps;

class PortfolioTooltipContent extends React.Component<Props, { completed: number, total: number }> {
    constructor(props: Props) {
        super(props);

        this.state = Utils.getProjectsMetrics(props.portfolio.calculation);
    }

    componentWillReceiveProps(nextProps: { portfolio: Portfolio }) {
        if (this.props.portfolio.calculation != nextProps.portfolio.calculation) {
            this.setState(Utils.getProjectsMetrics(nextProps.portfolio.calculation));
        }
    }

    render() {
        const { portfolio } = this.props;

        const startDate = portfolio.attributes.StartDate
            ? FormatDate(portfolio.attributes.StartDate)
            : "-";
        const finishDate = portfolio.attributes.FinishDate
            ? FormatDate(portfolio.attributes.FinishDate)
            : "-";
        const { completed, total } = this.state;
        const progress = total ? `${completed}/${total}` : 0;
        const manager = portfolio.attributes.Manager;
        const portfolioOverallStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Portfolio, this.props.fields)!;
        const statusOption = portfolioOverallStatusDescriptor.getOptionOrDefault(portfolio.attributes.OverallStatus, StatusCategory.NA);
        
        return (
            <div className="timeline-tooltip">
                <div className="header">
                    <Logo className="pf-logo" imageId={portfolio.imageId} />
                    <div className="title"><div className="overflow-text">{portfolio.attributes.Name}</div></div>
                    <CategoryColorStatusView
                        statusOption={statusOption}
                        trend={portfolio.insights.statuses[nameof<PortfolioAttrs>("OverallStatus")]?.trend}
                    />
                </div>

                <div className="content">
                    {this.renderField("StartDate", <div>{startDate}</div>)}
                    {this.renderField("FinishDate", <div>{finishDate}</div>)}
                    {this.renderField("Budget", <div>{formatFieldValue(portfolio.attributes.Budget, FormatType.Cost, this.props.user)}</div>)}
                    <div className="item align-center">
                        <div className="label">Progress</div>
                        <div className="align-center">
                            <div className="completed">{progress}</div>
                            <div>projects complete</div>
                        </div>
                    </div>
                    {this.renderField("Stage",
                        portfolio.attributes.Stage != undefined
                            ? <StageView value={portfolio.attributes.Stage} className="portfolio-stage" map={portfolioStagesMap} />
                            : <div>-</div>)}
                    {this.renderField("Manager",
                        manager.length > 0
                            ? <div className="align-center">
                                <ResourceFormatter resource={manager} withNavigation={false} />
                              </div>
                            : <div className="align-center">
                                <span>(Manager is not assigned yet)</span>
                              </div>)}
                </div>
            </div>
        );
    }

    private renderField = (name: keyof PortfolioAttrs, valueElement: JSX.Element): JSX.Element | null => {
        const field = this.props.fields.find(_ => _.name === name);
        if (!field) {
            return null;
        }

        return (
            <div className="item align-center">
                <div className="label">{getLabel(field)}</div>
                {valueElement}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Portfolio];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        user: state.user
    };
}

export default connect(mapStateToProps)(PortfolioTooltipContent);