import * as React from 'react';
import { IColumn, SearchBox } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import * as Metadata from '../../entities/Metadata';
import { Dictionary, EntityType } from '../../entities/common';
import LabellableComponent from '../common/LabellableComponent';
import { DisplayFieldService } from '../common/DisplayFieldService';
import { nameof } from '../../store/services/metadataService';
import { isIntersect, waitForFinalEvent } from '../utils/common';
import {
    actionCreators, IObjectiveAttrs, Objective, KeyResult, objectiveStatesMap, OKRState, OKRValueType, OKRDirection, ObjectiveCalculationType
} from '../../store/ObjectivesListStore';
import { post } from '../../fetch-interceptor';
import { defaultCatch } from '../../store/utils';
import EntityName from '../views/list/columns/EntityName';
import StageView from '../views/list/columns/StageView';
import SelectionPanel, { SelectionType, IViewItem } from '../common/SelectionPanel';

const debounceDelay = 600;

type OKRSelectedItem = {
    objectiveId: string;
    keyResultId?: string;
    name: string;
    calculationType: ObjectiveCalculationType;
    valueType: OKRValueType;
    direction: OKRDirection;
    item: Objective | KeyResult;
}

type ViewItem = IViewItem & {
    item: Objective | KeyResult,
    name: string,
    scope: string[],
    state: OKRState,

    startDate?: string;
    finishDate?: string;

    calculationType: ObjectiveCalculationType;
    valueType: OKRValueType;
    direction: OKRDirection;
}

type OwnProps = {
    multichoice?: boolean;
    showStatus?: boolean;
    selectableEntity: EntityType.Objective | 'keyresult';
    header: {
        title: string;
        description: string;
    };
    onDismiss: () => void;
    onSelectionComplete: (selected: OKRSelectedItem[]) => void;
    objectives?: Objective[];
    filters?: {
        states?: OKRState[],
        excludeIds?: string[],
        disabledIds?: string[] | ((objectives: Objective[])=> string[])
    };
}
type StoreProps = {
    fields: Metadata.Field[];
}
type ActionProps = {
    objectivesActions: typeof actionCreators;
}
type Props = ActionProps & OwnProps & StoreProps;

type State = {
    isLoading: boolean;
    disabledIds?: string[];
    allItems: ViewItem[];
}

class OKRSelectionPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            allItems: []
        };
    }

    componentWillMount() {
        const { filters, objectives } = this.props;
        if (objectives) {
            this.setState({
                isLoading: false,
                disabledIds: filters?.disabledIds
                    ? Array.isArray(filters.disabledIds)
                        ? filters.disabledIds
                        : filters.disabledIds(objectives)
                    : undefined,
                allItems: this._buildViewItems(objectives, this.props.selectableEntity === 'keyresult')
            });
        } else {
            post<Objective[]>('api/objective/lite', { states: this.props.filters?.states ?? null, skipKeyResults: this.props.selectableEntity == EntityType.Objective })
                .then(data => this.setState({
                    isLoading: false,
                    disabledIds: filters?.disabledIds
                        ? Array.isArray(filters.disabledIds)
                            ? filters.disabledIds
                            : filters.disabledIds(data)
                        : undefined,
                    allItems: this._buildViewItems(data, this.props.selectableEntity === 'keyresult')
                }))
                .catch(defaultCatch());
        }
    }

    public render() {
        const { filters, onDismiss, onSelectionComplete, header, multichoice } = this.props;
        const { isLoading, allItems, disabledIds } = this.state;
        return <SelectionPanel
            isLoading={isLoading}
            multichoice={multichoice}
            header={header}
            allItems={allItems}
            selectionType={(this.props.selectableEntity === EntityType.Objective) ? SelectionType.Entity : SelectionType.Subentity}
            onDismiss={onDismiss}
            nameColumnIconName="PPMXStrategicPriority"
            sortItems={(items) => [...items].sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))}
            onRenderNameColumn={(item: ViewItem) => {
                return <EntityName
                    key="list-view"
                    name={item.name}
                    withDates={!item.isSubentity}
                    isTimelineView
                    startDate={item.startDate}
                    finishDate={item.finishDate}
                    className="strategic-priority"
                    imageClassName={`${!item.isSubentity ? 'objective' : 'key-result'} ${objectiveStatesMap[item.state].cssClassName}`} />
            }}
            extraColumns={this._buildExtraColumns()}
            onSelectionComplete={(selectedItem: ViewItem[]) => {
                const items: OKRSelectedItem[] = selectedItem
                    .map(_ => ({
                        objectiveId: !_.isSubentity ? _.id : _.parentId!,
                        keyResultId: !_.isSubentity ? undefined : _.id,
                        name: _.name,
                        calculationType: _.calculationType,
                        valueType: _.valueType,
                        direction: _.direction,
                        item: _.item
                    }));
                onSelectionComplete(items);
            }}
            onRenderFilters={this._renderFilters}
            applyFilters={this._applyFilters}
            filters={{
                disabledIds: disabledIds,
                excludeIds: filters?.excludeIds,
            }}
        ></SelectionPanel>;
    }

    private _buildViewItems(objectives: Objective[], addKeyResults: boolean): ViewItem[] {
        return objectives.reduce((cum, cur) => ([
            ...cum,
            ...[{
                id: cur.id,
                parentId: cur.attributes.Parent?.id,
                name: cur.attributes.Name,
                isSubentity: false,
                item: cur,
                scope: cur.attributes.Scope,
                state: cur.attributes.State,
                startDate: cur.attributes.StartDate,
                finishDate: cur.attributes.FinishDate,
                calculationType: cur.attributes.CalculationType,
                valueType: cur.attributes.ValueType,
                direction: cur.attributes.Direction
            }],
            ...cur.keyResults.filter(_ => addKeyResults).map(kr => ({
                id: kr.id,
                parentId: cur.id,
                name: kr.name,
                isSubentity: true,
                item: kr,
                scope: cur.attributes.Scope,
                state: kr.state,
                valueType: kr.valueType,
                calculationType: kr.calculationType,
                direction: kr.direction
            }))
        ]), []);
    }

    private _renderFilters = (filterValue: Dictionary<any>, saveFilter: (key: string, value: any) => void): JSX.Element | null => {
        const { fields } = this.props;
        const scopeField = fields.find(_ => _.name === nameof<IObjectiveAttrs>("Scope"))!;
        return <>
            <div className="grid-item">
                <LabellableComponent label={`Search by Title`} 
                    className="field-container">
                    <div className="field-value">
                        <SearchBox
                            value={filterValue.text}
                            onChange={waitForFinalEvent((e, v: string) => saveFilter("text", v), debounceDelay, 'search')}
                            onClear={() => saveFilter("text", undefined)}
                        />
                    </div>
                </LabellableComponent>
            </div>
            <div className="grid-item">
                <LabellableComponent label={`Search by ${scopeField.label || scopeField.name}`}
                    className="field-container">
                    <div className="field-value">
                        {DisplayFieldService.buildFieldMultiSelectInput(scopeField, filterValue.scope, changed => saveFilter("scope", changed))}
                    </div>
                </LabellableComponent>
            </div>
        </>;
    }

    private _buildExtraColumns = (): IColumn[] | undefined => {
        if (this.props.showStatus !== false) {
            return [{
                key: "state",
                fieldName: "state",
                name: "State",
                minWidth: 100,
                onRender: (item: ViewItem) => <StageView
                    value={item.state}
                    className={`objective-state ${objectiveStatesMap[item.state].cssClassName}`}
                    map={objectiveStatesMap} />
            }]
        }
    }

    private _applyFilters = (item: ViewItem, filterValues: Dictionary<any>): boolean => {
        const { filters } = this.props;
        const result = (!filters?.states || !!~filters.states.indexOf(item.state))
            && (!filterValues.scope || !filterValues.scope.length || isIntersect(filterValues.scope, item.scope ?? []))
            && (!filterValues.text || !!~item.name.toLowerCase().indexOf(filterValues.text.toLowerCase()));
        return !!result;
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Objective];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        objectivesActions: bindActionCreators(actionCreators, dispatch),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(OKRSelectionPanel);