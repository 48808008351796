import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Dictionary } from '../../../../../entities/common';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../../store/index';
import { ProjectInfo } from '../../../../../store/ProjectsListStore';
import ProjectName from '../project/Name';
import ProgramName from '../program/Name';
import { Field, FieldGroup, FieldType } from '../../../../../entities/Metadata';
import { KeyDate } from '../../../../../entities/Subentities';
import { Program } from '../../../../../store/ProgramsListStore';

interface StateProps {
    projects: Dictionary<ProjectInfo>,
    programs: Dictionary<Program>
}

export const ImportedFromFakeField: Field = {
    id: "c4592b40-6853-4dd1-93bf-6aa4baad881c",
    label: "From",
    name: "ImportedFrom",
    type: FieldType.Text,
    isNative: false,
    isCustom: false,
    isReadonly: false,
    isFake: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    settings: { views: { list: { componentPath: "subentity/ImportedFrom", maxWidth: 150 } } },
};

class Parent extends React.Component<StateProps & IListViewFieldColumn<{ externalData: Dictionary<any> }>> {
    render() {
        const { entity, projects, programs } = this.props;
        const parentId = entity.externalData["ImportedFromId"];
        const project = parentId ? projects[parentId] : undefined;
        if (project) {
        	return <ProjectName {...this.props} entity={{ ...project, warnings: [] }} />;
	}
        const program = parentId ? programs[parentId] : undefined
        if (program) {
        	return <ProgramName {...this.props} entity={{ ...program, warnings: [] }} />;
        }
        return null;
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        projects: { ...state.projectsList.byId },
        programs: { ...state.programs.byId }
    };
}

export function getValue(entity: KeyDate, field?: Field): string {
    //known issue, extrnalData can contain obsolete ProjectName, this impacts sorting only
    return entity.externalData["ImportedFromName"];
}

export default connect(mapStateToProps)(Parent);