import * as React from 'react';
import { Resource } from "../../../store/ResourcesListStore";
import { ChoiceGroup, ComboBox, IChoiceGroupOption, IComboBoxOption } from 'office-ui-fabric-react';
import LabellableComponent from '../../common/LabellableComponent';
import DatePickerInput from '../../common/inputs/DatePickerInput';
import { EntityType, IExtensibleEntity, IWithResourcePlan, MaybeDate, Quantization } from '../../../entities/common';
import { toDate } from '../../utils/common';
import { IMinMax } from '../../common/timeline/utils';
import { Validator } from '../../../validation';
import { AllocationInfoPanel } from './AllocationInfoPanel';
import { ViewType } from './ResourceUsageGrid';
import { buildAllocationParams } from './ResourceUsageCell';
import NumberInput from '../../common/inputs/NumberInput';

type Props = {
    entityType: EntityType;
    byResource?: boolean;
    isActual: boolean;
    rows: { resource: Resource, entity: IExtensibleEntity & IWithResourcePlan }[];
    onComplete: (startDate: Date, finishDate: Date, value: number | true, timeType?: ViewType, isActual?: boolean, valuePerDay?: boolean) => void;
    onDismiss: () => void;
}

const validators: { [key: string]: Validator } = {
    startDate: Validator.new().required().build(),
    finishDate: Validator.new().required().build(),
    value: Validator.new().required().decimal().build()
}

export const AllocatePanel = (props: Props) => {
    const map = React.useMemo(() => (props.rows.reduce((cum, cur) => ({
        entities: { ...cum.entities, [cur.entity.id]: cur.entity },
        resources: { ...cum.resources, [cur.resource.id]: cur.resource }
    }), {
        entities: {},
        resources: {}
    })), [props.rows]);

    const entityRange: IMinMax | undefined = React.useMemo(() => (Object.keys(map.entities).length === 1
        ? fixProjectRange({
            minDate: toDate(props.rows[0].entity?.attributes.StartDate),
            maxDate: toDate(props.rows[0].entity?.attributes.FinishDate)
        }) : undefined), [map.entities, props.rows]);
    const [startDate, setStartDate] = React.useState<MaybeDate>(entityRange?.minDate);
    const [finishDate, setFinishDate] = React.useState<MaybeDate>(entityRange?.maxDate);
    const [viewType, setViewType] = React.useState<ViewType | 'auto' | 'total'>('auto');
    const [value, setValue] = React.useState<number | true | undefined>(true);

    const title = React.useMemo(() => !props.isActual ? "Allocate Resource" : "Set Actual", [props.isActual]);
    const description = React.useMemo(() => {
        const lineLabel = props.byResource ? props.entityType : 'resource';
        const count = Object.keys(props.byResource ? map.entities : map.resources).length;
        return !props.isActual
            ? `Define resource allocation (planned time) for ${count} selected ${lineLabel}${count ? '' : 's'}`
            : `Define actual time for ${count} selected ${lineLabel}${count ? '' : 's'}`
    }, [props.entityType, props.isActual, map]);
    const extraText = React.useMemo(() => !props.isActual ? "Select a period for allocation" : "Select a period for setting actual time", [props.isActual]);

    const totalKey = "total";
    const viewTypeOptions = React.useMemo(() => [
        { text: "Auto", key: "" },
        ...[ViewType.Hours, ViewType.Percent, ViewType.FTE].map(_ => ({ text: ViewType[_], key: _.toString(), value: _ })),
        { text: "Total hours for period", key: totalKey }
    ], [])

    const onViewTypeChanged = React.useCallback((ev: React.FormEvent<HTMLElement>, item: IChoiceGroupOption) => {
        if (item.key === totalKey) {
            setViewType('total');
            setValue(undefined);
        } else if (item.key) {
            setViewType(parseInt(item.key));
            setValue(undefined);
        } else {
            setViewType('auto');
            setValue(true);
        }
    }, []);

    const allocationParams = React.useMemo(() => viewType === 'auto' || viewType === 'total'
        ? undefined
        : buildAllocationParams(viewType, Quantization.days), [viewType])
    const valueOptions = React.useMemo(() => allocationParams !== undefined
        ? [0, 0.25, 0.5, 0.75, 1].map(_ => ({
            key: _.toString(),
            text: allocationParams.formatValue(allocationParams.getOptionValue(_)),
            data: allocationParams.getOptionValue(_)
        }))
        : undefined, [allocationParams, value]);
    const valueErrorMessage = React.useMemo(() => allocationParams?.validator.getErrorMessage(value), [value, allocationParams]);
    const formatedValue = React.useMemo(() => allocationParams !== undefined && value !== undefined && typeof value === 'number'
        ? allocationParams.formatValue(value)
        : undefined, [allocationParams, value]);
    const onValueChanged = React.useCallback((e: any, option?: IComboBoxOption, i?: number, v?: string) => {
        if (option) {
            setValue(option?.data)
        } else if (v) {
            const value = allocationParams?.parseValue(v);
            if (value !== undefined && allocationParams?.validator.isValid(value)) {
                setValue(value)
            }
        }
    }, [allocationParams]);
    const onTotalChanged = React.useCallback((value) => {
        const count = Object.keys(props.byResource ? map.entities : map.resources).length;
        setValue(value / count);
    }, [map]);

    const confirmButtonIsDisabled = React.useMemo(() => !Validator.isValid(validators, { startDate, finishDate, value }),
        [validators, startDate, finishDate, value]);
    const onConfirm = React.useCallback(() => {
        props.onComplete(toDate(startDate)!, toDate(finishDate)!,
            value!,
            viewType === 'auto' ? undefined : viewType === 'total' ? ViewType.Hours : viewType,
            props.isActual,
            viewType !== 'total');
        props.onDismiss();
    }, [props.onDismiss, props.onComplete, props.isActual, startDate, finishDate, value, viewType]);

    return <AllocationInfoPanel
        entityType={props.entityType}
        onConfirm={onConfirm}
        onDismiss={props.onDismiss}
        title={title}
        description={description}
        confirmButtonText="Confirm"
        confirmButtonIsDisabled={confirmButtonIsDisabled}
        rows={props.rows}>
        <div className="grid-item space-before">
            {extraText}
        </div>
        <div className="grid-item half-width odd">
            <LabellableComponent label="Start Date" className="field-container">
                <DatePickerInput
                    value={startDate instanceof Date ? startDate.toDateOnlyString() : startDate as string | undefined}
                    validator={validators.startDate}
                    onChanged={_ => setStartDate(toDate(_))} />
            </LabellableComponent>
        </div>
        <div className="grid-item half-width">
            <LabellableComponent label="Finish Date" className="field-container">
                <DatePickerInput
                    value={finishDate instanceof Date ? finishDate.toDateOnlyString() : finishDate as string | undefined}
                    validator={validators.finishDate}
                    onChanged={_ => setFinishDate(toDate(_))} />
            </LabellableComponent>
        </div>
        <div className="grid-item">
            <LabellableComponent label="Type of allocation" className="field-container">
                <ChoiceGroup
                    defaultSelectedKey={viewTypeOptions[0].key}
                    onChange={onViewTypeChanged}
                    className="with-cb-in-row"
                    options={viewTypeOptions} />
            </LabellableComponent>
        </div>
        {allocationParams && <div className="grid-item">
            <LabellableComponent label="Value" required className="field-container">
                <ComboBox
                    text={formatedValue}
                    options={valueOptions}
                    onChange={onValueChanged}
                    placeholder="Select or type custom value"
                    errorMessage={valueErrorMessage}
                    autoComplete='off'
                    allowFreeform
                    useComboBoxAsMenuWidth
                />
            </LabellableComponent>
        </div>}
        {viewType === 'total' && <div className="grid-item">
            <LabellableComponent label="Value" required className="field-container"
description="Hours will be distributed evenly between working days for selected period. If several resources are selected, hours will be split between these resources.">
                <NumberInput
                    validator={validators.value}
                    onChanged={onTotalChanged} />
            </LabellableComponent>
        </div>}
    </AllocationInfoPanel>;
}

function fixProjectRange(projectRange: IMinMax | undefined): IMinMax | undefined  {
    if (!projectRange) {
        return projectRange;
    }

    const now = new Date().getBeginOfDay();
    if (!projectRange.minDate || projectRange.minDate < now) {
        projectRange.minDate = now;
    }
    if (projectRange.maxDate && projectRange.maxDate < projectRange.minDate) {
        projectRange.maxDate = undefined;
    }

    return projectRange;
}
