import * as React from 'react';
import TextInput from './TextInput';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import { FormatType } from "../../../entities/Metadata";
import { IFormInputComponent } from "../interfaces/IFormInputComponent";

type Props = IFormInputProps<number, HTMLTextAreaElement> & { format?: FormatType; }

export default class NumberInput extends React.Component<Props, {}> implements IFormInputComponent {
    private _input: any;

    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    public render() {
        return <TextInput
            {...this.props}
            inputRef={_ => this._input = _}
            onChanged={this._onChanged}
            onEditComplete={this._onEditComplete}
            value={this.props.value != null ? this.props.value.toString() : ''}
            isNumber
        />;
    }

    private _onChanged = (fieldValue: any): void => {
        if (!this.props.onChanged)
            return;

        if (typeof fieldValue === 'string') {
            fieldValue = fieldValue.trim();
        }
        if (fieldValue === '') {
            fieldValue = null;
        }
        const number = fieldValue ? Number(fieldValue) : null;
        this.props.onChanged(Number.isNaN(number) ? fieldValue : number);
    }

    private _onEditComplete = (fieldValue: string | null): void => {
        if (!this.props.onEditComplete)
            return;

        const number = fieldValue ? Number(fieldValue) : null;
        this.props.onEditComplete(Number.isNaN(number) ? null : number);
    }

    public focus() {
        this._input.focus();
    }
}