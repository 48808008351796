import * as React from "react";
import { connect } from "react-redux";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { ApplicationState } from "../../store/index";
import { UserState, UserStatus } from "../../store/User";
import { LicenseType } from "../../store/permissions";
import { IsRouteAvailable, SubscriptionStatus, TenantState, ToRouteAvailabilitySettings } from "../../store/Tenant";
import { bindActionCreators } from "redux";
import * as MetadataStore from "../../store/MetadataStore";

type OwnProps = {
    allowAnonymous?: boolean;
    isAvailable?: IsRouteAvailable;
};
type StateProps = { user: UserState; tenant: TenantState };
type ActionProps = { metadataActions: typeof MetadataStore.actionCreators }
type Props = OwnProps & RouteProps & StateProps & ActionProps;

class ProtectedRoute extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        //metadata is loaded in this component to fix react/redux behavior of late update of the props if done somethere in inner component
        this._isAllowed(props) && this.props.metadataActions.ensureMetadata();
    }

    componentWillReceiveProps(nextProps: Props) {
        this._isAllowed(nextProps) && nextProps.metadataActions.ensureMetadata();
    }

    public render() {
        const { user, tenant } = this.props;
        if (this.props.allowAnonymous || this._isAllowed(this.props)) {
            const isAvailable = this.props.isAvailable?.(tenant.subscription, user, ToRouteAvailabilitySettings(tenant)) !== false;
            return isAvailable
                ? <Route {...this.props} />
                : <Redirect to='/error/403' />;
        }
        
        if (!tenant.id) {
            return <Redirect to={{ pathname: "/setup/tenant", state: { source: this.props.location ? this.props.location.pathname : this.props.path, query: this.props.location?.search } }} />;
        }

        if (!user.isSignedUp) {
            return <Redirect to={{ pathname: "/setup/user", state: { source: this.props.location ? this.props.location.pathname : this.props.path } }} />;
        }

        if (user.status === UserStatus.Inactive) {
            return <Redirect to="/forbidden" />;
        }

        if (tenant.subscription.status !== SubscriptionStatus.Active) {
            return <Redirect to="/subscription/expired" />;
        }

        if (this.props.tenant.subscription.subscriptionId && this.props.user.license === LicenseType.None) {
            return <Redirect to="/nolicense" />;
        }

        return <Redirect to='/error/403' />;
    }

    private _isAllowed(props: Props) {
        const { user, tenant } = props;
        return user.isSignedUp
            && user.status === UserStatus.Active
            // todo: !tenant.subscription.subscriptionId after release (crunch for clients with a temporary subscription)
            && (!tenant.subscription.subscriptionId || user.license != LicenseType.None)
            && tenant.subscription.status === SubscriptionStatus.Active;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        metadataActions: bindActionCreators(MetadataStore.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ProtectedRoute);