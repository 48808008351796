import * as React from 'react';
import "./RowMenuColumn.css";
import { RowMenuContainer } from './RowMenuContainer';

interface Props {
    onItemMenuRender?: () => JSX.Element | null;
}

export const RowMenuColumn = (props: React.PropsWithChildren<Props>) => {
    return !props.onItemMenuRender
        ? <>{props.children}</>
        : <div className='column-with-menu'>
            <div className='column-value'>
                {props.children}
            </div>
            <RowMenuContainer className="column-menu">
                {props.onItemMenuRender()}
            </RowMenuContainer>
        </div>;
}