import { Resource } from "../ResourcesListStore";
import { SourceTypeMap, SourceType } from "../ExternalEpmConnectStore";
import { IFilterHelper, FilterAttribute, IFilter, Field, Filter, BaseFilterValue, getLabel, IEntityFilterHelper, IActiveFilter, Layout } from "../../entities/Metadata";
import { Option, OptionsPickerProps } from "../../components/common/inputs/OptionsPicker";
import { InputsService, DisplayFieldService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { Dictionary } from "../../entities/common";
import { buildLayoutFilterHelper } from "../project/filters";

export interface ResourceFilterValue extends BaseFilterValue {
    linkedSystem?: string[];
    layoutId?: string[];
}

export class ActiveFilter {
    private readonly _filter: IFilter<ResourceFilterValue>;

    constructor(name?: string) {
        this._filter = Filter.empty(name);
    }

    public withAttributes(attrNames: string[]) {
        let attributes = this._filter.attributeNames.filter(_ => _.type === "attributes");
        attrNames.forEach(name => {
            let attribute = attributes.find(attr => attr.name === name);
            if (!attribute) {
                this._filter.attributeNames.push({ name: name, type: "attributes" });
            }
        });
        return this;
    }

    public withTag(tag: string): ActiveFilter {
        if (!this._filter.value.attributes) {
            this._filter.value!.attributes = new Dictionary<any[]>();
        }
        const attrName = 'Tags';
        this._filter.value.attributes![attrName] = [tag];
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public build(): IFilter<ResourceFilterValue> {
        return this._filter;
    }
}

export type FilterHelperProps = {
    fields: Field[];
    resources: Resource[];
    layouts: Layout[];
}

export class FilterHelper implements IEntityFilterHelper<Resource> {
    public getFilterAttributes = (fields: Field[]): FilterAttribute<ResourceFilterValue>[] => {
        let attrs = fields.map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<ResourceFilterValue>);
        attrs.push({ type: "linkedSystem", name: "linkedSystem", displayName: "Linked System", value: undefined });
        return attrs;
    }

    private attributes: IFilterHelper<ResourceFilterValue, Resource> = {
        buildFilterElement: (attr: FilterAttribute<ResourceFilterValue>,
            filter: IFilter<ResourceFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const field: Field = attr.value;
            const value = filter.value?.attributes?.[field.name];

            return DisplayFieldService.buildFieldMultiSelectInput(field, value, changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            let newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            oldValue[attrName] = value;
            return oldValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.fields, value),
        validateItem: (item: Resource, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] == undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find(_ => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    const value = item.attributes[key];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    private linkedSystem: IFilterHelper<ResourceFilterValue, Resource> =
        {
            buildFilterElement: (
                attr: FilterAttribute<ResourceFilterValue>,
                filter: IFilter<ResourceFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const types: SourceType[] = [];
                this._props.resources.forEach(resource => {
                    resource.sourceInfos.forEach(sourceInfo => {
                        if (types.indexOf(sourceInfo.type) === -1) {
                            types.push(sourceInfo.type);
                        }
                    });
                });

                const typeOptions: Option[] = types.map(_ => ({ key: _, text: SourceTypeMap[_] }));
                const selectedItems: Option[] = [];

                filter.value &&
                    filter.value.linkedSystem &&
                    filter.value.linkedSystem.forEach((id: string) => {
                        let opt = typeOptions.find(_ => _.key === id);
                        if (opt !== undefined) {
                            selectedItems.push(opt);
                        }
                    });

                const itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (value?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "linkedSystem",
                            value && value.map(v => v.key));
                    },
                    onResolveSuggestions: (filter: string, selectedItems?: Option[]) => {
                        let res: Option[] =
                            typeOptions.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                        if (selectedItems) {
                            res = res.filter((opt: Option) => selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return new Promise<Option[]>((resolve, reject) => {
                            resolve(res);
                        });
                    },
                    selectedItems: selectedItems
                }

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                let values: string[] = [];
                (value as SourceType[]).forEach(system => {
                    values.push(SourceTypeMap[system]);
                });
                return values;
            },
            validateItem: (item: Resource, filterValue: any): boolean => {
                if (!filterValue || (<string[]>filterValue).length === 0) {
                    return true;
                }
                return item.sourceInfos.map(_ => _.type)
                    .filter(_ => (filterValue as SourceType[]).indexOf(_) !== -1).length >
                    0;
            }
        }
    private layoutId = buildLayoutFilterHelper<ResourceFilterValue, Resource>(() => this._props.layouts)

    private readonly _props: FilterHelperProps
    constructor(props: FilterHelperProps) {
        this._props = props;
    }

    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name);
    public helpersMap: { [K in keyof ResourceFilterValue]: IFilterHelper<ResourceFilterValue, Resource> } =
        {
            "attributes": this.attributes,
            "linkedSystem": this.linkedSystem,
            "layoutId": this.layoutId
        }
}