import React, { PropsWithChildren } from "react";
import "./ColumnFieldsCollapsible.css";
import { nameof } from "../../../../store/services/metadataService";
import { ColumnGroupedFieldNames } from "../GroupedFieldsConfiguration";
import Collapsible, { ChevronDirection } from "../../../common/Collapsible";

const COLUMN_1 = nameof<ColumnGroupedFieldNames>('column1');
const COLUMN_2 = nameof<ColumnGroupedFieldNames>('column2');

export const COLUMN_GROUP_NAMES = { COLUMN_1, COLUMN_2 };

type Props = {
    group: string;
};

const ColumnFieldsCollapsible = ({ group, children }: PropsWithChildren<Props>) => {
    const columnNumber = getColumnNumber(group);
    return (
        <Collapsible
            key={group}
            expanded
            iconName={getColumnGroupIcon(columnNumber)}
            title={`Column ${columnNumber}`}
            collapsedChevronDirection={ChevronDirection.Up}
        >
            {children}
        </Collapsible>
    );
};

const getColumnNumber = (group: string) => {
    switch (group) {
        case COLUMN_1:
            return 1;
        case COLUMN_2:
            return 2;
        default:
            return 1;
    }
};

const getColumnGroupIcon = (columnNumber: number) => {
    switch (columnNumber) {
        case 1:
            return "SingleColumn";
        case 2:
            return "DoubleColumn";
        default:
            return "SingleColumn";
    }
};

export default ColumnFieldsCollapsible;
