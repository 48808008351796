import * as React from 'react';
import { Pivot, PivotItem } from 'office-ui-fabric-react';
import HeaderSecondaryText, { Props as HeaderSecondaryTextProps } from './HeaderSecondaryText';
import { ISubentity } from '../../../entities/Subentities';
import { Dictionary } from '../../../entities/common';

export interface IExtraTab<TEntity extends ISubentity> {
    key: string;
    label: string;
    renderHeaderSecondaryText: (entity: TEntity) => string | JSX.Element;
    renderBody: (entity: TEntity, onChange: (fieldName?: string, fieldValue?: unknown, extra?: Dictionary<unknown>) => void) => JSX.Element;
}

type ExtraTabsProps<TEntity extends ISubentity> = {
    selectedTab?: IExtraTab<TEntity>;
    onTabChange: (item: PivotItem) => void;
    isTabsDisabled: boolean;
    extraTabs: IExtraTab<TEntity>[]
}

const ExtraTabs = React.memo(<TEntity extends ISubentity,>(props: ExtraTabsProps<TEntity> & HeaderSecondaryTextProps<TEntity>) => {
    const { isTabsDisabled, extraTabs, onTabChange, selectedTab, entity } = props;
    const tabs = React.useMemo(
        () => extraTabs.map(_ => <PivotItem
            key={_.key}
            itemKey={_.key}
            headerText={_.label}
            headerButtonProps={isTabsDisabled ? { 'disabled': true, 'style-color': 'grey' } : undefined} />),
        [extraTabs, isTabsDisabled])
    return <>
        {!!extraTabs.length &&
            <Pivot headersOnly={true} className="panel-tabs" onLinkClick={onTabChange}>
                <PivotItem key="details" headerText="Details" />
                {
                    tabs
                }
            </Pivot>
        }
        {
            selectedTab
                ? selectedTab.renderHeaderSecondaryText(entity)
                : <HeaderSecondaryText {...props} />
        }
    </>;
})

export default ExtraTabs;