import React, { useState } from 'react';
import { IFindResult } from "../../common/inputs/EntityPickerInput";
import { ResourceOperations } from "../../../store/permissions";
import DropdownInput from "../../common/inputs/DropdownInput";
import { EntityType, ServerEntityType, entityLogoConfig, mapServerEntityType } from '../../../entities/common';
import { ParamsControlProps } from './EntityTypeButton';
import ComboboxPicker from '../../common/inputs/ComboboxPicker';

export default function KeyDateParamsControl(props: ParamsControlProps) {
    const [selectedEntityType, setSelectedEntityType] = useState<ServerEntityType>();
    const [selectedEntity, setSelectedEntity] = useState<IFindResult>();

    return <div className='export-params'>
        <div className='label'>Select Entity</div>
        <DropdownInput
            className='input'
            value={selectedEntityType}
            inputProps={{
                placeholder: 'Select Portfolio, Program or Project',
                options: [
                    { key: ServerEntityType.Portfolio, text: entityLogoConfig[EntityType.Portfolio]?.label! },
                    { key: ServerEntityType.Program, text: entityLogoConfig[EntityType.Program]?.label! },
                    { key: ServerEntityType.Project, text: entityLogoConfig[EntityType.Project]?.label! }
                ]
            }}
            onChanged={(value: ServerEntityType) => {
                setSelectedEntityType(value);
                setSelectedEntity(undefined);
                props.onComplete?.({ parentEntityType: value, parentEntityId: undefined, parentEntityName: undefined });
            }}
        />

        {!!selectedEntityType && <>
            <div className='label'>{entityLogoConfig[mapServerEntityType[selectedEntityType]!]?.label}</div>
            <ComboboxPicker
                inputProps={{ placeholder: "Type to search" }}
                selectedKey={selectedEntity?.id}
                searchUrl={`api/${mapServerEntityType[selectedEntityType]}/find`}
                filter={{ accessLevel: ResourceOperations.Update, exceptIds: props.exceptIds }}
                onEditComplete={(value?: IFindResult) => {
                    setSelectedEntity(value);
                    props.onComplete?.({
                        parentEntityType: selectedEntityType,
                        parentEntityId: value?.id,
                        parentEntityName: value?.name
                    });
                }} />
        </>}
    </div>;
}
