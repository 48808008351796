import { ISubentity } from "../../../entities/Subentities";
import CreatePanel, { Props } from "./CreatePanel";

export { CreatePanel };
export type ISubentityPanelProps<TEntity extends ISubentity> = Props<TEntity>

export enum DialogAction {
    None,
    Update,
    Add
}

export { getDeleteButtonCommand } from './UpdatePanel';
export { UpdatePanel } from './UpdatePanel';
export type { OnComplete } from './UpdatePanel';
