import "./QuickStart.css";
import * as React from 'react';
import LetsStart from "../LetsStart";
import MoreResources from "../MoreResources";
import RequestDemo from "./RequestDemo";
import ContactUs from "./ContactUs";
import HelpGuide from "./HelpGuide";
import WhatsNew from "../WhatsNew";
import Connections from "../Connections";
import DemoData from "../DemoData";
import VideoBox from "../VideoBox";
import { ProductType } from "../../../store/Tenant";
import AiInsights from "../AiInsights";
import ViewEntities from "./ViewEntities";
import { canCollaborate, canRead } from "../../../store/permissions";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/User";

type StateProps = {
    user: UserState;
}

type OwnProps = {
    isTrial: boolean;
    hasPartnersSupport: boolean;
    hasAiInsights: boolean;
    hasProjectManagement: boolean;
    hasIdeation: boolean;
    isConnectionAvailable: boolean;
    productType: ProductType;
}

type Props = StateProps & OwnProps;

const QuickStartRegular = (props: Props) => <>
    {props.hasAiInsights && <div className="row"><AiInsights /></div>}
    {
        props.isConnectionAvailable && <div className="row">
            <Connections />
        </div>
    }
    <div className="row">
        <HelpGuide />
        <div className="column double">
            <DemoData />
            {props.hasPartnersSupport
                ? undefined
                : props.isTrial
                    ? <RequestDemo />
                    : <ContactUs />}
        </div>
    </div>
    <div className="row withVideo">
        <VideoBox text="PPM Express Overview" href="https://player.vimeo.com/video/685714660" />
        <VideoBox text="Overview for Team Members" href="https://player.vimeo.com/video/686620891" />
        {props.productType === ProductType.Product
            ? <VideoBox text="PPM Express Roadmap Overview" href="https://player.vimeo.com/video/691236246" />
            : <VideoBox text="PPM Express Task Management" href="https://player.vimeo.com/video/685716110" />}
    </div>
    <div className="row">
        <div className="column double">
            <div className="column">
                <LetsStart />
                <MoreResources />
            </div>
        </div>
        <div className="column">
            {
                props.hasProjectManagement
                    ? <ViewEntities text="View Projects" path='/projects' fixed disabled={!canRead(props.user.permissions.project)} />
                    : props.hasIdeation
                        ? <ViewEntities text="View Business Challenges" path='/challenges' fixed disabled={!canCollaborate(props.user.permissions.challenge)}/>
                        : undefined
            }
            <WhatsNew big />
        </div>
    </div>
</>;

function mapStateToProps(state: ApplicationState): StateProps {
    return { user: state.user };
}

export default connect(mapStateToProps)(QuickStartRegular);