import * as React from 'react';
import * as analytics from '../analytics';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { ApplicationState } from '../store';
import { UserState } from '../store/User';
import { CommonOperations, contains } from '../store/permissions';
import { TenantState, actionCreators, Insights, Subscription, PPMFeatures, StatusCalculationTypes, AiInsightsSettings } from '../store/Tenant';
import * as Notifications from "../store/NotificationsStore";
import { Link, Overlay } from 'office-ui-fabric-react';
import Spinner from "./common/Spinner";
import { ProjectStatusCalculationSettings } from "./insights/ProjectStatusCalculationSettings";
import { PortfolioOrProgramStatusCalculationSettings } from "./insights/PortfolioOrProgramStatusCalculationSettings";
import { nameof } from '../store/services/metadataService';
import { GenericStatusCalculationSettings } from './insights/GenericStatusCalculationSettings';
import { OKRStatusCalculationSettings } from './insights/OKRStatusCalculationSettings';
import WarningsSettings from './insights/WarningsSettings';
import SettingsPage from './settings/SettingsPage';
import { IWithAiInsights } from '../entities/common';
import { AiInsightSettings } from './insights/AiInsightSettings';
import * as MetadataStore from "./../store/MetadataStore";
import { notBoolean } from './utils/common';

type OwnProps = RouteComponentProps<{}>;
type StateProps = {
    user: UserState;
    tenant: TenantState;
}
type ActionProps = {
    tenantActions: typeof actionCreators;
    metadataActions: typeof MetadataStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
}
type Props = OwnProps & StateProps & ActionProps;

export const trackStatusCalculation = (name: string, user: analytics.CreatedBy, type: StatusCalculationTypes) => {
    analytics.trackEvent(name, user, { value: StatusCalculationTypes[type] });
}

const InsightsSettings = (props: Props) => {
    const { tenant, user, tenantActions, metadataActions, notificationsActions } = props;

    if (!tenant.id
        || !user.isSignedUp
        || !contains(user.permissions.common, CommonOperations.AdministrateView)) {
        return <Redirect to="/" />;
    }

    const updateSettings = (settings: AiInsightsSettings) => {
        const refreshMetadata = tenant.aiInsights?.aiEnabled != settings.aiEnabled;
        tenantActions.updateAiInsightsSettings(settings, () => {
            if (refreshMetadata) {
                metadataActions.loadMetadata();
            }
        });
        analytics.trackEvent("AI Settings updated", user, {
            aiEnabled: settings.aiEnabled,
            customApiKeyEnabled: !!settings.customApiKey,
            modelType: settings.modelType,
            temperature: settings.temperature,
        });
    };

    const canManage = contains(user.permissions.common, CommonOperations.Administrate);
    const hasML = Subscription.contains(tenant.subscription, PPMFeatures.ML);
    return <>
        <SettingsPage
            className="insights-settings"
            title="PPM Insights Settings"
            sections={[
                Subscription.contains(tenant.subscription, PPMFeatures.AiInsights) && {
                    key: nameof<IWithAiInsights>("aiInsights"),
                    name: "PPM Insights AI",
                    icon: "D365CustomerInsights",
                    tooltip: <span>Define the settings for the use of AI features in your PPM Express tenant.
                        Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/579092-insights" target="_blank">article</Link> for more details. </span>,
                    onRenderBody: () => <AiInsightSettings
                        data={tenant.aiInsights}
                        onChange={canManage ? (aiInsights, onValidateError) => {
                            const newValue = { ...tenant.aiInsights, ...aiInsights };
                            if (onValidateError) {
                                tenantActions.validateAiInsightsSettings(newValue, (errorMessage) => {
                                    onValidateError(errorMessage);
                                    if (!errorMessage) {
                                        updateSettings(newValue);
                                    }
                                });
                            }
                            else {
                                updateSettings(newValue);
                            }
                        } : undefined}
                    />
                },
                Subscription.containsAny(tenant.subscription, PPMFeatures.PortfolioManagement | PPMFeatures.ProjectManagement) && {
                    key: nameof<Insights>("keyDate"),
                    name: "Key Dates Statuses",
                    icon: "PPMXSectionKeyDates",
                    onRenderBody: () => <GenericStatusCalculationSettings
                        data={tenant.insights.keyDate}
                        onChange={canManage ? keyDate => {
                            tenantActions.updateInsights({ ...tenant.insights, keyDate });
                            trackStatusCalculation('Key Dates Statuses updated', user, keyDate.statusCalculation);
                        } : undefined}
                        thresholdTooltip="The difference between Current Date and Due Date is evaluated and compared with the threshold values defined.
Usage of negative values means that certain status will be applied before the actual date is reached"
                    />
                },
                Subscription.contains(tenant.subscription, PPMFeatures.ProjectManagement) && {
                    key: nameof<Insights>("task"),
                    name: "Tasks Statuses",
                    icon: "GroupedList",
                    onRenderBody: () => <GenericStatusCalculationSettings
                        data={tenant.insights.task}
                        onChange={canManage ? task => {
                            tenantActions.updateInsights({ ...tenant.insights, task });
                            trackStatusCalculation('Tasks Statuses updated', user, task.statusCalculation);
                        } : undefined}
                        thresholdTooltip="The difference between Current Date and Due Date is evaluated and compared with the threshold values defined.
Usage of negative values means that certain status will be applied before the actual date is reached"
                    />
                },
                Subscription.contains(tenant.subscription, PPMFeatures.OKR) && {
                    key: nameof<Insights>("keyResult"),
                    name: "Objectives and Key Results Statuses",
                    icon: "PPMXCompanyObjectives",
                    onRenderBody: () => <OKRStatusCalculationSettings
                        data={tenant.insights.keyResult}
                        onChange={canManage ? keyResult => {
                            tenantActions.updateInsights({ ...tenant.insights, keyResult });
                            trackStatusCalculation('Objectives and Key results statuses updated', user, keyResult.statusCalculation);
                        } : undefined}
                        thresholdTooltip="Status will be calculated based on this threshold when Objective or Key Result is closed"
                    />
                },
                Subscription.containsAny(tenant.subscription, PPMFeatures.PortfolioManagement | PPMFeatures.ProjectManagement) && {
                    key: "warnings",
                    name: "Warnings",
                    icon: "Warning",
                    onRenderBody: () => <WarningsSettings
                        canManage={canManage}
                        user={user}
                        tenant={tenant}
                        tenantActions={tenantActions}
                        notificationsActions={notificationsActions} />
                },
                Subscription.contains(tenant.subscription, PPMFeatures.ProjectManagement) && {
                    key: nameof<Insights>("project"),
                    name: "Projects Statuses",
                    icon: "PPMXProject",
                    tooltip: <span>Define the settings for automatic calculation or manual definition of project statuses. Please refer to the <Link href="https://help.ppm.express/94162-portfolio-and-project-management/563277" target="_blank">article</Link> for more details.</span>,
                    onRenderBody: () => <ProjectStatusCalculationSettings
                        hasML={hasML}
                        data={tenant.insights.project}
                        onChange={canManage && hasML ? project => {
                            tenantActions.updateInsights({ ...tenant.insights, project });
                            trackStatusCalculation('Projects Statuses updated', user, project.statusCalculation);
                        } : undefined}
                    />
                },
                Subscription.contains(tenant.subscription, PPMFeatures.PortfolioManagement) && {
                    key: nameof<Insights>("program"),
                    name: "Programs Statuses",
                    icon: "PPMXProgram",
                    tooltip: <span>Define the settings for automatic calculation or manual definition of program statuses. Please refer to the <Link href="https://help.ppm.express/94162-portfolio-and-project-management/563277" target="_blank">article</Link> for more details.</span>,
                    onRenderBody: () => <PortfolioOrProgramStatusCalculationSettings
                        entityTitle="program"
                        hasML={hasML}
                        data={tenant.insights.program}
                        onChange={canManage && hasML ? program => {
                            tenantActions.updateInsights({ ...tenant.insights, program });
                            trackStatusCalculation('Programs Statuses updated', user, program.statusCalculation);
                        } : undefined}
                    />
                },
                Subscription.contains(tenant.subscription, PPMFeatures.PortfolioManagement) && {
                    key: nameof<Insights>("portfolio"),
                    name: "Portfolios Statuses",
                    icon: "PPMXPortfolio",
                    tooltip: <span>Define the settings for automatic calculation or manual definition of portfolio statuses. Please refer to the <Link href="https://help.ppm.express/94162-portfolio-and-project-management/563277" target="_blank">article</Link> for more details.</span>,
                    onRenderBody: () => <PortfolioOrProgramStatusCalculationSettings
                        entityTitle="portfolio"
                        hasML={hasML}
                        data={tenant.insights.portfolio}
                        onChange={canManage && hasML ? portfolio => {
                            tenantActions.updateInsights({ ...tenant.insights, portfolio });
                            trackStatusCalculation('Portfolios Statuses updated', user, portfolio.statusCalculation);
                        } : undefined}
                    />
                }
            ].filter(notBoolean)}
        />
        {tenant.isProcessing && <Overlay><Spinner /></Overlay>}
    </>
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        tenantActions: bindActionCreators(actionCreators, dispatch),
        metadataActions: bindActionCreators(MetadataStore.actionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(InsightsSettings);