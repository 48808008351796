import * as React from "react";
import * as analytics from '../../../analytics';
import { connect } from "react-redux";
import { IJiraProject, actionCreators, IJiraBoard, JiraConnectionInfo, IJiraConnectionState, IJiraLinkInfo } from "../../../store/integration/JiraStore";
import { IImportProjectMap, validateConnection, loadDataAfterConnectionValidation } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import { getValidationError, IImportEntity, IImportEntityMap } from "./common";
import { toDictionaryById } from "../../utils/common";
import { IImportProjectState } from "../../../store/integration/ImportStore";
import JiraBoardEditor from "./JiraBoardEditor";
import { ProjectsImportProps } from "./ProjectsImportPanel";
import { UserState } from "../../../store/User";
import { Column, FormatterProps } from "react-data-grid";

type OwnProps = {
    connectionId: string;
    onRender: (props: ProjectsImportProps) => JSX.Element;
}

type StateProps = {
    user: UserState;
    projects: IJiraProject[];
    boards: IJiraBoard[];
    isLoading: boolean;
    isImporting: boolean;
    error: string | null;
    connections: IJiraConnectionState;
}

type Props = OwnProps & StateProps & typeof actionCreators;

type State = {
    entities: IImportEntity[];
    columns: Column<any>[];
    connection: JiraConnectionInfo;
}

interface IJiraImportEntity extends IImportEntity {
    boards: IJiraBoard[];
}

export interface IJiraImportProjectState extends IImportProjectState {
    board?: IJiraBoard;
    boards: IJiraBoard[];
}

interface IImportJiraProjectMap extends IImportEntityMap {
    boardId?: string;
    boardName?: string;
}

class JiraProjectImport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const connection = props.connections.data.find(_ => _.id === props.connectionId)!;
        this.state = {
            entities: [],
            connection: connection,
            columns: connection.isOnPremise
                ? []
                : [{
                    key: "board",
                    name: "Project Board",
                    formatter: JiraBoardFormater,
                    editor: JiraBoardEditor,
                    editable: (rowData: IJiraImportProjectState) => rowData.boards.length > 0
                }]
        };
    }

    public render() {
        const connectionValidation = validateConnection(this.props.connections, this.props.connectionId);

        return this.props.onRender({
            label: "Projects",
            connectionId: this.props.connectionId,
            isLoading: connectionValidation?.isValidating || this.props.isLoading,
            error: getValidationError(connectionValidation) ?? this.props.error,
            onImport: this._onImport,
            warning: this.state.connection.isOnPremise
                ? "Projects sprints import is not supported for Jira Server. If you need to import Jira sprints, please use projects linking instead."
                : undefined,
            oldImportGridProps: {
                entities: this.state.entities,
                getSourcesInfo: __ => __.sourceInfos.map(_ => ({
                    sourceId: _.sourceData.uniqueKey,
                    connectionId: _.connectionId,
                    data: _
                })),
                extraColumns: this.state.columns,
                populateState: this.state.connection.isOnPremise ? undefined : this._populateState,
                populateMap: this._populateMap
            }
        });
    }

    componentWillMount() {
        const { connectionId, isImporting } = this.props;
        !isImporting && connectionId && this.props.verifyConnection(connectionId);
    }

    componentWillReceiveProps(nextProps: Props) {
        const connection = nextProps.connections.data.find(_ => _.id === nextProps.connectionId)!;
        if (this.props.connectionId !== nextProps.connectionId) {
            this.setState({ connection: connection });
        }

        loadDataAfterConnectionValidation(
            this.props.connections,
            nextProps.connections,
            connection.id,
            (_) => this._loadData(_, connection.isOnPremise));

        const projectsChanged = this.props.projects != nextProps.projects;
        const boardsChanged = this.props.boards != nextProps.boards;
        if (!nextProps.isLoading && (projectsChanged || boardsChanged)) {
            const map = connection.isOnPremise ? {} : this._buildBoardsMap(nextProps.boards);
            this.setState({
                entities: nextProps.projects.map<IJiraImportEntity>(_ => ({
                    id: _.id,
                    name: _.name,
                    fullName: `${_.name} (${_.key})`,
                    boards: map[_.id] || [],
                    isAlreadyLinked: _.isAlreadyLinked
                }))
            });
        }
    }

    private _loadData = (connectionId: string, isOnPremise: boolean) => {
        this.props.loadProjects(connectionId);
        !isOnPremise && this.props.loadBoards(connectionId);
    }

    private _populateState = (entity: IJiraImportEntity, map: IImportProjectState): IJiraImportProjectState => {
        return {
            ...map,
            boards: entity.boards,
            board: map.sourceInfo?.sourceData?.boardId
                ? entity.boards.find(_ => _.id == map.sourceInfo.sourceData.boardId)
                : entity.boards.length === 1 && entity.boards[0].isSprintsEnabled ? entity.boards[0] : undefined
        }
    }

    private _populateMap = (state: IJiraImportProjectState, map: IImportEntityMap): IImportJiraProjectMap => {
        return {
            ...map,
            boardId: state.board?.id,
            boardName: state.board?.name
        }
    }

    private _buildBoardsMap(boards: IJiraBoard[]): { [projectId: string]: IJiraBoard[] } {
        const map: { [projectId: string]: IJiraBoard[] } = {};
        boards.forEach(_ => {
            map[_.projectId] = map[_.projectId] ? map[_.projectId].concat(_) : [_];
        });
        return map;
    }

    private _onImport = (connectionId: string, maps: IImportJiraProjectMap[]) => {
        const map = toDictionaryById(this.props.projects);
        this.props.importProjects(connectionId, maps.map<IImportProjectMap<IJiraLinkInfo>>(_ => {
            return {
                linkingData: {
                    projects: [{ ...map[_.entityId] }],
                    boards: _.boardId ? [{ id: _.boardId, name: _.boardName, projectId: map[_.entityId].id } as IJiraBoard ] : [],
                    issues: []
                },
                projectId: _.projectId,
                projectName: _.projectName
            }
        }));
        analytics.trackImport('Import Projects from Jira', this.props.user, { count: maps.length });
    }
}

const JiraBoardFormater = (props: FormatterProps<IJiraImportProjectState>) => props.row.board
    ? <span title={props.row.board.name}>{props.row.board.name}</span>
    : props.row.boards.length === 0
        ? <span>No Boards</span>
        : <span></span>

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        projects: state.jira.projects.entities,
        boards: state.jira.boards.entities,
        isLoading: state.jira.projects.isLoading || state.jira.boards.isLoading,
        error: state.jira.projects.error,
        isImporting: state.import.projects.isImporting,
        connections: state.jira.connections,
        user: state.user,
    }
}

export default connect(mapStateToProps, actionCreators)(JiraProjectImport);