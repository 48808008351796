import * as React from 'react';
import { connect } from 'react-redux';
import { Program } from '../../../store/ProgramsListStore';
import { ApplicationState } from "../../../store/index";
import { IProgramInfo } from '../../../store/ProjectsListStore';
import { Dictionary, EntityType } from '../../../entities/common';
import { notUndefined } from '../../utils/common';
import { FormatterLogoItems } from './PortfolioFormatter';
import { PPMFeatures, Subscription } from '../../../store/Tenant';

type OwnProps = {
    program?: Program | IProgramInfo | IProgramInfo[];
    programIds?: string[];
    withNavigation?: boolean;
    target?: string;
};

type StateProps = {
    programsById: Dictionary<Program>;
    subscription: Subscription;
};

type Props = OwnProps & StateProps;

class ProgramFormatter extends React.PureComponent<Props>{
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { program, programIds, withNavigation, programsById, target, subscription } = this.props;

        if (!program && !programIds) {
            return null;
        }
        const entities = (programIds
            ? programIds.map(_ => programsById[_]).filter(notUndefined)
                .map(_ => ({ id: _.id, name: _.attributes.Name }))
            : !!(program as Program).attributes
                ? [{ id: (program as Program).id, name: (program as Program).attributes.Name }]
                : Array.isArray(program) ? program : [program as IProgramInfo])
            .map(_ => ({ ..._, imageId: programsById[_.id]?.imageId }));

        return <FormatterLogoItems
            entityType={EntityType.Program}
            entities={entities}
            withNavigation={withNavigation && Subscription.contains(subscription, PPMFeatures.PortfolioManagement)}
            target={target}
            cssClass="prog-logo" />
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        programsById: state.programs.byId,
        subscription: state.tenant.subscription
    }
}

export default connect(mapStateToProps, {})(ProgramFormatter);