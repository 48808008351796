import "./WhatsNew.css";
import * as React from "react";
import { Icon } from "office-ui-fabric-react";

type OwnProps = {
    big?: boolean;
}
const WhatsNew = (props: OwnProps) => <div className={`box release-notes ${props.big ? 'big' : ''}`}>
    <div className="description">
        Stay up-to-date with new<br />
        PPM Express features and updates
    </div>
    <a href="https://help.ppm.express/89154-ppm-express/release-notes-ppm-express" target="_blank" className="link">Release Notes</a>
    {props.big && <Icon iconName="PPMXQuickStartWhatsNew" />}
</div>;
export default WhatsNew;