import * as React from "react";
import { Link, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { SourceType, SourceType_ } from "../../store/ExternalEpmConnectStore";
import { ConfirmationDialog } from "../common/ConfirmationDialog";

type Props = {
    from: SourceType;
    to: SourceType;
    onDismiss: () => void;
    onClick: (isPrimary: boolean) => void;
}

const SwitchPrimaryScheduleConfirmation = (props: Props) =>
    <ConfirmationDialog
        onDismiss={props.onDismiss}
        onYes={() => props.onClick(true)}
        onNo={() => props.onClick(false)}
        yesButtonProps={{ text: "Confirm" }}
        noButtonProps={{ text: "Cancel" }}
        dialogContentProps={{
            title: 'Confirmation',
            subText: `Would you also like to switch the primary schedule from ${SourceType_.getName(props.from)} to ${SourceType_.getName(props.to)}?`
        }} >
        <MessageBar messageBarType={MessageBarType.warning}>
            Values rolled up from the primary schedule will be set into the project's main rollup fields. Please refer to this
            <Link href="https://help.ppm.express/94162-portfolio-and-project-management/2082039-project-settings?from_search=134828352#section-1" target="_blank">article</Link> for
            more details about the primary schedule.
        </MessageBar>
    </ConfirmationDialog>;

export default SwitchPrimaryScheduleConfirmation;