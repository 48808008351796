import * as React from 'react';
import { Overlay, css } from 'office-ui-fabric-react';
import { Dictionary } from '../../../entities/common';
import PostMessageReceiver from '../../integration/PostMessageReceiver';
import Spinner from '../Spinner';
import './EmbeddedContent.css'
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';

type MessageData = {
    id: string,
    type: string,
    version: string,
    data?: any,
    error?: string;
}

enum PPMXMessageType {
    RequestConnection = "ppmx_extension_request_connection",
    ResponseConnection = "ppmx_extension_response_connection",
    RequestContext = "ppmx_extension_request_context",
    ResponseContext = "ppmx_extension_response_context",
}

type OwnProps = {
    style?: React.CSSProperties,
    context?: Dictionary<any>
}

type StateProps = {
    tenantId: string;
    userId: string;
}

type Props = OwnProps & StateProps;

function tryGetUrl(url: string | URL | undefined) {
    if (!url) {
        console.error("Url is empty");
        return { success: false, value: undefined };
    }

    if (url instanceof URL) {
        return { success: true, value: url };
    }

    try {
        return { success: true, value: new URL(url) };
    } catch (err) {
        console.error("Invalid url");
        return { success: false, value: undefined }
    }
}

const EmbeddedContent = (props: Props & { url: string | URL | undefined }) => {
    const result = tryGetUrl(props.url);
    return result.success
        ? <ExternalContent {...props} url={result.value!} />
        : null;
}

const ExternalContent = (props: Props & { url: URL }) => {
    const version = "1.0.0";
    const { url, style, tenantId, userId } = props;
    const context = {...(props.context || {}), tenantId, userId };

    const [isLoading, setIsLoading] = React.useState(true);
    const channel = React.useRef<MessageChannel>();
    const closeChannel = () => {
        if (channel.current) {
            channel.current.port1.close();
            channel.current.port2.close();
            channel.current = undefined;
        }
    }

    React.useEffect(() => () => closeChannel(), []);

    const recreateChannel = () => {
        closeChannel();
        channel.current = new MessageChannel();
        channel.current.port2.onmessage = onMessage;
    }

    const _iframe = React.useRef<HTMLIFrameElement>(null);

    const setupClientPort = (e: MessageEvent, data: MessageData) => {
        if (data?.type !== PPMXMessageType.RequestConnection) {
            return;
        }
        setIsLoading(false);
        recreateChannel();
        const message: MessageData = {
            type: PPMXMessageType.ResponseConnection,
            id: data.id,
            version: version,
        }
        try {
            if (channel.current) {
                _iframe.current?.contentWindow?.postMessage(message, url.origin, [channel.current.port1]);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const onMessage = (ev: MessageEvent<MessageData>) => {
        let message: MessageData | undefined;
        if (ev.data?.type === PPMXMessageType.RequestContext) { 
            message = {
                id: ev.data.id,
                type: PPMXMessageType.ResponseContext,
                version: version,
                data: context
            }
        }
        if(!message){
            return;
        }
        try {
            channel.current?.port2.postMessage(message);
        }
        catch (error) {
            console.error(error);
        }
    };

    const validateMessage = (e: MessageEvent) => {
        return e.origin === url.origin;
    }

    return <div className="embedded-content" style={style}>
        <PostMessageReceiver<MessageData> validateMessage={validateMessage} onMessageReceived={setupClientPort} />
        <iframe ref={_iframe}
            src={url.href}
            role="presentation"
            frameBorder="0"
            className={css(isLoading && "hidden")}
            onLoad={() => setIsLoading(false)} />
        {isLoading && <Overlay><Spinner /></Overlay>}
    </div>
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        tenantId: state.tenant.id,
        userId: state.user.id!
    };
}
export default connect(mapStateToProps)(EmbeddedContent);