import * as React from 'react';
import * as Metadata from "../../../entities/Metadata";
import { FormatDate, toDate } from '../../utils/common';
import { MaybeDate } from '../../../entities/common';
import BaselineRow from './BaselineRow';

type Props = {
    field: Metadata.Field;
    date: MaybeDate;
    baseline: MaybeDate;
    variance?: number | null;
}

const BaselineDateRow = (props: Props) => {
    const dateObj = toDate(props.date);
    const baselineObj = toDate(props.baseline);
    return <BaselineRow
        label={Metadata.getLabel(props.field)}
        value={FormatDate(dateObj)}
        baseline={FormatDate(baselineObj) }
        variance={props.variance == undefined ? undefined : `${props.variance} d`}
        isRed={!!dateObj && !!baselineObj && dateObj > baselineObj} />;
}
export default BaselineDateRow;