import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as ResourcesListStore from '../../store/ResourcesListStore';
import * as ChallengesListStore from '../../store/ChallengesListStore';
import * as ObjectivesListStore from '../../store/ObjectivesListStore';
import { History } from 'history';
import * as analytics from '../../analytics';
import { IContextualMenuItem, ContextualMenuItemType } from "office-ui-fabric-react";

export type ReportNav = {
    id: string;
    type: string;
    title: string;
    sections: string[];
}

export type ReportsNav = {
    packs: ReportNav[];
    subPacks: ReportNav[];
}

export class Reporting {

    public static openPortfolioReport(history: History, report: ReportNav, portfolio: PortfoliosListStore.Portfolio) {
        this.openReportWindow(history, report, 'portfolio', portfolio.id);
    }

    public static openProgramReport(history: History, report: ReportNav, program: ProgramsListStore.Program) {
        this.openReportWindow(history, report, 'program', program.id);
    }

    public static openReport(history: History, report: ReportNav) {
        this.openReportWindow(history, report, report.type);
    }

    public static openPortfoliosReport(history: History, report: ReportNav, portfolios: PortfoliosListStore.Portfolio[]) {
        this.openReportWindow(history, report, 'portfolio', Reporting._getEntityId(portfolios));
    }

    public static openProgramsReport(history: History, report: ReportNav, programs: ProgramsListStore.Program[]) {
        this.openReportWindow(history, report, 'program', Reporting._getEntityId(programs));
    }

    public static openProjectsReport(history: History, report: ReportNav, projects: ProjectsListStore.ProjectInfo[]) {
        this.openReportWindow(history, report, 'project', Reporting._getEntityId(projects));
    }

    public static openResourcesReport(history: History, report: ReportNav, resources: ResourcesListStore.Resource[]) {
        this.openReportWindow(history, report, 'resource', Reporting._getEntityId(resources));
    }

    public static openChallengesReport(history: History, report: ReportNav, challenges: ChallengesListStore.Challenge[]) {
        this.openReportWindow(history, report, 'challenge', Reporting._getEntityId(challenges));
    }

    public static openObjectivesReport(history: History, report: ReportNav, objectives: ObjectivesListStore.Objective[]) {
        this.openReportWindow(history, report, 'objective', Reporting._getEntityId(objectives));
    }

    public static openProjectReport(history: History, report: ReportNav, project: ProjectsListStore.ProjectInfo) {
        this.openReportWindow(history, report, 'project', project.id);
    }

    public static openResourceReport(history: History, report: ReportNav, resource: ResourcesListStore.Resource) {
        this.openReportWindow(history, report, 'resource', resource.id);
    }

    //due to powerbi report url max length limitation. reports will be available in two variants: single entity or all entities
    private static _getEntityId(entities: {id: string}[]): string | undefined {
        return entities.length === 1 ? entities[0].id : undefined;
    } 

    public static openReportWindow(history: History, report: ReportNav, entityType: string, id?: string, popupWindow?: boolean) {
        analytics.page('Report', {
            title: report.title,
            type: report.type,
            entityType
        });

        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", history.createHref({pathname: `/reporting/report?id=${report.id}&entityType=${entityType}`}));

        if (popupWindow) {
            form.setAttribute("target", "formresult");
        } else {
            form.setAttribute("target", "_blank");
        }
        if(id != null){
            const hiddenFieldEntityId = document.createElement("input");
            hiddenFieldEntityId.setAttribute("type", "hidden");
            hiddenFieldEntityId.setAttribute("name", "selectedItemId");
            hiddenFieldEntityId.setAttribute("value", id);
            form.appendChild(hiddenFieldEntityId);
        }
        document.body.appendChild(form);

        if (popupWindow) {
            window.open(undefined, 'formresult', 'scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,status=no');
        }

        form.submit();
        document.body.removeChild(form);
    }

    public static buildReportsList(entityReportItems: IContextualMenuItem[], reportItems: IContextualMenuItem[]): IContextualMenuItem[] {
        let items = reportItems;
        if (entityReportItems.length > 0) {
            items.push({
                
                key: 'divider_1',
                itemType: ContextualMenuItemType.Divider
            });
            items.push({
                key: 'header',
                text: 'Advanced Pack',
                itemType: ContextualMenuItemType.Header
            });
        }

        items = items.concat(entityReportItems);
        return items;
    }
}