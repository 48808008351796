import "./TextDropdownFormatter.css";
import * as React from 'react';
import * as Metadata from "../../../../entities/Metadata";
import { ColorOption } from "../../inputs/DropdownInput";

const PlainText = ({ text }: { text: string }) => <div
    title={text}
    className='text-dropdown-formatter overflow-text'>
    {text}
</div>

const Option = ({ name, color }: { name: string, color?: string }) => <div
    title={name}
    className={`text-dropdown-formatter-option overflow-text ${color ? 'colored' : ''}`}
    style={{ backgroundColor: color }}>
    <span>
        {name}
    </span>
</div>

const SinglechoiceFormatter = ({ option, isColored }: { option: Metadata.Option, isColored: boolean }) => isColored
    ? <div className='text-dropdown-formatter singlechoice'>
        <Option {...option} />
    </div>
    : <PlainText text={option.name} />

export const MultichoiceFormatter = ({ options, isColored }: { options: Metadata.Option[], isColored?: boolean }) => !!isColored
    ? <div className='text-dropdown-formatter multichoice overflow-text'>
        { options.map(_ => <Option key={_.name} {..._} />) }
    </div>
    : <PlainText text={options.map(_ => _.name).join(", ")} />;

export const TextDropdownFormatter = React.memo((props: { value?: string | string[], options: Metadata.Option[] }) => {
    if (!props.value) {
        return null;
    }

    const isMultiValue = Array.isArray(props.value);
    const isColored = !!props.options.find(_ => !!_.color);
    if (isMultiValue) {
        const selectedOpts = props.options.filter(opt => props.value!.includes(opt.name));
        return <MultichoiceFormatter options={selectedOpts} isColored={isColored} />;
    }

    const option = props.options.find(_ => _.name === props.value);
    if (!option) {
        return null;
    }

    return <SinglechoiceFormatter option={option} isColored={isColored} />
});

export const CardDropdownFormatter = React.memo((props: { value?: string | string[], options: Metadata.Option[] }) => {
    if (!props.value) {
        return null;
    }

    const { options, value } = props;
    const isColored = options.some(_ => !!_.color);
    const selected = getSelected(value, options);
    const title = selected.map(_ => _.name).join(", ");
    return isColored
        ? <span title={title} className="card-dropdown-formatter">
            {Metadata
                .normalizeOptions(selected)
                .map(ColorOption)
                .reduce<(JSX.Element | null)[]>((prev, curr, index, array) => {
                    if(index == 0){
                        return [...prev, curr];
                    }
                    return [...prev, <span key={index}>, </span>, curr];
                }, [])}
        </span>
        : <span title={title}>{title}</span>;
});

const getSelected = (value: string | string[], options: Metadata.Option[]) => {
    if (Array.isArray(value)) {
        return options.filter(opt => value.includes(opt.name));
    }

    const selected = options.find(_ => _.name === value);
    return selected ? [selected] : [];
}