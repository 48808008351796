import React, { useState } from "react";
import { PrimaryButton, TextField, MessageBar, DefaultButton } from "office-ui-fabric-react";
import { ICalculationSettings, StatusCalculationTypes } from "../../../../../store/Tenant";
import ToggleOptionInput from "../../../inputs/ToggleOptionInput";
import { StatusViewProps } from "./StatusView";
import ResetStatusButton from "./ResetStatusButton";
import { Props as StatusHistoryProps } from "./StatusHistory";
import { StatusInsights } from "../../../../views/list/columns/ColorStatus";
import { DirtyFormMessageBar } from "../../../DirtyFormMessageBar";
import { FormatType, Group } from "../../../../../entities/Metadata";
import { formatValue } from "../../../../utils/common";
import GroupDropdown from "../../../inputs/GroupDropdown";
import * as StatusDescriptorFactory from "../../../../../entities/StatusDescriptorFactory";
import ShowMoreComponent from "../../../ShowMoreComponent";
import { CategoryStatusOption } from "../../../../../entities/StatusDescriptor";
import { contains } from "../../../../../store/permissions";
import CategoryColorStatusView from "../../../../views/list/columns/CategoryColorStatusView";

const defaultOverrideDurationDays: number = 7;
const overrideDurationDays: number[] = [3, 5, defaultOverrideDurationDays, 10];

export type StatusEditProps = {
    calculation: ICalculationSettings;
    onEditComplete: (status: CategoryStatusOption | undefined, description: string | undefined, overrideDuration: number | undefined) => void;
    resetStatus: () => void;
}

type Props = StatusViewProps & StatusEditProps & StatusHistoryProps & {
    onClose: () => void;
    onFormChanged: () => void;
    showHasChangesWarning?: boolean;
};

const cancelCommandText = "Cancel";

const StatusEdit = (props: Props) => {
    const { insights, calculation, statusField, showHasChangesWarning, onEditComplete, onClose, onFormChanged } = props;
    const options = StatusDescriptorFactory.createStatusDescriptor(statusField).getOptions()!;

    const [status, setStatus] = useState(props.statusOption!);
    const [overrideDuration, setOverrideDuration] = useState(calculation.statusCalculation === StatusCalculationTypes.AutoManual
        ? defaultOverrideDurationDays
        : undefined);
    const [description, setDescription] = useState(props.description);
    const [hasChanges, setHasChanges] = useState(false);
    const readOnlyStatus = calculation.statusCalculation === StatusCalculationTypes.Auto;
    const updateOnlyDescription = calculation.statusCalculation === StatusCalculationTypes.Auto && calculation.manualDescription;
    const primaryCommandText = updateOnlyDescription
        ? "Update Description"
        : "Update Status";

    return (
        <>
            {
                calculation.statusCalculation === StatusCalculationTypes.AutoManual && (
                    <MessageBar>
                        Changing status manually will override automatically calculated status
                    </MessageBar>
                )
            }
            <div className="status-edit">
                <div className="label">Status</div>
                <GroupDropdown
                    value={status && buildGroup(status)}
                    groups={options.map<Group>(_ => (buildGroup(_)))}
                    dropdownInputProps={{
                        disabled: readOnlyStatus,
                        onChanged: _ => {
                            setStatus(_);
                            setHasChanges(true);
                            onFormChanged();
                        }
                    }}
                />
                <div className="color-status-info">
                    <StatusInsights insights={insights} />
                </div>
                <div className="label">Description</div>
                <ShowMoreComponent containerClassName={'show-more-field'}>
                    <TextField
                        multiline
                        resizable={false}
                        autoAdjustHeight
                        value={description}
                        borderless
                        onChange={(e, descr) => {
                            setDescription(descr);
                            setHasChanges(true);
                            onFormChanged();
                        }}
                    />
                </ShowMoreComponent>
                {calculation.statusCalculation === StatusCalculationTypes.AutoManual && <>
                    <div className="label">Override duration</div>
                    <ToggleOptionInput
                        inputProps={{ options: overrideDurationDays.map(_ => ({ key: _, text: `${formatValue(_, FormatType.Days)}` })) }}
                        onChanged={_ => {
                            setOverrideDuration(_);
                            setHasChanges(true);
                            onFormChanged();
                        }}
                        value={overrideDuration} />
                    <span className="sub">Select the duration of automatic status calculation override.</span>
                </>}
            </div>
            {showHasChangesWarning && <DirtyFormMessageBar primaryCommandLabel={primaryCommandText} cancelCommandLabel={cancelCommandText} />}
            <div className="commands justify">
                <div>
                    <PrimaryButton
                        text={primaryCommandText}
                        disabled={!hasChanges}
                        onClick={() => {
                            onEditComplete(updateOnlyDescription ? undefined : status, description, overrideDuration);
                            onClose();
                        }}
                    />
                    <DefaultButton text={cancelCommandText} onClick={onClose} />
                </div>
                {
                    calculation.statusCalculation === StatusCalculationTypes.AutoManual && <ResetStatusButton {...props} />
                }
            </div>
        </>
    );
}

function buildGroup(status: CategoryStatusOption): Group {
    return { ...status, id: status.name, isDefault: false, isShown: true };
}

export default StatusEdit;
