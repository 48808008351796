import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { actionCreators as vstsActionCreators, IWorkItemType, IConnectionConfiguration, IProgressCalculationSettings, vstsFieldToPpmxFieldsMap } from '../../../store/integration/VSTSStore';
import VSTSProgressCalculationSettings from './VSTSProgressCalculationSettings';
import VSTSMappingControl from './VSTSMappingControl';
import { ApplicationState } from "../../../store/index";
import { ExternalFieldInfo, IExternalTypeWithStatus, IFieldMapping, TaskImportSettings } from "../../../store/integration/common";
import { Field } from "../../../entities/Metadata";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import ConfigureConnectionPanel from '../ConfigureConnectionPanel';
import ImportTaskSettings from '../ImportTaskSettings'
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { PivotItem } from 'office-ui-fabric-react';
import { get } from '../../../fetch-interceptor';
import { EntityType } from '../../../entities/common';

type OwnProps = {
    onDismiss: () => void;
    connectionId: string;
    projectId: string;
    enableMapping: boolean;
    initialTab?: string;
}
type StateProps = {
    workItemTypes: IWorkItemType[];
    configuration: IConnectionConfiguration;
    defaultTaskSettings: TaskImportSettings;
    ppmxfields: Field[];
    vstsFields: ExternalFieldInfo[];
    user: UserState;
    isLoading: boolean;
    isProcessing: boolean;
    error: string | null;
}
type Props = OwnProps & StateProps & {
    vstsActionCreators: typeof vstsActionCreators;
};

export enum VSTSTabs {
    ProgressCalculation = "progress-calculation",
    ImportTask = "import-task"
}

type State = {
    workItemTypes: IExternalTypeWithStatus[];
}

class VSTSConfigureConnectionPanel extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { workItemTypes: [] };

        const { connectionId, projectId } = this.props;
        this.props.vstsActionCreators.loadDefaultTaskMappings(connectionId, projectId);
        this.props.vstsActionCreators.loadConnectionConfiguration(connectionId, projectId);
        this.props.vstsActionCreators.loadWorkItemTypes(connectionId, projectId);
        this.props.vstsActionCreators.loadFields(connectionId, projectId);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.workItemTypes !== nextProps.workItemTypes) {
            const workItemTypes = nextProps.workItemTypes.map(_ => ({
                id: _.name,
                name: _.name,
                iconUrl: _.icon?.url ?? "",
                statuses: _.states.map(__ => ({ name: __.name, categoryKey: __.category }))
            }));
            this.setState({ workItemTypes });
        }
    }

    render() {
        return <ConfigureConnectionPanel
            {...this.props}
            sourceType={SourceType.VSTS}
            pivots={this._getPivots()}
        />
    }

    private _getPivots = () => {
        const { connectionId, projectId, enableMapping, workItemTypes, configuration, defaultTaskSettings, user, vstsFields, onDismiss, error, isLoading, isProcessing } = this.props;
        const isReadOnly = !contains(user.permissions.common, CommonOperations.ConnectionManage);

        const pivots = [
            <PivotItem headerText="Project Progress" className="pivot-content" itemKey={VSTSTabs.ProgressCalculation} key="progress">
                <VSTSProgressCalculationSettings connectionId={connectionId}
                    projectId={projectId}
                    workItemTypes={workItemTypes}
                    progressCalculationSettings={configuration.progressCalculationSettings}
                    readOnly={isReadOnly}
                    error={error}
                    isLoading={isLoading}
                    isProcessing={isProcessing}
                    onDismiss={onDismiss}
                    onSave={this._updateProgressCalculationSettings}/>
            </PivotItem>,
            <PivotItem headerText="External Items Mapping" className="pivot-content" itemKey={VSTSTabs.ImportTask} key="tasks">
                <ImportTaskSettings
                    sourceType={SourceType.VSTS}
                    typeLabel="work item"
                    getExternalFields={() => vstsFields}
                    externalTypes={this.state.workItemTypes}
                    taskImportSettings={configuration.taskImportSettings}
                    readOnly={isReadOnly}
                    onDismiss={onDismiss}
                    onSave={this._updateTaskImportSettings}
                    getDefaultTaskImportmapping={(type) => defaultTaskSettings[type]}
                    externalFieldToPpmxFieldsMap={vstsFieldToPpmxFieldsMap}
                    error={error}
                    isLoading={isLoading}
                    isProcessing={isProcessing}
                    helpLink="https://ppmx.helpjuice.com/89488-azure-devops-connection/how-to-set-up-additional-fields-mapping-between-ppm-express-and-azure-devops#section-1"
                    onGetExternalFieldOptions={this._onGetExternalFieldOptions}
                />
            </PivotItem>
        ];

        if (enableMapping) {
            pivots.push(this._renderMappingPivotItem(isReadOnly));
        }
        return pivots;
    }

    private _renderMappingPivotItem = (isReadOnly: boolean) => {
        const { configuration, ppmxfields, vstsFields, onDismiss, error, isLoading, isProcessing } = this.props;
        return (
            <PivotItem headerText="Project Mapping" className="pivot-content" key="project">
                <VSTSMappingControl 
                    mapping={configuration.mapping}
                    ppmxfields={ppmxfields}
                    vstsFields={vstsFields}
                    readOnly={isReadOnly}
                    onDismiss={onDismiss}
                    onSave={this._updateMappingSettings}
                    error={error}
                    isLoading={isLoading}
                    isProcessing={isProcessing} />
            </PivotItem>
        );
    }

    private _updateMappingSettings = (mapping: IFieldMapping[]) => {
        this._updateConnectionConfiguration({ ...this.props.configuration, mapping });
    }
    private _updateProgressCalculationSettings = (progressCalculationSettings: IProgressCalculationSettings) => {
        this._updateConnectionConfiguration({ ...this.props.configuration, progressCalculationSettings });
    }
    private _updateTaskImportSettings = (taskImportSettings: TaskImportSettings) => {
        this._updateConnectionConfiguration({ ...this.props.configuration, taskImportSettings });
    }
    private _updateConnectionConfiguration(configuration: IConnectionConfiguration) {
        const { connectionId, projectId } = this.props;
        this.props.vstsActionCreators.updateConnectionConfiguration(connectionId, projectId, configuration);
    }

    private _onGetExternalFieldOptions = (field: ExternalFieldInfo, issueTypeId: string) => {
        const { connectionId, projectId } = this.props;
        return get<{ options: string[] | number[] | null }>(`api/integration/vsts/field/${field.internalName}/options`, { connectionId, projectId, workItemType: issueTypeId })
                .then(_ => _.options);
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const fields = state.fields[EntityType.Project];
    return {
        workItemTypes: state.vsts.workItemTypes.entities.map(_ => {
            _.states = _.states.filter(__ => __.category !== "Removed");
            return _;
        }),
        configuration: {
            progressCalculationSettings: state.vsts.progressCalculationSettings.entities,
            mapping: state.vsts.mapping.entities,
            taskImportSettings: state.vsts.taskImportSettings.entities
        },
        defaultTaskSettings: state.vsts.defaultTaskSettings.entities,
        vstsFields: state.vsts.fields.entities,
        ppmxfields: fields.allIds.map(_ => fields.byId[_]),
        user: state.user,
        error: state.vsts.workItemTypes.error ||
            state.vsts.progressCalculationSettings.error ||
            state.vsts.taskImportSettings.error,
        isLoading: state.vsts.workItemTypes.isLoading ||
            state.vsts.progressCalculationSettings.isLoading ||
            state.vsts.defaultTaskSettings.isLoading,
        isProcessing: state.vsts.progressCalculationSettings.isProcessing
    }
}
function mergeActionCreators(dispatch: any) {
    return {
        vstsActionCreators: bindActionCreators(vstsActionCreators, dispatch)
    }
}
export default connect(mapStateToProps, mergeActionCreators)(VSTSConfigureConnectionPanel);