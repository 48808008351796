import * as React from 'react';
import "./SelectEntityPanel.css"
import { PanelType, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { IFindResult } from '../inputs/EntityPickerInput';
import { ResourceOperations } from '../../../store/permissions';
import ExpandablePanel from '../ExpandablePanel';
import { EntityType, entityTypeLabelMap } from '../../../entities/common';
import ComboboxPicker from '../inputs/ComboboxPicker';
import { ItemCreation } from '../ItemCreation';

type Props = {
    searchUrl: string;
    entityType: EntityType;
    headerDescription: string;
    entityId?: string;
    entityTitle?: string;
    readOnly?: boolean;
    onDismiss: () => void;
    onSave: (entityId?: string, entityTitle?: string) => void;
}

const SelectEntityPanel = (props: Props) => {
    const [entityId, setEntityId] = React.useState(props.entityId);
    const [entityTitle, setEntityTitle] = React.useState(props.entityTitle);

    const _onSave = () => {
        props.onSave(entityId, entityTitle);
        props.onDismiss();
    }

    const entityTypeTitle = entityTypeLabelMap[props.entityType].singular;
    const isDirty = entityId !== props.entityId;

    return <ItemCreation
        customWidth="400px"
        onDismiss={props.onDismiss}
        header={{
            text: `Select ${entityTypeTitle}`,
            secondaryText: props.headerDescription,
            showNameEditor: false
        }}
        isDirty={isDirty}
        commands={[
            {
                primary: true,
                text: `Save`,
                onClick: _onSave ,
                disabled: !isDirty || props.readOnly
            }, {
                text: 'Cancel',
                onClick: props.onDismiss
            }
        ]}
        className={`field-panel entity-select-panel ${isDirty ? " not-saved" : ""}`}>
    
        <div className="field-label">{entityTypeTitle}</div>

        <ComboboxPicker
            inputProps={{ placeholder: `Select ${entityTypeTitle}`, disabled: props.readOnly }}
            searchUrl={props.searchUrl}
            selectedKey={entityId}
            selectedText={entityTitle}
            filter={{ accessLevel: ResourceOperations.Read }}
            onEditComplete={(find?: IFindResult) => { setEntityId(find?.id); setEntityTitle(find?.name) }} />

    </ItemCreation >
}

export default SelectEntityPanel;
