import { TextField } from 'office-ui-fabric-react';
import './AIReviewDigestDataControl.css';
import React from 'react';
import Spinner from '../../../common/Spinner';
import { AiDigest } from '../common';

type Props = {
    aiDigest: AiDigest;
    isLoading: boolean;
    onDigestChange: (value: string) => void;
}

const AIReviewDigestDataControl = (props: Props) => {
    const { aiDigest, isLoading, onDigestChange } = props;

    return <>{ isLoading
        ? <Spinner text={"Generating AI Project Digest"} />
        : aiDigest.errorMessage 
            ? <div className='error-message'>{aiDigest.errorMessage}</div>
            : <div className='digest-contrainer'> 
                <TextField
                    multiline
                    value={aiDigest.digest}
                    onChange={(e, _) => onDigestChange(_!)}
                    resizable={false}
                    placeholder='Enter a digest...' />
            </div>
    }</>
}

export default AIReviewDigestDataControl;