import * as React from 'react';
import { AuthProvider, AuthProvidersMap } from '../../entities/common';
import { ApplicationState } from '../../store';
import { Dropdown } from 'office-ui-fabric-react';
import { connect } from 'react-redux';

interface OwnProps {
    label?: string;
    disabled?: boolean;
    authProvider?: AuthProvider;
    onChange: (authProvider: AuthProvider) => void;
}
interface StateProps {
    allowedAuthProviders?: AuthProvider[];
}

const AnyDefaultAuthProviderKey = "";

export const buildAllowedAuthProvidersOptions = (allowedAuthProviders: AuthProvider[]) => {
    return [
        { key: AnyDefaultAuthProviderKey, text: "Any" },
        ...allowedAuthProviders.map((_) => ({ key: _, text: AuthProvidersMap[_].friendlyName }))
    ]
}

type Props = OwnProps & StateProps;
const AuthProviderSelect = (props: Props) => {
    const { allowedAuthProviders, authProvider, onChange, label } = props;
    const allowedAuthProvidersOptions = React.useMemo(() => buildAllowedAuthProvidersOptions(allowedAuthProviders!), [allowedAuthProviders]);

    return <Dropdown
        label={label}
        disabled={props.disabled}
        selectedKey={authProvider ?? AnyDefaultAuthProviderKey}
        options={allowedAuthProvidersOptions}
        onChange={(e, _) => onChange(_!.key as AuthProvider)}
    />
};

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        allowedAuthProviders: state.tenant.security?.allowedAuthProviders,
    };
}

export default connect(mapStateToProps)(AuthProviderSelect);
