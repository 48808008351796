import * as React from "react";
import { IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import { notUndefined } from "../../utils/common";

type Props = {
    onRename?: () => void;
    onEdit?: () => void;
    onUpgrade?: () => void;
    onDelete?: () => void;
}
const ConnectionBoxMenu = (props: Props) => {
    const items: IContextualMenuItem[] = [
        !props.onRename ? undefined : {
            key: 'rename',
            name: 'Rename',
            iconProps: { iconName: "Rename" },
            onClick: props.onRename
        },
        !props.onEdit ? undefined : {
            key: 'edit',
            name: 'Refresh Connection',
            iconProps: { iconName: "Refresh" },
            onClick: props.onEdit
        },
        !props.onUpgrade ? undefined : {
            key: 'upgrade',
            name: 'Request full access',
            iconProps: { iconName: "Unlock" },
            onClick: props.onUpgrade,
        },
        !props.onDelete ? undefined : {
            key: 'delete',
            name: 'Delete',
            iconProps: { iconName: "Delete" },
            onClick: props.onDelete
        }
    ].filter(notUndefined);
    return <IconButton
        className="menu"
        menuIconProps={{ iconName: 'More' }}
        menuProps={{ items }}
    />;
}
export default ConnectionBoxMenu;