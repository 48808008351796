import React from 'react'
import LabellableComponent from '../../common/LabellableComponent';
import FlagInput from '../../common/inputs/FlagInput';
import { TimeTrackingGlobalSettings } from '../../../store/Tenant';
import DropdownInput from '../../common/inputs/DropdownInput';
import { entityTypeLabelMap, mapServerEntityType, ServerEntityType } from '../../../entities/common';

type Props = {
    saveSettings: (settings: Partial<TimeTrackingGlobalSettings>, callback?: () => void) => void;
    settings: TimeTrackingGlobalSettings;
    canManage: boolean;
}


export default function TimeTrackingCostTypeAndBillableSettings(props: Props) {

    const { canManage, settings, saveSettings } = props;

    return (
        <div className="settings-fields-holder time-tracking-settings">
            <div className="settings-row">
              
                <LabellableComponent
                    className="field-container"
                    label="Users can override Cost Type value"
                    description={(<>
                        By default, the Cost Type field values are synchronized from the corresponding {_getEnityTypeLabel(settings.costTypeSource)} level field to the Timesheet entry field.
                        Specify if users in your tenant can manually change the values in Timesheet entries.</>
                    )}>
                    <FlagInput value={settings.enableEditingCostType}
                        onChanged={_ => saveSettings({ enableEditingCostType: _ })}
                        readOnly={!canManage} />
                </LabellableComponent>
                

                <LabellableComponent
                    className="field-container"
                    label="Users can override Billable Type value"
                    description={(<>
                        By default, the Billable field values are synchronized from the corresponding {_getEnityTypeLabel(settings.billableSource)} level field to the Timesheet entry field.
                        Specify if users in your tenant can manually change the values in Timesheet entries</>
                    )}>
                    <FlagInput value={settings.enableEditingBillable}
                        onChanged={_ => saveSettings({ enableEditingBillable: _ })}
                        readOnly={!canManage} />
                </LabellableComponent>
                        
                <LabellableComponent
                    className="field-container"
                    label="Use Cost Type value from"
                    description={"Define if the Cost Type field values will be synchronized from the corresponding Task level field or Project level field to the Timesheet entry."}>
                    <DropdownInput
                        value={settings.costTypeSource}
                        readOnly={!canManage}
                        inputProps={{
                            options: _sourceOptions
                        }}
                        onChanged={(_) => {
                            saveSettings({
                                costTypeSource: _
                            });
                        }}
                    />
                </LabellableComponent>
                        
                <LabellableComponent
                    className="field-container"
                    label="Use Billable value from"
                    description={"Define if the Billable field values will be synchronized from the corresponding Task level field or Project level field to the Timesheet entry."}>
                    <DropdownInput
                        value={settings.billableSource}
                        readOnly={!canManage}
                        inputProps={{
                            options: _sourceOptions
                        }}
                        onChanged={(_) => {
                            saveSettings({
                                billableSource: _
                            });
                        }}
                    />
                </LabellableComponent>
            </div>
        </div>
    );
}

const _getEnityTypeLabel = (serverEntityType: ServerEntityType): string => {
    const entityType = mapServerEntityType[serverEntityType];
    return entityTypeLabelMap[entityType!].singular;
}

const _sourceOptions: { key: ServerEntityType, text: string }[] =
    [
        ServerEntityType.Task,
        ServerEntityType.Project
    ].map(_ => {
        return {
            key: _,
            text: _getEnityTypeLabel(_)
        }
    });