import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Metadata from "../../entities/Metadata";
import { UserState } from '../../store/User';
import { contains, CommonOperations } from '../../store/permissions';
import { Icon, IconButton } from 'office-ui-fabric-react';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import { EntityType, Dictionary, UserPreferencesSettingsUpdate } from '../../entities/common';
import { FilterHelper, ProjectFilterValue } from '../../store/project/filters';
import EntityHeader from '../common/EntityHeader';
import { CounterFilter } from './common';
import { Program } from '../../store/ProgramsListStore';
import { Portfolio } from '../../store/PortfoliosListStore';
import { Insights } from '../../store/InsightsStore';
import ProjectsFilter from '../project/ProjectsFilter';
import { EditLayoutPanel, EditType } from '../common/EditLayoutPanel';
import { PreferenceUpdates } from '../../store/services/viewSaver';
import { ControlSettings } from './PPMInsights';
import EntitiesFilterTags from '../common/EntitiesFilterTags/EntitiesFilterTags';

export interface IActions {
    updateSections: (updates: Dictionary<Metadata.IUpdateSectionInfo>) => void;
    onFilterChange: (projects: ProjectsListStore.ProjectInfo[], filterId?: string, preFilterId?: string) => void;
    onCounterFilterChange: (filter?: CounterFilter) => void;
    onSaveSettings?: (update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => void;
}

type DataContext = {
    filters: Metadata.IFilter<ProjectFilterValue>[];
    counterFilter?: CounterFilter;
    sections: Metadata.Section[];
    projectFields: Metadata.Field[];
    projects: ProjectsListStore.ProjectInfo[];
    programs: Program[];
    portfolios: Portfolio[];
}

type OwnProps = {
    entity: { insights: Insights[] };
    datacontext: DataContext;
    actions: IActions;
    controlSettings: ControlSettings;
}

type StateProps = {
    user: UserState;
    projectFields: Metadata.Field[];
    settingsIsLoading: boolean;
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;
const InsightsHeader = (props: Props) => {
    const { datacontext, actions, controlSettings, settingsIsLoading } = props;

    const [isLayoutPanelOpen, setIsLayoutPanelOpen] = React.useState(false);
    const [isFilterPanelOpen, setIsFilterPanelOpen] = React.useState(false);

    const filter = React.useMemo(() => datacontext.filters.find(_ => controlSettings.activeFilter?.filterId === _.id),
        [datacontext.filters, controlSettings.activeFilter]);
    const canManageConfiguration = contains(props.user.permissions.common, CommonOperations.ConfigurationManage)

    const menuItems = [{
        key: 'configureLayout',
        iconProps: { iconName: 'ActivateOrders' },
        name: 'Configure Layout',
        onClick: () => setIsLayoutPanelOpen(true)
    }];

    const getAttributeValue = (attrType: keyof ProjectFilterValue, value: any): string[] => {
        return new FilterHelper({
            portfolios: props.datacontext.portfolios,
            programs: props.datacontext.programs,
            projects: props.datacontext.projects,
            projectFields: props.datacontext.projectFields,
            layouts: []
        }).helpersMap[attrType].getAttributeValues(value);
    }

    return <>
        <EntityHeader
            name="PPM Insights"
            nameTitle="PPM Insights"
            logo={<div className="logo insights-logo"></div>}
            rigthSide={<>
                <EntitiesFilterTags
                    getAttributeValue={getAttributeValue}
                    filter={filter}
                    showRightDivider={!!datacontext.counterFilter}
                    onTagClick={filter && (!filter.isPublic || canManageConfiguration)
                        ? () => setIsFilterPanelOpen(!isFilterPanelOpen)
                        : undefined}
                >
                    {datacontext.counterFilter && <div className="filter-tag align-center" title={datacontext.counterFilter.label}>
                        <div className="name overflow-text">
                            {datacontext.counterFilter.label}
                        </div>
                        <Icon iconName="Cancel" className="clickable" title="Remove Filter" onClick={() => props.actions.onCounterFilterChange()} />
                    </div>}
                </EntitiesFilterTags>
                {!settingsIsLoading && <ProjectsFilter
                    isFilterPanelOpen={isFilterPanelOpen}
                    toggleFilterPanel={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
                    activePrefilterId={props.controlSettings.activeFilter?.preFilterId}
                    activeFilterId={props.controlSettings.activeFilter?.filterId}
                    projects={datacontext.projects}
                    programs={datacontext.programs}
                    portfolios={datacontext.portfolios}
                    fields={datacontext.projectFields}
                    onFilterChange={(projects, filterId, preFilterId) => {
                        actions.onSaveSettings?.(PreferenceUpdates.filter({
                            filterId,
                            preFilterId: preFilterId ?? null
                        }), filterId !== Metadata.NEW_ID);

                        actions.onFilterChange(projects);
                    }}
                />}
            </>}
            buttons={<IconButton
                menuIconProps={{ iconName: 'More' }}
                className='menu'
                text='More'
                menuProps={{ items: menuItems, className: 'section-menu' }} />
            }
        ></EntityHeader>
        {
            isLayoutPanelOpen && <EditLayoutPanel
                editType={EditType.Edit}
                layout={{ id: Metadata.DEFAULT_ID, sections: datacontext.sections } as Metadata.Layout}
                onSave={update => props.actions.updateSections(update.sections!)}
                onDismiss={() => setIsLayoutPanelOpen(false)} />
        }
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    const fields = state.fields[EntityType.Project];
    return {
        user: state.user,
        settingsIsLoading: state.insightsSettings.isLoading,
        projectFields: fields.allIds.map(_ => fields.byId[_]),
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(InsightsHeader));