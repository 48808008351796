import React, { useMemo } from 'react';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { getTimeZones, TimeZone } from "@vvo/tzdb";

export const ianaTimeZones = getTimeZones();

type Props = {
    value: string;
    disabled: boolean;
    label: string;
    onChange: (value: string) => void;
    timezones?: TimeZone[];
}

const TimezoneSelector = (props: Props) => {
    const { value, timezones = ianaTimeZones } = props;
    const options = useMemo(() => {
        let timezoneOptions = timezones
            .map(entry => ({
                name: entry.name,
                group: entry.group,
                label: entry.currentTimeFormat,
                offset: entry.currentTimeOffsetInMinutes
            }))
            .sort((a, b) => a.offset - b.offset);
        
        // If tenant has an obsolete timezone name, its timezone is not found in ianaTimeZones names,
        // since ianaTimeZones names are actual ones, not obsolete.
        // Instead, we should seek for a timezone using obsolete name in timezone groups (obsolete timezone names are there).
        if (!timezoneOptions.some(_ => _.name === value)) {
            timezoneOptions = timezoneOptions
                .map(tz => ({ ...tz, name: tz.group.find(_ => _ === value) ?? tz.name }));
        }

        return timezoneOptions.map<IDropdownOption>(_ => ({ key: _.name, text: _.label }));
    }, [timezones, value]);
    
    return (
        <Dropdown
            selectedKey={value}
            {...props}
            onChange={(e, o) => props.onChange('' + o?.key)}
            options={options}
        />
    );
}

export default TimezoneSelector;
