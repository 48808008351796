import * as React from "react";
import { Dictionary } from "../../entities/common";
import { Toggle, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { FieldInfo } from "../../entities/Metadata";
import { ISettingsEditComponent, IBaseFieldInfo, FieldActionTypes } from "./FieldPanel";

type Props = {
    isNative: boolean;
    settings?: Dictionary<any>;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
}

type State = {
    multiline: boolean;
    switchedToMultiline?: boolean;
}

export class TextSettingsEdit extends React.Component<Props, State> implements ISettingsEditComponent {
    constructor(props: Props) {
        super(props);
        this.state = {
            multiline: props.settings ? props.settings['multiline'] == true : false
        };
    }

    public render() {
        const { multiline, switchedToMultiline } = this.state;
        const { actionType, isNative, settings } = this.props;

        return <>
            <Toggle label='Multiline'
                key='toggle'
                checked={multiline}
                disabled={isNative || actionType == FieldActionTypes.Edit && settings && settings['multiline']}
                onChange={(e, checked) => { this.setState({ multiline: !!checked, switchedToMultiline: !!checked }); this.props.onChange(true); }}
                onText='Yes'
                offText='No' />
            {
                switchedToMultiline && <MessageBar key="msgbar" messageBarType={MessageBarType.warning}>
                    Note, if you enable Multiline option, you won't be able to change it back later.
                </MessageBar>
            }
            </>;
    }

    public save(fieldInfo: FieldInfo) {
        this._applyChanges(fieldInfo);
    }

    public update(fieldInfo: IBaseFieldInfo) {
        this._applyChanges(fieldInfo);
    }

    private _applyChanges(fieldInfo: IBaseFieldInfo | FieldInfo) {
        fieldInfo.settings = {
            ...fieldInfo.settings,
            multiline: this.state.multiline
        };
    }
}
