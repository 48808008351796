import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router-dom';
import * as ChallengesListStore from '../../../store/ChallengesListStore';
import { ActiveFilter as ChallengeActiveFilter } from '../../../store/challenge/filters';
import { IEntityCardProps } from '../../common/interfaces/IEntity';
import Logo from "../../common/Logo";
import Link from '../../common/Link';
import { Dictionary, IUserInfo } from "../../../entities/common";
import * as Metadata from "../../../entities/Metadata";
import { FormatDateTime } from "../../utils/common";
import CardMetric, { CardMetricConfig } from './CardMetric';
import ResourceFormatter from '../../common/formatters/ResourceFormatter';
import { nameof } from '../../../store/services/metadataService';

export type ChallengeCardState = {};
type ChallengeCardOwnProps = {
    onMenuRender: (entity: ChallengesListStore.Challenge) => JSX.Element | null;
    getLink: (entity: ChallengesListStore.Challenge) => string;
    fields: Dictionary<Metadata.Field>;
} & IEntityCardProps<ChallengesListStore.Challenge, ChallengeCardState>;

type ChallengeCardProps = ChallengeCardOwnProps & RouteComponentProps<{}>;

class ChallengeCard extends React.Component<ChallengeCardProps> {
    constructor(props: ChallengeCardProps) {
        super(props);
    }

    public render() {
        const { entity, fields } = this.props;
        const { attributes } = entity;
        return (
            <Link className="card flex-card challenge-card" href={this.props.getLink(this.props.entity)}>
                <div className={"content" + (attributes.Stage == ChallengesListStore.ChallengeStage.Draft ? " draft" : "")}>
                    <div className="first-row">
                        <div style={{ "float": "left" }}>
                            <Logo imageId={this.props.entity.imageId} className="challenge-logo" />
                        </div>
                        <div className="info">
                            <div className="title overflow-text" title={attributes.Name}>{attributes.Name}</div>
                            <div className="align-center">
                                <span className="progress-tip">{entity.calculation.activeIdeasCount}</span>
                                <span className="ideas-couner">Active Ideas</span>
                            </div>
                        </div>
                    </div>
                    <div className="metrics">
                        {
                            this.renderMetrics(this.props.entity.attributes, fields)
                        }
                    </div>
                    <div className="separator"></div>
                    <div className="row big">
                        <div className="title-container">
                            <Icon iconName="PPMXBusinessIdeas" className="card-icon" />
                            <span className="title">Details</span>
                        </div>
                        <div className="align-center">
                            <span className="description">{attributes.Description}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="align-center">
                            <Icon iconName="PPMXCardManager" className="card-icon" />
                            <span className="title">Manager</span>
                        </div>
                        {
                            attributes.Manager.length > 0
                                ? <div className="align-center">
                                    <ResourceFormatter resource={attributes.Manager} onClick={this.goToChallenges} />
                                </div>
                                : <div className="align-center">
                                    <span>(Manager is not assigned yet)</span>
                                </div>
                        }
                    </div>
                    <div className="separator"></div>
                    <div className="actions">
                        {this.props.onMenuRender(this.props.entity)}
                    </div>
                </div>
            </Link>
        );
    }

    private renderMetrics(attrs: ChallengesListStore.ChallengeAttrs, fields: Dictionary<Metadata.Field>): JSX.Element[] {
        const stageConfig = ChallengesListStore.challengeStagesMap[attrs.Stage];

        const metrics: { config: CardMetricConfig, key: string }[] = [
            {
                config: {
                    title: "Stage",
                    value: (stageConfig && stageConfig.title) || attrs.Stage,
                    valueClassName: `colorfull ${(stageConfig && stageConfig.cssClassName) ? stageConfig.cssClassName : ''}`
                },
                key: "stage"
            },{
                config: {
                    title: "Created Date",
                    value: FormatDateTime(attrs.CreatedDate),
                },
                key: "creaated-date"
            }, {
                config: {
                    title: "Area",
                    value: attrs.Area,
                    valueStyle: {
                        color: Metadata.getOption(fields[nameof<ChallengesListStore.ChallengeAttrs>("Area")], attrs.Area)?.color 
                    }
                },
                key: "area"
            }
        ];

        return metrics.map(_ => <CardMetric config={_.config} key={_.key} />);
    }

    private goToChallenges = (e: any, resource: IUserInfo) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.history.push(`/challenges`, new ChallengeActiveFilter("Custom").withManager(resource).build());
    }
}

export default withRouter<ChallengeCardOwnProps>(ChallengeCard);