import * as React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, TextField, Overlay } from 'office-ui-fabric-react';
import { Validator } from '../../../validation';
import { TenantState } from '../../../store/Tenant';
import { UserState } from '../../../store/User';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { get, post } from '../../../fetch-interceptor';
import Spinner from '../../common/Spinner';
import * as analytics from '../../../analytics';
import * as Notifications from "../../../store/NotificationsStore";
import { catchApiError } from '../../../store/utils';
import { removeEmptyProps } from '../../utils/common';
import { BillingPlan } from './common';

type OwnProps = { plan: BillingPlan; onDismiss: () => void };
type StateProps = { tenant: TenantState, user: UserState };
type Props = OwnProps & StateProps & typeof Notifications.actionCreators;

type State = {
    fullName?: string;
    email?: string;
    phone?: string;
    jobTitle?: string;

    company?: string;
    webSite?: string;
    message?: string;

    isLoading: boolean;
    isSending: boolean;
}

const validator = {
    fullName: Validator.new().required().build(),
    email: Validator.new().required().email().build(),
    phone: Validator.new().required().phone().build(),
    jobTitle: Validator.new().required().build(),
    company: Validator.new().required().build(),
    webSite: Validator.new().required().url().build(),
    message: Validator.new().required().build()
}

export class ContactUsDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false, isSending: false };
    }

    componentWillMount() {
        get<State>("api/billing/contactus")
            .then(_ => this.setState({ ...removeEmptyProps(_), isLoading: false }))
            .catch(_ => this.setState({ isLoading: false }))
        this.setState({ isLoading: true })
    }

    public render() {
        const { isSending } = this.state;
        if (this.state.isLoading) {
            return <Overlay><Spinner /></Overlay>
        }

        return <Dialog
            minWidth={500}
            dialogContentProps={{ title: "Contact us" }}
            modalProps={{ className: "Contact-us-dialog" }}
            hidden={false}
            onDismiss={this.props.onDismiss}>
            <TextField
                label="Name"
                placeholder="Full Name"
                required={true}
                value={this.state.fullName}
                onGetErrorMessage={validator.fullName.getErrorMessage}
                validateOnLoad={true}
                onChange={(e, _) => this.setState({ fullName: _ })} />
            <TextField
                label="Email (Corporate)"
                placeholder="Email Address"
                required={true}
                value={this.state.email}
                onGetErrorMessage={validator.email.getErrorMessage}
                validateOnLoad={true}
                onChange={(e, _) => this.setState({ email: _ })} />
            <TextField
                label="Company"
                placeholder="Company Name"
                required={true}
                value={this.state.company}
                onGetErrorMessage={validator.company.getErrorMessage}
                validateOnLoad={true}
                onChange={(e, _) => this.setState({ company: _ })} />
            <TextField
                label="Website"
                placeholder="company.com"
                value={this.state.webSite}
                required={true}
                onChange={(e, _) => this.setState({ webSite: _ })}
                validateOnLoad={true}
                onGetErrorMessage={validator.webSite.getErrorMessage} />
            <TextField
                label="Job Title"
                placeholder="Job Title"
                value={this.state.jobTitle}
                required={true}
                onChange={(e, _) => this.setState({ jobTitle: _ })}
                validateOnLoad={true}
                onGetErrorMessage={validator.jobTitle.getErrorMessage}  />
            <TextField
                label="Phone Number"
                placeholder="Phone Number"
                value={this.state.phone}
                onChange={(e, _) => this.setState({ phone: _ })}
                required={true}
                validateOnLoad={true}
                onGetErrorMessage={validator.phone.getErrorMessage} />
            <TextField
                label="Message"
                placeholder="Message"
                value={this.state.message}
                multiline={true}
                rows={5}
                required={true}
                onChange={(e, _) => this.setState({ message: _ })}
                validateOnLoad={true}
                onGetErrorMessage={validator.message.getErrorMessage} />
            <DialogFooter>
                <PrimaryButton text='Submit' onClick={this._onSave} disabled={isSending || !Validator.isValid(validator, this.state)} />
                <DefaultButton text='Cancel' onClick={this.props.onDismiss} disabled={isSending} />
            </DialogFooter>
            {this.state.isSending && <Overlay><Spinner /></Overlay>}
        </Dialog>
    }

    private _onSave = () => {
        const { email, fullName, phone, jobTitle, company, webSite, message } = this.state;
        const { plan } = this.props;

        analytics.trackEvent('Sent Contact Us', this.props.user, { email: email, fullName: fullName, company: company, planName: plan.name });
        post("api/billing/contactus", { fullName, email, phone, jobTitle, company, webSite, message: `${plan.name}\n${message}` })
            .then(_ => this.setState({ isSending: false }, () => this.props.onDismiss()))
            .catch(catchApiError(_ => {
                this.setState({ isSending: false }, () => this.props.onDismiss());
                this.props.pushNotification({ message: _, type: Notifications.NotificationType.Error });
            }));

        this.setState({ isSending: true });
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, Notifications.actionCreators)(ContactUsDialog);