import { BaseFilterValue, IFilter, Filter, IFilterHelper, FilterAttribute, Field, IActiveFilter, getLabel, IEntityFilterHelper } from "../../entities/Metadata";
import { Dictionary } from "../../entities/common";
import { DisplayFieldService, InputsService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { Option, OptionsPickerProps } from "../../components/common/inputs/OptionsPicker";
import { MyWork, MyWorkType, MyWorkAttrs, MyWorkParent } from "../MyWorkStore";
import { nameof } from "../services/metadataService";

export interface MyWorkFilterValue extends BaseFilterValue {
    portfolioId?: string[];
    projectId?: string[];
    type?: MyWorkType[];
    [key: string]: any;
}
export type MyWorkParentInfo = { id: string, name: string };

export class ActiveFilter implements IActiveFilter {
    private readonly _filter: IFilter<MyWorkFilterValue>;

    constructor(name?: string) {
        this._filter = Filter.empty(name);
    }

    public withParent(parent?: MyWorkParentInfo): ActiveFilter {
        if (parent) {
            this._filter.value.parentId = [parent];
        }
        this._filter.attributeNames.push({ name: 'parentId', type: "parentId" });
        return this;
    }

    public withParents(parents?: MyWorkParentInfo[]): ActiveFilter {
        if (parents) {
            this._filter.value.parentId = parents;
        }
        this._filter.attributeNames.push({ name: 'parentId', type: "parentId" });
        return this;
    }

    public withValues(values: { name: string, value: any }[]): ActiveFilter {
        if (!this._filter.value.attributes) {
            this._filter.value!.attributes = new Dictionary<any>();
        }

        values.forEach(_ => {
            this._filter.value.attributes![_.name] = _.value;
            this._filter.attributeNames.push({ name: _.name, type: "attributes" });
        })

        return this;
    }

    public build(): IFilter<MyWorkFilterValue> {
        return this._filter;
    }
}

export type FilterHelperProps = { work: MyWork[]; fields: Field[] }

export class FilterHelper implements IEntityFilterHelper<MyWork> {
    public getFilterAttributes = (fields: Field[]): FilterAttribute<MyWorkFilterValue>[] => {
        const hideFields = [nameof<MyWorkAttrs>("Parent"), nameof<MyWorkAttrs>("AssignedTo")];
        let attrs = fields
            .filter(_ => !(_.isNative && hideFields.indexOf(_.name) > -1))
            .map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<MyWorkFilterValue>);
        attrs.push({ type: "parentId", name: "parentId", displayName: "Parent", value: undefined });
        return attrs;
    }

    private attributes: IFilterHelper<MyWorkFilterValue, MyWork> = {
        buildFilterElement: (attr: FilterAttribute<MyWorkFilterValue>,
            filter: IFilter<MyWorkFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const field: Field = attr.value;
            const value = filter.value?.attributes?.[field.name];

            return DisplayFieldService.buildFieldMultiSelectInput(field, value, changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            let newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            let tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.fields, value),
        validateItem: (item: MyWork, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] == undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find(_ => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    const value = item.attributes[key as keyof MyWorkAttrs];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    private parentId: IFilterHelper<MyWorkFilterValue, MyWork> =
        {
            buildFilterElement: (attr: FilterAttribute<MyWorkFilterValue>,
                filter: IFilter<MyWorkFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const parentsMap = this._props.work.reduce((a, b) =>
                    a[b.attributes.Parent.id] ? a : ({ ...a, [b.attributes.Parent.id]: b.attributes.Parent }),
                    {} as { [key: string]: MyWorkParent }
                );

                const options = Object.keys(parentsMap).map(_ => {
                    const parent = parentsMap[_];
                    return { key: parent.id, text: parent.name };
                });

                const selectedItems: Option[] = [];
                filter.value?.parentId?.forEach((parentId: string | MyWorkParentInfo) => {
                    const parent = parentId as MyWorkParentInfo;
                    let opt = options.find(_ => _.key === (parent?.id ?? parentId));
                    if (opt === undefined && parent?.name) {
                        opt = { key: parent.id, text: parent.name };
                        options.push(opt);
                    }
                    if (opt) {
                        selectedItems.push(opt);
                    }
                });

                let itemsProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "parentId",
                            opts ? opts.map(_ => ({ id: _.key, name: _.text })) : undefined);
                    },
                    onResolveSuggestions: (filter: string, selectedItems?: Option[]) => {
                        let res: Option[] =
                            options.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                        if (selectedItems) {
                            res = res.filter((opt: Option) => selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return new Promise<Option[]>((resolve) => {
                            resolve(res);
                        });
                    },
                    selectedItems: selectedItems
                }

                return InputsService.buildOptionsPicker(itemsProps);
            },
            removeFilterAttribute: () => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                return [];
            },
            validateItem: (item: MyWork, filterValue: any): boolean => {
                if (!filterValue || (<any[]>filterValue).length === 0) {
                    return true;
                }

                const parent = item.attributes.Parent;
                return (filterValue as (MyWorkParentInfo | string)[])
                    .find((_: MyWorkParentInfo | string) => (parent ? parent.id : null) === ((_ as MyWorkParentInfo)?.id ?? _)) !== undefined;
            }
        }

    private readonly _props: FilterHelperProps
    constructor(props: FilterHelperProps) {
        this._props = props;
    }
    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name);

    public helpersMap: { [K in keyof MyWorkFilterValue]: IFilterHelper<MyWorkFilterValue, MyWork> } =
        {
            "attributes": this.attributes,
            "parentId": this.parentId
        }
}