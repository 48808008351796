import { get, post, remove } from '../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { defaultCatch } from "./utils";
import { addOrUpdateOrRemove } from './services/storeHelper';
import { ActionTypes, IReportingInfo, ReceivedReportingInfoAction } from './Tenant';
import { Dictionary } from '../entities/common';
import { toDictionaryById } from '../components/utils/common';
export type ReportMetadata = {
    id: string,
    title: string, 
    downloadUrl: string, 
    aboutUrl: string, 
    helpUrl: string,
    version: string
};
export const ReportScope = {
    PROJECTS: "Projects",
    PROGRAMS: "Programs",
    PORTFOLIOS: "Portfolios",
    RESOURCES: "Resources",
    OBJECTIVES: "Objectives",
    CHALLENGES: "Challenges"
}
export interface ReportInfo {
    id?: string;
    title: string;
    url?: string;
    scope?: string[];
    isCustom: boolean;
}

export interface Report extends ReportInfo{
    id: string;
    isActive?: boolean;
    version?: string;
}

export interface ReportsState {
    isLoading: boolean;
    reports: Report[];
    metadata: Dictionary<ReportMetadata>;
    isLoadingMetadata: boolean;
}

const unloadedState: ReportsState = {
    isLoading: false,
    reports: [],
    metadata: {},
    isLoadingMetadata: false
};

interface UpdateReport {
    report: Report;
    reportInfo: IReportingInfo;
}

interface UpdateReportsAction {
    type: 'UPDATE_REPORTS';
    report?: Report;
    removeids?: string[];
}
interface RequestReportsAction {
    type: 'REQUEST_REPORT';
}
interface ReceiveReportsAction {
    type: 'RECEIVE_REPORT';
    report: Report[];
}
interface RequestReportMetadataAction {
    type: 'REQUEST_REPORT_METADATA';
}
interface ReceiveReportMetadataAction {
    type: 'RECEIVE_REPORT_METADATA';
    data: ReportMetadata[];
}

type KnownAction = UpdateReportsAction
                  | RequestReportsAction 
                  | ReceiveReportsAction
                  | RequestReportMetadataAction
                  | ReceiveReportMetadataAction;

export const actionCreators = {
    saveReport: (report: ReportInfo): AppThunkAction<KnownAction | ReceivedReportingInfoAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_REPORT' });
        post<UpdateReport>(`api/reporting`, report )
            .then(data => {
                dispatch({ type: 'UPDATE_REPORTS', report: data.report });
                dispatch({ type: ActionTypes.RECEIVED_REPORTING_INFO, info: data.reportInfo });
            })
            .catch(defaultCatch(dispatch));
    },
    getReports: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_REPORT' });
        get<Report[]>(`api/reporting`)
            .then(data => { dispatch({ type: 'RECEIVE_REPORT', report: data }); })
            .catch(defaultCatch(dispatch));
    },
    removeReport: (ids: string[]): AppThunkAction<KnownAction | ReceivedReportingInfoAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_REPORT' });
        remove<{ reportInfo: IReportingInfo }>(`api/reporting`, { ids: ids })
            .then(data => {
                dispatch({ type: 'UPDATE_REPORTS', removeids: ids });
                dispatch({ type: ActionTypes.RECEIVED_REPORTING_INFO, info: data.reportInfo });
            })
            .catch(defaultCatch(dispatch));
    },
    getReportMetadata: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_REPORT_METADATA' });
        get<ReportMetadata[]>(`api/reporting/metadata`)
            .then(data => { dispatch({ type: 'RECEIVE_REPORT_METADATA', data }); })
            .catch(defaultCatch(dispatch));
    },
}

export const reducer: Reducer<ReportsState> = (state: ReportsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'UPDATE_REPORTS':
            return {
                ...state,
                reports: addOrUpdateOrRemove(state.reports, action.report, action.removeids),
                isLoading: false
            };
        case 'REQUEST_REPORT':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_REPORT':
            return {
                ...state,
                reports: action.report,
                isLoading: false
            };
            case 'REQUEST_REPORT_METADATA':
                return {
                    ...state,
                    isLoadingMetadata: true
                };   
        case 'RECEIVE_REPORT_METADATA':
            return {
                ...state,
                metadata: toDictionaryById(action.data),
                isLoadingMetadata: false
            };
        default:
            return state || unloadedState;
    }
};