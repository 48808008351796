import "./AiInsights.css";
import * as React from "react";
import { bindActionCreators } from 'redux';
import * as analytics from '../../../analytics';
import { PrimaryButton } from "office-ui-fabric-react";
import EnableAiInsightsConfirmationDialog from "../../insights/AiInsightSettings/EnableAiInsightsConfirmationDialog";
import { connect } from "react-redux";
import { CommonOperations, contains } from "../../../store/permissions";
import { ApplicationState } from "../../../store";
import Link from "../../common/Link";
import { actionCreators as metadataActionCreators } from "../../../store/MetadataStore";
import { AiInsightsSettings, actionCreators as tenantActionCreators } from '../../../store/Tenant';
import { UserState } from "../../../store/User";

type StoreProps = {
    aiEnabled?: boolean;
    isAdmin: boolean;
    user: UserState
    aiInsightsSettings: AiInsightsSettings
}
type ActionProps = {
    tenantActions: typeof tenantActionCreators;
    metadataActions: typeof metadataActionCreators;
}
type Props = StoreProps & ActionProps;

const AiInsights = (props: Props) => {
    const [showAiInsightsEnableMessage, setShowAiInsightsEnableMessage] = React.useState<boolean>();
    const onEnable = React.useCallback(() => setShowAiInsightsEnableMessage(true), []);
    const onDismiss = React.useCallback(() => setShowAiInsightsEnableMessage(undefined), []);

    const enableAiInsightsSettings = React.useCallback(() => {
        const settings = { ...props.aiInsightsSettings, aiEnabled: true };
        props.tenantActions.updateAiInsightsSettings(settings, props.metadataActions.loadMetadata);
        analytics.trackEvent("AI Settings updated", props.user, {
            aiEnabled: settings.aiEnabled,
            customApiKeyEnabled: !!settings.customApiKey,
            modelType: settings.modelType,
            temperature: settings.temperature,
        });
    }, [props.aiInsightsSettings, props.user]);

    return <div className="box ai-insights">
        {
            props.aiEnabled
                ? <>
                    <div className="title">AI-Powered Features are enabled</div>
                    <div className="description">
                        Ready for a smarter, faster experience? Explore the possibilities and supercharge your work with AI
                    </div>
                    <div className="features"></div>
                    <div className="links">
                        {props.isAdmin && <Link href="/statusSettings" className="link">
                            AI Settings
                        </Link>}
                        <Link href="https://help.ppm.express/ppm-insights-ai" target="_blank" className="link">
                            Help Article
                        </Link>
                    </div>
                </>
                : <>
                    <div className="title">AI-Powered Project Portfolio Management</div>
                    <div className="description">
                        Allow your teams to use AI features powered by 3rd parties. If enabled, you are agreeing to
                        our <a href="https://ppm.express/privacy-policy/" target="_blank" className="link">
                            privacy policy
                        </a> and <a href="https://help.ppm.express/ppm-insights-ai/2131404" target="_blank" className="link">
                            how your data is used
                        </a> in PPM Express.
                    </div>
                    {props.isAdmin
                        ? <PrimaryButton text={"Enable AI"} onClick={onEnable} />
                        : <div className="message">Please contact your PPM Express Administrator to enable AI features on your tenant.</div>}
                    {showAiInsightsEnableMessage && <EnableAiInsightsConfirmationDialog onConfirm={enableAiInsightsSettings} onDismiss={onDismiss} />}
                </>
        }
    </div>;
};

function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        aiEnabled: state.tenant.aiInsights?.aiEnabled,
        isAdmin: contains(state.user.permissions.common, CommonOperations.Administrate),
        user: state.user,
        aiInsightsSettings: state.tenant.aiInsights,
    };
}
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch),
        metadataActions: bindActionCreators(metadataActionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(AiInsights);