import * as React from 'react';

interface OwnProps {
    className: string,
    title: string,
    description?: string,

}

export default class EmptyEntitiesScreen extends React.Component<OwnProps> {
    public render() {
        return (
            <div className={`empty-entities-screen ${this.props.className}`}>
                <div className="content">
                    <div className="title">
                        Welcome <br />
                        to {this.props.title}!
                    </div>
                    <div className="description">{this.props.description}</div>
                    <div className="commands">{this.props.children}</div>
                </div>
                <div>
                    <div className="image">
                    </div>
                </div>
            </div>
        );
    }

}