import * as React from 'react';
import { Dialog, DialogType, DialogFooter, IDialogContentProps } from 'office-ui-fabric-react/lib/components/Dialog';
import { PrimaryButton } from 'office-ui-fabric-react/lib/components/Button';

interface Props {
    dialogContentProps?: IDialogContentProps;
    confirmButtonText?: string;
    onComplete: (selectedIds: string[]) => void;
    onDismiss: () => void;
    renderEntitySelector: (onChanged: (selected?: string[]) => void) => JSX.Element | null;
}

export default class AddEntitiesDialog extends React.Component<Props, { selected?: string[] }>{
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        return <Dialog
            hidden={false}
            onDismiss={this.props.onDismiss}
            minWidth={570}
            maxWidth={570}
            dialogContentProps={{
                type: DialogType.close,
                ...this.props.dialogContentProps
            }}
            modalProps={{ isBlocking: false }}>
            {this.props.renderEntitySelector(selected => this.setState({ selected }))}
            <DialogFooter>
                <PrimaryButton
                    disabled={!this.state.selected?.length}
                    onClick={() => { this.props.onComplete(this.state.selected!) }}
                    text={this.props.confirmButtonText || 'Done'} />
            </DialogFooter>
        </Dialog>;
    }
}