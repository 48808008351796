import * as React from 'react';
import { ServerEntityType } from '../../entities/common';
import { EntityDescriptor } from '../../store/RoadmapsListStore';
import SearchItemsImportPanel from '../common/SearchItemsImportPanel';



type Props = {
    onDismiss: () => void;
    onImport: (descriptor: EntityDescriptor, callback: () => void) => void;
    onUndoImport: (type: ServerEntityType, externalId: string, callback: () => void) => void;
    isEntityImported: (externalEntityId: string) => boolean;
    selectedEntityType?: ServerEntityType;
}

export default function RoadmapItemsImportPanel(props: Props) {

    return (
        <SearchItemsImportPanel
            {...props}
            title='Import Items'
            subTitle='Select items that you want to import in current Roadmap' />
    )
}