import './AiInsightsSettings.css';
import { ChoiceGroup, DirectionalHint, IChoiceGroupOption, Icon, Toggle, TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import * as React from 'react';
import { AiInsightsSettings, AiModelType } from '../../../store/Tenant';
import Link from "../../common/Link";
import SliderInput from '../../common/inputs/SliderInput';
import TextInput from '../../common/inputs/TextInput';
import { Validator } from '../../../validation';
import { TextFormatter } from '../../common/formatters/TextFormatter';
import EnableAiInsightsConfirmationDialog from './EnableAiInsightsConfirmationDialog';

type OwnProps = {
    data: AiInsightsSettings;
    onChange?: (data: AiInsightsSettings, onValidateError?: (errorMessage: string) => void) => void;
}

const defaultTemperature = 0.2;

const AiInsightSettings = (props: OwnProps) => {

    const { data, onChange } = props;
    const [useDefaultApiKey, setUseDefaultApiKey] = React.useState(!data.customApiKey);
    const [showConfirmEnabled, setShowConfirmEnabled] = React.useState<boolean>(false);
    const onDismiss = React.useCallback(() => setShowConfirmEnabled(false), []);

    const allowEdit = !!onChange;
    const disabled = !allowEdit || !data.aiEnabled;

    const [apiKeyError, setApiKeyError] = React.useState<string>();
    const validator = !disabled && !useDefaultApiKey ? Validator.new().required().build() : undefined;

    const modelOptions: IChoiceGroupOption[] = [
        {
            key: AiModelType.GPT_4o_mini.toString(),
            text: "GPT 4o mini",
            value: AiModelType.GPT_4o_mini,
        },
        {
            key: AiModelType.GPT_4o.toString(),
            text: "GPT 4o",
            value: AiModelType.GPT_4o,
            onRenderLabel: (p, render) => <TooltipHost content="Not available with default API key">{render!(p)}</TooltipHost>,
            disabled: useDefaultApiKey || !!apiKeyError
        }
    ]

    const sliderProps = {
        min: 0,
        max: 1,
        step: 0.05,
        defaultValue: defaultTemperature,
        value: data.temperature ?? defaultTemperature,
        className: '',
        readOnly: disabled
    };

    const _onEnabled = (isEnabled: boolean) => {
        if (isEnabled) {
            setShowConfirmEnabled(true);
        }
        else {
            _enableAi(false);
        }
    }

    const _clearApiKeyError = () => {
        if (!data.aiEnabled || !data.customApiKey) {
            setApiKeyError(undefined);
        }
    }

    const _onModelTypeChanged = (ev: React.FormEvent<HTMLElement>, item: IChoiceGroupOption) => {
        const modelType = item.value as AiModelType;
        if (modelType !== AiModelType.GPT_4o || data.customApiKey) {
            onChange?.({ ...data, modelType: modelType });
        }
    };

    const _onUseDefaultApiKey = (enabled: boolean) => {
        setUseDefaultApiKey(enabled);
        _clearApiKeyError();
        if (enabled) {
            const newObj = { ...data, customApiKey: undefined, modelType: AiModelType.GPT_4o_mini };
            onChange?.(newObj);
        }
    };

    const _enableAi = (enabled: boolean) => {
        _clearApiKeyError();
        if (data.aiEnabled != enabled) {
            onChange?.({ ...data, aiEnabled: enabled });
        }
    }

    const _apiKeyChanged = (value: string | null) => {
        data.modelType = AiModelType.GPT_4o_mini;
        onChange?.({ ...data, customApiKey: value ?? undefined }, (errorMessage) => {
            setApiKeyError(errorMessage);
        })
    }

    const numberValidator = Validator.new().decimal().min(0).max(1).build();

    return <div className="ai-settings">
        <div className="settings-fields-holder">
            <div className="settings-row">
                <div className="setting-toggle-holder align-center">
                    <span>PPM Insights AI</span>
                    <Toggle className="toggle"
                            disabled={!allowEdit}
                            checked={data.aiEnabled}
                            onChanged={onChange ? (checked: boolean) => _onEnabled(checked) : undefined}
                            onText='On' offText='Off' />
                </div>
                <div className="small-description">
                    Allow your team members to use AI features powered by 3rd parties.
                    If enabled, you are agreeing to our&nbsp;
                    <Link href="https://ppm.express/privacy-policy" target="_blank">privacy policy</Link> and&nbsp;
                    <Link href="https://help.ppm.express/ppm-insights-ai/2131404" target="_blank">how your data is used</Link> in PPM Express.
                </div>
            </div>
            <div className="setting-toggle-holder align-center">
                <div className="align-center">
                    <div>Use default API key</div>
                    <TooltipHost content="Default API Key is provided by PPM Express, and it supports only the GPT 4o mini Open AI model. If disabled, a custom API Key should be used that may support the GPT 4 model."
                        delay={TooltipDelay.zero} hostClassName="tooltip" calloutProps={{ directionalHint: DirectionalHint.topCenter }}>
                        <Icon iconName="Info" />
                    </TooltipHost>
                </div>
                <Toggle className="toggle"
                        disabled={disabled}
                        checked={useDefaultApiKey}
                        onChanged={(checked: boolean) => _onUseDefaultApiKey(checked)}
                        onText='On' offText='Off' />
            </div>
            {
                !useDefaultApiKey &&
                <div className="settings-row">
                    <span className="setting-label">Use custom API key</span>
                    <div className="setting-value setting-input">
                        <TextInput value={data.customApiKey} disabled={disabled} validator={validator}
                            onEditComplete={_apiKeyChanged}
                            onChanged={() => setApiKeyError(undefined)}
                            errorMessage={apiKeyError ? <TextFormatter value={apiKeyError} withNavigation={true} urlTextMaxLen={50}></TextFormatter> : undefined}
                            inputProps={{ type: "password", autoComplete: "new-password" }} />
                    </div>
                </div>
            }
            <div className="settings-row">
                <span className="setting-label">Model</span>
                <TooltipHost content="An OpenAI model to be used for generating AI-based content. Please note that by opting to use your custom API Key, the GPT-4o model will be made available. If selected, please ensure that you support this model."
                    delay={TooltipDelay.zero} hostClassName="tooltip" calloutProps={{ directionalHint: DirectionalHint.topCenter }}>
                    <Icon iconName="Info" />
                </TooltipHost>
                <div className="setting-value">
                    <ChoiceGroup
                        readOnly={disabled}
                        selectedKey={"" + (data.modelType ?? 0)}
                        onChange={_onModelTypeChanged}
                        disabled={disabled}
                        className="with-cb-in-row"
                        options={modelOptions} />
                </div>
            </div>
            <div className="settings-row">
                <span className="setting-label">Temperature</span>
                <TooltipHost content="Temperature is a parameter in OpenAI models that controls the level of randomness and creativity in the generated responses. It is represented by a number between 0 and 1. A temperature of 0 produces very predictable and consistent responses, while a temperature of 1 allows for more varied and creative responses."
                    delay={TooltipDelay.zero} hostClassName="tooltip" calloutProps={{ directionalHint: DirectionalHint.topCenter }}>
                    <Icon iconName="Info" />
                </TooltipHost>
                <div className="setting-value setting-slider">
                    <SliderInput {...sliderProps} inputProps={sliderProps}
                        validator={numberValidator}
                        onChanged={(value) => value >= 0 && data.temperature !== value && onChange?.({ ...data, temperature: value })} />
                </div>
            </div>
        </div>
        {
            showConfirmEnabled && <EnableAiInsightsConfirmationDialog onConfirm={() => _enableAi(true)} onDismiss={onDismiss} />
        }
    </div>
}

export default AiInsightSettings;