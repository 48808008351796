import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { suppressMouseEventsBubble } from "../utils/common";

type Props = {
    isFavorite: boolean;
    onChange: (isFavorite: boolean) => void;
    className?: string;
    disabled?: boolean;
};

const FavoriteToggle = (props: Props) => {
    const { isFavorite, disabled, onChange } = props;
    return (
        <span className="favorite-toggle hover-container" title={isFavorite ? "Remove from favorites" : "Add to favorites"} {...suppressMouseEventsBubble}>
            <Icon
                className={`main align-left ${props.className ? props.className : ''}`}
                iconName={isFavorite ? "FavoriteStarFill" : "FavoriteStar"}
                onClick={!disabled ? _ => onChange(!isFavorite) : undefined}
            />
            <Icon
                className={`hover align-left ${props.className ? props.className : ''}`}
                iconName={isFavorite ? "Unfavorite" : "AddFavorite"}
                onClick={!disabled ? _ => onChange(!isFavorite) : undefined}
            />
        </span>
    );
}

export default FavoriteToggle;