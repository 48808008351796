import * as React from 'react';
import Link from "../../common/Link";
import { ConfirmationDialog } from '../../common/ConfirmationDialog';

type OwnProps = {
    onConfirm: () => void;
    onDismiss: () => void;
}

const EnableAiInsightsConfirmationDialog = (props: OwnProps) => <ConfirmationDialog
    onDismiss={props.onDismiss}
    onYes={props.onConfirm}
    onNo={() => { }}
    yesButtonProps={{ text: "Confirm" }}
    noButtonProps={{ text: "Cancel" }}
    dialogContentProps={{
        title: 'Enable PPM Insights AI'
    }}>
    <div>We offer you the opportunity to use a range of AI features developed by PPM Express and based on the underlying models made available by the OpenAI
        (OpenAI LLC located at 3180 18th St San Francisco CA 94110).</div>
    <p>PPM Express only transmits the information from your project fields or sections,
        along with a prompt and a unique identifier, to OpenAI. This identifier is randomly generated and can be traced by OpenAI.
        No additional user data is sent beyond this scope.</p>
    <p>
        The transmitted data is not used for training or improving the OpenAI models.
        However, <Link href="https://openai.com/policies/api-data-usage-policies" target="_blank">OpenAI states</Link>:
        “Any data sent through the API will be retained for abuse and misuse monitoring purposes for a maximum of 30 days, after which it will be deleted
        (unless otherwise required by law).</p>
</ConfirmationDialog>;
export default EnableAiInsightsConfirmationDialog;