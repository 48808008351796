import { MessageBar, MessageBarType, Toggle } from 'office-ui-fabric-react';
import React, { useState } from 'react'
import { ConfirmationDialog } from '../../common/ConfirmationDialog';

type Props = {
    readonly?: boolean,
    value: boolean;
    originalValue: boolean;
    onChange: (value: boolean) => void;
    isGlobalSettingEnabled: boolean;
    isPrivateProject: boolean;
};

export default function TimeTrackingProjectSettingsSection(props: Props) {
    const { readonly, value, onChange, isGlobalSettingEnabled, originalValue, isPrivateProject } = props;
    const [showCompletedWorkConfirmation, setShowCompletedWorkConfirmation] = useState(false);

    return (
        <>
            <div className='section-description'>
                Select whether Completed Work for PPM Express tasks should be calculated based on the reported time.
            </div>
            <ul className="cb-list">
                <li>
                    <div className="cb-wrap">
                        <div className="title">Record Reported Time to Completed Work</div>
                        <Toggle
                            checked={value}
                            disabled={readonly || !isGlobalSettingEnabled || isPrivateProject}
                            onChange={(e: any, c?: boolean) => {
                                if (c && !originalValue) {
                                    setShowCompletedWorkConfirmation(true);
                                }
                                else {
                                    onChange(c!);
                                }
                            }}
                        />
                    </div>
                </li>
            </ul>

            {!isGlobalSettingEnabled
                ? <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    This setting is ignored as the Global setting is turned off.
                    {isPrivateProject ? <><br />Time cannot be reported for Private Projects.</> : undefined}
                </MessageBar>
                : isPrivateProject
                    ? <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                        Time cannot be reported for Private Projects.
                    </MessageBar>
                    : undefined
            }

            {showCompletedWorkConfirmation &&
                <ConfirmationDialog
                    dialogContentProps={{
                        isMultiline: true,
                        title: "Confirm this action",
                        subText: `Are you sure you want to enable recording of the Reported Time to Tasks Completed Work in this Project?
                    Tasks Completed Work will be recalculated immediately using the time reported in Users Timesheets.`
                    }}
                    onNo={() => { }}
                    onDismiss={() => setShowCompletedWorkConfirmation(false)}
                    onYes={() => onChange(true)}
                />}
        </>);
}
