import * as React from 'react';
import { ActionButton, Icon, getId, IconButton, IButton } from 'office-ui-fabric-react';
import { ISourceInfo, Dictionary, EntityType, } from "../../../entities/common";
import { SourceType, SourceType_ } from "../../../store/ExternalEpmConnectStore";
import ExternalTaskSearch from "../../common/ExternalTaskSearch";
import { IExternalTaskSuggestion } from "../../common/ExternalTaskSearch";
import { post } from "../../../fetch-interceptor";
import ConnectionSelect from './keydate/ConnectionSelect';
import Link from '../../common/Link';
import { suppressEvent, FormatDate } from '../../utils/common';
import { ItemCreation } from '../../common/ItemCreation';
import { Validator } from '../../../validation';
import { ISubentity, SubentityInfo } from '../../../entities/Subentities';

type ImportSubentityProps = {
    projectId: string;
    allSubentities: ISubentity[];
    subentity: SubentityInfo;
    sources: ISourceInfo[];
    onImport: (data: Dictionary<string[]>) => void;
    onDismiss: () => void;
    connectionHelpLinks?: { [key in SourceType]?: string | undefined },
}

const ImportSubentityFromTask = (props: ImportSubentityProps) => {
    let _confirmButton: IButton | null = null;
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [all, setAll] = React.useState(() => { return props.sources.map(_ => ({ source: _, suggestions: [] as IExternalTaskSuggestion[] })) });
    const [exists,] = React.useState(() => {
        return props.allSubentities.reduce<Dictionary<string[]>>((s, k) => {
            if (!k.connectionId || !k.externalId) {
                return s;
            }

            if (s[k.connectionId]) {
                s[k.connectionId].push(k.externalId);
            } else {
                s[k.connectionId] = [k.externalId];
            }
            return s;
        }, {} as Dictionary<string[]>)
    });
    const [isDirty, setIsDirty] = React.useState(false);

    const _onImport = () => {
        const data: Dictionary<string[]> = all.reduce((s, a) => a.suggestions.length
            ? {
                ...s,
                [a.source.connectionId]: a.suggestions.map(_ => _.id)
            }
            : s, {});

        props.onImport(data);
        props.onDismiss();
    }

    const _onItemSelected = (item: IExternalTaskSuggestion) => {
        setIsDirty(true);
        setAll(all.map((_, i) => i === selectedIndex
            ? {
                ..._,
                suggestions: _.suggestions.concat(item).sort((a, b) => a.name === b.name
                    ? a.id > b.id ? 1 : -1
                    : a.name.localeCompare(b.name))
            }
            : _));
        _confirmButton?.focus();
    }

    const _onSearch = (template: string): Promise<IExternalTaskSuggestion[] | undefined> => {
        const selected = all[selectedIndex];
        const payload = {
            connectionId: selected.source.connectionId,
            template: template,
            ids: selected.suggestions.map(_ => _.id).concat(exists[selected.source.connectionId] || [])
        }
        return post<IExternalTaskSuggestion[]>(`api/project/${props.projectId}/externalTasks/search`, payload)
            .then(_ => filterSuggestions(_));
    }

    const filterSuggestions = (suggestions: IExternalTaskSuggestion[]): IExternalTaskSuggestion[] => {
        const selected = all[selectedIndex].suggestions;
        return selected.length ? suggestions.filter(_ => !selected.find(s => s.id === _.id)) : suggestions;
    }

    const showGroupTitle = all.find((_, i) => i !== selectedIndex && _.suggestions.length);

    return (<ItemCreation
        className="import-subentity"
        onDismiss={props.onDismiss}
        header={{
            showNameEditor: false,
            text: `Import ${props.subentity.pluralSubentityTypeLabel}`,
            secondaryText: `Select connection and add ${props.subentity.pluralSubentityTypeLabel} from the linked source.`,
            validator: Validator.new().build()
        }}
        isDirty={all.find(_ => _.suggestions.length) && isDirty}
        commands={[{
            text: "Confirm",
            primary: true,
            onClick: _onImport,
            disabled: !all.find(_ => _.suggestions.length),
            componentRef: _ => {
                _confirmButton = _;
            }
        },
        {
            text: "Cancel",
            onClick: props.onDismiss
        }]}>
        <ConnectionSelect
            selected={all[selectedIndex].source}
            sources={props.sources}
            helpLinks={props.connectionHelpLinks}
            onChange={_ => { setIsDirty(true); setSelectedIndex(all.findIndex(s => s.source.connectionId === _.connectionId)) }} />
        <ExternalTaskSearch onItemSelected={_onItemSelected} onChange={_onSearch} />
        <div className="import-subentity-list with-top-margin" data-is-scrollable={true}>
            {
                all.map((_, i) => !!_.suggestions.length &&
                    <div key={_.source.connectionId} className="import-subentity-group">
                        {
                            showGroupTitle && <div className="import-subentity-group-title">
                                <Icon iconName={SourceType_.getIconName(_.source.type)} />
                                {SourceType_.getName(_.source.type)}
                            </div>
                        }
                        {_.suggestions.map(s => <RenderSuggestion
                            parentIndex={i}
                            suggestion={s}
                            setIsDirty={setIsDirty}
                            setAll={setAll}
                            all={all}
                        />
                        )}
                    </div>)
            }
        </div>
    </ItemCreation>);
}

type RenderSuggestionProps = {
    parentIndex: number;
    suggestion: IExternalTaskSuggestion;
    setIsDirty: (value: boolean) => void;
    setAll: React.Dispatch<React.SetStateAction<{
        source: ISourceInfo<any>;
        suggestions: IExternalTaskSuggestion[];
    }[]>>;
    all: {
        source: ISourceInfo<any>;
        suggestions: IExternalTaskSuggestion[];
    }[];

}

const RenderSuggestion = (props: RenderSuggestionProps) => {
    const dateString = FormatDate(props.suggestion.date);
    return (
        <div key={props.suggestion.id} className="import-subentity-suggestion align-center" title={`${props.suggestion.name} (${dateString})`}>
            {props.suggestion.icon && <span className="icon" style={{ backgroundImage: `url(${props.suggestion.icon})` }} title={props.suggestion.type} />}
            {!props.suggestion.icon && <Icon iconName="WorkItem" className="icon" />}
            <span className="suggestion-name overflow-text">{props.suggestion.name}</span>
            <span className="suggestion-date">{dateString}</span>
            <div className="buttons-container">
                {!!props.suggestion.url &&
                    <Link href={props.suggestion.url} target="_blank" title={`Open ${props.suggestion.type}`}><IconButton iconProps={{ iconName: "Link" }} /></Link>}
                <ActionButton iconProps={{ iconName: "Delete" }}
                    className="remove"
                    onClick={suppressEvent(e => {
                        props.setIsDirty(true);
                        props.setAll(props.all.map((_, i) => i === props.parentIndex
                            ? {
                                ..._,
                                suggestions: _.suggestions.filter(s => s.id !== props.suggestion.id)
                            }
                            : _))
                    })}
                    title="Remove suggestion" />
            </div>
        </div>
    );
}

export default ImportSubentityFromTask;