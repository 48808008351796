import * as React from "react";
import { PrimaryButton, Dialog, IModalProps, DialogType, DialogFooter, IDialogContentProps } from 'office-ui-fabric-react';

export const AlertDialog: React.FunctionComponent<{
    onDismiss: () => void,
    dialogContentProps?: IDialogContentProps,
    modalProps?: IModalProps,
}> = props => <Dialog
    maxWidth={500}
    minWidth={500}
    hidden={false}
    modalProps={{
        isBlocking: false,
        containerClassName: 'ms-dialogMainOverride',
        ...props.modalProps
    }}
    dialogContentProps={{
        type: DialogType.normal,
        ...props.dialogContentProps,
        styles: {
            ...(props.dialogContentProps?.styles || {}),
            subText: {
                ...(props.dialogContentProps?.styles?.['subText'] || {}),
                whiteSpace: 'pre-wrap'
            }
        },
    }}
    onDismiss={props.onDismiss}>
    {props.children}
    <DialogFooter>
        <PrimaryButton text={"Got it"} onClick={props.onDismiss} />
    </DialogFooter>
</Dialog> 