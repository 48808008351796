import * as React from 'react';
import { IJiraBoard } from '../../../store/integration/JiraStore';
import { ComboBox, IComboBoxOption, IComboBox, ISelectableOption, IRenderFunction } from 'office-ui-fabric-react';
import { IJiraImportProjectState } from './JiraProjectImport';
import { EditorProps } from 'react-data-grid';

const onRenderItem = (props?: ISelectableOption, defaultRender?: IRenderFunction<ISelectableOption>) => {
    if (!props || !defaultRender) return null;
    return props.title
        ? <div key={props.key} title={props.title}>{defaultRender(props)}</div>
        : defaultRender(props);
}

const JiraBoardEditor = (props: EditorProps<IJiraImportProjectState>) => {
    const { row } = props;
    const options = React.useMemo(
        () => [{ key: "", text: "No Board" }].concat(row.boards.map(IJiraBoard.toSelectOption)),
        [row]);

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (!option) return;
        const selectedId = option.key as string;
        const selected = selectedId
            ? row.boards.find(_ => _.id === selectedId)
            : undefined;
        props.onRowChange({ ...row, board: selected });
    }

    const comboBoxRef = React.useRef<IComboBox>(null);
    React.useLayoutEffect(() => { comboBoxRef.current?.focus(true) }, []);

    return <ComboBox
        selectedKey={row.board?.id}
        componentRef={comboBoxRef}
        options={options}
        onChange={onChange}
        onRenderItem={onRenderItem}
    />
}

export default JiraBoardEditor;