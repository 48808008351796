import "./ButtonBox.css";
import * as React from "react";
import { Icon, Button } from 'office-ui-fabric-react';

type Props = {
    iconName: string;
    name: string;
    big?: boolean;
    description?: string;
    buttonText: string;
    action?: () => void;
    href?: string;
    className?: string;
}
const ButtonBox = (props: Props) => <div className={`box button-box ${props.big ? 'big' : ''}`}>
    <Icon iconName={props.iconName} />
    <div>
        <div className="name">{props.name}</div>
        <div className="description">{props.description}</div>
        <Button className={`button ${props.className ?? ''}`} href={props.href} onClick={props.action} text={props.buttonText} />
    </div>
</div>

export default ButtonBox;