import * as React from "react";
import ConnectionCard from './ConnectionCard';
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import ImportPanel from "../../import/project/ImportPanel";
import { IConnectionInfo } from "../../../entities/Metadata";
import { ApplicationState } from "../../../store";
import { canCreate, CommonOperations, contains } from "../../../store/permissions";
import { connect } from "react-redux";

type OwnProps = {
    system: SourceType;
    connections: IConnectionInfo[];
    renderConnect: (onCreated: (connectionId: string) => void, onDismiss: () => void) => void;
}
type StoreProps = {
    canConnect: boolean;
    canImport: boolean;
}
type Props = OwnProps & StoreProps;
const BasicAuthConnectionCard = (props: Props) => {
    const [showConnect, setShowConnect] = React.useState<boolean>();
    const onDismissConnect = React.useCallback(() => setShowConnect(undefined), []);
    const onConnect = React.useCallback(() => setShowConnect(true), []);
    const [importFromConnectionId, setImportFromConnectionId] = React.useState<string>();
    const onDismissImport = React.useCallback(() => setImportFromConnectionId(undefined), []);
    const onImport = React.useCallback((connectionId: string) => setImportFromConnectionId(connectionId), []);
    return <>
        <ConnectionCard {...props} onConnect={onConnect} onImport={onImport} />
        {showConnect && props.renderConnect((connectionId: string) => {
            if (props.canImport) {
                onImport(connectionId);
            }
        }, onDismissConnect)}
        {importFromConnectionId && <ImportPanel onDismiss={onDismissImport} integrations={[props.system]} connectionId={importFromConnectionId} />}
    </>;
}
function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        canConnect: contains(state.user.permissions.common, CommonOperations.ConnectionManage),
        canImport: canCreate(state.user.permissions.project),
    };
}
export default connect(mapStateToProps)(BasicAuthConnectionCard);