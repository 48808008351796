import React from "react";
import { CommandBar } from "office-ui-fabric-react";
import { getProjectPath, getProjectTasksPath, ProjectInfo } from "../../../../../store/ProjectsListStore";
import { ISourceInfo, SourceInfo, PpmxConnection } from "../../../../../entities/common";
import { SourceType_, SourceType, SourceTypeMap } from "../../../../../store/ExternalEpmConnectStore";
import { SystemIcon, IconSize } from "../summaryControl/LinkedSystemIcons";
import { RouteComponentProps } from "react-router-dom";
import { urlParamsBuilder } from "../../../../../entities/Subentities";
import { Location } from "history";

type Props = RouteComponentProps<{}> & {
    entity: ProjectInfo<unknown>;
    showDashboardNavButton?: boolean;
    sourceInfo: ISourceInfo;
};

export const navigationLabelsMap: { [k: number]: string } = {
    [SourceType.Ppmx]: `View PPM Express Tasks`,
    [SourceType.VSTS]: `View Backlog from ${SourceTypeMap[SourceType.VSTS]}`,
    [SourceType.Jira]: `View Backlog from ${SourceTypeMap[SourceType.Jira]}`,
    [SourceType.O365Planner]: `View Tasks from ${SourceTypeMap[SourceType.O365Planner]}`,
    [SourceType.Spo]: `View Tasks from ${SourceTypeMap[SourceType.Spo]}`,
    [SourceType.MondayCom]: `View Tasks from ${SourceTypeMap[SourceType.MondayCom]}`,
    [SourceType.Smartsheet]: `View Tasks from ${SourceTypeMap[SourceType.Smartsheet]}`,
    [SourceType.P4W]: `View Tasks from ${SourceTypeMap[SourceType.P4W]}`,
    [SourceType.MPPFile]: `View Tasks from ${SourceTypeMap[SourceType.MPPFile]}`
}

const NavigationRow = (props: Props) => {
    const { entity, showDashboardNavButton, sourceInfo, location } = props;

    const otherSources = SourceInfo.getSources([PpmxConnection, ...entity.sourceInfos])
        .filter(_ => SourceType_.isTaskViewable(_) && _.connectionId !== sourceInfo.connectionId);

    const items = showDashboardNavButton
        ? [{
            key: `current_${sourceInfo.type}`,
            onRender: () => <>
                <div className="schedule-label">{SourceTypeMap[sourceInfo.type]}</div>            
                <div className="divider"></div>
            </>
        },            
        {
            key: `dashboard`,
            onRender: () => <SystemIcon
                iconName="ViewDashboard"
                iconSize={IconSize.small}
                title={SourceTypeMap[SourceType.Ppmx]}
                label="Back to Project Dashboard"
                url={getProjectPath(entity)} />
        },
        ...otherSources.map(_ => ({
            key: `tasks_${_.type}`,
            onRender: () => <SystemIcon
                iconName={SourceType_.getIconName(_.type)}
                iconSize={IconSize.small}
                title={navigationLabelsMap[_.type]}
                label={navigationLabelsMap[_.type]}
                url={buildTaskPageUrl(entity, _, location)} />,
        }))]
        : [{
            key: `current_${sourceInfo.type}`,
            onRender: () => <>
                <div className="schedule-label">{SourceTypeMap[sourceInfo.type]}</div>
                <div className="divider"></div>
            </>
        },
        {
            key: `${sourceInfo.type}`,
            onRender: () => <SystemIcon
                iconName={SourceType_.getIconName(sourceInfo.type)}
                iconSize={IconSize.small}
                title={navigationLabelsMap[sourceInfo.type]}
                label={navigationLabelsMap[sourceInfo.type]}
                url={buildTaskPageUrl(entity, sourceInfo, location)} />,
        }];

    return <CommandBar className='schedule-navigation' items={items} styles={{ root: { paddingLeft: 8 } }} />
}

export default NavigationRow;

export function buildTaskPageUrl(entity: ProjectInfo<unknown>, source: ISourceInfo, location: Location): string {
    const query = new URLSearchParams(location.search);
    query.set(urlParamsBuilder.connectionId, source.connectionId);
    return `${getProjectTasksPath(entity)}?${query.toString()}`;
}
