import "./MoreConnectionsMenu.css";
import * as React from "react";
import { DirectionalHint, Icon, IconButton, IContextualMenuItem, TooltipHost } from 'office-ui-fabric-react';
import { IConnectionInfo } from "../../../../entities/Metadata";
import { NotEnoughPermissionsMessage } from "../../../../store/permissions";

type Props = {
    connections: IConnectionInfo[];
    selectedConnectionId: string;
    onConnect: () => void;
    onRestrictedConnect?: () => void;
    onSelect: (connectionId: string) => void;
    canConnect?: boolean;
    canSelect?: boolean;
    newConnectionTooltip?: JSX.Element;
    newRestrictedConnectionTooltip?: JSX.Element;
}
const MoreConnectionsMenu = (props: Props) => {

    const _renderItem = (item: IContextualMenuItem, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
        return <TooltipHost
            content={item.data?.title}
            calloutProps={{ directionalHint: DirectionalHint.bottomCenter, isBeakVisible: false, gapSpace: 10 }}
            className='border shadow-none'>
            <div className='more-connections-menu'>
            <button className="ms-ContextualMenu-link full-width" role="menuitem" onClick={item.onClick}>
                <div className="ms-ContextualMenu-linkContent full-width">
                    <Icon {...item.iconProps} className="ms-ContextualMenu-icon"></Icon>
                    <span className="ms-ContextualMenu-itemText">{item.text ?? item.name}</span>
                </div>
                </button>
            </div>
        </TooltipHost>
    }

    const items: IContextualMenuItem[] = [
        ...props.connections.map(_ => ({
            key: _.id,
            text: _.title,
            secondaryText: _.name,
            disabled: !props.canSelect,
            title: !props.canSelect ? NotEnoughPermissionsMessage : undefined,
            className: "connection",
            iconProps: { iconName: props.selectedConnectionId === _.id ? "CheckMark" : "" },
            onClick: () => props.onSelect(_.id)
        }))];
    if (props.onRestrictedConnect) {
        items.push({
            key: 'addNew',
            name: 'Add new restricted connection',
            iconProps: { iconName: "Add" },
            disabled: !props.canConnect,
            title: !props.canConnect ? NotEnoughPermissionsMessage : undefined,
            data: props.canConnect ? { title: props.newRestrictedConnectionTooltip } : undefined,
            onClick: props.onRestrictedConnect,
            onRender: _renderItem
        });
    }
    items.push({
        key: 'addNew',
        name: 'Add New Connection',
        iconProps: { iconName: "Add" },
        disabled: !props.canConnect,
        title: !props.canConnect ? NotEnoughPermissionsMessage : undefined,
        data: props.canConnect ? { title: props.newConnectionTooltip } : undefined,
        onClick: props.onConnect,
        onRender: _renderItem
    });
    return <IconButton
        className="menu"
        menuIconProps={{ iconName: 'More' }}
        menuProps={{
            className: "connections-menu",
            items
        }}
    />;
}
export default MoreConnectionsMenu;