import { DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { DirtyFormMessageBar } from "../../../common/DirtyFormMessageBar";

type Props = {
    isGenerateDisabled: boolean;
    showHasChangesWarning: boolean;
    onGenerateClick: () => void;
    onDismiss: () => void;
}

const AIScheduleDataInputFooter = (props: Props) => {
    const { isGenerateDisabled, showHasChangesWarning, onGenerateClick, onDismiss } = props;
    return <>
        {showHasChangesWarning && 
            <DirtyFormMessageBar 
                primaryCommandLabel='Generate' 
                primaryActionLabel='proceed'
                cancelCommandLabel='Cancel'
                cancelActionLabel='discard' 
            />}
        <div className="panel-buttons">
        <PrimaryButton
            text="Generate"
            disabled={isGenerateDisabled}
            onClick={onGenerateClick}
        />
        <DefaultButton
            text="Cancel"
            onClick={onDismiss}
        />
    </div>
    </>
}

export default AIScheduleDataInputFooter;