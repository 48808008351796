import * as React from 'react';
import { ActionButton } from 'office-ui-fabric-react';
import { IPredecessorInfo, ITask, ITaskAttrs } from '../../../entities/Subentities';
import TaskPredecessorEditBox from './TaskPredecessorEditBox';
import TaskPredecessor from './TaskPredecessor';
import { Dictionary } from '../../../entities/common';
import { nameof } from '../../../store/services/metadataService';
import { CalendarDataSet } from '../../../store/CalendarStore';
import { applyPredecessors } from '../../utils/duration';
import { toDictionaryById } from '../../utils/common';

interface RoadmapItemDependenciesProps {
    readonly: boolean;
    task: ITask;
    tasks: ITask[];
    calendar: CalendarDataSet;
    onChange: (fieldName?: string, fieldValue?: unknown, extra?: Dictionary<unknown>) => void;
}

const TaskPredecessors = (props: RoadmapItemDependenciesProps) => {
    const { readonly, calendar, tasks, task, onChange } = props;

    const [createPanelIsShown, setCreatePanelVisible] = React.useState<boolean>(false);
    const showCreatePanel = React.useCallback(() => setCreatePanelVisible(true), []);
    const hideCreatePanel = React.useCallback(() => setCreatePanelVisible(false), []);

    const [predecessorToEdit, setPredecessorToEdit] = React.useState<IPredecessorInfo>();
    const cancelPredecessorEdit = React.useCallback(() => setPredecessorToEdit(undefined), []);

    const onRemove = React.useCallback((predecessor: IPredecessorInfo) => {
        const predecessors = task.attributes.Predecessor?.filter(_ => predecessor !== _);
        const extra = applyPredecessors(task.attributes, predecessors, tasksMap, calendar);
        onChange(nameof<ITaskAttrs>("Predecessor"), predecessors, extra);
    }, [task, onChange]);

    const tasksMap = React.useMemo(() => toDictionaryById(tasks ?? []), [tasks]);

    const onCreate = (predecessor: IPredecessorInfo) => {
        const predecessors = [...(task.attributes.Predecessor?.map(_ => Object.assign({}, _)) || []), predecessor];
        const extra = applyPredecessors(task.attributes, predecessors, tasksMap, calendar);
        onChange(nameof<ITaskAttrs>("Predecessor"), predecessors, extra);
        hideCreatePanel();
    };

    const onUpdate = (predecessorId: string, predecessor: IPredecessorInfo) => {
        const predecessors = task.attributes.Predecessor?.map(_ => Object.assign({}, _.id === predecessorId ? predecessor : _))
        const extra = applyPredecessors(task.attributes, predecessors, tasksMap, calendar);
        onChange(nameof<ITaskAttrs>("Predecessor"), predecessors, extra);
        cancelPredecessorEdit();
    };

    return <>
        {task.attributes.Predecessor?.map((_, index) => predecessorToEdit === _
            ? <TaskPredecessorEditBox {...props} predecessor={_} onApply={(updated) => onUpdate(_.id, updated)} onCancel={cancelPredecessorEdit} />
            : <TaskPredecessor
                key={index}
                predecessor={_}
                readonly={readonly}
                allowEdit={!predecessorToEdit && !createPanelIsShown}
                onEdit={setPredecessorToEdit}
                onRemove={onRemove} />)}
        {!readonly && !createPanelIsShown && <ActionButton disabled={!!predecessorToEdit} iconProps={{ iconName: "Add" }} onClick={showCreatePanel}>Add</ActionButton>}
        {!readonly && !predecessorToEdit && createPanelIsShown && <TaskPredecessorEditBox {...props} onApply={onCreate} onCancel={hideCreatePanel} />}
    </>;
}
export default TaskPredecessors;