import * as React from 'react';
import { connect } from 'react-redux';
import {
    Spinner, Dialog, DialogType, TextField, PrimaryButton, DefaultButton, DialogFooter, SpinnerSize, MessageBar,
    MessageBarType
} from 'office-ui-fabric-react';
import { Validator } from "../../../validation";
import { ApplicationState } from '../../../store';
import ConnectionEditWrapper from '../ConnectionEditWrapper';
import { actionCreators, IConnectionRefreshInfo, IMondayComConnectionInfo } from '../../../store/integration/MondayComStore';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ConnectionTitle } from '../ConnectionTitle';
import { useDidMountEffect } from '../../utils/effects';

type OwnProps = { onDismiss: () => void; onCreated?: (connectionId: string) => void; connectionId?: string }
type StateProps = { isProcessing: boolean; error: string | null, refreshInfo: IConnectionRefreshInfo | null, createdConnectionId?: string }
type Props = OwnProps & StateProps & typeof actionCreators;

const validator = Validator.new().required().build();

const dismiss = (props: Props) => {
    props.onDismiss();
    props.cleanError();
}
const MondayComConnectionEdit = (props: Props) => {
    const [info, setInfo] = React.useState<IMondayComConnectionInfo>({ id: props.connectionId, personalAccessToken: '' });
    useDidMountEffect(() => {
        if (!props.isProcessing && !props.error) {
            props.onCreated && props.createdConnectionId && props.onCreated(props.createdConnectionId);
            dismiss(props);
        }
    }, [props.isProcessing]);

    const isEdit = !!info.id;
    const okText = isEdit ? "Save" : "Create";
    return <Dialog
        hidden={false}
        minWidth={400}
        maxWidth={400}
        dialogContentProps={{
            onDismiss: () => dismiss(props),
            type: DialogType.normal,
            title: <ConnectionTitle isEdit={isEdit} sourceType={SourceType.MondayCom} />
        }}
        modalProps={{
            isBlocking: true,
            containerClassName: 'ms-dialogMainOverride connection-popup'
        }}>
        <ConnectionEditWrapper sourceType={SourceType.MondayCom}
            articleGuidePath="https://help.ppm.express/mondaycom-connection/1423967"
            videoGuidePath="https://help.ppm.express/mondaycom-connection/1431368">
            <TextField validateOnFocusOut={true}
                validateOnLoad={false}
                type="password"
                label="Personal Access Token"
                value={info.personalAccessToken}
                placeholder="token"
                onChange={(e, _) => setInfo((prevState) => ({ ...prevState, personalAccessToken: _?.trim() || '' }))}
                onGetErrorMessage={validator.getErrorMessage} />
        </ConnectionEditWrapper>
        {props.error && <p>
            <MessageBar
                messageBarType={MessageBarType.severeWarning}
                isMultiline={true}>
                {props.error}
            </MessageBar>
        </p>}
        <DialogFooter>
            {
                props.isProcessing
                && [<PrimaryButton key="processing-ok" disabled text={okText}>
                    <Spinner size={SpinnerSize.medium} />
                </PrimaryButton>,
                <DefaultButton key="processing-cancel" disabled text="Cancel" />
                ]
            }
            {
                !props.isProcessing
                && [<PrimaryButton
                    key="ok"
                    disabled={!validator.isValid(info.personalAccessToken)}
                    onClick={() => validator.isValid(info.personalAccessToken) && props.createOrRefreshConnection(info)}
                    text={okText} />,
                <DefaultButton key="cancel" onClick={() => dismiss(props)} text="Cancel" />
                ]
            }
        </DialogFooter>
    </Dialog>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        isProcessing: state.mondayCom.connections.isProcessing,
        error: state.mondayCom.connections.error,
        refreshInfo: state.mondayCom.connections.refreshInfo,
        createdConnectionId: state.mondayCom.connections.createdConnectionId
    }
}

export default connect(mapStateToProps, actionCreators)(MondayComConnectionEdit);