import {
    IConnectionsState, IApiResult, IListStore, IImportResult, IImportProjectMap, IImportResourceMap, IFieldMapping, ExternalFieldInfo,
    IEntityStore, TaskImportSettings, MapConfig, IResourceSourceData, ConnectionsState, ItemsIterationsScheduleData
} from "./common";
import * as Metadata from "../../entities/Metadata";
import { get, post, remove } from "../../fetch-interceptor";
import { defaultCatch, catchApiError } from "../utils";
import { AppThunkAction } from "../index";
import { Reducer, Action } from 'redux';
import { ProjectsImportActions, Utils, ResourcesImportActions } from "./ImportStore";
import { Dictionary } from "../../entities/common";
import { IDeletionResult } from '../services/storeHelper';
import { RemovedPortfoliosSourceInfosAction } from '../PortfoliosListStore';
import { RemovedProjectsSourceInfosAction } from '../ProjectsListStore';
import { RemovedResourcesSourceInfosAction } from '../ResourcesListStore';

export interface IVSTSConnectionState extends IConnectionsState {
    refreshInfo: IConnectionRefreshInfo | null;
    createdConnectionId?: string;
}

export enum InstanceType {
    VSTS = 0,
    TFS2017 = 1
}

export interface IConnectionRefreshInfo {
    instanceType: InstanceType;
    targetUrl: string;
    account: string;
}

export interface WorkItem {
    id: number;
    name?: string;
    type: string;
}

export interface IBaseVSTSSourceData {
    projectId: string;
    projectName: string;
    areaPath?: string;
    iterationPath?: string;
    teamId?: string;
    teamName?: string;
    workItems: WorkItem[];
}
export interface IVSTSSourceData extends IBaseVSTSSourceData {
    projectUrl: string;
}

export interface IVSTSUserLinkInfo extends IResourceSourceData {
    userId: string;
    email: string;
}

export interface IVSTSWorkItem {
    id: number,
    name: string,
    type: string,
    state: string,
    iteration: string,
    area: string,
    tags: string[],
    isParent: boolean,
    parentId?: number,
    outlineLevel?: number,
    isAlreadyLinked: boolean
}

export interface IVSTSTeam {
    id: number,
    name: string,
}

export interface IVSTSUser {
    id: string;
    displayName: string;
    email: string;
    isAlreadyLinked: boolean;
}

export interface IVSTSProject {
    id: string;
    name: string;
    processId: string;
    workItems: WorkItem[];
    isAlreadyLinked: boolean;
}

interface IWorkItemClassificationNodeStore {
    entity: IWorkItemClassificationNode | null;
    isLoading: boolean;
    error: string | null;
}

export interface IWorkItemClassificationNode {
    identifier: string;
    name: string;
    path: string;
    hasChildren?: boolean;
    children?: IWorkItemClassificationNode[];
}

export interface IWorkItemState {
    name: string;
    category: string;
    color: string;
}

export interface IWorkItemType {
    name: string;
    referenceName: string;
    states: IWorkItemState[];
    icon?: { id: string, url: string }
}

export interface IConnectionConfiguration {
    mapping: IFieldMapping[];
    progressCalculationSettings: IProgressCalculationSettings;
    taskImportSettings: TaskImportSettings
}

export interface IProgressCalculationSettings {
    workItemTypes: string[];
    autoStatusCalculationWorkItemTypes: string[];
    calculateProgressBy?: string;
}

export interface VSTSState {
    connections: IVSTSConnectionState;
    projects: IListStore<IVSTSProject>;
    users: IListStore<IVSTSUser>;
    areas: IWorkItemClassificationNodeStore;
    iterations: IWorkItemClassificationNodeStore;
    fields: IListStore<ExternalFieldInfo>;
    workItemTypes: IListStore<IWorkItemType>;
    processWorkItemTypes: IEntityStore<Dictionary<IWorkItemType[]>>;
    teams: IListStore<IVSTSTeam>;
    mapping: IEntityStore<IFieldMapping[]>;
    progressCalculationSettings: IEntityStore<IProgressCalculationSettings>;
    taskImportSettings: IEntityStore<TaskImportSettings>,
    defaultTaskSettings: IEntityStore<TaskImportSettings>
}

export interface VSTSScheduleData extends ItemsIterationsScheduleData {}

export type LoadConnections = { type: "VSTS_LOAD_CONNECTIONS" }
export type ReceivedConnections = { type: "VSTS_RECEIVED_CONNECTIONS"; connections: Metadata.IConnectionInfo[]; }
type ConnectionCreatingOrRefreshing = { type: "VSTS_CONNECTION_CREATING_OR_REFRESHING", id?: string }
type ConnectionCreatedOrRefreshed = { type: "VSTS_CONNECTION_CREATED_OR_REFRESHED", connection: Metadata.IConnectionInfo, isRefreshing: boolean }
type ReceivedConnectionRefreshInfo = { type: "VSTS_RECEIVED_CONNECTION_REFRESH_INFO", info: IConnectionRefreshInfo }
type RemovedConnection = { type: "VSTS_CONNECTION_REMOVED", id: string }
type ConnectionOperationError = { type: "VSTS_CONNECTION_OPERATION_ERROR", error: string | null }

type VerifyConnection = { type: 'VSTS_VERIFY_CONNECTION', connectionId: string }
type ConnectionVerified = { type: 'VSTS_CONNECTION_VERIFIED', connectionId: string }
type ConnectionVerificationError = { type: 'VSTS_CONNECTION_VERIFICATION_ERROR', connectionId: string, error: string | null }

type ConnectionAction = LoadConnections
    | ReceivedConnections
    | ConnectionCreatingOrRefreshing
    | ConnectionCreatedOrRefreshed
    | RemovedConnection
    | ReceivedConnectionRefreshInfo
    | ConnectionOperationError
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError;

type LoadProjects = { type: "VSTS_LOAD_PROJECTS" }
type ReceivedProjects = { type: "VSTS_RECEIVED_PROJECTS", projects: IVSTSProject[] }
type SetProjectsError = { type: "VSTS_SET_PROJECTS_ERROR", error: string | null }

type LoadUsers = { type: "VSTS_LOAD_USERS" }
type ReceivedUsers = { type: "VSTS_RECEIVED_USERS", users: IVSTSUser[] }
type SetUsersError = { type: "VSTS_SET_USERS_ERROR", error: string | null }

type LoadIterations = { type: "VSTS_LOAD_ALL_ITERATIONS" }
type ReceivedIterations = { type: "VSTS_RECEIVED_ALL_ITERATIONS", iterations: IWorkItemClassificationNode }
type SetIterationsError = { type: "VSTS_SET_ALL_ITERATIONS_ERROR", error: string | null }

type LoadFields = { type: "VSTS_LOAD_FIELDS" }
type ReceivedFields = { type: "VSTS_RECEIVED_FIELDS", fields: ExternalFieldInfo[] }
type SetFieldsError = { type: "VSTS_SET_FIELDS_ERROR", error: string | null }

type LoadWorkItemTypes = { type: "VSTS_LOAD_WIT_TYPES" }
type ReceivedWorkItemTypes = { type: "VSTS_RECEIVED_WIT_TYPES", types: IWorkItemType[] }
type SetWorkItemTypesError = { type: "VSTS_SET_WIT_TYPES_ERROR", error: string | null }

type LoadProcessWorkItemTypes = { type: "VSTS_LOAD_PROCESS_WIT_TYPES" }
type ReceivedProcessWorkItemTypes = { type: "VSTS_RECEIVED_PROCESS_WIT_TYPES", map: Dictionary<IWorkItemType[]> }
type SetProcessWorkItemTypesError = { type: "VSTS_SET_PROCESS_WIT_TYPES_ERROR", error: string | null }

type LoadTeams = { type: "VSTS_LOAD_TEAMS" }
type ReceivedTeams = { type: "VSTS_RECEIVED_TEAMS", teams: IVSTSTeam[] }
type SetTeamsError = { type: "VSTS_SET_TEAMS_ERROR", error: string | null }

type LoadAreas = { type: "VSTS_LOAD_AREAS" }
type ReceivedAreas = { type: "VSTS_RECEIVED_AREAS", areas: IWorkItemClassificationNode }
type SetAreasError = { type: "VSTS_SET_AREAS_ERROR", error: string | null }

type LoadConnectionConfiguration = { type: "VSTS_LOAD_CONNECTION_CONFIGURATION" }
type UpdateConnectionConfiguration = { type: "VSTS_UPDATE_CONNECTION_CONFIGURATION" }
type ReceivedConnectionConfiguration = { type: "VSTS_RECEIVED_CONNECTION_CONFIGURATION", config: IConnectionConfiguration }
type LoadConnectionConfigurationError = { type: "VSTS_LOAD_CONNECTION_CONFIGURATION_ERROR", error: string | null }

type LoadDefaultTaskMapping = { type: "VSTS_LOAD_DEFAULT_TASK_MAPPINGS" }
type ReceivedDefaultTaskMapping = { type: "VSTS_RECEIVED_DEFAULT_TASK_MAPPINGS", mappings: TaskImportSettings }
type SetDefaultTaskMappingError = { type: "VSTS_LOAD_DEFAULT_TASK_MAPPINGS_ERROR", error: string | null }

type EntitiesAction = LoadProjects | ReceivedProjects | SetProjectsError |
    LoadUsers | ReceivedUsers | SetUsersError |
    LoadAreas | ReceivedAreas | SetAreasError |
    LoadIterations | ReceivedIterations | SetIterationsError |
    LoadFields | ReceivedFields | SetFieldsError |
    LoadWorkItemTypes | ReceivedWorkItemTypes | SetWorkItemTypesError |
    LoadProcessWorkItemTypes | ReceivedProcessWorkItemTypes | SetProcessWorkItemTypesError |
    LoadTeams | ReceivedTeams | SetTeamsError |
    LoadConnectionConfiguration | ReceivedConnectionConfiguration | UpdateConnectionConfiguration | LoadConnectionConfigurationError |
    LoadDefaultTaskMapping | ReceivedDefaultTaskMapping | SetDefaultTaskMappingError;

type KnownAction = ConnectionAction | EntitiesAction;

export interface IVSTSConnectionInfo {
    id?: string;
    instanceType: InstanceType;
    targetUrl: string;
    personalAccessToken: string;
}

//todo: verify field types
export enum VSTSFieldType {
    String = 'String',
    Integer = 'Integer',
    DateTime = 'DateTime',
    PlainText = 'PlainText',
    Html = 'Html',
    TreePath = 'TreePath',
    History = 'History',
    Double = 'Double',
    Guid = 'Guid',
    Boolean = 'Boolean',
    Identity = 'Identity',
    PicklistString = 'PicklistString',
    PicklistInteger = 'PicklistInteger',
    PicklistDouble = 'PicklistDouble'
}

export const vstsFieldToPpmxFieldsMap: { [i: string]: MapConfig } = {
    [VSTSFieldType.String]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Hyperlink], label: "Text" },
    [VSTSFieldType.Integer]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal, Metadata.FieldType.Text], label: "Number" },
    [VSTSFieldType.DateTime]: { types: [Metadata.FieldType.Date], label: "Date" },
    [VSTSFieldType.PlainText]: { types: [Metadata.FieldType.Text], label: "Plain Text" },
    [VSTSFieldType.Html]: { types: [Metadata.FieldType.Text], label: "Html" },
    [VSTSFieldType.TreePath]: { types: [Metadata.FieldType.Text], label: "TreePath" },
    [VSTSFieldType.History]: { types: [], label: "History" },
    [VSTSFieldType.Double]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [VSTSFieldType.Guid]: { types: [Metadata.FieldType.Text], label: "Guid" },
    [VSTSFieldType.Boolean]: { types: [Metadata.FieldType.Flag], label: "Flag" },
    [VSTSFieldType.Identity]: { types: [Metadata.FieldType.Resource, Metadata.FieldType.User], label: "Identity" },
    [VSTSFieldType.PicklistString]: { types: [Metadata.FieldType.Text], label: "PicklistString" },
    [VSTSFieldType.PicklistInteger]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "PicklistInteger" },
    [VSTSFieldType.PicklistDouble]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "PicklistDouble" }
}

export const actionCreators = {
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_CONNECTIONS' });
        get<Metadata.IConnectionInfo[]>(`api/integration/vsts/connection`)
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_CONNECTIONS', connections: _ }))
            .catch(defaultCatch(dispatch));
    },
    createOrRefreshConnection: (data: IVSTSConnectionInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_CONNECTION_CREATING_OR_REFRESHING', id: data.id });
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/vsts/connection`, data)
            .then(_ => {
                if (_.errorMessage) {
                    dispatch({ type: 'VSTS_CONNECTION_OPERATION_ERROR', error: _.errorMessage });
                } else {
                    dispatch({ type: 'VSTS_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: !!data.id });
                }
            })
            .catch(defaultCatch(dispatch));
    },
    renameConnection: (connectionId: string, title: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/vsts/connection/${connectionId}/rename`, { title })
            .then(_ => dispatch({ type: 'VSTS_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: true }))
            .catch(defaultCatch(dispatch));
    },
    removeConnection: (id: string, callback?: () => void):
        AppThunkAction<KnownAction | RemovedPortfoliosSourceInfosAction | RemovedProjectsSourceInfosAction | RemovedResourcesSourceInfosAction> => (dispatch, getState) => {
            dispatch({ type: 'VSTS_CONNECTION_CREATING_OR_REFRESHING', id });
            remove<IDeletionResult>(`api/integration/vsts/connection/${id}`)
                .then(_ => {
                    if (_.isDeleted) {
                        dispatch({ type: 'VSTS_CONNECTION_REMOVED', id: _.id });
                        dispatch({ type: "REMOVED_PORTFOLIOS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_PROJECTS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_RESOURCES_SOURCE_INFOS", connectionId: _.id });
                        callback?.();
                    } else {
                        dispatch({ type: 'VSTS_CONNECTION_OPERATION_ERROR', error: _.message || null });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    cleanError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_CONNECTION_OPERATION_ERROR', error: null });
    },
    loadRefreshInfo: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<IConnectionRefreshInfo>(`api/integration/vsts/connection/${connectionId}/refresh`)
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_CONNECTION_REFRESH_INFO', info: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadDefaultTaskMappings: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_DEFAULT_TASK_MAPPINGS' });
        get<TaskImportSettings>(`api/integration/vsts/defaultTaskMappings/${connectionId}/${projectId}`)
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_DEFAULT_TASK_MAPPINGS', mappings: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_LOAD_DEFAULT_TASK_MAPPINGS_ERROR', error: `Unable to load DevOps default task mappings settings: ${_}` })));
    },
    loadConnectionConfiguration: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_CONNECTION_CONFIGURATION' });
        get<IConnectionConfiguration>(`api/integration/vsts/connectionConfiguration/${connectionId}/${projectId}`)
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load DevOps connection configuration: ${_}` })));
    },
    updateConnectionConfiguration: (connectionId: string, projectId: string, configuration: IConnectionConfiguration): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(({ type: 'VSTS_UPDATE_CONNECTION_CONFIGURATION' }));
        post<IConnectionConfiguration>(`api/integration/vsts/connectionConfiguration/${connectionId}/${projectId}`, configuration)
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load DevOps mapping: ${_}` })));
    },
    loadProjects: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_PROJECTS' });
        get<IVSTSProject[]>(`api/integration/vsts/projects`, { connectionId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_PROJECTS', projects: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_PROJECTS_ERROR', error: `Unable to load DevOps projects: ${_}` })));
    },
    loadUsers: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_USERS' });
        get<IVSTSUser[]>(`api/integration/vsts/resources`, { connectionId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_USERS', users: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_USERS_ERROR', error: `Unable to load DevOps resources: ${_}` })));
    },
    loadAreas: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_AREAS' });
        get<IWorkItemClassificationNode>(`api/integration/vsts/areas`, { connectionId, projectId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_AREAS', areas: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_AREAS_ERROR', error: `Unable to load DevOps areas: ${_}` })));
    },
    loadIterations: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_ALL_ITERATIONS' });
        get<IWorkItemClassificationNode>(`api/integration/vsts/iterations`, { connectionId, projectId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_ALL_ITERATIONS', iterations: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_ALL_ITERATIONS_ERROR', error: `Unable to load DevOps iterations: ${_}` })));
    },
    loadFields: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_FIELDS' });
        get<ExternalFieldInfo[]>(`api/integration/vsts/fields`, { connectionId, projectId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_FIELDS_ERROR', error: `Unable to load DevOps fields: ${_}` })));
    },
    loadProcessWorkItemTypes: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_PROCESS_WIT_TYPES' });
        get<Dictionary<IWorkItemType[]>>(`api/integration/vsts/processworkitemtypes`, { connectionId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_PROCESS_WIT_TYPES', map: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_PROCESS_WIT_TYPES_ERROR', error: `Unable to load DevOps process work item types: ${_}` })));
    },
    loadWorkItemTypes: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_WIT_TYPES' });
        get<IWorkItemType[]>(`api/integration/vsts/workitemtypes`, { connectionId, projectId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_WIT_TYPES', types: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_WIT_TYPES_ERROR', error: `Unable to load DevOps work item types: ${_}` })));
    },
    loadTeams: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_LOAD_TEAMS' });
        get<IVSTSTeam[]>(`api/integration/vsts/teams`, { connectionId, projectId })
            .then(_ => dispatch({ type: 'VSTS_RECEIVED_TEAMS', teams: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_SET_TEAMS_ERROR', error: `Unable to load DevOps teams: ${_}` })));
    },
    importProjects: (connectionId: string, maps: IImportProjectMap<IVSTSProject>[]): AppThunkAction<ProjectsImportActions> => (dispatch, getState) => {
        const entityIds = maps.map(_ => _.linkingData.id);
        dispatch({ type: 'IMPORT_PROJECTS_STARTED', entityIds });

        Utils.batchSend(maps,
            batch => post<IImportResult[]>('api/integration/vsts/import/projects', { connectionId, maps: batch })
                .then(_ => dispatch({ type: 'IMPORT_PROJECTS_BATCH_FINISHED', results: _ })))
            .then(() => dispatch({ type: 'IMPORT_PROJECTS_FINISHED' }))
            .catch(defaultCatch(dispatch));
    },
    importResources: (connectionId: string, maps: IImportResourceMap<IVSTSUser>[]): AppThunkAction<ResourcesImportActions> => (dispatch, getState) => {
        dispatch({ type: 'IMPORT_RESOURCES_STARTED' });
        Utils.sendByChunks(maps, chunk => post<IImportResult[]>('api/integration/vsts/import/resources', { connectionId, maps: chunk }))
            .then(_ => dispatch({ type: 'IMPORT_RESOURCES_FINISHED', results: _ }))
            .catch(defaultCatch(dispatch));
    },
    verifyConnection: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'VSTS_VERIFY_CONNECTION', connectionId });
        get(`api/integration/vsts/verify-connection`, { connectionId })
            .then(_ => dispatch({ type: 'VSTS_CONNECTION_VERIFIED', connectionId }))
            .catch(catchApiError(_ => dispatch({ type: 'VSTS_CONNECTION_VERIFICATION_ERROR', connectionId: connectionId, error: _ })));
    }
}
const initState: VSTSState = {
    connections: {
        isLoading: false,
        isProcessing: false,
        data: [],
        refreshInfo: null,
        error: null,
        connectionsVerification: { }
    },
    projects: {
        entities: [],
        isLoading: false,
        error: null,
    },
    users: {
        entities: [],
        isLoading: false,
        error: null,
    },
    areas: {
        entity: null,
        isLoading: false,
        error: null,
    },
    iterations: {
        entity: null,
        isLoading: false,
        error: null,
    },
    fields: {
        entities: [],
        isLoading: false,
        error: null,
    },
    processWorkItemTypes: {
        entities: {},
        isLoading: false,
        isProcessing: false,
        error: null,
    },
    workItemTypes: {
        entities: [],
        isLoading: false,
        error: null,
    },
    teams: {
        entities: [],
        isLoading: false,
        error: null,
    },
    mapping: {
        isLoading: false,
        isProcessing: false,
        entities: [],
        error: null
    },
    progressCalculationSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {
            workItemTypes: [],
            autoStatusCalculationWorkItemTypes: []
        },
        error: null
    },
    taskImportSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {},
        error: null
    },
    defaultTaskSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {},
        error: null
    }
}

const connectionsReducer: Reducer<IVSTSConnectionState> = (state: IVSTSConnectionState, incomingAction: Action) => {
    const action = incomingAction as ConnectionAction;
    switch (action.type) {
        case 'VSTS_LOAD_CONNECTIONS':
            {
                return {
                    ...state,
                    isLoading: true,
                    connectionsVerification: { }
                }
            }
        case 'VSTS_RECEIVED_CONNECTIONS':
            {
                return {
                    ...state,
                    data: action.connections,
                    isLoading: false
                }
            }
        case 'VSTS_CONNECTION_CREATING_OR_REFRESHING':
            {
                return {
                    ...state,
                    isProcessing: true,
                    connectionsVerification: ConnectionsState.remove(state, action.id)
                }
            }
        case "VSTS_CONNECTION_CREATED_OR_REFRESHED":
            {
                return {
                    ...state,
                    data: action.isRefreshing
                        ? state.data.map(_ => _.id === action.connection.id ? action.connection : _)
                        : state.data.concat(action.connection),
                    error: null,
                    createdConnectionId: action.isRefreshing ? undefined : action.connection.id,
                    isProcessing: false
                }
            }
        case "VSTS_CONNECTION_REMOVED":
            {
                return {
                    ...state,
                    data: state.data.filter(_ => _.id !== action.id),
                    connectionsVerification: ConnectionsState.remove(state, action.id),
                    error: null,
                    isProcessing: false
                }
            }
        case "VSTS_RECEIVED_CONNECTION_REFRESH_INFO":
            {
                return {
                    ...state,
                    refreshInfo: action.info
                }
            }
        case "VSTS_CONNECTION_OPERATION_ERROR":
            {
                return {
                    ...state,
                    error: action.error,
                    isProcessing: false
                }
            }
        case "VSTS_VERIFY_CONNECTION":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationStarting(state, action.connectionId)
                }
            }
        case "VSTS_CONNECTION_VERIFIED":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFinished(state, action.connectionId)
                }
            }
        case "VSTS_CONNECTION_VERIFICATION_ERROR":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFailed(state, action.connectionId, action.error)
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}


const entitiesReducer: Reducer<VSTSState> = (state: VSTSState, incomingAction: Action) => {
    const action = incomingAction as EntitiesAction;
    switch (action.type) {
        case 'VSTS_LOAD_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'VSTS_RECEIVED_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: action.projects,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'VSTS_SET_PROJECTS_ERROR':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'VSTS_LOAD_USERS':
            {
                return {
                    ...state,
                    users: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'VSTS_RECEIVED_USERS':
            {
                return {
                    ...state,
                    users: {
                        entities: action.users,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'VSTS_SET_USERS_ERROR':
            {
                return {
                    ...state,
                    users: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'VSTS_LOAD_AREAS':
            {
                return {
                    ...state,
                    areas: {
                        entity: null,
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'VSTS_RECEIVED_AREAS':
            {
                return {
                    ...state,
                    areas: {
                        entity: action.areas,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'VSTS_SET_AREAS_ERROR':
            {
                return {
                    ...state,
                    areas: {
                        entity: null,
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'VSTS_LOAD_ALL_ITERATIONS':
            {
                return {
                    ...state,
                    iterations: {
                        entity: null,
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'VSTS_RECEIVED_ALL_ITERATIONS':
            {
                return {
                    ...state,
                    iterations: {
                        entity: action.iterations,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'VSTS_SET_ALL_ITERATIONS_ERROR':
            {
                return {
                    ...state,
                    iterations: {
                        entity: null,
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'VSTS_LOAD_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'VSTS_RECEIVED_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: action.fields,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'VSTS_SET_FIELDS_ERROR':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'VSTS_LOAD_PROCESS_WIT_TYPES':
            {
                return {
                    ...state,
                    processWorkItemTypes: {
                        ...initState.processWorkItemTypes,
                        isLoading: true,
                    }
                }
            }
        case 'VSTS_RECEIVED_PROCESS_WIT_TYPES':
            {
                return {
                    ...state,
                    processWorkItemTypes: {
                        ...initState.processWorkItemTypes,
                        entities: action.map,
                    }
                }
            }
        case 'VSTS_SET_PROCESS_WIT_TYPES_ERROR':
            {
                return {
                    ...state,
                    processWorkItemTypes: {
                        ...initState.processWorkItemTypes,
                        error: action.error
                    }
                }
            }
        case 'VSTS_LOAD_WIT_TYPES':
            {
                return {
                    ...state,
                    workItemTypes: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'VSTS_RECEIVED_WIT_TYPES':
            {
                return {
                    ...state,
                    workItemTypes: {
                        entities: action.types,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'VSTS_SET_WIT_TYPES_ERROR':
            {
                return {
                    ...state,
                    workItemTypes: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'VSTS_LOAD_TEAMS':
            {
                return {
                    ...state,
                    teams: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'VSTS_RECEIVED_TEAMS':
            {
                return {
                    ...state,
                    teams: {
                        entities: action.teams.sort((a, b) => a.name > b.name ? 1 : -1),
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'VSTS_SET_TEAMS_ERROR':
            {
                return {
                    ...state,
                    teams: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case "VSTS_RECEIVED_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, entities: action.config.progressCalculationSettings },
                    mapping: { ...initState.mapping, entities: action.config.mapping },
                    taskImportSettings: { ...initState.taskImportSettings, entities: action.config.taskImportSettings },
                }
            }
        case "VSTS_LOAD_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, isLoading: true },
                    mapping: { ...initState.mapping, isLoading: true },
                    taskImportSettings: { ...initState.taskImportSettings, isLoading: true }
                }
            }
        case "VSTS_UPDATE_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...state.progressCalculationSettings, isProcessing: true },
                    mapping: { ...state.mapping, isProcessing: true },
                    taskImportSettings: { ...state.taskImportSettings, isProcessing: true }
                }
            }
        case 'VSTS_LOAD_CONNECTION_CONFIGURATION_ERROR':
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, error: action.error }
                }
            }
        case 'VSTS_LOAD_DEFAULT_TASK_MAPPINGS':
            {
                return {
                    ...state,
                    defaultTaskSettings: { ...initState.defaultTaskSettings, isLoading: true }
                }
            }
        case "VSTS_RECEIVED_DEFAULT_TASK_MAPPINGS":
            {
                return {
                    ...state,
                    defaultTaskSettings: { ...initState.defaultTaskSettings, entities: action.mappings }
                }
            }
        case 'VSTS_LOAD_DEFAULT_TASK_MAPPINGS_ERROR':
            {
                return {
                    ...state,
                    defaultTaskSettings: { ...initState.defaultTaskSettings, error: action.error }
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

export const reducer: Reducer<VSTSState> = (state: VSTSState = initState, incomingAction: Action) => {
    return {
        ...entitiesReducer(state, incomingAction),
        connections: connectionsReducer(state.connections, incomingAction)
    }
}