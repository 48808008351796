import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Field, FieldGroup, FieldType } from '../../../../../entities/Metadata';
import { ITask } from '../../../../../entities/Subentities';

export const CalculationModeFakeField: Field = {
    id: "29f28f00-02c5-46e4-851f-253dfe8fa9ea",
    label: "Mode",
    name: "mode",
    type: FieldType.Integer,
    isNative: false,
    isCustom: false,
    isReadonly: true,
    isSystem: false,
    isFake: true,
    group: FieldGroup.SystemFields,
    description: "Mode defines whether horizontal and vertical (roll-up) calculation for progress-related fields is performed "
        + "automatically (Auto) or on-demand (Manual).",
    settings: {
        views: { list: { componentPath: "task/Mode", maxWidth: 170 } }
    },
};

export enum TaskCalculationModeTypes {
    Auto = "Auto",
    Manual = "Manual"
}

export default class Mode extends React.Component<IListViewFieldColumn<ITask>> {
    render() {
        const { entity } = this.props;
        return <>{entity.isAutoMode ? TaskCalculationModeTypes.Auto : TaskCalculationModeTypes.Manual}</>;
    }
}

export const getValue = (item: ITask) => item.isAutoMode;
export const getSearchValue = (item: ITask) => item.isAutoMode ? TaskCalculationModeTypes.Auto : TaskCalculationModeTypes.Manual;

