import * as React from 'react';
import { DisplayFieldService } from "../../../DisplayFieldService";
import * as Metadata from '../../../../../entities/Metadata';
import { IValidator } from '../../../../../validation';
import { Dictionary, EntityType, IWarning } from '../../../../../entities/common';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { UserState } from '../../../../../store/User';
import { IInputProps } from '../../../interfaces/IInputProps';
import LabellableFieldComponent from './LabellableFieldComponent';
import { TenantState } from '../../../../../store/Tenant';

export type IInputRender = (props: IInputProps, state: any, field: Metadata.Field, validator?: IValidator) => JSX.Element;
export type OnChangeEvent = (fieldName: string, fieldValue: any, extra?: Dictionary<any>) => void;
export type OnEditCompleteEvent = (fieldName: string, fieldValue: any, extra?: Dictionary<any>) => void;

export type FieldComponentProps = {
    state: { attributes: Dictionary<unknown> };
    entityType?: EntityType;
    onEditComplete?: OnEditCompleteEvent;
    onChanged?: OnChangeEvent;
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
    isConfigureMode?: boolean;
    field: Metadata.Field;
    hideLabel?: boolean;
    renderInput?: IInputRender;
    validator?: IValidator;
    warnings?: IWarning[];
};

type StateProps = {
    user: UserState;
    tenant: TenantState;
}
type Props = FieldComponentProps & StateProps;

class FieldComponent extends React.Component<Props, { value: unknown }> {
    private inputElement: any;

    public render() {
        const { field, readOnly, isConfigureMode, warnings } = this.props;
        const input = this.renderInput();
        if (!input) {
            return null;
        }

        return (
            <div className={`${this.props.className}`}>
                {
                    this.props.hideLabel
                        ? input
                        : <LabellableFieldComponent
                            label={Metadata.getLabel(field)}
                            description={field.description}
                            required={Metadata.isRequired(field)}
                            readOnly={readOnly}
                            fieldReadonly={field.isReadonly}
                            onClick={this.setFocus}
                            isConfigureMode={isConfigureMode}
                            warnings={warnings}>
                            {input}
                        </LabellableFieldComponent>
                }
            </div>
        );
    }

    private renderInput = (): React.FunctionComponentElement<any> | null => {
        const { field, renderInput, validator, readOnly, disabled, state, entityType, user, tenant } = this.props;
        const inputProps: IInputProps = {
            readOnly,
            disabled,
            value: this.props.state.attributes[field.name],
            onEditComplete: this.onEditComplete,
            onChanged: this.onChanged
        };

        let element = renderInput?.(inputProps, state, field, validator);
        element = element === undefined ? DisplayFieldService.buildFieldInputElement(field, inputProps, entityType, user, tenant, validator) : element;
        return element ? React.cloneElement(element, { ref: ((el: any) => { this.inputElement = el }).bind(this) }) : null;
    }

    private onEditComplete = (value: any, extra?: Dictionary<any>) => {
        if (this.isEnabled() && this.props.onEditComplete && !this.props.isConfigureMode) {
            this.props.onEditComplete(this.props.field.name, value, extra);
        }
    }

    private onChanged = (value: any, extra?: Dictionary<any>) => {
        if (this.isEnabled() && this.props.onChanged && !this.props.isConfigureMode) {
            this.props.onChanged(this.props.field.name, value, extra);
        }
    }

    private setFocus = () => {
        if (this.isEnabled() && this.inputElement && this.inputElement.focus)
            this.inputElement.focus();
    }

    private isEnabled = (): boolean => !this.props.disabled && !this.props.readOnly && !this.props.field.isReadonly;
}

function mapStateToProps(state: ApplicationState) {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(FieldComponent)