import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { css } from 'office-ui-fabric-react';
import * as analytics from './analytics';
import * as microsoftTeams from '@microsoft/teams-js'
import './LoginPage.css'
import { inIframe } from './components/utils/common';
import Spinner from './components/common/Spinner';
import { AuthProvider, AuthProvidersMap } from './entities/common';

export type RegionInfo = { title: string, region: string };
export type LoginAppProps = {
    region: string | null,
    regions: RegionInfo[],
    authProviders: AuthProvider[],
    allowedAuthProviders?: AuthProvider[],
    inviteAuthProvider?: AuthProvider,
    disableSignUp: boolean
}
type LoginProps = { isSignUp?: boolean } & LoginAppProps;
type State = {
    redirectUri: string | null,
    isInIframe?: boolean,
    signout: boolean,
    lastAuthProvider: AuthProvider | null,
    allowedAuthOptions: AuthProvider[]
}

export enum SignUpParams {
    Plan = 'plan',
    Type = 'type',
    ReferralCode = 'referralCode',
    CompanyName = 'companyName',
    PersonPhone = "personPhone"
}

const REDIRECTURI = "redirectUri";
const AUTH_PROVIDER = 'AUTH_PROVIDER';

const MAX_COUNT_TO_DISPLAY_LIKE_COLUMN = 2;
class LoginPage extends React.Component<LoginProps & RouteComponentProps<{}>, State> {
    constructor(props: LoginProps & RouteComponentProps<{}>) {
        super(props);
        this.state = this._buildState(this.props);
    }

    private _buildState(props: LoginProps & RouteComponentProps<{}>): State {
        const { authProviders, allowedAuthProviders, inviteAuthProvider } = props;
        let allowedAuthOptions = allowedAuthProviders && authProviders.filter(_ => allowedAuthProviders.indexOf(_) !== -1);
        if (inviteAuthProvider && allowedAuthOptions?.length) {
            allowedAuthOptions = authProviders.filter(_ => inviteAuthProvider === _)
        }
        if (!allowedAuthOptions?.length) {
            allowedAuthOptions = this.props.authProviders;
        }

        const lastAuthProviderValue = this.getCookie(AUTH_PROVIDER) as AuthProvider;
        const lastAuthProvider = lastAuthProviderValue && allowedAuthOptions.indexOf(lastAuthProviderValue) !== -1 ? lastAuthProviderValue : null;

        const params = new URLSearchParams(props.location.search);
        return {
            redirectUri: params.get(REDIRECTURI),
            isInIframe: inIframe(),
            signout: params.has("signout"),
            allowedAuthOptions,
            lastAuthProvider
        };
    }

    componentWillMount() {
        //keep requested query params in cookies and pick it up during tenant registration
        moveToCookie(Object.values(SignUpParams), window.location.search)
        if (this.state.lastAuthProvider) {
            if (this.state.signout) {
                this.deleteCookie(AUTH_PROVIDER);
            } else if (this.state.redirectUri && this.props.history.action !== 'POP') {
                // do not auto redirect on browser Back event (history POP)
                this.authenticate(this.state.lastAuthProvider);
            }
        }
    }

    componentWillReceiveProps(nextProps: LoginProps & RouteComponentProps<{}>) {
        this.setState(this._buildState(nextProps));
    }

    render() {
        const { isSignUp, disableSignUp } = this.props;
        const { redirectUri, lastAuthProvider, signout, isInIframe, allowedAuthOptions } = this.state;
        const regionPrefix = this._getRegionPrefix();

        //relogin
        if (lastAuthProvider && !signout && redirectUri && this.props.history.action !== 'POP') {
            return <Spinner />;
        }

        const displayInColumn = isInIframe || allowedAuthOptions.length <= MAX_COUNT_TO_DISPLAY_LIKE_COLUMN || !allowedAuthOptions.includes(AuthProvider.email);

        return (
            <div className={`App ${isSignUp ? " signup" : "signin"}`}>
                <div className="body">
                    <div className="login-content">
                        <div className="logo-img"></div>
                        <div className="panel">
                            <div className="text">
                                {isSignUp ? "Welcome to PPM Express" : "Log in to PPM Express"}
                            </div>
                            <div className="row">
                                <div className="column panel-content">
                                    {isSignUp && <div className='no-credit'>No credit card needed</div>}
                                    {allowedAuthOptions.includes(AuthProvider.email) && !isInIframe && <>
                                        <a className='k-button email' onClick={() => this.authenticate(AuthProvider.email, isSignUp ? 'signup' : 'select_account')}>
                                            {isSignUp ? "Sign up with Email" : "Log in with Email"}
                                        </a>
                                        {allowedAuthOptions.filter(_ => _ !== AuthProvider.email).length > 1 && <div className='spliter'>
                                            {isSignUp
                                                ? <span>Or Sign up with</span>
                                                : <span>Or Log in with</span>}
                                        </div>}
                                    </>}
                                    <div className={css("buttons", displayInColumn ? "column" : undefined)} >
                                        {allowedAuthOptions.includes(AuthProvider.o365) && this._renderAuthProviderLoginButton(AuthProvider.o365)}
                                        {allowedAuthOptions.includes(AuthProvider.govus_o365) && this._renderAuthProviderLoginButton(AuthProvider.govus_o365)}
                                        {allowedAuthOptions.includes(AuthProvider.google) && !isInIframe && this._renderAuthProviderLoginButton(AuthProvider.google)}
                                    </div>
                                </div>
                                {isSignUp &&
                                    <div className="column">
                                        <div className="regions">
                                            <div className="title">Select Your Data Center</div>
                                            <div className="description">Your data will be stored in selected Data Center</div>
                                            {
                                                this.props.regions.map(_ => (
                                                    <a className={`region ${(this.props.region === _.region ? "active" : "")}`}
                                                        href={this.addParam(`${_.region}/signup`, REDIRECTURI, redirectUri)}>
                                                        {_.title}
                                                    </a>))
                                            }
                                        </div>
                                    </div>}
                            </div>
                            {!disableSignUp &&
                                <div className="prefix">
                                    {isSignUp
                                        ? <span>Have an account? <a href={this.addParam(`${regionPrefix}/login`, REDIRECTURI, redirectUri)}>Log in</a></span>
                                        : <span>Don't have an account? <a href={this.addParam(`${regionPrefix}/signup`, REDIRECTURI, redirectUri)}>Sign up</a></span>
                                    }
                                </div>}
                            {isSignUp &&
                                <div className="postfix">
                                    By signing up, you agree to our <a href="https://ppm.express/terms-of-use" target="_blank">Terms of Use</a>
                                    {' '}and <a href="https://ppm.express/privacy-policy" target="_blank">Privacy Policy</a>
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="footer-img"></div>
                </div>
            </div>
        );
    }

    private _renderAuthProviderLoginButton(authProvider: AuthProvider) {
        return <a className={css(`k-button ${AuthProvidersMap[authProvider].buttonClassName}`)}
            onClick={() => this.authenticate(authProvider, 'select_account')}>
            <span className={AuthProvidersMap[authProvider].logoClassName} /> {AuthProvidersMap[authProvider].friendlyName}
        </a>
    }

    private authenticate = (authProvider: AuthProvider, prompt?: string) => {
        this._trackLogin(authProvider);
        const url = this.addParam(`${this._getRegionPrefix()}/account/signin/` + authProvider, 'prompt', prompt);
        const days_in_year = 365;
        setCookie(AUTH_PROVIDER, authProvider, days_in_year);

        if (this.state.isInIframe) {
            microsoftTeams.app.initialize().then(() => {
                microsoftTeams.authentication.authenticate({
                    url: this.addParam(url, "redirectUri", "/postLogin"),
                    width: 600,
                    height: 535
                })
                .finally(() => window.location.href = this.state.redirectUri || "");
            });
        }
        else {
            window.location.href = this.addParam(url, REDIRECTURI, this.state.redirectUri);
        }
    }
    private addParam = (url: string, name: string, value: any) => {
        if (!value) {
            return url;
        }
        return url + (url.indexOf('?') !== -1 ? '&' : '?') + name + '=' + encodeURIComponent(value);
    }
    private getCookie = (name: string) => {
        const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        const cookie_value_idx = 2;
        return v ? v[cookie_value_idx] : null;
    }
    private deleteCookie = (name: string) => {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    private _getRegionPrefix = () => {
        return this.props.region ? `/${this.props.region}` : ``;
    }
    private _trackLogin = (authType: string) => {
        analytics.track('AUTH', { authType, isSignUp: this.props.isSignUp });
    }
}

function setCookie(name: string, value: string, days: number) {
    const d = new Date;
    const msec_in_day = 86400000;
    d.setTime(d.getTime() + msec_in_day * days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
}

function moveToCookie(keys: string[], search: string) {
    const urlParams = new URLSearchParams(search);
    keys.forEach(key => {
        const value = urlParams.get(key);
        const five_min_part_of_day = 0.08;
        value && setCookie(key, value, five_min_part_of_day);
    })
}

export default withRouter<LoginProps>(LoginPage)