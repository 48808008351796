import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Resource } from "../../../../../store/ResourcesListStore";
import { Field } from "../../../../../entities/Metadata";

export default class ActiveDirectoryId extends React.Component<IListViewFieldColumn<Resource>> {
    public render() {
        const field = this.props.field;
        if (!field) {
            return <span>Invalid column configuration</span>;
        }
        return <span>{this.props.entity.activeDirectoryId}</span>;
    }

    public static getValue(entity: Resource, field: Field): string | undefined {
        return entity.activeDirectoryId;
    }

    public static getSearchValue(entity: Resource, field: Field) {
        return ActiveDirectoryId.getValue(entity, field);
    }
}