import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators, IJiraConnectionInfo, IConnectionRefreshInfo, JiraOAuthState, JiraConnectionInfo } from "../../../store/integration/JiraStore";
import { Validator } from "../../../validation";
import { ApplicationState } from "../../../store/index";
import {
    Dialog, DialogType, TextField, DialogFooter, PrimaryButton, DefaultButton,
    Spinner, SpinnerSize, MessageBar, MessageBarType, Link, Dropdown
} from 'office-ui-fabric-react';
import { CopyIcon } from '../../utils/copy';
import PostMessageReceiver from '../PostMessageReceiver';
import { post } from '../../../fetch-interceptor';
import { catchApiError } from '../../../store/utils';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ConnectionTitle } from '../ConnectionTitle';
import ConnectionEditWrapper from '../ConnectionEditWrapper';
import { useDidMountEffect } from '../../utils/effects';

type OwnProps = { onDismiss: () => void, onCreated?: (connectionId: string) => void; connectionId?: string; isRestricted?: boolean }
type StateProps = { isProcessing: boolean; error: string | null, refreshInfo: IConnectionRefreshInfo | null, createdConnectionId?: string }
type Props = OwnProps & typeof actionCreators & StateProps;

enum AuthenticationType {
    Basic = 0,
    OAuth = 1
}

const validation = {
    url: Validator.new().required().build(),
    login: Validator.new().required().build(),
    apiToken: Validator.new().required().build(),

    oAuthToken: Validator.new().required().build(),
    oAuthSessionHandle: Validator.new().required().build(),
}

const InfoBlock = ({ name, lines }: { name: string, lines: { name: string, value: string, hideCopy?: boolean }[] }) => {
    return <>
        <div className="stepName">{name}</div>
        <ul>
            {
                lines.map(_ => <li key={_.name}>
                    <label>{_.name}</label>
                    <div className="oauth-prop">
                        <span className="truncate" onClick={e => (e.target as HTMLSpanElement).classList.remove("truncate")}>{_.value}</span>
                        {
                            !_.hideCopy && <CopyIcon text={_.value} />
                        }
                    </div>
                </li>)
            }
        </ul>
    </>
}

type RequestToken = {
    oAuthToken: string;
    oAuthTokenSecret: string;
}

type AccessToken = {
    oAuthToken: string;
    oAuthTokenSecret: string;
    oAuthExpiresIn: number;
    oAuthSessionHandle: string;
    oAuthAuthorizationExpiresIn: number;
}

const OAuthConfig = {
    getAppRegistrationUrl: (url: string) => `${url}/plugins/servlet/applinks/listApplicationLinks`,
    ApplicationUrl: `https://${window.location.host}`,
    ApplicationName: 'PPM Express App',
    getOAuthTokenUrl: (url: string, oAuthToken: string) => `${url}/plugins/servlet/oauth/authorize?oauth_token=${oAuthToken}`,
    RequestTokenUrl: '/api/integration/auth/jira/oauth/request-token',
    AccessTokenUrl: '/api/integration/auth/jira/oauth/access-token'
}

const OAUTH_ACCESS_GRANT_FINISHED_CHECK_INTERVAL = 500;

const oauthTarget = "connectionDataReceived_spo";

type OAuthProps = {
    url: string;
    oauth: JiraOAuthState;
    setError: (error?: string) => void;
    onSuccess: (data: { oAuthToken: string; oAuthTokenSecret: string; oAuthSessionHandle: string }) => void;
}

const OAuth = connect((state: ApplicationState) => ({ oauth: state.jira.oauth }))((props: OAuthProps) => {
    const [isOAuthProcessing, setIsOAuthProcessing] = React.useState(false);
    const [showAppRegistrationInfo, setShowAppRegistrationInfo] = React.useState(false);
    const { url, oauth, setError, onSuccess } = props;

    const onAuthFailure = (reason: any) => {
        catchApiError(_ => {
            setIsOAuthProcessing(false);
            setError(_);
        })(reason);
    }

    const onAuthAuthorize = (): void => {
        post<RequestToken>(OAuthConfig.RequestTokenUrl, { url: url })
            .then((token: RequestToken) => {
                const window = PostMessageReceiver
                    .openPopupWindow(OAuthConfig.getOAuthTokenUrl(url, token.oAuthToken), oauthTarget, { width: 700, height: 450 });
                if (!window) return;

                const timer = setInterval(() => {
                    if (window.closed) {
                        clearInterval(timer);

                        const data = {
                            url: url,
                            oAuthToken: token.oAuthToken,
                            oAuthTokenSecret: token.oAuthTokenSecret
                        };

                        post<AccessToken>(OAuthConfig.AccessTokenUrl, data)
                            .then(token => {
                                setIsOAuthProcessing(false);
                                setError(undefined);
                                onSuccess(token);
                            })
                            .catch(onAuthFailure);
                    }
                }, OAUTH_ACCESS_GRANT_FINISHED_CHECK_INTERVAL);
            })
            .catch(onAuthFailure)

        setIsOAuthProcessing(true);
        setError(undefined);
    }

    return <>
        <div className="description">
            If you already registered your Jira app,
            please <Link className="link" disabled={isOAuthProcessing} onClick={onAuthAuthorize}>Authorize</Link>.
            If you don't -
            click <Link className="link" disabled={isOAuthProcessing} onClick={() => setShowAppRegistrationInfo(true)}>App Registration</Link> first.
        </div>
        {
            showAppRegistrationInfo && <div className="oauth-info">
                <div className="title">App Registration</div>
                <div className="description">
                    Please follow
                    the <Link target="_blank" href={OAuthConfig.getAppRegistrationUrl(url)}>link</Link> and
                    register Jira app using the following app registration details
                </div>
                {
                    <InfoBlock name="Step 1" lines={[
                        { name: 'Application URL', value: OAuthConfig.ApplicationUrl },
                        { name: 'Application Name', value: OAuthConfig.ApplicationName },
                        { name: 'Create incoming link', value: "Yes", hideCopy: true }
                    ]} />
                }
                {
                    <InfoBlock name="Step 2" lines={[
                        { name: 'Consumer Key', value: oauth.consumerKey },
                        { name: 'Consumer Name', value: oauth.consumerName },
                        { name: 'Public Key', value: oauth.publicKey }
                    ]} />
                }
            </div>
        }
    </>;
})

const BasicAuth = ({ login, apiToken, onChange }: { login?: string, apiToken?: string, onChange: (data: { login?: string, apiToken?: string }) => void }) => {
    return <>
        <TextField
            value={login}
            label="Login"
            placeholder="login"
            onChange={(e, _) => onChange({ login: _?.trim(), apiToken })}
            onGetErrorMessage={validation.login.getErrorMessage} />
        <TextField
            value={apiToken}
            type="password"
            label="API token for Jira Cloud or password for Jira Server"
            placeholder="API token or password"
            onChange={(e, _) => onChange({ login, apiToken: _ })}
            onGetErrorMessage={validation.apiToken.getErrorMessage} />
    </>;
}

const EmptyBasic = {
    login: undefined,
    apiToken: undefined
}

const EmptyOAuth = {
    oAuthToken: undefined,
    oAuthTokenSecret: undefined,
    oAuthSessionHandle: undefined,
    oAuthExpiresIn: undefined,
    oAuthAuthorizationExpiresIn: undefined
}

type AuthProps = {
    isEdit: boolean;
    connection: IJiraConnectionInfo;
    onChange: (connection: Partial<IJiraConnectionInfo>, isValid: boolean, error?: string) => void
}

const isBasicValid = (info: { login?: string, apiToken?: string }) => {
    return validation.login.isValid(info.login) && validation.apiToken.isValid(info.apiToken);
}

const isOAuthValid = (info: { oAuthToken?: string, oAuthSessionHandle?: string }) => {
    return validation.oAuthToken.isValid(info.oAuthToken)
        && validation.oAuthToken.isValid(info.oAuthSessionHandle);
}

const AuthSelector = (props: AuthProps) => {
    const [authType, setAuthType] = React.useState<AuthenticationType | string>(props.connection.id
        ? props.connection.login ? AuthenticationType.Basic : AuthenticationType.OAuth
        : '');
    const { isEdit, connection, onChange } = props;
    return <>
        <TextField
            autoFocus={true}
            disabled={isEdit}
            validateOnFocusOut={true}
            validateOnLoad={false}
            value={connection.url}
            label="Jira URL"
            placeholder="your_domain.atlassian.net"
            onChange={(e, _) => onChange({ url: _?.trim() }, isBasicValid(connection))}
            onGetErrorMessage={validation.url.getErrorMessage} />
        <Dropdown
            label="Authentication Type"
            disabled={!connection.url}
            selectedKey={authType}
            options={[
                { key: '', text: 'Select authentication' },
                { key: AuthenticationType.Basic, text: 'Basic (login/password)' },
                { key: AuthenticationType.OAuth, text: 'OAuth (Jira Server only)' },
            ]}
            onChange={(e, o) => {
                if (!o) return;
                setAuthType(o.key);

                if (o.key === AuthenticationType.Basic) {
                    onChange({ ...connection, ...EmptyOAuth }, false);
                } else if (o.key === AuthenticationType.OAuth) {
                    onChange({ ...connection, ...EmptyBasic }, false);
                }
            }} />
        <div>
            {authType === AuthenticationType.Basic && <BasicAuth
                login={connection.login}
                apiToken={connection.apiToken}
                onChange={_ => onChange(_, isBasicValid(_))}
            />}
            {authType === AuthenticationType.OAuth && <OAuth
                url={connection.url}
                setError={_ => onChange(connection, false, _)}
                onSuccess={_ => onChange(_, isOAuthValid(_))}
            />}
        </div>
    </>
}

const JiraConnectionEdit = (props: Props) => {
    const [connection, setConnection] = React.useState<IJiraConnectionInfo>({
        url: '',
        login: '',
        apiToken: '',
        id: props.connectionId,
        isRestricted: props.isRestricted ?? false
    });
    const [isInited, setIsInited] = React.useState(!props.connectionId);
    const [isValid, setIsValid] = React.useState(false);
    const [authError, setAuthError] = React.useState<string | undefined | null>();

    const dismiss = () => {
        props.onDismiss();
        props.cleanError();
    }

    React.useEffect(() => {
        props.connectionId && props.loadRefreshInfo(props.connectionId);
    }, []);

    React.useEffect(() => {
        if (!props.connectionId || !props.refreshInfo) {
            return;
        }

        const info = props.refreshInfo;
        setConnection({
            ...connection,
            url: info.url,
            login: info.login
        });
        setIsInited(true);
    }, [props.refreshInfo]);

    useDidMountEffect(() => {
        if (props.isProcessing || props.error) {
            return;
        }

        props.onCreated && props.createdConnectionId && props.onCreated(props.createdConnectionId);
        dismiss();
    }, [props.isProcessing]);

    const isEdit = !!connection.id;
    const okText = isEdit ? "Save" : "Create";
    const error = authError || props.error;
    return <Dialog
        hidden={false}
        minWidth={400}
        maxWidth={400}
        dialogContentProps={{
            onDismiss: dismiss,
            type: DialogType.normal,
            title: <ConnectionTitle isEdit={isEdit} sourceType={SourceType.Jira} />,
            className: "jira-connect"
        }}
        modalProps={{
            isBlocking: true,
            containerClassName: 'ms-dialogMainOverride connection-popup'
        }}>
        <ConnectionEditWrapper sourceType={SourceType.Jira}
            articleGuidePath="https://help.ppm.express/89435-jira-connection/522458"
            videoGuidePath="https://help.ppm.express/89435-jira-connection/1275629">
            <div className="connection-wrap">
                <AuthSelector
                    key={isInited ? 'selector' : ''}
                    connection={connection}
                    isEdit={isEdit}
                    onChange={(c, isValid, error) => {
                        setConnection({ ...connection, ...c });
                        setIsValid(isValid);
                        setAuthError(error);
                    }}
                />
                {error && <p>
                    <MessageBar
                        className='margin-top'
                        messageBarType={MessageBarType.severeWarning}
                        isMultiline={true}>
                        {error}
                    </MessageBar>
                </p>}
            </div>
        </ConnectionEditWrapper>
        <DialogFooter>
            {
                !props.isProcessing && [<PrimaryButton
                    key="ok"
                    disabled={!isValid}
                    onClick={() => isValid && props.createOrRefreshConnection(connection)}
                    text={okText} />,
                <DefaultButton key="cancel" onClick={dismiss} text="Cancel" />]
            }
            {
                props.isProcessing && [<PrimaryButton key="processing-ok" disabled={true} text={okText}>
                    <Spinner size={SpinnerSize.medium} />
                </PrimaryButton>,
                <DefaultButton key="processing-cancel" disabled={true} text="Cancel" />
                ]
            }
        </DialogFooter>
    </Dialog>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        isProcessing: state.jira.connections.isProcessing,
        error: state.jira.connections.error,
        refreshInfo: state.jira.connections.refreshInfo,
        createdConnectionId: state.jira.connections.createdConnectionId
    };
}

export default connect(mapStateToProps, actionCreators)(JiraConnectionEdit);