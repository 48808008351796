import React from 'react';
import { IFindResult } from "../../common/inputs/EntityPickerInput";
import { ResourceOperations } from "../../../store/permissions";
import { ParamsControlProps } from './EntityTypeButton';
import ComboboxPicker from '../../common/inputs/ComboboxPicker';
import { ChallengeStage } from '../../../store/ChallengesListStore';
import { ServerEntityType } from '../../../entities/common';

export const IdeaParamsControl = (props: ParamsControlProps) =>
    <div className='export-params'>
        <div className='message'>Select Business Challenge where the idea will be created</div>
        <div className='label'>Business Challenge</div>
        <ComboboxPicker
            inputProps={{ placeholder: "Select Business Challenge" }}
            searchUrl="api/challenge/find"
            filter={{ accessLevel: ResourceOperations.Update, exceptIds: props.exceptIds, stage: ChallengeStage.Active }}
            onEditComplete={(find?: IFindResult) => props.onComplete({
                parentEntityType: ServerEntityType.Challenge,
                parentEntityId: find?.id
            })}
        />
    </div>;