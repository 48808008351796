import React from "react";
import { EntityType, } from "../../entities/common";
import { Field } from "../../entities/Metadata";
import * as FieldsStore from '../../store/fields';
import { ApplicationState } from "../../store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UserState } from "../../store/User";
import { CommonOperations, contains } from "../../store/permissions";
import { IsEnterpriseTimeTracker } from "../../store/TimeTrackingStore";
import { ColumnGroupedFieldNames, GroupedFieldsConfigurationProps, useNewFieldsColumnGroupedHandler } from "../field/ConfigureFields/GroupedFieldsConfiguration";
import SearchableFieldsPanel from "../field/ConfigureFields/SearchableFieldsPanel";
import { FieldsList } from "../field/ConfigureFields/FieldsList";

type OwnProps = Pick<GroupedFieldsConfigurationProps, 'onDismiss'> & {
    onFieldsUpdate: () => void;
};

type StateProps = {
    fields: Field[];
    user: UserState,
    isEnterpriseTimeTracker: boolean;
};

type ActionProps = {
    fieldsActions: ReturnType<typeof FieldsStore.actionCreators.forEntity>;
};

type Props = OwnProps & StateProps & ActionProps;

const ConfigureCustomFieldsPanel = (props: Props) => {
    const { fields, user, isEnterpriseTimeTracker, fieldsActions, onDismiss } = props;
    const mandatoryViewFields = fields.map(_ => _.name); // to hide toggle
    const newFields = useNewFieldsHandler(fields);
    
    const canEditCustomFields = contains(user.permissions.common, CommonOperations.TimeTrackingManage)
        && contains(user.permissions.common, CommonOperations.ConfigurationManage)
        && isEnterpriseTimeTracker;

    return (
        <SearchableFieldsPanel
            key='columns-panel'
            headerText='Configure fields'
            secondaryText='Configure custom fields to be added to the ‘Report Custom Time’ and ‘Report Administrative Time’ panels.'
            entityType={EntityType.TimeTrackingEntry}
            onDismiss={onDismiss}
            allowManageFields={canEditCustomFields}
            fieldActions={fieldsActions}
        >
            {(filter) => (
                <FieldsList
                    mandatoryFields={mandatoryViewFields}
                    selected={mandatoryViewFields}
                    fields={fields}
                    newFields={newFields}
                    filter={filter}
                    entityType={EntityType.TimeTrackingEntry}
                    allowManageFields={canEditCustomFields}
                    fieldActions={fieldsActions}
                />
            )}
        </SearchableFieldsPanel>
    );
}

export const useNewFieldsHandler = (fields: Field[]) => {
    const selected = React.useMemo<ColumnGroupedFieldNames>(() => ({ column1: [], column2: [] }), []);
    return useNewFieldsColumnGroupedHandler({ fields, selected });
};

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps { 
    const fields = state.fields[EntityType.TimeTrackingEntry];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]).filter(_ => _.isCustom),
        user: state.user,
        isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription)
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): ActionProps {
    const entityFieldsStore = FieldsStore.actionCreators.forEntity(EntityType.TimeTrackingEntry, undefined, ownProps.onFieldsUpdate);
    return {
        fieldsActions: bindActionCreators(entityFieldsStore, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureCustomFieldsPanel);
