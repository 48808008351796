/// <reference types="@types/segment-analytics" />
import { UserState } from './store/User';
import { contains, CommonOperations, notNone } from './store/permissions';
import { PaymentType, ProductType, SubscriptionStatus, TenantState, PaymentPeriod } from './store/Tenant';
import { Dictionary, EntityType } from './entities/common';

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
        gtag?: (action: string, eventName: string, args?: any) => void;
    }
}

let isInit: boolean = false;
let isAuth: boolean = false;

export const load = (segmentWriteKey: string) => {
    if (segmentWriteKey) {
        window.analytics.load(segmentWriteKey);
        isInit = true;
    }
}

export const identify = (user: UserState, tenant: TenantState) => {
    analytics.ready(function () {
        if (isInit && user.id) {
            const permissions = user.permissions;
            const isAdmin = permissions && contains(permissions.common, CommonOperations.Administrate);
            const canManagePortfolio = permissions && notNone(permissions.portfolio);
            const canManageProgram = permissions && notNone(permissions.program);
            const canManageProject = permissions && notNone(permissions.project);
            const canManageResource = permissions && contains(permissions.common, CommonOperations.ResourceManage);
            const canManageUsers = permissions && contains(permissions.common, CommonOperations.UserManage);

            window.analytics.identify(
                user.id, {
                product: 'PPM Express',
                email: user.email,
                name: user.name,
                tenantId: tenant.id,
                company: {
                    // company.id is a property Intercom uses to match company where user works
                    id: tenant.id,
                    externalId: tenant.externalId,
                    name: tenant.companyName,
                    plan: tenant.subscription.plan
                },
                isAdmin,
                canManagePortfolio,
                canManageProgram,
                canManageProject,
                canManageResource,
                canManageUsers
            }, {
                Intercom: {
                    hideDefaultLauncher: true,
                    customLauncherSelector: '.live_chat'
                }
            } as SegmentAnalytics.SegmentOpts);

            isAuth = true;
        }
    });
}

export const group = (tenant: TenantState, callback?: (() => void) | undefined) => {
    analytics.ready(function () {
        if (isInit && isAuth) {
            window.analytics.group(tenant.id, {
                groupId: tenant.id,
                region: tenant.region,
                name: tenant.companyName,
                tenantName: tenant.name,
                url: tenant.url,
                plan: tenant.subscription.plan,
                subscriptionEndDate: tenant.subscription.endDate,
                subscriptionProductType: ProductType[tenant.subscription.productType],
                subscriptionPaymentType: PaymentType[tenant.subscription.paymentType],
                subscriptionPaymentPeriod: PaymentPeriod[tenant.subscription.paymentPeriod],
                subscriptionIsEnterprise: tenant.subscription.isEnterprise,
                subscriptionStatus: SubscriptionStatus[tenant.subscription.status],
                subscriptionIsTrial: tenant.subscription.isTrial
            }, callback);
        }
    });
}

export const identifyAndGroup = (user: UserState, tenant: TenantState) => {
    identify(user, tenant);
    group(tenant, () => { page() });
}

const insertGroupIdProp = (properties?: any): any => {
    const groupId = window.analytics.group().id();
    return groupId ? { ...(properties ?? {}), groupId } : properties;
}

export const page = (name?: string, properties?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) => {
    analytics.ready(function () {
        if (isInit && isAuth) {
            window.analytics.page(name, insertGroupIdProp(properties), insertGroupIdProp(options) as SegmentAnalytics.SegmentOpts, callback);
        }
    });
}

export const track = (name: string, properties?: any) => {
    analytics.ready(function () {
        if (!isInit) {
            return;
        }

        window.analytics.track(`PPMX: ${name}`,
            isAuth ? insertGroupIdProp(properties) : properties,
            isAuth ? insertGroupIdProp(properties) : properties as SegmentAnalytics.SegmentOpts);
    });
}

export type CreatedBy = { id: string | null, name: string };
type CreateEventProps = {
    itemTitle: string;
    itemType: EntityType | string;
    parentType?: EntityType;
}

export const trackEvent = (name: string, user: CreatedBy, properties?: any) => {
    track(name, {
        ...properties,
        createdBy: { userId: user.id, userName: user.name },
        operationDate: new Date().toISOString()
    })
}

export const trackCreate = (user: CreatedBy, props: CreateEventProps & Dictionary<any>) => {
    trackEvent("Create", user, props);
}

export const trackImport = (name: string, user: CreatedBy, props: { count: number }) => {
    trackEvent(name, user, props);
}

export const trackLink = (name: string, user: CreatedBy) => {
    trackEvent(name, user, {});
}

export const trackToggle = (name: string, user: CreatedBy, checked: boolean, props?: any) => {
    trackEvent(name, user, { toggleValue: checked ? 'On' : 'Off', ...props });
}

export const gtag = (action: string, eventName: string, args?: any) => {
    if (window.gtag) {
        window.gtag(action, eventName, args);
    }
}