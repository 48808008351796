import * as React from 'react'
import './Navigation.css';
import { connect } from 'react-redux';
import { MessageBar, MessageBarType} from 'office-ui-fabric-react';
import { inIframe } from "../../utils/common";
import { ApplicationState } from "../../../store/index";
import * as MetadataStore from "../../../store/MetadataStore";
import { UserState, actionCreators as userActionCreators } from '../../../store/User';
import { Subscription, SubscriptionStatus, TenantState } from '../../../store/Tenant';
import Sidebar from "../Sidebar";
import ErrorPage from '../../ErrorPage';
import Link from '../../common/Link';
import * as ErrorStore from '../../../store/ErrorStore';
import { bindActionCreators } from 'redux';
import Spinner from '../../common/Spinner';
import NavigationHeader from '../NavigationHeader';
import { NavigationProps } from '../Breadcrumbs';

type OwnProps = NavigationProps & {
    allowChangeAccount?: boolean;
};

type ActionProps = {
    userActions: typeof userActionCreators;
    errorActions: typeof ErrorStore.actionCreators;
};

type StateProps = {
    metadata: MetadataStore.MetadataState;
    subscription: Subscription;
    user: UserState;
    tenant: TenantState;
    error: ErrorStore.ErrorState;
};

type Props = OwnProps & StateProps & ActionProps;

class Navigation extends React.Component<Props> {
    
    componentWillReceiveProps(nextProps: Props) {
        if (this.props.router?.location !== nextProps.router?.location) {
            this.props.errorActions.resetError();
        }
    }

    public render() {
        const { route, router, error, subscription, tenant } = this.props;
        
        // there is no subscription on setup tenant screen
        const isExpired = subscription && subscription.status === SubscriptionStatus.Active && Subscription.isExpired(subscription);
        const hideNavigation = !!error.title || route.hideNavigation || inIframe();
        
        return <div className={`navigation ${route.disableNavigation ? 'disabled' : ''}`}>
            {
                !route.hideHeader && <NavigationHeader {...this.props} />
            }
            <div className="nav-body">
                {!hideNavigation && <Sidebar />}
                <div className={`nav-content ${(isExpired || tenant.isReadOnly) ? "with-messagebar" : ""}`}>
                    {!route.hideHeader && isExpired
                        && <MessageBar messageBarType={MessageBarType.error}>Your subscription has expired. Please <Link href="/billing">upgrade</Link>.</MessageBar>}
                    {!route.hideHeader && tenant.isReadOnly
                        && <MessageBar messageBarType={MessageBarType.warning}>Your tenant is currently in read-only mode.</MessageBar>}
                    {
                        error.title
                            ? <ErrorPage title={error.title} description={error.description} />
                            : this.props.metadata.isLoading
                                ? <Spinner />
                                : route.component
                                    ? React.createElement(this.props.route.component as any, router as any)
                                    : undefined
                    }
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        metadata: state.metadata,
        subscription: state.tenant.subscription,
        user: state.user,
        tenant: state.tenant,
        error: state.error,
    };
}

export default connect(
    mapStateToProps, (dispatch): ActionProps =>
({
    userActions: bindActionCreators(userActionCreators, dispatch),
    errorActions: bindActionCreators(ErrorStore.actionCreators, dispatch)
}))(Navigation);