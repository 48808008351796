import * as React from 'react';
import { Slider } from "office-ui-fabric-react";
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import { Dictionary } from "../../../entities/common";

type SliderProps = IFormInputProps<number>;

interface TextualSliderInputState {
    min: number;
    max: number;
    value: number;
}

export default class EnumSliderInput extends React.Component<SliderProps, TextualSliderInputState> {
    constructor(props: SliderProps) {
        super(props);
        this.state = this._buildState(props);
    }

    componentWillReceiveProps(nextProps: SliderProps) {
        if (nextProps.inputProps != this.props.inputProps) {
            this.setState(this._buildState(nextProps));
        }
    }

    private _buildState(props: SliderProps) {
        let extremum = props.inputProps ? this._getExtremum(props.inputProps.options) : { min: 0, max: 0 };
        return { ...extremum, value: props.value || extremum.min };
    }

    private _getExtremum(options: Dictionary<string>) {
        const values = Object.keys(options).map(_ => +_);
        let min: number;
        let max = min = values[0];
        for (let i = 1; i < values.length; ++i) {
            if (min > values[i]) min = values[i];
            if (max < values[i]) max = values[i];
        }

        return { min, max };
    }

    public render() {
        const value = this.state.value || this.state.min;
        const { inputProps, disabled, readOnly } = this.props;
        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;
        const className = inputProps?.className || '';
        return <div className="enum-slider">
            <Slider
                className={className}
                disabled={isDisabled || isReadOnly}
                value={this.state.value}
                onChange={_ => {
                    this.setState({ value: _ });
                    this.props.onChanged && this.props.onChanged(_);
                    this.props.onEditComplete && this.props.onEditComplete(_);
                }}
                min={this.state.min}
                max={this.state.max}
                step={1}
                showValue={false} />
            {inputProps && <span className={`align-center ${className} label ${className}-${value}`}>{inputProps.options[value]}</span>}
        </div>;
    }
}