import * as React from 'react';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { IModalProps } from 'office-ui-fabric-react/lib/Modal';
import { ConfirmationDialog } from './ConfirmationDialog';

type RemoveDialogProps = {
    onClose: () => void;
    onComplete?: () => void;
    dialogContentProps?: IDialogContentProps;
    modalProps?: IModalProps;
    confirmButtonProps?: { text?: string, disabled?: boolean };
}

const RemoveDialog = (props: React.PropsWithChildren<RemoveDialogProps>) => {
    const { onClose, dialogContentProps, confirmButtonProps, modalProps } = props;

    const onRemove = () => {
        props.onComplete?.();
        props.onClose();
    }

    return (
        <ConfirmationDialog
            onDismiss={onClose}
            onYes={onRemove}
            yesButtonProps={{ text: "Remove", ...confirmButtonProps }}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            {props.children}
        </ConfirmationDialog>
    );
}

export default RemoveDialog;
