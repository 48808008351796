import * as utils from "./utils";
import * as React from 'react';
import { IScale, TimelineScale, ITimelineInfo, timeframesEqual } from "./TimelineScale";
import {
    memoizeFunction, getScrollbarWidth, findScrollableParent, getId, mergeStyles, Icon, IconButton, CheckboxVisibility, Selection, IColumn, Checkbox, IObjectWithKey,
    IContextualMenuItem, SelectionMode
} from "office-ui-fabric-react";
import {
    IExtensibleEntity, Dictionary, Quantization, ITimeframe, OriginScale, MIN_COLUMN_WIDTH, IStyleSettingValues, EntityType, entityLogoConfig
} from "../../../entities/common";
import { TimelineBody, ScaleRenderMode, RowShift } from "./TimelineBody";
import { ITimelineRelation } from "./TimelineRelation";
import { IBaseTimelineElement, ITimelineSegment } from "./TimelineSegment";
import { ITimelineMarker } from "./TimelineMarker";
import { arraysEqual, distinct, notUndefined, waitForFinalEvent } from "../../utils/common";
import { ListGroupingProps, LIST_ROW_HEIGHT, CHECKBOX_COLUMN_WIDTH } from "../extensibleEntity/EntityDetailsList";
import { EntityGroup } from "../extensibleEntity/EntityGroupHeader";
import { DragDropContext, Droppable, Draggable, DropResult, DragStart } from "react-beautiful-dnd";
import { ResizeEnable } from "react-rnd";
import { RelationsAlignmentManager } from "./RelationsAlignmentManager";
import Td from "./Td";
import { calculateColumnWidth, calculateOriginWidth, getTimelineRowCellIndentation, THeader } from "./THead";
import { ITimelineBaseline } from "./TimelineBaseline";

export interface IRow {
    key: string;
    entity: IExtensibleEntity;
    segments: ITimelineSegment[];
    markers: ITimelineMarker[];
    relations?: ITimelineRelation[];
    baselines?: ITimelineBaseline[];
    subItems?: IRow[];
    subItemType?: EntityType;
    rowType?: string;
}

export function getRowExtremum(row: IRow): utils.IMinMax {
    const dates = [...row.segments.map(_ => _.startDate), ...row.segments.map(_ => _.finishDate), ...row.markers.map(_ => _.date)];
    return utils.minMax(dates);
}

type GroupInfo = EntityGroup & { row: IRow }

export type TimelineGroupingProps = { getGroupRow: (group: EntityGroup, rows: IRow[]) => IRow; }
export type Grouping = ListGroupingProps & TimelineGroupingProps

export type Styling<TStyleValues = IStyleSettingValues> = {
    settings: IStyleSettings<TStyleValues>;
    onChange: (key: string, value: boolean) => void;
    values: TStyleValues;
}
export interface IStyleSetting {
    label: string;
    configureTitle?: string;
    disableConfigure?: () => boolean;
    onConfigure?: () => void;
}
export type IStyleSettings<TStyleValues = IStyleSettingValues>  = Partial<{
    [key in keyof TStyleValues]: IStyleSetting;
}>

export type ScaleChange = {
    scale: IScale,
    quantization: Quantization,
    timeline: ITimelineInfo
}

export type TimelineChange = {
    newState: ScaleChange,
    prevState?: ScaleChange,
    origin?: OriginScale,
    prevOrigin?: OriginScale
}

interface IProps {
    columns: IColumn[];
    items: IRow[];
    buildTree?: boolean;
    showTreeExpandColumn?: boolean;
    expandedEntitiesIds?: string[];
    primaryOutlineLevel?: number;
    initialTimeframe?: Partial<ITimeframe>;
    userTimeframe?: Partial<ITimeframe>;
    userQuantization?: Quantization;
    scaleRenderMode?: ScaleRenderMode;
    scaleMultiplier?: number;
    onScaleChange?: (timelineChange: TimelineChange) => void;
    renderSegmentContent?: (row: IRow, segment: ITimelineSegment) => JSX.Element | undefined;
    renderMarkerContent?: (row: IRow, segment: ITimelineMarker) => JSX.Element | undefined;
    renderSegmentTooltipContent?: (row: IRow, segment: ITimelineSegment) => JSX.Element | undefined;
    renderMarkerTooltipContent?: (row: IRow, marker: ITimelineMarker) => JSX.Element | undefined;
    renderHeaderCellContent?: (column: IColumn) => JSX.Element | undefined;
    onSegmentClick?: (row: IRow, segment: ITimelineSegment, ev?: React.MouseEvent<HTMLElement>) => void;
    onMarkerClick?: (row: IRow, marker: ITimelineMarker, ev?: React.MouseEvent<HTMLElement>) => void;
    onSegmentDoubleClick?: (row: IRow, segment: ITimelineSegment, ev?: React.MouseEvent<HTMLElement>) => void;
    onMarkerDoubleClick?: (row: IRow, marker: ITimelineMarker, ev?: React.MouseEvent<HTMLElement>) => void;
    onSegmentChange?: (row: IRow, segment: ITimelineSegment, data: Partial<ITimelineSegment>, rowShift?: RowShift) => void;
    onMarkerChange?: (row: IRow, marker: ITimelineMarker, data: Partial<ITimelineMarker>, rowShift?: RowShift) => void;
    resolveSegmentPosition?: (data: ITimelineSegment, change: Partial<ITimelineSegment>, verticalOffset: number,
        rowPositionMap: RowPositionMap, currentRowPosition: number, rowHeight: number) => RowShift | undefined;
    resolveMarkerPosition?: (data: ITimelineMarker, change: Partial<ITimelineMarker>, verticalOffset: number,
        rowPositionMap: RowPositionMap, currentRowPosition: number, rowHeight: number) => RowShift | undefined;
    segmentDragAxis?: (row: IRow, segment: ITimelineSegment) => "x" | "y" | "both" | "none" | undefined;
    segmentEnableResizing?: (row: IRow, segment: ITimelineSegment) => ResizeEnable | undefined;
    markerDragAxis?: (row: IRow, marker: ITimelineMarker) => "x" | "y" | "both" | "none" | undefined;
    onColumnHeaderClick?: (column: IColumn) => void;
    selection?: Selection;
    checkboxVisibility?: CheckboxVisibility;
    groups?: EntityGroup[];
    grouping?: Grouping;
    styling?: Styling;
    dragDropEvents?: {
        start: (entity: IRow) => void,
        end: (row: IRow, entityGroupKey?: string, insertBeforeRow?: IRow) => void,
        isDragDisabled?: (row: IRow) => boolean
    };
    onColumnResize?: (columnId: string, newWidth: number) => void;
    isVirtualizationDisabled?: boolean;
    buildSegmentCommands?: (data: ITimelineSegment) => IContextualMenuItem[] | undefined;
    buildMarkerCommands?: (data: ITimelineMarker) => IContextualMenuItem[] | undefined;
    buildRelationCommands?: (data: ITimelineRelation) => IContextualMenuItem[] | undefined;
    timelineElementSelectionMode?: SelectionMode;
    renderTimelineHeaderFirstRow?: () => JSX.Element | undefined;
    isDayOff?: (date: Date) => boolean;
    className?: string;
}
type GroupState = {
    filteredByGroupsRows?: IRow[];
    groupedRows?: Dictionary<IRow[]>;
    groups?: GroupInfo[];
}
type ScaleState = {
    quantization: Quantization;
    scale: IScale;
    timeline: ITimelineInfo;
}

type State = GroupState & ScaleState & {
    timelineWidth?: number;
    rowPositionMap: RowPositionMap;
    renderedRowsRange: { from: number, to: number };
    rowShift?: RowShift;
}

export type RowPositionMap = {
    lineByRowKeyMap: Dictionary<number>;
    lineByEntityIdMap: Dictionary<number[]>;
    rowByLineMap: Dictionary<IRow>;
    shift?: Shift;
}

export interface Shift {
    key: string;
    x?: number;
    y?: number;
    type: 'left' | 'right' | 'both';
}

export const TIMELINE_MIN_WIDTH = 400;
const TIMELINE_COLUMN_PADDING = 14;
const TREE_COLUMN_WIDTH = 52;

export default class TimelineList extends React.Component<IProps, State> {
    private _expandSubentities: Selection;
    private _expandGrouping: Selection;
    private _relationsAlignmentManager: RelationsAlignmentManager;
    private _availableSpaceMap: Dictionary<Dictionary<number>>;
    private _table?: HTMLTableElement;
    private _tableView?: HTMLDivElement;
    private _tableOverlay?: HTMLDivElement;
    private _widthFixer?: HTMLDivElement;
    private _container: any;
    private _floatingScollbar?: HTMLDivElement;
    private _scrollableContainer?: Element;
    private _columnsWidths: Dictionary<number> = {};

    private headerHeight = 0;

    private _memoizedFn = memoizeFunction((row: IRow, fn: (row: IRow, segment: ITimelineSegment | ITimelineMarker) => JSX.Element | undefined) =>
        (_: ITimelineSegment | ITimelineMarker) => fn(row, _));

    constructor(props: IProps) {
        super(props);

        this._expandGrouping = new Selection({
            onSelectionChanged: () => {
                if (this.state) {
                    this.setState({ rowPositionMap: this._buildRowPositionMap(props.items, this.state.groups, this.state.groupedRows, !!props.grouping?.renderGroupFooter) });
                }
            }
        });
        if (props.groups) {
            this._setExpandGroupingAndExpandNew(props.groups);
        }

        this._expandSubentities = new Selection({
            onSelectionChanged: () => this.forceUpdate(this._onResize)
        });
        const groupState = this._buildGroupState(props);
        this._setKeyedItems(undefined, props, this.state);

        if (props.expandedEntitiesIds?.length) {
            props.expandedEntitiesIds.forEach(_ => this._expandSubentities.setKeySelected(_, true, true));
        }

        const totalRowsCount = this._getTotalRowsCount(props, groupState.groups, groupState.groupedRows);

        this.state = {
            ...groupState,
            ...this._buildScaleState(groupState.filteredByGroupsRows || props.items, this.props.userQuantization, { ...props.initialTimeframe, ...props.userTimeframe },
                props.scaleMultiplier),
            rowPositionMap: this._buildRowPositionMap(props.items, groupState.groups, groupState.groupedRows, !!props.grouping?.renderGroupFooter),
            renderedRowsRange: { from: 0, to: props.isVirtualizationDisabled ? totalRowsCount : 0 }
        };

        props.onScaleChange?.({newState: buildScaleChange(this.state), 
            origin: {
                quantization: this.props.userQuantization,
                timeframe: this.props.userTimeframe
            }
        });

        this._relationsAlignmentManager = new RelationsAlignmentManager();
        this._availableSpaceMap = {};

        this._columnsWidths = props.columns.reduce((a, b) => ({ ...a, [b.key]: calculateColumnWidth(b) }), {});
    }

    public componentWillReceiveProps(props: IProps) {
        if (props.groups && !arraysEqual(props.groups, this.props.groups)) {
            this._setExpandGroupingAndExpandNew(props.groups);
        }

        if (!arraysEqual(this.props.items, props.items)
            || this.props.columns !== props.columns
            || !arraysEqual(this.props.groups ?? [], props.groups ?? [])
            || !timeframesEqual(this.props.initialTimeframe, props.initialTimeframe)
            || this.props.userQuantization !== props.userQuantization
            || this.props.scaleMultiplier !== props.scaleMultiplier
            || !timeframesEqual(this.props.userTimeframe, props.userTimeframe)
            || !!this.props.isVirtualizationDisabled !== !!props.isVirtualizationDisabled) {
            const groupState = this._buildGroupState(props);
            const newState = {
                ...groupState,
                ...this._buildScaleState(groupState.filteredByGroupsRows || props.items,
                    props.userQuantization,
                    { ...props.initialTimeframe, ...props.userTimeframe },
                    props.scaleMultiplier),
                rowPositionMap: this._buildRowPositionMap(props.items, groupState.groups, groupState.groupedRows, !!props.grouping?.renderGroupFooter),
                renderedRowsRange: this.state.renderedRowsRange,
                quantizationListWidth: 0
            };

            if (props.isVirtualizationDisabled) {
                const totalRowsCount = this._getTotalRowsCount(props, groupState.groups, groupState.groupedRows);
                if (this.props.isVirtualizationDisabled !== props.isVirtualizationDisabled || newState.renderedRowsRange.to !== totalRowsCount) {
                    newState.renderedRowsRange = { from: 0, to: totalRowsCount };
                }
            }
            else if (this.props.isVirtualizationDisabled !== props.isVirtualizationDisabled) {
                newState.renderedRowsRange = { from: 0, to: 0 };
            }

            this._setKeyedItems(this.props, props, newState);

            props.onScaleChange?.({
                newState: buildScaleChange(newState),
                prevState: buildScaleChange(this.state)
            });
            this.setState(newState, this._resizeAndScroll);
        }

        if (props.expandedEntitiesIds?.length && (!arraysEqual(this.props.expandedEntitiesIds || [], props.expandedEntitiesIds) || !this._expandSubentities.getSelectedCount())) {
            props.expandedEntitiesIds.forEach(_ => this._expandSubentities.setKeySelected(_, true, true));
        }

        if (!arraysEqual(this.props.columns, props.columns)) {
            this._columnsWidths = {};
            props.columns.forEach(_ => {
                if (this._columnsWidths[_.key] === undefined) {
                    this._columnsWidths[_.key] = calculateColumnWidth(_);
                }
            })
        }
    }

    private _buildKeyedItems(prevProps: IProps | undefined, props: IProps, state: State): { primary: IObjectWithKey[]; expand: IObjectWithKey[] } {
        let items = props.items;
        if (prevProps?.groups !== props.groups && props.groups) {
            items = props.groups.map(_ => state.groupedRows![_.key]).reduce((prev, _) => [...prev, ..._], []);
        }
        const expand = this._getExpandKeys(items);

        const primary =
            (props.primaryOutlineLevel ?? 0) === 0
                ? items
                      .reduce(
                          (cum, cur) => [
                              ...cum,
                              ...[cur.entity as IObjectWithKey],
                              ...cur.markers.map(_ => _.entity as IObjectWithKey),
                              ...cur.segments.map(_ => _.entity as IObjectWithKey),
                              ...(cur.relations?.map(_ => _.entity as IObjectWithKey) ?? [])
                          ],
                          []
                      )
                      .filter(notUndefined)
                      .filter(distinct)
                : items.reduce((cum, cur) => [...cum, ...(cur.subItems || [])], []).map(_ => _.entity as IObjectWithKey);
        return { primary, expand };
    }

    private _getExpandKeys(items: IRow[]): IObjectWithKey[] {
        const result: IObjectWithKey[] = [];

        items.forEach(_ => {
            if (_.subItems?.length) {
                result.push(_.entity as IObjectWithKey);

                if (_.subItems?.length) {
                    result.push(...this._getExpandKeys(_.subItems!));
                }
            }
        });

        return result;
    }

    private _setKeyedItems(prevProps: IProps | undefined, nextProps: IProps, state: State) {
        const keyedItems = this._buildKeyedItems(this.props, nextProps, state);
        this._expandSubentities.setItems(keyedItems.expand, false);

        const prevSelectionIds = (prevProps?.selection?.getSelection() ?? []).map(_ => (_ as IExtensibleEntity).id);
        nextProps.selection?.setItems(keyedItems.primary, false);
        this._setSelectionById(nextProps.selection, prevSelectionIds);
    }

    private _buildScaleState(items: IRow[], userQuantization: Quantization | undefined, timeframe: Partial<ITimeframe> | undefined, scaleMultiplier?: number): ScaleState {
        const timeline = this.getTimeline(items, timeframe);
        const { quantization, scale } = TimelineScale.build(timeline, userQuantization, scaleMultiplier);
        return {
            quantization,
            scale,
            timeline
        };
    }

    private _buildGroupState(props: IProps): GroupState {
        let groupInfos: GroupInfo[] | undefined = undefined;
        let groupedRows: Dictionary<IRow[]> | undefined = undefined;
        let filteredByGroupsRows: IRow[] | undefined = undefined;
        const grouping = props.grouping;
        const groups = props.groups;

        if (groups && grouping) {
            groupInfos = [];
            groupedRows = {};
            const rowsWithGroupKey = props.items.map(_ => ({ item: _, groupKey: grouping.getGroupKey(_.entity) }));

            groups.forEach(group => {
                groupedRows![group.key] = rowsWithGroupKey.filter(_ => _.groupKey === group.key).map(_ => _.item);
                filteredByGroupsRows = [...(filteredByGroupsRows || []), ...groupedRows![group.key]];
            });

            groups.forEach(_ => {
                const rows: IRow[] = groupedRows![_.key] || [];
                groupInfos!.push({ ..._, row: grouping.getGroupRow(_, rows) });
            });
        }

        return {
            filteredByGroupsRows,
            groupedRows,
            groups: groupInfos
        };
    }

    private _buildRowPositionMap(rows: IRow[], groupInfos: GroupInfo[] | undefined, groupedRows: Dictionary<IRow[]> | undefined, hasGroupFooters: boolean): RowPositionMap {
        const rowPositionMap = {
            lineByRowKeyMap: {},
            lineByEntityIdMap: {},
            rowByLineMap: {}
        }
        if (groupInfos && groupedRows) {
            let line = 0;
            groupInfos.forEach(_ => {
                //group header row
                rowPositionMap.lineByRowKeyMap[_.row.key] = line;
                rowPositionMap.rowByLineMap[line] = _.row;
                line += 1;
                if (this._expandGrouping.isKeySelected(_.row.key)) {
                    groupedRows[_.key].forEach(row => {
                        rowPositionMap.lineByEntityIdMap[row.entity.id] = [...(rowPositionMap.lineByEntityIdMap[row.entity.id] ?? []), line];
                        rowPositionMap.lineByRowKeyMap[row.key] = line;
                        rowPositionMap.rowByLineMap[line] = row;

                        [...row.segments, ...row.markers]
                            .filter(__ => !!__.entity).forEach(__ => {
                                const entityId = __.entity!.id;
                                if (rowPositionMap.lineByEntityIdMap[entityId] === undefined || !~rowPositionMap.lineByEntityIdMap[entityId].indexOf(line)) {
                                    rowPositionMap.lineByEntityIdMap[entityId] = [...(rowPositionMap.lineByEntityIdMap[entityId] ?? []), line];
                                }
                            })
                        line += 1;
                    });
                }

                //group footer row
                if (hasGroupFooters) {
                    rowPositionMap.rowByLineMap[line] = _.row;
                    line += 1;
                }
            });
        } else {
            rows.forEach((_, index) => {
                rowPositionMap.lineByEntityIdMap[_.entity.id] = [...(rowPositionMap.lineByEntityIdMap[_.entity.id] ?? []), index];
                rowPositionMap.lineByRowKeyMap[_.key] = index;
                rowPositionMap.rowByLineMap[index] = _;
            });
        }

        return rowPositionMap;
    }

    public render() {
        this._relationsAlignmentManager.reset();
        return <>
            <div className={`TimelineList ${this.props.className ?? ''}`}
                ref={(_: any) => this._container = _}
                onScroll={e => {
                    this._setWidths();

                    this._floatingScollbar!.scrollLeft = this._container.scrollLeft;
                }}
                style={{
                    visibility: this.state.timelineWidth === undefined ? 'hidden' : 'visible',
                    overflowX: this.state.timelineWidth === TIMELINE_MIN_WIDTH ? "auto" : "visible",
                    overflowY: "hidden"
                }}>
                <div ref={(_: any) => this._widthFixer = _}></div>
                <div className="table-overlay" ref={(_: any) => this._tableOverlay = _}>
                    <table className="chart-table">
                        {this._renderHead(true)}
                    </table>
                </div>
                <div className="table-view"
                    ref={(_: any) => this._tableView = _}
                    style={{
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    onScroll={() => {
                        if (this._tableView!.scrollLeft > 0) {
                            this._container.scrollLeft = this._tableView!.scrollLeft;
                            this._tableView!.scrollLeft = 0;
                        }
                    }}>
                    {
                        this.props.dragDropEvents
                            ? <DragDropContext onDragStart={this._onDragStart} onDragEnd={this._onDragEnd}>
                                <Droppable droppableId="droppable-table" >
                                    {(provided) => this._renderTable(true, provided.innerRef)}
                                </Droppable>
                            </DragDropContext>
                            : this._renderTable()
                    }
                </div>
            </div>
            {
                //width and visibility managed in ComponentDidUpdate method
                this._container &&
                <div style={{ width: this._container.clientWidth }}
                    className="additional-scrollbar invisible"
                    ref={(_: any) => this._floatingScollbar = _}
                    onScroll={() => this._container.scrollLeft = this._floatingScollbar!.scrollLeft}>
                    <div className="inner"></div>
                </div>
            }
        </>;
    }

    private _renderTable = (isDraggable?: boolean, refFunc?: (element: HTMLElement | null) => void) => {
        return (
            <table className="chart-table drag-table" ref={(_: HTMLTableElement) => { this._table = _; refFunc?.(_); }}>
                {this._renderHead(false)}
                {this._renderBody(isDraggable)}
            </table>
        );
    }

    private _renderBody(isDraggable?: boolean) {
        if (!this.state.groups) {
            return this._renderSingleBody(isDraggable);
        }

        let rowIndex = 0;
        const groupsBody: JSX.Element[] = [];
        this.state.groups.forEach(_ => {
            if (!isDraggable && this.state.renderedRowsRange.to < rowIndex) {
                return;
            }
            const { element, count } = this._renderGroup(_, rowIndex, isDraggable);

            rowIndex += (count ?? 0);
            groupsBody.push(element);
        })

        if (!isDraggable) {
            if (this.state.renderedRowsRange.from) {
                groupsBody.splice(0, 0, (
                    <tbody key="top-padding" onClick={this._resetSelection}>
                        <tr style={{ height: LIST_ROW_HEIGHT * this.state.renderedRowsRange.from }} />
                    </tbody>));
            }
            const totalItemsCount = this._getTotalRowsCount(this.props, this.state.groups, this.state.groupedRows);
            if (this.state.renderedRowsRange.to < totalItemsCount) {
                groupsBody.push(
                    <tbody key="bottom-padding">
                        <tr style={{ height: LIST_ROW_HEIGHT * (totalItemsCount - this.state.renderedRowsRange.to) }} />
                    </tbody>);
            }
        }
        return groupsBody;
    }

    private _getTotalRowsCount = (props: IProps, groups?: GroupInfo[], groupedRows?: Dictionary<IRow[]>) => {

        if (!groups) {
            return this._getTotalItemsCount(props.items);
        }
        return groups.length
                + groups.reduce((cum, _) => cum
                    + (groupedRows?.[_.key] && this._expandGrouping.isKeySelected(_.key) ? groupedRows[_.key].length : 0)
                    + (props.grouping?.renderGroupFooter && this._expandGrouping.isKeySelected(_.key) ? 1 : 0)
                    , 0);
    }

    private _getTotalItemsCount = (items: IRow[]): number => {
        let result = items.length;

        items.forEach(item => {
            if (this._expandSubentities.isKeySelected(item.key) && item.subItems) {

                //if item have no subItems list will have row "no subitems"
                const subItemsCount = item.subItems.length
                    ? this._getTotalItemsCount(item.subItems)
                    : 1;
                result += subItemsCount;
            }
        });

        
        return result;
    }

    private _renderSingleBody(isDraggable?: boolean) {
        let rowIndex = 0;
        let rows: (JSX.Element | null | undefined)[] = [];
        this.props.items.every((_, index) => {
            if (!isDraggable && this.state.renderedRowsRange.to < rowIndex) {
                return false;
            }

            const newRows = this._renderItem(_, index, undefined, 0, undefined, isDraggable);
            const resultToRender = isDraggable ? newRows : this._sliceRowsToRender(newRows, rowIndex);
            rowIndex += newRows.length;
            rows = [...rows, ...resultToRender];
            return true;
        });

        if (!isDraggable) {
            if (this.state.renderedRowsRange.from) {
                rows.splice(0, 0, <tr key="top-padding" style={{ height: LIST_ROW_HEIGHT * this.state.renderedRowsRange.from }} />);
            }
            
            const totalRowsCount = this._getTotalItemsCount(this.props.items);

            if (this.state.renderedRowsRange.to < totalRowsCount) {
                rows.push(<tr key="bottom-padding" style={{ height: LIST_ROW_HEIGHT * (totalRowsCount - this.state.renderedRowsRange.to) }} />);
            }
        }

        return (
            <tbody key="timeline-body" onClick={this._resetSelection}>
                {rows}
            </tbody>
        );
    }
    private _sliceRowsToRender = (items: (JSX.Element | null | undefined)[], rowIndex: number): (JSX.Element | null | undefined)[] => {
        const renderFrom = Math.max(this.state.renderedRowsRange.from - rowIndex, 0);
        const renderTo = Math.max(this.state.renderedRowsRange.to - rowIndex, 0);

        return items.slice(renderFrom, renderTo);
    }
    private _renderTimelineCell(item: IRow, bgText?: string): JSX.Element {
        const shiftOnCurrentRow = this.state.rowShift?.targetRow === item ? this.state.rowShift : undefined;
        const segments = shiftOnCurrentRow && shiftOnCurrentRow.overlappedSegments
            ? item.segments?.map(_ => shiftOnCurrentRow.overlappedSegments!.some(__ => __.key === _.key) ? { ..._, className: _.className + " overlapped" } : _)
            : item.segments;
        const ghostSegments = shiftOnCurrentRow?.overlappedSegments?.filter(__ => item.segments.every(_ => __.key !== _.key));
        const markers = shiftOnCurrentRow && shiftOnCurrentRow.overlappedMarkers
            ? item.markers?.map(_ => shiftOnCurrentRow.overlappedMarkers?.some(__ => __.key === _.key) ? { ..._, className: _.className + " overlapped" } : _)
            : item.markers;
        const ghostMarkers = shiftOnCurrentRow?.overlappedMarkers?.filter(__ => item.markers.every(_ => __.key !== _.key));
        return <td key="timeline-cell" className="timeline-cell">
            <span className='bg-text'>{bgText}</span>
            <div className={`timeline-canvas-wrap ${shiftOnCurrentRow ? shiftOnCurrentRow?.addNewLine ? 'add-line-after' : 'add-on-line' : ''}`}>
                <TimelineBody
                    segments={segments}
                    baselines={this.props.styling?.values.showBaseline === false ? undefined : item.baselines}
                    ghostSegments={ghostSegments}
                    markers={markers}
                    ghostMarkers={ghostMarkers}
                    relations={this.props.styling?.values.showRelations === false ? undefined : item.relations}
                    rowPositionMap={this.state.rowPositionMap}
                    onSideShift={(shift) => this.setState({ rowPositionMap: { ...this.state.rowPositionMap, shift } })}
                    currentRowPosition={this.state.rowPositionMap.lineByRowKeyMap[item.key]}
                    relationsAlignmentManager={this._relationsAlignmentManager}
                    availableSpaceMap={this._availableSpaceMap}
                    scale={this.state.scale}
                    timelineWidth={this._getTimelineWidth()}
                    displayToday={this.props.styling?.values.showToday ?? true}
                    largeMode={this.props.styling?.values.largeBars}
                    scaleRenderMode={bgText ? ScaleRenderMode.None : this.props.scaleRenderMode}
                    renderSegmentTooltipContent={this.props.renderSegmentTooltipContent ? this._memoizedFn(item, this.props.renderSegmentTooltipContent) : undefined}
                    renderSegmentContent={this.props.renderSegmentContent ? this._memoizedFn(item, this.props.renderSegmentContent) : undefined}
                    renderMarkerTooltipContent={this.props.renderMarkerTooltipContent ? this._memoizedFn(item, this.props.renderMarkerTooltipContent) : undefined}
                    renderMarkerContent={this.props.renderMarkerContent ? this._memoizedFn(item, this.props.renderMarkerContent) : undefined}
                    onSegmentClick={(_, ev) => this._onSegmentClick(item, _, ev)}
                    onMarkerClick={(_, ev) => this._onMarkerClick(item, _, ev)}
                    onRelationClick={(_, ev) => this._onRelationClick(item, _, ev)}
                    onSegmentDoubleClick={this.props.onSegmentDoubleClick ? (_, ev) => this.props.onSegmentDoubleClick?.(item, _, ev) : undefined}
                    onMarkerDoubleClick={this.props.onMarkerDoubleClick ? (_, ev) => this.props.onMarkerDoubleClick?.(item, _, ev) : undefined}
                    resolveSegmentPosition={(_, __, ___) => {
                        const rowShift = this.props.resolveSegmentPosition?.(_, __, ___,
                            this.state.rowPositionMap, this.state.rowPositionMap.lineByRowKeyMap[item.key], LIST_ROW_HEIGHT);
                        if (!this._rowShiftEqual(rowShift, this.state.rowShift)) {
                            this.setState({ rowShift });
                        }
                        return rowShift;
                    }}
                    resolveMarkerPosition={(_, __, ___) => {
                        const rowShift = this.props.resolveMarkerPosition?.(_, __, ___,
                            this.state.rowPositionMap, this.state.rowPositionMap.lineByRowKeyMap[item.key], LIST_ROW_HEIGHT);
                        if (!this._rowShiftEqual(rowShift, this.state.rowShift)) {
                            this.setState({ rowShift });
                        }
                        return rowShift;
                    }}
                    onSegmentChange={this.props.onSegmentChange ? (_, __, ___) => this.props.onSegmentChange!(item, _, __, ___) : undefined}
                    onMarkerChange={this.props.onMarkerChange ? (_, __, ___) => this.props.onMarkerChange!(item, _, __, ___) : undefined}
                    segmentDragAxis={this.props.segmentDragAxis ? (_) => this.props.segmentDragAxis!(item, _) : undefined}
                    segmentEnableResizing={this.props.segmentEnableResizing ? (_) => this.props.segmentEnableResizing!(item, _) : undefined}
                    markerDragAxis={this.props.markerDragAxis ? (_) => this.props.markerDragAxis!(item, _) : undefined}
                    buildSegmentCommands={this.props.buildSegmentCommands}
                    buildMarkerCommands={this.props.buildMarkerCommands}
                    buildRelationCommands={this.props.buildRelationCommands}
                    resetSelection={this._allowTimelineElementSelection() ? this._resetSelection : undefined}
                    checkItemSelection={this._allowTimelineElementSelection() ? this._isItemSelected : undefined}
                    hideItemCommandPanel={this._allowTimelineElementSelection() ? () => this.props.selection?.getSelectedCount() !== 1 : undefined}
                />
            </div>
        </td>;
    }

    private _allowTimelineElementSelection = () =>
        this.props.timelineElementSelectionMode === SelectionMode.single || this.props.timelineElementSelectionMode === SelectionMode.multiple;

    private _onSegmentClick = (row: IRow, segment: ITimelineSegment, ev?: React.MouseEvent<HTMLElement>) => {
        this.props.selection?.setChangeEvents(false);
        this._changeSelection([segment.key], ev?.ctrlKey);
        this._selectRelations(row, ev?.ctrlKey);
        this.props.selection?.setChangeEvents(true);

        this.props.onSegmentClick?.(row, segment, ev);
    }

    private _onMarkerClick = (row: IRow, marker: ITimelineMarker, ev?: React.MouseEvent<HTMLElement>) => {
        this.props.selection?.setChangeEvents(false);
        this._changeSelection([marker.key], ev?.ctrlKey);
        this._selectRelations(row, ev?.ctrlKey);
        this.props.selection?.setChangeEvents(true);

        this.props.onMarkerClick?.(row, marker, ev);
    }

    private _onRelationClick = (row: IRow, relation: ITimelineRelation, ev?: React.MouseEvent<HTMLElement>) => {
        if (!this._allowTimelineElementSelection() || ev?.ctrlKey || ev?.altKey || ev?.shiftKey) {
            return;
        }
        this._changeSelection([relation.key]);
    }

    private _selectRelations = (row: IRow, toggle?: boolean) => {
        const { selection, items } = this.props;
        if (!selection || !this._allowTimelineElementSelection() || !toggle) {
            return;
        }

        const allRelations = items.reduce((arr, cur) => [...arr, ...cur.relations ?? []], []);
        const allRelationKeys = allRelations.map(_ => _.key).filter(distinct);
        const selectedIds = selection.getSelection().map(_ => (_ as IExtensibleEntity).id);
        const selectedSegmentsAndMarkersIds = selectedIds.filter(_ => !allRelationKeys.includes(_));

        const selectRelationIds = (
            selectedSegmentsAndMarkersIds.length === 1
                ? row.relations?.filter(_ => selectedSegmentsAndMarkersIds.includes(_.parent.key) || selectedSegmentsAndMarkersIds.includes(_.child.key)) ?? []
                : allRelations.filter(_ => selectedSegmentsAndMarkersIds.includes(_.parent.key) && selectedSegmentsAndMarkersIds.includes(_.child.key)) ?? []
        )
            .map(_ => _.key)
            .filter(notUndefined)
            .filter(distinct);

        const deselectRelationIds = allRelationKeys.filter(_ => selectedIds.includes(_) && !selectRelationIds.includes(_));
        this._setSelectionById(selection, deselectRelationIds, "unselect");
        this._setSelectionById(selection, selectRelationIds);
    }

    private _changeSelection(selectedIds: string[], toggle?: boolean) {
        const { selection, timelineElementSelectionMode } = this.props;
        if (!selection || !this._allowTimelineElementSelection()) {
            return;
        }

        selection.setChangeEvents(false);

        if (timelineElementSelectionMode !== SelectionMode.multiple || !toggle) {
            selection.setAllSelected(false);
        }
        this._setSelectionById(selection, selectedIds, toggle ? "toggle" : "select");

        selection.setChangeEvents(true);
    }

    private _setSelectionById(selection: Selection | undefined, selectedIds: string[], selectionState?: "select" | "unselect" | "toggle") {
        if (!selection) {
            return;
        }
        const items = selection.getItems() as IExtensibleEntity[];

        selection.setChangeEvents(false);
        selectedIds.forEach(_ => {
            const item = items.find(__ => __.id === _) as IObjectWithKey;
            if (!item) { return; }
            const key = selection.getKey(item);
            if (selectionState === "toggle") {
                selection.toggleKeySelected(key);
            } else {
                selection.setKeySelected(key, (selectionState ?? "select") === "select", true);
            }
        });
        selection.setChangeEvents(true);
    }

    private _isItemSelected = (data: IBaseTimelineElement | ITimelineRelation): boolean => {
        const key = this.props.selection?.getKey(data?.entity as IObjectWithKey);
        return !!(key && this.props.selection?.isKeySelected(key));
    }

    private _resetSelection = () => {
        if (this._allowTimelineElementSelection()
            && this.props.selection && this.props.selection.getSelectedCount() > 0) {
            this.props.selection?.setAllSelected(false);
        }
    }

    private _rowShiftEqual(rowShift?: RowShift, newRowShift?: RowShift): boolean {
        return rowShift?.addNewLine === newRowShift?.addNewLine
            && rowShift?.targetRow === newRowShift?.targetRow
            && arraysEqual(rowShift?.overlappedSegments?.map(_ => _.key) || [], newRowShift?.overlappedSegments?.map(_ => _.key) || [])
            && arraysEqual(rowShift?.overlappedMarkers?.map(_ => _.key) || [], newRowShift?.overlappedMarkers?.map(_ => _.key) || []);
    }

    private _renderHead(sticky: boolean): JSX.Element {
        const { scale, timeline, quantization } = this.state;
        const timelineWidth = this._getTimelineWidth();
        return <THeader
            key={sticky ? 'sticky-timeline-header' : 'timeline-header'}
            showTreeExpandColumn={this.props.buildTree && this.props.showTreeExpandColumn !== false}
            checkboxVisibility={this.props.checkboxVisibility}
            columns={this.props.columns}
            displayToday={this.props.styling?.values.showToday ?? true}
            isAllExpanded={this._expandSubentities.isAllSelected()}
            toggleAllExpanded={this._toggleAllExpanded}
            isAllSelected={this.props.selection?.isAllSelected()}
            toggleAllSelected={this._toggleAllSelected}
            onChangeTimeframe={this._onChangeTimeframe}
            renderCellContent={this.props.renderHeaderCellContent}
            onColumnHeaderClick={this.props.onColumnHeaderClick}
            columnsWidths={this._columnsWidths}
            onColumnResized={this._onColumnResized}
            quantization={quantization}
            onQuantizationChange={this._onQuantizationChange}
            scale={scale}
            sticky={sticky}
            timeline={timeline}
            timelineWidth={timelineWidth}
            userTimeframe={this.props.userTimeframe}
            renderTimelineHeaderFirstRow={this.props.renderTimelineHeaderFirstRow}
            isDayOff={this.props.isDayOff}
        />;
    }

    private _toggleAllSelected = () => {
        if (this.props.selection) {
            this.props.selection.toggleAllSelected();
            this.forceUpdate();
        }
    }

    private _toggleAllExpanded = () => {
        this._expandSubentities.toggleAllSelected();
        this.forceUpdate();
    }

    private _onColumnResized = (resizingColumn: IColumn, newWidth: number, resizingColumnIndex: number) => {
        const newCalculatedWidth = Math.max(MIN_COLUMN_WIDTH, newWidth);
        this._columnsWidths[resizingColumn.key] = newCalculatedWidth;
        this._onResize();
        this.forceUpdate();
        this.props.onColumnResize?.(resizingColumn.key, calculateOriginWidth(newCalculatedWidth));
    }

    private _addGroupMarker(tr: JSX.Element, groupColor: string): JSX.Element {
        const trChildren = React.Children.toArray(tr.props.children) as React.ReactElement[];
        const firstTd = trChildren[0];
        if (firstTd?.type === "td") {
            const wrap = (
                <div className="align-center">
                    <div className="group-marker" style={{ backgroundColor: groupColor }} />
                    {firstTd.props.children}
                </div>
            );

            trChildren[0] = React.cloneElement(firstTd, undefined, wrap);
            return React.cloneElement(tr, undefined, trChildren);
        }
        return tr;
    }

    fixColumnsWidthsForDragnDrop(tr: JSX.Element, isDragging: boolean): JSX.Element {
        const trChildren = React.Children.toArray(tr.props.children) as React.ReactElement[];
        for (let i = 0; i < this.props.columns.length; i++) {
            const index = trChildren.length - this.props.columns.length - 1 + i;

            const maxWidth = this._columnsWidths[this.props.columns[i].key];
            trChildren[index] = React.cloneElement(trChildren[index],
                {
                    ...trChildren[index].props,
                    style: isDragging ? { minWidth: maxWidth, maxWidth: maxWidth } : undefined
                },
                trChildren[index].props.children);
        }

        return React.cloneElement(tr, undefined, trChildren);
    }

    private _renderGroup = (group: GroupInfo, rowIndex: number, isDraggable?: boolean): { element: JSX.Element, count?: number } => {
        let colspan = this.props.columns.length;
        if (this.props.buildTree && this.props.showTreeExpandColumn !== false) {
            colspan += 1;
        }
        if (this.props.checkboxVisibility !== CheckboxVisibility.hidden) {
            colspan += 1;
        }

        let result: JSX.Element[] = [this._renderGroupHeader(group, colspan)];

        const expand = this._expandGrouping.isKeySelected(group.row.key);

        const rows = this.state.groupedRows && this.state.groupedRows[group.key];
        if (expand && rows) {
            rows.forEach((_, index) => {
                const trs = this._renderItem(_,
                    index,
                    isDraggable ? "isDraggable" : undefined,
                    0,
                    group.hideMarker ? undefined : group.color,
                    isDraggable)

                if (!group.hideMarker) {
                    trs.forEach((tr, ind) => trs[ind] = this._addGroupMarker(tr, group.color));
                }
                result = [...result, ...trs];
            });
        }

        const footer = expand ? this._renderGroupFooter(group, colspan) : undefined;

        if (isDraggable) {
            return {
                element: (
                    <Droppable droppableId={group.key} type="row" key={group.key}>
                        {(provided, snapshot) => (
                            <tbody ref={provided.innerRef} className={snapshot.isDraggingOver ? 'drag-over' : undefined}>
                                {result}
                                {provided.placeholder}
                                {footer}
                            </tbody>
                        )}
                    </Droppable>
                )
            };
        }

        footer && result.push(footer);

        return {
            element: (
                <tbody key={group.key} onClick={this._resetSelection}>
                    {this._sliceRowsToRender(result, rowIndex)}
                </tbody>
            ),
            count: result.length
        };
    }

    private _renderGroupHeader = (group: GroupInfo, colspan: number): JSX.Element => {
        const element = this.props.grouping!.renderGroupHeader(
            group,
            !this._expandGrouping.isKeySelected(group.row.key),
            this._isWholeGroupSelected(group.row.key),
            (e) => { this._expandGrouping.toggleKeySelected(group.row.key); e.stopPropagation(); e.preventDefault(); },
            this.props.checkboxVisibility === CheckboxVisibility.hidden ? undefined : () => {
                const rows = this.state.groupedRows?.[group.key];
                const isSelected = this._isWholeGroupSelected(group.key);
                if (rows?.length) {
                    rows.forEach(_ => this.props.selection?.setKeySelected(_.key, !isSelected, true));
                }
            });
        return (
            <tr key={group.key} className={`group-row ${!this.props.checkboxVisibility ? 'visible-onhover' : ''}`}>
                {this.props.columns.length > 0 && <td colSpan={colspan}>
                    {element}
                </td>}
                <td className="timeline-cell">
                    <span className='bg-text'></span>
                    {this.props.columns.length === 0 && element}
                    <div className={`timeline-canvas-wrap ${this.state.rowShift?.targetRow === group.row ? 'add-line-after' : ''}`}>
                        <TimelineBody
                            segments={group.row.segments}
                            markers={group.row.markers}
                            scale={this.state.scale}
                            timelineWidth={this._getTimelineWidth()}
                            displayToday={this.props.styling?.values.showToday ?? true}
                            largeMode={this.props.styling?.values.largeBars}
                            scaleRenderMode={this.props.scaleRenderMode}
                            renderSegmentTooltipContent={this.props.renderSegmentTooltipContent ? this._memoizedFn(group.row, this.props.renderSegmentTooltipContent) : undefined}
                            renderSegmentContent={this.props.renderSegmentContent ? this._memoizedFn(group.row, this.props.renderSegmentContent) : undefined}
                        />
                    </div>
                </td>
            </tr>
        );
    }

    private _isWholeGroupSelected = (groupKey: string): boolean => {
        const rows = this.state.groupedRows?.[groupKey];

        return !!rows?.length && !rows.find(_ => !this.props.selection?.isKeySelected(_.key));
    }

    private _renderGroupFooter = (group: GroupInfo, colspan: number): JSX.Element | undefined => {
        if (!this.props.grouping?.renderGroupFooter) {
            return undefined;
        }

        return (
            <tr key={`new-task-${group.key}`} className="group-row">
                {this.props.columns.length > 0 && <td colSpan={colspan}>
                    {this.props.grouping.renderGroupFooter(group)}
                </td>}
                <td className="timeline-cell">
                    <span className='bg-text'></span>
                    <div className="timeline-canvas-wrap">
                        <TimelineBody
                            segments={[]}
                            markers={[]}
                            scale={this.state.scale}
                            timelineWidth={this._getTimelineWidth()}
                            displayToday={this.props.styling?.values.showToday ?? true}
                            largeMode={this.props.styling?.values.largeBars}
                            scaleRenderMode={this.props.scaleRenderMode} />
                    </div>
                </td>
            </tr>
        );
    }

    private _renderItem(row: IRow, index: number, className: string | undefined, outlineLevel: number, groupMarkerColor?: string, isDraggable?: boolean, parentKey?: string)
        : JSX.Element[] {
        const expand = row.subItems && this._expandSubentities.isKeySelected(row.key);
        const isSelectable = (outlineLevel === (this.props.primaryOutlineLevel ?? 0)) && !!this.props.selection?.canSelectItem(row.entity as IObjectWithKey);
        let tr = (
            <tr key={parentKey + row.key} className={`${className ?? ''} ${expand ? 'expanded' : ''} ${'outline-level-' + outlineLevel}`}
                onClick={this.props.checkboxVisibility !== CheckboxVisibility.hidden && isSelectable
                    ? () => {
                        this.props.selection?.setAllSelected(false);
                        this.props.selection?.setKeySelected(row.key, true, true);
                    }
                    : undefined}>
                {this.props.checkboxVisibility !== CheckboxVisibility.hidden && this._renderCheckboxCell(row, isSelectable)}
                {this.props.buildTree && this.props.showTreeExpandColumn !== false && this._renderTreeIconCell(row)}
                {this.props.columns.map((column, idx) => this._renderCell(index, row, column, idx))}
                {this._renderTimelineCell(row)}
            </tr>
        );

        let mainRow;
        if (isDraggable) {
            mainRow = (
                <Draggable key={row.key} draggableId={row.key} index={index} type="row" isDragDisabled={this.props.dragDropEvents?.isDragDisabled?.(row)}>
                    {(provided, snapshot) => {
                        if (groupMarkerColor) {
                            tr = this._addGroupMarker(tr, groupMarkerColor);
                        }
                        tr = this.fixColumnsWidthsForDragnDrop(tr, snapshot.isDragging);
                        return React.cloneElement(tr, {
                            ref: provided.innerRef,
                            ...provided.draggableProps,
                            ...provided.dragHandleProps,
                            style: { ...provided.draggableProps.style },
                            className: snapshot.isDragging ? mergeStyles(tr.props.className, 'dragging') : tr.props.className
                        });
                    }}
                </Draggable>
            );
        } else {
            mainRow = tr;
        }

        let result: JSX.Element[] = [mainRow];
        if (expand) {
            if (row.subItems!.length) {
                result = result.concat(row.subItems!.map(
                    (_, subIndex) => this._renderItem(_, subIndex, `${className ?? ''} subitem`, outlineLevel + 1, undefined, undefined, row.key)).reduce((_, __) => _.concat(__)));
            } else {
                const subItemType = row.subItemType ?? EntityType.Project;
                result.push(
                    <tr key={`no-data ${row.key}`} className={`${className ?? ''} subitem no-child-items`}>
                        <td className="checkbox-column"><span className='bg-text'></span></td>
                        {this.props.buildTree && this.props.showTreeExpandColumn !== false && <td className="tree-column"><span className='bg-text'></span></td>}
                        {this.props.columns.length > 0 && <td colSpan={this.props.columns.length}>
                            <span className='bg-text'><Icon iconName={entityLogoConfig[subItemType]?.iconName} /> 0 {entityLogoConfig[subItemType]?.label}s</span>
                        </td>}
                        {this._renderTimelineCell({ key: '', entity: { id: '', attributes: {} }, markers: [], segments: [] }, `No ${subItemType}s included yet`)}
                    </tr>
                );
            }
        }
        return result;
    }

    private _renderCheckboxCell(row: IRow, isSelectable: boolean): JSX.Element {
        return <td key='checkbox-column' className={`checkbox-column ${this.props.checkboxVisibility === CheckboxVisibility.onHover ? 'visible-onhover' : ''}`}
            onClick={isSelectable ? (e) => {
                this.props.selection?.toggleKeySelected(row.key);
                e?.stopPropagation();
                e?.preventDefault();
            } : undefined}
        >
            {isSelectable && <Checkbox checked={this.props.selection?.isKeySelected(row.key)} />}
        </td>;
    }

    private _renderTreeIconCell(row: IRow): JSX.Element {
        return <td key='tree-column' className='tree-column'>
            {
                !!row.subItems?.length && <IconButton
                    title={this._expandSubentities.isKeySelected(row.key) ? 'Collapse' : 'Expand'}
                    iconProps={{ iconName: this._expandSubentities.isKeySelected(row.key) ? 'ChevronUp' : 'ChevronDown' }}
                    onClick={(e) => { this._expandSubentities.toggleKeySelected(row.key); e.stopPropagation(); e.preventDefault(); }} />
            }
        </td>;
    }

    private _renderCell(index: number, row: IRow, column: IColumn, idx: number): JSX.Element {
        const { checkboxVisibility } = this.props;

        const width = this._columnsWidths[column.key];
        return <Td
            key={column.key}
            className={`timeline-table-cell ${column.className || ''}`}
            minWidth={width}
            maxWidth={width}
            style={idx === 0 ? { paddingLeft: getTimelineRowCellIndentation(checkboxVisibility) } : undefined}
        >
            {column.onRender?.(row.entity, index, column)}
        </Td>;
    }

    public componentDidMount() {
        this.headerHeight = (this._table as HTMLElement).getElementsByTagName("thead")?.[0]?.clientHeight || 0;
        this._scrollableContainer = this.getScrollableContainer();
        this._scrollableContainer?.addEventListener("scroll", () => { this._handleHorizontalScrollDebounce(); this._handleVerticalScrollDebounce(); });
        this._tableView?.addEventListener("scroll", this._handleVerticalScrollDebounce);
        window.addEventListener("resize", this._resizeAndScroll);
        this._resizeAndScroll();
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this._resizeAndScroll);
        this._scrollableContainer?.removeEventListener("scroll", () => { this._handleHorizontalScrollDebounce(); this._handleVerticalScrollDebounce(); })
        this._tableView?.removeEventListener("scroll", this._handleVerticalScrollDebounce);
    }

    public componentDidUpdate() {
        this._resizeFloatingScrollbar();
        this._handleScroll();
    }

    private _resizeAndScroll = () => {
        this._onResize();
        this._onVerticalScroll();
    }

    private _setExpandGroupingAndExpandNew(groups: EntityGroup[]) {
        const keyedGroups = groups.map(_ => _ as IObjectWithKey);
        const curItems = new Set<string>(this._expandGrouping.getItems().map(_ => _.key as string));
        const toExpand = keyedGroups.map(_ => _.key as string).filter(_ => !curItems.has(_))
        this._expandGrouping.setItems(keyedGroups, false);
        toExpand.forEach(_ => this._expandGrouping.setKeySelected(_, true, true))
    }

    private getScrollableContainer = (): Element | undefined => {
        if (this._container.scrollHeight !== this._container.clientHeight) {
            return this._container;
        }
        const element = findScrollableParent((this._container as HTMLElement).parentElement) as HTMLElement | null | undefined;
        return element || undefined;
    }

    private _handleScroll() {
        const classList = (this._floatingScollbar as Element)?.classList;
        if (classList == null) {
            return;
        }

        if (this._isFloatingScollbarIsVisible()) {
            if (classList.contains("invisible")) {
                classList.remove("invisible");
            }
        } else {
            if (!classList.contains("invisible")) {
                classList.add("invisible");
            }
        }
    }

    private _onVerticalScroll = () => {
        if (this.props.dragDropEvents || this.props.isVirtualizationDisabled) {
            return;
        }

        let scrollTop = 0;
        let visibleHeight = 0;

        if (this._hasVerticalScroll()) {
            scrollTop = this._tableView && this._tableView.scrollTop || 0;
            visibleHeight = this._tableView?.clientHeight || 0
        }
        else if (this._table && this._scrollableContainer) {
            const rect = this._table.getBoundingClientRect()
            visibleHeight = this._scrollableContainer.clientHeight;

            if (rect.top < 0 && rect.bottom > 0) {
                scrollTop = -(rect.top - this.headerHeight);
            } else if (rect.bottom < 0) {
                visibleHeight = 0;
                scrollTop = rect.height;
            }
        }

        const from = Math.max(0, ~~((scrollTop / LIST_ROW_HEIGHT) - 1));
        const visible = Math.max(0, ~~((visibleHeight) / LIST_ROW_HEIGHT));
        const to = from + visible;

        if (from < this.state.renderedRowsRange.from || to > this.state.renderedRowsRange.to) {
            this.setState({ renderedRowsRange: { from: Math.max(0, from - visible), to: to + visible } });
        }
    }

    private _handleHorizontalScrollDebounce = waitForFinalEvent(this._handleScroll.bind(this), 50, getId());
    private _handleVerticalScrollDebounce = waitForFinalEvent(this._onVerticalScroll.bind(this), 50, getId());

    private _isFloatingScollbarIsVisible() {
        if (this._scrollableContainer && this._tableView) {
            const containerRect = this._scrollableContainer.getBoundingClientRect();
            const componentRect = this._tableView.getBoundingClientRect();

            return componentRect.bottom > containerRect.top && componentRect.bottom > containerRect.bottom;
        }

        return false;
    }

    private _hasVerticalScroll(): boolean {
        return !!this._tableView && this._tableView.scrollHeight > this._tableView.clientHeight;
    }

    private _onResize = () => {
        if (!this._table || !this._table.parentElement) {
            return;
        }

        let newWidth = Math.floor(this._container.offsetWidth
            - (this.props.checkboxVisibility !== CheckboxVisibility.hidden ? CHECKBOX_COLUMN_WIDTH : 0)
            - ((this.props.buildTree && this.props.showTreeExpandColumn !== false) ? TREE_COLUMN_WIDTH : 0)
            - Object.keys(this._columnsWidths).reduce((a, b) => this._columnsWidths[b] + a, 0)
            - (this._hasVerticalScroll() ? getScrollbarWidth() : 0)
            - TIMELINE_COLUMN_PADDING);

        if (newWidth < this.state.scale.totalWidthUnits) {
            newWidth = this.state.scale.totalWidthUnits
        }

        if (newWidth < TIMELINE_MIN_WIDTH) {
            newWidth = TIMELINE_MIN_WIDTH;
        }

        if (this.state.timelineWidth !== newWidth) {
            this.setState({ timelineWidth: newWidth }, this._resizeContent);
            return;
        }

        this._resizeContent();
    }

    private _resizeContent = () => {
        this._setWidths();
        this._fixContentWidth(this._widthFixer);
        this._resizeFloatingScrollbar();
    }

    private _resizeFloatingScrollbar = () => {
        if (this._floatingScollbar) {
            if (this._container && this._container.offsetWidth !== this._floatingScollbar.offsetWidth) {
                this._floatingScollbar.style.width = this._container.offsetWidth + "px";
            }
            this._fixContentWidth(this._floatingScollbar.firstElementChild as HTMLElement);
        }
    }

    private _fixContentWidth = (htmlElement: HTMLElement | undefined) => {
        if (htmlElement) {
            const scrollerWidth = this._hasVerticalScroll() ? getScrollbarWidth() : 0;
            htmlElement.style.width = this._table!.offsetWidth + scrollerWidth + 'px';
        }
    }

    private _setWidths() {
        if (this._tableView) {
            const width = this._getScrollPosition()!;
            this._tableView.style.width = width + 'px';
            const scrollerWidth = this._hasVerticalScroll() ? getScrollbarWidth() : 0;
            this._tableOverlay!.style.width = width - scrollerWidth + 'px';
        }
    }

    private _getScrollPosition = (): number | undefined => {
        if (this._container && this._table) {
            return this._container.scrollLeft + this._container.clientWidth;
        }

        return undefined;
    }

    private getTimeline(items: IRow[], timeframe: Partial<ITimeframe> | undefined): ITimelineInfo {
        const rowExtremums = items.map(_ => getRowExtremum(_));
        const timelineRowsExtremum = utils.minMax([...rowExtremums.map(_ => _.minDate), ...rowExtremums.map(_ => _.maxDate)]);
        const dates = [timeframe?.start || timelineRowsExtremum.minDate, timeframe?.end || timelineRowsExtremum.maxDate];
        let { minDate, maxDate } = utils.minMax(dates);

        minDate = minDate || new Date();
        maxDate = maxDate || new Date();

        return {
            start: minDate.getBeginOfDay(),
            end: maxDate.getEndOfDay(),
            duration: utils.diffDays(minDate, maxDate)
        }
    }

    private _getTimelineWidth = () => {
        return this.state.timelineWidth !== undefined ? this.state.timelineWidth : this.state.scale.totalWidthUnits;
    }

    private _onDragStart = (initial: DragStart) => {
        const { items, dragDropEvents } = this.props;
        const draggedRow: IRow | undefined = items.find(_ => _.key === initial.draggableId);
        if (!draggedRow) {
            return;
        }
        dragDropEvents?.start(draggedRow);
    }

    private _onDragEnd = (result: DropResult) => {
        const { items, dragDropEvents } = this.props;
        const { groups, groupedRows } = this.state;
        // dropped outside the list
        if (!result.destination || !dragDropEvents || !groupedRows) {
            return;
        }

        const rowId = result.draggableId;
        const sourceGroupId = result.source.droppableId;
        const targetGroupId = result.destination.droppableId;
        const indexInSourceGroup = result.source.index;
        const indexInTargetGroup = result.destination.index;

        const draggedRow: IRow | undefined = items.find(_ => _.key == rowId);
        if (!draggedRow
            || (sourceGroupId === targetGroupId && indexInSourceGroup === indexInTargetGroup)) {
            return;
        }

        const groupId: string | undefined = groups ? targetGroupId : undefined;
        const insertBeforeRow = !this.state.groups ? items[indexInTargetGroup] : this._getNextRow(result);

        //needed to prevent blinking while server is processing the request
        const newGroupedRows: Dictionary<IRow[]> = { ...groupedRows, [sourceGroupId]: (groupedRows[sourceGroupId] || []).filter(_ => _.key !== rowId) };
        if (!newGroupedRows[targetGroupId]) {
            newGroupedRows[targetGroupId] = [];
        }
        newGroupedRows[targetGroupId].splice(indexInTargetGroup, 0, draggedRow);
        this.setState({
            groupedRows: newGroupedRows,
            rowPositionMap: this._buildRowPositionMap(items, this.state.groups, newGroupedRows, !!this.props.grouping?.renderGroupFooter)
        });

        dragDropEvents.end(draggedRow, groupId, insertBeforeRow);
    }

    private _getNextRow = (result: DropResult): IRow | undefined => {
        const { groupedRows } = this.state;
        if (!groupedRows || !result.destination) {
            return undefined;
        }

        const rows: IRow[] = groupedRows[result.destination.droppableId] || [];
        if (result.source.droppableId === result.destination.droppableId) {
            return rows.filter(_ => _.key !== result.draggableId)[result.destination.index];
        }
        return rows[result.destination.index];
    }

    private _onQuantizationChange = (_: Quantization) => {
        const newState = {
            ...this._buildScaleState(this.state.filteredByGroupsRows || this.props.items, _, { ...this.props.initialTimeframe, ...this.props.userTimeframe },
                this.props.scaleMultiplier)
        };

        const prevState = buildScaleChange(this.state);

        this.setState(newState, () => {
            this._onResize();
            this.props.onScaleChange?.({
                newState: buildScaleChange(newState),
                prevState,
                origin: { 
                    quantization: _,
                    timeframe: this.props.userTimeframe
                },
                prevOrigin: { 
                    quantization: _,
                    timeframe: this.props.userTimeframe
                }
            });
        });
    }

    private _onChangeTimeframe = (_: Partial<ITimeframe> | undefined, resetQuantization?: boolean) => {
        resetQuantization = resetQuantization || _ === undefined;
        const userTimeframe = _ ? { ...this.props.userTimeframe, ..._ } : undefined;
        const newState = {
            ...this._buildScaleState(this.state.filteredByGroupsRows || this.props.items,
                resetQuantization ? undefined : this.state.quantization,
                { ...this.props.initialTimeframe, ...userTimeframe })
        };

        const prevState = buildScaleChange(this.state);
        this.setState(newState, () => {
            this._onResize();
            this.props.onScaleChange?.({
                newState: buildScaleChange(newState),
                prevState,
                origin: {
                    quantization: resetQuantization ? undefined : this.props.userQuantization,
                    timeframe: userTimeframe
                },
                prevOrigin: {
                    quantization: this.props.userQuantization,
                    timeframe: this.props.userTimeframe
                }
            });
        });
    }
}

const buildScaleChange = (state: Pick<State, keyof ScaleChange>): ScaleChange => {
    return {
        scale: state.scale,
        quantization: state.quantization,
        timeline: state.timeline,
    }
}