import * as React from "react";
import { IconButton } from "office-ui-fabric-react";
import { toDate } from "../../../../utils/common";
import { IInsightsStatusData } from "../../../../../entities/common";
import * as StatusDescriptorFactory from "../../../../../entities/StatusDescriptorFactory";
import { Field } from "../../../../../entities/Metadata";

type Props = {
    statusField: Field;
    insights: IInsightsStatusData | undefined;
    resetStatus: () => void;
};

export default function ResetStatusButton(props: Props) {
    const { statusField, insights, resetStatus } = props;
    
    if (!insights) {
        return null;
    }

    const autoCategory = insights.autoValue;
    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(statusField);
    const autoValue = statusDescriptor.getCategoryDefaultStatusValue(autoCategory);

    return insights.manualValueDueDate && new Date(insights.manualValueDueDate)! > new Date()
        ? <div>
            <IconButton
                iconProps={{ iconName: 'Processing' }}
                text="Reset"
                title={`Reset description and status to automatically calculated value - ${autoValue?.trim()}`}
                onClick={resetStatus}
            />
        </div>
        : null;
}
