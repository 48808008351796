import { DefaultButton, Icon, Link } from "office-ui-fabric-react";
import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from "../../store";
import { IReportingInfo } from "../../store/Tenant";

type Props = {
    reporting: IReportingInfo;
}
const helpUrl = "https://help.ppm.express/123270-ppm-express-external-power-bi-reports/external-power-bi-reports-configuration?from_search=55014437";

const ReportsPlaceholder = (props: Props) => <div className="reports-placeholder">
    <div className="reports-placeholder-header"><Icon iconName="PowerBILogo" /> Power BI</div>
    <div className="reports-placeholder-description">
        <p>Welcome to PPM Express Power BI reports menu.</p>
        <ul>
            <li>Check <Link target="_blank" href={helpUrl}>help</Link> for information about Power BI reports configuration using your Power BI license.</li>
            {props.reporting.sampleUrl && <li>View <Link target="_blank" href={props.reporting.sampleUrl}>sample</Link> report pack on our demo data.</li>}
            <li>Navigate to <Link target="_blank" href="/reportSettings">report settings page</Link> to configure reports.</li>
        </ul>
    </div>
    <div className="report-placeholder-actions">
        <DefaultButton
            primary={true}
            text="Settings"
            target="_blank"
            href="/reportSettings" />
        <DefaultButton
            primary={true}
            text="Help"
            href={helpUrl}
            target="_blank" />
        {
            props.reporting.sampleUrl && <DefaultButton
                primary={true}
                text="Sample"
                href={props.reporting.sampleUrl}
                target="_blank" />
        }
    </div>
</div>;

function mapStateToProps(state: ApplicationState) {
    return { reporting: state.tenant.reporting };
}

export default connect(mapStateToProps)(ReportsPlaceholder);