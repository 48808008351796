import * as React from 'react';
import CategoryColorStatusView from '../../../../views/list/columns/CategoryColorStatusView';
import EntityWarning from "../../../warnings/EntityWarning";
import { IInsightsStatusData, IWarning } from '../../../../../entities/common';
import { CategoryStatusOption } from '../../../../../entities/StatusDescriptor';

type Props = {
    entity: {
        name: string;
        statusOption: CategoryStatusOption | undefined;
        description?: string;
        descriptionPlaceholder: string;
        insights: IInsightsStatusData | undefined
    };
    onClick: () => void;
    warnings?: IWarning[];
}

const StatusCard  = (props: Props) => {
    const { entity, warnings, onClick } = props;
    const { name, description, statusOption, descriptionPlaceholder } = entity;

    return (
        <div className="card status-card" onClick={onClick}>
            <div className="align-center">
                <div className="title">{name}</div>
                {warnings && <EntityWarning warnings={warnings} />}
                <CategoryColorStatusView
                    statusOption={statusOption}
                    trend={entity.insights?.trend}
                />
            </div>
            <div className={`description-wrapper`} style={{ borderLeftColor: statusOption?.color }}>
                <div className={`description limited ${description ? '' : 'empty'}`} title={description}>
                    {description || descriptionPlaceholder}
                </div>
            </div>
        </div>
    );
}

export default StatusCard;
