import * as React from 'react';
import { Link as FabricLink, Icon } from 'office-ui-fabric-react';
import Link from '../../../common/Link';
import Logo from "../../../common/Logo";
import { FormatDate, stopEffects } from '../../../utils/common';
import EntityWarning from '../../../common/warnings/EntityWarning';
import { Dictionary, IWarning, MaybeDate, ServerEntityType } from '../../../../entities/common';
import { SourceType, SourceType_ } from '../../../../store/ExternalEpmConnectStore';
import PrivateProjectIcon from '../../../common/PrivateProjectIcon';

export interface SourceTypeInfo {
    type?: SourceType;
    url?: string;
    extraTitle?: string;
    iconName?: string;
    onClick?: () => void;
}

export interface Props {
    outlineLevel?: number;
    name: string;
    url?: string;
    replaceCurrentUrl?: boolean;
    onClick?: () => void;
    iconName?: string;
    icon?: JSX.Element;
    imageId?: string;
    imageClassName?: string;
    renderLogo?: () => JSX.Element;
    className?: string;
    withDates?: boolean;
    startDate?: MaybeDate;
    finishDate?: MaybeDate;
    withSingleDate?: boolean;
    singleDate?: MaybeDate;
    isTimelineView?: boolean;
    isPrivate?: boolean;
    warnings?: IWarning[];
    multipleItemsMessage?: string;
    sourceType?: SourceTypeInfo[] | null | SourceType;
    openNewPage?: boolean;
    onlyIcon?: boolean;
}

export default class EntityName extends React.Component<Props> {
    public render() {
        const { className, name, url, replaceCurrentUrl, onClick, sourceType, warnings, multipleItemsMessage, openNewPage, onlyIcon, isPrivate } = this.props;
        const isExternalUrl = !!url && (url.toLowerCase().startsWith("http://") || url.toLowerCase().startsWith("https://"));
        const linkProps = { href: url!, title: name, target: isExternalUrl || openNewPage ? "_blank" : "_self" };

        return (
            <div title={name} className={`with-dates entityName ${className || ''}`}>
                {url 
                    ? (isExternalUrl
                        ? <FabricLink {...linkProps} draggable={false} className="clickable title-link">
                            {this._renderContents()}
                        </FabricLink>
                        : <Link {...linkProps} replaceCurrentUrl={replaceCurrentUrl} draggable={false} className="clickable title-link">
                            {this._renderContents()}
                        </Link>)
                    : (
                        <div className={`${onClick ? 'clickable' : ''} name-wrap`} onClick={onClick}>
                            {this._renderContents()}
                        </div>
                    ) 
                }
                {
                    sourceType != null && !onlyIcon && (Array.isArray(sourceType) ? sourceType : [{ type: sourceType, url: url }]).map(EntityName._renderIcon)
                }
                {isPrivate && <PrivateProjectIcon />}
                {warnings && <EntityWarning warnings={warnings} multipleItemsMessage={multipleItemsMessage} />}
            </div>
        );
    }

    private _renderContents = () => {
        const { withDates, name, renderLogo, iconName, icon, imageId, imageClassName, isTimelineView, startDate, finishDate, withSingleDate, singleDate, onlyIcon } = this.props;
        return <>
            {icon ?? (renderLogo ? renderLogo() : <Logo className={imageClassName} imageId={imageId} iconName={iconName} />)}
            {!onlyIcon && <div className="title-wrapper">
                <span className="title font-14" title={name}>{name}</span>
                {withDates && isTimelineView && <span className="dates">{FormatDate(startDate)} - {FormatDate(finishDate)}</span>}
                {withSingleDate && <span className="dates">{FormatDate(singleDate)}</span>}
            </div>}
        </>;
    }

    static _buildSourceType(entity: { sourceType?: SourceType | null, externalData: Dictionary<any> }, extraTitle?: string): SourceTypeInfo[] {
        const sourceTypeInfo: SourceTypeInfo[] = [];
        if (entity.sourceType !== null && entity.externalData) {
            sourceTypeInfo.push({
                type: entity.sourceType,
                url: entity.externalData["NameLink"],
                extraTitle: entity.externalData["ImportedFromId"] && (extraTitle || ''),
                iconName: entity.externalData["ImportedFromId"] && "PPMXLink"
            });
        }
        else if (entity.sourceType === null && entity.externalData
            && !!entity.externalData["ImportedFromId"]
            && !entity.externalData["OriginIsDeleted"]) {
            sourceTypeInfo.push({
                type: SourceType.Ppmx,
                url: entity.externalData["NameLink"],
                extraTitle: entity.externalData["ImportedFromTypeLabel"] || ServerEntityType[entity.externalData?.["ImportedFromType"]],
                iconName: "PPMXLink"
            });
        }
        if (entity.externalData?.["ExtraLinks"]) {
            (entity.externalData?.["ExtraLinks"] as []).forEach(_ => sourceTypeInfo.push(_));
        }
        if (entity.externalData?.["ExtraNameLink"] || entity.externalData?.["ExtraSourceType"]) {
            sourceTypeInfo.push({
                type: entity.externalData["ExtraSourceType"],
                url: entity.externalData["ExtraNameLink"]
            });
        }
        return sourceTypeInfo;
    }

    static _renderIcon(icon: SourceTypeInfo, index: number = 0) {
        const iconName = icon.iconName ?? SourceType_.getIconName(icon.type!);
        return <div key={index} className="src" title={icon.type ? `Linked to ${SourceType_.getName(icon.type)} ${icon.extraTitle ?? ''}` : icon.extraTitle}>
            {
                icon.url
                    ? <FabricLink href={icon.url} target="_blank" onClick={_ => _.stopPropagation()}>
                        <Icon className={iconName} iconName={iconName} />
                    </FabricLink>
                    : <Icon
                        style={{ cursor: icon.onClick ? "pointer" : "default" }}
                        className={iconName}
                        iconName={iconName}
                        onClick={stopEffects(icon.onClick)} />
            }
        </div>;
    }
}