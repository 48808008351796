import { get, post } from '../fetch-interceptor';
import { RouterAction } from "react-router-redux";
import { AppThunkAction } from ".";
import { Reducer, Action } from "redux";
import { Tracker } from "./PreferencesTracker";
import { IUserInfo, IWithScale, IWithSortBy, IWithSubViews, UserPreferencesSettingsUpdate } from "../entities/common";
import { catchApiError, defaultCatch } from './utils';
import { saveSettings } from './Sections';
import { IWithActiveFilter } from './services/viewSaver';
import { IProjectInfo } from './integration/ImportStore';
import fileDownload from 'js-file-download';
import { KnownAction as NotificationsKnownAction, actionCreators as NotificationsActionCreators, NotificationType } from "./NotificationsStore";

export type Grouping = 'Resource' | 'Project';

export type ReportedTimeSettings = IWithSubViews & IWithSortBy & IWithScale & IWithActiveFilter & {
    displayFields: string[];
    grouping: Grouping;
}

export interface ReportedTimeState {
    isLoading: boolean;
    settings: ReportedTimeSettings;
}

export interface ReportedResourceProjectTime {
    date: Date;
    project: IProjectInfo;
    duration: number;
    resource: IUserInfo;

    administrativeCategory: string;
}

enum ActionTypes { 
    LOAD_REPORTED_TIME_SETTINGS = "LOAD_REPORTED_TIME_SETTINGS",
    LOADED_REPORTED_TIME_SETTINGS = "LOADED_REPORTED_TIME_SETTINGS",
    SAVE_REPORTED_TIME_SETTINGS = "SAVE_REPORTED_TIME_SETTINGS"
}

interface RequestSettingsAction {
    type: ActionTypes.LOAD_REPORTED_TIME_SETTINGS;
}

interface ReceiveSettingsAction {
    type: ActionTypes.LOADED_REPORTED_TIME_SETTINGS;
    settings: ReportedTimeSettings;
}

interface SaveSettingsAction {
    type: ActionTypes.SAVE_REPORTED_TIME_SETTINGS;
    update: UserPreferencesSettingsUpdate;
}

type KnownAction = RequestSettingsAction
    | ReceiveSettingsAction
    | SaveSettingsAction;

export const actionCreators = {
    loadSettings: (): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        get<ReportedTimeSettings>(`api/user/preferences/reported-time`)
            .then(data => dispatch({ type: ActionTypes.LOADED_REPORTED_TIME_SETTINGS, settings: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: ActionTypes.LOAD_REPORTED_TIME_SETTINGS });
    },
    saveSettings: (update: UserPreferencesSettingsUpdate, sendToServer: boolean = true): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.SAVE_REPORTED_TIME_SETTINGS, update });
        if (sendToServer) {
            Tracker.setReportedTimeSettings(dispatch, getState, update);
        }
    },
    exportReportedTimeToFile: (
        start?: Date,
        end?: Date,
        resourceIds?: string[],
        projectIds?: string[],
        onSuccess?: () => void,
        onError?: () => void): AppThunkAction<KnownAction | NotificationsKnownAction> => (dispatch, getState) => {
        post<string>(`api/reported-time/exportToFile`, { resourceIds, projectIds, start: start?.toDateOnlyString(), end: end?.toDateOnlyString() })
            .then(data => {
                let fileName = 'Resources Reported Time';

                if (start) {
                    fileName += " " + start.toDateOnlyString();
                }
                if (end) {
                    fileName += "-" + end.toDateOnlyString();
                }

                fileDownload(data, `${fileName}.csv`);
                onSuccess?.();
            })
            .catch(catchApiError(_ => {
                dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                onError?.();
            }));;
    },
};

const INIT_STATE: ReportedTimeState = {
    isLoading: true,
    settings: {} as ReportedTimeSettings
};

export const reducer: Reducer<ReportedTimeState> = (state: ReportedTimeState = INIT_STATE, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.LOAD_REPORTED_TIME_SETTINGS:
            {
                return {
                    ...state,
                    isLoading: true
                };
            }
        case ActionTypes.LOADED_REPORTED_TIME_SETTINGS:
            {
                return {
                    ...state,
                    isLoading: false,
                    settings: action.settings
                };
            }
        case ActionTypes.SAVE_REPORTED_TIME_SETTINGS: return saveSettings(state, action.update);
        default:
            const exhaustiveCheck: never = action;
    }
    return state;
};