import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogType, TextField, PrimaryButton, DefaultButton, DialogFooter, SpinnerSize, MessageBar, MessageBarType, Dropdown, Link, IconButton, Spinner, Overlay } from 'office-ui-fabric-react';
import { Validator } from "../../../validation";
import { ApplicationState } from '../../../store';
import { actionCreators, IConnectionRefreshInfo, ISpoConnectionInfo } from '../../../store/integration/SpoStore';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { copyToClipboard } from '../../utils/common';
import { Dictionary } from '../../../entities/common';
import PostMessageReceiver from '../PostMessageReceiver';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ConnectionTitle } from '../ConnectionTitle';
import ConnectionEditWrapper from '../ConnectionEditWrapper';

type OwnProps = {
    onDismiss: () => void;
    onCreated?: (connectionId: string) => void;
    onUpdated?: () => void;
    connectionId?: string;
}
type StateProps = { isLoading: boolean; isProcessing: boolean; error: string | null, refreshInfo: IConnectionRefreshInfo | null, createdConnectionId?: string }
type Props = OwnProps & StateProps & typeof actionCreators & RouteComponentProps<{}>;

enum AuthenticationType {
    Basic = 0,
    OAuth = 1
}
type ConnectionState = {
    authType?: AuthenticationType;
    id?: string;
    url: string;
    account?: string;
    login?: string;
    password?: string;
    clientId?: string;
    clientSecret?: string;
    refreshToken?: string;
    realm?: string;
};

type State = {
    connection: ConnectionState
}

const validator = {
    url: Validator.new().required().build(),
    login: Validator.new().required().build(),
    password: Validator.new().required().build(),
    clientId: Validator.new().required().build(),
    clientSecret: Validator.new().required().build(),
    refreshToken: Validator.new().required().build(),
    realm: Validator.new().required().build()
}

interface PostMessageData {
    context: {
        error?: { message: string },
        auth?: {
            refreshToken: string;
            realm: string;
            account: string;
        }
    }
}

class SpoConnectionEdit extends React.Component<Props, State> {
    readonly oauthTarget = "connectionDataReceived_spo";
    readonly urlHint = "your_domain.sharepoint.com/sites/pwa";
    readonly oauthRedirectPath = "/api/integration/auth/spo/accept";
    readonly secretHint = "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022";
    readonly empty = "";

    constructor(props: Props) {
        super(props);
        this.state = {
            connection: {
                id: props.connectionId,
                url: ''
            }
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.refreshInfo && nextProps.refreshInfo !== this.props.refreshInfo) {
            const info = nextProps.refreshInfo;
            this._setConnection({
                authType: nextProps.refreshInfo.login ? AuthenticationType.Basic : AuthenticationType.OAuth,
                ...info
            });
        }

        const isProcessed = this.props.isProcessing && !nextProps.isProcessing;
        if (isProcessed && !nextProps.error) {
            if (nextProps.createdConnectionId) {
                nextProps.onCreated?.(nextProps.createdConnectionId);
            }
            nextProps.onUpdated?.();
            this._dismiss();
        }
    }

    componentWillMount() {
        const { connectionId } = this.props;
        connectionId && this.props.loadRefreshInfo(connectionId);
    }

    public render() {
        const { connection } = this.state;
        const isEdit = !!connection.id;
        const { authType, url } = connection;
        const okText = isEdit ? "Save" : "Create";
        return <Dialog
            hidden={false}
            minWidth={400}
            maxWidth={400}
            dialogContentProps={{
                onDismiss: this._dismiss,
                type: DialogType.normal,
                title: <ConnectionTitle isEdit={isEdit} sourceType={SourceType.Spo} />
            }}
            modalProps={{
                isBlocking: true,
                containerClassName: 'ms-dialogMainOverride spo-popup connection-popup'
            }}>
            <ConnectionEditWrapper sourceType={SourceType.Spo}
                articleGuidePath="https://help.ppm.express/89437-project-online-connection/1075130-how-to-add-project-online-connection-to-ppm-express#section-1"
                videoGuidePath="https://help.ppm.express/89437-project-online-connection/1275715">
                <div className="connection-wrap">
                    <div>
                        <TextField label="Site URL"
                            value={url}
                            placeholder={this.urlHint}
                            onChange={(e, _) => this._setConnection({ url: _! })}
                            onGetErrorMessage={validator.url.getErrorMessage} />
                    </div>
                    <Dropdown
                        label="Authentication Type"
                        disabled={!url}
                        selectedKey={authType === undefined ? this.empty : authType}
                        options={[
                            { key: this.empty, text: 'Select authentication' },
                            { key: AuthenticationType.Basic, text: 'Basic (login/password)' },
                            { key: AuthenticationType.OAuth, text: 'OAuth (requires SharePoint App registration)' },
                        ]}
                        onChange={(e, o) => !!o && this._setConnection({ authType: o.key as AuthenticationType })} />
                    <div>
                        {authType === AuthenticationType.Basic && this._renderBasicAuth()}
                        {authType === AuthenticationType.OAuth && this._renderOAuth()}
                    </div>
                    {
                        this.props.error && <p>
                            <MessageBar
                                messageBarType={MessageBarType.severeWarning}
                                isMultiline={true}>
                                {this.props.error}
                            </MessageBar>
                        </p>
                    }
                    {this.props.isLoading && <Overlay><Spinner /></Overlay>}
                </div>
            </ConnectionEditWrapper>
            <DialogFooter>
                {
                    !this.props.isProcessing && [<PrimaryButton
                        key="ok"
                        disabled={!this._isValid()}
                        onClick={this._okClick}
                        text={okText} />,
                    <DefaultButton key="cancel" onClick={this._dismiss} text="Cancel" />]
                }
                {
                    this.props.isProcessing && [<PrimaryButton key="processing-ok" disabled={true} text={okText}>
                        <Spinner size={SpinnerSize.medium} />
                    </PrimaryButton>,
                    <DefaultButton key="processing-cancel" disabled={true} text="Cancel" />
                    ]
                }
            </DialogFooter>
        </Dialog>;
    }

    private _renderBasicAuth = () => {
        const { login, password } = this.state.connection;
        return <>
            <TextField label="Login" placeholder="login" value={login} onChange={(e, _) => this._setConnection({ login: _!.trim() })} onGetErrorMessage={validator.login.getErrorMessage} />
            <TextField label="Password" type="password" value={password} placeholder="password" onChange={(e, _) => this._setConnection({ password: _!.trim() })} onGetErrorMessage={validator.password.getErrorMessage} />
        </>;
    }

    private _setConnection = (state: Partial<ConnectionState>) => {
        this.setState({ connection: { ...this.state.connection, ...state } });
    }

    private _onOAuthMessageReceived = (e: MessageEvent, data: PostMessageData) => {
        if (data.context.auth) {
            this._setConnection({ refreshToken: data.context.auth.refreshToken, realm: data.context.auth.realm, account: data.context.auth.account });
            this.props.cleanError();
        }
        else if (data.context.error?.message) {
            this.props.setError(data.context.error.message);
        }
    }

    private _renderOAuth = () => {
        const { clientId, clientSecret, account } = this.state.connection;
        const appRegNewUrl = 'https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade';
        const isNewSecretRequired = this._isNewSecretRequired();
        return <>
            <PostMessageReceiver<PostMessageData>
                from={this.oauthTarget}
                onMessageReceived={this._onOAuthMessageReceived} />
            <div className='label-wrap'><label>App Registration Url</label></div>
            <div className='link-wrap'><Link href={appRegNewUrl} target="_blank" title={appRegNewUrl}>{appRegNewUrl}</Link></div>
            <TextField label="Application (client) ID" value={clientId} placeholder="application (client) id"
                onChange={(e, _) => this._setConnection({ clientId: _!.trim() })}
                onGetErrorMessage={validator.clientId.getErrorMessage} />
            <TextField label="Client secret value" value={clientSecret}
                placeholder={isNewSecretRequired ? 'client secret value' : this.secretHint}
                onChange={(e, _) => this._setConnection({ clientSecret: _!.trim() })}
                onGetErrorMessage={isNewSecretRequired
                    ? validator.clientSecret.getErrorMessage
                    : undefined} />
            {this._renderCopyField('Redirect URI', `https://${window.location.host}${this.oauthRedirectPath}`)}
            <PrimaryButton text="Connect" disabled={!this._canOAuthAuthorize()} onClick={this._oAuthAuthorize} style={{ marginTop: 5 }} />
            <span className="account-name">{account ? `Account: ${account}` : ''}</span>
        </>;
    }

    private _renderCopyField = (label: string, value: string) => {
        return <TextField label={label} readOnly value={value}
            onRenderSuffix={() => <IconButton iconProps={{ iconName: 'Copy' }} title="Copy to clipboard" onClick={() => copyToClipboard(value)} />}
        />;
    }

    private _okClick = () => {
        this._isValid() && this.props.createOrRefreshConnection(this.state.connection as ISpoConnectionInfo);
    }

    private _isNewSecretRequired = (): boolean => {
        const { id, clientId } = this.state.connection;
        return !id || !this.props.refreshInfo || !this.props.refreshInfo.clientId || this.props.refreshInfo.clientId !== clientId;
    }

    private _canOAuthAuthorize = (): boolean => {
        let reducedValidator: Dictionary<any> = {
            url: validator.url,
            clientId: validator.clientId
        };
        if (this._isNewSecretRequired()) {
            reducedValidator = { ...reducedValidator, clientSecret: validator.clientSecret };
        }
        return Validator.isValid(reducedValidator, this.state.connection);
    }

    private _oAuthAuthorize = (): void => {
        const { clientId, clientSecret, url } = this.state.connection;
        const { connectionId } = this.props;
        PostMessageReceiver.openPopupWindow(
            '/api/integration/auth/spo/oauth'
            + `?connectionId=${connectionId || ''}`
            + `&clientId=${encodeURIComponent(clientId || '')}`
            + `&clientSecret=${encodeURIComponent(clientSecret || '')}`
            + `&spSiteUrl=${encodeURIComponent(url)}`,
            this.oauthTarget);
    }

    private _isValid = () => {
        let reducedValidator: Dictionary<any> = { url: validator.url };

        if (this.state.connection.authType === AuthenticationType.Basic) {
            reducedValidator = {
                ...reducedValidator,
                login: validator.login,
                password: validator.password
            }
        } else {
            reducedValidator = {
                ...reducedValidator,
                realm: validator.realm,
                refreshToken: validator.refreshToken,
                clientId: validator.clientId
            };
            if (this._isNewSecretRequired()) {
                reducedValidator = { ...reducedValidator, clientSecret: validator.clientSecret };
            }
        }

        return Validator.isValid(reducedValidator, this.state.connection);
    }

    private _dismiss = () => {
        this.props.onDismiss();
        this.props.cleanError();
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isLoading: state.spo.connections.isLoading,
        isProcessing: state.spo.connections.isProcessing,
        error: state.spo.connections.error,
        refreshInfo: state.spo.connections.refreshInfo,
        createdConnectionId: state.spo.connections.createdConnectionId
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, actionCreators)(SpoConnectionEdit));