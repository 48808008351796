import { IConnectionsState, IApiResult, IListStore, IImportResult, IImportProjectMap, IImportResourceMap, IFieldMapping, ExternalFieldInfo, 
    MapConfig, IResourceSourceData, ConnectionsState } from "./common";
import * as Metadata from "../../entities/Metadata";
import { get, post, remove } from "../../fetch-interceptor";
import { defaultCatch, catchApiError } from "../utils";
import { AppThunkAction, AppThunkAction as IAppThunkAction } from "../index";
import { Reducer, Action } from 'redux';
import { ProjectsImportActions, Utils, ResourcesImportActions } from "./ImportStore";
import { IDeletionResult } from '../services/storeHelper';
import { RemovedPortfoliosSourceInfosAction } from '../PortfoliosListStore';
import { RemovedProjectsSourceInfosAction } from '../ProjectsListStore';
import { RemovedResourcesSourceInfosAction } from '../ResourcesListStore';

export enum SpoFieldType {
    Boolean = 'Boolean',
    Byte = 'Byte',
    DateTime = 'DateTime',
    Decimal = 'Decimal',
    Double = 'Double',
    Single = 'Single',
    Guid = 'Guid',
    Int16 = 'Int16',
    Int32 = 'Int32',
    Int64 = 'Int64',
    SByte = 'SByte',
    String = 'String'
}

export const spoFieldToPpmxFieldsMap: { [i: string]: MapConfig } = {
    [SpoFieldType.Boolean]: { types: [Metadata.FieldType.Flag], label: "Flag" },
    [SpoFieldType.DateTime]: { types: [Metadata.FieldType.Date], label: "Date" },

    [SpoFieldType.Guid]: { types: [Metadata.FieldType.Text], label: "Guid" },
    [SpoFieldType.String]: { types: [Metadata.FieldType.Text], label: "Text" },

    [SpoFieldType.Decimal]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [SpoFieldType.Double]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [SpoFieldType.Single]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [SpoFieldType.Int16]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [SpoFieldType.Int32]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [SpoFieldType.Int64]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [SpoFieldType.Byte]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [SpoFieldType.SByte]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
}

export interface ISpoResourceLinkInfo extends IResourceSourceData {
    resourceId: string,
    resourceNTAccount: string,
    email: string
}

export interface ISpoConnectionState extends IConnectionsState {
    refreshInfo: IConnectionRefreshInfo | null;
    mapping: { isLoading: boolean, entities: IFieldMapping[] | null },
    spoProjectFields: { isLoading: boolean, entities: ExternalFieldInfo[] },
    createdConnectionId?: string;
}

export interface IConnectionRefreshInfo {
    url: string;
    account: string;
    login: string;
    clientId: string;
}

export interface ISpoResource {
    id: string;
    displayName: string;
    email: string;
    isAlreadyLinked: boolean;
}

export interface ISpoProject {
    id: string;
    name: string;

    isAlreadyLinked: boolean;
}

export interface SpoState {
    connections: ISpoConnectionState;
    projects: IListStore<ISpoProject>;
    resources: IListStore<ISpoResource>;
}

export type LoadConnections = { type: "SPO_LOAD_CONNECTIONS" }
export type ReceivedConnections = { type: "SPO_RECEIVED_CONNECTIONS"; connections: Metadata.IConnectionInfo[]; }
type ConnectionCreatingOrRefreshing = { type: "SPO_CONNECTION_CREATING_OR_REFRESHING", id?: string }
type ConnectionCreatedOrRefreshed = { type: "SPO_CONNECTION_CREATED_OR_REFRESHED", connection: Metadata.IConnectionInfo, isRefreshing: boolean }
type RemovedConnection = { type: "SPO_CONNECTION_REMOVED", id: string }
type ReceivedConnectionRefreshInfo = { type: "SPO_RECEIVED_CONNECTION_REFRESH_INFO", info: IConnectionRefreshInfo }
type ReceivedConnectionMapping = { type: "SPO_RECEIVED_CONNECTION_MAPPING", mapping: IFieldMapping[] }
type LoadConnectionMapping = { type: "SPO_LOAD_CONNECTION_MAPPING" }
type ReceivedConnectionSpoProjectFields = { type: "SPO_RECEIVED_CONNECTION_SPO_PROJECT_FIELDS", fields: ExternalFieldInfo[] }
type LoadConnectionSpoProjectFields = { type: "SPO_LOAD_CONNECTION_SPO_PROJECT_FIELDS" }
type ConnectionOperationError = { type: "SPO_CONNECTION_OPERATION_ERROR", error: string | null }

type VerifyConnection = { type: 'SPO_VERIFY_CONNECTION', connectionId: string }
type ConnectionVerified = { type: 'SPO_CONNECTION_VERIFIED', connectionId: string }
type ConnectionVerificationError = { type: 'SPO_CONNECTION_VERIFICATION_ERROR', connectionId: string, error: string | null }

type ConnectionAction = LoadConnections
    | ReceivedConnections
    | ConnectionCreatingOrRefreshing
    | ConnectionCreatedOrRefreshed
    | ReceivedConnectionRefreshInfo
    | RemovedConnection
    | ConnectionOperationError
    | ReceivedConnectionMapping
    | LoadConnectionMapping
    | ReceivedConnectionSpoProjectFields
    | LoadConnectionSpoProjectFields
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError;

type LoadProjects = { type: "SPO_LOAD_PROJECTS" }
type ReceivedProjects = { type: "SPO_RECEIVED_PROJECTS", projects: ISpoProject[] }
type LoadResources = { type: "SPO_LOAD_RESOURCES" }
type ReceivedResources = { type: "SPO_RECEIVED_RESOURCES", resources: ISpoResource[] }
type SetProjectsError = { type: "SPO_SET_PROJECTS_ERROR", error: string | null }
type SetResourcesError = { type: "SPO_SET_RESOURCES_ERROR", error: string | null }
type EntitiesAction = LoadProjects | ReceivedProjects | LoadResources | ReceivedResources | SetProjectsError | SetResourcesError;

type KnownAction = ConnectionAction | EntitiesAction;

export interface ISpoConnectionInfo {
    id?: string;
    url: string;

    login: string;
    password: string;

    clientId: string;
    clientSecret: string;
    refreshToken: string;
}

export const actionCreators = {
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_LOAD_CONNECTIONS' });
        get<Metadata.IConnectionInfo[]>(`api/integration/spo/connection`)
            .then(_ => dispatch({ type: 'SPO_RECEIVED_CONNECTIONS', connections: _ }))
            .catch(defaultCatch(dispatch));
    },
    createOrRefreshConnection: (data: ISpoConnectionInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_CONNECTION_CREATING_OR_REFRESHING', id: data.id });
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/spo/connection`, data)
            .then(_ => {
                if (_.errorMessage) {
                    dispatch({ type: 'SPO_CONNECTION_OPERATION_ERROR', error: _.errorMessage });
                } else {
                    dispatch({ type: 'SPO_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: !!data.id });
                }
            })
            .catch(defaultCatch(dispatch));
    },
    renameConnection: (connectionId: string, title: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/spo/connection/${connectionId}/rename`, { title })
            .then(_ => dispatch({ type: 'SPO_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: true }))
            .catch(defaultCatch(dispatch));
    },
    removeConnection: (id: string, callback?: () => void):
        AppThunkAction<KnownAction | RemovedPortfoliosSourceInfosAction | RemovedProjectsSourceInfosAction | RemovedResourcesSourceInfosAction> => (dispatch, getState) => {
            dispatch({ type: 'SPO_CONNECTION_CREATING_OR_REFRESHING', id });
            remove<IDeletionResult>(`api/integration/spo/connection/${id}`)
                .then(_ => {
                    if (_.isDeleted) {
                        dispatch({ type: 'SPO_CONNECTION_REMOVED', id: _.id });
                        dispatch({ type: "REMOVED_PORTFOLIOS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_PROJECTS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_RESOURCES_SOURCE_INFOS", connectionId: _.id });
                        callback?.();
                    } else {
                        dispatch({ type: 'SPO_CONNECTION_OPERATION_ERROR', error: _.message || null });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    setError: (error: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_CONNECTION_OPERATION_ERROR', error });
    },
    cleanError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_CONNECTION_OPERATION_ERROR', error: null });
    },
    loadRefreshInfo: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_LOAD_CONNECTIONS' });
        get<IConnectionRefreshInfo>(`api/integration/spo/connection/${connectionId}/refresh`)
            .then(_ => dispatch({ type: 'SPO_RECEIVED_CONNECTION_REFRESH_INFO', info: _ }))
            .catch(defaultCatch(dispatch));
    },

    loadMapping: (connectionId: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_LOAD_CONNECTION_MAPPING' });
        get<IFieldMapping[]>(`api/integration/spo/connection/${connectionId}/mapping`)
            .then(_ => dispatch({ type: 'SPO_RECEIVED_CONNECTION_MAPPING', mapping: _ }))
            .catch(defaultCatch(dispatch));
    },
    updateMapping: (connectionId: string, mapping: IFieldMapping[]): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IFieldMapping[]>(`api/integration/spo/connection/${connectionId}/mapping`, mapping)
            .then(_ => dispatch({ type: 'SPO_RECEIVED_CONNECTION_MAPPING', mapping: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadSpoProjectFields: (connectionId: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_LOAD_CONNECTION_SPO_PROJECT_FIELDS' });
        get<ExternalFieldInfo[]>(`api/integration/spo/connection/${connectionId}/fields/project`)
            .then(_ => dispatch({ type: 'SPO_RECEIVED_CONNECTION_SPO_PROJECT_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SPO_CONNECTION_OPERATION_ERROR', error: `Unable to load Project Online fields: ${_}` })));
    },
    loadProjects: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_LOAD_PROJECTS' });
        get<ISpoProject[]>(`api/integration/spo/projects`, { connectionId })
            .then(_ => dispatch({ type: 'SPO_RECEIVED_PROJECTS', projects: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SPO_SET_PROJECTS_ERROR', error: `Unable to load Project Online projects: ${_}` })));
    },
    loadResources: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_LOAD_RESOURCES' });
        get<ISpoResource[]>(`api/integration/spo/resources`, { connectionId })
            .then(_ => dispatch({ type: 'SPO_RECEIVED_RESOURCES', resources: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SPO_SET_RESOURCES_ERROR', error: `Unable to load Project Online resources: ${_}` })));
    },
    importProjects: (connectionId: string, maps: IImportProjectMap<ISpoProject>[]): AppThunkAction<ProjectsImportActions> => (dispatch, getState) => {
        const entityIds = maps.map(_ => _.linkingData.id);
        dispatch({ type: 'IMPORT_PROJECTS_STARTED', entityIds });

        Utils.batchSend(maps,
            batch => post<IImportResult[]>('api/integration/spo/import/projects', { connectionId, maps: batch })
                .then(_ => dispatch({ type: 'IMPORT_PROJECTS_BATCH_FINISHED', results: _ })))
            .then(() => dispatch({ type: 'IMPORT_PROJECTS_FINISHED' }))
            .catch(defaultCatch(dispatch));
    },
    importResources: (connectionId: string, maps: IImportResourceMap<ISpoResource>[]): AppThunkAction<ResourcesImportActions> => (dispatch, getState) => {
        dispatch({ type: 'IMPORT_RESOURCES_STARTED' });
        Utils.sendByChunks(maps, chunk => post<IImportResult[]>('api/integration/spo/import/resources', { connectionId, maps: chunk }))
            .then(_ => dispatch({ type: 'IMPORT_RESOURCES_FINISHED', results: _ }))
            .catch(defaultCatch(dispatch));
    },
    verifyConnection: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SPO_VERIFY_CONNECTION', connectionId });
        get(`api/integration/spo/verify-connection`, { connectionId })
            .then(_ => dispatch({ type: 'SPO_CONNECTION_VERIFIED', connectionId }))
            .catch(catchApiError(_ => dispatch({ type: 'SPO_CONNECTION_VERIFICATION_ERROR', connectionId: connectionId, error: _ })));
    }
}
const initState: SpoState = {
    connections: {
        isLoading: false,
        isProcessing: false,
        data: [],
        refreshInfo: null,
        mapping: { isLoading: false, entities: [] },
        spoProjectFields: { isLoading: false, entities: [] },
        error: null,
        connectionsVerification: { }
    },
    projects: {
        entities: [],
        isLoading: false,
        error: null
    },
    resources: {
        entities: [],
        isLoading: false,
        error: null
    }
}

const connectionsReducer: Reducer<ISpoConnectionState> = (state: ISpoConnectionState, incomingAction: Action) => {
    const action = incomingAction as ConnectionAction;
    switch (action.type) {
        case 'SPO_LOAD_CONNECTIONS':
            {
                return {
                    ...state,
                    isLoading: true,
                    connectionsVerification: {}
                }
            }
        case 'SPO_RECEIVED_CONNECTIONS':
            {
                return {
                    ...state,
                    data: action.connections,
                    connectionsVerification: {},
                    isLoading: false
                }
            }
        case 'SPO_CONNECTION_CREATING_OR_REFRESHING':
            {
                return {
                    ...state,
                    isProcessing: true,
                    connectionsVerification: ConnectionsState.remove(state, action.id)
                }
            }
        case "SPO_CONNECTION_CREATED_OR_REFRESHED":
            {
                return {
                    ...state,
                    data: action.isRefreshing
                        ? state.data.map(_ => _.id === action.connection.id ? action.connection : _)
                        : state.data.concat(action.connection),
                    error: null,
                    createdConnectionId: action.isRefreshing ? undefined : action.connection.id,
                    isProcessing: false
                }
            }
        case "SPO_CONNECTION_REMOVED":
            {
                return {
                    ...state,
                    data: state.data.filter(_ => _.id !== action.id),
                    connectionsVerification: ConnectionsState.remove(state, action.id),
                    error: null,
                    isProcessing: false
                }
            }
        case "SPO_RECEIVED_CONNECTION_REFRESH_INFO":
            {
                return {
                    ...state,
                    isLoading: false,
                    refreshInfo: action.info
                }
            }
        case "SPO_RECEIVED_CONNECTION_MAPPING":
            {
                return {
                    ...state,
                    mapping: {
                        entities: action.mapping,
                        isLoading: false
                    },
                }
            }
        case "SPO_LOAD_CONNECTION_MAPPING":
            {
                return {
                    ...state,
                    mapping: {
                        entities: state.mapping.entities,
                        isLoading: true
                    },
                }
            }
        case "SPO_RECEIVED_CONNECTION_SPO_PROJECT_FIELDS":
            {
                return {
                    ...state,
                    spoProjectFields: {
                        entities: action.fields,
                        isLoading: false
                    },
                }
            }
        case "SPO_LOAD_CONNECTION_SPO_PROJECT_FIELDS":
            {
                return {
                    ...state,
                    spoProjectFields: {
                        entities: [],
                        isLoading: true
                    },
                }
            }
        case "SPO_CONNECTION_OPERATION_ERROR":
            {
                return {
                    ...state,
                    error: action.error,
                    spoProjectFields: { isLoading: false, entities: [] },
                    isProcessing: false
                }
            }
        case "SPO_VERIFY_CONNECTION":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationStarting(state, action.connectionId)
                }
            }
        case "SPO_CONNECTION_VERIFIED":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFinished(state, action.connectionId)
                }
            }
        case "SPO_CONNECTION_VERIFICATION_ERROR":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFailed(state, action.connectionId, action.error)
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}


const entitiesReducer: Reducer<SpoState> = (state: SpoState, incomingAction: Action) => {
    const action = incomingAction as EntitiesAction;
    switch (action.type) {
        case 'SPO_LOAD_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'SPO_RECEIVED_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: action.projects,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'SPO_SET_PROJECTS_ERROR':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'SPO_LOAD_RESOURCES':
            {
                return {
                    ...state,
                    resources: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'SPO_RECEIVED_RESOURCES':
            {
                return {
                    ...state,
                    resources: {
                        entities: action.resources,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'SPO_SET_RESOURCES_ERROR':
            {
                return {
                    ...state,
                    resources: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

export const reducer: Reducer<SpoState> = (state: SpoState = initState, incomingAction: Action) => {
    return {
        ...entitiesReducer(state, incomingAction),
        connections: connectionsReducer(state.connections, incomingAction)
    }
}