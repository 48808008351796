import * as React from "react";
import { ActionButton, Icon } from 'office-ui-fabric-react';
import { SourceType, SourceTypeMap, SourceType_ } from "../../../store/ExternalEpmConnectStore";
import { IConnectionInfo } from "../../../entities/Metadata";
import MoreConnectionsMenu from "./MoreConnectionsMenu";
import { NotEnoughPermissionsMessage } from "../../../store/permissions";

type Props = {
    system: SourceType;
    connections: IConnectionInfo[];
    canConnect: boolean;
    canImport: boolean;
    onConnect: () => void;
    onRestrictedConnect?: () => void;
    onImport: (connectionId: string) => void;
    newConnectionTooltip?: JSX.Element;
    newRestrictedConnectionTooltip?: JSX.Element;
}
const ConnectionCard = (props: Props) => {
    const [connectionId, setConnectionId] = React.useState<string | undefined>(props.connections.slice(-1)[0]?.id);
    React.useEffect(() => {
        setConnectionId(props.connections.slice(-1)[0]?.id);
    }, [props.connections]);
    return <>
        <div className="connection-card">
            {connectionId && <MoreConnectionsMenu
                connections={props.connections}
                selectedConnectionId={connectionId}
                canConnect={props.canConnect}
                canSelect={props.canImport}
                onConnect={props.onConnect}
                onRestrictedConnect={props.onRestrictedConnect}
                newConnectionTooltip={props.newConnectionTooltip}
                newRestrictedConnectionTooltip={props.newRestrictedConnectionTooltip}
                onSelect={(connId: string) => {
                    setConnectionId(connId);
                    props.onImport(connId);
                }}
            />}
            <div className="connection-body">
                <Icon className={`connection-logo ${!connectionId ? 'disabled' : ''}`}
                    title={!connectionId ? NotEnoughPermissionsMessage : undefined}
                    iconName={SourceType_.getIconName(props.system)} />
                <div className="connection-details">
                    <div className="connection-name">{SourceTypeMap[props.system]}</div>
                    {!connectionId
                        ? <div className="connection-status">Connection <Icon iconName="Cancel" /></div>
                        : <div className="connection-status ok">Connected <Icon iconName="Accept" /></div>}
                </div>
            </div>
            {!connectionId
                ? <ActionButton primary className="connection-action hyperlink-color" text="Create connection" iconProps={{ iconName: "PPMXLink" }}
                    disabled={!props.canConnect} title={!props.canConnect ? NotEnoughPermissionsMessage : undefined} onClick={props.onConnect} />
                : <ActionButton className="connection-action hyperlink-color" text="Import Projects" iconProps={{ iconName: "PPMXImport" }}
                    disabled={!props.canImport} title={!props.canImport ? NotEnoughPermissionsMessage : undefined} onClick={() => props.onImport(connectionId!)} />}
        </div>
    </>
}
export default ConnectionCard;