import { DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { CopyIcon } from "../../../utils/copy";
import * as NotificationsStore from "../../../../store/NotificationsStore";
import * as Notifications from "../../../../store/NotificationsStore";
import { connect } from "react-redux";
import { UserState } from "../../../../store/User";
import { post } from "../../../../fetch-interceptor";
import * as analytics from '../../../../analytics';
import { ApplicationState } from "../../../../store";
import { bindActionCreators } from "redux";
import { IUserProps } from "../../../common/inputs/PPMXSharePeoplePicker";
import { AiDigest } from "../common";

type OwnProps = {
    projectId: string;
    isHtmlViewSelected: boolean;
    aiDigest: AiDigest;
    isDigestLoading: boolean;
    selectedUsers: IUserProps[];
    setSelectedUsers: (users: IUserProps[]) => void;
    onCancel: () => void;
    onBack: () => void;
}

type ActionProps = {
    notificationActions: typeof NotificationsStore.actionCreators;
}

type StateProps = {
    user: UserState;
};

type Props = OwnProps & StateProps & ActionProps;

const AIDigestShareFooter = (props: Props) => {
    const { projectId, aiDigest, isHtmlViewSelected, selectedUsers, isDigestLoading, user, notificationActions,
        setSelectedUsers, onCancel, onBack } = props;

    const onShare = () => {
        const sender = { email: user.email, fullName: user.name };
        const recepients = selectedUsers.map(_ => ({ email: _.email, fullName: _.text })).concat([sender]);
        analytics.trackEvent("Share AI Digest", user);
        return post(`/api/ai/project/${projectId}/sendDigest`, { 
                digest: isHtmlViewSelected ? aiDigest.html : aiDigest.digest,
                sender,
                recepients,
                isHtml: isHtmlViewSelected
            })
            .then(() => {
                setSelectedUsers([]);
                notificationActions.pushNotification({ message: 'An email with a project digest was sent.', type: Notifications.NotificationType.Success });
            })
            .catch(() => {
                notificationActions.pushNotification({ message: 'Unable to send an email with a project digest.', type: Notifications.NotificationType.Error });
            })
    }

    return <>
        <div className="panel-buttons">
            <PrimaryButton
                text="Send"
                disabled={(isHtmlViewSelected ? !aiDigest.html : !aiDigest.digest)
                    || isDigestLoading
                    || !selectedUsers.length
                    || !!aiDigest.errorMessage}
                onClick={onShare}
            />
            <DefaultButton
                text="Cancel"
                onClick={onCancel}
            />
            <DefaultButton
                text='Back'
                onClick={onBack}
            />
            {isHtmlViewSelected && <CopyIcon 
                className="align-right" text={aiDigest.html ?? ""} 
                withFormat 
                disabled={isDigestLoading || !!aiDigest.errorMessage}/>}
        </div>
    </>
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return { user: state.user };
}

export default connect(mapStateToProps, (dispatch): ActionProps => ({
    notificationActions: bindActionCreators(NotificationsStore.actionCreators, dispatch)
}))(AIDigestShareFooter)