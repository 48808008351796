import React, { createRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './GroupedSelectedFieldsList.css';
import * as Metadata from '../../../../entities/Metadata';
import { arraysEqual, notEmpty, notUndefined, toDictionaryByKey, toDictionaryByName } from '../../../utils/common';
import FieldPanel, { IFieldActions } from '../../FieldPanel';
import ManageFieldCard from '../ManageFieldCard';
import { Dictionary, EntityType } from '../../../../entities/common';
import GroupedDraggableList, { GroupedDraggableItems } from '../../../common/GroupedDraggableList';
import { getKey, getOrdered, useGetSelectedIndex, useScrollToNewField } from '../FieldsList';

type GroupedFieldNames = {
    group: string;
    fields: string[];
};

export type GroupedFields = {
    group: string;
    fields: Metadata.Field[];
};

type Props = {
    fields: Metadata.Field[];
    selected: GroupedFieldNames[];
    entityType: EntityType;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    newFields?: string[];
    className?: string;
    onChange?: (fields: GroupedFields[]) => void;
    onGroupRender: (groupedItems: GroupedDraggableItems<Metadata.Field>, onGroupedItemsRender: () => JSX.Element | JSX.Element[]) => JSX.Element;
    onEmptyGroupItemsRender: (group: string) => JSX.Element;
}

export const GroupedSelectedFieldsList = (props: Props) => {    
    const flattenedSelected = useMemo(() => props.selected.flatMap(_ => _.fields), [props.selected]);
    
    const getOrderedFields = useCallback(() => props.selected.map(_ => ({
        group: _.group,
        fields: getOrdered(props.fields.filter(__ => _.fields.includes(__.name)), _.fields),
    })), [props.selected, props.fields]);

    const [orderedFields, setOrderedFields] = useState(getOrderedFields());
    const getSelectedIndex = useGetSelectedIndex(flattenedSelected);
    
    const onChange = useCallback((field: Metadata.Field, checked: boolean, destinationGroup: string, selectedIndex?: number) => {
        const groupedFieldNames = props.selected.map<GroupedFieldNames>(_ => ({
            group: _.group,
            fields: _.fields.filter(_ => _ !== field.name),
        }));

        if (checked) {
            const destinationGroupFields = groupedFieldNames.find(_ => _.group === destinationGroup)!.fields;
            destinationGroupFields.splice(selectedIndex!, 0, field.name);
        }
        
        const fieldsMap = toDictionaryByName(props.fields);
        const fields = groupedFieldNames.map<GroupedFields>(_ => ({
            group: _.group,
            fields: _.fields.map(__ => fieldsMap[__]).filter(notUndefined),
        })).filter(notEmpty)

        props.onChange!(fields);
    }, [props.onChange, props.selected]);

    const onOrderChanged = useCallback((changedGroupedItems: GroupedDraggableItems<Metadata.Field>[], destinationGroup: string, field: Metadata.Field) => {
        const changedOrderMap = toDictionaryByKey(changedGroupedItems, 'group');
        const reordered = orderedFields.map(_ => ({ ..._, fields: changedOrderMap[_.group]?.items ?? _.fields }));
        // for smooth fields reorder
        setOrderedFields(reordered);
        const reorderedGroupFields = reordered.find(_ => _.group === destinationGroup)!.fields;
        const index = getSelectedIndex(field, reorderedGroupFields)
        onChange(field, true, destinationGroup, index);
    }, [orderedFields, getSelectedIndex, onChange]);

    const refs = useRef<Dictionary<RefObject<HTMLDivElement>>>({});
    const onItemRender = useCallback((field: Metadata.Field, group: string) =>
        <ManageFieldCard
            ref={refs.current[field.name] || (refs.current[field.name] = createRef())}
            selected={flattenedSelected.includes(field.name)}
            field={field}
            isNew={!!props.newFields?.includes(field.name)}
            allowManage={props.allowManageFields}
            index={getSelectedIndex(field, orderedFields.find(_ => _.group === group)!.fields)}
            onChange={props.onChange ? (field, checked, ind) => onChange(field, checked, group, ind) : undefined}
            actions={props.fieldActions}
            onEditClick={onEditClick}
        />,
        [props.allowManageFields, orderedFields, flattenedSelected, props.newFields, props.onChange]);

    useEffect(() => {
        const newOrder = getOrderedFields();
        if (!arraysEqual(orderedFields, newOrder)) {
            setOrderedFields(newOrder);
        }
    }, [getOrderedFields]);

    useScrollToNewField(props.newFields, flattenedSelected, refs);

    const [editField, setEditField] = useState<Metadata.Field | null>(null);
    const onEditClick = useCallback((field) => setEditField(field), []);
    const resetEditing = useCallback(() => setEditField(null), []);

    return (
        <div className={`configure-fields ${props.className}`}>
            <GroupedDraggableList
                groupedItems={orderedFields.map(_ => ({ group: _.group, items: _.fields }))}
                onItemRender={onItemRender}
                isItemDraggable={() => true}
                onChanged={props.onChange ? onOrderChanged : undefined}
                getKey={getKey}
                onGroupRender={props.onGroupRender}
                onEmptyGroupItemsRender={props.onEmptyGroupItemsRender}
            />
            {
                // editing can't be made in the field card because the drag and drop issue
                // react dnd (used for drag and drop) doesn't allow to drag and drop component inside other draggable
                // that means select field option reorder won't work if panel declared inside the card which is draggable
                editField && props.fieldActions && (
                    <FieldPanel
                        entityType={props.entityType}
                        field={editField}
                        allowManageFields={props.allowManageFields}
                        actions={props.fieldActions}
                        onDismiss={resetEditing}
                    />
                )
            }
        </div>
    );
}
