import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { HubConnection } from '@microsoft/signalr';

type OwnProps = {events: {name: string, callback:(...args: any[]) => any}[]};
type StateProps = {signalRConnection: HubConnection | null};
type Props = OwnProps & StateProps;

const WithSignalR = (props: React.PropsWithChildren<Props>) => {
    props.events.forEach(_ =>
        React.useEffect(() => {
            props.signalRConnection?.on(_.name, _.callback);
            return () => props.signalRConnection?.off(_.name, _.callback);
        })
    );

    return <>{props.children}</>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        signalRConnection: state.sirnalR.connection
    };
}

export default connect(mapStateToProps)(WithSignalR)