import * as React from 'react';

interface ITdProps {
    className?: string;
    minWidth: number;
    maxWidth?: number;
    style?: React.CSSProperties;
}

export default class Td extends React.Component<ITdProps> {
    private _td: HTMLTableDataCellElement | null = null;
    private _width: number | undefined = undefined;

    public render() {
        return <td
            ref={_ => this._td = _}
            className={this.props.className}
            style={{
                minWidth: this.props.minWidth,
                maxWidth: this.props.maxWidth ?? this._width,
                ...(this.props.style ?? {})
            }}>
            {this.props.children}
        </td>;
    }

    componentDidMount() {
        if (!this._td) {
            return;
        }

        const rect = this._td.getBoundingClientRect();
        this._width = rect.width ?? (rect.right - rect.left);
        this._td.style.maxWidth = `${this._width}px`;
    }
}