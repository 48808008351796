import * as React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'office-ui-fabric-react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Idea, IdeaStage, actionCreators } from "../../../../../store/IdeasListStore";
import { ApplicationState } from '../../../../../store';
import { isInReadonlyMode } from '../../../../../store/User';
import { useDebounce } from '../../../../utils/effects';

type OwnProps = IListViewFieldColumn<Idea>;
type StateProps = {
    isReadonlyMode: boolean;
};

type ActionProps = typeof actionCreators;

type Props = OwnProps & StateProps & ActionProps;

export const Votes = (props: Props) => {
    return <VotesToggleView
        disabled={!props.entity.canCollaborate || props.entity.attributes.Stage !== IdeaStage.Active}
        isVoted={props.entity.isCurentUserVoted}
        votesCount={getValue(props.entity)}
        onChange={(isVoted) => props.changeVote(props.entity!.id, isVoted)}
    />;
}

export const getValue = (entity: Idea): number => entity.votes;
export const getSearchValue = (entity: Idea): number => getValue(entity);

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

export default connect(mapStateToProps, actionCreators)(Votes);

type VotesToggleViewProps = {
    disabled: boolean;
    votesCount: number;
    isVoted: boolean;
    onChange: (isVoted: boolean) => void;
}

const VotesToggleView = (props: VotesToggleViewProps) =>
    <span className={`votes-view ${props.disabled ? 'disabled' : ''}`}
        onClick={props.disabled ? undefined : useDebounce(_ => props.onChange(!props.isVoted), 500)} >
        <Icon className={props.disabled ? 'disabled' : ''}
            iconName={props.isVoted ? 'LikeSolid' : 'Like'}
        />
        <span className="progress-tip">{props.votesCount}</span>
    </span>;
