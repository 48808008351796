import "./OrganizationAuthenticationSettings.css";
import * as React from 'react';
import * as analytics from '../../../../analytics';
import { MessageBar, MessageBarType, Link, Toggle } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { UserState, UserStatus } from '../../../../store/User';
import { CommonOperations, contains } from '../../../../store/permissions';
import { actionCreators as tenantActionCreators, checkIfADUsersSyncEnabled, SecuritySettings, TenantState } from '../../../../store/Tenant';
import { ApplicationState } from '../../../../store';
import LabellableComponent from '../../../common/LabellableComponent';
import OrganizationLinkPanel from "./OrganizationLinkPanel";
import { SourceType } from "../../../../store/ExternalEpmConnectStore";
import { AuthProvider, AuthProvidersMap } from "../../../../entities/common";
import { distinct } from "../../../utils/common";
import { IUser, actionCreators as usersActionCreators } from "../../../../store/UsersListStore";
import { AlertDialog } from "../../../common/AlertDialog";
import AuthProviderSelect from "../../../user/AuthProviderSelect";

type OwnProps = {};
type StateProps = {
    user: UserState;
    tenant: TenantState;
    security: SecuritySettings;
    isLoading: boolean;
    canManage: boolean;
    isADUsersSyncEnabled: boolean;
    isLinkedToOrganization: boolean;
    authProviders: AuthProvider[];
    activeUsers: IUser[];
}

type Props = StateProps & OwnProps & {
    tenantActions: typeof tenantActionCreators;
    usersActions: typeof usersActionCreators;
};

export const hasLinkedOrganization = function (tenant: TenantState) {
    return tenant.externalAuthProvider !== AuthProvider.email;
}
const OrganizationAuthenticationSettings = (props: Props) => {
    React.useEffect(() => {
        props.usersActions.loadUsers();
    }, [])

    const [state, setState] = React.useState(props.security);
    React.useEffect(() => { setState(props.security) }, [props.security]);
    const { allowAutoRegistration, isAutoRegistrationDisabled, allowEveryoneToInviteUsers, allowedAuthProviders, defaultAuthProvider } = state;
    const { tenant, canManage, isADUsersSyncEnabled, isLinkedToOrganization, authProviders } = props;

    const saveSettings = React.useCallback((settings) => {
        setState(settings);
        props.tenantActions.updateSecurity(settings);
    }, []);
    const onChangedAllowAutoRegistration = React.useCallback((e, _) => {
        saveSettings({ ...state, allowAutoRegistration: !!_ });
        analytics.trackToggle("Auto registration updated", props.user, !!_);
    }, [allowAutoRegistration, state]);
    const onChangedAllowEveryoneToInviteUsers = React.useCallback((e, _) => {
        saveSettings({ ...state, allowEveryoneToInviteUsers: !!_ });
        analytics.trackToggle("Open Invites updated", props.user, !!_);
    }, [allowEveryoneToInviteUsers, state]);

    const onChangedAllowedAuthProviders = React.useCallback((e, _: AuthProvider[]) => {
        if (!!_?.length) {
            const update = {
                allowedAuthProviders: _,
                defaultAuthProvider: _.indexOf(defaultAuthProvider) > -1 ? defaultAuthProvider : undefined
            }
            saveSettings({ ...state, ...update });
            analytics.trackEvent("Allowed Auth Providers updated", props.user, update);
        }
    }, [allowedAuthProviders, state]);

    const onChangedDefaultAuthProvider = React.useCallback(_ => {
        const update = { defaultAuthProvider: _ };
        saveSettings({ ...state, ...update });
        analytics.trackEvent("Default Auth Provider updated", props.user, update);
    }, [state]);

    const [showProviderInUseMessage, setShowProviderInUseMessage] = React.useState(false);
    const authProvidersInUse = React.useMemo(() => props.activeUsers.map(_ => _.authProvider).filter(distinct), [props.activeUsers]);

    const _renderAuthProvider = (provider: AuthProvider) => {
        const isLastEnabledProvider = allowedAuthProviders.length === 1 && allowedAuthProviders[0] === provider;
        const isInUse = authProvidersInUse.indexOf(provider) > -1;
        const isEnabled = allowedAuthProviders.indexOf(provider) > -1;
        return <Toggle
            key={provider}
            label={AuthProvidersMap[provider].friendlyName}
            checked={isEnabled}
            disabled={isLastEnabledProvider}
            onChange={(e: any, c?: boolean) => {
                if (!!c) {
                    onChangedAllowedAuthProviders(e, [...allowedAuthProviders, provider]);
                } else {
                    if (isInUse) {
                        setShowProviderInUseMessage(true);
                    } else {
                        onChangedAllowedAuthProviders(e, allowedAuthProviders.filter(_ => _ !== provider));
                    }
                }
            }}
        />;
    }

    const [showLinkOrganisation, setShowLinkOrganisation] = React.useState<boolean>();
    const toggleShowLinkOrganisation = React.useCallback(() => setShowLinkOrganisation(!showLinkOrganisation), [showLinkOrganisation]);

    return <div className="sync-settings organization-authentication-settings">
        {canManage && !isLinkedToOrganization && <MessageBar isMultiline={true} messageBarType={MessageBarType.warning}>
            To enable Office 365 auto-registration for your PPM Express
            tenant, <Link className="inline-link" onClick={toggleShowLinkOrganisation}>link your tenant with Office 365 organization.</Link>
        </MessageBar>}

        <LabellableComponent className="field-container" label="Permitted organization for auto registration">
            {isLinkedToOrganization && tenant.externalAuthProvider
                ? `${AuthProvidersMap[tenant.externalAuthProvider].friendlyName} (id: ${tenant.externalId})`
                : "-"}
        </LabellableComponent>

        {isADUsersSyncEnabled && <MessageBar messageBarType={MessageBarType.warning}>
            Access Settings are managed by Azure Active Directory.</MessageBar>}
        <LabellableComponent className="field-container" label="Access Settings">
            <Toggle
                label='Auto Registration'
                disabled={!canManage || isADUsersSyncEnabled || isAutoRegistrationDisabled || !isLinkedToOrganization}
                checked={allowAutoRegistration}
                onChange={onChangedAllowAutoRegistration}
            />
            <div className="toggle-action-description">Users from the same authentication provider's tenant can sign up without invitation</div>
            <Toggle
                label='Open Invites'
                disabled={!canManage || isADUsersSyncEnabled}
                checked={allowEveryoneToInviteUsers}
                onChange={onChangedAllowEveryoneToInviteUsers}
            />
            <div className="toggle-action-description">
                All PPM Express users can invite other people. If this option is off, then only PPM Express Administrators can send invites
            </div>
        </LabellableComponent>
        <LabellableComponent className="field-container" label="Permitted Authentication Providers">
            <div className="field-container-description">
                Only those authentication providers that are turned on for the organization will appear on the Log in to PPM Express page for the tenant.
            </div>
            {authProviders.map(_renderAuthProvider)}
            {showProviderInUseMessage && <AlertDialog
                dialogContentProps={{
                    title: 'Authentication Provider is in use',
                    subText: `There are users with Active and/or Pending Invite status who use this authentication provider to log in to PPM Express. \
Before turning off this provider, it is necessary to:
- deactivate all users who use this provider with Active status;
- delete all users with Invite Pending status with this authentication provider defined in the System field or Change the Auth Provider to a different one.`
                }}
                onDismiss={() => setShowProviderInUseMessage(false)} />}
        </LabellableComponent>
        <LabellableComponent className="field-container" label="Default authentication provider for invitations"
            description={`The default authentication provider is automatically pre-selected in the Required Authentication Provider field for new invitations. \
PPM Express Administrators have the flexibility to change the authentication provider when inviting new users. For all other invitations, the default authentication \
provider will be pre-selected, without the ability to change it.`}>
            <AuthProviderSelect
                disabled={!canManage}
                authProvider={defaultAuthProvider}
                onChange={onChangedDefaultAuthProvider}
            ></AuthProviderSelect>
        </LabellableComponent>
        {showLinkOrganisation && <OrganizationLinkPanel
            dismissPanel={toggleShowLinkOrganisation}
            sources={[SourceType.O365User]}
            actions={{ [SourceType.O365User]: { link: props.tenantActions.linkToO365Organization } }}
        />}
    </div>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        authProviders: state.enviroment.authProviders,
        activeUsers: state.users.allIds.map(_ => state.users.byId[_])
            .filter(_ => _.status !== UserStatus.Inactive),
        isLoading: state.tenant.isLoading,
        security: state.tenant.security!,
        canManage: contains(state.user.permissions.common, CommonOperations.Administrate),
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
        isLinkedToOrganization: hasLinkedOrganization(state.tenant),
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch),
        usersActions: bindActionCreators(usersActionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(OrganizationAuthenticationSettings);