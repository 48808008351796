import * as React from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';
import { EntityType, entityTypeLabelMap } from '../../entities/common';

type OwnProps = {
    entityType: EntityType;
    layoutName: string;
    count?: number;
    onConfirm: () => void;
    onDismiss: () => void;
}

const ApplyLayoutConfirmationDialog = (props: OwnProps) => <ConfirmationDialog
    onDismiss={props.onDismiss}
    onYes={props.onConfirm}
    onNo={() => { }}
    yesButtonProps={{ text: "Confirm" }}
    noButtonProps={{ text: "Cancel" }}
    dialogContentProps={{
        title: "Apply Layout"
    }}>
    <div>Are you sure you want to apply a layout '{props.layoutName}' to {props.count === undefined
        ? `the current ${entityTypeLabelMap[props.entityType].singular.toLowerCase()}`
        : props.count > 1
            ? `selected ${entityTypeLabelMap[props.entityType].plural.toLowerCase()} (${props.count} items)`
            : `the selected ${entityTypeLabelMap[props.entityType].singular.toLowerCase()}`}?</div>
    <p>When the layout is applied, the configuration of sections and fields added to this layout will be copied over to the 'Default' view of  {props.count && props.count > 1
        ? `the ${entityTypeLabelMap[props.entityType].plural.toLowerCase()}`
        : `this ${entityTypeLabelMap[props.entityType].singular.toLowerCase()}`}.</p>
</ConfirmationDialog>;
export default ApplyLayoutConfirmationDialog;