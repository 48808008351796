import * as React from 'react';
import { Portfolio, portfolioStagesMap } from '../../store/PortfoliosListStore';
import { ProjectInfo } from '../../store/ProjectsListStore';
import { IRow } from "../common/timeline/TimelineList";
import { ITimelineSegment, Visibility } from '../common/timeline/TimelineSegment';
import { ITimelineMarker } from '../common/timeline/TimelineMarker';
import * as ProjectsTimeline from '../project/timeline';
import * as ProgramsTimeline from '../program/timeline';
import { min, minMax } from '../common/timeline/utils';
import { StatusCategoryClassMap } from '../../entities/Subentities';
import Logo from '../common/Logo';
import PortfolioTooltipContent from "./PortfolioTooltipContent";
import { distinct, notEmpty, toDate } from '../utils/common';
import { StatusCategory, EntityType, statusCategoryMap } from '../../entities/common';
import { Program } from '../../store/ProgramsListStore';
import { KeyDateTypeToMarkerTypeMap } from '../keyDate/timeline';
import { Field } from '../../entities/Metadata';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

export const portfolioRowType = 'portfolio';

export function renderSegmentContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    if (row.rowType !== portfolioRowType || !segment.addContent) {
        return undefined;
    }

    const programs = row.subItems?.filter(_ => _.rowType === ProgramsTimeline.programRowType);
    const projects = row.subItems?.filter(_ => _.rowType !== ProgramsTimeline.programRowType);

    return <>
        <Logo imageId={(row.entity as Portfolio).imageId} className="pf-logo" />
        <div className="subitems-counter">
            {!!programs?.length && <>
                {programs.length} <span className="extra">program{programs.length !== 1 ? 's' : ''}</span>&nbsp;/&nbsp;</>}
            {projects!.length} <span className="extra">project{projects!.length !== 1 ? 's' : ''}</span>
        </div>
    </>;
}

export function renderSegmentTooltipContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    return row.rowType === portfolioRowType
        ? <PortfolioTooltipContent portfolio={row.entity as Portfolio} />
        : undefined;
}

export function buildTimelineItem(
    portfolio: Portfolio,
    programs: Program[],
    projects: ProjectInfo[],
    portfolioFields: Field[],
    programFields: Field[],
    projectFields: Field[],
    keyDateFields: Field[],
    datesVisibility?: Visibility
): IRow {
    const pfPgs = portfolio.programIds
        .map(programId => programs.find(_ => _.id === programId))
        .filter(notEmpty);

    const pfPrograms = pfPgs.map(_ => ProgramsTimeline.buildTimelineItem(_, projects, programFields, projectFields, keyDateFields, datesVisibility));

    const pfProjects = pfPgs.reduce((arr, cur) => [...arr, ...cur.projectIds], portfolio.projectIds)
        .filter(distinct)
        .map(projectId => projects.find(_ => _.id === projectId))
        .filter(notEmpty)
        .map(_ => ProjectsTimeline.buildTimelineItem(_, projectFields, keyDateFields, datesVisibility));

    const keyDateStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.KeyDate, keyDateFields)!;
    const pfMarkers = portfolio.keyDates
        .filter(__ => __.attributes.ShowOnTimeline)
        .map<ITimelineMarker>(__ => {
            const statusCategory = keyDateStatusDescriptor.getCategoryOrDefault(__.attributes.Status, StatusCategory.NA);
            return {
                key: __.id,
                date: toDate(__.attributes.Date)?.getBeginOfDay()!,
                className: StatusCategoryClassMap[statusCategory],
                markerType: KeyDateTypeToMarkerTypeMap[__.attributes.Type]
            }
        });

    const startDate = toDate(portfolio.attributes.StartDate);
    const finishDate = toDate(portfolio.attributes.FinishDate);

    const timeframe = ProjectsTimeline.getMinimalTimeframe(startDate, finishDate);
    const allDates = [
        timeframe.start,
        timeframe.end,
        ...pfMarkers.map(_ => _.date),
        ...pfProjects.map(_ => _.segments.map(__ => [__.startDate, __.finishDate]).reduce((a, b) => a.concat(b), [])).reduce((a, b) => a.concat(b), []),
        ...pfPrograms.map(_ => _.segments.map(__ => [__.startDate, __.finishDate]).reduce((a, b) => a.concat(b), [])).reduce((a, b) => a.concat(b), [])
    ];
    const { minDate, maxDate } = minMax(allDates);

    let pfSegments: ITimelineSegment[] = [];
    const className = getPortfolioClassName(portfolio, portfolioFields);
    if (minDate !== startDate || maxDate !== finishDate) {
        pfSegments = [...pfSegments, {
            key: 'min-to-max',
            startDate: minDate?.getBeginOfDay()!,
            finishDate: maxDate?.getEndOfDay()!,
            className: `min-to-max ${className}`
        }];
    }

    if (startDate && finishDate) {
        pfSegments = [...pfSegments, {
            key: 'start-to-finish',
            startDate: startDate.getBeginOfDay()!,
            finishDate: finishDate.getEndOfDay()!,
            className: `start-to-finish ${className}`,
            datesVisibility: datesVisibility
        }];
        const today = new Date();
        if (startDate < today) {
            pfSegments = [...pfSegments, {
                key: 'start-to-progress',
                startDate: startDate.getBeginOfDay(),
                finishDate: min([finishDate, new Date()])?.getEndOfDay()!,
                className: `start-to-progress ${className}`
            }];
        }
    }

    pfSegments[pfSegments.length - 1].addContent = true;

    return {
        key: portfolio.id,
        entity: portfolio,
        segments: pfSegments,
        markers: pfMarkers,
        subItems: [...pfPrograms, ...pfProjects],
        rowType: portfolioRowType
    };
}

function getPortfolioClassName(portfolio: Portfolio, portfolioFields: Field[]): string | undefined {
    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Portfolio, portfolioFields)!;
    const statusOption = statusDescriptor.getOptionOrDefault(portfolio.attributes.OverallStatus, StatusCategory.NA);
    const status = statusCategoryMap[statusOption.category];
    const stage = portfolioStagesMap[portfolio.attributes.Stage];
    return `portfolio ${stage ? stage.cssClassName : ''} ${status ? status.cssClassName : ''}`
}