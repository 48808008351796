import * as React from 'react';
import { MarqueeSelection, EventGroup, IMarqueeSelectionProps, Async } from 'office-ui-fabric-react';

type Props = IMarqueeSelectionProps & { onSelectionStarted: () => void; onSelectionStopped: () => void; };
export default class MarqueeSelectionWrapper extends React.Component<Props> {
    private _async: Async;
    private _events: EventGroup;
    private _selectionStarted: boolean;

    constructor(props: Props) {
        super(props);

        this._async = new Async(this);
        this._events = new EventGroup(this);
        this._selectionStarted = false;
    }

    render() {
        return <MarqueeSelection
            onShouldStartSelection={(e) => {
                this._events.on(window, 'mousemove', this._onMouseMove, true);
                this._events.on(window, 'mouseup', this._onMouseUp, true);

                return true;
            }}
            {...this.props} />
    }

    public componentWillUnmount(): void {
        this._events.dispose();
        this._async.dispose();
    }

    private _onMouseMove(ev: MouseEvent): boolean | undefined {
        if (ev.buttons !== undefined && ev.buttons === 0) {
            this._onMouseUp(ev);
        }
        if (!this._selectionStarted) {
            this._selectionStarted = true;
            this.props.onSelectionStarted();
        }
        return false;
    }

    private _onMouseUp(ev: MouseEvent): void {
        this._events.off(window);

        if (this._selectionStarted) {
            this._selectionStarted = false;
            this.props.onSelectionStopped();
        }
    }
}