import * as React from 'react';
import './PrimaryScheduleSection.css';
import { ChoiceGroup, IChoiceGroupOption, Icon, Link, TooltipDelay, TooltipHost } from "office-ui-fabric-react";
import { SourceType, SourceType_ } from "../../../store/ExternalEpmConnectStore";

export interface IChoiceGroupOptionExt extends IChoiceGroupOption {
    description?: JSX.Element
};

export const onRenderField = (props?: IChoiceGroupOptionExt, defaultRender?: (props?: IChoiceGroupOption) => JSX.Element | null): JSX.Element | null =>
(<>
    {defaultRender?.(props)}
    {props?.description && <p>{props.description}</p>}
</>);

const onRenderLabel = (props?: IChoiceGroupOptionExt, defaultRender?: (props?: IChoiceGroupOption) => JSX.Element | null): JSX.Element | null => {
    const iconName = SourceType_.getIconName(props?.value as SourceType)
    return <span className='ms-ChoiceFieldLabel'>
        <Icon className={`linked-icon ${iconName}`} iconName={iconName} />
        <span className=''>{props?.text}</span>
    </span>;
}

type PrimaryScheduleSectionProps = {
    sourceTypes: SourceType[],
    value: SourceType,
    onChange: (value: SourceType) => void,
    readonly?: boolean
};

const PrimarySystemsOptions: { value: SourceType, text: string }[] = [
    { value: SourceType.Ppmx, text: "PPM Express" },
    { value: SourceType.VSTS, text: "Azure DevOps" },
    { value: SourceType.Jira, text: "Jira" },
    { value: SourceType.O365Planner, text: "Planner" },
    { value: SourceType.Spo, text: "Project Online" },
    { value: SourceType.MondayCom, text: "Monday.com" },
    { value: SourceType.Smartsheet, text: "Smartsheet" },
    { value: SourceType.MPPFile, text: "Project Desktop" },
    { value: SourceType.P4W, text: "Project for the Web" },
];

export const PrimaryScheduleSection = (props: React.PropsWithChildren<PrimaryScheduleSectionProps>) => {
    const options: IChoiceGroupOptionExt[] = PrimarySystemsOptions
        .filter(_ => _.value === SourceType.Ppmx || props.sourceTypes.includes(_.value))
        .map(_ => ({ ..._, key: _.value.toString(), onRenderField, onRenderLabel }));

    return <div className="primary-schedule">
        <div className='section-description'>
            Select the schedule that will be primary for the current project. Please refer
            to <Link href='https://help.ppm.express/94162-portfolio-and-project-management/2082039-project-settings#section-0' target='_blank'>this article</Link> for
            more details about primary schedule.</div>
        {props.children}
        <div className='choice-header'>
            Primary Schedule
            <TooltipHost
                hostClassName='choice-header-host'
                tooltipProps={{
                    onRenderContent: () => <>Values rolled up from this system schedule will be set into project main rollup fields</>
                }}
                delay={TooltipDelay.zero} >
                <Icon iconName="Info" onClick={(e) => e.stopPropagation()} className="info-icon" />
            </TooltipHost>
        </div>
        <ChoiceGroup
            className="horizontal"
            defaultSelectedKey={props.value?.toString()}
            options={options}
            disabled={props.readonly}
            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => props.onChange(option?.value as SourceType)}
        />
    </div>;
}