import * as React from 'react';
import { IObjectWithKey } from 'office-ui-fabric-react';
import { formatValue} from '../../../utils/common';
import { ViewType } from '../ResourceUsageGrid';
import { TimeType } from './CellMenu';
import CellValue from './CellValue';

export interface ISelectableCellData extends IObjectWithKey {
    entityId: string;
    start: Date;
    finish: Date;
}

export type HoursData = {
    capacity: number;
    plan: number;
    actual: number;
    availability: number;
    planPercent: number | undefined;
    actualPercent: number | undefined;
    availabilityPercent: number | undefined;
    planFte: number | undefined;
    actualFte: number | undefined;
    availabilityFte: number | undefined;
};

export type SummaryData = {
    hours: HoursData;
    fullFteCapacityHours: number;
    hasOverage: boolean;
    hasLocalOverage: boolean;
    hasProposedOverage: boolean;
    hasLocalProposedOverage: boolean;
}

type ViewModeProps = {
    viewType: ViewType, 
    timeType: TimeType[], 
    maxCapacityHours: number, 
    summaryData: SummaryData, 
    prevHours: HoursData | undefined,  
    _startEditing: (editTimeType?: TimeType) => void
}

const ViewMode = (props: ViewModeProps) => {
    const { viewType, timeType, maxCapacityHours, summaryData, prevHours, _startEditing } = props;
    const isEmpty = !summaryData.hours.capacity && !summaryData.hours.availability && !summaryData.hours.plan 
        && !summaryData.hours.actual && !summaryData.hasOverage && !summaryData.hasLocalOverage;
    const isTimeTypeOn = (_: TimeType) => timeType.includes(_);

    if(viewType === ViewType.Hours) {
        return <>
            {isTimeTypeOn('plan') && <CellValue value={formatValue(summaryData.hours.plan)} unit='hours' isEmpty={isEmpty} title='Planned' className='plan'
                onDoubleClick={() => _startEditing('plan')} />}
            {isTimeTypeOn('actual') && <CellValue value={formatValue(summaryData.hours.actual)} unit='hours' isEmpty={isEmpty} title='Actual' className='actual'
                onDoubleClick={() => _startEditing('actual')} />}
            {isTimeTypeOn('availability') && <CellValue value={formatValue(summaryData.hours.availability)} unit='hours' isEmpty={isEmpty} title='Availability'
                className='availability' />}
            {isTimeTypeOn('capacity') && <CellValue value={formatValue(summaryData.hours.capacity)} unit='hours' isEmpty={isEmpty} title='Capacity' className='capacity' />}
        </>;
    }
    if(viewType === ViewType.Percent) {
        return <>
            {isTimeTypeOn('plan') && <CellValue value={summaryData.hours.planPercent !== undefined ? formatValue(summaryData.hours.planPercent) : 'N/A'} unit='%'
                isEmpty={isEmpty} title='Planned' onDoubleClick={() => _startEditing('plan')} className='plan' />}
            {isTimeTypeOn('actual') && <CellValue value={summaryData.hours.actualPercent !== undefined ? formatValue(summaryData.hours.actualPercent) : 'N/A'} unit='%'
                isEmpty={isEmpty} title='Actual' onDoubleClick={() => _startEditing('actual')} className='actual' />}
            {isTimeTypeOn('availability') && <CellValue value={summaryData.hours.availability !== undefined ? formatValue(summaryData.hours.availabilityPercent) : 'N/A'} unit='%'
                isEmpty={isEmpty} title='Availability' className='availability' />}
            {isTimeTypeOn('capacity') && <CellValue value={summaryData.hours.capacity ? '100' : 'N/A'} unit='%' isEmpty={isEmpty} title='Capacity' className='capacity' />}
        </>;
    }
    if(viewType === ViewType.FTE) {
        return <>
            {isTimeTypeOn('plan') && <CellValue value={summaryData.hours.planFte !== undefined ? formatValue(summaryData.hours.planFte) : 'N/A'} 
                unit='FTE' isEmpty={isEmpty} title='Planned' onDoubleClick={() => _startEditing('plan')} className='plan' />}
            {isTimeTypeOn('actual') && <CellValue value={summaryData.hours.actualFte !== undefined ? formatValue(summaryData.hours.actualFte) : 'N/A'}
                unit='FTE' isEmpty={isEmpty} title='Actual' onDoubleClick={() => _startEditing('actual')} className='actual' />}
            {isTimeTypeOn('availability') && <CellValue value={summaryData.hours.availability !== undefined ? formatValue(summaryData.hours.availabilityFte) : 'N/A'}
                unit='FTE' isEmpty={isEmpty} title='Availability' className='availability' />}
            {isTimeTypeOn('capacity') && <CellValue value={summaryData.hours.capacity ? formatValue(summaryData.hours.capacity / summaryData.fullFteCapacityHours) : 'N/A'}
                unit='FTE' isEmpty={isEmpty} title='Capacity' className='capacity' />}
        </>;
    }
    if(viewType === ViewType.Chart) {
        return <>
            {isTimeTypeOn('plan') && 
                <Chart hours={summaryData.hours.plan} prevHours={prevHours?.plan} maxCapacityHours={maxCapacityHours} 
                    className='plan' title='Planned' onDoubleClick={() => _startEditing('plan')}/> }
            {isTimeTypeOn('actual') && 
                <Chart hours={summaryData.hours.actual} prevHours={prevHours?.actual} maxCapacityHours={maxCapacityHours} 
                    className='actual' title='Actual' onDoubleClick={() => _startEditing('actual')}/>}
            {isTimeTypeOn('availability') && 
                <Chart hours={summaryData.hours.availability} prevHours={prevHours?.availability} maxCapacityHours={maxCapacityHours} 
                    className='availability' title='Availability' onDoubleClick={() => _startEditing('availability')}/> }
            {isTimeTypeOn('capacity') && 
                <Chart hours={summaryData.hours.capacity} prevHours={prevHours?.capacity} maxCapacityHours={maxCapacityHours} 
                    className='capacity' title='Capacity' onDoubleClick={() => _startEditing('capacity')}/> } 
        </>
    }
    return null;
}

export default ViewMode;

type ChartProps = {
    hours: number, 
    prevHours?: number, 
    maxCapacityHours: number, 
    className: string, 
    title: string,
    onDoubleClick?: React.MouseEventHandler<HTMLDivElement>
}

const BarMaxHeight = 90;
const BarNormalHeight = 50;
const Chart = (props: ChartProps) => {
    const { hours, prevHours, maxCapacityHours, className, title, onDoubleClick} = props;
    const getBarHeight = (hoursValue: number, capacityValue: number): number => Math.min(BarMaxHeight, BarNormalHeight * hoursValue / capacityValue);

    return <>
        <div onDoubleClick={onDoubleClick} className={`bar ${className}`} title={`${title}: ${formatValue(hours)} hours`} style={{
            bottom: `${getBarHeight(hours, maxCapacityHours)}%`
        }}><div className={`dot ${className}`}></div></div>
        {prevHours !== undefined && prevHours !== hours && <div className={`bar-left-border ${className}`} style={{
            height: `calc(${getBarHeight(Math.max(prevHours, hours), maxCapacityHours) - getBarHeight(Math.min(prevHours, hours), maxCapacityHours)}% + 2px)`,
            bottom: `${getBarHeight(Math.min(prevHours, hours), maxCapacityHours)}%`
        }}></div>}
        {prevHours !== undefined && prevHours !== hours && <div className={`bar corner ${className}`} style={{
            height: `calc(${getBarHeight(prevHours, maxCapacityHours)}% + 2px)`
        }}></div>}
    </>
}
