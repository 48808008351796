import * as React from 'react';
import * as Metadata from "../../entities/Metadata";
import { ConfirmationDialog } from '../common/ConfirmationDialog';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ITask, ITaskAttrs } from '../../entities/Subentities';
import { connect } from 'react-redux';
import { Dictionary, EntityType } from '../../entities/common';
import { nameof } from '../../store/services/metadataService';
import { ApplicationState } from '../../store';

type OwnProps = {
    onConfirm: () => void;
    onDismiss: () => void;
    selectedItems: ITask[];
}
type StoreProps = {
    fieldsMap: Dictionary<Metadata.Field>;
}
type Props = OwnProps & StoreProps & RouteComponentProps<{}>;
const TaskSetBeselineConfirmation = (props: Props) => <ConfirmationDialog
    onDismiss={props.onDismiss}
    onYes={props.onConfirm}
    yesButtonProps={{ text: "Confirm" }}
    dialogContentProps={{
        title: `Set Baseline`,
        subText: (props.selectedItems.length === 1
            ? `Are you sure you want to set baseline for task "${props.selectedItems[0].attributes.Name}"?`
            : `Are you sure you want to set baseline for selected tasks (${props.selectedItems.length} items)?`)
    }}>
    {` This action will take a snapshot of your schedule that includes information about \
    ${Metadata.getLabel(props.fieldsMap[nameof<ITaskAttrs>("StartDate")])}, \
    ${Metadata.getLabel(props.fieldsMap[nameof<ITaskAttrs>("DueDate")])}, \
    ${Metadata.getLabel(props.fieldsMap[nameof<ITaskAttrs>("Duration")])}, \
    ${Metadata.getLabel(props.fieldsMap[nameof<ITaskAttrs>("Effort")])}, \
    ${Metadata.getLabel(props.fieldsMap[nameof<ITaskAttrs>("OriginalEstimate")])}.`}
</ConfirmationDialog>;

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Task];
    return {
        fieldsMap: Metadata.toMap(fields.allIds.map(_ => fields.byId[_]))
    };
}
export default withRouter<OwnProps>(connect(mapStateToProps)(TaskSetBeselineConfirmation));