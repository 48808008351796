import * as React from 'react';
import { connect } from 'react-redux';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { NotificationsState, actionCreators, INotification, NotificationType } from "../store/NotificationsStore";
import { ApplicationState } from "../store/index";

type NotificationsProps = NotificationsState & typeof actionCreators;

class Notifications extends React.Component<NotificationsProps>{
    public render() {
        return [this.props.children,
        <div key="notifications" className="notifications-container">
            {this.props.all.map((_, i) => <MessageBar
                key={i}
                className="notification"
                isMultiline={true}
                messageBarType={this._getBarType(_)}
                onDismiss={() => this.props.removeNotification(_)}
                dismissButtonAriaLabel='Close'>{_.message}</MessageBar>)}
        </div>];
    }

    private _getBarType(notification: INotification) {
        switch (notification.type) {
            case NotificationType.Success: return MessageBarType.success;
            case NotificationType.Warn: return MessageBarType.warning;
            case NotificationType.Error: return MessageBarType.error;
            default: return MessageBarType.info;
        }
    }
}

export default connect((state: ApplicationState) => state.notifications, actionCreators)(Notifications);