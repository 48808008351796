import * as React from 'react';
import { Portfolio } from '../../store/PortfoliosListStore';
import { ProjectInfo } from '../../store/ProjectsListStore';
import { IRow } from "../common/timeline/TimelineList";
import { ITimelineSegment } from '../common/timeline/TimelineSegment';
import { ITimelineMarker } from '../common/timeline/TimelineMarker';
import KeyDateTooltipContent from "../common/timeline/KeyDateTooltipContent";
import { Program } from '../../store/ProgramsListStore';
import * as PortfoliosTimeline from './timeline';
import * as ProgramsTimeline from '../program/timeline';
import * as ProjectsTimeline from '../project/timeline';
import { EntityType } from '../../entities/common';


export function renderSegmentContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    return row.rowType === PortfoliosTimeline.portfolioRowType
        ? PortfoliosTimeline.renderSegmentContent(row, segment)
        : row.rowType === ProgramsTimeline.programRowType
            ? ProgramsTimeline.renderSegmentContent(row, segment)
            : ProjectsTimeline.renderSegmentContent(row, segment);
}

export function renderSegmentTooltipContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    return row.rowType === PortfoliosTimeline.portfolioRowType
        ? PortfoliosTimeline.renderSegmentTooltipContent(row, segment)
        : row.rowType === ProgramsTimeline.programRowType
            ? ProgramsTimeline.renderSegmentTooltipContent(row, segment)
            : ProjectsTimeline.renderSegmentTooltipContent(row, segment);
}

export function renderMarkerTooltipContent(row: IRow, marker: ITimelineMarker, showParentEntityName?: boolean): JSX.Element | undefined {
    const keyDate = (row.entity as Portfolio | Program | ProjectInfo).keyDates.find(_ => _.id === marker.key);
    if (!keyDate) {
        return undefined;
    }

    return <KeyDateTooltipContent
        portfolio={showParentEntityName && row.rowType === PortfoliosTimeline.portfolioRowType ? row.entity as Portfolio : undefined}
        program={showParentEntityName && row.rowType === ProgramsTimeline.programRowType ? row.entity as Program : undefined}
        project={showParentEntityName && row.rowType !== PortfoliosTimeline.portfolioRowType && row.rowType !== ProgramsTimeline.programRowType
            ? row.entity as ProjectInfo
            : undefined}
        keyDate={keyDate}
        createdFromType={EntityType.KeyDate}
        showFrom />;
}