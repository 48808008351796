import * as React from 'react';
import { ApplicationState } from '../../store/index';
import { IPermissions, LicenseType, ResourceOperations } from "../../store/permissions";
import { PPMFeatures, Subscription } from '../../store/Tenant';
import { connect } from 'react-redux';
import { LayoutsState } from '../../store/layouts';
import { Dictionary, EntityType } from '../../entities/common';
import { ResourcePermsEdit } from './ResourcePermsEdit';

type OwnProps = {
    license: LicenseType;
    readOnly?: boolean;
    permissions: IPermissions;
    onChange: (changes: Partial<IPermissions>, entityType: EntityType) => void;
    hideGranularEntityPermissions?: boolean;
    disabled?: (entityType: EntityType, operation: ResourceOperations) => boolean | undefined;
    indeterminate?: (entityType: EntityType, operation: ResourceOperations) => boolean | undefined;
    extraLayoutOption?: { key: string, text: string; title: string };
}
type StateProps = { subscription: Subscription, layouts: Dictionary<LayoutsState> };
type Props = OwnProps & StateProps;

const ResourcePermissionsImpl = (props: Props) => {
    const { permissions, readOnly, subscription, license, layouts, extraLayoutOption, hideGranularEntityPermissions, disabled, indeterminate } = props;
    return <>
        {
            Subscription.contains(subscription, PPMFeatures.PortfolioManagement) && <>
                <ResourcePermsEdit
                    license={license}
                    readOnly={readOnly}
                    title="Portfolios"
                    icon="PPMXPortfolio"
                    expanded={license !== LicenseType.Regular}
                    searchUrl="api/user/permissions/portfolio"
                    perms={permissions.portfolio}
                    layouts={layouts[EntityType.Portfolio]}
                    extraLayoutOption={extraLayoutOption}
                    onChange={(_) => _onChange({ portfolio: { ...permissions.portfolio, ..._ } }, EntityType.Portfolio)}
                    hideGranularEntityPermissions={hideGranularEntityPermissions}
                    disabled={disabled ? _ => disabled(EntityType.Portfolio, _) : undefined}
                    indeterminate={indeterminate ? _ => indeterminate(EntityType.Portfolio, _) : undefined} />
                <ResourcePermsEdit
                    license={license}
                    readOnly={readOnly}
                    title="Programs"
                    icon="PPMXProgram"
                    searchUrl="api/user/permissions/program"
                    perms={permissions.program}
                    layouts={layouts[EntityType.Program]}
                    extraLayoutOption={extraLayoutOption}
                    onChange={(_) => _onChange({ program: { ...permissions.program, ..._ } }, EntityType.Program)}
                    hideGranularEntityPermissions={hideGranularEntityPermissions}
                    disabled={disabled ? _ => disabled(EntityType.Program, _) : undefined}
                    indeterminate={indeterminate ? _ => indeterminate(EntityType.Program, _) : undefined} />
            </>
        }
        {
            Subscription.contains(subscription, PPMFeatures.ProjectManagement) && <ResourcePermsEdit
                license={license}
                readOnly={readOnly}
                title="Projects"
                icon="PPMXProject"
                searchUrl="api/user/permissions/project"
                perms={permissions.project}
                layouts={layouts[EntityType.Project]}
                extraLayoutOption={extraLayoutOption}
                onChange={(_) => _onChange({ project: { ...permissions.project, ..._ } }, EntityType.Project)}
                hideGranularEntityPermissions={hideGranularEntityPermissions}
                disabled={disabled ? _ => disabled(EntityType.Project, _) : undefined}
                indeterminate={indeterminate ? _ => indeterminate(EntityType.Project, _) : undefined} />
        }
        {
            Subscription.contains(subscription, PPMFeatures.Roadmap) && <ResourcePermsEdit
                license={license}
                readOnly={readOnly}
                title="Roadmaps"
                icon="PPMXRoadmap"
                searchUrl="api/user/permissions/roadmap"
                perms={permissions.roadmap}
                layouts={layouts[EntityType.Roadmap]}
                onChange={(_) => _onChange({ roadmap: { ...permissions.roadmap, ..._ } }, EntityType.Roadmap)}
                hideCollaborate
                hideGranularEntityPermissions={hideGranularEntityPermissions}
                disabled={disabled ? _ => disabled(EntityType.Roadmap, _) : undefined}
                indeterminate={indeterminate ? _ => indeterminate(EntityType.Roadmap, _) : undefined} />
        }
        {
            Subscription.contains(subscription, PPMFeatures.Ideation) && <ResourcePermsEdit
                license={license}
                readOnly={readOnly}
                title="Business Challenges"
                icon="PPMXBusinessIdeas"
                searchUrl="api/user/permissions/challenge"
                perms={permissions.challenge}
                layouts={layouts[EntityType.Challenge]}
                onChange={(_) => _onChange({ challenge: { ...permissions.challenge, ..._ } }, EntityType.Challenge)}
                hideView
                hideGranularEntityPermissions={hideGranularEntityPermissions}
                disabled={disabled ? _ => disabled(EntityType.Challenge, _) : undefined}
                indeterminate={indeterminate ? _ => indeterminate(EntityType.Challenge, _) : undefined} />
        }
    </>;

    function _onChange(changes: Partial<IPermissions>, entityType: EntityType) {
        props.onChange(changes, entityType);
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        layouts: state.layouts
    }
}

export const ResourcePermissions = connect(mapStateToProps)(ResourcePermissionsImpl);

