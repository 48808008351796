import * as React from 'react';
import { Overlay } from 'office-ui-fabric-react';

const OverlayComponent = (props: React.PropsWithChildren<{ isOverlayed: boolean }>) => 
            <div style={{ 'position': 'relative' }}>
                <div className={props.isOverlayed ? "overlayed-component" : ""}>
                    {props.children}
                </div>
                { props.isOverlayed && <Overlay /> }
            </div>;

export default OverlayComponent;