import React, { useEffect, useRef, useState } from "react";
import PostMessageReceiver, { PostMessageData } from "../../integration/PostMessageReceiver";
import { ActionButton, Icon, IconButton, Panel, PanelType, Toggle } from "office-ui-fabric-react";
import {
    Office365TimeTrackingConnectorReaders,
    IPersonalO365TimeTrackingConnection,
    actionCreators as PersonalOffice365Actions,
    GetSuggestionTypeLabel
} from "../../../store/integration/PersonalOffice365Store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../../store";
import "./TimeTrackingO365Connector.css";
import { contains as enumContains } from "../../../store/permissions";
import Link from "../../common/Link";

type OwnProps = {
    onDismiss: () => void;
    forceRefresh: boolean;
};

type StateProps = {
    isValidating?: boolean;
    validationError: string | null;
    connection: IPersonalO365TimeTrackingConnection | null;
};

type ActionProps = {
    personalOffice365Actions: typeof PersonalOffice365Actions;
};

type Props = OwnProps & StateProps & ActionProps;

const newConnectionUrl = "/api/timetracking/integration/office365/auth";
const target = `connectionDataReceived_personalO365`;

function TimeTrackingO365Connector(props: Props) {
    const { connection, validationError, personalOffice365Actions, forceRefresh } = props;
    const readonly = false;

    const [readers, setReaders] = useState<Office365TimeTrackingConnectorReaders | null>(null);

    const onConnect = React.useCallback(
        () => (window.current = PostMessageReceiver.openPopupWindow(newConnectionUrl, target)),
        []
    );

    useEffect(() => {
        if (connection) {
            setReaders(connection.readers);
        }
    }, [connection]);

    useEffect(() => {
        if (forceRefresh) {
            onConnect();
        }
    }, [forceRefresh]);

    const window = useRef<Window | null>();

    const onDismiss = () => {
        window.current?.close();
        props.onDismiss();
    };

    const updateReaders = React.useCallback(
        (reader: Office365TimeTrackingConnectorReaders, isTurnedOn: boolean) =>{
            
            const newReaders = isTurnedOn
                ? readers! | reader
                : readers! & ~reader;

            setReaders(newReaders);

            personalOffice365Actions.saveSettings({ readers: newReaders });
        },
        [readers]
    );

    return (
        <Panel
            type={PanelType.custom}
            onDismiss={onDismiss}
            onRenderHeader={() => (
                <div className="ms-Panel-header">
                    <div className="ms-Panel-headerText">Configure M365 Connection</div>
                    <div className="ms-Panel-secondaryText">
                        Connect to your Microsoft 365 account to get suggested time entries based on your activities.
                        Please refer to <Link target="_blank" href="https://help.ppm.express/time-tracking/how-to-import-microsoft-365-activities-as-ppm-express-time-entries">the article</Link> for more details.
                    </div>
                </div>
            )}
            className="o365-time-tracking-panel"
            headerText="Export to CSV"
            isLightDismiss
            customWidth="400px"
            isOpen>
            <PostMessageReceiver<PostMessageData>
                from={target}
                onMessageReceived={(_, data) => {
                    if (data.context?.connectionId) {
                        personalOffice365Actions.loadConnection();
                    }
                }}
            />

            {connection ? (
                <>
                    <div className="header">Connection</div>
                    <div className="connection-card">
                        <IconButton
                            className="menu"
                            menuIconProps={{ iconName: "More" }}
                            menuProps={{
                                className: "connections-menu",
                                items: [
                                    {
                                        key: "refresh",
                                        name: "Refresh Connection",
                                        iconProps: { iconName: "Sync" },
                                        onClick: () => {
                                            onConnect();
                                        },
                                        disabled: readonly
                                    },
                                    {
                                        key: "delete",
                                        name: "Delete",
                                        iconProps: { iconName: "Delete", style: { color: "red" } },
                                        onClick: () => {
                                            personalOffice365Actions.deleteConnection();
                                        },
                                        style: { color: "red" }
                                    }
                                ]
                            }}
                        />
                        <div>Microsoft 365</div>
                        <div>{connection.name}</div>
                    </div>

                    {!!validationError && <div className="error-message">{validationError}</div>}
                    {readers !== null && (
                        <div className="connector-settings">
                            <div className="header">Microsoft 365 Connector Readers</div>
                            <Settings selectedReaders={readers} updateReaders={updateReaders} readonly={readonly} />
                        </div>
                    )}
                </>
            ) : (
                <>
                    <ActionButton iconProps={{ iconName: "Add" }} disabled={readonly} onClick={onConnect}>
                        Add new connection
                    </ActionButton>
                </>
            )}
        </Panel>
    );
}

const Settings = (
    props: {
        selectedReaders: Office365TimeTrackingConnectorReaders,
        updateReaders: (reader: Office365TimeTrackingConnectorReaders, turnedOn: boolean) => void,
        readonly: boolean
    }
): JSX.Element => {
    const { selectedReaders, updateReaders, readonly } = props;

    const readers: Office365TimeTrackingConnectorReaders[] = [
        Office365TimeTrackingConnectorReaders.CompletedOutlookCalendarEmails,
        Office365TimeTrackingConnectorReaders.OutlookTasks,
        Office365TimeTrackingConnectorReaders.SentOutlookEmails,
        Office365TimeTrackingConnectorReaders.OutlookCalendarEvents,
        Office365TimeTrackingConnectorReaders.TentativeCalendarEvents,
        Office365TimeTrackingConnectorReaders.RecentDocuments
    ];

    return (
        <ul className="cb-list">
            {readers.map(reader => {
                return (<li key={Office365TimeTrackingConnectorReaders[reader].toString()}>
                    <div className="cb-wrap">
                        <div className="title">
                            <Icon iconName={_getIcon(reader)} />
                            {GetSuggestionTypeLabel(reader)}
                        </div>
                        <Toggle
                            checked={enumContains(selectedReaders, reader)}
                            disabled={readonly}
                            onChange={(e: any, c?: boolean) => {
                                updateReaders(reader, c!);
                            }}
                        />
                    </div>
                </li>) 
            })}
        </ul>
    );
};

const _getIcon = (reader: Office365TimeTrackingConnectorReaders): string => {
    switch (reader) {
        case Office365TimeTrackingConnectorReaders.CompletedOutlookCalendarEmails:
            return "MailCheck";
        case Office365TimeTrackingConnectorReaders.OutlookTasks:
            return "TaskSolid";
        case Office365TimeTrackingConnectorReaders.SentOutlookEmails:
            return "Mail";
        case Office365TimeTrackingConnectorReaders.OutlookCalendarEvents:
            return "Calendar";
        case Office365TimeTrackingConnectorReaders.TentativeCalendarEvents:
            return "Event";
        case Office365TimeTrackingConnectorReaders.RecentDocuments:
            return "TextDocument";
        default: return ""
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        connection: state.personalOffice365.timeTrackingConnection,
        isValidating: state.personalOffice365.isValidating,
        validationError: state.personalOffice365.validationError
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        personalOffice365Actions: bindActionCreators(PersonalOffice365Actions, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(TimeTrackingO365Connector);
