import { post } from "../fetch-interceptor";
import { NEW_ID, SortDirection } from "../entities/Metadata";
import { Dictionary, EntityType, OriginScale, UserPreferencesSettingsUpdate } from '../entities/common';
import { actionCreators as Notifications, NotificationType } from './NotificationsStore';
import { debounce } from "./utils";
import { isInReadonlyMode, PreferencesUpdate } from "./User";
import { ApplicationState } from ".";

type ViewUpdate = {
    activeSubViewId?: string;
    SortById?: string;
    sortBy: {
        fieldName: string;
        direction: SortDirection;
    };
    columnResize: {
        subViewId: string;
        fieldId: string;
        width: number;
    };
    scale: OriginScale;
};

function getViewUpdateKey(update: Partial<ViewUpdate>) {
    return update.columnResize
        ? update.columnResize.subViewId + update.columnResize.fieldId
        : '';
}

function isReadonlyMode(appState: ApplicationState): boolean {
    return isInReadonlyMode(appState.user, appState.tenant)
}

const API = {
    setActiveView: (dispatch: any, entity: EntityType, viewType: string) => {
        post(`api/user/preferences/${entity}/view/active`, { type: viewType })
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save active view",
                type: NotificationType.Error
            })));
    },
    setViewSettings: (dispatch: any, entity: EntityType, viewType: string, data: Partial<ViewUpdate>) => {
        post(`api/user/preferences/${entity}/view/${viewType}/settings`, data)
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save current view state",
                type: NotificationType.Error
            })));
    },
    setActiveFilter: (dispatch: any, entity: EntityType, id: string, preFilterId?: string) => {
        post(`api/user/preferences/${entity}/filter/active`, { filterId: id, preFilterId })
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save current filter state",
                type: NotificationType.Error
            })));
    },
    updateViewPreferences: (dispatch: any, preferencesUpdate: PreferencesUpdate) => {
        post(`api/user/preferences`, preferencesUpdate)
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save current preferences",
                type: NotificationType.Error
            })));
    },
    setSectionSetting: (dispatch: any, update: SectionSettingsUpdateDto) => {
        post(`api/user/preferences/sectionSettings`, update)
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save current settings",
                type: NotificationType.Error
            })));
    },
    setUtilizationSetting: (dispatch: any, update: SectionSettingsUpdateDto) => {
        post(`api/user/preferences/utilization`, update)
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save utilization section settings",
                type: NotificationType.Error
            })));
    },
    setInsightsSetting: (dispatch: any, update: SectionSettingsUpdateDto) => {
        post(`api/user/preferences/insights`, update)
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save insights settings",
                type: NotificationType.Error
            })));
    },
    setReportedTimeSetting: (dispatch: any, update: SectionSettingsUpdateDto) => {
        post(`api/user/preferences/reported-time`, update)
            .catch(() => dispatch(Notifications.pushNotification({
                message: "Failed to save current settings",
                type: NotificationType.Error
            })));
    }
}

type SectionSettingsUpdateDto = {
    parentSettingsPathKeys: string[];
    valueBySettingNameMap: Dictionary<any>;
}

class PreferencesTracker {
    private _trackableEntities = new Set<EntityType>([
        EntityType.Portfolio, EntityType.Program, EntityType.Project,
        EntityType.Resource, EntityType.Challenge, EntityType.Objective,
        EntityType.Roadmap
    ]);

    private _debouncedSetActiveView = debounce(API.setActiveView);

    private _debouncedSetViewSettings = debounce(API.setViewSettings);

    private _debouncedSetActiveFilter = debounce(API.setActiveFilter);

    private _debouncedUpdateViewPreferences = debounce(API.updateViewPreferences);

    private _debouncedSetSectionSetting = debounce(API.setSectionSetting);

    private _debouncedSetUtilizationSetting = debounce(API.setUtilizationSetting);

    private _debouncedSetReportedTimeSetting = debounce(API.setReportedTimeSetting);

    private _debouncedSetInsightsSetting = debounce(API.setInsightsSetting);

    public setActiveView = (dispatch: any, getState: () => ApplicationState, entity: EntityType, viewType: string) => {
        if (!isReadonlyMode(getState()) && this._trackableEntities.has(entity)) {
            const debounceKey = entity;
            this._debouncedSetActiveView(debounceKey, dispatch, entity, viewType);
        }
    }

    public setViewSettings = (dispatch: any, getState: () => ApplicationState, entity: EntityType, viewType: string, data: Partial<ViewUpdate>) => {
        if (isReadonlyMode(getState()) || (data.activeSubViewId && data.activeSubViewId === NEW_ID)) {
            return;
        }

        if (this._trackableEntities.has(entity)) {
            const debounceKey = entity + viewType + getViewUpdateKey(data);
            this._debouncedSetViewSettings(debounceKey, dispatch, entity, viewType, data);
        }
    }

    public setActiveFilter = (dispatch: any, getState: () => ApplicationState, entity: EntityType, filterId?: string, preFilterId?: string) => {
        if (!isReadonlyMode(getState()) && filterId && filterId !== NEW_ID && this._trackableEntities.has(entity)) {
            const debounceKey = entity;
            this._debouncedSetActiveFilter(debounceKey, dispatch, entity, filterId, preFilterId);
        }
    }

    public updateViewPreferences = (dispatch: any, getState: () => ApplicationState, preferencesUpdate: PreferencesUpdate) => {
        if (isReadonlyMode(getState())) {
            return;
        }
        const debounceKey = "preferencesKey";
        this._debouncedUpdateViewPreferences(debounceKey, dispatch, preferencesUpdate);
    }

    public setSectionSettings = (dispatch: any, getState: () => ApplicationState, update: UserPreferencesSettingsUpdate) => {
        if (update.immediate) {
            this.setPreferencesSettings(dispatch, getState, update, (key, dispatch, update) => API.setSectionSetting(dispatch, update));
        } else {
            this.setPreferencesSettings(dispatch, getState, update, this._debouncedSetSectionSetting);
        }
    }

    public setUtilizationSettings = (dispatch: any, getState: () => ApplicationState, update: UserPreferencesSettingsUpdate) => {
        this.setPreferencesSettings(dispatch, getState, update, this._debouncedSetUtilizationSetting);
    }

    public setReportedTimeSettings = (dispatch: any, getState: () => ApplicationState, update: UserPreferencesSettingsUpdate) => {
        this.setPreferencesSettings(dispatch, getState, update, this._debouncedSetReportedTimeSetting);
    }

    public setInsightsSettings = (dispatch: any, getState: () => ApplicationState, update: UserPreferencesSettingsUpdate) => {
        this.setPreferencesSettings(dispatch, getState, update, this._debouncedSetInsightsSetting);
    }

    private setPreferencesSettings = (dispatch: any,
        getState: () => ApplicationState,
        update: UserPreferencesSettingsUpdate,
        sender: (debounceKey: string, dispatch: any, update: SectionSettingsUpdateDto) => void) => {
        if (isReadonlyMode(getState())) {
            return;
        }

        const debounceKey = update.parentSettingsPathKeys.concat(Object.keys(update.valueBySettingNameMap)).join('');
        sender(debounceKey, dispatch, {
            parentSettingsPathKeys: update.parentSettingsPathKeys,
            valueBySettingNameMap: update.valueBySettingNameMap
        });
    }
}

const Tracker = new PreferencesTracker();
export { Tracker };