import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ITask, ITaskAttrs } from "../../entities/Subentities";
import { Icon } from 'office-ui-fabric-react';
import { FormatDate, formatValue } from '../utils/common';
import { SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import { FormatType, Field, getLabel } from '../../entities/Metadata';
import ResourceFormatter from '../common/formatters/ResourceFormatter';
import { PriorityView } from "../views/list/columns/task/Priority";
import TaskIcon from './TaskIcon';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { EntityType, StatusCategory } from '../../entities/common';
import * as Calendar from '../../store/CalendarStore';
import { bindActionCreators } from 'redux';
import { TaskCalculationModeTypes } from '../views/list/columns/task/Mode';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

type OwnProps = { task: ITask; onClick: () => void };
type StoreProps = {
    fields: Field[];
    calendar: Calendar.CalendarDataSet;
}
type ActionProps = {
    calendarActions: typeof Calendar.actionCreators;
};
type Props = OwnProps & StoreProps & RouteComponentProps<{}> & ActionProps;

const TaskTooltipContent = (props: Props) => {
    React.useEffect(() => {
        if (!Object.keys(props.calendar.workDayExpectedHrs).length) {
            props.calendarActions.load();
        }
    }, []);

    const { task, onClick } = props;
    const sourceIconName = task.sourceType
        ? SourceType_.getIconName(task.sourceType)
        : undefined;
    const startDate = task.attributes.StartDate
        ? FormatDate(task.attributes.StartDate)
        : "-";
    const dueDate = task.attributes.DueDate
        ? FormatDate(task.attributes.DueDate)
        : "-";
    const effort = task.sourceType && [SourceType.MondayCom, SourceType.O365Planner].includes(task.sourceType) && task.attributes.Effort === undefined
        ? "N/A"                          
        : task.attributes.Effort != null
            ? formatValue(task.attributes.Effort, FormatType.Duration)
            : "-";
    const storyPoints = task.attributes.StoryPoints != null
        ? task.attributes.StoryPoints
        : "-";
    const taskStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Task, props.fields)!;
    const statusOption = taskStatusDescriptor.getOptionOrDefault(task.attributes.Status, StatusCategory.NA);

    return (
        <div className="timeline-tooltip clickable" onClick={onClick}>
            <div className="header">
                <TaskIcon entity={task} />
                <div className="title">
                    <div className="overflow-text" title={task.attributes.Name}>
                        {task.attributes.Name}
                    </div>
                    {sourceIconName && <div title={`Linked to ${SourceType_.getName(task.sourceType!)}`}>
                        <Icon className={"logo " + sourceIconName} iconName={sourceIconName} />
                    </div>}
                </div>
                <CategoryColorStatusView
                    statusOption={statusOption}
                />
            </div>
            <div className="content">
                {!!task.attributes.Predecessor?.length && renderField("Predecessor", <div>{task.attributes.Predecessor.map(_ => _.name).join(', ')}</div>)}
                {renderField("StartDate", <div>{startDate}</div>)}
                {renderField("DueDate", <div>{dueDate}</div>)}
                {renderField("Effort", <div>{effort}</div>)}
                {renderField("StoryPoints", <div>{storyPoints}</div>)}
                {
                    renderField("Progress",
                        <div className="align-center">
                            <div className="completed">{formatValue(task.attributes.Progress, FormatType.Percent)}</div>
                        </div>)
                }
                {
                    renderField("Priority",
                        <div className="align-center">
                            <PriorityView value={task.attributes.Priority} />
                        </div>)
                }
                {
                    renderField("AssignedTo",
                        <div className="align-center">
                            <ResourceFormatter resource={task.attributes.AssignedTo} withNavigation={false} />
                        </div>)
                }

                <div className="item align-center">
                    <div className="label">Mode</div>
                    <div className='calculation-mode'>
                        {task.isAutoMode ? TaskCalculationModeTypes.Auto : TaskCalculationModeTypes.Manual}
                    </div>
                </div>
            </div>
        </div>
    );

    function renderField(name: keyof ITaskAttrs, valueElement: JSX.Element): JSX.Element | null {
        const field = props.fields.find(_ => _.name === name);
        if (!field) {
            return null;
        }

        return (
            <div className="item align-center">
                <div className="label">{getLabel(field)}</div>
                {valueElement}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Task];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        calendar: state.calendar
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        calendarActions: bindActionCreators(Calendar.actionCreators, dispatch)
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, mergeActionCreators)(TaskTooltipContent));