import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from "../../../store/integration/Office365Store";
import { ApplicationState } from "../../../store/index";
import { IConnectionsState } from "../../../store/integration/common";
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import OAuthConnectionsList from '../OAuthConnectionsList';
import { IConnectionInfo } from '../../../entities/Metadata';
import { Office365Utils, PlannerPostMessageContext } from './Office365Utils';
import { PostMessageDataBase } from '../PostMessageReceiver';
import * as Notifications from "../../../store/NotificationsStore";
import { bindActionCreators } from "redux";

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    sourceType: SourceType.O365Group | SourceType.O365Planner | SourceType.O365TeamsChannel | SourceType.O365User;
    onSelect: (connection: IConnectionInfo) => void;
};

type ActionProps = {
    notificationsActions: typeof Notifications.actionCreators,
    connectionActions: typeof actionCreators
}

type StateProps = { connections: IConnectionsState; };
type Props = StateProps & OwnProps & ActionProps;

const Office365ConnectionsList = (props: Props) => {
    React.useEffect(() => {
        props.connectionActions.loadConnections();
    }, []);
    
    return <OAuthConnectionsList
        disabled={props.connections.isLoading || !!props.disabled}
        sourceType={props.sourceType}
        connections={props.connections.data}

        newConnectionUrl="/api/integration/office365/auth"
        newConnectionTooltip={Office365Utils.getNewConnectionTooltip()}
        newRestrictedConnectionUrl={Office365Utils.getNewRestrictedConnectionUrl(props.sourceType)}
        newRestrictedConnectionTooltip={Office365Utils.getNewRestrictedConnectionTooltip()}
        newAzureADConnectionUrl={props.sourceType === SourceType.O365User ? Office365Utils.getNewAzureADConnectionUrl(props.sourceType) : undefined}
        newAzureADConnectionTooltip={props.sourceType === SourceType.O365User ? Office365Utils.getNewAzureADConnectionTooltip() : undefined}
        getRefreshConnectionUrl={(cId) => Office365Utils.getRefreshConnectionUrl(props.connections, cId)}
        getUpgradeConnectionUrl={(cId) => Office365Utils.getUpgradeConnectionUrl(props.connections, cId)}
        grantPermissionsUrl="/api/integration/office365/auth/adminConsent"
        grantPermissionsText="Grant Permissions (requires Admin Consent)"
        onReceivedConnectionData={(data: PostMessageDataBase<PlannerPostMessageContext>) => {
            props.connectionActions.loadConnections();
            if (data.context?.scopeChanged) {
                props.notificationsActions.pushNotification({ message: Office365Utils.ScopeChangedMessage, type: Notifications.NotificationType.Warn });
            }
        }}
        onConnectionSelect={props.onSelect}
        onConnectionRemove={props.connectionActions.removeConnection}
        onConnectionRename={props.connectionActions.renameConnection}
    />;

    function buildRefreshConnectionUrl(connectionId: string) {
        return `/api/integration/office365/auth/connection/${connectionId}`;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.office365.connections };
}

export default connect(mapStateToProps,
    (dispatch): ActionProps =>
    ({
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        connectionActions: bindActionCreators(actionCreators, dispatch),
    })
)(Office365ConnectionsList);
