import * as React from 'react';
import { taskPriorityConfigMap, ITask, TaskPriority } from "../../../../../entities/Subentities";
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Icon } from 'office-ui-fabric-react';

export default class Priority extends React.Component<IListViewFieldColumn<ITask>> {
    render() {
        return <PriorityView value={this.props.entity.attributes.Priority} />;
    }
}

export class PriorityView extends React.Component<{ value: TaskPriority }> {
    render() {
        const { value } = this.props;
        const taskPriority = taskPriorityConfigMap[value || TaskPriority.NA];
        return (
            <div className="task-priority" title={taskPriority.title}>
                {
                    taskPriority.iconName &&
                        <Icon iconName={taskPriority.iconName} className={"task-priority-icon " + (taskPriority.className || "")} />
                }
                <span>{taskPriority.title}</span>
            </div>
        );
    }
}