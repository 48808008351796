import { Action, Reducer } from 'redux';
import { Dictionary } from '../entities/common';
import * as Metadata from "../entities/Metadata";
import { Field, IFilter, BaseFilterValue, IServerViews, Layout } from '../entities/Metadata';
import { AppThunkAction, ApplicationState } from '.';
import { get } from '../fetch-interceptor';
import { defaultCatch } from './utils';
import * as JiraStore from "./integration/JiraStore";
import * as MondayComStore from "./integration/MondayComStore";
import * as SpoStore from "./integration/SpoStore";
import * as VSTSStore from "./integration/VSTSStore";
import * as Office365Store from "./integration/Office365Store";
import * as SmartsheetStore from "./integration/SmartsheetStore";
import * as P4WStore from "./integration/P4WStore";
import * as Filters from "./filters";
import { ExtensionInfo } from './ExtensionStore';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MetadataState {
    isLoaded: boolean;
    isLoading: boolean;
}

const INIT_STATE = {
    isLoaded: false,
    isLoading: false
}

type IMetadata = {
    fields: Dictionary<Field[]>;
    filters: Dictionary<{ active?: Filters.ActiveFilter, filters: IFilter<BaseFilterValue>[] }>;
    views: Dictionary<{ activeType: string, views: IServerViews }>;
    layouts: Dictionary<Layout[]>;
    extensions: ExtensionInfo[];
}

export type MetadataLoadedAction = { type: "METADATA_LOADED"; data: IMetadata }
type MetadataLoadingStateChangedAction = { type: "METADATA_LOADING_STATE_CHANGED"; data: Partial<MetadataState> }
type KnownAction = MetadataLoadedAction
    | MetadataLoadingStateChangedAction
    | JiraStore.LoadConnections
    | JiraStore.ReceivedConnections
    | MondayComStore.LoadConnections
    | MondayComStore.ReceivedConnections
    | VSTSStore.LoadConnections
    | VSTSStore.ReceivedConnections
    | Office365Store.LoadConnections
    | Office365Store.ReceivedConnections
    | SmartsheetStore.LoadConnections
    | SmartsheetStore.ReceivedConnections
    | SpoStore.LoadConnections
    | SpoStore.ReceivedConnections
    | P4WStore.LoadConnections
    | P4WStore.ReceivedConnections;

const loadMetadata = (dispatch: (action: KnownAction | AppThunkAction<KnownAction>) => void, getState: () => ApplicationState) => { 
    const state = getState();
    if (state.metadata.isLoading) {
        return;
    }

    get<IMetadata>('api/metadata')
        .then(data => {
            dispatch({ type: "METADATA_LOADED", data });
            dispatch({ type: "METADATA_LOADING_STATE_CHANGED", data: { isLoaded: true, isLoading: false } });
        })
        .catch(defaultCatch(dispatch));
    dispatch({ type: "METADATA_LOADING_STATE_CHANGED", data: { isLoading: true } });
};

export const actionCreators = {
    expireMetadata: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "METADATA_LOADING_STATE_CHANGED", data: { isLoaded: false } });
    },
    ensureMetadata: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        if (state.metadata.isLoaded) {
            return;
        }

        loadMetadata(dispatch, getState);
    },
    loadMetadata: (): AppThunkAction<KnownAction> => loadMetadata,
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_CONNECTIONS' });
        dispatch({ type: 'SPO_LOAD_CONNECTIONS' });
        dispatch({ type: 'VSTS_LOAD_CONNECTIONS' });
        dispatch({ type: 'LOAD_PLANNER_CONNECTIONS' });
        dispatch({ type: 'MONDAYCOM_LOAD_CONNECTIONS' });
        dispatch({ type: 'SMARTSHEET_LOAD_CONNECTIONS' });
        dispatch({ type: 'P4W_LOAD_CONNECTIONS' });
        get<Metadata.IConnectionInfo[]>(`api/metadata/connection`)
            .then(connections => {
                const connectionsMap = connections.filter(_ => !!_.type)
                    .reduce((map, _) => ({ ...map, [_.type!]: [...(map[_.type!] || []), _] }), {});
                dispatch({ type: 'JIRA_RECEIVED_CONNECTIONS', connections: connectionsMap["jira"] as JiraStore.JiraConnectionInfo[] || [] });
                dispatch({ type: 'SPO_RECEIVED_CONNECTIONS', connections: connectionsMap["spo"] || [] });
                dispatch({ type: 'VSTS_RECEIVED_CONNECTIONS', connections: connectionsMap["vsts"] || [] });
                dispatch({ type: 'RECEIVED_PLANNER_CONNECTIONS', connections: connectionsMap["office365"] || [] });
                dispatch({ type: 'MONDAYCOM_RECEIVED_CONNECTIONS', connections: connectionsMap["mondaycom"] || [] });
                dispatch({ type: 'SMARTSHEET_RECEIVED_CONNECTIONS', connections: connectionsMap["smartsheet"] || [] });
                dispatch({ type: 'P4W_RECEIVED_CONNECTIONS', connections: connectionsMap["p4w"] || [] });
            })
            .catch(defaultCatch(dispatch));
    }
};

export const reducer: Reducer<MetadataState> = (state: MetadataState = INIT_STATE, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "METADATA_LOADING_STATE_CHANGED": return { ...state, ...action.data };
    }
    return state;
};