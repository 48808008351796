import { PivotItem } from "office-ui-fabric-react";
import * as React from "react";
import { connect } from "react-redux";
import { get } from "../../../fetch-interceptor";
import { ApplicationState } from "../../../store";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { ExternalFieldInfo, IExternalTypeWithStatus, TaskImportSettings } from "../../../store/integration/common";
import { IConnectionConfiguration, actionCreators, mondayComFieldToPpmxFieldsMap, IProgressCalculationSettings} from "../../../store/integration/MondayComStore";
import { CommonOperations, contains } from "../../../store/permissions";
import { UserState } from "../../../store/User";
import { notEmpty } from "../../utils/common";
import ConfigureConnectionPanel from "../ConfigureConnectionPanel";
import ImportTaskSettings from "../ImportTaskSettings";
import MondayComProgressCalculationSettings from "./MondayComProgressCalculationSettings";

const itemType: IExternalTypeWithStatus = {
    id: "Task",
    name: "Task",
    statuses: []
};

const subitemType: IExternalTypeWithStatus = {
    id: "Sub-task",
    name: "Sub-task",
    statuses: []
};

export enum MondayComTabs {
    ProgressCalculation = "progress-calculation",
    ImportTask = "import-task"
}


const onSaveProgressCalculationSettings = (props: Props, _: IProgressCalculationSettings) => {
    const { connectionId, itemBoardId, configuration } = props;
    if (connectionId && itemBoardId){
        const newConfig = { ...configuration, progressCalculationSettings: _ };
        props.updateConnectionConfiguration(connectionId, itemBoardId, newConfig);
    }}
const getPivots = (props: Props) => {
    const { connectionId, itemBoardId, subItemBoardId, mondayComItemFields, mondayComSubItemFields, configuration, defaultTaskSettings, 
        user, error, isLoading, isProcessing, onDismiss, updateConnectionConfiguration } = props;

    const isReadOnly = !contains(user.permissions.common, CommonOperations.ConnectionManage);
    
    return [
        <PivotItem headerText="Project Progress" className="pivot-content" itemKey={MondayComTabs.ProgressCalculation}  key="progress">
            <MondayComProgressCalculationSettings            
                types={[itemType, subitemType]}
                progressCalculationSettings={configuration.progressCalculationSettings}
                readOnly={isReadOnly}
                error={error}
                isLoading={isLoading}
                isProcessing={isProcessing}
                onDismiss={onDismiss}
                onSave={_ => onSaveProgressCalculationSettings(props, _)}/>
        </PivotItem>,
        <PivotItem headerText="External Items Mapping" className="pivot-content" itemKey={MondayComTabs.ImportTask} key="tasks">
            <ImportTaskSettings
                sourceType={SourceType.MondayCom}
                typeLabel="task"
                getExternalFields={(type: IExternalTypeWithStatus) => type.id === itemType.id 
                    ? mondayComItemFields
                    : type.id === subitemType.id 
                        ? mondayComSubItemFields
                        : []}
                externalTypes={[itemType, subitemType]}
                taskImportSettings={configuration.taskImportSettings}
                readOnly={isReadOnly}
                onDismiss={onDismiss}
                onSave={(taskImportSettings: TaskImportSettings) => updateConnectionConfiguration(connectionId, itemBoardId, { ...configuration, taskImportSettings })}
                getDefaultTaskImportmapping={(type) => defaultTaskSettings[type]}
                externalFieldToPpmxFieldsMap={mondayComFieldToPpmxFieldsMap}
                error={error}
                isLoading={isLoading}
                isProcessing={isProcessing}
                helpLink="https://help.ppm.express/mondaycom-connection/1423975"
                onGetExternalFieldOptions={onGetExternalFieldOptions}
            />
        </PivotItem>
    ];

    function onGetExternalFieldOptions(field: ExternalFieldInfo, issueTypeId: string) {
        const boardId = getBoardId(issueTypeId);
        return get<{ options: string[] | number[] | null }>(`api/integration/mondaycom/field/${field.internalName}/options`, { connectionId, boardId })
            .then(data => data.options);
    }

    function getBoardId(issueTypeId: string): number {
        return issueTypeId === itemType.id
            ? itemBoardId
            : subItemBoardId!;
    }
}

type OwnProps = {
    onDismiss: () => void;
    connectionId: string;
    itemBoardId: number;
    subItemBoardId?: number | null;
    initialTab?: string;
}

type StateProps = {
    configuration: IConnectionConfiguration;
    defaultTaskSettings: TaskImportSettings;
    mondayComItemFields: ExternalFieldInfo[];
    mondayComSubItemFields: ExternalFieldInfo[];
    user: UserState;
    isLoading: boolean;
    isProcessing: boolean;
    error: string | null;
}

type ActionProps = typeof actionCreators;

type Props = OwnProps & StateProps & ActionProps;

const MondayComConfigureConnectionPanel = (props: Props) => {
    const {connectionId, itemBoardId, subItemBoardId, loadDefaultTaskMappings, loadBoardFields, loadSubItemsBoardDefaultFields,
        loadConnectionConfiguration } = props;

    React.useEffect(() => {
        loadDefaultTaskMappings(connectionId, itemBoardId, subItemBoardId);        
        loadBoardFields(connectionId, [itemBoardId, subItemBoardId].filter(notEmpty));
        !subItemBoardId && loadSubItemsBoardDefaultFields();
        loadConnectionConfiguration(connectionId, itemBoardId, subItemBoardId);  
    }, [props.connectionId]);

    return <ConfigureConnectionPanel
        onDismiss={props.onDismiss}
        initialTab={props.initialTab}
        sourceType={SourceType.MondayCom}
        pivots={getPivots(props)}/>;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    return {
        configuration: {
            progressCalculationSettings: state.mondayCom.progressCalculationSettings.entities,
            taskImportSettings: state.mondayCom.taskImportSettings.entities
        },
        defaultTaskSettings: state.mondayCom.defaultTaskSettings.entities,
        mondayComItemFields: state.mondayCom.fieldsByBoardId.entities[ownProps.itemBoardId] || [],
        mondayComSubItemFields: ownProps.subItemBoardId 
            ? state.mondayCom.fieldsByBoardId.entities[ownProps.subItemBoardId] 
            : state.mondayCom.subItemDefaultFields.entities,
        user: state.user,
        error: state.mondayCom.fieldsByBoardId.error || 
                state.mondayCom.defaultTaskSettings.error || 
                state.mondayCom.progressCalculationSettings.error ||
                state.mondayCom.taskImportSettings.error ||
                state.mondayCom.subItemDefaultFields.error,
        isLoading: state.mondayCom.fieldsByBoardId.isLoading || 
                state.mondayCom.defaultTaskSettings.isLoading || 
                state.mondayCom.progressCalculationSettings.isLoading ||
                state.mondayCom.taskImportSettings.isLoading ||
                state.mondayCom.subItemDefaultFields.isLoading,
        isProcessing: state.mondayCom.progressCalculationSettings.isProcessing
    }
}
export default connect(mapStateToProps, actionCreators)(MondayComConfigureConnectionPanel);