import * as React from 'react';
import { Link } from 'office-ui-fabric-react';
import { post } from '../../fetch-interceptor';
import { UserState as IUserState } from '../../store/User';
import { ApplicationState } from '../../store';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Notifications from "../../store/NotificationsStore";
import { bindActionCreators } from 'redux';
import FeedbackDialog from './FeedbackDialog';

type OwnProps = {
    subject: string;
}

type StateProps = {
    user: IUserState
}

type ActionProps = {
    notificationsActions: typeof Notifications.actionCreators,
}

type Props = StateProps & OwnProps & ActionProps & RouteComponentProps<{}>;

const LeaveFeedbackLink = (props: Props) => {
    const [showDialog, setShowDialog] = React.useState<boolean>();

    const _onSave = (comments: string, followUp: boolean) => {
        const data = { comments, followUp, email: props.user.email, name: props.user.name };
        post("api/feedback", data)
            .then(_ => {
                setShowDialog(false);
                props.notificationsActions.pushNotification({ message: `Thank you for providing your feedback!`, type: Notifications.NotificationType.Success });                
            })
            .catch(_ => setShowDialog(false));
    }

    return <>
        <Link className="leave-feedback-link" title="Feedback" onClick={() => setShowDialog(true)}>
            <small>Leave feedback</small>
        </Link>
        {
            showDialog && <FeedbackDialog subject={props.subject} showfollowUp={true} onDismiss={() => setShowDialog(false)}
                onSave={_onSave} />
        }
    </>
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps,
    (dispatch): ActionProps =>
    ({
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
    }))(LeaveFeedbackLink));
