import * as React from 'react';
import { IconButton, css } from "office-ui-fabric-react";
import { copyToClipboard, copyToClipboardWithFormatting } from "./common";

const COPIED_CHECKMARK_DURATION = 1000;

export const CopyButton = (props: { text: string }) => {
    const [isCopied, handleCopy] = useCopyToClipboard();

    return <IconButton
        iconProps={{ iconName: isCopied ? 'CheckMark' : 'Copy' }}
        title="Copy to clipboard"
        onClick={() => handleCopy(props.text)}
    />;
}

export const CopyIcon = (props: { text: string, disabled?: boolean, withFormat?: boolean, className?: string }) => {
    const {text, disabled, withFormat, className} = props;    
    const [isCopied, handleCopy] = useCopyToClipboard();

    return <IconButton
        className={css("copy-icon", className)}
        disabled={disabled}
        iconProps={{iconName: isCopied ? "CheckMark" : "Copy"}}
        title="Copy to clipboard"
        onClick={(e) => handleCopy(text, withFormat)} />
}

export function useCopyToClipboard() {
    const [isCopied, setCopied] = React.useState(false);

    React.useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        if (isCopied) {
            timeout = setTimeout(() => setCopied(false), COPIED_CHECKMARK_DURATION);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied, COPIED_CHECKMARK_DURATION]);

    function handleCopy(text: string, withFormat?: boolean) {
        withFormat ? copyToClipboardWithFormatting(text) : copyToClipboard(text);
        setCopied(true);
    }

    return [isCopied, handleCopy] as const;
}