import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { bindActionCreators } from "redux";
import {
    IPersonalO365TimeTrackingConnection,
    actionCreators as PersonalOffice365Actions
} from "../../../store/integration/PersonalOffice365Store";
import TimeTrackingO365Connector from "./TimeTrackingO365Connector";
import { Icon, IconButton } from "office-ui-fabric-react";

import "./TimeTrackingO365Connector.css";
import { isInReadonlyMode } from "../../../store/User";

type StateProps = {
    validationError: string | null;
    connection: IPersonalO365TimeTrackingConnection | null;
    enableO365Connector: boolean;
    isReadonly: boolean;
};

type ActionProps = {
    personalOffice365Actions: typeof PersonalOffice365Actions;
};

type OwnProps = {};
type Props = StateProps & ActionProps & OwnProps;

function TimeTrackingConnectionButtons(props: Props) {
    const { enableO365Connector, connection, validationError, isReadonly } = props;
    const [isConnectingO365, setIsConnectingO365] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);

    useEffect(() => {
        if (enableO365Connector) {
            props.personalOffice365Actions.loadConnection();
        }
    }, [enableO365Connector]);

    useEffect(() => {
        if (props.connection) {
            props.personalOffice365Actions.verifyConnection();
        }
    }, [!!props.connection]);

    const isInvalid = !!validationError;

    if (!enableO365Connector) {
        return (<></>)
    };

    return (
        <div className="time-tracking-connect-buttons">
            <div className="connect-buttons">
                {isInvalid && (
                    <IconButton
                        iconProps={{ iconName: "Refresh" }}
                        style={{ color: "red" }}
                        title="Refresh Microsoft 365 Connection"
                        disabled={isReadonly}
                        onClick={() => {
                            setIsRefresh(true);
                            setIsConnectingO365(true);
                        }}
                    />
                )}
                {!!connection && (
                    <Icon
                        iconName="M365Logo"
                        className={`o365 ${isInvalid ? "invalid" : ""}`}
                        title={validationError || `Linked to ${connection.name}`}
                    />
                )}

                <IconButton
                    iconProps={{ iconName: "PPMXLink" }}
                    className="connect-launcher"
                    title="Configure Microsoft 365 Connection"
                    disabled={isReadonly}
                    onClick={() => setIsConnectingO365(true)}
                />
            </div>
            {isConnectingO365 && (
                <TimeTrackingO365Connector
                    forceRefresh={isRefresh}
                    onDismiss={() => {
                        setIsRefresh(false);
                        setIsConnectingO365(false);
                    }}
                />
            )}
        </div>
    );
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        connection: state.personalOffice365.timeTrackingConnection,
        validationError: state.personalOffice365.validationError,
        enableO365Connector: state.tenant.timeTracking.globalSettings.enableO365Connector,
        isReadonly: isInReadonlyMode(state.user, state.tenant)
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        personalOffice365Actions: bindActionCreators(PersonalOffice365Actions, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(TimeTrackingConnectionButtons);
