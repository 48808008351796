import "./QuickStart.css";
import * as React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'office-ui-fabric-react';
import { ApplicationState } from '../../../store';
import { PPMFeatures, Subscription } from '../../../store/Tenant';
import { UserState } from "../../../store/User";
import QuickStartTeamMember from "./QuickStartTeamMember";
import QuickStartRegular from "./QuickStartRegular";
import { LicenseType } from "../../../store/permissions";
import { ExtensionInfo, ExtensionLocation } from "../../../store/ExtensionStore";
import { EmbeddedContent } from "../../common/EmbeddedContent";
import Header from "./Header/Header";

type StateProps = {
    user: UserState;
    subscription: Subscription;
    extension: ExtensionInfo | undefined;
};

const DefaultQuickStart = (props: StateProps) => {
    const { user, subscription } = props;

    const productType = subscription.productType;
    const isTrial = subscription.isTrial;
    const hasPartnersSupport = Subscription.contains(subscription, PPMFeatures.PartnersSupport);
    const hasAiInsights = Subscription.contains(subscription, PPMFeatures.AiInsights);
    const hasProjectManagement = Subscription.contains(subscription, PPMFeatures.ProjectManagement);
    const hasIdeation = Subscription.contains(subscription, PPMFeatures.Ideation);

    return <div className="quick-start-body">
        <div className="description">
            Hi there! This is your quick start guide to help
            you {isTrial ? 'make the most out of your free trial on the' : 'navigate quickly around your'} {subscription.plan} plan.
            Start exploring now or come back to it later using the <Icon iconName="PPMXQuickStart" /> icon in the bottom left corner.
        </div>
        <div className="content">
            {user.license === LicenseType.Regular
                ? <QuickStartRegular
                    isTrial={isTrial}
                    isConnectionAvailable={Subscription.hasSyncableIntegration(subscription)}
                    productType={productType}
                    hasPartnersSupport={hasPartnersSupport}
                    hasAiInsights={hasAiInsights} 
                    hasProjectManagement={hasProjectManagement}
                    hasIdeation={hasIdeation}/>
                : <QuickStartTeamMember 
                    isTrial={isTrial} 
                    hasPartnersSupport={hasPartnersSupport} 
                    hasAiInsights={hasAiInsights} />}
        </div>
    </div>
}

const QuickStart = (props: StateProps) => {
    return <div className="quick-start">
        <Header />
        {props.extension
            ? <EmbeddedContent url={props.extension.url} />
            : <DefaultQuickStart {...props} />}
    </div>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        subscription: state.tenant.subscription,
        extension: state.extensions.entities.find(_ => _.location === ExtensionLocation.QuickStart),
    };
}
export default connect(mapStateToProps)(QuickStart);
