import * as React from 'react';
import { Link } from 'office-ui-fabric-react';
import { ServerEntityType } from "../../entities/common";
import { IRoadmapItem } from '../../entities/Subentities';

export const RoadmapItemOrigin = (props: { entity: IRoadmapItem }): JSX.Element => {
    const { entity } = props;
    const originIsDeleted = entity.externalData?.["OriginIsDeleted"];
    const entityName = entity.externalData?.["ImportedFromName"];
    const entityType = entity.externalData?.["ImportedFromTypeLabel"] || ServerEntityType[entity.externalData?.["ImportedFromType"]];
    const entityLink = entity.externalData?.["NameLink"];
    const parentEntityName = entity.externalData?.["ImportedFromParentName"];
    const parentEntityType = entity.externalData?.["ImportedFromParentType"];
    const parentEntityLink = entity.externalData?.["ImportedFromParentNameLink"];

    if (originIsDeleted) {
        return <span>
            <span>{entityType} "{entityName}"</span>
            {parentEntityName && <span> from {ServerEntityType[parentEntityType]} "{parentEntityName}"</span>}
            <span> was deleted or archived</span>
        </span>;
    }

    return <span>
        <span>{entityType} </span>
        <Link href={entityLink} target="_blank" onClick={_ => _.stopPropagation()}>
            "{entityName}"
        </Link>
        {parentEntityName && parentEntityLink
            && <>
                <span> from {ServerEntityType[parentEntityType]} </span>
                <Link href={parentEntityLink} target="_blank" onClick={_ => _.stopPropagation()}>
                    "{parentEntityName}"
                </Link>
            </>}
    </span>
}
