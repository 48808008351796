import * as React from 'react';
import { SourceType } from "../../../../store/ExternalEpmConnectStore";
import O365OrganizationConnectControl from '../../../integration/Office365/Organization/O365OrganizationConnectControl';
import ExternalSystemChoice from '../../../common/ExternalSystemChoice';
import { Dictionary, PANEL_CUSTOM_WIDTH } from '../../../../entities/common';
import { Panel, PanelType } from 'office-ui-fabric-react';

type OwnProps = {
    actions: Dictionary<any>;
    sources: SourceType[];
    dismissPanel: () => void;
}

type ConnectProps = { actions: any; dismissPanel: () => void; }
type RendersMap = { [key in SourceType]?: (props: ConnectProps) => JSX.Element } & { types: SourceType[] }
export const rendersMap: RendersMap = {
    [SourceType.O365User]: _ => <O365OrganizationConnectControl {..._} />,
    types: [SourceType.O365User]
}

const OrganizationLinkPanel = (props: OwnProps) => {
    const [selectedConnector, setSelectedConnector] = React.useState(props.sources[0]!);

    const onRenderHeader = React.useCallback(() => (
        <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Link Organization</p>
            <div className='ms-Panel-secondaryText'>Choose the Office 365 organization to link with your PPM Express tenant.</div>
        </div>), []
    );
    
    const renderConnectControl = React.useCallback((connector: SourceType) => {
        return rendersMap[connector]?.({
            actions: props.actions[connector],
            dismissPanel: props.dismissPanel,
        });
    }, [rendersMap, props.actions, props.dismissPanel]);

    return <Panel
        isLightDismiss
        type={PanelType.custom}
        customWidth={PANEL_CUSTOM_WIDTH}
        className="connect-panel"
        isOpen={true}
        onDismiss={props.dismissPanel}
        onRenderHeader={onRenderHeader}>
        <ExternalSystemChoice
            sources={props.sources}
            selected={selectedConnector!}
            onChanged={_ => setSelectedConnector(_)}
            sourceInfos={[]}
        />
        {selectedConnector !== undefined && renderConnectControl(selectedConnector)}
    </Panel>;
}

export default OrganizationLinkPanel;