import * as React from "react";

export interface IProps<TData> {
    from?: string;
    validateMessage?: (e: MessageEvent) => boolean;
    onMessageReceived: (e: MessageEvent, data: TData) => void;
}


export interface PostMessageContext {
    connectionId: string;
}

export interface PostMessageDataBase<T extends PostMessageContext> {
    context: T;
}

export interface PostMessageData extends PostMessageDataBase<PostMessageContext> {
}

export default class PostMessageReceiver<TData> extends React.Component<IProps<TData>>{
    public render() {
        return null;
    }

    componentDidMount() {
        window.addEventListener('message', this._onReceivedNewConnectionMessageEvent, true);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this._onReceivedNewConnectionMessageEvent, true);
    }

    private _onReceivedNewConnectionMessageEvent = (e: MessageEvent): void => {
        if (this.props.validateMessage && !this.props.validateMessage(e))
            return;

        if (e.data) {
            let data: any;
            try {
                data = JSON.parse(e.data);
            }
            catch (e) {
                return;
            }

            if (this.props.from === undefined || data?.source === this.props.from) {
                this.props.onMessageReceived(e, data);
            }
        }
    }

    public static openPopupWindow(url: string, target: string, size?: { width?: number, height?: number }) {
        let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        let dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

        let width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : window.screen.width;
        let height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : window.screen.height;

        let popupSize = Object.assign({ width: 500, height: 700 }, size);
        let left = ((width / 2) - (popupSize.width / 2)) + dualScreenLeft;
        let top = ((height / 2) - (popupSize.height / 2)) + dualScreenTop;

        return window.open(url, target, 'width=' + popupSize.width + ', height=' + popupSize.height + ', top=' + top + ', left=' + left);
    }
}