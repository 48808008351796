import { IEntityStore, StoreHelper, partialUpdate } from "./services/storeHelper";
import { AppThunkAction } from ".";
import { post } from "../fetch-interceptor";
import { defaultCatch, catchApiError } from "./utils";
import { Reducer, Action } from "redux";
import { EntityType, IBaseEntity } from "../entities/common";
import { IRoadmapItem, ISubentity, ITask, sortTasksByRank } from "../entities/Subentities";
import fileDownload from 'js-file-download';
import * as Notifications from "./NotificationsStore";
import { UpdatedTasksCalculation, defaultActionCreators as projectActionCreators } from "./ProjectsListStore";
import { Group } from "../entities/Metadata";
import { KnownAction as GroupsKnownAction } from "./groups";
import { ResourcesLoadedAction, ServerResource } from "./ResourcesListStore";
import { Idea, IdeasLoadedPartAction } from "./IdeasListStore";
import { UpdateChallengeIdeasAction } from "./ChallengesListStore";
import { ROADMAPITEM_LABEL_NAMESPACE, ROADMAPITEM_LANE_NAMESPACE, KnownAction as RoadmapKnownAction } from "./RoadmapsListStore";

export function ImportExportFactory<TNamespace, TTypeInfo extends IBaseEntity, TListState extends IEntityStore<TTypeInfo>>
    (namespace: TNamespace, entityType: EntityType) {

    interface AddEntities {
        namespace: TNamespace;
        type: "ADD_ENTITIES";
        entities: TTypeInfo[];
    }

    interface AddSubEntities {
        namespace: TNamespace;
        type: "ADD_SUBENTITIES";
        entityId: string;
        subEntityCollectionName: string;
        subEntities: ISubentity[];
        sortByRank?: boolean;
    }

    type KnownAction =
        | AddEntities
        | AddSubEntities;

    type ImportSubentityKnownAction =
        | KnownAction
        | Notifications.KnownAction
        | UpdatedTasksCalculation
        | GroupsKnownAction
        | IdeasLoadedPartAction
        | UpdateChallengeIdeasAction
        | RoadmapKnownAction;

    type TasksImportResult = {
        tasks: ITask[];
        groups: Group[];
    };

    type RoadmapItemsImportResult = {
        items: IRoadmapItem[];
        lanes: Group[];
        labels: Group[];
    };

    const importExportActionCreators = {
        exportEntitiesToFile: (pluralEntityTypeLabel: string, fields: string[], ids: string[]): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                post<string>(`api/${entityType}/exportToFile`, { fields, ids })
                    .then(data => fileDownload(data, `${pluralEntityTypeLabel}.csv`))
                    .catch(defaultCatch(dispatch));
            },
        exportSubEntitiesToFile: (entityId: string, subentityType: EntityType, pluralSubentityTypeLabel: string, fields: string[], ids: string[]): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                post<string>(`api/${entityType}/${entityId}/${subentityType}/exportToFile`, { fields, ids })
                    .then(data => fileDownload(data, `${pluralSubentityTypeLabel}.csv`))
                    .catch(defaultCatch(dispatch));
            },
        importEntitiesFromFile: (file: File): AppThunkAction<KnownAction | Notifications.KnownAction | ResourcesLoadedAction> =>
            (dispatch, getState) => {
                const formData = new FormData();
                formData.set('data', file);
                post<TTypeInfo[]>(`api/${entityType}/importFromFile`, formData)
                    .then(data => {
                        if (entityType === EntityType.Resource) {
                            dispatch({ type: "RESOURCES_LOADED", resources: data as unknown as ServerResource[], part: true });
                        } else {
                            dispatch({ namespace, type: 'ADD_ENTITIES', entities: data });
                        }
                    })
                    .catch(catchApiError(_ => {
                        dispatch(Notifications.actionCreators
                            .pushNotification({ message: `Failed to import. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
                    }));
            },
        importSubEntitiesFromFile: (entityId: string, subentityType: EntityType, subEntityCollectionName: string, file: File): AppThunkAction<ImportSubentityKnownAction> =>
            (dispatch, getState) => {
                const formData = new FormData();
                formData.set('data', file);
                post<TasksImportResult | ISubentity[] | Idea[] | RoadmapItemsImportResult>(`api/${entityType}/${entityId}/${subentityType}/importFromFile`, formData)
                    .then(data => {
                        if (subentityType === EntityType.Task) {
                            const resultData = data as TasksImportResult;
                            dispatch({ namespace, type: 'ADD_SUBENTITIES', entityId, subEntityCollectionName, subEntities: resultData.tasks, sortByRank: true });
                            dispatch({ namespace: "externaltask/group", type: 'RECEIVED_GROUPS', entityId: entityId, groupsItems: resultData.groups });
                            projectActionCreators.refreshProjectTaskCalculation(EntityType.Project, entityId)(dispatch);
                        } else if (subentityType === EntityType.Idea) {
                            const ideas = data as Idea[];
                            dispatch({
                                type: 'UPDATE_CHALLENGE_IDEAS',
                                id: entityId,
                                ideaIds: ideas.map(_ => _.id),
                                isAdd: true
                            });
                            dispatch({ type: 'RECEIVED_IDEAS_PART', ideas });
                        } else if (subentityType === EntityType.RoadmapItem) {
                            const resultData = data as RoadmapItemsImportResult;
                            dispatch({ namespace: ROADMAPITEM_LANE_NAMESPACE, type: 'RECEIVED_GROUPS', entityId: entityId, groupsItems: resultData.lanes });
                            dispatch({ namespace: ROADMAPITEM_LABEL_NAMESPACE, type: 'RECEIVED_GROUPS', entityId: entityId, groupsItems: resultData.labels });
                            dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId: entityId, addOrUpdate: resultData.items, sortByPlanStateRank: true })
                        } else {
                            dispatch({ namespace, type: 'ADD_SUBENTITIES', entityId, subEntityCollectionName, subEntities: data as ISubentity[] });
                        }
                    })
                    .catch(catchApiError(_ => {
                        dispatch(Notifications.actionCreators
                            .pushNotification({ message: `Failed to import. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
                    }));
            }
    };

    const importExportReducer: Reducer<TListState> = (state: TListState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;
        if (action.namespace !== namespace)
            return state;

        switch (action.type) {
            case 'ADD_ENTITIES':
                {
                    return {
                        ...state,
                        ...StoreHelper.union(state, action.entities)
                    };
                }
            case 'ADD_SUBENTITIES':
                {
                    return StoreHelper.applyHandler(state, action.entityId,
                        (entity: TTypeInfo) => {
                            const items = Object.values([...(((entity as any)[action.subEntityCollectionName]) as ISubentity[]), ...action.subEntities]
                                .reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {}));
                            if (action.sortByRank) {
                                items.sort((a: ITask, b: ITask) => sortTasksByRank(a, b));
                            }
                            return partialUpdate(entity, { [action.subEntityCollectionName]: items } as unknown as Partial<TTypeInfo>);
                        });
                }
            default:
                return state;
        }
    };

    return {
        importExportActionCreators,
        importExportReducer
    };
}