import React from "react";
import { IInsightsStatusData } from "../../../../../entities/common";
import CategoryColorStatusView from "../../../../views/list/columns/CategoryColorStatusView";
import { Props as StatusHistoryProps } from "./StatusHistory";
import ResetStatusButton from "./ResetStatusButton";
import { StatusInsights } from "../../../../views/list/columns/ColorStatus";
import { Field } from "../../../../../entities/Metadata";
import { CategoryStatusOption } from "../../../../../entities/StatusDescriptor";

export type StatusViewProps = {
    statusOption: CategoryStatusOption | undefined;
    statusField: Field;
    insights: IInsightsStatusData | undefined;
    description?: string;
    resetStatus?: () => void;
}

type Props = StatusViewProps & StatusHistoryProps;

const StatusView = (props: Props) => {
    const { statusOption, statusField, description, insights, resetStatus } = props;
    
    return (
        <div className="status-card">
            <div className="align-center">
                <div className="title">Status</div>
                <CategoryColorStatusView statusOption={statusOption} />
            </div>
            {description && <div className="description-wrapper" style={{ borderLeftColor: statusOption?.color }}>
                <div className="description">
                    {description}
                </div>
            </div>}
            <div className="status-change-history">
                <div className="status-change-history-info">
                    <StatusInsights insights={insights} />
                </div>
                {
                    resetStatus && <ResetStatusButton
                        statusField={statusField}
                        insights={insights}
                        resetStatus={resetStatus}
                    />
                }
            </div>
        </div>
    );
}

export default StatusView;
