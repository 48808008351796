import * as Metadata from "../../entities/Metadata";
import { AppThunkAction } from "..";
import { catchApiError, defaultCatch } from "../utils";
import { get, post, remove } from "../../fetch-interceptor";
import { Action, Reducer } from "redux";

export interface PersonalO365ConnectionState{
    isLoading: boolean;
    timeTrackingConnection: IPersonalO365TimeTrackingConnection | null;
    isValidating: boolean,
    isProcessing: boolean;
    validationError: string | null;
    error: string | null;
}

const unloadedState: PersonalO365ConnectionState = {
    timeTrackingConnection: null,
    isLoading: false,
    isProcessing: false,
    isValidating: false,
    validationError: null,
    error: null
};

export interface IPersonalO365TimeTrackingConnection extends Metadata.IConnectionInfo {
    id: string;
    title: string;
    name: string;
    readers: Office365TimeTrackingConnectorReaders;
};

export enum Office365TimeTrackingConnectorReaders {
    None = 0x0,

    RecentDocuments = 0x1,
    SentOutlookEmails = 0x2,
    CompletedOutlookCalendarEmails = 0x4,
    OutlookCalendarEvents = 0x8,
    TentativeCalendarEvents = 0x10,
    OutlookTasks = 0x20
}

export const Office365TimeTrackingConnectorReadersLabelsMap: Map<Office365TimeTrackingConnectorReaders, string> = new Map([
    [Office365TimeTrackingConnectorReaders.RecentDocuments, "Recent documents"],
    [Office365TimeTrackingConnectorReaders.SentOutlookEmails, "Sent Outlook Emails"],
    [Office365TimeTrackingConnectorReaders.CompletedOutlookCalendarEmails, "Completed Outlook emails"],
    [Office365TimeTrackingConnectorReaders.OutlookCalendarEvents, "Outlook Calendar events"],
    [Office365TimeTrackingConnectorReaders.TentativeCalendarEvents, "Tentative Calendar events"],
    [Office365TimeTrackingConnectorReaders.OutlookTasks, "Outlook tasks"],
]);

export const GetSuggestionTypeLabel = (type: Office365TimeTrackingConnectorReaders): string => {
    return Office365TimeTrackingConnectorReadersLabelsMap.get(type)!;
}

export const GetSuggestionTypeByLabel = (label: string): Office365TimeTrackingConnectorReaders => {
    let result = Office365TimeTrackingConnectorReaders.OutlookCalendarEvents;

    Office365TimeTrackingConnectorReadersLabelsMap.forEach((value, key) => {
        if (value === label) {
            result = key;
        }
    });

    return result;
}

type UpdateSettings = { readers: Office365TimeTrackingConnectorReaders };

enum ActionTypes {
    LOAD_TIMETRACKING_CONNECTION = "PERSONAL_O365_LOAD_TIMETRACKING_CONNECTION",
    RECEIVED_TIMETRACKING_CONNECTION = "PERSONAL_O365_RECEIVED_TIMETRACKING_CONNECTION",
    VERIFY_TIMETRACKING_CONNECTION = "PERSONAL_O365_VERIFY_TIMETRACKING_CONNECTION",
    CONNECTION_VERIFIED = "PERSONAL_O365_CONNECTION_VERIFIED",
    CONNECTION_VERIFICATION_ERROR = "PERSONAL_O365_CONNECTION_VERIFICATION_ERROR",
    SETTINS_UPDATED = "PERSONAL_O365_TIMETRACKING_SETTINGS_UPDATED"
}

type LoadConnections = { type: ActionTypes.LOAD_TIMETRACKING_CONNECTION };
type ReceivedConnections = { type: ActionTypes.RECEIVED_TIMETRACKING_CONNECTION, connection: IPersonalO365TimeTrackingConnection | null };
type VerifyConnection = { type: ActionTypes.VERIFY_TIMETRACKING_CONNECTION };
type ConnectionVerified = { type: ActionTypes.CONNECTION_VERIFIED };
type ConnectionVerificationError = { type: ActionTypes.CONNECTION_VERIFICATION_ERROR, error: string };
type SettingsUpdate = { type: ActionTypes.SETTINS_UPDATED, settings: UpdateSettings };

type ConnectionAction = LoadConnections
    | ReceivedConnections
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError
    | SettingsUpdate;

type KnownAction = ConnectionAction;

export const actionCreators = {
    loadConnection: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.LOAD_TIMETRACKING_CONNECTION });
        get<IPersonalO365TimeTrackingConnection>(`api/timetracking/integration/office365/connection`)
            .then(_ => dispatch({ type: ActionTypes.RECEIVED_TIMETRACKING_CONNECTION, connection: _ }))
            .catch(defaultCatch(dispatch));
    },
    verifyConnection: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.VERIFY_TIMETRACKING_CONNECTION });
        get(`api/timetracking/integration/office365/verify-connection`)
            .then(_ => dispatch({ type: ActionTypes.CONNECTION_VERIFIED }))
            .catch(catchApiError(_ => dispatch({ type: ActionTypes.CONNECTION_VERIFICATION_ERROR, error: _ })));
    },
    deleteConnection: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<IPersonalO365TimeTrackingConnection>(`api/timetracking/integration/office365/connection`)
            .then(_ => dispatch({ type: ActionTypes.RECEIVED_TIMETRACKING_CONNECTION, connection: null }))
            .catch(defaultCatch(dispatch));
    },
    saveSettings: (settings: UpdateSettings): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<any>(`api/timetracking/integration/office365/connection/settings`, settings)
            .then(_ => dispatch({ type: ActionTypes.SETTINS_UPDATED, settings: settings }))
            .catch(defaultCatch(dispatch));
    }
}

export const reducer: Reducer<PersonalO365ConnectionState> = (state: PersonalO365ConnectionState = unloadedState, incomingAction: Action): PersonalO365ConnectionState  => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.LOAD_TIMETRACKING_CONNECTION:
            {
                return {
                    ...state,
                    isLoading: true
                };
            }
        case ActionTypes.RECEIVED_TIMETRACKING_CONNECTION:
            {
                return {
                    ...state,
                    timeTrackingConnection: action.connection,
                    isValidating: false,
                    validationError: null,
                    isLoading: false
                };
            }
        case ActionTypes.VERIFY_TIMETRACKING_CONNECTION:
            {
                return {
                    ...state,
                    isValidating: true,
                    validationError: null
                }
            }
        case ActionTypes.CONNECTION_VERIFIED:
            {
                return {
                    ...state,
                    isValidating: false,
                    validationError: null
                }
            }
        case ActionTypes.CONNECTION_VERIFICATION_ERROR:
            {
                return {
                    ...state,
                    validationError: action.error,
                    isValidating: false
                }
            }
        case ActionTypes.SETTINS_UPDATED:
            {
                return {
                    ...state,
                    timeTrackingConnection: {
                        ...state.timeTrackingConnection!,
                        readers: action.settings.readers
                    },
                    isValidating: false
                }
            }
        default:
            return state;
    }

}