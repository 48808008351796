import * as React from 'react';
import { formatValue, HUNDRED_PCT, roundToHundreds } from '../../../utils/common';
import { DailyUsage, filterByEntityAndDatesUsage, Resource } from '../../../../store/ResourcesListStore';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { CalendarDataSet } from '../../../../store/CalendarStore';
import { TimeType } from './CellMenu';
import { calculateSummary } from './Cell';
import { getWorkingHoursBetweenDates } from '../../../common/timeline/utils';
import { ViewType } from '../ResourceUsageGrid';
import { EntityType, mapServerEntityType } from '../../../../entities/common';
import CellValue from './CellValue';
import { usePrevious } from '../../../utils/effects';
import { arraysEqual } from 'office-ui-fabric-react';

interface StateProps {
    calendar: CalendarDataSet;
    resourcePlanningLevel: EntityType;
}
interface OwnProps {
    resources: Resource[];
    entityIds: string[];
    entityType: EntityType;
    startDate: Date;
    finishDate: Date;
    timeType: TimeType[];
    viewType: ViewType;
}

type Props = StateProps & OwnProps;
interface State {
    plan: number;
    actual: number;
    capacity: number;
    availability: number;
    planPercent?: number;
    actualPercent?: number;
    capacityPercent?: number;
    availabilityPercent?: number;
    planFTE?: number;
    actualFTE?: number;
    capacityFTE?: number;
    availabilityFTE?: number;
}

const TotalUsageCell = (props: Props) => {
    const [state, setState] = React.useState<State>({plan: 0, actual: 0, capacity: 0, availability: 0});
    const prevProps = usePrevious(props);
    React.useEffect(        
        () => {
            if(!prevProps || !arraysEqual(props.entityIds, prevProps.entityIds)
                || props.calendar !== prevProps.calendar
                || props.resources !== prevProps.resources
                || props.startDate.getTime() !== prevProps.startDate.getTime()
                || props.finishDate.getTime() !== prevProps.finishDate.getTime()
                || props.entityType !== prevProps.entityType
                || props.resourcePlanningLevel !== prevProps.resourcePlanningLevel
            ){
                setState(buildState(props));
            }
        }, 
        [props]);

    const { timeType, viewType } = props;
    const { plan, actual, capacity, availability, planPercent, actualPercent, capacityPercent,
        availabilityPercent, planFTE, actualFTE, capacityFTE, availabilityFTE } = state;

    const unit = viewType === ViewType.Percent ? '%' : viewType === ViewType.FTE ? 'FTE' : 'hours';
    const planValue = viewType === ViewType.Percent ? planPercent : viewType === ViewType.FTE ? planFTE : plan;
    const actualValue = viewType === ViewType.Percent ? actualPercent : viewType === ViewType.FTE ? actualFTE : actual;
    const capacityValue = viewType === ViewType.Percent ? capacityPercent : viewType === ViewType.FTE ? capacityFTE : capacity;
    const availabilityValue = viewType === ViewType.Percent ? availabilityPercent : viewType === ViewType.FTE ? availabilityFTE : availability;

    return <div className="resource-usage-cell fill-space total" style={{ position: "relative" }}>
        <div className={`cell-content fill-space columns-${timeType.length}`}>
            {timeType.includes('plan') && <CellValue value={formatValue(planValue)} unit={unit} isEmpty={false} title='Planned' className='plan' />}
            {timeType.includes('actual') && <CellValue value={formatValue(actualValue)} unit={unit} isEmpty={false} title='Actual' className='actual' />}
            {timeType.includes('availability') && <CellValue value={formatValue(availabilityValue)} unit={unit} isEmpty={false} title='Availability'
                className='availability' />}
            {timeType.includes('capacity') && <CellValue value={formatValue(capacityValue)} unit={unit} isEmpty={false} title='Capacity' className='capacity' />}
        </div>
    </div>;
}

const buildState = (props: Props): State => {
    const hours = props.resources
        .reduce<DailyUsage[]>((acc, resource) => {
            const resourceUsages = props.entityIds.reduce<DailyUsage[]>((entityAcc, entityId) =>{
                return [...entityAcc, ...filterByEntityAndDatesUsage(resource.usage, entityId, props.startDate, props.finishDate)]
            }, []);

            return [...acc, ...resourceUsages];
        }, [])
        .reduce((cum, cur) => ({
            plan: cum.plan + cur.plannedHours,
            actual: cum.actual + cur.actualHours
        }), { plan: 0, actual: 0 });

    const { capacity, availability } = props.resources
        .map(resource => calculateSummary(resource, props.startDate, props.finishDate, props.calendar, props.entityType, props.resourcePlanningLevel))
        .reduce((cum, cur) => ({
            capacity: cum.capacity + cur.capacityHours,
            availability: cum.availability + cur.availability
        }), { capacity: 0, availability: 0 });
        
    const planPercent = capacity ? Math.round(hours.plan / capacity * HUNDRED_PCT) : 0;
    const actualPercent = capacity ? Math.round(hours.actual / capacity * HUNDRED_PCT) : 0;
    const capacityPercent = capacity ? HUNDRED_PCT : 0;
    const availabilityPercent = capacity ? Math.round(availability / capacity * HUNDRED_PCT) : 0;
    
    const fullFteCapacityHours = getWorkingHoursBetweenDates(props.startDate, props.finishDate, props.calendar);
    const planFTE = fullFteCapacityHours ? roundToHundreds(hours.plan / fullFteCapacityHours) : 0;
    const actualFTE = fullFteCapacityHours ? roundToHundreds(hours.actual / fullFteCapacityHours) : 0;
    const availabilityFTE = fullFteCapacityHours ? roundToHundreds(availability / fullFteCapacityHours) : 0;
    const capacityFTE = fullFteCapacityHours ? roundToHundreds(capacity / fullFteCapacityHours) : 0;
    
    return { ...hours, capacity, availability, planPercent, actualPercent, capacityPercent, availabilityPercent, planFTE, actualFTE, capacityFTE, availabilityFTE };
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        calendar: state.calendar,
        resourcePlanningLevel: mapServerEntityType[state.tenant.resourcePlanningSettings?.resourcePlanningLevel!]!
    };
}

export default connect(mapStateToProps)(TotalUsageCell);