import { get, remove } from '../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { IEntityStore, StoreHelper, partialUpdate, addOrUpdateOrRemove } from './services/storeHelper';
import { IBaseEntity, IWithChangeHistory, IHistoryRow, EntityType, IWithInsights } from "../entities/common";
import { catchApiError } from "./utils";
import * as Notifications from "./NotificationsStore";
import { InsightsData } from './InsightsStore';

export type IChangeHistoryState = {
    isLoading: boolean;
    isListLoading: boolean;
};

export const HISTORY_DEFAULT_STATE = {
    ...StoreHelper.create([]),
    isLoading: false,
    isListLoading: false
}

interface RequestedHistoryAction {
    namespace: string,
    type: 'REQUESTED_HISTORY';
    entityId: string;
}

interface ReceivedHistoryAction {
    namespace: string,
    type: 'UPDATED_HISTORY';
    entityId: string;

    replace?: IHistoryRow[];
    addOrUpdate?: IHistoryRow[];
    insights?: InsightsData;
}

interface LoadingStartedAction {
    namespace: string,
    type: 'LOADING_STARTED';
    isLoading: boolean;
}

type KnownAction = RequestedHistoryAction
    | ReceivedHistoryAction
    | LoadingStartedAction;

function getEntityName(namespace: EntityType) {
    return namespace.toLowerCase();
}

export const actionCreators = {
    forEntity: (namespace: EntityType) => ({
        loadHistory: (entityId: string, fieldName?: string): AppThunkAction<KnownAction | Notifications.KnownAction> => (dispatch, getState) => {
            get<{ changeHistory: IHistoryRow[], insights: InsightsData }>(`api/${getEntityName(namespace)}/${entityId}/history`, { fieldName })
                .then(data => dispatch({
                    namespace,
                    type: 'UPDATED_HISTORY',
                    entityId: entityId,
                    addOrUpdate: data.changeHistory,
                    insights: data.insights
                }))
                .catch(catchApiError(_ => {
                    dispatch(Notifications.actionCreators
                        .pushNotification({ message: `Failed to load status history. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
                }));
        },
        deleteHistory: (entityId: string, attributeName: string, id?: string): AppThunkAction<KnownAction | Notifications.KnownAction> => (dispatch, getState) => {
            remove<IHistoryRow[]>(`api/${getEntityName(namespace)}/${entityId}/history/${attributeName}/${id || ''}`, {})
                .then(data => dispatch({ namespace, type: 'UPDATED_HISTORY', entityId, replace: data }))
                .catch(catchApiError(_ => {
                    dispatch(Notifications.actionCreators
                        .pushNotification({ message: `Failed to remove status history. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
                }));
        }
    })
};

export function ChangeHistoryOperationsFactory<
    TTypeInfo extends IBaseEntity & IWithChangeHistory & IWithInsights,
    TListState extends IEntityStore<TTypeInfo> & { changeHistory: IChangeHistoryState }
>(namespace: EntityType) {

    const reducer: Reducer<TListState> = (state: TListState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;
        if (action.namespace !== namespace) {
            return state;
        }

        switch (action.type) {
            case 'REQUESTED_HISTORY':
                return {
                    ...state,
                    ...StoreHelper.applyHandler(state,
                        action.entityId,
                        (entity: TTypeInfo) => partialUpdate(entity, { changeHistory: [] as IHistoryRow[] } as Partial<TTypeInfo>)),
                    tasks: {
                        ...state.changeHistory,
                        isListLoading: true
                    }
                };
            case 'UPDATED_HISTORY':
                return {
                    ...state,
                    ...(state.activeEntity?.id === action.entityId
                        ? StoreHelper.applyHandler(state,
                            action.entityId,
                            (entity: TTypeInfo) => partialUpdate(entity,
                                {
                                    changeHistory: action.replace
                                        ? action.replace
                                        : addOrUpdateOrRemove(entity.changeHistory, action.addOrUpdate),
                                    insights: action.insights ?? entity.insights
                                } as Partial<TTypeInfo>))
                        : {}),
                    tasks: {
                        ...state.changeHistory,
                        isListLoading: false,
                        isLoading: false
                    }
                };
            case 'LOADING_STARTED':
                return {
                    ...state,
                    tasks: {
                        ...state.changeHistory,
                        isLoading: action.isLoading
                    }
                };
            default:
                return state;
        }
    };

    return {
        actionCreators: actionCreators.forEntity(namespace),
        reducer
    };
}