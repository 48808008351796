import "./SwitchTrialDialog.css";
import * as React from 'react';
import { Dialog, DialogFooter, DialogType, PrimaryButton } from 'office-ui-fabric-react';
import { BillingPlan, SubscriptionInfo } from '../common';
import { SwitchTrialMessage } from "./SwitchTrialMessage";

type Props = {
    plan: BillingPlan;
    subscription: SubscriptionInfo;
    onDismiss: () => void;
    onConfirm: () => void;
}

export const SwitchTrialDialog = (props: Props) => {
    const onConfirmClick = React.useCallback(() => {
        props.onConfirm();
        props.onDismiss();
    }, []);

    const { subscription, plan } = props;
    return <Dialog
        minWidth={500}
        title='Switch Trial'
        hidden={false}
        modalProps={{ className: 'switch-trial' }}
        dialogContentProps={{ type: DialogType.close }}
        onDismiss={props.onDismiss}>
        {
            <SwitchTrialMessage
                newFeatures={plan.features & ~subscription.features}
                lostFeatures={subscription.features & ~plan.features}
                newPlanName={plan.name}
                oldPlanName={subscription.planName}
            />
        }
        <DialogFooter>
            <PrimaryButton text="Confirm" onClick={onConfirmClick} />
        </DialogFooter>
    </Dialog>;
}