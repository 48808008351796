import { ComboBox, IComboBoxOption, ISelectableOption, PrimaryButton, Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as React from "react";
import { ISourceInfo } from "../../../entities/common";
import { ApplicationState } from "../../../store";
import { actionCreators, ISmartsheetConnectionState, ISmartsheetProgramSourceData, ISmartsheetWorkspaceInfo } from './../../../store/integration/SmartsheetStore';
import { connect } from "react-redux";
import SmartsheetConnectionSelect from "./SmartsheetConnectionSelect";
import OverlayComponent from "../../common/OverlayComponent";
import { ConnectionField } from "../ConnectionField";
import { validateConnection } from "../../../store/integration/common";
import { usePrevious } from "../../utils/effects";

type SmartsheetConnectActions = {
    deleteLink: (connectionId: string) => void;
    linkToSmartsheetWorkspace: (obj: { connectionId: string, data: ISmartsheetProgramSourceData }) => void;
}

type OwnProps = {
    readonly: boolean;
    actions: SmartsheetConnectActions;
    sourceInfo?: ISourceInfo<ISmartsheetProgramSourceData>;
    dismissPanel?: () => void;
}

type StateProps = {
    workspaces: ISmartsheetWorkspaceInfo[];
    isLoading: boolean;
    error: string | null;
    connections: ISmartsheetConnectionState;
}

type Props = OwnProps & StateProps & typeof actionCreators;

const workspacesToOptions = (o: ISmartsheetWorkspaceInfo[]): ISelectableOption[] =>
    o.map(_ => ({ key: _.workspaceId.toString(), text: _.workspaceName }))
        .sort((a, b) => a.text.localeCompare(b.text));

const onRenderItem = (props?: IComboBoxOption, defaultRender?: (props?: IComboBoxOption) => JSX.Element | null): JSX.Element | null => {
    if (!defaultRender) {
        return null;
    }

    const element = defaultRender(props);
    if (!props || !props.disabled) {
        return element;
    }

    return React.createElement("div", { title: "Already linked" }, element);
}

const linkToProgram = (props: Props, connectionId: string, workspaceId: number) => {
    const { actions, workspaces, dismissPanel } = props;
    const workspaceToLink = workspaces.find(_ => _.workspaceId == workspaceId);
    
    if (!workspaceToLink) {
        return;
    }

    actions.linkToSmartsheetWorkspace({
        connectionId,
        data: {
            workspaceId: workspaceToLink.workspaceId,
            workspaceName: workspaceToLink.workspaceName,
            workspaceUrl: workspaceToLink.workspaceUrl
        }
    });
    dismissPanel?.();
}

const deleteLink = (props: Props, connectionId: string) => {
    const { actions, dismissPanel } = props;
    actions.deleteLink(connectionId);
    dismissPanel?.();
}

const SmartsheetProgramConnectControl = (props: Props) => {
    const { readonly, isLoading, error, sourceInfo, connections } = props;
    const [connectionId, setConnectionId] = React.useState<string | undefined>(sourceInfo?.connectionId);
    const [workspaceId, setWorkspaceId] = React.useState<number | undefined>(sourceInfo?.sourceData.workspaceId);

    const previousConnectionsVerification = usePrevious(props.connections.connectionsVerification);
    React.useEffect(() => {
        const validationInfo = validateConnection(connections, connectionId);
        if (connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false
            && validationInfo?.isValidating === false && !validationInfo.validationError) {
            props.loadWorkspaces(connectionId);
        }
    }, [connectionId, connectionId && connections.connectionsVerification[connectionId]]);

    const workspaceOptionsMap = React.useMemo(() => workspacesToOptions(props.workspaces), [props.workspaces]);
    const isLinked = !!sourceInfo;

    const onConnectionChange = React.useCallback((_: string | undefined) => {
        if (!isLinked) {
            setConnectionId(_);
            setWorkspaceId(undefined);
        }
    }, []);

    const onWorkspaceChange = React.useCallback((e: React.FormEvent<any>, _?: IComboBoxOption) => {
        setWorkspaceId(_ ? (_.key as number) : undefined);
    }, [])

    const isWorkspaceSelectionDisabled = readonly || isLinked || !connectionId || isLoading;
    const workspaceName = workspaceId ? (workspaceOptionsMap.find(_ => _.key === workspaceId)?.text ?? sourceInfo?.sourceData.workspaceName) : '';
    const isValid = validateConnection(props.connections, connectionId);

    return <>
        <div key="select-connection-container" className="with-top-margin">
            <SmartsheetConnectionSelect
                disabled={readonly || isLinked}
                connectionId={connectionId}
                onChange={onConnectionChange} />
        </div>
        <div key="select-project-container" className="with-top-margin">
            <OverlayComponent isOverlayed={!connectionId || isValid?.isValidating !== false || !!isValid?.validationError}>
                <ConnectionField
                    isLinked={isLinked}
                    label="Workspace"
                    value={workspaceName}>
                    <ComboBox
                        disabled={isWorkspaceSelectionDisabled}
                        allowFreeform
                        useComboBoxAsMenuWidth
                        placeholder="Select workspace"
                        selectedKey={workspaceId}
                        onRenderItem={onRenderItem}
                        options={workspaceOptionsMap}
                        onChange={onWorkspaceChange}
                        errorMessage={!isLoading && connectionId && isValid?.isValidating === false && !isValid?.validationError
                            ? error
                                ? error
                                : !workspaceId
                                    ? `Please select workspace from list`
                                    : undefined
                            : undefined} />
                </ConnectionField>
                {isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
            </OverlayComponent>
            <OverlayComponent isOverlayed={!connectionId}>
                {
                    !isLinked && <PrimaryButton text={`Link workspace`}
                        disabled={readonly || workspaceId === undefined}
                        className="with-top-margin"
                        onClick={() => linkToProgram(props, connectionId!, workspaceId!)} />
                }
                {
                    isLinked && <PrimaryButton text="Delete workspace link"
                        className="with-top-margin"
                        disabled={readonly || sourceInfo?.syncStatus.inProgress}
                        title={!!sourceInfo?.syncStatus.inProgress ? "Sync in progress." : undefined}
                        onClick={() => deleteLink(props, connectionId!)} />
                }
            </OverlayComponent>
        </div>
    </>
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isLoading: state.smartsheet.workspacesInfo.isLoading,
        error: state.smartsheet.workspacesInfo.error,
        workspaces: state.smartsheet.workspacesInfo.entities,
        connections: state.smartsheet.connections
    }
}

export default connect(mapStateToProps, actionCreators)(SmartsheetProgramConnectControl);