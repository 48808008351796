import * as React from 'react';
import { ItemCreation, ICommand } from '../../common/ItemCreation';
import { Validator } from '../../../validation';
import * as Metadata from '../../../entities/Metadata';
import { Toggle, IconButton, ActionButton, Label } from 'office-ui-fabric-react';
import { DraggableList, NonDraggableList } from "../../common/DraggableList";
import { orderNotSelected, toDictionaryById } from "../../utils/common";
import FieldPanel, { IFieldActions } from '../../field/FieldPanel';
import { IsPublicTooltip } from '../../common/FilterPanel';
import { ApplicationState } from '../../../store';
import { UserState, isInReadonlyMode } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { connect } from 'react-redux';
import RemoveDialog from '../../common/RemoveDialog';
import { EntityType } from '../../../entities/common';
import { ViewService } from '../../../services/ViewService';

const validators = {
    viewName: Validator.new().required().build()
}

type OwnProps = {
    fields: Metadata.Field[];
    entityType: EntityType;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    subView: Metadata.IListSubView;
    selectedByDefault: string[];
    onChange: (changes: Partial<Metadata.IListSubView>) => void;
    onDismiss: () => void;
    onSave: () => void;
    onCopy: () => void;
}

type StateProps = {
    user: UserState;
    isReadonlyMode: boolean;
    isFieldsLoading: boolean;
    mandatoryFields?: string[];
};

type Props = OwnProps & StateProps;

interface State {
    fields: Metadata.Field[];
    selectedMap: { id: any };
    showFieldPanel?: boolean;
    editField?: Metadata.Field;
    removeField?: Metadata.Field;
    canManageConfiguration: boolean;
}

class EditListSubView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let columns = props.subView.columns;
        if (!columns.length && props.selectedByDefault.length) {
            columns = props.selectedByDefault.map(_ => ({ id: _ }));
            this._onColumnsChange(columns);
        }

        const map = this._buildHashSet(columns);
        this.state = {
            fields: this._orderFields(props, map),
            selectedMap: map,
            canManageConfiguration: contains(props.user.permissions.common, CommonOperations.ConfigurationManage)
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        let columns = nextProps.subView.columns;

        if (this.props.subView.id !== nextProps.subView.id && !columns.length && nextProps.selectedByDefault.length) {
            columns = nextProps.selectedByDefault.map(_ => ({ id: _ }));
            this._onColumnsChange(columns);
        }

        let map = this.state.selectedMap;
        if (this.props.subView.columns !== columns) {
            map = this._buildHashSet(columns);
            this.setState({ selectedMap: map });
        }
        if (this.props.fields.length !== nextProps.fields.length
            || this.props.subView.id !== nextProps.subView.id) {
            this.setState({ fields: this._orderFields(nextProps, map) });
        } else {
            this.setState({ fields: this.state.fields.map(_ => nextProps.fields.find(f => f.name === _.name)!) });
        }
    }

    private _buildHashSet(columns: { id: string }[]): { id: any; } {
        const map = Object.create(null);
        map.id = {};
        columns.forEach((_, index) => {
            map.id[_.id] = index + 1;
        });

        return map;
    }

    private _orderFields(props: Props, map: { id: any }): Metadata.Field[] {
        return orderNotSelected(props.fields, (c) => map.id[c.id] - 1, a => Metadata.getLabel(a), true);
    }

    public render() {
        const { subView, isReadonlyMode } = this.props;
        const { removeField } = this.state;
        const commands: ICommand[] = [];
        const isInvalid = !validators.viewName.isValid(subView.name);
        commands.push({
            primary: true,
            text: `${subView.id && subView.id !== Metadata.NEW_ID ? 'Save' : 'Create'} View`,
            onClick: this.props.onSave,
            disabled: isInvalid || isReadonlyMode
        });

        if (subView.id && subView.id !== Metadata.NEW_ID) {
            commands.push({
                iconName: "Copy",
                text: "Create a copy",
                onClick: this.props.onCopy,
                disabled: isInvalid || isReadonlyMode
            });
        }

        const mandatoryFields = this.state.fields.filter(_ => ViewService.isMandatory(_, this.props.mandatoryFields));
        return this.state.showFieldPanel && this.props.fieldActions
            ? <FieldPanel
                entityType={this.props.entityType}
                field={this.state.editField}
                actions={this.props.fieldActions}
                allowManageFields={this.props.allowManageFields}
                onDismiss={() => this.setState({ showFieldPanel: false })} />
            : <ItemCreation
                onDismiss={this.props.onDismiss}
                header={{
                    text: `${subView.id && subView.id !== Metadata.NEW_ID ? 'Configure' : 'Create'} View`,
                    secondaryText: "Fill in the fields below to configure the view",
                    nameEditorLabel: "View Name",
                    value: subView.name,
                    onChanged: (newName: string) => { this.props.onChange({ name: newName }) },
                    validator: validators.viewName
                }}
                commands={commands}
                className={"subview-panel" + (subView.isNotSaved ? ' not-saved' : '')}
                showSpinner={this.props.isFieldsLoading}>
                <div className="is-public-container">
                    <div className="label">
                        <Label>Is Public</Label>
                        <IsPublicTooltip text={this.state.canManageConfiguration
                            ? "Public Views are shared across whole organization and visible for all users. Private Views are accessible by the creator only."
                            : "To create Public Views, Manage Configuration permission is required."} />
                    </div>
                    <Toggle checked={subView.isPublic}
                        disabled={!this.state.canManageConfiguration}
                        onChange={(e, checked: boolean) => this.props.onChange({ isPublic: checked })}
                        styles={{ root: { marginBottom: 0 } }} />
                </div>
                <hr />
                <p>Select the columns that should be displayed in the view. Drag and drop to reorder.</p>
                {!!mandatoryFields.length && <NonDraggableList items={mandatoryFields} onItemRender={this._onItemRender} itemClassName='dragndrop-disabled' />}
                <DraggableList
                    items={this.state.fields.filter(_ => !ViewService.isMandatory(_, this.props.mandatoryFields))}
                    onItemRender={this._onItemRender}
                    onChanged={this._onOrderChanged} />
                {this.props.allowManageFields !== undefined && <ActionButton iconProps={{ iconName: 'Add' }}
                    text="New Field"
                    className="new-button"
                    disabled={this.props.isFieldsLoading || !this.props.allowManageFields}
                    onClick={() => this.setState({ showFieldPanel: true, editField: undefined })} />}
                {!!removeField && <RemoveDialog
                    key="remove-details-view-dialog"
                    onClose={() => this.setState({ removeField: undefined })}
                    onComplete={() => this.props.fieldActions!.removeField!(removeField.id)}
                    confirmButtonProps={{ text: "Delete" }}
                    dialogContentProps={{
                        title: "Delete field",
                        subText: `Are you sure you want to delete field "${Metadata.getLabel(removeField)}" and field data in all related entities? This action can't be undone.`

                    }} />}
            </ItemCreation>;
    }

    private _onItemRender = (item: Metadata.Field) => {
        const label = Metadata.getLabel(item);
        const isMandatory = ViewService.isMandatory(item, this.props.mandatoryFields);
        return (
            <div className="cb-wrap">
                <div className="title bold" title={label}>{label}</div>
                {
                    this.props.allowManageFields !== undefined && !item.isFake
                    && <div className="align-center">
                        <IconButton
                            menuIconProps={{ iconName: 'Edit' }}
                            title="Edit"
                            className='menu'
                            disabled={!this.props.allowManageFields}
                            onClick={() => this.setState({ showFieldPanel: true, editField: item })} />
                    </div>
                }
                {
                    this.props.allowManageFields !== undefined && !item.isFake
                    && <div className="align-center">
                        <IconButton
                            menuIconProps={{ iconName: 'Delete' }}
                            title="Delete"
                            className='menu'
                            disabled={!item.isCustom || !this.props.allowManageFields}
                            onClick={() => this.setState({ removeField: item })} />
                    </div>
                }
                {!isMandatory && <Toggle
                    checked={!!this.state.selectedMap.id[item.id]}
                    onChange={isMandatory ? undefined : (e, checked) => this._onSelectedColumnsChange(item, checked)} />}
            </div>);
    }

    private _onOrderChanged = (items: Metadata.Field[], changedPositionItem: Metadata.ISubViewColumn) => {
        const mandatoryFields = this.state.fields.filter(_ => ViewService.isMandatory(_, this.props.mandatoryFields));
        const reordered = mandatoryFields.length ? [...mandatoryFields, ...items] : items;
        const columns = reordered.filter(_ => this.state.selectedMap.id[_.id]).map(_ => ({ id: _.id }));
        this._onColumnsChange(columns);
        this.setState({ fields: reordered });
    }

    private _onSelectedColumnsChange(column: Metadata.Field, checked?: boolean) {
        if (checked) {
            const selected = this.state.selectedMap;
            const columns = this.state.fields.filter(_ => selected.id[_.id] || _ === column).map(_ => ({ id: _.id }));
            this._onColumnsChange(columns);
        } else {
            const columns = this.props.subView.columns.filter(_ => _.id !== column.id);
            this._onColumnsChange(columns);
        }
    }

    private _onColumnsChange = (columns: { id: string }[]) => {
        const map = toDictionaryById(this.props.subView.columns);
        this.props.onChange({ columns: columns.map(_ => !!map[_.id] ? map[_.id] : _) });
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[ownProps.entityType];
    return {
        user: state.user,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant),
        isFieldsLoading: fields?.isLoading ?? false,
        mandatoryFields: ViewService.getViewMandatoryFields(ownProps.entityType),
    };
}
export default connect(mapStateToProps)(EditListSubView)