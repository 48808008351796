import * as React from 'react';
import { IPanelProps, Panel } from "office-ui-fabric-react";
import { ApplicationState } from '../../store';
import { connect } from "react-redux";

type StateProps = {
    isFullScreen: boolean;
}

type Props = IPanelProps & StateProps;

const ExpandablePanel = (props: Props) => (
    <Panel
        {...props}
        focusTrapZoneProps={{ forceFocusInsideTrap: true }}
        className={`${props.className || ''} ${props.isFullScreen ? 'side-panel-maximized' : ''}`}
    />
)

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isFullScreen: state.userInterface.isFullScreen
    }
}

export default connect(mapStateToProps)(ExpandablePanel);