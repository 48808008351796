import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from "../../../store/User";
import { ApplicationState } from "../../../store/index";
import { Redirect } from "react-router-dom";
import { Subscription, SubscriptionStatus } from '../../../store/Tenant';
import BillingManage from '../../settings/billing/BillingManage';
import ErrorPage from '../../ErrorPage';
import { Link, PrimaryButton } from 'office-ui-fabric-react';
import { FormCall } from "../../settings/billing/FormCall";
import RequestCall from "./RequestCall";

import "./SubscriptionExpired.css";
import imageUrl from '../../../images/image-subscription-expired.svg';

type StateProps = { subscription: Subscription };
type Props = StateProps & typeof actionCreators;

const SubscriptionExpired = (props: Props) => {
    const { subscription } = props;

    if (subscription.status === SubscriptionStatus.Active) {
        return <Redirect to="/billing" />;
    }

    const [showBilling, setShowBilling] = React.useState(false);
    const onButtonsRender = React.useCallback(() => {
        return subscription.status == SubscriptionStatus.Disabled
            ? null
            : <PrimaryButton
                text='Buy Subscription'
                onClick={_ => setShowBilling(true)}
            />
    }, []);

    const [showRequestCall, setShowRequestCall] = React.useState(false);
    const onFormCallClick = React.useCallback(() => setShowRequestCall(true), []);

    const isDirect = Subscription.isDirect(subscription);
    const onIconRender = React.useCallback(() => <img src={imageUrl} />, []);
    const onRenderFooter = React.useCallback(() => isDirect
        ? <span>
            In order to continue using PPM Express services, please contact <SalesLink /> or schedule a call
        </span>
        : <span>
            If you have questions or need more time for evaluation, please contact <SalesLink /> or schedule a call
        </span>, [isDirect]);

    if (showBilling) {
        return <div className="subscription-expired">
            <BillingManage />
        </div>
    }

    if (showRequestCall) {
        return <RequestCall />;
    }

    return <>
        <ErrorPage
            className='subscription-expired'
            title={subscription.isTrial ? "Your trial has ended" : 'Your subscription has expired'}
            onIconRender={onIconRender}
            onButtonsRender={isDirect ? undefined : onButtonsRender}
            onRenderFooter={onRenderFooter}>
            {
                isDirect
                    ? <span>
                        Your subscription has expired and your tenant is currently disabled. Don't worry, your data is saved for now!
                        If you don't proceed with a paid subscription, your account and data will be deleted after 90 days period.
                    </span>
                    : <span>
                        Don't worry, your data is saved for now! Select a plan that works best for you and use the full power of PPM Express.
                        If you don't subscribe to any of the plans, your account and data will be deleted after 90 days period.
                    </span>
            }
        </ErrorPage>
        <FormCall onCLick={onFormCallClick} />
    </>
}

const SalesLink = () => <Link href="mailto:sales@ppm.express">sales@ppm.express</Link>;

function mapStateToProps(state: ApplicationState): StateProps {
    return { subscription: state.tenant.subscription };
}

export default connect(mapStateToProps, actionCreators)(SubscriptionExpired);