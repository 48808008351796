import { cancellablePost, get, post, remove } from './../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { push, RouterAction } from 'react-router-redux';
import { MetadataService, UpdateUIControlInfo, ActionsBuilder, namesof } from './services/metadataService';
import * as Metadata from "../entities/Metadata";
import { IEntityStore, StoreHelper, IDeletionResult, partialUpdate } from './services/storeHelper';
import { Dictionary, ISourceInfo, EntityType, IEditable, IBaseEntity, IWithLayout, IWithWarnings, IScalableTimeframe } from "../entities/common";
import { catchApiError, defaultCatch } from "./utils";
import { ISpoResource } from './integration/SpoStore';
import { ILinkDto } from './integration/common';
import { IJiraUser } from './integration/JiraStore';
import { IVSTSUser } from './integration/VSTSStore';
import { IMondayComUser } from './integration/MondayComStore';
import { IOffice365UserInfo } from './integration/Office365Store';
import { ApplyLayout, LayoutApplied } from './layouts';
import { CalendarException, CalendarDataSet, splitToDays, CalendarUpdateModel, checkExceptionDates } from './CalendarStore';
import { toDate, toDictionaryById } from '../components/utils/common';
import { groupTypes, MyWork } from './MyWorkStore';
import { ImportExportFactory } from './importExport';
import { ISmartsheetUser } from './integration/SmartsheetStore';
import fileDownload from 'js-file-download';
import { IP4WResource } from './integration/P4WStore';
import * as Notifications from "./NotificationsStore";
import { UpdateEntityResourcePlanAction } from './ResourcePlanListStore';

const namespace = 'RESOURCE';
const { importExportActionCreators, importExportReducer } = ImportExportFactory<string, Resource, ResourcesState>(namespace, EntityType.Resource);

export type ResourceAttrs = {
    Name: string;
    Email: string;
    ResourceType: ResourceType;
    Status: ResourceStatus;
    MaxUnitsPct?: number;
    NonProjectWorkPct?: number;
    StandardCost: number;
}

type ResourceEntityUsageDto = {
    entityId: string;
    entityTotals: EntityResourceUsageAttrs;
    byDay: DailyUsage[];
}

type UpdateResourceEntityUsageDto = {
    entityId: string;
    entityTotals: EntityResourceUsageAttrs;
}

type UpdateResourceUsageAttributesDto = {
    id: string;
    byEntity: UpdateResourceEntityUsageDto[];
}

type ServerResourceUsage = {
    id: string;
    calendar: {
        workDayExpectedHrs: Dictionary<number> | null;
        exceptions: CalendarException[];
    };
    byEntity: ResourceEntityUsageDto[];
}
export interface ServerResource extends IBaseEntity, IWithLayout, IEditable, IWithWarnings, Metadata.IWithSections, Metadata.IWithPinnedViews {
    name: string;
    email: string;
    resourceType: number;
    imageId?: string;
    attributes: ResourceAttrs & Dictionary<any>;
    sourceInfos: ISourceInfo[];
    usage?: ServerResourceUsage;
    work?: MyWork[];
    canConfigure: boolean;
    lastADSync?: string;
    activeDirectoryId?: string;
    isArchived?: boolean;
}

const toResource = (serverResource: ServerResource): Resource => {
    return {
        id: serverResource.id,
        attributes: serverResource.attributes,
        canConfigure: serverResource.canConfigure,
        email: serverResource.email,
        isEditable: serverResource.isEditable,
        name: serverResource.name,
        resourceType: serverResource.resourceType,
        sections: serverResource.sections,
        pinnedViews: serverResource.pinnedViews,
        sourceInfos: serverResource.sourceInfos,
        warnings: serverResource.warnings,
        activeDirectoryId: serverResource.activeDirectoryId,
        imageId: serverResource.imageId,
        isArchived: serverResource.isArchived,
        lastADSync: serverResource.lastADSync,
        layoutId: serverResource.layoutId,
        work: serverResource.work,
        usage: serverResource.usage
            ? toResourceUsage(serverResource.usage)
            : undefined
    }
}

const toResourceUsage = (serverResourceUsage: ServerResourceUsage): ResourceUsage => {
    return {
        id: serverResourceUsage.id,
        calendar: {
            workDayExpectedHrs: serverResourceUsage.calendar.workDayExpectedHrs,
            exceptions: checkExceptionDates(serverResourceUsage.calendar.exceptions)
        },
        byEntity: serverResourceUsage.byEntity.reduce<ResourceEntityUsage>((acc, byEntity) => {
            if (!acc[byEntity.entityId]) {
                acc[byEntity.entityId] = {
                    attributes: {
                        bookingType: undefined,
                        estimatedCharge: 0,
                        totalActual: 0,
                        totalPlanned: 0,
                        billingCode: undefined,
                        chargeRate: 0 
                    },
                    byDay: {}
                };
            }

            acc[byEntity.entityId].attributes = byEntity.entityTotals;
            byEntity.byDay.forEach(_ => {
                const newDate = toDate(_.date)!.getBeginOfDay();
                acc[byEntity.entityId].byDay[newDate.toISOString()] = { ..._, date: newDate };
            })

            return acc;
        }, {})
    }
}

const toResourceEntityUsage = (newByEntity: UpdateResourceEntityUsageDto[], prevByEntity: ResourceEntityUsage): ResourceEntityUsage => {
    return newByEntity.reduce<ResourceEntityUsage>((acc, byEntity) => {
        if (!acc[byEntity.entityId]) {
            acc[byEntity.entityId] = {
                attributes: {
                    bookingType: undefined,
                    estimatedCharge: 0,
                    totalActual: 0,
                    totalPlanned: 0,
                    billingCode: undefined,
                    chargeRate: 0 
                },
                byDay: {}
            };
        }

        acc[byEntity.entityId].byDay = prevByEntity[byEntity.entityId].byDay;
        acc[byEntity.entityId].attributes = byEntity.entityTotals;
        return acc;
    }, {}) 
}

export interface Resource extends IBaseEntity, IWithLayout, IEditable, IWithWarnings, Metadata.IWithSections, Metadata.IWithPinnedViews {
    name: string;
    email: string;
    resourceType: number;
    imageId?: string;
    attributes: ResourceAttrs & Dictionary<any>;
    sourceInfos: ISourceInfo[];
    usage?: ResourceUsage;
    work?: MyWork[];
    canConfigure: boolean;
    lastADSync?: string;
    activeDirectoryId?: string;
    isArchived?: boolean;
}

export interface ResourceUsage {
    id: string;
    calendar: ResourceCalendar;
    byEntity: ResourceEntityUsage;
}
export type EntityResourceUsageAttrs = {
    chargeRate: number,
    estimatedCharge: number,
    totalPlanned: number,
    totalActual: number
    bookingType?: boolean,
    billingCode?: string,
}
export type ResourceEntityUsage = Dictionary<{
    attributes: EntityResourceUsageAttrs
    byDay: Dictionary<DailyUsage>
}>;

export const firstUsage = (resourceUsage: ResourceUsage | undefined, entityId: string | undefined): DailyUsage | undefined =>
    findUsage(resourceUsage, entityId, _ => true);

export const findUsage = (resourceUsage: ResourceUsage | undefined, entityId: string | undefined, filter: (usage: DailyUsage) => boolean): DailyUsage | undefined => {
    if (!resourceUsage || !entityId) {
        return undefined;
    }

    const entityUsages = resourceUsage.byEntity[entityId];
    if (!entityUsages) {
        return undefined;
    }

    return Object.values(entityUsages.byDay).find(filter);
}
export const filterUsage = (resourceUsage: ResourceUsage | undefined, entityId: string | undefined, filter: (usage: DailyUsage) => boolean): DailyUsage[] => {
    if (!resourceUsage || !entityId) {
        return [];
    }

    const entityUsages = resourceUsage.byEntity[entityId];
    if (!entityUsages) {
        return [];
    }

    return Object.values(entityUsages.byDay).filter(filter);
}
export const filterByDatesUsage = (
    resourceUsage: ResourceUsage | undefined,
    startDate: Date,
    finishDate: Date): DailyUsage[] => {
    if (!resourceUsage) {
        return [];
    }
    return Object.keys(resourceUsage.byEntity).reduce<DailyUsage[]>(
        (acc, entityId) => [...acc, ...filterByEntityAndDatesUsage(resourceUsage, entityId, startDate, finishDate)],
        []);
}
export const filterByEntityAndDatesUsage = (
    resourceUsage: ResourceUsage | undefined,
    entityId: string | undefined,
    startDate: Date,
    finishDate: Date): DailyUsage[] => {
    if (!resourceUsage || !entityId) {
        return [];
    }

    const entityUsages = resourceUsage.byEntity[entityId];
    if (!entityUsages) {
        return [];
    }
    return getDatesBetween(startDate.getBeginOfDay(), finishDate.getBeginOfDay())
        .reduce<DailyUsage[]>((acc, date) => {
            const usage = entityUsages.byDay[date.toISOString()];
            return usage ? [...acc, usage] : acc;
        }, []);
}

const getDatesBetween = (start: Date, finish: Date) => {
    const result: Date[] = [];
    for (const date = start; date <= finish; date.addDays(1)) {
        result.push(new Date(date));
    }
    return result;
}

export interface ResourceCalendar {
    workDayExpectedHrs: Dictionary<number> | null;
    exceptions: CalendarException[];
}

export interface DailyUsage {
    date: Date;
    entityId: string;
    plannedHours: number;
    actualHours: number;
    committed: boolean;
    entityType: EntityType;
    rate: number;
    billingCode: string | undefined;
};

//method mimics behavior of server-side CalendarSettings ctor
//that accepts resource-specific and global calendar settings
export function createResourceCalendar(resource: Resource, globalCalendar: CalendarDataSet): CalendarDataSet {
    return calculateResourceCalendar(resource.usage?.calendar, globalCalendar);
}

export function calculateResourceCalendar(
    resourceCalendar: ResourceCalendar | undefined,
    globalCalendar: CalendarDataSet
): CalendarDataSet {
    const exceptions = [...(resourceCalendar?.exceptions || []), ...globalCalendar.exceptions];
    return {
        workDayExpectedHrs: resourceCalendar?.workDayExpectedHrs ?? globalCalendar.workDayExpectedHrs,
        workingHoursPerWeek: resourceCalendar?.workDayExpectedHrs
            ? Object.keys(resourceCalendar.workDayExpectedHrs).reduce(
                (sum, _) => sum + resourceCalendar.workDayExpectedHrs![_],
                0
            )
            : globalCalendar.workingHoursPerWeek,
        exceptions,
        exceptionsHoursMap: splitToDays(exceptions).reduce((cum, _) => ({ ...cum, [_.date.getTime()]: _.expectedHrs }), {})
    };
}

export interface ResourcesState extends IEntityStore<Resource> {
    isListLoading: boolean;
    isLoading: boolean;
    isLinking: boolean;
    isLoadingUsage: boolean;
    isUpdatingSections: boolean;
    deletionResult?: IDeletionResult[];
}

const unloadedState: ResourcesState = {
    byId: {},
    allIds: [],
    isListLoading: false,
    isLoading: false,
    isLinking: false,
    isLoadingUsage: false,
    isUpdatingSections: false
};

export enum ResourceType {
    Generic = 0,
    Work = 1
}
export interface ResourceTypeConfig { title: string, cssClassName: string }
export const ResourceTypeConfigMap: { [i: number]: ResourceTypeConfig } =
{
    [ResourceType.Generic]: {
        title: "Generic",
        cssClassName: "Grey",
    },
    [ResourceType.Work]: {
        title: "Work",
        cssClassName: "Rio-Grande",
    },
}
export enum ResourceStatus {
    Active = 0,
    Inactive = 1
}
export interface ResourceStatusConfig { title: string, cssClassName: string }
export const ResourceStatusConfigMap: { [i: number]: ResourceStatusConfig } =
{
    [ResourceStatus.Active]: { title: "Active", cssClassName: "Active" },
    [ResourceStatus.Inactive]: { title: "Inactive", cssClassName: "Inactive" },
}

export type ResourceUsageUpdate = {
    resourceId: string;
    entityId: string;
    startDate: Date;
    finishDate: Date;
} & ({
    auto?: boolean;
    hours?: number;
    fte?: number;
    percent?: number;
    valuePerDay?: boolean;
} | { daysShift: number; useCalendar: boolean });

export type ResourceUsageCommit = {
    resourceId: string;
    entityId: string;
};

export type ResourceUsageRate = {
    resourceId: string;
    entityId: string;
    rate: number;
};

export type ResourceUsageBillingCode = {
    resourceId: string;
    entityId: string;
    billingCode: string;
}

export type ResourceUsageInfo = {
    startDate: Date;
    finishDate: Date;
    auto?: boolean;
};

export type UsageUpdateModel = {
    usages: ServerResourceUsage[];
    resource?: Resource;
    resourcePlanTotals: ResourcePlanTotalsModel;
}

export type ResourcePlanTotalsModel = {
    plannedWork: number;
    actualWork: number;
    estimatedCost: number;
    actualCost: number;
    estimatedCharge: number;
    plannedResourcesCount: number;
    committedResourcesCount: number;
}

export const DEFAULT_BULK_EDIT_COLUMNS = namesof<ResourceAttrs>(['Name', 'Email', 'ResourceType']);

export interface ChangeUsageLoadingStateAction {
    type: 'USAGE_LOADING';
    isLoading: boolean;
}

interface LoadResourcesAction {
    type: 'LOAD_RESOURCES';
}

export interface ResourcesLoadedAction {
    type: 'RESOURCES_LOADED';
    resources: ServerResource[];
    part?: boolean;
}
interface ReceivedDeleteResourcesResultAction {
    type: 'RECEIVED_REMOVE_RESOURCES_RESULT';
    deletionResult?: IDeletionResult[];
}

export interface CreateResourceSuccessAction {
    type: 'CREATE_RESOURCE_SUCCESS';
    resource: ServerResource;
    isNotSetActiveEntity?: boolean;
}
interface LoadResourceAction {
    type: 'LOAD_RESOURCE';
    id: string;
}
interface ResourceLoadedAction {
    type: 'RESOURCE_LOADED';
    resource: ServerResource;
}

interface UpdatingSectionsAction {
    type: 'UPDATING_RESOURCE_SECTIONS';
    resourceId: string;
}

interface UpdateSectionAction {
    type: 'UPDATE_RESOURCE_SECTION_SUCCESS';
    resourceId: string;
    sections: Metadata.Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_RESOURCE_PINNED_VIEWS_SUCCESS';
    resourceId: string;
    pinnedViews: string[];
}

export interface ResourcesStatusesUpdatedAction {
    type: 'RESOURCES_STATUSES_UPDATED';
    resourceIds: string[];
    status: ResourceStatus;
}

export interface CreateResourceAction {
    type: 'CREATE_RESOURCE';
}

interface UpdateResourceUIControlAction {
    type: 'UPDATE_RESOURCE_UICONTROL_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

export interface BultUpdateResourcesSuccessAction {
    type: 'BULK_UPDATE_RESOURCES_SUCCESS';
    resources: ServerResource[];
}

interface UpdateImageAction {
    type: 'UPDATE_RESOURCE_IMAGE';
    resourceId: string;
    imageId?: string;
}

export interface ResourceLinkingAction {
    type: 'RESOURCE_LINKING';
}

export interface UpdateResourcesUsageAction {
    type: "UPDATE_RESOURCE_USAGE";
    data: ServerResourceUsage[];
}

export interface UpdateResourcesUsageAttributesAction {
    type: "UPDATE_RESOURCE_USAGE_ATTRIBUTES";
    data: UpdateResourceUsageAttributesDto[];
}

export interface RemovedResourcesSourceInfosAction {
    type: "REMOVED_RESOURCES_SOURCE_INFOS";
    connectionId: string;
}

type KnownAction = ChangeUsageLoadingStateAction
    | LoadResourcesAction
    | ResourcesLoadedAction
    | ReceivedDeleteResourcesResultAction
    | CreateResourceSuccessAction
    | LoadResourceAction
    | ResourceLoadedAction
    | UpdatingSectionsAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | CreateResourceAction
    | UpdateResourceUIControlAction
    | BultUpdateResourcesSuccessAction
    | UpdateImageAction
    | ResourceLinkingAction
    | UpdateResourcesUsageAction
    | RemovedResourcesSourceInfosAction
    | ResourcesStatusesUpdatedAction
    | UpdateResourcesUsageAttributesAction;

const defaultActionCreators = {
    loadResources: (loadUsage?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<ServerResource[]>(`api/resource`, { loadUsage })
            .then(data => dispatch({ type: 'RESOURCES_LOADED', resources: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_RESOURCES' });
    },
    loadResourcesByIds: (ids: string[], loadUsage: boolean = false, timeframe?: IScalableTimeframe): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource[]>(`api/resource/get`, { ids, loadUsage, timeframe })
            .then(data => dispatch({ type: 'RESOURCES_LOADED', resources: data, part: true }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_RESOURCES' });
    },
    loadResource: (resourceId: string, loadUsage: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<ServerResource>(`api/resource/${resourceId}`, { loadUsage })
            .then(data => dispatch(({ type: 'RESOURCE_LOADED', resource: data }) as any))
            .catch(defaultCatch(dispatch)); // Ensure server-side prerendering waits for this to complete
        dispatch(({ type: 'LOAD_RESOURCE', id: resourceId }));
    },
    updateStatuses: (resourceIds: string[], status: ResourceStatus): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (resourceIds.length) {
            post<string[]>(`api/resource/status`, { resourceIds, status })
                .then(_ => dispatch({ type: 'RESOURCES_STATUSES_UPDATED', resourceIds: _, status: status }))
                .catch(defaultCatch(dispatch));
        }
    },
    removeResources: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<IDeletionResult>(`api/resource/${ids[0]}`)
                .then(data => dispatch({ type: "RECEIVED_REMOVE_RESOURCES_RESULT", deletionResult: [data] }))
                .catch(defaultCatch(dispatch))
            : post<IDeletionResult[]>(`api/resource/bulkDelete`, { ids })
                .then(data => dispatch({ type: "RECEIVED_REMOVE_RESOURCES_RESULT", deletionResult: data }))
                .catch(defaultCatch(dispatch));
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_RESOURCES_RESULT" });
    },
    createResource: (name: string, layoutId: string, openOnComplete: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch,
        getState) => {
        post<ServerResource>(`api/resource`, { name, layoutId })
            .then(data => {
                dispatch(<CreateResourceSuccessAction>{ type: "CREATE_RESOURCE_SUCCESS", resource: data });
                if (openOnComplete) {
                    dispatch(push(`/resource/${data.id}`));
                }
            })
            .catch(defaultCatch(dispatch));

        dispatch(<CreateResourceAction>{ type: "CREATE_RESOURCE" });
    },
    updateResourceAttributes: (resourceId: string, newAttributeValues: Dictionary<any>):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<ServerResource>(`api/resource/${resourceId}/attributes`, newAttributeValues)
                .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
                .catch(defaultCatch(dispatch));
        },
    updateSections: ActionsBuilder.buildEntityUpdateSections(`api/resource`,
        (resourceId, sections, dispatch) => dispatch({
            type: 'UPDATE_RESOURCE_SECTION_SUCCESS',
            resourceId,
            sections
        })),
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((resourceId, sections, dispatch) => dispatch({
        type: 'UPDATE_RESOURCE_SECTION_SUCCESS',
        resourceId,
        sections
    })),
    updatePinnedViews: ActionsBuilder.buildEntityUpdatePinnedViews(`api/resource`,
        (resourceId, pinnedViews, dispatch) => dispatch({
            type: 'UPDATE_RESOURCE_PINNED_VIEWS_SUCCESS',
            resourceId,
            pinnedViews
        })),
    updateUIControl: ActionsBuilder.buildEntityUpdateUIControl(`api/resource`,
        (uiControlInfo, dispatch) => dispatch(<UpdateResourceUIControlAction>{
            type: 'UPDATE_RESOURCE_UICONTROL_SUCCESS',
            uiControlInfo: uiControlInfo
        })),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateResourceUIControlAction>{
        type: 'UPDATE_RESOURCE_UICONTROL_SUCCESS',
        uiControlInfo
    })),
    updateLayoutUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Resource),
    bulkUpdate: (updates: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource[]>(`api/resource/BulkUpdate`, updates)
            .then(data => dispatch({ type: 'BULK_UPDATE_RESOURCES_SUCCESS', resources: data }));
    },
    merge: (targetId: string, ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource[]>(`api/resource/merge`, { targetId, ids })
            .then(data => dispatch({ type: 'RESOURCES_LOADED', resources: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_RESOURCES' });
    },
    applyLayout: (resourceId: string, layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<ServerResource>(`api/resource/${resourceId}/applyLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'RESOURCE_LOADED', resource: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Resource });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Resource });
    },
    applyLayoutMany: (resourceIds: string[], layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<ServerResource[]>(`api/resource/applyLayout/${layoutId}`, { ids: resourceIds })
            .then(data => {
                dispatch({ type: 'BULK_UPDATE_RESOURCES_SUCCESS', resources: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Resource });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Resource });
    },
    linkResourceToO365User: (resourceId: string, linkData: ILinkDto<IOffice365UserInfo>):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<ServerResource>(`api/resource/${resourceId}/link/o365`, linkData)
                .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
                .catch(defaultCatch(dispatch));

            dispatch({ type: "RESOURCE_LINKING" });
        },
    deleteResourceToUserLink: (resourceId: string, connectionId: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            remove<ServerResource>(`api/resource/${resourceId}/link`, { connectionId })
                .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
                .catch(defaultCatch(dispatch));
        },
    updateImage: (resourceId: string, image: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const data = new FormData();
        data.set('image', image);
        post<{ imageId: string }>(`api/resource/${resourceId}/image`, data)
            .then(_ => dispatch({ type: 'UPDATE_RESOURCE_IMAGE', imageId: _.imageId, resourceId: resourceId }))
            .catch(defaultCatch(dispatch));
    },
    removeImage: (resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<void>(`api/resource/${resourceId}/image`)
            .then(_ => dispatch({ type: 'UPDATE_RESOURCE_IMAGE', imageId: undefined, resourceId: resourceId }))
            .catch(defaultCatch(dispatch));
    },
    linkToJiraUser: (resourceId: string, linkData: ILinkDto<IJiraUser>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource>(`api/resource/${resourceId}/link/jira`, linkData)
            .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "RESOURCE_LINKING" });
    },
    linkToVSTSUser: (resourceId: string, linkData: ILinkDto<IVSTSUser>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource>(`api/resource/${resourceId}/link/vsts`, linkData)
            .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "RESOURCE_LINKING" });
    },
    linkToPoResource: (resourceId: string, linkData: ILinkDto<ISpoResource>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource>(`api/resource/${resourceId}/link/spo`, linkData)
            .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "RESOURCE_LINKING" });
    },
    linkToMondayComResource: (resourceId: string, linkData: ILinkDto<IMondayComUser>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource>(`api/resource/${resourceId}/link/mondaycom`, linkData)
            .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "RESOURCE_LINKING" });
    },
    linkToSmartsheetResource: (resourceId: string, linkData: ILinkDto<ISmartsheetUser>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource>(`api/resource/${resourceId}/link/smartsheet`, linkData)
            .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "RESOURCE_LINKING" });
    },
    linkToP4WResource: (resourceId: string, linkData: ILinkDto<IP4WResource>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ServerResource>(`api/resource/${resourceId}/link/p4w`, linkData)
            .then(data => dispatch({ type: 'RESOURCE_LOADED', resource: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "RESOURCE_LINKING" });
    },
    loadUsages: (rosourceIds: string[], timeframe: IScalableTimeframe, includeArchived: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        cancellablePost<ServerResourceUsage[]>(`api/resourcePlan/getUsages`, { ids: rosourceIds, timeframe, includeArchived }).promise
            .then(data => {
                dispatch({ type: "UPDATE_RESOURCE_USAGE", data });
                dispatch({ type: "USAGE_LOADING", isLoading: false });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: "USAGE_LOADING", isLoading: true });
    },
    updateUsage: (entityType: EntityType, updates: ResourceUsageUpdate[], timeframe: IScalableTimeframe, actual?: boolean): AppThunkAction<KnownAction | UpdateEntityResourcePlanAction> =>
        (dispatch, getState) => {
            post<UsageUpdateModel>(`api/resourcePlan/usage`, { updates, entityType, actual, timeframe })
                .then(data => {
                    dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });

                    const entityIds = updates.map(_ => _.entityId).filter((_, i, self) => self.indexOf(_) === i);
                    if (entityIds.length === 1) {
                        dispatch({ namespace: entityType, type: "UPDATE_ENTITY_RESOURCE_PLAN", entityId: entityIds[0], resourcePlanTotals: data.resourcePlanTotals });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    commitUsage: (entityType: EntityType, commits: ResourceUsageCommit[], commit: boolean, timeframe: IScalableTimeframe): AppThunkAction<KnownAction | UpdateEntityResourcePlanAction> =>
        (dispatch, getState) => {
            post<UsageUpdateModel>(`api/resourcePlan/commit`, { commits, commit, entityType, timeframe })
                .then(data => {
                    dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });
                    dispatch({ type: "USAGE_LOADING", isLoading: false });

                    const entityIds = commits.map(_ => _.entityId).filter((_, i, self) => self.indexOf(_) === i);
                    if (entityIds.length === 1) {
                        dispatch({ namespace: entityType, type: "UPDATE_ENTITY_RESOURCE_PLAN", entityId: entityIds[0], resourcePlanTotals: data.resourcePlanTotals });
                    }
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: "USAGE_LOADING", isLoading: true });
        },
    setRate: (entityType: EntityType, rates: ResourceUsageRate[]): AppThunkAction<KnownAction | UpdateEntityResourcePlanAction> => (dispatch, getState) => {
        post<UsageUpdateModel>(`api/resourcePlan/setRate`, { rates, entityType })
            .then(data => {
                dispatch({ type: "UPDATE_RESOURCE_USAGE_ATTRIBUTES", data: data.usages });
                dispatch({ type: "USAGE_LOADING", isLoading: false });

                const entityIds = rates.map(_ => _.entityId).filter((_, i, self) => self.indexOf(_) === i);
                if (entityIds.length === 1) {
                    dispatch({ namespace: entityType, type: "UPDATE_ENTITY_RESOURCE_PLAN", entityId: entityIds[0], resourcePlanTotals: data.resourcePlanTotals });
                }
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: "USAGE_LOADING", isLoading: true });
    },
    setBillingCode: (entityType: EntityType, billingCodes: ResourceUsageBillingCode[]): AppThunkAction<KnownAction | UpdateEntityResourcePlanAction> => (dispatch, getState) => {
        post<UsageUpdateModel>(`api/resourcePlan/setBillingCode`, { billingCodes, entityType })
            .then(data => {
                dispatch({ type: "UPDATE_RESOURCE_USAGE_ATTRIBUTES", data: data.usages });
                dispatch({ type: "USAGE_LOADING", isLoading: false });

                const entityIds = billingCodes.map(_ => _.entityId).filter((_, i, self) => self.indexOf(_) === i);
                if (entityIds.length === 1) {
                    dispatch({ namespace: entityType, type: "UPDATE_ENTITY_RESOURCE_PLAN", entityId: entityIds[0], resourcePlanTotals: data.resourcePlanTotals });
                }
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: "USAGE_LOADING", isLoading: true });
    },
    addResourcesToEntities: (entityType: EntityType, resourceToEntityIds: { resourceId: string, entityId: string }[],
        callback: (entities: any[]) => void, timeframe: IScalableTimeframe)
        : AppThunkAction<KnownAction | ChangeUsageLoadingStateAction | UpdateResourcesUsageAction> => (dispatch, getState) => {
            post<{ usages: ServerResourceUsage[], entities: any[] }>(`api/resourcePlan/resources`, { resourceToEntityIds, entityType, timeframe })
                .then(data => {
                    dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });
                    dispatch({ type: "USAGE_LOADING", isLoading: false });
                    callback(data.entities);
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: "USAGE_LOADING", isLoading: true });
        },
    replaceResourcesInEntities: (entityType: string, entityId: string, prevId: string, nextId: string, keepRate: boolean, sumUpPlanHours: boolean,
        sumUpActualHours: boolean, callback: (entities: any[]) => void, timeframe: IScalableTimeframe)
        : AppThunkAction<KnownAction | ChangeUsageLoadingStateAction | UpdateResourcesUsageAction> => (dispatch, getState) => {
            post<{ usages: ServerResourceUsage[], entities: any[], resourcePlanTotals: ResourcePlanTotalsModel }>(`api/resourcePlan/${entityType}/${entityId}/resource/replace`,
                { prevId, nextId, keepRate, sumUpPlanHours, sumUpActualHours, timeframe })
                .then(data => {
                    dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });
                    dispatch({ type: "USAGE_LOADING", isLoading: false });
                    callback(data.entities);
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: "USAGE_LOADING", isLoading: true });
        },
    removeResourcesFromEntities: (entityType: EntityType, resourceToEntityIds: { resourceId: string, entityId: string }[],
        callback: (entities: any[]) => void, timeframe: IScalableTimeframe)
        : AppThunkAction<KnownAction | ChangeUsageLoadingStateAction | UpdateResourcesUsageAction> => (dispatch, getState) => {
            remove<{ usages: ServerResourceUsage[], entities: any[] }>(`api/resourcePlan/resources`, { resourceToEntityIds, entityType, timeframe })
                .then(data => {
                    dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });
                    dispatch({ type: "USAGE_LOADING", isLoading: false });
                    callback(data.entities);
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: "USAGE_LOADING", isLoading: true });
        },
    updateResourceCalendar: (resourceId: string, model: Partial<CalendarUpdateModel>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<UsageUpdateModel>(`api/resource/${resourceId}/calendar`, {
            updateHours: model.workDayExpectedHrs !== undefined,
            workDayExpectedHrs: model.workDayExpectedHrs,
            toCreate: model.toCreate,
            toUpdate: model.toUpdate,
            toDelete: model.toDelete
        })
            .then(data => {
                dispatch({ type: 'UPDATE_RESOURCE_USAGE', data: data.usages });
                dispatch({ type: "USAGE_LOADING", isLoading: false });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: "USAGE_LOADING", isLoading: true });
    },
    exportExceptionsToFile: (resourceId: string, fields: string[], exceptionIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<string>(`api/resource/${resourceId}/calendar/exceptions/exportToFile`, { fields, ids: exceptionIds })
            .then(data => fileDownload(data, 'Resource Calendar Exceptions.csv'))
            .catch(defaultCatch(dispatch));
    },
    importExceptionsFromFile: (resourceId: string, file: File): AppThunkAction<KnownAction | Notifications.KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        formData.set('data', file);
        post<UsageUpdateModel>(`api/resource/${resourceId}/calendar/exceptions/importFromFile`, formData)
            .then(data => {
                dispatch({ type: 'UPDATE_RESOURCE_USAGE', data: data.usages });
                dispatch({ type: "USAGE_LOADING", isLoading: false });
            })
            .catch(catchApiError(_ => {
                dispatch(Notifications.actionCreators
                    .pushNotification({ message: `Failed to import. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
            }));

        dispatch({ type: "USAGE_LOADING", isLoading: true });
    }
};

export const defaultReducer: Reducer<ResourcesState> = (state: ResourcesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'USAGE_LOADING':
            return {
                ...state,
                isLoadingUsage: action.isLoading
            };
        case 'LOAD_RESOURCES':
            return {
                ...state,
                isLoading: true,
                isListLoading: true,
            };
        case 'RESOURCES_LOADED':
            {
                const resources = action.resources.map(_ => {
                    const resource = toResource(_);
                    checkResourceUsage(resource, state);
                    return resource;
                });

                const newState = {
                    ...state,
                    ...(action.part ? StoreHelper.union(state, resources) : StoreHelper.create(resources)),
                    isLoading: false,
                    isListLoading: false
                };
                if (state.activeEntityId) {
                    newState.activeEntity = newState.byId[state.activeEntityId];
                }
                return newState;
            }
        case 'LOAD_RESOURCE':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id == action.id ? state.activeEntity : undefined,
                isLoading: true
            };
        case 'RECEIVED_REMOVE_RESOURCES_RESULT':
            let newState = { ...state };
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'CREATE_RESOURCE':
            return {
                ...state,
                isLoading: true
            };
        case 'CREATE_RESOURCE_SUCCESS':
            {
                const resource = toResource(action.resource);
                checkResourceUsage(resource, state);
                checkResourceWork(resource, state);

                return {
                    ...state,
                    ...StoreHelper.addOrUpdate(state, resource),
                    activeEntityId: action.isNotSetActiveEntity ? undefined : resource.id,
                    activeEntity: action.isNotSetActiveEntity ? undefined : resource,
                    isLoading: false
                };
            };
        case 'RESOURCE_LOADED':
            {
                const resource = toResource(action.resource);
                checkResourceUsage(resource, state);
                checkResourceWork(resource, state);

                let newState = {
                    ...state,
                    ...StoreHelper.addOrUpdate(state, resource),
                    isLoading: false,
                    isLinking: false
                };
                if (state.activeEntityId) {
                    newState.activeEntity = newState.byId[state.activeEntityId];
                }
                return newState;
            }
        case 'RESOURCES_STATUSES_UPDATED':
            {
                return StoreHelper.applyHandler(state,
                    action.resourceIds,
                    (resource: Resource) => partialUpdate(resource, { attributes: { ...resource.attributes, Status: action.status } }))
            }
        case 'UPDATING_RESOURCE_SECTIONS':
            return {
                ...state,
                isUpdatingSections: true
            };
        case 'UPDATE_RESOURCE_SECTION_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.resourceId,
                        (resource: Resource) => Object.assign({}, resource, { sections: action.sections })),
                    isUpdatingSections: false
                };
            }
        case 'UPDATE_RESOURCE_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.resourceId,
                        (resource: Resource) => Object.assign({}, resource, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_RESOURCE_UICONTROL_SUCCESS':
            {
                return StoreHelper.applyHandler(state, action.uiControlInfo.entityId, (resource: Resource) => MetadataService.UpdateUIControlSettings(resource, action.uiControlInfo));
            }
        case 'BULK_UPDATE_RESOURCES_SUCCESS':
            {
                const resources = action.resources.map(_ => {
                    const resource = toResource(_);
                    checkResourceUsage(resource, state);
                    return resource;
                });
                return {
                    ...state,
                    ...StoreHelper.union(state, resources),
                    isLoading: false
                };
            }
        case 'UPDATE_RESOURCE_IMAGE':
            {
                return StoreHelper.applyHandler(state, action.resourceId, (resource: Resource) => Object.assign({}, resource, { imageId: action.imageId }));
            }
        case 'RESOURCE_LINKING':
            {
                return {
                    ...state,
                    isLinking: true
                }
            }
        case 'UPDATE_RESOURCE_USAGE':
            {
                const map = toDictionaryById(action.data);
                return StoreHelper.applyHandler(state, Object.keys(map), (_: Resource) => partialUpdate(_, { usage: toResourceUsage(map[_.id]) }));
            }
        case 'UPDATE_RESOURCE_USAGE_ATTRIBUTES': 
            {
                const map = toDictionaryById(action.data);
                return StoreHelper.applyHandler(state, Object.keys(map), (_: Resource) => (
                    !!_.usage 
                        ? partialUpdate(_, { usage: partialUpdate(_.usage, { byEntity: toResourceEntityUsage(map[_.id].byEntity, _.usage.byEntity) }) }) 
                        : _
                ))
            }
        case 'REMOVED_RESOURCES_SOURCE_INFOS':
            return {
                ...state,
                ...StoreHelper.applyHandler(state, state.allIds, (resource: Resource) => partialUpdate(resource, { sourceInfos: resource.sourceInfos.filter(_ => _.connectionId !== action.connectionId) })),
                activeEntity: state.activeEntity
                    ? { ...state.activeEntity, sourceInfos: state.activeEntity.sourceInfos.filter(_ => _.connectionId !== action.connectionId) }
                    : state.activeEntity
            };
        default: const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

function checkResourceWork(resource: Resource, state: ResourcesState): void {
    if (resource.work) {
        resource.work?.forEach(_ => _.groupsKeys = groupTypes.map(g => g.keygen(_)));
    }
    else if (state.byId[resource.id]?.work) {
        resource.work = state.byId[resource.id].work;
    }
}

function checkResourceUsage(resource: Resource, state: ResourcesState): void {
    if (!resource.usage && state.byId[resource.id]?.usage) {
        resource.usage = state.byId[resource.id].usage;
    }
}

export const reducer: Reducer<ResourcesState> = (state: ResourcesState = unloadedState, incomingAction: Action) => {
    return defaultReducer(importExportReducer(state, incomingAction), incomingAction);
};

export const actionCreators = {
    ...defaultActionCreators,
    ...importExportActionCreators
};