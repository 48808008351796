import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Resource, createResourceCalendar } from '../../../store/ResourcesListStore';
import { CalendarDataSet } from '../../../store/CalendarStore';
import { FormatDate, formatValue } from '../../utils/common';
import { calculateSummary } from './ResourceUsageCell';
import { EntityType, mapServerEntityType } from '../../../entities/common';
type OwnProps = {
    resources: Resource[];
    startDate: Date;
    finishDate: Date;
    entityId?: string;
    entityType: EntityType;
};

type StateProps = {
    calendar: CalendarDataSet;
    resourcePlanningLevel: EntityType;
}
type Props = OwnProps & StateProps;

const TotalTooltipContent = ({ resources, startDate, finishDate, entityId, calendar, entityType, resourcePlanningLevel }: Props) => {
    const isSingleDay = startDate.getBeginOfDay().getTime() == finishDate.getBeginOfDay().getTime();
    const calendarWithExceptions = resources.length == 1 ? createResourceCalendar(resources[0], calendar) : calendar;
    const calendarException = isSingleDay && calendarWithExceptions.exceptionsHoursMap[startDate.getBeginOfDay().getTime()] !== undefined
        ? calendarWithExceptions.exceptions.find(_ => _.start <= startDate && startDate <= _.end && _.days.includes(startDate.getDay()))
        : undefined;
    const { availability, capacityHours, committedHours, proposedHours, actualHours, committedByEntity } = resources
        .map(resource => calculateSummary(resource, startDate, finishDate, calendar, entityType, resourcePlanningLevel))
        .reduce((cum, cur) => ({
            availability: cum.availability + cur.availability,
            capacityHours: cum.capacityHours + cur.capacityHours,
            committedHours: cum.committedHours + (entityId ? cur.committedByEntity[entityId] || 0 : cur.committedWork),
            proposedHours: cum.proposedHours + (entityId ? cur.proposedByEntity[entityId] || 0 : cur.proposedWork),
            actualHours: cum.actualHours + (entityId ? cur.actualByEntity[entityId] || 0 : cur.actualWork),
            committedByEntity: [...cum.committedByEntity, cur.committedByEntity],
        }), { availability: 0, capacityHours: 0, committedHours: 0, proposedHours: 0, actualHours: 0, committedByEntity: [] });
    const otherWork = !entityId ? undefined : committedByEntity
        .map(_ => Object.keys(_).filter(__ => __ !== entityId).reduce((cum, cur) => cum + _[cur], 0))
        .reduce((cum, cur) => cum + cur, 0);

    return <div className="timeline-tooltip total">
        <div className="content">
            {isSingleDay
                ? renderField("Date", <div className="no-overflow ellipsis">
                    {FormatDate(startDate, { weekday: 'long' })}
                    {calendarException ? <> - <span title={calendarException.name}>{calendarException.name}</span></> : ''}
                </div>)
                : renderField("Period", <div>{FormatDate(startDate)} - {FormatDate(finishDate)}</div>)}
            {!entityId && renderField("Availability",
                <div><span className={`progress-tip ${availability > 0 ? '' : 'gray'}`}>{formatValue(availability)}</span> hour{availability !== 1 ? 's' : ''}</div>)}
            {renderField("Capacity",
                <div>{formatValue(capacityHours)} hour{capacityHours !== 1 ? 's' : ''}</div>)}
            {renderField("Committed",
                <div><span className="progress-tip gray">
                    {formatValue(committedHours)}</span> hour{committedHours !== 1 ? 's' : ''} {entityId ? `on this ${entityType}` : ''}</div>)}
            {renderField("Proposed",
                <div><span className="progress-tip gray">
                    {formatValue(proposedHours)}</span> hour{proposedHours !== 1 ? 's' : ''} {entityId ? `on this ${entityType}` : ''}</div>)}
            {renderField("Actual",
                <div>{formatValue(actualHours)} hour{actualHours !== 1 ? 's' : ''}</div>)}
            {!!entityId && renderField("Other Work", <div>{formatValue(otherWork)} hours</div>)}
        </div>
    </div>
}

const renderField = (name: string, valueElement: JSX.Element, className?: string): JSX.Element | null => {
    return (
        <div className={`item align-center ${className || ''}`}>
            {!!name && <div className="label">{name}</div>}
            {valueElement}
        </div>
    );
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        calendar: state.calendar,
        resourcePlanningLevel: mapServerEntityType[state.tenant.resourcePlanningSettings?.resourcePlanningLevel!]!
    };
}

export default connect(mapStateToProps)(TotalTooltipContent);