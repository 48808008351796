import React from "react";
import { formatValue } from "../../../components/utils/common";
import { FormatType } from "../../../entities/Metadata";

interface OwnProps {
    value: number | undefined;
    title: string;
    className?: string;
    onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
    expected?: number;
    isDayOff: boolean;
}

type Props = OwnProps;

export default function TimeTrackingCellValue(props: Props) {
    const { value, title, className, onDoubleClick, expected, isDayOff } = props;
    const isExceeded = expected !== undefined && !!value && value > expected;

    return (
        <div
            onDoubleClick={
                onDoubleClick
                    ? ev => {
                        onDoubleClick(ev);
                        ev.stopPropagation();
                    }
                    : undefined
            }
            className={`value-wrap ${className ?? ""}`}
            title={title}>
            
            <div className="value">
                {expected || isExceeded
                    ?
                    <>
                        {formatValue(value!, FormatType.Duration)} of {formatValue(expected, FormatType.Duration)}
                    </>
                    :
                    <>
                        {value
                            ? formatValue(value, FormatType.Duration)
                            : isDayOff
                                ? ""
                                : "-"
                        }
                    </>
                }
            </div>
           
        </div>
    );
}
