import * as React from 'react';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';

type Props = {
    onConfirm: () => void;
    onDismiss: () => void;
}
const DemoDataDeployDialog = (props: Props) => <ConfirmationDialog
    onYes={props.onConfirm}
    onDismiss={props.onDismiss}
    yesButtonProps={{ text: "Deploy" }}
    dialogContentProps={{
        title: "Use demonstration data",
        subText: "As a PPM Express Administrator, you can deploy the demonstration data that will be visible for everyone in your PPM Express tenant. \
All users can then play with the data and remove it at any point."
    }}>
</ConfirmationDialog>;
export default DemoDataDeployDialog;