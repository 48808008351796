import * as React from 'react';
import { IWarning } from '../../../entities/common';
import EntityWarning from "./EntityWarning";

type Props = { warningsTypes?: string[], warnings: IWarning[] }
type State = { warnings: IWarning[] }

export class EntitySectionWarning extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { warnings: [] };
    }

    componentWillMount() {
        this.setState(this.buildState(this.props));
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState(this.buildState(nextProps));
    }

    render() {
        return (
            <EntityWarning warnings={this.state.warnings}
                           multipleItemsMessage={"There are warnings related to this section. Please review marked items below."}/>
        );
    }

    private buildState(nextProps: Props) {
        const { warnings, warningsTypes } = nextProps;
        return { warnings: warningsTypes ? warnings.filter(_ => warningsTypes.find(w => w == _.type)) : []};
    }
}