import * as React from 'react';
import { ISliderProps, Slider } from "office-ui-fabric-react";
import { debounce } from '../utils/common';
import TextInput from './inputs/TextInput';
import { IValidator } from '../../validation';
import InputErrorMessage from './inputs/inputErrorMessage';

export interface IDebouncedSliderProps extends ISliderProps {
    onDebouncedChange: (value: number) => void;
    showInput?: boolean;
    validator?: IValidator;
}
type State = {
    value?: number;
    inputValue?: string;
};

export default class DebouncedSlider extends React.Component<IDebouncedSliderProps, State> {
    private debouncedSaveAttribute = debounce<number>(500);

    constructor(props: IDebouncedSliderProps) {
        super(props);
        this.state = {
            value: this.props.value,
            inputValue: this.props.value?.toString()
        };
    }

    componentWillReceiveProps(nextProps: IDebouncedSliderProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({ value: nextProps.value, inputValue: nextProps.value?.toString() });
        }
    }

    public render() {
        return <>
            <div className="slider-with-input" >
                <Slider {...this.props} value={this.state.value} onChange={this.onChange} showValue={!!this.props.showValue && !this.props.showInput} />
                {this.props.showInput &&
                    <TextInput
                        disabled={this.props.disabled}
                        onChanged={this.onInputChanged}
                        onEditComplete={this.onInputEditComplete}
                        value={this.state.inputValue}
                    />
                }
            </div>
            {this.props.showInput && <InputErrorMessage text={this.getErrorMessage(this.state.inputValue)} />}
        </>;
    }

    private getErrorMessage = (value?: string) => {
        return this.props.validator && this.props.validator.getErrorMessage(value);
    }

    private onInputChanged = (fieldValue: any): void => {
        this.setState({ inputValue: fieldValue });
        if (typeof fieldValue == 'string') {
            fieldValue = (fieldValue as string).trim();
        }
        const number = fieldValue ? Number(fieldValue) : null;
        if (number != null && !Number.isNaN(number) && this.props.validator?.isValid(number)) {
            this.onChange(number);
        }
    }

    private onInputEditComplete = (fieldValue: string | null): void => {
        const number = fieldValue ? Number(fieldValue) : null;
        if (number != null && !Number.isNaN(number) && this.props.validator?.isValid(number)) {
            this.onChange(number);
        } else {
            this.setState({ inputValue: this.state.value?.toString() });
        }
    }

    private onChange = (value: number): void => {
        this.setState({ value, inputValue: value.toString() });
        this.props.onChange && this.props.onChange(value);
        this.props.onDebouncedChange && this.debouncedSaveAttribute(value, this.props.onDebouncedChange);
    }
}