import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import { IFieldActions } from '../FieldPanel';
import { FieldCard } from './FieldCard';
import RemoveDialog from '../../common/RemoveDialog';

type CardProps = {
    field: Metadata.Field;
    index: number;
    selected: boolean;
    isNew: boolean;
    actions?: IFieldActions;
    allowManage?: boolean;
    onEditClick: (field: Metadata.Field) => void;
    onChange?: (field: Metadata.Field, checked: boolean, selectedIndex?: number) => void;
    hideToggle?: boolean;
}

const ManageFieldCard = React.forwardRef<HTMLDivElement, CardProps>((props, ref) => {
    const { field, index, selected, isNew, allowManage, hideToggle } = props;
    const [isRemoving, setIsRemoving] = React.useState(false);
    const onRemoveClick = React.useCallback(() => setIsRemoving(true), []);
    const onRemoveComplete = React.useCallback(() => {
        props.actions?.removeField!(field.id);
        analytics.track('Delete', { itemType: 'field', a: field.name });
    }, []);
    const onToggle = props.onChange
        ? React.useCallback((f: Metadata.Field, checked: boolean) => {
            props.onChange!(f, checked, checked ? index : undefined)
        }, [index, props.onChange])
        : undefined;
    const resetRemoving = React.useCallback(() => setIsRemoving(false), []);
    return <>
        <FieldCard
            ref={ref}
            key={field.id}
            selected={selected}
            field={field}
            isNew={isNew}
            allowManage={allowManage}
            onEditClick={props.onEditClick}
            onRemoveClick={onRemoveClick}
            hideToggle={hideToggle}
            onToggle={onToggle} />
        {
            isRemoving && <RemoveDialog
                onClose={resetRemoving}
                onComplete={onRemoveComplete}
                confirmButtonProps={{ text: "Delete" }}
                dialogContentProps={{
                    title: "Delete field",
                    subText: `Are you sure you want to delete field "${Metadata.getLabel(field)}" and field data in all related entities? This action can't be undone.`
                }} />
        }
    </>
});

export default ManageFieldCard;