import * as React from 'react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import DebouncedSlider from "../DebouncedSlider";

export type SliderProps = IFormInputProps<number>;

export default class SliderInput extends React.Component<SliderProps> {
    public render() {
        return <DebouncedSlider
            {...this.props.inputProps}
            disabled={this.props.disabled || this.props.readOnly || this.props.inputProps?.disabled || this.props.inputProps?.readOnly}
            value={this.props.value}
            onDebouncedChange={this.onChange}
            validator={this.props.validator}
            showInput />;
    }

    private onChange = (value: number): void => {
        this.props.onChanged && this.props.onChanged(value);
        this.props.onEditComplete && this.props.onEditComplete(value);
    }
}