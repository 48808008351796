import * as React from 'react';
import { RowMenu, RowMenuProps } from './RowMenu';
import { Dictionary, EntityType } from '../../../entities/common';
import { ExtensionInfo, ExtensionPageLocation, findExtension } from '../../../store/ExtensionStore';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { buildExtensionsMenuItem } from '../headerMenuItemBuilders';
import ExtensionPanel from '../ExtensionPanel';
import { notUndefined } from '../../utils/common';
import { IContextualMenuItem } from 'office-ui-fabric-react';

export type RowMenuWithExtensionsProps = RowMenuProps & {
    entityType: EntityType;
    context?: Dictionary<any>;
    commandsBefore?: IContextualMenuItem[];
};

type StateProps = {
    extensions: ExtensionInfo[];
};

type Props = RowMenuWithExtensionsProps & StateProps;

const RowMenuWithExtensions = (props: Props) => {
    const { extensions, entityType, commandsBefore, item, context } = props;
    const [isExtensionPanelOpen, setIsExtensionPanelOpen] = React.useState<ExtensionInfo>();
    const commands = [
        ...commandsBefore ?? [],
        ...(item.isEditable || item.allowExtensions ? buildExtensionsMenuItem(extensions, setIsExtensionPanelOpen) : []),
        ...props.commands
    ].filter(notUndefined);

    return <>
        <RowMenu
            {...props}
            commands={commands}
        />
        {isExtensionPanelOpen && <ExtensionPanel
            entityType={entityType}
            info={isExtensionPanelOpen}
            onDismiss={() => setIsExtensionPanelOpen(undefined)}
            context={{ ...context, entityId: item?.id, entityType }}
            urlParams={{ entityId: item?.id }}
        />}
    </>;
}

function mapStateToProps(state: ApplicationState, ownProps: RowMenuWithExtensionsProps): StateProps {
    return {
        extensions: findExtension(state.extensions, ownProps.entityType, ExtensionPageLocation.EntityAction, ownProps.item.sourceType)
    }
}

export default connect(mapStateToProps)(RowMenuWithExtensions);