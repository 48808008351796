import * as React from 'react';
import { Dictionary, EntityType, IConnected, IWithSourceInfos } from '../../../../entities/common';
import { Field } from "../../../../entities/Metadata";
import { TaskProgressData } from '../../../../store/ProjectsListStore';
import LinkedSystemIcons from '../../../common/sectionsControl/uiControls/summaryControl/LinkedSystemIcons';

type Props = {
    field: Field,
    taskProgresses?: Dictionary<TaskProgressData>,
    entityType: EntityType;
} & IWithSourceInfos;

export default class BaseLinkedSystem extends React.Component<Props> {
    public render() {
        const { field, sourceInfos, taskProgresses, entityType } = this.props;

        if (!field) {
            return <span>Invalid column configuration</span>;
        }

        return sourceInfos.length
            ? <div className="entityName clickable overflow-text font-14">
                <LinkedSystemIcons
                    sourceInfos={sourceInfos}
                    taskProgresses={taskProgresses}
                    showTitle
                    allowNavigate
                    entityType={entityType} />
            </div>
            : null;
    }
}
