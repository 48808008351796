import * as React from 'react';
import { connect } from 'react-redux';
import { UserState, actionCreators } from "../../store/User";
import { LicenseType } from "../../store/permissions";
import { ApplicationState } from "../../store/index";
import ErrorPage from '../ErrorPage';
import { Redirect } from "react-router-dom";
import { DefaultButton } from 'office-ui-fabric-react';

type StateProps = { user: UserState };
type Props = StateProps & typeof actionCreators;

type State = {
    isLicenseRequested: boolean;
}

class NoLicense extends React.Component<Props, State>  {
    constructor(props: Props) {
        super(props);
        this.state = { isLicenseRequested: false }
    }

    public render() {
        if (this.props.user.license !== LicenseType.None) {
            return <Redirect to="/" />;
        }

        return <ErrorPage
            title="You do not have valid license"
            description="You are registered in PPM Express but have no license assigned. Please contact administrator of PPM Express for your organization or requests a license below."
            onButtonsRender={this._onButtonsRender}
        />;
    }

    private _onButtonsRender = () => {
        const { isLicenseRequested } = this.state;
        return <DefaultButton
            text="Request License"
            disabled={isLicenseRequested}
            primary={true}
            onClick={() => {
                this.props.requestLicense();
                this.setState({ isLicenseRequested: true })
            }} />;
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return { user: state.user };
}

export default connect(mapStateToProps, actionCreators)(NoLicense);