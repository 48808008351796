import React from 'react'
import { ITimelineSegment } from '../../../components/common/timeline/TimelineSegment';
import { formatValue } from '../../..//components/utils/common';
import { FormatType } from '../../..//entities/Metadata';
import { ReportedResourceProjectTime } from '../../..//store/ReportedTimeStore'

type Props = {
    reportedTime: ReportedResourceProjectTime[];
    segment: ITimelineSegment;
    isTotal: boolean;

    expected?: number;
}

export default function ReportedTimeCell(props: Props) {

    const { reportedTime, segment, isTotal, expected } = props;

    const reported = reportedTime
        .filter(_ => _.date >= segment.startDate && _.date <= segment.finishDate)
        .reduce((sum, current) => sum + current.duration, 0);

    const isExceeded = expected !== undefined && !!reported && reported > expected;
    
    const className = [
        'time-tracking-cell',
        'fill-space',
        isTotal ? 'total' : undefined,
        isExceeded ? 'exceeded' : undefined
    ].join(" ");

    
    return (
        <div className={className}>
            <div className={`cell-content fill-space columns-${1}`}>
                <div className='value-wrap'>
                    <div className="value">
                        {!!expected || isExceeded
                            ?
                            <>
                                {formatValue(reported!, FormatType.Duration)} of {formatValue(expected, FormatType.Duration)}
                            </>
                            :
                            <>
                                {!reported ? "-" : formatValue(reported, FormatType.Duration)}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
