import * as React from 'react';
import { IRoadmapItem } from '../../entities/Subentities';
import EntityWarning from '../common/warnings/EntityWarning';
import { getRoadmapItemIsPlanned } from '../../store/RoadmapsListStore';
import { IWarning } from '../../entities/common';

export const RoadmapSummaryWarning = (props: { roadmapItems: IRoadmapItem[] }): JSX.Element | null => {
    const warnings = props.roadmapItems.filter(_ => _.warnings.length > 0)
        .reduce(
            (aggr, _) => getRoadmapItemIsPlanned(_)
                ? ({ ...aggr, planned: [...aggr.planned, _.warnings[0]] })
                : ({ ...aggr, backlog: [...aggr.backlog, _.warnings[0]] }),
            { planned: [], backlog: [] }
        );

    if (warnings.planned.length === 0 && warnings.backlog.length === 0) {
        return null;
    }

    return <EntityWarning className="warning-button-wrapper"
        asButton={true}
        tooltipTitle="Warnings"
        onRenderBody={() => <>
            <ul className="list">
                {warnings.planned.length > 0 && <li key={`planned`}>
                    {warnings.planned.length} item{warnings.planned.length > 1 ? 's' : ''} with warnings in the Planned / Roadmap</li>}
                {warnings.backlog.length > 0 && <li key={`backlog`}>
                    {warnings.backlog.length} item{warnings.backlog.length > 1 ? 's' : ''} with warnings in the Backlog</li>}
            </ul>
            <div>For more details please review items with a warning icon.</div>
        </>}
        warnings={[{} as IWarning]}
    />;
}
