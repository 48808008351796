import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Program } from "../../../../../store/ProgramsListStore";
import EntityName from '../EntityName';

export default class Name extends React.Component<IListViewFieldColumn<Program>> {
    public render() {
        const { entity, isTimelineView, className, disableNavigation } = this.props;
        return <EntityName
            name={entity.attributes.Name}
            startDate={entity.attributes.StartDate}
            finishDate={entity.attributes.FinishDate}
            withDates={true}
            isTimelineView={isTimelineView}
            url={!disableNavigation ? `/program/${entity.id}` : undefined}
            className={className}
            imageId={entity.imageId}
            imageClassName='prog-logo'
            warnings={entity.warnings}
            multipleItemsMessage="There are warnings related to this program. Please review program details for more information"
        />;
    }
}