import * as React from "react";
import {
    IDetailsHeaderProps, IDetailsListProps, IRenderFunction, ITooltipHostProps, ScrollablePane, ScrollbarVisibility,
    Sticky, StickyPositionType, TooltipHost
} from "office-ui-fabric-react";
import DetailsListWrapper from "./DetailsListWrapper";

type Props = Omit<IDetailsListProps, "onRenderDetailsHeader">

export const StickyDetailsList = (props: Props) => {
    return <ScrollablePane
        scrollbarVisibility={ScrollbarVisibility.auto}
        className={`list-container header-with-bottom-line`}>
        <DetailsListWrapper {...props} onRenderDetailsHeader={onRenderHeader} />
    </ScrollablePane>
}
const onRenderHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>) =>
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender!({
            ...props!,
            onRenderColumnHeaderTooltip: (tooltipHostProps?: ITooltipHostProps) => <TooltipHost {...tooltipHostProps!} />
        })}
    </Sticky>;
