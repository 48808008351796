import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { Field, getLabel } from '../../entities/Metadata';
import { IRoadmapItemAttrs } from '../../entities/Subentities';
import TooltipField from './TooltipField';
import { EntityType } from '../../entities/common';

type OwnProps = {
    name: keyof IRoadmapItemAttrs;
};

type StateProps = {
    fields: Field[];
};

type Props = OwnProps & StateProps;

const RoadmapItemAttrsTooltipField = (props: PropsWithChildren<Props>) => {
    const { name, children } = props;
    const field = props.fields.find(_ => _.name === name);
    if (!field) {
        return null;
    }
    return (
        <TooltipField label={getLabel(field)}>
            {children}
        </TooltipField>
    );
};

function mapStateToProps(state: ApplicationState): StateProps {
    const fields = state.fields[EntityType.RoadmapItem];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
    };
}

export default connect(mapStateToProps)(RoadmapItemAttrsTooltipField);
