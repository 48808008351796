import * as React from 'react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'

interface IErrorInputProps extends IFormInputProps<any> {
    errorMessage: string;
}

export default class ErrorInput extends React.Component<IErrorInputProps, any> {
    public render(): JSX.Element {
        return (
            <div className="error-message">{this.props.errorMessage}</div>
        );
    }
}