import { IPersonaProps, Icon } from "office-ui-fabric-react";
import { post } from "../../../fetch-interceptor";
import { IUser } from "../../../store/UsersListStore";
import { DebouncedPPMXPeoplePicker } from "./PPMXPeoplePicker";
import { getPersonInfoImageUrl } from "../../utils/common";
import { Validator } from "../../../validation";
import React from "react";

const validators = {
    email: Validator.new().required().email().build()
}

type Props = {
    pickerPlaceholder: string;
    selectedUsers: IUserProps[];
    setSelectedUsers: (users: IUserProps[]) => void;
    maxUsersCount?: number;
}

export interface IUserProps extends IPersonaProps { email: string; externalUser?: boolean; };

const PPMXSharePeoplePicker = (props: Props) => {
    const { selectedUsers, pickerPlaceholder, setSelectedUsers} = props;

    const getPersona = (info: IUser): IUserProps => {
        return { id: info.id, text: info.fullName, email: info.email, imageUrl: getPersonInfoImageUrl(info)};
    }

    const onUserSearch = (filterText: string, selectedItems?: IPersonaProps[]): Promise<IPersonaProps[]> | IPersonaProps[] => {
        if (!filterText) {
            return [];
        }

        if (selectedItems && props.maxUsersCount && selectedItems.length >= props.maxUsersCount){
            return [];
        }

        const exceptIds = selectedItems?.filter(_ => _.id).map(_ => _.id);
        return post<IUser[]>('api/user/find', { name: filterText, exclude: exceptIds, searchByEmail: true })
            .then(data => {
                let users = data.map(getPersona);
                if (!users.length && validators.email.isValid(filterText) && !selectedUsers.some(_ => _.email.toLowerCase() === filterText.toLowerCase())) {
                    users = [{
                        initialsColor: '#128B10',
                        onRenderInitials: () => <Icon iconName="PPMXPeopleInvite" />,
                        text: `Add email: ${filterText}`,
                        email: filterText.toLowerCase(),
                        externalUser: true,
                        coinSize: 0
                    }]
                }
                return users;
            })
            .catch(_ => []);
    };

    const onChange = (values?: IUserProps[]) => {
        const users = values?.map(_ => { 
            return _.externalUser 
                ? { 
                    email: _.email,
                    text: _.email,
                    coinProps: { styles: { imageArea: { display: 'none' } } }, 
                } as IUserProps 
                : _; 
        }) || [];

        setSelectedUsers(users);
    }

    return <DebouncedPPMXPeoplePicker
        inputProps={{ placeholder: pickerPlaceholder }}
        selectedItems={selectedUsers}
        className='ms-PeoplePicker'
        disabledEmptyInputFocus
        onResolveSuggestions={onUserSearch}
        onChange={onChange}
        pickerSuggestionsProps={{
            noResultsFoundText: !!props.maxUsersCount && selectedUsers.length >= props.maxUsersCount ? `Maximum ${props.maxUsersCount} emails allowed` : "No people found"
        }}
    />;
}

export default PPMXSharePeoplePicker;