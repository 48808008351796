import * as React from 'react';
import './NavigationHeaderActions.css';
import { connect } from 'react-redux';
import { PersonaCoin, DefaultButton, PersonaSize, ActionButton } from 'office-ui-fabric-react';
import { getPersonInfoImageUrl, inIframe } from "../../utils/common";
import { ApplicationState } from "../../../store/index";
import { UserState, isAdminViewer } from '../../../store/User';
import { contains, CommonOperations } from '../../../store/permissions';
import { TenantState, checkIfADUsersSyncEnabled } from '../../../store/Tenant';
import Breadcrumbs, { NavigationProps } from "../Breadcrumbs";
import * as ErrorStore from '../../../store/ErrorStore';
import InvitePeople from '../InvitePeople';
import BookFreeOnboardingButton from '../BookFreeOnboardingButton';
import HeaderSettingsButton from '../HeaderSettingsButton';
import HeaderHelpButton from '../HeaderHelpButton';
import AccountPanel, { VIEWER_PREFIX } from '../AccountPanel';
import GlobalSearch from '../GlobalSearch';

type OwnProps = NavigationProps & {
    allowChangeAccount: boolean;
};

type StateProps = {
    user: UserState;
    tenant: TenantState;
    error: ErrorStore.ErrorState;
};

type Props = OwnProps & StateProps;

const NavigationHeaderActions = (props: Props) => {
    const { route, router, error, user, tenant, allowChangeAccount } = props;
    const [showUserInfo, setShowUserInfo] = React.useState(false);
    
    const isUserAuthenticated = !!user.name;
    const isViewer = user.permissions?.common === CommonOperations.AdministrateView;
    const hasAdministrate = user.permissions && contains(user.permissions.common, CommonOperations.Administrate);
    
    const hideNavigation = !!error.title || route.hideNavigation || inIframe();
    const hideInviteBtn = !!error.title || route.hideNavigation || isAdminViewer(user) || (!hasAdministrate && !tenant.security?.allowEveryoneToInviteUsers)
        || checkIfADUsersSyncEnabled(tenant);
    
    return (
        <div className="nav-header-actions-container">
            <Breadcrumbs route={{ ...route, hideNavigation }} />
            <div className="nav-header-buttons">
                {!hideInviteBtn && <InvitePeople className="nav-header-button with-background allow-shrink-title" />}
                {!hideNavigation && <BookFreeOnboardingButton />}
            </div>
            {!hideNavigation && <GlobalSearch />}
            <div className='nav-header-actions'>
                <div className="nav-header-settings">
                    {!hideNavigation && !inIframe() && <HeaderSettingsButton route={route} router={router} />}
                    <HeaderHelpButton />
                </div>
                {isUserAuthenticated
                    ? <>
                        <DefaultButton className="nav-button nav-button-persona" onClick={() => setShowUserInfo(true)}>
                            {isViewer && <span className="viewer-prefix-title">{VIEWER_PREFIX}</span>}
                            <PersonaCoin
                                size={PersonaSize.size32}
                                imageUrl={getPersonInfoImageUrl(user)}
                            />
                        </DefaultButton>
                        <AccountPanel
                            route={route}
                            router={router}
                            isOpen={showUserInfo}
                            onDismiss={() => setShowUserInfo(false)}
                            allowChangeAccount={allowChangeAccount}
                        />
                    </>
                    : <div className="actions">
                        <ActionButton className="nav-button" iconProps={{ iconName: 'SignIn' }} href='/login'>Log in</ActionButton>
                    </div>}
            </div>
        </div>
    );
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        error: state.error
    };
}

export default connect(mapStateToProps)(NavigationHeaderActions);
