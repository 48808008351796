import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';

type Props = {
    isEdit: boolean;
    sourceType: SourceType;
}

export const ConnectionTitle = (props: Props) => <>
    {props.isEdit ? "Edit" : 'Create'} <Icon iconName={SourceType_.getIconName(props.sourceType)} /> {SourceType_.getName(props.sourceType)} connection
</>;
export default ConnectionTitle;
