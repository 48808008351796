import * as React from "react";
import { PrimaryButton, Button, Dialog, IModalProps, DialogType, DialogFooter, IDialogContentProps } from 'office-ui-fabric-react';

export const ConfirmationDialog: React.FunctionComponent<{
    onDismiss: () => void,
    onYes?: () => void,
    onNo?: () => void,
    dialogContentProps?: IDialogContentProps,
    modalProps?: IModalProps,
    yesButtonProps?: { text?: string, disabled?: boolean },
    noButtonProps?: { text?: string, disabled?: boolean }
}> = props => <Dialog
    maxWidth={500}
    hidden={false}
    onDismiss={props.onDismiss}
    dialogContentProps={{
        type: DialogType.close,
        ...props.dialogContentProps
    }}
    modalProps={{
        className: "remove-dialog",
        isBlocking: false,
        containerClassName: 'ms-dialogMainOverride',
        ...props.modalProps
    }}>
        {props.children}
        <DialogFooter>
            {props.onYes && <PrimaryButton
                onClick={() => {
                    props.onYes?.();
                    props.onDismiss();
                }}
                text={'Yes'}
                {...props.yesButtonProps} />}
            {props.onNo && <Button
                onClick={() => {
                    props.onNo?.();
                    props.onDismiss();
                }}
                text={'No'}
                {...props.noButtonProps} />}
        </DialogFooter>
    </Dialog>
