import './SearchBox.css';
import * as React from "react";
import { Field } from "../../../entities/Metadata";
import { Callout, Checkbox, DefaultButton, DirectionalHint, IconButton } from "office-ui-fabric-react";
import { arraysEqual, notUndefined } from '../../utils/common';
import { Dictionary } from '../../../entities/common';
import { SearchFieldService } from '../SearchFieldService';

export type SearchValue = {
    searchText: string;
    searchFields: Field[];
    isFieldFake?: (field: Field) => boolean;
}

interface ISearchBoxProps {
    value?: SearchValue;
    onSearch: (search: SearchValue) => void;
    viewColumns: string[];
    fieldsMap: Dictionary<Field>;
    isFieldFake?: (field: Field) => boolean;
}

export const SearchBox = (props: ISearchBoxProps) => {
    const [searchText, setSearchText] = React.useState("");
    const [editMode, setEditMode] = React.useState(false);
    const [configureMode, setConfigureMode] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [viewFields, setViewFields] = React.useState<Field[]>(filterFields(props.viewColumns, props.fieldsMap));
    const [searchFields, setSearchFields] = React.useState<Field[]>(filterFields(props.viewColumns, props.fieldsMap));

    React.useEffect(() => {
        if (props.value && !arraysEqual(searchFields, props.value?.searchFields)) {
            setSearchFields(props.value?.searchFields);
        }
        if (props.value && props.value?.searchText !== searchText) {
            setSearchText(props.value?.searchText)
        }
        if (props.value && !!props.value?.searchText !== editMode) {
            setEditMode(true);
        }
    }, [props.value, editMode, searchText, searchFields])

    React.useEffect(() => {
        const newViewFields = filterFields(props.viewColumns, props.fieldsMap);
        if (arraysEqual(newViewFields.map(_ => _.id), viewFields.map(_ => _.id))) {
            return;
        }
        setViewFields(newViewFields);
        setSearchFields(newViewFields);
        onDismiss();
    }, [props.viewColumns, props.fieldsMap]);

    const changeSelectedFields = React.useCallback((newSearchFields: Field[]) => {
        setSearchFields(newSearchFields);
        props.onSearch({ searchText, searchFields: newSearchFields, isFieldFake: props.isFieldFake });
    }, [props.onSearch, searchText, props.isFieldFake]);

    const onClear = React.useCallback(() => {
        setSearchText("");
        props.onSearch({ searchText: "", searchFields, isFieldFake: props.isFieldFake });
        inputRef.current?.focus();
    }, [searchFields, props.isFieldFake]);

    const onDismiss = React.useCallback(() => {
        onClear();
        setEditMode(false);
    }, [onClear]);

    const onSearch = React.useCallback((value: string) => {
        setSearchText(value);
        return props.onSearch({ searchText: value, searchFields, isFieldFake: props.isFieldFake });
    }, [searchFields, props.isFieldFake]);

    const onBlur = React.useCallback((ev) => {
        if (!ev.currentTarget.contains?.(ev.relatedTarget)) {
            if (!searchText && !configureMode) {
                setEditMode(false);
            }
            setIsActive(false);
        }
    }, [searchText, configureMode]);

    const onDismissConfigure = React.useCallback((ev) => {
        setConfigureMode(false);
        if (!ev.currentTarget.contains?.(ev.relatedTarget) && !searchText) {
            setEditMode(false);
        }
    }, [searchText]);

    const onFocusInput = React.useCallback(() => {
        if (configureMode) {
            setConfigureMode(false);
        }
    }, [configureMode]);

    const onFocus = React.useCallback(() => {
        setIsActive(true);
    }, []);

    React.useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onDismiss();
            }
            // Bug 14156: Search. The pointer jumps to the Actions button after moving the pointer to first place in the search field
            // prevent coursour leaving the search field (command bar control default behavior)
            if ((e.key === 'ArrowLeft' && inputRef.current?.selectionStart === 0) ||
                (e.key === 'ArrowRight' && inputRef.current?.selectionStart === inputRef.current?.value.length)) {
                inputRef.current?.focus();
            }
        };
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, []);

    const inputRef = React.useRef<HTMLInputElement>(null);

    return <>
        {!editMode && <DefaultButton
            className={`dropdown-button filter-btn`}
            iconProps={{ iconName: 'Search' }}
            text={props.value?.searchText || "Search"}
            onClick={() => setEditMode(true)} />}
        {editMode && <div className="search-box-wrapper" onFocus={onFocus} onBlur={onBlur}>
            <div className={`search-box ${isActive ? 'is-active' : ''}`}>
                <input
                    type="text"
                    autoFocus
                    placeholder="Search"
                    ref={inputRef}
                    className="search-input"
                    value={searchText}
                    onFocus={onFocusInput}
                    onChange={e => onSearch(e.target.value)} />
                <IconButton
                    iconProps={{ iconName: 'PPMXGear' }}
                    title="Configure columns"
                    onClick={() => setConfigureMode(!configureMode)} />
                {searchText && <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    title="Clear"
                    onClick={onClear} />}
            </div>

            {configureMode && <Callout
                className="configure-columns"
                onDismiss={onDismissConfigure}
                target=".search-box-wrapper"
                calloutWidth={300}
                isBeakVisible={false}
                gapSpace={2}
                preventDismissOnResize={true}
                directionalHint={DirectionalHint.bottomRightEdge}
                directionalHintForRTL={DirectionalHint.bottomLeftEdge}
            >
                <div className="configure-columns-header">
                    <span className="title">Columns to search</span>
                    <span>({searchFields.length} selected)</span>
                </div>

                <div className="configure-columns-buttons">
                    <Checkbox
                        className="select-all"
                        checked={searchFields.length === viewFields.length}
                        label="All columns"
                        onChange={(e, checked) => changeSelectedFields(checked ? viewFields : [])}
                    />
                    {viewFields.map(f => <Checkbox
                        key={f.id}
                        checked={searchFields.includes(f)}
                        label={f.label ?? f.name}
                        title={f.label ?? f.name}
                        onChange={(e, checked) => changeSelectedFields(checked ? [...searchFields, f] : searchFields.filter(c => c !== f))}
                    />)}
                </div>
            </Callout>}
        </div>}
    </>;

    function filterFields(fieldIds: string[], fieldsMap: Dictionary<Field>) {
        return fieldIds.map(_ => props.fieldsMap[_])
            .filter(notUndefined)
            .filter(_ => SearchFieldService.canHandle(_, props.isFieldFake));
    }
}