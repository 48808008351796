import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Challenge } from "../../../../../store/ChallengesListStore";

export default class Ideas extends React.Component<IListViewFieldColumn<Challenge>> {
    public render() {
        const { entity } = this.props;
        return <span className="progress-tip">{entity.calculation.activeIdeasCount}</span>;
    }

    public static getValue(entity: Challenge): number {
        return entity.calculation.activeIdeasCount;
    }

    public static getSearchValue(entity: Challenge) {
        return `${Ideas.getValue(entity)}`;
    }
}