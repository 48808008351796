import * as React from "react";
import './ThemePicker.css';

export type ColorRowType = {
    title?: string;
    options: ThemeOption[];
};

type ThemeOption = {
    id: string;
    title?: string;
    colors: {
        primary: string;
        secondary?: string;
    };
};

type Props = {
    themeId: string;
    row: ColorRowType;
    onColorClick: (id: string) => void;
    hasTitles: boolean;
};

const ColorRow = ({ themeId, row, onColorClick, hasTitles }: Props) => (
    <div className="color-row">
        {(row.title || hasTitles) && <div className="row-title">{row.title}</div>}
        <div className="color-options">
            {row.options.map(option => (
                <div
                    key={option.id}
                    className={`color-option ${option.id === themeId ? 'selected' : ''}`}
                    onClick={() => onColorClick(option.id)}
                    data-id={option.id}
                    title={option.title}
                    style={{
                        background: option.colors.secondary
                            ? `linear-gradient(to right, ${option.colors.primary} 50%, ${option.colors.secondary} 50%)`
                            : option.colors.primary,
                    }}
                ></div>
            ))}
        </div>
    </div>
);

export default ColorRow;
