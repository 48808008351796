import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface UserInterfaceState {
    isFullScreen: boolean;
    sectionId: string | undefined;
}
type ToggleFullScreenAction = { type: 'TOGGLE_FULLSCREEN', value: boolean, sectionId: string }
type KnownAction = ToggleFullScreenAction;


const initialState = {
    isFullScreen: false,
    sectionId: undefined
};

export const actionCreators = {
    toggleFullScreen: (value: boolean, sectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) =>
        dispatch({ type: 'TOGGLE_FULLSCREEN', value, sectionId })
};

export const reducer: Reducer<UserInterfaceState> = (state = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "TOGGLE_FULLSCREEN":
            return {
                ...state,
                isFullScreen: action.value,
                sectionId: action.sectionId
            };
        default:
            return state;
    }
};