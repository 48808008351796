import * as React from 'react';
import { Panel, PanelType} from 'office-ui-fabric-react';
import { IFieldMapping, MappingType, ExternalFieldInfo } from '../../store/integration/common';
import { FieldType } from "../../entities/Metadata";
import { SourceType, SourceTypeMap } from '../../store/ExternalEpmConnectStore';
import { EntityType } from '../../entities/common';
import MappingPanelControl from './MappingPanelControl/MappingPanelControl';

type OwnProps = {
    connector: SourceType;
    entityType: EntityType;
    externalFields: ExternalFieldInfo[];
    typesMap: { [externalFieldMap: string]: { types: Partial<FieldType>[], label: string } };
    mapping: IFieldMapping[] | null;
    updateMapping: (mapping: IFieldMapping[]) => void;
    mappingTypes: MappingType[];
    showTransformationScript?: boolean;
    onDismiss: () => void;
    isLoading: boolean;
    error: string | null;
    disabled?: boolean;
    typeName?: string;
    onRenderHeader?: () => JSX.Element;
    onGetExternalFieldOptions?: (field: ExternalFieldInfo) => Promise<string[] | number[] | null>
}

type StateProps = {
}

const panelWidth: string = "600px";

const defaultHeaderRenderer = (connector: SourceType, typeName?: string) => (): JSX.Element => {
    return <div className="ms-Panel-header">
        <p className="ms-Panel-headerText">Configure {typeName ? `${typeName} ` : ''}import mapping</p>
        <div className='ms-Panel-secondaryText'>Set up fields and directions for the data exchange in {SourceTypeMap[connector]}.</div>
    </div>;
}

const MappingPanel = (props: OwnProps & StateProps) => {

    const { typeName, connector } = props;
    const onRenderHeader = React.useCallback(() => props.onRenderHeader ?? defaultHeaderRenderer(connector, typeName), [connector, typeName])

    return <Panel isLightDismiss
            type={PanelType.custom}
            customWidth={panelWidth}
            className="mapping-panel"
            isOpen
            onRenderHeader={onRenderHeader()}
            onDismiss={props.onDismiss}>
        <MappingPanelControl {...props}
            onRenderHeader={undefined}
            panelWidth={panelWidth}
        />
        </Panel>
}

export default MappingPanel;