import "./PlanCard.css";
import * as React from 'react';
import { DirectionalHint, Icon, Link, TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import { ProductType } from '../../../../store/Tenant';
import { CurrentPlanBadge, BadgeProps } from "./CurrentPlanBadge";
import PlanIcon from "../PlanIcon";
import { FeaturesDescription } from "../common";

type CardProps = {
    isCurrent?: boolean;
    badgeProps?: BadgeProps;
    disabled?: boolean;
    productType: ProductType;
    name: string;
    description: string;
    features?: (FeaturesDescription | string)[];
    onPriceRender?: () => JSX.Element;
    onRenderButtons?: () => JSX.Element;
    onClick?: () => void;
    hideComparePlans?: boolean;
    className?: string;
    iconName?: string;
}

const href = "https://ppm.express/ppm-express-pricing/";

export const PlanCard = (props: CardProps) => {
    const { isCurrent, badgeProps, disabled, productType, name, description, features, hideComparePlans, className } = props;
    return <div className={`card plan-card ${isCurrent ? "current" : ""} ${disabled ? "disabled" : ""} ${className ?? ''}`} onClick={props.onClick}>
        {props.isCurrent && badgeProps && <CurrentPlanBadge {...badgeProps} />}
        <div className='plan-card-header'>
            <PlanIcon productType={productType} iconName={props.iconName} />
            <div className="plan-name">{name}</div>
        </div>
        <div className="plan-description">
            <span>{description}</span>
            {
                !hideComparePlans && <>&nbsp;<Link
                    onClick={_ => _.stopPropagation()}
                    className="pricing"
                    href={href}
                    target="_blank">Compare plans</Link>
                </>
            }
        </div>
        {
            features && !!features.length && <div className='plan-features'>
                <ul>
                    {
                        features.map(_ => typeof _ === "string"
                            ? <li key={_}><span>{_}</span></li>
                            : <li key={_.text} style={{ fontWeight: _.marked ? "bold" : undefined }}>
                                <span>{_.text}</span><Hint text={_.hint} />
                            </li>)
                    }
                </ul>
            </div>
        }
        {
            props.onPriceRender?.()
        }
        <div className="plan-card-space"></div>
        {
            props.onRenderButtons && <div className="buttons">
                {
                    props.onRenderButtons()
                }
            </div>
        }
    </div>
}

const Hint = (props: { text?: string }) => props.text ?
    <TooltipHost
        calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
        tooltipProps={{
            onRenderContent: () => {
                return (
                    <div style={{ padding: 10 }}>
                        {props.text}
                    </div>
                );
            }
        }}
        delay={TooltipDelay.zero}>
        <Icon
            onClick={(e) => e.stopPropagation()}
            iconName="Info"
            className='info-icon'
        />
    </TooltipHost>
    : null;