import React, { useState } from 'react';
import { IFindResult } from "../../common/inputs/EntityPickerInput";
import { IGroupInfo, Group } from '../../../entities/Metadata';
import { ResourceOperations } from "../../../store/permissions";
import GroupDropdown from '../../common/inputs/GroupDropdown';
import ComboboxPicker from '../../common/inputs/ComboboxPicker';
import { ParamsControlProps } from './EntityTypeButton';
import { ServerEntityType } from '../../../entities/common';

export default function TaskParamsControl(props: ParamsControlProps) {
    const [selectedProject, setSelectedProject] = useState<IFindResult>();
    const [selectedGroup, setSelectedGroup] = useState<IGroupInfo>();
    const [groups, setGroups] = useState<Group[]>([]);

    return <div className='export-params'>
        <div className='message'>Select Project where the task will be created</div>
        <div className='label'>Project</div>
        <ComboboxPicker
            inputProps={{ placeholder: "Select project" }}
            searchUrl="api/project/find?groups=true"
            filter={{ accessLevel: ResourceOperations.Collaborate, exceptIds: props.exceptIds }}
            onEditComplete={(find?: IFindResult) => {
                setSelectedProject(find);
                const prjGroups = find?.attributes?.["TaskGroups"] as Group[] ?? [];
                setGroups(prjGroups);
                const defaultGroup = prjGroups.find(_ => _.isDefault);
                setSelectedGroup(prjGroups.find(_ => _.isDefault));
                props.onComplete({
                    parentEntityType: ServerEntityType.Project,
                    parentEntityId: find?.id,
                    parentEntityName: find?.name,
                    attributes: { Group: defaultGroup }
                })
            }}
        />
        <div className='label'>Group</div>
        <GroupDropdown
            groups={groups}
            value={selectedGroup}
            dropdownInputProps={{
                disabled: !groups.length,
                onChanged: (value: IGroupInfo) => {
                    setSelectedGroup(value);
                    props.onComplete({
                        parentEntityType: ServerEntityType.Project,
                        parentEntityId: selectedProject?.id,
                        parentEntityName: selectedProject?.name,
                        attributes: { Group: value }
                    });
                }
            }}
        />
    </div >;
}