import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { BadgeProps } from './CurrentPlanBadge';
import { PlanCard } from './PlanCard';
import { ProductType } from '../../../../store/Tenant';
import { BillingPlan } from '../common';

type EnterpriseCardProps = {
    plan: BillingPlan;
    isCurrent: boolean;
    badgeProps: BadgeProps;
    onShowContactUsDialog: (plan: BillingPlan) => void;
}

export const EnterprisePlanCard = (props: EnterpriseCardProps) => {
    const { plan } = props;

    const onRenderButtons = React.useCallback(() => <div className="buttons">
        <DefaultButton className='invisible' />
        <DefaultButton
            primary={true}
            onClick={() => props.onShowContactUsDialog(plan)}
            text="Contact us" />
    </div>, [plan]);

    return <PlanCard
        isCurrent={props.isCurrent}
        productType={ProductType.Enterprise}
        name={plan.name}
        description={plan.description}
        iconName={plan.iconName}
        badgeProps={props.badgeProps}
        features={plan.featuresDescriptions}
        onRenderButtons={onRenderButtons}
    />
}