import * as React from "react";
import { Icon } from 'office-ui-fabric-react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NotEnoughPermissionsMessage } from "../../../store/permissions";

type OwnProps = {
    name: string;
    iconName: string;
    disabled?: boolean;
    title?: string;
    action?: () => void;
    path?: string;
}
type Props = OwnProps & RouteComponentProps<{}>;
const ActionCard = (props: Props) => <div
    className={`card action-card ${props.disabled ? 'is-disabled' : ''}`}
    title={props.title ?? (props.disabled ? NotEnoughPermissionsMessage : undefined)}
    onClick={props.disabled ? undefined : (e) => {
        if (props.action) {
            props.action();
        } else if (props.path) {
            e.ctrlKey ? window.open(props.path, '_blank') : props.history.push(props.path);
        }
    }}>
    <Icon iconName={props.iconName} />
    <div className="action-name">{props.name}</div>
</div>;
export default withRouter<OwnProps>(ActionCard);