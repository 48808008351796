import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

export interface NotificationsState {
    all: INotification[];
}

export interface INotification {
    message: string | JSX.Element;
    duration?: number;
    type?: NotificationType;
}

export enum NotificationType {
    Info = 0,
    Success = 1,
    Warn = 2,
    Error = 3
}

const DEFAULT_DURATION = 8000;

type PushNotificationAction = { type: 'PUSH_NOTIFICATION', notification: INotification }
type RemoveNotificationAction = { type: 'REMOVE_NOTIFICATION', notification: INotification }

export type KnownAction = PushNotificationAction | RemoveNotificationAction;

export const actionCreators = {
    pushNotification: (notification: INotification): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PUSH_NOTIFICATION', notification: notification });
        setTimeout(() => dispatch({ type: 'REMOVE_NOTIFICATION', notification: notification }), notification.duration || DEFAULT_DURATION);
    },
    removeNotification: (notification: INotification): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REMOVE_NOTIFICATION', notification: notification });
    }
};

export const reducer: Reducer<NotificationsState> = (state: NotificationsState = { all: [] }, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'PUSH_NOTIFICATION':
            return { all: state.all.concat(action.notification) };
        case 'REMOVE_NOTIFICATION':
            return { all: state.all.filter(_ => _ != action.notification) };
    }

    return state;
};