import * as React from 'react';
import GroupedFieldsConfiguration, { GroupedFieldsConfigurationProps } from './GroupedFieldsConfiguration';
import SearchableFieldsPanel from './SearchableFieldsPanel';

const ConfigureColumnGroupedFieldsPanel = (props: GroupedFieldsConfigurationProps) => (
    <SearchableFieldsPanel {...props}>
        {(filter) => <GroupedFieldsConfiguration {...props} filter={filter} />}
    </SearchableFieldsPanel>
);

export default ConfigureColumnGroupedFieldsPanel;
