import * as React from 'react';
import { FormatDate, formatValue, getPersonInfoImageUrl, notUndefined } from '../../utils/common';
import { Resource, createResourceCalendar, findUsage } from '../../../store/ResourcesListStore';
import { PersonaCoin, PersonaSize, Icon } from 'office-ui-fabric-react';
import { CalendarDataSet } from '../../../store/CalendarStore';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { calculateSummary, ISummaryInfo } from './ResourceUsageCell';
import { post } from '../../../fetch-interceptor';
import Link from '../../common/Link';
import { defaultCatch } from '../../../store/utils';
import { Dictionary, EntityType, IEditableExtensibleEntityWithResourcePlan, mapServerEntityType } from '../../../entities/common';
import { ResourceBookingType } from './ResourceUsageGrid';

type StateProps = {
    calendar: CalendarDataSet;
    resourcePlanningLevel: EntityType;
}
export type OwnProps = {
    resource: Resource;
    startDate: Date;
    finishDate: Date;
    entityId?: string;
    entityType: EntityType;
    onMenuRender?: () => JSX.Element;
    showPerEntityWork?: boolean;
};
type Props = OwnProps & StateProps;
type State = { summary: ISummaryInfo, knownEntitiesMap: Dictionary<IEditableExtensibleEntityWithResourcePlan> };

class UtilizationTooltipContent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { resource, startDate, finishDate, entityId, calendar, showPerEntityWork, entityType, resourcePlanningLevel } = props;
        const summary = calculateSummary(resource, startDate, finishDate, calendar, entityType, resourcePlanningLevel);
        this.state = {
            summary,
            knownEntitiesMap: {}
        };

        if (showPerEntityWork) {
            const unknownEntityIds = entityId
                ? Object.keys(summary.committedByEntity).filter(_ => _ !== entityId && !!summary.committedByEntity[_])
                : [
                    ...Object.keys(summary.committedByEntity).filter(_ => !!summary.committedByEntity[_]),
                    ...Object.keys(summary.proposedByEntity).filter(_ => !!summary.proposedByEntity[_])
                ];

            if (unknownEntityIds.length) {
                post<IEditableExtensibleEntityWithResourcePlan[]>(`api/resourcePlan/find`, { entityType: resourcePlanningLevel, ids: unknownEntityIds })
                    .then(entities => {
                        this.setState({
                            knownEntitiesMap: entities.reduce((cum, cur) => ({ ...cum, [cur.id]: cur }), {})
                        });
                    })
                    .catch(defaultCatch());
            }
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { resource, startDate, finishDate, calendar, entityType, resourcePlanningLevel } = nextProps;
        this.setState({ summary: calculateSummary(resource, startDate, finishDate, calendar, entityType, resourcePlanningLevel) });
    }

    render() {
        const { resource, startDate, finishDate, entityId, calendar } = this.props;
        const { summary } = this.state;
        const { capacityHours, availability, overage, hasLocalOverage, committedByEntity, proposedByEntity, committedWork, proposedWork, actualWork,
            proposed_overage, proposed_hasLocalOverage, proposed_overage_byEntity, proposed_hasLocalOverage_byEntity } = summary;
        const proposedOverage = entityId ? (proposed_overage_byEntity[entityId] ?? overage) : proposed_overage;
        const hasLocalProposedOverage = entityId ? proposed_hasLocalOverage_byEntity[entityId] : proposed_hasLocalOverage;

        const isSingleDay = startDate.getBeginOfDay().getTime() == finishDate.getBeginOfDay().getTime();
        const resourceCalendar = createResourceCalendar(resource, calendar);
        const calendarException = isSingleDay && resourceCalendar.exceptionsHoursMap[startDate.getBeginOfDay().getTime()] !== undefined
            ? resourceCalendar.exceptions.find(_ => _.start <= startDate && startDate <= _.end && _.days.includes(startDate.getDay()))
        : undefined;

        return (
            <div className="timeline-tooltip">
                <div className="header">
                    <PersonaCoin className="logo" size={PersonaSize.size32} text={resource.name} imageUrl={getPersonInfoImageUrl(resource)} />
                    <div className="title">
                        <div className="overflow-text" title={resource.name}>
                            {resource.name}
                        </div>
                    </div>
                    {!!entityId && <ResourceBookingType resource={resource} entityId={entityId} />}
                </div>
                <div className="content">
                    {isSingleDay
                        ? this.renderField("Date", <div className="no-overflow ellipsis">
                            {FormatDate(startDate, { weekday: 'long' })}
                            {calendarException ? <> - <span title={calendarException.name}>{calendarException.name}</span></> : ''}
                        </div>)
                        : this.renderField("Period", <div>{FormatDate(startDate)} - {FormatDate(finishDate)}</div>)}
                    {this.renderField("Availability",
                        <div><span className={`progress-tip ${availability > 0 ? '' : 'gray'}`}>{formatValue(availability)}</span> hour{availability !== 1 ? 's' : ''}</div>)}
                    {this.renderField("Capacity", <div>{formatValue(capacityHours)} hour{capacityHours !== 1 ? 's' : ''}</div>)}
                    {entityId
                        ? this.renderEntityWork()
                        : <>
                            {this.renderAllWork("Committed", committedWork, committedByEntity)}
                            {this.renderAllWork("Proposed", proposedWork, proposedByEntity)}
                        </>}
                    {this.renderField("Actual", <div>{formatValue(actualWork)} hour{actualWork !== 1 ? 's' : ''}</div>)}
                    {this.renderField("Overallocated", <div className='align-top'>
                        {overage !== 0
                            ? <div><span className="progress-tip red">{formatValue(overage)}</span>
                                {proposedOverage === overage ? ` hour${overage !== 1 ? 's' : ''}` : undefined}</div>
                            : hasLocalOverage
                                ? <div><Icon iconName="Warning" className="warning-icon" title="" />Some days</div>
                                : <div className="non-completed">n/a</div>}
                        {proposedOverage !== overage
                            ? <div>
                                &nbsp;/ <span className="progress-tip red">{formatValue(proposedOverage)}</span> hour{overage > 0 || proposedOverage > 0 ? 's' : ''} (if committed)
                            </div>
                            : !hasLocalOverage && hasLocalProposedOverage
                                ? <div>
                                    &nbsp;/ <Icon iconName="Warning" className="warning-icon" />Some days (if committed)
                                </div>
                                : undefined}
                    </div>)}
                    {!!this.props.onMenuRender && this.renderField("", this.props.onMenuRender(), 'menu')}
                </div>
            </div>
        );
    }

    private renderEntityWork = () => {
        const { entityId, showPerEntityWork, resource, entityType, resourcePlanningLevel } = this.props;
        const { summary, knownEntitiesMap } = this.state;
        const { committedByEntity, proposedByEntity } = summary;
        const committed = findUsage(resource.usage, entityId, _ => _.committed);
        const hours = (committed ? committedByEntity : proposedByEntity)[entityId!] ?? 0;
        
        const otherEntityIds = Object.keys(committedByEntity).filter(_ => _ !== entityId && !!committedByEntity[_]);
        const knownEntities = otherEntityIds.map(_ => knownEntitiesMap[_]).filter(notUndefined);
        const unknownEntityIds = otherEntityIds.filter(_ => !knownEntitiesMap[_]);
        const unknownEntityWork = unknownEntityIds.reduce((cum, cur) => (cum + committedByEntity[cur]), 0);
        return <>
            {this.renderField("Allocated",
                <div><span className="progress-tip gray">{formatValue(hours)}</span> hour{hours !== 1 ? 's' : ''} on this {entityType}</div>)}
            {showPerEntityWork && this.renderField("Other Work", <>
                {otherEntityIds.length
                    ? <div className="no-overflow">
                        {knownEntities.map(_ => <div key={_.id} className="ellipsis">
                            {formatValue(committedByEntity[_.id])} hour{committedByEntity[_.id] !== 1 ? 's' : ''} committed on {resourcePlanningLevel}
                            &nbsp;<Link href={`/${resourcePlanningLevel}/${_.id}`} title={_.attributes.Name}>{_.attributes.Name}</Link>
                        </div>)}
                        {unknownEntityWork !== 0 && unknownEntityIds.length > 0 && <div>
                            {formatValue(unknownEntityWork)} hour{unknownEntityWork !== 1 ? 's' : ''} committed on {unknownEntityIds!.length}
                            &nbsp;{knownEntities!.length ? 'more' : ''} {resourcePlanningLevel}{unknownEntityIds!.length !== 1 ? 's' : ''}
                        </div>}
                    </div>
                    : <div>0 hours</div>}
            </>, 'align-top')}
        </>;
    }

    private renderAllWork = (label: string, hours: number, hoursByEntity: Dictionary<number>) => {
        const { showPerEntityWork, resourcePlanningLevel } = this.props;
        const { knownEntitiesMap } = this.state;

        const entityIds = Object.keys(hoursByEntity).filter(_ => !!hoursByEntity[_]);
        const knownEntities = entityIds.map(_ => knownEntitiesMap[_]).filter(notUndefined);
        const unknownEntityIds = entityIds.filter(_ => knownEntitiesMap[_] === undefined);
        const unknownEntityWork = unknownEntityIds.reduce((cum, cur) => (cum + hoursByEntity[cur]), 0);
        const isOneEntity = knownEntities.length === 1 && entityIds.length === 1;
        const isAllEntitiesUnknown = knownEntities.length === 0 && unknownEntityWork !== 0;

        return this.renderField(label, <div className="no-overflow">
            <div>
                <span className="progress-tip gray">{formatValue(hours)}</span> hour{hours !== 1 ? 's' : ''}
                {showPerEntityWork && <>
                    {isOneEntity && <> on {resourcePlanningLevel} <Link href={`/${resourcePlanningLevel}/${knownEntities[0].id}`} title={knownEntities[0].attributes.Name}>
                        {knownEntities[0].attributes.Name}
                    </Link></>}
                    {isAllEntitiesUnknown && <> on {entityIds.length} {resourcePlanningLevel}{entityIds.length !== 1 ? 's' : ''}</>}
                </>}
            </div>
            {showPerEntityWork && !isOneEntity && !isAllEntitiesUnknown && <>
                {knownEntities.map(_ => <div key={_.id} className="ellipsis">
                    {formatValue(hoursByEntity[_.id])} hour{hoursByEntity[_.id] !== 1 ? 's' : ''} on {resourcePlanningLevel}&nbsp;
                    <Link href={`/${resourcePlanningLevel}/${_.id}`} title={_.attributes.Name}>{_.attributes.Name}</Link>
                </div>)}
                {unknownEntityWork !== 0 && <div>
                    {formatValue(unknownEntityWork)} hour{unknownEntityWork !== 1 ? 's' : ''} on {unknownEntityIds.length}
                    &nbsp;{knownEntities.length ? 'more' : ''} {resourcePlanningLevel}{unknownEntityIds.length !== 1 ? 's' : ''}
                </div>}
            </>}
        </div>, 'align-top');
    }

    private renderField = (name: string, valueElement: JSX.Element, className?: string): JSX.Element | null => {
        return (
            <div className={`item align-center ${className || ''}`}>
                {!!name && <div className="label">{name}</div>}
                {valueElement}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        calendar: state.calendar,
        resourcePlanningLevel: mapServerEntityType[state.tenant.resourcePlanningSettings?.resourcePlanningLevel!]!
    };
}

export default connect(mapStateToProps)(UtilizationTooltipContent);