import * as React from 'react';
import { IEntityListProps } from '../common/interfaces/IEntity';
import { IExtensibleEntity, Dictionary } from '../../entities/common';

interface IProps<T extends IExtensibleEntity, TCardState> extends IEntityListProps<T> {
    onCardRender: (entity: any, cardState?: TCardState, persistCardState?: (newCardState: Partial<TCardState>) => any) => JSX.Element | null;
    cardParams: {width: number, height: number}
}

export default class EntitiesCardList<T extends IExtensibleEntity, TCardState> extends React.Component<IProps<T, TCardState>,
    {
        visibleWidth: number;
        visibleHeight: number;
        skipRows: number;
        cardsState: Dictionary<TCardState>
    }> {
    constructor(props: IProps<T, TCardState>) {
        super(props);

        this.state = {
            skipRows: 0,
            visibleWidth: 0,
            visibleHeight: 0,
            cardsState: {}
        };
    }

    private divEntitites?: Element | null;
    
    componentDidMount() {
        if (this.divEntitites) {
            this.divEntitites.addEventListener('scroll', this.refreshState);
            window.addEventListener('resize', this.refreshState);
        }
        this.refreshState();
    }

    componentWillUnmount() {
        if (this.divEntitites) {
            this.divEntitites.removeEventListener('scroll', this.refreshState);
            window.removeEventListener('resize', this.refreshState);
        }
    };

    public render() {
        let takeFromIndex: number = 0;
        let takeToIndex: number = 0;
        let cardInRowCount: number = 0;

        const cardHeight = this.props.cardParams.height;
        const cardWidth = this.props.cardParams.width;

        const { visibleWidth, visibleHeight } = this.state;
        const styles: React.CSSProperties = {};

        if (visibleHeight === 0 && visibleWidth === 0) {
            takeToIndex = 10; // default value for server rendering
        } else {
            const displayedRowsCount = ~~(visibleHeight / cardHeight) + 1;
            cardInRowCount = visibleWidth && (~~(visibleWidth / cardWidth)) || 1;
            takeFromIndex = (this.state.skipRows || 0) * cardInRowCount;
            takeToIndex = (takeFromIndex + (displayedRowsCount + 1) * cardInRowCount);
            styles.minHeight = (this.props.entities.length / cardInRowCount * cardHeight)
        }
        const displayEntities = this.props.entities.slice(0, takeFromIndex + takeToIndex);

        return (
            <div className="entities" ref={(_: Element | null) => this.divEntitites = _}>
                <div style={styles}>
                    <div className="entities-list card-list">
                        {displayEntities.map((entity, index) => this.props.onCardRender(
                            entity,
                            this.state.cardsState[entity.id],
                            (newCardState: Partial<TCardState>) => {
                                let cardsState = { ...this.state.cardsState };
                                let cardState = cardsState[entity.id] || {};
                                cardsState[entity.id] = Object.assign(cardState, newCardState) as TCardState;
                                this.setState({ cardsState });
                            }))}
                    </div>
                </div>
            </div>
        );
    }

    private refreshState = () => {
        const height = this.divEntitites ? this.divEntitites.clientHeight : 0;
        const width = this.divEntitites ? this.divEntitites.clientWidth : 0;

        const scrollTop = this.divEntitites && this.divEntitites.scrollTop || 0;
        const scrolledRowsCount = ~~(scrollTop / this.props.cardParams.height);

        this.setState({ visibleHeight: height, visibleWidth: width, skipRows: scrolledRowsCount });
    }
};