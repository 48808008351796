
import { Dialog, DialogFooter, DialogType, PrimaryButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { withRouter, RouteComponentProps } from "react-router-dom";

type Props = { 
    onDismiss: () => void 
}

export const UpgradeYourPlanDialog = withRouter<Props>((props: Props & RouteComponentProps<{}>) => {
    return <Dialog
        minWidth={500}
        hidden={false}
        dialogContentProps={{ type: DialogType.close, title: 'Upgrade Your Plan' }}
        onDismiss={props.onDismiss}>
        {
            <div>
                Creating a project from an idea is not available as project features are not included in your current subscription plan. Upgrade to the Portfolio Management Plan (FREE Upgrade) or Product Management Plan to get access to project management capabilities.
            </div>
        }
        <DialogFooter>
            <PrimaryButton href='/billing' text="Go to Billing Settings" />
        </DialogFooter>
    </Dialog>;
});