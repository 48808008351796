import * as React from "react";
import { IContextualMenuItem, ContextualMenuItemType, DefaultButton, IRenderFunction, IContextualMenuListProps, Callout } from "office-ui-fabric-react";
import { Iteration, IterationState } from "../../entities/Subentities";
import { FormatDate, notEmpty, toDate } from "../utils/common";
import { SourceType } from "../../store/ExternalEpmConnectStore";

type Props = {
    iterations: Iteration[];
    onIterationChange: (selectedId: string) => void;
    onEntireProject: () => void;
};

type Selected = { key: string, text?: string; title?: string }; 

type State = {
    selected: Selected;
    current?: Iteration;
    iterations: IContextualMenuItem[];
}

const headers = {
    project: { key: "project", text: "Entire Project" },
    current: { key: "current", text: "Current Iteration" }
}

export default class IterationSelect extends React.Component<Props, State> {
    private _callout: Callout | null = null
    private _header = React.createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
        const current = IterationSelect.getCurrent(props.iterations);
        const selected = current ? headers.current : headers.project;
        this.state = {
            selected: selected,
            current: current,
            iterations: this._getIterations(props, selected)
        };
    }

    componentWillMount(){
        this.state.current && this._onCurrentClick();
    }

    public render() {
        const { selected, iterations } = this.state;
        return <DefaultButton
            text={selected.text}
            title={selected.title}
            className="select-iteration-button"
            menuProps={{
                items: iterations,
                onRenderMenuList: this._onRenderMenuList,
                calloutProps: {
                    //removed in new version of fabricui
                    //componentRef: this._calloutRef,
                    className: "iteration-select"
                }
            }} />
    }

    private _getIterations(props: Props, selected: Selected) {
        return [
            ...props.iterations.map(_ => ({
                key: _.id,
                text: _.attributes.Name,
                title: _.attributes.Name,
                iconProps: { iconName: "Rotate" },
                secondaryText: `${FormatDate(_.attributes.StartDate) ?? "N/A"} - ${FormatDate(_.attributes.FinishDate) ?? "N/A"}`,
                data: _,
                canCheck: true,
                isChecked: selected.key == _.id,
                onClick: this._onToggle
            }))
        ];
    }

    public static getCurrent(iterations: Iteration[], sourceType?: SourceType) {
        const now = new Date().toISOString();
        const current = sourceType === SourceType.VSTS
            ? IterationSelect._getVSTSCurrentIteration(iterations)
            : iterations.find(_ => !!_.attributes.State && _.attributes.State === IterationState.Started);
        if (!current) {
            return iterations.find(_ => !!_.attributes.StartDate && !!_.attributes.FinishDate && _.attributes.State !== IterationState.Closed
                && _.attributes.StartDate <= now && now <= _.attributes.FinishDate);
        }
        return current;
    }

    private static _getVSTSCurrentIteration(iterations: Iteration[]) {
        return iterations
            .filter(_ => !!_.attributes.State && _.attributes.State === IterationState.Started)
            .map((_: Iteration, i: number) => ({ iteration: _, index: i }))
            .sort((a, b) => b.iteration.externalData?.IterationPath?.split('\\').length !== a.iteration.externalData?.IterationPath?.split('\\').length
                ? b.iteration.externalData?.IterationPath?.split('\\').length - a.iteration.externalData?.IterationPath?.split('\\').length
                : b.index - a.index
            )?.[0]?.iteration
    }

    //removed in new version of fabricui
    //private _calloutRef = (callout: Callout | null) => {
    //    this._callout = callout;
    //    this.forceUpdate();
    //}

    private _onRenderMenuList = (props?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        const { selected, current } = this.state;
        const headerItems: IContextualMenuItem[] = [
            {
                key: headers.project.key,
                text: headers.project.text,
                title: headers.project.text,
                canCheck: true,
                isChecked: selected.key == headers.project.key,
                onClick: this._onToggle
            },
            current && {
                key: current.id,
                text: headers.current.text,
                title: `${headers.current.text} (${current.attributes.Name})`,
                canCheck: true,
                isChecked: selected.key == current.id,
                onClick: this._onCurrentClick
            },
            { key: "all-iteration", text: "All Iterations", itemType: ContextualMenuItemType.Header },
        ].filter(notEmpty);

        return <div>
            <div ref={this._header}>
                {defaultRender!({
                    items: headerItems,
                    totalItemCount: headerItems.length,
                    hasCheckmarks: true,
                    hasIcons: false
                })}
            </div>
            <div style={{
                maxHeight: this._callout && this._header.current
                    ? (this._callout as any)._maxHeight - this._header.current.clientHeight
                    : undefined,
                overflowY: "auto"
            }}>
                {defaultRender!(props)}
            </div>
        </div>;
    }

    private _onCurrentClick = (): void => {
        const { current } = this.state;
        this._setSelected({ key: current!.id, text: headers.current.text, title: current!.attributes.Name });
    }

    private _onToggle = (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
        this._setSelected(item!, ev);
    }

    private _setSelected(selected: Selected, ev?: React.MouseEvent<HTMLButtonElement>) {
        if (this.state.selected.key == selected.key) return;

        this.setState({ selected: selected, iterations: this._getIterations(this.props, selected) });
        if (selected.key == headers.project.key){
            this.props.onEntireProject();
        } else{
            this.props.onIterationChange(selected.key);
        }
    }
}