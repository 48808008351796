import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { IconButton, Icon } from "office-ui-fabric-react";
import { SourceInfo } from "../../../../../entities/common";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { NotificationType } from "../../../../../store/NotificationsStore";
import * as ProjectsListStore from "../../../../../store/ProjectsListStore";
import * as NotificationsStore from "../../../../../store/NotificationsStore";
import { ApplicationState } from '../../../../../store';
import { Integrations } from '../../../../../store/Tenant';
import WithSignalR from '../../../withSignalR';
import { EventType } from '../../../../../store/SignalRStore';

type OwnProps = {
    entity: ProjectInfo,
    className?: string
}

type StateProps = {
    integrations: Integrations;
    projectsActions: typeof ProjectsListStore.actionCreators;
    notificationsActions: typeof NotificationsStore.actionCreators;
}

type Props = OwnProps & StateProps;

class RefreshProjectButton extends React.Component<Props>{
    render() {
        const { entity, integrations } = this.props;
        const { sourceInfos, isSyncable } = entity;
        const syncFailed = SourceInfo.syncFailed(sourceInfos);

        const enabledSources = SourceInfo.getSyncableConnections(sourceInfos).filter(_ => integrations.isEnabled(_.type));
        const sources = SourceInfo.getDataConnections(enabledSources);
        if (!sources.length) {
            return null;
        }

        const events = [
            {
                name: EventType.ProjectSyncFinished, 
                callback: (projectId: string) => projectId === entity.id && this.props.projectsActions.refreshSyncStatus(projectId)
            }
        ];

        return (
            <WithSignalR events={events}>
                <div title={isSyncable ? (syncFailed ? 'Sync failed' : "Synchronize project") : 'Sync in progress'}>
                    {isSyncable
                        ? <IconButton
                            iconProps={{ iconName: 'Sync' }}
                            className={`sync-btn ${syncFailed ? 'error' : ''} ${this.props.className || ''}`}
                            onClick={this.refresh} />
                        : <div className={`disabled sync-btn ${this.props.className || ''}`}>
                            <Icon iconName="Sync" />
                        </div>}
                </div>
            </WithSignalR>
        );
    }

    private refresh = () => {
        const projectId = this.props.entity.id;
        if (projectId === undefined) {
            return;
        }

        this.props.projectsActions.scheduleSync(projectId);
        this.props.notificationsActions.pushNotification({ message: 'Project refresh is launched. Please allow some time for it to complete', type: NotificationType.Info });
    }
}

function mapStateToProps(state: ApplicationState){
    return {
        integrations: new Integrations(state.tenant.subscription.integrations)
    }
}

function mergeActionCreators(dispatch: any) {
    return {
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(NotificationsStore.actionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(RefreshProjectButton);