import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../store';
import * as Metadata from '../../entities/Metadata';
import { IDeletionResult } from '../../store/services/storeHelper';
import { IEntitiesScreenProps, getActiveViewType } from '../common/EntitiesScreen';
import { IEntitiesScreenView, IHeaderProps } from '../common/EntitiesScreenHeader';
import EntitiesScreenBuilder from '../common/EntitiesScreen';
import EmptyEntitiesScreen from "../common/EmptyEntitiesScreen";
import { DisplayFieldService } from "../common/DisplayFieldService";
import {
    PrimaryButton, IContextualMenuItem, IDialogContentProps, MessageBar, MessageBarType, arraysEqual, Selection, IObjectWithKey, ICommandBarItemProps
} from 'office-ui-fabric-react';
import { Dictionary, EntityType } from "../../entities/common";
import { Reporting, ReportNav, ReportsNav } from "../utils/reporting";
import EntitiesCardList from "../common/EntitiesCardList";
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import RemoveDialog from '../common/RemoveDialog';
import ListSubView, { getPngExportConfigSelectors } from "../views/list/ListSubView";
import EditListSubView from "../views/list/EditListSubView";
import { default as PortfolioCard, PortfolioCardState } from '../views/card/PortfolioCard';
import { SortService } from "../../services/SortService";
import { distinct, notUndefined, toDictionaryById } from "../utils/common";
import Spinner from "../common/Spinner";
import { Validator } from "../../validation";
import DatePickerInput from '../common/inputs/DatePickerInput';
import { IBulkEditInput } from "../common/EntitiesBulkEdit";
import PortfolioCreation from './PortfolioCreation';
import { UserState } from "../../store/User";
import { contains, CommonOperations, canCreate, canUpdate, LicenseType } from "../../store/permissions";
import { PortfolioFilterValue, FilterHelper } from '../../store/portfolio/filters';
import * as Notifications from "../../store/NotificationsStore";
import * as LayoutsStore from "../../store/layouts";
import * as FiltersStore from "../../store/filters";
import * as ViewsStore from "../../store/views";
import { IDetailsProps, IListProps, TInlineEditProps } from '../common/extensibleEntity/EntityDetailsList';
import { ITimelineProps } from '../common/extensibleEntity/EntityTimelineList';
import { ViewService } from '../../services/ViewService';
import { Visibility } from '../common/timeline/TimelineSegment';
import { Insights, StatusCalculationTypes } from '../../store/Tenant';
import { default as GenericEntitiesFilter } from '../common/EntitiesFilter';
import { LayoutService } from '../utils/LayoutService';
import SharePanel from '../common/SharePanel';
import MenuWithReports from '../reporting/MenuWithReports';
import * as CommonTimeline from './commonTimeline';
import * as PortfolioTimeline from './timeline';
import * as ProgramTimeline from '../program/timeline';
import * as ProjectTimeline from '../project/timeline';
import { HierarchyManager, IHierarchyItem, Sorter } from '../utils/HierarchyManager';
import { HierarchyContainer, HierarchyContainerEntity, isPortfolio, isProgram, isProject } from '../utils/HierarchyContainer';
import { IRow } from '../common/timeline/TimelineList';
import { buildStatusDescriptionAttributeName } from '../common/sectionsControl/uiControls/StatusesControl';
import PngExporter, { PngExportConfig } from '../common/PngExporter';
import { PngExportControlDetails } from '../../store/PngExporterStore';
import { IInputProps } from '../common/interfaces/IInputProps';
import { buildExportToCsvMenuItem } from '../common/headerMenuItemBuilders';
import { MenuTitleBuilder } from '../MenuTitleBuilder';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import { buildStatusAttributeName } from '../common/sectionsControl/uiControls/statusesControl/StatusSelectionPanel';
import { buildReadOnlyStatusFieldsNames, isStatusesEditable } from '../common/sectionsControl/uiControls/fieldsArea/common';
import { buildIWithBenefitsCustomRenders, buildIWithBenefitsValidators, rendersBuilder, validators } from '../field/Fields';
import { buildProjectItemRender } from '../project/ProjectsList';
import { buildProgramItemRender } from '../program/ProgramsList';
import { IEntityMapHelper, useEntityMapHelper } from '../utils/IEntityMapHelper';
import { SearchValue } from '../common/SearchBox';
import { SearchFieldService } from '../common/SearchFieldService';

type ActionProps = {
    portfoliosActions: typeof PortfoliosListStore.actionCreators;
    programsActions: typeof ProgramsListStore.actionCreators;
    projectsActions: typeof ProjectsListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
};

type StateProps = {
    portfolios: PortfoliosListStore.Portfolio[];
    portfoliosMap: IEntityMapHelper<PortfoliosListStore.Portfolio>,
    programs: ProgramsListStore.Program[];
    projects: ProjectsListStore.ProjectInfo[];
    layouts: LayoutsStore.LayoutsState;
    portfolioFields: Metadata.Field[];
    portfolioFakeFields: Metadata.Field[];
    programFields: Metadata.Field[];
    programFakeFields: Metadata.Field[];
    projectFields: Metadata.Field[];
    projectFakeFields: Metadata.Field[];
    keyDateFields: Metadata.Field[];
    portfolioFilters: Metadata.IFilter<PortfolioFilterValue>[];
    activeFilter?: Metadata.IFilter<PortfolioFilterValue>;
    autoFilterId: string;
    preFilterId?: string;
    portfolioReports: ReportNav[];
    portfoliosReports: ReportsNav;
    views?: ViewsStore.IViewsState;
    deletionResult: IDeletionResult[] | undefined;
    isLoading: boolean;
    isListLoading: boolean;
    isListUpdating: boolean;
    user: UserState;
    insights: Insights,
    pngExportDetails?: PngExportControlDetails;
};
export type Props = StateProps & ActionProps & RouteComponentProps<{}>;

type State = {
    isCreate: boolean;
    share?: PortfoliosListStore.Portfolio;
    portfoliosToRemove: PortfoliosListStore.Portfolio[];
    layoutToApply?: Metadata.Layout;
    canManageConfiguration: boolean;
    canEdit: boolean;
    selectedItemIds: string[];
    allItems: PortfolioOrProgramOrProject[];

    isListViewEdit: boolean;
    isTimelineViewEdit: boolean;

    preFilter: Metadata.PreFilter<PortfoliosListStore.Portfolio>;
    entityFilterHelper: Metadata.IEntityFilterHelper<PortfoliosListStore.Portfolio>;

    search?: SearchValue;
};

const EntitiesFilter = GenericEntitiesFilter<PortfoliosListStore.Portfolio>();
const EntitiesScreen = EntitiesScreenBuilder<PortfoliosListStore.Portfolio>();

type PortfolioOrProgramOrProject = (PortfoliosListStore.Portfolio | ProgramsListStore.Program | ProjectsListStore.ProjectInfo) & HierarchyContainerEntity;

class PortfoliosList extends React.Component<Props, State> {
    private _hierarchy: HierarchyManager<PortfoliosListStore.Portfolio, void>;
    private _selection: Selection;

    constructor(props: Props) {
        super(props);
        const preFilterOptions: Metadata.PreFilterOption<PortfoliosListStore.Portfolio>[] = [
            { key: "only-my", name: "Only Mine", predicate: _ => !!_.attributes.Manager && !!_.attributes.Manager.find(m => m.id === props.user.id) }
        ];

        this.state = {
            isCreate: false,
            canManageConfiguration: contains(props.user.permissions.common, CommonOperations.ConfigurationManage),
            canEdit: this._canEdit(props),
            selectedItemIds: [],
            allItems: _buildPPPEntities(props.portfolios, props.programs, props.projects),

            isListViewEdit: false,
            isTimelineViewEdit: false,
            preFilter: Metadata.PreFilter.create(preFilterOptions,
                active => this.props.filtersActions.setActiveFilter(props.activeFilter?.id, active?.key)),
            portfoliosToRemove: [],
            entityFilterHelper: new FilterHelper({
                fields: props.portfolioFields,
                layouts: props.layouts.allIds.map(_ => props.layouts.byId[_])
            })
        };

        this._hierarchy = new HierarchyManager<PortfoliosListStore.Portfolio, void>({
            fieldId: props.portfolioFields.find(_ => _.name === "Name")?.id!,
            getItemId: (entity: PortfolioOrProgramOrProject) => entity.id,
            getItemParentId: (entity: PortfolioOrProgramOrProject) => entity.parentId,
            isHierarchicalSort: () => true
        });

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedItemIds: (this._selection.getSelection() as PortfoliosListStore.Portfolio[]).map(_ => _.id) });
            },
            getKey: (_) => this._hierarchy.getKey(_ as PortfoliosListStore.Portfolio & IHierarchyItem),
            canSelectItem: this.canSelectItem as ((item: IObjectWithKey, index?: number) => boolean)
        });
    }

    componentWillMount() {
        this.props.portfoliosActions.requestPortfolios();
        this.props.programsActions.requestPrograms();
        this.props.projectsActions.requestProjects();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.portfolioFields !== nextProps.portfolioFields) {
            const entityFilterHelper = new FilterHelper({
                fields: this.props.portfolioFields,
                layouts: this.props.layouts.allIds.map(_ => this.props.layouts.byId[_])
            });
            this.setState({ entityFilterHelper });
        }

        if (!arraysEqual(this.props.portfolios, nextProps.portfolios)
            || !arraysEqual(this.props.programs, nextProps.programs)
            || !arraysEqual(this.props.projects, nextProps.projects)) {
            this.setState({ allItems: _buildPPPEntities(nextProps.portfolios, nextProps.programs, nextProps.projects) })
        }
    }

    private _canEdit(props: Props) {
        return canCreate(props.user.permissions.portfolio) || canUpdate(props.user.permissions.portfolio) || props.portfolios.some(_ => _.isEditable);
    }
    
    private _canInlineEdit(props: Props) {
        if (this._canEdit(props)) {
            return true;
        }

        const programIds = props.portfolios.reduce((res, p) => [...res, ...p.programIds], []).filter(distinct);
        const programs = props.programs.filter(_ => programIds.includes(_.id));
        if (programs.some(_ => _.isEditable)) {
            return true;
        }

        const projectIds = [
            ...props.portfolios.reduce((res, p) => [...res, ...p.projectIds], []),
            ...programs.reduce((res, p) => [...res, ...p.projectIds], [])
        ].filter(distinct);

        return props.projects.filter(_ => projectIds.includes(_.id)).some(_ => _.isEditable);
    }

    private _viewChanged = (view: IEntitiesScreenView<PortfoliosListStore.Portfolio>) => {
        this.setState({ selectedItemIds: [] });
        this.props.viewsActions.setActiveView(view.url);
    }

    private _clearPreFilter = () => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id);

    public render() {
        if (this.props.isLoading || this.props.isListLoading) {
            return <Spinner />;
        }

        const { deletionResult, pngExportDetails } = this.props;
        const { portfoliosToRemove, allItems } = this.state;
        const notDeleted = deletionResult?.filter(_ => !_.isDeleted);

        const top = this._getFilteredEntities();

        return <>
            {this.props.portfolios.length === 0
                ? <EmptyEntitiesScreen
                    className="portfolio"
                    title="portfolios"
                    description="Get the complete portfolio visibility across your project and instant access to fresh portfolio data and actionable insights - all in one place">
                    <PrimaryButton disabled={!canCreate(this.props.user.permissions.portfolio)} text="Create Portfolio" onClick={() => this.setState({ isCreate: true })} />
                </EmptyEntitiesScreen>
                : <PngExporter details={pngExportDetails} getConfig={this._getPngExportConfig}>
                    <HierarchyContainer<PortfolioOrProgramOrProject, void>
                        items={top}
                        allItems={allItems}
                        expandIds={[]}
                        hierarchy={this._hierarchy}>
                        <EntitiesScreen {...this._buildEntitiesScreenProps()} />
                    </HierarchyContainer>
                </PngExporter>}
            {this.state.share && <SharePanel
                key="share-panel"
                entity={this.state.share}
                entityType={EntityType.Portfolio}
                layouts={this.props.layouts}
                onDismiss={() => this.setState({ share: undefined })} />}
            {this.state.isCreate && <PortfolioCreation onDismiss={() => this.setState({ isCreate: false })} openOnComplete={true} />}
            {!!portfoliosToRemove.length && <RemoveDialog
                onClose={() => this.setState({ portfoliosToRemove: [] })}
                onComplete={() => this.props.portfoliosActions.removePortfolios(portfoliosToRemove.filter(_ => _.isEditable).map(_ => _.id))}
                dialogContentProps={this._getRemoveDialogContent(portfoliosToRemove)}
                confirmButtonProps={{ text: "Delete", disabled: portfoliosToRemove.filter(_ => _.isEditable).length === 0 }} >
                {portfoliosToRemove.some(_ => !_.isEditable) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    You don't have permissions to delete the following portfolios:
                    <ul>
                        {portfoliosToRemove.filter(_ => !_.isEditable).map(_ => <li key={_.id}>{_.attributes.Name}</li>)}
                    </ul>
                </MessageBar>}
            </RemoveDialog>}
            {deletionResult && notDeleted && <RemoveDialog
                onClose={() => this.props.portfoliosActions.dismissDeletionResult()}
                confirmButtonProps={{ text: "Got it" }}
                modalProps={{ styles: { main: { minWidth: 500 } } }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {deletionResult.length > 1 && notDeleted.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    Failed to delete the following {notDeleted.length > 1 ? "portfolios" : "portfolio"}:
                    <ul>
                        {notDeleted.map(_ => <li key={_.id}>{_.name}</li>)}
                    </ul>
                    Please check if you have necessary permissions. If {notDeleted.length > 1
                        ? "portfolios contain"
                        : "the portfolio contains"} programs or projects, please remove them first.
                </MessageBar>}
            </RemoveDialog>}
            {this.state.layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => {
                    this.props.portfoliosActions.applyLayoutMany(this.state.selectedItemIds, this.state.layoutToApply!.id);
                    this.props.notificationsActions.pushNotification({
                        message: `Layout '${this.state.layoutToApply!.name}' applied`,
                        type: Notifications.NotificationType.Info
                    });
                }}
                onDismiss={() => this.setState({ layoutToApply: undefined })}
                entityType={EntityType.Portfolio}
                layoutName={this.state.layoutToApply!.name}
                count={this.state.selectedItemIds.length}
            />}
        </>;
    }

    private _buildEntitiesScreenProps(): IEntitiesScreenProps<PortfoliosListStore.Portfolio> {
        const top = this._getFilteredEntities();
        return {
            title: "portfolios",
            clearPreFilter: this._clearPreFilter,
            canManageConfiguration: this.state.canManageConfiguration,
            activeViewType: this.props.views!.activeViewType,
            viewChanged: this._viewChanged,
            fields: this.props.portfolioFields,
            fakeFields: this.props.portfolioFakeFields,
            bulkEditAttributesCustomRender: this.bulkEditAttributesCustomRenderBuilder(),
            defaultBulkEditColumns: PortfoliosListStore.DEFAULT_BULK_EDIT_COLUMNS,
            entities: top as PortfoliosListStore.Portfolio[],
            entitiesIsUpdating: this.props.layouts.isApplyingLayout,
            getBulkEditEntities: this.getBulkEditEntities,
            actions: {
                bulkComponent: {
                    bulkUpdate: this.gridBulkUpdate
                },
                importFromFile: this.props.portfoliosActions.importEntitiesFromFile
            },
            views: this._getViews(),
            filter: {
                activeFilter: this.props.activeFilter,
                autoFilterId: this.props.autoFilterId,
                getAttributeValue: this.getAttributeValue,
                onFilterRender: this._renderFilter
            },
            headerProps: this.getHeaderProps(),
            router: {
                history: this.props.history,
                match: this.props.match,
                location: this.props.location
            },
            baseUrl: "/portfolios",
            canEdit: this.state.canEdit,
            selectedItemIds: this.state.selectedItemIds,
            getPngExportConfig: this._getPngExportConfig,
            search: {
                search: this.state.search,
                onSearch: (value) => this.setState({ search: value })
            }
        }
    }

    private _getFilteredEntities = () => this.state.allItems.filter(_ => _.entityType === EntityType.Portfolio && this._isItemVisible(_ as PortfoliosListStore.Portfolio));

    private _getRemoveDialogContent(toRemove: PortfoliosListStore.Portfolio[]) {
        toRemove = toRemove.filter(_ => _.isEditable);
        if (toRemove.length === 1) {
            return {
                title: "Delete portfolio",
                subText: `Are you sure you want to delete portfolio "${toRemove[0].attributes.Name}"?`
            }
        }

        return {
            title: "Delete portfolios",
            subText: toRemove.length
                ? `Are you sure you want to delete selected portfolios (${toRemove.length} items)?`
                : undefined
        }
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1 && !deletionResult[0].isDeleted) {
            return {
                title: "Unable to delete portfolio",
                subText: `Please check if you have necessary permissions. If the portfolio "${deletionResult[0].name}" contains programs or projects, please remove them first.`
            };
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return deleted.length === 1
            ? {
                title: "Portfolio deletion is complete",
                subText: `Portfolio "${deleted[0].name}" was deleted successfully.`
            }
            : deleted.length
                ? {
                    title: "Portfolios deletion is complete",
                    subText: `Selected portfolios (${deleted.length} items) were deleted successfully.`
                }
                : {
                    title: "Portfolios deletion failed"
                };
    }

    private getBulkEditEntities = (): PortfoliosListStore.Portfolio[] => {
        if (this.state.selectedItemIds.length > 0) {
            return this.props.portfoliosMap.getItems(this.state.selectedItemIds)
        }
        return this.applyFilter(this.props.portfolios);
    }

    private getHeaderProps = (): IHeaderProps => {
        const { portfoliosReports, history } = this.props;

        const reportItems: IContextualMenuItem[] = portfoliosReports.packs.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openReport(history, _);
                }
            }));

        const portfolioReportItems: IContextualMenuItem[] = portfoliosReports.subPacks.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openPortfoliosReport(history, _, this.getBulkEditEntities());
                }
            }));

        return {
            entityType: EntityType.Portfolio,
            createEntityTypes: [EntityType.Portfolio, EntityType.Program, EntityType.Project, EntityType.Roadmap, EntityType.Resource, EntityType.PrivateProject],
            importEntityTypes: [EntityType.Project, EntityType.Resource],
            reportProps: {
                reportsButtonAdditionalOptions: Reporting.buildReportsList(portfolioReportItems, reportItems)
            },
            allowExportToFile: true,
            allowImportFromFile: this.props.user.license === LicenseType.Regular
        }
    }

    private _isFieldFake = (field: Metadata.Field): boolean => {
        return this.props.portfolioFakeFields.some(_ => _.id === field.id) ||
            this.props.programFakeFields.some(_ => _.id === field.id) ||
            this.props.projectFakeFields.some(_ => _.id === field.id);
    }

    private _renderFilter = (isFilterPanelOpen: boolean, toggleFilterPanel: () => void) => (
        <EntitiesFilter
            canManageConfiguration={this.state.canManageConfiguration}
            preFilter={this.state.preFilter}
            preFilterId={this.props.preFilterId}
            activeFilter={this.props.activeFilter}
            onActiveFilterChanged={this._onActiveFilterChanged}
            onFilterChanged={this.props.filtersActions.updateFilter}
            isFilterPanelOpen={isFilterPanelOpen}
            toggleFilterPanel={toggleFilterPanel}
            entityType={EntityType.Portfolio}
            entityFilterHelper={this.state.entityFilterHelper}
        />
    );

    private _onActiveFilterChanged = (id?: string) => {
        this.props.filtersActions.setActiveFilter(id, this.props.preFilterId);
    }

    private applyFilter = (_: PortfoliosListStore.Portfolio[]) => {
        return _.filter(this._isItemVisible);
    }

    private _isItemVisible = (item: PortfoliosListStore.Portfolio): boolean => {
        const { preFilter, search } = this.state;
        const { activeFilter } = this.props;

        if (Metadata.PreFilter.isItemVisible(preFilter, item, this.props.preFilterId)) {
            return false;
        }

        if (search?.searchText && !SearchFieldService.searchValues(search, item)) {
            return false;
        }

        if (activeFilter) {
            const filterValue = activeFilter.value;
            const allAttributes = this.state.entityFilterHelper.getFilterAttributes(this.props.portfolioFields);

            for (const type in filterValue) {
                if (!this.state.entityFilterHelper.helpersMap[type].validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type))) {
                    return false;
                }
            }
        }

        return true;
    }

    private getAttributeValue = (attrType: keyof PortfolioFilterValue, value: any): string[] => {
        return this.state.entityFilterHelper.helpersMap[attrType].getAttributeValues(value);
    }

    private _renderMenu = (entity: PortfolioOrProgramOrProject) => {
        if (entity.entityType !== EntityType.Portfolio) {
            return null;
        }

        const commands: IContextualMenuItem[] = [
            entity.isEditable ? {
                key: 'share',
                name: 'Share',
                iconProps: { iconName: 'Share' },
                onClick: () => this.setState({ share: entity as PortfoliosListStore.Portfolio })
            } : undefined,
            entity.isEditable ? {
                key: 'pfedit',
                name: 'Edit',
                iconProps: { iconName: "Edit" },
                onClick: () => this.props.history.push(`/portfolio/${entity.id}`)
            } : undefined,
            entity.isEditable ? {
                key: 'pfDelete',
                name: 'Delete',
                iconProps: { iconName: "Delete", style: { color: 'red' } },
                disabled: this.props.isLoading,
                style: { color: (this.props.isLoading ? 'initial' : 'red'), backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset') },
                onClick: () => {
                    this.setState({ portfoliosToRemove: [entity as PortfoliosListStore.Portfolio] });
                }
            } : undefined
        ].filter(notUndefined);

        return <MenuWithReports
            commands={commands}
            item={entity}
            entityType={EntityType.Portfolio}
            reports={this.props.portfolioReports}
            onClick={_ => Reporting.openPortfolioReport(this.props.history, _, entity as PortfoliosListStore.Portfolio)} />;
    }

    private getCardView(): IEntitiesScreenView<PortfoliosListStore.Portfolio> {
        const { card } = this.props.views!;
        const fieldsMap = Metadata.toMap(this.props.portfolioFields);
        return {
            icon: 'PPMXCardView',
            url: Metadata.ViewTypes.card,
            subViews: card.subViews,
            sortBy: card.sortBy,
            onSortChange: this.props.viewsActions.changeCardViewSort,
            activeSubViewId: card.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setCardActiveSubView,
            render: (key: string, activeSubView: Metadata.ICardSubView, entities: PortfoliosListStore.Portfolio[]) => {
                const comparer = SortService.getComparer(fieldsMap, card.sortBy.active.orderBy);
                return <EntitiesCardList
                    key={key}
                    entities={entities.sort(comparer)}
                    onCardRender={
                        (entity: PortfoliosListStore.Portfolio,
                            cardState: PortfolioCardState | undefined,
                            persistCardState: (newCardState: Partial<PortfolioCardState>) => void) => <PortfolioCard
                                key={entity.id}
                                entity={entity}
                                fields={fieldsMap}
                                showWork={activeSubView.showWork}
                                showCosts={activeSubView.showCosts}
                                onMenuRender={this._renderMenu}
                                state={cardState}
                                onChangeState={persistCardState}
                            />}
                    cardParams={{ width: 456, height: 263 }} />;
            }
        }
    }

    private buildItemRender = (
        allProgramFields: Metadata.Field[],
        allProjectFields: Metadata.Field[],
        isTimelineView: boolean,
        activeSubView: Metadata.IListSubView
    ): (item: PortfolioOrProgramOrProject, index: number, field: Metadata.Field, defaultRender: () => JSX.Element) => JSX.Element => {
        return (item: PortfolioOrProgramOrProject, index, field, defaultRender) => {
            const canEdit = this._canInlineEdit(this.props);
            if (isProgram(item)) {
                return canEdit
                    ? buildProgramItemRender(item as ProgramsListStore.Program, field, allProgramFields, [...this.props.portfolioFakeFields, ...this.props.programFakeFields, ...this.props.projectFakeFields],
                        this.props.insights.program.statusCalculation, isTimelineView, activeSubView.columns, this.onInlineEditComplete)
                    : ViewService.buildCellRenderer(item, EntityType.Program, allProgramFields, field, this._isFieldFake);
            }
            if (isProject(item)) {
                return canEdit
                    ? buildProjectItemRender(item as ProjectsListStore.ProjectInfo, field, allProjectFields, [...this.props.portfolioFakeFields, ...this.props.programFakeFields, ...this.props.projectFakeFields],
                        this.props.insights.project.statusCalculation, isTimelineView, activeSubView.columns, this.onInlineEditComplete)
                    : ViewService.buildCellRenderer(item, EntityType.Project, allProjectFields, field, this._isFieldFake);
            }

            return defaultRender();
        }
    }

    private canSelectItem = (item: PortfolioOrProgramOrProject): boolean => isPortfolio(item);

    private sorter: Sorter<PortfolioOrProgramOrProject> = (orderBy) => {
        const { projectFakeFields, programFakeFields, portfolioFakeFields } = this.props;

        const allPortfolioFields = this.props.portfolioFields.concat(portfolioFakeFields),
            allProgramFields = this.props.programFields.concat(programFakeFields),
            allProjectFields = this.props.projectFields.concat(projectFakeFields);

        const extractor = (item: PortfoliosListStore.Portfolio, field: Metadata.Field) => {
            if (field.name === LayoutsStore._layoutFakeFieldName) {
                return item.layoutId ? this.props.layouts.byId[item.layoutId]?.name : null;
            }
            return SortService.getFieldValueBaseImpl(field, item, this._isFieldFake)
        }

        return (a, b) => {
            if (a.entityType !== b.entityType) {
                return (isPortfolio(a) || isProgram(a) && isProject(b)) ? 0 : 1;
            }

            if (isProgram(a)) {
                const allProgramFieldsMap = Metadata.toMap(allProgramFields, this._isFieldFake);
                return SortService.getComparer(allProgramFieldsMap, orderBy, this._isFieldFake)(a, b);
            }

            if (isProject(a)) {
                const allProjectFieldsMap = Metadata.toMap(allProjectFields, this._isFieldFake);
                return SortService.getComparer(allProjectFieldsMap, orderBy, this._isFieldFake)(a, b);
            }

            const allPortfolioFieldsMap = Metadata.toMap(allPortfolioFields, this._isFieldFake);
            return SortService.getComparer(allPortfolioFieldsMap, orderBy, this._isFieldFake, extractor)(a, b);
        }
    };

    private onInlineEditComplete = (field: Metadata.Field, item: PortfolioOrProgramOrProject, value: any, extraUpdates?: Dictionary<any>) => {
        const updates = ViewService.buildBulkUpdates(item, field, value, extraUpdates);
        if (isProgram(item)) {
            this.props.programsActions.bulkUpdate(updates);
        } else if (isProject(item)) {
            this.props.projectsActions.bulkUpdate(updates);
        } else {
            this.props.portfoliosActions.bulkUpdate(updates);
        }
    }

    private getReadonlyFields = (entity: PortfoliosListStore.Portfolio) => {
        return [
            ...this.props.portfolioFakeFields.map(_ => _.name),
            ...buildReadOnlyStatusFieldsNames(this.props.insights.portfolio.statusCalculation, PortfoliosListStore.statuses, !entity?.insights.statusCalculationDisabled)
        ];
    }

    private getInlineEditProps = (): TInlineEditProps | undefined =>
        this._canInlineEdit(this.props)
            ? {
                onInlineEditComplete: this.onInlineEditComplete,
                readonlyFields: this.getReadonlyFields,
                customFieldValidatorBuilder: { ...validators, ...buildIWithBenefitsValidators() },
                uiControlElementsCustomRender: { ...rendersBuilder(), ...buildIWithBenefitsCustomRenders() },
            }
            : undefined;

    private getListView(): IEntitiesScreenView<PortfoliosListStore.Portfolio> {
        const { projectFakeFields, programFakeFields, portfolioFakeFields, views } = this.props;
        const { list } = views!;

        const allPortfolioFields = this.props.portfolioFields.concat(portfolioFakeFields),
            allProgramFields = this.props.programFields.concat(programFakeFields),
            allProjectFields = this.props.projectFields.concat(projectFakeFields);

        return {
            subViews: list.subViews.allIds.map(_ => list.subViews.byId[_]),
            icon: 'PPMXListView',
            url: Metadata.ViewTypes.list,
            activeSubViewId: list.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setListActiveSubView,
            onEditSubViewClick: id => {
                if (list.activeSubViewId !== id) {
                    this.props.history.push(`/portfolios/list/${id}`)
                }
                this.setState({ isListViewEdit: true });
            },
            onCopySubViewClick: this._onCopyListSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeListSubView,
            render: (key: string, activeSubView: Metadata.IListSubView, entities: PortfoliosListStore.Portfolio[]) => {
                const listProps: Partial<IListProps> & IDetailsProps = {
                    onItemMenuRender: this._renderMenu,
                    onItemRender: this.buildItemRender(allProgramFields, allProjectFields, false, activeSubView),
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress,
                    inlineEditProps: this.getInlineEditProps(),
                };
                return [<ListSubView
                    key="details-view"
                    type="Details"
                    entities={entities}
                    selection={this._selection}
                    hierarchy={this._hierarchy}
                    entityType={EntityType.Portfolio}
                    fields={allPortfolioFields}
                    isFieldFake={this._isFieldFake}
                    sort={list.sortBy}
                    sorter={this.sorter}
                    onSortChange={this.props.viewsActions.changeListViewSort}
                    view={activeSubView}
                    listProps={listProps}
                    selectionModeItems={this._listViewSelectedItemsMenu(activeSubView)}
                    onColumnResized={(id, w) => this.props.viewsActions.onListColumnResized(activeSubView.id, id, w)}
                    showSpinner={this.props.isListUpdating} />,
                this.state.isListViewEdit ? <EditListSubView
                    key="create-details-view"
                    subView={activeSubView}
                    entityType={EntityType.Portfolio}
                    selectedByDefault={this.props.views!.list.selectedByDefault}
                    fields={allPortfolioFields}
                    onChange={changes => this.props.viewsActions.updateListSubView(activeSubView.id, changes)}
                    onSave={() => {
                        this.props.viewsActions.saveListSubView(activeSubView, 'portfolios');
                        this.setState({ isListViewEdit: false });
                    }}
                    onCopy={() => this._onCopyListSubView(activeSubView)}
                    onDismiss={() => this.setState({ isListViewEdit: false })}
                /> : <span key="no-edit"></span>
                ];
            },
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addListSubView(subView);
                this.props.history.push(`/portfolios/list/${subView.id}`);
                this.setState({ isListViewEdit: true });
            }
        }
    }

    private _onCopyListSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/portfolios/list/${subView.id}`);
        this.setState({ isListViewEdit: true });
        this.props.viewsActions.saveListSubView(subView, 'portfolios', undefined, view.id);
    }

    private _listViewSelectedItemsMenu = (activeSubView: Metadata.ISubView): ICommandBarItemProps[] | undefined => {
        const { selectedItemIds } = this.state;
        const selectedItems = this.props.portfoliosMap.getItems(selectedItemIds)
        const entitiesScreenProps = this._buildEntitiesScreenProps();
        const exportCsvMenu = buildExportToCsvMenuItem({
            views: entitiesScreenProps.views,
            fields: entitiesScreenProps.fields,
            fakeFields: entitiesScreenProps.fakeFields,
            selectedItemIds: selectedItemIds,
            entityType: EntityType.Portfolio,
            activeSubView: activeSubView,
            selection: this._selection,
            onExportEntitiesToFile: this.props.portfoliosActions.exportEntitiesToFile,
        });

        if (!this.state.canEdit) {
            return [exportCsvMenu];
        }

        const layoutMenuItem = LayoutService.buildApplyLayoutMenuItem(this.props.layouts, (layout: Metadata.Layout) => {
            this.setState({ layoutToApply: layout });
        });

        return [
            {
                key: 'bulk-edit',
                text: "Bulk edit",
                iconProps: { iconName: "TripleColumnEdit" },
                onClick: () => this.props.history.push(`/portfolios/bulk`),
            },
            exportCsvMenu,
            {
                ...layoutMenuItem,
                disabled: !!selectedItems.find(_ => !_.canConfigure),
            },
            {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.Portfolio),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                onClick: () => this.setState({ portfoliosToRemove: selectedItems }),
            }
        ];
    }

    private getTimelineView(): IEntitiesScreenView<PortfoliosListStore.Portfolio> {
        const { projectFakeFields, programFakeFields, portfolioFakeFields, views } = this.props;
        const { timeline } = views!;

        const allPortfolioFields = this.props.portfolioFields.concat(portfolioFakeFields),
            allProgramFields = this.props.programFields.concat(programFakeFields),
            allProjectFields = this.props.projectFields.concat(projectFakeFields);

        return {
            icon: 'PPMXTimelineView',
            url: Metadata.ViewTypes.timeline,
            subViews: timeline.subViews.allIds.map(_ => timeline.subViews.byId[_]),
            activeSubViewId: timeline.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setTimelineActiveSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addTimelineSubView(subView);
                this.props.history.push(`/portfolios/timeline/${subView.id}`);
                this.setState({ isTimelineViewEdit: true });
            },
            onEditSubViewClick: id => {
                if (timeline.activeSubViewId !== id) {
                    this.props.history.push(`/portfolios/timeline/${id}`)
                }
                this.setState({ isTimelineViewEdit: true });
            },
            onCopySubViewClick: this._onCopyTimelineSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeTimelineSubView,
            render: (key: string, activeSubView: Metadata.ITimelineSubView, entities: PortfoliosListStore.Portfolio[]) => {
                const listProps: Partial<IListProps> & ITimelineProps = {
                    buildRow: (_: PortfolioOrProgramOrProject): IRow => {
                        return _.entityType === EntityType.Portfolio
                            ? PortfolioTimeline.buildTimelineItem(
                                _ as PortfoliosListStore.Portfolio,
                                this.props.programs,
                                this.props.projects,
                                this.props.portfolioFields,
                                this.props.programFields,
                                this.props.projectFields,
                                this.props.keyDateFields,
                                Visibility.OnHover)
                            : _.entityType === EntityType.Program
                                ? ProgramTimeline.buildTimelineItem(
                                    _ as ProgramsListStore.Program,
                                    this.props.projects,
                                    this.props.programFields,
                                    this.props.projectFields,
                                    this.props.keyDateFields,
                                    Visibility.OnHover)
                                : ProjectTimeline.buildTimelineItem(
                                    _ as ProjectsListStore.ProjectInfo,
                                    this.props.projectFields,
                                    this.props.keyDateFields,
                                    Visibility.OnHover,
                                    undefined,
                                    toDictionaryById(this.props.projects));
                    },
                    renderSegmentContent: CommonTimeline.renderSegmentContent,
                    renderSegmentTooltipContent: CommonTimeline.renderSegmentTooltipContent,
                    renderMarkerTooltipContent: (row, marker) => CommonTimeline.renderMarkerTooltipContent(row, marker, true),
                    onItemRender: this.buildItemRender(allProgramFields, allProjectFields, true, activeSubView),
                    onItemMenuRender: this._renderMenu,
                    userQuantization: timeline.quantization,
                    userTimeframe: timeline.timeframe,
                    onScaleChange: (timelineChange) => timelineChange.origin && this.props.viewsActions.setTimelineScale(timelineChange.origin),
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress,
                    inlineEditProps: this.getInlineEditProps(),
                };
                return <>
                    <ListSubView
                        key="timeline-view"
                        type="Timeline"
                        selection={this._selection}
                        hierarchy={this._hierarchy}
                        entities={entities}
                        entityType={EntityType.Portfolio}
                        fields={allPortfolioFields}
                        isFieldFake={this._isFieldFake}
                        sort={timeline.sortBy}
                        sorter={this.sorter}
                        onSortChange={this.props.viewsActions.changeTimelineViewSort}
                        view={activeSubView}
                        listProps={listProps}
                        selectionModeItems={this._listViewSelectedItemsMenu(activeSubView)}
                        showSpinner={this.props.isListUpdating}
                        onColumnResized={(id, w) => this.props.viewsActions.onTimelineColumnResized(activeSubView.id, id, w)} />
                    {
                        this.state.isTimelineViewEdit && <EditListSubView
                            key="create-timeline-view"
                            subView={activeSubView}
                            entityType={EntityType.Portfolio}
                            selectedByDefault={timeline.selectedByDefault}
                            fields={allPortfolioFields}
                            onChange={changes => {
                                this.props.viewsActions.updateTimelineSubView(activeSubView.id, changes);
                            }}
                            onSave={() => {
                                this.props.viewsActions.saveTimelineSubView(activeSubView, 'portfolios');
                                this.setState({ isTimelineViewEdit: false })
                            }}
                            onCopy={() => this._onCopyTimelineSubView(activeSubView)}
                            onDismiss={() => this.setState({ isTimelineViewEdit: false })}
                        />
                    }
                </>
            }
        }
    }

    private _onCopyTimelineSubView = (view: Metadata.ITimelineSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/portfolios/timeline/${subView.id}`);
        this.setState({ isTimelineViewEdit: true });
        this.props.viewsActions.saveTimelineSubView(subView, 'portfolios', undefined, view.id);
    }

    private gridBulkUpdate = (updates: Dictionary<any>) => {
        this.props.portfoliosActions.bulkUpdate(updates);
    }

    private getFinishFieldConfig(): IBulkEditInput {
        const getValidator = (attributes: PortfoliosListStore.PortfolioAttrs) => Validator.new().date().dateIsGreaterThenOrEqual(attributes.StartDate).build();
        return {
            validator: (attributes: PortfoliosListStore.PortfolioAttrs) => getValidator(attributes),
            render: (props: IInputProps, field: Metadata.Field, entity: PortfoliosListStore.Portfolio) =>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={getValidator(entity.attributes)} minDate={entity.attributes.StartDate} />
        }
    }
    private getStartFieldConfig(): IBulkEditInput {
        const getValidator = (attributes: PortfoliosListStore.PortfolioAttrs) => Validator.new().date().dateIsLessThenOrEqual(attributes.FinishDate).build();
        return {
            validator: (attributes: PortfoliosListStore.PortfolioAttrs) => getValidator(attributes),
            render: (props: IInputProps, field: Metadata.Field, entity: PortfoliosListStore.Portfolio) =>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={getValidator(entity.attributes)} maxDate={entity.attributes.FinishDate} />
        }
    }

    private bulkEditAttributesCustomRenderBuilder = (): Dictionary<IBulkEditInput> => {
        return {
            "StartDate": this.getStartFieldConfig(),
            "FinishDate": this.getFinishFieldConfig(),
            ...getStatusesInputs(PortfoliosListStore.statuses, this.props.insights.portfolio.statusCalculation)
        }
    }

    private _getViews = () => [this.getCardView(), this.getListView(), this.getTimelineView()];

    private _getPngExportConfig = (): PngExportConfig => {
        const views = this._getViews();
        const activeView = getActiveViewType(views, this.props.views!.activeViewType);
        return {
            ...getPngExportConfigSelectors(activeView),
            name: "Portfolios",
            controlId: PortfoliosList.name,
            activeView,
            rowsCount: this._getFilteredEntities().length + this._hierarchy.getExpandedChildrenCount()
        };
    }
}

export function getStatusesInputs(statuses: string[], statusCalculation: StatusCalculationTypes): Dictionary<IBulkEditInput> {
    return statuses.reduce((attrNameConfigPair, status) => ({
        ...attrNameConfigPair,
        [buildStatusAttributeName(status)]: getStatusFieldConfig(statusCalculation),
        [buildStatusDescriptionAttributeName(status)]: getStatusFieldConfig(statusCalculation)
    }), {});
}

export function getStatusFieldConfig(statusCalculation: StatusCalculationTypes): IBulkEditInput {
    return {
        render: (propsRender: IInputProps, field: Metadata.Field) => DisplayFieldService.buildCompactFieldInputElement(field, propsRender),
        editable: (entity: PortfoliosListStore.Portfolio) => isStatusesEditable(statusCalculation, entity.insights.statusCalculationDisabled)
    }
}

function _buildPPPEntities(
    allPortfolios: PortfoliosListStore.Portfolio[],
    allPrograms: ProgramsListStore.Program[],
    allProjects: ProjectsListStore.ProjectInfo[]): PortfolioOrProgramOrProject[] {
    const portfolios = allPortfolios.map<PortfolioOrProgramOrProject>(_ => ({ entityType: EntityType.Portfolio, ..._ }))
    const programsInPortfolios = allPortfolios.reduce((arr, cur) => [
        ...arr,
        ...allPrograms
            .filter(_ => cur.programIds.indexOf(_.id) !== -1)
            .map(_ => ({ entityType: EntityType.Program, ..._, parentId: cur.id }))
    ], []);

    const pgMapIds = programsInPortfolios.map(_ => _.id).filter(distinct).reduce((obj, cur) => ({ ...obj, [cur]: cur }), {});
    const programs = allPrograms.filter(_ => !!pgMapIds[_.id]);
    return [
        ...portfolios,
        ...programsInPortfolios,
        ...portfolios.reduce((arr, cur: PortfoliosListStore.Portfolio & HierarchyContainerEntity) => [
            ...arr,
            ...allProjects
                .filter(_ => cur.projectIds.indexOf(_.id) !== -1)
                .map(_ => ({ entityType: EntityType.Project, ..._, parentId: cur.id }))
        ], []),
        ...programs
            .reduce((arr, cur) => [
                ...arr,
                ...allProjects
                    .filter(_ => cur.projectIds.indexOf(_.id) !== -1)
                    .map(_ => ({ entityType: EntityType.Project, ..._, parentId: cur.id }))
            ], [])
    ];
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps => {
    const fields = state.fields[EntityType.Portfolio];
    const projectFields = state.fields[EntityType.Project];
    const programFields = state.fields[EntityType.Program];
    const keyDateFields = state.fields[EntityType.KeyDate];
    const filters = FiltersStore.getFilter(state.filters, EntityType.Portfolio);
    const autoFilterId = Metadata.Filter.getAutoFilterId(filters.all) ?? filters.all[0].id;
    const portfolios = state.portfolios.allIds.map(_ => state.portfolios.byId[_]);
    const programs = state.programs.allIds.map(_ => state.programs.byId[_]);
    const projects = state.projectsList.allIds.map(_ => state.projectsList.byId[_]);
    return {
        portfolios,
        programs,
        projects,
        portfoliosMap: useEntityMapHelper(state.portfolios.byId),
        layouts: state.layouts[EntityType.Portfolio],
        portfolioFields: fields.allIds.map(_ => fields.byId[_]),
        portfolioFakeFields: state.views[EntityType.Portfolio].list.fakeFields,
        programFields: programFields.allIds.map(_ => programFields.byId[_]),
        programFakeFields: state.views[EntityType.Program].list.fakeFields,
        projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
        projectFakeFields: state.views[EntityType.Project].list.fakeFields,
        keyDateFields: keyDateFields.allIds.map(_ => keyDateFields.byId[_]),
        portfolioFilters: filters.all,
        activeFilter: filters.active.filter,
        autoFilterId: autoFilterId,
        preFilterId: filters.active.preFilterId,
        portfolioReports: state.tenant.reporting.portfolioReports.subPacks,
        portfoliosReports: state.tenant.reporting.portfoliosReports,
        views: state.views[EntityType.Portfolio],
        deletionResult: state.portfolios.deletionResult,
        isLoading: state.portfolios.isLoading,
        isListLoading: state.portfolios.isListLoading,
        isListUpdating: state.portfolios.isListUpdating,
        user: state.user,
        insights: state.tenant.insights,
        pngExportDetails: state.pngExporter.controls[PortfoliosList.name]
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Portfolio), dispatch),
        viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Portfolio), dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(PortfoliosList)