import * as React from 'react';
import { connect } from 'react-redux';
import { Spinner, SpinnerSize, ComboBox, IComboBoxOption, PrimaryButton } from 'office-ui-fabric-react';
import { ApplicationState } from "../../../store/index";
import { actionCreators, IVSTSConnectionState, IVSTSUser, IVSTSUserLinkInfo } from "../../../store/integration/VSTSStore";
import { ILinkDto, IListStore, validateConnection, loadDataAfterConnectionValidation } from "../../../store/integration/common";
import OverlayComponent from "../../common/OverlayComponent";
import { ISelectableOption } from 'office-ui-fabric-react/lib/utilities/selectableOption/SelectableOption.types';
import VSTSConnectionSelect from '../Vsts/VSTSConnectionSelect';
import { ConnectionField } from '../ConnectionField';
import { buildUserDisplayInfo } from './common';

type OwnProps = {
    actions: {
        linkToUser: (linkData: ILinkDto<IVSTSUser>) => void;
        deleteUserLink: (connectionId: string) => void;
    };
    connectionId?: string;
    sourceData?: IVSTSUserLinkInfo;
    dismissPanel?: () => void;
}

type StateProps = { 
    users: IListStore<IVSTSUser>;
    connections: IVSTSConnectionState;
};
type Props = StateProps & typeof actionCreators & OwnProps;

type State = {
    connectionId: string | undefined;
    userId: string | undefined;
    displayName: string | undefined;
    email: string | undefined;
    isLinked: boolean;

    users: IComboBoxOption[];
}

class VSTSConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLinked: !!props.sourceData,
            connectionId: props.connectionId,
            userId: props.sourceData?.userId,
            displayName: props.sourceData?.displayName,
            email: props.sourceData?.email,
            users: []
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.users !== nextProps.users) {
            const users: IComboBoxOption[] = [];
            let displayName: string | undefined;
            let email: string | undefined;

            for (const user of nextProps.users.entities) {
                if (user.id === this.state.userId) {
                    displayName = user.displayName;
                    email = user.email;
                }

                users.push({
                    key: user.id,
                    text: buildUserDisplayInfo(user.displayName, user.email),
                    disabled: user.isAlreadyLinked
                });
            }

            this.setState({ displayName, email, users });
        }

        const isLinked = !!nextProps.sourceData;
        if (isLinked != this.state.isLinked) {
            this.setState({ isLinked });
        }

        loadDataAfterConnectionValidation(
            this.props.connections, 
            nextProps.connections, 
            this.state.connectionId,
            this.props.loadUsers);
    }

    public render() {
        const { connectionId, userId, displayName, email, users } = this.state;
        const { sourceData } = this.props;
        
        const isLinked = !!sourceData?.userId;
        const userDisplayInfo = displayName && email && buildUserDisplayInfo(displayName, email)
            || sourceData && buildUserDisplayInfo(sourceData.displayName, sourceData.email);
            
        const isValid = validateConnection(this.props.connections, connectionId);

        return <>
            <div key="select-connection-container" className="with-top-margin">
                <VSTSConnectionSelect
                    disabled={isLinked}
                    connectionId={connectionId}
                    onChange={this._onConnectionChange} />
            </div>
            <div key="select-resource-container" className="with-top-margin">
                <OverlayComponent isOverlayed={!connectionId || isValid?.isValidating !== false || !!isValid?.validationError}>
                    <ConnectionField
                        isLinked={isLinked}
                        value={userDisplayInfo}
                        label="Resource">
                        <ComboBox
                            disabled={isLinked || !connectionId || this.props.users.isLoading}
                            placeholder="Select resource"
                            selectedKey={userId}
                            useComboBoxAsMenuWidth
                            allowFreeform
                            options={users}
                            onRenderItem={this.onRenderItem}
                            onChange={(e, _) => this.setState({ userId: _ && _.key as string })}
                            errorMessage={isValid?.isValidating === false && !isValid?.validationError ? this._getComboboxValidationMessage() : undefined} />
                    </ConnectionField>
                    {this.props.users.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                    {!this.props.users.isLoading && this.props.users.error && <div className="error-message">{this.props.users.error}</div>}
                    {
                        !isLinked && <PrimaryButton text="Link resource"
                            disabled={userId == undefined}
                            className="with-top-margin"
                            onClick={this._linkToUser} />
                    }
                    {
                        isLinked && <PrimaryButton text="Delete resource link"
                            className="with-top-margin"
                            onClick={this._deleteUserLink} />
                    }
                </OverlayComponent>
            </div>
        </>
    }

    private _onConnectionChange = (connectionId?: string) => {
        if (this.state.isLinked) return;

        this.setState({
            connectionId: connectionId,
            userId: undefined,
            displayName: undefined,
            email: undefined
        });
    }

    private _linkToUser = () => {
        const { connectionId, userId } = this.state;
        if (!connectionId || !userId) {
            return;
        }

        const user = this.props.users.entities.find(_ => _.id == userId);
        if (!user) {
            return;
        }

        this.props.actions.linkToUser({ connectionId, data: user });
        const dismissPanel = this.props.dismissPanel;
        dismissPanel && dismissPanel();
    }

    private _deleteUserLink = () => {
        const { connectionId } = this.state;
        if (!connectionId) return;

        this.props.actions.deleteUserLink(connectionId);
        this.setState({ userId: undefined, displayName: undefined, email: undefined });
    }

    private _getComboboxValidationMessage = () => {
        if (!this.state.connectionId || this.props.users.isLoading) {
            return undefined;
        }

        return this.props.users.error
            ? this.props.users.error
            : !this.state.userId
                ? `Please select resource from list`
                : undefined;
    }

    private onRenderItem = (props?: ISelectableOption, defaultRender?: (props?: ISelectableOption) => JSX.Element | null): JSX.Element | null => {
        if (!defaultRender) {
            return null;
        }

        let element = defaultRender(props);
        if (!props || !props.disabled) {
            return element;
        }
        return React.createElement("div", { title: "Already linked" }, element);
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { 
        users: state.vsts.users,
        connections: state.vsts.connections
    };
}

export default connect(mapStateToProps, actionCreators)(VSTSConnectControl);