import { Dictionary, IBaseEntity } from "../../entities/common";

export interface IEntityStore<T extends IBaseEntity> {
    byId: Dictionary<T>;
    allIds: string[];
    activeEntity?: T;
    activeEntityId?: string;
}

export interface IDeletionResult {
    id: string;
    name: string;
    isDeleted: boolean;
    message?: string;
    dependendencies?: string[];
    hidden?: boolean;
}

export interface IArchivingResult {
    id: string;
    name: string;
    isArchived: boolean;
    message?: string;
    dependendencies?: string[];
}

export namespace StoreHelper {
    export function addOrUpdate<TStore extends IEntityStore<TEntity>, TEntity extends IBaseEntity>(store: TStore, entity: TEntity): TStore {
        const newStore = Object.assign({}, store);
        if (!newStore.byId[entity.id]) {
            newStore.allIds.push(entity.id);
        }

        newStore.byId[entity.id] = entity;
        if(newStore.activeEntity?.id === entity.id){
            newStore.activeEntity = entity;
        }
        return newStore;
    }

    export function union<T extends IBaseEntity>(store: IEntityStore<T>, entities: T[]): IEntityStore<T> {
        const newStore = Object.assign({}, store);
        entities.forEach(entity => {
            if (!newStore.byId[entity.id]) {
                newStore.allIds.push(entity.id);
            }

            newStore.byId[entity.id] = entity;
            if(newStore.activeEntity?.id === entity.id){
                newStore.activeEntity = entity;
            }
        });

        return newStore;
    }

    export function create<T extends IBaseEntity>(entities: T[]): IEntityStore<T> {
        const byId = new Dictionary<T>();
        entities.forEach(_ => byId[_.id] = _);
        return {
            byId,
            allIds: Object.keys(byId)
        };
    }

    export function remove<T extends IBaseEntity>(store: IEntityStore<T>, entityId: string): IEntityStore<T> {
        const newStore = Object.assign({}, store);

        const index = newStore.allIds.indexOf(entityId);
        if (index !== -1) {
            newStore.allIds.splice(index, 1);
            delete newStore.byId[entityId];
        }
        if (newStore.activeEntityId === entityId) {
            newStore.activeEntity = undefined;
            newStore.activeEntityId = undefined;
        }

        return newStore;
    }

    export function filter<T extends IBaseEntity>(store: IEntityStore<T>, predicate: (entity: T) => boolean): IEntityStore<T> {
        const entities = store.allIds.map(_ => store.byId[_]);
        return create(entities.filter(predicate));
    }

    export function applyHandler<TEntity extends IBaseEntity, TState extends IEntityStore<TEntity>>(
        state: TState, entityIds: string[] | string, handler: (entity: TEntity) => TEntity): TState {
            state = Object.assign({}, state, { isLoading: false });
            const ids = Array.isArray(entityIds) ? entityIds : [entityIds];
            ids.forEach(entityId => {
                let updatedEntity: TEntity | undefined;
                if (state.activeEntityId === entityId && state.activeEntity?.id === entityId) {
                    updatedEntity = handler(state.activeEntity);

                    state.activeEntity = updatedEntity;
                    state.activeEntityId = updatedEntity.id;
                }
                else if (state.byId[entityId]) {
                    updatedEntity = handler(state.byId[entityId]);
                }

                if (updatedEntity && state.byId[entityId]) {
                    if (!~state.allIds.indexOf(updatedEntity.id)) {
                        state.allIds.push(updatedEntity.id);
                    }
                    state.byId[updatedEntity.id] = updatedEntity;

                    if (updatedEntity.id != entityId) {
                        const index = state.allIds.indexOf(entityId);
                        if (index !== -1) {
                            state.allIds.splice(index, 1);
                            delete state.byId[entityId];
                        }
                    }
                }
            });
            
            return state;
    }
}

export function partialUpdate<T>(entity: T, changes: Partial<T>) {
    return Object.assign({}, entity, changes);
}

export function addOrUpdateOrRemove<T extends { id: string }>(a1?: T[], a2?: T[] | T, remove?: string[]) {
    const arr1 = a1 || [];
    const arr2 = a2 ? Array.isArray(a2) ? a2 : [a2] : [];

    const result = [...arr1.map(_ => arr2.find(__ => __.id === _.id) || _), ...arr2.filter(_ => !arr1.find(__ => __.id === _.id))];
    return remove ? result.filter(_ => !~remove.indexOf(_.id)) : result;
}