import React, { useMemo } from "react";
import TimeTrackingCellValue from "./TimeTrackingCellValue";
import { TimeTrackingEntry, TimeTrackingLine } from "../../../store/TimeTrackingStore";
import { ITimelineSegment } from "../../common/timeline/TimelineSegment";
import { IRow } from "../../common/timeline/TimelineList";
import { EntityType } from "../../../entities/common";

interface OwnProps {
    row: IRow;
    segment: ITimelineSegment;
    expected?: number;
    isDayOff: boolean;
}

type Props = OwnProps;

export default function TotalTimeTrackingCell(props: Props) {
    const { row, segment, expected, isDayOff } = props;

    const entries = getTimeEntries(row, segment);

    const total = useMemo(() => {
        const reported = entries.reduce((sum, cur) => sum + cur.attributes.Duration!, 0);
        return reported;
    }, [row, segment]);

    const isExceeded = expected !== undefined && total > expected;

    const className = [
        'time-tracking-cell',
        'fill-space',
        'total',
        isExceeded ? 'exceeded' : undefined
    ].join(" ");

    return (
        <div className={className} style={{ position: "relative" }}>
            <div className={`cell-content fill-space columns-${1}`}>
                <TimeTrackingCellValue
                    value={total}
                    title="Reported"
                    className="reported"
                    expected={expected}
                    isDayOff={isDayOff}
                />
            </div>
        </div>
    );
}

export const getTimeTrackinLines = (row: IRow): TimeTrackingLine[] => {
    const result: TimeTrackingLine[] = [];

    if (row.subItemType === EntityType.Task) {
        result.push(row.entity as TimeTrackingLine);
    } else if (row.subItems?.length) {
        row.subItems.forEach(_ => {
            const subLines = getTimeTrackinLines(_);
            result.push(...subLines);
        });
    }

    return result;
};

export const getTimeEntries = (row: IRow, segment: ITimelineSegment) => {
    const lines = getTimeTrackinLines(row);
    const entries: TimeTrackingEntry[] = lines.reduce((result: TimeTrackingEntry[], line) => {
        const lineEntries = line.TimeEntries.filter(
            _ => _.attributes.Date >= segment.startDate && _.attributes.Date <= segment.finishDate
        );
        result.push(...lineEntries);
        return result;
    }, []);

    return entries;
};
