import * as React from "react";
import * as Metadata from '../../entities/Metadata';
import { FieldActionTypes, ISettingsEditComponent, IBaseFieldInfo } from "./FieldPanel";
import { TextField, ITextField } from "office-ui-fabric-react";
import { Dictionary } from "../../entities/common";
import { Validator, IValidator } from "../../validation";

type Props = {
    settings: Dictionary<any>;
    onChange: (isValid: boolean) => void;
    actionType: FieldActionTypes;
    isInteger: boolean;
    disabled?: boolean;
}

interface NumberInputValue {
    stringValue: string;
    numberValue: number;
}

type State = {
    minValue: NumberInputValue;
    maxValue: NumberInputValue;
    step: NumberInputValue;
}

export class SliderSettingsEdit extends React.Component<Props, State> implements ISettingsEditComponent {
    private _min = React.createRef<SliderInput>();
    private validators: {
        minValue: IValidator,
        maxValue: IValidator,
        step: IValidator,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            minValue: this.getNumberInputValue(props.settings.minValue),
            maxValue: this.getNumberInputValue(props.settings.maxValue),
            step: this.getNumberInputValue(props.settings.step),
        };
        this.validators = this._buildValidators();
        this._isValid();
    }

    public render() {
        const { disabled } = this.props;
        const { minValue: min, maxValue: max, step } = this.state;
        return <div>
            <SliderInput
                ref={this._min}
                label="Min value"
                disabled={disabled}
                value={min}
                onChange={_ => this._setSettings({ minValue: _ })}
                validator={this.validators.minValue}
            />
            <SliderInput
                label="Max value"
                disabled={disabled}
                value={max}
                onChange={_ => this._setSettings({ maxValue: _ })}
                validator={this.validators.maxValue}
            />
            <SliderInput
                label="Step"
                disabled={disabled}
                value={step}
                onChange={_ => this._setSettings({ step: _ })}
                validator={this.validators.step}
            />
        </div>;
    }

    componentDidMount() {
        if (this.props.actionType == FieldActionTypes.Create) {
            this._min.current!.focus();
        }
    }

    private getNumberInputValue(value?: any): NumberInputValue {
        return value !== null && value !== undefined
            ? {
                stringValue: value.toString(),
                numberValue: value
            }
            : {
                stringValue: undefined,
                numberValue: undefined
            };
    }

    private _buildValidators() {
        return {
            minValue: (this.props.isInteger ? Validator.new().required().int32() : Validator.new().required().decimal()).build(),
            maxValue: (this.props.isInteger ? Validator.new().required().int32() : Validator.new().required().decimal()).min(this.state.minValue.numberValue).step(this.state.minValue.numberValue, this.state.step.numberValue).build(),
            step: (this.props.isInteger ? Validator.new().required().int32() : Validator.new().required().decimal()).min(0).build()
        };
    }

    private _setSettings<K extends keyof State>(changes: Pick<State, K>) {
        this.setState({ ...changes }, () => {   
            this.validators = this._buildValidators();
            let isValid = this._isValid();
            this.props.onChange(isValid);
        });
    }

    private _isValid() {
        const { minValue, maxValue, step } = this.state;
        return Validator.isValid(this.validators as Dictionary<IValidator>, {
            minValue: minValue.stringValue,
            maxValue: maxValue.stringValue,
            step: step.stringValue
        });
    }

    public save(fieldInfo: Metadata.FieldInfo) {
        fieldInfo.type = Metadata.FieldType.Decimal;
        fieldInfo.settings = {
            ...fieldInfo.settings,
            ...this._getSettings(),
            editControl: "Slider"
        }
    }

    public update(fieldInfo: IBaseFieldInfo) {
        fieldInfo.settings = {
            ...fieldInfo.settings,
            ...this._getSettings()
        };
    }

    private _getSettings() {
        const { minValue, maxValue, step } = this.state;
        return {
            minValue: Number.isNaN(minValue.numberValue)
                ? undefined
                : minValue.numberValue,
            maxValue: Number.isNaN(maxValue.numberValue)
                ? undefined
                : maxValue.numberValue,
            step: Number.isNaN(step.numberValue)
                ? undefined
                : step.numberValue
        }
    }
}

type SliderInputProps = {
    label: string;
    value?: NumberInputValue;
    onChange: (value: NumberInputValue) => void;
    validator: IValidator;
    disabled?: boolean;
};

class SliderInput extends React.Component<SliderInputProps> {
    private _field = React.createRef<ITextField>();

    public render() {
        const { value, label, onChange, validator, disabled } = this.props;
        return <TextField
            componentRef={this._field}
            label={label}
            required
            disabled={disabled}
            onChange={(e, _) => onChange({
                stringValue: _ || "",
                numberValue: Number(_)
            })}
            value={value?.stringValue}
            errorMessage={validator.getErrorMessage(value?.stringValue)} />;
    }

    public focus() {
        this._field.current!.focus();
    }
}