import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Resource } from "../../../../../store/ResourcesListStore";
import { Field } from "../../../../../entities/Metadata";
import { FormatDateTime, toDateTime } from '../../../../utils/common';
import { DateTimeFormatter } from '../../../../common/formatters/DateFormatter';

export default class LastADSync extends React.Component<IListViewFieldColumn<Resource>> {
    public render() {
        const field = this.props.field;
        if (!field) {
            return <span>Invalid column configuration</span>;
        }
        return <DateTimeFormatter value={this.props.entity.lastADSync}></DateTimeFormatter>;
    }

    public static getValue(entity: Resource, field: Field): Date | undefined {
        return toDateTime(entity.lastADSync);
    }

    public static getSearchValue(entity: Resource, field: Field): string | undefined {
        return FormatDateTime(entity.lastADSync, true) ;
    }
}