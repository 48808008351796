import * as React from 'react';

const FullscreenReMountComponent = (props: React.PropsWithChildren<{ isFullScreen: boolean; }>) => {
    const { isFullScreen } = props; 
    const [reMount, setReMount] = React.useState(false);
    const [prevIsFullScreen, setPrevIsFullScreen] = React.useState(isFullScreen);

    React.useEffect(() => {
        if (prevIsFullScreen !== isFullScreen) {
            setReMount(true);
            setPrevIsFullScreen(isFullScreen);
        }
    }, [isFullScreen]);

    React.useEffect(() => {
        if (reMount) {
            setReMount(false);
        }
    }, [reMount]);
    return <>{reMount ? null : props.children}</>
}

export default FullscreenReMountComponent;