import * as React from 'react';
import * as analytics from '../../analytics';
import { Panel, PanelType, IPanelProps } from 'office-ui-fabric-react';
import { actionCreators as ideasActionCreators, Idea } from '../../store/IdeasListStore';
import { actionCreators as notificationsActionCreators } from "../../store/NotificationsStore";
import LinkTab from '../integration/LinkTab';
import { SourceType } from "../../store/ExternalEpmConnectStore";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Integrations } from '../../store/Tenant';
import { ApplicationState } from '../../store';
import { PANEL_CUSTOM_WIDTH } from '../../entities/common';
import { ConnectProps, RendersMap } from '../integration/TabConnectControl';
import FileConnectControl from '../integration/FileConnectControl';
import { UserState } from '../../store/User';

type OwnProps = {
    entity: Idea;
    onDismiss: () => void;
    visibleTypes?: SourceType[];
}

type StateProps = {
    user: UserState;
    integrations: Integrations;
}

type ActionProps = {
    ideasActions: typeof ideasActionCreators;
    notificationsActions: typeof notificationsActionCreators;
}

type Props = OwnProps & StateProps & ActionProps;

type State = {
    context?: any;
}

class ExternalEpmConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const hiddenOptions = Object.keys(SourceType).map(key => SourceType[key]).filter(_ => !this.props.visibleTypes?.includes(_));
        return <Panel
            isLightDismiss
            type={PanelType.custom}
            customWidth={PANEL_CUSTOM_WIDTH}
            className="connect-panel"
            isOpen={true}
            onDismiss={this.props.onDismiss}
            onRenderHeader={this._onRenderHeader}>
            <LinkTab
                rendersMap={this._rendersMap}
                readonly={!this.props.entity.isEditable}
                sourceInfos={this.props.entity.sourceInfos}
                actions={this.epmConnectControlActionsBuilder()}
                onDismiss={this.props.onDismiss}
                integrations={this.props.integrations}
                hiddenOptions={hiddenOptions}/>
        </Panel>;
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure Connections</p>
            <div className='ms-Panel-secondaryText'>Create file integration to add a document link to an idea.</div>
        </div>;
    }

    private _rendersMap: RendersMap = {
        [SourceType.File]: { render: (props: ConnectProps) => <FileConnectControl key="file" {...props} /> }
    }

    private epmConnectControlActionsBuilder = () => {
        return {
            [SourceType.File]: {
                linkToFile: this.linkToFile,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.File)
            }
        };
    }

    private deleteLink = (connectionId: string, type: SourceType) => {
        this.props.ideasActions.deleteExternalSystemLink(this.props.entity.id, connectionId, type);
        this.props.notificationsActions.pushNotification({ message: `Unlinking idea '${this.props.entity.attributes.Name}' has been started.` });
    }

    private linkToFile = (fileUrl: string) => {
        this.props.ideasActions.linkToFile(this.props.entity.id, fileUrl);
        analytics.trackLink('Linked Idea to file', this.props.user);
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        integrations: new Integrations(state.tenant.subscription.integrations)
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        ideasActions: bindActionCreators(ideasActionCreators, dispatch),
        notificationsActions: bindActionCreators(notificationsActionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ExternalEpmConnectControl);