import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react";
import { ProgressCalculationType } from "../../../entities/common";
import { onRenderField, IChoiceGroupOptionExt } from './PrimaryScheduleSection';


export const progressCalculationTypeOptions: IChoiceGroupOption[] = [
    {
        text: 'Effort',
        value: ProgressCalculationType.Effort,
        key: ProgressCalculationType.Effort.toString(),
    },
    {
        text: 'Story Points',
        value: ProgressCalculationType.StoryPoints,
        key: ProgressCalculationType.StoryPoints.toString(),
    },
    {
        text: 'Average Progress',
        value: ProgressCalculationType.AverageProgress,
        key: ProgressCalculationType.AverageProgress.toString(), 
    }
];

const progressCalculationTypeDescriptionsMap = {
    [ProgressCalculationType.Effort]: <>Progress is calculated by the formula:<br />(Completed Work / Effort) * 100%</>,
    [ProgressCalculationType.StoryPoints]: <>Progress is calculated by the formula:<br />(Completed Story Points / Story Points) * 100%</>,
    [ProgressCalculationType.AverageProgress]: <>Progress is calculated as average of Progress,<br /> % from “leaf” tasks</>
}

const options: IChoiceGroupOptionExt[] = progressCalculationTypeOptions.map(opt => ({
    ...opt,
    description: progressCalculationTypeDescriptionsMap[opt.value as ProgressCalculationType]
}) as IChoiceGroupOptionExt).map(opt => ({ ...opt, onRenderField }));

export const ProjectCalculationSection = (props: React.PropsWithChildren<{
    value?: ProgressCalculationType,
    onChange: (value: ProgressCalculationType) => void,
    readonly?: boolean
}>) => {
    return <div className="project-calculation">
        <div className='section-description'>
            Select Progress Calculation rule for PPM Express tasks, task groups and rolled up project progress.
        </div>
        {props.children}
        <ChoiceGroup label='Calculate Progress by'
            className="horizontal"
            defaultSelectedKey={props.value?.toString()}
            options={options}
            disabled={props.readonly}
            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => props.onChange(option?.value as ProgressCalculationType)}
        />
    </div>;
}
