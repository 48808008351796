import * as React from 'react';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { UserState, UserStatus } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { TenantState, actionCreators, SyncSchedule, SyncSettings as SyncSettingsType, Subscription, PPMFeatures, PPMIntegrations } from '../../../store/Tenant';
import TimeSelector from '../../common/TimeSelector';
import { Dropdown, Label, IDropdownOption, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import PersonPickerInput, { PersonInfo } from '../../common/inputs/PersonPickerInput';
import { post } from '../../../fetch-interceptor';
import { toDictionaryById } from '../../utils/common';

type OwnProps = {};
type StateProps = {
    user: UserState;
    tenant: TenantState;
}

type State = {
    syncSchedule: SyncSchedule;
    alertDelay?: string;
    alertRecipients: PersonInfo[];
    isLoadingAlertRecipients?: boolean;
}

type Props = StateProps & OwnProps & typeof actionCreators;

class SyncSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { syncSchedule: { isPerpetual: false, days: [], times: [] }, alertRecipients: [] };
    }

    public componentWillMount() {
        if (this.props.tenant.syncSettings && this.props.tenant.healthReportingSettings) {
            this.setState(this._buildState(this.props.tenant.syncSettings));
        }
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (nextProps.tenant.syncSettings && nextProps.tenant.healthReportingSettings && !nextProps.tenant.isProcessing) {
            this.setState(this._buildState(nextProps.tenant.syncSettings));
        }
    }

    public render() {
        const tenant = this.props.tenant;
        const { syncSchedule, isLoadingAlertRecipients } = this.state;

        const canManage = contains(this.props.user.permissions.common, CommonOperations.ScheduleManage);
        const isPerpetualSyncEnabled = Subscription.contains(tenant.subscription, PPMFeatures.PerpetualSync);
        const noSyncableIntegration = !Subscription.hasSyncableIntegration(tenant.subscription);
        return <div className="sync-settings">
            {isPerpetualSyncEnabled && <Toggle label="Perpetual Synchronization"
                checked={syncSchedule.isPerpetual}
                onChanged={(checked: boolean) => { this.setState({ syncSchedule: { ...syncSchedule, isPerpetual: checked } }, this._save); }}
                onText='On'
                offText='Off' />}
            <TimeSelector
                disabled={noSyncableIntegration || !canManage || isPerpetualSyncEnabled && syncSchedule.isPerpetual}
                label={`Synchronization Schedule (${noSyncableIntegration
                    ? "disabled by license"
                    : `${syncSchedule.times.length} out of ${this.props.tenant.subscription!.dataSyncCountLimit}`})`}
                times={syncSchedule.times}
                onChange={(times: any[]) => this.setState({ syncSchedule: { ...syncSchedule, times: times } }, this._save)}
                limit={this.props.tenant.subscription!.dataSyncCountLimit}
                multiSelect={true}
            />
            {
                !noSyncableIntegration &&
                <div>
                    <Label>Send synchronization alerts</Label>
                    <Dropdown options={this._alertDelayOptions()}
                        disabled={!canManage}
                        selectedKey={this.state.alertDelay}
                        onChange={(e: any, option: IDropdownOption) => this.setState({ alertDelay: option.key as string }, this._save)}></Dropdown>
                    <div className="recipients" style={{ position: "relative" }}>
                        <PersonPickerInput multichoice
                            disabled={isLoadingAlertRecipients}
                            readOnly={!canManage}
                            searchUrl={`api/user/find`}
                            filter={{ status: UserStatus.Active }}
                            inputProps={{ placeholder: "Recipients" }}
                            value={isLoadingAlertRecipients ? [] : this.state.alertRecipients}
                            onChanged={(items: PersonInfo[]) => this.setState({ alertRecipients: items }, this._save)} />
                        {isLoadingAlertRecipients && <Spinner size={SpinnerSize.small} style={{ position: "absolute", left: 475, top: 15 }} />}
                    </div>
                </div>
            }
        </div>;
    }

    private _save = () => {
        this.props.updateSyncSchedule(
            this.state.syncSchedule,
            !this.state.alertDelay || this.state.alertDelay === this._alertDelayNever ? null : this.state.alertDelay,
            this.state.alertRecipients.map(_ => _.id),
            this.props.tenant.healthReportingSettings!);
    }

    private _buildState = (syncSettings: SyncSettingsType) => {
        let newState = {
            syncSchedule: syncSettings.schedule,
            alertDelay: syncSettings.alertDelay == null ? this._alertDelayNever : syncSettings.alertDelay,
            isLoadingAlertRecipients: false
        };

        const userIdsToLoad = syncSettings.alertRecipientIds.filter(_ => !this.state.alertRecipients.find(__ => __.id === _));
        if (userIdsToLoad.length > 0) {
            newState.isLoadingAlertRecipients = true;
            post<PersonInfo[]>("api/user/find", { ids: userIdsToLoad })
                .then(data => {
                    const recipients = Object.values(toDictionaryById([...this.state.alertRecipients, ...data]));

                    this.setState({
                        isLoadingAlertRecipients: false,
                        alertRecipients: recipients.filter(_ => syncSettings.alertRecipientIds.find(__ => _.id === __))
                    })
                })
                .catch(_ => this.setState({ isLoadingAlertRecipients: false }))
        }

        return newState;
    }

    private _alertDelayNever = "never";
    private _alertDelayOptions(): IDropdownOption[] {
        return [
            { key: "00:05:00", text: "5 min" },
            { key: "01:00:00", text: "1 hour" },
            { key: "03:00:00", text: "3 hours" },
            { key: "08:00:00", text: "8 hours" },
            { key: "1.00:00:00", text: "1 day" },
            { key: "7.00:00:00", text: "1 week" },
            { key: this._alertDelayNever, text: "Never" }
        ];
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, actionCreators)(SyncSettings);