import * as React from 'react';
import * as Metadata from "../../../entities/Metadata";
import "./EntitiesFilterTags.css";

interface Props {
    filter?: Metadata.IFilter<Metadata.BaseFilterValue>;
    getAttributeValue: (attrType: string, value: any) => string[];
    showRightDivider?: boolean;
    onTagClick?: () => void;
}

const MAX_SHOWN_BUBBLE_COUNT = 3;

function EntitiesFilterTags(props: React.PropsWithChildren<Props>) {

    const buildFilterTags = () => {
        const { filter, getAttributeValue, onTagClick } = props;
        if (!filter) {
            return undefined;
        }

        //first get ordered by attributeNames
        let values: string[] = [];
        let clonedValue = filter.value;
        if (filter.value) {
            clonedValue = JSON.parse(JSON.stringify(filter.value));
            filter.attributeNames.forEach(attr => {
                const valueType = clonedValue[attr.type];
                if (valueType) {
                    const propertyValue = valueType[attr.name];
                    if (propertyValue !== undefined) {
                        const valueWith1Prop = {};
                        valueWith1Prop[attr.name] = propertyValue;
                        const attrValues = getAttributeValue(attr.type.toString(), valueWith1Prop);
                        values = values.concat(attrValues);
                    }
                    delete valueType[attr.name];
                    if (Object.keys(valueType).length === 0) {
                        delete clonedValue[attr.type];
                    }
                }
            });
        }

        //then add if something is missing
        const valuesToAdd = Object.keys(clonedValue).reduce<string[]>((acc, type) => {
            const attrValues = getAttributeValue(type, clonedValue[type]);
            return acc.concat(attrValues);
        }, []);

        values = values.concat(valuesToAdd);

        const bubbles = values.filter(value => value).slice(0, MAX_SHOWN_BUBBLE_COUNT).map((value, index) => (
            <div onClick={onTagClick} className={`filter-tag align-center ${onTagClick ? 'clickable' : ''}`} title={value} key={index}>
                <div className="name overflow-text">{value}</div>
            </div>
        ));

        const moreBubblesCount = values.length - MAX_SHOWN_BUBBLE_COUNT;
        if (moreBubblesCount > 0) {
            bubbles.push(
                <div className="more-filter-tags" onClick={onTagClick}>+{moreBubblesCount}</div>
            );
        }

        return bubbles;
    };

    const filterTags = buildFilterTags();
    return (
        <div className='entities-filter-tags align-center'>
            {filterTags}
            {props.showRightDivider && !!filterTags?.length && <div className="filter-tag-divider" key="filter-tag-divider"></div>}
            {props.children}
        </div>
    );
}

export default EntitiesFilterTags;
