import * as React from "react";
import FlagInput from "../inputs/FlagInput";

type FlagFilterProps = { value?: boolean; onEditComplete: (value: boolean) => void }

export default class FlagFilter extends React.Component<FlagFilterProps> {
    public render(){
        return <FlagInput {...this.props} />;
    }

    componentDidMount(){
        const { value, onEditComplete } = this.props;
        value == undefined && onEditComplete(false);
    }
}