import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import CalendlyRequest from '../common/CalendlyRequest';

interface StateProps {
    calendlyEventPath: string
}

type Props = StateProps & RouteComponentProps<{}>;

const RequestDemo = (props: Props) => {
    return <CalendlyRequest
        calendlyEventName='RequestDemo'
        calendlyEventPath={props.calendlyEventPath}

        title={<>Rather Have Us Show You How It Would Work For <u>Your Company?</u></>}
        description={<>Instead of getting a trial and figuring it all out by yourself why not book a fully personalized onboarding call with our team where we’ll show you:</>}
        details={<> <p>
            ✔️ How it would work with <u>YOUR use cases.</u>
        </p>
            <p>
                ✔️ How it would integrate with <u>YOUR system and tools you use.</u>
            </p>
            <p>
                ✔️ Show you all the features that are important to you and your company.
            </p>
            <p>
                Plus, onboard you and answer any questions you might have.
            </p>
            <p>
                <b>Interested? Just book a free call with our team here 👉</b>
            </p>
            <p>
                Let’s help you get set up!
            </p>
        </>}
        skipText='No, I just want to try it myself without any help and continue my free trial.'
    />
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        calendlyEventPath: state.analytics.calendlyEventPath,
    }
}

export default withRouter<{}>(connect(mapStateToProps)(RequestDemo));