import * as React from "react";
import { Spinner, ComboBox, SpinnerSize, PrimaryButton, IComboBoxOption, ISelectableOption, Label, IconButton } from "office-ui-fabric-react";
import { connect } from "react-redux";
import SmartsheetConnectionSelect from "./SmartsheetConnectionSelect";
import { Dictionary, ISourceInfo } from "../../../entities/common";
import OverlayComponent from "../../common/OverlayComponent";
import { ApplicationState } from "../../../store/index";
import { actionCreators, ISmartsheetSheet, ISmartsheetProjectSourceData, ISmartsheetWorkspace, ISmartsheetConnectionState } from "../../../store/integration/SmartsheetStore";
import SmartsheetMappingPanel from "./SmartsheetMappingPanel";
import { ConnectionField } from "../ConnectionField";
import { validateConnection } from "../../../store/integration/common";
import { usePrevious } from "../../utils/effects";
import SwitchPrimaryScheduleConfirmation from "../SwitchPrimaryScheduleConfirmation";
import { SourceType } from "../../../store/ExternalEpmConnectStore";

export type SmartsheetConnectActions = {
    deleteLink: (connectionId: string) => void,
    linkToSmartsheetSheet: (obj: { connectionId: string, data: ISmartsheetProjectSourceData, setAsPrimary?: boolean }) => void
}

type OwnProps = {
    readonly: boolean;
    actions: SmartsheetConnectActions;
    allowMultipleConnectionsToOneProject?: boolean;
    disableConfigureConnection?: boolean;
    expandAdditionalFilter?: boolean;
    sourceInfo?: ISourceInfo<ISmartsheetProjectSourceData>;
    dismissPanel?: () => void;
    showSwitchPrimaryDialog?: boolean;
    setAsPrimary?: boolean;
}

type StateProps = {
    sheetsByWorkspaceMap: Dictionary<ISmartsheetSheet[]>;
    workspaces: ISmartsheetWorkspace[];
    isLoading: boolean;
    error: string | null;
    connections: ISmartsheetConnectionState;
};
type Props = OwnProps & StateProps & typeof actionCreators;

const sheetsToOptions = (o: Dictionary<ISmartsheetSheet[]>): Dictionary<ISelectableOption[]> =>
    Object.keys(o).reduce<Dictionary<ISelectableOption[]>>((acc, key) => {
        acc[key] = o[key].map(_ => ({ key: _.sheetId, text: _.sheetName, disabled: _.isAlreadyLinked }))
            .sort((a, b) => a.text.localeCompare(b.text));
        return acc;
    }, {});

const workspacesToOptions = (o: ISmartsheetWorkspace[]): ISelectableOption[] =>
    o.map(_ => ({ key: _.id, text: _.name }))
        .sort((a, b) => a.text.localeCompare(b.text));

const onRenderItem = (props?: IComboBoxOption, defaultRender?: (props?: IComboBoxOption) => JSX.Element | null): JSX.Element | null => {
    if (!defaultRender) {
        return null;
    }

    const element = defaultRender(props);
    if (!props || !props.disabled) {
        return element;
    }
    return React.createElement("div", { title: "Already linked" }, element);
}

const linkToProject = (props: Props, connectionId: string, workspaceId: number, sheetId: number, setAsPrimary?: boolean) => {
    const { sheetsByWorkspaceMap, actions, dismissPanel } = props;
    const itemsSheet = sheetsByWorkspaceMap[workspaceId].find(_ => _.sheetId === sheetId);

    if (!itemsSheet || itemsSheet.workspaceId !== workspaceId) {
        return;
    }

    actions.linkToSmartsheetSheet({
        connectionId,
        data: {
            workspaceId: itemsSheet.workspaceId,
            workspaceName: itemsSheet.workspaceName,
            sheetId: itemsSheet.sheetId,
            sheetName: itemsSheet.sheetName,
            sheetUrl: itemsSheet.sheetUrl,
        },
        setAsPrimary: !!setAsPrimary
    });
    dismissPanel?.();
}

const deleteLink = (props: Props, connectionId: string) => {
    const { actions, dismissPanel } = props;

    actions.deleteLink(connectionId);
    dismissPanel?.();
}

const SmartsheetConnectControl = (props: Props) => {
    const { readonly, sheetsByWorkspaceMap, isLoading, error, sourceInfo, allowMultipleConnectionsToOneProject, disableConfigureConnection,
        connections, showSwitchPrimaryDialog } = props;
    const [connectionId, setConnectionId] = React.useState<string | undefined>(sourceInfo?.connectionId);
    const [sheetId, setsheetId] = React.useState<number | undefined>(sourceInfo?.sourceData.sheetId);
    const [workspaceId, setWorkspaceId] = React.useState<number | undefined>(sourceInfo?.sourceData.workspaceId);
    const [isConfigureConnection, setIsConfigureConnection] = React.useState<boolean | undefined>();
    const [showSwitchPrimaryScheduleConfirm, setShowSwitchPrimaryScheduleConfirm] = React.useState<boolean>(false);

    const previousConnectionsVerification = usePrevious(props.connections.connectionsVerification);
    React.useEffect(() => {
        const validationInfo = validateConnection(connections, connectionId);
        if (connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false
            && validationInfo?.isValidating === false && !validationInfo.validationError) {
            props.loadSheets(connectionId);
        }
    }, [connectionId, connectionId && connections.connectionsVerification[connectionId]]);

    const connectionValidation = validateConnection(connections, connectionId);

    const sheetOptionsMap = React.useMemo(() => sheetsToOptions(props.sheetsByWorkspaceMap), [props.sheetsByWorkspaceMap]);
    const workspaceOptionsMap = React.useMemo(() => workspacesToOptions(props.workspaces), [props.workspaces]);
    const isSheetAlreadyLinked = React.useMemo(() => {
        const sheet = (workspaceId && sheetsByWorkspaceMap.hasOwnProperty(workspaceId) ? sheetsByWorkspaceMap[workspaceId] : []).find(_ => _.sheetId === sheetId)
        return !allowMultipleConnectionsToOneProject && sheet?.isAlreadyLinked;
    }, [workspaceId, sheetId, sheetsByWorkspaceMap]);
    const isLinked = !!sourceInfo;

    const onConnectionChange = React.useCallback((_: string | undefined) => {
        if (!isLinked) {
            setConnectionId(_);
            setWorkspaceId(undefined);
            setsheetId(undefined);
        }
    }, []);

    const onWorkspaceChange = React.useCallback((e: React.FormEvent<any>, _?: IComboBoxOption) => {
        setWorkspaceId(_ ? (_.key as number) : undefined)
        setsheetId(undefined);
    }, []);

    const isSheetSelectionDisabled = readonly || isLinked || !connectionId || isLoading || !workspaceId;
    const sheetOptions = workspaceId ? sheetOptionsMap[workspaceId] : [];
    const workspaceName = workspaceId ? (workspaceOptionsMap.find(_ => _.key === workspaceId)?.text ?? sourceInfo?.sourceData.workspaceName) : '';
    const sheetName = sheetId ? (sheetOptions?.find(_ => _.key === sheetId)?.text ?? sourceInfo?.sourceData.sheetName) : '';

    return <>
        <div key="select-connection-container" className="with-top-margin">
            <SmartsheetConnectionSelect
                disabled={readonly || isLinked}
                connectionId={connectionId}
                onChange={onConnectionChange} />
        </div>
        <div key="select-project-container" className="with-top-margin">
            <OverlayComponent isOverlayed={!connectionId || connectionValidation?.isValidating !== false || !!connectionValidation?.validationError}>
                <ConnectionField
                    isLinked={isLinked}
                    label="Workspace"
                    value={workspaceName}>
                    <ComboBox
                        disabled={readonly || isLinked || !connectionId || isLoading}
                        allowFreeform
                        useComboBoxAsMenuWidth
                        placeholder="Select workspace"
                        selectedKey={workspaceId}
                        options={workspaceOptionsMap}
                        onChange={onWorkspaceChange}
                        errorMessage={!isLoading && connectionId && connectionValidation?.isValidating === false && !connectionValidation?.validationError
                            ? error
                                ? error
                                : !workspaceId
                                    ? `Please select workspace from list`
                                    : undefined
                            : undefined} />
                </ConnectionField>
                {isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                <div style={{ position: "relative" }}>
                    <ConnectionField
                        isLinked={isLinked}
                        value={sheetName}
                        label="Sheet">
                        <ComboBox
                            disabled={isSheetSelectionDisabled}
                            placeholder="Select sheet"
                            allowFreeform
                            useComboBoxAsMenuWidth
                            selectedKey={sheetId}
                            options={sheetOptions}
                            onRenderItem={onRenderItem}
                            onChange={(e, _) => setsheetId(_ ? (_.key as number) : undefined)}
                            errorMessage={!isSheetSelectionDisabled
                                ? error
                                    ? error
                                    : !sheetId
                                        ? `Please select sheet from list`
                                        : isSheetAlreadyLinked
                                            ? 'Sheet already linked'
                                            : undefined
                                : undefined} />
                    </ConnectionField>
                    {!disableConfigureConnection && <IconButton
                        disabled={!sheetId}
                        title="Configure mapping"
                        iconProps={{ iconName: "PPMXMapping" }}
                        onClick={() => setIsConfigureConnection(true)}
                        className="mapping-button" />}
                </div>
                {isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
            </OverlayComponent>
            {
                !disableConfigureConnection &&
                isConfigureConnection &&
                !!sheetId &&
                !!connectionId &&
                <SmartsheetMappingPanel
                    connectionId={connectionId}
                    itemSheetId={sheetId}
                    onDismiss={() => setIsConfigureConnection(false)} />
            }
            <OverlayComponent isOverlayed={!connectionId}>
                {
                    !isLinked && <PrimaryButton text={`Link sheet`}
                        disabled={readonly || isSheetAlreadyLinked || workspaceId === undefined || sheetId === undefined}
                        className="with-top-margin"
                        onClick={() => {
                            if (showSwitchPrimaryDialog) {
                                setShowSwitchPrimaryScheduleConfirm(true);
                                return;
                            }
                            linkToProject(props, connectionId!, workspaceId!, sheetId!, props.setAsPrimary);
                        }} />
                }
                {
                    isLinked && <PrimaryButton text="Delete sheet link"
                        className="with-top-margin"
                        disabled={readonly || sourceInfo?.syncStatus.inProgress}
                        title={!!sourceInfo?.syncStatus.inProgress ? "Sync in progress." : undefined}
                        onClick={() => deleteLink(props, connectionId!)} />
                }
            </OverlayComponent>
            {showSwitchPrimaryScheduleConfirm && <SwitchPrimaryScheduleConfirmation
                from={SourceType.Ppmx} to={SourceType.Smartsheet}
                onDismiss={() => setShowSwitchPrimaryScheduleConfirm(false)}
                onClick={(isPrimary) => linkToProject(props, connectionId!, workspaceId!, sheetId!, isPrimary)} />
            }
        </div>
    </>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        isLoading: state.smartsheet.sheets.isLoading,
        error: state.smartsheet.sheets.error,
        sheetsByWorkspaceMap: state.smartsheet.sheetsByWorkspaceMap,
        workspaces: state.smartsheet.workspaces,
        connections: state.smartsheet.connections
    };
}

export default connect(mapStateToProps, actionCreators)(SmartsheetConnectControl);