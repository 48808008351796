export enum SourceType {
    Ppmx = 0,
    O365Group = 1,
    VSTS = 2,
    Jira = 3,
    File = 4,
    O365Planner = 5,
    O365User = 6,
    Spo = 7,
    MondayCom = 12,

    //collaborative
    O365TeamsChannel = 8,
    PpmxTime = 9,
    Slack = 10,
    Confluence = 11,
    Smartsheet = 13,
    MPPFile = 14,
    P4W = 15
}

export interface ISyncVersion {
    task: number
}

const SourceTypeConfigMap: { [k: number]: { name: string, icon: string } } = {
    [SourceType.Ppmx]: { name: "PPM Express", icon: "PPMXLogo" },
    [SourceType.O365Group]: { name: "Office 365", icon: "OfficeLogo" },
    [SourceType.VSTS]: { name: "Azure DevOps", icon: "VSTSLogo" },
    [SourceType.Jira]: { name: "Jira", icon: "JiraLogo" },
    [SourceType.File]: { name: "File Integration", icon: "FileLinkIcon" },
    [SourceType.O365Planner]: { name: "Planner", icon: "PlannerNewLogo" },
    [SourceType.O365User]: { name: "Office 365", icon: "OfficeLogo" },
    [SourceType.Spo]: { name: "Project Online", icon: "SpoLogo" },
    [SourceType.MondayCom]: { name: "Monday.com", icon: "MondayComLogo" },
    [SourceType.Smartsheet]: { name: "Smartsheet", icon: "SmartsheetLogo" },
    [SourceType.MPPFile]: { name: "Project Desktop", icon: "MPPLogo" },
    [SourceType.P4W]: { name: "Project for the Web", icon: "P4WLogo" },

    [SourceType.O365TeamsChannel]: { name: "MS Teams", icon: "PPMXTeamsLogo" },
    [SourceType.Slack]: { name: "Slack", icon: "SlackLogo" },
    [SourceType.Confluence]: { name: "Confluence", icon: "ConfluenceLogo" },
    [SourceType.PpmxTime]: { name: "PPM Express Time", icon: "PPMXTimeLogo" },
}
export const SourceTypeMap = Object.keys(SourceTypeConfigMap).reduce<{ [k: number]: string }>(
    (acc, _) => {
        acc[_] = SourceTypeConfigMap[_].name
        return acc;
    }, {});
    
export namespace SourceType_ {
    export function getIconName(type: SourceType): string {
        return SourceTypeConfigMap[type]?.icon;
    }

    export function getName(type: SourceType): string {
        return SourceTypeConfigMap[type]?.name;
    }

    export const collaborative: SourceType[] = [SourceType.O365Group, SourceType.O365TeamsChannel, SourceType.PpmxTime];

    export function isO365(type: SourceType): boolean {
        return !![SourceType.O365Group, SourceType.O365Planner, SourceType.O365TeamsChannel, SourceType.O365User].find(_ => _ == type);
    }

    export function isTaskViewable(link: { syncVersion: ISyncVersion; type: SourceType }) {
        if (link.type === SourceType.Ppmx) {
            return true;
        }

        if (link.type === SourceType.O365Planner && link.syncVersion.task === SyncActualVersions.plannerPlan) {
            return true;
        }

        if (link.type === SourceType.Spo && link.syncVersion.task === SyncActualVersions.spoProject) {
            return true;
        }

        if (link.type === SourceType.VSTS && link.syncVersion.task === SyncActualVersions.vstsProject) {
            return true;
        }

        if (link.type === SourceType.Jira && link.syncVersion.task === SyncActualVersions.jiraProject) {
            return true;
        }

        if (link.type === SourceType.MondayCom && link.syncVersion.task === SyncActualVersions.mondayComBoard) {
            return true;
        }

        if (link.type === SourceType.Smartsheet && link.syncVersion.task === SyncActualVersions.smartsheetSheet) {
            return true;
        }

        if (link.type === SourceType.MPPFile && link.syncVersion.task === SyncActualVersions.mppFile) {
            return true;
        }

        if (link.type === SourceType.P4W && link.syncVersion.task === SyncActualVersions.p4W) {
            return true;
        }

        return false;
    }

    export function isTaskUnactualVersion(link: { syncVersion: ISyncVersion; type: SourceType }) {
        if (link.type === SourceType.O365Planner && link.syncVersion.task !== SyncActualVersions.plannerPlan) {
            return true;
        }

        if (link.type === SourceType.Spo && link.syncVersion.task !== SyncActualVersions.spoProject) {
            return true;
        }

        if (link.type === SourceType.VSTS && link.syncVersion.task !== SyncActualVersions.vstsProject) {
            return true;
        }

        return false;
    }
}

export const SyncActualVersions: { plannerPlan: number, spoProject: number, vstsProject: number,
    jiraProject: number, mondayComBoard: number, smartsheetSheet: number, mppFile: number, p4W: number } = {
    plannerPlan: 1,
    spoProject: 1,
    vstsProject: 1,
    jiraProject: 1,
    mondayComBoard: 0,
    smartsheetSheet: 0,
    mppFile: 0,
    p4W: 0,
}