import React from 'react';
import { FormatType, FieldType } from '../../entities/Metadata';
import { formatValue } from '../utils/common';
import { ProgressIcon } from "../common/ProgressFormatter";
import { Dictionary } from '../../entities/common';
import { TooltipFakeField, TooltipFieldSettings } from './ConfigureRoadmapTooltipPanel';
import TooltipField from './TooltipField';
import RoadmapItemAttrsTooltipField from './RoadmapItemAttrsTooltipField';
import EstimatedTooltipField from './EstimatedTooltipField';

export type GroupSummary = {
    counts: {
        total: number;
        new: number;
        inProgress: number;
        complete: number;
    }
    progress: number;
    effort: number;
    cost: number;
    benefit: number;
    storyPoints: number;
};

export const laneFakeFields: TooltipFakeField[] = [
    {
        id: "1a4f5fed-029f-4b10-817a-19a90295c4d4",
        name: "Progress",
        label: "Progress",
        type: FieldType.Integer,
        settings: { format: FormatType.Percent },
    },
    {
        id: "9d3a0973-b82e-4c02-b8d5-911325cc2cb1",
        name: "StoryPoints",
        label: "Σ Story Points",
        type: FieldType.Integer,
    },
    {
        id: "8fc55a47-9019-4d86-a3f6-b9016c69f352",
        name: "EstimantedEffort",
        label: "Σ Estimated Effort",
        type: FieldType.Integer,
        settings: { format: FormatType.Duration },
    },
    {
        id: "cbaa1787-661e-4cf9-af2e-1c605d706d5b",
        name: "EstimatedCost",
        label: "Σ Estimated Cost",
        type: FieldType.Decimal,
        settings: { format: FormatType.Cost },
    },
    {
        id: "c44f60de-b082-48ea-bae5-a56b19b8952b",
        name: "EstimatedBenefit",
        label: "Σ Estimated Benefit",
        type: FieldType.Decimal,
        settings: { format: FormatType.Cost },
    },
    {
        id: "1b428d13-bfa5-4836-b38b-43dd05b599d8",
        name: "RoadmapItems",
        label: "Roadmap Items",
        type: FieldType.Integer,
        settings: { editControl: 'EnumSlider', className: 'impact' },
    },
];

const defaultViewFields = [
    'Progress',
    'StoryPoints',
    'EstimantedEffort',
    'EstimatedCost',
    'EstimatedBenefit',
    'RoadmapItems',
];

type Props = {
    lane: {
        name: string;
        color: string;
    };
    summary: GroupSummary;
    settings?: TooltipFieldSettings;
};

function RoadmapLaneTooltipContent(props: Props) {
    const { lane, summary, settings } = props;

    const renderByFieldName: Dictionary<(key: string) => JSX.Element | null> = {
        Progress: (_) => (
            <RoadmapItemAttrsTooltipField name="Progress" key={_}>
                <div className="align-center">
                    <div className="completed">{formatValue(summary.progress, FormatType.Percent)}</div>
                </div>
            </RoadmapItemAttrsTooltipField>
        ),
        StoryPoints: (_) => <EstimatedTooltipField key={_} label="Σ Story Points" value={summary.storyPoints} />,
        EstimantedEffort: (_) => <EstimatedTooltipField key={_} label="Σ Estimated Effort" value={summary.effort} format={FormatType.Duration} />,
        EstimatedCost: (_) => <EstimatedTooltipField key={_} label="Σ Estimated Cost" value={summary.cost} format={FormatType.Cost} />,
        EstimatedBenefit: (_) => <EstimatedTooltipField key={_} label="Σ Estimated Benefit" value={summary.benefit} format={FormatType.Cost} />,
        RoadmapItems: (_) => (
            <TooltipField key={_} label="Roadmap Items">
                <div className="progress-formatter align-center">
                    <ProgressIcon progress={0} /><span className="value">{summary.counts.new}</span>
                    <span className="space"></span> 
                    <ProgressIcon progress={50} /><span className="value">{summary.counts.inProgress}</span>
                    <span className="space"></span> 
                    <ProgressIcon progress={100} /><span className="value">{summary.counts.complete}</span>
                </div>
            </TooltipField>
        ),
    };

    return (
        <div className="timeline-tooltip">
            <div className="header">
                <div style={{ backgroundColor: lane.color, minWidth: 32, width: 32, height: 32, marginRight: 8, borderRadius:3 }}></div>
                <div className="title">
                    <div className="overflow-text" title={lane.name}>
                        {lane.name}
                    </div>
                </div>
            </div>

            <div className="content">
                {
                    (!!settings?.fieldNames?.length
                        ? settings.fieldNames
                        : defaultViewFields)
                        .map(_ => renderByFieldName[_]?.(_))
                }
            </div>
        </div>
    );
}

export default RoadmapLaneTooltipContent;
