import * as React from 'react';
import { IListViewFieldColumn } from "../../../../services/ViewService";
import { Field } from "../../../../entities/Metadata";
import { formatFieldValue } from "../../../utils/common";
import { IBlinePlanActual } from "../../../../entities/common";
import { UserState } from '../../../../store/User';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';

type StoreProps = { user: UserState }
type Props = IListViewFieldColumn<IBlinePlanActual> & StoreProps;

export class CalculationFinancialsTotals extends React.Component<Props>{
    public render() {
        const { entity, field } = this.props;
        const settings = field.settings;
        if (!settings) {
            return <div></div>;
        }

        const format = settings.format;
        const className = this.props.className ? this.props.className : "";
        return <span className={`budget-totals ${className}`}>
            {formatFieldValue(CalculationFinancialsTotals.getValue(entity, field), format, this.props.user)}
        </span>;
    }

    public static getValue(actual: IBlinePlanActual, field: Field): number | undefined {
        const settings = field.settings;
        if (!settings) {
            return undefined;
        }

        const listSettings = settings.views.list;
        if (listSettings.showActual) {
            return actual.actual;
        }
        if (listSettings.showBaseline) {
            return actual.baseline;
        }
        if (listSettings.showPlan) {
            return actual.plan;
        }

        return undefined;
    }
}

function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(CalculationFinancialsTotals);