import * as React from 'react';
import { connect } from "react-redux";
import { Panel, PanelType, DefaultButton, PrimaryButton, Selection, MessageBarType } from 'office-ui-fabric-react';
import ResourceImportGrid from './ResourceImportGrid';
import { ApplicationState } from "../../../store/index";
import { IImportEntityMap, IImportEntity, ISourceInfo } from "./common";
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { IConnectionInfo } from '../../../entities/Metadata';
import { ImportHeader } from '../common/ImportHeader';
import { Dictionary } from '../../../entities/common';
import { bindActionCreators } from "redux";
import { Resource, actionCreators as resourcesActions } from "../../../store/ResourcesListStore";
import { IImportResourceState as ImportMap, actionCreators as importActions, IMappedResource, ImportStatus } from "../../../store/integration/ImportStore";
import Spinner from '../../common/Spinner';

type OwnProps = {
    entities: IImportEntity[];
    isLoading: boolean;
    error: string | null;
    getSourceInfos: (resource: Resource) => ISourceInfo[];

    system: SourceType;
    onBack: () => void;
    width: string;
    connection: IConnectionInfo;
    onImport: (connectionId: string, maps: IImportEntityMap[]) => void;
};

type StateProps = {
    maps: ImportMap[];
    resources: Resource[];
    isImporting: boolean;
    isLoading: boolean;
    isFinished: boolean;
    failedCount: number;
    totalCount: number;
}
type ActionsProps = { importActions: typeof importActions, resourcesActions: typeof resourcesActions };

type Props = StateProps & OwnProps & ActionsProps;

const ArticleUrl = "https://help.ppm.express/111183-resource-management/importing-a-resource";

const getKey = (_: ImportMap) => _.externalId;

const ResourcesImportPanel = (props: Props) => {
    const [selectedCount, setSelectedCount] = React.useState(0);
    const selection = React.useMemo(() => {
        const selection: Selection<ImportMap> = new Selection<ImportMap>({
            getKey: getKey,
            onSelectionChanged: () => setSelectedCount(selection.getSelectedCount()),
            canSelectItem: item => item.status !== ImportStatus.Linked
        });
        return selection;
    }, []);

    React.useEffect(() => {
        if (!props.isLoading) {
            const { entities, resources, connection, getSourceInfos } = props;
            const importStates = buildImportStates(entities, resources, connection.id, getSourceInfos);
            props.importActions.setResourcesImportMaps(importStates);
        }
    }, [props.isLoading])

    React.useEffect(() => {
        props.importActions.clearResourcesImportInfo();
        props.resourcesActions.loadResources();
        return () => {
            props.importActions.clearResourcesImportInfo();
            props.importActions.setResourcesImportMaps([]);
        }
    }, []);

    React.useEffect(() => {
        if (!props.isImporting) {
            selection.setAllSelected(false);
            setSelectedCount(0);
        }
    }, [props.isImporting]);

    const onImport = React.useCallback(
        () => {
            const maps = selection.getSelection().map(_ => ({
                entityId: _.externalId,
                resourceId: _.resource.id,
                resourceName: _.resource.name
            }));

            props.onImport(props.connection.id, maps);
        },
        [props.connection.id, props.onImport, selection]);

    const clearMessage = React.useCallback(() => props.importActions.clearResourcesImportInfo(), []);
    const message = React.useMemo(() =>
        props.isFinished
            ? props.failedCount
                ? {
                    type: MessageBarType.error,
                    text: `Failed to import ${getResourceTitle(props.failedCount)}. Successfuly imported ${getResourceTitle(props.totalCount - props.failedCount)}`,
                    clearMessage: clearMessage
                }
                : {
                    type: MessageBarType.success,
                    text: `You imported ${getResourceTitle(props.totalCount)}`,
                    clearMessage: clearMessage
                }
            : undefined,
        [props.isFinished]);

    return <Panel
        className="import import-panel"
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth={props.width}
        onRenderFooterContent={() => <Footer
            selectedCount={selectedCount}
            onImport={onImport}
            onBack={props.onBack}
            disabled={props.isImporting}
        />}
        onRenderHeader={() => <ImportHeader
            connection={props.connection}
            system={props.system}
            entityName="Resources"
            articleUrl={ArticleUrl}
        />}
        onDismiss={props.onBack}
        isOpen={true}>
        {
            props.error
                ? <div className="error-message">{props.error}</div>
                : props.isLoading
                    ? <Spinner />
                    : <ResourceImportGrid
                        {...props}
                        selection={selection}
                        selectedCount={selectedCount}
                        getKey={getKey}
                        message={message}
                    />
        }
    </Panel>;
}

const Footer = React.memo((props: { disabled: boolean, selectedCount: number; onImport: () => void, onBack: () => void }) => {
    return <div className="commands">
        <PrimaryButton text="Import Resources" onClick={props.onImport} disabled={!props.selectedCount || props.disabled} />
        <DefaultButton text="Cancel" onClick={props.onBack} disabled={props.disabled} />
    </div>;
});

const getResourceTitle = (count: number) => {
    return count > 1 ? `${count} Resources` : `${count} Resource`;
}

const buildImportStates = (entities: IImportEntity[], existResources: Resource[], connectionId: string, getSourceInfos: (resource: Resource) => ISourceInfo[]): ImportMap[] => {
    if (!entities.length) {
        return [];
    }

    const resources = groupResources(existResources, connectionId, getSourceInfos);
    return entities
        .map(_ => ({
            externalId: _.id,
            externalName: _.displayName,
            externalEmail: _.email,
            isInProcessing: false,
            ...getMappedResource(_, resources, connectionId)
        }))
        .sort((a, b) => {
            if (a.status === b.status) {
                return a.externalName.localeCompare(b.externalName);
            }

            return a.status > b.status ? 1 : -1;
        });
}

const getMappedResource = (entity: IImportEntity, resources: ResourceGroups, connectionId: string) => {
    const byId = resources.linked[getId(entity.id, connectionId)];
    if (byId) {
        return {
            resource: toDto(byId),
            status: ImportStatus.Linked
        }
    }

    const byMatch = resources.notLinked[getMatchKey(entity.displayName, entity.email)]
    if (byMatch?.length) {
        return {
            resource: toDto(byMatch[0]),
            status: ImportStatus.Matched
        }
    }

    return {
        resource: { name: entity.displayName },
        status: ImportStatus.New
    }
}

const getId = (sourceId: string, connectionId: string) => {
    return `${connectionId}_${sourceId}`;
}

type ResourceGroups = {
    linked: Dictionary<Resource>;
    notLinked: Dictionary<Resource[]>;
}

const groupResources = (resources: Resource[], connectionId: string, getSourceInfos: (resource: Resource) => ISourceInfo[]) => {
    const result: ResourceGroups = {
        linked: {},
        notLinked: {}
    }

    for (const resource of resources) {
        const sourceInfo = getSourceInfos(resource).find(_ => _.connectionId === connectionId);
        if (!sourceInfo) {
            const key = getMatchKey(resource.attributes.Name, resource.attributes.Email);
            if (result.notLinked[key]) {
                result.notLinked[key].push(resource);
            } else {
                result.notLinked[key] = [resource];
            }
        } else {
            const id = getId(sourceInfo.sourceId, sourceInfo.connectionId);
            result.linked[id] = resource;
        }
    }

    return result;
}

function getMatchKey(name: string, email?: string) {
    return `${toMapKey(email)}|${toMapKey(name)}`;
}

function toMapKey(value?: string) {
    return value?.toLowerCase() || "";
}

function toDto(resource: Resource): IMappedResource {
    return {
        id: resource.id,
        name: resource.attributes.Name
    };
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const byId = state.resources.byId;
    return {
        maps: state.import.resources.data,
        isImporting: state.import.resources.isImporting,
        isFinished: state.import.resources.isFinished,
        totalCount: state.import.resources.totalCount,
        failedCount: state.import.resources.failedCount,
        resources: state.resources.allIds.map(_ => byId[_]),
        isLoading: (ownProps && ownProps.isLoading) || state.resources.isListLoading
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        importActions: bindActionCreators(importActions, dispatch),
        resourcesActions: bindActionCreators(resourcesActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesImportPanel);