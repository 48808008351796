import * as React from 'react';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react';

interface Props {
    disabled: boolean;
    label: string;
    times: any[];
    onChange: (times: any[]) => void;
    limit?: number;
    multiSelect?: boolean;
}

interface State {
    times: any[];
}

const noSelectionKey = "-1";
export default class TimeSelector extends React.Component<Props, State> {
    private _timeOptions: { key: string, text: string, disabled?: boolean }[];

    public componentWillMount() {
        if (this.props.times) {
            this.setState({ times: this._sortTimes(this.props.times) });
        }
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (this.props.times != nextProps.times) {
            this.setState({ times: this._sortTimes(nextProps.times) });
        }
    }

    constructor(props: Props) {
        super(props);
        this._timeOptions = this._buildTimeOptions();
    }

    public render() {
        const { disabled, label, times, limit, multiSelect } = this.props;
        let options = multiSelect
            ? {
                multiSelect: true,
                selectedKeys: times,
                options: this._timeOptions.filter(_ => _.key !== noSelectionKey).map((_) => { return { ..._, disabled: times.indexOf(_.key) == -1 && limit !== undefined && limit <= times.length } })
            }
            : {
                selectedKey: times.length > 0 ? times[0] : noSelectionKey,
                options: this._timeOptions
            }
        return <Dropdown
            {...options}
            disabled={disabled}
            label={label}
            calloutProps={{ calloutMaxHeight: 200 }}
            onChange={this._onChange}
            placeHolder="Select time..."
        />
    }

    private _onChange = (ev: React.FormEvent<HTMLElement>, item: IDropdownOption) => {
        if (this.props.multiSelect) {
            let times = this._onTimesChanged(item, [...this.state.times]);
            this.setState({ times });
            this.props.onChange(times)
        } else {
            let times = item.key == noSelectionKey ? [] : [item.key];
            this.setState({ times });
            this.props.onChange(times)
        }
    }

    private _onTimesChanged = (item: IDropdownOption, times: any[]): any[] => {
        if (item.selected) {
            times.push(item.key);
            times = this._sortTimes(times);
        } else {
            const currIndex = times.indexOf(item.key);
            if (currIndex > -1) {
                times.splice(currIndex, 1);
            }
        }
        return times;
    }

    private _sortTimes = (times: any[]): any[] => {
        return this._timeOptions.filter(_ => times.indexOf(_.key) != -1).map(_ => _.key);
    }

    private _buildTimeOptions = (): { key: string, text: string }[] => {
        const times = [{ text: "Select time...", key: noSelectionKey }];
        for (let hour = 0; hour < 24; hour++) {
            for (let min = 0; min < 60; min += 15) {
                const date = new Date(new Date().setHours(hour, min, 0, 0));
                const dateUTC = new Date(new Date().setHours(date.getUTCHours(), date.getUTCMinutes(), 0, 0));
                const hours = date.getHours();
                times.push({
                    text: `${hours == 0 ? 12 : hours > 12 ? (hours - 12) : hours}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()} ${hours >= 12 ? 'PM' : 'AM'}`,
                    key: `${dateUTC.getHours() < 10 ? '0' : ''}${dateUTC.getHours()}:${dateUTC.getMinutes() < 10 ? '0' : ''}${dateUTC.getMinutes()}:${dateUTC.getSeconds() < 10 ? '0' : ''}${dateUTC.getSeconds()}`
                });
            }
        }
        return times;
    }
}