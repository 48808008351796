import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/integration/SmartsheetStore";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { IConnectionsState } from "../../../store/integration/common";
import OAuthConnectionCard from "./OAuthConnectionCard";

type OwnProps = {}
type StateProps = { connections: IConnectionsState };
type Props = StateProps & OwnProps & typeof actionCreators;
const SmartsheetConnectionCard = (props: Props) => <OAuthConnectionCard
    system={SourceType.Smartsheet}
    newConnectionUrl={"/api/integration/smartsheet/auth"}
    connections={props.connections.data}
    onReceivedConnectionData={props.loadConnections}
/>;
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.smartsheet.connections }
}
export default connect(mapStateToProps, actionCreators)(SmartsheetConnectionCard);