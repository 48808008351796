import * as React from 'react';
import EntityCreation from "../common/extensibleEntity/EntityCreation";
import { EntityType, ITimeframe } from '../../entities/common';
import { Toggle } from 'office-ui-fabric-react';
import { FormatDate } from '../utils/common';

interface OwnProps {
    onDismiss: () => void;
    entityType: string;
    onCreate: (name: string, layoutId: string, autoAllocate: boolean, startDate?: Date, finishDate?: Date) => void;
    timeframe: ITimeframe;
}
type Props = OwnProps;

const ResourceCreationInResourcePlanControl = (props: Props) => {
    const [ autoAllocate, setAutoAllocate ] = React.useState(false);
    const { timeframe, entityType, onDismiss, onCreate } = props;
    return <EntityCreation
        entityType={EntityType.Resource}
        hideSectionSelector={true}
        onDismiss={onDismiss}
        onSave={(name, layoutId) => onCreate(name, layoutId, autoAllocate, timeframe.start, timeframe.end.getBeginOfDay())}>
        <p>Allocate new resource for the current {entityType} on {FormatDate(timeframe.start)} - {FormatDate(timeframe.end)}</p>
        <Toggle className="two-column"
            label="Allocate"
            checked={autoAllocate}
            onChange={(e, checked) => setAutoAllocate(!!checked)}
            onText='Yes'
            offText='No' />
    </EntityCreation>;
}

export default ResourceCreationInResourcePlanControl;