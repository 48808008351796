import * as React from 'react';

type Props = { 
    text?: string;
    onErrorRender?: (error: string) => JSX.Element | undefined;
}

const InputErrorMessage = ({text, onErrorRender}: Props) => {
    if (!text) {
        return (null);
    }
    if(onErrorRender){
        return onErrorRender(text) || null;
    }
    return <div className="error-message">{text}</div>;
}
export default InputErrorMessage;