import * as React from 'react';
import { Icon, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { KeyResult, OKRValueType } from "../../store/ObjectivesListStore";
import { OKRValueFormatter } from '../common/formatters/OkrFormatters';
import { ControlSpiner } from '../common/Spinner';
import EntityName from '../views/list/columns/EntityName';
import { EntityInfo } from '../common/inputs/EntityPicker';

type Props = {
    entity: KeyResult;
    projects: EntityInfo[];
    isLoading: boolean;
    valueType: OKRValueType;
}

export const KeyResultContributingProjects = (props: Props) => {
    const otherProjectsCount = props.entity.projectIds.length - props.projects.length;

    return <>
        <div className="history-header align-center">
            <h2><Icon iconName="PPMXProject" className="card-icon" /> Contributing projects</h2>
        </div>
        {props.isLoading
            ? <ControlSpiner isLoading={true} />
            : props.entity.projectIds.length
                ? <>
                    {otherProjectsCount > 0 && <MessageBar messageBarType={MessageBarType.warning}>
                        There {otherProjectsCount > 1 ? "are" : "is"} {otherProjectsCount} other project{otherProjectsCount > 1 ? "s" : ""} contributing to this Key Result that you have no access.
            </MessageBar>}
                    <div className="contributing-projects">
                        {props.projects.map(_ => <ProjectRow key={_.id} project={_} valueType={props.valueType} />)}
                    </div>
                </>
                : <span>No projects contribute to current Key Result</span>}
    </>;
}

const ProjectRow = (props: { project: EntityInfo; valueType: OKRValueType; }) => {
    return <div className="project-row">
        <div className="main-info">
            <EntityName
                name={props.project.name}
                imageClassName="proj-logo"
                imageId={props.project.attributes.ImageId}
                url={`/project/${props.project.id}`} />
        </div>
        <div className="current-value">
            <OKRValueFormatter
                type={props.valueType}
                value={props.project.attributes.CurrentValue} />
        </div>
    </div>;
}