import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../store';
import * as ArchivedProjectsListStore from '../../store/ArchivedProjectsListStore';
import { ProjectAttrs, ProjectInfo } from '../../store/ProjectsListStore';
import { IDeletionResult } from '../../store/services/storeHelper';
import {
    CheckboxVisibility, DetailsListLayoutMode, IColumn, IContextualMenuItem, IDetailsHeaderProps,
    IDialogContentProps, IRenderFunction, ITooltipHostProps, MessageBar, MessageBarType, ScrollablePane, ScrollbarVisibility, Selection, Sticky, StickyPositionType, TooltipHost
} from 'office-ui-fabric-react';
import { ppmxTaskConnectionId, SortDirection, SourceInfo } from "../../entities/common";
import RemoveDialog from '../common/RemoveDialog';
import { SortService } from "../../services/SortService";
import Spinner from "../common/Spinner";
import { UserState } from "../../store/User";
import { SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';
import { urlParamsBuilder } from '../../entities/Subentities';
import DetailsListWrapper from '../common/DetailsListWrapper';
import { ViewService, IListViewColumn } from "../../services/ViewService";
import ResourceFormatter from '../common/formatters/ResourceFormatter';
import { DateFormatter } from '../common/formatters/DateFormatter';
import { SearchResultsNotFoundPlaceholder, SectionControlPlaceholder } from '../common/sectionsControl/SectionPlaceholder';
import { nameof } from '../../store/services/metadataService';
import { canCreate } from '../../store/permissions';
import { RowMenuColumn } from '../common/extensibleEntity/RowMenuColumn';
import { RowMenu } from '../common/extensibleEntity/RowMenu';
import SelectionModeSwitchableCommandBar from '../common/SelectionModeSwitchableCommandBar';
import { Field, FieldType } from '../../entities/Metadata';
import { SearchBox, SearchValue } from '../common/SearchBox';
import { toDictionaryById } from '../utils/common';
import { SearchFieldService } from '../common/SearchFieldService';

type ActionProps = {
    archivedProjectsListStore: typeof ArchivedProjectsListStore.actionCreators;
}
type StateProps = {
    projects: ProjectInfo[];
    isLoading: boolean;
    isListLoading: boolean;
    deletionResult: IDeletionResult[] | undefined;
    user: UserState;
};
export type Props = StateProps & ActionProps & RouteComponentProps<{}>;

type State = {
    sortedProjects: ProjectInfo[];
    projectsToRemove: ProjectInfo[];
    selectedCount: number;
    sortBy: SortBy;
    columns: IColumn[];
    search?: SearchValue;
};

type SortBy = {
    fieldName: string;
    direction: SortDirection;
}

class ArchivedProjectsList extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);

        const sortBy: SortBy = {
            fieldName: "Name",
            direction: SortDirection.ASC
        };

        this.state = {
            selectedCount: 0,
            projectsToRemove: [],
            sortedProjects: props.projects,
            sortBy: sortBy,
            columns: this._buildColumns(sortBy),
        };

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedCount: this._selection.getSelectedCount() });
            }
        });
    }

    componentWillMount() {
        this.props.archivedProjectsListStore.requestProjects();
    }

    componentWillReceiveProps(props: Props) {
        if (this.props.projects !== props.projects) {
            this.setState({
                sortedProjects: this._sort(props.projects, this.state.sortBy)
            })
        }
    }

    private _isItemVisible = (item: ProjectInfo): boolean => {
        const { search } = this.state;

        if (search?.searchText && !SearchFieldService.searchInProperties(search, item)) {
            return false;
        }

        return true;
    }

    private _getFilteredEntities = () => this.state.sortedProjects.filter(this._isItemVisible);

    public render() {
        if (this.props.isLoading || this.props.isListLoading) {
            return <Spinner />;
        }

        const { deletionResult } = this.props;
        const { projectsToRemove, selectedCount, columns } = this.state;

        const filteredItems = this._getFilteredEntities();

        return <div className="entities-screen archived-projects">
            <div className="header">
                <div className="align-center first-row">
                    <div className="entity-name">Archived Projects</div>
                </div>
            </div>
            <div className="entities-list">
                <div className="entities-list-header">
                    <SelectionModeSwitchableCommandBar
                        items={[]}
                        farItems={this._getFarCommands()}
                        selectionMode={{
                            enabled: selectedCount > 0,
                            items: [
                                {
                                    key: 'delete',
                                    text: "Delete",
                                    iconProps: { iconName: "Delete" },
                                    className: "more-deleteButton",
                                    onClick: () => this.setState({ projectsToRemove: this._selection.getSelection() as ProjectInfo[] }),
                                }
                            ],
                            onCancel: () => this._selection.setAllSelected(false),
                            selectedCount,
                        }}
                    />
                </div>
                {!this.props.projects.length && <SectionControlPlaceholder
                    iconName="Archive"
                    title="Archived Projects page is empty"
                    description="You don't have any archived projects yet"
                />}
                {this.props.projects.length && !filteredItems.length && <SearchResultsNotFoundPlaceholder />}
                {this.props.projects.length && filteredItems.length && <div className="entities-list-body list-container">
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <DetailsListWrapper
                            layoutMode={DetailsListLayoutMode.justified}
                            setKey='set'
                            items={filteredItems}
                            columns={columns}
                            selection={this._selection}
                            checkboxVisibility={CheckboxVisibility.always}
                            onRenderDetailsHeader={this._onRenderDetailsHeader}
                            onColumnHeaderClick={this._onColumnHeaderClick}
                        />
                    </ScrollablePane>
                </div>
                }
            </div>
            {!!projectsToRemove.length && <RemoveDialog
                onClose={() => this.setState({ projectsToRemove: [] })}
                onComplete={() => this._onRemove(projectsToRemove.map(_ => _.id))}
                dialogContentProps={this._getRemoveDialogContent(projectsToRemove)}
                confirmButtonProps={{ text: "Delete" }}>
            </RemoveDialog>}
            {deletionResult && <RemoveDialog
                onClose={() => this.props.archivedProjectsListStore.dismissDeletionResult()}
                confirmButtonProps={{ text: "Got it" }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {deletionResult.length > 1 && deletionResult.some(_ => !_.isDeleted) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    Failed to delete the following projects:
                    <ul>
                        {deletionResult.filter(_ => !_.isDeleted).map(_ => <li key={_.id}>{_.name}</li>)}
                    </ul>
                    Please check if you have necessary permissions.
                </MessageBar>}
            </RemoveDialog>}
        </div>;
    }

    private fieldsToSearch: Field[] = [
        { id: 'name', name: 'Name', label: "Name", type: FieldType.Text } as any as Field,
        { id: 'Manager', name: 'Manager', label: "Manager", type: FieldType.Resource } as any as Field,
        { id: 'startDate', name: 'StartDate', label: "Start Date", type: FieldType.Date } as any as Field,
        { id: 'finishDate', name: 'FinishDate', label: "Finish Date", type: FieldType.Date } as any as Field,
        { id: 'archivedDate', name: 'archivedDate', label: "Archived Date", type: FieldType.Date } as any as Field
    ];

    private _getFarCommands = (): IContextualMenuItem[] => {
        const { search } = this.state;
        return [
            {
                key: 'search',
                onRender: () => <SearchBox
                    viewColumns={this.fieldsToSearch.map(_ => _.id)}
                    fieldsMap={toDictionaryById(this.fieldsToSearch)}
                    value={search}
                    onSearch={(value) => this.setState({ search: value })}
                />
            }
        ];
    }
    private _onRemove = (ids: string[]) => {
        if (ids.length) {
            this.props.archivedProjectsListStore.removeProjects(ids)
        }
        this._selection.setAllSelected(false);
    }

    private _getRemoveDialogContent(toRemove: ProjectInfo[]) {
        if (toRemove.length === 1) {
            return {
                title: "Delete project",
                subText: `Are you sure you want to delete project "${toRemove[0].attributes.Name}"?`
            }
        }

        return {
            title: "Delete projects",
            subText: toRemove.length
                ? `Are you sure you want to delete selected projects (${toRemove.length} items)?`
                : undefined
        }
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1) {
            return deletionResult[0].isDeleted
                ? {
                    title: "Project deletion is complete",
                    subText: `Project "${deletionResult[0].name}" was deleted successfully.`
                }
                : {
                    title: "Unable to delete project. Please check if you have necessary permissions",
                    subText: deletionResult[0].message
                }
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return {
            title: "Projects deletion is complete",
            subText: deleted.length
                ? `Selected projects (${deleted.length} items) were deleted successfully.`
                : undefined
        };
    }

    private _buildColumns = (sortBy: SortBy): IColumn[] => {
        return [
            {
                key: "name",
                fieldName: "Name",
                name: "Name",
                iconName: "PPMXProject",
                headerClassName: "with-icon",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: any, index?: number, column?: IColumn, defaultRender?: () => JSX.Element) => {
                    const component = ViewService.createListColumn<IListViewColumn<ProjectInfo>>('archivedProject/Name');
                    return <RowMenuColumn
                        onItemMenuRender={() => <RowMenu item={item} commands={this._getItemCommands(item)} />} >
                        {React.createElement(component, { entity: item })}
                    </RowMenuColumn>;
                }
            },
            {
                key: "Manager",
                fieldName: "Manager",
                name: "Manager",
                minWidth: 220,
                isResizable: true,
                onRender: (item: ProjectInfo) => <ResourceFormatter resource={item.attributes.Manager} />
            },
            {
                key: "startDate",
                fieldName: "StartDate",
                name: "Start Date",
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: ProjectInfo) => <DateFormatter value={item.attributes.StartDate} />
            },
            {
                key: "finishDate",
                fieldName: "FinishDate",
                name: "Finish Date",
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: ProjectInfo) => <DateFormatter value={item.attributes.FinishDate} />
            },
            {
                key: "archivedDate",
                fieldName: "archivedDate",
                name: "Archived Date",
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: ProjectInfo) => <DateFormatter value={item.archivedDate} />
            }
        ].map(_ => ({
            ..._,
            isSorted: _.fieldName === sortBy.fieldName,
            isSortedDescending: _.fieldName === sortBy.fieldName && sortBy.direction === SortDirection.DESC
        }));
    }

    private _getItemCommands = (entity: ProjectInfo): IContextualMenuItem[] => {
        const canClone = canCreate(this.props.user.permissions.project) && (entity.isEditable || entity.isArchived);
        return [
            this._getOpenScheduleItem(entity),
            {
                key: 'view',
                name: 'View',
                iconProps: { iconName: "View" },
                onClick: () => this.props.history.push(`/archivedproject/${entity.id}`)
            },
            {
                key: 'clone',
                name: 'Clone',
                title: "A project will be duplicated, using a limited dataset from an existing archived project.",
                iconProps: { iconName: "Copy" },
                disabled: this.props.isLoading || !canClone,
                onClick: () => {
                    this.props.archivedProjectsListStore.cloneProject(entity.id)
                }
            },
            {
                key: 'delete',
                name: 'Delete',
                iconProps: { iconName: "Delete", style: { color: 'red' } },
                disabled: this.props.isLoading,
                style: { color: (this.props.isLoading ? 'initial' : 'red'), backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset') },
                onClick: () => this.setState({ projectsToRemove: [entity] })
            }
        ];
    }

    private _getOpenScheduleItem(project: ProjectInfo): IContextualMenuItem {
        const schedulableOrNotCollaborativeSourceInfos = project.sourceInfos
            .filter(_ => !SourceType_.collaborative.includes(_.type) && SourceInfo.isSyncable(_));

        const menuItems: IContextualMenuItem[] = [
            this._buildScheduleMenuItem(SourceType.Ppmx, ppmxTaskConnectionId, project.id),
            ...schedulableOrNotCollaborativeSourceInfos.map(_ => this._buildScheduleMenuItem(_.type, _.connectionId, project.id))
        ];

        return {
            key: 'open-schedule',
            name: 'Open Tasks',
            subMenuProps: { items: menuItems },
            iconProps: { iconName: 'PPMXProjectSchedule' },
        }
    }

    private _buildScheduleMenuItem(sourceType: SourceType, connectionId: string, projectId: string): IContextualMenuItem {
        return {
            key: `${projectId}-${SourceType_.getName(sourceType)}`,
            name: SourceType_.getName(sourceType),
            iconProps: { iconName: SourceType_.getIconName(sourceType), className: 'open-schedule-menu-icon' },
            onClick: () => this._getTasksUrl(connectionId, projectId)
        }
    }

    private _getTasksUrl(connectionId: string, projectId: string) {
        const { location } = this.props;
        const query = new URLSearchParams(location.search);
        query.set(urlParamsBuilder.connectionId, connectionId);
        this.props.history.push(`/archivedproject/${projectId}/tasks?${query.toString()}`);
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private _onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
        if (!column) {
            return;
        }

        const sortBy = SortService.getColumnOrderBy(column, this.state.sortBy);
        this.setState({
            sortBy,
            sortedProjects: this._sort(this.props.projects, sortBy),
            columns: this._buildColumns(sortBy)
        });
    }

    private _sort(projects: ProjectInfo[], sortBy: SortBy) {
        return projects.map(_ => _).sort(SortService.getSimpleComparer(sortBy, (item: ProjectInfo, fieldName) =>
            fieldName === nameof<ProjectInfo>("archivedDate")
                ? item.archivedDate
                : fieldName === nameof<ProjectAttrs>("Manager")
                    ? item.attributes.Manager.map(_ => _.fullName).concat()
                    : item.attributes[fieldName]));
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps {
    return {
        isLoading: state.archivedProjectsList.isLoading,
        isListLoading: state.archivedProjectsList.isListLoading,
        projects: state.archivedProjectsList.allIds.map(_ => state.archivedProjectsList.byId[_]),
        deletionResult: state.archivedProjectsList.deletionResult,
        user: state.user
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        archivedProjectsListStore: bindActionCreators(ArchivedProjectsListStore.actionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(ArchivedProjectsList);