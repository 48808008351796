import * as React from "react";
import * as Metadata from '../../entities/Metadata';
import { FieldActionTypes, ISettingsEditComponent, IBaseFieldInfo } from "./FieldPanel";
import { TextField } from "office-ui-fabric-react";
import { Dictionary } from "../../entities/common";
import { Validator, IValidator } from "../../validation";
import LabellableComponent from "../common/LabellableComponent";

type Props = {
    settings: Dictionary<any>;
    onChange: (isValid: boolean) => void;
    actionType: FieldActionTypes;
}

type State = {
    prefix: string;
    suffix: string;
    index: string;
}

const IdentifierIndexMaxLength = 4;
const IdentifierSuffixPrefixMaxLength = 10;

export class IdentifierSettingsEdit extends React.Component<Props, State> implements ISettingsEditComponent {
    private validators: {
        prefix: IValidator,
        suffix: IValidator,
        index: IValidator,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            prefix: props.settings.prefix,
            suffix: props.settings.suffix,
            index: props.settings.index
        };
        this.validators = this._buildValidators();
        this._isValid();
    }

    public render() {
        const { prefix, index, suffix } = this.state;
        return <div className="panel-area">
            <div className="grid-item">
                <LabellableComponent label="Prefix" className="field-container"
                    description={`Define the prefix part for identifier that will be auto generated at entity creation.`}>
                    <TextField
                        value={prefix}
                        onChange={(e, _) => _ !== undefined && this._setSettings({ prefix: _ })}
                        errorMessage={this.validators.prefix.getErrorMessage(prefix)}
                    />
                </LabellableComponent>
            </div>
            <div className="grid-item">
                <LabellableComponent label="Starting Number" className="field-container"
                    description={`Define the numerical part for identifier that will be auto generated at entity creation. \
This number will be auto incremented after each created entity. If Starting Number is left blank, identifier will not be auto generated.`}>
                    <TextField
                        value={index}
                        onChange={(e, _) => _ !== undefined && this._setSettings({ index: _ })}
                        errorMessage={this.validators.index.getErrorMessage(index)}
                    />
                </LabellableComponent>
            </div>
            <div className="grid-item">
                <LabellableComponent label="Suffix" className="field-container"
                    description={`Define the suffix part for identifier that will be auto generated at entity creation.`}>
                    <TextField
                        value={suffix}
                        onChange={(e, _) => _ !== undefined && this._setSettings({ suffix: _ })}
                        errorMessage={this.validators.suffix.getErrorMessage(suffix)}
                    />
                </LabellableComponent>
            </div>
        </div>;
    }

    private _buildValidators() {
        return {
            prefix: Validator.new().maxLength(IdentifierSuffixPrefixMaxLength).build(),
            suffix: Validator.new().maxLength(IdentifierSuffixPrefixMaxLength).build(),
            index: Validator.new().maxLength(IdentifierIndexMaxLength).int32().min(0).build()
        };
    }

    private _setSettings<K extends keyof State>(changes: Pick<State, K>) {
        this.setState({ ...changes }, () => {
            this.validators = this._buildValidators();
            const isValid = this._isValid();
            this.props.onChange(isValid);
        });
    }

    private _isValid() {
        const { prefix, suffix, index } = this.state;
        return Validator.isValid(this.validators as Dictionary<IValidator>, {
            prefix,
            suffix,
            index
        });
    }

    public save(fieldInfo: Metadata.FieldInfo) {
        fieldInfo.settings = {
            ...fieldInfo.settings,
            ...this._getSettings()
        }
    }

    public update(fieldInfo: IBaseFieldInfo) {
        fieldInfo.settings = {
            ...fieldInfo.settings,
            ...this._getSettings()
        };
    }

    private _getSettings() {
        const { prefix, suffix, index } = this.state;
        return {
            prefix,
            suffix,
            index: index?.trim()
        }
    }
}