import * as React from 'react';
import { DefaultButton, Callout, DirectionalHint, Icon } from 'office-ui-fabric-react';
import { abbreviateNumber } from '../../utils/common';

type Props = { hasValue: boolean, label: string, getLabelExtraDetails: () => { suffix: string, count: number } };
export const BaseFilterAttribute = (props: React.PropsWithChildren<Props>) => {
    const _button = React.useRef(null);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const { hasValue, label, getLabelExtraDetails } = props;
    const { suffix, count } = getLabelExtraDetails();
    return <>
        <DefaultButton className={`filter-attribute ${hasValue ? "with-selected-value" : ""} align-center`}
            componentRef={_ => _button.current = (_ as any)?._buttonElement?.current}
            onClick={() => setShowContent(!showContent)}
        >
            <div className="overflow-text label">{label}{suffix ? `: ${suffix}` : ''}</div>
            {count > 1 && <div className="counter">{`+${abbreviateNumber(count - 1)}`}</div>}
            <Icon iconName="ChevronDownSmall" className="filter-attribute-icon" />
        </DefaultButton>
        {showContent && <Callout
            target={_button.current}
            calloutWidth={300}
            isBeakVisible={false}
            gapSpace={5}
            preventDismissOnScroll={true}
            preventDismissOnResize={true}
            directionalHint={DirectionalHint.bottomLeftEdge}
            directionalHintForRTL={DirectionalHint.bottomRightEdge}
            onDismiss={() => setShowContent(false)}
        >
            <div style={{ padding: 10 }}>
                {props.children}
            </div>
        </Callout>}
    </>;
}