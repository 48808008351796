import React from 'react';
import * as ResourcesListStore from '../../../store/ResourcesListStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { MessageBar, MessageBarType, MessageBarButton } from 'office-ui-fabric-react';
import { WorkWeekSettings } from './WorkWeekSettings';
import { CalendarDataSet } from '../../../store/CalendarStore';
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';

type OwnProps = {
    resource: ResourcesListStore.Resource;
};

type StoreProps = {
    user: UserState;
    globalCalendar: CalendarDataSet;
};

type ActionProps = {
    resourcesActions: typeof ResourcesListStore.actionCreators;
};

type Props = OwnProps & StoreProps & ActionProps;

const WorkWeek = (props: Props) => {
    const { resource, user, globalCalendar, resourcesActions } = props;
    const personal = !!resource.usage?.calendar.workDayExpectedHrs;
    const canEdit = contains(user.permissions.common, CommonOperations.ResourceManage);
    return <>
         <MessageBar messageBarType={MessageBarType.info} isMultiline={false}
            actions={<MessageBarButton
                disabled={!canEdit}
                onClick={() => resourcesActions.updateResourceCalendar(resource.id, { workDayExpectedHrs: personal ? null : globalCalendar.workDayExpectedHrs })}>
                Use {resource.usage?.calendar.workDayExpectedHrs ? 'global' : 'personal'} settings</MessageBarButton>}>
            {resource.usage?.calendar.workDayExpectedHrs
                ? "The personal work week settings are applied to this resource. You can apply the tenant-wide global work week settings by switching to common settings mode."
                : "The global work week settings are applied to this resource. You can define the resource's individual work week settings by switching to personal settings mode."}
        </MessageBar>
        <WorkWeekSettings
            disabled={!resource.usage?.calendar.workDayExpectedHrs || !canEdit}
            workDayExpectedHrs={resource.usage?.calendar.workDayExpectedHrs ?? globalCalendar.workDayExpectedHrs}
            save={workDayExpectedHrs => resourcesActions.updateResourceCalendar(resource.id, { workDayExpectedHrs })}
        />
    </>;
};

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    return {
        user: state.user,
        globalCalendar: state.calendar
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        resourcesActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(WorkWeek);