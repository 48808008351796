import * as React from 'react';
import * as Metadata from '../../entities/Metadata';
import { IconButton, ActionButton } from 'office-ui-fabric-react/lib/Button';
import { IComboBoxOption, ComboBox, IComboBox } from 'office-ui-fabric-react/lib/ComboBox';
import { ICommand, ItemCreation } from './ItemCreation';
import { Validator } from "../../validation";
import { Toggle, Label, DirectionalHint, TooltipHost, TooltipDelay, Icon } from 'office-ui-fabric-react';
import { FilterAttribute, BaseFilterValue } from '../../entities/Metadata';
import { isAdminViewer, isInReadonlyMode } from "../../store/User";
import { upperFirstSort } from '../utils/common';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import FieldOption from '../field/FieldOption';

type OwnProps<T> = {
    filter: Metadata.IFilter<Metadata.BaseFilterValue>;
    canManageConfiguration: boolean;
    fields: Metadata.Field[];
    nonFilterableFields?: string[];
    entityFilterHelper: Metadata.IEntityFilterHelper<T>;
    onFilterChanged: (filter: Metadata.IFilter<Metadata.BaseFilterValue>) => void;
    onSave: () => void;
    onCopy: (copy: Metadata.IFilter<Metadata.BaseFilterValue>) => void;
    onDismiss: () => void;
}

type StateProps = {
    isReadonlyMode: boolean;
}

const validators = {
    filterName: Validator.new().required().build()
}

class FilterPanel<T> extends React.Component<OwnProps<T> & StateProps> {
    componentWillMount() {
        this.setState({
            isEditMode: false
        });
    }

    render() {
        const { filter, isReadonlyMode } = this.props;

        let commands: ICommand[] = [];
        let isInvalid = !validators.filterName.isValid(filter.name);
        commands.push({
            primary: true,
            text: `${filter.id != Metadata.NEW_ID ? 'Save' : 'Create'} Filter`,
            onClick: this.save,
            disabled: isInvalid || filter.isBuiltIn || isReadonlyMode
        });

        if (filter.id != Metadata.NEW_ID) {
            commands.push({
                iconName: "Copy",
                text: "Create a copy",
                onClick: this.saveAs,
                disabled: isInvalid || isReadonlyMode
            });
        }

        return <ItemCreation onDismiss={this.props.onDismiss}
            header={{
                text: `${filter.id != Metadata.NEW_ID ? 'Configure' : 'Create'} Filter`,
                secondaryText: "Set up conditions to filter the items in the view",
                nameEditorLabel: "Filter Name",
                onChanged: this.onChanged,
                validator: validators.filterName,
                value: filter.name,
                disableNameEditor: filter.isBuiltIn
            }}
            commands={commands}
            className="filter-panel">
            <div className="is-public-container">
                <div className="label">
                    <Label>Is Public</Label>
                    <IsPublicTooltip text={this.props.canManageConfiguration
                        ? "Public Filters are shared across whole organization and visible for all users. Private Filters are accessible by the creator only."
                        : "To create Public Filters, Manage Configuration permission is required."} />
                </div>
                <Toggle checked={filter.isPublic}
                    disabled={!this.props.canManageConfiguration || filter.isBuiltIn}
                    onChange={(e, checked: boolean) => this.props.onFilterChanged({ ...filter, isPublic: checked })}
                    styles={{ root: { marginBottom: 0 } }} />
            </div>
            <hr />
            <EditableFilter activeFilter={filter}
                buildItems={this.buildItems}
                getAdditionalItems={this.getAdditionalItems}
                onFilterChanged={this.props.onFilterChanged} />
        </ItemCreation>;
    }

    private onChanged = (newName: string) => {
        const { filter } = this.props;
        let newFilter = Object.assign({}, filter);
        newFilter.name = newName;
        this.props.onFilterChanged(newFilter);
    }

    private save = () => {
        this.props.onSave();
        this.props.onDismiss();
    }

    private saveAs = () => {
        const { filter } = this.props;

        const newFilter = Metadata.Filter.copy(filter);
        this.props.onCopy(newFilter);
    }

    private getAdditionalItems = (): { key: string, text: string }[] => {
        const allAttributes = this.props.entityFilterHelper.getFilterAttributes(this.props.fields, this.props.nonFilterableFields);
        const filter = this.props.filter;

        return allAttributes.filter((attribute: Metadata.FilterAttribute<Metadata.BaseFilterValue>) => {
            return !filter || filter.attributeNames.find(_ => _.type === attribute.type && _.name === attribute.name) === undefined;
        })
            .map((attr: Metadata.FilterAttribute<Metadata.BaseFilterValue>) => (
                {
                    key: JSON.stringify({ type: attr.type, name: attr.name }),
                    text: attr.displayName
                }
            ))
            .sort((a: { key: string, text: string }, b: { key: string, text: string }) => upperFirstSort(a.text, b.text));
    }

    private buildItems = (): (JSX.Element | null)[] => {
        const filter = this.props.filter;

        if (!filter) {
            return [];
        }

        const attributeNames = filter.attributeNames;
        const allAttributes = this.props.entityFilterHelper.getFilterAttributes(this.props.fields, this.props.nonFilterableFields);
        const attrs: Metadata.FilterAttribute<Metadata.BaseFilterValue>[] = [];
        attributeNames &&
            attributeNames.forEach(_ => {
                let attr = allAttributes.find(attr => _.type === attr.type && _.name === attr.name);
                if (attr !== undefined) {
                    attrs.push(attr);
                }
            });

        return attrs.map((attr: Metadata.FilterAttribute<Metadata.BaseFilterValue>) => this.buildFilterElement(attr, filter));
    }

    private buildFilterElement = (attr: Metadata.FilterAttribute<Metadata.BaseFilterValue>, filter: Metadata.IFilter<Metadata.BaseFilterValue>) => {
        const element = this.props.entityFilterHelper.helpersMap[attr.type].buildFilterElement(attr, filter, this._onFilterEditComplete);

        if (!element) {
            return element;
        }
        return (
            <div key={"filter-item-" + attr.name + "-" + attr.type}>
                <div className="align-center filter-attribute">
                    <div className="overflow-text">{attr.displayName}</div>
                    <FilterElementTooltip attr={attr} />
                    <IconButton
                        menuIconProps={{ iconName: 'More' }}
                        className='menu'
                        text='Actions'
                        menuProps={{
                            items: [{
                                key: 'remove-filter-' + attr.name,
                                name: 'Remove',
                                iconProps: { iconName: 'Delete' },
                                onClick: () => { this.removeFilterAttribute(attr.type, attr.name) }
                            }],
                            calloutProps: { className: "filter-attribute-menu" }
                        }} />
                </div>
                {element}
            </div>);
    }

    private removeFilterAttribute = (attrType: keyof Metadata.BaseFilterValue, attrName: string) => {
        const { filter, onFilterChanged } = this.props;
        if (!filter) {
            return;
        }
        let newAttributeNames: { name: string, type: keyof Metadata.BaseFilterValue }[] = [];
        const attributeNames = filter.attributeNames;
        attributeNames &&
            attributeNames.forEach(_ => {
                if (_.name !== attrName || _.type !== attrType) {
                    newAttributeNames.push(_);
                }
            });
        let newValue: Metadata.BaseFilterValue = {};
        filter.value &&
            Object.keys(filter.value).forEach((type: string) => {
                if (type !== attrType) {
                    newValue[type] = filter.value[type];
                } else {
                    newValue[type] = this.props.entityFilterHelper.helpersMap[type].removeFilterAttribute(attrName, filter.value[type]);
                }
            });

        let updatedFilter: Metadata.IFilter<Metadata.BaseFilterValue> = {
            ...filter,
            attributeNames: newAttributeNames,
            value: newValue
        };
        onFilterChanged(updatedFilter);
    }

    private _onFilterEditComplete = (attrType: Extract<keyof Metadata.BaseFilterValue, string>, attrName: string, value: any) => {
        const { filter, onFilterChanged } = this.props;
        const newValue = { ...filter.value };

        newValue[attrType] = this.props.entityFilterHelper.helpersMap[attrType].setAttributeValue(attrName, value, newValue[attrType]);

        const newFilter = { ...filter, value: newValue } as Metadata.IFilter<Metadata.BaseFilterValue>;

        onFilterChanged(newFilter);
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}
export default connect(mapStateToProps)(FilterPanel);

type EditableFilterProps = {
    activeFilter: Metadata.IFilter<Metadata.BaseFilterValue>;
    buildItems: () => (JSX.Element | null)[];
    getAdditionalItems: () => { text: string, key: string }[];
    onFilterChanged: (filter?: Metadata.IFilter<Metadata.BaseFilterValue>) => void;
}

class EditableFilter extends React.Component<EditableFilterProps> {
    render() {
        let elements = this.props.buildItems();

        let merged: JSX.Element[] = [];
        elements.forEach((value: JSX.Element | null, index: number, array) => {
            if (value) {
                merged.push(value);
            }

            if (index < array.length - 1)
                merged.push(<Divider key={"divider_" + index}>And</Divider>);
        });

        const additionalFields = this.props.getAdditionalItems();

        return (
            <div className="filter-items-container">
                {
                    [
                        merged,
                        merged.length > 0 && <hr className="divider" key="add-attibute-divider" />,
                        <AddFilterAttribute options={additionalFields} addAttribute={this.addAttribute} key="add-filter-attribute" />
                    ]
                }
            </div>
        );
    }

    private addAttribute = (attrType: keyof Metadata.BaseFilterValue, attrName: string) => {
        if (!this.props.activeFilter) {
            return;
        }
        let newAttributeNames = this.props.activeFilter.attributeNames
            ? [...this.props.activeFilter.attributeNames, { type: attrType, name: attrName }]
            : [{ type: attrType, name: attrName }];

        let updatedFilter: Metadata.IFilter<Metadata.BaseFilterValue> = Object.assign({}, this.props.activeFilter); //{ ...this.props.activeFilter/*, attributeNames: newAttributeNames */} as TFilterInfo;
        updatedFilter.attributeNames = newAttributeNames;
        this.props.onFilterChanged(updatedFilter);
    }
}

class AddFilterAttribute extends React.Component<{ options: IComboBoxOption[], addAttribute: (type: string, name: string) => void }, { isOpenAddFilter: boolean }> {
    componentWillMount() {
        this.setState({ isOpenAddFilter: false });
    }
    private comboBox: IComboBox | null = null;

    render() {
        return (
            [
                !this.state.isOpenAddFilter &&
                this.props.options.length > 0 &&
                <ActionButton iconProps={{ iconName: 'Add' }}
                    text="New Filter Attribute"
                    onClick={() => this.setState({ isOpenAddFilter: true })}
                    className="add-filter-attribute"
                    title="Add filter"
                    key="editable-filter-add-item-button" />,
                this.state.isOpenAddFilter &&
                <div key="editable-filter-add-item-dropdown">
                    <div className="align-center add-filter-attribute-title">
                        Additional filter item
                        <IconButton
                            className='menu'
                            onClick={() => this.setState({ isOpenAddFilter: false })}
                            menuIconProps={{ iconName: 'More' }}
                            menuProps={{
                                items: [{
                                    key: 'remove-add-filter-attrinbute',
                                    name: 'Hide',
                                    onClick: () => { this.setState({ isOpenAddFilter: false }) }
                                }],
                            }}
                        />
                    </div>
                    <ComboBox
                        allowFreeform={true}
                        autoComplete='on'
                        options={this.props.options}
                        onChange={(e: any, option?: IComboBoxOption) => {
                            if (option && option.key && typeof option.key == 'string') {
                                const keyObj = JSON.parse(option.key) as { name: string; type: string };
                                this.props.addAttribute(keyObj.type, keyObj.name);
                            }
                            this.setState({ isOpenAddFilter: false });
                        }}
                        useComboBoxAsMenuWidth={true}
                        onClick={() => this.comboBox?.focus(true)}
                        componentRef={el => this.comboBox = el}
                        key="editable-filter-add-item-combobox"
                        calloutProps={{ minPagePadding: 20 }}
                        onRenderOption={FieldOption}
                    />
                </div>
            ]
        );
    }
}

class FilterElementTooltip extends React.Component<{ attr: FilterAttribute<BaseFilterValue> }> {
    render() {
        const { attr } = this.props;

        if (attr.type !== "attributes" || (attr.value?.type !== Metadata.FieldType.Date && attr.value?.type !== Metadata.FieldType.DateTime)) {
            return null;
        }

        return (
            <TooltipHost content={"Support macroses @today, @today-3d, @today+1d"} delay={TooltipDelay.zero} hostClassName="tooltip" calloutProps={{ directionalHint: DirectionalHint.topCenter }}>
                <Icon iconName="Info" styles={{ root: { fontSize: 14, padding: "0 8px" } }} />
            </TooltipHost>
        );
    }
}

export class IsPublicTooltip extends React.Component<{ text: string }>{
    render() {
        return (
            <TooltipHost
                calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
                tooltipProps={{
                    onRenderContent: () => {
                        return (
                            <div style={{ padding: 10 }}>
                                {this.props.text}
                            </div>
                        );
                    }
                }}
                delay={TooltipDelay.zero}
                hostClassName="align-center">
                <Icon
                    onClick={(e) => e.stopPropagation()}
                    iconName="Info"
                    className='info-icon'
                />
            </TooltipHost>
        );
    }
}

const Divider: React.FunctionComponent<{}> = ({ children }) => {
    return (
        <div className="divider container">
            <div className="border" />
            <span className="content">
                {children}
            </span>
            <div className="border" />
        </div>
    );
};