import * as React from 'react';
import { IconButton, ActionButton } from 'office-ui-fabric-react';
import { ISourceInfo, SourceInfo, PpmxConnection } from '../../entities/common';
import { SourceTypeMap, SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';

interface Props {
    sourceInfos: ISourceInfo[];
    selected?: string;
    onItemSelected: (connectionId?: string) => void;
    onAddConnection: () => void;
    isConnectPanelAvailable: boolean;
}

export const getDefaultSelected = (sourceInfos: ISourceInfo[]): ISourceInfo | undefined => {
    const externalSourceInfos = getExternalSourceInfos(sourceInfos);
    return externalSourceInfos.length > 0
        ? externalSourceInfos[0]
        : PpmxConnection
}

const getExternalSourceInfos = (sourceInfos: ISourceInfo[]) => SourceInfo.getSyncableConnections(sourceInfos).filter(_ => !SourceInfo.isCollaborative(_))

const DataSourceSelect = (props: Props) => {
    const externalSourceInfos = getExternalSourceInfos(props.sourceInfos);
    const options = [PpmxConnection, ...externalSourceInfos];
    const selected = props.selected ? options.find(_ => _.connectionId === props.selected) : undefined;

    return <>
        {options.map(_ => {
            const isSelected = selected?.connectionId === _.connectionId;
            return <IconButton
                key={_.connectionId}
                iconProps={{ iconName: SourceType_.getIconName(_.type) }}
                title={_.type === SourceType.Ppmx ? SourceTypeMap[_.type] : `Connected to ${SourceTypeMap[_.type]}`}
                className={`icon-switcher ${isSelected ? 'on' : ''}`}
                onClick={e => {
                    e.stopPropagation();
                    !isSelected && props.onItemSelected(_.connectionId);
                }}
            />
        })}
        {options.length === 1 && <ActionButton
            title="Connect"
            iconProps={{ iconName: "PPMXLink" }}
            disabled={!props.isConnectPanelAvailable}
            className="link-btn"
            onClick={e => {
                e.stopPropagation();
                props.onAddConnection();
            }}
        />}
    </>
}

export default DataSourceSelect;