import * as React from "react";
import { Hyperlink } from "../inputs/HyperlinkInput";
import { Link } from 'office-ui-fabric-react';

type Props = {
    hyperlink?: Hyperlink;
};

export const HyperlinkFormatter = (props: Props) => {
    const { hyperlink } = props;
    const title = hyperlink?.text || hyperlink?.url;
    
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        window.open(hyperlink?.url ?? "#", '_blank');
        e.stopPropagation();
    };

    return <Link
        className="text-link hyperlink" 
        title={title}
        //prevent row select on list view
        onMouseDown={handleClick}
        //prevent row select on timeline view
        onClick={handleClick}>
            {title}
        </Link>
}