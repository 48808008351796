import * as React from 'react';
import { Panel, PanelType, DefaultButton, MessageBar, MessageBarType, PersonaSize, Persona } from 'office-ui-fabric-react';
import { actionCreators, IUser } from "../../store/UsersListStore";
import { connect } from 'react-redux';
import PersonPickerInput, { PersonInfo } from '../common/inputs/PersonPickerInput';
import LabellableComponent from '../common/LabellableComponent';
import { Validator } from '../../validation';
import { UserStatus } from '../../store/User';
import { getPersonInfoImageUrl } from '../utils/common';

type OwnProps = { prevUser: IUser; onDismiss: () => void; }
type State = {
    nextUser?: PersonInfo;
}
type UserReplaceProps = OwnProps & typeof actionCreators;

class UserReplace extends React.Component<UserReplaceProps, State> {
    constructor(props: UserReplaceProps) {
        super(props);
        this.state = {
        };
    }

    public render() {
        const { prevUser } = this.props;
        return <Panel
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth="400px"
            isOpen={true}
            onRenderHeader={this._onRenderHeader}
            onRenderFooterContent={this._onRenderFooterContent}
            onDismiss={this.props.onDismiss}>

            <MessageBar messageBarType={MessageBarType.severeWarning}>Please note: once replaced, inactive user will be removed.
                User assignments will be transferred to another user selected for the replacement;
                associated resource data will be merged into the resource related to the resource selected as a replacement.</MessageBar>
            <div className="panel-area">
                <div className="grid-item">
                    <LabellableComponent label="User being replaced" className="field-container">
                        <Persona size={PersonaSize.size72}
                            imageUrl={getPersonInfoImageUrl(prevUser)}
                            text={prevUser.fullName}
                            secondaryText={`Email: ${prevUser.email || '-'}`}
                            tertiaryText={`Account: ${prevUser.logonAccount || '-'}`}
                        />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent label="User for replacement" required={true} className="field-container">
                        <PersonPickerInput
                            value={this.state.nextUser}
                            onChanged={(value) => { this.setState({ nextUser: value }); }}
                            searchUrl="api/user/find"
                            filter={{ exclude: [prevUser.id], status: UserStatus.Active }}
                            validator={Validator.new().required().build()}
                            buildSecondaryText={_ => _.logonAccount}
                        />
                    </LabellableComponent>
                </div>
            </div>
        </Panel>;
    }

    private _onRenderHeader = (): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Replace User</p>
            <div className='ms-Panel-secondaryText'>Replace inactive PPM Express user</div>
        </div>;
    }

    private _onRenderFooterContent = (): JSX.Element | null => {
        return <div className="commands">
            <DefaultButton
                disabled={!this.state.nextUser}
                primary={true}
                text="Confirm"
                onClick={() => {
                    this.props.replace(this.props.prevUser.id, this.state.nextUser!.id);
                    this.props.onDismiss();
                }}
            />
        </div>;
    }
}

export default connect(null, actionCreators)(UserReplace)