import * as React from 'react';
import { Checkbox, Icon } from 'office-ui-fabric-react';
import { ImportStatus, IBaseEntityImportState } from "../../../store/integration/ImportStore";
import { FormatterProps, HeaderRendererProps, useFocusRef, useRowSelection } from 'react-data-grid';

const SelectFormatter = (props: FormatterProps<IBaseEntityImportState>) => {
    const { row } = props;
    const [isRowSelected, onRowSelectionChange] = useRowSelection();
    const { tabIndex } = useFocusRef<HTMLInputElement>(props.isCellSelected);

    if (ImportStatus.isLinked(row.status)) {
        return null;
    }

    return <>
        {
            row.error && <Icon key="icon" iconName="ErrorBadge" title={row.error} />
        }
        <Checkbox
            key="checkbox"
            disabled={row.isInProcessing || !row.valid || !!row.error}
            checkmarkIconProps={{ iconName: "PPMXCheckMark" }}
            onChange={(e, checked) =>
                onRowSelectionChange({ row: props.row, checked: !!checked, isShiftClick: !!(e as any)?.shiftKey })
            }
            checked={isRowSelected}
            tabIndex={tabIndex}
        />
    </>
}

const SelectAllFormatter = (props: HeaderRendererProps<IBaseEntityImportState>) => {
    const { tabIndex } = useFocusRef<HTMLInputElement>(props.isCellSelected);

    return <Checkbox
        checkmarkIconProps={{ iconName: "PPMXCheckMark" }}
        key="details-checkbox"
        tabIndex={tabIndex}
        checked={props.allRowsSelected}
        onChange={(e, checked) => props.onAllRowsSelectionChange(!!checked)}
    />
}

export { SelectFormatter, SelectAllFormatter };