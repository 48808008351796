import { Dictionary, IConnected, ISourceInfo, MaybeDate, SourceInfo } from "../../entities/common";
import { IPortfolioInfo, ProjectInfo, TaskProgressData } from "../ProjectsListStore";
import { Program } from '../ProgramsListStore';
import { CalendarDataSet } from "../CalendarStore";
import { getWorkingDaysBetweenDates } from "../../components/common/timeline/utils";
import { toDate } from "../../components/utils/common";

export function getTasksMetrics(sourceInfos: ISourceInfo[], taskProgresses: Dictionary<TaskProgressData>, sourceConnectionId?: string): TaskProgressData | undefined {
    const sources = SourceInfo.getSources(sourceInfos);
    if (sourceConnectionId) {
        return taskProgresses[sourceConnectionId];
    }
    else if (sources.length === 1 && taskProgresses.length) {
        return taskProgresses[0];
    }

    return undefined;
}

export function getPortfolios(entity: ProjectInfo | Program): IPortfolioInfo[] {
    const portfoliosMap: Dictionary<IPortfolioInfo> = {};
    (entity as ProjectInfo).portfolioViaProgram?.forEach(_ => portfoliosMap[_.portfolio.id] = { id: _.portfolio.id, name: _.portfolio.name });
    entity.attributes.Portfolio.forEach(_ => portfoliosMap[_.id] = _);
    return Object.values(portfoliosMap);
}

//the same is in BaseVarianceCalculator.cs on server side
export const calculateDateVariance = (subentityDate: MaybeDate, subentityBaselineDate: MaybeDate, calendar: CalendarDataSet) => {
    const date = toDate(subentityDate);
    const baselineDate = toDate(subentityBaselineDate);

    if (!date || !baselineDate) {
        return undefined;
    }

    if (date > baselineDate) {
        return getWorkingDaysBetweenDates(baselineDate, date, calendar) - 1;
    }
    
    if (date < baselineDate) {
        return -1 * (getWorkingDaysBetweenDates(date, baselineDate, calendar) - 1);
    }

    return 0;
}