import * as React from "react";
import { OnSaveSettings, UpdateType, useColumnResize, useScale, useSorting } from '../../../../store/services/viewSaver';
import { IWithSubViews, IWithSortBy, IWithScale, ISortState } from '../../../../entities/common';
import BaseResourceUsageGrid, { OwnProps, defaultTimeType } from './BaseResourceUsageGrid';
import { TimeType, timeTypes } from '../ResourceUsageCell';
import { nameof } from "../../../../store/services/metadataService";
import { TimelineChange } from "../../../common/timeline/TimelineList";

export type ResourceUsageGridSettings = IWithSubViews & IWithSortBy & IWithScale & IWithTimeType;

export type OnUsageGridSaveSettings = OnSaveSettings<UpdateType | 'TimeType'>;

type Props = {
    controlSettings: ResourceUsageGridSettings;
    onSaveSettings?: OnUsageGridSaveSettings;
    defaultSort?: ISortState;
    onScaleChanged: (timelineChange: TimelineChange) => void;
}
export const PersistResourceUsageGrid = (props: Omit<OwnProps, 'timeType' | "onTimeTypeChange"> & Props) => {
    const { controlSettings, onSaveSettings, defaultSort, onScaleChanged } = props;

    const saverProps = {
        ...props.listProps,
        ...useColumnResize(controlSettings, onSaveSettings),
        ...useScale(controlSettings, onSaveSettings, onScaleChanged),
        sorting: useSorting(controlSettings, onSaveSettings, defaultSort),
    }

    return <BaseResourceUsageGrid
        {...props}
        {...useTimeType(controlSettings, onSaveSettings)}
        listProps={saverProps}
    />;
}

interface IWithTimeType {
    timeType?: TimeType[];
}

const useTimeType = (userSettings?: IWithTimeType, onSaveSettings?: OnUsageGridSaveSettings) => {
    const onTimeTypeChange = React.useCallback(
        (timeType: TimeType[]) => {
            onSaveSettings?.({
                type: 'TimeType',
                parentSettingsPathKeys: [nameof<IWithTimeType>('timeType')],
                valueBySettingNameMap: timeTypes
                    .map(_ => _.type)
                    .reduce((prev, cur) => ({ ...prev, [cur]: timeType.includes(cur) }), {})
            });
        },
        [onSaveSettings]
    );

    const timeType = React.useMemo(
        () => userSettings?.timeType
            ? Object.keys(userSettings.timeType).filter(_ => userSettings.timeType![_]) as TimeType[]
            : defaultTimeType,
        [userSettings?.timeType]);

    return {
        timeType: timeType,
        onTimeTypeChange: onTimeTypeChange
    }
}
