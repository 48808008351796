import * as React from "react";
import { IImportResourceState as ImportMap, ImportStatus } from "../../../store/integration/ImportStore";
import { IColumn, SearchBox, SelectionMode, Selection, Label, Overlay, MessageBar, MessageBarType } from "office-ui-fabric-react";
import Link from "../../common/Link";
import { nameof } from "../../../store/services/metadataService";
import { FormatterProps } from "../common/EditableCell";
import { StickyDetailsList } from "../../common/StickyDetailsList";
import Spinner from "../../common/Spinner";
import { StatusFormatter } from "../common/StatusFormatter";

type Props = {
    selection: Selection<ImportMap>;
    selectedCount: number;
    error: string | null;
    isImporting: boolean;
    maps: ImportMap[];
    isLoading: boolean;
    getKey: (map: ImportMap) => string;
    message?: {
        type: MessageBarType
        text: string;
        clearMessage: () => void;
    }
};

const ResourceImportGrid = (props: Props) => {
    const [filter, setFilter] = React.useState('');
    const clearFilter = React.useCallback(() => setFilter(''), []);

    const selected = React.useMemo(
        () => new Set<string>(props.selection.getSelection().map(props.getKey)),
        [props.selectedCount]);

    const maps = React.useMemo(
        () => props.maps.filter(_ => mapFilter(_, filter, selected)),
        [props.maps, filter, selected]);

    React.useEffect(() => {
        props.selection.setChangeEvents(false);
        props.selection.setAllSelected(false);
        selected.forEach(_ => props.selection.setKeySelected(_, true, true));
        props.selection.setChangeEvents(true);
    }, [maps.length]);

    const selectionZoneProps = React.useMemo(
        () => ({ selection: props.selection, disableAutoSelectOnInputElements: true }),
        [props.selection]);

    return <>
        {props.message && <MessageBar
            styles={{ root: { marginBottom: 10 } }}
            messageBarType={props.message.type}
            onDismiss={props.message.clearMessage}> {props.message.text}
        </MessageBar>}
        <SearchBox
            onChange={(e: any, v: string) => setFilter(v)}
            onClear={clearFilter}
            onEscape={clearFilter}
            value={filter}
        />
        <div className="align-center with-top-margin">
            <Label>{props.selectedCount
                ? `Selected ${props.selectedCount} from ${getTitle(maps.length)}`
                : `${getTitle(maps.length)} found`}
            </Label>
        </div>
        <StickyDetailsList
            className="high-row"
            columns={columns}
            items={maps}
            selectionPreservedOnEmptyClick
            selectionMode={SelectionMode.multiple}
            selection={props.selection}
            selectionZoneProps={selectionZoneProps}
            setKey='set'
            getKey={props.getKey}
        />
        {props.isImporting && <Overlay><Spinner /></Overlay>}
    </>
}

export default ResourceImportGrid;

const getTitle = (count: number) => `${count} resource${count > 1 ? "s" : ""}`;

const TextFormater = (props: FormatterProps<ImportMap, string>) => {
    return <div title={props.value} className="align-center imported-entity" style={{ paddingLeft: 23 }}>
        <span className="overflow-text">{props.value}</span>
    </div >
}

const columns = [
    {
        key: nameof<ImportMap>("resource"),
        minWidth: 250,
        name: "Name",
        headerClassName: "name-column",
        onRender: (item: ImportMap, index?: number, column?: IColumn) =>
            item.status === ImportStatus.Linked
                ? <Link target="_blank" href={`/resource/${item.resource.id}`}>
                    <TextFormater item={item} value={item.externalName} />
                </Link>
                : <TextFormater item={item} value={item.externalName} />
    },
    {
        key: nameof<ImportMap>("externalEmail"),
        minWidth: 250,
        name: "Email",
        onRender: (_: ImportMap) => <span title={_.externalEmail}>{_.externalEmail}</span>
    },
    {
        key: nameof<ImportMap>("status"),
        minWidth: 170,
        name: "Status",
        onRender: (_: ImportMap) => <StatusFormatter {...statusMap[_.status]} />
    }
]

const mapFilter = (map: ImportMap, filter: string, selected: Set<string>) => {
    const normilized = filter.toLowerCase();
    return selected.has(map.externalId)
        || includes(normilized, map.externalEmail)
        || includes(normilized, map.externalName)
        || includes(normilized, map.resource?.id);
}

const includes = (filter: string, value?: string) => {
    return value?.toLowerCase().includes(filter);
}

const statusMap: { [status: number]: { cssClass: string, label: string, title?: string } } = {
    [ImportStatus.New]: {
        cssClass: 'not-linked',
        label: 'Not linked',
        title: 'Resource is not found in PPM Express yet'
    },
    [ImportStatus.Linked]: {
        cssClass: 'linked',
        label: "Linked",
        title: 'Resource is found in PPM Express and linked with an external system'
    },
    [ImportStatus.Matched]: {
        cssClass: 'matched',
        label: "Match by name & email",
        title: 'Resource with the same email and name is found in PPM Express. After an import, it will be linked with an existing resource'
    }
}
