import * as React from 'react';
import EntityCreation from "../common/extensibleEntity/EntityCreation";
import { connect } from 'react-redux';
import { actionCreators } from "../../store/ChallengesListStore";
import { EntityType } from '../../entities/common';
import { bindActionCreators } from 'redux';

interface OwnProps {
    onDismiss: () => void;
    openOnComplete: boolean;
}

type Props = typeof actionCreators & OwnProps;

const ChallengeCreation = (props: Props) => {
    return <EntityCreation
        entityType={EntityType.Challenge}
        onDismiss={props.onDismiss}
        onSave={(name, layoutId) => props.createChallenge(name, layoutId, props.openOnComplete)} />;
}

export default connect(undefined, (dispatch) => bindActionCreators(actionCreators, dispatch))(ChallengeCreation)