import { 
    IConnectionsState, IImportResult, IApiResult, IImportProjectMap, IImportResourceMap, IListStore, IFieldMapping, 
    IEntityStore, ExternalFieldInfo, TaskImportSettings, MapConfig, IResourceSourceData, ConnectionsState, ItemsIterationsScheduleData
} from "./common";
import { get, post, remove } from '../../fetch-interceptor';
import { AppThunkAction } from "../index";
import * as Metadata from "../../entities/Metadata";
import { Reducer, Action } from "redux";
import { ProjectsImportActions, ResourcesImportActions, Utils } from "./ImportStore";
import { defaultCatch, catchApiError } from "../utils";
import { IDeletionResult } from "../services/storeHelper";
import { RemovedPortfoliosSourceInfosAction } from "../PortfoliosListStore";
import { RemovedProjectsSourceInfosAction } from "../ProjectsListStore";
import { RemovedResourcesSourceInfosAction } from "../ResourcesListStore";

export type JiraConnectionInfo = Metadata.IConnectionInfo & { isOnPremise: boolean, isRestricted: boolean};
export interface IJiraConnectionState extends IConnectionsState<JiraConnectionInfo> {
    refreshInfo: IConnectionRefreshInfo | null;
    createdConnectionId?: string;
}

export type JiraOAuthState = {
    publicKey: string;
    consumerKey: string;
    consumerName: string;
}

export interface JiraState {
    connections: IJiraConnectionState;
    projects: IListStore<IJiraProject>;
    users: IListStore<IJiraUser>;
    boards: IListStore<IJiraBoard>;

    fields: IListStore<ExternalFieldInfo>;
    issueTypes: IListStore<IIssueTypeWithStatus>;
    progressCalculationSettings: IEntityStore<IProgressCalculationSettings>;
    taskImportSettings: IEntityStore<TaskImportSettings>;
    defaultTaskMappings: IEntityStore<IFieldMapping[]>;

    oauth: JiraOAuthState;
}

export interface JiraScheduleData extends ItemsIterationsScheduleData {}

export interface IConnectionRefreshInfo {
    url: string;
    login: string;
}

export interface IJiraProject {
    id: string;
    name: string;
    key: string;
    style: string;
    isAlreadyLinked: boolean;
    taskImportSettingsKey: string;
}

export interface IJiraLinkInfo {
    projects: IJiraProject[];
    boards: IJiraBoard[];
    issues: IJiraIssue[];
}

export enum ProjectStyles {
    Classic = "classic",
    NextGen = "next-gen"
}

export interface IJiraUser {
    accountId: string;
    displayName: string;
    email: string;
    isAlreadyLinked: boolean;
}

export interface IJiraBoard {
    id: string;
    name: string;
    projectId: string;
    isSprintsEnabled: boolean;
}

export interface IJiraIssue {
    id: string;
    name: string;
    type: string;
    projectId: string;
}

export interface IIssueStatus {
    name: string;
    categoryKey: string;
}

export interface IIssueType {
    id: string;
    name: string;
    iconUrl: string;
}

export interface IJiraUserLinkInfo extends IResourceSourceData {
    key: string;
    accountId: string;
    userName: string;
    email: string;
}

export interface IIssueTypeWithStatus extends IIssueType {
    statuses: IIssueStatus[];
}

export interface IProgressCalculationSettings {
    issueTypes: string[];
    autoStatusCalculationIssueTypes: string[];
    calculateProgressBy?: string;
}

export interface IConnectionConfiguration {
    progressCalculationSettings: IProgressCalculationSettings;
    taskImportSettings: TaskImportSettings;
}

export namespace IJiraBoard {
    export function toSelectOption(board: IJiraBoard) {
        return {
            key: board.id,
            text: board.name,
            disabled: !board.isSprintsEnabled,
            title: board.isSprintsEnabled ? undefined : "Sprints feature must be enabled in \"Project settings\" of Jira project"
        }
    }
}

export enum JiraFieldTypes {
    DateTime = 'datetime',
    Issuetype = 'issuetype',
    Number = 'number',
    Project = 'project',
    Array = 'array',
    Resolution = 'resolution',
    SdFeedback = 'sd-feedback',
    Issuerestriction = 'issuerestriction',
    Watches = 'watches',
    Date = 'date',
    Priority = 'priority',
    SdApprovals = 'sd-approvals',
    String = 'string',
    User = 'user',
    Status = 'status',
    Option = 'option',
    Timetracking = 'timetracking',
    Securitylevel = 'securitylevel',
    Progress = 'progress',
    // Comment
    CommentsPage = 'comments-page',
    Votes = 'votes'
}

export const jiraFieldToPpmxFieldsMap: { [i: string]: MapConfig } = {
    [JiraFieldTypes.User]: { types: [Metadata.FieldType.User, Metadata.FieldType.Resource], label: "User" },
    [JiraFieldTypes.Date]: { types: [Metadata.FieldType.Date], label: "Date" },
    [JiraFieldTypes.DateTime]: { types: [Metadata.FieldType.Date], label: "DateTime" },
    [JiraFieldTypes.Number]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Number" },
    [JiraFieldTypes.String]: { types: [Metadata.FieldType.Text], label: "String" },
    [JiraFieldTypes.Option]: { types: [Metadata.FieldType.Text], label: "Option" },
    [JiraFieldTypes.Status]: { types: [Metadata.FieldType.Text], label: "Status" },
    [JiraFieldTypes.Priority]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Integer], label: "Priority" },
    [JiraFieldTypes.Issuetype]: { types: [Metadata.FieldType.Text], label: "Issue type" },
    [JiraFieldTypes.Array + JiraFieldTypes.User]: { types: [Metadata.FieldType.User, Metadata.FieldType.Resource], label: "User Array" },
    [JiraFieldTypes.Array + JiraFieldTypes.String]: { types: [Metadata.FieldType.Text], label: "String Array" },
    [JiraFieldTypes.Array + JiraFieldTypes.Option]: { types: [Metadata.FieldType.Text], label: "Array" },
}

export interface IJiraSourceData {
    projectUrl?: string;
    projects: IJiraProject[];
    boards: IJiraBoard[];
    issues: IJiraIssue[];
}

type Flags = { isProcessing: boolean } | { isLoading: boolean };

export type LoadConnections = { type: "JIRA_LOAD_CONNECTIONS" }
export type ReceivedConnections = { type: "JIRA_RECEIVED_CONNECTIONS"; connections: JiraConnectionInfo[]; }
type ConnectionCreatingOrRefreshing = { type: "JIRA_CONNECTION_CREATING_OR_REFRESHING", id?: string }
type ConnectionCreatedOrRefreshed = { type: "JIRA_CONNECTION_CREATED_OR_REFRESHED", connection: JiraConnectionInfo, isRefreshing: boolean }
type RemovedConnection = { type: "JIRA_CONNECTION_REMOVED", id: string }
type ReceivedConnectionRefreshInfo = { type: "JIRA_RECEIVED_CONNECTION_REFRESH_INFO", info: IConnectionRefreshInfo }
type ConnectionOperationError = { type: "JIRA_CONNECTION_OPERATION_ERROR", error: string | null, flag?: Flags }
type SetSelectedConnection = { type: "JIRA_SET_SELECTED_CONNECTION", connectionId?: string }

type VerifyConnection = { type: 'JIRA_VERIFY_CONNECTION', connectionId: string }
type ConnectionVerified = { type: 'JIRA_CONNECTION_VERIFIED', connectionId: string }
type ConnectionVerificationError = { type: 'JIRA_CONNECTION_VERIFICATION_ERROR', connectionId: string, error: string | null }

type ConnectionAction = LoadConnections
    | ReceivedConnections
    | ConnectionCreatingOrRefreshing
    | ConnectionCreatedOrRefreshed
    | RemovedConnection
    | ReceivedConnectionRefreshInfo
    | ConnectionOperationError
    | SetSelectedConnection
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError;

type LoadProjects = { type: "JIRA_LOAD_PROJECTS" }
type ReceivedProjects = { type: "JIRA_RECEIVED_PROJECTS", projects: IJiraProject[] }
type SetProjectsError = { type: "JIRA_SET_PROJECTS_ERROR", error: string | null }

type LoadUsers = { type: "JIRA_LOAD_USERS" }
type ReceivedUsers = { type: "JIRA_RECEIVED_USERS", users: IJiraUser[] }
type SetUsersError = { type: "JIRA_SET_USERS_ERROR", error: string | null }

type LoadBoards = { type: "JIRA_LOAD_BOARDS" }
type ReceivedBoards = { type: "JIRA_RECEIVED_BOARDS", boards: IJiraBoard[] }
type SetBoardsError = { type: "JIRA_SET_BOARDS_ERROR", error: string | null }

type LoadFields = { type: "JIRA_LOAD_FIELDS" }
type ReceivedFields = { type: "JIRA_RECEIVED_FIELDS", fields: ExternalFieldInfo[] }
type SetFieldsError = { type: "JIRA_SET_FIELDS_ERROR", error: string | null }

type LoadIssueTypes = { type: "JIRA_LOAD_ISSUE_TYPES" }
type ReceivedIssueTypes = { type: "JIRA_RECEIVED_ISSUE_TYPES", types: IIssueTypeWithStatus[] }
type SetIssueTypesError = { type: "JIRA_SET_ISSUE_TYPES_ERROR", error: string | null }

type LoadConnectionConfiguration = { type: "JIRA_LOAD_CONNECTION_CONFIGURATION" }
type UpdateConnectionConfiguration = { type: "JIRA_UPDATE_CONNECTION_CONFIGURATION" }
type ReceivedConnectionConfiguration = { type: "JIRA_RECEIVED_CONNECTION_CONFIGURATION", config: IConnectionConfiguration }
type LoadConnectionConfigurationError = { type: "JIRA_LOAD_CONNECTION_CONFIGURATION_ERROR", error: string | null }

type LoadDefaultTaskMappings = { type: "JIRA_LOAD_DEFAULT_TASK_MAPPINGS" }
type ReceivedDefaultTaskMappings = { type: "JIRA_RECEIVED_DEFAULT_TASK_MAPPINGS", mappings: IFieldMapping[] }
type LoadDefaultTaskMappingsError = { type: "JIRA_LOAD_DEFAULT_TASK_MAPPINGS_ERROR", error: string | null }

type EntitiesAction = LoadProjects
    | ReceivedProjects
    | SetProjectsError
    | LoadUsers
    | ReceivedUsers
    | SetUsersError
    | LoadBoards
    | ReceivedBoards
    | SetBoardsError

    | LoadFields
    | ReceivedFields
    | SetFieldsError

    | LoadIssueTypes
    | ReceivedIssueTypes
    | SetIssueTypesError

    | LoadConnectionConfiguration
    | UpdateConnectionConfiguration
    | ReceivedConnectionConfiguration
    | LoadConnectionConfigurationError

    | LoadDefaultTaskMappings
    | ReceivedDefaultTaskMappings
    | LoadDefaultTaskMappingsError;

type KnownAction = ConnectionAction | EntitiesAction;

export interface IJiraConnectionInfo {
    id?: string;
    url: string;

    isRestricted: boolean;

    login?: string;
    apiToken?: string;

    oAuthToken?: string;
    oAuthTokenSecret?: string;
    oAuthExpiresIn?: number;
    oAuthSessionHandle?: string;
    oAuthAuthorizationExpiresIn?: number;
}

export const actionCreators = {
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_CONNECTIONS' });
        get<JiraConnectionInfo[]>(`api/integration/jira/connection`)
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_CONNECTIONS', connections: _ }))
            .catch(defaultCatch(dispatch));
    },
    createOrRefreshConnection: (data: IJiraConnectionInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_CONNECTION_CREATING_OR_REFRESHING', id: data.id });
        post<IApiResult<JiraConnectionInfo>>(`api/integration/jira/connection`, data)
            .then(_ => {
                if (_.errorMessage) {
                    dispatch({ type: 'JIRA_CONNECTION_OPERATION_ERROR', error: _.errorMessage, flag: { isProcessing: false } });
                } else {
                    dispatch({ type: 'JIRA_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: !!data.id });
                    dispatch({ type: 'JIRA_SET_SELECTED_CONNECTION', connectionId: _.data.id });
                }
            })
            .catch(defaultCatch(dispatch));
    },
    renameConnection: (connectionId: string, title: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IApiResult<JiraConnectionInfo>>(`api/integration/jira/connection/${connectionId}/rename`, { title })
            .then(_ => dispatch({ type: 'JIRA_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: true }))
            .catch(defaultCatch(dispatch));
    },
    removeConnection: (id: string, callback?: () => void):
        AppThunkAction<KnownAction | RemovedPortfoliosSourceInfosAction | RemovedProjectsSourceInfosAction | RemovedResourcesSourceInfosAction> => (dispatch, getState) => {
            dispatch({ type: 'JIRA_CONNECTION_CREATING_OR_REFRESHING', id });
            remove<IDeletionResult>(`api/integration/jira/connection/${id}`)
                .then(_ => {
                    if (_.isDeleted) {
                        dispatch({ type: 'JIRA_CONNECTION_REMOVED', id: _.id });
                        dispatch({ type: "REMOVED_PORTFOLIOS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_PROJECTS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_RESOURCES_SOURCE_INFOS", connectionId: _.id });
                        callback?.();
                    } else {
                        dispatch({ type: 'JIRA_CONNECTION_OPERATION_ERROR', error: _.message || null });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    setSelectedConnection: (connectionId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_SET_SELECTED_CONNECTION', connectionId });
    },
    cleanError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_CONNECTION_OPERATION_ERROR', error: null });
    },
    loadRefreshInfo: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<IConnectionRefreshInfo>(`api/integration/jira/connection/${connectionId}/refresh`)
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_CONNECTION_REFRESH_INFO', info: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadProjects: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_PROJECTS' });
        get<IJiraProject[]>(`api/integration/jira/projects`, { connectionId })
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_PROJECTS', projects: _ }))
            .catch(catchApiError(_ => dispatch({ type: "JIRA_SET_PROJECTS_ERROR", error: `Unable to load Jira projects: ${_}` })));
    },
    loadUsers: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_USERS' });
        get<IJiraUser[]>(`api/integration/jira/resources`, { connectionId })
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_USERS', users: _ }))
            .catch(catchApiError(_ => dispatch({ type: "JIRA_SET_USERS_ERROR", error: `Unable to load Jira resources: ${_}` })));
    },
    loadBoards: (connectionId: string, projectIds?: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_BOARDS' });
        post<IJiraBoard[]>(`api/integration/jira/boards`, { connectionId, projectIds })
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_BOARDS', boards: _ }))
            .catch(catchApiError(_ => dispatch({ type: "JIRA_SET_BOARDS_ERROR", error: `Unable to load Jira boards: ${_}` })));
    },
    importProjects: (connectionId: string, maps: IImportProjectMap<IJiraLinkInfo>[]): AppThunkAction<ProjectsImportActions> => (dispatch, getState) => {
        const entityIds = maps.map(_ => _.linkingData.projects[0].id);
        dispatch({ type: 'IMPORT_PROJECTS_STARTED', entityIds });

        Utils.batchSend(maps,
            batch => post<IImportResult[]>('api/integration/jira/import/projects', { connectionId, maps: batch })
                .then(_ => dispatch({ type: 'IMPORT_PROJECTS_BATCH_FINISHED', results: _ })))
            .then(() => dispatch({ type: 'IMPORT_PROJECTS_FINISHED' }))
            .catch(defaultCatch(dispatch));
    },
    importResources: (connectionId: string, maps: IImportResourceMap<IJiraUser>[]): AppThunkAction<ResourcesImportActions> => (dispatch, getState) => {
        dispatch({ type: 'IMPORT_RESOURCES_STARTED' });
        Utils.sendByChunks(maps, chunk => post<IImportResult[]>('api/integration/jira/import/resources', { connectionId, maps: chunk }))
            .then(_ => dispatch({ type: 'IMPORT_RESOURCES_FINISHED', results: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadDefaultTaskMappings: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_DEFAULT_TASK_MAPPINGS' });
        get<IFieldMapping[]>(`api/integration/jira/defaultTaskMappings/${connectionId}/${projectId}`)
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_DEFAULT_TASK_MAPPINGS', mappings: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'JIRA_LOAD_DEFAULT_TASK_MAPPINGS_ERROR', error: `Unable to load Jira default task mappings settings: ${_}` })));
    },
    loadConnectionConfiguration: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_CONNECTION_CONFIGURATION' });
        get<IConnectionConfiguration>(`api/integration/jira/connectionConfiguration/${connectionId}/${projectId}`)
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'JIRA_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load Jira connection configuration: ${_}` })));
    },
    updateConnectionConfiguration: (connectionId: string, projectId: string, configuration: IConnectionConfiguration): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(({ type: 'JIRA_UPDATE_CONNECTION_CONFIGURATION' }));
        post<IConnectionConfiguration>(`api/integration/jira/connectionConfiguration/${connectionId}/${projectId}`, configuration)
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'JIRA_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to update Jira connection configuration: ${_}` })));
    },
    loadIssueTypes: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_ISSUE_TYPES' });
        get<IIssueTypeWithStatus[]>(`api/integration/jira/issueTypes`, { connectionId, projectId })
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_ISSUE_TYPES', types: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'JIRA_SET_ISSUE_TYPES_ERROR', error: `Unable to load Jira issue types: ${_}` })));
    },
    loadFields: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_LOAD_FIELDS' });
        get<ExternalFieldInfo[]>(`api/integration/jira/fields`, { connectionId, projectId })
            .then(_ => dispatch({ type: 'JIRA_RECEIVED_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'JIRA_SET_FIELDS_ERROR', error: `Unable to load Jira fields: ${_}` })));
    },
    verifyConnection: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'JIRA_VERIFY_CONNECTION', connectionId });
        get(`api/integration/jira/verify-connection`, { connectionId })
            .then(_ => dispatch({ type: 'JIRA_CONNECTION_VERIFIED', connectionId }))
            .catch(catchApiError(_ => dispatch({ type: 'JIRA_CONNECTION_VERIFICATION_ERROR', connectionId: connectionId, error: _ })));
    }
}

const initState: JiraState = {
    connections: {
        isLoading: false,
        isProcessing: false,
        data: [],
        refreshInfo: null,
        error: null,
        connectionsVerification: { }
    },
    projects: {
        entities: [],
        isLoading: false,
        error: null,
    },
    users: {
        entities: [],
        isLoading: false,
        error: null,
    },
    boards: {
        entities: [],
        isLoading: false,
        error: null
    },
    fields: {
        entities: [],
        isLoading: false,
        error: null,
    },
    issueTypes: {
        entities: [],
        isLoading: false,
        error: null,
    },
    progressCalculationSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {
            issueTypes: [],
            autoStatusCalculationIssueTypes: []
        },
        error: null
    },
    taskImportSettings: {
        isLoading: false,
        isProcessing: false,
        entities: { },
        error: null
    },
    defaultTaskMappings: {
        isLoading: false,
        isProcessing: false,
        entities: [],
        error: null
    },
    oauth: {
        consumerKey: '',
        consumerName: '',
        publicKey: ''
    }
}

const connectionsReducer: Reducer<IJiraConnectionState> = (state: IJiraConnectionState, incomingAction: Action) => {
    const action = incomingAction as ConnectionAction;
    switch (action.type) {
        case 'JIRA_LOAD_CONNECTIONS':
            {
                return {
                    ...state,
                    connectionsVerification: {},
                    isLoading: true
                }
            }
        case 'JIRA_RECEIVED_CONNECTIONS':
            {
                return {
                    ...state,
                    data: action.connections.map(_ => ({..._, title: _.isRestricted ? `${_.title} (Restricted connection)` : _.title})),
                    isLoading: false
                }
            }
        case 'JIRA_CONNECTION_CREATING_OR_REFRESHING':
            {
                return {
                    ...state,
                    isProcessing: true,
                    connectionsVerification: ConnectionsState.remove(state, action.id)  
                }
            }
        case "JIRA_CONNECTION_CREATED_OR_REFRESHED":
            {
                return {
                    ...state,
                    data: action.isRefreshing
                        ? state.data.map(_ => _.id === action.connection.id ? action.connection : _)
                        : state.data.concat(action.connection),
                    error: null,
                    createdConnectionId: action.isRefreshing ? undefined : action.connection.id,
                    isProcessing: false
                }
            }
        case "JIRA_CONNECTION_REMOVED":
            {
                return {
                    ...state,
                    data: state.data.filter(_ => _.id !== action.id),                    
                    connectionsVerification: ConnectionsState.remove(state, action.id),
                    error: null,
                    isProcessing: false
                }
            }
        case "JIRA_RECEIVED_CONNECTION_REFRESH_INFO":
            {
                return {
                    ...state,
                    refreshInfo: action.info
                }
            }
        case "JIRA_CONNECTION_OPERATION_ERROR":
            {
                return {
                    ...state,
                    ...action.flag,
                    error: action.error
                }
            }
        case "JIRA_SET_SELECTED_CONNECTION":
            {
                return {
                    ...state,
                    selectedId: action.connectionId
                }
            }
        case "JIRA_VERIFY_CONNECTION":
            {
                return {
                    ...state,
                    data: state.data.map(con =>
                        ({ ...con, isOnPremise: state.data.find(_ => _.id === con.id)!.isOnPremise})),
                    connectionsVerification: ConnectionsState.setVerificationStarting(state, action.connectionId)
                }
            }
        case "JIRA_CONNECTION_VERIFIED":
            {
                return {
                    ...state,
                    data: state.data.map(con =>
                        ({ ...con, isOnPremise: state.data.find(_ => _.id === con.id)!.isOnPremise})),
                    connectionsVerification: ConnectionsState.setVerificationFinished(state, action.connectionId)
                }
            }
        case "JIRA_CONNECTION_VERIFICATION_ERROR":
            {
                return {
                    ...state,
                    data: state.data.map(con =>
                        ({ ...con, isOnPremise: state.data.find(__ => __.id === con.id)!.isOnPremise})),
                    connectionsVerification: ConnectionsState.setVerificationFailed(state, action.connectionId, action.error)
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

const entitiesReducer: Reducer<JiraState> = (state: JiraState, incomingAction: Action) => {
    const action = incomingAction as EntitiesAction;
    switch (action.type) {
        case 'JIRA_LOAD_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'JIRA_RECEIVED_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: action.projects,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'JIRA_SET_PROJECTS_ERROR':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'JIRA_LOAD_USERS':
            {
                return {
                    ...state,
                    users: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'JIRA_RECEIVED_USERS':
            {
                return {
                    ...state,
                    users: {
                        entities: action.users,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'JIRA_SET_USERS_ERROR':
            {
                return {
                    ...state,
                    users: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'JIRA_LOAD_BOARDS':
            {
                return {
                    ...state,
                    boards: {
                        entities: Array.of<IJiraBoard>(),
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'JIRA_RECEIVED_BOARDS':
            {
                return {
                    ...state,
                    boards: {
                        entities: action.boards,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'JIRA_SET_BOARDS_ERROR':
            {
                return {
                    ...state,
                    boards: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case "JIRA_RECEIVED_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, entities: action.config.progressCalculationSettings },
                    taskImportSettings: { ...initState.taskImportSettings, entities: action.config.taskImportSettings },
                }
            }
        case "JIRA_LOAD_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, isLoading: true },
                    taskImportSettings: { ...initState.taskImportSettings, isLoading: true }
                }
            }
        case "JIRA_UPDATE_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...state.progressCalculationSettings, isProcessing: true },
                    taskImportSettings: { ...state.taskImportSettings, isProcessing: true }
                }
            }
        case 'JIRA_LOAD_CONNECTION_CONFIGURATION_ERROR':
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, error: action.error }
                }
            }
        case "JIRA_RECEIVED_DEFAULT_TASK_MAPPINGS":
            {
                return {
                    ...state,
                    defaultTaskMappings: { ...initState.defaultTaskMappings, entities: action.mappings }
                }
            }
        case "JIRA_LOAD_DEFAULT_TASK_MAPPINGS":
            {
                return {
                    ...state,
                    defaultTaskMappings: { ...initState.defaultTaskMappings, isLoading: true }
                }
            }
        case 'JIRA_LOAD_DEFAULT_TASK_MAPPINGS_ERROR':
            {
                return {
                    ...state,
                    defaultTaskMappings: { ...initState.defaultTaskMappings, error: action.error }
                }
            }
        case 'JIRA_LOAD_ISSUE_TYPES':
            {
                return {
                    ...state,
                    issueTypes: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'JIRA_RECEIVED_ISSUE_TYPES':
            {
                return {
                    ...state,
                    issueTypes: {
                        entities: action.types,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'JIRA_SET_ISSUE_TYPES_ERROR':
            {
                return {
                    ...state,
                    issueTypes: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }

        case 'JIRA_LOAD_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'JIRA_RECEIVED_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: action.fields,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'JIRA_SET_FIELDS_ERROR':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

export const reducer: Reducer<JiraState> = (state: JiraState, incomingAction: Action) => {
    if (!state || !state.connections) {
        state = Object.assign(initState, state);
    }

    return {
        ...entitiesReducer(state, incomingAction),
        connections: connectionsReducer(state.connections, incomingAction)
    }
}