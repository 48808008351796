import { get, post, remove } from './../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { IEntityStore, StoreHelper, partialUpdate, IDeletionResult, addOrUpdateOrRemove } from './services/storeHelper';
import { push, RouterAction } from 'react-router-redux';
import { Section, IFilter, BaseFilterValue, IWithSections, IWithPinnedViews } from '../entities/Metadata';
import { MetadataService, UpdateUIControlInfo, ActionsBuilder, namesof } from './services/metadataService';
import * as ExternalEpmConnectStore from "./ExternalEpmConnectStore";
import { O365GroupLinkInfo, ProjectInfo, CreateProjectSuccessAction, ReceivedProjectsPartAction, RequestProjectsAction } from "./ProjectsListStore";
import {
    Dictionary, IBlinePlanActual, ICalculation, ISourceInfo, EntityType, StatusCategory,
    Impact, IBaseEntity, IWithWarnings, IWithChangeHistory, IHistoryRow, IWithInsights, UpdateContext,
    IInsightsData, IWarning, IEditable, IWithImage, IPatch, IWithLayout, IWithResourcePlan, IWithName, IWithManager, IWithBenefits, IWithStartFinishDates,
    IExtensibleEntity
} from "../entities/common";
import { KeyDate, KeyDateWithWarnings, ActionItem, KeyDecision, Risk } from "../entities/Subentities";
import { defaultCatch } from "./utils";
import { ILinkDto } from './integration/common';
import { IWithPrioritiesAlignment } from './StrategicPrioritiesListStore';
import { TeamsChannelLink } from '../components/integration/TeamsChannelConnectControl';
import { ImportExportFactory } from './importExport';
import { ApplyLayout, LayoutApplied } from './layouts';
import * as NotificationsStore from "./NotificationsStore";
import { UpdateFilterAction } from './filters';
import { Program, RequestProgramsAction, ReceivedProgramsPartAction, CreatedProgramAction } from './ProgramsListStore';
import { ChangeHistoryOperationsFactory, HISTORY_DEFAULT_STATE, IChangeHistoryState } from './HistoryListStore';
import { actionsForBuilder, BaseSubentitiesUpdateAction, ICreationData } from './Subentity';
import { ResourcePlanOperationsFactory } from './ResourcePlanListStore';

const namespace = 'PORTFOLIO';
const { importExportActionCreators, importExportReducer } = ImportExportFactory<string, Portfolio, PortfoliosState>(namespace, EntityType.Portfolio);
const historyStore = ChangeHistoryOperationsFactory<Portfolio, PortfoliosState>(EntityType.Portfolio);
const resourcePlanStore = ResourcePlanOperationsFactory<Portfolio, PortfoliosState>(EntityType.Portfolio);

export const statuses = ["Overall", "Cost", "Schedule"];
export const StatusNames = ["OverallStatus", "CostStatus", "ScheduleStatus"];

export interface PortfolioAttrs extends IWithName, IWithManager, IWithStartFinishDates, IWithBenefits {
    Identifier: string;
    Stage: PortfolioStage;
    Budget: number;
    CostStatus: string;
    OverallStatus: string;
    CreatedDate: string;
}

type PortfolioAttributesModel = IBaseEntity & IWithWarnings & IWithChangeHistory & IWithInsights & {
    attributes: PortfolioAttrs;
    keyDates: KeyDate[];
}

export interface Portfolio
    extends IExtensibleEntity,
        IWithPrioritiesAlignment,
        IWithInsights,
        IWithLayout,
        IWithImage,
        IEditable,
        IWithResourcePlan,
        IWithSections,
        IWithPinnedViews {
    id: string;
    projectIds: string[];
    programIds: string[];
    calculation: PortfolioCalculation;
    sourceInfos: ISourceInfo[];
    attributes: PortfolioAttrs & Dictionary<any>;
    keyDates: KeyDate[];
    actionItems: ActionItem[];
    keyDecisions: KeyDecision[];
    canConfigure: boolean;
    warnings: IWarning[];
    changeHistory: IHistoryRow[];
    risks: Risk[];
}

export interface PortfolioCalculation extends ICalculation {
    projectStatuses: Dictionary<StatusCategory>;
    programStatuses: Dictionary<StatusCategory>;
    projectProgresses: any;
    costs: IBlinePlanActual;
    work: IBlinePlanActual;
}

export enum PortfolioStage {
    Active = 0,
    Closed = 1,
    Inactive = 2,
    Future = 3
}

export interface PortfolioStageConfig { title: string, cssClassName: string }
export const portfolioStagesMap: { [i: number]: PortfolioStageConfig } =
{
    [PortfolioStage.Active]: {
        title: "Active",
        cssClassName: "Active"
    },
    [PortfolioStage.Closed]: {
        title: "Closed",
        cssClassName: "Closed"
    },
    [PortfolioStage.Inactive]: {
        title: "Inactive",
        cssClassName: "Inactive",
    },
    [PortfolioStage.Future]: {
        title: "Future",
        cssClassName: "Future",
    }
}

export interface PortfoliosState extends IEntityStore<Portfolio> {
    isLoading: boolean;
    isListLoading: boolean;
    isListUpdating: boolean;
    isUpdatingSections: boolean;
    deletionResult?: IDeletionResult[];

    changeHistory: IChangeHistoryState;
}

export const DEFAULT_BULK_EDIT_COLUMNS = namesof<PortfolioAttrs>(["Name", "OverallStatus"]);

interface CreatePortfolioAction {
    type: 'CREATE_PORTFOLIO';
}

interface LoadPortfolioAction {
    type: 'LOAD_PORTFOLIO';
    id?: string;
}

interface ReceivedDeletePortfoliosResultAction {
    type: 'RECEIVED_REMOVE_PORTFOLIOS_RESULT';
    deletionResult?: IDeletionResult[];
}

interface CreatedPortfolioAction {
    type: 'CREATED_PORTFOLIO';
    portfolio: Portfolio;
}

interface ReceivedPortfolioAction {
    type: 'RECEIVED_PORTFOLIO';
    portfolio: Portfolio;
}

interface ReceivedPortfolioAttributesAction {
    type: "RECEIVED_PORTFOLIO_ATTRIBUTES";
    data: PortfolioAttributesModel;
}

interface UpdatingSectionsAction {
    type: 'UPDATING_PORTFOLIO_SECTIONS';
    portfolioId: string;
}

interface UpdateSectionAction {
    type: 'UPDATE_PORTFOLIO_SECTION_SUCCESS';
    portfolioId: string;
    sections: Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_PORTFOLIO_PINNED_VIEWS_SUCCESS';
    portfolioId: string;
    pinnedViews: string[];
}

interface RequestPortfoliosAction {
    type: 'REQUEST_PORTFOLIOS';
}

interface ReceivePortfoliosAction {
    type: 'RECEIVED_PORTFOLIOS';
    portfolios: Portfolio[];
}

interface UpdateUIControlAction {
    type: 'UPDATE_UICONTROL_IN_PORTFOLIO_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

export interface UpdateProjectsAction {
    type: 'UPDATE_PORTFOLIO_PROJECTS';
    portfolioId: string;
    projectIds: string[];
    calculation: PortfolioCalculation;
    keyDates?: KeyDate[];
    warnings?: IWarning[];
    isAdd?: boolean;
}

export interface UpdateProgramsAction {
    type: 'UPDATE_PORTFOLIO_PROGRAMS';
    portfolioId: string;
    programIds: string[];
    calculation: PortfolioCalculation;
    isAdd?: boolean;
}

interface BulkUpdatePortfoliosSuccessAction {
    type: 'BULK_UPDATE_PORTFOLIOS_SUCCESS';
    portfolios: Portfolio[];
}

interface UpdateKeyDatesAction {
    type: 'UPDATE_PORTFOLIO_KEYDATES';
    portfolioId: string;
    set?: KeyDate[];
    addOrUpdate?: KeyDate[] | KeyDate;
    remove?: string[];
    warnings: IWarning[];
}

interface UpdateImageAction {
    type: 'UPDATE_PORTFOLIO_IMAGE';
    portfolioId: string;
    imageId?: string;
}

interface UpdateCalculationAction {
    type: 'UPDATE_PORTFOLIO_CALCULATION';
    portfolioId: string;
    calculation: PortfolioCalculation;
    updates: Dictionary<any>;
}

interface UpdatePortfolioActionItems extends BaseSubentitiesUpdateAction<ActionItem> {
    type: "UPDATE_PORTFOLIO_ACTIONITEMS";
}

interface UpdaePortfolioKeyDecisions extends BaseSubentitiesUpdateAction<KeyDecision> {
    type: "UPDATE_PORTFOLIO_KEYDECISIONS";
}

export interface RemovedPortfoliosSourceInfosAction {
    type: "REMOVED_PORTFOLIOS_SOURCE_INFOS";
    connectionId: string;
}

interface UpdatePortfolioRisks extends BaseSubentitiesUpdateAction<Risk> {
    type: "UPDATE_PORTFOLIO_RISKS";
}

type KnownAction = RequestPortfoliosAction
    | ReceivePortfoliosAction
    | CreatePortfolioAction
    | ReceivedPortfolioAction
    | ReceivedPortfolioAttributesAction
    | CreatedPortfolioAction
    | ReceivedDeletePortfoliosResultAction
    | UpdatingSectionsAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | LoadPortfolioAction
    | UpdateProjectsAction
    | BulkUpdatePortfoliosSuccessAction
    | UpdateKeyDatesAction
    | UpdateImageAction
    | UpdateCalculationAction
    | UpdateUIControlAction
    | UpdatePortfolioActionItems
    | UpdaePortfolioKeyDecisions
    | RemovedPortfoliosSourceInfosAction
    | UpdatePortfolioRisks
    | UpdateProgramsAction;

const actionsFor = actionsForBuilder<KnownAction>(EntityType.Portfolio);
const subentitiesActionCreators = {
    removeKeyDates: (portfolioId: string, keyDateIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<KeyDateWithWarnings>(`api/portfolio/${portfolioId}/keyDate`, { ids: keyDateIds })
            .then(data => dispatch({ type: "UPDATE_PORTFOLIO_KEYDATES", portfolioId, remove: keyDateIds, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    createKeyDate: (portfolioId: string, data: ICreationData): AppThunkAction<KnownAction> => (dispatch) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/portfolio/${portfolioId}/keyDate`, data)
            .then(dto => dispatch({ type: "UPDATE_PORTFOLIO_KEYDATES", portfolioId, addOrUpdate: dto.keyDates, warnings: dto.warnings }))
            .catch(defaultCatch(dispatch));
    },
    updateKeyDates: (portfolioId: string, keyDates: IPatch<KeyDate>[]): AppThunkAction<KnownAction> => (dispatch) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/portfolio/${portfolioId}/keyDate/bulk`, keyDates)
            .then(data => dispatch({ type: "UPDATE_PORTFOLIO_KEYDATES", portfolioId, addOrUpdate: data.keyDates, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    linkKeyDates: (portfolioId: string, subentityToParentMap: Dictionary<string[]>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/portfolio/${portfolioId}/keyDate/link`, subentityToParentMap)
            .then(data => dispatch({ type: 'UPDATE_PORTFOLIO_KEYDATES', portfolioId, addOrUpdate: data.keyDates, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    setKeyDatesBaseline: (portfolioId: string, ids: string[]): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/portfolio/${portfolioId}/keyDate/baseline`, { ids })
            .then(data => dispatch({ type: 'UPDATE_PORTFOLIO_KEYDATES', portfolioId, addOrUpdate: data.keyDates, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    ...actionsFor<Risk>().create('Risk', _ => ({ type: 'UPDATE_PORTFOLIO_RISKS', ..._ })),
    linkRisks: (portfolioId: string, subentityToParentMap: Dictionary<string[]>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ risks: Risk[] }>(`api/portfolio/${portfolioId}/risk/link`, subentityToParentMap)
            .then(data => dispatch({ type: 'UPDATE_PORTFOLIO_RISKS', entityId: portfolioId, addOrUpdate: data.risks }))
            .catch(defaultCatch(dispatch));
    },
    ...actionsFor<ActionItem>().create('ActionItem', _ => ({ type: 'UPDATE_PORTFOLIO_ACTIONITEMS', ..._ })),
    ...actionsFor<KeyDecision>().create('KeyDecision', _ => ({ type: 'UPDATE_PORTFOLIO_KEYDECISIONS', ..._ }))
}

const defaultActionCreators = {
    requestPortfolios: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Portfolio[]>(`api/portfolio`)
            .then(data => { dispatch({ type: 'RECEIVED_PORTFOLIOS', portfolios: data }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_PORTFOLIOS' }); // Ensure server-side prerendering waits for this to complete
    },
    createPortfolio: (name: string, layoutId: string, createFilter: boolean, openOnComplete: boolean)
        : AppThunkAction<KnownAction | RouterAction | UpdateFilterAction> => (dispatch) => {
            post<{ portfolio: Portfolio; filter: IFilter<BaseFilterValue> | null }>(`api/portfolio`, { name, layoutId, createFilter })
                .then(data => {
                    dispatch({ type: "CREATED_PORTFOLIO", portfolio: data.portfolio });
                    data.filter && dispatch({ entity: EntityType.Project, type: 'UPDATE_FILTER', filter: data.filter });

                    if (openOnComplete) {
                        dispatch(push(`/portfolio/${data.portfolio.id}`));
                    }
                })
                .catch(defaultCatch(dispatch));

            dispatch(<CreatePortfolioAction>{ type: "CREATE_PORTFOLIO" });
        },
    removePortfolios: (ids: string[], redirectBack?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<IDeletionResult>(`api/portfolio/${ids[0]}`)
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_PORTFOLIOS_RESULT", deletionResult: [data] });
                    if (redirectBack) {
                        dispatch(push('/portfolios'));
                    }
                })
                .catch(defaultCatch(dispatch))
            : post<IDeletionResult[]>(`api/portfolio/bulkDelete`, { ids })
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_PORTFOLIOS_RESULT", deletionResult: data });
                    if (redirectBack) {
                        dispatch(push('/portfolios'));
                    }
                })
                .catch(defaultCatch(dispatch));

        dispatch({ type: "LOAD_PORTFOLIO" });
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_PORTFOLIOS_RESULT" });
    },
    loadPortfolio: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Portfolio>(`api/portfolio/${id}`)
            .then(data => dispatch({ type: "RECEIVED_PORTFOLIO", portfolio: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "LOAD_PORTFOLIO", id: id });
    },
    updateAttributes: (portfolioId: string, updates: Dictionary<any>, context?: Dictionary<UpdateContext>): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            post<PortfolioAttributesModel>(`api/portfolio/${portfolioId}/attributes`, { updates, context })
                .then(data => dispatch({ type: 'RECEIVED_PORTFOLIO_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    resetPortfolioStatus: (portfolioId: string, statusAttributeName: string): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            post<PortfolioAttributesModel>(`api/portfolio/${portfolioId}/resetStatus/${statusAttributeName}`, {})
                .then(data => dispatch({ type: 'RECEIVED_PORTFOLIO_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    updateInsights: (portfolioId: string, data: Partial<IInsightsData>):
        AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
            post<Portfolio>(`api/portfolio/${portfolioId}/insights`, data)
                .then(portfolio => dispatch({ type: 'RECEIVED_PORTFOLIO', portfolio }))
                .catch(defaultCatch(dispatch));
        },
    updateSections: ActionsBuilder.buildEntityUpdateSections(`api/portfolio`,
        (portfolioId, sections, dispatch) => dispatch({
            type: 'UPDATE_PORTFOLIO_SECTION_SUCCESS',
            portfolioId,
            sections
        })),
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((portfolioId, sections, dispatch) => dispatch({
        type: 'UPDATE_PORTFOLIO_SECTION_SUCCESS',
        portfolioId,
        sections
    })),
    updatePinnedViews: ActionsBuilder.buildEntityUpdatePinnedViews(`api/portfolio`,
        (portfolioId, pinnedViews, dispatch) => dispatch({
            type: 'UPDATE_PORTFOLIO_PINNED_VIEWS_SUCCESS',
            portfolioId,
            pinnedViews
        })),
    updateUIControl: ActionsBuilder.buildEntityUpdateUIControl(`api/portfolio`,
        (uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
            type: 'UPDATE_UICONTROL_IN_PORTFOLIO_SUCCESS',
            uiControlInfo: uiControlInfo
        })),
    updateLayoutUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Portfolio),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_UICONTROL_IN_PORTFOLIO_SUCCESS',
        uiControlInfo: uiControlInfo
    })),
    removeProjects: (portfolioId: string, projectIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<Portfolio>(`api/portfolio/${portfolioId}/project`, { ids: projectIds })
            .then(data => {
                dispatch({ type: "UPDATE_PORTFOLIO_PROJECTS", portfolioId, projectIds: data.projectIds, calculation: data.calculation });
                dispatch({ type: "UPDATE_PORTFOLIO_KEYDATES", portfolioId, set: data.keyDates, warnings: data.warnings });
                dispatch({ type: "UPDATE_PORTFOLIO_RISKS", entityId: portfolioId, set: data.risks });
            })
            .catch(defaultCatch(dispatch));
    },
    addProjects: (portfolioId: string, projectIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Portfolio>(`api/portfolio/${portfolioId}/project`, { ids: projectIds })
            .then(data => dispatch({ type: "UPDATE_PORTFOLIO_PROJECTS", portfolioId, projectIds: data.projectIds, calculation: data.calculation }))
            .catch(defaultCatch(dispatch));
    },
    removePrograms: (portfolioId: string, programIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<Portfolio>(`api/portfolio/${portfolioId}/program`, { ids: programIds })
            .then(data => {
                dispatch({ type: "UPDATE_PORTFOLIO_PROGRAMS", portfolioId, programIds: data.programIds, calculation: data.calculation });
                dispatch({ type: "UPDATE_PORTFOLIO_KEYDATES", portfolioId, set: data.keyDates, warnings: data.warnings });
                dispatch({ type: "UPDATE_PORTFOLIO_RISKS", entityId: portfolioId, set: data.risks });
            })
            .catch(defaultCatch(dispatch));
    },
    addPrograms: (portfolioId: string, programIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Portfolio>(`api/portfolio/${portfolioId}/program`, { ids: programIds })
            .then(data => dispatch({ type: "UPDATE_PORTFOLIO_PROGRAMS", portfolioId, programIds: data.programIds, calculation: data.calculation }))
            .catch(defaultCatch(dispatch));
    },
    createProjectAndAddToPortfolio: (portfolioId: string, name: string, layoutId: string):
        AppThunkAction<KnownAction | CreateProjectSuccessAction> => (dispatch, getState) => {
            post<{ project: ProjectInfo, portfolio: Portfolio }>(`api/portfolio/${portfolioId}/project/new`, { name, layoutId })
                .then(data => {
                    dispatch(<CreateProjectSuccessAction>{
                        type: 'CREATE_PROJECT_SUCCESS',
                        project: data.project,
                        isNotSetActiveEntity: true
                    });
                    dispatch({
                        type: 'UPDATE_PORTFOLIO_PROJECTS',
                        portfolioId,
                        projectIds: [data.project.id],
                        calculation: data.portfolio.calculation,
                        isAdd: true
                    });
                })
                .catch(defaultCatch(dispatch));
        },
    createProgramAndAddToPortfolio: (portfolioId: string, name: string, layoutId: string):
        AppThunkAction<KnownAction | CreatedProgramAction> => (dispatch, getState) => {
            post<{ program: Program, portfolio: Portfolio }>(`api/portfolio/${portfolioId}/program/new`, { name, layoutId })
                .then(data => {
                    dispatch(<CreatedProgramAction>{
                        type: 'CREATED_PROGRAM',
                        program: data.program,
                        isNotSetActiveEntity: true
                    });
                    dispatch({
                        type: 'UPDATE_PORTFOLIO_PROGRAMS',
                        portfolioId,
                        programIds: [data.program.id],
                        calculation: data.portfolio.calculation,
                        isAdd: true
                    });
                })
                .catch(defaultCatch(dispatch));
        },
    getProgramsAndProjects: (portfolioId: string):
        AppThunkAction<KnownAction | RequestProgramsAction | ReceivedProgramsPartAction | RequestProjectsAction | ReceivedProjectsPartAction> => (dispatch, getState) => {
            get<{ programs: Program[], projects: ProjectInfo[] }>(`api/portfolio/${portfolioId}/programs`)
                .then(data => {
                    dispatch({ type: 'RECEIVED_PROGRAMS_PART', programs: data.programs });
                    dispatch({ type: 'RECEIVED_PROJECTS_PART', projects: data.projects });
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: 'REQUEST_PROGRAMS' });
            dispatch({ type: 'REQUEST_PROJECTS' });
        },
    bulkUpdate: (updates: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Portfolio[]>(`api/portfolio/BulkUpdate`, updates)
            .then(data => dispatch({ type: "BULK_UPDATE_PORTFOLIOS_SUCCESS", portfolios: data }))
            .catch(defaultCatch(dispatch));
    },
    updateImage: (portfolioId: string, logo: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const data = new FormData();
        data.set('image', logo);

        post<{ imageId: string }>(`api/portfolio/${portfolioId}/image`, data)
            .then(_ => dispatch({ type: 'UPDATE_PORTFOLIO_IMAGE', imageId: _.imageId, portfolioId: portfolioId }))
            .catch(defaultCatch(dispatch));
    },
    removeImage: (portfolioId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<void>(`api/portfolio/${portfolioId}/image`)
            .then(_ => dispatch({ type: 'UPDATE_PORTFOLIO_IMAGE', imageId: undefined, portfolioId: portfolioId }))
            .catch(defaultCatch(dispatch));
    },
    applyLayout: (portfolioId: string, layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Portfolio>(`api/portfolio/${portfolioId}/applyLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'RECEIVED_PORTFOLIO', portfolio: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Portfolio });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Portfolio });
    },
    applyLayoutMany: (portfolioIds: string[], layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Portfolio[]>(`api/portfolio/applyLayout/${layoutId}`, { ids: portfolioIds })
            .then(data => {
                dispatch({ type: 'BULK_UPDATE_PORTFOLIOS_SUCCESS', portfolios: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Portfolio });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Portfolio });
    },
    updateCalculation: (portfolioId: string, changes: Partial<PortfolioCalculation>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{calculation: PortfolioCalculation, updates: Dictionary<any>}>(`api/portfolio/${portfolioId}/calculation`, changes)
            .then(_ => dispatch({ type: 'UPDATE_PORTFOLIO_CALCULATION', portfolioId, ..._ }))
            .catch(defaultCatch(dispatch));
    },
    updatePriorityAlignment: (portfolioId: string, strategicPriorityId: string, impact: Impact):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Portfolio>(`api/portfolio/${portfolioId}/priorityAlignments`, { strategicPriority: { id: strategicPriorityId }, impact: impact })
                .then(data => dispatch({ type: 'RECEIVED_PORTFOLIO', portfolio: data }))
                .catch(defaultCatch(dispatch));
        },
    recalculateAlignmentScore: (portfolioId: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Portfolio>(`api/portfolio/${portfolioId}/recalculateAlignmentScore`, {})
                .then(data => dispatch({ type: 'RECEIVED_PORTFOLIO', portfolio: data }))
                .catch(defaultCatch(dispatch));
        },
    linkToO365Group: (portfolioId: string, linkData: ILinkDto<O365GroupLinkInfo>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Portfolio>(`api/portfolio/${portfolioId}/link/o365Group`, linkData)
            .then(data => dispatch({ type: 'RECEIVED_PORTFOLIO', portfolio: data }))
            .catch(defaultCatch(dispatch));
    },
    linkToTeamsChannel: (portfolioId: string, linkData: ILinkDto<TeamsChannelLink>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Portfolio>(`api/portfolio/${portfolioId}/link/teamsChannel`, linkData)
            .then(data => dispatch({ type: 'RECEIVED_PORTFOLIO', portfolio: data }))
            .catch(defaultCatch(dispatch));
    },
    deletePortfolioToExternalSystemLink: (portfolioId: string, connectionId: string, sourceType: ExternalEpmConnectStore.SourceType): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            remove<Portfolio>(`api/portfolio/${portfolioId}/link`, { connectionId, sourceType })
                .then(data => dispatch({ type: 'RECEIVED_PORTFOLIO', portfolio: data }))
                .catch(defaultCatch(dispatch));
        },
    requestAccess: (portfolioId: string, message?: string): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post(`api/portfolio/${portfolioId}/requestAccess`, { message })
            .then(_ => dispatch(NotificationsStore.actionCreators.pushNotification({ message: `Thank you for your request! Access will be provided as soon as possible.` })))
            .catch(defaultCatch(dispatch));
    },
    ...subentitiesActionCreators
};

const unloadedState: PortfoliosState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isListLoading: false,
    isListUpdating: false,
    isUpdatingSections: false,

    changeHistory: HISTORY_DEFAULT_STATE
};

const defaultReducer: Reducer<PortfoliosState> = (state: PortfoliosState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PORTFOLIOS':
            return {
                ...state,
                isLoading: true,
                isListLoading: true
            };
        case 'RECEIVED_PORTFOLIOS':
            return {
                ...state,
                ...StoreHelper.create(action.portfolios),
                isLoading: false,
                isListLoading: false
            };
        case 'RECEIVED_PORTFOLIO':
            if (state.activeEntity?.id === action.portfolio?.id) {
                action.portfolio.changeHistory = state.activeEntity.changeHistory;
            }

            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.portfolio),
                activeEntity: state.activeEntityId == action.portfolio.id ? action.portfolio : state.activeEntity,
                isLoading: false
            };
        case 'RECEIVED_PORTFOLIO_ATTRIBUTES':
            return {
                ...state,
                activeEntity: state.activeEntityId == action.data.id && state.activeEntity
                    ? {
                        ...state.activeEntity,
                        attributes: action.data.attributes,
                        warnings: action.data.warnings,
                        insights: action.data.insights,
                        keyDates: action.data.keyDates
                    }
                    : state.activeEntity
            };
        case 'CREATED_PORTFOLIO':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.portfolio),
                activeEntityId: action.portfolio.id,
                activeEntity: action.portfolio,
                isLoading: false
            };
        case 'CREATE_PORTFOLIO':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVED_REMOVE_PORTFOLIOS_RESULT':
            let newState = state;
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted && newState.byId[result.id]) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'LOAD_PORTFOLIO':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id == action.id ? state.activeEntity : undefined,
                isLoading: true
            }
        case 'UPDATE_PORTFOLIO_PROJECTS':
            {
                return StoreHelper.applyHandler(state,
                    action.portfolioId,
                    (portfolio: Portfolio) => Object.assign({},
                        portfolio,
                        {
                            projectIds: action.isAdd ? [...portfolio.projectIds, ...action.projectIds] : action.projectIds,
                            calculation: action.calculation
                        }));
            }
        case 'UPDATE_PORTFOLIO_PROGRAMS':
            {
                return StoreHelper.applyHandler(state,
                    action.portfolioId,
                    (portfolio: Portfolio) => Object.assign({},
                        portfolio,
                        {
                            programIds: action.isAdd ? [...portfolio.programIds, ...action.programIds] : action.programIds,
                            calculation: action.calculation
                        }));
            }
        case 'UPDATE_PORTFOLIO_KEYDATES':
            {
                return StoreHelper.applyHandler(state, action.portfolioId,
                    (portfolio: Portfolio) => partialUpdate(portfolio, {
                        keyDates: action.set ? action.set : addOrUpdateOrRemove(portfolio.keyDates, action.addOrUpdate, action.remove),
                        warnings: action.warnings
                    }));

            }
        case 'UPDATING_PORTFOLIO_SECTIONS':
            return {
                ...state,
                isUpdatingSections: true
            };
        case 'UPDATE_PORTFOLIO_SECTION_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.portfolioId,
                        (portfolio: Portfolio) => Object.assign({}, portfolio, { sections: action.sections })),
                    isUpdatingSections: false
                };
            }
        case 'UPDATE_PORTFOLIO_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.portfolioId,
                        (portfolio: Portfolio) => Object.assign({}, portfolio, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_UICONTROL_IN_PORTFOLIO_SUCCESS':
            {
                return StoreHelper.applyHandler(state, action.uiControlInfo.entityId,
                    (portfolio: Portfolio) => MetadataService.UpdateUIControlSettings(portfolio, action.uiControlInfo));
            }
        case 'BULK_UPDATE_PORTFOLIOS_SUCCESS':
            {
                return {
                    ...state,
                    ...StoreHelper.union(state, action.portfolios),
                    isLoading: false
                };
            }
        case 'UPDATE_PORTFOLIO_IMAGE':
            {
                return StoreHelper.applyHandler(state, action.portfolioId, (portfolio: Portfolio) => partialUpdate(portfolio, { imageId: action.imageId }));
            }
        case 'UPDATE_PORTFOLIO_CALCULATION':
            {
                return StoreHelper.applyHandler(state, action.portfolioId,
                    (portfolio: Portfolio) => partialUpdate(portfolio, { calculation: action.calculation, attributes: { ...portfolio.attributes, ...action.updates } }));
            }
        case 'UPDATE_PORTFOLIO_ACTIONITEMS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (portfolio: Portfolio) => partialUpdate(portfolio, {
                    actionItems: addOrUpdateOrRemove(portfolio.actionItems, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PORTFOLIO_KEYDECISIONS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (portfolio: Portfolio) => partialUpdate(portfolio, {
                    keyDecisions: addOrUpdateOrRemove(portfolio.keyDecisions, action.addOrUpdate, action.remove)
                }));
            }
        case "REMOVED_PORTFOLIOS_SOURCE_INFOS":
            {
                return {
                    ...state,
                    ...StoreHelper.applyHandler(state, state.allIds,
                        (porfolio: Portfolio) => partialUpdate(porfolio, { sourceInfos: porfolio.sourceInfos.filter(_ => _.connectionId !== action.connectionId) })),
                    activeEntity: state.activeEntity
                        ? { ...state.activeEntity, sourceInfos: state.activeEntity.sourceInfos.filter(_ => _.connectionId !== action.connectionId) }
                        : state.activeEntity
                };
            }
        case "UPDATE_PORTFOLIO_RISKS":
            {
                return StoreHelper.applyHandler(state, action.entityId, (portfolio: Portfolio) => partialUpdate(portfolio, {
                    risks: action.set ? action.set : addOrUpdateOrRemove(portfolio.risks, action.addOrUpdate, action.remove)
                }));
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state;
};

export const reducer: Reducer<PortfoliosState> = (state: PortfoliosState = unloadedState, incomingAction: Action) => {
    return defaultReducer(
        resourcePlanStore.reducer(
            historyStore.reducer(importExportReducer(state, incomingAction),
                incomingAction),
            incomingAction),
        incomingAction);
}

export const actionCreators = {
    ...defaultActionCreators,
    ...importExportActionCreators,
    ...historyStore.actionCreators,
    ...resourcePlanStore.actionCreators
}