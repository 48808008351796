import { get, post, remove } from './../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { defaultCatch } from './utils';
import { GroupInfo } from '../components/common/CreateGroupPanel';
import { Group } from "../entities/Metadata";
import { EntityType } from "../entities/common";
import { IEntityStore, StoreHelper } from './services/storeHelper';
import { Roadmap } from './roadmap/common';
import { LoadedRoadmapAction } from './RoadmapsListStore';

type WithGroups = { id: string, items: Group[] }
export interface GroupsState extends IEntityStore<WithGroups> {
    isLoading: boolean;
}

interface RequestGroupsAction {
    namespace: string,
    type: 'REQUEST_GROUPS';
    entityId: string;
}

export interface ReceivedGroupsAction {
    namespace: string,
    type: 'RECEIVED_GROUPS';
    groupsItems: Group[] | null;
    items?: any[];
    entityId: string;
}

export type KnownAction = RequestGroupsAction | ReceivedGroupsAction;

export const actionCreators = {
    forEntity: (namespace: string, entityType: EntityType) => {
        return {
            load: (entityId: string, connectionId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
                get<{ groups: Group[] | null }>(`api/${entityType}/${entityId}/${namespace}${connectionId ? `?connectionId=${connectionId}` : ""}`)
                    .then(data => dispatch({ namespace, type: 'RECEIVED_GROUPS', entityId, groupsItems: data.groups }))
                    .catch(defaultCatch(dispatch));
                dispatch({ namespace, entityId, type: 'REQUEST_GROUPS' });
            },
            create: (entityId: string, group: GroupInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
                post<Group[]>(`api/${entityType}/${entityId}/${namespace}`, group)
                    .then(data => dispatch({ namespace, type: 'RECEIVED_GROUPS', entityId, groupsItems: data }))
                    .catch(defaultCatch(dispatch));
            },
            update: (entityId: string, group: Group): AppThunkAction<KnownAction> => (dispatch, getState) => {
                post<Group[]>(`api/${entityType}/${entityId}/${namespace}/${group.id}`, group)
                    .then(data => dispatch({ namespace, type: 'RECEIVED_GROUPS', entityId, groupsItems: data }))
                    .catch(defaultCatch(dispatch));
            },
            remove: (entityId: string, groupId: string): AppThunkAction<KnownAction | LoadedRoadmapAction> => (dispatch, getState) => {
                remove<{ groups: Group[], items: any[], entity?: any }>(`api/${entityType}/${entityId}/${namespace}/${groupId}`)
                    .then(data => {
                        dispatch({ namespace, type: 'RECEIVED_GROUPS', entityId, groupsItems: data.groups, items: data.items });
                        // ToDo need to rework and delete
                        // needed for roadmap KPI update rolluped values after lane deletion
                        if (entityType === EntityType.Roadmap && data.entity){
                            dispatch({ type: 'LOADED_ROADMAP', roadmap: data.entity as Roadmap });
                        }
                    })
                    .catch(defaultCatch(dispatch));
            },
            reorder: (entityId: string, groupIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
                post<Group[]>(`api/${entityType}/${entityId}/${namespace}/reorder`, { ids: groupIds })
                    .then(data => dispatch({ namespace, type: 'RECEIVED_GROUPS', entityId, groupsItems: data }))
                    .catch(defaultCatch(dispatch));
            }
        };
    }
};

export function GroupsOperationsFactory(namespace: string, entity: EntityType) {
    const reducer: Reducer<GroupsState> = (state: GroupsState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;
        if (action.namespace !== namespace) {
            return state;
        }
        switch (action.type) {
            case 'REQUEST_GROUPS':
                return {
                    ...state,
                    ...StoreHelper.addOrUpdate(state, { id: action.entityId, items: [] as Group[] }),
                    isLoading: true
                };
            case 'RECEIVED_GROUPS':
                return {
                    ...state,
                    ...StoreHelper.addOrUpdate(state, { id: action.entityId, items: action.groupsItems }),
                    isLoading: false
                };
            default:
                return state;
        }
    };

    return {
        actionCreators: actionCreators.forEntity(namespace, entity),
        reducer
    };
}