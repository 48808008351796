import React, { PropsWithChildren } from 'react';

type Props = {
    label: string;
};

const TooltipField = (props: PropsWithChildren<Props>) => {
    const { label, children } = props;
    return (
        <div className="item align-center">
            <div className="label">{label}</div>
            {children}
        </div>
    );
};

export default TooltipField;
