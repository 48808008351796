import * as React from "react";
import "./HeaderLogoSidebarButton.css";
import { NavLink } from "react-router-dom";
import { TenantState } from "../../../store/Tenant";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { DefaultButton } from "office-ui-fabric-react";
import { imageIdToUrl, inIframe } from "../../utils/common";
import { UserState, actionCreators as userActionCreators } from "../../../store/User";
import { bindActionCreators, Dispatch } from "redux";
import { ErrorState } from "../../../store/ErrorStore";
import { NavigationProps } from "../Breadcrumbs";

type StateProps = {
    tenant: TenantState;
    user: UserState;
    error: ErrorState;
};

type ActionProps = {
    userActions: typeof userActionCreators;
};

type Props = NavigationProps & StateProps & ActionProps;

const HeaderLogoSidebarButton = (props: Props) => {
    const { tenant, user, userActions, error, route } = props;
    const isTenantAvailable = !!tenant.id;

    const showFull = user.preferences.expandSideBar;
    const onChangeSidebarType = () => {
        userActions.updateViewPreferences({ expandSideBar: !showFull });
    };

    const customLogoId = tenant.styleSettings?.logoId;
    const hideNavigation = !!error.title || route.hideNavigation || inIframe();

    return (
        <div className="header-logo-sidebar-container">
            {!hideNavigation && <DefaultButton
                className='change-sidebar-type-button'
                title={showFull ? 'Close menu' : 'Expand menu'}
                iconProps={{ iconName: showFull ? 'Cancel' : 'PPMXExpand' }}
                onClick={onChangeSidebarType}
            />}
            <NavLink
                to="/"
                className={`tenant-logo header-logo ${!customLogoId ? "default-logo" : ""} ${hideNavigation ? "hide-navigation" : ""}`}
                style={{
                    pointerEvents: inIframe() ? "none" : "auto",
                    backgroundImage: customLogoId ? `url(${imageIdToUrl(customLogoId)})` : undefined,
                }}
                onClick={(e) => { if (!isTenantAvailable) { e.preventDefault(); } }}
            />
        </div>
    );
};

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        tenant: state.tenant,
        user: state.user,
        error: state.error,
    };
}

function mergeActionCreators(dispatch: Dispatch): ActionProps {
    return {
        userActions: bindActionCreators(userActionCreators, dispatch),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(HeaderLogoSidebarButton);
