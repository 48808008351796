import * as React from 'react';
import { Icon, ISelectableOption } from 'office-ui-fabric-react';
import * as Metadata from '../../entities/Metadata';

const FieldOption = (props: ISelectableOption) => {
    const field = props.data as Metadata.Field;
    const icon = field ? getIconName(props.data) : undefined;
    return <>
        <Icon iconName={icon} className={`${field?.isCustom ? 'custom-field-icon' : ''}`}/>
        <span>{props.text}</span>
    </>
}

export default FieldOption;

export function getIconName(field: Metadata.Field): string | undefined {
    if (field.settings?.editControl === 'Dropdown') {
        return 'PPMXFieldSelect';
    }

    if (field.settings?.editControl === 'Slider') {
        return 'PPMXFieldSlider';
    }

    if (field.settings?.editControl === 'EnumSlider' && field.settings.className === 'impact') {
        return 'PPMXFieldImpact';
    }

    switch (field.type) {
        case Metadata.FieldType.Date:
        case Metadata.FieldType.DateTime: return 'PPMXFieldDate';
        case Metadata.FieldType.Integer: return getFormatIcon(field) ?? 'PPMXFieldInteger';
        case Metadata.FieldType.Decimal: return getFormatIcon(field) ?? 'PPMXFieldDecimal';
        case Metadata.FieldType.Flag: return 'PPMXFieldFlag';
        case Metadata.FieldType.Resource: return 'PPMXFieldResource';
        case Metadata.FieldType.User: return 'PPMXFieldUser';
        case Metadata.FieldType.Text: return 'PPMXFieldText';
        case Metadata.FieldType.Hyperlink: return 'PPMXFieldHyperlink';
        case Metadata.FieldType.Portfolio: return 'PPMXPortfolio';
        case Metadata.FieldType.Project: return 'PPMXProject';
        case Metadata.FieldType.Program: return 'PPMXProgram';
        case Metadata.FieldType.Challenge: return 'PPMXBusinessChallenge';
        case Metadata.FieldType.Task: return 'PPMXLogo';
        case Metadata.FieldType.Objective: return 'PPMXStrategicPriority';
        case Metadata.FieldType.Ref: return 'PPMXSectionDependencies';
        case Metadata.FieldType.Group: return 'GroupList';
        case Metadata.FieldType.Predecessor: return 'PPMXDependencyDependsOn';
        case Metadata.FieldType.Idea: return 'PPMXBusinessIdeas';
        default: return 'PPMXFieldText';
    }
}

function getFormatIcon(field: Metadata.Field) {
    const format = field.settings?.format;
    switch (format) {
        case Metadata.FormatType.Cost: return 'PPMXFieldFormatCost';
        case Metadata.FormatType.Days: return 'PPMXFieldFormatDays';
        case Metadata.FormatType.Duration: return 'PPMXFieldFormatDuration';
        case Metadata.FormatType.Percent: return 'PPMXFieldFormatPercent';
        default: return undefined;
    }
}