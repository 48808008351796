import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import * as TenantStore from '../../../store/Tenant';
import * as MetadataStore from '../../../store/MetadataStore';
import { ApplicationState } from '../../../store';
import { useDidMountEffect } from '../../utils/effects';
import { entityLogoConfig, EntityType, mapServerEntityType } from '../../../entities/common';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import LabellableComponent from '../../common/LabellableComponent';

type OwnProps = {};
type StateProps = {
    user: UserState;
    tenant: TenantStore.TenantState;
}
type ActionProps = {
    tenantActions: typeof TenantStore.actionCreators;
    metadataActions: typeof MetadataStore.actionCreators;
}
type Props = StateProps & OwnProps & ActionProps;

export const ResourcePlanningLevels = [EntityType.Project, EntityType.Program, EntityType.Portfolio];

const ResourcePlanningSettings = (props: Props) => {
    const canManage = contains(props.user.permissions.common, CommonOperations.Administrate);
    const [resourcePlanningLevel, setResourcePlanningLevel] = React.useState(mapServerEntityType[props.tenant.resourcePlanningSettings!.resourcePlanningLevel]!);
    const [promptResourcePlanningLevel, setPromptResourcePlanningLevel] = React.useState<EntityType>();

    useDidMountEffect(() => {
        props.tenantActions.updateResourcePlanningSettings(resourcePlanningLevel, props.metadataActions.expireMetadata);
    }, [resourcePlanningLevel]);

    const resourcePlanningLevelOptions = React.useMemo(() => ResourcePlanningLevels.map(_ => ({ text: entityLogoConfig[_]?.label!, key: _ })),
        [ResourcePlanningLevels]);

    const onResourcePlanningLevelsChanged = React.useCallback((ev: React.FormEvent<HTMLElement>, item: IChoiceGroupOption) =>
        setPromptResourcePlanningLevel(item.key as EntityType),
        [ResourcePlanningLevels]);

    return <div className="sync-settings resource-planning-settings">
        <LabellableComponent className="field-container" label="Resource Planning Level">
            <ChoiceGroup
                disabled={!canManage}
                selectedKey={resourcePlanningLevel}
                onChange={onResourcePlanningLevelsChanged}
                className="with-cb-in-row"
                options={resourcePlanningLevelOptions} />
        </LabellableComponent>
        {
            promptResourcePlanningLevel && <ConfirmationDialog
                onDismiss={() => setPromptResourcePlanningLevel(undefined)}
                onYes={() => promptResourcePlanningLevel && setResourcePlanningLevel(promptResourcePlanningLevel)}
                onNo={() => setPromptResourcePlanningLevel(undefined)}
                yesButtonProps={{ text: "Confirm" }}
                noButtonProps={{ text: "Cancel" }}
                dialogContentProps={{
                    title: 'Change Resource Planning level',
                    subText: `Are you sure you want to change the level on which Resource Planning will be performed? \
                    Once the level is changed, Resource Planning will appear on the newly selected level and disappear from the previous level.`
                }}>
                <MessageBar isMultiline={true} messageBarType={MessageBarType.warning}>
                    The data will not be deleted from the Resource Plans created on the previously selected level, it will be hidden from the application.
                </MessageBar>
            </ConfirmationDialog>
        }
    </div>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        metadataActions: bindActionCreators(MetadataStore.actionCreators, dispatch),
        tenantActions: bindActionCreators(TenantStore.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ResourcePlanningSettings);