import * as React from "react";
import { connect } from "react-redux";
import { TenantState } from "../../store/Tenant";
import { ApplicationState, EnviromentState } from "../../store/index";
import UserSignUpDialog from "./UserSignUpDialog";
import TenantSetup from "./TenantSetup";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { gtag } from "../../analytics";
import RequestDemo from "./RequestDemo";

type StatePtops = {
    tenant: TenantState;
    enviroment: EnviromentState;
}
type Props = StatePtops & RouteComponentProps<{ what: string }>;

const _gtag = (pathname: string) => gtag('event', 'page_view', { page_path: pathname });

const SetupScreen = (props: Props) => {

    React.useEffect(() => _gtag(props.location.pathname) , [props.location.pathname]);

    if (!props.tenant.id) {
        return  props.enviroment.disabledSignUp
            ? <Redirect to='/notenant' />
            : <TenantSetup />;
    }

    if (props.match.params.what === "demo") {
        return <RequestDemo />
    }
    
    return <div className='user-setup'>
        <UserSignUpDialog />
        <div className='wallpaper-container'>
            <div className='wallpaper'>
            </div>
        </div>
    </div>;
}

function mapStateToProps(state: ApplicationState): StatePtops {
    return {
        tenant: state.tenant,
        enviroment: state.enviroment
    }
}

export default withRouter(connect(mapStateToProps)(SetupScreen));