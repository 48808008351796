import * as React from 'react';

type Props = {
    label: string;
    value?: string;
    baseline?: string;
    variance?: string;
    isRed: boolean;
}

const BaselineRow = (props: Props) => <div className="item align-center">
    <div className="label">{props.label}</div>
    <div className="value">{props.baseline ?? '-'}</div>
    <div className={`value ${props.isRed ? 'red' : ''}`}>{props.value ?? '-'}</div>
    <div className="value">{props.variance ?? '-'}</div>
</div>;

export default BaselineRow;