import { ProgramCalculation } from "../ProgramsListStore";

export function getProjectsMetrics(programCalculation: ProgramCalculation) {
    const data = programCalculation.projectProgresses;
    const inProgress = data.InProgress || 0;
    const completed = data.Completed || 0;
    const notStarted = data.NotStarted || 0;
    const onHold = data.OnHold || 0;
    const canceled = data.Canceled || 0;
    const total = notStarted + inProgress + completed + onHold + canceled;
    return { inProgress, completed, notStarted, total };
}