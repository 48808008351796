import * as React from 'react';
import BackButton from '../navigation/BackButton';
import AccordionSection from '../common/AccordionSection';
import { DirectionalHint, Icon, TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import { UserState } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import { Redirect } from "react-router-dom";
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

export interface Section {
    key: string;
    name: string;
    className?: string;
    icon: string;
    onRenderBody: () => JSX.Element;
    tooltip?: string | JSX.Element | JSX.Element[];
}

type OwnProps = {
    title: string;
    className?: string;
    requiredPermission?: CommonOperations;
    sections?: Section[]
}

type StateProps = { user: UserState; }
type Props = OwnProps & StateProps
type State = { openSections: string[] }

class SettingsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            openSections: this.props.sections?.map(_ => _.key) || []
        };
    }

    public render() {
        const { user, requiredPermission, title, className, sections, children } = this.props;
        if (requiredPermission !== undefined && !contains(user.permissions.common, requiredPermission)) {
            return <Redirect to="/" />;
        }

        return <div className="page-settings">
            <div className="page-navigation">
                <BackButton />
            </div>
            <h2>{title}</h2>
            <div className={`scrollable ${className || ''}`}>
                {sections && sections.map(_ => this._renderAccordionSection(_))}
                {children}
            </div>
        </div>
    }

    private _onSectionStateChange = (section: Section) => {
        if (!!this.state.openSections.find(_ => _ === section.key)) {
            this.setState({ openSections: this.state.openSections.filter(_ => _ !== section.key) });
        } else {
            this.setState({ openSections: [...this.state.openSections, section.key] });
        }
    }

    private _renderAccordionSection = (section: Section): JSX.Element => {
        return (
            <AccordionSection
                key={section.key}
                className={`section settings-section ${section.className || ''}`}
                isOpen={!!this.state.openSections.find(_ => _ == section.key)}
                onExpand={() => this.setState({ openSections: [...this.state.openSections, section.key] })}
                onCollapse={() => this.setState({ openSections: this.state.openSections.filter(_ => _ != section.key) })}
                onRenderBody={section.onRenderBody}
                onRenderHeader={() => this._renderSectionHeader(section)} />
        );
    }

    private _renderSectionHeader = (section: Section) => {
        return (
            <div className="align-center">
                <div className="title align-center section-name" onClick={() => this._onSectionStateChange(section)}>
                    <span className="chevron"></span>
                    <Icon iconName={section.icon} />
                    <span>{section.name}</span>
                    {section.tooltip && <TooltipHost content={section.tooltip} delay={TooltipDelay.zero} hostClassName='tooltip' className='text-hint' calloutProps={{ directionalHint: DirectionalHint.rightCenter }}>
                        <Icon iconName="Info" />
                    </TooltipHost>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProp: OwnProps) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(SettingsPage);