import * as Metadata from "../../entities/Metadata";
import { get, post, remove } from "../../fetch-interceptor";
import { defaultCatch, catchApiError } from "../utils";
import { AppThunkAction, AppThunkAction as IAppThunkAction } from "../index";
import { Reducer, Action } from 'redux';
import {
    ConnectionsState,
    ExternalFieldInfo, IApiResult, IConnectionsState, IEntityStore, IImportProjectMap, IImportResourceMap,
    IImportResult, IListStore, IResourceSourceData, MapConfig, TaskImportSettings
} from "./common";
import { IDeletionResult } from "../services/storeHelper";
import { RemovedPortfoliosSourceInfosAction } from '../PortfoliosListStore';
import { RemovedProjectsSourceInfosAction } from '../ProjectsListStore';
import { RemovedResourcesSourceInfosAction } from '../ResourcesListStore';
import { Dictionary } from "../../entities/common";
import { ProjectsImportActions, ResourcesImportActions, Utils } from "./ImportStore";
import { FieldsService } from "../../components/common/FieldsService";

export interface IMondayComConnectionInfo {
    id?: string;
    personalAccessToken: string;
}
export enum MondayComFieldType {
    MultiplePerson = "people",
    Name = "name",
    Date = "date",
    Status = "status",
    Number = "numbers",
    Boolean = "checkbox",
    Email = "email",
    Dropdown = "dropdown",
    Text = "text",
    Link = "link",
    Hour = "hour",
    Phone = "phone",
    LastUpdated = "last_updated",
    Id = "item_id",
    Tag = "tags",
    Country = "country",
    TimeRangeStart = "timerange-start",
    TimeRangeFinish = "timerange-finish",
    TimeRangeIsMilestone = "timerange-is-milestone"
}


export const mondayComFieldToPpmxFieldsMap: { [i: string]: MapConfig } = {
    [MondayComFieldType.MultiplePerson]: { 
        types: [Metadata.FieldType.Resource, Metadata.FieldType.User],
        label: "People",
        validateField: (ppmxField: Metadata.Field, ppmxFieldType: Metadata.FieldType, label: string) => {
            const message = !ppmxField.settings?.multichoice
                ? `Unable to match field types single choice "${Metadata.fieldTypeMap[ppmxFieldType]}" and "${label}"`
                : undefined;
            return { isValid: !message, message };
        }
    },
    [MondayComFieldType.Date]: { types: [Metadata.FieldType.Date], label: "Date" },
    [MondayComFieldType.LastUpdated]: { types: [Metadata.FieldType.Date], label: "Last updated"},
    [MondayComFieldType.Number]: { types: [Metadata.FieldType.Decimal, Metadata.FieldType.Integer, Metadata.FieldType.Text], label: "Numbers" },
    [MondayComFieldType.Boolean]: { types: [Metadata.FieldType.Flag, Metadata.FieldType.Text ], label: "Checkbox" },
    [MondayComFieldType.Email]: { types: [Metadata.FieldType.Text ], label: "Email" },
    [MondayComFieldType.Hour]: { types: [Metadata.FieldType.Text], label: "Hour" },
    [MondayComFieldType.Dropdown]: {
        types: [Metadata.FieldType.Text],
        label: "Dropdown",
        validateField: (ppmxField: Metadata.Field) => ({ isValid: FieldsService.isDropDown(ppmxField) || FieldsService.isTag(ppmxField) })
    },
    [MondayComFieldType.Phone]: { types: [Metadata.FieldType.Text ], label: "Phone" },
    [MondayComFieldType.Status]: { types: [Metadata.FieldType.Text ], label: "Color Picker" },
    [MondayComFieldType.Link]: { types: [Metadata.FieldType.Hyperlink, Metadata.FieldType.Text ], label: "Link" },
    [MondayComFieldType.Tag]: { types: [Metadata.FieldType.Text ], label: "Tag" },
    [MondayComFieldType.Name]: { types: [ Metadata.FieldType.Text], label: "Name" },
    [MondayComFieldType.Text]: { types: [Metadata.FieldType.Text ], label: "Text" },
    [MondayComFieldType.Id]: { types: [Metadata.FieldType.Text ], label: "Text" },
    [MondayComFieldType.Country]: { types: [Metadata.FieldType.Text], label: "Text" },
    [MondayComFieldType.TimeRangeStart]: { types: [Metadata.FieldType.Date], label: "Timeline Start" },
    [MondayComFieldType.TimeRangeFinish]: { types: [Metadata.FieldType.Date], label: "Timeline Finish" },
    [MondayComFieldType.TimeRangeIsMilestone]: { types: [Metadata.FieldType.Flag], label: "Timeline IsMilestone" },
}

export interface IMondayComConnectionState extends IConnectionsState {
    refreshInfo: IConnectionRefreshInfo | null;
    createdConnectionId?: string;
}

export interface IConnectionRefreshInfo {
    targetUrl: string;
    account: string;
}

export interface IMondayComBaseSourceData {
    boardId: number;
    boardName: string;
    subItemsBoardId?: number;
    subItemsBoardName?: string;
    workspaceId: number;
    workspaceName: string;
}

export interface IMondayComSourceData extends IMondayComBaseSourceData {
    boardUrl: string;
}

export interface IMondayComBoard {
    boardId: number;
    boardName: string;
    workspaceId: number;
    workspaceName: string;
    isAlreadyLinked: boolean;
}

export interface IMondayComWorkspace {
    id: string;
    name: string;
}
export type MondayComUserKind = "person" | "team";
export interface IMondayComUserLinkInfo extends IResourceSourceData { 
    userId: number;
    userKind: MondayComUserKind;
    email: string;
}
export interface IMondayComUser {
    id: number;
    displayName: string;
    userKind: MondayComUserKind;
    email: string;
    isAlreadyLinked: boolean;
}

export interface IProgressCalculationSettings {
    types: string[];
    autoStatusCalculationTypes: string[];
}

export interface MondayComState {
    connections: IMondayComConnectionState;
    boards: IEntityStore<IMondayComBoard[]>;
    boardsByWorkspaceMap: Dictionary<IMondayComBoard[]>;
    workspaces: IMondayComWorkspace[];
    users: IListStore<IMondayComUser>;
    fieldsByBoardId: IEntityStore<{[k: number]: ExternalFieldInfo[]}>;
    subItemDefaultFields: IListStore<ExternalFieldInfo>;
    
    progressCalculationSettings: IEntityStore<IProgressCalculationSettings>;
    taskImportSettings: IEntityStore<TaskImportSettings>,
    defaultTaskSettings: IEntityStore<TaskImportSettings>
}

export interface IConnectionConfiguration {
    progressCalculationSettings: IProgressCalculationSettings;
    taskImportSettings: TaskImportSettings
}

export type LoadConnections = { type: "MONDAYCOM_LOAD_CONNECTIONS" }
export type ReceivedConnections = { type: "MONDAYCOM_RECEIVED_CONNECTIONS"; connections: Metadata.IConnectionInfo[]; }
type ConnectionCreatingOrRefreshing = { type: "MONDAYCOM_CONNECTION_CREATING_OR_REFRESHING", connectionId: string | undefined }
type ConnectionCreatedOrRefreshed = { type: "MONDAYCOM_CONNECTION_CREATED_OR_REFRESHED", connection: Metadata.IConnectionInfo, isRefreshing: boolean }
type ReceivedConnectionRefreshInfo = { type: "MONDAYCOM_RECEIVED_CONNECTION_REFRESH_INFO", info: IConnectionRefreshInfo }
type RemovedConnection = { type: "MONDAYCOM_CONNECTION_REMOVED", id: string }
type ConnectionOperationError = { type: "MONDAYCOM_CONNECTION_OPERATION_ERROR", error: string | null }

type VerifyConnection = { type: 'MONDAYCOM_VERIFY_CONNECTION', connectionId: string }
type ConnectionVerified = { type: 'MONDAYCOM_CONNECTION_VERIFIED', connectionId: string }
type ConnectionVerificationError = { type: 'MONDAYCOM_CONNECTION_VERIFICATION_ERROR', connectionId: string, error: string | null }

type ConnectionAction = LoadConnections
    | ReceivedConnections
    | ConnectionCreatingOrRefreshing
    | ConnectionCreatedOrRefreshed
    | RemovedConnection
    | ReceivedConnectionRefreshInfo
    | ConnectionOperationError
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError;

type LoadBoards = { type: "MONDAYCOM_LOAD_BOARDS" }
type ReceivedBoards = { type: "MONDAYCOM_RECEIVED_BOARDS", boards: IMondayComBoard[] }
type SetBoardsError = { type: "MONDAYCOM_SET_BOARDS_ERROR", error: string | null }

type LoadItemBoardFields = { type: "MONDAYCOM_LOAD_ITEMBOARD_FIELDS" }
type ReceivedItemBoardFields = { type: "MONDAYCOM_RECEIVED_ITEMBOARD_FIELDS", fields: {[k: number]: ExternalFieldInfo[]} }
type SetItemBoardFieldsError = { type: "MONDAYCOM_SET_ITEMBOARD_FIELDS_ERROR", error: string | null }

type LoadDefaultSubItemBoardFields = { type: "MONDAYCOM_LOAD_SUBITEMBOARD_DEFAULT_FIELDS" }
type ReceivedDefaultSubItemBoardFields = { type: "MONDAYCOM_RECEIVED_SUBITEMBOARD_DEFAULT_FIELDS", fields: ExternalFieldInfo[] }
type SetDefaultSubItemBoardFieldsError = { type: "MONDAYCOM_SET_SUBITEMBOARD_DEFAULT_FIELDS_ERROR", error: string | null }

type LoadConnectionConfiguration = { type: "MONDAYCOM_LOAD_CONNECTION_CONFIGURATION" }
type UpdateConnectionConfiguration = { type: "MONDAYCOM_UPDATE_CONNECTION_CONFIGURATION" }
type ReceivedConnectionConfiguration = { type: "MONDAYCOM_RECEIVED_CONNECTION_CONFIGURATION", config: IConnectionConfiguration }
type LoadConnectionConfigurationError = { type: "MONDAYCOM_LOAD_CONNECTION_CONFIGURATION_ERROR", error: string | null }

type LoadDefaultTaskMapping = { type: "MONDAYCOM_LOAD_DEFAULT_TASK_MAPPINGS" }
type ReceivedDefaultTaskMapping = { type: "MONDAYCOM_RECEIVED_DEFAULT_TASK_MAPPINGS", mappings: TaskImportSettings }
type SetDefaultTaskMappingError = { type: "MONDAYCOM_LOAD_DEFAULT_TASK_MAPPINGS_ERROR", error: string | null }

type LoadUsers = { type: "MONDAYCOM_LOAD_USERS" }
type ReceivedUsers = { type: "MONDAYCOM_RECEIVED_USERS", users: IMondayComUser[] }
type SetUsersError = { type: "MONDAYCOM_SET_USERS_ERROR", error: string | null }

type EntitiesAction = LoadBoards | ReceivedBoards | SetBoardsError |
    LoadItemBoardFields | ReceivedItemBoardFields | SetItemBoardFieldsError |
    LoadDefaultSubItemBoardFields | ReceivedDefaultSubItemBoardFields | SetDefaultSubItemBoardFieldsError |
    LoadConnectionConfiguration | ReceivedConnectionConfiguration | UpdateConnectionConfiguration | LoadConnectionConfigurationError |
    LoadDefaultTaskMapping | ReceivedDefaultTaskMapping | SetDefaultTaskMappingError |
    LoadUsers | ReceivedUsers | SetUsersError;

type KnownAction = ConnectionAction | EntitiesAction;

export const actionCreators = {
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_LOAD_CONNECTIONS' });
        get<Metadata.IConnectionInfo[]>(`api/integration/mondaycom/connection`)
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_CONNECTIONS', connections: _ }))
            .catch(defaultCatch(dispatch));
    },
    createOrRefreshConnection: (data: IMondayComConnectionInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_CONNECTION_CREATING_OR_REFRESHING', connectionId: data.id });
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/mondaycom/connection`, data)
            .then(_ => {
                if (_.errorMessage) {
                    dispatch({ type: 'MONDAYCOM_CONNECTION_OPERATION_ERROR', error: _.errorMessage });
                } else {
                    dispatch({ type: 'MONDAYCOM_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: !!data.id });
                }
            })
            .catch(defaultCatch(dispatch));
    },
    renameConnection: (connectionId: string, title: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/mondaycom/connection/${connectionId}/rename`, { title })
            .then(_ => dispatch({ type: 'MONDAYCOM_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: true }))
            .catch(defaultCatch(dispatch));
    },
    removeConnection: (id: string, callback?: () => void):
        AppThunkAction<KnownAction | RemovedPortfoliosSourceInfosAction | RemovedProjectsSourceInfosAction | RemovedResourcesSourceInfosAction> => (dispatch, getState) => {
            dispatch({ type: 'MONDAYCOM_CONNECTION_CREATING_OR_REFRESHING', connectionId: id });
            remove<IDeletionResult>(`api/integration/mondaycom/connection/${id}`)
                .then(_ => {
                    if (_.isDeleted) {
                        dispatch({ type: 'MONDAYCOM_CONNECTION_REMOVED', id: _.id });
                        dispatch({ type: "REMOVED_PORTFOLIOS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_PROJECTS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_RESOURCES_SOURCE_INFOS", connectionId: _.id });
                        callback?.();
                    } else {
                        dispatch({ type: 'MONDAYCOM_CONNECTION_OPERATION_ERROR', error: _.message || null });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    cleanError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_CONNECTION_OPERATION_ERROR', error: null });
    },
    loadRefreshInfo: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<IConnectionRefreshInfo>(`api/integration/mondaycom/connection/${connectionId}/refresh`)
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_CONNECTION_REFRESH_INFO', info: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadBoards: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_LOAD_BOARDS' });
        get<IMondayComBoard[]>(`api/integration/mondaycom/projects`, { connectionId })
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_BOARDS', boards: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'MONDAYCOM_SET_BOARDS_ERROR', error: `Unable to load Monday.com boards: ${_}` })));
    },
    loadBoardFields: (connectionId: string, boardIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_LOAD_ITEMBOARD_FIELDS' });
        post<{[k: number]: ExternalFieldInfo[]}>(`api/integration/mondaycom/fields/${connectionId}`, { ids: boardIds })
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_ITEMBOARD_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'MONDAYCOM_SET_ITEMBOARD_FIELDS_ERROR', error: `Unable to load Monday.com task board fields: ${_}` })));
    },
    loadSubItemsBoardDefaultFields: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_LOAD_SUBITEMBOARD_DEFAULT_FIELDS' });
        get<ExternalFieldInfo[]>(`api/integration/mondaycom/fields/subitem/default`)
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_SUBITEMBOARD_DEFAULT_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ 
                type: 'MONDAYCOM_SET_SUBITEMBOARD_DEFAULT_FIELDS_ERROR', 
                error: `Unable to load Monday.com subtask board default fields: ${_}` 
            })));
    },   
    loadConnectionConfiguration: (connectionId: string, boardId: number, subBoardId?: number | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_LOAD_CONNECTION_CONFIGURATION' });
        get<IConnectionConfiguration>(`api/integration/mondaycom/connectionConfiguration/${connectionId}/${boardId}`, { subBoardId })
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'MONDAYCOM_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load Monday.com connection configuration: ${_}` })));
    },
    updateConnectionConfiguration: (connectionId: string, boardId: number, configuration: IConnectionConfiguration): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(({ type: 'MONDAYCOM_UPDATE_CONNECTION_CONFIGURATION' }));
        post<IConnectionConfiguration>(`api/integration/mondaycom/connectionConfiguration/${connectionId}/${boardId}`, configuration )
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'MONDAYCOM_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load Monday.com mapping: ${_}` })));
    },    
    loadDefaultTaskMappings: (connectionId: string, taskBoardId: number, subTaskBoardId?: number | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_LOAD_DEFAULT_TASK_MAPPINGS' });
        get<TaskImportSettings>(`api/integration/mondaycom/defaultTaskMappings/${connectionId}`, {taskBoardId, subTaskBoardId})
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_DEFAULT_TASK_MAPPINGS', mappings: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'MONDAYCOM_LOAD_DEFAULT_TASK_MAPPINGS_ERROR', error: `Unable to load Monday.com default mappings settings: ${_}` })));
    },
    loadUsers: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_LOAD_USERS' });
        get<IMondayComUser[]>(`api/integration/mondaycom/resources`, { connectionId })
            .then(_ => dispatch({ type: 'MONDAYCOM_RECEIVED_USERS', users: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'MONDAYCOM_SET_USERS_ERROR', error: `Unable to load Monday.com resources: ${_}` })));
    },
    importProjects: (connectionId: string, maps: IImportProjectMap<IMondayComBoard>[]): AppThunkAction<ProjectsImportActions> => (dispatch, getState) => {
        const entityIds = maps.map(_ => `${_.linkingData.boardId}`);
        dispatch({ type: 'IMPORT_PROJECTS_STARTED', entityIds });

        Utils.batchSend(maps,
            batch => post<IImportResult[]>('api/integration/mondaycom/import/projects', { connectionId, maps: batch })
                .then(_ => dispatch({ type: 'IMPORT_PROJECTS_BATCH_FINISHED', results: _ })))
            .then(() => dispatch({ type: 'IMPORT_PROJECTS_FINISHED' }))
            .catch(defaultCatch(dispatch));
    },
    importResources: (connectionId: string, maps: IImportResourceMap<IMondayComUser>[]): AppThunkAction<ResourcesImportActions> => (dispatch, getState) => {
        dispatch({ type: 'IMPORT_RESOURCES_STARTED' });
        Utils.sendByChunks(maps, chunk => post<IImportResult[]>('api/integration/mondaycom/import/resources', { connectionId, maps: chunk }))
            .then(_ => dispatch({ type: 'IMPORT_RESOURCES_FINISHED', results: _ }))
            .catch(defaultCatch(dispatch));
    },
    verifyConnection: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MONDAYCOM_VERIFY_CONNECTION', connectionId });
        get(`api/integration/mondaycom/verify-connection`, { connectionId })
            .then(_ => dispatch({ type: 'MONDAYCOM_CONNECTION_VERIFIED', connectionId }))
            .catch(catchApiError(_ => dispatch({ type: 'MONDAYCOM_CONNECTION_VERIFICATION_ERROR', connectionId: connectionId, error: _ })));
    }
}

const connectionsReducer: Reducer<IMondayComConnectionState> = (state: IMondayComConnectionState, incomingAction: Action) => {
    const action = incomingAction as ConnectionAction;
    switch (action.type) {
        case 'MONDAYCOM_LOAD_CONNECTIONS':
            {
                return {
                    ...state,
                    isLoading: true,
                    connectionsVerification: { }
                }
            }
        case 'MONDAYCOM_RECEIVED_CONNECTIONS':
            {
                return {
                    ...state,
                    data: action.connections,
                    isLoading: false
                }
            }
        case 'MONDAYCOM_CONNECTION_CREATING_OR_REFRESHING':
            {
                return {
                    ...state,
                    isProcessing: true,
                    connectionsVerification: ConnectionsState.remove(state, action.connectionId)
                }
            }
        case "MONDAYCOM_CONNECTION_CREATED_OR_REFRESHED":
            {
                return {
                    ...state,
                    data: action.isRefreshing
                        ? state.data.map(_ => _.id === action.connection.id ? action.connection : _)
                        : state.data.concat(action.connection),
                    error: null,
                    createdConnectionId: action.isRefreshing ? undefined : action.connection.id,
                    isProcessing: false
                }
            }
        case "MONDAYCOM_CONNECTION_REMOVED":
            {
                return {
                    ...state,
                    data: state.data.filter(_ => _.id !== action.id),
                    connectionsVerification: ConnectionsState.remove(state, action.id),
                    error: null,
                    isProcessing: false
                }
            }
        case "MONDAYCOM_RECEIVED_CONNECTION_REFRESH_INFO":
            {
                return {
                    ...state,
                    refreshInfo: action.info
                }
            }
        case "MONDAYCOM_CONNECTION_OPERATION_ERROR":
            {
                return {
                    ...state,
                    error: action.error,
                    isProcessing: false
                }
            }
        case "MONDAYCOM_VERIFY_CONNECTION":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationStarting(state, action.connectionId)
                }
            }
        case "MONDAYCOM_CONNECTION_VERIFIED":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFinished(state, action.connectionId)
                }
            }
        case "MONDAYCOM_CONNECTION_VERIFICATION_ERROR":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFailed(state, action.connectionId, action.error)
                }
            }

        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

const entitiesReducer: Reducer<MondayComState> = (state: MondayComState, incomingAction: Action) => {
    const action = incomingAction as EntitiesAction;
    switch (action.type) {
        case 'MONDAYCOM_LOAD_USERS':
            {
                return {
                    ...state,
                    users: {
                        ...initState.users,
                        isLoading: true
                    }
                }
            }
        case 'MONDAYCOM_RECEIVED_USERS':
            {
                return {
                    ...state,
                    users: {
                        ...initState.users,
                        entities: action.users
                    }
                }
            }
        case 'MONDAYCOM_SET_USERS_ERROR':
            {
                return {
                    ...state,
                    users: {
                        ...initState.users,
                        error: action.error
                    }
                }
            }
        case 'MONDAYCOM_LOAD_BOARDS':
            {
                return {
                    ...state,
                    workspaces: initState.workspaces,
                    boardsByWorkspaceMap: initState.boardsByWorkspaceMap,
                    boards: {
                        ...initState.boards,
                        isLoading: true
                    }
                }
            }
        case 'MONDAYCOM_RECEIVED_BOARDS':
            {
                const boardsMap: Dictionary<IMondayComBoard[]> = {};
                const workspacesMap = new Map();
                action.boards.forEach(_ => {
                    boardsMap[_.workspaceId] = [...(boardsMap[_.workspaceId] || []), _];
                    workspacesMap.set(_.workspaceId, { id: _.workspaceId, name: _.workspaceName });
                });

                return {
                    ...state,
                    workspaces: Array.from(workspacesMap.values()),
                    boardsByWorkspaceMap: boardsMap,
                    boards: {
                        ...initState.boards,
                        entities: action.boards
                    }
                }
            }
        case 'MONDAYCOM_SET_BOARDS_ERROR':
            {
                return {
                    ...state,
                    boards: {
                        ...initState.boards,
                        error: action.error
                    },
                    workspaces: initState.workspaces
                }
            }                
        case 'MONDAYCOM_LOAD_ITEMBOARD_FIELDS':
            {
                return {
                    ...state,
                    fieldsByBoardId: {
                        ...initState.fieldsByBoardId,
                        isLoading: true
                    }
                }
            }
        case 'MONDAYCOM_RECEIVED_ITEMBOARD_FIELDS':
            {
                return {
                    ...state,
                    fieldsByBoardId: {
                        ...initState.fieldsByBoardId,
                        entities: action.fields
                    }
                }
            }
        case 'MONDAYCOM_SET_ITEMBOARD_FIELDS_ERROR':
            {
                return {
                    ...state,
                    fieldsByBoardId: {
                        ...initState.fieldsByBoardId,
                        error: action.error
                    }
                }
            }
        case 'MONDAYCOM_LOAD_SUBITEMBOARD_DEFAULT_FIELDS':
            {
                return {
                    ...state,
                    subItemDefaultFields: {
                        ...initState.subItemDefaultFields,
                        isLoading: true
                    }
                }
            }
        case 'MONDAYCOM_RECEIVED_SUBITEMBOARD_DEFAULT_FIELDS':
            {
                return {
                    ...state,
                    subItemDefaultFields: {
                        ...initState.subItemDefaultFields,
                        entities: action.fields
                    }
                }
            }
        case 'MONDAYCOM_SET_SUBITEMBOARD_DEFAULT_FIELDS_ERROR':
            {
                return {
                    ...state,
                    subItemDefaultFields: {
                        ...initState.subItemDefaultFields,
                        error: action.error
                    }
                }
            }           
        case "MONDAYCOM_RECEIVED_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, entities: action.config.progressCalculationSettings },
                    taskImportSettings: { ...initState.taskImportSettings, entities: action.config.taskImportSettings },
                }
            }
        case "MONDAYCOM_LOAD_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...initState.progressCalculationSettings, isLoading: true },
                    taskImportSettings: { ...initState.taskImportSettings, isLoading: true }
                }
            }
        case "MONDAYCOM_UPDATE_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    progressCalculationSettings: { ...state.progressCalculationSettings, isProcessing: true },
                    taskImportSettings: { ...state.taskImportSettings, isProcessing: true }
                }
            }
        case 'MONDAYCOM_LOAD_CONNECTION_CONFIGURATION_ERROR':
            {
                return {
                    ...state,
                    progressCalculationSettings: { 
                        ...initState.progressCalculationSettings, 
                        error: action.error 
                    }
                }
            }        
        case 'MONDAYCOM_LOAD_DEFAULT_TASK_MAPPINGS': 
            {
                return {
                    ...state,
                    defaultTaskSettings: { 
                        ...initState.defaultTaskSettings, 
                        isLoading: true 
                    }
                }
            }
        case "MONDAYCOM_RECEIVED_DEFAULT_TASK_MAPPINGS":
            {
                return {
                    ...state,
                    defaultTaskSettings: { 
                        ...initState.defaultTaskSettings, 
                        entities: action.mappings 
                    }
                }
            }
        case 'MONDAYCOM_LOAD_DEFAULT_TASK_MAPPINGS_ERROR':
            {
                return {
                    ...state,
                    defaultTaskSettings: { 
                        ...initState.defaultTaskSettings, 
                        error: action.error 
                    }
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}
const initState: MondayComState = {
    connections: {
        isLoading: false,
        isProcessing: false,
        data: [],
        refreshInfo: null,
        error: null,
        connectionsVerification: { }
    },
    users: {
        entities: [],
        isLoading: false,
        error: null,
    },
    boards: {
        entities: [],
        isProcessing: false,
        isLoading: false,
        error: null,
    },
    workspaces: [],
    boardsByWorkspaceMap: {},
    fieldsByBoardId: {
        entities: {},
        isLoading: false,
        isProcessing: false,
        error: null,
    },    
    subItemDefaultFields: {
        entities: [],
        isLoading: false,
        error: null,
    },    
    progressCalculationSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {
            types: [],
            autoStatusCalculationTypes: []
        },
        error: null
    },
    taskImportSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {},
        error: null
    },
    defaultTaskSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {},
        error: null
    },
}
export const reducer: Reducer<MondayComState> = (state: MondayComState = initState, incomingAction: Action) => {
    return {
        ...entitiesReducer(state, incomingAction),
        connections: connectionsReducer(state.connections, incomingAction)
    }
}