import * as React from 'react';
import { Link } from 'office-ui-fabric-react';
import { EntityType} from "../../entities/common";
import { IRoadmapItem, urlParamsBuilder } from '../../entities/Subentities';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getRoadmapItemIsPlanned } from '../../store/RoadmapsListStore';

type OwnProps = {
    entity: IRoadmapItem;
}
type Props = OwnProps & RouteComponentProps<{}>


class RoadmapItemLink extends React.Component<Props> {
    public render() {
        const { entity, location } = this.props;
        const query = new URLSearchParams(location.search);
        query.set(EntityType.RoadmapItem, entity.id);
        if (!getRoadmapItemIsPlanned(entity)) {
            query.set(urlParamsBuilder.viewType(EntityType.RoadmapItem), 'Details');
        }
        const entityLink = `${location.pathname}?${query.toString()}`;
        return <Link className="overflow-text" href={entityLink} target="_blank">
            {entity.attributes.Name}
        </Link>;
    }
}

export default withRouter<OwnProps>(RoadmapItemLink);
