import { Action, Reducer } from 'redux';
import { get, post, remove } from '../../fetch-interceptor';
import * as Metadata from "../../entities/Metadata";
import { AppThunkAction } from '../';
import { IConnectionsState, IImportResult, IImportProjectMap, IImportResourceMap, IListStore, IApiResult, ExternalFieldInfo, MapConfig, 
    IResourceSourceData, ConnectionsState } from "./common";
import { ProjectsImportActions, ResourcesImportActions, Utils } from "./ImportStore";
import { defaultCatch, catchApiError } from "../utils";
import { IDeletionResult } from '../services/storeHelper';
import { RemovedProjectsSourceInfosAction } from '../ProjectsListStore';
import { RemovedPortfoliosSourceInfosAction } from '../PortfoliosListStore';
import { RemovedResourcesSourceInfosAction } from '../ResourcesListStore';
import { InfoMessages } from '../../components/utils/common';

export enum O365FieldType {
    String = 'String',
    StringArray = 'StringArray',
    Date = 'Date',
    Flag = 'Flag',
    User = 'User',
    GroupArray = 'GroupArray',
}

export const o365FieldToPpmxFieldsMap: { [i: string]: MapConfig } = {
    [O365FieldType.String]: { types: [Metadata.FieldType.Text], label: "String" },
    [O365FieldType.StringArray]: { types: [Metadata.FieldType.Text], label: "String Array" },
    [O365FieldType.Date]: { types: [Metadata.FieldType.Date, Metadata.FieldType.Text], label: "Date" },
    [O365FieldType.Flag]: { types: [Metadata.FieldType.Flag, Metadata.FieldType.Text], label: "Flag" },
    [O365FieldType.User]: { types: [Metadata.FieldType.User, Metadata.FieldType.Resource], label: "User" },
    [O365FieldType.GroupArray]: { types: [Metadata.FieldType.Text], label: "Group Array" },
}

export interface IPlanInfo {
    id: string;
    title: string;
    owner: string;

    isAlreadyLinked: boolean;
}

export interface GroupInfo {
    id: string;
    title: string;
    email: string;

    isAlreadyLinked: boolean;
}

export interface OrganizationInfo {
    name: string;
}

export interface ChannelInfo {
    id: string;
    title: string;
    team: GroupInfo;
}

export interface ChannelTabInfo {
    id: string;
    displayName: string;
    url: string;
}

export interface TeamsAppInfo {
    id?: string;
    status: string | null,
    inProgress: boolean
}

export interface TeamsTabCreationSettings {
    connectionId: string;
    teamId: string;
    channelId: string;
    teamName?: string;
    channelName?: string;
    tabDisplayName: string;
    contentUrl: string;
}

export interface IO365UserLinkInfo extends IResourceSourceData {
    userId: string;
    account: string;
    email: string;
}

export interface IOffice365UserInfo {
    id: string;
    displayName: string;
    mail: string;
    userPrincipalName: string;
    isAlreadyLinked: boolean;
}

export interface IPlannerConnectionInfo extends Metadata.IConnectionInfo {
    connectionType?: ConnectionType;
}

export enum ConnectionType {
    Full = 0,
    RestrictedPlannerSync = 1,
    AzureADSync = 2
}

export interface IPlannerConnectionState extends IConnectionsState<IPlannerConnectionInfo> {}

export interface Office365State {
    plans: IListStore<IPlanInfo>;
    myGroups: IListStore<GroupInfo>;
    teams: IListStore<GroupInfo>;
    channels: IListStore<ChannelInfo>;
    tabs: IListStore<ChannelTabInfo>;
    users: IListStore<IOffice365UserInfo>;
    connections: IPlannerConnectionState;
    teamsApp: TeamsAppInfo;
    fields: IListStore<ExternalFieldInfo>;
    organizations: IListStore<OrganizationInfo>;
    groups: IListStore<GroupInfo>;
}

export const actionCreators = {
    loadPlans: (connectionId: string, groupId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_PLANS' });
        const path = groupId ? `group/${groupId}/plans` : 'projects';
        get<IPlanInfo[]>(`api/integration/office365/${path}`, { connectionId })
            .then(_ => dispatch({ type: 'RECEIVED_PLANS', plans: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_PLANS_ERROR', error: `Unable to load Planner plans: ${_}` })));
    },
    loadMyGroups: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_MY_GROUPS' });
        const connections = getState().office365.connections;
        const connection = connections?.data.find(_ => _.id === connectionId);
        if (connection?.connectionType === ConnectionType.RestrictedPlannerSync) {
            dispatch({ type: 'O365_SET_MY_GROUPS_ERROR', error: `Unable to load my Office 365 groups: ${InfoMessages.GroupsBasicConnectionPermissions}` });
            return;
        }
        get<GroupInfo[]>('api/integration/office365/myGroups', { connectionId })
            .then(_ => dispatch({ type: 'RECEIVED_MY_GROUPS', groups: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_MY_GROUPS_ERROR', error: `Unable to load my Office 365 groups: ${_}` })));
    },
    loadTeams: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_TEAMS' });
        get<GroupInfo[]>('api/integration/office365/teams', { connectionId })
            .then(_ => dispatch({ type: 'RECEIVED_TEAMS', teams: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_TEAMS_ERROR', error: `Unable to load Office 365 teams: ${_}` })));
    },
    loadChannels: (connectionId: string, teamId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_CHANNELS' });
        get<ChannelInfo[]>(`api/integration/office365/teams/${teamId}/channels`, { connectionId })
            .then(_ => dispatch({ type: 'RECEIVED_CHANNELS', channels: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_CHANNELS_ERROR', error: `Unable to load Office 365 channels: ${_}` })));
    },
    loadChannelTabs: (connectionId: string, teamId: string, channelId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_CHANNEL_TABS' });
        get<ChannelTabInfo[]>(`api/integration/office365/teams/${teamId}/channels/${channelId}/tabs`, { connectionId })
            .then(_ => dispatch({ type: 'RECEIVED_CHANNEL_TABS', tabs: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_CHANNEL_TABS_ERROR', error: `Unable to load Office 365 channel tabs: ${_}` })));
    },
    addTeamsTab: (settings: TeamsTabCreationSettings, callback: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TEAMS_APP_PROCESS' });
        post<string>(`api/integration/office365/teams/addTab`, { ...settings })
            .then(_ => dispatch({ type: 'TEAMS_APP_PROCESSED' }))
            .catch(catchApiError(_ => dispatch({ type: 'TEAMS_APP_ERROR', error: `Unable to add tab: ${_}` })))
            .then(_ => callback());
    },
    loadUsers: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_USERS' });
        get<IOffice365UserInfo[]>('api/integration/office365/resources', { connectionId })
            .then(_ => dispatch({ type: 'RECEIVED_USERS', users: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_USERS_ERROR', error: `Unable to load Office 365 users: ${_}` })));
    },
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_PLANNER_CONNECTIONS' });
        get<IPlannerConnectionInfo[]>(`api/integration/office365/connection`)
            .then(_ => dispatch({ type: 'RECEIVED_PLANNER_CONNECTIONS', connections: _ }))
            .catch(defaultCatch(dispatch));
    },
    renameConnection: (connectionId: string, title: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IApiResult<IPlannerConnectionInfo>>(`api/integration/office365/connection/${connectionId}/rename`, { title })
            .then(_ => dispatch({ type: 'PLANNER_CONNECTION_REFRESHED', connection: _.data }))
            .catch(defaultCatch(dispatch));
    },
    removeConnection: (id: string, callback?: () => void):
        AppThunkAction<KnownAction | RemovedPortfoliosSourceInfosAction | RemovedProjectsSourceInfosAction | RemovedResourcesSourceInfosAction> => (dispatch, getState) => {
            dispatch({ type: 'PLANNER_CONNECTION_OPERATION_EXECUTING', id });
            remove<IDeletionResult>(`api/integration/office365/connection/${id}`)
                .then(_ => {
                    if (_.isDeleted) {
                        dispatch({ type: 'PLANNER_CONNECTION_REMOVED', id: _.id });
                        dispatch({ type: "REMOVED_PORTFOLIOS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_PROJECTS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_RESOURCES_SOURCE_INFOS", connectionId: _.id });
                        callback?.();
                    } else {
                        dispatch({ type: 'PLANNER_CONNECTION_OPERATION_ERROR', error: _.message || null });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    importProjects: (connectionId: string, maps: IImportProjectMap<IPlanInfo>[]): AppThunkAction<ProjectsImportActions> => (dispatch, getState) => {
        const entityIds = maps.map(_ => _.linkingData.id);
        dispatch({ type: 'IMPORT_PROJECTS_STARTED', entityIds });

        Utils.batchSend(maps,
            batch => post<IImportResult[]>('api/integration/office365/import/projects', { connectionId, maps: batch })
                .then(_ => dispatch({ type: 'IMPORT_PROJECTS_BATCH_FINISHED', results: _ })))
            .then(() => dispatch({ type: 'IMPORT_PROJECTS_FINISHED' }))
            .catch(defaultCatch(dispatch));
    },
    importResources: (connectionId: string, maps: IImportResourceMap<IOffice365UserInfo>[]): AppThunkAction<ResourcesImportActions> => (dispatch, getState) => {
        dispatch({ type: 'IMPORT_RESOURCES_STARTED' });
        Utils.sendByChunks(maps, chunk => post<IImportResult[]>('api/integration/office365/import/resources', { connectionId, maps: chunk }))
            .then(_ => dispatch({ type: 'IMPORT_RESOURCES_FINISHED', results: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadFields: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'O365_LOAD_FIELDS' });
        get<ExternalFieldInfo[]>('api/integration/office365/fields')
            .then(_ => dispatch({ type: 'O365_RECEIVED_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_FIELDS_ERROR', error: `Unable to load Office 365 fields: ${_}` })));
    },
    loadOrganizations: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_ORGANIZATIONS' });
        get<OrganizationInfo[]>('api/integration/office365/organizations')
            .then(_ => dispatch({ type: 'RECEIVED_ORGANIZATIONS', organizations: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_ORGANIZATIONS_ERROR', error: `Unable to load Office 365 organizations: ${_}` })));
    },
    loadGroups: (organizationName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LOAD_GROUPS' });
        get<GroupInfo[]>('api/integration/office365/groups', { organizationName })
            .then(_ => dispatch({ type: 'RECEIVED_GROUPS', groups: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_SET_GROUPS_ERROR', error: `Unable to load Office 365 groups: ${_}` })));
    },
    verifyConnection: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'O365_VERIFY_CONNECTION', connectionId });
        get(`api/integration/office365/verify-connection`, { connectionId })
            .then(_ => dispatch({ type: 'O365_CONNECTION_VERIFIED', connectionId }))
            .catch(catchApiError(_ => dispatch({ type: 'O365_CONNECTION_VERIFICATION_ERROR', connectionId: connectionId, error: _ })));
    }
};

export interface LoadConnections {
    type: "LOAD_PLANNER_CONNECTIONS";
}
interface ConnectionOperationExecution {
    type: "PLANNER_CONNECTION_OPERATION_EXECUTING", 
    id: string;
}
type ConnectionRefreshed = {
    type: "PLANNER_CONNECTION_REFRESHED";
    connection: IPlannerConnectionInfo;
}
interface SetConnectionOperationError {
    type: "PLANNER_CONNECTION_OPERATION_ERROR";
    error: string | null;
}
interface RemovedConnection {
    type: "PLANNER_CONNECTION_REMOVED",
    id: string
}
export interface ReceivedConnections {
    type: "RECEIVED_PLANNER_CONNECTIONS";
    connections: IPlannerConnectionInfo[];
}
interface LoadPlans {
    type: "LOAD_PLANS";
}
interface ReceivedPlans {
    type: "RECEIVED_PLANS";
    plans: IPlanInfo[];
}
interface LoadMyGroups {
    type: "LOAD_MY_GROUPS";
}
interface ReceivedMyGroups {
    type: "RECEIVED_MY_GROUPS";
    groups: GroupInfo[];
}
interface LoadTeams {
    type: "LOAD_TEAMS";
}
interface ReceivedTeams {
    type: "RECEIVED_TEAMS";
    teams: GroupInfo[];
}
interface LoadOrganizations {
    type: "LOAD_ORGANIZATIONS";
}
interface ReceivedOrganizations {
    type: "RECEIVED_ORGANIZATIONS";
    organizations: OrganizationInfo[];
}
interface LoadGroups {
    type: "LOAD_GROUPS";
}
interface ReceivedGroups {
    type: "RECEIVED_GROUPS";
    groups: GroupInfo[];
}
interface LoadChannels {
    type: "LOAD_CHANNELS";
}
interface ReceivedChannels {
    type: "RECEIVED_CHANNELS";
    channels: ChannelInfo[];
}
interface LoadChannelTabs {
    type: "LOAD_CHANNEL_TABS";
}
interface ReceivedChannelTabs {
    type: "RECEIVED_CHANNEL_TABS";
    tabs: ChannelTabInfo[];
}
interface LoadUsers {
    type: "LOAD_USERS";
}
interface ReceivedUsers {
    type: "RECEIVED_USERS";
    users: IOffice365UserInfo[];
}
interface TeamsAppInit {
    type: "TEAMS_APP_INIT";
    id?: string;
}
interface TeamsAppProcess {
    type: "TEAMS_APP_PROCESS";
}
interface TeamsAppProcessed {
    type: "TEAMS_APP_PROCESSED";
}
interface SetPlansError { type: "O365_SET_PLANS_ERROR", error: string | null }
interface SetMyGroupsError { type: "O365_SET_MY_GROUPS_ERROR", error: string | null }
interface SetTeamsError { type: "O365_SET_TEAMS_ERROR", error: string | null }
interface SetOrganizationsError { type: "O365_SET_ORGANIZATIONS_ERROR", error: string | null }
interface SetGroupsError { type: "O365_SET_GROUPS_ERROR", error: string | null }
interface SetChannelsError { type: "O365_SET_CHANNELS_ERROR", error: string | null }
interface SetChannelTabsError { type: "O365_SET_CHANNEL_TABS_ERROR", error: string | null }
interface SetUsersError { type: "O365_SET_USERS_ERROR", error: string | null }
interface SetTeamsAppError { type: "TEAMS_APP_ERROR", error: string | null }

type LoadFields = { type: "O365_LOAD_FIELDS" }
type ReceivedFields = { type: "O365_RECEIVED_FIELDS", fields: ExternalFieldInfo[] }
type SetFieldsError = { type: "O365_SET_FIELDS_ERROR", error: string | null }

type VerifyConnection = { type: 'O365_VERIFY_CONNECTION', connectionId: string }
type ConnectionVerified = { type: 'O365_CONNECTION_VERIFIED', connectionId: string }
type ConnectionVerificationError = { type: 'O365_CONNECTION_VERIFICATION_ERROR', connectionId: string, error: string | null }

type KnownAction = LoadPlans
    | ReceivedPlans
    | LoadMyGroups
    | ReceivedMyGroups
    | LoadTeams
    | ReceivedTeams
    | LoadChannels
    | ReceivedChannels
    | LoadChannelTabs
    | ReceivedChannelTabs
    | LoadConnections
    | RemovedConnection
    | SetConnectionOperationError
    | ConnectionOperationExecution
    | ReceivedConnections
    | LoadUsers
    | ReceivedUsers
    | TeamsAppProcess
    | TeamsAppProcessed
    | TeamsAppInit
    | SetPlansError
    | SetMyGroupsError
    | SetTeamsError
    | SetChannelsError
    | SetChannelTabsError
    | SetUsersError
    | SetTeamsAppError
    | ConnectionRefreshed
    | LoadFields
    | ReceivedFields
    | SetFieldsError
    | LoadOrganizations
    | ReceivedOrganizations
    | SetOrganizationsError
    | LoadGroups
    | ReceivedGroups
    | SetGroupsError
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError;

const initState: Office365State = {
    connections: {
        isLoading: false,
        isProcessing: false,
        error: null,
        data: [],
        connectionsVerification: { }
    },
    plans: {
        entities: [],
        isLoading: false,
        error: null
    },
    myGroups: {
        entities: [],
        isLoading: false,
        error: null
    },
    teams: {
        entities: [],
        isLoading: false,
        error: null
    },
    channels: {
        entities: [],
        isLoading: false,
        error: null
    },
    tabs: {
        entities: [],
        isLoading: false,
        error: null
    },
    users: {
        entities: [],
        isLoading: false,
        error: null
    },
    teamsApp: {
        inProgress: false,
        status: null
    },
    fields: {
        entities: [],
        isLoading: false,
        error: null,
    },
    organizations: {
        entities: [],
        isLoading: false,
        error: null,
    },
    groups: {
        entities: [],
        isLoading: false,
        error: null
    },
};

export const reducer: Reducer<Office365State> = (state: Office365State = initState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "LOAD_PLANS":
            return {
                ...state,
                plans: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "RECEIVED_PLANS":
            return {
                ...state,
                plans: {
                    isLoading: false,
                    entities: action.plans,
                    error: null
                }
            }
        case 'O365_SET_PLANS_ERROR':
            {
                return {
                    ...state,
                    plans: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case "LOAD_MY_GROUPS":
            return {
                ...state,
                myGroups: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "RECEIVED_MY_GROUPS":
            return {
                ...state,
                myGroups: {
                    isLoading: false,
                    entities: action.groups,
                    error: null
                }
            }
        case 'O365_SET_MY_GROUPS_ERROR':
            return {
                ...state,
                myGroups: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case "LOAD_TEAMS":
            return {
                ...state,
                teams: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "RECEIVED_TEAMS":
            return {
                ...state,
                teams: {
                    isLoading: false,
                    entities: action.teams,
                    error: null
                }
            }
        case 'O365_SET_TEAMS_ERROR':
            return {
                ...state,
                teams: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case "LOAD_ORGANIZATIONS":
            return {
                ...state,
                organizations: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "RECEIVED_ORGANIZATIONS":
            return {
                ...state,
                organizations: {
                    isLoading: false,
                    entities: action.organizations,
                    error: null
                }
            }
        case 'O365_SET_ORGANIZATIONS_ERROR':
            return {
                ...state,
                organizations: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case "LOAD_GROUPS":
            return {
                ...state,
                groups: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "RECEIVED_GROUPS":
            return {
                ...state,
                groups: {
                    isLoading: false,
                    entities: action.groups,
                    error: null
                }
            }
        case 'O365_SET_GROUPS_ERROR':
            return {
                ...state,
                groups: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case "LOAD_CHANNELS":
            return {
                ...state,
                channels: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "RECEIVED_CHANNELS":
            return {
                ...state,
                channels: {
                    isLoading: false,
                    entities: action.channels,
                    error: null
                }
            }
        case 'O365_SET_CHANNELS_ERROR':
            return {
                ...state,
                channels: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case "LOAD_CHANNEL_TABS":
            return {
                ...state,
                tabs: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "RECEIVED_CHANNEL_TABS":
            return {
                ...state,
                tabs: {
                    isLoading: false,
                    entities: action.tabs,
                    error: null
                }
            }
        case 'O365_SET_CHANNEL_TABS_ERROR':
            return {
                ...state,
                tabs: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case "LOAD_PLANNER_CONNECTIONS":
            return {
                ...state,
                connections: {
                    data: Array.of<IPlannerConnectionInfo>(),
                    connectionsVerification: { },
                    isLoading: true,
                    isProcessing: false,
                    error: null
                },
            };
        case 'RECEIVED_PLANNER_CONNECTIONS':
            return {
                ...state,
                connections: {
                    data: action.connections,
                    connectionsVerification: { },
                    isLoading: false,
                    isProcessing: false,
                    error: null
                }
            }
        case "PLANNER_CONNECTION_REFRESHED":
            {
                return {
                    ...state,
                    connections: {
                        data: state.connections.data.map(_ => _.id === action.connection.id ? action.connection : _),
                        connectionsVerification: ConnectionsState.setVerificationFinished(state.connections, action.connection.id),
                        isLoading: false,
                        isProcessing: false,
                        error: null
                    }
                }
            }
        case 'PLANNER_CONNECTION_REMOVED':
            return {
                ...state,
                connections: {
                    data: state.connections.data.filter(_ => _.id !== action.id),
                    connectionsVerification: ConnectionsState.remove(state.connections, action.id),
                    isLoading: false,
                    isProcessing: false,
                    error: null
                }
            }
        case 'PLANNER_CONNECTION_OPERATION_EXECUTING':
            return {
                ...state,
                connections: {
                    data: state.connections.data,
                    connectionsVerification: ConnectionsState.remove(state.connections, action.id),
                    isLoading: false,
                    isProcessing: true,
                    error: null
                }
            }
        case 'PLANNER_CONNECTION_OPERATION_ERROR':
            return {
                ...state,
                connections: {
                    data: state.connections.data,
                    connectionsVerification: state.connections.connectionsVerification,
                    isLoading: false,
                    isProcessing: false,
                    error: action.error
                }
            }
        case "LOAD_USERS":
            return {
                ...state,
                users: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            }
        case "RECEIVED_USERS":
            return {
                ...state,
                users: {
                    isLoading: false,
                    entities: action.users,
                    error: null
                }
            }
        case 'O365_SET_USERS_ERROR':
            return {
                ...state,
                users: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case "TEAMS_APP_PROCESS":
            return {
                ...state,
                teamsApp: {
                    inProgress: true,
                    status: null
                }
            };
        case "TEAMS_APP_PROCESSED":
            return {
                ...state,
                teamsApp: {
                    inProgress: false,
                    status: null
                }
            };
        case "TEAMS_APP_INIT":
            return {
                ...state,
                teamsApp: {
                    id: action.id,
                    inProgress: false,
                    status: null
                }
            };
        case 'TEAMS_APP_ERROR':
            return {
                ...state,
                teamsApp: {
                    inProgress: false,
                    status: action.error
                }
            }

        case 'O365_LOAD_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'O365_RECEIVED_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: action.fields,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'O365_SET_FIELDS_ERROR':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case "O365_VERIFY_CONNECTION":
            {
                return {
                    ...state,
                    connections: {
                        ...state.connections,
                        connectionsVerification: ConnectionsState.setVerificationStarting(state.connections, action.connectionId)
                    }
                }
            }
        case "O365_CONNECTION_VERIFIED":
            {
                return {
                    ...state,
                    connections: {
                        ...state.connections,
                        connectionsVerification: ConnectionsState.setVerificationFinished(state.connections, action.connectionId)
                    }
                }
            }
        case "O365_CONNECTION_VERIFICATION_ERROR":
            {
                return {
                    ...state,
                    connections: {
                        ...state.connections,
                        connectionsVerification: ConnectionsState.setVerificationFailed(state.connections, action.connectionId, action.error)
                    }
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
};