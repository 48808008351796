import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { get } from './fetch-interceptor';
import ErrorPage from './components/ErrorPage';
import './Invite.css'
import { getErrorInfo } from './store/utils';
import { ErrorState } from './store/ErrorStore';
import { MessageBar, MessageBarType, Overlay } from 'office-ui-fabric-react';
import Spinner from './components/common/Spinner';
import { ApplicationState } from './store';
import { UserState } from './store/User';
import { TenantState } from './store/Tenant';
import { AuthProvider, AuthProvidersMap } from './entities/common';

type InviteModel = {
    tenantName: string;
    companyName: string;
    invite: string;
    logonAuthProvider: AuthProvider;
    logonAccount: string;
    inviteAuthProvider?: AuthProvider;
    inviteLogonAccount: string;
}

type State = {
    tenantName?: string;
    companyName?: string;
    invite?: string;
    logonAuthProvider?: AuthProvider;
    logonAccount?: string;
    inviteAuthProvider?: AuthProvider;
    inviteLogonAccount?: string;
    error?: ErrorState;
    isLoading?: boolean;
    isProcessing?: boolean;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
}
type Props = StateProps
    & RouteComponentProps<{ id: string, location: string }>;

class Invite extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        if (this.props.user.isSignedUp) {
            this.props.history.replace("/");
            return;
        }
        this.setState({ isLoading: true });
        get<InviteModel>(`api/invite/${this.props.match.params.id}`).then(
            _ => this.setState({ ..._, isLoading: false }),
            _ => this.setState({ isLoading: false, error: getErrorInfo(_.response.status) }));
    }

    render() {
        const { isLoading, isProcessing, error, tenantName, companyName, logonAccount, logonAuthProvider, inviteLogonAccount, inviteAuthProvider } = this.state;
        const { security } = this.props.tenant;

        if (isLoading) {
            return <Overlay>
                <Spinner />
            </Overlay>;
        }

        if (error?.title) {
            return <ErrorPage title={error.title} description={error.description} />
        }
        const signOutUrl = `account/signout?redirectUri=/@${this.props.tenant.url}${this.props.location.pathname}`;

        const renderAuthProviderDisabled = () => <>
            <MessageBar messageBarType={MessageBarType.blocked}>
                {`You are attempting to join with an authentication provider that is disabled on this tenant.`}
            </MessageBar>
            <a className="k-button" href={signOutUrl} title="Log in with another provider">
                Log in with another provider
            </a>
        </>;

        const renderAuthProviderMismatch = () => <>
            <MessageBar messageBarType={MessageBarType.blocked}>
                {`You are attempting to join with an authentication provider different from the one specified in your invitation.`}
            </MessageBar>
            <a className="k-button" href={signOutUrl} title="Log in with another provider">
                Log in with {AuthProvidersMap[inviteAuthProvider!].friendlyName}
            </a>
        </>;

        const renderLogonAccountMismatch = () => <>
            <MessageBar messageBarType={MessageBarType.warning}>
                {`You are attempting to join with an email different from the one specified in your invitation.
                    Please log in with another account and use email ${inviteLogonAccount} to access PPM Express again.
                    If you prefer to join with ${logonAccount}, please confirm by clicking Join below.`}
            </MessageBar>
            {renderGeneral()}
        </>;

        const renderGeneral = () => <>
            <a className="k-button primary" onClick={this._accept} title={`Join as ${logonAccount}`}>
                Join as <b>{logonAccount}</b>
            </a>
            <a className="k-button" href={signOutUrl} title="Log in with another account">
                Log in with another account
            </a>
        </>;
        const providerFriendlyName = logonAuthProvider && AuthProvidersMap[logonAuthProvider].friendlyName;
        return <>
            <div className="App signup">
                <div className="body">
                    <div className="invite-content">
                        <div className="logo-img"></div>
                        <div className="panel">
                            <div className="text">
                                You are invited to '{tenantName || companyName}'
                            </div>
                            {
                                inviteAuthProvider && logonAuthProvider !== inviteAuthProvider
                                    ? renderAuthProviderMismatch()
                                    : security?.allowedAuthProviders && logonAuthProvider && security.allowedAuthProviders.indexOf(logonAuthProvider) === -1
                                        ? renderAuthProviderDisabled()
                                        : logonAccount?.toLowerCase() !== inviteLogonAccount?.toLowerCase()
                                            ? renderLogonAccountMismatch()
                                            : renderGeneral()}
                            <div className="prefix">
                                <a href="https://ppm.express">Learn more about PPM Express</a>
                            </div>
                        </div>
                        <div className="postfix">
                            By joining you agree that the information from your {providerFriendlyName} account <b>{logonAccount}</b> will
                            be stored on PPM Express servers.
                        </div>
                    </div>
                </div>
            </div>
            {
                isProcessing &&
                <Overlay>
                    <Spinner />
                </Overlay>
            }
        </>;
    }

    private _accept = () => {
        this.setState({ isProcessing: true })
        get(`api/invite/accept/${this.state.invite}`).then(
            _ => window.location.href = "/",
            _ => {
                this.setState({ isProcessing: false });
                this.setState({
                    error: getErrorInfo(_.response.status)
                })
            });
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(Invite)
