import { Panel, PanelType } from "office-ui-fabric-react";
import { CalendlyEventListener, InlineWidget } from 'react-calendly';
import { UserState } from '../../store/User';
import * as React from 'react';
import { ApplicationState } from '../../store';
import { connect } from "react-redux";
import { PANEL_CUSTOM_WIDTH } from "../../entities/common";
import * as analytics from '../../analytics';

type StateProps = {
    user: UserState;
    calendlyEventPath: string;
}

type OwnProps = {
    headerText?: JSX.Element;
    headerDescription?: JSX.Element;
    onDismiss: () => void
}

type Props = OwnProps & StateProps

export class CalendlyPanel extends React.Component<Props> {

    private onDismiss = (): void => {
        this.props.onDismiss()
    };

    public render() {
        return <Panel
                isLightDismiss
                type={PanelType.custom}
                customWidth={PANEL_CUSTOM_WIDTH}
                onRenderHeader={this._onRenderHeader}
                className="calendly-panel"
                isOpen={true}
                onDismiss={this.onDismiss}>
            <div className="embed">
                <CalendlyEventListener>
                        <InlineWidget
                            pageSettings={{
                                hideEventTypeDetails: true,
                                hideLandingPageDetails: true
                            }}
                            url={this.props.calendlyEventPath}
                            prefill={{
                                email: this.props.user.email,
                                name: this.props.user.name
                            }} />
                    </CalendlyEventListener>
                </div>
            </Panel>
        
    }

    private _onRenderHeader = (): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">{this.props.headerText ?? "Request PPM Express Trial"}</p>
            <p className="ms-Panel-secondaryText">
                {this.props.headerDescription
                    ?? "Book a discovery call with our team to learn how it would integrate with YOUR system and start a Trial."}
            </p>
        </div>;
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        calendlyEventPath: state.analytics.calendlyEventPath
    }
}

export default (connect(mapStateToProps)(CalendlyPanel));