import { Action, Reducer } from 'redux';
import { Dictionary } from '../entities/common';
import { AppThunkAction } from './';

export type ExportDetails = { element?: HTMLElement, fileName: string, width?: number, rowsCount?: number, maxRowsCount?: number };
export type PngExportControlDetails = { isInProgress: boolean, disabledWithWarning: boolean }
export interface PngExporterState {
    controls: Dictionary<PngExportControlDetails>;
}

type StartExportAction = { type: 'START_EXPORT', controlId: string, isDisabled: boolean }
type FinishExportAction = { type: 'FINISH_EXPORT', controlId: string }
type KnownAction = StartExportAction | FinishExportAction;

export const actionCreators = {
    startExport: (controlId: string, rowsCount: number, maxRowsCount: number): AppThunkAction<KnownAction> =>
        (dispatch, getState) => dispatch({ type: 'START_EXPORT', controlId, isDisabled: rowsCount > maxRowsCount }),
    finishExport: (controlId: string): AppThunkAction<KnownAction> => (dispatch, getState) =>
        dispatch({ type: 'FINISH_EXPORT', controlId})
};

const initialState: PngExporterState = { controls: {} }; 

export const reducer: Reducer<PngExporterState> = (state: PngExporterState = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'START_EXPORT':
            return {
                controls: {
                    ...state.controls, [action.controlId]: { isInProgress: !action.isDisabled, disabledWithWarning: action.isDisabled }
                }
            };
        case 'FINISH_EXPORT': {
            const newControls = { ...state.controls };
            delete newControls[action.controlId];

            return {
                controls: newControls
            }
        }
        default:
            return state;
    }
};