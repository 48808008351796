import * as React from 'react';
import { connect } from 'react-redux';
import OAuthConnectionSelect from "../OAuthConnectionSelect";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { actionCreators } from "../../../store/integration/SmartsheetStore";
import { ApplicationState } from "../../../store/index";
import { IConnectionsState, validateConnection } from "../../../store/integration/common";
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ConnectionVerifier } from '../ConnectionSelect';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onChange?: (connectionId?: string) => void;
};

type StateProps = { connections: IConnectionsState; };
type Props = StateProps & typeof actionCreators & OwnProps;

class SmartsheetConnectionSelect extends React.Component<Props> {
    public render() {
        const { onChange, connections, connectionId, verifyConnection } = this.props;

        return <>
            <ConnectionVerifier
                connections={connections}
                connectionId={connectionId}
                verifyConnection={verifyConnection}>
                <OAuthConnectionSelect
                    key="connectionSelect"
                    disabled={connections.isLoading || !!this.props.disabled}
                    sourceType={SourceType.Smartsheet}
                    connectionId={connectionId}
                    connections={connections.data}
                    newConnectionUrl="/api/integration/smartsheet/auth"
                    getRefreshConnectionUrl={(cId) => `/api/integration/smartsheet/auth/connection/${cId}`}
                    systemName="smartsheet"
                    onReceivedConnectionData={(data) => this.acceptAccessToken(data.context?.connectionId)}
                    onConnectionChange={_ => {
                        if(_?.id){
                            verifyConnection(_.id);
                        }
                        onChange?.(_?.id)}
                    }
                    onConnectionRemove={(_) => this.props.removeConnection(_, () => onChange?.())}
                    onConnectionRename={(_, __) => this.props.renameConnection(_, __)}
                />
            </ConnectionVerifier>
            { this.props.connections.isLoading && <Spinner key="spinner" size={SpinnerSize.small} className="connection-select-spinner" /> }
        </>
    }

    componentWillMount() {
        this.props.loadConnections();
    }

    private acceptAccessToken = (connectionId: string) => {
        const { onChange, loadConnections } = this.props;

        loadConnections();
        onChange?.(connectionId);
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps) {
    return { connections: state.smartsheet.connections };
}

export default connect(mapStateToProps, actionCreators)(SmartsheetConnectionSelect);
