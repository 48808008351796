import * as React from 'react';
import { DEFAULT_ID, IUpdateLayoutInfo, IWithPinnedViews, IWithSections, Layout, Section } from '../../entities/Metadata';
import { nameof } from '../../store/services/metadataService';
import { EntityViewMenu, EntityViewMenuProps } from './EntityViewMenu';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../store/Sections';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { LayoutService } from '../utils/LayoutService';
import { useDidMount } from '../utils/effects';
import { IBaseEntity, IEditable } from '../../entities/common';

export type ControlSettings = { layoutId?: string };
type StateProps = {
    sectionsSettings?: ControlSettings;
    isLoading: boolean;
}
type OwnProps = Omit<EntityViewMenuProps, 'entityLayout' | 'pinnedViews'> & {
    entity: IBaseEntity & IEditable & IWithSections & IWithPinnedViews;

    profileLayoutId?: string;
    layoutIdByEntityId?: string;
    disableChangeView?: boolean;
};
type Props = OwnProps & StateProps & typeof actionCreators & RouteComponentProps<{}>;

export const PersistEntityViewMenu = (props: Props) => {
    const entityLayout = React.useMemo<Layout | undefined>(() => (props.entity.sections
        ? {
            id: DEFAULT_ID,
            sections: props.entity.sections.map(_ => ({ ..._ })),
            isView: true,
            isDefault: false,
            name: 'Default'
        }
        : undefined),
        [props.entity.sections]);

    const userPredefinedLayoutId = getUserDefaultLayoutId(props);

    const canChangeView = props.readonlyMode || props.entity.isEditable || !userPredefinedLayoutId;

    const userPredefinedLayout = userPredefinedLayoutId ?
        userPredefinedLayoutId !== LayoutService.DefaultLayoutId
            ? props.layoutsState.byId[userPredefinedLayoutId]
            : entityLayout
        : undefined;

    const viewLayout = canChangeView && !props.disableChangeView && props.viewLayout
        ? (layout: Layout) => {
            props.viewLayout?.(layout);

            props.saveSettings?.({
                immediate: true,
                parentSettingsPathKeys: [props.entity.id],
                valueBySettingNameMap: { [nameof<ControlSettings>("layoutId")]: layout.id }
            }, true);

            props.history.replace({ ...props.location, search: '' })
        }
        : undefined;
    const saveLayout = props.saveLayout
        ? (layoutId: string | undefined, sections: Section[], update: IUpdateLayoutInfo) => {
            props.saveLayout?.(layoutId, sections, update, (newLayoutId: string) => viewLayout?.(props.layoutsState.byId[newLayoutId]));
        }
        : undefined;
    const initMarker = React.useRef(false);
    const didMount = useDidMount();
    React.useEffect(() => {
        if (initMarker.current === true) {
            return;
        }

        if (!canChangeView) {
            if (userPredefinedLayout) {
                props.viewLayout?.(userPredefinedLayout);
            } else {
                entityLayout && props.viewLayout?.(entityLayout);
            }

            initMarker.current = true;
            return;
        }

        if (props.isLoading || !didMount && !props.sectionsSettings?.layoutId) {
            return;
        }

        if (!props.sectionsSettings?.layoutId && userPredefinedLayout) {
            props.viewLayout?.(userPredefinedLayout);
            initMarker.current = true;
            return;
        }

        if (props.layoutsState.activeEntityId === props.sectionsSettings?.layoutId) {
            initMarker.current = true;
            return;
        }

        if (!props.sectionsSettings?.layoutId || props.sectionsSettings.layoutId === DEFAULT_ID) {
            entityLayout && props.viewLayout?.(entityLayout);
            initMarker.current = true;
            return;
        }

        const layout = props.layoutsState.byId[props.sectionsSettings.layoutId];
        if (layout && (props.entity.isEditable || props.entity.pinnedViews.indexOf(layout.id) !== -1)) {
            props.viewLayout?.(layout);
            initMarker.current = true;
            return;
        }

        entityLayout && props.viewLayout?.(entityLayout);
        initMarker.current = true;
    }, [props.sectionsSettings?.layoutId, props.layoutsState, props.isLoading, userPredefinedLayout, canChangeView, didMount]);
    return <EntityViewMenu
        {...props}
        pinnedViews={props.entity.pinnedViews}
        entityIsEditable={props.entity.isEditable}
        viewLayout={viewLayout}
        saveLayout={saveLayout}
        entityLayout={entityLayout}
    />
}

const getUserDefaultLayoutId = (props: Props) => {
    if (props.layoutIdByEntityId === LayoutService.DefaultLayoutId) {
        return props.layoutIdByEntityId;
    }
    if (props.layoutIdByEntityId && props.layoutsState.byId[props.layoutIdByEntityId]) {
        return props.layoutIdByEntityId;
    }
    if (props.profileLayoutId === LayoutService.DefaultLayoutId) {
        return props.profileLayoutId;
    }
    if (props.profileLayoutId && props.layoutsState.byId[props.profileLayoutId]) {
        return props.profileLayoutId;
    }
    return !!props.layoutIdByEntityId || !!props.profileLayoutId ? LayoutService.DefaultLayoutId : undefined;
}

const mapStateToProps = (state: ApplicationState, props: OwnProps): StateProps => {
    return ({
        sectionsSettings: state.sections.settings?.[props.entity.id],
        isLoading: state.sections.isLoading[props.entity.id]
    });
}

export default withRouter<OwnProps>(connect(mapStateToProps, actionCreators)(PersistEntityViewMenu));