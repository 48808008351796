import "./VSTSProjectsImport.css";
import * as analytics from '../../../analytics';
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IVSTSProject, actionCreators, IWorkItemType, IVSTSWorkItem, IBaseVSTSSourceData, IVSTSConnectionState, WorkItem } from "../../../store/integration/VSTSStore";
import { ApplicationState } from "../../../store/index";
import { notUndefined, toDictionaryById, waitForFinalEvent } from "../../utils/common";
import { Dictionary, ServerEntityType } from "../../../entities/common";
import { IDropdownOption, ISelectableOption, IColumn, arraysEqual, Selection, DirectionalHint, MessageBarType, DefaultButton, SearchBox, TextField } from "office-ui-fabric-react";
import { post } from "../../../fetch-interceptor";
import { catchApiError } from "../../../store/utils";
import { ProjectsImportProps } from "./ProjectsImportPanel";
import DropdownInput from "../../common/inputs/DropdownInput";
import { ProjectInfo, actionCreators as projectsActions } from "../../../store/ProjectsListStore";
import { Program, actionCreators as programsActions } from "../../../store/ProgramsListStore";
import { IFormInputComponent } from "../../common/interfaces/IFormInputComponent";
import { EntityChevron } from '../../common/extensibleEntity/EntityGroupHeader';
import { nameof } from '../../../store/services/metadataService';
import { BaseFilterAttribute } from "../../common/FilterAttributes/BaseFilterAttribute";
import OptionsPicker, { Option } from "../../common/inputs/OptionsPicker";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { StatusFormatter } from "../common/StatusFormatter";
import { EditableCell } from "../common/EditableCell";
import { UserState } from "../../../store/User";
import { validateConnection, loadDataAfterConnectionValidation } from "../../../store/integration/common";
import { getValidationError } from "./common";
import { PPMFeatures, Subscription } from "../../../store/Tenant";

const subitemBaseOutlineLevel = 2;
enum ImportAs {
    NotSet = 'notset',
    Project = 'project',
    Program = 'program'
}
const ImportAsMap: Dictionary<string> = {
    [ImportAs.NotSet]: "",
    [ImportAs.Program]: "Program",
    [ImportAs.Project]: "Project",
}
const ImportAsMapOptions: IDropdownOption[] = Object.keys(ImportAsMap).map(_ => ({ key: _, text: ImportAsMap[_] }))

type EntityToImport = {
    id: string,
    parentId?: string,
    entityType: ServerEntityType,
    entityId?: string,
    entityName?: string,
    connectionId: string,
    linkingData: IBaseVSTSSourceData
}
type ImportResult = {
    id: string,
    entityType: ServerEntityType,
    entityId: string,
    sourceData: IBaseVSTSSourceData
    isSuccessful: boolean,
    error?: string
}

enum ImportStatus {
    NotLinked = 0,
    LinkedAsProject = 1,
    LinkedAsProgram = 2,
    Linked = 3
}

const ImportStatusMap: { [k: number]: { label: string, cssClass: string } } = {
    [ImportStatus.NotLinked]: { label: "not linked", cssClass: "not-linked" },
    [ImportStatus.LinkedAsProgram]: { label: "linked as program", cssClass: "linked" },
    [ImportStatus.LinkedAsProject]: { label: "linked as project", cssClass: "linked" },
    [ImportStatus.Linked]: { label: "linked", cssClass: "linked" },
}

type BreakdownEntity = { key: string, text: string, iconUrl?: string }

type BreakDownByTypes = 'project' | 'workitem' | 'iteration' | 'area' | 'all_workitems' | 'all_iterations' | 'all_areas';
const groupRowTypes: BreakDownByTypes[] = ['all_workitems', 'all_iterations', 'all_areas'];

type BreakdownEntities = { entities: ImportMap[], groupRow: ImportMap }
type Breakdown = { workitems: BreakdownEntities, iterations: BreakdownEntities, areas: BreakdownEntities };
type BreakdownByProjectId = { [projectId: string]: { entities: Breakdown, isCollapsed: boolean } };
type Filter = {
    name?: string;
    states?: string[];
    tags?: string[];
    iterations?: string[];
    areas?: string[];
};
type ImportMapWithIndex = { id: string, index: number, map: ImportMap }
type ImportMap = {
    type: BreakDownByTypes,
    uniqueId: string,
    externalId?: string,
    name: string,
    state?: string,
    iteration?: string,
    area?: string,
    status?: ImportStatus,
    linkedEntityId?: string,
    importAs: ImportAs,
    breakdownBy: string[],
    sourceData: IBaseVSTSSourceData,
    outlineLevel: number,
    processId?: string,
    workitemType?: string,
    isCollapsed?: boolean;
    isParent?: boolean;
    parentId?: string;
    tags?: string[];
}
type OwnProps = {
    connectionId: string;
    onRender: (props: ProjectsImportProps) => JSX.Element;
    onDismiss: () => void;
}

type StateProps = {
    user: UserState;
    projects: ProjectInfo[];
    programs: Program[];
    vstsProjects: IVSTSProject[];
    processWorkItemTypes: Dictionary<IWorkItemType[]>,
    isLoading: boolean;
    isImporting: boolean;
    error: string | null;
    connections: IVSTSConnectionState;
    hasPortfolioManagement: boolean;
}
type ActionsProps = {
    vstsActions: typeof actionCreators,
    projectsActions: typeof projectsActions,
    programsActions: typeof programsActions,
};
type Props = OwnProps & StateProps & ActionsProps;
type State = {
    itemsCount: number,
    selectedCount: number,
    processWorkItemTypes: Dictionary<Dictionary<IWorkItemType>>,
    entities: ImportMap[];
    projects: Dictionary<{ id: string, sourceInfos: { connectionId: string; sourceData: any; type: SourceType; }[] }>;
    programs: Dictionary<{ id: string, sourceInfos: { connectionId: string; sourceData: any; type: SourceType; }[] }>;
    entitiesBreakDown: {
        entities: BreakdownByProjectId,
        isLoading: boolean,
        error: string | null
    },
    search?: string,
    filter: Filter,
    filteredMaps: ImportMap[],
    states: IDropdownOption[],
    tags: IDropdownOption[],
    iterations: IDropdownOption[],
    areas: IDropdownOption[],
    message?: { text: string, type: MessageBarType },
    isImporting?: boolean
}

const typesToExclude = ['Task', 'Bug', 'Test Case', 'Test Plan', 'Test Suite'];
const columnsWidth = {
    name: 450,
    nameWithState: 350,
    state: 100,
    status: 135,
    importAs: 100,
    breakdown: 100
}

class VSTSProjectImport extends React.Component<Props, State> {
    private _selection: Selection<ImportMap>;
    private _prevSelection: string[];
    constructor(props: Props) {
        super(props);
        this.state = {
            itemsCount: 0,
            selectedCount: 0,
            processWorkItemTypes: {},
            entities: [],
            entitiesBreakDown: { entities: {}, isLoading: false, error: null },
            filter: {},
            filteredMaps: [],
            states: [],
            tags: [],
            iterations: [],
            areas: [],
            programs: {},
            projects: {}
        };
        this._prevSelection = [];
        this._selection = new Selection({
            getKey: (item: ImportMap, index?: number) => item.uniqueId,
            onSelectionChanged: this._onSelectionChanged,
            canSelectItem: this._canSelect
        });
    }
    private _canSelect = (item: ImportMap) => item.status !== ImportStatus.LinkedAsProgram && item.status !== ImportStatus.LinkedAsProject && item.status !== ImportStatus.Linked;
    private _onSelectionChanged = () => {
        const selection = this._selection.getSelection();
        if (!arraysEqual(this._prevSelection, selection.map(_ => _.uniqueId))) {
            const selected = this._ensureCheckedMaps(selection);
            this.setState({ selectedCount: Object.values(selected).filter(_ => !groupRowTypes.includes(_)).length });

            this._setSelection(Object.keys(selected));
        }
    }

    private _resetGroupRowSelection = () => {
        const keys = this._selection.getSelection().filter(_ => !groupRowTypes.includes(_.type)).map(_ => _.uniqueId);
        this._setSelection(keys);
    }

    private _setSelection = (selectedKeys: string[]) => {
        this._prevSelection = selectedKeys;
        this._selection.setChangeEvents(false);
        this._selection.setAllSelected(false);
        this._prevSelection.forEach(_ => this._selection.setKeySelected(_, true, true));
        this._selection.setChangeEvents(true);
    }

    render() {
        const connectionValidation = validateConnection(this.props.connections, this.props.connectionId);

        return this.props.onRender({
            label: "Items",
            connectionId: this.props.connectionId,
            isLoading: connectionValidation?.isValidating || this.props.isLoading,
            error: getValidationError(connectionValidation) ?? (this.props.error || this.state.entitiesBreakDown.error),
            onImport: this._onImport,
            isProcessing: this.state.entitiesBreakDown.isLoading || this.state.isImporting,
            newImportGridProps: {
                className: "vsts",
                message: this.state.message,
                onMessageDismiss: () => this.setState({ message: undefined }),
                onImport: this._onImport,
                itemsCount: this.state.itemsCount,
                selectedItemsCount: this.state.selectedCount,
                maps: this._filterMapsByIsCollapsed(),
                getKey: (_: ImportMap) => _.uniqueId,
                columns: this._buildColumns(),
                selection: this._selection,
                isImportEnabled: this.state.selectedCount > 0,
                onSearch: (search?: string) => {
                    const { filter, entities, entitiesBreakDown } = this.state;
                    this.setState({ search, ...this._buildFilteredMaps(search, filter, entities, entitiesBreakDown.entities) }, () => this._resetGroupRowSelection());
                },
                search: this.state.search,
                searchPlaceholder: "Search project",
                onRenderFilter: workitemFilter(
                    this.state.filter,
                    { states: this.state.states, areas: this.state.areas, iterations: this.state.iterations, tags: this.state.tags },
                    Object.keys(this.state.entitiesBreakDown.entities).length > 0,
                    (filter: Filter) => {
                        const { search, entities, entitiesBreakDown } = this.state;
                        this.setState({ filter, ...this._buildFilteredMaps(search, filter, entities, entitiesBreakDown.entities) }, () => this._resetGroupRowSelection());
                    })
            }
        });
    }

    componentWillMount() {
        const { connectionId, isImporting } = this.props;
        if (!isImporting && connectionId) {
            this.props.vstsActions.verifyConnection(connectionId);
            this.props.projectsActions.requestProjects();
            if (this.props.hasPortfolioManagement) {
                this.props.programsActions.requestPrograms();
            }
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { processWorkItemTypes, vstsProjects, projects, programs } = this.props;

        loadDataAfterConnectionValidation(
            this.props.connections, 
            nextProps.connections, 
            nextProps.connectionId,
            (_)=> {
                this.props.vstsActions.loadProjects(_);
                this.props.vstsActions.loadProcessWorkItemTypes(_);
        });

        if (processWorkItemTypes !== nextProps.processWorkItemTypes) {
            const newProcessWorkItemTypes = Object.keys(nextProps.processWorkItemTypes)
                .reduce((acc, _) => {
                    acc[_] = nextProps.processWorkItemTypes[_].reduce((a, __) => { a[__.name] = __; return a; }, {} as Dictionary<IWorkItemType>);
                    return acc;
                }, {} as Dictionary<Dictionary<IWorkItemType>>);

            this.setState({ processWorkItemTypes: newProcessWorkItemTypes })
        }

        if (vstsProjects !== nextProps.vstsProjects || programs !== nextProps.programs || projects !== nextProps.projects) {
            const maps = [...nextProps.vstsProjects]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map<ImportMapWithIndex>((_, index) => {
                    const sourceData = { projectId: _.id, projectName: _.name, workItems: _.workItems };
                    const id = this._buildUniqueId(sourceData);
                    return {
                        index,
                        id,
                        map: {
                            type: 'project',
                            externalId: _.id,
                            name: _.name,
                            processId: _.processId,
                            sourceData,
                            uniqueId: id,
                            breakdownBy: [],
                            importAs: ImportAs.NotSet,
                            status: _.isAlreadyLinked ? ImportStatus.Linked : ImportStatus.NotLinked,
                            index: 0,
                            outlineLevel: 0,
                        }
                    };
                });

            const entities = this._toImportMap(maps)
            this.setState({
                programs: toDictionaryById(nextProps.programs),
                projects: toDictionaryById(nextProps.projects),
                entities,
                ...this._buildFilteredMaps(this.state.search, this.state.filter, entities, {})
            });
        }
    }

    private _toImportMap = (items: ImportMapWithIndex[]): ImportMap[] => {
        const { connectionId } = this.props;
        const { programs, projects } = this.state;
        const maps = toDictionaryById(items);

        Object.values(projects).forEach(_ => {
            _.sourceInfos.forEach(__ => {
                const entity = maps[this._buildUniqueId(__.sourceData)];
                if (__.connectionId === connectionId && entity) {
                    entity.map.status = ImportStatus.LinkedAsProject;
                    entity.map.importAs = ImportAs.Project;
                    entity.map.linkedEntityId = _.id;
                }
            })
        });

        Object.values(programs).forEach(_ => {
            _.sourceInfos.forEach(__ => {
                const entity = maps[this._buildUniqueId(__.sourceData)];
                if (__.connectionId === connectionId && entity) {
                    entity.map.status = ImportStatus.LinkedAsProgram;
                    entity.map.importAs = ImportAs.Program;
                    entity.map.linkedEntityId = _.id;
                }
            })
        });

        return Object.values(maps).sort((a, b) => a.index > b.index ? 1 : -1).map(_ => _.map);
    }
    private _buildGroupRowUniqueId = (breakDown: keyof Breakdown, item: ImportMap) => `${item.sourceData.projectId}_${breakDown}_group_row`;

    private _buildFilteredMaps = (
        search: string | undefined,
        filter: Filter,
        entities: ImportMap[],
        breakdownByProjectId: BreakdownByProjectId,
        expandAll?: boolean
    ): { filteredMaps: ImportMap[], itemsCount: number } => {
        const selectedItemsSet = this._selection.getSelection().reduce((acc, _) => { acc.add(_.uniqueId); return acc; }, new Set<string>())

        const filteredMaps = entities.reduce((acc, item) => {
            let workitemMaps: ImportMap[] = [];

            const breakdownBy = breakdownByProjectId[item.sourceData.projectId];
            if (!item.isCollapsed && item.sourceData && breakdownBy) {
                const { workitems } = breakdownBy.entities
                if (workitems) {
                    workitemMaps = expandAll
                        ? workitems.entities
                        : workitems.groupRow.isCollapsed
                            ? []
                            : this._isWorkitemFilterEmpty(filter)
                                ? workitems.entities
                                : this._calculateFilteredWorkitemsTree(workitems.entities, _ => this._workitemFilter(filter, selectedItemsSet, _));
                }
            }

            const projectMaps = this._projectFilter(search, item)
                ? [
                    item,
                    workitemMaps.length > 0 ? breakdownBy.entities.workitems.groupRow : undefined,
                    ...workitemMaps
                ].filter(notUndefined).map(_ => ({ ..._, isCollapsed: false }))
                : [];

            return [...acc, ...projectMaps];
        }, Array<ImportMap>());

        return { filteredMaps, itemsCount: filteredMaps.filter(_ => !groupRowTypes.includes(_.type)).length };
    }

    private _isWorkitemFilterEmpty = (filter: Filter): boolean => !filter.name && !filter.states && !filter.iterations && !filter.areas && !filter.tags;
    private _workitemFilter = (filter: Filter, selectedItemsUniqueIdsSet: Set<string>, map: ImportMap): boolean =>
        ((!filter.name || map.name.toLowerCase().includes(filter.name.toLowerCase()) || map.externalId === filter.name)
            && (!filter.states || (!!map.state && !!filter.states.includes(map.state)))
            && (!filter.iterations || (!!map.iteration && !!filter.iterations.includes(map.iteration))))
        && (!filter.areas || (!!map.area && !!filter.areas.includes(map.area)))
        && (!filter.tags || (!!filter.tags.some(_ => !!map.tags && map.tags.includes(_))))
        || selectedItemsUniqueIdsSet.has(map.uniqueId);
    private _projectFilter = (filter: string | undefined, map: ImportMap): boolean => !filter || map.name.toLowerCase().includes(filter.toLowerCase())

    private _calculateFilteredWorkitemsTree = (items: ImportMap[], filter: (_: ImportMap) => boolean): ImportMap[] => {
        const buildMapByParentId = (array: ImportMap[]) => array.reduce<Dictionary<ImportMap[]>>((acc, _) => {
            if (_.parentId) {
                acc[_.parentId] = acc[_.parentId] ? [...acc[_.parentId], _] : [_];
            };
            return acc;
        }, {});

        const mapByParentId = buildMapByParentId(items);
        const witsMap = items.filter(filter).reduce<{ [witId: number]: ImportMap }>((acc, _) => { acc[_.sourceData.workItems[0]?.id!] = _; return acc; }, {});
        const result: ImportMap[] = [];
        items.filter(_ => _.outlineLevel === subitemBaseOutlineLevel)
            .forEach(_ => this._calculateChildHierarchy(_.sourceData.workItems[0]?.id!, _.parentId!, subitemBaseOutlineLevel, mapByParentId, witsMap, result))

        const resultByParentId = buildMapByParentId(result)
        return result.map(_ => ({ ..._, isParent: !!resultByParentId[this._buildWorkitemParentId(_.sourceData.workItems[0]?.id!)] }));
    }
    private _calculateChildHierarchy = (
        id: number,
        parentId: string,
        outlineLevel: number,
        mapByParentId: Dictionary<ImportMap[]>,
        witsMap: { [witId: number]: ImportMap },
        result: ImportMap[]
    ) => {
        const map = witsMap[id];
        if (map) {
            result.push({ ...map, parentId, outlineLevel });
        }

        mapByParentId[id]?.map(_ => ({
            id: _.sourceData.workItems[0]?.id!,
            parentId: map ? this._buildWorkitemParentId(id) : parentId,
            outlineLevel: map ? outlineLevel + 1 : outlineLevel
        }))
            .forEach(_ => this._calculateChildHierarchy(_.id, _.parentId, _.outlineLevel, mapByParentId, witsMap, result))
    }
    private _buildWorkitemParentId = (id: number) => `${id}`;
    private _buildUniqueId = (sourceData: IBaseVSTSSourceData): string => {
        const { projectId, workItems, areaPath, iterationPath, teamId } = sourceData;
        const witId = workItems ? workItems[0]?.id : undefined;
        return [projectId, getKey(witId), getKey(teamId), getKey(iterationPath), getKey(areaPath)].join('_');
    }

    private _onImport = () => {
        const entitiesToImport = this._getEntititesToImport();
        this.setState({ isImporting: true });

        post<{ connectionId: string, maps: ImportResult[] }>('api/integration/vsts/import/programsandprojects', {
            connectionId: this.props.connectionId,
            maps: entitiesToImport
        }).then(result => {
            const { entitiesBreakDown, search, filter, projects, programs } = this.state;
            const projectUniqueIdToEntityIdMap = result.maps.filter(_ => _.entityType === ServerEntityType.Project && result.connectionId === this.props.connectionId)
                .reduce<Dictionary<string>>((acc, _) => {
                    acc[this._buildUniqueId(_.sourceData)] = _.entityId;
                    return acc;
                }, {});
            const programUniqueIdToEntityIdMap = result.maps.filter(_ => _.entityType === ServerEntityType.Program && result.connectionId === this.props.connectionId)
                .reduce<Dictionary<string>>((acc, _) => {
                    acc[this._buildUniqueId(_.sourceData)] = _.entityId;
                    return acc;
                }, {});

            const entities = this.state.entities.map(_ => {
                this._updateStatus(_, programUniqueIdToEntityIdMap, projectUniqueIdToEntityIdMap)
                return _;
            });
            const breakdownByProjectId = entitiesBreakDown.entities;

            Object.keys(breakdownByProjectId).forEach(projectId => {
                const breakdown = breakdownByProjectId[projectId].entities
                breakdown.workitems.entities.forEach(_ => this._updateStatus(_, programUniqueIdToEntityIdMap, projectUniqueIdToEntityIdMap));
            });

            const importedProjectsCount = Object.values(projectUniqueIdToEntityIdMap).filter(_ => !projects[_]).length;
            const importedProgramsCount = Object.values(programUniqueIdToEntityIdMap).filter(_ => !programs[_]).length;

            const messageBuilder = (entity: string, count: number) => `${(count > 0 ? ` ${count} ${entity}${count > 1 ? 's' : ''}` : '')}`;
            const messageText = `You imported 
                ${importedProgramsCount > 0 && importedProjectsCount > 0
                    ? `${messageBuilder("Program", importedProgramsCount)} and ${messageBuilder("Project", importedProjectsCount)}`
                    : importedProjectsCount > 0
                        ? messageBuilder("Project", importedProjectsCount)
                        : importedProgramsCount > 0
                            ? messageBuilder("Program", importedProgramsCount)
                            : ""}`;

            result.maps.forEach(_ => {
                if (_.entityType === ServerEntityType.Program && result.connectionId === this.props.connectionId) {
                    programs[_.entityId] = {
                        id: _.entityId,
                        sourceInfos: [{ connectionId: result.connectionId, sourceData: _.sourceData, type: SourceType.VSTS }]
                    };
                }
                if (_.entityType === ServerEntityType.Project && result.connectionId === this.props.connectionId) {
                    projects[_.entityId] = {
                        id: _.entityId,
                        sourceInfos: [{ connectionId: result.connectionId, sourceData: _.sourceData, type: SourceType.VSTS }]
                    };
                }
            });

            this.setState({
                isImporting: false,
                message: {
                    type: MessageBarType.success,
                    text: messageText
                },
                programs,
                projects,
                entities,
                ...this._buildFilteredMaps(search, filter, entities, breakdownByProjectId)
            })
            this._selection.setAllSelected(false);
        }).catch(_ => this.setState({ isImporting: false }));

        analytics.trackImport('Import Items from Azure DevOps', this.props.user, { count: entitiesToImport.length });
    }

    private _updateStatus = (item: ImportMap, programUniqueIds: Dictionary<string>, projectUniqueIds: Dictionary<string>): void => {
        item.linkedEntityId = programUniqueIds[item.uniqueId] || projectUniqueIds[item.uniqueId];
        item.status = programUniqueIds[item.uniqueId]
            ? ImportStatus.LinkedAsProgram
            : projectUniqueIds[item.uniqueId]
                ? ImportStatus.LinkedAsProject
                : item.status;
    }

    private _buildProgramEntityToImport = (connectionId: string, _: ImportMap): EntityToImport => ({
        id: _.uniqueId,
        entityType: ServerEntityType.Program,
        entityId: _.linkedEntityId,
        entityName: _.name,
        connectionId,
        linkingData: _.sourceData
    })

    private _getEntititesToImport = (): EntityToImport[] => {
        const { connectionId } = this.props;
        const selectedItemUniqueIdSet = this._selection.getSelection().reduce((acc, _) => { acc.add(_.uniqueId); return acc; }, new Set<string>());
        const selectedProgramsInCurrentSubtree: ImportMap[] = [];

        const dic = this.state.filteredMaps.reduce<Dictionary<EntityToImport>>((acc, _) => {
            if (selectedProgramsInCurrentSubtree.length > 0
                && selectedProgramsInCurrentSubtree[selectedProgramsInCurrentSubtree.length - 1].outlineLevel >= _.outlineLevel) {
                selectedProgramsInCurrentSubtree.pop();
            }

            if (_.importAs === ImportAs.Program || _.status === ImportStatus.LinkedAsProgram) {
                selectedProgramsInCurrentSubtree.push(_);

                if (selectedItemUniqueIdSet.has(_.uniqueId) && _.importAs === ImportAs.Program && _.status === ImportStatus.NotLinked) {
                    acc[_.uniqueId] = this._buildProgramEntityToImport(connectionId, _);
                }
            } else if (selectedItemUniqueIdSet.has(_.uniqueId) && _.importAs === ImportAs.Project && _.status === ImportStatus.NotLinked) {
                const parentProgram = selectedProgramsInCurrentSubtree[selectedProgramsInCurrentSubtree.length - 1];
                if (parentProgram && !acc[parentProgram.uniqueId]) {
                    acc[parentProgram.uniqueId] = this._buildProgramEntityToImport(connectionId, parentProgram);
                }
                acc[_.uniqueId] = {
                    id: _.uniqueId,
                    parentId: parentProgram?.uniqueId,
                    entityName: _.name,
                    entityType: ServerEntityType.Project,
                    entityId: _.linkedEntityId,
                    connectionId,
                    linkingData: _.sourceData,
                };
            }
            return acc;
        }, {});
        return Object.values(dic);
    }

    private _getBreakdownOptions = (item: ImportMap): BreakdownEntity[] =>
        item.type === 'project' && item.processId && this.state.processWorkItemTypes[item.processId]
            ? Object.values(this.state.processWorkItemTypes[item.processId])
                ?.sort((a: IWorkItemType, b: IWorkItemType) => a.name.localeCompare(b.name))
                .filter(_ => typesToExclude.indexOf(_.name) === -1)
                .map<BreakdownEntity>(_ => ({ key: _.name, text: _.name, iconUrl: _.icon?.url }))
            : [];

    private _onBreakdown = (item: ImportMap, breakdown?: string[]) => {
        if (!item.sourceData) { return };

        const { search, filter, entities, entitiesBreakDown } = this.state;
        const projectId = item.sourceData.projectId;
        if (!breakdown || breakdown.length === 0) {
            const breakdownByProjectId = entitiesBreakDown.entities;
            delete breakdownByProjectId[projectId];
            const newFilter: Filter = Object.keys(breakdownByProjectId).length === 0 ? {} : filter;

            this.setState({
                filter: newFilter,
                ...this._buildFilteredMaps(search, newFilter, entities, breakdownByProjectId),
                entitiesBreakDown: { entities: breakdownByProjectId, isLoading: false, error: null }
            });

            return;
        }

        const areaIndex = breakdown.indexOf('area');
        if (areaIndex !== -1) {
            breakdown.splice(areaIndex, 1);
            //load araas
        }
        const iterationIndex = breakdown.indexOf('iteration');
        if (iterationIndex !== -1) {
            breakdown.splice(iterationIndex, 1);
            //load iterations
        }
        this._breakdownByWorkitemTypes(item, breakdown);
    }

    private _breakdownByWorkitemTypes = (item: ImportMap, workItemTypes: string[]) => {
        if (!item.sourceData || workItemTypes.length === 0) {
            return;
        }
        const { connectionId } = this.props;
        const projectId = item.sourceData.projectId;

        post<IVSTSWorkItem[]>(`api/integration/vsts/workitemstree`, { connectionId, projectId, workItemTypes })
            .then(_ => {
                const workitems = _
                    .map<ImportMapWithIndex>((__, index) => {
                        const { entitiesBreakDown } = this.state;
                        const entities = entitiesBreakDown.entities[item.sourceData.projectId]?.entities?.workitems?.entities || [];
                        const dic = new Dictionary<ImportMap>();
                        entities.forEach(e => dic[e.uniqueId] = e);

                        const sourceData = { 
                            projectId: item.sourceData.projectId,
                            projectName: item.sourceData.projectName,
                            workItems: [{ id: __.id, name: __.name, type: __.type }] as WorkItem[]
                        };
                        const id = this._buildUniqueId(sourceData);
                        return {
                            index,
                            id,
                            map: {
                                type: 'workitem',
                                externalId: `${__.id}`,
                                name: __.name,
                                state: __.state,
                                iteration: __.iteration,
                                area: __.area,
                                tags: __.tags,
                                workitemType: __.type,
                                processId: item.processId,
                                sourceData,
                                uniqueId: id,
                                breakdownBy: [],
                                importAs: dic[id]?.importAs || ImportAs.NotSet,
                                status: dic[id]?.status || (__.isAlreadyLinked ? ImportStatus.Linked : ImportStatus.NotLinked),
                                outlineLevel: subitemBaseOutlineLevel + (__.outlineLevel || 0),
                                isParent: __.isParent,
                                parentId: __.id !== __.parentId ? this._buildWorkitemParentId(__.parentId!) : undefined
                            }
                        };
                    });
                this._updateEntity(item, __ => __.isParent = workitems.length > 0);
                this._setBreakdownEntities('workitems', item, this._toImportMap(workitems));
            })
            .catch(catchApiError(_ => this.setState({ entitiesBreakDown: { ...this.state.entitiesBreakDown, isLoading: false, error: `Unable to load DevOps workitems: ${_}` } })));

        this.setState({ entitiesBreakDown: { ...this.state.entitiesBreakDown, isLoading: true, error: null } });
    }

    private _updateEntity = (item: ImportMap, applyChanges: (entity: ImportMap) => void, callback?: () => void) => {
        const { filteredMaps } = this.state;
        const filteredItem = filteredMaps.find(_ => _.uniqueId === item.uniqueId);
        if (filteredItem) {
            applyChanges(filteredItem);
        }
        if (item.type === 'project') {
            const entities = this.state.entities;
            const entity = entities.find(_ => _.uniqueId === item.uniqueId);
            if (entity) {
                applyChanges(entity)
                this.setState({ entities, filteredMaps }, callback);
                return;
            }
        }
        if (item.type === "workitem") {
            const entities = this.state.entitiesBreakDown.entities[item.sourceData.projectId];
            const entity = entities.entities.workitems.entities.find(_ => _.uniqueId === item.uniqueId);

            if (entity) {
                applyChanges(entity)
                this.setState({
                    filteredMaps,
                    entitiesBreakDown: {
                        ...this.state.entitiesBreakDown,
                        entities: {
                            ...this.state.entitiesBreakDown.entities,
                            [item.sourceData.projectId]: entities
                        }
                    }
                }, callback);
                return;
            }
        }
    }

    private _getGroupRow = (breakdown: keyof Breakdown, item: ImportMap): ImportMap => {
        if (breakdown === 'workitems') {
            return {
                name: 'Work items',
                type: 'all_workitems',
                uniqueId: this._buildGroupRowUniqueId(breakdown, item),
                outlineLevel: 1,
                sourceData: { projectId: item.sourceData.projectId, projectName: item.sourceData.projectName, workItems: item.sourceData.workItems },
                importAs: ImportAs.NotSet,
                breakdownBy: [],
                isParent: true,
                isCollapsed: true
            }
        }
        throw new DOMException("breakdown type is not supported");
    }

    private _setBreakdownEntities = (breakDown: keyof Breakdown, item: ImportMap, breakdownEntities: ImportMap[]) => {
        const { search, filter, entitiesBreakDown } = this.state;
        const entities = entitiesBreakDown.entities[item.sourceData.projectId] || { entities: {}, isCollapsed: false };
        entities.entities[breakDown] = {
            entities: breakdownEntities,
            groupRow: { ...this._getGroupRow(breakDown, item), isCollapsed: !!entities?.entities?.[breakDown]?.groupRow.isCollapsed }
        };

        const breakdownByProjectId = { ...entitiesBreakDown.entities, [item.sourceData.projectId]: entities } as BreakdownByProjectId;

        const filterOptions = Object.keys(breakdownByProjectId).concat([item.sourceData.projectId])
            .reduce<{ states: Set<string>, iterations: Set<string>, areas: Set<string>, tags: Set<string>, itemsCount: number }>((acc, projectId) => {
                const breakdown = breakdownByProjectId[projectId].entities;

                breakdown.workitems.entities.forEach(_ => {
                    if (_.state) { acc.states.add(_.state); }
                    if (_.iteration) { acc.iterations.add(_.iteration); }
                    if (_.area) { acc.areas.add(_.area); }
                    if (!!_.tags) { _.tags.forEach(t => acc.tags.add(t)); }
                });
                acc.itemsCount += breakdown.workitems.entities.length;
                return acc;
            }, { states: new Set<string>(), iterations: new Set<string>(), areas: new Set<string>(), tags: new Set<string>(), itemsCount: 0 });

        this.setState({
            states: Array.from<string, IDropdownOption>(filterOptions.states, _ => ({ key: _, text: _ })),
            iterations: Array.from<string, IDropdownOption>(filterOptions.iterations, _ => ({ key: _, text: _ })),
            areas: Array.from<string, IDropdownOption>(filterOptions.areas, _ => ({ key: _, text: _ })),
            tags: Array.from<string, IDropdownOption>(filterOptions.tags, _ => ({ key: _, text: _ })),
            entitiesBreakDown: {
                entities: breakdownByProjectId,
                isLoading: false,
                error: null
            },
            ...this._buildFilteredMaps(search, filter, this.state.entities, breakdownByProjectId)
        });
    }

    private _updateBreakdownEntities = (breakDown: keyof Breakdown, item: ImportMap, applyChanges: (entity: ImportMap) => void, callback?: () => void) => {
        const { entitiesBreakDown, filteredMaps } = this.state;
        const entities = entitiesBreakDown.entities[item.sourceData.projectId];
        const filteredUniqueIds = new Set<string>();
        entities.entities[breakDown].entities.forEach(_ => { filteredUniqueIds.add(_.uniqueId); applyChanges(_); });

        filteredMaps.forEach(_ => filteredUniqueIds.has(_.uniqueId) && applyChanges(_));

        this.setState({
            filteredMaps,
            entitiesBreakDown: {
                ...entitiesBreakDown,
                entities: {
                    ...entitiesBreakDown.entities,
                    [item.sourceData.projectId]: entities
                }
            }
        }, callback);
    }

    private _toggleRowCollapse = (item: ImportMap) => {
        const { filteredMaps } = this.state;
        const map = filteredMaps.find(_ => _.uniqueId === item.uniqueId);
        if (!map) { return; }
        map.isCollapsed = !map.isCollapsed;

        this.setState({ filteredMaps });
    }

    private _filterMapsByIsCollapsed = (): ImportMap[] => {
        const { filteredMaps } = this.state;
        const result: ImportMap[] = [];
        let isParentCollapsed = false;
        let collapsedParentOutlineLevel = -1;
        filteredMaps.forEach((_, index) => {
            if (isParentCollapsed && _.outlineLevel > collapsedParentOutlineLevel) {
                //skip item as child of collapsed parent
                return;
            }

            isParentCollapsed = !!_.isCollapsed;
            collapsedParentOutlineLevel = _.isCollapsed ? _.outlineLevel : -1;

            result.push(_);
        })
        return result;
    }

    private _buildColumns = (): IColumn[] => [{
        key: "name",
        minWidth: Object.keys(this.state.entitiesBreakDown.entities).length !== 0 ? columnsWidth.nameWithState : columnsWidth.name,
        name: "Name",
        headerClassName: "name-column",
        onRender: (item: ImportMap, index?: number, column?: IColumn) => this._nameFormatter({ item, value: item.name })
    },
    Object.keys(this.state.entitiesBreakDown.entities).length !== 0
        ? {
            key: "state",
            minWidth: columnsWidth.state,
            name: "State",
            onRender: (item: ImportMap, index?: number, column?: IColumn) => item.state != null && item.type === 'workitem' && item.processId && item.workitemType
                ? <div className="state align-center">
                    <div className="dot" style={{
                        backgroundColor: `#${this.state.processWorkItemTypes[item.processId]?.[item.workitemType]?.states.find(_ => _.name === item.state)?.color}`
                    }} />
                    <div>{item.state}</div>
                </div>
                : undefined
        }
        : undefined,
    {
        key: "status",
        minWidth: columnsWidth.status,
        name: "Status",
        onRender: (item: ImportMap, index?: number, column?: IColumn) => item.status != null
            ? <StatusFormatter
                {...ImportStatusMap[item.status]}
                title={item.status === ImportStatus.LinkedAsProgram
                    ? "Item is found in PPM Express and linked with an external system as program"
                    : item.status === ImportStatus.LinkedAsProject
                        ? "Item is found in PPM Express and linked with an external system as project"
                        : item.status === ImportStatus.Linked
                            ? "Item is found in PPM Express and linked with an external system"
                            : item.status === ImportStatus.NotLinked
                                ? "Item is not found in PPM Express yet"
                                : undefined}
            />
            : undefined
    },
    {
        key: "importAs",
        minWidth: columnsWidth.importAs,
        name: "Import as",
        onRender: (item: ImportMap, index?: number, column?: IColumn) =>
            item.status === ImportStatus.NotLinked
                ? <EditableCell
                    item={item}
                    value={item.importAs}
                    formatter={this._importAsFormatter}
                    editor={this._importAsEditor}
                    onEditComplete={(value: ImportAs) => {
                        this._updateEntity(item, _ => _.importAs = value);
                        this._selection.setKeySelected(item.uniqueId, value !== ImportAs.NotSet, true)
                    }} />
                : null
    },
    {
        key: "breakdownBy",
        minWidth: columnsWidth.breakdown,
        name: "Expand",
        onRender: (item: ImportMap, index?: number, column?: IColumn) =>
            item.type === "project"
                ? <EditableCell
                    item={item}
                    value={item.breakdownBy}
                    formatter={this._breakdownByFormatter}
                    editor={this._breakdownByEditorBuilder(this._getBreakdownOptions(item))}
                    onEditComplete={(value: string[]) => {
                        const newValue = value || [];
                        if (!arraysEqual(item.breakdownBy, newValue)) {
                            this._updateEntity(item, _ => {
                                _.breakdownBy = newValue;
                                if (newValue.length === 0) {
                                    _.isParent = false;
                                }
                            }, () => this._onBreakdown(item, newValue));
                        }
                    }} />
                : null
    }].filter(notUndefined).map((_: IColumn) => { _.maxWidth = _.minWidth; return _; });

    private _nameFormatter = (props: FormatterProps<string>): JSX.Element => {
        const { item, value } = props;
        const workitemType = item.processId && item.workitemType
            ? this.state.processWorkItemTypes[item.processId]?.[item.workitemType]
            : null;
        const outlineLevelShift = 23;
        return <div className="align-center" style={{ paddingLeft: outlineLevelShift * (item.outlineLevel || 0) }}>
            {item.isParent && <EntityChevron isCollapsed={item.isCollapsed} onClick={() => this._toggleRowCollapse(item)} />}
            <div title={value} className="align-center imported-entity" style={{ paddingLeft: !item.isParent ? outlineLevelShift : undefined }}>
                {
                    workitemType &&
                    <span className="icon" style={{ backgroundImage: `url(${workitemType.icon?.url})` }} title={workitemType.name} />
                }
                <span className="overflow-text">{value}</span>
            </div>
        </div>;
    }

    private _importAsFormatter = (props: FormatterProps<ImportAs>): JSX.Element =>
        props.value === ImportAs.NotSet
            ? <div className="hoverable-placeholder" title="Select PPM Express entity type">Select entity</div>
            : <div className="importas-value">{ImportAsMap[props.value]}</div>;

    private _importAsEditor = (props: EditorProps<ImportAs>) =>
        <DropdownInput
            value={props.value}
            onChanged={props.onChange}
            onEditComplete={(_: ImportAs) => props.onEditComplete(_)}
            inputRef={props.inputRef}
            inputProps={{
                calloutProps: { preventDismissOnResize: true, preventDismissOnLostFocus: true },
                options: ImportAsMapOptions
            }} />;
    private _breakdownByFormatter = (props: FormatterProps<string[]>): JSX.Element | null => {
        const processId = props.item.processId;
        if (!processId) { return null; }

        const typesMap = this.state.processWorkItemTypes[processId];
        if (!typesMap) { return null; }

        const visibleElementsCount = 2;
        const types = props.value.map(_ => typesMap[_])
            .filter(notUndefined)
            .sort((a, b) => a.name.localeCompare(b.name));
        const visibleTypes = types.slice(0, visibleElementsCount);
        const extraCount = types.length - visibleElementsCount;

        if (types.length === 0) {
            return <div className="hoverable-placeholder" title="Expand Azure DevOps project by work item types">Select item</div>;
        }

        return <div className="breakdown-by" title={types.map(_ => _.name).join(', ')}>
            {visibleTypes.map(_ => <div key={_.referenceName} className="imported-entity">
                <span key={_.name} className="icon" style={{ backgroundImage: `url(${_.icon?.url})` }} />
            </div>)}
            {extraCount > 0 && <span>{` + ${extraCount}`}</span>}
        </div>;
    }
    private _breakdownByEditorBuilder = (options: IDropdownOption[]) => (props: EditorProps<string[]>) =>
        <DropdownInput multichoice
            value={props.value}
            onChanged={props.onChange}
            inputRef={props.inputRef}
            inputProps={{
                onRenderTitle: () => this._breakdownByFormatter(props),
                onRenderOption: (dropdownProps?: ISelectableOption) => {
                    if (!dropdownProps) { return null; }

                    const processId = props.item.processId;
                    if (!processId) { return null; }

                    const typesMap = this.state.processWorkItemTypes[processId];
                    if (!typesMap) { return null; }

                    const type = typesMap[dropdownProps.key];

                    return <>
                        {type && <div className="imported-entity">
                            <span className="icon" style={{ backgroundImage: `url(${type.icon?.url})` }} />
                        </div>}
                        <div className="overflow-text">{dropdownProps.text}</div>
                    </>;
                },
                dropdownWidth: 180,
                calloutProps: {
                    alignTargetEdge: true,
                    directionalHint: DirectionalHint.bottomRightEdge
                },
                options,
                onDismiss: () => props.onEditComplete()
            }} />;

    private _ensureCheckedMaps = (selectedItems: ImportMap[]): Dictionary<BreakDownByTypes> => {
        const checked: Dictionary<BreakDownByTypes> = {};
        const newlyCheckedUniqueIds: string[] = [];
        selectedItems.forEach(_ => {
            if (this._prevSelection.includes(_.uniqueId)) {
                checked[_.uniqueId] = _.type;
            } else {
                newlyCheckedUniqueIds.push(_.uniqueId)
            }
        });
        const entititesMap = this._filterMapsByIsCollapsed().reduce<Dictionary<ImportMap>>((acc, _) => { acc[_.uniqueId] = _; return acc; }, {});

        const newlyUncheckedUniqueIds = this._prevSelection.filter(_ => !(checked[_] && entititesMap[_]));

        newlyCheckedUniqueIds.forEach(id => {
            const _ = entititesMap[id];
            if (_) {
                checked[_.uniqueId] = _.type;

                if (_.type === "all_workitems") {
                    this.state.entitiesBreakDown.entities[_.sourceData.projectId].entities.workitems.entities.forEach(w => {
                        if (this._canSelect(w)) {
                            checked[w.uniqueId] = w.type
                        }
                    });
                    this._updateBreakdownEntities("workitems", _, m => {
                        if (this._canSelect(m) && entititesMap[m.uniqueId]) {
                            m.importAs = m.importAs === ImportAs.NotSet ? ImportAs.Project : m.importAs
                        }
                    });
                } else {
                    this._updateEntity(_, m => m.importAs = m.importAs === ImportAs.NotSet ? ImportAs.Project : m.importAs);
                }
            }
        })

        newlyUncheckedUniqueIds.forEach(id => {
            const _ = entititesMap[id];
            if (_) {
                if (_.type === "all_workitems") {
                    this.state.entitiesBreakDown.entities[_.sourceData.projectId].entities.workitems.entities.forEach(w => delete checked[w.uniqueId]);
                    this._updateBreakdownEntities("workitems", _, m => m.importAs = ImportAs.NotSet);
                } else if (_.type === "workitem") {
                    delete checked[this._buildGroupRowUniqueId('workitems', _)];
                    this._updateEntity(_, m => m.importAs = ImportAs.NotSet);
                } else {
                    delete checked[_.uniqueId];
                    this._updateEntity(_, m => m.importAs = ImportAs.NotSet);
                }
            }
        })

        return checked;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        vstsProjects: state.vsts.projects.entities,
        isLoading: state.vsts.projects.isLoading || state.vsts.processWorkItemTypes.isLoading || state.projectsList.isLoading || state.programs.isLoading,
        projects: state.projectsList.allIds.map(_ => state.projectsList.byId[_]),
        programs: state.programs.allIds.map(_ => state.programs.byId[_]),
        error: state.vsts.projects.error || state.vsts.processWorkItemTypes.error,
        isImporting: state.import.projects.isImporting,
        processWorkItemTypes: state.vsts.processWorkItemTypes.entities,
        hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
        connections: state.vsts.connections
    };
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        vstsActions: bindActionCreators(actionCreators, dispatch),
        projectsActions: bindActionCreators(projectsActions, dispatch),
        programsActions: bindActionCreators(programsActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(VSTSProjectImport);

const getKey = (value: unknown) => value ?? 'notset';

export type FormatterProps<T = unknown> = { item: ImportMap, value: T };
export type EditorProps<T = unknown> = {
    item: ImportMap,
    value: T,
    onChange: (value: T) => void,
    onEditComplete: (value?: T) => void,
    inputRef?: (_: IFormInputComponent) => void;
};

type WorkitemFilterOptions = {
    states: IDropdownOption[],
    tags: IDropdownOption[],
    iterations: IDropdownOption[],
    areas: IDropdownOption[],
}
const workitemFilter = (filter: Filter, options: WorkitemFilterOptions, showFields: boolean, onChange: (filter: Filter) => void) => () => {
    const _setTextFilter = (propName: string, value?: string) => {
        const newFilter = { ...filter, [propName]: value };
        if (!value) {
            delete newFilter[propName];
        }
        onChange(newFilter);
    };
    const _setArrayFilter = (propName: string, value?: string[]) => {
        const newFilter = { ...filter, [propName]: value };
        if (!value || value.length === 0) {
            delete newFilter[propName];
        }
        onChange(newFilter);
    };
    return showFields
        ? <>
            <div className="filter-attributes-container">
                <TextFilterAttribute label="Name"
                    value={filter.name}
                    onEditComplete={_ => _setTextFilter(nameof<Filter>('name'), _)} />
                <DropdownFilterAttribute label="State"
                    options={options.states}
                    value={filter.states}
                    onEditComplete={_ => _setArrayFilter(nameof<Filter>('states'), _)} />
                <DropdownFilterAttribute label="Tag"
                    options={options.tags}
                    value={filter.tags}
                    onEditComplete={_ => _setArrayFilter(nameof<Filter>('tags'), _)} />
                <DropdownFilterAttribute label="Iteration"
                    options={options.iterations}
                    value={filter.iterations}
                    onEditComplete={_ => _setArrayFilter(nameof<Filter>('iterations'), _)} />
                <DropdownFilterAttribute label="Area"
                    options={options.areas}
                    value={filter.areas}
                    onEditComplete={_ => _setArrayFilter(nameof<Filter>('areas'), _)} />
            </div>
            {Object.keys(filter).length > 0 && <DefaultButton className="reset-filter-attributes"
                iconProps={{ iconName: 'ClearFilter' }}
                text="Clear Filter"
                onClick={() => onChange({})} />}
        </>
        : null;
}

type DropdownFilterAttributeProps = {
    label: string,
    options: IDropdownOption[],
    value?: string[],
    onEditComplete: (value?: string[]) => void
}

const DropdownFilterAttribute = (props: DropdownFilterAttributeProps) => {
    const { value, onEditComplete, label, options } = props;
    const selecteditems: Option[] = value?.map((v: string) => options.find(_ => _.key === v)).filter(notUndefined) ?? [];

    return <BaseFilterAttribute
        hasValue={!!value && value.length > 0}
        label={label}
        getLabelExtraDetails={() => !!value && value.length > 0 ? { suffix: value[0], count: value.length } : { suffix: "", count: 0 }}>
        <OptionsPicker
            onChange={(opts?: Option[]) => onEditComplete(opts?.map(_ => _.key as string))}
            onResolveSuggestions={(filter: string, selectedItems?: Option[]) => {
                return Promise.resolve<Option[]>(options.filter(_ => _.text.toLowerCase().includes(filter.toLowerCase()) && !selectedItems?.find(__ => __.key === _.key)));
            }}
            selectedItems={selecteditems}
            inputProps={{ autoFocus: true }} />
    </BaseFilterAttribute>;
}

type TextFilterAttributeProps = {
    label: string,
    value?: string,
    onEditComplete: (value?: string) => void
}

const timeDelay = 500;
const TextFilterAttribute = (props: TextFilterAttributeProps) => {
    const { value, onEditComplete, label } = props;
    const [state, setState] = React.useState(value)
    React.useEffect(waitForFinalEvent(() => onEditComplete(state), timeDelay, 'search'), [state]);
    React.useEffect(() => { if (value !== state) { setState(value) } }, [value]);

    return <BaseFilterAttribute
        hasValue={!!value && value.length > 0}
        label={label}
        getLabelExtraDetails={() => ({ suffix: value || "", count: 1 })}>
        <TextField autoFocus value={state || ''} onChange={(e: any, v: string) => setState(v)} />
    </BaseFilterAttribute>;
}
