import "./ConnectionEditWrapper.css";
import * as React from 'react';
import { Link, ActionButton } from 'office-ui-fabric-react';
import { SourceType, SourceType_ } from "../../../store/ExternalEpmConnectStore";

type Props = {
    articleGuidePath: string;
    videoGuidePath: string;
    sourceType: SourceType;
}

export const ConnectionEditWrapper = (props: React.PropsWithChildren<Props>) => <div className="connection-edit-wrapper">
    <div className="description">
        Please check out guides on how to connect {SourceType_.getName(props.sourceType)}
    </div>
    <div className="buttons">
        <ActionButton href={props.videoGuidePath} target="_blank" className="button" iconProps={{ iconName: "PPMXVideoGuide" }} text="Video guide" />
        <ActionButton href={props.articleGuidePath} target="_blank" className="button" iconProps={{ iconName: "PPMXUserManual" }} text="Article guide" />
    </div>
    {props.children}
</div>;
export default ConnectionEditWrapper;
