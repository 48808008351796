import React from 'react'
import { Icon } from 'office-ui-fabric-react'

export default function ClearInput(props: {onClick: () => void}) {
    return (
        <Icon className='clear' iconName='Cancel' title='Clear' onClick={(event) => {
            props.onClick();
            event.stopPropagation();
      }}/>
  )
}
