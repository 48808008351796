import { IFilter, BaseFilterValue, Filter, IFilterHelper, FilterAttribute, Field, IActiveFilter, IEntityFilterHelper, getLabel } from "../../entities/Metadata";
import { Dictionary } from "../../entities/common";
import { DisplayFieldService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import { ISubentity} from "../../entities/Subentities";
import { Idea } from "../IdeasListStore";
import { SourceType } from "../ExternalEpmConnectStore";

export type BaseLocationStateFilter = {
    filter?: IFilter<BaseFilterValue>;
    filterId?: string;
    prefilterKey?: string;
    sourceType?: SourceType;
};

export type LocationState = {
    filters: { [type: string]: BaseLocationStateFilter };
};

type Type = ISubentity | Idea

export class ActiveFilter implements IActiveFilter {
    private readonly _filter: IFilter<BaseFilterValue>;

    constructor(name?: string, sourceType?: SourceType) {
        this._filter = Filter.empty(name);

        if (sourceType != null) {
            this._filter.type = sourceType;
        }
    }

    public withValues(values: { name: string, value: any }[]): ActiveFilter {
        if (!this._filter.value.attributes) {
            this._filter.value!.attributes = new Dictionary<any>();
        }

        values.forEach(_ => {
            this._filter.value.attributes![_.name] = _.value;
            this._filter.attributeNames.push({ name: _.name, type: "attributes" });
        })

        return this;
    }

    public withAttributes(attrNames: string[]) {
        let attributes = this._filter.attributeNames.filter(_ => _.type === "attributes");
        attrNames.forEach(name => {
            let attribute = attributes.find(attr => attr.name === name);
            if (!attribute) {
                this._filter.attributeNames.push({ name: name, type: "attributes" });
            }
        });
        return this;
    }

    public build(): IFilter<BaseFilterValue> {
        return this._filter;
    }
}

export type FilterHelperProps = { entities: Type[], fields: Field[] };

export class FilterHelper implements IEntityFilterHelper<Type> {
    public getFilterAttributes = (fields: Field[], nonFilterableFields?: string[] | undefined): FilterAttribute<BaseFilterValue>[] => {
        return fields
            .filter(_ => !nonFilterableFields || !~nonFilterableFields.indexOf(_.name))
            .map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<BaseFilterValue>);
    }

    private attributes: IFilterHelper<BaseFilterValue, Type> = {
        buildFilterElement: (attr: FilterAttribute<BaseFilterValue>,
            filter: IFilter<BaseFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void): JSX.Element | null => {
            const field: Field = attr.value;
            const value = filter.value?.attributes?.[field.name];

            return DisplayFieldService.buildFieldMultiSelectInput(field, value, changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            let newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            let tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.fields, value),
        validateItem: (item: Type, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] === undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find(_ => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    const value = item.attributes[key];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }
    }

    private readonly _props: FilterHelperProps
    constructor(props: FilterHelperProps) {
        this._props = props;
    }

    public newFilter = (name: string, sourceType?: SourceType): IActiveFilter => new ActiveFilter(name, sourceType);
    protected baseHelpersMap: { [K in keyof BaseFilterValue]: IFilterHelper<BaseFilterValue, Type> } =
    {
        "attributes": this.attributes
    }
    public helpersMap: { [K in keyof BaseFilterValue]: IFilterHelper<BaseFilterValue, Type> } = this.baseHelpersMap;
}