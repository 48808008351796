import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { ProductType } from "../../../store/Tenant";
import LinkBox from "../LinkBox";

type OwnProps = {
    big?: boolean;
}
type StateProps = {
    productType: ProductType;
    helpUrl?: string;
}
type Props = OwnProps & StateProps;

const helpLinkMap = {
    [ProductType.Product]: "https://help.ppm.express/89245-getting-started-with-ppm-express/1255926",
    [ProductType.Portfolio]: "https://help.ppm.express/89245-getting-started-with-ppm-express/1255929",
    [ProductType.Work]: "https://help.ppm.express/89245-getting-started-with-ppm-express/2309526",
    [ProductType.Resource]: "https://help.ppm.express/89245-getting-started-with-ppm-express/2463414",
    [ProductType.Ideation]: "https://help.ppm.express/89245-getting-started-with-ppm-express/2463415~41"
}

const HelpGuide = (props: Props) => <LinkBox text="PPM Express Tutorial"
    href={props.helpUrl ?? helpLinkMap[props.productType]}
    iconName="PPMXQuickStartHelpGuide"
    big={props.big} />;

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        productType: state.tenant.subscription.productType,
        helpUrl: state.tenant.subscription.helpUrl
    };
}
export default connect(mapStateToProps)(HelpGuide);