import * as React from 'react';
import { IToggle, Toggle } from "office-ui-fabric-react";
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import { IFormInputComponent } from "../interfaces/IFormInputComponent";

type FlagProps = IFormInputProps<boolean> & { onText?: string, offText?: string }

export default class FlagInput extends React.Component<FlagProps> implements IFormInputComponent {
    private _toggle?: IToggle;

    constructor(props: FlagProps) {
        super(props);
    }

    public render() {
        const { disabled, readOnly, inputProps, onText, offText } = this.props;
        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;
        return (
            <Toggle
                componentRef={_ => _ && (this._toggle = _)}
                {...this.props.inputProps}
                disabled={isDisabled || isReadOnly}
                checked={this.props.value}
                onChange={this.onChange}
                onText={onText}
                offText={offText} />
        );
    }

    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    private onChange = (e:any, value?: boolean): void => {
        this.props.onChanged && this.props.onChanged(value!);
        this.props.onEditComplete && this.props.onEditComplete(value!);
    }

    focus(): void {
        this._toggle && this._toggle.focus();
    }
}