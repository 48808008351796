import * as React from 'react';
import * as ArchivedProjectsListStore from '../../store/ArchivedProjectsListStore';
import * as Notifications from "../../store/NotificationsStore";
import * as ViewsStore from '../../store/views';
import * as LayoutsStore from '../../store/layouts';
import * as WarningsTypeMapsStore from "../../store/warningsTypeMapsStore";
import { EntityType } from '../../entities/common';
import { RouteComponentProps } from "react-router-dom";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators } from 'redux';
import * as CalendarStore from '../../store/CalendarStore';
import { actionCreators as ProjectsListStoreActionCreators } from '../../store/ProjectsListStore';
import { ProjectTasksList, StateProps, ActionProps } from './ProjectTasksList';
import { PPMFeatures, Subscription } from '../../store/Tenant';
import * as MondayComStore from '../../store/integration/MondayComStore';
import { IsEnterpriseTimeTracker } from '../../store/TimeTrackingStore';


const entityName = EntityType.Project;
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        taskViewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Task), dispatch),
        groupsActions: bindActionCreators(ArchivedProjectsListStore.groupsCreators, dispatch),
        projectsActions: bindActionCreators(ArchivedProjectsListStore.actionCreators, dispatch) as unknown as typeof ProjectsListStoreActionCreators,
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        warningsTypeMapsActions: bindActionCreators(WarningsTypeMapsStore.actionCreators, dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch),
        layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
        mondayComActions: bindActionCreators(MondayComStore.actionCreators, dispatch),
    }
}

export default connect(
    (state: ApplicationState, ownProp: RouteComponentProps<{ id: string }>): StateProps => {
        const projectFields = state.fields[entityName];
        const taskFields = state.fields[EntityType.Task];
        const entity = state.archivedProjectsList.activeEntity && state.archivedProjectsList.activeEntity.id === ownProp.match.params.id
            ? state.archivedProjectsList.activeEntity
            : undefined;

        return {
            entity,
            insights: state.tenant.insights,
            calendar: state.calendar,
            projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
            taskFields: taskFields.allIds.map(_ => taskFields.byId[_]),
            groups: state.archivedProjectsList.groups.byId[ownProp.match.params.id]?.items,
            isLoading: state.archivedProjectsList.isLoading,
            hasArchiveProjects: Subscription.contains(state.tenant.subscription, PPMFeatures.ArchiveProjects),
            timeTrackingSettings: state.tenant.timeTracking.globalSettings,
            isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription),
            user: state.user
        };
    },
    mergeActionCreators
)(ProjectTasksList);