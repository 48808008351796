import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'office-ui-fabric-react';
import { CancellablePromiseMap, cancellablePost } from '../../../fetch-interceptor';
import * as analytics from '../../../analytics';
import { ApplicationState } from '../../../store';
import { AIDigestState, UserState } from '../../../store/User';
import * as UserStore from '../../../store/User';
import { AiDigest, DigestAdjustOptions, DigestDataItem, Settings, TextStyles, projectDigestItems } from './common';
import { IUserProps } from '../../common/inputs/PPMXSharePeoplePicker';
import { AIDigestDataSelector, AIDigestShareControl, AIReviewDigestDataControl } from './AIDigestSteps';
import { AIDigestReviewFooter, AIDigestSelectedDataFooter, AIDigestShareFooter } from './AIDigestPanelFooters';
import './AIDigestPanel.css';
import { AiPanelHeader } from '../../common/ai/panelHeader';
import { AiPanel } from '../../common/ai/panel';
import { ProjectInfo } from '../../../store/ProjectsListStore';
import PrivateProjectLabel from '../../common/PrivateProjectLabel';

enum CreateDigestSteps {
    SelectData,
    Review,
    Share
}

type OwnProps = {
    project: ProjectInfo;
    onDismiss: () => void;
}

type StateProps = {
    aiDigestSettings: AIDigestState;
    user: UserState;
};

type ActionProps = {
    aiDigestActions: typeof UserStore.aiDisgestActionCreators;
}

type Props = OwnProps & StateProps & ActionProps;

const buildSelectedItems = (selectedItems: string[]): DigestDataItem[]  => {
    return projectDigestItems.map(_ => selectedItems.includes(_.key) ? { ..._, isSelected: true } : _)
        .sort((a, b) => a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1);
}

const AIDigestPanel = (props: Props) => {
    const { project, user, aiDigestSettings, aiDigestActions, onDismiss } = props;
    const [selectedStep, setSelectedStep] = React.useState<CreateDigestSteps>(CreateDigestSteps.SelectData);
    const [isDigestLoading, setIsDigestLoading] = React.useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = React.useState<IUserProps[]>([]);
    const [isHtmlViewSelected, setIsHtmlViewSelected] = React.useState<boolean>(false);
    const [cancellableRequests, setCancellableRequests] = React.useState<CancellablePromiseMap<AiDigest>>({});

    const [aiDigest, setAiDigest] = React.useState<AiDigest>({ prompt: '', digest: '' });

    const [settingsInfo, setSettingsInfo] = React.useState<Settings>({
        digestDataItems: [],
        textStyle: TextStyles.None
    });

    React.useEffect(() => { aiDigestActions.getAiDigestSettings(); }, []);

    React.useEffect(() => {
        if (aiDigestSettings && !aiDigestSettings.isLoading) {
            const digestDataItems = aiDigestSettings.aiDigestItems.length
                ? buildSelectedItems(aiDigestSettings.aiDigestItems) 
                : projectDigestItems;
            setSettingsInfo({ ...settingsInfo, digestDataItems });
        }
    }, [aiDigestSettings]);

    const generateDigest = (digestOptions: DigestAdjustOptions): Promise<void> => {
        setIsDigestLoading(true);
        const request = cancellablePost<AiDigest>(`/api/ai/project/${project.id}/digest`, { 
            selectedDigestItems: getSelectedItems(),
            digestOptions: digestOptions
        });
        setCancellableRequests({ ...cancellableRequests, ['digest']: request });
        return request.promise
            .then(response => setAiDigest({ prompt: response.prompt, digest: response.digest }))
            .catch(ex => setAiDigest({ prompt: undefined, digest: '', errorMessage: ex?.response?.data?.errorMessage }))
            .finally(() => setIsDigestLoading(false));
    }

    const generateHtmlDigest = (): Promise<void> => {
        setIsDigestLoading(true);
        const request = cancellablePost<AiDigest>(`/api/ai/toHtml`, { text: aiDigest.digest });
        setCancellableRequests({ ...cancellableRequests, ['htmlDigest']: request });
        return request.promise
            .then(response => setAiDigest({ ...aiDigest, html: response.html }))
            .catch(ex => setAiDigest({ ...aiDigest, html: undefined, errorMessage: ex?.response?.data?.errorMessage }))
            .finally(() => setIsDigestLoading(false));
    }

    const cancelAllRequests = () => {
        Object.values(cancellableRequests).forEach(request => {
          request?.cancelTokenSource?.cancel();
        });
        setCancellableRequests({});
    };

    const onItemChanged = (item: DigestDataItem): void => {
        const updatedItems = settingsInfo.digestDataItems.map((_) => {
          if (_.name === item.name) {
            return { ..._, isSelected: !_.isSelected };
          }
          return _;
        });

        const sortedItems = updatedItems.sort((a, b) => a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1);
        setSettingsInfo({ ...settingsInfo, digestDataItems: sortedItems });
    };

    const getSelectedItems = (): string[] => settingsInfo.digestDataItems.filter(_ => _.isSelected).map(_ => _.key);

    const onGenerateClick = (): void => {
        const selectedItems = getSelectedItems();
        aiDigestActions.saveAiDigestSettings(selectedItems);
        setSelectedStep(CreateDigestSteps.Review);
        analytics.trackEvent("Generate AI Digest", user);
        generateDigest({ textStyle: TextStyles.None });
    }

    const onRenderFooter = (): JSX.Element => {
        return <div className='panel-footer'>
            {selectedStep === CreateDigestSteps.SelectData
                && <AIDigestSelectedDataFooter 
                        isGenerateDisabled={aiDigestSettings?.isLoading || !getSelectedItems().length}
                        onGenerateClick={onGenerateClick}
                        onDismiss={onDismiss}/>}
            {selectedStep === CreateDigestSteps.Review
                && <AIDigestReviewFooter 
                        aiDigest={aiDigest}
                        isDigestLoading={isDigestLoading}
                        selectedTextStyle={settingsInfo.textStyle}
                        onTextStyleChanged={(textStyle) => {
                            setSettingsInfo({ ...settingsInfo, textStyle });
                            generateDigest({ textStyle });
                        }}
                        onNextClick={() => {
                            setSelectedStep(CreateDigestSteps.Share);
                            setIsHtmlViewSelected(false); 
                        }} 
                        onBackClick={() => {
                            cancellableRequests['digest']?.cancelTokenSource?.cancel();
                            setSelectedStep(selectedStep - 1);
                        }} />}
            {selectedStep === CreateDigestSteps.Share
                && <AIDigestShareFooter
                        isHtmlViewSelected={isHtmlViewSelected}
                        isDigestLoading={isDigestLoading}
                        aiDigest={aiDigest}
                        projectId={project.id}
                        setSelectedUsers={(users) => setSelectedUsers(users)}
                        selectedUsers={selectedUsers}
                        onCancel={() => {
                            cancelAllRequests();
                            onDismiss();
                        }}
                        onBack={() => {
                            cancellableRequests['htmlDigest']?.cancelTokenSource?.cancel();
                            setAiDigest({ ...aiDigest, errorMessage: undefined })
                            setSelectedStep(selectedStep - 1);
                        }} />}
        </div>;
    }

    return <AiPanel
        className='ai-digest-panel'
        onDismiss={() => { 
            cancelAllRequests();
            onDismiss();
        }}
        onRenderHeader={() => <AiPanelHeader 
            headerText={<>Create Project Digest {project.isPrivate && <PrivateProjectLabel />}</>}
            secondaryText={<>Create a digest for a project "{project.attributes.Name}" to share with project stakeholders via email. 
                Please refer to the <Link target="_blank" href="https://help.ppm.express/ppm-insights-ai/2354664">article</Link> for more details.</>} />}
        onRenderFooter={onRenderFooter}>
            {selectedStep === CreateDigestSteps.SelectData 
                && <AIDigestDataSelector 
                        items={settingsInfo.digestDataItems} 
                        isLoading={aiDigestSettings?.isLoading} 
                        onItemChecked={onItemChanged} />}
            {selectedStep === CreateDigestSteps.Review 
                && <AIReviewDigestDataControl 
                        aiDigest={aiDigest}
                        isLoading={isDigestLoading}
                        onDigestChange={(_) => setAiDigest({ ...aiDigest, digest: _ })} />}
            {selectedStep === CreateDigestSteps.Share
                && <AIDigestShareControl
                        projectId={project.id}
                        aiDigest={aiDigest}
                        htmlViewSelected={isHtmlViewSelected}
                        setDigestHtmlView={(checked: boolean) => { 
                            setIsHtmlViewSelected(checked);
                            checked && generateHtmlDigest();
                        }}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        isLoading={isDigestLoading} />}
    </AiPanel>
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return { 
        aiDigestSettings: state.user.preferences.aiDigestSettings,
        user: state.user
    };
}

export default connect(mapStateToProps, (dispatch): ActionProps => ({
    aiDigestActions: bindActionCreators(UserStore.aiDisgestActionCreators, dispatch)
}))(AIDigestPanel);