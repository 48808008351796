import './KPICard.css';
import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { IBlinePlanActual } from '../../../../entities/common';
import { formatValue } from '../../../utils/common';
import { FormatType } from '../../../../entities/Metadata';

export type KPIItem = {
    icon: string;
    name: string;
    value: string | JSX.Element;
}

type Props = {
    items: KPIItem[];
}

export const KPIList = (props: Props) => {
    return <div className={`kpi-cards columns${props.items.length}`}>
        {props.items.map((_, i) => <div key={i} className='kpi-card'>
            <Icon iconName={_.icon} />
            <div className='description'>
                <div className='label ellipsis' title={_.name}>{_.name}</div>
                <div className='value ellipsis'>{_.value}</div>
            </div>
        </div>)}
    </div>;
}

export const CostsKPI = (props: { costs: IBlinePlanActual }) => {
    const items = React.useMemo(() => getCostKPI(props.costs), [props.costs]);
    return <KPIList items={items} />
}

function getCostKPI(costs: IBlinePlanActual) {
    return [
        { name: 'Baseline', value: costs.baseline },
        { name: 'Plan', value: costs.plan },
        { name: 'Actual', value: costs.actual }
    ].map(_ => ({
        ..._,
        value: formatValue(_.value, FormatType.Cost),
        icon: 'PPMXKPIBudget'
    }));
}

export const ProjectsWorkKPI = (props: { inProgress: number, completed: number, total: number }) => {
    const items = React.useMemo(
        () => getWorkKPI(props.inProgress, props.completed, props.total), 
        [props.inProgress, props.completed, props.total]);
    return <KPIList items={items} />
}

function getWorkKPI(inProgress: number, completed: number, total: number) {
    return [
        { name: 'Total Projects', value: total },
        { name: 'Completed Projects', value: completed },
        { name: 'Open Projects', value: inProgress }
    ].map(_ => ({
        ..._,
        value: (_.value || 0).toString(),
        icon: 'PPMXProjectNormal'
    }));
}
