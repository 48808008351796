import * as React from 'react';
import { connect } from 'react-redux';
import ConnectionSelect, { ConnectionVerifier } from "../ConnectionSelect";
import { actionCreators } from "../../../store/integration/SpoStore";
import { IConnectionsState } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import SpoConnectionEdit from "../Spo/SpoConnectionEdit";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SourceType } from '../../../store/ExternalEpmConnectStore';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onChange: (connectionId?: string) => void;
};

type StateProps = { connections: IConnectionsState; };
type Props = StateProps & typeof actionCreators & OwnProps;

interface IState {
    refreshId: string | undefined;
    isAdd: boolean;
}

class SpoConnectionSelect extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = { refreshId: undefined, isAdd: false };
    }

    public render() {
        const { connectionId, connections, disabled } = this.props;
        const { isAdd, refreshId } = this.state;

        return <>
            <ConnectionVerifier
                connections={connections}
                connectionId={connectionId}
                verifyConnection={this.props.verifyConnection}>
                <ConnectionSelect
                    key="connection-select"
                    disabled={connections.isLoading || !!disabled}
                    sourceType={SourceType.Spo}
                    connectionId={connectionId}
                    connections={connections.data}
                    onConnectionChange={_ => {
                        if(_?.id){
                            this.props.verifyConnection(_.id);
                        }
                        this.props.onChange(_?.id)}
                    }
                    onConnectionRemove={_ => this.props.removeConnection(_, this.props.onChange)}
                    onConnectionRename={(_, __) => this.props.renameConnection(_, __)}
                    onRefreshClick={(_) => this.setState({ refreshId: _ })}
                    onNewConnectionClick={() => this.setState({ isAdd: true })}
                />
            </ConnectionVerifier>
            { this.props.connections.isLoading && <Spinner key="spinner" size={SpinnerSize.small} className="connection-select-spinner" /> }
            { (isAdd || refreshId) && <SpoConnectionEdit
                connectionId={refreshId}
                onCreated={isAdd ? this.props.onChange : undefined}
                onUpdated={refreshId ? () => this.props.onChange(connectionId) : undefined}
                onDismiss={() => { this.setState({ isAdd: false, refreshId: undefined }) }} />}
        </>;
    }

    componentWillMount() {
        this.props.loadConnections();
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.spo.connections };
}

export default connect(mapStateToProps, actionCreators)(SpoConnectionSelect);