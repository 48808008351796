import { Dictionary } from '../../../entities/common';

export class RelationsAlignmentManager {
    private _verticalMap: Dictionary<{ start: number, end: number, key: string }[]>;
    private _horisontalMap: Dictionary<{ start: number, end: number, key: string }[]>;

    constructor() {
        this._verticalMap = {};
        this._horisontalMap = {};
    }

    public reset() {
        this._verticalMap = {};
        this._horisontalMap = {};
    }

    public applyHorisontalOffset(x: number, topOffset: number, start: number, end: number, key: string, gapWidth: number): number {
        if (start > end) {
            return this.applyHorisontalOffset(x, topOffset, end, start, key, gapWidth);
        }

        const lines = this._verticalMap[x] || [];
        if (lines.find(_ => _.key !== key && _.start < (end + topOffset) && _.end > (start + topOffset))) {
            return this.applyHorisontalOffset(x + gapWidth, topOffset, start, end, key, gapWidth);
        }
        this._verticalMap[x] = [...lines, { start: start + topOffset, end: end + topOffset, key }];
        return x;
    }

    public applyVerticalOffset(y: number, topOffset: number, start: number, end: number, key: string, gapHeight: number): number {
        if (start > end) {
            return this.applyVerticalOffset(y, topOffset, end, start, key, gapHeight);
        }

        const lines = this._horisontalMap[y + topOffset] || [];
        if (lines.find(_ => _.key !== key && _.start < end && _.end > start)) {
            return this.applyVerticalOffset(y + gapHeight, topOffset, start, end, key, gapHeight);
        }
        this._horisontalMap[y + topOffset] = [...lines, { start, end, key }];
        return y;
    }

    public remove(key: string) {
        for (const lineKey in this._horisontalMap) {
            this._horisontalMap[lineKey] = this._horisontalMap[lineKey].filter(_ => _.key !== key)
        }
        for (const lineKey in this._verticalMap) {
            this._verticalMap[lineKey] = this._verticalMap[lineKey].filter(_ => _.key !== key)
        }
    }
}