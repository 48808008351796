import * as React from 'react';
import { connect } from 'react-redux';
import {
    Spinner, Dialog, DialogType, TextField, PrimaryButton, DefaultButton, DialogFooter, SpinnerSize, MessageBar, MessageBarType
} from 'office-ui-fabric-react';
import { Validator } from "../../../validation";
import { ApplicationState } from '../../../store';
import ConnectionEditWrapper from '../ConnectionEditWrapper';
import { actionCreators, IConnectionRefreshInfo, InstanceType, IVSTSConnectionInfo } from '../../../store/integration/VSTSStore';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ConnectionTitle } from '../ConnectionTitle';

type OwnProps = { onDismiss: () => void; onCreated?: (connectionId: string) => void; connectionId?: string }
type StateProps = { isProcessing: boolean; error: string | null, refreshInfo: IConnectionRefreshInfo | null, createdConnectionId?: string }
type Props = OwnProps & StateProps & typeof actionCreators;

type State = {
    info: IVSTSConnectionInfo;
    showHelpLink: boolean;
}

const validator = {
    targetUrl: Validator.new().required().build(),
    personalAccessToken: Validator.new().required().build()
}

class VSTSConnectionEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showHelpLink: true,
            info: {
                instanceType: InstanceType.VSTS,
                id: props.connectionId,
                targetUrl: '',
                personalAccessToken: ''
            }
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.refreshInfo && nextProps.refreshInfo !== this.props.refreshInfo) {
            const info = nextProps.refreshInfo;
            this.setState({ info: { ...this.state.info, instanceType: info.instanceType, targetUrl: info.targetUrl } });
        }

        const isProcessed = this.props.isProcessing && !nextProps.isProcessing;
        if (isProcessed && !nextProps.error) {
            nextProps.onCreated && nextProps.createdConnectionId && nextProps.onCreated(nextProps.createdConnectionId);
            this._dismiss();
        }
    }

    componentWillMount() {
        const { connectionId } = this.props;
        connectionId && this.props.loadRefreshInfo(connectionId);
    }

    public render() {
        const { info } = this.state;

        const isEdit = !!info.id;
        const okText = isEdit ? "Save" : "Create";
        return <Dialog
            hidden={false}
            minWidth={400}
            maxWidth={400}
            dialogContentProps={{
                onDismiss: this._dismiss,
                type: DialogType.normal,
                title: <ConnectionTitle isEdit={isEdit} sourceType={SourceType.VSTS} />
            }
            }
            modalProps={{
                isBlocking: true,
                containerClassName: 'ms-dialogMainOverride connection-popup'
            }}>
            {info.instanceType === InstanceType.VSTS && this._renderVSTS()}
            {info.instanceType === InstanceType.TFS2017 && this._renderTFS2017()}
            {
                this.props.error && <p>
                    <MessageBar
                        messageBarType={MessageBarType.severeWarning}
                        isMultiline={true}>
                        {this.props.error}
                    </MessageBar>
                </p>
            }
            <DialogFooter>
                {
                    !this.props.isProcessing && [<PrimaryButton
                        key="ok"
                        disabled={!this._isValid()}
                        onClick={this._okClick}
                        text={okText} />,
                    <DefaultButton key="cancel" onClick={this._dismiss} text="Cancel" />]
                }
                {
                    this.props.isProcessing && [<PrimaryButton key="processing-ok" disabled={true} text={okText}>
                        <Spinner size={SpinnerSize.medium} />
                    </PrimaryButton>,
                    <DefaultButton key="processing-cancel" disabled={true} text="Cancel" />
                    ]
                }
            </DialogFooter>
        </Dialog>;
    }

    private _renderVSTS() {
        const { info } = this.state;
        return (
            <ConnectionEditWrapper sourceType={SourceType.VSTS}
                articleGuidePath="https://help.ppm.express/89488-azure-devops-connection/how-to-create-a-personal-access-token-for-a-azure-devops-connection"
                videoGuidePath="https://help.ppm.express/89488-azure-devops-connection/1275718">
                <TextField label="Site URL"
                    value={info.targetUrl}
                    placeholder="dev.azure.com/your_domain"
                    onChange={(e, _) => this.updateStateInfo({ targetUrl: _ })}
                    onGetErrorMessage={validator.targetUrl.getErrorMessage} />
                {this._renderPAT()}
            </ConnectionEditWrapper>
        );
    }

    private _renderTFS2017() {
        return <div>
            <TextField label="Site URL" value={this.state.info.targetUrl} 
                onChange={(e, _) => this.updateStateInfo({ targetUrl: _ })} onGetErrorMessage={validator.targetUrl.getErrorMessage} />
            {this._renderPAT()}
        </div>;
    }

    private _renderPAT = () => {
        const { info } = this.state;
        return (
            <div>
                <TextField validateOnFocusOut={true}
                    validateOnLoad={false}
                    type="password"
                    label="Personal Access Token"
                    value={info.personalAccessToken}
                    placeholder="token"
                    onChange={(e, _) => this.updateStateInfo({ personalAccessToken: _?.trim() })}
                    onGetErrorMessage={validator.personalAccessToken.getErrorMessage} />
            </div>
        );
    }

    private _okClick = () => {
        this._isValid() && this.props.createOrRefreshConnection(this.state.info);
    }

    private _isValid() {
        return Validator.isValid(validator, this.state.info);
    }

    private _dismiss = () => {
        this.props.onDismiss();
        this.props.cleanError();
    }

    private updateStateInfo = (update: Partial<IVSTSConnectionInfo>) => {
        this.setState({ info: { ...this.state.info, ...update } });
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        isProcessing: state.vsts.connections.isProcessing,
        error: state.vsts.connections.error,
        refreshInfo: state.vsts.connections.refreshInfo,
        createdConnectionId: state.vsts.connections.createdConnectionId
    }
}

export default connect(mapStateToProps, actionCreators)(VSTSConnectionEdit);