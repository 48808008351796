import * as React from 'react';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import OAuthConnectButton from '../OAuthConnectButton';

type Props = {
    disabled?: boolean;
    sourceType: SourceType.O365Group | SourceType.O365Planner | SourceType.O365TeamsChannel | SourceType.O365User;
    onCreated: () => void;
};

const Office365ConnectButton = (props: React.PropsWithChildren<Props>) => <OAuthConnectButton
    disabled={!!props.disabled}
    sourceType={props.sourceType}
    newConnectionUrl="/api/integration/office365/auth"
    onConnectionCreated={props.onCreated}
>{props.children}</OAuthConnectButton>;
export default Office365ConnectButton;
