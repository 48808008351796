import * as React from 'react';
import { TaskProgressData } from "../../../../../store/ProjectsListStore";
import { SourceType, SourceType_, SourceTypeMap } from "../../../../../store/ExternalEpmConnectStore";
import { ActionButton } from 'office-ui-fabric-react';
import { Dictionary, EntityType, ISourceInfo, SourceInfo } from '../../../../../entities/common';
import { getTasksMetrics } from '../../../../../store/project/utils';
import { shortenNumber, notUndefined } from '../../../../utils/common';
import { RouteComponentProps, withRouter } from "react-router-dom";

type Props = {
    sourceInfos: ISourceInfo[];
    taskProgresses?: Dictionary<TaskProgressData>;
    excludeCollaborative?: boolean;
    showTitle?: boolean;
    showMetrics?: boolean;
    allowNavigate?: boolean;
    entityType: EntityType;
};
export default class LinkedSystemIcons extends React.Component<Props> {
    render() {
        const sources = this.props.excludeCollaborative
            ? SourceInfo.getSources(this.props.sourceInfos)
            : this.props.sourceInfos;
        const systemsWithoutLink = [SourceType.MPPFile];

        return sources.map((source, index) => {
            const iconName = this.props.allowNavigate && source.type === SourceType.O365Group ? 'SharepointLogo' : SourceType_.getIconName(source.type);
            const url = this.props.allowNavigate ? SourceInfo.getUrl(source, this.props.entityType) : undefined;
            const showMetrics = this.props.showMetrics && SourceInfo.isSyncable(source) && !SourceInfo.isCollaborative(source);
            const metrics = showMetrics ? getTasksMetrics(this.props.sourceInfos, this.props.taskProgresses!, source.connectionId) : undefined;

            const defaultIconTitle = `Linked to ${SourceTypeMap[source.type]}${source.type === SourceType.O365Group ? ' group site' : ''}`;
            const externalHint = SourceInfo.GetExternalHint(source, this.props.entityType);

            return (
                <SystemIcon key={`icon-${index}`}
                    iconName={iconName}
                    disabled={this.props.allowNavigate ? !url && !systemsWithoutLink.includes(source.type) : undefined}
                    showMetrics={showMetrics}
                    title={this.props.showTitle ? notUndefined(externalHint) ? externalHint : defaultIconTitle : undefined}
                    url={url}
                    target="_blank"
                    metrics={metrics} />
            );
        })
    }
}

export enum IconSize {
    small
} 

type SystemIconProps = {
    showMetrics?: boolean;
    iconSize?: IconSize
    url?: string;
    target?: string;
    label?: string;
    title?: string;
    disabled?: boolean;
    iconName: string;
    metrics?: TaskProgressData;
    onClick?: () => void;
};

class SystemIconImpl extends React.Component<SystemIconProps & RouteComponentProps<{}>>{
    render() {
        const { url, target, label, disabled, iconName, title, metrics, showMetrics, iconSize } = this.props;

        return (
            <div title={title} className={buildIconStyleClass(iconSize, !!label)}>
                <ActionButton
                    href={url}
                    target={target}
                    disabled={disabled}
                    iconProps={{ iconName }}
                    className={iconName}
                    onClick={url ? this._onClick : undefined}
                    style={{ verticalAlign: showMetrics && !url ? "unset" : undefined }}>
                    {showMetrics && this._renderProgressTip(metrics)}
                    {label && <span className="label">{label}</span>}
                </ActionButton>
            </div>
        );
    }

    private _onClick = (e: any) => {
        e.stopPropagation();
        if (this.props.target === '_blank') {
            return;
        }

        e.preventDefault();
        e.ctrlKey
            ? window.open(this.props.url!, '_blank')
            : this.props.history.push(this.props.url!);
    }

    private _renderProgressTip(progressData: TaskProgressData | undefined): JSX.Element {
        const naValue = 'N/A';
        const data = progressData && progressData.pctComplete != null
            ? { value: `${progressData.pctComplete}%` }
            : progressData && progressData.total
                ? { tooltip: `${progressData.completed}/${progressData.total}`, value: `${shortenNumber(progressData.completed)}/${shortenNumber(progressData.total)}` }
                : { value: naValue };
        const sizeClass = data.value
            ? data.value.length > 5
                ? 'long'
                : data.value.length === 5
                    ? 'longer'
                    : undefined
            : undefined;
        return <div className={`progress-tip ${data.value === naValue ? 'na' : ''} ${sizeClass || ''}`} title={`Progress: ${data.tooltip || data.value}`}>{data.value}</div>;
    }
}

function buildIconStyleClass(iconSize: IconSize | undefined, withLabel: boolean): string | undefined {
    return `linked-system ${iconSize === IconSize.small ? "icon-14" : ""} ${withLabel ? "with-label": ""}`;
}

export const SystemIcon = withRouter<SystemIconProps>(SystemIconImpl);
