import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as IdeasListStore from "../../store/IdeasListStore";
import * as ChallengesListStore from "../../store/ChallengesListStore";
import { EntityType } from '../../entities/common';
import { CreatePanel } from '../common/SubentityPanel';
import { ApplicationState } from '../../store';
import * as Metadata from '../../entities/Metadata';
import { namesof, nameof } from '../../store/services/metadataService';
import { validators, rendersBuilder } from '../field/Fields';
import { Idea } from '../../store/IdeasListStore';
import EntityCreation from '../common/extensibleEntity/EntityCreation';

interface OwnProps {
    onDismiss: () => void;
    challengeId: string;
}

type StoreProps = {
    fields: Metadata.Field[];
};

type Props = {
    ideasActions: typeof IdeasListStore.actionCreators,
    challengesActions: typeof ChallengesListStore.actionCreators
} & StoreProps & OwnProps;

export function getIdeaCreationPanelProps(fields: Metadata.Field[]) {
    return {
        displayFields: namesof<IdeasListStore.IdeaAttrs>(['Name', 'Description', 'Benefits', 'BusinessPriority', 'ExpectedEfforts', 'ExpectedCost']),
        fields: fields,
        customFieldValidatorBuilder: validators,
        uiControlElementsCustomRender: rendersBuilder(),
        buildNewEntity: () => ({ attributes: { Budget: 0 } } as Idea)
    };
}

const IdeaCreationInChallenge = (props: Props) => {
    return <EntityCreation
        entityType={EntityType.Idea}
        header={{
            secondaryText: 'Fill in the Idea Name field below to create a new Idea. You will be able to provide Idea details later'
        }}
        onDismiss={props.onDismiss}
        hideSectionSelector
        onSave={(name) => {
            props.challengesActions.createIdeaAndAddToChallenge(props.challengeId, { Name: name, Budget: 0 });
        }} />;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Idea];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]).filter(_ => !_.isReadonly && _.name != nameof<IdeasListStore.IdeaAttrs>("Challenge")),
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        ideasActions: bindActionCreators(IdeasListStore.actionCreators, dispatch),
        challengesActions: bindActionCreators(ChallengesListStore.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(IdeaCreationInChallenge);