import * as React from 'react';
import DropdownInput from './DropdownInput';
import { IInputProps } from '../interfaces/IInputProps';
import { FlagEyeIconSet, FlagFormatter } from '../formatters/FlagFormatter';

const yesValue = "Yes";
const noValue = "No";

const FlagBaseEditor = (props: IInputProps & { yesIconName?: string, noIconName?: string }) =>
    <DropdownInput {...props} hideCaretDown
        onChanged={(fieldValue: string) => props.onChanged?.(fieldValue === yesValue)}
        onEditComplete={(value: string) => props.onEditComplete?.(value === yesValue)}
        value={props.value ? yesValue : noValue}
        inputProps={{
            options: [
                { key: yesValue, text: "Yes" },
                { key: noValue, text: "No" }
            ],
            onRenderOption: (p: any) => <FlagFormatter value={p.key === yesValue} yesIconName={props.yesIconName} noIconName={props.noIconName} />,
            onRenderTitle: (p: any) => !p || !Array.isArray(p)
                ? <></>
                : <FlagFormatter value={p[0].key === yesValue} yesIconName={props.yesIconName} noIconName={props.noIconName} />
        }} />;

export const FlagEyeEditor = (props: IInputProps) => <FlagBaseEditor {...props} {...FlagEyeIconSet} />;
export const FlagEditor = (props: IInputProps) => <FlagBaseEditor {...props} />;
