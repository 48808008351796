import * as React from 'react';
import { connect } from 'react-redux';
import OAuthConnectionSelect from "../OAuthConnectionSelect";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { actionCreators, IPlannerConnectionState } from "../../../store/integration/Office365Store";
import { ApplicationState } from "../../../store/index";
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ConnectionVerifier } from '../ConnectionSelect';
import { IConnectionsState } from '../../../store/integration/common';
import { Office365Utils, PlannerPostMessageContext } from './Office365Utils';
import { PostMessageDataBase } from '../PostMessageReceiver';
import * as Notifications from "../../../store/NotificationsStore";
import { bindActionCreators } from 'redux';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onChange?: (connectionId?: string) => void;
    sourceType: SourceType.O365Group | SourceType.O365Planner | SourceType.O365TeamsChannel | SourceType.O365User;
    validateConnection?: (state: IConnectionsState, connectionId?: string) => { isValidating?: boolean, validationError?: string | React.ReactNode | null } | undefined;
    hideRestricted?: boolean;
};

type ActionProps = {
    notificationsActions: typeof Notifications.actionCreators,
    connectionActions: typeof actionCreators
}

type StateProps = { connections: IPlannerConnectionState; };
type Props = StateProps & ActionProps & OwnProps;

const Office365ConnectionSelect = (props: Props) => {
    React.useEffect(() => {
        props.connectionActions.loadConnections();
    }, []);

    const { connectionId, connections, disabled, sourceType, hideRestricted } = props;

    const _acceptAccessToken = (data: PostMessageDataBase<PlannerPostMessageContext>) => {
        props.connectionActions.loadConnections();
        props.onChange?.(data.context?.connectionId);
        if (data.context?.scopeChanged) {
            props.notificationsActions.pushNotification({ message: Office365Utils.ScopeChangedMessage, type: Notifications.NotificationType.Warn });
        }
    }

    return <>
        <ConnectionVerifier
            connections={connections}
            connectionId={connectionId}
            verifyConnection={props.connectionActions.verifyConnection}
            validateConnection={props.validateConnection}>
            <OAuthConnectionSelect
                key="connectionSelect"
                disabled={connections.isLoading || !!disabled}
                sourceType={sourceType}
                connectionId={connectionId}
                connections={connections.data}
                newConnectionUrl="/api/integration/office365/auth"
                newConnectionTooltip={Office365Utils.getNewConnectionTooltip()}
                newRestrictedConnectionUrl={hideRestricted ? undefined : Office365Utils.getNewRestrictedConnectionUrl(props.sourceType)}
                newRestrictedConnectionTooltip={hideRestricted ? undefined : Office365Utils.getNewRestrictedConnectionTooltip()}
                getRefreshConnectionUrl={(cId) => Office365Utils.getRefreshConnectionUrl(props.connections, cId)}
                getUpgradeConnectionUrl={(cId) => Office365Utils.getUpgradeConnectionUrl(props.connections, cId)}
                grantPermissionsUrl="/api/integration/office365/auth/adminConsent"
                grantPermissionsText="Grant Permissions (requires Admin Consent)"
                systemName="office365"
                onReceivedConnectionData={_acceptAccessToken}
                onConnectionChange={_ => {
                    if (_?.id) {
                        props.connectionActions.verifyConnection(_?.id);
                    }
                    props.onChange?.(_?.id);
                }}
                onConnectionRemove={(_) => props.connectionActions.removeConnection(_, () => props.onChange?.())}
                onConnectionRename={(_, __) => props.connectionActions.renameConnection(_, __)}
            />
        </ConnectionVerifier>
        {props.connections.isLoading && <Spinner key="spinner" size={SpinnerSize.small} className="connection-select-spinner" />}
    </>;
}


function mapStateToProps(state: ApplicationState, ownProps: OwnProps) {
    return { connections: state.office365.connections };
}

export default connect(mapStateToProps, (dispatch): ActionProps =>
    ({
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        connectionActions: bindActionCreators(actionCreators, dispatch),
    })
)(Office365ConnectionSelect);
