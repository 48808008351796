import * as React from 'react';
import { ISubentity, isLinkedSubentity } from "../../../entities/Subentities";
import { ItemCreation, IHeader, ICommand } from '../ItemCreation';
import { Validator, IValidator } from "../../../validation";
import * as Metadata from '../../../entities/Metadata';
import { DisplayFieldService } from '../../common/DisplayFieldService';
import { OnChangeEvent } from "../sectionsControl/uiControls/fieldsArea/FieldComponent";
import { filterUnique, notUndefined } from '../../utils/common';
import { Dictionary, EntityType } from '../../../entities/common';
import { PivotItem } from 'office-ui-fabric-react';
import FieldsEdit from './FieldsEdit';
import ExtraTabs, { IExtraTab } from './ExtraTabs';
import HeaderText from './HeaderText';
import { FieldConfig } from './FieldEditor';
import { SecondaryMessage } from './HeaderSecondaryText';
import { ViewService } from '../../../services/ViewService';

const EmptyTabArray: IExtraTab<any>[] = [];

export type GenericPanelProps<TSubentity extends ISubentity> = React.PropsWithChildren<{
    extraTabs?: IExtraTab<TSubentity>[];
    subentityType: EntityType;
    subentityTypeLabel: ((entity: TSubentity) => string) | string;
    operation?: string;
    className?: ((entity: TSubentity) => string) | string;

    readOnly?: boolean;
    header?: Partial<IHeader>;
    secondaryMessage?: SecondaryMessage<TSubentity>;

    onDismiss: () => void;

    fields: ((entity: TSubentity) => Metadata.Field[]) | Metadata.Field[];
    displayFields: string[];
    mandatoryEditFields?: string[];
    uiControlElementsCustomRender?: Dictionary<any>;
    customFieldValidatorBuilder?: Dictionary<(state: TSubentity, field: Metadata.Field) => IValidator>;
    editableNativeFieldsForLinkedEntities?: ((entity: TSubentity) => string[]) | string[];
    theOnlyEditableFieldsForLinkedEntities?: string[];
    readOnlyFields?: string[] | ((entity: TSubentity) => string[]);
    mandatoryEditableFields?: (entity: TSubentity) => string[] | undefined;
    isDirty?: boolean;
    customCommands?: ICommand[];
}>

export type OnComplete = (id: string, changes: Dictionary<unknown>) => void;

type Props<TSubentity extends ISubentity> = GenericPanelProps<TSubentity> & {
    onComplete: OnComplete;
    onDelete?: (entityId: string) => void;
    entity: TSubentity;
}

export const UpdatePanel = <TSubentity extends ISubentity>(props: Props<TSubentity>) => {
    const { subentityType, entity, onDismiss, onComplete, onDelete, extraTabs = EmptyTabArray, children, operation,
        uiControlElementsCustomRender, customFieldValidatorBuilder, editableNativeFieldsForLinkedEntities, 
        theOnlyEditableFieldsForLinkedEntities, readOnlyFields, customCommands } = props;

    const mandatoryEditableFields = React.useMemo(() => props.mandatoryEditableFields?.(entity) ?? [], [entity, props.mandatoryEditableFields]);
    const readOnly = !!props.readOnly && !mandatoryEditableFields.length;

    const [selectedTab, setSelectedTab] = React.useState<IExtraTab<TSubentity> | undefined>();
    const onTabChange = React.useCallback(
        (item: PivotItem) => setSelectedTab(extraTabs.find(_ => _.key === item.props.itemKey)),
        [extraTabs]);

    const [changes, setChanges] = React.useState<Dictionary<unknown>>({});

    // full entity necessary for:
    // predecessor field type to filter entity from suggestion list
    // to correctly render entity changes in extraTabs because they can use some entity specific data
    const editEntity = React.useMemo(() => ({ ...entity, attributes: { ...entity.attributes, ...changes } }), [changes, entity]);

    const isFieldReadOnly = (field: Metadata.Field): boolean => {
        return props.readOnly || field.isReadonly 
            || ViewService.isReadonlyField(field, readOnlyFields, subentityType, entity, editableNativeFieldsForLinkedEntities, theOnlyEditableFieldsForLinkedEntities);
    }
    
    const fields = props.fields instanceof Function ? props.fields(entity) : props.fields;
    const config: Dictionary<FieldConfig> = React.useMemo(
        () => fields
            .map(_ => ({
                fieldName: _.name,
                renderInput: uiControlElementsCustomRender?.[_.name],
                validator: !mandatoryEditableFields.includes(_.name) && isFieldReadOnly(_) ? undefined :
                                customFieldValidatorBuilder?.[_.name]?.(editEntity as TSubentity, _) || DisplayFieldService.buildValidator(_),
                readOnly: !mandatoryEditableFields.includes(_.name) && isFieldReadOnly(_)
            }))
            .reduce((p, c) => ({ ...p, [c.fieldName]: c }), {}),
        [fields, editEntity, mandatoryEditableFields]);

    const displayFields: string[] = React.useMemo(
        () => filterUnique((props.mandatoryEditFields || []).concat(props.displayFields)),
        [props.displayFields, props.mandatoryEditFields]);

    const isValid = React.useMemo(
        () => {
            const validators = fields
                .map(_ => _.name)
                .filter(_ => config[_]?.validator)
                .reduce((v, k) => ({ ...v, [k]: config[k].validator }), {});
            return Validator.isValid(validators, editEntity.attributes);
        },
        [config, editEntity, fields]);

    const subentityTypeLabel = props.subentityTypeLabel instanceof Function ? props.subentityTypeLabel(entity) : props.subentityTypeLabel;
    const className = props.className instanceof Function ? props.className(entity) : props.className;

    const [isDirty, setIsDirty] = React.useState(props.isDirty && isValid);
    const setDirty = React.useCallback(() => setIsDirty(true), []);
    const onNameChanged = React.useCallback((newName: string) => {
        setChanges((p) => ({ ...p, "Name": newName }));
        setDirty();
    }, []);
    const onChanged = React.useCallback<OnChangeEvent>(
        (f, v, extra) => {
            setChanges((p) => ({ ...p, [f]: v, ...extra }));
            setDirty();
        },
        [changes]);

    const commands = React.useMemo(() => {
        return [
            !readOnly ? {
                primary: true,
                text: `${operation ? operation : "Save"} ${subentityTypeLabel}`,
                onClick: () => { onComplete(entity.id, changes); onDismiss(); },
                disabled: !isValid || !isDirty
            } : undefined,
            {
                text: readOnly ? 'Close' : 'Cancel',
                onClick: onDismiss
            },
            !props.readOnly && onDelete
                ? getDeleteButtonCommand(() => onDelete(entity.id))
                : undefined,
        ]
            .filter(notUndefined)
            .concat(customCommands || []);
            
    }, [entity.id, changes, onDismiss, onComplete, onDelete, readOnly, subentityTypeLabel, isValid, isDirty, customCommands]);

    return <ItemCreation onDismiss={props.onDismiss}
        header={{
            showNameEditor: !selectedTab,
            nameEditorLabel: `${subentityTypeLabel} Name`,
            disableNameEditor: readOnly || isLinkedSubentity(subentityType, entity),
            onChanged: onNameChanged,
            validator: config["Name"]?.validator,
            value: editEntity.attributes['Name'],
            text: <HeaderText {...props} operation={operation} readOnly={readOnly} subentityTypeLabel={subentityTypeLabel} suffixRender={props.header?.suffixRender} />,
            ...props.header,
            secondaryText: <ExtraTabs
                {...props}
                readOnly={readOnly}
                extraTabs={extraTabs}
                selectedTab={selectedTab}
                subentityTypeLabel={subentityTypeLabel}
                isTabsDisabled={!isValid}
                onTabChange={onTabChange} />,
        }}
        isDirty={isDirty}
        commands={commands}
        className={`subentity-panel ${className}`}>
        <div className="panel-area">
            {extraTabs && selectedTab
                ? selectedTab.renderBody(editEntity, onChanged)
                : <FieldsEdit
                    fields={fields}
                    entity={editEntity}
                    config={config}
                    displayFields={displayFields}
                    subentityType={subentityType}
                    onChanged={onChanged}
                />
            }
        </div>
        {children}
    </ItemCreation>;
}


export function getDeleteButtonCommand(onClick: () => void): ICommand {
    return {
        text: 'Delete',
        iconName: 'Delete',
        right: true,
        title: `Delete`,
        onClick: onClick
    };
}
