import "./ConnectionBox.css";
import * as React from "react";
import { IConnectionInfo } from "../../../entities/Metadata";
import ConnectionBoxMenu from "./ConnectionBoxMenu";
import TextInput from "../../common/inputs/TextInput";

type Props = {
    canManage: boolean;
    connection: IConnectionInfo;
    onSelect?: () => void;
    onRename?: (newTitle: string) => void;
    onEdit?: () => void;
    onDelete?: () => void;
    onUpdrade?: () => void;
}

const ConnectionBox = (props: Props) => {
    const [rename, setRename] = React.useState<boolean>();
    const [title, setTitle] = React.useState<string>(props.connection.title);
    const onDismissRename = React.useCallback(() => setRename(undefined), []);
    const stopPropagation = React.useCallback((e: any) => e.stopPropagation(), []);

    return <div className="connection-box" onClick={props.onSelect}>
        {rename
            ? <div
                onKeyDown={stopPropagation}
                onClick={stopPropagation}>
                <TextInput className="overflow-text-input"
                    inputRef={_ => _?.focus()}
                    onBlur={onDismissRename}
                    onEditComplete={(newTitle: string) => {
                        setTitle(newTitle);
                        props.onRename?.(newTitle);
                    }}
                    value={title} />
            </div>
            : <div className="overflow-text">{title}</div>}
        <div className="overflow-text" title={props.connection.name}>{props.connection.name}</div>
        {props.canManage && <ConnectionBoxMenu
            onEdit={props.onEdit}
            onUpgrade={props.onUpdrade}
            onRename={!props.onRename ? undefined : () => setRename(true)}
            onDelete={props.onDelete}
        />}
    </div>;
}
export default ConnectionBox;