import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import * as Metadata from "../../../entities/Metadata";
import EntityPicker from './EntityPicker';
import { EntityType } from "../../../entities/common";
import RemoveDialog from '../RemoveDialog';
import { FieldInputElementProps } from '../interfaces/IInputProps';
import { ChallengeStage } from '../../../store/ChallengesListStore';
import { ResourceOperations } from '../../../store/permissions';
import { searchPathMap } from '../../../entities/Metadata';

type Props = FieldInputElementProps & {
    withNavigation?: boolean
}

const EntityFieldInputElement = (props: Props) => {
    const { field, entityType, validator } = props;
    const multichoice = field.settings && field.settings.multichoice;
    return <EntityPicker {...props}
        renderRemovalConfirmation={!props.isConfigureMode
            && ((entityType === EntityType.Project && field.type === Metadata.FieldType.Portfolio)
                || (entityType === EntityType.Project && field.type === Metadata.FieldType.Program)
                || (entityType === EntityType.Program && field.type === Metadata.FieldType.Portfolio))
            ? (onSave, onClose) => <RemoveDialog onComplete={onSave} onClose={onClose}
                dialogContentProps={{
                    title: `Removal Confirmation`,
                    subText: `Are you sure you want to remove this ${entityType} from the ${Metadata.fieldTypeMap[field.type].toLowerCase()}?`
                }}>
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    If the {entityType} is removed from the {Metadata.fieldTypeMap[field.type].toLowerCase()},
                    items imported from this {entityType} will be removed as well.
                </MessageBar>
            </RemoveDialog>
            : undefined}
        inputProps={{ ...field.settings, readOnly: field.isReadonly }}
        filter={buildFieldInputFilter(field.type)}
        entityViewPath={props.withNavigation === false ? undefined : `/${searchPathMap[field.type]}`}
        validator={validator}
        multichoice={multichoice}
        searchPath={`api/${searchPathMap[field.type]}/find`} />;
}

export const buildFieldInputFilter = (type: Metadata.FieldType) => {
    switch (type) {
        case Metadata.FieldType.Challenge: return { stage: ChallengeStage.Active };
        case Metadata.FieldType.Portfolio: return { accessLevel: ResourceOperations.Update };
        case Metadata.FieldType.Program: return { accessLevel: ResourceOperations.Update };
        default: return undefined;
    }
}

export default EntityFieldInputElement;
