import React from "react";
import NumberInput from "../../../components/common/inputs/NumberInput";
import { IFormInputComponent } from "../../../components/common/interfaces/IFormInputComponent";
import { FormatType } from "../../../entities/Metadata";
import { ApplicationState } from "../../../store";
import { TimeTrackingGlobalSettings } from "../../../store/Tenant";
import { connect } from "react-redux";
import { FixMinAndIncrementDuration } from "../Fields";

type OwnProps = {
    value: number;
    onEditCompleted: (value: number | null) => void;
}

type StateProps = {
    timeTrackingSettings: TimeTrackingGlobalSettings;
}

type Props = OwnProps & StateProps;

 function TimeTrackingEditCell(props: Props) {
    const { value, onEditCompleted, timeTrackingSettings } = props;

    return (
        <div
            onClick={e => {
                e.stopPropagation();
            }}
            className="value-wrap">
            <NumberInput
                value={value || undefined}
                format={FormatType.Duration}
                onEditComplete={(newValue) => {

                    if (newValue) {
                        newValue = FixMinAndIncrementDuration(
                            newValue,
                            timeTrackingSettings.minReportingDurationMinutes,
                            timeTrackingSettings.reportingIncrementMinutes);
                    }

                    onEditCompleted(newValue);

                }}
                selectOnFocus
                inputRef={(_: IFormInputComponent | null) => {
                    _ && _.focus();
                }}
                resetInvalidOnBlur
            />
        </div>
    );
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        timeTrackingSettings: state.tenant.timeTracking.globalSettings
    };
}

export default connect(mapStateToProps)(TimeTrackingEditCell);