import * as React from 'react';

const CellValue = (props: {
    value: string | undefined, unit: string, isEmpty: boolean, title: string, className?: string,
    onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
    const { value, unit, isEmpty, title, className, onDoubleClick } = props;
    return <div onDoubleClick={onDoubleClick ? (ev) => { onDoubleClick(ev); ev.stopPropagation(); } : undefined}
        className={`value-wrap ${className ?? ''} ${!value || value === '0' ? 'no-data' : ''}`} title={title}>
        {!isEmpty && <div className="value">{value}</div>}
        {!isEmpty && <div className="unit">{unit}</div>}
    </div>;
}

export default CellValue;