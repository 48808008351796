import { PivotItem } from "office-ui-fabric-react";
import * as React from "react";
import { connect } from "react-redux";
import { EntityType } from "../../../entities/common";
import { Field } from "../../../entities/Metadata";
import { ApplicationState } from "../../../store";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { ExternalFieldInfo, IFieldMapping, TaskImportSettings } from "../../../store/integration/common";
import { 
    IIssueTypeWithStatus, actionCreators, IConnectionConfiguration, jiraFieldToPpmxFieldsMap, IProgressCalculationSettings
} from "../../../store/integration/JiraStore";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import ImportTaskSettings from "../ImportTaskSettings";
import JiraProgressCalculationSettings from "./JiraProgressCalculationSettings";
import ConfigureConnectionPanel from "../ConfigureConnectionPanel";

type OwnProps = {
    onDismiss: () => void;
    connectionId: string;
    projectId: string;
    enableMapping: boolean;
    initialTab?: string;
}

type StateProps = {
    issueTypes: IIssueTypeWithStatus[];
    configuration: IConnectionConfiguration;
    defaultTaskMappings: IFieldMapping[];
    ppmxfields: Field[];
    jiraFields: ExternalFieldInfo[];
    user: UserState;
    isLoading: boolean;
    isProcessing: boolean;
    error: string | null;
}

type Props = OwnProps & StateProps & typeof actionCreators;

export enum JiraTabs {
    ProgressCalculation = "progress-calculation",
    ImportTask = "import-task"
}

const updateProgressCalculationSettings = (props: Props, _: IProgressCalculationSettings) => {
    updateConnectionConfiguration(props, { ...props.configuration, progressCalculationSettings: _ });
}

const updateTaskImportSettings = (props: Props, _: TaskImportSettings) => {
    updateConnectionConfiguration(props, { ...props.configuration, taskImportSettings: _ });
}

const updateConnectionConfiguration = (props: Props, configuration: IConnectionConfiguration) => {
    const { connectionId, projectId } = props;
    props.updateConnectionConfiguration(connectionId, projectId, configuration);
}

const getPivots = (props: Props) => {
    const { user, jiraFields, onDismiss, connectionId, projectId, issueTypes, defaultTaskMappings, configuration, isLoading, isProcessing, error } = props;
    const isReadOnly = !contains(user.permissions.common, CommonOperations.ConnectionManage);

    return [
        <PivotItem headerText="Project Progress" className="pivot-content" itemKey={JiraTabs.ProgressCalculation} key="progress-calculation">
            <JiraProgressCalculationSettings
                connectionId={connectionId}
                projectId={projectId}
                issueTypes={issueTypes}
                progressCalculationSettings={configuration.progressCalculationSettings}
                readOnly={isReadOnly}
                error={error}
                isProcessing={isProcessing}
                isLoading={isLoading}
                onSave={_ => updateProgressCalculationSettings(props, _)}
                onDismiss={onDismiss} />
        </PivotItem>,
        <PivotItem headerText="External Items Mapping" className="pivot-content" itemKey={JiraTabs.ImportTask} key="import-task">
            <ImportTaskSettings
                sourceType={SourceType.Jira}
                typeLabel={"issue"}
                getExternalFields={() => jiraFields}
                externalTypes={issueTypes}
                taskImportSettings={configuration.taskImportSettings}
                readOnly={isReadOnly}
                onDismiss={onDismiss}
                onSave={_ => updateTaskImportSettings(props, _)}
                getDefaultTaskImportmapping={() => defaultTaskMappings}
                externalFieldToPpmxFieldsMap={jiraFieldToPpmxFieldsMap}
                isLoading={isLoading}
                isProcessing={isProcessing}
                error={error}
                helpLink="https://help.ppm.express/89435-jira-connection/861210-how-to-set-up-additional-fields-mapping-between-ppm-express-and-jira"
            />
        </PivotItem>
    ];
}

const JiraConfigureConnectionPanel = (props: Props) => {
    const { connectionId, projectId } = props;

    React.useEffect(() => { 
        props.loadIssueTypes(connectionId, projectId);
        props.loadDefaultTaskMappings(connectionId, projectId);
        props.loadConnectionConfiguration(connectionId, projectId);
        props.loadFields(connectionId, projectId);
    }, [connectionId]);

    return <>
        <ConfigureConnectionPanel
            {...props}
            sourceType={SourceType.Jira}
            pivots={getPivots(props)}
        />
    </>
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const fields = state.fields[EntityType.Project];
        
    return {
        issueTypes: state.jira.issueTypes.entities,
        configuration: {
            progressCalculationSettings: state.jira.progressCalculationSettings.entities,
            taskImportSettings: state.jira.taskImportSettings.entities
        },
        defaultTaskMappings: state.jira.defaultTaskMappings.entities,
        jiraFields: state.jira.fields.entities,
        ppmxfields: fields.allIds.map(_ => fields.byId[_]),
        user: state.user,
        error: state.jira.issueTypes.error || 
            state.jira.progressCalculationSettings.error || 
            state.jira.taskImportSettings.error,
        isLoading: state.jira.issueTypes.isLoading ||
            state.jira.progressCalculationSettings.isLoading ||
            state.jira.defaultTaskMappings.isLoading,
        isProcessing: state.jira.progressCalculationSettings.isProcessing
    }
}

export default connect(mapStateToProps, actionCreators)(JiraConfigureConnectionPanel);