import * as React from 'react';
import { connect } from 'react-redux';
import ConnectionSelect, { ConnectionVerifier } from "../ConnectionSelect";
import { actionCreators } from "../../../store/integration/MondayComStore";
import { IConnectionsState } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import MondayComConnectionEdit from "./MondayComConnectionEdit";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SourceType } from '../../../store/ExternalEpmConnectStore';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onChange: (connectionId?: string) => void;
};

type StateProps = { connections: IConnectionsState };
type Props = OwnProps & StateProps & typeof actionCreators;

const MondayComConnectionSelect = (props: Props) => {
    const [isAdd, setIsAdd] = React.useState(false);
    const [refreshId, setRefreshId] = React.useState<string>();

    React.useEffect(() => { props.loadConnections() }, []);
    
    return <>
        <ConnectionVerifier
            connections={props.connections}
            connectionId={props.connectionId}
            verifyConnection={props.verifyConnection}>
            <ConnectionSelect
                key="connection-select"
                disabled={props.connections.isLoading || !!props.disabled}
                sourceType={SourceType.MondayCom}
                connectionId={props.connectionId}
                connections={props.connections.data}
                onConnectionChange={_ => { 
                    if (_?.id) {
                        props.verifyConnection(_.id);
                    }
                    props.onChange(_?.id);
                }}
                onConnectionRemove={id => props.removeConnection(id, () => props.onChange())}
                onConnectionRename={(_, __) => props.renameConnection(_, __)}
                onRefreshClick={(_) => setRefreshId(_)}
                onNewConnectionClick={() => setIsAdd(true)}
            />
        </ConnectionVerifier>
        {props.connections.isLoading &&
            <Spinner key="spinner" size={SpinnerSize.small} className='field-spinner' />}
        {isAdd &&
            <MondayComConnectionEdit key="connection-adding" onCreated={props.onChange} onDismiss={() => setIsAdd(false)} />}
        {refreshId &&
            <MondayComConnectionEdit key="connection-refreshing" connectionId={refreshId} onDismiss={() => setRefreshId(undefined)} />}
    </>
}
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.mondayCom.connections };
}

export default connect(mapStateToProps, actionCreators)(MondayComConnectionSelect);