import * as React from 'react';
import { ITask, buildKeyDateIconName, buildMilestone } from "../../../../../entities/Subentities";
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import TaskIcon from '../../../../task/TaskIcon';
import EntityName from '../EntityName';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { EntityType, PROGRESS_COMPLETED, StatusCategory } from '../../../../../entities/common';
import { SourceType } from '../../../../../store/ExternalEpmConnectStore';
import * as StatusDescriptorFactory from '../../../../../entities/StatusDescriptorFactory';
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { Field } from '../../../../../entities/Metadata';

type OwnProps = IListViewFieldColumn<ITask> & { onlyIcon?: boolean, name?: string }
type StateProps = {
    fields: Field[];
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

class Title extends React.Component<Props, { milestone?: { iconName: string } }> {
    componentWillMount() {
        this.setState({ milestone: this._getMilestone(this.props) });
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState({ milestone: this._getMilestone(nextProps) });
    }
    
    render() {
        const { entity, isTimelineView, onlyIcon, disableNavigation, name } = this.props;
        const { milestone } = this.state;

        const isCompleted = entity.attributes.Progress === PROGRESS_COMPLETED;
        return <EntityName
            className={`${isCompleted ? 'crossed-out' : ''} ${this.props.className ?? ''} `}
            name={name ?? entity.attributes.Name}
            singleDate={entity.attributes.DueDate}
            startDate={entity.attributes.StartDate}
            finishDate={entity.attributes.DueDate}
            withSingleDate={!!milestone}
            withDates={!milestone}
            isTimelineView={isTimelineView}
            onClick={!disableNavigation ? this._openTask : undefined}
            renderLogo={() => <TaskIcon entity={this.props.entity} milestoneIconName={milestone?.iconName} summaryIcon />}
            sourceType={entity.sourceType !== null 
                && entity.sourceType !== SourceType.Ppmx 
                    ? [{ type: entity.sourceType, url: entity.externalData.NameLink }] 
                    : null}
            warnings={entity.warnings}
            onlyIcon={onlyIcon}
        />;
    }
    
    private _getMilestone = (props: Props): { iconName: string } | undefined => {
        const { entity, fields } = props;
        const milestone = buildMilestone(entity);

        if (!milestone) {
            return undefined;
        }

        const keyDateStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Task, fields)!;
        const statusCategory = keyDateStatusDescriptor.getCategoryOrDefault(milestone.attributes.Status, StatusCategory.NA);

        return {
            iconName: buildKeyDateIconName(milestone.attributes.Type, statusCategory)
        };
    }
    
    private _openTask = () => {
        const { entity, location, history } = this.props;

        const query = new URLSearchParams(this.props.location.search);
        query.set(EntityType.Task.toLowerCase(), entity.id);

        history.replace({
            ...location,
            search: query.toString()
        })
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const fields = state.fields[EntityType.Task];
    return {
        fields: fields.allIds.map(_ => fields.byId[_])
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps)(Title));