import * as React from 'react';
import { ExternalFieldInfo, IExternalTypeWithStatus, IFieldMapping, MappingType, TaskImportSettings } from '../../store/integration/common';
import { Overlay, DefaultButton, PrimaryButton, IconButton, Toggle, Link } from 'office-ui-fabric-react';
import Spinner from '../common/Spinner';
import { isDeepEqual } from '../utils/common';
import MappingPanel from './MappingPanel';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { EntityType } from '../../entities/common';
import { FieldType } from '../../entities/Metadata';

type Props = {
    sourceType: SourceType,
    typeLabel: string;
    getExternalFields: (issueType: IExternalTypeWithStatus) => ExternalFieldInfo[];
    externalTypes: IExternalTypeWithStatus[];
    taskImportSettings: TaskImportSettings;
    readOnly: boolean;
    onDismiss: () => void;
    onSave: (settings: TaskImportSettings) => void,

    getDefaultTaskImportmapping: (issueTypeId: string) => IFieldMapping[];
    externalFieldToPpmxFieldsMap: { [i: string]: { types: Partial<FieldType>[], label: string } }
    onGetExternalFieldOptions?: (field: ExternalFieldInfo, issueTypeId: string) => Promise<string[] | number[] | null>

    isLoading: boolean;
    isProcessing: boolean;
    error: string | null;

    helpLink?: string;
}

type State = {
    settings: TaskImportSettings;
    issueType?: IExternalTypeWithStatus;
}

export default class ImportTaskSettings extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = { settings: {} };
    }

    componentWillMount() {
        this.setState(this.buildState(this.props));
    }

    componentWillReceiveProps(nextProps: Props) {
        if ((this.props.isLoading && !nextProps.isLoading) || (this.props.isProcessing && !nextProps.isProcessing)) {
            this.setState(this.buildState(nextProps));
        }
    }

    render() {
        const { isLoading, isProcessing, error, externalTypes, readOnly, taskImportSettings, sourceType, 
            externalFieldToPpmxFieldsMap, helpLink, onDismiss, getExternalFields, onGetExternalFieldOptions } = this.props;

        if (isLoading) {
            return <Overlay><Spinner /></Overlay>;
        }
        if (error) {
            return <div className="error-message">{error}</div>;
        }

        const { settings, issueType } = this.state;
        return <div className="connection-settings">
            {helpLink && <div className='description'>Please refer to {this._renderHelpLink("this article")} on how to configure mapping.</div>}
            <ul className="scrollable-content cb-list">
                {externalTypes.map(this._renderIssueType)}
            </ul>
            <div className="commands">
                <PrimaryButton text="Save"
                    disabled={readOnly || isDeepEqual(settings, taskImportSettings)}
                    onClick={this._saveMapping} />
                <DefaultButton text={readOnly ? "Close" : "Cancel"} onClick={onDismiss} />
            </div>
            {issueType && <MappingPanel typeName={issueType.name}
                    connector={sourceType}
                    entityType={EntityType.Task}
                    isLoading={false}
                    error={error}
                    onDismiss={() => this.setState({ issueType: undefined })}
                    mapping={settings[issueType.id]}
                    externalFields={getExternalFields(issueType)}
                    typesMap={externalFieldToPpmxFieldsMap}
                    mappingTypes={[MappingType.ToPpmx]}
                    updateMapping={_ => this.setState({
                        issueType: undefined,
                        settings: { ...this.state.settings, [issueType.id]: _ }
                    }, this._saveMapping)}
                    showTransformationScript
                    onGetExternalFieldOptions={onGetExternalFieldOptions && ((field: ExternalFieldInfo) => onGetExternalFieldOptions(field, issueType.id))}
                />}
            {isProcessing && <Overlay><Spinner /></Overlay>}
        </div>;
    }

    private _renderIssueType = (type: IExternalTypeWithStatus) => {
        const { typeLabel } = this.props;
        const { settings } = this.state;
        return (
            <li key={type.id} className="field itemtype">
                <div className="align-center">
                    <div className="cb-wrap">
                        {
                            type.iconUrl &&
                            <div>
                                <span className="icon" style={{ backgroundImage: `url(${type.iconUrl})` }} />
                            </div>
                        }
                        <div className="title bold" title={type.name}>{type.name}</div>
                        <div className="align-center">
                            {
                                <IconButton menuIconProps={{ iconName: 'PPMXGear' }}
                                    title={`Edit ${typeLabel} type configuration`}
                                    onClick={() => {
                                        if (!settings[type.id]) {
                                            this._addIssueType(type.id)
                                        }

                                        this.setState({ issueType: type });
                                    }} />
                            }
                        </div>
                        <Toggle
                            checked={!!settings[type.id]}
                            onChange={(e, checked) => checked ? this._addIssueType(type.id) : this._removeIssueType(type.id)}
                        />
                    </div>
                </div>
            </li>
        );
    }

    private _removeIssueType = (issueTypeId: string) => {
        const { settings } = this.state;
        delete settings[issueTypeId];
        this.setState({ settings });
    }

    private _addIssueType = (issueTypeId: string) => {
        const { settings } = this.state;
        this.setState({
            settings: {
                ...settings,
                [issueTypeId]: JSON.parse(JSON.stringify(this.props.taskImportSettings[issueTypeId] || this.props.getDefaultTaskImportmapping(issueTypeId)))
            }
        });
    }

    private _saveMapping = () => {
        const { settings } = this.state;
        this.props.onSave(settings);
    }

    private buildState = (props: Props) => {
        return { settings: JSON.parse(JSON.stringify(props.taskImportSettings)) };
    }

    private _renderHelpLink(text: string) {
        return (
            <Link href={this.props.helpLink} target="_blank">
                {text}
            </Link>
        );
    }
}