import { PrimaryButton, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { IResourceSourceData } from '../../../store/integration/common';
import OverlayComponent from '../../common/OverlayComponent';

type Props = {
    actions: {
        deleteUserLink: (connectionId: string) => void;
    };
    sourceData?: MPPFileUserLinkInfo;
    connectionId?: string;
}

export interface MPPFileUserLinkInfo extends IResourceSourceData {
    email: string;
}

const MPPFileConnectControl = (props: Props) => {
    const {sourceData, connectionId} = props;

    if (!sourceData) {
        return null;
    }

    const deleteUserLink = React.useCallback(() => {
        if (!connectionId) {
            return null;
        }   
        return props.actions.deleteUserLink(connectionId);
    }, [connectionId]);

    return <div>
        <TextField label="Name"
            disabled
            value={sourceData.displayName} />
        <TextField label="Email"
            disabled
            value={sourceData.email} />
        <OverlayComponent isOverlayed={!connectionId}>
            <PrimaryButton text="Delete resource link"
                        className="with-top-margin"
                        onClick={() => deleteUserLink()} />
        </OverlayComponent>
    </div>
}

export default MPPFileConnectControl;