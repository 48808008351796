import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DebouncedAction, distinctBy, groupBy, toDate, toDateTime } from "../utils/common";
import { EntityType, IExtensibleEntity, ITimeframe, IWithScale, Quantization, ServerEntityType, TimelineControlSettings } from "../../entities/common";
import { CreateTimeTrackingEntryAttr, TimeTrackingEntry, TimeTrackingEntryAttr, TimeTrackingGridPeriod, TimeTrackingLine, TimeTrackingTaskDetails, actionCreators as TimeTrackingActionCreators, TimeTrackingProjectDetails, CreateTimeTrackingEntry } from "../../store/TimeTrackingStore";
import { MyWork, MyWorkType } from "../../store/MyWorkStore";
import { PPMFeatures, Subscription, TenantState, TimeTrackingAdministrativeCategory } from "../../store/Tenant";
import { IRow } from "../common/timeline/TimelineList";
import { SortService } from "../../services/SortService";
import { Field, FieldGroup, FieldType, SortDirection } from "../../entities/Metadata";
import { nameof } from "../../store/services/metadataService";
import { WithEntityType } from "./TimeTrackingGrid";
import { Views } from "../../store/services/viewSaver";
import { calculateDateAndTimeFrame } from "./TimeTrackingTimeFrameSelector";
import { cancellableGet, get, post } from "../../fetch-interceptor";
import { addOrUpdateOrRemove } from "../../store/services/storeHelper";
import { Resource } from "../../store/ResourcesListStore";
import { ApplicationState } from "../../store";

export const NameField: Field = {
    id: "name",
    name: "Name",
    type: FieldType.Text,
    isNative: false,
    isSystem: false,
    isCustom: false,
    label: "Projects / Tasks",
    isReadonly: true,
    isFake: true,
    group: FieldGroup.SystemFields,
    settings: {
        views: {
            list: {
                minWidth: 350
            }
        }
    },
};

export const TotalReportedField: Field = {
    id: "totalReported",
    name: "TotalReported",
    type: FieldType.Text,
    isNative: false,
    isSystem: false,
    isCustom: false,
    label: "Total Reported",
    isReadonly: true,
    isFake: true,
    group: FieldGroup.SystemFields,
};

export type ControlSettings = IWithScale &
{
    viewType: Views,
    timeline: TimelineControlSettings,
    suggestTimeEntries: boolean
};

export const useDebounceActions = (resource: Resource | undefined, timeTrackingActions: typeof TimeTrackingActionCreators) => {
    
    return useMemo(() => {

        const debouncedUpdate = new DebouncedAction(
            (data: { id: string, attributes: Partial<TimeTrackingEntryAttr> }[]) => {
                if (data) {
                    
                    //get the latest update
                    data.reverse();
                    data = distinctBy(data, _ => _.id);
                    data.reverse();
    
                    timeTrackingActions.updateTimeEntries(data);
                }
            });
    
        const debouncedCreate = new DebouncedAction(
            (data: CreateTimeTrackingEntry[]) => {
                if (data) {
    
                    //get the latest insert
                    data.reverse();
                    data = distinctBy(data, _ => `${_.attributes.Project?.id}_${_.attributes.Task?.id}_${_.attributes.CustomTimeEntryName}_${_.attributes.AdministrativeCategory}_${_.attributes.Date}`);
                    data.reverse();
    
                    resource && timeTrackingActions.addTimeEntries(resource.id, data);
                }
            }
        );
    
        return { debouncedCreate, debouncedUpdate };

    }, [resource, timeTrackingActions]);
}

const _administrativeProjectId = "administrative";

export const BuildEntriesTimeTrackingLines = (
    entries: TimeTrackingEntry[],
    administrativeCategories: TimeTrackingAdministrativeCategory[],
    timeFrame: ITimeframe,
    tasks: TimeTrackingTaskDetails[],
    projects: TimeTrackingProjectDetails[],
    period: TimeTrackingGridPeriod
): TimeTrackingLine[] => {

    entries = entries.filter(_ => timeFrame.start <= _.attributes.Date && _.attributes.Date <= timeFrame.end);

    const groups = groupBy(
        entries,
        _ => _buildTaskLineKey(
            _.attributes.Project?.id ?? _administrativeProjectId,
            _.attributes.Task?.uniqueId ?? _.attributes.Task?.id ?? _.attributes.CustomTimeEntryName ?? _.attributes.AdministrativeCategory ?? "")            
    );

    const lines: TimeTrackingLine[] = [];

    for (const [key, entriesByTask] of Object.entries<TimeTrackingEntry[]>(groups)) {
        const timeEntry = entriesByTask[entriesByTask.length - 1].attributes;

        const administrativeCategory = timeEntry.AdministrativeCategory ? administrativeCategories.find(_ => _.id === timeEntry.AdministrativeCategory) : undefined;

        lines.push({
            id: key,
            attributes: {
                Project: timeEntry.Project
                    ? timeEntry.Project
                    : timeEntry.AdministrativeCategory
                        ? {
                            id: _administrativeProjectId,
                            name: "Administrative"
                        }
                        : { id: "Unknown", name: "Unknown" },
                Task: timeEntry.Task
                    ? timeEntry.Task
                    : timeEntry.CustomTimeEntryName
                        ? {
                            id: timeEntry.CustomTimeEntryName!,
                            name: timeEntry.CustomTimeEntryName!
                        }
                        : {
                            id: timeEntry.AdministrativeCategory!,
                            name: administrativeCategory?.title ?? timeEntry.AdministrativeCategory!
                        },
                TaskDetails: timeEntry.Task ?
                    (
                        tasks.find(_ => _.id === timeEntry.Task?.id) ||
                        {
                            id: timeEntry.Task.id,
                            attributes: { Name: timeEntry.Task.name },
                            uniqueId: timeEntry.Task.uniqueId,
                            externalData: {},
                            sourceType: null,
                            isDeleted: true
                        })
                        :  administrativeCategory && !administrativeCategory.isEnabled
                        ? {
                            id: "",
                            attributes: {},
                            externalData: {},
                            sourceType: null,
                            isArchived: true
                        }
                        : undefined,
                ProjectDetails: timeEntry.Project && (
                    projects.find(_ => _.id === timeEntry.Project?.id) ||
                    {
                        id: timeEntry.Project.id,
                        name: timeEntry.Project.name,
                        isDeleted: true,
                        attributes: {}
                    }),
                IsAdministrative: !!timeEntry.AdministrativeCategory,
                IsCustomEvent: !!timeEntry.CustomTimeEntryName,
                TotalReported: entriesByTask.reduce((sum, current) => sum + (current.attributes.Duration ?? 0), 0),
                TimeFrame: timeFrame,
                Period: period
            },
            TimeEntries: entriesByTask
        });
    }
   
    return lines;
};

export const BuildProjectLines = (lines: TimeTrackingLine[]): TimeTrackingLine[] => {
    const projectLines: TimeTrackingLine[] = [];
    
    for (const [_, tasksByProject] of Object.entries<TimeTrackingLine[]>(groupBy(lines, l => l.attributes.Project.id))) {
        const project: TimeTrackingLine = {
            ...tasksByProject[0],
            attributes: {
                ...tasksByProject[0].attributes,
                TotalReported: tasksByProject.reduce((sum, task) => sum + task.attributes.TotalReported, 0),
                HasSuggestedTasks: tasksByProject.some(task => task.attributes.HasSuggestedTasks)
            },
            TimeEntries: tasksByProject.reduce((projEntries, task) => projEntries.concat(task.TimeEntries), new Array<TimeTrackingEntry>())
        };
        project.attributes.TotalReported = tasksByProject.reduce((sum, task) => sum + task.attributes.TotalReported, 0);

        projectLines.push(project);
    }
   
    projectLines.sort((p1, p2) => {
        if (p1.attributes.IsAdministrative) {
            return 1;
        }

        if (p2.attributes.IsAdministrative) {
            return -1;
        }

        return p1.attributes.Project.name < p2.attributes.Project.name
            ? - 1
            : (p1.attributes.Project.name > p2.attributes.Project.name ? 1 : 0);
    });

    return projectLines;
}

export function BuildAssignmentSuggestionTimeTrackingLines(
    work: MyWork[],
    timeFrame: ITimeframe,
    tasks: TimeTrackingTaskDetails[],
    projects: TimeTrackingProjectDetails[],
    period: TimeTrackingGridPeriod
): TimeTrackingLine[] {

    const lines: TimeTrackingLine[] = [];
    
    work.forEach(assignment => {

        if (assignment.source.type !== MyWorkType.Task || assignment.attributes.Parent.isPrivate) {
            return;
        }

        const isWithinTimeFrame =
            //start is within
            assignment.attributes.StartDate &&
            toDate(assignment.attributes.StartDate)! >= timeFrame.start &&
            toDate(assignment.attributes.StartDate)! <= timeFrame.end ||
            //due date is within
            assignment.attributes.DueDate &&
            toDate(assignment.attributes.DueDate)! >= timeFrame.start &&
            toDate(assignment.attributes.DueDate)! <= timeFrame.end ||
            // task is too long and time range is within task dates
            assignment.attributes.StartDate &&
            toDate(assignment.attributes.StartDate)! < timeFrame.start &&
            assignment.attributes.DueDate &&
            toDate(assignment.attributes.DueDate)! > timeFrame.end;

        if (!isWithinTimeFrame) {
            return;
        }

        const assignmentTaskDetails = tasks.find(_ => _.id === assignment.source.id);
        const key = _buildTaskLineKey(assignment.attributes.Parent.id, assignmentTaskDetails?.uniqueId ?? assignment.source.id);

        lines.push({
            id: key,
            TimeEntries: [],
            attributes: {
                Project: {
                    name: assignment.attributes.Parent.name,
                    id: assignment.attributes.Parent.id
                },
                Task: {
                    name: assignment.attributes.Name,
                    id: assignment.source.id,
                    uniqueId: assignmentTaskDetails?.uniqueId
                },
                TaskDetails: assignmentTaskDetails ?? {
                    ...assignment,
                    sourceType: assignment.source.link?.type ?? null,
                    externalData: {
                        NameLink: assignment.source.link?.url
                    }
                },
                ProjectDetails: projects.find(_ => _.id == assignment.attributes.Parent.id) ?? {
                    id: assignment.attributes.Parent.id,
                    imageId: assignment.attributes.Parent.imageId,
                    name: assignment.attributes.Parent.name,
                    attributes: {}
                },
                IsSuggestion: true,
                TotalReported: 0,
                TimeFrame: timeFrame,
                Period: period
            }
        });
        
    });
   
    return lines;
};

export const BuildResourcePlanSuggestionTimeTrackingLines = (
    projects: TimeTrackingProjectDetails[],
    timeFrame: ITimeframe,
    period: TimeTrackingGridPeriod): TimeTrackingLine[] => {
    
    const projectWorkTaskName = "Project Work";

    const result: TimeTrackingLine[] = projects.map(project => {

        return {
            id: _buildTaskLineKey(project.id, projectWorkTaskName),
            TimeEntries: [],
            attributes: {
                Project: {
                    name: project.name,
                    id: project.id
                },
                Task: {
                    name: projectWorkTaskName,
                    id: projectWorkTaskName
                },
                TaskDetails: undefined,
                ProjectDetails: project,
                IsSuggestion: true,
                TotalReported: 0,
                TimeFrame: timeFrame,
                Period: period,
                IsCustomEvent: true
            }
        };

    });

    return result;
}

export const MergeTimeTrackingLines = (
    entryLines: TimeTrackingLine[],
    assignmentSuggestionLines: TimeTrackingLine[],
    resourcePlanSuggestions: TimeTrackingLine[]
): TimeTrackingLine[] => {

    const result = [...entryLines];

    assignmentSuggestionLines.concat(resourcePlanSuggestions).forEach(suggestion => {

        const existingLine = entryLines.find(_ => _.id == suggestion.id);

        if (existingLine) {
            existingLine.attributes.HasSuggestedTasks = true;
        }
        else {
            result.push(suggestion);
        }
    });

    return result;
}


const _buildTaskLineKey = (projectId: string, taskUniqueIdOrId: string) => {
    return `${projectId}_${taskUniqueIdOrId}`;
}

export const BuildLineGroup = (groupingEntity: IExtensibleEntity, projectLines: TimeTrackingLine[], lines: TimeTrackingLine[]): IRow => {    
   
    const result = {
        key: groupingEntity.id,
        entity: groupingEntity,
        subItems: projectLines.map<IRow>(project => {
            const projectInfo = project.attributes.Project;
            const tasks = lines.filter(_ => _.attributes.Project.id === projectInfo.id);

            tasks.sort(SortService.getSimpleComparer({ direction: SortDirection.ASC, fieldName: "name" }, (task, field) => task.attributes.Task[field]));

            return {
                key: projectInfo.id,
                entity: {
                    ...project,
                    attributes: {
                        ...project.attributes,
                        Name: projectInfo.name
                    },
                    id: projectInfo.id,
                    key: projectInfo.id,
                    [nameof<WithEntityType>("entityType")]: EntityType.Project
                },
                markers: [],
                segments: [],
                subItemType: EntityType.Project,
                subItems: tasks
                    .map<IRow>(task => {
                        const taskInfo = task.attributes.Task;

                        const key = _buildTaskLineKey(projectInfo.id, taskInfo.uniqueId ?? taskInfo.id);

                        return {
                            key: key,
                            entity: {
                                ...task,
                                attributes: {
                                    ...task.attributes,
                                    Name: taskInfo.name
                                },
                                id: key,
                                key: key,
                                [nameof<WithEntityType>("entityType")]: EntityType.Task
                            },
                            subItemType: EntityType.Task,
                            markers: [],
                            segments: []
                        };
                    })
            };
        }),
        markers: [],
        segments: []
    };

    return result;
};

export const usePeriodAndTimeFrame = (controlSettings: ControlSettings) => {

    return useMemo(() => {

        const period = (controlSettings.quantization ?? Quantization.weeks) as TimeTrackingGridPeriod;
        const timeFrame = controlSettings.timeframe
            ?
            {
                start: toDateTime(controlSettings.timeframe.start)!,
                end: toDateTime(controlSettings.timeframe.end)!,
            }
            : calculateDateAndTimeFrame(new Date().getBeginOfDay(), period).timeFrame;
        
        timeFrame.start = timeFrame.start.getBeginOfDay();
        timeFrame.end = timeFrame.end.getEndOfDay();

        return {
            period,
            timeFrame
        };

    }, [
        controlSettings.quantization,
        controlSettings.timeframe?.start,
        controlSettings.timeframe?.end]);
}

export const useSuggestedTasksDetails = () => {
    const [suggestedTasks, setSuggestedTasks] = useState<TimeTrackingTaskDetails[]>([]);

    const loadSuggestedTasks = useCallback((ids: string[]) => {

        if (!ids.length) {
            return;
        }

        LoadTimeTrackingTaskDetails(ids)
            .then(data => {
            
                setSuggestedTasks(addOrUpdateOrRemove(suggestedTasks, data));
            });

       
    }, [suggestedTasks]);

    return { suggestedTasks, loadSuggestedTasks };
}

export const useSuggestedProjectsDetails = () => {
    const [suggestedProjects, setSuggestedProjects] = useState<TimeTrackingProjectDetails[]>([]);

    const loadSuggestedProjects = useCallback((ids: string[]) => {

        if (!ids.length) {
            return;
        }

        LoadTimeTrackingProjectDetails(ids)
            .then(data => {
            
                setSuggestedProjects(addOrUpdateOrRemove(suggestedProjects, data));
            });

       
    }, [suggestedProjects]);

    return {  suggestedProjects, loadSuggestedProjects };
}

export const LoadTimeTrackingTaskDetails = (taskIds: string[]) => {
    return post<TimeTrackingTaskDetails[]>("/api/timetracking/tasks", { ids: taskIds })
}

export const LoadTimeTrackingProjectDetails = (projectIds: string[]) => {
    return post<TimeTrackingProjectDetails[]>("/api/timetracking/projects", { ids: projectIds })
}

export const useResourcePlanProjects = (resource: Resource | undefined, timeFrame: ITimeframe, mergeResourcePlanSuggestions: boolean ) => {
    const [resourcePlanProjects, setResourcePlanProjects] = useState<TimeTrackingProjectDetails[]>([]);
    
    useEffect(() => {

        if (!resource || !mergeResourcePlanSuggestions) {
            return () => { };
        }

        const params = {
            start: timeFrame.start.toDateOnlyString(),
            end: timeFrame.end.toDateOnlyString()
        };

        const request = cancellableGet<TimeTrackingProjectDetails[]>(`/api/timetracking/resource/${resource.id}/plan/projects`, params);
        let isCancelled = false;
        
        request.promise
            .then(projects => {
                setResourcePlanProjects(projects);
            })
            .catch(_ => {
                if (!isCancelled) {
                    console.error(_);   
                }
            });
        
        return () => {
            request.cancelTokenSource.cancel();
            isCancelled = true;
        };        
    }, [resource?.id, mergeResourcePlanSuggestions, timeFrame.start.toDateOnlyString(), timeFrame.end.toDateOnlyString()]);

    return resourcePlanProjects;
}

export const GetNewEntryDate = (timeFrame: ITimeframe): Date => {

    const today = new Date();

    if (timeFrame.start <= today && today <= timeFrame.end) {
        return today;
    }

    return timeFrame.start;
}

export const IsShowMergeSmartSuggerstions = (tenant: TenantState): boolean => {
    return tenant.timeTracking.globalSettings.enabledAssignmentSmartSuggestions
        || tenant.timeTracking.globalSettings.enablaResourcePlanSmartSuggestion
        && ShowResourcePlanSmartSuggestion(tenant);
}

export const ShowResourcePlanSmartSuggestion = (tenant: TenantState): boolean => {
    return Subscription.contains(tenant.subscription, PPMFeatures.ResourceManagement)
    && tenant.resourcePlanningSettings?.resourcePlanningLevel == ServerEntityType.Project;
}

export const EnableCalculatingResourcePlanActualsBasedOnReportedTime = (tenant: TenantState): boolean => {
    return Subscription.contains(tenant.subscription, PPMFeatures.TimeTrackingEnterprise) &&
        ShowResourcePlanSmartSuggestion(tenant);
}

export const ShowO365Connector = (state: ApplicationState): boolean => {
    return state.tenant.timeTracking.globalSettings.enableO365Connector && !!state.personalOffice365.timeTrackingConnection
}