import { get, post } from './../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { Dictionary } from "../entities/common";
import { catchApiError, defaultCatch } from "./utils";
import { getDaysBetweenDates } from '../components/common/timeline/utils';
import { toDate } from '../components/utils/common';
import { DayOfWeek } from 'office-ui-fabric-react';
import fileDownload from 'js-file-download';
import * as Notifications from "./NotificationsStore";

export interface CalendarException {
    id?: string;
    start: Date;
    end: Date;
    days: DayOfWeek[];
    expectedHrs: number;
    name: string;
    description: string;
}

export interface CalendarDataSet {
    workDayExpectedHrs: Dictionary<number>;
    exceptions: CalendarException[];
    exceptionsHoursMap: { [id: number]: number };
    workingHoursPerWeek: number;
}

export interface CalendarState extends CalendarDataSet {
    isLoading: boolean;
    updating: 'exceptions' | 'workDayExpectedHrs' | undefined;
}

interface CalendarData {
    workDayExpectedHrs: Dictionary<number>;
    exceptions: CalendarException[];
}

export interface CalendarUpdateModel {
    workDayExpectedHrs: Dictionary<number> | null;
    toCreate: CalendarException[];
    toUpdate: CalendarException[];
    toDelete: string[];
}

const unloadedState: CalendarState = {
    workDayExpectedHrs: {},
    exceptions: [],
    exceptionsHoursMap: {},
    workingHoursPerWeek: 0,
    isLoading: false,
    updating: undefined
};

interface LoadCalendarAction {
    type: 'LOAD_CALENDAR';
}

interface CalendarLoadedAction {
    type: 'CALENDAR_LOADED';
    calendar: CalendarData;
}

interface CalendarUpdatingAction {
    type: 'CALENDAR_UPDATING';
    updating?: 'exceptions' | 'workDayExpectedHrs';
}

export interface SaveCalendarSuccessAction {
    type: 'SAVE_CALENDAR_SUCCESS';
    calendar: CalendarData;
}

type KnownAction = 
    | LoadCalendarAction
    | CalendarLoadedAction
    | CalendarUpdatingAction
    | SaveCalendarSuccessAction;

export const actionCreators = {
    load: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<CalendarData>(`api/metadata/calendar`)
            .then(data => dispatch({ type: 'CALENDAR_LOADED', calendar: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_CALENDAR' });
    },
    save: (update: Partial<CalendarUpdateModel>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<CalendarData>(`api/metadata/calendar`, update)
            .then(data => {
                dispatch({ type: "SAVE_CALENDAR_SUCCESS", calendar: data });
                dispatch({ type: 'CALENDAR_UPDATING', updating: undefined });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'CALENDAR_UPDATING', updating: update.toCreate || update.toDelete || update.toUpdate ? 'exceptions' : update.workDayExpectedHrs ? 'workDayExpectedHrs' : undefined });
    },
    exportExceptionsToFile: (fields: string[], ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<string>('api/metadata/calendar/exceptions/exportToFile', { fields, ids })
            .then(data => fileDownload(data, 'Tenant Calendar Exceptions.csv'))
            .catch(defaultCatch(dispatch));
    },
    importExceptionsFromFile: (file: File): AppThunkAction<KnownAction | Notifications.KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        formData.set('data', file);
        post<CalendarData>(`api/metadata/calendar/exceptions/importFromFile`, formData)
            .then(data => {
                dispatch({ type: "SAVE_CALENDAR_SUCCESS", calendar: data });
                dispatch({ type: 'CALENDAR_UPDATING', updating: undefined });
            })
            .catch(catchApiError(_ => {
                dispatch(Notifications.actionCreators
                    .pushNotification({ message: `Failed to import. ${_ ? _ : ""}`, type: Notifications.NotificationType.Error }))
            }));
    },
};

export const reducer: Reducer<CalendarState> = (state: CalendarState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'LOAD_CALENDAR':
            return {
                ...state,
                isLoading: true
            };
        case 'CALENDAR_LOADED':
            {
                const exceptions = checkExceptionDates(action.calendar.exceptions);
                return {
                    ...state,
                    ...action.calendar,
                    exceptions,
                    exceptionsHoursMap: splitToDays(exceptions).reduce((cum, _) => ({ ...cum, [_.date.getTime()]: _.expectedHrs }), {}),
                    workingHoursPerWeek: Object.keys(action.calendar.workDayExpectedHrs).reduce((sum, _) => (sum + action.calendar.workDayExpectedHrs[_]), 0),
                    isLoading: false
                };
            }
        case 'CALENDAR_UPDATING': {
            return {
                ...state,
                updating: action.updating
            };
        }
        case 'SAVE_CALENDAR_SUCCESS': {
            const exceptions = checkExceptionDates(action.calendar.exceptions);
            return {
                ...state,
                ...action.calendar,
                exceptions,
                exceptionsHoursMap: splitToDays(exceptions).reduce((cum, _) => ({ ...cum, [_.date.getTime()]: _.expectedHrs }), {}),
                workingHoursPerWeek: Object.keys(action.calendar.workDayExpectedHrs).reduce((sum, _) => (sum += action.calendar.workDayExpectedHrs[_]), 0)
            };
        }
        default: const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};


export function checkExceptionDates(exceptions: CalendarException[]): CalendarException[] {
    return exceptions.map(_ => ({
        ..._,
        start: toDate(_.start)!.getBeginOfDay(),
        end: toDate(_.end)!.getBeginOfDay()
    }));
}

//similar method is on server side in CalendarExceptionExtensions
export function splitToDays(exceptions: {
    start: Date;
    end: Date;
    days: DayOfWeek[];
    expectedHrs: number;
}[]): { date: Date, isWorkingDay: boolean, expectedHrs: number }[] {
    return exceptions
        .map(_ => getDaysBetweenDates(_.start, _.end)
            .filter(__ => _.days.includes(__.getDay()))
            .map(__ => ({ date: __, isWorkingDay: _.expectedHrs > 0, expectedHrs: _.expectedHrs })))
        .reduce((cum, cur) => ([...cum, ...cur]), [])
        .reduce((cum, cur) => {
            const key = cur.date.getTime();
            if (!cum.set.has(key)) {
                cum.set.add(key);
                cum.arr.push(cur);
            }
            return cum;
        }, { set: new Set(), arr: [] as { date: Date, isWorkingDay: boolean, expectedHrs: number }[] })
        .arr;
}