import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import FieldComponent, { IInputRender, OnChangeEvent } from "../sectionsControl/uiControls/fieldsArea/FieldComponent";
import { Dictionary, EntityType } from '../../../entities/common';
import { Validator } from '../../../validation';

export type FieldConfig = {
    renderInput?: IInputRender;
    validator: Validator;
    readOnly: boolean;
}

export type EntityState = {
    attributes: Dictionary<unknown>;
}

type FieldEditorProps = {
    field: Metadata.Field;
    entity: EntityState;
    onChanged: OnChangeEvent;
    subentityType: EntityType
} & FieldConfig

const FieldEditor = (props: FieldEditorProps) => {
    const { field, entity, subentityType, readOnly } = props;
    const warnings = React.useMemo(() => field.settings?.warning && [{ text: field.settings?.warning, type: '' }], [field]);
    return <FieldComponent
        {...props}
        state={entity}
        className={`grid-item ${field.settings?.['views']?.['details']?.['className'] || ''}`}
        entityType={subentityType}
        disabled={readOnly}
        warnings={warnings}
    />;
}

export default FieldEditor;