import React from 'react';
import {  IRefInfo, ServerEntityType } from '../../../entities/common';
import IdeaFormatter from './IdeaFormatter';
import ProjectFormatter from './ProjectFormatter';

type Props = {
    data?: IRefInfo;
    withNavigation?: boolean;
};

export const RefFormatter = (props: Props) => {
    const { data, withNavigation } = props;

    if (!data) {
        return null;
    }

    if (data.entityType === ServerEntityType.Project) {
        return <ProjectFormatter project={data} withNavigation={withNavigation && !data.isDeleted} />;
    }
    if (data.entityType === ServerEntityType.Idea) {
        return <IdeaFormatter idea={data} withNavigation={withNavigation && !data.isDeleted} />;
    }

    return <span>{data.name}</span>;
}