import { get } from '../fetch-interceptor';
import { RouterAction } from "react-router-redux";
import { AppThunkAction } from ".";
import { Reducer, Action } from "redux";
import { Tracker } from "./PreferencesTracker";
import { Dictionary, UserPreferencesSettingsUpdate } from "../entities/common";
import { defaultCatch } from './utils';
import { saveSettings } from './Sections';

export interface UtilizationState {
    isLoading: boolean;
    settings: any;
}

interface RequestSettingsAction {
    type: 'LOAD_UTILIZATION_SETTINGS';
}

interface ReceiveSettingsAction {
    type: 'LOADED_UTILIZATION_SETTINGS';
    settings: any;
}

interface SaveSettingsAction {
    type: 'SAVE_UTILIZATION_SETTINGS';
    update: UserPreferencesSettingsUpdate;
}

type KnownAction = RequestSettingsAction
    | ReceiveSettingsAction
    | SaveSettingsAction;

export const actionCreators = {
    loadSettings: (): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        get<Dictionary<any>[]>(`api/user/preferences/utilization`)
            .then(data => dispatch({ type: 'LOADED_UTILIZATION_SETTINGS', settings: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_UTILIZATION_SETTINGS' });
    },
    saveSettings: (update: UserPreferencesSettingsUpdate, sendToServer: boolean = true): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        dispatch({ type: 'SAVE_UTILIZATION_SETTINGS', update });
        if (sendToServer) {
            Tracker.setUtilizationSettings(dispatch, getState, update);
        }
    }
};

const INIT_STATE: UtilizationState = {
    isLoading: false,
    settings: {}
};

export const reducer: Reducer<UtilizationState> = (state: UtilizationState = INIT_STATE, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_UTILIZATION_SETTINGS':
            {
                return {
                    ...state,
                    isLoading: true
                };
            }
        case 'LOADED_UTILIZATION_SETTINGS':
            {
                return {
                    ...state,
                    isLoading: false,
                    settings: action.settings
                };
            }
        case 'SAVE_UTILIZATION_SETTINGS': return saveSettings(state, action.update);
        default:
            const exhaustiveCheck: never = action;
    }
    return state;
};