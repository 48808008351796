import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import Home from './components/Home';
import ProjectDetails from './components/project/ProjectDetails';
import ProjectsList from './components/project/ProjectsList';
import ProjectTasksList from './components/task/ProjectTasksList';
import PortfolioDetails from './components/portfolio/PortfolioDetails';
import ResourcesList from './components/resource/ResourcesList';
import ResourceDetails from './components/resource/ResourceDetails';
import StrategicPrioritiesList from './components/priority/StrategicPrioritiesList';
import ChallengesList from './components/challenge/ChallengesList';
import ChallengeDetails from './components/challenge/ChallengeDetails';
import IdeaDetails from './components/idea/IdeaDetails';
import UsersList from "./components/user/UsersList";
import QuickStart from "./components/onboarding/QuickStart";
import InsightsSettings from "./components/InsightsSettings";
import TimeSettings from "./components/time/TimeSettings";
import SetupScreen from "./components/setup/SetupScreen";
import PortfoliosList from './components/portfolio/PortfoliosList';
import { IRoute } from "./components/navigation/common";
import ErrorPage from "./components/ErrorPage";
import InactiveUser from './components/navigation/InactiveUser';
import BillingManage from './components/settings/billing/BillingManage';
import NoLicense from './components/navigation/NoLicense';
import SettingsPage from './components/settings/SettingsPage';
import SubscriptionExpired from './components/navigation/SubscriptionExpired';
import * as TeamsApp from './components/teamsApp';
import PPMInsights from './components/insights/PPMInsights';
import { NotEnoughPermissionsMessage } from './store/permissions';
import CalendarSettings from './components/settings/CalendarSettings';
import MyWorkload from './components/mywork/MyWorkload';
import ResourceWorkload from './components/mywork/ResourceWorkload';
import Automation from './components/settings/publicApi/Automation';
import Invite from './Invite';
import ObjectivesList from './components/objective/ObjectivesList';
import ObjectiveDetails from './components/objective/ObjectiveDetails';
import ProgramsList from './components/program/ProgramsList';
import RoadmapsList from './components/roadmap/RoadmapsList';
import RoadmapDetails from './components/roadmap/RoadmapDetails';
import ProgramDetails from './components/program/ProgramDetails';
import ReportSettings from './components/ReportSettings';
import NavigateToTask from './components/task/NavigateToTask';
import ArchivedProjectsList from './components/project/ArchivedProjectsList';
import ArchivedProjectDetails from './components/project/ArchivedProjectDetails';
import ArchivedProjectTasksList from './components/task/ArchivedProjectTasksList';
import TenantSettings from './components/settings/tenantSettings/TenantSettings';
import { RoutesAvailability } from './store/Tenant';

import TimeTrackingSettings from "./components/settings/timeTracking/TimeTrackingSettings";
import MyTime from './components/mytime/MyTime';
import ResourceTime from './components/mytime/ResourceTime';

export const routes: IRoute[] = [
    {
        path: '/',
        exact: true,
        title: <Icon iconName="PPMXHome" />,
        component: Home,
        childs: [
            {
                path: '/portfolios/:type?/:subViewId?',
                url: '/portfolios',
                title: "Portfolio",
                component: PortfoliosList,
                isAvailable: RoutesAvailability.Portfolios,
                childs: [
                    {
                        path: '/portfolio/:id?',
                        title: (s, id) => s.portfolios.activeEntity && s.portfolios.activeEntity.id === id && s.portfolios.activeEntity.attributes.Name,
                        component: PortfolioDetails
                    },
                    {
                        path: '/portfolios/:type(bulk)',
                        title: "Bulk Edit",
                        component: PortfoliosList,
                    }
                ]
            },
            {
                path: '/programs/:type?/:subViewId?',
                url: '/programs',
                title: "Program",
                component: ProgramsList,
                isAvailable: RoutesAvailability.Programs,
                childs: [
                    {
                        path: '/program/:id?',
                        title: (s, id) => s.programs.activeEntity && s.programs.activeEntity.id === id && s.programs.activeEntity.attributes.Name,
                        component: ProgramDetails
                    },
                    {
                        path: '/programs/:type(bulk)',
                        title: "Bulk Edit",
                        component: ProgramsList,
                    }
                ]
            },
            {
                path: '/projects/:type?/:subViewId?',
                url: '/projects',
                title: 'Projects',
                component: ProjectsList,
                isAvailable: RoutesAvailability.Projects,
                childs: [
                    {
                        path: '/project/:id?',
                        url: (s, id) => `/project/${id}`,
                        title: (s, id) => s.projectsList.activeEntity && s.projectsList.activeEntity.id === id && s.projectsList.activeEntity.attributes.Name,
                        component: ProjectDetails,
                        childs: [
                            {
                                path: '/project/:id/tasks',
                                title: 'Tasks',
                                component: ProjectTasksList
                            },
                            {
                                path: '/project/task/:uniqueId',
                                title: 'Tasks',
                                component: NavigateToTask
                            },
                        ]
                    },
                    {
                        path: '/projects/:type(bulk)',
                        title: "Bulk Edit",
                        component: ProjectsList,
                    }
                ]
            },
            {
                path: '/archivedProjects/:type?/:subViewId?',
                url: '/archivedProjects',
                title: 'Archived Projects',
                component: ArchivedProjectsList,
                isAvailable: RoutesAvailability.ArchivedProjects,
                childs: [
                    {
                        path: '/archivedproject/:id?',
                        url: (s, id) => `/archivedproject/${id}`,
                        title: (s, id) => s.archivedProjectsList.activeEntity && s.archivedProjectsList.activeEntity.id === id
                            && s.archivedProjectsList.activeEntity.attributes.Name,
                        component: ArchivedProjectDetails,
                        childs: [
                            {
                                path: '/archivedproject/:id/tasks',
                                title: 'Tasks',
                                component: ArchivedProjectTasksList
                            }
                        ]
                    }
                ]
            },
            {
                path: '/roadmaps/:type?/:subViewId?',
                url: '/roadmaps',
                title: "Roadmap",
                component: RoadmapsList,
                isAvailable: RoutesAvailability.Roadmaps,
                childs: [
                    {
                        path: '/roadmap/:id',
                        url: (s, id) => `/roadmap/${id}`,
                        title: (s, id) => s.roadmaps.byId[id] && s.roadmaps.byId[id].attributes.Name,
                        component: RoadmapDetails
                    }
                ]
            },
            {
                path: '/resources/:type?/:subViewId?',
                url: '/resources',
                title: 'Resources',
                component: ResourcesList,
                childs: [
                    {
                        path: '/resource/:id?',
                        url: (s, id) => `/resource/${id}`,
                        title: (s, id) => s.resources.byId[id] && s.resources.byId[id].attributes.Name,
                        component: ResourceDetails,
                        childs: [
                            {
                                path: '/resource/:id/workload',
                                title: 'Workload',
                                component: ResourceWorkload
                            },
                            {
                                path: '/resource/:id/time',
                                title: 'Time',
                                component: ResourceTime,
                                isAvailable: RoutesAvailability.ReportedTime
                            }
                        ]
                    },
                    {
                        path: '/resources/:type(bulk)',
                        title: "Bulk Edit",
                        component: ResourcesList,
                    },
                    {
                        path: '/resources/:type(plan)',
                        title: "Utilization",
                        component: ResourcesList,
                        isAvailable: RoutesAvailability.ResourcePlan,
                    },
                    {
                        path: '/resources/:type(reported-time)',
                        title: "Reported Time",
                        component: ResourcesList,
                        isAvailable: RoutesAvailability.ReportedTime,
                    }
                ]
            },
            {
                path: '/priorities',
                title: 'Strategic Priorities',
                isAvailable: RoutesAvailability.Priorities,
                component: StrategicPrioritiesList,
            },
            {
                path: '/challenges/:type?/:subViewId?',
                url: '/challenges',
                title: "Challenges & Ideas",
                component: ChallengesList,
                isAvailable: RoutesAvailability.Challenges,
                childs: [
                    {
                        path: '/challenge/:id?',
                        title: (s, id) => s.challenges.activeEntity && s.challenges.activeEntity.id === id && s.challenges.activeEntity.attributes.Name,
                        component: ChallengeDetails
                    },
                    {
                        path: '/parentchallenge',
                        url: (s, id) => s.ideas.activeEntity && s.ideas.activeEntity.id == id
                            ? `/challenge/${s.ideas.activeEntity.attributes.Challenge.id}`
                            : '/challenge',
                        title: (s, id) => s.ideas.activeEntity && s.ideas.activeEntity.id == id
                            ? s.ideas.activeEntity.attributes.Challenge.name
                            : undefined,
                        component: ChallengesList,
                        childs: [
                            {
                                path: '/idea/:id?',
                                title: (s, id) => s.ideas.activeEntity && s.ideas.activeEntity.id === id && s.ideas.activeEntity.attributes.Name,
                                component: IdeaDetails
                            }
                        ]
                    },
                    {
                        path: '/challenges/:type(bulk)',
                        title: "Bulk Edit",
                        component: ChallengesList
                    }
                ]
            },
            {
                path: '/users',
                title: 'People Management',
                isAvailable: RoutesAvailability.Users,
                component: UsersList
            },
            {
                path: '/billing',
                title: 'Billing Settings',
                isAvailable: RoutesAvailability.Billing,
                component: (props: any) => <SettingsPage className="billing-settings" title="Billing Settings">
                    <BillingManage />
                </SettingsPage>
            },
            {
                path: '/tenantSettings',
                title: "Tenant Settings",
                isAvailable: RoutesAvailability.TenantSettings,
                component: TenantSettings
            },
            {
                path: '/reportSettings',
                title: 'Report Settings',
                component: ReportSettings
            },
            {
                path: '/timeSettings',
                title: 'Time Settings',
                component: TimeSettings
            },
            {
                path: '/statusSettings',
                title: 'PPM Insights Settings',
                isAvailable: RoutesAvailability.StatusSettings,
                component: InsightsSettings
            },
            {
                path: "/timeTrackingSettings",
                title: "Time Tracking Settings",
                isAvailable: RoutesAvailability.TimeTrackingtSettings,
                component: TimeTrackingSettings
            },
            {
                path: '/automation',
                title: 'Automation',
                component: Automation,
                isAvailable: RoutesAvailability.Automation
            },
            {
                path: '/teamsApp/config',
                title: "PPMX Portfolio/Project selection",
                allowAnonymous: false,
                disableNavigation: true,
                hideNavigation: true,
                component: TeamsApp.Config
            },
            {
                path: '/quickstart',
                title: 'Quick Start',
                component: QuickStart
            },
            {
                path: '/insights',
                title: 'PPM Insights',
                component: PPMInsights,
                isAvailable: RoutesAvailability.Insights
            },
            {
                path: '/myspace',
                title: 'My Space',
                component: MyWorkload,
                isAvailable: RoutesAvailability.MySpace
            },
            {
                path: '/mytime',
                title: 'My Time',
                component: MyTime,
                isAvailable: RoutesAvailability.MyTime
            },
            {
                path: '/calendar',
                title: "Calendar",
                component: (props: any) => <CalendarSettings />
            },
            {
                path: '/objectives/:type?/:subViewId?',
                title: 'Objectives',
                url: '/objectives',
                component: ObjectivesList,
                isAvailable: RoutesAvailability.Objectives,
                childs: [
                    {
                        path: '/parentobjective',
                        url: (s, id) => s.objectives.activeEntity && s.objectives.activeEntity.id === id && s.objectives.activeEntity.attributes.Parent
                            ? `/objective/${s.objectives.activeEntity.attributes.Parent.id}`
                            : 'objective',
                        title: (s, id) => s.objectives.activeEntity && s.objectives.activeEntity.id === id && s.objectives.activeEntity.attributes.Parent
                            ? s.objectives.activeEntity.attributes.Parent.name
                            : undefined,
                        component: ObjectivesList,
                        childs: [
                            {
                                path: '/objective/:id?',
                                title: (s, id) => s.objectives.activeEntity && s.objectives.activeEntity.id === id && s.objectives.activeEntity.attributes.Name,
                                component: ObjectiveDetails
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        path: '/setup/:what?',
        title: 'Setup',
        allowAnonymous: true,
        hideNavigation: true,
        component: SetupScreen
    },
    {
        path: '/forbidden',
        title: 'Access Denied',
        allowAnonymous: true,
        hideNavigation: true,
        component: InactiveUser
    },
    {
        path: '/error/403',
        title: 'Access Denied',
        allowAnonymous: true,
        hideNavigation: true,
        component: (props: any) => <ErrorPage title="Access Denied" description={NotEnoughPermissionsMessage} />
    },
    {
        path: '/error/404',
        title: 'Not Found',
        allowAnonymous: true,
        hideNavigation: true,
        component: (props: any) => <ErrorPage title="Not Found" description="PPM Express couldn't find the item requested. It may have been deleted or moved." />
    },
    {
        path: '/error/:code',
        title: 'Error',
        allowAnonymous: true,
        hideNavigation: true,
        component: (props: any) => <ErrorPage title={props.match.params.code} description="Oops! Something went wrong." />
    },
    {
        path: '/notenant',
        title: 'Not Found',
        allowAnonymous: true,
        hideNavigation: true,
        component: (props: any) => <ErrorPage title="Not Found" description="PPM Express couldn't find the requested tenant. It may have been deleted or moved." />
    },
    {
        path: '/nolicense',
        title: 'No valid license',
        allowAnonymous: true,
        hideNavigation: true,
        component: NoLicense
    },
    {
        path: '/subscription/expired',
        title: 'Subscription expired',
        allowAnonymous: true,
        hideNavigation: true,
        component: SubscriptionExpired
    },
    {
        path: '/postLogin',
        title: 'Completing auth...',
        allowAnonymous: true,
        hideNavigation: true,
        hideHeader: true,
        component: TeamsApp.PostLogin
    },
    {
        path: '/invite/:id',
        title: 'Invite user...',
        allowAnonymous: true,
        hideNavigation: true,
        component: Invite
    }
];