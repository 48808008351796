import * as React from 'react';
import { Dictionary } from '../../entities/common';
import RemoveDialog from './RemoveDialog';
import { IDialogContentProps, MessageBar, MessageBarType, Spinner } from 'office-ui-fabric-react';

interface OwnProps {
    entitiesTitle: string;
    onClose: () => void;
    onComplete: () => void;
    onValidate?: () => Promise<JSX.Element | undefined>;
    dialogContentProps?: IDialogContentProps;
    itemsToRemove: { id: string, isEditable: boolean, attributes: Dictionary<any> }[];
}

const ValidateRemoveDialog = (props: OwnProps) => {
    const { itemsToRemove } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [warning, setWarning] = React.useState<JSX.Element>();
    React.useEffect(() => {
        setWarning(undefined);
        if (props.onValidate) {
            setIsLoading(true);
            setWarning(undefined);
            props.onValidate().then((warning) => {
                setWarning(warning);
                setIsLoading(false);
            });
        }
    }, [props.itemsToRemove]);

    return <RemoveDialog
        onClose={props.onClose}
        onComplete={props.onComplete}
        dialogContentProps={props.dialogContentProps}
        confirmButtonProps={{ text: "Delete", disabled: isLoading || itemsToRemove.filter(_ => _.isEditable).length === 0 }} >
        {itemsToRemove.some(_ => !_.isEditable) && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
            You don't have permissions to delete the following {props.entitiesTitle}:
            <ul>
                {itemsToRemove.filter(_ => !_.isEditable).map(_ => <li key={_.id}>{_.attributes.Name}</li>)}
            </ul>
        </MessageBar>}
        {isLoading ? <Spinner /> : <>
                {warning && <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                    {warning}
                </MessageBar>}</>}
    </RemoveDialog>
}

export default ValidateRemoveDialog;