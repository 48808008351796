
import './FieldTypeDropdown.css';
import * as React from 'react';
import { Dropdown, IDropdownOption, ISelectableOption, Icon, IDropdownProps } from 'office-ui-fabric-react';

export const FieldTypeDrowdown = (props: IDropdownProps) => <Dropdown
    {...props}
    className='field-type-dropdown'
    calloutProps={{ className: 'field-type-dropdown-options' }}
    onRenderTitle={DrodownTitle}
    onRenderOption={DrodownOption}
/>

const DrodownTitle = (props: IDropdownOption[]) => {
    const selected = props[0];
    return <div className='field-type-dropdown-title' title={selected.text} >
        <Icon iconName={selected.data?.iconName} />
        <div>
            {selected.text}
        </div>
    </div>;
}

const DrodownOption = (props: ISelectableOption) => {
    return <div className='field-type-dropdown-option' title={props.text}>
        <div className='field-type-dropdown-option-header'>
            <Icon iconName={props.data?.iconName} />
            <div>
                {props.text}
            </div>
        </div>
        <div className='field-type-dropdown-option-description'>
            {props.data?.description}
        </div>
    </div>;
}