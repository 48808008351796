import React from "react";
import { AiLogo } from "../logo"

type Props = {
    headerText: string | JSX.Element;
    secondaryText: string | JSX.Element;
}

const AiPanelHeader = (props: Props): JSX.Element => {
    return <div className="ms-Panel-header">
        <p className="ms-Panel-headerText">{props.headerText}</p>
        <div className='panel-secondary-text-container'>
            <div className='ms-Panel-secondaryText'>{props.secondaryText}</div>
            <AiLogo />
        </div>
    </div>
}

export default AiPanelHeader;