import * as React from 'react';
import { connect } from 'react-redux';
import ConnectionSelect, { ConnectionVerifier } from "../ConnectionSelect";
import { actionCreators } from "../../../store/integration/P4WStore";
import { IConnectionsState } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import P4WConnectionEdit from "../P4W/P4WConnectionEdit/P4WConnectionEdit";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import PostMessageReceiver from '../PostMessageReceiver';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onChange: (connectionId?: string) => void;
};

type StateProps = { connections: IConnectionsState; };
type Props = StateProps & typeof actionCreators & OwnProps;

interface IState {
    refreshId: string | undefined;
    isAdd: boolean;
}

class P4WConnectionSelect extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = { refreshId: undefined, isAdd: false };
    }

    public render() {
        const { connectionId, connections, disabled } = this.props;
        const { isAdd, refreshId } = this.state;
        
        return <>
            <ConnectionVerifier
                connections={connections}
                connectionId={connectionId}
                verifyConnection={this.props.verifyConnection}>
                <ConnectionSelect
                    key="connection-select"
                    disabled={connections.isLoading || !!disabled}
                    sourceType={SourceType.P4W}
                    connectionId={connectionId}
                    connections={connections.data}
                    onConnectionChange={_ => {
                        if(_?.id){
                            this.props.verifyConnection(_.id);
                        }
                        this.props.onChange(_?.id)
                    }}
                    onConnectionRemove={_ => this.props.removeConnection(_, this.props.onChange)}
                    onConnectionRename={(_, __) => this.props.renameConnection(_, __)}
                    onRefreshClick={(_) => this.setState({ refreshId: _ })}
                    onNewConnectionClick={() => this.setState({ isAdd: true })}
                    grantPermissionsText="Grant Permissions (requires Admin Consent)"
                    onGrantPermissionsClick={ this._onGrantPermissionsClick }
                />
            </ConnectionVerifier>
            { connections.isLoading && <Spinner key="spinner" size={SpinnerSize.small} className="connection-select-spinner" /> }
            { (isAdd || refreshId) && <P4WConnectionEdit
                connectionId={refreshId}
                onCreated={isAdd ? this.props.onChange : undefined}
                onDismiss={() => { this.setState({ isAdd: false, refreshId: undefined }) }} />}
        </>;
    }

    componentWillMount() {
        this.props.loadConnections();
    }

    private _onGrantPermissionsClick() {
        const target = 'grantPermissionsDataReceived_P4W';
        const grantPermissionsUrl = '/api/integration/p4w/auth/adminConsent';
        PostMessageReceiver.openPopupWindow(grantPermissionsUrl, target)
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.p4w.connections };
}

export default connect(mapStateToProps, actionCreators)(P4WConnectionSelect);

