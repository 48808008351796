import * as React from 'react'
import { Icon, TooltipHost, Toggle, TooltipDelay } from 'office-ui-fabric-react';

type EmailToggleProps = {
    label: string;
    checked: boolean | undefined;
    tooltipContent: JSX.Element;
    onChange: (event: React.MouseEvent<HTMLElement>, checked?: boolean) => void;
    disabled?: boolean;
}

const EmailToggle = (props: EmailToggleProps) => {
    return <Toggle disabled={props.disabled}
        checked={props.checked}
        onText="Yes"
        offText="No"
        onChange={props.onChange}
        styles={{ label: { flex: 1, fontWeight: "normal" }, root: { marginBottom: "unset" } }}
        inlineLabel={true}
        label={
            <div>
                {props.label}
                <TooltipHost styles={{ root: { paddingLeft: 5 } }}
                    delay={TooltipDelay.zero}
                    closeDelay={TooltipDelay.long}
                    tooltipProps={{ onRenderContent: () => props.tooltipContent }}>
                    <Icon iconName="Info" className='info' />
                </TooltipHost>
            </div>
        }
    />
}

export default EmailToggle;