import "./RoadmapItemCreateFromPanel.css";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link, MessageBar, MessageBarType, PanelType, PrimaryButton } from 'office-ui-fabric-react';
import { EntityType, Dictionary, ServerEntityType } from '../../../entities/common';
import { ApplicationState } from '../../../store';
import { EntityLinkDescriptor } from '../../../store/RoadmapsListStore';
import * as Metadata from '../../../entities/Metadata';
import { IRoadmapItem, ISubentity, KeyDateType, RoadmapItemType, RoadmapItemTypeMap } from '../../../entities/Subentities';
import { IRoadmapAttrs } from '../../../store/roadmap/common';
import { UserState } from '../../../store/User';
import { PPMFeatures, Subscription } from "../../../store/Tenant";
import { notUndefined } from "../../utils/common";
import { actionCreators as notificationsActionCreators, NotificationType } from "../../../store/NotificationsStore";
import TaskParamsControl from "./TaskParamsControl";
import { IdeaParamsControl } from "./IdeaParamsControl";
import KeyDateParamsControl from "./KeyDateParamsControl";
import { ButtonOptions, EntityTypeButton, ParamsControlProps } from "./EntityTypeButton";
import { canCreate } from "../../../store/permissions";
import * as analytics from '../../../analytics';
import ExpandablePanel from "../../common/ExpandablePanel";

export interface RoadmapSubentityWrapper extends ISubentity {
    id: string;
    attributes: IRoadmapAttrs & Dictionary<any>;
}

interface OwnProps {
    entity: IRoadmapItem;
    exceptIds?: string[];
    onDismiss: () => void;
    onComplete: (descriptor: EntityLinkDescriptor, callback: (roadmapItem: IRoadmapItem) => void) => void;
}

type StoreProps = {
    fields: Metadata.Field[];
    user: UserState;
    subscription: Subscription;
}

type ActionsProps = {
    notificationsActions: typeof notificationsActionCreators
}

type Props = StoreProps & OwnProps & ActionsProps;

type SelectedType = Partial<EntityLinkDescriptor> & { keyDateType?: KeyDateType } | undefined;

const _getButtonOptions = (props: Props): ButtonOptions[] => {
    const { entity, subscription } = props;
    const permissions = props.user.permissions;

    if (entity.attributes.Type === RoadmapItemType.Bar) {
        return [
            { entityType: ServerEntityType.Program, canCreate: () => canCreate(permissions.program) },
            { entityType: ServerEntityType.Project, canCreate: () => canCreate(permissions.project) },
            {
                entityType: ServerEntityType.Task, cssClass: "task-logo",
                renderParamsControl: (paramsProps: ParamsControlProps) => <TaskParamsControl {...paramsProps} />
            },
            Subscription.contains(subscription, PPMFeatures.OKR) ? {
                entityType: ServerEntityType.Objective, cssClass: "objective-logo",
                canCreate: () => canCreate(permissions.objective)
            } : undefined,
            { entityType: ServerEntityType.Idea, renderParamsControl: (paramsProps: ParamsControlProps) => <IdeaParamsControl {...paramsProps} /> }
        ].filter(notUndefined);
    }

    if (entity.attributes.Type === RoadmapItemType.KeyDate) {
        return [
            {
                entityType: ServerEntityType.KeyDate, keyDateType: KeyDateType.KeyDate, cssClass: "keydate-logo",
                renderParamsControl: (paramsProps: ParamsControlProps) => <KeyDateParamsControl {...paramsProps} />
            },
            {
                entityType: ServerEntityType.KeyDate, keyDateType: KeyDateType.Milestone, label: "Milestone", cssClass: "milestone-logo",
                renderParamsControl: (paramsProps: ParamsControlProps) => <KeyDateParamsControl {...paramsProps} />
            },
            {
                entityType: ServerEntityType.KeyDate, keyDateType: KeyDateType.Release, label: "Release", cssClass: "release-logo",
                renderParamsControl: (paramsProps: ParamsControlProps) => <KeyDateParamsControl {...paramsProps} />
            }
        ];
    }

    return [];
}

const RoadmapItemCreateFromPanel = (props: Props) => {
    const [selected, setSelected] = useState<SelectedType>();
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const { entity, onDismiss, exceptIds } = props;
    const lowerEntityTypeName = RoadmapItemTypeMap[entity.attributes.Type].toLowerCase();
    const options = _getButtonOptions(props);
    const selectedOptions = options.find(_ => _.entityType === selected?.entityType && _.keyDateType === selected.keyDateType);

    return <ExpandablePanel
        isOpen={true}
        isLightDismiss={true}
        type={PanelType.custom}
        customWidth="400px"
        focusTrapZoneProps={{ disableFirstFocus: true }}
        onRenderHeader={() => <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Create from {RoadmapItemTypeMap[entity.attributes.Type]}</p>
            <div className='ms-Panel-secondaryText'>
                Select a PPM Express entity to create from this {lowerEntityTypeName}.
            </div>
        </div>}
        onRenderFooterContent={() => <div className="commands">
            <PrimaryButton
                text="Create"
                disabled={isCreating || !selectedOptions || selectedOptions.renderParamsControl && !selected?.parentEntityId}
                onClick={() => {
                    if (!selected) { return; }

                    setIsCreating(true);
                    const descriptor: EntityLinkDescriptor = {
                        ...selected,
                        roadmapItemId: props.entity.id,
                        entityType: selected?.entityType!,
                        attributes: selected.entityType === ServerEntityType.KeyDate ? { ...selected.attributes, Type: selected.keyDateType } : selected.attributes
                    };

                    props.onComplete(descriptor, (roadmapItem: IRoadmapItem) => {
                        props.notificationsActions.pushNotification({ type: NotificationType.Success, message: <RoadmapItemCreatedMessage entity={roadmapItem} /> });
                        setIsCreating(false);
                        props.onDismiss();

                        analytics.trackEvent("Create from RoadmapItem", props.user, { 
                            roadmapItemType: RoadmapItemTypeMap[props.entity.attributes.Type], 
                            itemTitle: roadmapItem.attributes.Name
                         });
                    });
                }} />
        </div>}
        onDismiss={onDismiss} >

        <MessageBar messageBarType={MessageBarType.info}>
            The entity will be created with the critical info and will be linked with the {lowerEntityTypeName}.
            The changes made to the entity will be then applied to this {lowerEntityTypeName}.<br />
            Please refer to the <a href="https://help.ppm.express/roadmap-management/1344231" target="_blank">article</a> for more details.
        </MessageBar>
        <div className="button-selector">
            {options?.map((_, index) => {
                const isSelected = selected?.entityType === _.entityType && selected?.keyDateType === _.keyDateType;
                return <EntityTypeButton key={`btn${index}`}
                    options={_}
                    selected={isSelected}
                    onClick={() => !isSelected && setSelected({ entityType: _.entityType, keyDateType: _.keyDateType })}
                />
            })}
        </div>

        {selectedOptions?.renderParamsControl?.({
            exceptIds,
            onComplete: (descriptor?: Partial<EntityLinkDescriptor>) => setSelected({ ...selected, ...descriptor })
        })}
    </ExpandablePanel>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Roadmap];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]).filter(_ => !_.isReadonly),
        user: state.user,
        subscription: state.tenant.subscription
    };
}

function mergeActionCreators(dispatch: any): ActionsProps {
    return {
        notificationsActions: bindActionCreators(notificationsActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(RoadmapItemCreateFromPanel);

const RoadmapItemCreatedMessage = (props: { entity: IRoadmapItem }): JSX.Element => {
    const { entity } = props;
    const entityName = entity.externalData?.["ImportedFromName"];
    const entityType = entity.externalData?.["ImportedFromTypeLabel"] || ServerEntityType[entity.externalData?.["ImportedFromType"]];
    const entityLink = entity.externalData?.["NameLink"];
    const parentEntityName = entity.externalData?.["ImportedFromParentName"];
    const parentEntityType = entity.externalData?.["ImportedFromParentType"];
    const parentEntityLink = entity.externalData?.["ImportedFromParentNameLink"];

    return <span>
        <span>{entityType}</span>
        <Link href={entityLink} target="_blank" onClick={_ => _.stopPropagation()}>{entityName}</Link>
        {parentEntityName && parentEntityLink
            && <>
                <span> in the {ServerEntityType[parentEntityType]}</span>
                <Link href={parentEntityLink} target="_blank" onClick={_ => _.stopPropagation()}>{parentEntityName}</Link>
            </>}
        <span> is created</span>
    </span>
}