import * as React from 'react';
import { DefaultButton, PrimaryButton, Dialog, DialogFooter, TextField, Toggle, Link } from 'office-ui-fabric-react';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

type OwnProps = {
    onSave: (comments: string, followUp: boolean) => void;
    onDismiss: () => void;
    showfollowUp: boolean;
    allowEmptyComment?: boolean;
    subject: string;
}

type StateProps = {
    intercomAppId: string | null;
}

type Props = OwnProps & StateProps & RouteComponentProps<{}>;
const FeedbackDialog = (props: Props) => {

    const [comments, setComments] = React.useState("");
    const [followUp, setFollowUp] = React.useState(true);

    const _onChangedDescription = (e: any, value: string) => {
        setComments(value);
    }

    const _getComments = (): string => {
        let comm = comments;
        if (props.subject) {
            comm = `PPM Express Feedback (${props.subject})\n${comm!}`;
        }
        if (!props.showfollowUp) {
            return comm!;
        }
        return `${comm}\nFollow up: ${followUp ? "Yes" : "No"}`;
    }

    return (
        <Dialog title="Submit Feedback"
            minWidth={400}
            modalProps={{
                className: "feedback-dialog"
            }}
            hidden={false}
            dialogContentProps={{ showCloseButton: true }}
            onDismiss={props.onDismiss}>
            <div>
                Have feedback for the PPM Express Team?
                We'd love to hear about your experience. If you have any questions,
                please visit our&nbsp;<Link href="https://help.ppm.express/89154-ppm-express" target="_blank">Help Center</Link>
                &nbsp;or contact support via&nbsp;
                {props.intercomAppId && <span><Link className='live_chat' href={`mailto:${props.intercomAppId}@incoming.intercom.io`}>Live Chat</Link>&nbsp;or at</span>}
                &nbsp;<Link href="mailto:support@ppm.express">support@ppm.express</Link>.
            </div>
            <TextField label="Message"
                multiline={true}
                rows={6}
                onChange={_onChangedDescription} />
            {props.showfollowUp && <Toggle label='If necessary, can we follow up with you for more details?'
                checked={followUp}
                onChange={(e, checked) => setFollowUp(!!checked)}
                onText='Yes'
                offText='No' />
            }
            <DialogFooter>
                <PrimaryButton text='Submit' onClick={() => { props.onSave(_getComments(), followUp); }} disabled={!comments && !props.allowEmptyComment} />
                <DefaultButton text='Cancel' onClick={props.onDismiss} />
            </DialogFooter>
        </Dialog>
    );
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        intercomAppId: state.analytics.intercomAppId,
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps)(FeedbackDialog));
