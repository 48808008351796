import { ContextualMenuItemType, IContextualMenuItem } from 'office-ui-fabric-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { TenantState } from '../../store/Tenant';
import ReportsPlaceholder from './ReportsPlaceholder';
import { ReportNav } from '../utils/reporting';
import RowMenuWithExtensions, { RowMenuWithExtensionsProps } from '../common/extensibleEntity/RowMenuWithExtensions';

type OwnProps = RowMenuWithExtensionsProps & {
    reports: ReportNav[];
    onClick: (report: ReportNav) => void;
}

type StateProps = { tenant: TenantState }
type Props = OwnProps & StateProps;
class MenuWithReport extends React.Component<Props, { reportItem: IContextualMenuItem }> {
    constructor(props: Props) {
        super(props);
        const reportItem: IContextualMenuItem = {
            key: 'reports',
            name: 'Reports',
            iconProps: { iconName: "FabricReportLibrary" },
            ...(props.reports.length > 0
                ? {
                    title: props.tenant.reporting.error,
                    disabled: !!props.tenant.reporting.error,
                    subMenuProps: {
                        items: props.reports.map(_ => (
                            {
                                key: _.id,
                                name: _.title,
                                iconProps: { iconName: "FileSymlink" },
                                onClick: () => this.props.onClick(_)
                            }))
                    }
                }
                : {
                    subMenuProps: {
                        items: [{ key: "" }],
                        onRenderMenuList: () => <ReportsPlaceholder />
                    }
                })
        };

        this.state = { reportItem }
    }
    
    public render() {
        const commands = [this.state.reportItem, { key: 'divider_1', itemType: ContextualMenuItemType.Divider }];
        return <RowMenuWithExtensions
            {...this.props}
            commandsBefore={commands}
            renderOnlyMenu
        />
    }
}

function mapStateToProps(state: ApplicationState) {
    return { tenant: state.tenant }
}

export default connect(mapStateToProps, {})(MenuWithReport);