import { StoreHelper } from "../services/storeHelper";
import { ICardSubView, SortDirection } from "../../entities/Metadata";
import { IViewsState, ICardSortOption, DEFAULT_LIST_SUBVIEW } from "../views";
import { UserState } from "../User";
import { CommonOperations, contains } from "../permissions";
import { notUndefined } from "../../components/utils/common";

const cardActiveSubView: ICardSubView = {
    id: '602347c4-48b5-41d8-8bcb-d1acfc32c59b',
    name: 'Summary View',
    isBuiltIn: false,
    isNotSaved: false,
    showCosts: false,
    showWork: false
};

// 'Newest on Top' sort option index
const DEFAULT_CARD_SORT_OPTION_INDEX = 2;
const cardSortOptions: ICardSortOption[] = [
    {
        id: "176b6c73-bcbb-49dc-8afa-07f2148beab8",
        orderBy: {
            fieldName: 'Name',
            direction: SortDirection.ASC,
        },
        label: 'Name: A – Z',
        iconName: 'PPMXSortAZ'
    },
    {
        id: "dceddf9e-a443-4a18-a5bf-f56e8c9afec3",
        orderBy: {
            fieldName: 'Name',
            direction: SortDirection.DESC
        },
        label: 'Name: Z – A',
        iconName: 'PPMXSortZA'
    },
    {
        id: "8683aa56-e6cd-43bb-b89c-b2bce1363c7f",
        orderBy: {
            fieldName: 'CreatedDate',
            direction: SortDirection.DESC,
        },
        label: 'Newest on Top',
        iconName: 'PPMXSortNewest'
    },
    {
        id: "e160d736-e7fb-400c-9c41-95c0ab12d84e",
        orderBy: {
            fieldName: 'CreatedDate',
            direction: SortDirection.ASC,
        },
        label: 'Oldest on Top',
        iconName: 'PPMXSortOldest'
    }
];

const TIMELINE_DEFAULT_SORT = {
    fieldName: "Name",
    direction: SortDirection.ASC
};

export function getViewsDefaults(user: UserState): IViewsState {
    const canManageBudget = contains(user.permissions.common, CommonOperations.BudgetManage);

    return {
        card: {
            subViews: [
                cardActiveSubView,
                {
                    id: '83c7bee7-54ec-4a9e-92ea-2ce09b7b729a',
                    name: 'Work Details',
                    isBuiltIn: false,
                    isNotSaved: false,
                    showCosts: false,
                    showWork: true
                },
                canManageBudget 
                    ? {
                        id: '555d9f33-0f2a-4768-bc9f-b7f8ec3a9b78',
                        name: 'Cost Details',
                        isBuiltIn: false,
                        isNotSaved: false,
                        showCosts: true,
                        showWork: false
                    }
                    : undefined
            ].filter(notUndefined),
            sortBy: {
                active: cardSortOptions[DEFAULT_CARD_SORT_OPTION_INDEX],
                options: cardSortOptions
            }
        },
        list: {
            fakeFields: [],
            selectedByDefault: [],
            subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
            sortBy: {
                fieldName: "Name",
                direction: SortDirection.ASC
            }
        },
        timeline: {
            subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
            selectedByDefault: [],
            sortBy: TIMELINE_DEFAULT_SORT
        },
        activeViewType: 'timeline'
    };
}