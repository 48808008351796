import React from "react";
import { IContextualMenuItem, Icon, IconButton } from "office-ui-fabric-react";

export type ColorStageViewProps = {
    title?: string;
    className?: string;
    rootClassName?: string;
    iconName?: string;
    subMenuItems?: IContextualMenuItem[];
    style?: React.CSSProperties;
}

const ColorStageView = React.memo((props: ColorStageViewProps) => <div className={`color-stage ${props.rootClassName || ''}`}>
    <div className={`status ${props.className ? props.className : ''}`} title={`Project Stage (${props.title})`}>
        <div className="align-center details" style={props.style}>
            {props.iconName ? <Icon iconName={props.iconName} className="status-icon" /> : undefined}
            <span className="status-label">{props.title}</span>
        </div>
        {props.subMenuItems && props.subMenuItems.length > 0 && <IconButton
            menuIconProps={{ iconName: 'ChevronDown' }}
            className='menu'
            menuProps={{ items: props.subMenuItems, className: 'section-menu' }} />}
    </div>
</div>);

export default ColorStageView;