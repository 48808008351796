import * as React from 'react';
import { Toggle, IconButton, TooltipDelay, TooltipHost, DirectionalHint } from 'office-ui-fabric-react';
import { ISectionInfo, IUpdateSectionInfo, getLabel } from "../../../entities/Metadata";
import { DraggableList } from "../DraggableList";
import { orderNotSelected, toDictionaryById } from '../../utils/common';
import SectionPanel from './SectionPanel';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props {
    allSections?: ISectionInfo[];
    sections: ISectionInfo[];
    updateSections: (updates: { id: string, update: IUpdateSectionInfo }[]) => void;
    onDismiss: () => void;
    allowEditSectionDetails?: boolean;
}

const sectionsWithState = ["PPM Insights AI"];

const SectionsSelector = (props: Props & RouteComponentProps<{}>) => {
    const [orderedSections, setOrderedSections] = React.useState(_orderSections(props.sections, props.allSections));
    const [editSection, setEditSection] = React.useState<ISectionInfo>();

    function _orderSections(sections: ISectionInfo[], allSections: ISectionInfo[] | undefined): ISectionInfo[] {
        sections = sections.map(_ => ({ ..._ }));
        const map = toDictionaryById(sections);
        if (allSections) {
            for (const section of allSections) {
                if (!map[section.id]) {
                    map[section.id] = { ...section, isSelected: false };
                    sections.push(map[section.id]);
                }
            }
        }
        const selected = sections.filter(_ => _.isSelected).map(_ => _.id);
        return orderNotSelected(
            sections,
            f => selected.indexOf(f.id), a => a.name);
    }

    const _onItemRender = React.useCallback((section: ISectionInfo, index: number) => {
        const inaccessibleSection = !section.isAccessible && !sectionsWithState.includes(section.name);
        return <div className="cb-wrap">
            <div className="title bold" title={getLabel(section)}>{getLabel(section)}</div>
            {inaccessibleSection && 
                <TooltipHost
                    content="Upgrade your plan to unlock full access to this functionality."
                    delay={TooltipDelay.zero}
                    hostClassName="tooltip"
                    calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}>
                    <div className="green-label clickable"
                        onClick={() => props.history.push('/billing')}>
                        Upgrade plan
                    </div>
                </TooltipHost>
            }
            <div className="align-center" style={{ minWidth: inaccessibleSection ? '32px' : 'auto' }}>
                <IconButton
                    menuIconProps={{ iconName: props.allowEditSectionDetails ? 'Edit' : 'View' }}
                    title={props.allowEditSectionDetails ? 'Edit' : 'View'}
                    className='menu'
                    onClick={() => setEditSection(section)} />
            </div>
            <Toggle
                checked={section.isSelected}
                disabled={!props.updateSections}
                onChange={(e: any, c?: boolean) => {
                    setOrderedSections(orderedSections.map(_ => _.id === section.id ? { ...section, isSelected: !!c } : _));
                    props.updateSections([{
                        id: section.id,
                        update: {
                            isSelected: !!c,
                            index: c ? orderedSections.slice(0, index).filter(_ => _.isSelected).length : undefined
                        }
                    }]);
                }}
            />
        </div>;
    }, [props.updateSections, props.allowEditSectionDetails, setEditSection, orderedSections]);

    const _onOrderChanged = React.useCallback((sections: ISectionInfo[], section: ISectionInfo) => {
        setOrderedSections(sections);

        if (section.isSelected) {
            const updates: { id: string, update: IUpdateSectionInfo }[] = sections.filter(_ => _.isSelected).map((_, index) => ({ id: _.id, update: { index } }));
            props.updateSections(updates);
        }
    }, [orderedSections, props.updateSections, setOrderedSections]);

    return <>
        <DraggableList
            items={orderedSections}
            onItemRender={_onItemRender}
            onChanged={_onOrderChanged}
        />
        {editSection && <SectionPanel
            section={editSection}
            updateSection={props.allowEditSectionDetails
                ? (id, update) => {
                    props.updateSections([{ id, update }]);
                    setOrderedSections(orderedSections.map(_ => _.id === id
                        ? {
                            ..._,
                            label: update.label !== undefined ? update.label : _.label,
                            description: update.description !== undefined ? update.description : _.description,
                            isOpen: update.isOpen !== undefined ? update.isOpen : _.isOpen,
                        }
                        : _));
                }
                : undefined}
            onDismiss={() => setEditSection(undefined)} />}
    </>;
}

export default withRouter<Props>(SectionsSelector);