import { Icon } from "office-ui-fabric-react";
import React from "react";
import { DigestDataItem } from "../common";
import './AIDigestDataItem.css';

type Props = {
    item: DigestDataItem;
    onItemChecked: (item: DigestDataItem) => void;
}

const AIDigestDataItem = (props: Props) => {
    const { item, onItemChecked } = props;
    const [isIconHovered, setIsIconHovered] = React.useState(false);

    return <div className={`ai-digest-item ${item.isSelected ? 'selected-item' : ''}`}>
        <div className='item-header'>
            <div className='item-name'>{item.name}</div>
            <label className='item-selector'>
                <Icon className='item-checkbox' iconName={isIconHovered || item.isSelected ? 'CompletedSolid' : 'CircleRing'}                    
                    onMouseEnter={() => setIsIconHovered(true)}
                    onMouseLeave={() => setIsIconHovered(false)} />
                <input type="checkbox" checked={item.isSelected} onChange={() => onItemChecked(item)} />
            </label>
        </div>
        <div className='item-description'>{item.description}</div>
    </div>
}

export default AIDigestDataItem;