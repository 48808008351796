import { ComboBox, IComboBoxOption, ISelectableOption, PrimaryButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from "../../../store/index";
import { ILinkDto, IListStore, validateConnection } from "../../../store/integration/common";
import { actionCreators, ISmartsheetConnectionState, ISmartsheetUser, ISmartsheetUserLinkInfo } from './../../../store/integration/SmartsheetStore';
import SmartsheetConnectionSelect from '../Smartsheet/SmartsheetConnectionSelect';
import { ConnectionField } from '../ConnectionField';
import { buildUserDisplayInfo } from './common';
import OverlayComponent from '../../common/OverlayComponent';
import { usePrevious } from '../../utils/effects';

type OwnProps = {
    actions: { 
        linkToUser: (linkData: ILinkDto<ISmartsheetUser>) => void;
        deleteUserLink: (connectionId: string) => void;
    },
    connectionId?: string;
    sourceData?: ISmartsheetUserLinkInfo;
    dismissPanel?: () => void;
}

type StateProps = { 
    users: IListStore<ISmartsheetUser>;
    connections: ISmartsheetConnectionState;
 };
type Props = StateProps & typeof actionCreators & OwnProps;

const onRenderItem = (props?: ISelectableOption, defaultRender?: (props?: ISelectableOption) => JSX.Element | null): JSX.Element | null => {
    if (!defaultRender) {
        return null;
    }

    const element = defaultRender(props);
    if (!props || !props.disabled) {
        return element;
    }
    return React.createElement("div", { title: "Already linked" }, element);
}
const linkToUser = (props: Props, connectionId?: string, user?: ISmartsheetUser) => {
    if (!connectionId || !user) {
        return;
    }

    props.actions.linkToUser({ connectionId, data: user });
    props.dismissPanel?.();
}

const SmartsheetConnectControl = (props: Props) => {
    const { sourceData, users, connections } = props;
    const [isLinked, setIsLinked] = React.useState<boolean>(!!props.sourceData);
    const [options, setOptions] = React.useState<IComboBoxOption[]>([]);
    const [selectedUser, setSelectedKey] = React.useState<ISmartsheetUser | undefined>();
    const [connectionId, setConnectionId] = React.useState<string | undefined>(props.connectionId);

    const previousConnectionsVerification = usePrevious(props.connections.connectionsVerification);
    React.useEffect(() => { 
        const selectedConnection = validateConnection(connections, connectionId);
        if (connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false
            && selectedConnection?.isValidating === false && !selectedConnection.validationError) {
                props.loadUsers(connectionId)
        } 
    }, [connectionId && connections.connectionsVerification[connectionId]]);

    const setUser = (email: string) => {
        const user = users.entities.find(_ => _.email === email);
        setSelectedKey(user);
    }

    React.useMemo(() => {
        const o = users.entities.map(_ => ({
            key: _.email,
            text: `${_.displayName}${_.email ? ` (${_.email})` : ''}`,
            disabled: _.isAlreadyLinked
        }));
        setOptions(o);
        sourceData && setUser(sourceData.userEmail);
    }, [users.entities, sourceData]);
    React.useMemo(() => { setIsLinked(!!sourceData) }, [!!sourceData]);

    const getComboboxValidationMessage = React.useCallback(() => {
        if (isLinked || !connectionId || props.users.isLoading) {
            return undefined;
        }

        return users.error 
            ? users.error
            : selectedUser
                ? selectedUser.isAlreadyLinked
                    ? "Resource already linked"
                    : undefined
                : `Please select resource from list`;
    }, []);

    const _onConnectionChange = (_?: string) => {
        if (isLinked) {
            return;
        }
        setConnectionId(_);
        setSelectedKey(undefined);
    }

    const deleteUserLink = () => {
        if (!connectionId) {
            return;
        }
    
        props.actions.deleteUserLink(connectionId);
        setSelectedKey(undefined);
    }

    const setSelectedUser = (key?: string) => {
        if(!key){
            setSelectedKey(undefined);
            return;
        }
        setUser(key);
    }

    const userDisplayInfo = selectedUser?.displayName && selectedUser?.email && buildUserDisplayInfo(selectedUser.displayName, selectedUser.email)
        || sourceData && buildUserDisplayInfo(sourceData.displayName || sourceData.userTitle, sourceData.userEmail);

    const isValid = validateConnection(props.connections, connectionId);

    return <>
        <div className="with-top-margin">
            <SmartsheetConnectionSelect
                disabled={isLinked}
                connectionId={connectionId}
                onChange={_onConnectionChange} />
        </div>
        <div className="with-top-margin">
            <OverlayComponent isOverlayed={!connectionId || isValid?.isValidating !== false || !!isValid?.validationError}>
                <ConnectionField
                    isLinked={isLinked}
                    label="Resource"
                    value={userDisplayInfo}>
                <ComboBox
                    disabled={isLinked || !connectionId || users.isLoading}
                    placeholder="Select resource"
                    selectedKey={selectedUser ? selectedUser.email : undefined}
                    useComboBoxAsMenuWidth
                    allowFreeform
                    options={options}
                    onRenderItem={onRenderItem}
                    onChange={(e, _) => setSelectedUser(_?.key as string | undefined)}
                    errorMessage={isValid?.isValidating === false && !isValid?.validationError ? getComboboxValidationMessage() : undefined} />
                </ConnectionField>
                {users.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                {!users.isLoading && users.error && <div className="error-message">{users.error}</div>}
                {
                    !isLinked && <PrimaryButton text="Link resource"
                        disabled={!selectedUser}
                        className="with-top-margin"
                        onClick={() => linkToUser(props, connectionId, selectedUser)} />
                }
                {
                    isLinked && <PrimaryButton text="Delete resource link"
                        className="with-top-margin"
                        onClick={deleteUserLink} />
                }
            </OverlayComponent>
        </div>
    </>
}

function mapStateToProps(state: ApplicationState): StateProps {
    return { 
        users: state.smartsheet.users,
        connections: state.smartsheet.connections
    };
}

export default connect(mapStateToProps, actionCreators)(SmartsheetConnectControl);