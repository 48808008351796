import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogType, TextField, PrimaryButton, DefaultButton, DialogFooter, SpinnerSize, MessageBar, MessageBarType, Spinner, Overlay, Checkbox, TooltipDelay, DirectionalHint, TooltipHost, Link, Icon } from 'office-ui-fabric-react';
import { Validator } from "../../../../validation";
import { ApplicationState } from '../../../../store';
import { actionCreators, IConnectionRefreshInfo, IP4WConnectionInfo } from '../../../../store/integration/P4WStore';
import { RouteComponentProps, withRouter } from "react-router-dom";
import PostMessageReceiver from '../../PostMessageReceiver';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import { ConnectionTitle } from '../../ConnectionTitle';
import ConnectionEditWrapper from '../../ConnectionEditWrapper';
import { useDidMountEffect } from '../../../utils/effects';
import { PSSTokenValidator } from '../validation';
import './P4WConnectionEdit.css'

type OwnProps = {
    onDismiss: () => void;
    onCreated?: (connectionId: string) => void;
    onUpdated?: () => void;
    connectionId?: string;
}
type StateProps = { isLoading: boolean; isProcessing: boolean; error: string | null, refreshInfo: IConnectionRefreshInfo | null, createdConnectionId?: string }
type Props = OwnProps & StateProps & typeof actionCreators & RouteComponentProps<{}>;

type ConnectionState = {
    id?: string;
    url: string;
    account?: string;
    accountTitle?: string;
    token?: string;
    pssToken?: string;
    enableCustomFields: boolean
};

type State = {
    connection: ConnectionState
}

const validator = {
    url: Validator
        .new()
        .regExp(/^\s*(https?:\/\/)?(?<environmentUrl>[a-z0-9]+(-[a-z0-9]+)*\.crm\d*\.dynamics\.com)\/?\s*$/, "i", "Environment URL is invalid")
        .required()
        .build(),
    pssToken: Validator.new().required().add(new PSSTokenValidator()).build(),
}

interface PostMessageData {
    context: {
        error?: {
            message: string,
            description: string
        },
        auth?: {
            token: string;
            account: string;
            accountTitle: string;
        }
    }
}

const P4WConnectionEdit = (props: Props) => {

    const oauthTarget = "connectionDataReceived_p4w";
    const oauthRedirectPath = "/api/integration/p4w/auth";
    
    const [state, setState] = React.useState<State>({
        connection: {
            id: props.connectionId,
            url: '',
            enableCustomFields: false
        }
    });
    const { connection } = state;
    const isEdit = !!connection.id;
    const { url, enableCustomFields, pssToken } = connection;
    const okText = isEdit ? "Save" : "Create";

    React.useEffect(() => {
        if (props.connectionId && props.refreshInfo) {
            state.connection.enableCustomFields = props.refreshInfo.pssToken ? true : false;
            _setConnection({ ...props.refreshInfo });
        }
    }, [props.refreshInfo]);

    React.useEffect(() => {
        props.connectionId && props.loadRefreshInfo(props.connectionId);
    }, [props.connectionId]);

    useDidMountEffect(() => {
        if (!props.isProcessing && !props.error) {
            props.onCreated && props.createdConnectionId && props.onCreated(props.createdConnectionId);
            _dismiss();
        }
    }, [props.isProcessing]);

    const _setConnection = (newState: Partial<ConnectionState>) => {
        setState({ connection: { ...state.connection, ...newState } });
    }

    const _onOAuthMessageReceived = (e: MessageEvent, data: PostMessageData) => {
        if (data.context.auth) {
            const connInfo = { token: data.context.auth.token, accountTitle: data.context.auth.accountTitle, account: data.context.auth.account };
            const newState = { connection: { ...state.connection, ...connInfo } };
            setState(newState);
            props.cleanError();
            if (!isEdit) {
                props.createOrRefreshConnection(newState.connection as IP4WConnectionInfo);
            }
        }
        else if (data.context.error?.message) {
            props.setError(data.context.error.description ?? data.context.error.message);
        }
    }

    const _renderOAuth = () => {
        const { accountTitle, account } = state.connection;
        return <>
            <PostMessageReceiver<PostMessageData>
                from={oauthTarget}
                onMessageReceived={_onOAuthMessageReceived} />
            {
                isEdit && <span className="account-name">
                    Account:<span className="refresh-credentials-control clickable" title='Update or refresh connection' onClick={_oAuthAuthorize} >
                        {accountTitle ? accountTitle : account}<Icon iconName='Refresh' />
                    </span>
                </span>
            }
        </>;
    }

    const _okClick = () => {
        if (validator.url.isValid(state.connection.url)) {
            if (isEdit) {
                props.cleanError();
                props.createOrRefreshConnection(state.connection as IP4WConnectionInfo);
            }
            else {
                _oAuthAuthorize();
            }
        }
    }

    const _oAuthAuthorize = (): void => {
        const { url } = state.connection;
        PostMessageReceiver.openPopupWindow(
            oauthRedirectPath + `?url=${encodeURIComponent(url)}`, oauthTarget);
    }

    const _dismiss = () => {
        props.onDismiss();
        props.cleanError();
    }

    return <Dialog
        hidden={false}
        minWidth={430}
        maxWidth={430}
        dialogContentProps={{
            onDismiss: _dismiss,
            type: DialogType.normal,
            title: <ConnectionTitle isEdit={isEdit} sourceType={SourceType.P4W} />
        }}
        modalProps={{
            isBlocking: true,
            containerClassName: 'ms-dialogMainOverride p4w-popup connection-popup'
        }}>
        <ConnectionEditWrapper sourceType={SourceType.P4W}
            articleGuidePath="https://help.ppm.express/project-for-the-web-connection/1726264"
            videoGuidePath="https://help.ppm.express/project-for-the-web-connection/1726215">
            <div className="connection-wrap">
                <div>
                    <TextField label="Environment URL"
                        disabled={isEdit}
                        value={url}
                        placeholder="your_domain.crm.dynamics.com"
                        onChange={(e, _) => _setConnection({ url: _, token: '', accountTitle: '', account: '' })}
                        onGetErrorMessage={props.isLoading ? undefined : validator.url.getErrorMessage} />
                </div>
                <div>
                    {_renderOAuth()}
                </div>
                <div>
                    <TooltipHost content={"Select this checkbox to open the task custom fields synchronization settings. If the checkbox is not enabled and a token is not provided, only default task fields will be synchronized."}
                        delay={TooltipDelay.zero} hostClassName='tooltip' className='text-hint' calloutProps={{ directionalHint: DirectionalHint.topRightEdge }}>
                        <Checkbox
                            label="Enable Task Custom Fields Synchronization"
                            className="with-top-margin"
                                disabled={isEdit && !!props.refreshInfo?.pssToken}
                                checked={enableCustomFields}
                                onChange={(e, _) => _setConnection({ enableCustomFields: _, pssToken: undefined })}
                        />
                    </TooltipHost>
                </div>
                {
                    enableCustomFields && <div>
                        <p>
                            Please download <Link href="https://downloads.ppm.express/P4WTokenGenerator" target="_blank">Token
                                Generator.exe
                            </Link> to generate a new token
                        </p>
                        <TextField
                            label="Tasks custom fields Token"
                            value={pssToken}
                            type="password"
                            placeholder="Token"
                            onChange={(e, _) => _setConnection({ pssToken: _ })}
                            onGetErrorMessage={validator.pssToken.getErrorMessage} />
                    </div>
                }
                {
                    props.error && <div className="error-message">
                        <MessageBar
                            messageBarType={MessageBarType.severeWarning}
                            isMultiline={true}>
                            {props.error}
                        </MessageBar>
                    </div>
                }
                {props.isLoading && <Overlay><Spinner /></Overlay>}
            </div>
        </ConnectionEditWrapper>
        <DialogFooter>
            {
                !props.isProcessing && [<PrimaryButton
                    key="ok"
                    disabled={!validator.url.isValid(state.connection.url) || (enableCustomFields && !validator.pssToken.isValid(state.connection.pssToken))}
                    onClick={_okClick}
                    text={okText} />,
                <DefaultButton key="cancel" onClick={_dismiss} text="Cancel" />]
            }
            {
                props.isProcessing && [<PrimaryButton key="processing-ok" disabled={true} text={okText}>
                    <Spinner size={SpinnerSize.medium} />
                </PrimaryButton>,
                <DefaultButton key="processing-cancel" disabled={true} text="Cancel" />
                ]
            }
        </DialogFooter>
    </Dialog>;

}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isLoading: state.p4w.connections.isLoading,
        isProcessing: state.p4w.connections.isProcessing,
        error: state.p4w.connections.error,
        refreshInfo: state.p4w.connections.refreshInfo,
        createdConnectionId: state.p4w.connections.createdConnectionId
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, actionCreators)(P4WConnectionEdit));