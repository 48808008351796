import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { StatusCategory, EntityType, statusCategoryMap, entityTypeLabelMap } from '../../../entities/common';
import { FieldActionTypes, ISettingsEditComponent } from '../FieldPanel';
import ColorCategorySettingsEdit, { areCategoryOptionsUnique, areCategoryOptionsValid, isEqual } from './ColorCategorySettingsEdit/ColorCategorySettingsEdit';
import { DraggableOption } from '../SelectSettingsEdit/OptionEdit';
import { buildOptionsUpdate, buildOptions } from '../SelectSettingsEdit/SelectSettingsEdit';
import { FieldType } from '../../../entities/Metadata';
import { useDidMountEffect } from '../../utils/effects';
import { Link } from 'office-ui-fabric-react';
import { CategoryStatusOption, IStatusDropDownSettings } from '../../../entities/StatusDescriptor';

const orderedCategories = [
    StatusCategory.NA,
    StatusCategory.Green,
    StatusCategory.Amber,
    StatusCategory.Red,
    StatusCategory.Done,
];

type Props = {
    settings: IStatusDropDownSettings;
    entityType: EntityType;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
};

const entityTypesWithOverrideAutoCalculationFeature = [EntityType.Project, EntityType.Program, EntityType.Portfolio];
export const entityTypesWithAutoCalculationFeature = [...entityTypesWithOverrideAutoCalculationFeature, EntityType.KeyDate, EntityType.Task];

const StatusSelectSettingsEdit = forwardRef<ISettingsEditComponent, Props>((props, ref) => {
    const { settings, entityType } = props;

    const [draggableOptions, setDraggableOptions] = useState<DraggableOption<CategoryStatusOption>[]>(settings.options
        .map((_, index) => ({ ..._, id: 'id_' + index, key: _.name })));

    const isValid = areCategoryOptionsValid(draggableOptions);
    useDidMountEffect(() => props.onChange(isValid), [isValid, draggableOptions]);

    useImperativeHandle(ref, (): ISettingsEditComponent => ({
        save: (fieldInfo) => {
            fieldInfo.type = FieldType.Text;
            fieldInfo.settings = {
                ...fieldInfo.settings,
                editControl: "ColorStatusDropdown",
                options: buildOptions(draggableOptions, buildCategoryStatusOption)
            };
        },
        update: (fieldInfo) => ({
            ...fieldInfo,
            optionsUpdate: buildOptionsUpdate(settings.options, draggableOptions, buildCategoryStatusOption, isEqual)
        })
    }));

    const onCategoryOptionsChange = (options: DraggableOption<CategoryStatusOption>[]) => {
        setDraggableOptions(options);
    }

    return <>
        <LearnMoreMessage entityType={entityType} />
        <ColorCategorySettingsEdit
            categoryConfig={statusCategoryMap}
            categoryTypeName="Status"
            defaultInCategoryRequired={true}
            getDeletionWarning={(option: CategoryStatusOption) => <DeletionWarningMessage entityType={entityType} option={option} />}
            orderedCategories={orderedCategories}
            options={draggableOptions}
            isUnique={() => areCategoryOptionsUnique(draggableOptions)}
            onChange={(options: DraggableOption<CategoryStatusOption>[]) => onCategoryOptionsChange(options)}
        />
    </>;
})

export default StatusSelectSettingsEdit;

const LearnMoreMessage = ({ entityType }: { entityType: EntityType }) => {
    const withAutoCalculation = entityTypesWithAutoCalculationFeature.includes(entityType);
    return (
        <div>
            Define statuses for each status category. Drag the preferred status to
            the top to make it default{withAutoCalculation ? " for the automatic status calculation." : <>.<br /></>} <Link
                target="_blank"
                href="https://help.ppm.express/89502-ppm-express-how-to-articles/how-to-customize-status-categories-for-projectsprogramsportfoliostasksetc">
                Learn more
            </Link>
        </div>
    );
}

const DeletionWarningMessage = ({ entityType, option }: { entityType: EntityType, option: CategoryStatusOption }) => {
    const NATitle = statusCategoryMap[StatusCategory.NA].title;

    const entityTypeNames = entityTypeLabelMap[entityType];
    const entityTypeLabel = entityTypeNames.singular;

    const message = entityTypesWithAutoCalculationFeature.includes(entityType)
        ? <>
            If the auto-calculation for {entityTypeLabel} status is turned off,
            the default status from the '{NATitle}' category will be applied to current items.
            <br />If the auto-calculation for {entityTypeLabel} status is turned on,
            the default status from the '{statusCategoryMap[option.category].title}' category will be applied to current items.
            {entityTypesWithOverrideAutoCalculationFeature.includes(entityType) && <>
                <br />If the auto-calculation for {entityTypeLabel} status is turned on, and the status was previously overridden,
                the status will be reset to the calculated one.
            </>}
        </>
        : <>The default status from the '{NATitle}' category will apply to current items.</>;

    return <>
        {entityTypeNames.plural} will no longer be able to transition to this status.
        <br />
        {message}
    </>;
}

const buildCategoryStatusOption = (draggableOption: DraggableOption<CategoryStatusOption>): CategoryStatusOption => ({
    category: draggableOption.category,
    name: draggableOption.name,
    color: draggableOption.color
});
