import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { UserState as IUserState } from "../../store/User";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AppcuesOptions, ApplicationState } from "../../store";
import { connect } from "react-redux";
import { Subscription, ProductType } from "../../store/Tenant";
import { useEffect, useState } from "react";

type StateProps = {
    subscription: Subscription;
    user: IUserState;
    appcuesOptions: AppcuesOptions;
};

type Props = StateProps & RouteComponentProps<{}>;

const GuideMe = (props: Props) => {
    const { user } = props;
    const [appcuesScenarioId, setAppcuesScenarioId] = useState<string>(getAppcuesScenarioId());

    useEffect(() => {
        setAppcuesScenarioId(getAppcuesScenarioId());
    }, [props.location.pathname]);

    const showFull = user.preferences.expandSideBar;
    
    return (
        <a
            className="nav-link"
            title="Guide me"
            onClick={() => {
                const appcues: any = (window as any).Appcues;
                if (appcues) {
                    appcues.show(appcuesScenarioId);
                }
            }}
        >
            <div className="icon-container">
                <Icon iconName="PPMXGuide" />
                {showFull && <span className="title">Guide me</span>}
            </div>
        </a>
    );

    
    function getAppcuesScenarioId() {
        return (
            getPrePageProductScenario() ||
            getPrePageScenario() ||
            getCommonProductScenario() ||
            props.appcuesOptions.commonScenarios.find((_) => !_.productType)!.scenarioId
        );
    };

    function getPrePageProductScenario() {
        const { appcuesOptions, subscription, location } = props;
        return subscription
            && appcuesOptions.perPageScenarios.find((_) => _.productType === ProductType[subscription.productType] && !!~location.pathname.indexOf(_.path))?.scenarioId;
    };

    function getPrePageScenario() {
        const { appcuesOptions, location } = props;
        return appcuesOptions.perPageScenarios.find((_) => !_.productType && !!~location.pathname.indexOf(_.path))?.scenarioId;
    };

    function getCommonProductScenario() {
        const { appcuesOptions, subscription } = props;
        return subscription && appcuesOptions.commonScenarios.find((_) => _.productType === ProductType[subscription.productType])?.scenarioId;
    };
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
    subscription: state.tenant.subscription,
    user: state.user,
    appcuesOptions: state.analytics.appcues,
});

export default withRouter<{}>(connect(mapStateToProps)(GuideMe));
