import * as React from "react";
import { connect } from "react-redux";
import { DefaultButton, IContextualMenuItem, ContextualMenuItemType } from "office-ui-fabric-react";
import { ApplicationState } from "../../store/index";
import { UserState } from "../../store/User";
import { Subscription, RoutesAvailability, ToRouteAvailabilitySettings, TenantState } from "../../store/Tenant";
import { NavigationProps } from "./Breadcrumbs";

type StateProps = {
    subscription: Subscription;
    user: UserState;
    tenant: TenantState;
};

type Props = NavigationProps & StateProps;

const HeaderSettingsButton = (props: Props) => {
    const items = getButtonItems(props);

    if (!items.length) {
        return null;
    }

    return (
        <DefaultButton
            title="Settings"
            className="nav-header-button icon-button"
            iconProps={{ iconName: "PPMXGear" }}
            menuProps={{
                items,
                calloutProps: {
                    className: "nav-header-button-callout",
                },
            }}
        />
    );
};

function getButtonItems(props: Props): IContextualMenuItem[] {
    const { user, router, subscription, tenant } = props;
    
    if (!user.permissions) {
        return [];
    }

    const routeAvailabilitySettings = ToRouteAvailabilitySettings(tenant);

    const items: IContextualMenuItem[] = [];

    if (RoutesAvailability.Users(subscription, user)) {
        items.push({ key: "users", name: "People Management", onClick: () => router.history.push("/users"), iconProps: { iconName: "Group" } });
    }

    if (RoutesAvailability.TenantSettings(subscription, user)) {
        items.push({ key: "tenantSettings", name: "Tenant Settings", onClick: () => router.history.push("/tenantSettings"), iconProps: { iconName: "Settings" } });
    }

    if (RoutesAvailability.StatusSettings(subscription, user)) {
        items.push({ key: "statusSettings", name: "PPM Insights", onClick: () => router.history.push("/statusSettings"), iconProps: { iconName: "D365CustomerInsights" } });
    }

    items.push({ key: "reportSettings", name: "Reporting", onClick: () => router.history.push("/reportSettings"), iconProps: { iconName: "ReportDocument" } });

    items.push({ key: "calendar", name: "Calendar", onClick: () => router.history.push("/calendar"), iconProps: { iconName: "Calendar" } });

    if (RoutesAvailability.ArchivedProjects(subscription, user)) {
        items.push({ key: "archivedProjects", name: "Archived Projects", onClick: () => router.history.push("/archivedProjects"), iconProps: { iconName: "Archive" } });
    }

    if (RoutesAvailability.TimeTrackingtSettings(subscription, user, routeAvailabilitySettings)) {
        items.push({ key: 'timeTracking', name: "Time Tracking Settings", onClick: () => router.history.push("/timeTrackingSettings"), iconProps: { iconName: 'Clock' } } );
    }

    if (RoutesAvailability.Billing(subscription, user)) {
        items.push({ key: "billingSettings", name: "Billing", onClick: () => router.history.push("/billing"), iconProps: { iconName: "Money" } });
    }

    if (RoutesAvailability.Automation(subscription, user)) {
        items.push({ key: "divider_1", itemType: ContextualMenuItemType.Divider });
        items.push({ key: "automation", name: "Automation", onClick: () => router.history.push("/automation"), iconProps: { iconName: "Robot" } });
    }

    return items;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(HeaderSettingsButton);
