import * as React from "react";
import * as analytics from "../../analytics";
import { Icon, Dialog } from "office-ui-fabric-react";
import { UserState as IUserState } from "../../store/User";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { post } from "../../fetch-interceptor";
import FeedbackDialog from "../common/FeedbackDialog";
import { PPMFeatures, Subscription } from "../../store/Tenant";

type StateProps = {
    user: IUserState;
    subscription: Subscription;
};

type Props = StateProps & RouteComponentProps<{}>;

const Feedback = ({ user, subscription }: Props) => {
    const [showFeedbackDialog, setShowFeedbackDialog] = React.useState(false);
    const [showFeedbackSentDialog, setShowFeedbackSentDialog] = React.useState(false);
    const showTitle = user.preferences.expandSideBar;
    const isPartnersSupport = Subscription.contains(subscription, PPMFeatures.PartnersSupport);
    
    if (isPartnersSupport || !user.id) {
        return null;
    }

    return (
        <>
            <a className={`nav-link feedback-btn ${showTitle ? "allow-shrink-title" : "notitle"}`} title="Feedback" onClick={() => setShowFeedbackDialog(true)}>
                <div className="icon-container">
                    <Icon iconName="PPMXFeedback" />
                    {showTitle && <span className="title">Feedback</span>}
                </div>
            </a>
            {showFeedbackDialog && (
                <FeedbackDialog
                    showfollowUp={true}
                    subject="General"
                    onDismiss={() => setShowFeedbackDialog(false)}
                    onSave={(comments, followUp) => {
                        const data = { comments, followUp, email: user.email, name: user.name };
                        analytics.trackEvent("Sent Feedback", user);
                        post("api/feedback", data)
                            .then(() => {
                                setShowFeedbackDialog(false);
                                setShowFeedbackSentDialog(true);
                            })
                            .catch(() => {
                                setShowFeedbackDialog(false);
                            });

                        setTimeout(() => setShowFeedbackSentDialog(false), 6000);
                    }}
                />
            )}
            {showFeedbackSentDialog && (
                <Dialog title="Feedback sent" className="feedback-sent-dialog" hidden={false} onDismiss={() => setShowFeedbackSentDialog(false)}>
                    Thank you for providing your feedback!
                </Dialog>
            )}
        </>
    );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
    user: state.user,
    subscription: state.tenant.subscription,
});

export default withRouter<{}>(connect(mapStateToProps)(Feedback));
