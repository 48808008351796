import * as React from "react";
import { Link, Toggle } from "office-ui-fabric-react";
import { LayoutsState } from "../../store/layouts";
import { Validator } from "../../validation";
import { ItemCreation } from "./ItemCreation";
import { DEFAULT_ID, IUpdateLayoutInfo, Layout, NEW_ID } from "../../entities/Metadata";
import SectionsSelector from "./sectionsControl/SectionsSelector";
import { LayoutService } from "../utils/LayoutService";
import { EntityType, entityTypeLabelMap } from "../../entities/common";

export enum EditType {
    Create = "Create",
    Edit = "Edit",
    Rename = "Rename",
    Configure = "Configure"
}

export interface Props {
    layoutsState?: LayoutsState;
    onSave: (update: IUpdateLayoutInfo) => void;
    onDismiss: () => void;
    layout: Layout;
    editType?: EditType;
    entityType?: EntityType;
    allowEditSectionDetails?: boolean;
}

const _nameValidator = Validator.new().required().build();

export const EditLayoutPanel = (props: Props) => {
    const [layoutUpdate, setLayoutUpdate] = React.useState<IUpdateLayoutInfo>({
        name: props.layout.name,
        isView: props.layout.isView,
        isDefault: props.layout.isDefault,
        sections: {}
    });
    const [isDirty, setIsDirty] = React.useState(false);

    const isDefaultEntityLayout = props.layout.id === DEFAULT_ID;

    const headerText = React.useMemo(() => isDefaultEntityLayout
        ? `Configure Default View`
        : `${props.editType === EditType.Create ? "Create new" : props.editType} ${props.layout.isView ? 'View' : 'Layout'}`
        , [props.editType, props.layout]);

    const singular = props.entityType ? entityTypeLabelMap[props.entityType].singular.toLowerCase() : 'item';
    const plural = props.entityType ? entityTypeLabelMap[props.entityType].plural.toLowerCase() : 'items';

    const description = React.useMemo(() => isDefaultEntityLayout
        ? <>Default View is a default configuration for this {singular}.
            {' '}Using this setting, you can configure the look and feel and elements of this {singular}.</>
        : props.editType === EditType.Rename
            ? <>Specify corresponding name for this {props.layout.isView ? 'view' : 'layout'}.</>
            : <> Manage the settings below to edit this {props.layout.isView ? 'view' : 'layout'}. Please refer to the
                {' '}<Link href="https://help.ppm.express/89502-ppm-express-how-to-articles/save-and-apply-default-layout" target="_blank">article</Link> for more details.</>
        , [props.entityType, props.layout.isView])

    return <ItemCreation onDismiss={props.onDismiss}
        header={{
            text: headerText,
            secondaryText: description,
            showNameEditor: !isDefaultEntityLayout && props.editType !== EditType.Configure,
            nameEditorLabel: "Name",
            onChanged: _ => {
                setIsDirty(true);
                setLayoutUpdate({ ...layoutUpdate, name: _ });
            },
            validator: _nameValidator,
            value: layoutUpdate.name
        }}
        isDirty={!isDefaultEntityLayout && isDirty}
        commands={isDefaultEntityLayout ? [] : [
            {
                primary: true,
                text: `${props.layout.id === NEW_ID ? 'Create' : 'Save'}`,
                onClick: () => {
                    props.onSave(layoutUpdate);
                    props.onDismiss();
                },
                disabled: !_nameValidator.isValid(layoutUpdate.name)
            }, {
                text: 'Cancel',
                onClick: props.onDismiss
            }
        ]}>
        <div className="panel-area">
            {!isDefaultEntityLayout && !props.layout.isView && props.editType !== EditType.Rename && <div className="grid-item">
                <Toggle className="two-column" styles={{ root: { marginBottom: '0!important' } }}
                    label={`Set as default for new ${plural}`}
                    checked={layoutUpdate.isDefault}
                    disabled={props.layout.isDefault}
                    onChange={(e, checked) => {
                        setIsDirty(true);
                        setLayoutUpdate({ ...layoutUpdate, isDefault: !!checked });
                    }} />
                <span>If you save this layout as Default, it will be automatically applied to newly created {plural}.</span>
            </div>}
            {props.editType !== EditType.Rename && <div className="grid-item">
                <div>Turn on data sections that you want to make visible in this {props.layout.isView ? 'view' : 'layout'}. Drag and drop to reorder.</div>
                <div style={{ marginTop: 16 }}>
                    <SectionsSelector
                        allSections={props.layoutsState ? LayoutService.getDefault(props.layoutsState).sections : undefined}
                        sections={props.layout.sections}
                        allowEditSectionDetails={props.allowEditSectionDetails}
                        updateSections={updates => {
                            const sections = updates.reduce((cum, cur) => ({ ...cum, [cur.id]: { ...cum[cur.id], ...cur.update } }), layoutUpdate.sections ?? {});
                            setIsDirty(true);
                            setLayoutUpdate({ ...layoutUpdate, sections });

                            if (isDefaultEntityLayout) {
                                props.onSave({ sections } as IUpdateLayoutInfo);
                            }
                        }}
                        onDismiss={props.onDismiss}
                    />
                </div>
            </div>}
        </div>
    </ItemCreation>;
}