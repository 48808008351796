import * as React from 'react';
import { Switch, Redirect } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import { IRoute, IBreadcrumb, IBreadcrumbsRoute, RouteTitle } from "./common";
import Navigation from './Navigation';
import DocumentTitle from './DocumentTitle';
import { ConnectedRouter } from 'react-router-redux';
import { History } from "history";
import { IsRouteAvailable } from '../../store/Tenant';

type Props = { routes: IRoute[], history: History };
type State = { routes: IBreadcrumbsRoute[] };

export default class NavigationRouter extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { routes: build(this.props.routes, []) }
    }

    public render() {
        return <ConnectedRouter history={this.props.history}>
            <Switch>
                {
                    this.state.routes.map(_ => <ProtectedRoute
                        key="router"
                        allowAnonymous={_.allowAnonymous}
                        isAvailable={_.isAvailable}
                        exact={_.exact}
                        path={_.path}
                        render={props => <DocumentTitle title={this._getTitle(_)}>
                            <Navigation route={_} router={props} />
                        </DocumentTitle>} />
                    )
                }
                <Redirect to='/' />
            </Switch>
        </ConnectedRouter>
    }

    private _getTitle(route: IBreadcrumbsRoute): RouteTitle | undefined {
        return route.breadcrumbs.length
            ? route.breadcrumbs[route.breadcrumbs.length - 1].title
            : undefined;
    }
}

function build(childs: IRoute[], breadcrumbs: IBreadcrumb[], isAvailable?: IsRouteAvailable): IBreadcrumbsRoute[] {
    return childs.reduce((prev, c) => {
        const brdcrmbs = breadcrumbs.concat({ title: c.title, path: c.path, url: c.url });
        
        const IsRouteAvailable: IsRouteAvailable = (subscription, user, settings) => {
            return isAvailable?.(subscription, user, settings) !== false
                && c.isAvailable?.(subscription, user, settings) !== false;
        };

        return prev.concat(c.childs ? build(c.childs, brdcrmbs, IsRouteAvailable) : [], {
            path: c.path,
            allowAnonymous: c.allowAnonymous,
            isAvailable: IsRouteAvailable,
            disableNavigation: c.disableNavigation,
            hideNavigation: c.hideNavigation,
            hideHeader: c.hideHeader,
            exact: c.exact,
            component: c.component,
            breadcrumbs: brdcrmbs
        });
    }, Array.of<IBreadcrumbsRoute>());
}