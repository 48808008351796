
import * as React from 'react';

export type BadgeProps = {
    isTrial?: boolean;
    isExpired?: boolean;
    isPopular?: boolean;
}

export const CurrentPlanBadge = (props: BadgeProps) => {
    const text = props.isPopular !== undefined
    ? 'Most Popular' 
    : props.isTrial
        ? props.isExpired ? 'Your expired trial' : 'Your current trial'
        : props.isExpired ? 'Your expired subscription' : 'Your current subscription';
    return <div className='current-plan-badge'>{text}</div>
}