import * as React from 'react';
import { IChoiceGroupOption, Checkbox, mapEnumByName, DayOfWeek } from 'office-ui-fabric-react';
import { notUndefined } from '../../utils/common';

type OwnProps = {
    readonly: boolean;
    value: DayOfWeek[];
    onChange: (value: DayOfWeek[]) => void;
};

const DayOfWeekSelector = (props: OwnProps) => {
    const _dayOptions = React.useMemo(() => mapEnumByName(DayOfWeek, (name, value) => ({
        key: value?.toString() || "",
        text: name!,
        value: value
    }))!.filter(notUndefined), []);
    return <ul className="cb-list cb-in-row">
        {_dayOptions.map((item: IChoiceGroupOption): JSX.Element => {
            return <li key={item.key}>
                <div className="cb-wrap">
                    <Checkbox
                        disabled={props.readonly}
                        label={item.text}
                        checked={props.value.indexOf(item.value as DayOfWeek) !== -1}
                        onChange={(ev: React.FormEvent<HTMLInputElement>, checked: boolean) => {
                            const value = parseInt(ev.currentTarget.name);
                            const days = [...props.value];
                            const idx = days.indexOf(value);
                            if (checked && idx === -1) {
                                days.push(value);
                            }
                            else if (!checked && idx !== -1) {
                                days.splice(idx, 1);
                            }
                            props.onChange(days);
                        }}
                        name={item.key} />
                </div>
            </li>
        })}
    </ul>;
}
export default DayOfWeekSelector;