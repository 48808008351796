import * as React from "react";
import ConnectionSelect from "./ConnectionSelect";
import { IConnectionInfo } from "../../entities/Metadata";
import PostMessageReceiver, { PostMessageData } from "./PostMessageReceiver";
import { SourceType } from "../../store/ExternalEpmConnectStore";

export interface IOAuthConnectionSelect {
    connectionId?: string;
    connections: IConnectionInfo[];
    onConnectionChange: (connection?: IConnectionInfo) => void;
    onConnectionRemove: (connectionId: string) => void;
    onConnectionRename: (connectionId: string, title: string) => void;
    disabled: boolean;

    onReceivedConnectionData: (data: PostMessageData) => void;

    newConnectionUrl: string;
    newConnectionTooltip?: JSX.Element;

    newRestrictedConnectionUrl?: string;
    newRestrictedConnectionTooltip?: JSX.Element;
    
    getRefreshConnectionUrl: (connectionId: string) => string;
    getUpgradeConnectionUrl?: (connectionId: string) => string | undefined;
    grantPermissionsText?: string;
    grantPermissionsUrl?: string;
    systemName: string;
    sourceType: SourceType
}

const OAuthConnectionSelect = (props: IOAuthConnectionSelect) => {

    const { systemName, newConnectionUrl, newRestrictedConnectionUrl, grantPermissionsUrl } = props;
    const target = `connectionDataReceived_${systemName}`;

    const _refreshConnectionUrlClick = (connectionId: string) => {
        const refreshConnectionUrl = props.getRefreshConnectionUrl(connectionId);
        PostMessageReceiver.openPopupWindow(refreshConnectionUrl, target);
    }

    const _upgradeConnectionUrlClick = (connectionId: string) => {
        const upgradeConnectionUrl = props.getUpgradeConnectionUrl?.(connectionId);
        if (upgradeConnectionUrl) {
            PostMessageReceiver.openPopupWindow(upgradeConnectionUrl, target);
        }
    }

    return <>
        <PostMessageReceiver<PostMessageData>
            from={target}
            onMessageReceived={(_, data) => props.onReceivedConnectionData(data)} />
        <ConnectionSelect
            {...props}
            onNewConnectionClick={() => PostMessageReceiver.openPopupWindow(newConnectionUrl, target)}
            onNewRestrictedConnectionClick={newRestrictedConnectionUrl ? () => PostMessageReceiver.openPopupWindow(newRestrictedConnectionUrl, target) : undefined}
            onRefreshClick={(connectionId) => _refreshConnectionUrlClick(connectionId)}
            hasUpgradePremissions={(connectionId) => !!props.getUpgradeConnectionUrl?.(connectionId)}
            onUpgradePremissionsClick={(connectionId) => _upgradeConnectionUrlClick(connectionId)}
            onGrantPermissionsClick={grantPermissionsUrl
                ? () => PostMessageReceiver.openPopupWindow(grantPermissionsUrl, `grantPermissionsDataReceived_${systemName}`)
                : undefined}
        />
    </>;
}

export default OAuthConnectionSelect;