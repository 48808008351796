import * as React from 'react';
import { Dropdown, IDropdownOption, IDropdown, Icon } from 'office-ui-fabric-react';
import { IFormInputProps } from '../interfaces/IFormInputProps'
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { StatusCategoryConfigsList, IColorOption } from '../../../entities/common';
import ColorDropdownOption, { DropdownOptionData } from './ColorDropdownOption';
import { notUndefined } from '../../utils/common';
import { MayBeInlineEditingProps } from '../interfaces/IInputProps';

type ColorDropdownInputProps = IFormInputProps<string | number> & MayBeInlineEditingProps & {
    defaultValue?: any;
    options?: IColorOption[];
    useEmptyValue?: boolean
};

const placeholderKey = "placeholder";
export default class ColorDropdownInput extends React.Component<ColorDropdownInputProps> implements IFormInputComponent {
    private _dropdown = React.createRef<IDropdown>();

    componentDidMount() {
        this.props.inputRef?.(this);
    }

    public render(): JSX.Element {
        const { inputProps, disabled, readOnly, useEmptyValue, inlineEditing: isInlineEditor } = this.props;
        const value = this.props.value ?? this.props.defaultValue;
        
        const isReadOnly = readOnly ?? inputProps?.readOnly;
        const isDisabled = disabled ?? inputProps?.disabled;
        const matchedValue = inputProps?.options?.find((_: any) => _.key === value);

        const options = [
            (useEmptyValue && value !== undefined ? { key: placeholderKey, text: "" } : undefined),
            ...this.props.options
                ? this._mapDropdownOptions(this.props.options, value)
                : this._mapDefaultDropdownOptions(value)
        ].filter(notUndefined);

        return <Dropdown
            {...inputProps}
            disabled={isDisabled}
            onKeyDown={e => { if (isDisabled || isReadOnly) { e.preventDefault(); } }}
            componentRef={this._dropdown}
            selectedKey={matchedValue !== undefined && matchedValue !== null ? matchedValue.key : null}
            onChange={isDisabled || isReadOnly ? undefined : this._onChange}
            onRenderOption={this.onRenderOption}
            options={options}
            onRenderTitle={this.onRenderTitle}
            className="color-status-dropdown-input"
            calloutProps={{ className: "color-status-dropdown-input-callout" }}
            onRenderCaretDown={isInlineEditor ? () => null : undefined}
        />;
    }

    private onRenderOption = (props?: IDropdownOption, defaultRender?: (props?: IDropdownOption) => JSX.Element | null): JSX.Element | null => {
        if (!props) {
            return <span />;
        }
        
        const { selected, text } = props;
        const data = props.data as DropdownOptionData;
        return <ColorDropdownOption data={data} text={text} selected={selected} inlineEditing={this.props.inlineEditing} />;
    }

    private onRenderTitle = (props?: IDropdownOption[], defaultRender?: (props?: IDropdownOption[]) => JSX.Element | null): JSX.Element | null => {
        if (!props || !Array.isArray(props)) {
            return <span />;
        }

        const data = props[0].data as DropdownOptionData;
        return <ColorDropdownOption data={data} text={props[0].text} inlineEditing={this.props.inlineEditing} />
    }

    private _onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        const newValue = item.key === placeholderKey ? null : item.key;
        this.props.onChanged?.(newValue);
        this.props.onEditComplete?.(newValue);
    }

    private _mapDropdownOptions = (options: IColorOption[], value: string | number): IDropdownOption[] => {
        return options.map<IDropdownOption>(_ => ({
            key: _.name,
            text: _.name,
            data: { backgroundColor: _.color } as DropdownOptionData,
            selected: _.name === value,
        }));
    }

    private _mapDefaultDropdownOptions = (value: string | number): IDropdownOption[] => {
        return StatusCategoryConfigsList.map<IDropdownOption>(_ => ({
            key: _.status,
            text: _.title,
            data: { cssClassName: _.cssClassName } as DropdownOptionData,
            selected: _.status === value,
        }));
    }

    focus(): void {
        const { readOnly, disabled, inputProps } = this.props;
        const isDisabled = readOnly ?? inputProps?.readOnly ?? disabled ?? inputProps?.disabled;
        this._dropdown.current!.focus(!isDisabled);
    }
}
