import * as React from 'react';

export type CardMetricConfig = {
    title: string;
    value?: string | number;
    valueClassName?: string;
    valueStyle?: {
        color?: string;
    }
}

type Props = {
    config?: CardMetricConfig;
    renderItem?: () => JSX.Element | null;
    className?: string
}

const CardMetric = ({ className, config, renderItem }: Props) =>
    <div className={"metric" + (className ? " " + className : "")}>
        {
            config && !renderItem
                ? <div key="value">
                    <div className="metric-title">
                        {config.title}
                    </div>
                    <div
                        style={config.valueStyle}
                        className={"metric-value overflow-text" + (config.valueClassName ? " " + config.valueClassName : "")}>
                        {config.value || "-"}
                    </div>
                </div>
                : renderItem?.()
        }
    </div>

export default CardMetric;