import * as React from 'react';
import { Program, programStagesMap, ProgramAttrs } from "../../store/ProgramsListStore";
import Logo from "../common/Logo";
import { getProjectsMetrics } from "../../store/program/utils";
import { formatValue, FormatDate } from "../utils/common";
import StageView from '../views/list/columns/StageView';
import { FormatType, Field, getLabel } from '../../entities/Metadata';
import { connect } from 'react-redux';
import { nameof } from '../../store/services/metadataService';
import { ApplicationState } from '../../store';
import { EntityType, StatusCategory } from '../../entities/common';
import ResourceFormatter from '../common/formatters/ResourceFormatter';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

type OwnProps = { program: Program  };
type StoreProps = { fields: Field[] }
type Props = OwnProps & StoreProps;

class ProgramTooltipContent extends React.Component<Props, { completed: number, total: number }> {
    constructor(props: Props) {
        super(props);

        this.state = getProjectsMetrics(props.program.calculation);
    }

    componentWillReceiveProps(nextProps: { program: Program }) {
        if (this.props.program.calculation !== nextProps.program.calculation) {
            this.setState(getProjectsMetrics(nextProps.program.calculation));
        }
    }

    render() {
        const { program } = this.props;

        const startDate = program.attributes.StartDate
            ? FormatDate(program.attributes.StartDate)
            : "-";
        const finishDate = program.attributes.FinishDate
            ? FormatDate(program.attributes.FinishDate)
            : "-";
        const { completed, total } = this.state;
        const progress = total ? `${completed}/${total}` : 0;
        const manager = program.attributes.Manager;
        const programOverallStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Program, this.props.fields)!;
        const statusOption = programOverallStatusDescriptor.getOptionOrDefault(program.attributes.OverallStatus, StatusCategory.NA);
        
        return (
            <div className="timeline-tooltip">
                <div className="header">
                    <Logo className="prog-logo" imageId={program.imageId} />
                    <div className="title"><div className="overflow-text">{program.attributes.Name}</div></div>
                    <CategoryColorStatusView
                        statusOption={statusOption}
                        trend={program.insights.statuses[nameof<ProgramAttrs>("OverallStatus")]?.trend}
                    />
                </div>

                <div className="content">
                    {this.renderField("StartDate", <div>{startDate}</div>)}
                    {this.renderField("FinishDate", <div>{finishDate}</div>)}
                    {this.renderField("Budget", <div>{formatValue(program.attributes.Budget, FormatType.Cost)}</div>)}
                    <div className="item align-center">
                        <div className="label">Progress</div>
                        <div className="align-center">
                            <div className="completed">{progress}</div>
                            <div>projects complete</div>
                        </div>
                    </div>
                    {this.renderField("Stage",
                        program.attributes.Stage !== undefined
                            ? <StageView value={program.attributes.Stage} className="program-stage" map={programStagesMap} />
                            : <div>-</div>)}
                    {this.renderField("Manager",
                        manager.length > 0
                            ? <div className="align-center">
                                <ResourceFormatter resource={manager} withNavigation={false} />
                              </div>
                            : <div className="align-center">
                                <span>(Manager is not assigned yet)</span>
                              </div>)}
                </div>
            </div>
        );
    }

    private renderField = (name: keyof ProgramAttrs, valueElement: JSX.Element): JSX.Element | null => {
        const field = this.props.fields.find(_ => _.name === name);
        if (!field) {
            return null;
        }

        return (
            <div className="item align-center">
                <div className="label">{getLabel(field)}</div>
                {valueElement}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Program];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
    };
}

export default connect(mapStateToProps)(ProgramTooltipContent);