import * as React from "react";
import ActionCard from './ActionCard';
import InviteUserPanel from "../../user/InviteUserPanel";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { CommonOperations, contains } from "../../../store/permissions";
import { checkIfADUsersSyncEnabled } from "../../../store/Tenant";
import { isAdminViewer } from "../../../store/User";

type OwnProps = {
    name: string;
    iconName: string;
}
type StoreProps = {
    isTenantReadOnly: boolean;
    canInvite: boolean;
    isADUsersSyncEnabled: boolean;
}
type Props = OwnProps & StoreProps;
const InvitePeopleActionCard = (props: Props) => {
    const [showInvite, setShowInvite] = React.useState<boolean>();
    const onDismiss = React.useCallback(() => setShowInvite(undefined), []);
    return <>
        <ActionCard
            iconName={props.iconName}
            name={props.name}
            title={props.isADUsersSyncEnabled ? "Invite People option is disabled as access to PPM Express is managed by synchronization with Azure AD" : undefined}
            disabled={props.isTenantReadOnly || !props.canInvite || props.isADUsersSyncEnabled}
            action={() => setShowInvite(true)}
        />
        {showInvite && <InviteUserPanel onDismiss={onDismiss} />}
    </>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    return {
        isTenantReadOnly: state.tenant.isReadOnly,
        canInvite: !isAdminViewer(state.user) && (contains(state.user.permissions.common, CommonOperations.Administrate) || !!state.tenant.security?.allowEveryoneToInviteUsers),
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
    };
}
export default connect(mapStateToProps)(InvitePeopleActionCard);