import "./EmailSettings.css";
import * as React from 'react';
import * as analytics from '../../../analytics';
import { connect } from 'react-redux';
import { Label } from 'office-ui-fabric-react';
import { ApplicationState } from "../../../store/index";
import { UserState, actionCreators, isAdminViewer } from '../../../store/User';
import { CommonOperations, contains, LicenseType } from '../../../store/permissions';
import { TenantState } from '../../../store/Tenant';
import ScheduleDisplay from '.././ScheduleDisplay';
import EmailToggle from './EmailToggle';

type OwnProps = {}
type StateProps = {
    user: UserState;
    tenant: TenantState;
}
type ActionProps = typeof actionCreators;
type Props = OwnProps & StateProps & ActionProps;

export const EmailSettings = (props: Props) => {
    const canManage = !isAdminViewer(props.user);
    const userNotificationsDisabled = !props.tenant.healthReportingSettings?.isUserNotificationsEnabled;
    const dailyScheduleDisabled = !props.tenant.healthReportingSettings?.dailySchedule?.times?.length
        || !props.tenant.healthReportingSettings?.dailySchedule?.days?.length;
    const weeklyScheduleDisabled = !props.tenant.healthReportingSettings?.weeklySchedule?.times?.length
        || !props.tenant.healthReportingSettings?.weeklySchedule?.days?.length;

    const onChangeAssignedToEmailNotifications = React.useCallback(
        (event: React.MouseEvent<HTMLElement>, checked: boolean) => {
            props.updateNotificationPreferences({ entityEmailNotifications: checked });
            analytics.trackToggle("Receive notifications updated", props.user, checked);
        },
        []);

    const onChangeDaily = React.useCallback(
        (event: React.MouseEvent<HTMLElement>, checked: boolean) => {
            props.updateNotificationPreferences({ dailyEmailNotifications: checked });
            analytics.trackToggle("Receive daily summary updated", props.user, checked);
        },
        []);

    const onChangeWeekly = React.useCallback(
        (event: React.MouseEvent<HTMLElement>, checked: boolean) => {
            props.updateNotificationPreferences({ weeklyEmailNotifications: checked });
            analytics.trackToggle("Receive weekly summary updated", props.user, checked);
        },
        []);

    return <div className={`user-email-settings ${userNotificationsDisabled ? 'is-disabled' : ''}`}>
        <Label>Email Settings</Label>
        <div className="settings-list-item">
            <EmailToggle
                label='Receive notifications'
                checked={props.user.preferences.entityEmailNotifications}
                tooltipContent={<AssignedToEmailTooltipContent disabled={!canManage || userNotificationsDisabled} />}
                onChange={onChangeAssignedToEmailNotifications}
                disabled={!canManage || userNotificationsDisabled}
            />
        </div>
        {
            props.user.license === LicenseType.Regular &&
            <>
                <div className="settings-list-item">
                    <EmailToggle
                        label='Receive daily summary'
                        checked={props.user.preferences.dailyEmailNotifications}
                        tooltipContent={<EmailTooltipContent disabled={!canManage || dailyScheduleDisabled} />}
                        onChange={onChangeDaily}
                        disabled={!canManage || dailyScheduleDisabled}
                    />
                    {props.tenant.healthReportingSettings && <ScheduleDisplay
                        schedule={props.tenant.healthReportingSettings.dailySchedule} />}
                </div>
                <div className="settings-list-item">
                    <EmailToggle
                        label='Receive weekly summary'
                        checked={props.user.preferences.weeklyEmailNotifications}
                        tooltipContent={<EmailTooltipContent disabled={!canManage || weeklyScheduleDisabled} />}
                        onChange={onChangeWeekly}
                        disabled={!canManage || weeklyScheduleDisabled}
                    />
                    {props.tenant.healthReportingSettings && <ScheduleDisplay
                        schedule={props.tenant.healthReportingSettings.weeklySchedule} />}
                </div>
            </>
        }
    </div>;
}

const EmailTooltipContent = React.memo((props: { disabled: boolean }) => {
    return <div className="content">
        {props.disabled
            ? "If you want to receive Project, Program and Portfolio summary by email, please contact your Tenant Administrator to enable this feature."
            : "You will get summary emails only for Projects, Programs and Portfolios of your own. \
           In case you would like to change the time emails are sent, please contact your Tenant Administrator."}
        <br />
        <span>For more details please refer to <a href='https://help.ppm.express/89245-getting-started-with-ppm-express/529387-notifications-on-ppm-express-actions#section-1' target='_blank'>this</a> article</span>
    </div>
});

const AssignedToEmailTooltipContent = React.memo((props: { disabled: boolean }) => {
    return <div className="content">
        {
            props.disabled
                ? "If you want to receive emails when you are assigned to PPM Express items, please contact your Tenant Administrator to enable this feature."
                : <>
                    You will get an email when:
                    <ul>
                        <li>You are assigned to an Action Item, Issue, Risk, Task, Key Date or a Change Request</li>
                        <li>You are assigned as a Requester or Decider to a Change Request</li>
                        <li>You are assigned as a manager of Portfolio, Program, Project, Business Challenge or as an Objective owner</li>
                        <li>New Idea is submitted to your Business Challenge</li>
                    </ul>
                </>
        }
        <br />
        <span>For more details please refer to <a href='https://help.ppm.express/89245-getting-started-with-ppm-express/599126' target='_blank'>this</a> article</span>
    </div >
});


function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, actionCreators)(EmailSettings);