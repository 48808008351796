import * as React from 'react';
import * as ReactDOM from 'react-dom';
import SectionControl, { ISectionInfo } from '../SectionControl';
import { Section, getLabel } from '../../../../entities/Metadata';
import { Dictionary, EMPTY_GUID, EntityType, IWarning, UserPreferencesSettingsUpdate } from "../../../../entities/common";
import { getId, Icon, IconButton } from 'office-ui-fabric-react';
import { waitForFinalEvent } from "../../../utils/common";
import { RouteComponentProps } from "react-router-dom";
import { ISectionReports } from '../../../reporting/SectionReports';
import { LayoutsState } from '../../../../store/layouts';
import { UserState, actionCreators as UserActionCreators } from '../../../../store/User';
import AccordionSection from '../../AccordionSection';
import { IControlConfiguration } from '../../interfaces/ISectionUIControlProps';
import { nameof } from '../../../../store/services/metadataService';
import { actionCreators as UserInterfaceActionCreators, UserInterfaceState } from '../../../../store/UserInterfaceStore';

export interface IContainerSectionInfo extends ISectionInfo {
    isInView: boolean;
}

interface State {
    sections: IContainerSectionInfo[];
    lastUpdatedSectionIndex?: number;
    isExpanded: boolean;
}

type SectionsSettings = Dictionary<Dictionary<SectionSettings>> & {
    layoutId?: string;
}

export interface BaseSectionsContainerProps {
    entity: { id: string; sections?: Section[], isEditable?: boolean, canConfigure?: boolean, canCollaborate?: boolean };
    hideSideBar?: boolean;
    getSections?: (entity: any) => Section[];
    warnings?: IWarning[];
    onHeaderMiddleRender?: (section: IContainerSectionInfo) => JSX.Element | JSX.Element[] | null;
    onSaveSettings?: (update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => void;
    layouts?: LayoutsState;
    isUpdatingSections?: boolean;
    reporting?: ISectionReports;
    sectionsSettings?: SectionsSettings;
    entityType?: EntityType;
    controlsConfig?: Dictionary<IControlConfiguration>;
    user: UserState;
    userInterface: UserInterfaceState;
    userActions: typeof UserActionCreators;
    userInterfaceActions: typeof UserInterfaceActionCreators;
    withViewsInHeader?: boolean;
}

type SectionSettings = { isOpen: boolean } & Dictionary<unknown>;

type Props = BaseSectionsContainerProps & RouteComponentProps<{}>;

export class BaseSectionsContainer extends React.Component<Props, State> {
    private sections: AccordionSection[] = [];
    private sideBar: any[] = [];
    private scrollContainer: any;
    private highlightSideBarDebounce = waitForFinalEvent(this.highlightSideBar.bind(this), 50, getId());

    private sectionNameToEntityType: Dictionary<string> = {
        "Key dates": EntityType.KeyDate,
        "Iterations": EntityType.Iteration,
        "Risks": EntityType.Risk,
        "Issues": EntityType.Issue,
        "Lessons learned": EntityType.LessonLearned,
        "Action items": EntityType.ActionItem,
        "Steering committee": EntityType.SteeringCommittee,
        "Key Decisions": EntityType.KeyDecision,
        "Change Requests": EntityType.ChangeRequest,
        "Purchase Orders": EntityType.PurchaseOrder,
        "Invoices": EntityType.Invoice,
        "Deliverables": EntityType.Deliverable,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            isExpanded: props.user.preferences.expandSecondSidebar?.[props.entityType!] ?? true,
            ...this._buildState(props),
        };
    }

    private _buildState(props: BaseSectionsContainerProps) {
        const query = new URLSearchParams(this.props.location.search);
        const isInQuery = (name: string) => !!this.sectionNameToEntityType[name] && !!query.get(this.sectionNameToEntityType[name]);
        return {
            sections: (props.layouts?.activeEntity ? props.layouts?.activeEntity.sections : props.getSections?.(props.entity) ?? props.entity.sections!)
                .filter(_ => _.isSelected)
                .map(_ => ({
                    ..._,
                    isInView: false,
                    isConfigureMode: false,
                    isOpen: isInQuery(_.name) || this._isOpen(_, getSectionSettings(_.id, props.sectionsSettings))
                })),
        };
    }

    componentDidUpdate(prevProps: BaseSectionsContainerProps, prevState: State) {
        this.highlightSideBar();

        const isLayoutApplied = prevProps.layouts?.isApplyingLayout && !this.props.layouts?.isApplyingLayout;
        const isSectionsUpdated = prevProps.isUpdatingSections && !this.props.isUpdatingSections;
        if (!isLayoutApplied && !isSectionsUpdated) {
            const index = this.state.lastUpdatedSectionIndex;
            if (!index) {
                return;
            }

            const prevSection = prevState.sections[index];
            const section = this.state.sections[index];
            if (!prevSection || !section) {
                return;
            }

            if (!prevSection.isOpen && section.isOpen) {
                const node = ReactDOM.findDOMNode(this.sections[index]) as HTMLElement;
                if (!node) {
                    return;
                }

                if (typeof (node as any).scrollIntoViewIfNeeded === "function") {
                    (node as any).scrollIntoViewIfNeeded();
                    return;
                }
                node.scrollIntoView();
            }
        }
    }

    componentDidMount() {
        this.highlightSideBar();
    }

    componentWillReceiveProps(nextProps: BaseSectionsContainerProps) {
        const newState = this._buildState(nextProps);
        const isLayoutApplied = this.props.layouts?.isApplyingLayout && !nextProps.layouts?.isApplyingLayout;
        const isLayoutChanged = this.props.layouts?.activeEntityId !== nextProps.layouts?.activeEntityId;
        const isSectionsUpdated = this.props.isUpdatingSections && !nextProps.isUpdatingSections;
        const isSettingsLoaded = !this.props.onSaveSettings && !!nextProps.onSaveSettings;
        if (!isLayoutApplied && !isSectionsUpdated && !isLayoutChanged && !isSettingsLoaded) {
            newState.sections.forEach(_ => {
                const oldSection = this.state.sections.find(__ => __.id === _.id);
                if (oldSection) {
                    _.isInView = oldSection.isInView;
                    _.isConfigureMode = oldSection.isConfigureMode;
                    _.isOpen = oldSection.isOpen;
                }
            });
        }

        this.sections = [];
        this.sideBar = [];
        this.setState(newState);
    }

    private _isOpen = (section: Section, sectionSettings?: SectionSettings) => {
        return sectionSettings?.isOpen === undefined ? section.isOpen : sectionSettings?.isOpen;
    }

    highlightSideBar() {
        if (this.props.hideSideBar) {
            return;
        }

        this.sections.forEach((section, index) => {
            if (this.isVisible(ReactDOM.findDOMNode(section))) {
                this.sideBar[index].classList.add('in-view');
            } else {
                this.sideBar[index].classList.remove('in-view');
            }
        });
    }

    isVisible(element: any) {
        if (this.scrollContainer) {
            const offset = 10;

            const containerRect = this.scrollContainer.getBoundingClientRect();
            const componentRect = element.getBoundingClientRect();

            const containerTop = containerRect.top + offset;
            const containerBottom = containerRect.bottom - offset;

            return componentRect.top < containerBottom && componentRect.bottom > containerTop;
        }

        return false;
    }

    render() {
        const { hideSideBar, withViewsInHeader, entity, user, warnings, reporting, layouts, sectionsSettings,
            controlsConfig, onHeaderMiddleRender, entityType, userActions, onSaveSettings } = this.props;
        const { sections = [], isExpanded } = this.state;
        return <div key={getLayoutId(sectionsSettings)} className={`view ${withViewsInHeader ? 'with-views-in-header' : ''} ${hideSideBar || !isExpanded ? '' : 'with-sidebar'}`}>
            {!hideSideBar && <div className={`sidebar ${isExpanded ? '' : 'collapsable'}`}>
                <IconButton
                    className='chevron'
                    iconProps={{ iconName: isExpanded ? 'ChevronLeft' : 'ChevronRight' }}
                    onClick={() => {
                        this.setState({ isExpanded: !isExpanded }, () => window.dispatchEvent(new Event("resize")));
                        entityType && userActions.updateSecondSidebarPreferences(entityType, !isExpanded);
                    }} />
                <div className="items">
                    {sections.map((section, index) =>
                        <div key={section.id} ref={_ => this.sideBar[index] = _}
                            className={`menu align-center ${section.isInView ? 'in-view' : ''}`}
                            onClick={_ => this._expandAccordionAndScroll(index)}>
                            {section.settings.iconName && <Icon iconName={section.settings.iconName} />}
                            {getLabel(section)}
                        </div>)}
                </div>
            </div>}
            <div className={`content scrollable`} ref={_ => this.scrollContainer = _} onScroll={this.highlightSideBarDebounce}>
                <div className="sections-container">
                    {
                        sections.map((section, index) => <SectionControl
                            key={section.id}
                            ref={el => el && (this.sections[index] = el)}
                            section={section}
                            index={index}
                            entity={entity}
                            entityType={entityType}
                            user={user}
                            warnings={warnings}
                            onHeaderMiddleRender={onHeaderMiddleRender ? () => (onHeaderMiddleRender(section)) : undefined}
                            reporting={reporting}
                            isViewSelected={!!layouts?.activeEntity}
                            onChange={this._onChange}
                            onSaveSettings={onSaveSettings ? this._onSaveSettings : undefined}
                            sectionSettings={getSectionSettings(section.id, sectionsSettings)}
                            controlsConfig={controlsConfig}
                            onToggleFullScreen={_ => this.props.userInterfaceActions.toggleFullScreen(_, section.id)}
                            isFullScreen={this.props.userInterface.isFullScreen && section.id === this.props.userInterface.sectionId}
                        />)
                    }
                </div>
            </div>
        </div>;
    }

    private _onSaveSettings = (sectionId: string, update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => {
        const { onSaveSettings, sectionsSettings } = this.props;

        const updateWithLayoutAndSectionIds: UserPreferencesSettingsUpdate = {
            ...update,
            parentSettingsPathKeys: [
                getLayoutId(sectionsSettings),
                sectionId,
                ...update.parentSettingsPathKeys
            ]
        };
        onSaveSettings!(updateWithLayoutAndSectionIds, sendToServer);
    }

    private _expandAccordionAndScroll = (i: number) => {
        const node = ReactDOM.findDOMNode(this.sections[i]) as HTMLElement;

        if (!this.state.sections[i].isOpen) {
            this.sections[i].expand();

            //nail: checks if section has dynamic content which is loaded with delay.
            //If the size of the section is stretched by the content, re-scrollIntoView() is needed
            //whole duration of check cannot be too long to avoid UI artifacts
            let count = 10;
            let height = node.clientHeight;
            const interval = setInterval(() => {
                if (height !== node.clientHeight) {
                    height = node.clientHeight;
                    node.scrollIntoView();
                }
                if (!--count) {
                    clearInterval(interval);
                }
            }, 50);
        }
        node.scrollIntoView();
    }

    private _onChange = (index: number, changes: Partial<ISectionInfo>) => {
        const section = this.state.sections[index];

        const settings = getSectionSettings(section.id, this.props.sectionsSettings);
        if (changes.isOpen !== undefined && settings?.isOpen !== changes.isOpen) {
            this.props.onSaveSettings?.({
                parentSettingsPathKeys: [getLayoutId(this.props.sectionsSettings), section.id],
                valueBySettingNameMap: { [nameof<SectionSettings>("isOpen")]: changes.isOpen }
            })
        }

        const sections: IContainerSectionInfo[] = this.state.sections.map((section, i) => i === index ? Object.assign({}, section, changes) : section);
        this.setState({ sections, lastUpdatedSectionIndex: index }, () => this.highlightSideBar());
    }
}

const getSectionSettings = (sectionId: string, sectionsSettings?: SectionsSettings): SectionSettings | undefined => sectionsSettings?.[getLayoutId(sectionsSettings)]?.[sectionId];

const getLayoutId = (sectionsSettings?: SectionsSettings) => sectionsSettings?.layoutId
    ? sectionsSettings.layoutId
    : EMPTY_GUID