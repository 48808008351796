import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../../store/index";
import { ExternalFieldInfo, MappingType, TaskImportSettings } from '../../../store/integration/common';
import { actionCreators, IConnectionConfiguration, SmartsheetFieldToPpmxFieldsMap } from '../../../store/integration/SmartsheetStore';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import MappingPanel from "../MappingPanel";
import { EntityType } from '../../../entities/common';
import { get } from "../../../fetch-interceptor";
import { Link } from 'office-ui-fabric-react';

type OwnProps = {
    onDismiss: () => void;
    connectionId: string;
    itemSheetId: number;
    initialTab?: string;
}

type StateProps = {
    configuration: IConnectionConfiguration;
    defaultTaskSettings: TaskImportSettings;
    smartsheetItemFields: ExternalFieldInfo[];
    isLoading: boolean;
    isProcessing: boolean;
    error: string | null;
}
type Props = OwnProps & StateProps & {
    smartsheetActionCreators: typeof actionCreators;
};

const SmartsheetMappingPanel = (props: Props) => {
    const { connectionId, itemSheetId, smartsheetActionCreators } = props;
    React.useEffect(() => {
        smartsheetActionCreators.loadDefaultTaskMappings(connectionId, itemSheetId);
        smartsheetActionCreators.loadSheetFields(connectionId, itemSheetId);
        smartsheetActionCreators.loadConnectionConfiguration(connectionId, itemSheetId);
    }, [props.connectionId]);

    function _onGetExternalFieldOptions(field: ExternalFieldInfo) {
        return get<{ options: string[] | number[] | null }>(`api/integration/smartsheet/field/${field.internalName}/options`, { connectionId, boardId: itemSheetId })
            .then(data => data.options);
    }

    return <MappingPanel
        onRenderHeader={() => <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure Task Fields Mapping</p>
            <div className='ms-Panel-secondaryText'>Select fields for mapping to be displayed in Schedule section.<br />
                Please refer to <Link href='https://help.ppm.express/smartsheet-connection/1553622' target='_blank'>this article</Link> on how to configure mapping.
            </div>
        </div>}
        isLoading={props.isLoading}
        error={props.error}
        onDismiss={props.onDismiss}
        connector={SourceType.Smartsheet}
        entityType={EntityType.Task}
        externalFields={props.smartsheetItemFields}
        typesMap={SmartsheetFieldToPpmxFieldsMap}
        mapping={props.configuration.taskImportSettings["Task"]}
        showTransformationScript={true}
        updateMapping={mapping => {
            props.configuration.taskImportSettings["Task"] = mapping;
            props.smartsheetActionCreators.updateConnectionConfiguration(connectionId, itemSheetId, props.configuration);
            props.onDismiss();
        }}
        mappingTypes={[MappingType.ToPpmx]}
        onGetExternalFieldOptions={_onGetExternalFieldOptions}
    />;

}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    return {
        configuration: {
            taskImportSettings: state.smartsheet.taskImportSettings.entities
        },
        defaultTaskSettings: state.smartsheet.defaultTaskSettings.entities,
        smartsheetItemFields: state.smartsheet.fieldsBySheetId.entities || [],
        error: state.smartsheet.fieldsBySheetId.error ||
            state.smartsheet.defaultTaskSettings.error ||
            state.smartsheet.taskImportSettings.error,
        isLoading: state.smartsheet.fieldsBySheetId.isLoading ||
            state.smartsheet.defaultTaskSettings.isLoading ||
            state.smartsheet.taskImportSettings.isLoading,
        isProcessing: false
    }
}

const mergeActionCreators = (dispatch: any) => {
    return {
        smartsheetActionCreators: bindActionCreators(actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(SmartsheetMappingPanel);