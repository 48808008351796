import { nameof } from "../store/services/metadataService";
import { Dictionary, EntityType } from "./common";
import * as Metadata from "./Metadata";
import * as PortfoliosListStore from "../store/PortfoliosListStore";
import * as ProgramsListStore from "../store/ProgramsListStore";
import * as ProjectsListStore from "../store/ProjectsListStore";
import StatusDescriptor from "./StatusDescriptor";
import { IDeliverableAttrs, IKeyDateAttrs, IRoadmapItemAttrs, ITaskAttrs } from "./Subentities";
import { IObjectiveAttrs } from "../store/ObjectivesListStore";

// the same is in StatusDescriptorFactory.cs on server side
function buildStatusFieldNameByEntityTypeMap(): Partial<Record<EntityType, string>> {
    return {
        [EntityType.Portfolio]:     nameof<PortfoliosListStore.PortfolioAttrs>("OverallStatus"),
        [EntityType.Program]:       nameof<ProgramsListStore.ProgramAttrs>("OverallStatus"),
        [EntityType.Project]:       nameof<ProjectsListStore.ProjectAttrs>("OverallStatus"),
        [EntityType.KeyDate]:       nameof<IKeyDateAttrs>("Status"),
        [EntityType.Deliverable]:   nameof<IDeliverableAttrs>("Status"),
        [EntityType.Objective]:     nameof<IObjectiveAttrs>("Status"),
        [EntityType.RoadmapItem]:   nameof<IRoadmapItemAttrs>("Status"),
        [EntityType.Task]:          nameof<ITaskAttrs>("Status"),
    };
};

// the same is in StatusDescriptorFactory.cs on server side
function buildAllStatusFieldNamesByEntityTypeMap(): Partial<Record<EntityType, string[]>> {
    return {
        [EntityType.Portfolio]:     PortfoliosListStore.StatusNames,
        [EntityType.Program]:       ProgramsListStore.StatusNames,
        [EntityType.Project]:       ProjectsListStore.StatusNames,
        [EntityType.KeyDate]:       [nameof<IKeyDateAttrs>("Status")],
        [EntityType.Deliverable]:   [nameof<IDeliverableAttrs>("Status")],
        [EntityType.Objective]:     [nameof<IObjectiveAttrs>("Status")],
        [EntityType.RoadmapItem]:   [nameof<IRoadmapItemAttrs>("Status")],
        [EntityType.Task]:          [nameof<ITaskAttrs>("Status")],
    };
};

export function createStatusDescriptorByFieldNameMapFor(entityType: EntityType, entityFields: Metadata.Field[]): Dictionary<StatusDescriptor> | undefined {
    const allStatusFieldNamesByEntityTypeMap = buildAllStatusFieldNamesByEntityTypeMap();
    const entityStatusFieldNames = allStatusFieldNamesByEntityTypeMap[entityType];
    if (!entityStatusFieldNames) {
        return undefined
    }
    return createStatusDescriptorByFieldNameMapForImpl(entityStatusFieldNames, entityFields);
}

export function createStatusDescriptorFor(entityType: EntityType, entityFields: Metadata.Field[]): StatusDescriptor | undefined {
    const statusFieldNameByEntityTypeMap = buildStatusFieldNameByEntityTypeMap();
    const statusFieldName = statusFieldNameByEntityTypeMap[entityType];
    if (!statusFieldName) {
        return undefined;
    }
    return createStatusDescriptorForImpl(statusFieldName, entityFields);
}

export function createStatusDescriptor(statusField: Metadata.Field): StatusDescriptor {
    return createStatusDescriptorForImpl(statusField.name, [statusField]);
}

function createStatusDescriptorForImpl(statusFieldName: string, entityFields: Metadata.Field[]): StatusDescriptor {
    return createStatusDescriptorByFieldNameMapForImpl([statusFieldName], entityFields)[statusFieldName];
}

function createStatusDescriptorByFieldNameMapForImpl(statusFieldNames: string[], entityFields: Metadata.Field[]): Dictionary<StatusDescriptor> {
    const statusDescriptorByFieldNameMap = new Dictionary<StatusDescriptor>();
    for (const field of entityFields) {
        if (statusFieldNames.includes(field.name)) {
            statusDescriptorByFieldNameMap[field.name] = new StatusDescriptor(field);
        }
    }
    return statusDescriptorByFieldNameMap;
}
