import { Action, Reducer } from 'redux';
import { get } from '../../fetch-interceptor';
import { AppThunkAction } from '../';
import { IListStore } from "./common";
import { catchApiError } from "../utils";

export interface IProjectInfo {
    id: string;
    name: string;

    isAlreadyLinked: boolean;
}

export interface PPMXTimeState {
    projects: IListStore<IProjectInfo>;
}

export const actionCreators = {
    loadProjects: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PPMX_TIME_LOAD_PROJECTS' });
        get<IProjectInfo[]>('api/integration/time/projects')
            .then(_ => dispatch({ type: 'PPMX_TIME_RECEIVED_PROJECTS', projects: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'PPMX_TIME_SET_PROJECTS_ERROR', error: `Unable to load PPM Express Time projects: ${_}` })));
    }
};

interface LoadProjects {
    type: "PPMX_TIME_LOAD_PROJECTS";
}
interface ReceivedProjects {
    type: "PPMX_TIME_RECEIVED_PROJECTS";
    projects: IProjectInfo[];
}
interface SetProjectsError {
    type: "PPMX_TIME_SET_PROJECTS_ERROR",
    error: string | null
}

type KnownAction = LoadProjects
    | ReceivedProjects
    | SetProjectsError;

const initState: PPMXTimeState = {
    projects: {
        entities: [],
        isLoading: false,
        error: null
    }
};

export const reducer: Reducer<PPMXTimeState> = (state: PPMXTimeState = initState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "PPMX_TIME_LOAD_PROJECTS":
            return {
                ...state,
                projects: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "PPMX_TIME_RECEIVED_PROJECTS":
            return {
                ...state,
                projects: {
                    entities: action.projects,
                    isLoading: false,
                    error: null
                }
            }
        case 'PPMX_TIME_SET_PROJECTS_ERROR':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        default:
            return state;
    }
};