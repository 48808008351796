import * as React from 'react';
import { connect } from 'react-redux';
import { PersonaSize, Persona, IPersonaProps } from "office-ui-fabric-react";
import { getPersonInfoImageUrl } from "../../utils/common";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { ApplicationState } from "../../../store/index";
import { IUserInfo } from "../../../entities/common";
import Link from "../Link";
import { Validator } from "../../../validation";

type OwnProps = {
    resource?: IUserInfo | IUserInfo[];
    withNavigation?: boolean;
    onClick?: (e: any, resource: IUserInfo) => void;
    coinSize?: PersonaSize;
    passiveShowMore?: boolean;
    coinsDisplayLimit?: number;
    onlyCoins?: boolean;
};
type StateProps = { user: UserState };
type Props = OwnProps & StateProps;
type State = {
    showAllResources: boolean;
}

const personValidator = Validator.new().person().build();
const shortListLimit = 5;

class ResourceFormatter extends React.PureComponent<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = { showAllResources: false };
    }
    render() {
        const { user, withNavigation, onClick, coinSize, passiveShowMore, coinsDisplayLimit, onlyCoins } = this.props;
        if (!this.props.resource) return null;
        const resource = Array.isArray(this.props.resource) ? this.props.resource : [this.props.resource];
        const size = coinSize ?? PersonaSize.size28;
        const renderLink = contains(user.permissions.common, CommonOperations.ResourceView) && withNavigation;
        const itemsLimit = coinsDisplayLimit ?? shortListLimit;

        if (!onlyCoins && resource.length == 1) {
            let person = !Array.isArray(resource) ? resource : resource[0];
            if (!personValidator.isValid(person)) return null;
            const imageUrl = getPersonInfoImageUrl(person);

            return onClick
                ? <a onClick={(e) => onClick(e, person)} className="with-persona">
                    <Persona size={size} imageUrl={imageUrl} text={person.fullName} onRenderPrimaryText={this.onRenderPrimaryText} />
                </a>
                : renderLink
                    ? <Link href={`/resource/${person.id}`} className="with-persona">
                        <Persona size={size} imageUrl={imageUrl} text={person.fullName} onRenderPrimaryText={this.onRenderPrimaryText} />
                    </Link>
                    : <Persona size={size} imageUrl={imageUrl} text={person.fullName} onRenderPrimaryText={this.onRenderPrimaryText} />;

        }

        return <>
            {(this.state.showAllResources || resource.length <= itemsLimit ? resource : resource.slice(0, itemsLimit)).map((r, index) =>
                onClick
                    ? <a key={index} title={r.fullName} onClick={(e) => onClick(e, r)} className="with-persona">
                        <Persona className="persona-inline" size={size} imageUrl={getPersonInfoImageUrl(r)} hidePersonaDetails text={r.fullName} />
                    </a>
                    : renderLink
                        ? <Link key={index} href={`/resource/${r.id}`} title={r.fullName} className="with-persona">
                            <Persona className="persona-inline" size={size} imageUrl={getPersonInfoImageUrl(r)} hidePersonaDetails text={r.fullName} />
                        </Link>
                        : <Persona key={index} className="persona-inline" size={size} imageUrl={getPersonInfoImageUrl(r)} hidePersonaDetails text={r.fullName} title={r.fullName} />
            )}
            {!this.state.showAllResources && resource.length > itemsLimit
                && <span className="res-cut" onClick={passiveShowMore ? undefined : (e) => { this.setState({ showAllResources: true }); e.stopPropagation(); }} title="Show More">+{resource.length - itemsLimit}</span >}
        </>;
    }
    //need for not rendering default tooltip
    private onRenderPrimaryText = (props?: IPersonaProps): JSX.Element => <span>{props?.text}</span>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { user: state.user }
}

export default connect(mapStateToProps)(ResourceFormatter);