import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import * as FieldsStore from "../../../../store/fields";
import { Selection, CheckboxVisibility } from 'office-ui-fabric-react';
import { IControlConfiguration, ISectionUIControlProps } from "../../interfaces/ISectionUIControlProps";
import * as Metadata from "../../../../entities/Metadata";
import {
    Dictionary, IHistoryRow, statusCategoryMap, IWithWarnings, IWithInsights, IWithChangeHistory,
    IStatusHistoryRow, UpdateContext, EntityType, StatusCategory
} from "../../../../entities/common";
import StatusPanel from "./statusesControl/StatusPanel";
import { renderHistoryTooltipContent } from "./statusesControl/StatusHistory";
import StatusSelectionPanel, { buildStatusAttributeName } from "./statusesControl/StatusSelectionPanel";
import StatusCard from "./statusesControl/StatusCard";
import { warningService } from "../../../../store/services/warningService";
import { PersistTimelineList } from '../../extensibleEntity/EntityTimelineList';
import { IRow } from '../../timeline/TimelineList';
import { nameof } from '../../../../store/services/metadataService';
import { ITimelineMarker } from '../../timeline/TimelineMarker';
import { IStatusInfo, IStatusInfoAttributes } from '../../../views/list/columns/status/Name';
import { ICalculationSettings, StatusCalculationTypes } from '../../../../store/Tenant';
import { isInReadonlyMode, UserState } from '../../../../store/User';
import { contains, CommonOperations } from '../../../../store/permissions';
import { bindActionCreators, Dispatch } from 'redux';
import { toDate } from '../../../utils/common';
import { IControlWarningsTypeMap } from '../../../../store/warningsTypeMapsStore';
import { ControlSettings } from '../../../../store/services/viewSaver';
import * as StatusDescriptorFactory from '../../../../entities/StatusDescriptorFactory';
import { CategoryStatusOption } from '../../../../entities/StatusDescriptor';

interface IStatusesControlSettings {
    statuses: string[];
}

type State = {
    activeStatusName?: string;
    statusHistoryViewFields: Metadata.Field[];
    isHistoryInited: boolean;
}

interface IFieldAreaEnity extends IWithWarnings, IWithInsights, IWithChangeHistory {
    attributes: Dictionary<any>;
}

interface IHistoryTimelineMarker extends ITimelineMarker {
    row: IStatusHistoryRow
}

interface StatusHistoryValue {
    value: string;
    category: StatusCategory;
}

export interface IDataContext {
    entityId: string;
    entityType: EntityType;
    statusCalculation: ICalculationSettings;
    statuses: string[];
    warningsTypeMap?: IControlWarningsTypeMap;
    fields?: any;
}

export interface IActions {
    refreshEntity: () => void;
    onEditComplete: (value: Dictionary<string | number | undefined>, context: Dictionary<UpdateContext>) => void;
    resetStatus: (statusAttributeName: string) => void;
    loadStatusHistory: (fieldName?: string) => void;
    deleteStatusHistory: (statusAttributeName: string, id: string | undefined) => void;
    updateUIControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void;
}
type StateProps = {
    user: UserState;
    fields: Metadata.Field[];
    isFieldsLoading: boolean;
    canConfigure: boolean;
    canConfigureSelection: boolean;
    canManageHistory: boolean;
}

type ActionProps = {
    fieldsActions: ReturnType<typeof FieldsStore.actionCreators.forEntity>;
}

export type IConfiguration = IControlConfiguration<IActions, ControlSettings, IDataContext>
type OwnProps = ISectionUIControlProps<IActions, IStatusesControlSettings, IFieldAreaEnity, {}, ControlSettings, IDataContext>;
type Props = OwnProps & StateProps & ActionProps;

class StatusesControl extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);
        this.state = {
            statusHistoryViewFields: this._buildStatusHistoryViewFields(),
            isHistoryInited: false
        };
        this._selection = new Selection();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.datacontext?.fields && nextProps.datacontext?.fields !== this.props.datacontext?.fields
            || nextProps.settings.statuses.length && !this.props.settings.statuses.length) {
            this.setState({
                statusHistoryViewFields: this._buildStatusHistoryViewFields()
            });
        }

        if (isTimelineView(nextProps) && !this.state.isHistoryInited) {
            nextProps.actions.loadStatusHistory();
            this.setState({ isHistoryInited: true });
        }
    }

    public render() {
        const { key, settings, datacontext, isConfigureMode, onConfigureModeComplete, canConfigure,
            canConfigureSelection, controlSettings, onSaveSettings } = this.props;
        const isTimeline = isTimelineView(this.props);
        const { activeStatusName, statusHistoryViewFields } = this.state;

        return (
            <div className="statuses-area" key={key}>
                {!isTimeline && <div className="statuses-card-list">{settings.statuses.map(_ => this.renderCard(_))}</div>}
                {isTimeline && <PersistTimelineList
                    selection={this._selection}
                    entities={settings.statuses.map(this._buildStatusFieldInfo)}
                    entityType={datacontext.entityType}
                    fields={statusHistoryViewFields}
                    displayFields={statusHistoryViewFields.map(_ => _.name)}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    buildRow={this._buildTimelineItem}
                    renderMarkerTooltipContent={(_, marker: IHistoryTimelineMarker) => renderHistoryTooltipContent(
                        marker.row,
                        this._getStatusName((_.entity as IStatusInfo).id))
                    }
                    controlSettings={controlSettings}
                    onSaveSettings={onSaveSettings}
                />}
                {activeStatusName && this.renderStatusPanel(activeStatusName)}
                {isConfigureMode && <StatusSelectionPanel
                    statuses={datacontext.statuses}
                    selected={settings.statuses}
                    entityType={datacontext.entityType}
                    onChange={canConfigureSelection ? this._onStatusesSelectionChange : undefined}
                    onDismiss={onConfigureModeComplete}
                    allowManageFields={canConfigure}
                    fieldActions={{ updateField: this.updateField }}
                    showSpinner={this.props.isFieldsLoading}
                />}
            </div>
        );
    }

    private updateField = (fieldId: string, update: Metadata.IUpdateFieldInfo): void => {
        this.props.fieldsActions.updateField(fieldId, update);
    }

    private _buildStatusHistoryViewFields(): Metadata.Field[] {
        return [
            {
                id: 'name',
                isNative: true,
                isCustom: false,
                isReadonly: true,
                isSystem: true,
                label: 'Name',
                name: nameof<IStatusInfoAttributes>('name'),
                type: Metadata.FieldType.Text,
                group: Metadata.FieldGroup.SystemFields,
                settings: {
                    views: {
                        list: {
                            componentPath: 'status/Name',
                            minWidth: 200,
                            maxWidth: 300
                        }
                    }
                }
            },
            {
                id: 'status',
                isNative: true,
                isCustom: false,
                isReadonly: true,
                isSystem: true,
                label: 'Status',
                name: 'status',
                type: Metadata.FieldType.Text,
                group: Metadata.FieldGroup.SystemFields,
                settings: {
                    views: {
                        list: {
                            componentPath: 'status/HistoryViewColorStatus',
                            minWidth: 200,
                            maxWidth: 300
                        }
                    }
                }
            }];
    }

    private _buildStatusFieldInfo = (status: string): IStatusInfo => {
        const { entity, datacontext } = this.props;
        const statusAttributeName = buildStatusAttributeName(status);
        const statusField = this._getStatusField(status)!;
        const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(statusField);
        return {
            id: status,
            attributes: {
                name: this._getStatusName(status),
                statusOption: statusDescriptor.getOptionOrDefault(entity.attributes[statusAttributeName], StatusCategory.NA)
            },
            history: this.getStatusHistory(statusAttributeName),
            warnings: warningService.getWarningsByKey(`${status}Status`, entity.warnings || [], datacontext.warningsTypeMap)!,
            onClick: () => { this.setState({ activeStatusName: status }) }
        };
    }

    private _buildTimelineItem = (statusInfo: IStatusInfo): IRow => {
        return {
            key: statusInfo.id,
            entity: statusInfo,
            segments: [],
            markers: statusInfo.history.map((_, index): IHistoryTimelineMarker => {
                const statusHistoryValue = _.value as StatusHistoryValue;
                return ({
                    key: `${index}`,
                    date: toDate(_.date)?.getBeginOfDay()!,
                    className: statusCategoryMap[statusHistoryValue.category].cssClassName,
                    row: _
                });
            })
        };
    }

    private _onStatusesSelectionChange = (statuses: string[]) => {
        const { actions, id, sectionId } = this.props;
        actions.updateUIControl(sectionId, id, { statuses });
    }

    private _getStatusName = (status: string): string => {
        return this._getStatusField(status)?.label || `${status} Status`;
    }

    private _getStatusField = (status: string): Metadata.Field | undefined => {
        const statusAttrName = buildStatusAttributeName(status);
        return this.props.fields.find(_ => _.name === statusAttrName);
    }

    private _getStatusCalculation = () => this.props.entity.insights.statusCalculationDisabled
        ? { statusCalculation: StatusCalculationTypes.Manual }
        : this.props.datacontext.statusCalculation;

    private renderStatusPanel = (statusName: string) => {
        const { entity, datacontext, fields } = this.props;
        const calculation = this._getStatusCalculation();

        const statusField = this._getStatusField(statusName)!;
        const statusAttributeName = buildStatusAttributeName(statusName);
        const statusDescriptionAttributeName = buildStatusDescriptionAttributeName(statusName);
        const entityStatusDescriptor = StatusDescriptorFactory.createStatusDescriptor(statusField);
        const statusOption = entityStatusDescriptor.getOptionOrDefault(entity.attributes[statusAttributeName], StatusCategory.NA);

        return <StatusPanel
            descriptionPlaceholder={fields.find((f: Metadata.Field) => f.name === statusDescriptionAttributeName)!.settings?.placeholder}
            view={{
                statusOption,
                statusField,
                insights: entity.insights.statuses[statusAttributeName],
                description: entity.attributes[statusDescriptionAttributeName],
                resetStatus: entity.isEditable ? () => this._resetStatus(statusAttributeName) : undefined,
            }}
            edit={(calculation.statusCalculation !== StatusCalculationTypes.Auto || calculation.manualDescription) && entity.isEditable
                ? {
                    calculation,
                    onEditComplete: (st, desr, dur) => this._saveStatus(statusName, st, desr, dur),
                    resetStatus: () => this._resetStatus(statusAttributeName)
                }
                : undefined}
            history={{
                label: this._getStatusName(statusName),
                changeHistory: this.getStatusHistory(statusAttributeName),
                loadHistory: () => this.props.actions.loadStatusHistory(statusAttributeName),
                deleteHistory: this.props.canManageHistory ? id => this.props.actions.deleteStatusHistory(statusAttributeName, id) : undefined
            }}
            onDismiss={this._onDismiss}
        />;
    }

    private getStatusHistory = (statusAttributeName: string) => {
        const { changeHistory } = this.props.entity;
        return changeHistory
            ? changeHistory.filter((r: IHistoryRow) => r.fieldName === statusAttributeName) as IStatusHistoryRow[]
            : [];
    }

    private _resetStatus = (statusAttributeName: string) => {
        this.props.actions.resetStatus(statusAttributeName);
        this._onDismiss();
    }

    private _onDismiss = () => {
        this.setState({ activeStatusName: undefined });
    }

    private renderCard = (statusName: string) => {
        const statusAttrName = buildStatusAttributeName(statusName);
        const descriptionAttrName = buildStatusDescriptionAttributeName(statusName);

        const { datacontext, fields, entity, warnings } = this.props;

        const field = fields.find((f: Metadata.Field) => f.name === statusAttrName);
        const descriptionField = fields.find((f: Metadata.Field) => f.name === descriptionAttrName);

        if (!field || !descriptionField) {
            return null;
        }

        const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(field);
        const statusOption = statusDescriptor.getOptionOrDefault(entity.attributes[statusAttrName], StatusCategory.NA);

        return (
            <StatusCard key={field.name}
                onClick={() => { this.setState({ activeStatusName: statusName }); }}
                entity={{
                    name: field.label,
                    statusOption,
                    insights: entity.insights.statuses[statusAttrName],
                    description: entity.attributes[descriptionAttrName],
                    descriptionPlaceholder: field.description || descriptionField.settings?.placeholder
                }}
                warnings={warningService.getWarningsByKey(statusAttrName, warnings ?? [], datacontext.warningsTypeMap)} />
        );
    }

    private _saveStatus = (statusName: string, status: CategoryStatusOption | undefined, description: string | undefined, overrideDuration: number | undefined) => {
        const attributes = {
            [buildStatusDescriptionAttributeName(statusName)]: description
        };
        if (status) {
            attributes[buildStatusAttributeName(statusName)] = status.name
        }
        this.props.actions.onEditComplete(attributes, { [buildStatusAttributeName(statusName)]: { forceWriteHistory: true, overrideDuration }});
    }
}

function isTimelineView(props: Props) {
    return props.controlSettings.viewType === 'Timeline';
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[ownProps.datacontext.entityType];
    const isReadOnlyMode = isInReadonlyMode(state.user, state.tenant, ownProps.entity);
    return {
        user: state.user,
        fields: fields.allIds.map(_ => fields.byId[_]),
        isFieldsLoading: fields.isLoading,
        canManageHistory: !ownProps.entity.isArchived && contains(state.user.permissions.common, CommonOperations.Administrate),
        canConfigure: !ownProps.entity.isArchived && contains(state.user.permissions.common, CommonOperations.ConfigurationManage),
        canConfigureSelection: ownProps.isViewSelected
            ? !ownProps.entity.isArchived && contains(state.user.permissions.common, CommonOperations.ConfigurationManage)
            : isReadOnlyMode || !!ownProps.entity.canConfigure
    };
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): ActionProps {
    const entityFieldsStore = FieldsStore.actionCreators.forEntity(ownProps.datacontext.entityType, ownProps.datacontext.entityId, ownProps.actions.refreshEntity);
    return {
        fieldsActions: bindActionCreators(entityFieldsStore, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(StatusesControl);

export function buildStatusDescriptionAttributeName(name: string) {
    return `${name}StatusDescription`;
}