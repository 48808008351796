import * as React from "react";
import { IconButton, Icon } from "office-ui-fabric-react";
import { imageIdToUrl } from "../utils/common";

export interface ILogoProps {
    onChanged?: (logo: File) => void;
    onRemove?: () => void;
    imageId?: string;
    className?: string;
    iconName?: string;
    text?: string;
    accept?: string;
}

const Logo = ({ onChanged, onRemove, imageId, className, iconName, text, accept }: ILogoProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const editable = !!onChanged && !!onRemove;

    const handleChange = React.useCallback(() => {
        const files = inputRef.current!.files;
        if (files?.length) {
            onChanged!(files[0]);
        }
    }, [onChanged]);

    const handleRemove = React.useCallback(() => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        onRemove?.();
    }, [onRemove]);

    return (
        <div
            className={`logo ${editable ? "editable" : ""} ${className || ""} ${iconName || ""}`}
            style={imageId ? { backgroundImage: `url(${imageIdToUrl(imageId)})` } : {}}
            title={text}
        >
            {iconName && !imageId && <Icon className={iconName} iconName={iconName} />}
            {editable && (
                <div className="buttons-container">
                    <IconButton title="Upload" iconProps={{ iconName: "Edit" }} onClick={() => inputRef.current!.click()} />
                    <input ref={inputRef} type="file" accept={accept} style={{ display: "none" }} onChange={handleChange} />
                    {imageId && <IconButton title="Delete" iconProps={{ iconName: "Delete" }} onClick={handleRemove} />}
                </div>
            )}
        </div>
    );
};

export default Logo;
