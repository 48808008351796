import React from "react";
import { CommandBar, IContextualMenuItem } from "office-ui-fabric-react";
import SelectionModeCommandBar from "./SelectionModeCommandBar";
import { EntityTypeLabels } from '../../entities/common';

export type SelectionModeSettings = {
    enabled: boolean;
    items: IContextualMenuItem[];
    onCancel: () => void;
};

type Props = {
    items: IContextualMenuItem[];
    farItems?: IContextualMenuItem[];
    selectionMode?: SelectionModeSettings & { selectedCount?: number; };
    entityTypeLabels?: EntityTypeLabels;
}

const SelectionModeSwitchableCommandBar = (props: Props) => {
    const { items, farItems, selectionMode } = props;
    if (items.length === 0 && farItems?.length === 0 && selectionMode?.items.length === 0 && selectionMode.selectedCount === undefined) {
        return null;
    }

    return (
        <div className="selection-mode-switchable-command-bar" style={{ width: '100%' }}>
            {selectionMode?.enabled && (selectionMode.selectedCount !== undefined || selectionMode.items.length > 0)
                ? <SelectionModeCommandBar
                    entityTypeLabels={props.entityTypeLabels}
                    selectedCount={selectionMode.selectedCount}
                    items={selectionMode.items}
                    onCancel={selectionMode.onCancel} />
                : <CommandBar
                    style={{ width: '100%' }}
                    className="header-command-bar"
                    items={items}
                    farItems={farItems} />}
        </div>
    );
}

export default SelectionModeSwitchableCommandBar;
