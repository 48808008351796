import "./EditableGridCell.css";
import * as React from "react";
import { connect } from "react-redux";
import { DisplayFieldService } from "../DisplayFieldService";
import { Icon, IconButton } from "office-ui-fabric-react";
import { IExtensibleEntity } from "../../../entities/common";
import { IListViewFieldColumn, ViewService } from "../../../services/ViewService";
import { isLockedField } from "../../utils/common";
import { GridCellProps } from "./EditableGridCell";
import { ApplicationState } from "../../../store";
import { TenantState } from "../../../store/Tenant";
import { UserState } from "../../../store/User";

type StateProps = {
    user: UserState;
    tenant: TenantState;
}

type Props = GridCellProps & StateProps & {
    onEditClick: () => void,
    value: any,
    controlId?: string,
};

const CellFormatter = (props: Props) => {
    const onEditClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        props.onEditClick();
    }, [props.withEditIcon]);

    const readonlyCustomControl = props.customFieldElementRender?.[props.field.name]?.({}, props.item, props.field, undefined) === null;
    const isReadonly = props.field.isReadonly || !!props.field.isFake || !!props.readonly || readonlyCustomControl 
        || isLockedField(props.field.settings?.format, props.field.name, props.user, props.tenant, props.entityType);
    const isActionable = ViewService.isActionable(props.field);

    const formattedControl = React.useMemo(() => {
        if (!!props.field.settings?.views?.list?.componentPath) {
            return React.createElement(
                ViewService.createListColumn<IListViewFieldColumn<IExtensibleEntity>>(props.field.settings.views.list.componentPath),
                {
                    entity: props.item,
                    field: props.field,
                    className: "font-14",
                    isTimelineView: props.isTimelineView,
                    entityType: props.entityType,
                    disableNavigation: props.disableNavigation
                }
            );
        }
        const Formatter = DisplayFieldService.getFieldFormatter(props.field, !props.disableNavigation, props.entityType);
        return Formatter ? <Formatter item={props.item} value={props.value} /> : undefined;
    }, [props.item, props.field, props.isTimelineView, props.entityType, props.disableNavigation, props.value]);

    return <div className="grid-editable-cell" id={props.controlId}
        data-selection-disabled
        onClick={!isReadonly && !props.withEditIcon ? onEditClick : undefined}>
        <div className="formatter">{formattedControl}</div>
        {!isReadonly && !!props.withEditIcon
            && <div className="grid-edit-button">
                <IconButton iconProps={{ iconName: "Edit" }} onClick={onEditClick} />
            </div>}
        {!isActionable && isReadonly && <span className="readonly-button">
            <Icon iconName="Uneditable" title="This cell is read-only" />
        </span>}
    </div>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(CellFormatter)