import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

type DirtyFormMessageBarProps = {
    primaryCommandLabel?: string;
    primaryActionLabel?: string;
    cancelCommandLabel?: string;
    cancelActionLabel?: string;
}

export const DirtyFormMessageBar = (props: DirtyFormMessageBarProps) => {
    const { primaryCommandLabel, primaryActionLabel, cancelCommandLabel, cancelActionLabel } = props;
    return <div className="warning">
        <MessageBar messageBarType={MessageBarType.warning}>
            You have unsaved changes. Please click
            {primaryCommandLabel && ` ${primaryCommandLabel} to ${primaryActionLabel ?? 'save the changes'}`}
            {primaryCommandLabel && cancelCommandLabel ? " or" : "."}
            {cancelCommandLabel && ` ${cancelCommandLabel} to ${cancelActionLabel ?? 'discard them'}.`}
        </MessageBar>
    </div>
}