import { Icon } from "office-ui-fabric-react";
import * as React from "react";
import { ProductType } from '../../../store/Tenant';

type Props = {
    iconName?: string;
    productType: ProductType;
}

const PlanIcon = (props: Props) => {
    return <Icon iconName={props.iconName ?? ProductType.getIcoName(props.productType)} />
}

export default PlanIcon;