import { 
    ActionButton, ComboBox, IComboBoxOption, ISelectableOption, Label, PrimaryButton, Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as React from "react";
import { ISourceInfo } from "../../../entities/common";
import { validateConnection, ILinkDto, IListStore } from "../../../store/integration/common";
import { 
    IVSTSProject, IVSTSWorkItem, IWorkItemType, actionCreators, IWorkItemClassificationNode, WorkItem, IBaseVSTSSourceData, IVSTSConnectionState
} from "../../../store/integration/VSTSStore";
import OverlayComponent from "../../common/OverlayComponent";
import { DebouncedOptionsPicker, Option } from "../../common/inputs/OptionsPicker";
import { ConnectionField } from "../ConnectionField";
import VSTSConfigureConnectionPanel from "./VSTSConfigureConnectionPanel";
import VSTSConnectionSelect from "./VSTSConnectionSelect";
import { IVSTSTeam } from './../../../store/integration/VSTSStore';
import { post } from "../../../fetch-interceptor";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { usePrevious } from "../../utils/effects";
import { useDidMountEffect } from './../../utils/effects';
import FieldLabel from "../FieldLabel";
import SwitchPrimaryScheduleConfirmation from "../SwitchPrimaryScheduleConfirmation";
import { SourceType } from "../../../store/ExternalEpmConnectStore";

type VSTSConnectActions = {
    deleteLink: (connectionId: string) => void,
    linkToVSTSProject: (linkInfo: ILinkDto<IVSTSLinkData> & { setAsPrimary?: boolean }) => void
}

export enum VSTSConnectionType {
    Project = "project",
    Program = "program"
}

type OwnProps = {
    sourceInfo?: ISourceInfo<IBaseVSTSSourceData>;
    readonly: boolean;
    allowMultipleConnectionsToOneProject?: boolean;
    disableConfigureConnection?: boolean;
    actions: VSTSConnectActions;
    dismissPanel?: () => void;
    connectionType: VSTSConnectionType;
    showSwitchPrimaryDialog?: boolean;
    setAsPrimary?: boolean;
}

type WorkItemClassificationEntity = {
    order: number;
    identifier: string;
    path: string;
    name: string;
    indent: number;
};

type StateProps = {
    projects: IListStore<IVSTSProject>;
    teams: IListStore<IVSTSTeam>;
    areas: IListStore<WorkItemClassificationEntity>;
    iterations: IListStore<WorkItemClassificationEntity>;
    workItemTypes: IListStore<IWorkItemType>;
    connections: IVSTSConnectionState;
}

type Props = OwnProps & StateProps & typeof actionCreators;

export interface IVSTSLinkData {
    projectId: string;
    projectName: string;
    teamId?: string;
    teamName?: string;
    areaPath?: string;
    iterationPath?: string;
    workItems: WorkItem[];
}

const projectsToOptions = (projects: IListStore<IVSTSProject>): ISelectableOption[] => {
    return projects.entities.map(_ => ({ key: _.id, text: _.name })).sort((a, b) => a.text.localeCompare(b.text));
}

const teamsToOptions = (teams: IListStore<IVSTSTeam>): ISelectableOption[] => {
    return teams.entities.map(_ => ({ key: _.id, text: _.name })).sort((a, b) => a.text.localeCompare(b.text));
}

const areasToOptions = (areas: IListStore<WorkItemClassificationEntity>): ISelectableOption[] => {
    return areas.entities.map(_ => ({ key: _.path, text: _.name, data: _ })).sort((a, b) => a.text.localeCompare(b.text));
}

const iterationsToOptions = (iterations: IListStore<WorkItemClassificationEntity>): ISelectableOption[] => {
    return iterations.entities.map(_ => ({ key: _.path, text: _.name, data: _ })).sort((a, b) => a.text.localeCompare(b.text));
}

const workItemsToOptions = (workItems: WorkItem[], workItemTypes: IListStore<IWorkItemType>): ISelectableOption[] => {
    return workItems.map(_ => ({
        key: _.id,
        text: _.name || _.id?.toString(),
        icon: workItemTypes.entities.find(witType => witType.name === _.type)?.icon?.url
    })).sort((a, b) => a.text.localeCompare(b.text));
}

const onRenderItem = (props?: IComboBoxOption, defaultRender?: (props?: IComboBoxOption) => JSX.Element | null): JSX.Element | null => {
    if (!defaultRender) {
        return null;
    }

    const element = defaultRender(props);
    if (!props || !props.disabled) {
        return element;
    }
    return React.createElement("div", { title: "Already linked" }, element);
}

const linkToProject = (props: Props, connectionId: string, projectId: string, teamId: string | undefined,
    areaPath: string | undefined, iterationPath: string | undefined, selectedWorkItems: Option[], setAsPrimary?: boolean) => {
    const { projects, teams, actions, workItemTypes, dismissPanel } = props;
    const project = projects.entities.find(_ => _.id === projectId);

    if (connectionId && project) {
        const team = teams.entities.find(_ => _.id.toString() === teamId);
        const workItems = selectedWorkItems.map(_ => ({
            id: _.key as number,
            name: _.text,
            type: workItemTypes.entities.find(t => t.icon?.url === _.icon)?.name
        }) as WorkItem);


        actions.linkToVSTSProject({
            connectionId,
            data: {
                projectId: project.id,
                projectName: project.name,
                teamId: team?.id.toString(),
                teamName: team?.name,
                areaPath: areaPath,
                iterationPath: iterationPath,
                workItems: workItems
            },
            setAsPrimary: !!setAsPrimary
        });
        dismissPanel?.();
    }
}

const deleteLink = (props: Props, connectionId: string) => {
    const { actions, dismissPanel } = props;

    actions.deleteLink(connectionId);
    dismissPanel?.();
}

export const isPartialLink = (data: IVSTSLinkData) => !!data.areaPath || !!data.iterationPath || data.workItems.length || !!data.teamId;

const VSTSConnectControl = (props: Props) => {
    const { sourceInfo, readonly, projects, teams, areas, iterations, allowMultipleConnectionsToOneProject,
        disableConfigureConnection, workItemTypes, connections, connectionType, showSwitchPrimaryDialog } = props;
    const [connectionId, setConnectionId] = React.useState<string | undefined>(sourceInfo?.connectionId);
    const [projectId, setProjectId] = React.useState<string | undefined>(sourceInfo?.sourceData.projectId);
    const [teamId, setTeamId] = React.useState<string | undefined>(sourceInfo?.sourceData.teamId)
    const [areaPath, setAreaPath] = React.useState<string | undefined>(sourceInfo?.sourceData.areaPath);
    const [iterationPath, setIterationPath] = React.useState<string | undefined>(sourceInfo?.sourceData.iterationPath);
    const [isConfigureConnection, setIsConfigureConnection] = React.useState<boolean | undefined>();
    const [selectedWorkItems, setSelectedWorkItems] = React.useState<Option[]>([]);
    const [showSwitchPrimaryScheduleConfirm, setShowSwitchPrimaryScheduleConfirm] = React.useState<boolean>(false);

    const isSelectedConnectionValid = () => {
        const selectedConnection = validateConnection(connections, connectionId);
        return selectedConnection?.isValidating === false && !selectedConnection.validationError;
    }

    const previousConnectionsVerification = usePrevious(connections.connectionsVerification);

    useDidMountEffect(() => { 
        connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false && isSelectedConnectionValid() && props.loadProjects(connectionId);
    }, [connectionId && connections.connectionsVerification[connectionId]]);

    useDidMountEffect(() => { 
        if (connectionId && projectId && isSelectedConnectionValid()) {
            props.loadTeams(connectionId, projectId);
            props.loadAreas(connectionId, projectId);
            props.loadIterations(connectionId, projectId);
            props.loadWorkItemTypes(connectionId, projectId);
        }
    }, [projectId, connectionId && connections.connectionsVerification[connectionId]]);

    React.useEffect(() => {
        !isConfigureConnection && workItemTypes 
            && setSelectedWorkItems(sourceInfo?.sourceData?.workItems?.length ? workItemsToOptions(sourceInfo.sourceData.workItems, workItemTypes) : []);
    }, [workItemTypes]);

    const projectsOptionsMap = React.useMemo(() => projectsToOptions(projects), [projects]);
    const teamsOptionsMap = React.useMemo(() => teamsToOptions(teams), [teams]);
    const areasOptionsMap = React.useMemo(() => areasToOptions(areas), [areas]);
    const iterationsOptionsMap = React.useMemo(() => iterationsToOptions(iterations), [iterations]);

    const isLinked = !!sourceInfo?.sourceData;
    const disabled = readonly || isLinked || !connectionId;

    const isProjectAlreadyLinked = React.useMemo(() => {
        const project = projects.entities.find(_ => _.id === projectId);
        return !allowMultipleConnectionsToOneProject && project?.isAlreadyLinked;
    }, [projectId, projectsOptionsMap]);

    const isAnyProjectPartSelected = () => !!(teamId || areaPath || iterationPath || selectedWorkItems.length);

    const onConnectionChange = React.useCallback((_: string | undefined) => {
        if (!isLinked) {
            setConnectionId(_);
            setIterationPath(undefined);
            setAreaPath(undefined);
            setTeamId(undefined);
            setProjectId(undefined);
            setSelectedWorkItems([]);
        }
    }, []);

    const onResolveSuggestions = (template: string, selectedItems?: Option[]) => {
        const exceptIds = selectedItems?.length 
            ? selectedItems.map(_ => _.key)
            : [];
        
        return post<IVSTSWorkItem[]>(`api/integration/vsts/workitems`, { connectionId: connectionId, projectId: projectId, template: template, exceptIds: exceptIds })
            .then(workItems => workItemsToOptions(workItems, workItemTypes));
    }

    const isValidConfiguration = !!projectId && !!connectionId;
    const connectionValidationInfo = validateConnection(connections, connectionId);

    const getComboboxValidationMessage = () => {
        if (!connectionId || projects.isLoading || connectionValidationInfo?.isValidating !== false || connectionValidationInfo?.validationError) {
            return undefined;
        }
    
        if (projects.error) {
            return projects.error;
        }

        if (!projectId && projects.entities.length) {
            return 'Please select project from list.';
        }

        if (!allowMultipleConnectionsToOneProject && !sourceInfo && isProjectAlreadyLinked && !isAnyProjectPartSelected()) {
            return 'Project is already linked. You can link it to the project part.';
        }
    
        return undefined;
    }

    return <>
        <div key="select-connection-container" className="with-top-margin spinner-container">
            <VSTSConnectionSelect 
                disabled={readonly || isLinked}
                connectionId={connectionId}
                onChange={onConnectionChange} />
        </div>
        <div key="select-project-container" className="with-top-margin">
            <OverlayComponent isOverlayed={!connectionId || connectionValidationInfo?.isValidating !== false || !!connectionValidationInfo?.validationError}>
                <ConnectionField
                    isLinked={isLinked}
                    value={sourceInfo?.sourceData.projectName}
                    label="Project">
                        <ComboBox
                            disabled={disabled || projects.isLoading || !projects.entities.length}
                            placeholder={!projects.isLoading && !projects.entities.length ? 'No projects' : 'Select project'}
                            selectedKey={projectId}
                            allowFreeform
                            useComboBoxAsMenuWidth
                            options={projectsOptionsMap}
                            onRenderItem={onRenderItem}
                            onChange={(e, _) => {
                                setIterationPath(undefined);
                                setAreaPath(undefined);
                                setTeamId(undefined);
                                setSelectedWorkItems([]);
                                setProjectId(_ ? (_.key as string) : undefined)
                            }}
                            errorMessage={getComboboxValidationMessage()}>
                        </ComboBox>
                </ConnectionField>
                {projects.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
            </OverlayComponent>
            <OverlayComponent isOverlayed={!projectId}>
                <ConnectionField
                    className="with-top-margin"
                    isLinked={isLinked}
                    value={sourceInfo?.sourceData.teamName}
                    label="Team">
                        <ComboBox
                            disabled={disabled || !projectId || teams.isLoading || !teams.entities.length}
                            placeholder="Select team"
                            allowFreeform
                            useComboBoxAsMenuWidth
                            selectedKey={teamId}
                            options={teamsOptionsMap}
                            onRenderItem={onRenderItem}
                            onChange={(e, _) => setTeamId(_ ? (_.key as string) : undefined)}
                            errorMessage={projectId && !teams.isLoading && !teams.entities.length
                                ? 'No teams'
                                : undefined}>
                        </ComboBox>
                </ConnectionField>
                { teams.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -24, width: 30, position: 'absolute' }} />}
            </OverlayComponent>
            <div className="with-top-margin spinner-container">
                <Label>
                    <FieldLabel 
                        label="Parent Work Item" 
                        title={ `To link the PPM Express ${props.connectionType} to one or several work items instead of the whole Azure DevOps project,
                            select the work items in the field` } />
                </Label>
                <OverlayComponent isOverlayed={!projectId}>
                    <DebouncedOptionsPicker
                        inputProps={{ 
                            placeholder: selectedWorkItems.length === 0 
                                ? !isLinked
                                    ? "Search work item by Name or ID" 
                                    : "Not selected" 
                                : undefined 
                        }}
                        pickerSuggestionsProps={{ 
                            suggestionsClassName: "options-picker-container",
                            noResultsFoundText: "No options",
                            loadingText: 'Loading'
                        }}
                        selectedItems={selectedWorkItems}
                        className={disabled || !projectId || workItemTypes.isLoading ? "disabled-picker" : undefined}
                        disabled={disabled || !projectId || workItemTypes.isLoading}
                        onChange={(value?: Option[]) => setSelectedWorkItems(value || [])}
                        onResolveSuggestions={onResolveSuggestions} />
                    {workItemTypes.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                </OverlayComponent>
            </div>
            <OverlayComponent isOverlayed={!projectId}>
                <ConnectionField
                    className="with-top-margin"
                    isLinked={isLinked}
                    value={sourceInfo?.sourceData.areaPath}
                    label="Area">
                        <ComboBox
                            disabled={disabled || !projectId || areas.isLoading || !areas.entities.length}
                            placeholder="Select area"
                            allowFreeform
                            useComboBoxAsMenuWidth
                            selectedKey={areaPath}
                            options={areasOptionsMap}
                            onRenderItem={onRenderItem}
                            onChange={(e, _) => setAreaPath(_ ? (_.key as string) : undefined)}
                            errorMessage={projectId && !areas.isLoading && !areas.entities.length
                                ? 'No areas'
                                : undefined}>
                        </ComboBox>
                </ConnectionField>
                { areas.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -24, width: 30, position: 'absolute' }} />}
                <ConnectionField
                    className="with-top-margin"
                    isLinked={isLinked}
                    value={sourceInfo?.sourceData.iterationPath}
                    label="Iteration">
                        <ComboBox
                            disabled={disabled || !projectId || iterations.isLoading || !iterations.entities.length}
                            placeholder="Select iteration"
                            allowFreeform
                            useComboBoxAsMenuWidth
                            selectedKey={iterationPath}
                            options={iterationsOptionsMap}
                            onRenderItem={onRenderItem}
                            onChange={(e, _) => setIterationPath(_ ? (_.key as string) : undefined)}
                            errorMessage={projectId && !iterations.isLoading && !iterations.entities.length
                                ? 'No iterations'
                                : undefined}>
                        </ComboBox>
                </ConnectionField>
                { iterations.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -24, width: 30, position: 'absolute' }} />}
            </OverlayComponent>
            { connectionType !== VSTSConnectionType.Program &&
                <div key="configure-connection-container" className="flex-space-between with-top-margin">
                    <ActionButton 
                        disabled={!isValidConfiguration || connectionValidationInfo?.isValidating !== false || !!connectionValidationInfo?.validationError}
                        text="Configure connection"
                        iconProps={{ iconName: "PPMXGear" }}
                        onClick={() => setIsConfigureConnection(true)} />
                </div>
            }
            {
                !disableConfigureConnection &&
                isConfigureConnection &&
                <VSTSConfigureConnectionPanel
                    connectionId={connectionId!}
                    projectId={projectId!}
                    enableMapping={selectedWorkItems.length === 1}
                    onDismiss={() => setIsConfigureConnection(false)} />
            }
            <OverlayComponent isOverlayed={!connectionId && !projectId}>
                {
                    !isLinked && <PrimaryButton text={`Link project ${isAnyProjectPartSelected() ? "part" : ""}`}
                        disabled={readonly || !projectId || !allowMultipleConnectionsToOneProject && isProjectAlreadyLinked && !isAnyProjectPartSelected()}
                        className="with-top-margin"
                        onClick={() => {
                            if (showSwitchPrimaryDialog) {
                                setShowSwitchPrimaryScheduleConfirm(true);
                                return;
                            }
                            linkToProject(props, connectionId!, projectId!, teamId, areaPath, iterationPath, selectedWorkItems, props.setAsPrimary);
                        }} />
                }
                {
                    isLinked && <PrimaryButton text="Delete project link"
                        className="with-top-margin"
                        disabled={readonly || sourceInfo?.syncStatus.inProgress}
                        title={!!sourceInfo?.syncStatus.inProgress ? "Sync in progress." : undefined}
                        onClick={() => deleteLink(props, connectionId!)} />
                }
                {showSwitchPrimaryScheduleConfirm && <SwitchPrimaryScheduleConfirmation
                    from={SourceType.Ppmx} to={SourceType.VSTS}
                    onDismiss={() => setShowSwitchPrimaryScheduleConfirm(false)}
                    onClick={(isPrimary) => linkToProject(props, connectionId!, projectId!, teamId, areaPath, iterationPath, selectedWorkItems, isPrimary)} />
                }
            </OverlayComponent>
        </div>
    </>
}

function convertToArray(entity: IWorkItemClassificationNode, areasList: WorkItemClassificationEntity[], rootIndent?: number, rootPath?: string) {
    const path = rootPath ? `${rootPath}\\${entity.name}` : entity.name;
    const indent = rootIndent !== undefined ? rootIndent + 1 : 0;
    areasList.push({
        indent,
        identifier: entity.identifier,
        name: entity.name,
        order: 0,
        path
    });
    if (entity.hasChildren) {
        entity.children && entity.children.forEach(_ => convertToArray(_, areasList, indent, path));
    }
}

function mapStateToProps(state: ApplicationState) {
    const areas: WorkItemClassificationEntity[] = [];
    state.vsts.areas.entity && convertToArray(state.vsts.areas.entity, areas);

    const iterations: WorkItemClassificationEntity[] = [];
    state.vsts.iterations.entity && convertToArray(state.vsts.iterations.entity, iterations);

    return {
        projects: state.vsts.projects,
        teams: state.vsts.teams,
        areas: { error: state.vsts.areas.error, isLoading: state.vsts.areas.isLoading, entities: areas },
        iterations: { error: state.vsts.iterations.error, isLoading: state.vsts.iterations.isLoading, entities: iterations },
        workItemTypes: state.vsts.workItemTypes,
        connections: state.vsts.connections
    };
}

export default connect(mapStateToProps, actionCreators)(VSTSConnectControl);