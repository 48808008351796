import { Field } from "./Metadata";
import { CategoryOption, StageCategory, stageCategoryMap } from "./common";

export type IStageDropDownSettings = {
    options: CategoryStageOption[];
}

export type CategoryStageOption = CategoryOption<StageCategory>;

export default class StageDescriptor {
    private readonly _stageField: Field;

    constructor(stageField: Field) {
        this._stageField = stageField;
    }

    public getCategoryDefaultOption(category: StageCategory): CategoryStageOption {
        const options = this.getOptions();
        return options?.find(_ => _.category === category)!;
    }

    public getCategoryDefaultStageValue(category: StageCategory): string {
        return this.getCategoryDefaultOption(category).name;
    }

    public getCategoryOrDefault(stageValue: string, defaultCategory: StageCategory): StageCategory {
        return this.getCategory(stageValue) ?? defaultCategory;
    }

    public getColorOrDefault(stageValue: string, defaultColorCategory: StageCategory): string {
        return this.getColor(stageValue) ?? stageCategoryMap[defaultColorCategory].color;
    }

    public isInCategory(stageValue: string, category: StageCategory): boolean {
        return this.isInOneOfCategories(stageValue, category);
    }

    public isInOneOfCategories(stageValue: string, ...categories: StageCategory[]): boolean {
        const option = this.getOption(stageValue);
        return option !== undefined && categories.includes(option.category);
    }

    public getOptionOrDefault(stageValue: string, defaultOptionCategory: StageCategory): CategoryStageOption {
        return this.getOption(stageValue) ?? this.getCategoryDefaultOption(defaultOptionCategory)!;
    }

    public getOption(stageValue: string): CategoryStageOption | undefined {
        const options = this.getOptions();
        return options?.find(_ => _.name === stageValue);
    }

    public getOptions(): CategoryStageOption[] {
        const stageSettings = this._stageField.settings as IStageDropDownSettings;
        return stageSettings.options;
    }

    public getCategoryOptions(category: StageCategory): CategoryStageOption[] {
        return this.getOptions().filter(_ => _.category === category);
    }

    private getCategory(stageValue: string): StageCategory | undefined {
        const option = this.getOption(stageValue);
        return option?.category;
    }

    private getColor(stageValue: string): string | undefined {
        const option = this.getOption(stageValue);
        return option?.color;
    }
}
