import * as React from "react";
import './ThemePicker.css';
import ColorRow, { ColorRowType } from './ColorRow';

type Props = {
    themeId: string;
    rows: ColorRowType[];
    onThemeChanged: (id: string) => void;
};

const ThemePicker = ({ themeId, rows, onThemeChanged: onColorSelected }: Props) => {
    const hasTitles = rows.some(row => row.title);

    return (
        <div className={`theme-picker ${hasTitles ? 'has-titles' : ''}`}>
            {rows.map((row, rowIndex) => (
                <ColorRow
                    key={rowIndex}
                    themeId={themeId}
                    row={row}
                    onColorClick={onColorSelected}
                    hasTitles={hasTitles}
                />
            ))}
        </div>
    );
};

export default ThemePicker;
