import * as React from "react";
import { CommandBarButton, DirectionalHint, IButton, IButtonProps, IContextualMenuItem } from "office-ui-fabric-react";

type DraggableCommandBarButtonProps = IButtonProps & {
    dragHandleProps?: any;
    isDragging?: any;
    isSelected?: any;
    text: string;
    commands?: IContextualMenuItem[];
}

export const DraggableCommandBarButton = (props: DraggableCommandBarButtonProps) => {
    const { dragHandleProps, isSelected, text, commands, isDragging, ...otherProps } = props;

    const menuProps = isSelected && !!commands?.length ? {
        items: commands,
        directionalHint: DirectionalHint.bottomRightEdge
    } : undefined;

    const componentRef = React.createRef<IButton>();
    React.useEffect(() => {
        componentRef.current?.dismissMenu();
    }, [isDragging])

    return <CommandBarButton
        {...otherProps}
        componentRef={componentRef}
        onRenderText={() => <div key="text" className="draggable-tab-text" title={text}>{text}</div>}
        className={`draggable-tab-control ${isSelected ? "selected" : ''} ${!dragHandleProps ? "no-dnd" : ''} ${!menuProps ? "no-menu" : ''}`}
        iconProps={{ iconName: 'PPMXDragHandle', className: 'draggable-tab-handle', ...dragHandleProps }}
        menuIconProps={{ iconName: 'MoreVertical', className: "draggable-tab-menu" }}
        menuProps={menuProps}
    />;
}