import * as React from 'react';
import EntityFieldInputElement from './EntityFieldInputElement';
import { FieldInputElementProps } from '../interfaces/IInputProps';
import { PPMFeatures, Subscription } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';

type OwnProps = FieldInputElementProps;
type StateProps = { subscription: Subscription; }
type Props = OwnProps & StateProps;

const ProgramFieldInputElement = (props: Props) => {
    const available = Subscription.contains(props.subscription, PPMFeatures.PortfolioManagement);
    return <EntityFieldInputElement
        {...props}
        readOnly={props.readOnly || !available}
        withNavigation={available}
    />;
}

const mapStateToProps = (state: ApplicationState) => ({ subscription: state.tenant.subscription });
export default connect(mapStateToProps)(ProgramFieldInputElement);