import * as React from 'react';
import { Dictionary, EntityType } from '../../../entities/common';
import { ExtensionInfo } from '../../../store/ExtensionStore';
import { EmbeddedContent } from '../EmbeddedContent';

export type ExtensionViewerProps = {
    entityType: EntityType;
    info: ExtensionInfo;
    context: Dictionary<any>;
    ///will be removed
    urlParams?: Dictionary<string>;
};

export const ExtensionViewer = (props: ExtensionViewerProps) => {
    const urlStr = props.info.url;
    if (!urlStr) {
        return <div className="error-message">Extension is not configured well for {props.entityType}</div>;
    }
    const url = new URL(urlStr);
    if (props.urlParams) {
        for (const key in props.urlParams) {
            if (props.urlParams.hasOwnProperty(key)) {
                url.searchParams.set(key, props.urlParams[key]);
            }
        }
    }

    return <EmbeddedContent url={url} context={props.context} />;
};
