import "./AzureADSyncSettings.css";
import * as React from 'react';
import { Dropdown, IDropdownOption, MessageBar, MessageBarType, ActionButton, Link } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { UserState } from '../../../../store/User';
import { CommonOperations, contains } from '../../../../store/permissions';
import { Integrations, actionCreators, AzureADSyncSettings as AzureADSyncSettingsType } from '../../../../store/Tenant';
import { ApplicationState } from '../../../../store';
import TimeSelector from '../../../common/TimeSelector';
import DayOfWeekSelector from '../DayOfWeekSelector';
import LabellableComponent from '../../../common/LabellableComponent';
import { FormatDateTime } from '../../../utils/common';
import MappingPanel from '../../../integration/MappingPanel';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import { EntityType } from '../../../../entities/common';
import { ExternalFieldInfo, MappingType } from '../../../../store/integration/common';
import { o365FieldToPpmxFieldsMap, OrganizationInfo } from '../../../../store/integration/Office365Store';
import { actionCreators as Office365StoreActionCreators } from "../../../../store/integration/Office365Store";
import ImportPanel, { supported } from "../../../import/resource/ImportPanel";

type OwnProps = {};
type StateProps = {
    user: UserState;
    adSyncSettings: AzureADSyncSettingsType;
    adFields: ExternalFieldInfo[];
    organizations: OrganizationInfo[];
    isLoading: boolean;
    error: string | null;
    integrations: Integrations;
}

type Props = StateProps & OwnProps & {
    tenantActionCreators: typeof actionCreators;
    office365StoreActionCreators: typeof Office365StoreActionCreators;
};
const AzureADSyncSettings = (props: Props) => {
    const [state, setState] = React.useState(props.adSyncSettings!);
    React.useEffect(() => { setState(props.adSyncSettings) }, [props.adSyncSettings]);
    const { organization, lastSyncTime, lastSyncErrorMessage, schedule, mapping } = state;
    const { isLoading, organizations, user, error, adFields } = props;

    const saveSettings = React.useCallback((settings) => {
        setState(settings);
        props.tenantActionCreators.updateADSyncSettings(settings.schedule, settings.mapping, settings.organization)
    }, []);
    const onChangeDayOfWeek = React.useCallback(days => saveSettings({ ...state, schedule: { ...state.schedule, days } }), [organization, mapping, schedule]);
    const onChangeTime = React.useCallback(times => saveSettings({ ...state, schedule: { ...state.schedule, times } }), [organization, mapping, schedule]);
    const onChangeOrganization = React.useCallback(_ => saveSettings({ ...state, organization: _ }), [organization, mapping, schedule]);
    const onChangeMapping = React.useCallback(_ => saveSettings({ ...state, mapping: _ }), [organization, mapping, schedule]);

    const [configureMapping, setConfigureMapping] = React.useState<boolean>();
    const toggleConfigureMapping = React.useCallback(() => setConfigureMapping(!configureMapping), [configureMapping]);
    const runADSync = React.useCallback(() => {
        props.tenantActionCreators.runADSync();
        setSyncRunned(true);
    }, []);
    const [syncRunned, setSyncRunned] = React.useState<boolean>();

    const integrations = props.integrations.getAvailable(supported);
    const [importResources, setImportResources] = React.useState<boolean>();
    const toggleImportResources = React.useCallback(() => setImportResources(!importResources), [importResources]);

    const canManage = React.useMemo(() => contains(user.permissions.common, CommonOperations.ScheduleManage), [user.permissions.common]);
    const noOrganizationsAvailable = React.useMemo(() => !organizations?.length, [organizations]);
    const organizationNotFound = !organizations?.find(_ => _.name === organization?.name);
    const isSectionDisabled = noOrganizationsAvailable || !canManage;

    const organizationOptions = React.useMemo(() => {
        const options = organizations.map((_) => ({ key: _.name, text: _.name } as IDropdownOption));
        if (organization?.name && !options.find(_ => _.key === organization.name)) {
            options.push({ key: organization.name, text: `${organization.name} <Connection not found>`, disabled: true })
        }
        return options;
    }, [organizations]);

    React.useEffect(() => {
        props.tenantActionCreators.loadADSyncSettings();
        props.office365StoreActionCreators.loadFields();
        props.office365StoreActionCreators.loadOrganizations();
    }, []);

    return <div className="sync-settings settings-fields-holder ad-sync-settings">
        {!isLoading && noOrganizationsAvailable && <MessageBar isMultiline={true} messageBarType={MessageBarType.warning}>
                To start data synchronization from Azure Active Directory to PPM Express Resources page,
                please<Link href="https://help.ppm.express/89413-ppm-express-connections/522445-office365-group-connection" target="_blank">add</Link> an
                Office 365 connection and <Link onClick={toggleImportResources}>import resources</Link>
            </MessageBar>}
        {importResources && <ImportPanel integrations={integrations} onDismiss={() => {
            toggleImportResources();
            props.office365StoreActionCreators.loadOrganizations();
        }} />}
        <Dropdown
            label="Organization"
            options={organizationOptions}
            disabled={isLoading || isSectionDisabled}
            selectedKey={organization?.name}
            onChange={(e, option) => option && onChangeOrganization(props.organizations.find(_ => _.name === option.key))}
        ></Dropdown>
        <ActionButton disabled={isSectionDisabled || !organization || organizationNotFound} iconProps={{ iconName: "Settings" }} onClick={toggleConfigureMapping}>
            Configure fields mapping
        </ActionButton>
        <TimeSelector
            disabled={isSectionDisabled}
            label="Daily synchronization schedule"
            times={schedule.times}
            onChange={onChangeTime}
        />
        <DayOfWeekSelector readonly={isSectionDisabled || !canManage} value={schedule.days} onChange={onChangeDayOfWeek} />
        <LabellableComponent className={`field-container ${isSectionDisabled ? "readonly" : ""}`} label="Last Sync">
            {FormatDateTime(lastSyncTime, true) || "-"}
            {lastSyncErrorMessage && <MessageBar messageBarType={MessageBarType.error}>{lastSyncErrorMessage}</MessageBar>}
        </LabellableComponent>
        <ActionButton disabled={isSectionDisabled || !organization || organizationNotFound || syncRunned} iconProps={{ iconName: "Sync" }} onClick={runADSync}>
            Synchronize now
        </ActionButton>
        {configureMapping && <MappingPanel
            onRenderHeader={() => <div className="ms-Panel-header">
                <p className="ms-Panel-headerText">Configure fields mapping</p>
                <div className='ms-Panel-secondaryText'>Set up fields for the data exchange from Azure Active Directory (Office 365) to <br />PPM Express resources.</div>
            </div>}
            isLoading={isLoading}
            error={error}
            onDismiss={toggleConfigureMapping}
            connector={SourceType.O365User}
            entityType={EntityType.Resource}
            externalFields={adFields}
            typesMap={o365FieldToPpmxFieldsMap}
            mapping={mapping}
            showTransformationScript={true}
            updateMapping={_ => {
                onChangeMapping(_);
                toggleConfigureMapping();
            }}
            mappingTypes={[MappingType.ToPpmx]}
        />}
    </div>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        adSyncSettings: state.tenant.adSyncSettings!,
        adFields: state.office365.fields.entities,
        organizations: state.office365.organizations.entities,
        isLoading: state.tenant.isLoading || state.office365.fields.isLoading || state.office365.organizations.isLoading,
        error: state.office365.fields.error,
        integrations: new Integrations(state.tenant.subscription.integrations)
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        tenantActionCreators: bindActionCreators(actionCreators, dispatch),
        office365StoreActionCreators: bindActionCreators(Office365StoreActionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(AzureADSyncSettings);