import React from "react";
import "./EmptyGroupMessage.css";

type Props = {
    message: string | undefined;
    className?: string;
};

const EmptyGroupMessage = ({ message, className }: Props) => <span className={`empty-group-message ${className}`}>{message}</span>;

export default EmptyGroupMessage;
