import * as React from 'react';
import { Spinner as FabricSpinner, Overlay } from 'office-ui-fabric-react';
import './Spinner.css'

const Spinner = (props: {text?: string}) => 
    <div className='spinner-wrapper'>
        <div className="spinner"></div>
        {props.text && <div className="text">{props.text}</div>}
    </div>
export default Spinner;

export class ControlSpiner extends React.Component<{ isLoading: boolean, title?: string, className?: string }>{
    public render() {
        const { isLoading, title, className } = this.props;
        return <div className={`control-spinner ${isLoading ? "is-loading" : ""} ${className}`}>
            <div className="control-spinner-body">{this.props.children}</div>
            {isLoading && <FabricSpinner label={`${title ? title : "Loading"}...`} labelPosition="right" />}
        </div>
    }
}

export const DetailsSpinner = (props: React.PropsWithChildren<{ isLoading: boolean }>) => 
    <div className="details-spinner">
        {props.children}        
        {props.isLoading && <Overlay><Spinner key="spinner" /></Overlay>}
    </div>;
