import React from 'react';
import { Program, programStagesMap } from '../../store/ProgramsListStore';
import { ProjectInfo } from '../../store/ProjectsListStore';
import { IRow } from "../common/timeline/TimelineList";
import { ITimelineSegment, Visibility } from '../common/timeline/TimelineSegment';
import { ITimelineMarker } from '../common/timeline/TimelineMarker';
import * as ProjectsTimeline from '../project/timeline';
import { min, minMax } from '../common/timeline/utils';
import { StatusCategoryClassMap } from '../../entities/Subentities';
import Logo from '../common/Logo';
import ProgramTooltipContent from "./ProgramTooltipContent";
import { notEmpty, toDate } from '../utils/common';
import { StatusCategory, EntityType, statusCategoryMap } from '../../entities/common';
import { KeyDateTypeToMarkerTypeMap } from '../keyDate/timeline';
import { Field } from '../../entities/Metadata';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

export const programRowType = 'program';

export function renderSegmentContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    return row.rowType === programRowType && segment.addContent
        ? <>
            <Logo imageId={(row.entity as Program).imageId} className="prog-logo" />
            <div className="subitems-counter">{row.subItems?.length ?? 0} <span className="extra">project{row.subItems?.length !== 1 ? 's' : ''}</span></div>
        </>
        : undefined;
}

export function renderSegmentTooltipContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    return row.rowType === programRowType
        ? <ProgramTooltipContent program={row.entity as Program} />
        : ProjectsTimeline.renderSegmentTooltipContent(row, segment);
}

export function buildTimelineItem(
    program: Program,
    projects: ProjectInfo[],
    programFields: Field[],
    projectFields: Field[],
    keyDateFields: Field[],
    datesVisibility?: Visibility
): IRow {
    const pfProjects = program.projectIds
        .map(projectId => projects.find(_ => _.id === projectId))
        .filter(notEmpty)
        .map(_ => ProjectsTimeline.buildTimelineItem(_, projectFields, keyDateFields, datesVisibility));

    const keyDateStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.KeyDate, keyDateFields)!;
    const pfMarkers = program.keyDates
        .filter(__ => __.attributes.ShowOnTimeline)
        .map<ITimelineMarker>(__ => {
            const statusCategory = keyDateStatusDescriptor.getCategoryOrDefault(__.attributes.Status, StatusCategory.NA);
            return ({
                key: __.id,
                date: toDate(__.attributes.Date)?.getBeginOfDay()!,
                className: StatusCategoryClassMap[statusCategory],
                markerType: KeyDateTypeToMarkerTypeMap[__.attributes.Type]
            });
        });

    const startDate = toDate(program.attributes.StartDate);
    const finishDate = toDate(program.attributes.FinishDate);

    const timeframe = ProjectsTimeline.getMinimalTimeframe(startDate, finishDate);
    const allDates = [
        timeframe.start,
        timeframe.end,
        ...pfMarkers.map(_ => _.date),
        ...pfProjects.map(_ => _.segments.map(__ => [__.startDate, __.finishDate]).reduce((a, b) => a.concat(b), [])).reduce((a, b) => a.concat(b), [])];
    const { minDate, maxDate } = minMax(allDates);

    let pfSegments: ITimelineSegment[] = [];
    const className = getProgramClassName(program, programFields);
    if (minDate !== startDate || maxDate !== finishDate) {
        pfSegments = [...pfSegments, {
            key: 'min-to-max',
            startDate: minDate?.getBeginOfDay()!,
            finishDate: maxDate?.getEndOfDay()!,
            className: `min-to-max ${className}`
        }];
    }

    if (startDate && finishDate) {
        pfSegments = [...pfSegments, {
            key: 'start-to-finish',
            startDate: startDate.getBeginOfDay()!,
            finishDate: finishDate.getEndOfDay()!,
            className: `start-to-finish ${className}`,
            datesVisibility: datesVisibility
        }];
        const today = new Date();
        if (startDate < today) {
            pfSegments = [...pfSegments, {
                key: 'start-to-progress',
                startDate: startDate.getBeginOfDay(),
                finishDate: min([finishDate, new Date()])?.getEndOfDay()!,
                className: `start-to-progress ${className}`
            }];
        }
    }

    pfSegments[pfSegments.length - 1].addContent = true;

    return {
        key: program.id,
        entity: program,
        segments: pfSegments,
        markers: pfMarkers,
        subItems: pfProjects.length > 0 ? pfProjects : undefined,
        rowType: programRowType
    };
}

function getProgramClassName(program: Program, programFields: Field[]): string | undefined {
    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Program, programFields)!;
    const statusOption = statusDescriptor.getOptionOrDefault(program.attributes.OverallStatus, StatusCategory.NA);
    const status = statusCategoryMap[statusOption.category];
    const stage = programStagesMap[program.attributes.Stage];
    return `program ${stage ? stage.cssClassName : ''} ${status ? status.cssClassName : ''}`
}