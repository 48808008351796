import * as React from 'react';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { ItemCreation, ICommand, IHeader } from "../../common/ItemCreation";
import { getLabel } from "../../../entities/Metadata";
import { LayoutsState, actionCreators } from "../../../store/layouts";
import { Validator } from "../../../validation";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { EntityType, entityTypeLabelMap } from '../../../entities/common';
import { bindActionCreators } from 'redux';
import { LayoutService } from '../../utils/LayoutService';
import LabellableComponent from '../LabellableComponent';
import { notUndefined } from '../../utils/common';
import * as analytics from '../../../analytics';
import { UserState } from '../../../store/User';

interface OwnProps {
    entityType: EntityType;
    entityTypeLabel?: string;
    onDismiss: () => void;
    onSave: (name: string, layoutId: string) => void;
    hideSectionSelector?: boolean;
    header?: Partial<IHeader>;
}

type StateProps = {
    user: UserState;
    layouts: LayoutsState;
};

type IProps = ReturnType<typeof actionCreators.forEntity> & StateProps & OwnProps;

const validators = {
    name: Validator.new().required().build()
}

const EntityCreation = (props: React.PropsWithChildren<IProps>) => {
    const [name, setName] = React.useState('');
    const [isDirty, setIsDirty] = React.useState(false);
    const [selectedLayoutId, setSelectedLayoutId] = React.useState(LayoutService.getDefault(props.layouts).id);
    const options = React.useMemo(() => props.layouts.allIds.map(_ => (props.layouts.byId[_].isView ? undefined : {
        key: _,
        text: props.layouts.byId[_].name
    })).filter(notUndefined), [props.layouts]);
    const entityTypeLabel = props.entityTypeLabel ?? entityTypeLabelMap[props.entityType].singular;

    const _onChangeLayout = React.useCallback((e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setIsDirty(true);
            setSelectedLayoutId(option.key as string);
        }
    }, [props.layouts, setIsDirty, setSelectedLayoutId]);

    const _onSave = React.useCallback(() => {
        const trimmed = name && name.trim();
        if (trimmed) {
            props.onSave(trimmed, selectedLayoutId);
            analytics.trackCreate(props.user, { itemType: props.entityType, itemTitle: name });

            props.onDismiss();
        }
    }, [props.onSave, props.onDismiss, name, selectedLayoutId]);

    const _getCommands = React.useCallback((): ICommand[] => {
        return [
            { primary: true, text: `Create ${entityTypeLabel}`, onClick: _onSave, disabled: !validators.name.isValid(name) },
            { text: "Cancel", onClick: props.onDismiss }
        ];
    }, [entityTypeLabel, _onSave, name, props.onDismiss]);

    return <ItemCreation
        onDismiss={props.onDismiss}
        isDirty={isDirty}
        commands={_getCommands()}
        header={{
            text: `Create ${entityTypeLabel}`,
            secondaryText: `Fill in the fields below to create new ${entityTypeLabel}`,
            nameEditorLabel: `${entityTypeLabel} Name`,
            onChanged: (newValue: string) => {
                setIsDirty(true);
                setName(newValue);
            },
            validator: validators.name,
            ...props.header
        }}>
        <div className="panel-area">
            {props.children}
            {!props.hideSectionSelector && <>
                <div className="grid-item">
                    <span>Select a layout that will be applied to this {entityTypeLabel}</span>
                    <LabellableComponent label='Layout' className="field-container">
                        <Dropdown
                            selectedKey={selectedLayoutId}
                            options={options}
                            onChange={_onChangeLayout} />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent label='Layout Sections' className="field-container">
                        <ul className='simple-list'>
                            {
                                props.layouts.byId[selectedLayoutId].sections
                                    .filter(_ => _.isSelected)
                                    .map(_ => <li className='item' key={_.id}>{getLabel(_)}</li>)
                            }
                        </ul>
                    </LabellableComponent>
                </div>
            </>}
        </div>
    </ItemCreation>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        user: state.user,
        layouts: state.layouts[ownProps.entityType]
    };
}
export default connect(mapStateToProps, (dispatch, ownProps) => bindActionCreators(actionCreators.forEntity(ownProps.entityType), dispatch))(EntityCreation);