import React from 'react';
import { Icon } from 'office-ui-fabric-react';
import "./NotFound.css";

const NotFound = () => {
    return (
        <div className='fields-list-not-found'>
            <div className='icon-container'>
                <Icon iconName='PPMXResultsNotFound' />
            </div>
            <div className='description'>
                Results not found
            </div>
        </div>
    );
};

export default NotFound;
