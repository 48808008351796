import * as React from "react";
import { connect } from 'react-redux';
import ActionCard from './ActionCard';
import { actionCreators } from '../../../store/RoadmapsListStore';
import { ApplicationState } from "../../../store";
import { EntityType } from "../../../entities/common";
import { Field } from '../../../entities/Metadata';
import { UserState } from '../../../store/User';
import PortfolioCreation from "../../portfolio/PortfolioCreation";
import ProgramCreation from "../../program/ProgramCreation";
import ProjectCreation from "../../project/ProjectCreation";
import { ProductType, Subscription } from "../../../store/Tenant";
import ChallengeCreation from "../../challenge/ChallengeCreation";

type OwnProps = {
    name: string;
    iconName: string;
    disabled: boolean;
    entityType: EntityType;
}

type StoreProps = {
    fields: Field[];
    user: UserState;
    subscription: Subscription;
};

type Props = OwnProps & StoreProps & typeof actionCreators;
const CreateEntityActionCard = (props: Props) => {
    const [currentEntityType, setCurrentEntityType] = React.useState<EntityType>();
    const onDismiss = React.useCallback(() => setCurrentEntityType(undefined), []);
    return <>
        <ActionCard
            iconName={props.iconName}
            name={props.name}
            disabled={props.disabled}
            action={() => setCurrentEntityType(props.entityType)}
        />
        {currentEntityType === EntityType.Challenge && <ChallengeCreation key="challenge-creation" onDismiss={onDismiss} openOnComplete />}
        {currentEntityType === EntityType.Portfolio && <PortfolioCreation key="portfolio-creation" onDismiss={onDismiss} openOnComplete />}
        {currentEntityType === EntityType.Program && <ProgramCreation key="program-creation" onDismiss={onDismiss} openOnComplete />}
        {currentEntityType === EntityType.Project && <ProjectCreation key="project-creation" onDismiss={onDismiss} openOnComplete
            showConfigureConnectionsOption={Subscription.hasSyncableIntegration(props.subscription)} />}
    </>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Roadmap];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]).filter(_ => !_.isReadonly),
        user: state.user,
        subscription: state.tenant.subscription
    };
}

export default connect(mapStateToProps, actionCreators)(CreateEntityActionCard);
