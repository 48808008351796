import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from "../../../store/integration/P4WStore";
import { ApplicationState } from "../../../store/index";
import { IConnectionsState } from "../../../store/integration/common";
import P4WConnectionEdit from './P4WConnectionEdit/P4WConnectionEdit';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import BasicAuthConnectionsList from "../BasicAuthConnectionsList";
import { IConnectionInfo } from '../../../entities/Metadata';
import PostMessageReceiver from '../PostMessageReceiver';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onSelect: (connection: IConnectionInfo) => void;
};

type StateProps = { connections: IConnectionsState; };
type Props = StateProps & typeof actionCreators & OwnProps;

const P4WConnectionsList = (props: Props) => {
    React.useEffect(() => {
        props.loadConnections();
    }, []);

    const _onGrantPermissionsClick = () => {
        const target = 'grantPermissionsDataReceived_P4W';
        const grantPermissionsUrl = '/api/integration/p4w/auth/adminConsent';
        PostMessageReceiver.openPopupWindow(grantPermissionsUrl, target)
    }

    return <BasicAuthConnectionsList
        disabled={props.connections.isLoading || !!props.disabled}
        sourceType={SourceType.P4W}
        connections={props.connections.data}
        onConnectionSelect={props.onSelect}
        onConnectionRemove={props.removeConnection}
        onConnectionRename={props.renameConnection}
        grantPermissionsText="Grant Permissions (requires Admin Consent)"
        onGrantPermissionsClick={_onGrantPermissionsClick}
        renderAdd={(onCreated: (connectionId: string) => void, onDismiss: () => void) =>
            <P4WConnectionEdit onCreated={onCreated} onDismiss={onDismiss} />}
        renderEdit={(connectionId: string, onDismiss: () => void) =>
            <P4WConnectionEdit connectionId={connectionId} onDismiss={onDismiss} />}
    />;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.p4w.connections };
}

export default connect(mapStateToProps, actionCreators)(P4WConnectionsList);
