import React from "react";
import { DetailsSpinner } from "../common/Spinner";
import { SectionsContainer } from "../common/sectionsControl/SectionsContainer";
import { actionCreators, DueDateGroup, Groups, MyWork, _buildFields } from "../../store/MyWorkStore";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { Dictionary } from "../../entities/common";
import { bindActionCreators } from "redux";
import * as WorkloadControl from "../common/sectionsControl/uiControls/WorkloadControl";
import { ViewTypeViews, buildViewTypeSelect } from "../common/ViewTypeSelect";
import { Field, Section } from "../../entities/Metadata";
import { arraysEqual } from "office-ui-fabric-react";
import { actionCreators as UserActionCreators } from '../../store/User';
import { IControlConfiguration } from "../common/interfaces/ISectionUIControlProps";
import EntityHeader from "../common/EntityHeader";
import * as ResourceWorkloadControl from "../common/sectionsControl/uiControls/ResourceWorkloadControl";

enum UIControls {
    Summary = "ResourceWorkloadControl",
    Workload = "WorkloadControl"
}

const summarySectionSettings: ResourceWorkloadControl.ISettings = {
    dueDate: DueDateGroup.ThisWeek,
    isMyAssignments: true
};

const workloadControlSettings: WorkloadControl.IWorkloadControlSettings = {
    defaultFilterName: "Active",
    defaultPreFilterKey: DueDateGroup.ThisWeek,
    defaultGroupping: Groups.DueDate,
    isMyAssignments: true
};

const MY_WORK_ID = '2ae6a911-9817-4be6-97c1-cdd8ca9ba145';

const sections: Section[] = [
    {
        id: "3ab7ab61-9810-480b-94e4-9b8447f16aa0",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "Summary",
        settings: { iconName: 'PPMXSectionSummary' },
        uiControls: [{
            id: "3f46ae14-fa4e-494a-8d99-8e69fc819e9b",
            type: UIControls.Summary,
            settings: summarySectionSettings
        }]
    },
    {
        id: "0155903c-e7b7-41c9-ad15-c70851e5d27b",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "My Assignments",
        settings: { "iconName": "AccountActivity" },
        uiControls: [{
            id: "237e624b-b929-477e-a71c-3946ba872adb",
            type: UIControls.Workload,
            settings: workloadControlSettings
        }]
    }
];

type StateProps = {
    work: MyWork[];
    isLoading: boolean;
}
type ActionProps = {
    actions: typeof actionCreators;
    userActions: typeof UserActionCreators;
}
type OwnProps = {}
type Props = StateProps & ActionProps & OwnProps;

type State = {
    fields: Field[];
}

class MyWorkload extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { fields: [] }
    }

    componentWillMount() {
        this.props.actions.load();
        this.props.userActions.loadResource();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (arraysEqual(this.props.work, nextProps.work)) {
            this.setState(_buildFields(nextProps.work));
        }
    }

    public render() {
        const { isLoading, work } = this.props;

        return <DetailsSpinner isLoading={isLoading}>
            <EntityHeader
                name="My Space"
                nameTitle="My Space"
                logo={<div className="logo myspace-logo"></div>}
            ></EntityHeader>
            <SectionsContainer
                hideSideBar
                entity={{
                    id: MY_WORK_ID,
                    sections: sections,
                    work: work
                } as any}
                controlsConfig={this._buildControlsConfigurations()}
            />
        </DetailsSpinner>;
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { fields } = this.state;
        return {
            [UIControls.Summary]: {
                datacontext: {},
                actions: {}
            },
            [UIControls.Workload]: {
                ...buildViewTypeSelect(ViewTypeViews.Workload),
                datacontext: { fields },
                actions: {
                    onMyWorkUpdated: (works: MyWork[]) => { this.props.actions.findBySourceId(works.map(_ => _.source.id)) }
                }
            }
        }
    }
}

interface IConfiguration extends Dictionary<IControlConfiguration> {
    [UIControls.Summary]: {};
    [UIControls.Workload]: WorkloadControl.IConfiguration;
}

function mapStateToProps(state: ApplicationState): StateProps {
    const myWork = state.myWork;
    return {
        work: myWork.allIds.map(_ => myWork.byId[_]),
        isLoading: myWork.isLoading
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        userActions: bindActionCreators(UserActionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(MyWorkload);