import { DefaultButton, TextField, DialogType, DialogFooter, Dialog, MessageBar, MessageBarType, Spinner } from 'office-ui-fabric-react';
import * as React from 'react';
import { catchApiError } from '../../../store/utils';
import { Validator } from '../../../validation';
import * as Notifications from '../../../store/NotificationsStore';
import { connect } from 'react-redux';
import { remove } from '../../../fetch-interceptor';
import * as analytics from '../../../analytics';

type OwnProps = {
    onDismiss: () => void;
}
type ActionProps = typeof Notifications.actionCreators
type Props = ActionProps & OwnProps;

const DELETE_TITLE = 'Delete Account';
const DELETE_PASSPHRASE = 'DELETE';
const validators = {
    deletePassphrase: Validator.new().required().equals(DELETE_PASSPHRASE).build(),
    deleteReason: Validator.new().required().build()
}

export const DeleteDialog = (props: Props) => {
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [deletePassphrase, setDeletePassphrase] = React.useState('');
    const [deleteReason, setDeleteReason] = React.useState('');

    const onUnsubscribe = React.useCallback(() => {
        remove('api/tenant/account')
            .then(_ => { window.location.href = '/login?signout'; })
            .catch(catchApiError(_ => {
                props.pushNotification({ message: _, type: Notifications.NotificationType.Error })
                setIsDeleting(false)
            }));
        setIsDeleting(true);
        analytics.track('Delete Account', { reason: deleteReason });
    }, [deleteReason]);

    return <Dialog
        maxWidth={500}
        hidden={false}
        dialogContentProps={{ type: DialogType.close, title: DELETE_TITLE }}
        modalProps={{
            isBlocking: isDeleting,
            containerClassName: 'ms-dialogMainOverride'
        }}
        onDismiss={props.onDismiss}>
        <div>Are you sure that you want to delete your account?</div>
        <MessageBar className='with-top-margin' messageBarType={MessageBarType.warning}>
            <div>Deleting your account will remove all users and data in your PPM Express tenant and cancel your current subscription. </div>
            <div>This action is irreversible</div>
        </MessageBar>
        <TextField
            className='with-top-margin'
            label={`Please type “${DELETE_PASSPHRASE}” to confirm`}
            onGetErrorMessage={validators.deletePassphrase.getErrorMessage}
            deferredValidationTime={1}
            value={deletePassphrase}
            onChange={(e, _) => setDeletePassphrase(_ || '')}
        />
        <TextField
            multiline={true}
            className='with-top-margin'
            label='Please enter a reason for deleting the account'
            onGetErrorMessage={validators.deleteReason.getErrorMessage}
            deferredValidationTime={1}
            value={deleteReason}
            onChange={(e, _) => setDeleteReason(_ || '')}
        />
        <DialogFooter>
            <DefaultButton
                primary={true}
                className='button-danger'
                disabled={!validators.deletePassphrase.isValid(deletePassphrase)
                    || !validators.deleteReason.isValid(deleteReason)}
                onClick={onUnsubscribe}>
                {
                    isDeleting
                        ? <>
                            <Spinner /> Processing...
                        </>
                        : DELETE_TITLE
                }
            </DefaultButton>
        </DialogFooter>
    </Dialog>
}

export default connect(undefined, Notifications.actionCreators)(DeleteDialog)