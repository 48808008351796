import * as React from "react";
import { IValidator, Validator } from "../../validation";
import { nameof } from "../../store/services/metadataService";
import * as Metadata from '../../entities/Metadata';
import { DisplayFieldService } from "../common/DisplayFieldService";
import DatePickerInput from '../common/inputs/DatePickerInput';
import { IWithBenefits, IWithStartFinishDates } from '../../entities/common';
import { IInputProps } from "../common/interfaces/IInputProps";

export const validators = {
    [nameof<IWithStartFinishDates>("StartDate")]: (state: { attributes: IWithStartFinishDates }, field: Metadata.Field): IValidator => {
        return field.isReadonly ? Validator.new().build() :
            DisplayFieldService.getValidatorBuilder(field).dateIsLessThenOrEqual(state.attributes.FinishDate).build();
    },
    [nameof<IWithStartFinishDates>("FinishDate")]: (state: { attributes: IWithStartFinishDates }, field: Metadata.Field): IValidator => {
        return field.isReadonly ? Validator.new().build() :
            DisplayFieldService.getValidatorBuilder(field).dateIsGreaterThenOrEqual(state.attributes.StartDate).build();
    }
}

export const rendersBuilder = () => ({
    [nameof<IWithStartFinishDates>("StartDate")]: (props: IInputProps, state: { attributes: IWithStartFinishDates }, field: Metadata.Field, validator?: IValidator): JSX.Element | null =>
        <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator} maxDate={state.attributes.FinishDate} />,
    [nameof<IWithStartFinishDates>("FinishDate")]: (props: IInputProps, state: { attributes: IWithStartFinishDates }, field: Metadata.Field, validator?: IValidator): JSX.Element | null =>
        <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator} minDate={state.attributes.StartDate} />
})

export const buildIWithBenefitsValidators = () => ({
    [nameof<IWithBenefits>("BenefitsStartDate")]: (state: { attributes: IWithBenefits }, field: Metadata.Field): IValidator => {
        return field.isReadonly ? Validator.new().build() :
            DisplayFieldService.getValidatorBuilder(field).dateIsLessThenOrEqual(state.attributes.BenefitsFinishDate).build();
    },
    [nameof<IWithBenefits>("BenefitsFinishDate")]: (state: { attributes: IWithBenefits }, field: Metadata.Field): IValidator => {
        return field.isReadonly ? Validator.new().build() :
            DisplayFieldService.getValidatorBuilder(field).dateIsGreaterThenOrEqual(state.attributes.BenefitsStartDate).build();
    }
});

export const buildIWithBenefitsCustomRenders = () => ({
    [nameof<IWithBenefits>("BenefitsStartDate")]: (props: IInputProps, state: { attributes: IWithBenefits }, field: Metadata.Field, validator?: IValidator): JSX.Element | null =>
        <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator} maxDate={state.attributes.BenefitsFinishDate} />,
    [nameof<IWithBenefits>("BenefitsFinishDate")]: (props: IInputProps, state: { attributes: IWithBenefits }, field: Metadata.Field, validator?: IValidator): JSX.Element | null =>
        <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={validator} minDate={state.attributes.BenefitsStartDate} />
})