import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import { actionCreators as UserActionCreators, UserState } from '../../store/User';

const PingInterval = 300000;

type ActionsProps = {
    userActions: typeof UserActionCreators;
}

type StoreProps = {
    user: UserState;
}

const KeepAliveUserToken = (props: ActionsProps & StoreProps) => {
    useEffect(() => {
        const timer = setInterval(() => {
            props.user.isSignedUp && props.userActions.ping();
        }, PingInterval);
        return () => clearInterval(timer);
    }, []);
    return null;
}

function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        user: state.user
    };
}

function mergeActionCreators(dispatch: any): ActionsProps {
    return {
        userActions: bindActionCreators(UserActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(KeepAliveUserToken);