import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { UserState } from '../store/User';
import { notNone } from '../store/permissions';
import { Redirect } from "react-router-dom";
import { PPMFeatures, Subscription, TenantState } from '../store/Tenant';

type HomeOwnProps = RouteComponentProps<{}>;
type HomeStateProps = {
    user: UserState;
    tenant: TenantState;
}

type HomeProps = HomeStateProps & HomeOwnProps;

class Home extends React.Component<HomeProps> {
    public render() {
        const { user, tenant } = this.props;
        const permissions = user.permissions;

        if (!user.preferences.dontShowQuickStartOnStartup) {
            return <Redirect to="/quickstart" />;
        } else if (Subscription.canViewInsights(tenant.subscription, user)) {
            return <Redirect to="/insights" />;
        } else if (Subscription.contains(tenant.subscription, PPMFeatures.PortfolioManagement) && notNone(permissions.portfolio)) {
            return <Redirect to="/portfolios" />;
        } else if (Subscription.contains(tenant.subscription, PPMFeatures.PortfolioManagement) && notNone(permissions.program)) {
            return <Redirect to="/programs" />;
        } else if (Subscription.contains(tenant.subscription, PPMFeatures.ProjectManagement) && notNone(permissions.project)) {
            return <Redirect to="/projects" />;
        } else if (Subscription.contains(tenant.subscription, PPMFeatures.Ideation) && notNone(permissions.challenge)) {
            return <Redirect to="/challenges" />;
        }

        return null;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: HomeOwnProps): HomeStateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, {})(Home);