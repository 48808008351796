import * as React from 'react';
import * as Metadata from "../../entities/Metadata";
import { IKeyDateAttrs, KeyDate } from '../../entities/Subentities';
import { Dictionary, EntityType } from '../../entities/common';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { nameof } from '../../store/services/metadataService';
import { Baseline, BaselineDateRow } from '../common/baseLine';

type OwnProps = {
    keyDate: KeyDate;
    variance: KeyDateVariance | undefined;
}
type StoreProps = {
    fieldsMap: Dictionary<Metadata.Field>;
}
type Props = OwnProps & StoreProps;

export type KeyDateVariance = {
    dateVariance?: number | null; 
}

const KeyDateBaseline = (props: Props) => {
    const { keyDate, variance, fieldsMap } = props;
    return <Baseline date={keyDate.baseline?.setDate}>
                <BaselineDateRow 
                    field={fieldsMap[nameof<IKeyDateAttrs>("Date")]}
                    date={keyDate.attributes.Date} 
                    baseline={keyDate.baseline?.date}
                    variance={variance?.dateVariance} />
           </Baseline>;
}
function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.KeyDate];
    return {
        fieldsMap: Metadata.toMap(fields.allIds.map(_ => fields.byId[_]))
    };
}

export default connect(mapStateToProps)(KeyDateBaseline);