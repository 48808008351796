import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import { insert, reorder, toDictionaryByKey } from "../utils/common";

const idKey = (item: IDraggableItem) => item.id;

export interface IDraggableItem {
    id: string;
}

export type GroupedDraggableItems<T extends IDraggableItem> = {
    group: string;
    items: T[];
};

type Props<T extends IDraggableItem> = {
    groupedItems: GroupedDraggableItems<T>[];
    itemClassName?: string;
    onItemRender: (item: T, group: string, index: number) => JSX.Element | (JSX.Element | null)[];
    onChanged?: (changedGroupedItems: GroupedDraggableItems<T>[], destinationGroup: string, changedPositionItem: T) => void;
    isItemDraggable?: (item: T, group: string, index: number) => boolean;
    getKey?: (item: T, group: string, index: number) => string | number;
    onGroupRender?: (groupedItems: GroupedDraggableItems<T>, onGroupedItemsRender: () => JSX.Element | JSX.Element[]) => JSX.Element;
    onEmptyGroupItemsRender?: (group: string) => JSX.Element;
}

const GroupedDraggableList = <T extends IDraggableItem>(props: Props<T>) => {
    const getKey = props.getKey || idKey;
    const groupedItemsMap = useMemo(() => toDictionaryByKey(props.groupedItems, 'group'), [props.groupedItems]);

    const arrangeGroupedItems = useCallback((source: DraggableLocation, destination: DraggableLocation) => {
        const sourceItems = groupedItemsMap[source.droppableId].items;

        if (source.droppableId === destination.droppableId) {
            return [{
                group: destination.droppableId,
                items: reorder(sourceItems, source.index, destination.index)
            }];
        }

        const destinationItems = groupedItemsMap[destination.droppableId].items;
        const item = sourceItems[source.index];
        return [{
            group: source.droppableId,
            items: sourceItems.filter((_, i) => i !== source.index),
        }, {
            group: destination.droppableId,
            items: insert(destinationItems, destination.index, item),
        }];
    }, [groupedItemsMap]);

    const onDragEnd = useCallback((result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        
        const changedGroupedItems = arrangeGroupedItems(result.source, result.destination);
        const sourceItems = groupedItemsMap[result.source.droppableId].items;
        const item = sourceItems[result.source.index];

        props.onChanged!(changedGroupedItems, result.destination.droppableId, item);

    }, [props.onChanged, arrangeGroupedItems, groupedItemsMap]);

    const renderGroupedItems = useCallback((groupedItems: GroupedDraggableItems<T>) => {
        return groupedItems.items.map((_, index) => {
            const isDragDisabled = !props.onChanged || props.isItemDraggable?.(_, groupedItems.group, index) === false;
            return <Draggable
                key={getKey(_, groupedItems.group, index)}
                draggableId={_.id}
                index={index}
                isDragDisabled={isDragDisabled}
            >
                {(provided, snapshot) => (
                    <li
                        className={"field" + (snapshot.isDragging ? ' dragging' : '') + (isDragDisabled ? ' drag-disabled' : '')}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                    >
                        <div className="align-center">
                            {props.onItemRender(_, groupedItems.group, index)}
                        </div>
                    </li>
                )}
            </Draggable>;
        });
    }, [props.onChanged, props.isItemDraggable, props.onItemRender]);

    const defaultRenderGroup = useCallback((groupedItems: GroupedDraggableItems<T>) => (
        <Droppable key={groupedItems.group} droppableId={groupedItems.group}>
            {(provided, snapshot) => {
                const renderEmptyGroup = groupedItems.items.length === 0 && props.onEmptyGroupItemsRender;
                return (
                    <ul
                        className={`cb-list ${props.itemClassName ?? (!renderEmptyGroup ? "with-dragndrop" : "")}` +
                            (!props.onChanged ? " dragndrop-disabled" : "") +
                            (snapshot.isDraggingOver ? " drag-over" : "") +
                            (renderEmptyGroup ? " empty-group" : "")}
                        ref={provided.innerRef}
                    >
                        {renderEmptyGroup && !snapshot.isDraggingOver
                            ? <li>{props.onEmptyGroupItemsRender!(groupedItems.group)}</li>
                            : renderGroupedItems(groupedItems)}
                        {provided.placeholder}
                    </ul>
                );
            }}
        </Droppable>
    ), [renderGroupedItems, props.onEmptyGroupItemsRender]);

    const renderGroup = useCallback((groupedItems: GroupedDraggableItems<T>) => props.onGroupRender
        ? props.onGroupRender(groupedItems, () => defaultRenderGroup(groupedItems))
        : defaultRenderGroup(groupedItems),
    [props.onGroupRender, defaultRenderGroup]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {props.groupedItems.map(renderGroup)}
        </DragDropContext>
    );
}

export default GroupedDraggableList;
