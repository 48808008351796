import { arraysEqual, IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { TenantState } from '../../store/Tenant';
import ReportsPlaceholder from './ReportsPlaceholder';
import { ReportNav } from '../utils/reporting';
import { Dictionary } from '../../entities/common';
import { inIframe } from '../utils/common';

export interface ISectionReports {
    reports: ReportNav[];
    onClick: (report: ReportNav) => void;
}

type OwnProps = {
    entity: Dictionary<any>;
    sectionName: string;
    items: IContextualMenuItem[];
    getMenuItems?: (entity: Dictionary<any>) => IContextualMenuItem[];
    reporting?: ISectionReports
}

type StateProps = { tenant: TenantState }
type Props = OwnProps & StateProps;
class SectionReports extends React.Component<Props, { items: IContextualMenuItem[] }> {
    constructor(props: Props) {
        super(props);
        this.state = { items: this._getItems(props) };
    }

    componentWillReceiveProps(nextProps: Props) {
        const items = this._getItems(nextProps);
        !arraysEqual(this.state.items, items) && this.setState({ items: items });
    }

    public render() {
        const { items } = this.state;
        return items.length
            ? <IconButton
                menuIconProps={{ iconName: 'More' }}
                className='more'
                text='Actions'
                menuProps={{ items: items, className: 'section-menu' }} />
            : null;
    }

    private _getItems(props: Props) {
        const { sectionName, entity, getMenuItems, reporting } = props;
        const items = props.items.concat(getMenuItems?.(entity) ?? []);

        //no reports when embedded into Teams
        if (!inIframe() && reporting && !entity.isPrivate) {
            const reportItems = reporting.reports
                .filter(_ => _.sections.indexOf(sectionName.toLowerCase()) !== -1)
                .map(_ => (
                    {
                        key: _.id,
                        name: _.title,
                        iconProps: { iconName: "FileSymlink" },
                        onClick: () => reporting.onClick(_)
                    }));

            items.push({
                key: 'reports-item',
                name: 'Reports',
                iconProps: { iconName: "FabricReportLibrary" },
                subMenuProps: reportItems?.length
                    ? {
                        items: reportItems
                    }
                    : {
                        items: [{ key: "" }],
                        onRenderMenuList: () => <ReportsPlaceholder />
                    }
            });
        }

        return items;
    }
}

function mapStateToProps(state: ApplicationState) {
    return { tenant: state.tenant }
}

export default connect(mapStateToProps, {})(SectionReports);