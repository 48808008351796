import "./Connections.css";
import * as React from "react";
import { actionCreators } from "../../../store/MetadataStore";
import AzureDevOpsConnectionCard from './AzureDevOpsConnectionCard';
import JiraConnectionCard from './JiraConnectionCard';
import SpoConnectionCard from './SpoConnectionCard';
import PlannerConnectionCard from './PlannerConnectionCard';
import { connect } from "react-redux";
import MondayComConnectionCard from "./MondayComConnectionCard";
import { ApplicationState } from "../../../store";
import { Integrations } from "../../../store/Tenant";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import SmartsheetConnectionCard from "./SmartsheetConnectionCard";
import MPPFileConnectionCard from "./MPPFileConnectionCard";
import P4WConnectionCard from "./P4WConnectionCard";

type StateProps = {
    integrations: Integrations;
};
type Props = StateProps & typeof actionCreators;
const Connections = (props: Props) => {
    React.useEffect(() => {
        props.loadConnections();
    }, []);
    return <div className="box connections">
        <h2>Create Connection and Import your Projects to PPM Express</h2>
        <div className="connections-list">
            {isEnabled(props, SourceType.VSTS) && <AzureDevOpsConnectionCard />}
            {isEnabled(props, SourceType.Jira) && <JiraConnectionCard />}
            {isEnabled(props, SourceType.O365Planner) && <PlannerConnectionCard />}
            {isEnabled(props, SourceType.Spo) && <SpoConnectionCard />}
            {isEnabled(props, SourceType.MondayCom) && <MondayComConnectionCard />}
            {isEnabled(props, SourceType.Smartsheet) && <SmartsheetConnectionCard />}
            {isEnabled(props, SourceType.MPPFile) && <MPPFileConnectionCard />}
            {isEnabled(props, SourceType.P4W) && <P4WConnectionCard />}
        </div>
    </div>
}
function isEnabled(props: StateProps, type: SourceType ){
    return props.integrations.isEnabled(type);
}
function mapStateToProps(state: ApplicationState): StateProps {
    return {
        integrations: new Integrations(state.tenant.subscription.integrations)
    }
}
export default connect(mapStateToProps, actionCreators)(Connections);