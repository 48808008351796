import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TenantState, ThemeType, actionCreators as tenantActionCreators } from "../../../../store/Tenant";
import { ApplicationState } from "../../../../store";
import ThemePicker, { ColorRow } from "../../ThemePicker";
import LabellableComponent from "../../../common/LabellableComponent";

type StateProps = {
    tenant: TenantState;
};

type ActionProps = {
    tenantActions: typeof tenantActionCreators;
};

type Props = StateProps & ActionProps;

const rows: ColorRow[] = [
    {
        options: [
            { id: ThemeType.Dark.toString(), title: "Dark", colors: { primary: "#1E1E1E", secondary: "var(--brand-green-color)" } },
            { id: ThemeType.Light.toString(), title: "Light", colors: { primary: "#0000000A", secondary: "var(--brand-green-color)" } },
            { id: ThemeType.Solid.toString(), title: "Solid", colors: { primary: "var(--brand-green-color)" } },
        ],
    },
];

const StyleSection = ({ tenant, tenantActions }: Props) => (
    <LabellableComponent className="field-container" label="Style">
        <span className="section-description">Select a style for your tenant.</span>
        <ThemePicker rows={rows} themeId={tenant.styleSettings.themeType.toString()} onThemeChanged={(themeId) => tenantActions.changeTheme(parseInt(themeId) as ThemeType)} />
    </LabellableComponent>
);

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        tenant: state.tenant,
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(StyleSection);
