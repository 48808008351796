import axios, { AxiosRequestConfig, CancelTokenSource, Method } from "axios";

let requestDefaults: AxiosRequestConfig = {
    baseURL: '/'
};

const NO_CONTENT_CODE: number = 204;

export function setupFetchDefaults(init?: Partial<AxiosRequestConfig>) {
    requestDefaults = { ...requestDefaults, ...init };
}

export function appendFetchHeaderDefaults(additionalHeaders?: Record<string, string>) {
    requestDefaults.headers = { ...requestDefaults.headers, ...additionalHeaders };
}

export type CancellablePromiseWrapper<T> = { promise: Promise<T>; cancelTokenSource: CancelTokenSource };
export type MaybeCancellablePromiseWrapper<T> = { promise: Promise<T>; cancelTokenSource?: CancelTokenSource };

export type CancellablePromiseMap<T> = {
    [id: string]: CancellablePromiseWrapper<T>;
}

const getList: CancellablePromiseMap<any> = {};
const postList: CancellablePromiseMap<any> = {};

export function get<T>(url: string, data?: any): Promise<T> {
    return cancellableGet<T>(url, data).promise;
}

export function cancellableGet<T>(url: string, data?: any): CancellablePromiseWrapper<T> {
    const source = axios.CancelToken.source();
    const request: AxiosRequestConfig = {
        ...requestDefaults,
        cancelToken: source.token
    }

    const queryParams = data && Object.getOwnPropertyNames(data)
        .filter(_ => data[_] !== undefined)
        .map(_ => `${_}=${encodeURIComponent(data[_])}`).join("&");
    const getUrl = `${url}${queryParams ? ("?" + queryParams) : ""}`;
    const promise = axios.get<T>(getUrl, request).then(_ => {
        delete getList[url];
        return _;
    }, err => {
        getList[url].promise === promise && delete getList[url];
        throw err;
    }).then(response => {
        return response.data;
    });

    if (getList[url]) {
        getList[url].cancelTokenSource.cancel();
    }

    const promiseWrapper: CancellablePromiseWrapper<T> = { promise, cancelTokenSource: source };

    getList[url] = promiseWrapper;

    return promiseWrapper;
}

export function post<T>(url: string, data: object): Promise<T> {
    const request: AxiosRequestConfig = {
        ...requestDefaults
    }

    return axios.post<T>(url, data, request).then(response => response.data);
}

export function cancellablePost<T>(url: string, data: object): CancellablePromiseWrapper<T> {
    const source = axios.CancelToken.source();
    const request: AxiosRequestConfig = {
        ...requestDefaults,
        cancelToken: source.token
    }

    const promise = axios.post<T>(url, data, request)
        .then(_ => _.data)
        .finally(() => { delete postList[url] });

    if (postList[url]) {
        postList[url].cancelTokenSource.cancel();
    }

    const promiseWrapper: CancellablePromiseWrapper<T> = { promise, cancelTokenSource: source };
    postList[url] = promiseWrapper;
    return promiseWrapper;
}

export function remove<T>(url: string, data?: object): Promise<T> {
    const request: AxiosRequestConfig = {
        ...requestDefaults,
        method: 'delete',
    }

    if (data) {
        request.headers = {
            ...request.headers,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        request.data = JSON.stringify(data);
    }

    return axios.delete(url, request).then(response => {
        if (response.status == NO_CONTENT_CODE) {
            return Promise.resolve() as any;
        }

        return response.data;
    });
}