import * as React from "react";
import { IConnectionsState, IImportResourceMap, validateConnection } from "../../../store/integration/common";
import { IImportEntity, IImportEntityMap } from "./common";
import ResourcesImportPanel from "./ResourcesImportPanel";
import { Resource } from "../../../store/ResourcesListStore";
import { SourceType, SourceTypeMap } from "../../../store/ExternalEpmConnectStore";
import { Dictionary } from "../../../entities/common";
import { IConnectionInfo } from "../../../entities/Metadata";
import * as analytics from '../../../analytics';
import { UserState } from "../../../store/User";
import { useDataLoadingAfterConnectionValidationEffect } from "../project/common";

export type ConverterProps<T> = {
    toEntity: (user: T) => IImportEntity;
    getMap: (users: T[]) => Dictionary<T>;
    getSourceId: (sourceData: any) => string;
}

export type OwnProps = {
    connection: IConnectionInfo;
    onBack: () => void;
    width: string;
    system: SourceType;
};

export type StateProps<T> = {
    user: UserState;
    users: T[];
    error: string | null,
    isLoading: boolean;
    isImporting: boolean;
    connections: IConnectionsState;
}

export type ActionProps<T> = {
    importResources: (connectionId: string, maps: IImportResourceMap<T>[]) => void;
    loadUsers: (connectionId: string) => void;
    verifyConnection: (connectionId: string) => void;
}

export type Props<T> = OwnProps & StateProps<T> & ActionProps<T> & ConverterProps<T>;

export const SystemResourceImportPanel = <T extends {}>(props: Props<T>) => {
    const connectionValidation = useDataLoadingAfterConnectionValidationEffect(
        props.connection.id, 
        props.connections, 
        props.isImporting, 
        props.verifyConnection,
        (_) => props.loadUsers(_)
    );

    const entities = React.useMemo(
        () => props.users.map<IImportEntity>(props.toEntity),
        [props.users, props.toEntity]);

    const onImport = React.useCallback((connectionId: string, maps: IImportEntityMap[]) => {
        const map = props.getMap(props.users);
        props.importResources(connectionId, maps.map(_ => ({
            linkingData: map[_.entityId],

            resourceId: _.resourceId,
            resourceName: _.resourceName
        })));
        analytics.trackImport(`'Import Resources from ${SourceTypeMap[props.system]}`, props.user, { count: maps.length });

    }, [props.users, props.importResources, props.getMap]);

    const getSourceInfos = React.useCallback((_: Resource) => _.sourceInfos.map(_ => ({
        sourceId: props.getSourceId(_.sourceData),
        connectionId: _.connectionId
    })), []);

    return <ResourcesImportPanel
        {...props}
        error={connectionValidation?.isValidating === false && !!connectionValidation.validationError ? connectionValidation.validationError : props.error}
        entities={entities}
        onImport={onImport}
        getSourceInfos={getSourceInfos}
    />;
}