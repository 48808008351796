import "./VideoBox.css";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ReactPlayer from "react-player";
import { Link, Modal } from "office-ui-fabric-react";
import previewImg from '../../../images/video-preview.png';

type OwnProps = {
    text: string,
    href?: string;
}

type Props = OwnProps & RouteComponentProps<{}>

const VideoBox = (props: Props) => {
    const [popup, setPopup] = React.useState<boolean>();
    const playerRef = React.useRef<ReactPlayer>(null);
    return <div className="box video-box">
        <div className="wrapper">
            <ReactPlayer url={props.href}
                className='react-player'
                width='100%'
                height='100%'
                light={previewImg}
                controls={true}
                playsinline={false}
                ref={playerRef}
                onClickPreview={(event: any) => {
                    if (playerRef?.current) {
                        playerRef.current.showPreview();
                    }
                    setPopup(true);
                }} />
        </div>
        <Link className="description" onClick={() => setPopup(true)}>{props.text}</Link>
        {popup && <Modal isOpen={true} onDismiss={() => setPopup(undefined)}>
            <ReactPlayer url={props.href}
                className='react-player'
                width='840px'
                height='480px'
                playing={true}
                controls={true} />
        </Modal>}
    </div>
}
export default withRouter<OwnProps>(VideoBox);