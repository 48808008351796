import React, { useState, useEffect } from 'react';
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxProps } from 'office-ui-fabric-react';
import { IFindProps, IFindResult } from "./EntityPickerInput";
import { post } from '../../../fetch-interceptor';
import { ControlSpiner } from '../Spinner';

export type ComboboxPickerProps = IFindProps &
{
    onEditComplete?: (find?: IFindResult) => void;
    selectedKey?: string | number;
    selectedText?: string;
    inputProps?: Partial<IComboBoxProps>;
};

export default function ComboboxPicker(props: ComboboxPickerProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedKey, setSelectedKey] = useState(props.selectedKey);
    const [options, setOptions] = useState<IComboBoxOption[]>(selectedKey && props.selectedText ? [{ key: selectedKey, text: props.selectedText }] : []);

    useEffect(() => {
        setIsLoaded(false);
        setIsLoading(false);
        setOptions(selectedKey && props.selectedText ? [{ key: selectedKey, text: props.selectedText }] : []);
        setSelectedKey(props.selectedKey);
    }, [props.searchUrl]);

    return <ComboBox
        {...props.inputProps}
        selectedKey={selectedKey}
        allowFreeform
        className='input'
        options={options}
        useComboBoxAsMenuWidth={true}
        onRenderList={(props, defaultRender) => {
            if (!isLoaded || isLoading) {
                return <ControlSpiner isLoading={true} />
            }
            if (defaultRender) {
                return defaultRender(props);
            }
            return null;
        }}
        onResolveOptions={(opts: IComboBoxOption[]): IComboBoxOption[] | PromiseLike<IComboBoxOption[]> => {
            if (!isLoading && !isLoaded) {
                setIsLoading(true);
                post<IFindResult[]>(props.searchUrl, { ...props.filter })
                    .then(_ => {
                        setOptions(_.map(__ => ({ key: __.id, text: __.name, data: __ } as IComboBoxOption)));
                        setIsLoading(false);
                        setIsLoaded(true);
                    });
            }
            return opts;
        }}
        onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
            setSelectedKey(option?.key);
            props.onEditComplete?.(option?.data as IFindResult);
        }}
    />;
}