import * as React from "react";
import { Persona, PersonaSize, Toggle, MessageBar, Panel, DefaultButton, PanelType, MessageBarType } from "office-ui-fabric-react";
import { LicenseType, LicenseTypeMap, ResourceOperations } from "../../store/permissions";
import { get, post } from "../../fetch-interceptor";
import { getPersonInfoImageUrl } from "../utils/common";
import { defaultCatch } from "../../store/utils";
import { connect } from "react-redux";
import Spinner from "./Spinner";
import { EntityType } from "../../entities/common";
import { Portfolio } from "../../store/PortfoliosListStore";
import { Program } from "../../store/ProgramsListStore";
import { ProjectInfo } from "../../store/ProjectsListStore";
import { SharedWith } from "./SharePanel/ShareUserList";
import { LayoutsState } from "../../store/layouts";
import { EntityViewSelector } from "./EntityViewSelector";
import { LayoutService } from "../utils/LayoutService";

type ActionProps = {
    defaultCatch: (error: any) => void;
};
type OwnProps = {
    entity: ProjectInfo | Portfolio | Program;
    entityType: EntityType;
    entityTypeLabel: string;
    userId: string;
    onDismiss: () => void;
    layouts: LayoutsState;
}
type Props = OwnProps & ActionProps;

type State = {
    user?: SharedWith;
    alreadyInTeam: boolean;
    isManager: boolean;
    isViewer: boolean;
    isLoading: boolean;
}

class AccessRequestConfirmationPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            alreadyInTeam: false,
            isManager: false,
            isViewer: false,
        }
    }

    componentDidMount() {
        get<SharedWith>(`/api/${this.props.entityType}/${this.props.entity.id}/share/${this.props.userId}`)
            .then(_ => {
                const alreadyInTeam = !!_.view.isAvailable || !!_.collaborate.isAvailable || !!_.edit.isAvailable;
                this.setState({
                    alreadyInTeam: alreadyInTeam,
                    user: alreadyInTeam ? _ : { ..._, view: { isAvailable: true }, collaborate: { isAvailable: true } },
                    isManager: !!this.props.entity.attributes.Manager.find(m => m.id === _.user.id),
                    isViewer: _.user.license === LicenseType.Viewer,
                    isLoading: false
                })
            })
            .catch(this.props.defaultCatch);
    }

    public render() {
        const { isLoading, alreadyInTeam, isManager } = this.state;

        return <Panel
            type={PanelType.custom}
            customWidth="400px"
            isOpen
            onRenderHeader={this._onRenderHeader}
            onRenderFooterContent={isLoading || alreadyInTeam || isManager ? undefined : this._onRenderFooterContent}
            onDismiss={this.props.onDismiss}>
            {
                isLoading
                    ? <Spinner />
                    : this._onRenderContent()
            }

        </Panel>;
    }

    private _onRenderHeader = (): JSX.Element | null => {
        const { entityTypeLabel, entity } = this.props;
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">{`${entityTypeLabel} \"${entity.attributes.Name}\" Access Request`}</p>
            <div className='ms-Panel-secondaryText'>{`You can grant a user the permission to View, Edit or Collaborate on the ${entityTypeLabel
                } (according to user's license).`}</div>
        </div>;
    }

    private _onRenderContent = (): JSX.Element | null => {
        const { user, alreadyInTeam, isManager, isViewer } = this.state;
        const { entityTypeLabel, entityType, layouts } = this.props;

        if (!user) {
            return null;
        }

        return <div className="panel-area">
            <div className="grid-item">
                <Persona size={PersonaSize.size72} imageUrl={getPersonInfoImageUrl(user.user)} text={user.user.fullName} title={user.user.fullName}
                    secondaryText={`Email: ${user.user.email || '-'}`}
                    tertiaryText={`Account: ${user.user.logonAccount}`} />
            </div>
            {(isManager || alreadyInTeam) && <div className="grid-item">
                <MessageBar messageBarType={MessageBarType.warning}>
                    {
                        !isManager && alreadyInTeam
                            ? `This user is already in Team, please use Share panel to manage permissions.`
                            : isViewer
                                ? `The user is a Manager of this ${entityTypeLabel}. Managers can view their ${entityTypeLabel}s by default.`
                                : `The user is a Manager of this ${entityTypeLabel}. ${entityTypeLabel
                                } Managers can view, edit and collaborate on their ${entityTypeLabel}s by default.`
                    }
                </MessageBar>
            </div>}
            <div className="grid-item">
                <EntityViewSelector
                    readOnly={isManager || alreadyInTeam}
                    layoutsState={layouts}
                    onChange={layout => {
                        this._onChange({ ...user, layoutId: layout?.id })
                    }}
                    selectedKey={user.layoutId}
                    entityLayout={{
                        key: LayoutService.DefaultLayoutId,
                        title: 'Overwrite profile view with default configuration',
                        text: 'Default'
                    }}
                    profileLayoutId={user.profileLayoutId} />
                <Toggle
                    label="View"
                    title={isManager
                        ? undefined
                        : user.view.isGlobal && !user.view.isInherited
                            ? `The user has View All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                            : user.view.isInherited
                                ? entityType === EntityType.Project
                                    ? `The project is included to the Portfolio this user has permissions to view. Inherited permissions cannot be changed.`
                                    : `Inherited permissions cannot be changed.`
                                : undefined
                    }
                    disabled={isManager || alreadyInTeam || user.view.isInherited || user.view.isGlobal || user.collaborate.isAvailable || user.edit.isAvailable}
                    checked={user.view.isAvailable || user.collaborate.isAvailable || user.edit.isAvailable}
                    onChange={(e, c) => this._onChange({ ...user, view: { ...user.view, isAvailable: c } })}
                />
                <Toggle
                    label="Collaborate"
                    title={isManager
                        ? undefined
                        : user.collaborate.isGlobal && !user.collaborate.isInherited
                            ? `The user has Collaborate on All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                            : user.view.isInherited
                                ? entityType === EntityType.Project
                                    ? `The project is included to the Portfolio this user has permissions to collaborate on. Inherited permissions cannot be changed.`
                                    : `Inherited permissions cannot be changed.`
                                : undefined
                    }
                    disabled={isManager || alreadyInTeam || user.collaborate.isInherited || user.collaborate.isGlobal || user.edit.isAvailable}
                    checked={user.collaborate.isAvailable || user.edit.isAvailable}
                    onChange={(e, c) => this._onChange({ ...user, collaborate: { ...user.collaborate, isAvailable: c } })}
                />
                <Toggle
                    label="Edit"
                    title={isViewer
                        ? `This user has '${LicenseTypeMap[user.user.license].label}' license type and cannot edit ${entityTypeLabel}s.`
                        : isManager
                            ? undefined
                            : user.edit.isGlobal && !user.edit.isInherited
                                ? `The user has Edit All ${entityTypeLabel}s permission. It is impossible to change permissions for such user.`
                                : user.edit.isInherited
                                    ? entityType === EntityType.Project
                                        ? `The project is included to the Portfolio this user has permissions to view and edit. Inherited permissions cannot be changed.`
                                        : `Inherited permissions cannot be changed.`
                                    : undefined}
                    disabled={isManager || alreadyInTeam || user.edit.isInherited || user.edit.isGlobal || isViewer}
                    checked={user.edit.isAvailable}
                    onChange={(e, c) => this._onChange({ ...user, edit: { ...user.edit, isAvailable: c } })}
                />
            </div>
        </div>;
    }

    private _onRenderFooterContent = (): JSX.Element | null => {
        const { user } = this.state;
        return <div className="commands">
            <DefaultButton
                onClick={this._onGrant}
                disabled={!user?.view.isAvailable && !user?.collaborate.isAvailable && !user?.edit.isAvailable}
                primary={true}
                text="Grant"
            />
            <DefaultButton
                onClick={this._onDeny}
                text="Deny"
            />
        </div>;
    }

    private _onChange = (user: SharedWith) => {
        this.setState({ user })
    }

    private _onGrant = () => {
        const { user } = this.state;

        if (!user) {
            return;
        }

        const operations = (!user.view.isInherited && user.view.isAvailable ? ResourceOperations.Read : ResourceOperations.None)
            | (!user.collaborate.isInherited && user.collaborate.isAvailable ? ResourceOperations.Collaborate : ResourceOperations.None)
            | (!user.edit.isInherited && user.edit.isAvailable ? ResourceOperations.Update : ResourceOperations.None);

        post(`/api/${this.props.entityType}/${this.props.entity.id}/share`,
            [{ userId: this.props.userId, operations, layoutId: user.layoutId, isAccessRequest: true }])
            .then(() => this.props.onDismiss())
            .catch(this.props.defaultCatch);
        this.props.onDismiss();
    }

    private _onDeny = () => {
        post(`/api/${this.props.entityType}/${this.props.entity.id}/share`,
            [{ userId: this.props.userId, operations: ResourceOperations.None, isAccessRequest: true }])
            .then(() => this.props.onDismiss())
            .catch(this.props.defaultCatch);
        this.props.onDismiss();
    }
}

function mergeActionCreators(dispatch: any) {
    return {
        defaultCatch: defaultCatch(dispatch)
    }
}

export default connect(undefined, mergeActionCreators)(AccessRequestConfirmationPanel);