import * as StatusDescriptorFactory from './StatusDescriptorFactory';
import {
    IExtensibleEntity, IUserInfo, StatusCategory, IWithWarnings, Dictionary, EntityType, IEntityInfo,
    IWarning, IWithTagsAttrs, IRefInfo, Impact, MaybeDate, IPatch, mapServerEntityType, IWithName,
    CostType
} from "./common";
import * as analytics from '../analytics';
import { SourceType } from "../store/ExternalEpmConnectStore";
import * as Metadata from "./Metadata";
import { notUndefined, toDate } from "../components/utils/common";
import { getRoadmapItemPlanState } from "../store/RoadmapsListStore";
import { LayoutsState } from "../store/layouts";
import { ICreationData, StoreActions } from "../store/Subentity";
import { Idea, IdeaStage } from "../store/IdeasListStore";
import * as React from 'react';
import * as H from "history";
import { Views } from "../store/services/viewSaver";

export interface ISubentity extends IExtensibleEntity {
    connectionId: string | null;
    sourceType: SourceType | null;
    externalId: string | null;
    externalData: Dictionary<any>;
}

export interface IKeyDateBaseline {
    setDate: MaybeDate;
    date: MaybeDate;
    dateVariance?: number | null;
}

export interface IKeyDateAttrs extends IWithName, IWithTagsAttrs {
    Type: KeyDateType;
    Date: string;
    IsComplete: boolean;
    Status: string;
    Description: string;
    ShowOnTimeline: boolean;
    AssignedTo?: IUserInfo[];
    Stage?: string;
}

export interface KeyDate extends ISubentity, IWithWarnings {
    attributes: IKeyDateAttrs;
    baseline: IKeyDateBaseline | undefined;
}

export enum KeyDateType {
    KeyDate = 0,
    Milestone = 1,
    Release = 2
}

export const KeyDateTypeMap: { [i: number]: string } = {
    [KeyDateType.KeyDate]: "Key Date",
    [KeyDateType.Milestone]: "Milestone",
    [KeyDateType.Release]: "Release",
}

export type KeyDateWithWarnings = {
    keyDate?: KeyDate,
    warnings: IWarning[]
}

export type DeliverableWithWarnings = {
    deliverable?: Deliverable,
    warnings: IWarning[]
}

export enum RiskIssueStatus {
    Active = 0,
    Closed = 1,
    Postponed = 2
}

export interface IRiskAttrs extends IWithName, IWithTagsAttrs {
    Owner: IUserInfo;
    AssignedTo: IUserInfo;
    Status: RiskIssueStatus;
    Category: string;
    DueDate: string;
    Probability: number;
    Impact: number | null;
    Cost: number | null;
    Description: string;
}

export interface Risk extends ISubentity {
    id: string;
    attributes: IRiskAttrs;
}

export interface Iteration extends ISubentity {
    id: string;
    attributes: IIterationAttrs;
}

export interface IIterationAttrs extends IWithName, IWithTagsAttrs {
    Description: string;
    StartDate?: string;
    FinishDate?: string;
    State?: IterationState;
}

export enum IterationState {
    NotStarted = 0,
    Started = 1,
    Closed = 2
}

export interface IterationStateConfig { title: string, cssClassName: string, iconName: string }
export const iterationStateConfigMap: { [i: number]: IterationStateConfig } = {
    [IterationState.NotStarted]:
    {
        title: "Not Started",
        cssClassName: "NotStarted",
        iconName: "PPMXStateNotStarted"
    },
    [IterationState.Started]:
    {
        title: "Started",
        cssClassName: "Started",
        iconName: "PPMXStateStarted"
    },
    [IterationState.Closed]:
    {
        title: "Closed",
        cssClassName: "Closed",
        iconName: "Accept"
    }
}

enum IssuePriority {
    Low = 0,
    Medium = 1,
    High = 2
}

export interface IIssueAttrs extends IWithName, IWithTagsAttrs {
    Owner: IUserInfo;
    AssignedTo: IUserInfo;
    Status: RiskIssueStatus;
    Category: string;
    DueDate: string;
    Priority: IssuePriority;
    Description: string;
}

export interface Issue extends ISubentity {
    attributes: IIssueAttrs;
}

export interface ILessonLearnedAttrs extends IWithName, IWithTagsAttrs {
    Lesson: string;
    DescriptionOfAction: string;
    DescriptionOfOutcome: string;
    Recommendation: string;
    Outcome: string;
    ProjectStage: string;
}

export interface LessonLearned extends ISubentity {
    attributes: ILessonLearnedAttrs;
}

export interface IActionItemAttrs extends IWithName, IWithTagsAttrs {
    Description: string;
    DateAssigned?: string;
    AssignedTo?: IUserInfo[];
    Status: ActionItemStatuses;
    ClosedDate?: string;
    Comments?: string;
    DueDate?: string;
}

export enum ActionItemStatuses {
    Active = "Active",
    Closed = "Closed",
    Postponed = "Postponed"
}

export interface ActionItem extends ISubentity {
    attributes: IActionItemAttrs;
}

export interface IKeyDecisionAttrs extends IWithName, IWithTagsAttrs {
    Deciders?: IUserInfo[];
    Date?: string;
    Description: string;
    Comments: string;
}

export interface KeyDecision extends ISubentity {
    attributes: IKeyDecisionAttrs;
}

export enum DependencyType {
    DependsOn = 0,
    DeliversTo = 1,
    RelatedTo = 2
}

export interface IDependencyAttrs extends IWithTagsAttrs {
    Item: IRefInfo;
    Type: DependencyType;
    Description: string;
    Owner: IUserInfo;
    Impact: Impact;
    Status: RiskIssueStatus;
    DueDate?: string;
}
export interface Dependency extends ISubentity, IWithWarnings {
    attributes: IDependencyAttrs;
}

export interface ISteeringCommitteeAttrs extends IWithTagsAttrs {
    Person: IUserInfo;
    RoleDescription: string;
}

export interface SteeringCommittee extends ISubentity {
    attributes: ISteeringCommitteeAttrs;
}

export interface IPurchaseOrderAttrs extends IWithTagsAttrs {
    Name: string;
    DueDate: string;
}

export interface PurchaseOrder extends ISubentity {
    attributes: IPurchaseOrderAttrs;
}

export interface IInvoiceAttrs extends IWithName, IWithTagsAttrs {
    DueDate: string;
}

export interface Invoice extends ISubentity {
    attributes: IInvoiceAttrs;
}

export interface IChangeRequestAttrs extends IWithName, IWithTagsAttrs {
    Requesters?: IUserInfo[];
    RequestDate?: string;
    Description: string;
    ScheduleCostImpact: string;
    Deciders?: IUserInfo[];
    Decision: string;
    DecisionDate?: string;
    Comments: string;
    AssignedTo?: IUserInfo;
    Status?: RiskIssueStatus;
    DueDate?: string;
}

export interface ChangeRequest extends ISubentity {
    attributes: IChangeRequestAttrs;
}

export enum TaskPriority {
    NA = 0,
    Low = 1,
    Medium = 2,
    High = 3,
    Urgent = 4
}
export interface TaskPriorityConfig { title: string, iconName?: string, className?: string }
export const taskPriorityConfigMap: { [i: number]: TaskPriorityConfig } = {
    [TaskPriority.NA]: {
        iconName: "PPMXTaskPriorityNA",
        title: "N/A",
    },
    [TaskPriority.Low]: {
        iconName: "PPMXTaskPriorityLow",
        title: "Low",
    },
    [TaskPriority.Medium]: {
        iconName: "PPMXTaskPriorityMedium",
        title: "Medium",
    },
    [TaskPriority.High]: {
        title: "High",
        iconName: "PPMXTaskPriorityHigh",
    },
    [TaskPriority.Urgent]: {
        title: "Urgent",
        iconName: "PPMXTaskPriorityUrgent",
    },
}

export interface ITaskAttrs extends IWithName, IWithTagsAttrs {
    Description: string;
    Group: Metadata.IGroupInfo | undefined;
    Label: Metadata.IGroupInfo | undefined;
    Duration?: number | null;
    StartDate?: MaybeDate;
    DueDate?: MaybeDate;
    AssignedTo?: IUserInfo[];
    Status: string;
    Progress: number;
    Priority: TaskPriority;
    Effort?: number;
    IsMilestone: boolean;
    Predecessor?: IPredecessorInfo[];
    Type?: string;
    WBS?: string;
    Iteration?: string;
    OriginalEstimate?: number;
    CompletedWork?: number;
    RemainingWork?: number;
    AreaPath?: string;
    State?: string;
    StoryPoints?: number;
    CompletedStoryPoints?: number;
    Parent?: string;
    ParentName?: string;
    CostType?: CostType | null;
    Stage?: string;
}

export interface ITaskExternalData {
    NameLink?: string;
    NameIcon?: string;
}

export interface ITaskBaseline {
    date: MaybeDate;
    startDate?: MaybeDate;
    dueDate?: MaybeDate;
    duration?: number | null;
    effort?: number | null;
    originalEstimate?: number | null;
    startDateVariance?: number | null
    dueDateVariance?: number | null;
    durationVariance?: number | null;
    effortVariance?: number | null;
    originalEstimateVariance?: number | null;
}

export interface ITask extends ISubentity, IWithWarnings {
    attributes: ITaskAttrs;
    externalData: ITaskExternalData;
    baseline: ITaskBaseline;
    hierarchy: IHierarchyInfo;
    isAutoMode: boolean;
}

export interface IHierarchyInfo {
    rank?: number;
    outlineLevel: number;
    parentId?: string;
    isParent: boolean;
    parentIds?: string[];
    parentExternalId?: string;
}

export interface ITaskInfo extends IEntityInfo { }

export interface IPredecessorInfo extends IEntityInfo {
    lag: number;
    type: PredecessorType
}

export enum PredecessorType {
    FinishToStart = 0,
    FinishToFinish = 1,
    StartToFinish = 2,
    StartToStart = 3,
}

export const PredecessorTypeMetadata = {
    [PredecessorType.FinishToStart]: {
        abbreviation: 'FS',
        label: 'Finish-to-Start',
    },
    [PredecessorType.FinishToFinish]: {
        abbreviation: 'FF',
        label: 'Finish-to-Finish',
    },
    [PredecessorType.StartToFinish]: {
        abbreviation: 'SF',
        label: 'Start-to-Finish',
    },
    [PredecessorType.StartToStart]: {
        abbreviation: 'SS',
        label: 'Start-to-Start',
    },
}

export interface IWithTasks { tasks?: ITask[]; tasksConnectionId?: string }

export const sortTasksByRank = (a: ITask, b: ITask): -1 | 0 | 1 =>
    sortByRankImpl((v: ITask) => v.hierarchy.rank)(a, b);

export const sortByRank = (a: { externalData: any }, b: { externalData: any }, propertyRank: string): -1 | 0 | 1 =>
    sortByRankImpl((v: { externalData: any }) => v.externalData[propertyRank])(a, b);

function sortByRankImpl<T>(extractor: (_: T) => any) {
    return (a: T, b: T): -1 | 0 | 1 => {
        const aValue = extractor(a);
        const bValue = extractor(b);
        if (aValue !== undefined && bValue === undefined) {
            return -1;
        }
        if (aValue === undefined && bValue !== undefined) {
            return 1;
        }

        return aValue === bValue
            ? 0
            : aValue! < bValue!
                ? - 1
                : 1;
    };
}

export function buildPredecessorsMap(tasks?: { id: string, attributes: { Predecessor?: { id: string; }[] } }[]): Dictionary<Dictionary<boolean>> {
    let map: Dictionary<Dictionary<boolean>> = {};
    tasks?.forEach(_ => {
        if (_.attributes.Predecessor) {
            _.attributes.Predecessor.forEach(predecessor => {
                map[_.id] = { ...map[_.id], [predecessor.id]: true }
            })
        };
    });
    let hasChange = false;
    do {
        hasChange = false;
        for (const taskId in map) {
            for (const predecessorId in map[taskId]) {
                if (map[predecessorId]) {
                    for (const id in map[predecessorId]) {
                        if (!map[taskId][id]) {
                            map[taskId][id] = true;
                            hasChange = true;
                        }
                    }
                }
            }
        }
    } while (hasChange);

    return map;
};

export function buildMilestone(entity: ITask): KeyDate | undefined {
    const isMilestone = entity.attributes.IsMilestone && !!entity.attributes.DueDate;

    if (!isMilestone)
        return undefined;

    return {
        id: '',
        connectionId: null,
        attributes: {
            Date: entity.attributes.DueDate! as string,
            IsComplete: entity.attributes.Progress == 100,
            Description: entity.attributes.Description,
            Name: entity.attributes.Name,
            ShowOnTimeline: false,
            Status: entity.attributes.Status,
            Type: KeyDateType.Milestone
        },
        externalData: {},
        externalId: null,
        sourceType: SourceType.Ppmx,
        warnings: [],
        baseline: undefined
    };
}

export interface IRoadmapItemAttrs {
    Type: RoadmapItemType;
    Name: string;
    Description: string;
    Comments: string;
    StartDate?: MaybeDate;
    FinishDate: string;
    Lane: Metadata.IGroupInfo;
    Label?: Metadata.IGroupInfo;
    Progress?: number;
    AssignedTo?: IUserInfo[];
    Tags?: string[];
    EstimatedEffort?: number;
    EstimatedCost?: number;
    EstimatedBenefit?: number;
    StoryPoints?: number;
    Status?: string;
    // Prioritization fields
    Impact?: number;
    Confidence?: number;
    Ease?: number;
    ICEScore?: number;
    Reach?: number;
    Effort?: number;
    RICEScore?: number;
    ValueVsEffortScore?: number;
    MoSCoW?: string;
    UserBusinessValue?: number;
    RROE?: number;
    TimeCriticality?: number;
    JobSize?: number;
    CostOfDelay?: number;
    WSJF?: number;
}

export interface IRoadmapItem extends ISubentity, IWithWarnings {
    attributes: IRoadmapItemAttrs;
}

export enum RoadmapItemType {
    Bar = 0,
    KeyDate = 1
}

export interface IRoadmapItemDependency {
    id: string;
    sourceId: string;
    targetId: string;
}

export const RoadmapItemTypeMap: Record<RoadmapItemType, string> = {
    [RoadmapItemType.KeyDate]: "Key Date",
    [RoadmapItemType.Bar]: "Bar",
}

export interface IDeliverableAttrs extends IWithTagsAttrs {
    Name: string;
    AssignedTo: IUserInfo[];
    Status: string;
    DueDate: string;
    Description: string;
    Progress: number;
}

export interface Deliverable extends ISubentity, IWithWarnings {
    attributes: IDeliverableAttrs;
}

export function buildKeyDateIconName(type: KeyDateType, statusCategory: StatusCategory): string {
    return `PPMX${KeyDateType[type]}${StatusCategoryClassMap[statusCategory]}`;
}

export function buildDeliverableIconName(statusCategory: StatusCategory): string {
    return `PPMXDeliverable${StatusCategoryClassMap[statusCategory]}`;
}

export function buildIterationIconName(entity: Iteration): string {
    return `PPMXIteration${getIterationClassName(entity)}`;
}

export const StatusCategoryClassMap: Record<StatusCategory, string> = {
    [StatusCategory.NA]: "NA",
    [StatusCategory.Done]: "Completed",
    [StatusCategory.Green]: "Ontrack",
    [StatusCategory.Red]: "Critical",
    [StatusCategory.Amber]: "Atrisk",
}

export function buildKeyDateWithoutStatus(keyDate: IPatch<KeyDate>) {
    const keyDateWithoutStatus = { ...keyDate, attributes: { ...keyDate.attributes } };
    delete (keyDateWithoutStatus.attributes as any).Status;
    return keyDateWithoutStatus;
}

export function buildRoadmapItemIconName(entity: IRoadmapItem, small?: boolean): string {
    return `PPMXRoadmap${RoadmapItemType[entity.attributes.Type]}${getRoadmapItemPlanState(entity)}${small ? 'Small' : ''}`;
}

export function buildRoadmapItemDependencyIconName(dependencyType: DependencyType, entity?: IRoadmapItem): string {
    return `PPMXDependency${DependencyType[dependencyType]}${entity ? getRoadmapItemPlanState(entity) : ''}`;
}

export function getIterationClassName(iteration: Iteration): string | undefined {
    if (iteration.attributes.State == IterationState.Closed) {
        return 'Completed';
    }
    else if (iteration.attributes.FinishDate && toDate(iteration.attributes.FinishDate)! < new Date().getBeginOfDay()) {
        return 'Late';
    }
    else if (iteration.attributes.StartDate
        && iteration.attributes.FinishDate
        && toDate(iteration.attributes.StartDate)! <= new Date().getBeginOfDay()
        && toDate(iteration.attributes.FinishDate)! > new Date().getBeginOfDay()) {
        return 'Current';
    }
    else {
        return 'Future';
    }
}

export function isLinkedSubentity(entityType: string, entity: ISubentity): boolean {
    return entityType === EntityType.Task
        ? false
        : entity.sourceType !== null && entity.sourceType !== undefined;
}

export function isSubentity(type: EntityType): boolean {
    const subentities: Dictionary<any> =
    {
        [EntityType.ActionItem]: 1,
        [EntityType.ChangeRequest]: 1,
        [EntityType.Issue]: 1,
        [EntityType.Iteration]: 1,
        [EntityType.KeyDate]: 1,
        [EntityType.KeyDecision]: 1,
        [EntityType.LessonLearned]: 1,
        [EntityType.Risk]: 1,
        [EntityType.SteeringCommittee]: 1,
        [EntityType.StrategicPriority]: 1,
        [EntityType.Task]: 1,
        [EntityType.RoadmapItem]: 1,
        [EntityType.Idea]: 1,
        [EntityType.PurchaseOrder]: 1,
        [EntityType.Invoice]: 1,
        [EntityType.Deliverable]: 1
    };

    return notUndefined(subentities[type]);
}

export const urlParamsBuilder = {
    filter: (type: EntityType): string => `${type.toLowerCase()}filter`,
    preFilter: (type: EntityType): string => `${type.toLowerCase()}prefilter`,
    view: (type: EntityType): string => `${type.toLowerCase()}view`,
    viewType: (type: EntityType): string => `${type.toLowerCase()}viewtype`,
    connectionId: "connectionid",
    uniqueId: "uniqueId",
    item: (type: EntityType): string => `${type.toLowerCase()}`,
    itemType: (type: EntityType): string => `${type.toLowerCase()}type`
}

type StringEnum = { [id: string]: string }

export interface IUrlHelper {
    openSubView: (subViewId: string) => void;
    getSubViewId: () => string | undefined;
    openViewType: (viewType: string) => void;
    getUrlViewType: <T extends StringEnum>(types: T, subentityType: EntityType) => T[keyof T] | undefined;
    getUrlFilterId: () => string | undefined;
    getUrlPreFilterId: () => string | undefined;
    openFilter: (filterId: string) => void;
}

export const urlHelperBuilder = (history: H.History,
    location: H.Location,
    query: URLSearchParams,
    subentityType: EntityType): IUrlHelper => ({
        openSubView: subViewId => {
            query.set(urlParamsBuilder.view(subentityType), subViewId);
            history.replace({
                ...location,
                search: query.toString()
            });
        },
        getSubViewId: () => query.get(`${subentityType}view`.toLowerCase()) ?? undefined,
        openViewType: viewType => {
            query.set(urlParamsBuilder.viewType(subentityType), viewType.toLowerCase());
            history.replace({
                ...location,
                search: query.toString()
            });
        },
        getUrlViewType: <T extends StringEnum>(types: T, subentityType: EntityType): T[keyof T] | undefined => {
            const urlViewType = query.get(urlParamsBuilder.viewType(subentityType))?.toLowerCase();
            if (!urlViewType) {
                return;
            }

            return Object.keys(types).map(k => types[k]).find(_ => _.toLowerCase() == urlViewType) as T[keyof T];
        },
        getUrlFilterId: () => query.get(`${subentityType}filter`.toLowerCase()) || undefined,
        getUrlPreFilterId: () => query.get(`${subentityType}prefilter`.toLowerCase()) || undefined,
        openFilter: (filterId: string) => {
            query.set(urlParamsBuilder.filter(subentityType), filterId);
            history.replace({
                ...location,
                search: query.toString(),
                state: {
                    ...location.state,
                    filters: undefined
                }
            });
        }
    });

export const useUrlHelper = (history: H.History, location: H.Location, subentityType: EntityType): IUrlHelper => {
    const { search } = location;
    const query = React.useMemo(() => new URLSearchParams(search), [search]);
    const urlHelper = React.useMemo(
        () => urlHelperBuilder(history, location, query, subentityType),
        [history, location, query, subentityType]);

    return urlHelper;
}

export type ImportRendererProps = { onDismiss: () => void, viewType?: string };
type ImportRender = (props: ImportRendererProps) => JSX.Element | JSX.Element[] | null;

export interface ISubentityImportExport {
    exportToFile?: (subentityType: EntityType, exportSubEntitiesToFile: string, fields: string[], ids: string[], viewType: Views) => void;
    importFromFile?: (subentityType: EntityType, subentityCollectionName: string, file: File) => void;
    renderImport?: ImportRender;
}

export interface ISubentitySectionActions<TSubentity extends ISubentity, IAttrs = Dictionary<any>> extends ISubentityImportExport {
    buildNew?: () => TSubentity;
    create: ((item: ICreationData<IAttrs>) => void) | null;
    update: ((id: string, changes: Dictionary<unknown>) => void) | null;
    remove: ((ids: string[]) => void) | null;
    updateUIControl: ((sectionId: string, uiControlId: string, settings: Dictionary<any>) => void) | null;
    refreshEntity: () => void;
    dragEntities?: (entities: string[], groupid?: string, insertbeforeId?: string) => void;
}

export type ImportExportStoreActions = {
    exportSubEntitiesToFile: (entityId: string, subentityType: EntityType, pluralSubentityTypeLabel: string, fields: string[], ids: string[]) => void;
    importSubEntitiesFromFile: (entityId: string, subentityType: EntityType, subEntityCollectionName: string, file: File) => void;
}

type EntityStoreActions = {
    updateLayoutUIControl: (
        sectionId: string,
        uiControlId: string,
        settings: Dictionary<any>,
        layoutId?: string) => void,
    updateUIControl: (
        entityId: string,
        sectionId: string,
        uiControlId: string,
        settings: Dictionary<any>) => void,
    updateUIControlOnClient: (
        entityId: string,
        sectionId: string,
        uiControlId: string,
        settings: Dictionary<any>) => void
}

export const buildActions = (entityId: string,
    isReadOnly: boolean,
    analyticsProps: AnalyticsProps,
    layouts: LayoutsState,
    storeActions: EntityStoreActions & ImportExportStoreActions,
    loader: (id: string) => void
) => {
    const entityActions = buildEntityActions(entityId, isReadOnly, layouts, storeActions, loader);
    const importExportActions = buildImportExportActions(entityId, analyticsProps, storeActions);
    const actions = { ...entityActions, ...importExportActions };
    return { entityId, entityActions, importExportActions, actions };
}

export const buildImportExportActions = (id: string,
    analyticsProps: AnalyticsProps,
    actions: ImportExportStoreActions): ISubentityImportExport => ({
        exportToFile: (t, l, f, ids) => {
            actions.exportSubEntitiesToFile(id, t, l, f, ids);
            analytics.trackEvent("Export", analyticsProps.user, {
                count: ids.length,
                parentType: analyticsProps.parentType,
                itemType: t
            });
        },
        importFromFile: (t, c, f) => {
            actions.importSubEntitiesFromFile(id, t, c, f);
            analytics.trackEvent("Import", analyticsProps.user, {
                parentType: analyticsProps.parentType,
                itemType: t,
                fileSize: f.size,
                fileType: f.type
            });
        },
    });

const buildEntityActions = (id: string, isReadOnly: boolean, layouts: LayoutsState, actions: EntityStoreActions, loader: (id: string) => void) => ({
    refreshEntity: () => loader(id),
    updateUIControl: buildUpdateUIControl(id, isReadOnly, layouts, actions)
})

export const buildUpdateUIControl = (id: string, isReadOnly: boolean, layouts: LayoutsState, actions: EntityStoreActions) =>
((sectionId: string, uiControlId: string, settings: Dictionary<any>) =>
    layouts.activeEntity
        ? actions.updateLayoutUIControl(sectionId, uiControlId, settings, layouts.activeEntity.id)
        : isReadOnly
            ? actions.updateUIControlOnClient(id, sectionId, uiControlId, settings)
            : actions.updateUIControl(id, sectionId, uiControlId, settings))

export type IEntityActions = ReturnType<typeof buildEntityActions>;

export type IIdeasControlActions = {
    bulkApplyIdeasLayout: (layout: Metadata.Layout, ideas: Idea[]) => void;
} & ISubentitySectionActions<Idea>;

export const buildIdeaActions = (entityId: string,
    actions: IEntityActions & ISubentityImportExport,
    updateIdea: (ideaId: string, updates: Dictionary<any>) => void,
    removeIdea: (entityId: string, ids: string[]) => void,
    bulkApplyIdeasLayout: (layout: Metadata.Layout, ideas: Idea[]) => void): IIdeasControlActions => ({
        buildNew: () => ({
            attributes: {
                Stage: IdeaStage.Draft
            }
        } as Idea),
        create: () => undefined,
        update: (id, changes) => updateIdea(id, changes),
        ...actions,
        remove: ids => removeIdea(entityId, ids),
        bulkApplyIdeasLayout
    })

export const buildRiskActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'Risk'>,
    renderImport?: ImportRender): ISubentitySectionActions<Risk> => ({
        buildNew: () => ({
            attributes: {
                Status: RiskIssueStatus.Active,
                Probability: 0
            }
        } as Risk),
        create: data => {
            storeActions.createRisk(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.Risk,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateRisk(entityId, id, changes),
        remove: ids => storeActions.removeRisk(entityId, ids),
        renderImport: renderImport,
        ...actions
    })

export const buildIterationActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'Iteration'>): ISubentitySectionActions<Iteration> => ({
        create: data => {
            storeActions.createIteration(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.Iteration,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateIteration(entityId, id, changes),
        remove: ids => storeActions.removeIteration(entityId, ids),
        ...actions
    })

export const buildIssuesActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'Issue'>): ISubentitySectionActions<Issue> => ({
        buildNew: () => ({
            attributes: {
                Status: RiskIssueStatus.Active,
                Priority: IssuePriority.Low
            }
        } as Issue),
        create: issue => {
            storeActions.createIssue(entityId, issue);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: issue.attributes.Name,
                itemType: EntityType.Issue,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateIssue(entityId, id, changes),
        remove: ids => storeActions.removeIssue(entityId, ids),
        ...actions
    })

export type KeyDateControlActions = ISubentitySectionActions<KeyDate> & {
    bulkUpdate: (items: IPatch<KeyDate>[]) => void;
    setBaseline: (entities: string[]) => void;
}

type KeyDateStoreActions = {
    createKeyDate: (entityId: string, keyDate: ICreationData) => void;
    updateKeyDates: (enitityId: string, keyDates: IPatch<KeyDate>[]) => void;
    removeKeyDates: (entityId: string, ids: string[]) => void;
    setKeyDatesBaseline: (entityId: string, ids: string[]) => void;
}
type AnalyticsProps = {
    user: analytics.CreatedBy;
    parentType: EntityType;
}

export const buildKeyDateActions = (id: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: KeyDateStoreActions,
    keyDateFields: Metadata.Field[],
    renderImport?: ImportRender): KeyDateControlActions => ({
        buildNew: () => {
            const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.KeyDate, keyDateFields)!;
            return ({
                attributes: {
                    Type: KeyDateType.KeyDate,
                    Status: statusDescriptor.getCategoryDefaultStatusValue(StatusCategory.NA),
                    IsComplete: false
                }
            } as KeyDate);
        },
        create: _ => {
            storeActions.createKeyDate(id, _);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: _.attributes.Name,
                itemType: EntityType.KeyDate,
                parentType: analyticsProps.parentType
            });
        },
        bulkUpdate: p => storeActions.updateKeyDates(id, p),
        setBaseline: ids => {
            storeActions.setKeyDatesBaseline(id, ids);
            analytics.trackEvent('Set keydates baseline', analyticsProps.user,
                {
                    count: ids.length,
                    parentType: analyticsProps.parentType
                });
        },
        remove: ids => storeActions.removeKeyDates(id, ids),
        update: (subId, changes) => storeActions.updateKeyDates(id, [{ id: subId, attributes: changes }]),
        renderImport,
        ...actions,
    })

export const buildKeyDecisionActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'KeyDecision'>): ISubentitySectionActions<KeyDecision> => ({
        buildNew: () => ({
            attributes: {
                Date: new Date().getBeginOfDay().toDateOnlyString()
            }
        } as KeyDecision),
        create: data => {
            storeActions.createKeyDecision(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.KeyDecision,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateKeyDecision(entityId, id, changes),
        remove: ids => storeActions.removeKeyDecision(entityId, ids),
        ...actions
    })

export const buildSteeringCommitteeActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'SteeringCommittee'>): ISubentitySectionActions<SteeringCommittee> => ({
        create: data => {
            storeActions.createSteeringCommittee(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.SteeringCommittee,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateSteeringCommittee(entityId, id, changes),
        remove: ids => storeActions.removeSteeringCommittee(entityId, ids),
        ...actions
    })

export const buildPurchaseOrderActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'PurchaseOrder'>): ISubentitySectionActions<PurchaseOrder> => ({
        create: data => {
            storeActions.createPurchaseOrder(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.PurchaseOrder,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updatePurchaseOrder(entityId, id, changes),
        remove: ids => storeActions.removePurchaseOrder(entityId, ids),
        ...actions
    })

export const buildInvoiceActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'Invoice'>): ISubentitySectionActions<Invoice> => ({
        create: data => {
            storeActions.createInvoice(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.Invoice,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateInvoice(entityId, id, changes),
        remove: ids => storeActions.removeInvoice(entityId, ids),
        ...actions
    })

    

    type DeliverableStoreActions = {
        createDeliverable: (entityId: string, deliverable: ICreationData) => void;
        updateDeliverables: (enitityId: string, deliverables: IPatch<Deliverable>[]) => void;
        removeDeliverables: (entityId: string, ids: string[]) => void;
        linkDeliverable: (projectId: string, data: Dictionary<string[]>) => void;
    } 

export const buildDeliverablesActions = (id: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: DeliverableStoreActions,
    deliverableFields: Metadata.Field[],
    renderImport?: ImportRender): ISubentitySectionActions<Deliverable> => ({
        buildNew: () => {
            const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Deliverable, deliverableFields)!;
            return {
                attributes: {
                    Progress: 0,
                    Status: statusDescriptor.getCategoryDefaultStatusValue(StatusCategory.NA)
                }
            } as Deliverable;
        },
        create: _ => {
            storeActions.createDeliverable(id, _);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: _.attributes.Name,
                itemType: EntityType.Deliverable,
                parentType: analyticsProps.parentType
            });
        },
        remove: ids => storeActions.removeDeliverables(id, ids),
        update: (subId, changes) => storeActions.updateDeliverables(id, [{ id: subId, attributes: changes }]),
        renderImport,
        ...actions,
    })
        
export const buildChangeRequestActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'ChangeRequest'>): ISubentitySectionActions<ChangeRequest> => ({
        buildNew: () => ({
            attributes: {
                RequestDate: new Date().getBeginOfDay().toDateOnlyString(),
                Decision: "Pending",
                Status: RiskIssueStatus.Active
            }
        } as ChangeRequest),
        create: data => {
            storeActions.createChangeRequest(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.ChangeRequest,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateChangeRequest(entityId, id, changes),
        remove: ids => storeActions.removeChangeRequest(entityId, ids),
        ...actions
    })

export const buildDependencyActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'Dependency'>): ISubentitySectionActions<Dependency, IDependencyAttrs> => ({
        buildNew: () => ({
            attributes: {
                Type: DependencyType.DependsOn,
                Status: RiskIssueStatus.Active
            }
        } as Dependency),
        create: data => {
            storeActions.createDependency(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: '',
                dependencyType: DependencyType[data.attributes.Type],
                dependencyRelation: data.attributes.Item?.entityType
                    ? mapServerEntityType[data.attributes.Item.entityType]
                    : undefined,
                itemType: EntityType.Dependency,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateDependency(entityId, id, changes),
        remove: ids => storeActions.removeDependency(entityId, ids),
        ...actions
    })

export const buildActionItemActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'ActionItem'>): ISubentitySectionActions<ActionItem> => ({
        buildNew: (): ActionItem => ({
            attributes: {
                DateAssigned: new Date().getBeginOfDay().toDateOnlyString(),
                Status: ActionItemStatuses.Active
            }
        } as ActionItem),
        create: data => {
            storeActions.createActionItem(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Name,
                itemType: EntityType.ActionItem,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateActionItem(entityId, id, changes),
        remove: ids => storeActions.removeActionItem(entityId, ids),
        ...actions
    })

export const buildLessonLearnedActions = (entityId: string,
    analyticsProps: AnalyticsProps,
    actions: IEntityActions & ISubentityImportExport,
    storeActions: StoreActions<'LessonLearned'>,
    buildNew: () => LessonLearned): ISubentitySectionActions<LessonLearned, ILessonLearnedAttrs> => ({
        create: data => {
            storeActions.createLessonLearned(entityId, data);
            analytics.trackCreate(analyticsProps.user, {
                itemTitle: data.attributes.Lesson,
                itemType: EntityType.LessonLearned,
                parentType: analyticsProps.parentType
            });
        },
        update: (id, changes) => storeActions.updateLessonLearned(entityId, id, changes),
        remove: ids => storeActions.removeLessonLearned(entityId, ids),
        buildNew: buildNew,
        ...actions
    })

export type SubentityInfo = {
    subentityType: EntityType;
    subentityTypeLabel: string;
    pluralSubentityTypeLabel?: string;
    subentityCollectionName: string;
}