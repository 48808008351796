import * as React from 'react';
import { TextField, mapEnumByName, DayOfWeek } from 'office-ui-fabric-react';
import { Dictionary } from '../../../entities/common';
import { debounce, notUndefined } from '../../utils/common';
import { Validator } from '../../../validation';

const hoursValidator = Validator.new().required().decimal().min(0).max(24).build();

type Props = {
    disabled?: boolean;
    workDayExpectedHrs: Dictionary<number>;
    save: (workDayExpectedHrs: Dictionary<number>) => void;
};

type State = {
    workDayExpectedHrs: Dictionary<string | undefined>;
    errors: Dictionary<string | undefined>;
};

export class WorkWeekSettings extends React.Component<Props, State> {
    private _dayOptions: { name: string, key: string | number }[];
    private debouncedOnChange = debounce<Dictionary<string | undefined>>(500);

    constructor(props: Props) {
        super(props);

        this._dayOptions = mapEnumByName(DayOfWeek, (name, value) => ({ name: name!, key: value! }))!.filter(notUndefined);
        this.state = this._buildState(props);
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (this.props.workDayExpectedHrs != nextProps.workDayExpectedHrs) {
            this.setState(this._buildState(nextProps));
        }
    }

    public render() {
        const { disabled } = this.props;
        const { workDayExpectedHrs, errors } = this.state;
        const isInitialized = !!Object.keys(this.props.workDayExpectedHrs).length;

        return <div className="workweek-settings">
            <ul className="tb-list tb-in-row">
                {
                    this._dayOptions.map((item): JSX.Element => {
                        return <li key={item.key} style={workDayExpectedHrs[item.key] == "0" ? { opacity: 0.7 } : {}}>
                            <TextField suffix="hours"
                                disabled={disabled}
                                label={item.name}
                                value={workDayExpectedHrs[item.key]}
                                className={!disabled && isInitialized && errors[item.key] ? "error" : undefined}
                                onChange={(e, _) => this._setWorkDayExpectedHrs(item.key, _)} />
                        </li>
                    })
                }
            </ul>
            {!disabled && isInitialized && <div className="errors">
                {
                    this._dayOptions.map((item) => {
                        return errors[item.key] && <div key={item.key} className="error">{item.name}: {errors[item.key]};</div>;
                    })
                }
            </div>}
        </div>;
    }

    private _setWorkDayExpectedHrs = (key: number | string, value: string | undefined) => {
        const workDayExpectedHrs = { ...this.state.workDayExpectedHrs, [key]: value };
        const errors = { ...this.state.errors, [key]: hoursValidator.getErrorMessage(value) };
        this.setState({ workDayExpectedHrs, errors });
        this.debouncedOnChange(workDayExpectedHrs, hrs => {
            if (!this.anyErrors(errors)) {
                const numbers = this._dayOptions.map(_ => _.key)
                    .reduce((prev: Dictionary<number>, _: string | number) => ({ ...prev, [_]: parseFloat(hrs[_]!) }), {});
                this.props.save(numbers);
            }
        })
    }

    private anyErrors(errors: Dictionary<string | undefined>): boolean {
        return this._dayOptions.map(_ => _.key).find(_ => !!errors[_]) != undefined;
    }

    private _buildState(props: Props): State {
        let workDayExpectedHrs = this._dayOptions.map(_ => _.key)
            .reduce((prev: Dictionary<string>, _: string | number) => ({ ...prev, [_]: props.workDayExpectedHrs[_]?.toString() }), {});
        const errors = this._dayOptions.map(_ => _.key)
            .reduce((prev: Dictionary<string>, _: string | number) => ({ ...prev, [_]: hoursValidator.getErrorMessage(workDayExpectedHrs[_]) }), {});
        return { workDayExpectedHrs, errors };
    }
}