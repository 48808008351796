import * as React from 'react';
import { connect } from 'react-redux';
import { Persona, PersonaSize, Panel, ActionButton, IconButton } from 'office-ui-fabric-react';
import { getPersonInfoImageUrl, inIframe } from "../utils/common";
import { ApplicationState } from "../../store/index";
import { UserState } from '../../store/User';
import { CommonOperations } from '../../store/permissions';
import { Subscription, TenantState } from '../../store/Tenant';
import TenantCard from './TenantCard';
import EmailSettings from './EmailSettings';
import { AuthProvider } from '../../entities/common';
import { NavigationProps } from './Breadcrumbs';

export const VIEWER_PREFIX = '[VIEWER]';

type OwnProps = NavigationProps & {
    allowChangeAccount: boolean;
    isOpen: boolean;
    onDismiss: () => void;
};

type StateProps = {
    subscription: Subscription;
    user: UserState;
    tenant: TenantState;
};

type Props = OwnProps & StateProps;

const AccountPanel = (props: Props) => {
    const { allowChangeAccount, user, tenant, isOpen, router, onDismiss } = props;
    const isTenantAvailable = !!tenant.id;
    
    const userName = getUserName(user);
    
    const isViewer = user.permissions?.common === CommonOperations.AdministrateView;
    const allowEditProfile = !isViewer && user.signInAuthProvider === AuthProvider.email;
    
    return (
        <Panel
            isOpen={isOpen}
            isLightDismiss
            onRenderHeader={(headerProps) => <PanelHeader {...headerProps} />}
            onDismiss={onDismiss}
            className='user-info-panel'>
            <Persona size={PersonaSize.size48} imageUrl={getPersonInfoImageUrl(user)} secondaryText={user.email} onRenderPrimaryText={() => <>
                {userName}
                {allowEditProfile && <IconButton className="edit-profile" title="Edit Profile" menuIconProps={{ iconName: 'Edit' }}
                    href={`account/editprofile?redirectUri=${encodeURIComponent(router.location.pathname)}`}>
                </IconButton>}
            </>} />
            <div className="actions">
                <ActionButton className="sign-out"
                    iconProps={{ iconName: allowChangeAccount ? 'UserSync' : 'SignOut' }}
                    href={`account/signout${allowChangeAccount || inIframe() ? "?redirectUri=" + encodeURIComponent(router.location.pathname) : ""}`}>
                    {allowChangeAccount ? 'Change account' : 'Log out'}
                </ActionButton>
            </div>
            {
                user.availableTenants && <div className="tenants card-list">
                    {user.availableTenants.map((viewModel, index) => <TenantCard key={viewModel.id} tenant={viewModel} />)}
                </div>
            }
            {isTenantAvailable && <EmailSettings />}
        </Panel>
    );
}

const getUserName = (user: UserState) => {
    const isViewer = user.permissions?.common === CommonOperations.AdministrateView;
    return isViewer
        ? `${VIEWER_PREFIX} ${user.name}`
        : user.name;
}

const PanelHeader = () => (
    <div className="ms-Panel-header">
        <p className="ms-Panel-headerText">My account</p>
        <div className='ms-Panel-secondaryText'>View current logon account and switch Organization context.</div>
    </div>
);

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
        tenant: state.tenant,
    };
}

export default connect(mapStateToProps)(AccountPanel);
