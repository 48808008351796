import * as React from 'react';
import { buildRoadmapItemDependencyIconName, DependencyType, IRoadmapItem } from '../../entities/Subentities';
import { Icon, IconButton } from 'office-ui-fabric-react';
import RoadmapItemLink from './RoadmapItemLink';
import { DependencyTypeMetadata } from './RoadmapItemDependencies';

interface RoadmapItemDependencyProps {
    readonly: boolean;
    onRemove: () => void;
    dependencyType: DependencyType;
    dependencyItem: IRoadmapItem;
}

const RoadmapItemDependency = (props: RoadmapItemDependencyProps) => {
    const { readonly, dependencyItem, dependencyType, onRemove } = props;
    return <div className="dependency-box align-center">
        <Icon title={DependencyTypeMetadata[dependencyType].label} iconName={buildRoadmapItemDependencyIconName(dependencyType, dependencyItem)} />
        <RoadmapItemLink entity={dependencyItem} />
        {!readonly && <IconButton menuIconProps={{ iconName: 'Delete' }} title="Delete" className='menu' onClick={onRemove} />}
    </div>;
}

export default RoadmapItemDependency;
