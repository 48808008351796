import * as React from "react";
import UploadHeaderLogo from "./UploadHeaderLogo";
import LabellableComponent from "../../../common/LabellableComponent";

const CustomLogoSection = () => {
    return (
        <LabellableComponent className="field-container" label="Custom Logo">
            <span className="section-description">
                Customize your logo in the navigation bar.<br />
                It supports PNG formats. For the best view, use an image with a size of 130x32 pixels and a transparent background.
            </span>
            <UploadHeaderLogo />
        </LabellableComponent>
    );
};

export default CustomLogoSection;
