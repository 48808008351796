import * as React from 'react';
import ListMenu, { OwnProps as ListMenuProps } from '../../../common/extensibleEntity/ListMenu';
import { IContextualMenuItem, DefaultButton, DirectionalHint, getId } from 'office-ui-fabric-react';
import { ISelectableCellData } from '.';
import { ViewType } from '../ResourceUsageGrid';
import { Validator } from '../../../../validation';
import { formatValue, HUNDRED_PCT } from '../../../utils/common';
import { FormatType } from '../../../../entities/Metadata';
import { Quantization } from '../../../../entities/common';
import { ProgressIcon } from '../../../common/ProgressFormatter';
import NumberInput from '../../../common/inputs/NumberInput';
import ContextualMenuToggle from '../../../common/ContextualMenuToggle';
import TextInput from '../../../common/inputs/TextInput';

export type SetTimeAction = (plan: number | true, viewType?: ViewType, cells?: ISelectableCellData[]) => void;

type SetRateAction = (rate: number) => void;
type SetBillingCodeAction = (billingCode: string) => void;

interface Props {
    quantization: Quantization;
    menuProps: ListMenuProps;
    availableTimeTypes: TimeType[];
    timeType: TimeType[];
    onTimeTypeChange: (type: TimeType, checked: boolean) => void;
}
export type TimeType = 'plan' | 'actual' | 'capacity' | 'availability';
export const timeTypes: { title: string, type: TimeType }[] = [{
    title: 'Planned',
    type: 'plan'
}, {
    title: 'Actual',
    type: 'actual'
}, {
    title: 'Availability',
    type: 'availability'
}, {
    title: 'Capacity',
    type: 'capacity'
}];

export const ResourceUsageMenu = (props: Props) => {
    const { menuProps, availableTimeTypes, timeType, onTimeTypeChange } = props;
    return <ListMenu {...menuProps}
        farCommands={[...(menuProps?.farCommands ?? []), {
            key: 'timeType',
            onRender: () => {
                menuProps.commands.forEach(_ => _.key = getId());
                return <TimeTypeSelector timeType={timeType} availableTimeTypes={availableTimeTypes} onTimeTypeChange={onTimeTypeChange} />;
            } 
        }]} />;
}

const decimalValidator = Validator.new().decimal().min(0).build();
const integerValidator = Validator.new().int32().min(0).build();

const fullHoursPerPeriod = {
    [Quantization.days]: { maxOption: 8, label: 'hours / day' },
    [Quantization.weeks]: { maxOption: 40, label: 'hours / week' },
    [Quantization.months]: { maxOption: 160, label: 'hours / month' },
    [Quantization.quarters]: { maxOption: 480, label: 'hours / quarter' },
    [Quantization.years]: { maxOption: 2080, label: 'hours / year' }
}

export function buildAutoAllocateCommand(cells: ISelectableCellData[] | undefined, setPlan: SetTimeAction, text: string): IContextualMenuItem {
    return {
        key: 'auto',
        name: 'Auto',
        title: `${text}: Auto`,
        iconProps: { iconName: 'AutoEnhanceOn' },
        onClick: () => setPlan(true, undefined, cells)
    };
}

export function buildSetTimeCommand(timeLabel: string, setTime: SetTimeAction, quantization: Quantization, disabled?: boolean): IContextualMenuItem {
    const text = `Set ${timeLabel}`;
    return {
        key: timeLabel,
        text,
        iconProps: { iconName: 'UserGauge' },
        disabled,
        subMenuProps: {
            items: disabled
                ? [] 
                : [
                    buildAutoAllocateCommand(undefined, setTime, text),
                    buildAllocateCommands(ViewType.Hours, undefined, quantization, setTime, text)!,
                    buildAllocateCommands(ViewType.Percent, undefined, quantization, setTime, text)!,
                    buildAllocateCommands(ViewType.FTE, undefined, quantization, setTime, text)!,
                ]
        }
    };
}

type AllocationParams = {
    validator: Validator;
    iconName: string;
    getOptionValue: (option: number) => number;
    formatValue: (val: number) => string;
    parseValue: (str: string) => number;
}

export function buildAllocationParams(viewType: ViewType, quantization: Quantization): AllocationParams | undefined {
    switch (viewType) {
        case ViewType.Hours:
            return {
                iconName: 'HourGlass',
                validator: decimalValidator,
                getOptionValue: _ => _ * fullHoursPerPeriod[quantization].maxOption,
                formatValue: _ => `${_} ${fullHoursPerPeriod[quantization].label}`,
                parseValue: _ => Number(_.replace(fullHoursPerPeriod[quantization].label, '').trimEnd())
            }
        case ViewType.Percent:
            return {
                iconName: 'CalculatorPercentage',
                validator: integerValidator,
                getOptionValue: _ => _ * HUNDRED_PCT,
                formatValue: _ => formatValue(_, FormatType.Percent),
                parseValue: _ => Number(_.replace('%', '').trimEnd())
            }
        case ViewType.FTE:
            return {
                iconName: 'UserGauge',
                validator: decimalValidator,
                getOptionValue: _ => _,
                formatValue: _ => `${_} FTE`,
                parseValue: _ => Number(_.replace('FTE', '').trimEnd())
            }
        default:
            return undefined;
    }
}

export function buildAllocateCommands(viewType: ViewType, cells: ISelectableCellData[] | undefined, quantization: Quantization, setPlan: SetTimeAction, text: string)
    : IContextualMenuItem | undefined {
    const params = buildAllocationParams(viewType, quantization);
    if (!params) {
        return undefined;
    }
    return {
        key: viewType.toString(),
        name: ViewType[viewType],
        title: `${text}: ${ViewType[viewType]}`,
        iconProps: { iconName: params.iconName },
        subMenuProps: {
            items: [...[0, 0.25, 0.5, 0.75, 1].map(_ => ({
                key: _.toString(),
                name: params.formatValue(params.getOptionValue(_)),
                className: "progress-formatter",
                onRenderIcon: () => <ProgressIcon progress={_ * 100} />,
                iconProps: {},
                onClick: () => setPlan(params.getOptionValue(_), viewType, cells)
            })), {
                key: "custom",
                onRender: (item, dismissMenu) => (
                    <div className="resource-usage-input">
                        <NumberInput
                            inputProps={{ placeholder: 'type custom value' }}
                            validator={params.validator}
                            onEditComplete={(value) => { if (value !== null) { setPlan(value, viewType, cells); dismissMenu(); } }}
                        />
                    </div>
                ),
            }]
        }
    };
}

export function buildSetRateCommand(setRate: SetRateAction): IContextualMenuItem | undefined {
    return {
        key: "setRate",
        name: "Set Charge Rate",
        iconProps: { iconName: "money" },
        subMenuProps: {
            items: [{
                key: "custom",
                onRender: (item, dismissMenu) => (
                    <div className="resource-usage-input">
                        <NumberInput
                            inputProps={{ placeholder: "type charge rate" }}
                            validator={decimalValidator}
                            onEditComplete={(value) => {
                                if (value !== null) {
                                    setRate(value);
                                    dismissMenu();
                                }
                            }}
                        />
                    </div>
                ),
            }],
        },
    };
}

export function buildSetBillingCodeCommand(setBillingCode: SetBillingCodeAction): IContextualMenuItem | undefined {
    return {
            key: "setBillingCode",
            name: "Set Billing Code",
            iconProps: { iconName: "money" },
            subMenuProps: {
                items: [{
                    key: "custom",
                    onRender: (item, dismissMenu) => (
                        <div className="resource-usage-input">
                            <TextInput
                                inputProps={{ placeholder: "type billing code" }}
                                onEditComplete={(value) => {
                                    if (value !== null) {
                                        setBillingCode(value);
                                        dismissMenu();
                                    }
                                }}
                            />
                        </div>
                    ),
                }],
            },
        };
}

export const TimeTypeSelector = (props: {
    timeType: TimeType[],
    availableTimeTypes: TimeType[],
    onTimeTypeChange: (timeType: TimeType, ckecked: boolean) => void
}) => {
    const { timeType, availableTimeTypes, onTimeTypeChange } = props;
    return <DefaultButton
        className="dropdown-button time-type-selector"
        iconProps={{ iconName: 'Clock' }}
        onRenderText={() => <span key='_'>
            Time Type: {timeTypes.filter(_ => timeType.includes(_.type)).map((_) => <span className={`time-type-lbl ${_.type}`} key={_.type}>{_.title}</span>)}
        </span>}
        menuProps={{
            directionalHint: DirectionalHint.bottomRightEdge,
            items: timeTypes.filter(_ => availableTimeTypes.includes(_.type)).map(_ => ({
                key: _.type,
                onRender: () => <ContextualMenuToggle
                    label={_.title}
                    disabled={timeType.length === 1 && timeType.includes(_.type)}
                    checked={timeType.includes(_.type)}
                    onChange={(e, checked: boolean) => onTimeTypeChange(_.type, checked)}
                />
            }))
        }} />
}