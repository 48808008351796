import * as React from 'react';
import * as analytics from '../../analytics';
import { Pivot, PivotItem, Panel, PanelType, IPanelProps } from 'office-ui-fabric-react';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as Notifications from "../../store/NotificationsStore";
import LinkTab from '../integration/LinkTab';
import WorkTogetherTab from "../integration/WorkTogetherTab";
import { SourceType, SourceType_ } from "../../store/ExternalEpmConnectStore";
import { ILinkDto } from '../../store/integration/common';
import { IPlanInfo } from '../../store/integration/Office365Store';
import { IJiraLinkInfo } from '../../store/integration/JiraStore';
import { ISpoProject } from '../../store/integration/SpoStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TeamsChannelLink, TeamsChannelActions } from '../integration/TeamsChannelConnectControl';
import { Integrations } from '../../store/Tenant';
import { ApplicationState } from '../../store';
import { PANEL_CUSTOM_WIDTH, SourceInfo, ppmxTaskConnectionId } from '../../entities/common';
import { O365ConnectActions } from '../integration/Office365/Group/O365GroupConnectControl';
import { PpmxTimeConnectActions, PpmxTimeLinkInfo } from '../integration/PpmxTimeConnectControl';
import CalendlyPanel from '../../components/common/CalendlyPanel';
import { ConnectProps, RendersMap } from '../integration/TabConnectControl';
import FileConnectControl from '../integration/FileConnectControl';
import VSTSConnectControl, { isPartialLink, IVSTSLinkData, VSTSConnectionType } from '../integration/Vsts/VSTSConnectControl';
import SpoConnectControl from '../integration/Spo/SpoConnectControl';
import O365PlanConnectControl from '../integration/Office365/Planner/O365PlannerPlanConnectControl';
import MondayComConnectControl from '../integration/MondayCom/MondayComConnectControl';
import { IMondayComBaseSourceData } from '../../store/integration/MondayComStore';
import { ISmartsheetProjectSourceData } from '../../store/integration/SmartsheetStore';
import SmartsheetConnectControl from '../integration/Smartsheet/SmartsheetConnectControl';
import { MPPFileConnectControl } from '../integration/MPPFileConnectControl';
import { UserState } from '../../store/User';
import { IP4WProject } from '../../store/integration/P4WStore';
import P4WConnectControl from '../integration/P4W/P4WConnectControl';
import JiraConnectControl, { JiraConnectionType } from '../integration/Jira/JiraConnectControl';
import ExpandablePanel from '../common/ExpandablePanel';
import { notUndefined } from '../utils/common';

type OwnProps = {
    entity: ProjectsListStore.ProjectInfo;
    onDismiss: () => void;
}
type StateProps = {
    user: UserState;
    integrations: Integrations;
}
type Props = OwnProps & StateProps & {
    projectsActions: typeof ProjectsListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
};
type State = {
    selectedTab: string;
    selectedType?: SourceType;
    context?: any;
    isCalendlyPanelOpen?: boolean;
}

class ExternalEpmConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTab: "link",
            selectedType: props.entity.sourceInfos.find(SourceInfo.isCollaborative)?.type
                || SourceType_.collaborative.find(props.integrations.isEnabled)
        }
    }

    public render() {
        const linkProps = {
            ...this.props,
            rendersMap: this._rendersMap,
            readonly: !this.props.entity.isEditable,
            sourceInfos: this.props.entity.sourceInfos,
            actions: this.epmConnectControlActionsBuilder(),
            hiddenCollaborativeOptions: this.props.entity.isPrivate ? [SourceType.PpmxTime] : undefined,
            openCalendlyPanel: () => { this.setState({ isCalendlyPanelOpen: true }) }
        }

        return this.state.isCalendlyPanelOpen ? this._renderCalendlyPanel() : this._renderConfigureConnection(linkProps);
    }

    private _renderCalendlyPanel = (): JSX.Element => {
        return <CalendlyPanel onDismiss={() => this.setState({ isCalendlyPanelOpen: false })}> </CalendlyPanel>
    }

    private _renderConfigureConnection = (linkProps: any): JSX.Element => {
        return <ExpandablePanel
            isLightDismiss
            type={PanelType.custom}
            customWidth={PANEL_CUSTOM_WIDTH}
            className="connect-panel"
            isOpen={true}
            onDismiss={this.props.onDismiss}
            onRenderHeader={this._onRenderHeader}>
            <div className="entity-linking">
                <Pivot selectedKey={this.state.selectedTab} onLinkClick={_ => _ && this.setState({ selectedTab: _.props.itemKey! })}>
                    <PivotItem itemKey="link" headerText="Link to Project" itemIcon="PPMXLinkProject">
                        <LinkTab {...linkProps} />
                    </PivotItem>
                    <PivotItem itemKey="work-together" headerText="Work Together" itemIcon="PPMXWorkTogether">
                        <WorkTogetherTab {...linkProps}
                            selected={this.state.selectedType}
                            context={this.state.context} />
                    </PivotItem>
                </Pivot>
            </div>
        </ExpandablePanel>;
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure Connections</p>
            <div className='ms-Panel-secondaryText'>Connect to projects in external systems to setup collaboration process and aggregate all related activities.</div>
        </div>;
    }

    private epmConnectControlActionsBuilder = () => {
        const o365GroupActions: O365ConnectActions = {
            linkToO365Group: this.linkToO365Group,
            deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365Group)
        };
        const teamsActions: TeamsChannelActions = {
            linkToTeamsChannel: this.linkToTeamsChannel,
            deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365TeamsChannel)
        };
        const ppmxTimeProjectActions: PpmxTimeConnectActions = {
            linkToPpmxTimeProject: this.linkToPpmxTimeProject,
            deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.PpmxTime)
        };
        return {
            [SourceType.O365Group]: o365GroupActions,
            [SourceType.O365TeamsChannel]: teamsActions,
            [SourceType.PpmxTime]: ppmxTimeProjectActions,
            [SourceType.O365Planner]: {
                linkToItem: this.linkToPlannerPlan,
                createItemAndLink: this.createPlannerPlanAndLink,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365Planner)
            },
            [SourceType.Jira]: {
                linkToJiraProject: this.linkToJiraProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.Jira)
            },
            [SourceType.File]: {
                linkToFile: this.linkToFile,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.File)
            },
            [SourceType.MPPFile]: {
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.MPPFile)
            },
            [SourceType.VSTS]: {
                linkToVSTSProject: this.linkToVSTSProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.VSTS)
            },
            [SourceType.Spo]: {
                createPoProjectAndLink: this.createPoProjectAndLink,
                linkToPoProject: this.linkToPoProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.Spo)
            },
            [SourceType.MondayCom]: {
                linkToMondayComBoard: this.linkToMondayComBoard,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.MondayCom)
            },
            [SourceType.Smartsheet]: {
                linkToSmartsheetSheet: this.linkToSmartsheetSheet,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.Smartsheet)
            },
            [SourceType.P4W]: {
                linkToP4WProject: this.linkToP4WProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.P4W)
            }
        };
    }

    private linkToO365Group = (linkData: ILinkDto<ProjectsListStore.O365GroupLinkInfo>) => {
        this.props.projectsActions.linkToO365Group(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to o365 group has been started.` });
        analytics.trackLink('Linked Project to o365 group', this.props.user);
    }

    private linkToPlannerPlan = (linkData: ILinkDto<IPlanInfo> & ProjectsListStore.ISetAsPrimary) => {
        this.props.projectsActions.linkToPlannerPlan(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to plan in Planner has been started.` });

        analytics.trackLink('Linked Project to Planner plan', this.props.user);
    }

    private createPlannerPlanAndLink = (connectionId: string, planName: string, groupId: string, setAsPrimary?: boolean) => {
        this.props.projectsActions.linkToPlannerPlan(this.props.entity.id, 
            { connectionId, data: ({ owner: groupId, title: planName }) as unknown as IPlanInfo, setAsPrimary: !!setAsPrimary });
        this.props.notificationsActions.pushNotification(
            { message: `Linking project '${this.props.entity.attributes.Name}' to plan in Planner has been started. New plan "${planName}" will be created.` });

        analytics.trackLink('Linked Project to new Planner plan', this.props.user);
    }

    private deleteLink = (connectionId: string, type: SourceType) => {
        this.props.projectsActions.deleteProjectToExternalSystemLink(this.props.entity.id, connectionId, type);
        this.props.notificationsActions.pushNotification({ message: `Unlinking project '${this.props.entity.attributes.Name}' has been started.` });
    }

    private linkToJiraProject = (linkData: ILinkDto<IJiraLinkInfo> & ProjectsListStore.ISetAsPrimary) => {
        this.props.projectsActions.linkToJiraProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to Jira project has been started.` });

        analytics.trackLink('Linked Project to Jira project', this.props.user);
    }

    private linkToFile = (fileUrl: string) => {
        this.props.projectsActions.linkToFile(this.props.entity.id, fileUrl);
        analytics.trackLink('Linked Project to file', this.props.user);
    }

    private linkToVSTSProject = (linkData: ILinkDto<IVSTSLinkData> & ProjectsListStore.ISetAsPrimary) => {
        this.props.projectsActions.linkToVSTSProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to Azure DevOps project has been started.` });

        if (isPartialLink(linkData.data)) {
            analytics.trackLink('Linked Project to Azure DevOps project part', this.props.user);
        } else {
            analytics.trackLink('Linked Project to Azure DevOps project', this.props.user);
        }
    }

    private createPoProjectAndLink = (connectionId: string, name: string, setAsPrimary?: boolean) => {
        this.props.projectsActions.linkToPoProject(this.props.entity.id, { connectionId, data: ({ name: name }) as unknown as ISpoProject, setAsPrimary: !!setAsPrimary });
        this.props.notificationsActions.pushNotification(
            { message: `Linking project '${this.props.entity.attributes.Name}' to project in Project Online has been started. New project "${name}" will be created.` });
            
        analytics.trackLink('Linked Project to new Project Online project', this.props.user);
    }

    private linkToPoProject = (linkData: ILinkDto<ISpoProject> & ProjectsListStore.ISetAsPrimary) => {
        this.props.projectsActions.linkToPoProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to project in Project Online has been started.` });

        analytics.trackLink('Linked Project to Project Online project', this.props.user);
    }

    private linkToTeamsChannel = (linkData: ILinkDto<TeamsChannelLink>) => {
        this.props.projectsActions.linkToTeamsChannel(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to Teams channel has been started.` });
        analytics.trackLink('Linked Project to Teams channel', this.props.user);
    }

    private linkToPpmxTimeProject = (linkData: ILinkDto<PpmxTimeLinkInfo>) => {
        this.props.projectsActions.linkToPpmxTimeProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to PPM Express Time project has been started.` });
        analytics.trackLink('Linked Project to PPM Express Time project', this.props.user);
    }

    private linkToMondayComBoard = (linkData: ILinkDto<IMondayComBaseSourceData> & ProjectsListStore.ISetAsPrimary) => {
        this.props.projectsActions.linkToMondayComBoard(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to board in Monday.com has been started.` });

        analytics.trackLink('Linked Project to Monday.com board', this.props.user);
    }

    private linkToSmartsheetSheet = (linkData: ILinkDto<ISmartsheetProjectSourceData> & ProjectsListStore.ISetAsPrimary) => {
        this.props.projectsActions.linkToSmartsheetSheet(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking project '${this.props.entity.attributes.Name}' to sheet in Smartsheet has been started.` });

        analytics.trackLink('Linked Project to Smartsheet sheet', this.props.user);
    }

    private linkToP4WProject = (linkData: ILinkDto<IP4WProject> & ProjectsListStore.ISetAsPrimary) => {
        this.props.projectsActions.linkToP4WProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification(
            { message: `Linking project '${this.props.entity.attributes.Name}' to project in Project for the Web has been started.` });

        analytics.trackLink('Linked Project to Project for the Web project', this.props.user);
    }

    private _renderPlanner = (props: ConnectProps) => {
        return <O365PlanConnectControl
            {...props}
            {...this._buildPimarySwitchDialogProps()}
            allowNewItems
            showPlanGroupConnection
            key="planner"
            redirectTo={(type, context) => !this.props.integrations.isDisabled(type)
                && this.setState({ selectedTab: "work-together", selectedType: type, context: context })}
            connectToGroupEnabled={!this.props.entity.sourceInfos.find(_ => _.type === SourceType.O365Group)}
        />;
    }

    private _renderJira(props: ConnectProps) {
        return <JiraConnectControl key="jira" 
            allowConfigureConnection={true} 
            {...props} 
            {...this._buildPimarySwitchDialogProps()}
            connectionType={JiraConnectionType.Project} />;
    }

    private _renderFile(props: ConnectProps) {
        return <FileConnectControl key="file" {...props} />;
    }

    private _renderMPPFile(props: ConnectProps) {
        return <MPPFileConnectControl key="mpp-file" {...props} />
    }

    private _renderVSTS(props: ConnectProps) {
        return <VSTSConnectControl 
            key="vsts"
            connectionType={VSTSConnectionType.Project}
            {...props}
            {...this._buildPimarySwitchDialogProps()} />;
    }

    private _renderSpo(props: ConnectProps) {
        return <SpoConnectControl
            {...props}
            allowNewItems
            {...this._buildPimarySwitchDialogProps()}
            key="spo" />;
    }

    private _renderMondayCom(props: ConnectProps) {
        return <MondayComConnectControl
            {...props}
            {...this._buildPimarySwitchDialogProps()}
            key="mondaycom" />;
    }

    private _renderSmartsheet(props: ConnectProps) {
        return <SmartsheetConnectControl
            {...props}
            {...this._buildPimarySwitchDialogProps()}
            key="smartsheet" />;
    }

    private _renderP4W(props: ConnectProps) {
        return <P4WConnectControl
            {...props}
            allowNewItems={false}
            {...this._buildPimarySwitchDialogProps()}
            key="p4w" />;
    }

    private _buildPimarySwitchDialogProps = () => {
        const isPPMXTasksExists = this.props.entity.calculation.taskProgresses[ppmxTaskConnectionId]?.total > 0;
        return {
            setAsPrimary: !isPPMXTasksExists && !this.props.entity.sourceInfos.length,
            showSwitchPrimaryDialog: isPPMXTasksExists && !this.props.entity.sourceInfos.length
        };
    }

    private _rendersMap: RendersMap = {
        [SourceType.O365Planner]: { render: (props: ConnectProps) => this._renderPlanner(props), helpUrl: "https://help.ppm.express/89434-planner-connection" },
        [SourceType.Jira]: { render: (props: ConnectProps) => this._renderJira(props), helpUrl: "https://help.ppm.express/89435-jira-connection" },
        [SourceType.File]: { render: this._renderFile, helpUrl: undefined },
        [SourceType.MPPFile]: { render: this._renderMPPFile, helpUrl: "https://help.ppm.express/ppm-express-project-publisher" },
        [SourceType.VSTS]: { render: (props: ConnectProps) => this._renderVSTS(props), helpUrl: "https://help.ppm.express/89488-azure-devops-connection" },
        [SourceType.Spo]: { render: (props: ConnectProps) => this._renderSpo(props), helpUrl: "https://help.ppm.express/89437-project-online-connection" },
        [SourceType.MondayCom]: { render: (props: ConnectProps) => this._renderMondayCom(props), helpUrl: "https://help.ppm.express/mondaycom-connection" },
        [SourceType.Smartsheet]: { render: (props: ConnectProps) => this._renderSmartsheet(props), helpUrl: "https://help.ppm.express/smartsheet-connection" },
        [SourceType.P4W]: { render: (props: ConnectProps) => this._renderP4W(props), helpUrl: "https://help.ppm.express/project-for-the-web-connection" },
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        integrations: new Integrations(state.tenant.subscription.integrations)
    }
}

function mergeActionCreators(dispatch: any) {
    return {
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ExternalEpmConnectControl);