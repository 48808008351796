import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { bindActionCreators } from 'redux';
import { TenantState, actionCreators as tenantActionCreators } from '../../../../store/Tenant';
import Logo from '../../../common/Logo';

type StateProps = {
    tenant: TenantState;
};

type ActionProps = {
    tenantActions: typeof tenantActionCreators;
};

type Props = StateProps & ActionProps;

const UploadHeaderLogo = ({ tenant, tenantActions }: Props) => (
    <Logo
        className='tenant-logo'
        imageId={tenant.styleSettings.logoId}
        iconName='PPMXUploadLogoPlaceholder'
        onChanged={tenantActions.uploadLogo}
        onRemove={tenantActions.removeLogo}
        accept="image/png"
    />
);

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        tenant: state.tenant
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(UploadHeaderLogo);