import { BaseButton, DefaultButton, Dialog, DialogFooter, DialogType, Panel, PanelType, PrimaryButton } from 'office-ui-fabric-react';
import React, { useMemo, useState } from 'react'
import { bindActionCreators } from 'redux';
import * as ReportedTimeStore from '../../../store/ReportedTimeStore';
import { connect } from 'react-redux';
import DatePickerInput from '../../common/inputs/DatePickerInput';
import LabellableComponent from '../../common/LabellableComponent';
import { EntityType, entityTypeLabelMap, ITimeframe } from '../../../entities/common';
import { toDateTime } from '../../utils/common';
import { Validator } from '../../../validation';
import './ExportReportedTimePanel.css'
import { ControlSpiner } from '../../common/Spinner';

type OwnProps = {
    onDismiss: () => void;

    resourceIds?: string[];
    projectIds?: string[];
    
    timeFrame?: Partial<ITimeframe>;
};

type ActionProps = {
    reportedTimeActions: typeof ReportedTimeStore.actionCreators;
};

const today = new Date();


type Props = OwnProps & ActionProps;

type DateRangeOption = { label: string; start?: Date; end?: Date };

const _options: DateRangeOption[] = [
    { label: "Today", start: today, end: today },
    { label: "Yesterday", start: new Date(today).addDays(-1), end: new Date(today).addDays(-1) },
    { label: "This week", ...today.getThisWeek() }, 
    { label: "Last week", ...today.getWeekAgo().getThisWeek() },
    { label: "This month", ...today.getThisMonth() },
    { label: "Last month", ...new Date(today).getMonthStartDate().addDays(-1).getThisMonth() },
    { label: "This year", start: new Date(today.getFullYear(), 0, 1), end: new Date(today.getFullYear() + 1, 0, 1).addDays(-1) },
    { label: "All project time entries" },
];

function ExportReportedTimePanel(props: Props) {

    const { onDismiss, reportedTimeActions, resourceIds, projectIds } = props;

    const [isExporting, setIsExporting] = useState(false);

    const [timeFrame, setTimeFrame] = useState<Partial<ITimeframe>>(props.timeFrame ?? new Date().getThisMonth());
    
    const projectsEntityLabel = projectIds?.length === 1
        ? entityTypeLabelMap[EntityType.Project].singular.toLowerCase()
        : entityTypeLabelMap[EntityType.Project].plural.toLowerCase();
    
    
    const resourcesEntityLabel = resourceIds?.length === 1
        ? entityTypeLabelMap[EntityType.Resource].singular.toLowerCase()
        : entityTypeLabelMap[EntityType.Resource].plural.toLowerCase();
    return (
        <Panel
            type={PanelType.custom}
            
            onDismiss={onDismiss}
            onRenderHeader={() => (
                <div className="ms-Panel-header">
                    <p className="ms-Panel-headerText">Export to CSV</p>
                    <div className='ms-Panel-secondaryText'>Export reported time for <b>{resourceIds?.length}</b> {resourcesEntityLabel}
                        {!!projectIds?.length && <span> for <b>{projectIds.length}</b> {projectsEntityLabel}</span>}
                    </div>
                </div>
            )}
            headerText='Export to CSV'
            headerClassName='ms-Panel-headerText'
            className='export-reported-time-panel'
            isLightDismiss
            customWidth='400px'
            isOpen
            onRenderFooterContent={() => (
                <div className="commands">
                    <PrimaryButton disabled={isExporting} text={"Export"} onClick={() => {
                        setIsExporting(true);
                        reportedTimeActions.exportReportedTimeToFile(timeFrame.start!, timeFrame.end!, resourceIds, projectIds, onDismiss, () => setIsExporting(false));
                    }} />
                    <DefaultButton text={"Cancel"} onClick={onDismiss} />
                </div>
            )}>
            <ControlSpiner isLoading={isExporting } className="show-over">
                <div className='dates-range'>
                    <LabellableComponent label='Export Period Start'>
                        <DatePickerInput
                            value={timeFrame.start?.toDateOnlyString()}
                            onChanged={_ => {

                                const newStart = toDateTime(_);
                                const endDate = newStart && timeFrame.end && newStart > timeFrame.end
                                    ? newStart
                                    : timeFrame.end;

                                setTimeFrame({
                                    ...timeFrame,
                                    start: toDateTime(_),
                                    end: endDate
                                })
                            }} />
                    </LabellableComponent>

                    <LabellableComponent label='Export Period Finish'>
                        <DatePickerInput
                            value={timeFrame.end?.toDateOnlyString()}
                            onChanged={_ => {

                                const newEnd = toDateTime(_);
                                const newStart = newEnd && timeFrame.start && newEnd < timeFrame.start
                                    ? newEnd
                                    : timeFrame.start;

                                setTimeFrame({
                                    ...timeFrame,
                                    start: newStart,
                                    end: newEnd
                                })
                            }} />
                    </LabellableComponent>
                </div>

            <div className='period-options'>
                {_options.map(option => {
                
                    const isSelected = option.start?.toDateOnlyString() === timeFrame.start?.toDateOnlyString()
                        && option.end?.toDateOnlyString() === timeFrame.end?.toDateOnlyString();

                    return (
                        <BaseButton
                            key={option.label}
                            className={`period-option ${isSelected ? 'selected' : ''}`}
                            onClick={() => { setTimeFrame({ start: option.start, end: option.end }) }}>
                            {option.label}
                        </BaseButton>
                    );
                })}
                </div>
                
            </ControlSpiner>
        </Panel>
    );
}

function mergeActionCreators(dispatch: any, ownProps: OwnProps): ActionProps {
    return {
        reportedTimeActions: bindActionCreators(ReportedTimeStore.actionCreators, dispatch),
    }
}


export default connect(null, mergeActionCreators)(ExportReportedTimePanel);