import { Dictionary, IEditable, IUserInfo, IWithLayout, IWithStartFinishDates } from "../../entities/common";
import { IRoadmapItem, IRoadmapItemDependency } from "../../entities/Subentities";
import { IWithPinnedViews, IWithSections } from "../../entities/Metadata";

export interface Roadmap extends IEditable, IWithSections, IWithPinnedViews, IWithLayout
{
    id: string;
    attributes: IRoadmapAttrs & Dictionary<any>;
    roadmapItems: IRoadmapItem[];
    roadmapItemsDependencies: IRoadmapItemDependency[];
    canConfigure: boolean;
}

export type IRoadmapAttrs = IWithStartFinishDates & {
    Name: string;
    Manager: IUserInfo[];
    Description: string;
    Stage: RoadmapStage;
    CreatedDate: string;
    Tags: string[];
}

export enum RoadmapStage {
    Draft = 0,
    InReview = 1,
    Published = 2,
    Rejected = 3,
    Closed = 4
}

export const roadmapStageMap: { [i: number]: { title: string, cssClassName: string } } =
{
    [RoadmapStage.Draft]: {
        title: "Draft",
        cssClassName: "Draft",
    },
    [RoadmapStage.InReview]: {
        title: "In Review",
        cssClassName: "InReview"
    },
    [RoadmapStage.Published]: {
        title: "Published",
        cssClassName: "Published"
    },
    [RoadmapStage.Rejected]: {
        title: "Rejected",
        cssClassName: "Rejected"
    },
    [RoadmapStage.Closed]: {
        title: "Closed",
        cssClassName: "Closed"
    }
}