import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { UserState, isInReadonlyMode } from '../../store/User';
import { IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import { ILayoutActions, LayoutService } from '../utils/LayoutService';
import { EntityType } from '../../entities/common';
import { PPMFeatures, Subscription, TenantState } from '../../store/Tenant';
import { notUndefined } from '../utils/common';
import { LayoutsState } from '../../store/layouts';
import PersistEntityViewMenu from '../common/PersistEntityViewMenu';
import EntityHeader from '../common/EntityHeader';
import LinkedSystemIcons from '../common/sectionsControl/uiControls/summaryControl/LinkedSystemIcons';
import RemoveDialog from '../common/RemoveDialog';
import Logo from '../common/Logo';
import StageView from '../views/list/columns/StageView';
import { Idea, IdeaStage, ideaStagesMap } from '../../store/IdeasListStore';
import VotesToggle from '../common/VotesToggle';
import { UpgradeYourPlanDialog } from '../common/UpgradeYourPlanDialog';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import { Layout } from '../../entities/Metadata';
import EntityHeaderMoreMenu from '../common/EntityHeaderMoreMenu';

interface IActions {
    updateImage: (image: File) => void;
    removeImage: () => void;
    proposeIdea: () => void;
    activateIdea: () => void;
    reactivateIdea: () => void;
    selectIdea: () => void;
    rejectIdea: () => void;
    initiateProject: () => void;
    deactivateIdea: () => void;
    markAsDraftIdea: () => void;
    layoutActions: ILayoutActions;
    removeIdea: () => void;
    changeVote: (isVote: boolean) => void;
    openConfigurationPanel: () => void;
}

type OwnProps = {
    entity: Idea;
    actions: IActions;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
    layouts: LayoutsState;
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

const IdeaHeader = (props: Props) => {
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    const [isUpgradeYourPlanDialogOpen, setIsUpgradeYourPlanDialogOpen] = React.useState(false);
    const [layoutToApply, setLayoutToApply] = React.useState<Layout>();

    const { entity, actions, layouts, user, tenant } = props;

    const _initiateProject = () => {
        if (Subscription.contains(tenant.subscription, PPMFeatures.ProjectManagement)) {
            actions.initiateProject()
        } else {
            setIsUpgradeYourPlanDialogOpen(true);
        }
    }

    const moreMenuItems = [
        entity.canConfigure && actions.layoutActions.applyLayout
            ? {
                ...LayoutService.buildApplyLayoutMenuItem(layouts, (layout) => setLayoutToApply(layout), entity.layoutId),
                disabled: !entity.isEditable
            }
            : undefined,
        entity.attributes.Stage === IdeaStage.NotSelected
            || (entity.attributes.Stage === IdeaStage.Archived && !entity.attributes.ChildProject) ? {
            key: 'reactivate',
            iconProps: { iconName: "Refresh" },
            name: 'Activate Idea',
            disabled: !entity.canEditChallenge,
            onClick: () => actions.reactivateIdea()
        } : undefined,
        entity.attributes.Stage !== IdeaStage.Archived ? {
            key: 'deactivate',
            iconProps: { iconName: "Archive" },
            name: 'Archive Idea',
            disabled: !entity.canEditChallenge,
            onClick: () => actions.deactivateIdea()
        } : undefined,
        entity.attributes.Stage !== IdeaStage.Draft && !entity.attributes.ChildProject ? {
            key: 'markAsDraft',
            iconProps: { iconName: "EditNote" },
            name: 'Mark as Draft',
            disabled: !entity.canEditChallenge,
            onClick: () => actions.markAsDraftIdea()
        } : undefined,
        {
            key: 'deleteIdea',
            iconProps: { iconName: 'Delete' },
            name: 'Delete Idea',
            className: 'more-deleteButton',
            disabled: !entity.isEditable,
            onClick: () => setIsRemoveDialogOpen(true)
        }
    ].filter(notUndefined);

    const items: IContextualMenuItem[] = entity.isEditable
        ? [
            entity.attributes.Stage === IdeaStage.Draft ? {
                key: 'propose',
                name: "Propose",
                onClick: () => actions.proposeIdea()
            } : undefined,
            entity.canEditChallenge && entity.attributes.Stage === IdeaStage.Proposed ? {
                key: 'activate',
                name: "Activate Idea",
                onClick: () => actions.activateIdea()
            } : undefined,
            entity.canEditChallenge && entity.attributes.Stage === IdeaStage.Active ? {
                key: 'select',
                name: "Select Idea",
                onClick: () => actions.selectIdea()
            } : undefined,
            entity.canEditChallenge && entity.attributes.Stage === IdeaStage.Active ? {
                key: 'reject',
                name: "Reject Idea",
                onClick: () => actions.rejectIdea()
            } : undefined,
            entity.canEditChallenge && entity.attributes.Stage === IdeaStage.Selected && !entity.attributes.ChildProject ? {
                key: 'initiateProject',
                name: "Initiate new Project",
                onClick: () => _initiateProject()
            } : undefined
        ].filter(notUndefined)
        : [];
    const readonlyMode = isInReadonlyMode(user, tenant);

    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className="idea-logo"
                imageId={entity.imageId}
                onChanged={!entity.isEditable ? undefined : actions.updateImage}
                onRemove={!entity.isEditable ? undefined : actions.removeImage} />}
            leftSide={<>
                <StageView
                    value={entity.attributes.Stage}
                    className="idea-stage"
                    map={ideaStagesMap}
                    subMenuItems={items}
                />
                <VotesToggle
                    disabled={!entity.canCollaborate || entity.attributes.Stage !== IdeaStage.Active}
                    isVoted={entity.isCurentUserVoted}
                    votesCount={entity.votes}
                    onChange={actions.changeVote}
                />
            </>}
            buttons={<>
                {
                    entity.attributes.DiscussionUrl &&
                    <IconButton title="Open idea discussion in Teams"
                        href={entity.attributes.DiscussionUrl}
                        target="_blank"
                        iconProps={{ iconName: "PPMXTeamsLogo" }}
                        className="connect-launcher teams-discussion-link" />
                }
                <LinkedSystemIcons
                    sourceInfos={entity.sourceInfos}
                    showTitle
                    allowNavigate
                    entityType={EntityType.Idea} />
                <IconButton iconProps={{ iconName: 'PPMXLink' }}
                    className="connect-launcher"
                    title="Connect"
                    disabled={!entity.isEditable && !readonlyMode}
                    onClick={() => { actions.openConfigurationPanel(); }} />
                <EntityHeaderMoreMenu
                    entity={entity as any}
                    entityType={EntityType.Idea}
                    items={moreMenuItems} />
            </>}
            views={actions.layoutActions
                ? <PersistEntityViewMenu
                    layoutsState={layouts}
                    entity={entity}
                    entityType={EntityType.Idea}
                    readonlyMode={readonlyMode}
                    user={user}
                    viewLayout={actions.layoutActions?.viewLayout}
                    saveLayout={entity.isEditable ? actions.layoutActions?.saveLayout : undefined}
                    savePinnedViews={entity.canConfigure
                        ? actions.layoutActions?.updateEntityPinnedViews
                        : undefined}
                    saveEntityLayout={entity.canConfigure || readonlyMode
                        ? actions.layoutActions?.updateEntityLayout
                        : undefined}
                    deleteLayout={actions.layoutActions?.deleteLayout}
                />
                : undefined}
        ></EntityHeader>
        {
            isRemoveDialogOpen &&
            <RemoveDialog
                onClose={() => setIsRemoveDialogOpen(false)}
                onComplete={() => { actions.removeIdea(); }}
                dialogContentProps={{
                    title: "Delete idea",
                    subText: `Are you sure you want to delete idea "${entity!.attributes.Name}" ?`
                }}
                confirmButtonProps={{ text: "Delete" }} />
        }
        {
            layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => actions.layoutActions.applyLayout!(layoutToApply)}
                onDismiss={() => setLayoutToApply(undefined)}
                entityType={EntityType.Idea}
                layoutName={layoutToApply!.name}
            />
        }
        {isUpgradeYourPlanDialogOpen && <UpgradeYourPlanDialog onDismiss={() => setIsUpgradeYourPlanDialogOpen(false)} />}
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        layouts: state.layouts[EntityType.Idea]
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(IdeaHeader));