import * as React from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IBreadcrumbsRoute } from './navigation/common';
import Navigation from './navigation/Navigation';
import { actionCreators, IErrorProps } from '../store/ErrorStore';
import { Fabric } from 'office-ui-fabric-react';

type OwnProps = IErrorProps & {
    allowChangeAccount?: boolean;
};

type ActionProps = typeof actionCreators;

type Props = OwnProps & ActionProps & RouteComponentProps<{}>;

class ErrorApp extends React.Component<Props> {
    componentWillMount() {
        this.props.setError(this.props);
    }
    
    public render() {
        const route: IBreadcrumbsRoute = {
            breadcrumbs: [],
            path: this.props.location.pathname,
            hideNavigation: true,
            isAvailable: () => true
        };

        return <Fabric className="App">
            <Navigation route={route} router={this.props} allowChangeAccount={this.props.allowChangeAccount} />
        </Fabric>;
    }
}

export default withRouter<OwnProps>(connect(undefined, actionCreators)(ErrorApp));