import * as React from 'react';
import { Field } from "../../../../../entities/Metadata";
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";

type OwnProps = IListViewFieldColumn<ProjectInfo>;
type Props = OwnProps;

export const ProjectVisibility = (props: Props) => props.entity.isPrivate ? "Private" : "Public";

export function getValue(entity: ProjectInfo, field: Field): boolean | undefined {
    return entity.isPrivate;
}

export function getSearchValue(entity: ProjectInfo, field: Field): string {
    return entity.isPrivate ? "Private" : "Public";
}

export default ProjectVisibility;