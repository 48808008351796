import * as React from 'react';
import { BaseFilterAttribute } from './BaseFilterAttribute';
import { IUser } from '../../../store/UsersListStore';
import { PersonFromListPickerInput, PersonInfo } from '../inputs/PersonFromListPickerInput';
import { notUndefined } from '../../utils/common';

type OwnProps =
    {
        label: string;
        users: IUser[];
        value?: IUser[],
        onEditComplete: (data?: IUser[]) => void
    }

export const UsersFromListFilterAttribute = (props: OwnProps) => {
    const { value } = props;

    const _getLabelExtraDetails = () => {
        const { value } = props;

        if (!value?.length) {
            return { suffix: '', count: 0 };
        }

        const values = (value !== undefined && value !== null) ? value.map(_ => _.fullName) : [];
        return { suffix: values[0], count: values.length };
    }

    const _onEditComplete = (data: PersonInfo | PersonInfo[] | null) => 
    {
        let result: IUser[] | undefined = undefined;
        if (Array.isArray(data)) {
            if (data?.length > 0) {
                result = data?.map(_ => props.users.find(__ => __.id == _.id && __.email == _.email)).filter(notUndefined);
            }
        }
        else {
            const user = data as PersonInfo;
            const foundUser = props.users.find(__ => __.id == user?.id && __.email == user.email);
            result = foundUser ? [foundUser] : undefined;
        }
        props.onEditComplete(result);
    }

    return <>
        <BaseFilterAttribute hasValue={value !== undefined && value !== null}
            label={props.label}
            getLabelExtraDetails={_getLabelExtraDetails}>
            <PersonFromListPickerInput users={props.users.map(_ => (
                { id: _.id, fullName: _.fullName, email: _.email }))} onEditComplete={_onEditComplete}
                value={props.value} autoExpand={true} inputProps={{ autoFocus: true }} multichoice={true} />
        </BaseFilterAttribute>
    </>;
}
