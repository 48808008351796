import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import ErrorApp from './ErrorApp';

type State = { regionName?: string };

const AdRegions = [
    { name: "Azure AD (global service)", code: "0" },
    { name: "Azure AD for US Government", code: "1" },
    { name: "Azure AD Germany", code: "2" },
    { name: "Azure AD China", code: "3" }
];

class AuthErrorApp extends React.Component<RouteComponentProps<{}>, State> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);

        const regionCode = new URLSearchParams(this.props.location.search).get("azRegionCode");
        const region = AdRegions.find(_ => _.code === regionCode);
        this.state = { regionName: region?.name };
    }

    render() {
        if (!this.state.regionName) {
            return <Redirect to="/Error" />;
        }

        return <ErrorApp title="Authentication Error" description={`Unfortunately, ${this.state.regionName} is not supported.`} />;
    }
}

export default withRouter(AuthErrorApp);