import * as React from 'react';
import { connect } from 'react-redux';
import { Spinner, SpinnerSize, ComboBox, IComboBoxOption, PrimaryButton } from 'office-ui-fabric-react';
import { ApplicationState } from "../../../store/index";
import { actionCreators, IMondayComUser, MondayComUserKind, IMondayComUserLinkInfo, IMondayComConnectionState } from "../../../store/integration/MondayComStore";
import { ILinkDto, IListStore, validateConnection } from "../../../store/integration/common";
import OverlayComponent from "../../common/OverlayComponent";
import { ISelectableOption } from 'office-ui-fabric-react/lib/utilities/selectableOption/SelectableOption.types';
import MondayComConnectionSelect from '../MondayCom/MondayComConnectionSelect';
import { buildUserDisplayInfo } from './common';
import { ConnectionField } from '../ConnectionField';
import { usePrevious } from '../../utils/effects';

type OwnProps = {
    actions: {
        linkToUser: (linkData: ILinkDto<IMondayComUser>) => void;
        deleteUserLink: (connectionId: string) => void;
    };
    sourceData?: IMondayComUserLinkInfo;
    connectionId?: string;
    dismissPanel?: () => void;
}

type StateProps = { 
    users: IListStore<IMondayComUser>;
    connections: IMondayComConnectionState;
};
type Props = StateProps & typeof actionCreators & OwnProps;

const separator = "_";
const parseKey = (key: string): {userKind: MondayComUserKind, id: number} => {
    const parts = key.split(separator);
    return {userKind: parts[0] as MondayComUserKind, id: Number.parseInt(parts[1])};
} 
const buildKeyFromUser = (_: IMondayComUser) => [_.userKind, _.id].join(separator);
const onRenderItem = (props?: ISelectableOption, defaultRender?: (props?: ISelectableOption) => JSX.Element | null): JSX.Element | null => {
    if (!defaultRender) {
        return null;
    }

    const element = defaultRender(props);
    if (!props || !props.disabled) {
        return element;
    }
    return React.createElement("div", { title: "Already linked" }, element);
}
const linkToUser = (props: Props, connectionId?: string, user?: IMondayComUser) => {
    if (!connectionId || !user) {
        return;
    }
    
    props.actions.linkToUser({ connectionId, data: user });
    props.dismissPanel?.();
}

const MondayComConnectControl = (props: Props) => {
    const {sourceData, users, connections} = props;    
    const [isLinked, setIsLinked] = React.useState<boolean>(!!sourceData);
    const [options, setOptions] = React.useState<IComboBoxOption[]>([]);
    const [selectedUser, setSelectedKey] = React.useState<IMondayComUser | undefined>();
    const [connectionId, setConnectionId] = React.useState<string | undefined>(props.connectionId);
    
    const previousConnectionsVerification = usePrevious(props.connections.connectionsVerification);
    React.useEffect(() => { 
        const selectedConnection = validateConnection(connections, connectionId);
        if (connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false
            && selectedConnection?.isValidating === false && !selectedConnection.validationError) {
                props.loadUsers(connectionId)
        } 
    }, [connectionId && connections.connectionsVerification[connectionId]]);

    React.useEffect(() => {
        const o = users.entities.map(_ => ({
            key: buildKeyFromUser(_),
            text: buildUserDisplayInfo(_.displayName, _.email),
            disabled: _.isAlreadyLinked
        }))
        setOptions(o);
        sourceData && setUser(sourceData?.userKind, sourceData?.userId);
    }, [users.entities, sourceData]);
    React.useEffect(() => {setIsLinked(!!sourceData)}, [!!sourceData])

    const getComboboxValidationMessage = React.useCallback(() => {
        if (isLinked || !connectionId || props.users.isLoading) {
            return undefined;
        }

        return users.error 
            ? users.error
            : selectedUser
                ? selectedUser.isAlreadyLinked
                    ? "Resource already linked"
                    : undefined
                : `Please select resource from list`;
    }, []);

    const _onConnectionChange = (_?: string) => {
        if (isLinked) {
            return;
        }
        setConnectionId(_);
        setSelectedKey(undefined);
    }

    const deleteUserLink = () => {
        if (!connectionId) {
            return;
        }
    
        props.actions.deleteUserLink(connectionId);
        setSelectedKey(undefined);
    }

    const setSelectedUser = (key?: string) => {
        if(!key){
            setSelectedKey(undefined);
            return;
        }
        const { userKind, id } = parseKey(key);
        setUser(userKind, id);
    }
    const setUser = (userKind: MondayComUserKind, id: number) => {
        const user = users.entities.find(_ => _.userKind === userKind && _.id === id);
        setSelectedKey(user);
    }
    
    const userDisplayInfo = selectedUser?.displayName && selectedUser?.email && buildUserDisplayInfo(selectedUser.displayName, selectedUser.email)
        || sourceData && buildUserDisplayInfo(sourceData.displayName, sourceData.email);

    const isValid = validateConnection(props.connections, connectionId);

    return <>
        <div className="with-top-margin">
            <MondayComConnectionSelect
                disabled={isLinked}
                connectionId={connectionId}
                onChange={_onConnectionChange} />
        </div>
        <div className="with-top-margin">
            <OverlayComponent isOverlayed={!connectionId || isValid?.isValidating !== false || !!isValid?.validationError}>
                <ConnectionField
                    isLinked={isLinked}
                    label="Resource"
                    value={userDisplayInfo}>
                        <ComboBox
                            disabled={isLinked || !connectionId || users.isLoading}
                            placeholder="Select resource"
                            selectedKey={selectedUser ? buildKeyFromUser(selectedUser) : undefined}
                            useComboBoxAsMenuWidth
                            allowFreeform
                            options={options}
                            onRenderItem={onRenderItem}
                            onChange={(e, _) => setSelectedUser(_?.key as string | undefined)}
                            errorMessage={isValid?.isValidating === false && !isValid?.validationError ? getComboboxValidationMessage() : undefined} />
                </ConnectionField>
                {users.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                {!users.isLoading && users.error && <div className="error-message">{users.error}</div>}
                {
                    !isLinked && <PrimaryButton text="Link resource"
                        disabled={!selectedUser}
                        className="with-top-margin"
                        onClick={() => linkToUser(props, connectionId, selectedUser)} />
                }
                {
                    isLinked && <PrimaryButton text="Delete resource link"
                        className="with-top-margin"
                        onClick={deleteUserLink} />
                }
            </OverlayComponent>
        </div>
    </>
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { 
        users: state.mondayCom.users,
        connections: state.mondayCom.connections
    };
}

export default connect(mapStateToProps, actionCreators)(MondayComConnectControl);