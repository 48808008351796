import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/integration/JiraStore";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { IConnectionsState } from "../../../store/integration/common";
import JiraConnectionEdit from "../../integration/Jira/JiraConnectionEdit";
import BasicAuthConnectionCard from './BasicAuthConnectionCard';

type OwnProps = {}
type StateProps = { connections: IConnectionsState };
type Props = StateProps & OwnProps & typeof actionCreators;
const JiraConnectionCard = (props: Props) => <BasicAuthConnectionCard
    system={SourceType.Jira}
    connections={props.connections.data}
    renderConnect={(onCreated, onDismiss) => <JiraConnectionEdit
        onCreated={(connectionId: string) => {
            props.loadConnections();
            onCreated(connectionId);
        }}
        onDismiss={onDismiss}
    />}
/>;
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.jira.connections }
}
export default connect(mapStateToProps, actionCreators)(JiraConnectionCard);