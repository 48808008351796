import * as React from 'react';
import { ISubentity } from '../../../entities/Subentities';
import EntityName from '../../views/list/columns/EntityName';

type Props<TEntity> = {
    entity: TEntity;
    readOnly: boolean;
    subentityTypeLabel: string;
    operation?: string;
    suffixRender?: (entity: TEntity) => (JSX.Element | undefined);
}

const HeaderText = <TEntity extends ISubentity>({ entity, readOnly, subentityTypeLabel, operation, suffixRender: renderSuffix }: Props<TEntity>) =>
    <div className="align-center overflow-text">
        <div>
            {`${operation ? operation : readOnly ? "View" : "Edit"} ${subentityTypeLabel}`}
        </div>
        {EntityName._buildSourceType(entity, subentityTypeLabel).map(EntityName._renderIcon)}
        {renderSuffix?.(entity)}
    </div>;

export default React.memo(HeaderText);
