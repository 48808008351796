import "./ErrorMessageCallout.css";
import { connect } from "react-redux";
import { Callout, DirectionalHint, Icon } from "office-ui-fabric-react";
import React from "react";

const ErrorMessageCallout = (props: {
    controlId: string,
    error?: JSX.Element | string
}) => {
    const isVisible = !!props.controlId && !!props.error;
    if (!isVisible) {
        return <></>;
    }

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    return <Callout className="grid-cell-error"
            isBeakVisible={false}
            onDismiss={forceUpdate} //force refresh control on scroll
            directionalHint={DirectionalHint.bottomLeftEdge}
            target={`#${props.controlId}`}>
            <Icon key="icon" iconName="ErrorBadge" />
            <span className="info-message">{props.error}</span>
        </Callout>
};

export default connect()(ErrorMessageCallout)