import * as React from 'react';
import { bindActionCreators } from 'redux';
import { IPanelProps, PanelType, DefaultButton, TextField, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { IUser, actionCreators, LicensesUtilization } from "../../store/UsersListStore";
import { IPermissions, LicenseType, LicenseUtils } from '../../store/permissions';
import PermissionsEdit from './PermissionsEdit';
import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as tenantActionCreators, getDefaultPermissions, IDefaultPermissions, checkIfADUsersSyncEnabled } from "../../store/Tenant";
import DirtyPanel from '../common/DirtyPanel';
import { MultiActionConfirmationDialog } from '../common/MultiActionConfirmationDialog';

type UserEditOwnProps = {
    userId: string; onDismiss: () => void; readOnly: boolean; isCurrentUser: boolean;
}
type StateProps = {
    user: IUser;
    isADUsersSyncEnabled: boolean;
    licensesUtilization: LicensesUtilization;
    defaultPermissions?: IDefaultPermissions<IPermissions>;
};
type ActionProps = {
    userActions: typeof actionCreators;
    tenantActions: typeof tenantActionCreators;
};
type UserEditProps = UserEditOwnProps & ActionProps & StateProps;
type State = {
    user: IUser;
    isDirty: boolean;
    promptLicenseChange?: LicenseType;
}

const primaryCommandLabel = "Save";
const cancelCommandLabel = 'Cancel';

class UserEdit extends React.Component<UserEditProps, State> {
    constructor(props: UserEditProps) {
        super(props);
        this.state = this._buildState(props);
    }

    componentWillMount() {
        this.props.userActions.loadPermissions(this.props.user.id, this.props.user.license);
        !this.props.defaultPermissions && this.props.tenantActions.loadDefaultPermissions();
    }

    componentWillReceiveProps(nextProps: UserEditProps) {
        if (nextProps.userId !== this.props.userId) {
            this.props.userActions.loadPermissions(nextProps.user.id, nextProps.user.license);
        }

        this.setState(this._buildState(nextProps));
    }

    _buildState(props: UserEditProps): State {
        return {
            ...this.state,
            user: {
                ...props.user,
                permissions: props.user.permissions
            }
        }
    }

    public render() {
        const { user, isDirty, promptLicenseChange } = this.state;
        const { readOnly, isCurrentUser, defaultPermissions } = this.props;
        return <DirtyPanel
            className="user-edit"
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth="400px"
            isOpen={true}
            isDirty={isDirty}
            cancelCommandLabel={cancelCommandLabel}
            primaryCommandLabel={primaryCommandLabel}
            onDismiss={this.props.onDismiss}
            onRenderHeader={this._onRenderHeader}
            onRenderFooterContent={this._onRenderFooterContent}>
            <TextField
                label="User Name"
                disabled={true}
                value={user.fullName} />
            <TextField
                label="Email"
                disabled={true}
                value={user.email} />
            {user.permissions && defaultPermissions && <PermissionsEdit
                disableLicenseSelector={this.props.isADUsersSyncEnabled}
                license={user.license}
                readOnly={readOnly}
                isCurrentUser={isCurrentUser}
                permissions={user.permissions}
                onChange={this._onPermissionsChange}
                errorMessage={this._onGetLicenseErrorMessage()}
            />}
            {
                promptLicenseChange && <MultiActionConfirmationDialog
                    onDismiss={() => this.setState({ promptLicenseChange: undefined })}
                    dialogContentProps={{
                        title: 'Change license',
                        subText: 'Would you prefer to change the license only, retaining existing permissions, \
                            or change the license and overwrite permissions with the default permissions associated with the new license?'
                    }}
                    actionButtonProps={[
                        {
                            text: "Change License Only",
                            onClick: () => promptLicenseChange && this._applyLicenseChange(promptLicenseChange, false)
                        },
                        {
                            text: "Change License and Apply Default Permissions",
                            onClick: () => promptLicenseChange && this._applyLicenseChange(promptLicenseChange, true)
                        }
                    ]}>
                    <MessageBar isMultiline={true} messageBarType={MessageBarType.warning}>
                        Choosing to apply default permissions will result in the overwrite of all
                        pre-existing per-entity permissions with the license's default per-entity permissions.
                    </MessageBar>
                </MultiActionConfirmationDialog>
            }
        </DirtyPanel>;
    }

    private _onGetLicenseErrorMessage = () => {
        if (this.props.user.license !== this.state.user.license) {
            return LicenseUtils.getLicenseError(this.props.licensesUtilization, this.state.user.license, 1);
        }
        return undefined;
    }

    private _onPermissionsChange = (license: LicenseType, changes: Partial<IPermissions>) => {
        if (license === this.state.user.license) {
            this._applyPermissionsChange(changes);
        } else if (license === LicenseType.None || this.state.user.license === LicenseType.None) {
            this._applyLicenseChange(license, true);
        } else {
            this.setState({ promptLicenseChange: license });
        }
    }

    private _applyLicenseChange = (license: LicenseType, applyDefault: boolean) => {
        const { user } = this.state;

        const permissions: IPermissions = LicenseUtils.cutPermissions(license,
            applyDefault
                ? LicenseUtils.appendPermissionsToRemove(getDefaultPermissions(license, this.props.defaultPermissions) ?? LicenseUtils.buildEmptyPermissions(), user.permissions!)
                : user.permissions!
        );
        this.setState({ isDirty: true, user: { ...user, permissions, license } });
    }

    private _applyPermissionsChange = (changes: Partial<IPermissions>) => {
        const { user } = this.state;

        const permissions: IPermissions = { ...user.permissions!, ...changes };
        this.setState({ isDirty: true, user: { ...user, permissions } });
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">User settings</p>
            <div className='ms-Panel-secondaryText'>Apply user license and define permission level</div>
        </div>;
    }

    private _onRenderFooterContent = (props?: IPanelProps): JSX.Element | null => {
        const { readOnly } = this.props;
        return <div className="commands">
            {
                !readOnly && <DefaultButton
                    primary={true}
                    text={primaryCommandLabel}
                    iconProps={{ iconName: "Save" }}
                    onClick={this._save}
                    disabled={!!this._onGetLicenseErrorMessage()}
                />
            }
            <DefaultButton
                text={readOnly ? 'Close' : cancelCommandLabel}
                onClick={this.props.onDismiss}
            />
        </div>;
    }

    private _save = () => {
        const { id, permissions, license } = this.state.user;
        this.props.userActions.savePermissions(id, license, permissions!);
        this.props.onDismiss();
    }
}

function mapStateToProps(state: ApplicationState, ownProps: UserEditOwnProps): StateProps {
    const byId = state.users.byId;
    return {
        user: state.tenant.subscription.subscriptionId ? byId[ownProps.userId] : { ...byId[ownProps.userId], license: LicenseType.Regular },
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
        licensesUtilization: state.users.licensesUtilization,
        defaultPermissions: state.tenant.defaultPermissions
    }
}
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(actionCreators, dispatch),
        tenantActions: bindActionCreators(tenantActionCreators, dispatch)
    }
}
export default connect(mapStateToProps, mergeActionCreators)(UserEdit)