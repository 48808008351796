import * as React from 'react';
import { Link } from 'office-ui-fabric-react';
import { ITenantViewState } from '../../store/User';

type OwnProps = {
    tenant: ITenantViewState;
    disabled?: boolean;
    relativePath?: string;
}
type Props = OwnProps;

const TenantCard = (props: Props) => {
    const { disabled, tenant, relativePath } = props;
    const name = tenant.name || tenant.companyName;
    return <Link className="card ten-card" disabled={disabled} href={`/${tenant.region}/@${tenant.url}${relativePath || ''}`}>
        <div className="first-row">
            <div className="info">
                <div className="title" title={name}>
                    {name}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="title">
                {window.location.host}/{tenant.region}/@{tenant.url}
            </div>
        </div>
    </Link>
}

export default TenantCard;