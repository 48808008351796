import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { actionCreators, IIssueType, IJiraBoard, IJiraConnectionState, IJiraIssue, IJiraLinkInfo, IJiraSourceData } from "../../../store/integration/JiraStore";
import { Dictionary, ISourceInfo } from "../../../entities/common";
import JiraConnectionSelect from "./JiraConnectionSelect";
import OverlayComponent from "../../common/OverlayComponent";
import {
    ActionButton, IBasePickerSuggestionsProps, ISelectableOption,
    Label, Link, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize
} from "office-ui-fabric-react";
import { IJiraProject } from '../../../store/integration/JiraStore';
import { validateConnection, ILinkDto, IListStore } from "../../../store/integration/common";
import { DebouncedOptionsPicker, Option } from "../../common/inputs/OptionsPicker";
import { post } from "../../../fetch-interceptor";
import JiraConfigureConnectionPanel from "./JiraConfigureConnectionPanel";
import { usePrevious } from "../../utils/effects";
import { useDidMountEffect } from './../../utils/effects';
import FieldLabel from "../FieldLabel";
import SwitchPrimaryScheduleConfirmation from "../SwitchPrimaryScheduleConfirmation";
import { SourceType } from "../../../store/ExternalEpmConnectStore";

export type JiraConnectActions = {
    deleteLink: (connectionId: string) => void,
    linkToJiraProject: (linkInfo: ILinkDto<IJiraLinkInfo> & { setAsPrimary?: boolean }) => void
}

export enum JiraConnectionType {
    Project = "project",
    Program = "program"
}

type OwnProps = {
    actions: JiraConnectActions;
    readonly: boolean;
    sourceInfo?: ISourceInfo<IJiraSourceData>;
    allowMultipleConnectionsToOneProject?: boolean;
    allowConfigureConnection: boolean;
    dismissPanel?: () => void;
    connectionType: JiraConnectionType;
    showSwitchPrimaryDialog?: boolean;
    setAsPrimary?: boolean;
}

type StateProps = {
    projects: IListStore<IJiraProject>;
    boards: IListStore<IJiraBoard>;
    issueTypes: IListStore<IIssueType>;
    error: string | null;
    connections: IJiraConnectionState;
}

type Props = OwnProps & StateProps & typeof actionCreators;

const projectsToOptions = (projects: IJiraProject[]): Option[] => {
    return projects.map(_ => ({ key: _.id, text: _.key ? `${_.name} (${_.key})` : _.name })).sort((a, b) => a.text.localeCompare(b.text));
}

const boardsToOptions = (boards: IJiraBoard[]): Option[] => {
    return boards.map(_ => ({ key: _.id, text: _.name })).sort((a, b) => a.text.localeCompare(b.text));
}

const issuesToOptions = (issues: IJiraIssue[], issueTypes: IIssueType[], projects: IJiraProject[]): Option[] => {
    return issues.map(_ => {
        const projectKey = projects.find(p => p.id === _.projectId)?.key;
        return {
            key: _.id,
            text: projectKey ? `${_.name} (${projectKey})` : _.name,
            icon: issueTypes.find(t => t.name === _.type)?.iconUrl,
        }
    });
}

const linkToProject = (props: Props, connectionId: string | undefined, selectedProjects: Option[], selectedBoards: Option[],
    selectedIssues: Option[], idIssueMap: Dictionary<{ projectId: string, name: string }>, setAsPrimary: boolean) => {
    const { actions, issueTypes, dismissPanel } = props;

    if (connectionId) {
        const projects = selectedProjects.map(_ => {
            const project = props.projects.entities.find(p => _.key === p.id)!;
            return {
                id: project.id,
                name: project.name
            } as IJiraProject;
        });
        const boards = selectedBoards.map(_ => {
            const board = props.boards.entities.find(b => _.key === b.id)!;
            return {
                id: board.id,
                name: board.name,
                projectId: board.projectId
            } as IJiraBoard;
        });
        const issues = selectedIssues.map(_ => {
            const map = idIssueMap[_.key];
            return {
                id: _.key as string,
                name: map.name,
                type: issueTypes.entities.find(t => t.iconUrl === _.icon)?.name,
                projectId: map.projectId
            } as IJiraIssue
        });

        actions.linkToJiraProject({ connectionId, data: { projects, boards, issues }, setAsPrimary: setAsPrimary });
        dismissPanel?.();
    }

    return;
}

const deleteLink = (props: Props, connectionId: string) => {
    const { actions, dismissPanel } = props;

    actions.deleteLink(connectionId);
    dismissPanel?.();
}

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsClassName: "options-picker-container",
    noResultsFoundText: "No options",
    loadingText: 'Loading'
};

const jiraPickerProps: { notSelected: string, disabledClassName: string } = {
    notSelected: "Not selected",
    disabledClassName: "disabled-picker"
};

const JiraConnectControl = (props: Props) => {
    const { readonly, projects, boards, issueTypes, sourceInfo, allowConfigureConnection, connectionType, connections, showSwitchPrimaryDialog } = props;
    const [connectionId, setConnectionId] = React.useState<string | undefined>(sourceInfo?.connectionId);
    const [selectedProjectsInfo, setSelectedProjectsInfo] = React.useState<{ selectedProjects: Option[], taskImportSettingsKey?: string }>({ selectedProjects: [] });
    const setSelectedProjects = (selectedOptions: Option[]) => {
        const isSameAllTaskImportsettingsKey = projects.entities
            .filter(_ => selectedOptions.some(__ => __.key === _.id))
            .reduce<boolean>((acc, item, index, array) => index === 0 ? true : acc && (array[index - 1].taskImportSettingsKey === item.taskImportSettingsKey), false);
        const taskImportSettingsKey = isSameAllTaskImportsettingsKey ? projects.entities.find(_ => selectedOptions.some(__ => __.key === _.id))?.taskImportSettingsKey : undefined;
        setSelectedProjectsInfo({ selectedProjects: selectedOptions, taskImportSettingsKey })
    };
    const [selectedBoards, setSelectedBoards] = React.useState<{ option: Option, isValid?: boolean }[]>
        (boardsToOptions(sourceInfo?.sourceData?.boards ?? []).map(_ => ({ option: _, isValid: true })));
    const [selectedIssues, setSelectedIssues] = React.useState<Option[]>([]);
    const [idIssueMap, setIdIssueMap] = React.useState<Dictionary<{ projectId: string, name: string }>>({});
    const [isConfigureConnection, setIsConfigureConnection] = React.useState<boolean | undefined>();
    const [issuesSearchError, setIssuesSearchError] = React.useState<string | undefined>();
    const [projectsInputFocused, setProjectsInputFocused] = React.useState<boolean>(false);
    const [projectsWithoutPermissions, setProjectsWithoutPermissions] = React.useState<string[]>([]);
    const [showSwitchPrimaryScheduleConfirm, setShowSwitchPrimaryScheduleConfirm] = React.useState<boolean>(false);

    const isLinked = !!sourceInfo?.sourceData;
    const disabled = readonly || isLinked || !connectionId;
    const isValidConfiguration = !!selectedProjectsInfo.selectedProjects.length && !!connectionId;
    const connectionValidationInfo = validateConnection(connections, connectionId);
    const isConnectionInvalid = connectionValidationInfo?.isValidating !== false || !!connectionValidationInfo?.validationError;

    const projectsOptionsMap = React.useMemo(() => projectsToOptions(projects.entities), [projects]);
    const boardsOptionsMap = React.useMemo(() => {
        const boardsMap = boardsToOptions(boards.entities);
        return boardsMap.map(_ => ({ option: _, isValid: boards.entities.find(__ => __.id === _.key)?.isSprintsEnabled }));
    }, [boards]);

    const disableBoardsAndIssuesSelect = disabled || !selectedProjectsInfo.selectedProjects.length || !!projects.error;

    const previousConnectionsVerification = usePrevious(connections.connectionsVerification);

    const isSelectedConnectionValid = () => {
        const selectedConnection = validateConnection(connections, connectionId);
        return selectedConnection?.isValidating === false && !selectedConnection.validationError;
    }

    useDidMountEffect(() => {
        connectionId && previousConnectionsVerification?.[connectionId]?.isValidating !== false && isSelectedConnectionValid() && props.loadProjects(connectionId);
    }, [connectionId && connections.connectionsVerification[connectionId]]);

    useDidMountEffect(() => {
        if (connectionId && isSelectedConnectionValid()) {
            const selectedProjectIds = selectedProjectsInfo.selectedProjects.map(_ => _.key as string);
            if (selectedProjectIds.length && !isCrossprojectLinkingDisabled() && !projectsWithoutPermissions.length) {
                connectionType === JiraConnectionType.Project && props.loadBoards(connectionId, selectedProjectIds);
                props.loadIssueTypes(connectionId, selectedProjectIds[0]);
            }
        }
    }, [selectedProjectsInfo.selectedProjects, connectionId && connections.connectionsVerification[connectionId]]);

    React.useEffect(() => {
        if (!isConfigureConnection && sourceInfo?.sourceData?.projects && projects.entities.length) {
            const sourceDataProjectIds = sourceInfo.sourceData.projects.map(p => p.id);
            let jiraProjects = projects.entities.filter(_ => sourceDataProjectIds.includes(_.id));
            if (isLinked) {
                const unavailableProjects = sourceInfo.sourceData.projects.filter(_ => !projects.entities.map(p => p.id).includes(_.id));
                if (unavailableProjects.length) {
                    post<string[]>('api/integration/jira/availablePojects', { connectionId: connectionId, projectIds: sourceDataProjectIds })
                        .then(_ => setProjectsWithoutPermissions(sourceDataProjectIds.filter(p => !_.includes(p))))
                        .catch(_ => setProjectsWithoutPermissions([]));
                }

                jiraProjects = jiraProjects.concat(unavailableProjects);
            }

            setSelectedProjects(projectsToOptions(jiraProjects));
        }
    }, [projects, isConfigureConnection]);

    React.useEffect(() => {
        const sourceData = sourceInfo?.sourceData;
        if (!isConfigureConnection && sourceData?.issues && sourceData?.issues && sourceData?.projects && issueTypes.entities.length) {
            const newSelectedProjects = projects.entities.filter(_ => sourceData.projects.map(p => p.id).includes(_.id));
            setSelectedIssues(issuesToOptions(sourceData.issues, issueTypes.entities, newSelectedProjects));
        }

    }, [issueTypes, projects, isConfigureConnection]);

    const onConnectionChange = React.useCallback((_: string | undefined) => {
        if (!isLinked) {
            setConnectionId(_);
            setSelectedIssues([]);
            setSelectedBoards([]);
            setSelectedProjects([]);
        }
    }, []);

    const onProjectChange = (newSelectedProjects: Option[]) => {
        const selectedProjectIds = newSelectedProjects.map(_ => _.key as string);

        if (selectedIssues.length || selectedBoards.length) {
            const boardsProjectIdMap = selectedBoards.map(_ => ({ board: _, projectId: boards.entities.find(b => b.id === _.option.key)!.projectId }))
            const newSelectedBoards = boardsProjectIdMap.filter(_ => selectedProjectIds.includes(_.projectId)).map(_ => _.board);
            setSelectedBoards(newSelectedBoards);

            const isssueProjectIdMap = selectedIssues.map(_ => ({ issue: _, projectId: idIssueMap[_.key].projectId }));
            const newSelectedIssues = isssueProjectIdMap.filter(_ => selectedProjectIds.includes(_.projectId)).map(_ => _.issue);
            setSelectedIssues(newSelectedIssues);
        }
    };

    const onResolveSuggestions = (options: ISelectableOption[], template: string, selectedItems?: Option[]): Option[] => {
        if (!template) { return []; }
        return options.filter(_ => (_.key === template || _.text.toLowerCase().includes(template.toLowerCase())) && !selectedItems?.map(__ => __.key).includes(_.key));
    }

    const onResolveIssuesSuggestions = (template: string, selectedItems?: Option[]): PromiseLike<Option[]> | Option[] => {
        if (!template) {
            setIssuesSearchError(undefined);
            return [];
        }

        const exceptIds = selectedItems?.length
            ? selectedItems.map(_ => _.key as string)
            : [];

        const projectsIds = selectedProjectsInfo.selectedProjects.map(_ => _.key as string);
        return post<IJiraIssue[]>(`api/integration/jira/issues`, { connectionId: connectionId, projectIds: projectsIds, template: template, exceptIds: exceptIds })
            .then(issues => {
                const resolvedIssuesMap = issues.reduce<Dictionary<{ projectId: string, name: string }>>((acc, issue) => {
                    if (!acc[issue.id]) {
                        acc[issue.id] = { projectId: issue.projectId, name: issue.name };
                    }
                    return acc;
                }, {});
                setIdIssueMap({ ...resolvedIssuesMap, ...idIssueMap });
                return issuesToOptions(issues, issueTypes.entities, projects.entities)
            })
            .catch(error => {
                setIssuesSearchError(error?.response?.data?.errorMessage);
                return [];
            });
    }

    const getProjectFieldValidationMessage = () => {
        if (!connectionId || projects.isLoading || isConnectionInvalid) { return undefined; }

        if (projects.error) { return projects.error; }

        if (projectsWithoutPermissions.length) {
            return `You do not have enough permissions to view this projects (${projectsWithoutPermissions.join(', ')})`;
        }

        if (!selectedProjectsInfo.selectedProjects.length && projects.entities.length && !projectsInputFocused) {
            return 'Please select project';
        }

        if (!isLinked && selectedProjectsInfo.selectedProjects.length === 1 && !selectedIssues.length) {
            const project = projects.entities.find(_ => selectedProjectsInfo.selectedProjects.map(__ => __.key).includes(_.id));

            if (project?.isAlreadyLinked) {
                return "Project is already linked. You can link it to an issue or add another project."
            }
        }

        return undefined;
    }

    const getBoardsFieldValidationMessage = () => {
        if (!connectionId || boards.isLoading || isConnectionInvalid || !selectedProjectsInfo.selectedProjects.length) { return undefined };

        if (boards.error) { return boards.error; }

        const boardsWithoutSprints = selectedBoards.filter(_ => !_.isValid);
        if (!!selectedBoards.length && boardsWithoutSprints.length) {
            return `Boards (${boardsWithoutSprints.map(_ => _.option.text).join(', ')}) do not contain sprints. 
            Enable Sprints in Jira 'Project Settings' or select a boards that contains sprints.`
        }

        return undefined;
    }

    const isCrossprojectLinkingDisabled = (): boolean => !!selectedProjectsInfo.selectedProjects.length && !selectedProjectsInfo.taskImportSettingsKey;

    return <>
        <div key="select-connection-container" className="with-top-margin spinner-container">
            <JiraConnectionSelect
                disabled={isLinked || readonly}
                connectionId={connectionId}
                onChange={onConnectionChange} />
        </div>
        <div key="select-project-container" className="with-top-margin">
            <div className="with-top-margin spinner-container">
                <Label>Project</Label>
                <OverlayComponent isOverlayed={!connectionId || isConnectionInvalid}>
                    <DebouncedOptionsPicker
                        inputProps={{
                            placeholder: selectedProjectsInfo.selectedProjects.length === 0
                                ? !isLinked
                                    ? "Search project by Key, Name or Id"
                                    : jiraPickerProps.notSelected
                                : undefined,
                            onFocus: () => setProjectsInputFocused(true),
                            onBlur: () => setProjectsInputFocused(false),
                        }}
                        disabledEmptyInputFocus
                        pickerSuggestionsProps={pickerSuggestionsProps}
                        selectedItems={selectedProjectsInfo.selectedProjects}
                        className={disabled || projects.isLoading ? jiraPickerProps.disabledClassName : undefined}
                        disabled={disabled || projects.isLoading}
                        onChange={(value?: Option[]) => {
                            onProjectChange(value || []);
                            setSelectedProjects(value || []);
                        }}
                        onResolveSuggestions={(template, selectedItems) => onResolveSuggestions(projectsOptionsMap, template, selectedItems)}
                    />
                    {<div className='error-message'>{getProjectFieldValidationMessage()}</div>}
                    {projects.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -29, width: 30, position: 'absolute' }} />}
                </OverlayComponent>
            </div>
            {
                connectionType === JiraConnectionType.Project &&
                <div className="with-top-margin spinner-container">
                    <Label>
                        <FieldLabel 
                            label="Board"
                            title="Select the board(s) to synchronize Sprints from the Jira project(s) to Iterations Section"/>
                    </Label>
                    <OverlayComponent isOverlayed={!selectedProjectsInfo.selectedProjects.length}>
                        <DebouncedOptionsPicker
                            inputProps={{
                                placeholder: selectedBoards.length === 0
                                    ? !isLinked
                                        ? "Search board by Name or Id"
                                        : jiraPickerProps.notSelected
                                    : undefined
                            }}
                            disabledEmptyInputFocus
                            pickerSuggestionsProps={pickerSuggestionsProps}
                            selectedItems={selectedBoards.map(_ => _.option)}
                            className={boards.isLoading || disableBoardsAndIssuesSelect ? jiraPickerProps.disabledClassName : undefined}
                            disabled={boards.isLoading || disableBoardsAndIssuesSelect}
                            onChange={(value?: Option[]) => {
                                if (value) {
                                    const newSelectedBoards = boardsOptionsMap.filter(_ => value.find(__ => __.key === _.option.key));
                                    setSelectedBoards(newSelectedBoards);
                                } else {
                                    setSelectedBoards([]);
                                }
                            }}
                            onResolveSuggestions={(template, selectedItems) => onResolveSuggestions(boardsOptionsMap.map(_ => _.option), template, selectedItems)}
                        />
                        {<div className='error-message'>{getBoardsFieldValidationMessage()}</div>}
                        {boards.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -29, width: 30, position: 'absolute' }} />}
                    </OverlayComponent>
                </div>
            }
            <div className="with-top-margin spinner-container">
                <Label>
                    <FieldLabel 
                        label="Parent Issue" 
                        title={`To link the PPM Express ${connectionType} to one or several issues instead of the whole Jira project, select the issues in the field`} />
                </Label>
                <OverlayComponent isOverlayed={!selectedProjectsInfo.selectedProjects.length}>
                    <DebouncedOptionsPicker
                        inputProps={{
                            placeholder: selectedIssues.length === 0
                                ? !isLinked
                                    ? "Search issue by Key, Name or ID"
                                    : jiraPickerProps.notSelected
                                : undefined
                        }}
                        pickerSuggestionsProps={pickerSuggestionsProps}
                        disabledEmptyInputFocus
                        selectedItems={selectedIssues}
                        className={issueTypes.isLoading || disableBoardsAndIssuesSelect ? jiraPickerProps.disabledClassName : undefined}
                        disabled={issueTypes.isLoading || disableBoardsAndIssuesSelect}
                        onChange={(value?: Option[]) => setSelectedIssues(value || [])}
                        onResolveSuggestions={onResolveIssuesSuggestions}
                    />
                    {<div className='error-message'>{issuesSearchError}</div>}
                    {issueTypes.isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -29, width: 30, position: 'absolute' }} />}
                </OverlayComponent>
            </div>
            <OverlayComponent isOverlayed={!selectedProjectsInfo.selectedProjects}>
                {
                    isCrossprojectLinkingDisabled() && <MessageBar messageBarType={MessageBarType.warning}>
                        <div>
                            The link cannot be established because either multiple Jira projects were selected with different Issue type schemes, or a connection was made via the 'Restricted connection' option.
                            <Link href="https://help.ppm.express/89435-jira-connection/1061408-how-to-import-link-projects-from-jira-to-ppm-express#section-1" target="_blank">
                                Learn more
                            </Link>
                        </div>
                    </MessageBar>
                }
                {
                    allowConfigureConnection &&
                    <div className="flex-space-between with-top-margin">
                        <ActionButton
                            disabled={!isValidConfiguration || isConnectionInvalid || !selectedProjectsInfo.taskImportSettingsKey}
                            text="Configure connection"
                            iconProps={{ iconName: "PPMXGear" }}
                            onClick={() => setIsConfigureConnection(true)} />
                    </div>
                }
                {
                    isConfigureConnection && selectedProjectsInfo.taskImportSettingsKey?.length &&
                    <JiraConfigureConnectionPanel
                        connectionId={connectionId!}
                        projectId={selectedProjectsInfo.selectedProjects[0].key as string}
                        enableMapping={selectedProjectsInfo.selectedProjects.length === 1 && selectedIssues.length === 1}
                        onDismiss={() => setIsConfigureConnection(false)} />
                }
            </OverlayComponent>
            {
                !isLinked && !isCrossprojectLinkingDisabled() && <PrimaryButton text={`Link project${selectedIssues.length > 0 ? " part" : ''}`}
                    disabled={disabled || !selectedProjectsInfo.selectedProjects.length || !selectedBoards.every(_ => _.isValid)
                        || !!projects.error || !!boards.error || !!issuesSearchError}
                    className="with-top-margin"
                    onClick={() => {
                        if (showSwitchPrimaryDialog) {
                            setShowSwitchPrimaryScheduleConfirm(true);
                            return;
                        }
                        linkToProject(props, connectionId, selectedProjectsInfo.selectedProjects, selectedBoards.map(_ => _.option),
                            selectedIssues, idIssueMap, !!props.setAsPrimary);
                    }} />
            }
            {
                isLinked && <PrimaryButton text="Delete project link"
                    className="with-top-margin"
                    disabled={readonly || (sourceInfo && sourceInfo.syncStatus.inProgress)}
                    title={!!(sourceInfo && sourceInfo.syncStatus.inProgress) ? "Sync in progress." : undefined}
                    onClick={() => deleteLink(props, connectionId!)} />
            }
            {showSwitchPrimaryScheduleConfirm && <SwitchPrimaryScheduleConfirmation
                from={SourceType.Ppmx} to={SourceType.Jira}
                onDismiss={() => setShowSwitchPrimaryScheduleConfirm(false)}
                onClick={(isPrimary) => linkToProject(props, connectionId, selectedProjectsInfo.selectedProjects,
                    selectedBoards.map(_ => _.option), selectedIssues, idIssueMap, isPrimary)} />
            }
        </div>
    </>
}

function mapStateToProps(state: ApplicationState) {
    return {
        projects: state.jira.projects,
        boards: state.jira.boards,
        issueTypes: state.jira.issueTypes,
        isLoading: state.jira.projects.isLoading,
        error: state.jira.projects.error,
        connections: state.jira.connections
    };
}

export default connect(mapStateToProps, actionCreators)(JiraConnectControl);