import * as React from "react";
import * as Metadata from '../../../entities/Metadata';
import { TextField, IconButton, ITextField, ITextFieldProps } from "office-ui-fabric-react";
import { PPMXColorPicker } from "../../common/PPMXColorPicker/PPMXColorPicker";

type Props = {
    index: number;
    option: DraggableOption;
    isUnique: boolean;
    hideRemoveColorButton?: boolean;
    autoFocus?: boolean;
    textFieldProps?: ITextFieldProps;
    cancelButtonTitle?: string;
    onChange: (option: DraggableOption) => void;
    onRemove?: (id: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export type DraggableOption<T = Metadata.Option> = T & { id: string, key?: string };

const OptionEdit = (props: Props) => {
    const { index, option, isUnique, hideRemoveColorButton, autoFocus, textFieldProps, cancelButtonTitle, onRemove, onBlur } = props;
    const ref = React.useRef<ITextField>(null);
    React.useEffect(() => {
        if (!autoFocus || option.key === undefined) {
            ref.current?.focus();
        }
    }, []);
    return <div className="option" key={index}>
        <TextField
            componentRef={ref}
            onRenderSuffix={() => <PPMXColorPicker
                color={option.color}
                onChange={color => { props.onChange({ ...option, color: color }); ref.current?.focus(); }}
                hideRemoveColorButton={hideRemoveColorButton}
            />}
            value={option.name}
            onChange={(_, value = '') => props.onChange({ ...option, name: value })}
            validateOnLoad={false}
            errorMessage={option.name.trim() === ""
                ? "Please enter value"
                : !isUnique
                    ? "Same option already exists"
                    : undefined}
            className="value"
            onBlur={onBlur}
            {...textFieldProps}
        />
        {
            onRemove && <IconButton
                className="cancel-option-edit-btn"
                title={cancelButtonTitle}
                iconProps={{ iconName: 'Cancel' }}
                onClick={() => onRemove(option.id)} />
        }
    </div >
}

export default OptionEdit;