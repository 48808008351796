import {
    CheckboxVisibility,
    CommandBar,
    DetailsListLayoutMode,
    DirectionalHint,
    IColumn,
    IContextualMenuItem,
    IconButton,
    SelectionMode
} from "office-ui-fabric-react";
import React from "react";
import DetailsListWrapper from "../../../components/common/DetailsListWrapper";
import RemoveDialog from "../../../components/common/RemoveDialog";
import { ControlSpiner } from "../../../components/common/Spinner";
import { TimeTrackingAdministrativeCategory, TimeTrackingAdministrativeCategoryTypeLabelsMap } from "../../../store/Tenant";
import { nameof } from "../../../store/services/metadataService";
import AdministrativeCategoryEdit from "./AdministrativeCategoryEdit";
import { actionCreators as tenantActionCreators} from "../../../store/Tenant";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { Dispatch, bindActionCreators } from "redux";
import TimeTrackingAdministrativeCategoryEntityName from "../../../components/timeTracking/TimeTrackingAdministrativeCategoryEntityName";
import { UserState } from "../../../store/User";
import { CommonOperations, contains } from "../../../store/permissions";

type StateProps = {
    categories: TimeTrackingAdministrativeCategory[];
    isLoading: boolean;
    user: UserState;
};

type ActionProps = {
    tenantActions: typeof tenantActionCreators;
};

type Props = StateProps & ActionProps;

const TimeTrackingAdministrative = (props: Props) => {

    const { isLoading, categories, tenantActions, user } = props;

    const canManage = contains(user.permissions.common, CommonOperations.TimeTrackingManage);

    const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>();
    const [showPanel, setShowPanel] = React.useState<boolean>();
    const [selectedCategory, setSelectedCategory] = React.useState<TimeTrackingAdministrativeCategory>();

    const commands: IContextualMenuItem[] = [
        {
            key: "addRow",
            name: `New Administrative Category`,
            iconProps: { iconName: "Add" },
            disabled: !canManage,
            onClick: () => {
                setShowPanel(true);
                setSelectedCategory(undefined);
            }
        }
    ];

    const columns: IColumn[] = [
        {
            key: nameof<TimeTrackingAdministrativeCategory>("title"),
            fieldName: nameof<TimeTrackingAdministrativeCategory>("title"),
            name: "Category Name",
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
            onRender: (category: TimeTrackingAdministrativeCategory) => {

                const menuItems: IContextualMenuItem[] = [
                    {
                        key: 'edit',
                        name: 'Edit',
                        iconProps: { iconName: 'Edit' },
                        disabled: !canManage,
                        onClick: () => {
                            setShowPanel(true);
                            setSelectedCategory(category);
                        }
                    },
                    {
                        key: "disableRow",
                        name: category.isEnabled ? "Deactivate" : "Activate",
                        iconProps: { iconName: category.isEnabled ? "Blocked2" : "AddTo" },
                        disabled: !canManage,
                        onClick: () => {
                            category.isEnabled = !category.isEnabled;
                            tenantActions.createOrUpdateTimeTrackingAdministrativeCategory(category);
                        }
                    },
                    {
                        key: 'delete',
                        name: 'Delete',
                        iconProps: { iconName: "Delete", style: { color: 'red' } },
                        disabled: !canManage,
                        onClick: () => {
                            setShowRemoveDialog(true);
                            setSelectedCategory(category);
                        }
                    },
                ];

                return (
                    <div className="flex-space-between full-width">
                        <div className="overflow-text">
                            <TimeTrackingAdministrativeCategoryEntityName
                                categoryId={category.id}
                                onClick={() => {
                                    setShowPanel(true);
                                    setSelectedCategory(category);
                                }}
                            />
                        </div>
                        <IconButton
                            menuIconProps={{ iconName: 'PPMXMore' }}
                            menuProps={{
                                directionalHint: DirectionalHint.bottomRightEdge,
                                items: menuItems
                            }}
                        />
                    </div>
                );
            }
        },
        {
            key: nameof<TimeTrackingAdministrativeCategory>("isEnabled"),
            fieldName: nameof<TimeTrackingAdministrativeCategory>("isEnabled"),
            name: "Status",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            onRender: (category: TimeTrackingAdministrativeCategory) => {
                    
                const status = category.isEnabled ? "Active" : "Inactive";

                return (
                    <div className="administrative-category-status">
                        <div className={`align-center status ${status}`}> {status} </div>
                    </div>
                )
            }
        },
        {
            key: nameof<TimeTrackingAdministrativeCategory>("type"),
            fieldName: nameof<TimeTrackingAdministrativeCategory>("type"),
            name: "Type",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            onRender: (category: TimeTrackingAdministrativeCategory) => (
                <div className="overflow-text" title={TimeTrackingAdministrativeCategoryTypeLabelsMap[category.type]}>
                    {TimeTrackingAdministrativeCategoryTypeLabelsMap[category.type]}
                </div>
            )
        },
        {
            key: nameof<TimeTrackingAdministrativeCategory>("description"),
            fieldName: nameof<TimeTrackingAdministrativeCategory>("description"),
            name: "Description",
            minWidth: 120,
            
            isResizable: true,
            onRender: (category: TimeTrackingAdministrativeCategory) => (
                <div className="overflow-text" title={category.description}>{category.description}</div>
            )
        }
    ];

    return (
        <ControlSpiner isLoading={!!isLoading} className="show-over">
            <div className="report-management">
                {showPanel && (
                    <AdministrativeCategoryEdit
                        existingCategories={categories}
                        category={selectedCategory}
                        onSave={_ => {
                            tenantActions.createOrUpdateTimeTrackingAdministrativeCategory(_);
                        }}
                        onDismiss={() => setShowPanel(false)}
                    />
                )}
                <div className="entities-list">
                    <CommandBar items={commands} />
                    <DetailsListWrapper
                        layoutMode={DetailsListLayoutMode.justified}
                        items={categories}
                        columns={columns}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        selectionMode={SelectionMode.none}
                    />
                </div>
                {showRemoveDialog && !!selectedCategory && (
                    <RemoveDialog
                        onClose={() => setShowRemoveDialog(false)}
                        onComplete={() => {
                            tenantActions.deleteTimeTrackingAdministrativeCategory(selectedCategory.id);
                            setShowRemoveDialog(false);
                        }}
                        dialogContentProps={{
                            title: `Delete Category`,
                            subText: `Are you sure you want to delete Administrative Category 
                            "${selectedCategory.title}"?`
                        }}
                        confirmButtonProps={{ text: "Delete" }}
                    />
                )}
            </div>
        </ControlSpiner>
    );
};

const mapStateToProps = (_: ApplicationState): StateProps => ({
    categories: _.tenant.timeTracking.administrativeCategories,
    isLoading: _.tenant.isLoading,
    user: _.user
});

function mapDispatchToProps(dispatch: Dispatch): ActionProps {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingAdministrative);
