import * as React from "react";
import { IBreadcrumbsRoute, IBreadcrumb } from "./common";
import { Icon } from "office-ui-fabric-react";
import { NavLink } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import { notUndefined } from "../utils/common";

export type NavigationProps = {
    route: IBreadcrumbsRoute;
    router: RouteComponentProps<any>;
};

type OwnProps = {
    route: IBreadcrumbsRoute;
};

type StateProps = {
    state: ApplicationState;
};

type RouterProps = RouteComponentProps<{ id: string }>;

type Props = OwnProps & StateProps & RouterProps;

const Breadcrumbs = (props: Props) => {
    const { breadcrumbs, hideNavigation } = props.route;

    return (
        <ul className="breadcrumbs">
            {!hideNavigation && breadcrumbs.map((_, i) => {
                const title = getTitle(_);
                return title ? <li key={_.path} className="breadcrumb-container">
                    <NavLink className="breadcrumbs-title" to={getUrl(_) || _.path}>{title}</NavLink>
                    {i < breadcrumbs.length - 1 ? <Icon className="breadcrumbs-separator" iconName="PPMXBreadcrumbsSeparator" /> : null}
                </li>  : null;
            })
            .filter(notUndefined)}
        </ul>
    );

    function getTitle(breadcrumb: IBreadcrumb) {
        if (typeof breadcrumb.title == "function") {
            return breadcrumb.title(props.state, props.match.params.id);
        }

        return breadcrumb.title;
    }

    function getUrl(breadcrumb: IBreadcrumb) {
        if (typeof breadcrumb.url == "function") {
            return breadcrumb.url(props.state, props.match.params.id);
        }

        return breadcrumb.url;
    }
};

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        state,
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps)(Breadcrumbs));
