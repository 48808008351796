import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';

export type Card = {
    title: string;
    text: string;
    iconName?: string;
    highlight?: boolean;
}

export const SubscriptionCard = (props: Card) => {
    return <div className='subscription-card'>
        <Icon iconName={props.iconName} />
        <div className='description-container'>
            <div className='title overflow-text' title={props.title}>{props.title}</div>
            <div
                className={`text overflow-text ${props.highlight ? 'error-message' : ''}`}
                title={props.text}>{props.text}
            </div>
        </div>
    </div>
}