import './SubscriptionCards.css';
import * as React from 'react';
import { Card, SubscriptionCard } from './SubscriptionCard';
import { SubscriptionInfo, GetPriceString, GetPaymentPeriodTitle } from '../common';
import { ProductType, Subscription, SubscriptionStatus } from '../../../../store/Tenant';
import { remainingDaysToString } from '../../../common/timeline/utils';
import { FormatDate, toDateTime } from '../../../utils/common';

type Props = {
    subscription: SubscriptionInfo;
}

export const SubscriptionCards = (props: Props) => {
    const { subscription } = props;
    const cards = React.useMemo(() => cardsBuilder(subscription), [subscription]);

    return <div className='subscription-cards'>
        {
            cards.map(_ => <SubscriptionCard key={_.title} {..._} />)
        }
    </div>;
}

const cardsBuilder = (subscription: SubscriptionInfo) => {
    if (!subscription) {
        return [];
    }

    const cards: Card[] = [{
        title: 'Current subscription',
        text: subscription.planName,
        iconName: subscription.planIconName ?? ProductType.getIcoName(subscription.productType)
    }];

    if (subscription.isTrial) {
        cards.push({
            title: 'Trial Remaining',
            text: remainingDaysToString(subscription.endDate),
            iconName: 'PPMXTrialRemaining',
            highlight: subscription.status != SubscriptionStatus.Active || Subscription.isExpired(subscription)
        })
    }

    if (!subscription.isTrial) {
        cards.push({
            title: 'Renewal Date',
            text: FormatDate(toDateTime(subscription.endDate))!,
            iconName: 'PPMXRenewalDate',
            highlight: subscription.status != SubscriptionStatus.Active || Subscription.isExpired(subscription)
        });
    }

    cards.push({
        title: 'Users Seats',
        text: `${subscription.utilization.usersAllocated} / ${subscription.usersLimit}`,
        iconName: 'PPMXUsersSeats'
    })

    if (subscription.viewersLimit > 0) {
        cards.push({
            title: 'Team Members Seats',
            text: `${subscription.utilization.viewersAllocated} / ${subscription.viewersLimit}`,
            iconName: 'PPMXTeamMembersSeats'
        })
    }

    if (!Subscription.isDirect(subscription) && !!subscription.price) {
        cards.push({
            title: GetPaymentPeriodTitle(subscription),
            text: GetPriceString(subscription.price),
            iconName: 'PPMXPayment'
        })
    }

    return cards;
}