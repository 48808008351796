import * as React from 'react';
import { post, get } from '../../../fetch-interceptor';
import { DefaultButton, TextField, Panel, IPanelProps, PanelType, MessageBar, MessageBarType, Spinner, SpinnerSize, Dropdown, IDropdownOption, Link } from 'office-ui-fabric-react';
import { Validator } from '../../../validation';
import * as Notifications from "../../../store/NotificationsStore";
import { connect } from 'react-redux';
import { catchApiError } from '../../../store/utils';
import { SubscriptionInfo, BillingPlan } from './common';
import { isEqual, debounce, toDateTime, formatCurrency } from '../../utils/common';
import { SubscriptionStatus } from '../../../store/Tenant';

type Props = {
    plan: BillingPlan;
    subscription: SubscriptionInfo;
    onDismiss: () => void;
    onSuccessful: () => void;
    chargebee: any;
} & typeof Notifications.actionCreators;

type Estimate = { total?: number; }

type State = {
    usersCount?: number;

    openPanel: boolean;
    validators: { usersCount: Validator },
    isPlanChange: boolean;

    checkoutOpened: boolean;
    isSuccessful: boolean;

    estimate: Estimate;
    isEstimateLoading: boolean;

    viewers: IDropdownOption;
    viewersStairsteps: IDropdownOption[];
};

type CheckoutDto = {
    planId: string;
    usersCount: number;
    viewersCount?: number;
}
const toUSD = (price: number) => formatCurrency(price, "USD");
const toPriceString = (price: number) => toUSD(price / 100);

const estimateDebounce = debounce<CheckoutDto>(500);
class Checkout extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const steps: IDropdownOption[] = [{
            key: 0,
            data: { from: 0, to: 0 },
            text: "None",
            disabled: !!props.subscription.utilization.viewersAllocated
        },
        ...props.plan.viewersStairsteps.map(_ => ({
            key: _.from,
            data: _,
            text: `${_.to} (${toPriceString(_.price)})`,
            disabled: _.to < props.subscription.utilization.viewersAllocated
        }))
        ];

        this.state = {
            openPanel: true,

            usersCount: props.subscription.usersLimit,

            validators: this._buildValidators(props),
            isPlanChange: props.subscription.planId != props.plan.id,
            isSuccessful: false,
            checkoutOpened: false,
            isEstimateLoading: false,
            estimate: { total: props.subscription.price },

            viewers: steps.find(_ =>
                props.subscription.viewersLimit >= _.data.from
                && props.subscription.viewersLimit <= _.data.to) || steps[0],
            viewersStairsteps: steps
        }
    }

    private _buildValidators(props: Props) {
        const { utilization } = props.subscription;
        const { defaultUserCount } = props.plan;
        const usersValidator = Validator.new().required().int32();
        usersValidator
            .min(defaultUserCount, `Value can't be less than ${defaultUserCount}`)
            .min(utilization.usersAllocated, `Value can't be less then already allocated users count`);

        return { usersCount: usersValidator.build() }
    }

    componentWillMount() {
        this.state.isPlanChange && this._getEstimate(this._buildCheckoutDto());
    }

    public render() {
        const { viewers, estimate, isEstimateLoading, viewersStairsteps, isPlanChange } = this.state;
        const { plan, subscription } = this.props;
        return <Panel
            className="checkout-panel"
            isOpen={this.state.openPanel}
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth="400px"
            onRenderHeader={this._onRenderHeader}
            onRenderFooterContent={this._onRenderFooterContent}
            onDismiss={() => !this.state.checkoutOpened && this.props.onDismiss()}>
            <MessageBar messageBarType={MessageBarType.info}>
                Total price and price per user depend on the number of users.
                <Link href="https://ppm.express/ppm-express-pricing" target="_blank">Learn more</Link> about our pricing and plans.
            </MessageBar>
            <TextField
                disabled={true}
                label={isPlanChange ? "Selected Subscription" : "Current Subscription"}
                value={plan.name}
                title={plan.name}
            />
            <TextField
                type="number"
                tabIndex={1}
                autoFocus={true}
                label="User Seats"
                value={this.state.usersCount?.toString() || ""}
                onChange={(e, _) => {
                    this._onChange({ usersCount: _ ? parseInt(_) : undefined })
                }}
                onGetErrorMessage={this.state.validators.usersCount.getErrorMessage}
            />
            {!!plan.viewersStairsteps.length && <Dropdown
                    tabIndex={2}
                    label="Team Member Seats"
                    selectedKey={viewers.key}
                    options={viewersStairsteps}
                    onChange={(e, _) => _ && this._onChange({ viewers: _ })}
                />
            }
            {
                Validator.isValid(this.state.validators, this.state) && (estimate.total != undefined || isEstimateLoading) && <MessageBar className="estimate" messageBarType={MessageBarType.info}>
                    <span>Total Price: </span>
                    {!isEstimateLoading && <span>
                        {estimate.total != undefined
                            ? toUSD(estimate.total)
                            : ""
                        }
                    </span>
                    }
                    <Spinner size={SpinnerSize.small} style={{ visibility: isEstimateLoading ? "visible" : "hidden" }} />
                </MessageBar>
            }
        </Panel>;
    }

    private _onChange<K extends keyof State>(changes: Pick<State, K>) {
        this.setState({ ...changes }, () => estimateDebounce(this._buildCheckoutDto(), this._getEstimate));
    }

    private _getEstimate = (dto: CheckoutDto) => {
        if (!dto.usersCount) {
            return;
        }

        get<Estimate>("api/billing/estimate", dto)
            .then(_ => {
                isEqual(dto, this._buildCheckoutDto()) && this.setState({ estimate: _, isEstimateLoading: false });
            })
            .catch(catchApiError(_ => this.setState({ estimate: { total: undefined }, isEstimateLoading: false })))

        this.setState({ isEstimateLoading: true });
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        const { isPlanChange } = this.state;
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">{isPlanChange ? "Buy Subscription" : "Manage Subscription"}</p>
        </div>;
    }

    private _onRenderFooterContent = (props?: IPanelProps): JSX.Element | null => {
        const { subscription } = this.props;

        const invalid = !Validator.isValid(this.state.validators, this.state);
        return <div className="commands">
            <DefaultButton
                primary={true}
                text="Proceed"
                tabIndex={3}
                iconProps={{ iconName: "Shop" }}
                onClick={this._onCheckout}
                disabled={subscription.isTrial
                    ? invalid
                    : subscription.status !== SubscriptionStatus.NotPaid && (invalid || this._noChanges()) && !this._isTenantInGracePeriod()
                }
            />
        </div>;
    }

    private _noChanges() {
        const { subscription } = this.props;
        const { usersCount, viewers, isPlanChange } = this.state;

        return !isPlanChange
            && subscription.usersLimit === usersCount
            && subscription.viewersLimit === viewers.data.to;
    }

    private _onCheckout = () => {
        this.setState({ openPanel: false, checkoutOpened: true });

        this.props.chargebee.openCheckout({
            hostedPage: () => post("api/billing/checkout", this._buildCheckoutDto()),
            success: (hostedPageId: any) => this.setState({ isSuccessful: true }),
            close: this._onClose,
            error: catchApiError(_ => this.props.pushNotification({ message: _, type: Notifications.NotificationType.Error }))
        });
    }

    private _onClose = () => {
        if (this.state.isSuccessful) {
            this.props.onSuccessful();
        }

        this.props.onDismiss();
    }

    private _buildCheckoutDto(): CheckoutDto {
        const { usersCount, viewers } = this.state;
        const { plan } = this.props;
        return { planId: plan.id, usersCount: usersCount!, viewersCount: viewers.data.to };
    }

    private _isTenantInGracePeriod() {
        const { subscription } = this.props;

        const subscriptionEndDate = toDateTime(subscription.endDate)!;
        const gracePeriodEndDate = subscriptionEndDate.addDays(subscription.gracePeriodDuration);

        return subscriptionEndDate.getTime() < Date.now() && Date.now() < gracePeriodEndDate.getTime();
    }
}

export default connect(undefined, Notifications.actionCreators)(Checkout);