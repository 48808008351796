import React from "react";
import { StatusCategory, StatusTrendType, trendTitleMap } from "../../../../entities/common";
import { CategoryStatusOption } from "../../../../entities/StatusDescriptor";
import ColoredStatusView, { ColoredStatusViewProps } from "./ColoredStatusView";

type Props = Omit<ColoredStatusViewProps, 'value'> & {
    statusOption: CategoryStatusOption | undefined;
};

const CategoryColorStatusView = (props: Props) => {
    const { statusOption, trend, itemsCount, title } = props;

    if (!statusOption) {
        return null;
    }

    const trendValue = trend ?? calculateTrend(statusOption);

    return <ColoredStatusView
        {...props}
        value={{ ...statusOption, title: getTitle() }}
        trend={trendValue}
    />

    function getTitle(): string | undefined {
        const titlePrefix = title === null
            ? undefined
            : title !== undefined
                ? title
                : itemsCount !== undefined
                    ? itemsCount.toString()
                    : `Status Trend: ${trendTitleMap[trendValue]}`;

        return `${titlePrefix} (${statusOption?.name.trim()})`;
    }
}

function calculateTrend(option: CategoryStatusOption | undefined): StatusTrendType {
    const category = option?.category;
    return (category === StatusCategory.Red || category === StatusCategory.Amber
        ? StatusTrendType.Negative
        : category === StatusCategory.Green || category === StatusCategory.Done
            ? StatusTrendType.Stable
            : StatusTrendType.NA);
}

export default CategoryColorStatusView;
