import * as React from "react";
import * as Metadata from "../../../../../entities/Metadata";
import { notUndefined, toDictionaryByName } from "../../../../utils/common";
import { IFieldActions } from "../../../../field/FieldPanel";
import { Dictionary, EntityType } from "../../../../../entities/common";
import { ConfigureFieldsPanel } from "../../../../field/ConfigureFields";
import { ApplicationState } from "../../../../../store";
import { connect } from "react-redux";

type OwnProps = {
    statuses: string[];
    selected: string[];
    entityType: EntityType;
    onChange?: (fields: string[]) => void;
    onDismiss: () => void;
    allowManageFields?: boolean;
    fieldActions?: IFieldActions;
    showSpinner?: boolean;
};

type StateProps = {
    fieldsMap: Dictionary<Metadata.Field>;
};

type Props = OwnProps & StateProps;

const StatusSelectionPanel = (props: Props) => {
    const getStatusField = React.useCallback((statusName: string) => {
        const statusAttrName = buildStatusAttributeName(statusName);
        return props.fieldsMap[statusAttrName];
    }, [props.fieldsMap]);

    const fields = React.useMemo(
        () => props.statuses.map(getStatusField).filter(notUndefined),
        [props.statuses, getStatusField]);

    const selected = React.useMemo(
        () => props.selected.map(_ => getStatusField(_)?.name).filter(notUndefined),
        [props.selected, getStatusField]);

    return (
        <ConfigureFieldsPanel
            {...props}
            headerText="Configure View"
            secondaryText="Select the statuses to be displayed in the view. Drag and drop to reorder."
            fields={fields}
            selected={selected}
            hideNewFieldButton
            flattenAvailableFieldGroups
            onChange={_ => props.onChange?.(_.map(__ => __.replace('Status', '')))}
        />
    );
};

export const buildStatusAttributeName = (name: string) => `${name}Status`;

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[ownProps.entityType];
    return {
        fieldsMap: toDictionaryByName(fields.allIds.map(_ => fields.byId[_])),
    };
}

export default connect(mapStateToProps)(StatusSelectionPanel);
