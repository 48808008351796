import * as React from "react";

export default class ScrollPositionFixer extends React.Component {
    private _listContainer: HTMLDivElement | null = null;

    render() {
        return <div ref={_ => this._listContainer = _}>
            {this.props.children}
        </div>;
    }

    getSnapshotBeforeUpdate(): { scrollTop?: number } {
        const scrollable = this._listContainer?.closest('.scrollable');
        return {
            scrollTop: scrollable?.scrollTop
        };
    }

    componentDidUpdate(_prevProps: any, _prevState: any, snapshot: { scrollTop?: number }) {
        const scrollable = this._listContainer?.closest('.scrollable');
        if (scrollable && snapshot.scrollTop) {
            scrollable.scrollTop = snapshot.scrollTop;
        }
    }
}