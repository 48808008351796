import './ExtensionPanel.css'
import * as React from 'react';
import { connect } from 'react-redux';
import { PanelType } from 'office-ui-fabric-react';
import ExpandablePanel from '../ExpandablePanel';
import { EntityType, Dictionary, EXTRA_EXTRA_LARGE_PANEL_WIDTH, EXTRA_LARGE_PANEL_WIDTH, FULL_SCREEN_PANEL_WIDTH, PANEL_CUSTOM_WIDTH } from '../../../entities/common';
import { ExtensionEmbededContentSize, ExtensionInfo, ExtensionType } from '../../../store/ExtensionStore';
import { TenantState } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import { ExtensionViewer } from './ExtensionViewer';
import ExtensionTrigger from './ExtensionTrigger';
import ExtensionNavigate from './ExtensionNavigate';

type OwnProps = { entityType: EntityType, info: ExtensionInfo, onDismiss: () => void, onRefresh?: () => void, context: Dictionary<any>, urlParams?: Dictionary<string> }
type StateProps = { tenant: TenantState }

const Header = (props: {name: string}) => (
    <div className="ms-Panel-header align-center">
        <p className="ms-Panel-headerText">{props.name}</p>
    </div>
);

export const sizeToWidthMap = {
    [ExtensionEmbededContentSize.Small]: PANEL_CUSTOM_WIDTH,
    [ExtensionEmbededContentSize.Normal]: EXTRA_LARGE_PANEL_WIDTH,
    [ExtensionEmbededContentSize.Large]: EXTRA_EXTRA_LARGE_PANEL_WIDTH,
    [ExtensionEmbededContentSize.FullScreen]: FULL_SCREEN_PANEL_WIDTH
};

const ExtensionPanel = (props: OwnProps & StateProps) => {
    const { info } = props;
    const urlParams = props.urlParams ? { ...(props.urlParams || {}), tenantId: props.tenant.id } : undefined;

    if (info.type == ExtensionType.TriggerAction) {
        return <ExtensionTrigger {...props} />
    }

    if (info.type == ExtensionType.OpenNewTab) {
        return <ExtensionNavigate {...props} urlParams={urlParams} />
    }

    return <ExpandablePanel
        className="extension-panel"
        isOpen
        type={PanelType.custom}
        customWidth={sizeToWidthMap[info.size ?? ExtensionEmbededContentSize.Normal]}
        onDismiss={() => {
            props.onDismiss();
            props.onRefresh?.();
        }}
        onRenderHeader={() => <Header name={props.info.name}/>}
    >
        <ExtensionViewer context={props.context} urlParams={urlParams} entityType={props.entityType} info={props.info} />
    </ExpandablePanel>;
}
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        tenant: state.tenant
    };
}

export default connect(mapStateToProps)(ExtensionPanel);