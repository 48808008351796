import * as React from 'react';
import { IConnectionsState, validateConnection } from "../../../store/integration/common";
import { usePrevious } from '../../utils/effects';

type Props = {
    connections: IConnectionsState,
    connectionId: string | undefined,
    verifyConnection: (connectionId: string) => void,
    validateConnection?: (state: IConnectionsState, connectionId?: string) => { isValidating?: boolean, validationError?: string | React.ReactNode | null } | undefined;
}

export const ConnectionVerifier = (props: React.PropsWithChildren<Props>) => {
    const previousProps = usePrevious(props);
    React.useEffect(() => {
        const isConnectionValid = !!props.connectionId && props.connections.connectionsVerification[props.connectionId]?.validationError !== null
            && !props.connections.connectionsVerification[props.connectionId]?.isValidating;
        if (props.connectionId && isConnectionValid
            //Connections list loaded
            && ((previousProps?.connections.isLoading && !props.connections.isLoading)
                //Connection refreshed
                || (previousProps?.connections.isProcessing && !props.connections.isProcessing && !props.connections.error)
                //Connection changed
                || (previousProps?.connectionId !== props.connectionId))) {
            //Data loading should be started after successful connection verification 
            props.verifyConnection(props.connectionId);
        }
    }, [props.connectionId, props.connections.isLoading, props.connections.isProcessing]);

    const isValid = props.validateConnection
                        ? props.validateConnection(props.connections, props.connectionId)
                        : validateConnection(props.connections, props.connectionId);
    return <>
        {props.children}        
        { isValid?.validationError && <div className='error-message'>{isValid.validationError}</div> }
    </>;
}