import * as React from 'react';
import PPMXPicker from "./PPMXPicker";
import { IPersonaProps, PeoplePickerItem, IBasePickerSuggestionsProps, PeoplePickerItemSuggestion, createGenericItem, IPeoplePickerItemSelectedProps } from 'office-ui-fabric-react';

const defaultProps = {
    onRenderItem: (props: IPeoplePickerItemSelectedProps) => <PeoplePickerItem {...props} />,
    onRenderSuggestionsItem: (personaProps: IPersonaProps, suggestionsProps?: IBasePickerSuggestionsProps) => (
        <PeoplePickerItemSuggestion personaProps={personaProps} suggestionsProps={suggestionsProps} compact={!personaProps.secondaryText} />
    ),
    createGenericItem: createGenericItem,
    pickerSuggestionsProps: { noResultsFoundText: "No people found", loadingText: 'Loading' },
};

export default class PPMXPeoplePicker extends PPMXPicker<IPersonaProps> {
    public static defaultProps = defaultProps;
}

export class DebouncedPPMXPeoplePicker extends PPMXPicker<IPersonaProps> {
    public static defaultProps = { ...defaultProps, resolveDelay: 500 }
}