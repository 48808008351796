import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from "../../../store/integration/ImportStore";
import { actionCreators as ResourceActionCreators } from "../../../store/ResourcesListStore";
import { SystemChoisePanel } from "../SystemChoisePanel";
import { ApplicationState } from "../../../store/index";
import { bindActionCreators } from 'redux';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { IConnectionInfo } from '../../../entities/Metadata';
import { ImportPanelSelector } from './ImportPanelSelector';

type OwnProps = { onDismiss: () => void; integrations: SourceType[] }
type StateProps = { isImporting: boolean; }
type Props = OwnProps & StateProps & {
    actions: typeof actionCreators,
    resourceActions: typeof ResourceActionCreators
};

export const supported = [SourceType.O365User, SourceType.Jira, SourceType.VSTS, SourceType.Spo, SourceType.MondayCom, SourceType.Smartsheet, SourceType.P4W];

const ImportPanel = (props: Props) => {
    const [step, setStep] = React.useState(props.isImporting ? Steps.Import : Steps.SystemChoise);
    const [system, setSystem] = React.useState(props.integrations[0]);
    const [connection, setConnection] = React.useState<IConnectionInfo | undefined>();

    React.useEffect(() => {
        props.actions.toggleResourceImportDialog(true);
        return () => {
            props.actions.toggleResourceImportDialog(false);
            props.resourceActions.loadResources();
        }
    }, [])

    const onSelected = React.useCallback((system: SourceType, connection: IConnectionInfo) => {
        setStep(Steps.Import);
        setSystem(system);
        setConnection(connection);
    }, [])

    const onBack = React.useCallback(() => {
        if (props.isImporting) {
            props.onDismiss();
        } else {
            setStep(Steps.SystemChoise);
        }
    }, [props.isImporting, props.onDismiss])

    return <>
        {
            step === Steps.SystemChoise && <SystemChoisePanel
                key="system-choise"
                entitiesName="Resources"
                width="420px"
                className="import-panel"
                system={system!}
                systems={props.integrations}
                connectionId={connection?.id}
                onSelected={onSelected}
                onDismiss={props.onDismiss}
            />
        }
        {
            step === Steps.Import && connection && <ImportPanelSelector
                connection={connection}
                system={system}
                width="900px"
                onBack={onBack}
            />
        }
    </>;
}

enum Steps {
    SystemChoise,
    Import
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        isImporting: state.import.resources.isImporting
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
        resourceActions: bindActionCreators(ResourceActionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ImportPanel);