import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import SidebarLink from './SidebarLink';
import { UserState as IUserState } from '../../store/User';
import { contains, CommonOperations, notNone } from '../../store/permissions';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import Feedback from './Feedback';
import { Subscription, RoutesAvailability, TenantState, ToRouteAvailabilitySettings } from '../../store/Tenant';
import UpgradeTrialAction from './UpgradeTrialAction';
import LiveChat from './LiveChat';
import GuideMe from './GuideMe';

type StateProps = {
    subscription: Subscription;
    user: IUserState;
    tenant: TenantState;
};

type Props = StateProps & RouteComponentProps<{}>;

const Sidebar = (props: Props) => {
    const { user, tenant, subscription } = props;

    const showFull = user.preferences.expandSideBar;
    const permissions = user.permissions;
    const canViewPortfolio = permissions && notNone(permissions.portfolio);
    const canViewProgram = canViewPortfolio || permissions && notNone(permissions.program);
    const canViewProject = canViewProgram || permissions && notNone(permissions.project);
    const canViewRoadmap = permissions && notNone(permissions.roadmap);
    const canViewChallenge = permissions && notNone(permissions.challenge);
    const canViewResource = permissions && contains(permissions.common, CommonOperations.ResourceView);
    const canViewInsights = RoutesAvailability.Insights(subscription, user);
    const isPortfoliosAvailable = RoutesAvailability.Portfolios(subscription, user);
    const isProgramsAvailable = RoutesAvailability.Programs(subscription, user);
    const isProjectsAvailable = RoutesAvailability.Projects(subscription, user);
    const isChalangesAvailable = RoutesAvailability.Challenges(subscription, user);
    const isPrioritiesAvailable = RoutesAvailability.Priorities(subscription, user);
    const isObjectivesAvailable = RoutesAvailability.Objectives(subscription, user);
    const isRoadmapsAvailable = RoutesAvailability.Roadmaps(subscription, user);
    const isMySpaceAvailable = RoutesAvailability.MySpace(subscription, user);
    const isMyTimeAvailable = RoutesAvailability.MyTime(subscription, user, ToRouteAvailabilitySettings(tenant));

    return <div className={`nav-sidebar ${showFull ? 'nav-sidebar-full' : ''}`}>
        <div className='nav-sidebar-top'>
            {isMySpaceAvailable && <SidebarLink secondary to="/myspace" matchPath="/myspace" text="My Space" iconName="CheckboxComposite" showDetailed={showFull} />}
            {isMyTimeAvailable && <SidebarLink secondary to="/mytime" matchPath="/mytime" text="My Time" iconName="Clock" showDetailed={showFull} />}
            {
                canViewInsights &&
                <>
                    <SidebarLink secondary to="/insights" matchPath="/insights" text="PPM Insights" iconName="PPMXInsights" showDetailed={showFull} />
                    <div className="split"></div>
                </>
            }
            {isPortfoliosAvailable && <SidebarLink to='/portfolios' matchPath='/portfolio' text='Portfolios' iconName='PPMXPortfolio' showDetailed={showFull} disabled={!canViewPortfolio} />}
            {isProgramsAvailable && <SidebarLink to='/programs' matchPath='/program' text='Programs' iconName='PPMXProgram' showDetailed={showFull} disabled={!canViewProgram} />}
            {isProjectsAvailable && <SidebarLink to='/projects' matchPath='/project' text='Projects' iconName='PPMXProject' showDetailed={showFull} disabled={!canViewProject} />}
            {isRoadmapsAvailable && <SidebarLink to='/roadmaps' matchPath='/roadmap' text='Roadmaps' iconName='PPMXRoadmap' showDetailed={showFull} disabled={!canViewRoadmap} />}
            <SidebarLink to='/resources' matchPath='/resource' text='Resources' iconName='PPMXResource' showDetailed={showFull} disabled={!canViewResource} />
            {(isPrioritiesAvailable || isChalangesAvailable || isObjectivesAvailable) && <div className='split'></div>}
            {isPrioritiesAvailable && <SidebarLink secondary to='/priorities' matchPath='/priorities'
                text='Strategic Priorities' iconName='PPMXStrategicPriorities' showDetailed={showFull} />}
            {isObjectivesAvailable && <SidebarLink secondary to='/objectives' matchPath='/objective'
                text='Company Objectives' iconName='PPMXCompanyObjectives' showDetailed={showFull} />}
            {isChalangesAvailable && <SidebarLink secondary to='/challenges' matchPath='/challenge'
                text='Business Challenges' iconName='PPMXBusinessIdeas' showDetailed={showFull} disabled={!canViewChallenge} />}
        </div>
        <div className='nav-sidebar-bottom'>
            <UpgradeTrialAction />
            <LiveChat />
            <Feedback />
            <SidebarLink to="/quickstart" matchPath="/quickstart" text="Quick Start" iconName="PPMXQuickStart" showDetailed={showFull} />
            <GuideMe />
            {tenant.styleSettings.logoId && <>
                <div className='split'></div>
                <div className='sidebar-bottom-logo' title="PPM Express">
                    <Icon iconName='PPMXLogo' />
                    {showFull && <span>PPM Express</span>}
                </div>
            </>}
        </div>
    </div>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user,
        tenant: state.tenant,
    };
}

export default withRouter<{}>(connect(mapStateToProps)(Sidebar));