import * as React from "react";
import { IImportProjectState, ImportStatus } from "../../store/integration/ImportStore";
import { Icon } from "office-ui-fabric-react";
import { FormatterProps } from "react-data-grid";
import { SourceType, SourceTypeMap } from "../../store/ExternalEpmConnectStore";

const StatusFormatter = (props: FormatterProps<IImportProjectState> & { system?: SourceType }) => {
    let color = 'rgba(18, 138, 16, 0.4)';
    let message = 'New';
    let title: string | undefined;
    const value = props.row.status;

    if (ImportStatus.isLinked(value)) {
        color = 'rgba(18, 138, 16, 1)';
        message = "Already linked";
    } else if (value === ImportStatus.Matched) {
        return (<div className="status">
                <Icon className="warning-icon" iconName="Warning"
                    title={props.system
                        ? `The primary schedule of the project will be switched to ${SourceTypeMap[props.system]}`
                        : undefined} />
                <span className="title" title="Matched by name">Matched by name</span>
            </div>);
    }

    return (<div className="status">
        <Icon styles={{ root: { color: color } }} iconName="PPMXCircle" />
        <span className="title" title={title ?? message}>{message}</span>
    </div>);
}

export default StatusFormatter;