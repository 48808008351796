import * as React from "react";
import { bindActionCreators } from "redux";
import { connect, } from "react-redux";
import ImportGrid from "../ImportGrid";
import ProjectEditor from "./ProjectEditor";
import { ImportStatus, IImportProjectState, actionCreators as importActions, IProjectInfo } from "../../../store/integration/ImportStore";
import { ProjectInfo, actionCreators as projectsActions } from "../../../store/ProjectsListStore";
import { ApplicationState } from "../../../store/index";
import StatusFormatter from "../StatusFormatter";
import { IImportEntityMap, IImportEntity, IGridBaseProps } from "./common";
import { Dictionary, ISourceInfo } from "../../../entities/common";
import { DirectionalHint, IconButton } from "office-ui-fabric-react";
import { Column, FormatterProps } from "react-data-grid";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import PrivateProjectIcon from "../../common/PrivateProjectIcon";

type OwnProps = {
    connectionId: string;
    isLoading: boolean;
    isProcessing?: boolean;
    error: string | null;
    system: SourceType;

    onSelectionChanged: (entities: IImportEntityMap[]) => void;
} & IGridBaseProps;

type StateProps = {
    total?: number;
    imported?: number;
    isImporting: boolean;
    maps: IImportProjectState[];
    projects: ProjectInfo[];
    isLoading: boolean;
}

type ActionsProps = { importActions: typeof importActions, projectsActions: typeof projectsActions };
type Props = StateProps & OwnProps & ActionsProps;
type State = { selectedIds: string[]; }

class ProjectImportGrid extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { selectedIds: [] };
    }

    public render() {
        const { error, maps, isLoading, isProcessing } = this.props;
        return (
            <>
                {
                    error
                        ? <div className="error-message">{error}</div>
                        : <ImportGrid
                            columns={this.getGridColumns()}
                            isLoading={isLoading}
                            isProcessing={isProcessing}
                            maps={maps}
                            selectedIds={this.state.selectedIds}
                            onMapsChanged={this._onMapsChanged}
                            onSelectionChanged={(_) => this._onSelectionChanged(_, maps)} />
                }
            </>
        );
    }

    componentWillMount() {
        if (!this.props.isImporting) {
            this.props.importActions.setProjectsImportMaps([]);
            this.props.projectsActions.requestProjects();
        }

        this._setSelected(this.props);
    }

    componentWillUnmount() {
        this.props.importActions.setProjectsImportMaps([]);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { entities, projects, isImporting, isLoading, connectionId } = nextProps;
        if (isLoading) return;

        const mapsInfoChanged = this.props.entities != nextProps.entities || this.props.isLoading && !nextProps.isLoading;
        if (!isImporting && mapsInfoChanged) {
            const maps = this._buildMaps(entities, projects, connectionId);
            const selected = maps.filter(_ => this.state.selectedIds.indexOf(_.externalId) !== -1).map(_ => _.externalId);
            if (this.state.selectedIds.length !== selected.length) {
                this._onSelectionChanged(selected, maps);
            }
            this.props.importActions.setProjectsImportMaps(maps);
        }

        if (this.props.isImporting || this.props.imported !== nextProps.imported) {
            this._setSelected(nextProps);
        }
    }

    private _setSelected(props: Props) {
        const selectedIds: string[] = [];
        props.maps.forEach(_ => _.isInProcessing && selectedIds.push(_.externalId));
        if (selectedIds.length != this.state.selectedIds.length) {
            this._onSelectionChanged(selectedIds, this.props.maps);
        }
    }

    private _onSelectionChanged = (selected: string[], states: IImportProjectState[]) => {
        const { connectionId } = this.props;
        if (!connectionId) return;

        const dic: Dictionary<IImportProjectState> = {};
        states.forEach(_ => dic[_.externalId] = _);

        const entities = selected.map<IImportEntityMap>(_ => {
            const state = dic[_];
            const entity = {
                entityId: state.externalId,
                projectName: state.project.name,
                projectId: state.project.id
            };

            return this.props.populateMap ? this.props.populateMap(state, entity) : entity;
        });

        this.setState({ selectedIds: selected });
        this.props.onSelectionChanged(entities);
    }

    private _onMapsChanged = (maps: IImportProjectState[]) => {
        this.props.importActions.setProjectsImportMaps(maps);
        this._onSelectionChanged(this.state.selectedIds, maps);
    }

    private _buildMaps(entities: IImportEntity[], existProjects: ProjectInfo[], connectionId: string): IImportProjectState[] {
        if (!entities.length) return [];

        const storeStatesByExternalId: Dictionary<IImportProjectState> = {};
        this.props.maps.forEach(_ => storeStatesByExternalId[_.externalId] = _);

        const projects = this._groupProjects(existProjects);
        const maps: { state: IImportProjectState, subitems: IImportEntity[] }[] = [];

        const duplicateIndexes: number[] = [];
        for (let i = 0; i < entities.length; ++i) {
            const entity = entities[i];

            let hasDuplicate = !!~duplicateIndexes.indexOf(i);
            if (!hasDuplicate) {
                for (let j = i + 1; j < entities.length; ++j) {
                    if (entity.name == entities[j].name) {
                        duplicateIndexes.push(j);
                        hasDuplicate = true;
                    }
                }
            }

            const state = this._buildImportProjectState(entity, connectionId, hasDuplicate, projects, storeStatesByExternalId);
            maps.push({ state, subitems: entity.subItems || [] });
        }

        return maps
            .sort((a, b) => this._sort(a.state, b.state))
            .map(_ => [_.state, ..._.subitems?.map(s => this._buildImportProjectState(s, connectionId, true, projects, storeStatesByExternalId)).sort(this._sort)])
            .reduce((a, b) => a.concat(b), []);
    }

    private _sort = (a: IImportProjectState, b: IImportProjectState) => {
        if (a.status === b.status) {
            return a.externalName.localeCompare(b.externalName);
        }

        return a.status > b.status ? 1 : -1;
    }

    private _buildImportProjectState = (entity: IImportEntity, connectionId: string, useFullName: boolean, projects: {
        linked: Dictionary<{ project: ProjectInfo, sourceInfo: ISourceInfo<any> }>;
        notLinked: Dictionary<ProjectInfo[]>;
    }, storeStatesByExternalId: Dictionary<IImportProjectState>): IImportProjectState => {
        const storeState = storeStatesByExternalId[entity.id];
        if (storeState) {
            return storeState;
        }

        let state: IImportProjectState = {
            externalId: entity.id,
            externalName: useFullName ? entity.fullName : entity.name,
            isInProcessing: false,
            project: { name: entity.name },
            projects: [],
            status: ImportStatus.New
        };

        const entityId = this._getId(entity.id, connectionId);
        if (projects.linked[entityId]) {
            let link = projects.linked[entityId];
            state.status = ImportStatus.Linked;
            state.project = toDto(link.project);
            state.sourceInfo = link.sourceInfo;
        } else if (entity.isAlreadyLinked) {
            state.status = ImportStatus.Unaccessible;
            state.project = { name: 'Project' };
        } else if (projects.notLinked[entity.name]) {
            let matched = projects.notLinked[entity.name].map(toDto);
            if (matched.length > 1) {
                matched.forEach(_ => _.name = `${_.name} (${_.id})`);
            }

            state.status = ImportStatus.Matched;
            state.project = matched[0];
            state.projects = state.projects.concat(matched);
        }

        return this.props.populateState ? this.props.populateState(entity, state) : state;
    }

    private _groupProjects(projects: ProjectInfo[]) {
        const result: {
            linked: Dictionary<{ project: ProjectInfo, sourceInfo: ISourceInfo<any> }>;
            notLinked: Dictionary<ProjectInfo[]>;
        } = {
            linked: {},
            notLinked: {}
        };

        for (const project of projects) {
            const sourcesInfo = this.props.getSourcesInfo(project);
            if (!sourcesInfo.length) {
                const projectName = project.attributes.Name;
                if (result.notLinked[projectName]) {
                    result.notLinked[projectName].push(project);
                } else {
                    result.notLinked[projectName] = [project];
                }
            } else {
                sourcesInfo.forEach(_ => {
                    const id = this._getId(_.sourceId, _.connectionId);
                    result.linked[id] = { project, sourceInfo: _.data };
                });
            }
        }

        return result;
    }

    private _getId(sourceId: string, connectionId: string) {
        return `${connectionId}_${sourceId}`;
    }

    private getGridColumns(): Column<IImportProjectState>[] {
        const nameColumn: Column<IImportProjectState> = {
            key: "externalName",
            name: "Imported Name",
            formatter: (props: FormatterProps<IImportProjectState>) => {
                const value = props.row[props.column.key];
                return this.props.onRenderName?.(props.row, value) || <span title={value}>{value}</span>
            }
        };

        const actionsColumn: Column<IImportProjectState> = {
            key: "actions",
            name: "",
            width: 36,
            formatter: (props: FormatterProps<IImportProjectState>) => this._renderMenu(props.row)
        }

        return [nameColumn].concat(this.props.extraColumns ? this.props.extraColumns : []).concat([
            {
                key: "project",
                name: "Name in PPM Express",
                formatter: (props: FormatterProps<IImportProjectState>) => {
                    const project = props.row.project as IProjectInfo;
                    const isUnaccessible = props.row.status === ImportStatus.Unaccessible;
                    const title = isUnaccessible
                        ? 'You do not have permissions to view the project name and details'
                        : project.name
                    return <div className="project">
                        {project?.isPrivate && <PrivateProjectIcon/>}
                        <span className={isUnaccessible ? "unaccessible" : ""} title={title}>
                            {project.name}
                        </span>
                    </div>;
                },
                editor: ProjectEditor,
                editable: true
            },
            {
                key: "status",
                name: "Status",
                formatter: (props: FormatterProps<IImportProjectState>) => <StatusFormatter {...props} system={this.props.system} />
            }])
            .concat(this.props.getMenuActions ? [actionsColumn] : [])
            .map(_ => ({
                ..._,
                editable: _.editable
                    ? (rowdata: IImportProjectState) => ImportStatus.isEditable(rowdata.status) && (typeof _.editable === "function" ? _.editable(rowdata) : _.editable || false)
                    : _.editable || false
            }));
    }

    private _renderMenu = (value: IImportProjectState) => {
        const items = this.props.getMenuActions?.(value);
        if (!items) {
            return <span />;
        }
        return (
            <div className="menu">
                <IconButton
                    menuIconProps={{ iconName: 'PPMXMore' }}
                    menuProps={{
                        directionalHint: DirectionalHint.bottomRightEdge,
                        items
                    }}
                />
            </div>
        );
    }
}

function toDto(project: ProjectInfo): IProjectInfo {
    return { id: project.id, name: project.attributes.Name, isPrivate: project.isPrivate };
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const projectsById = state.projectsList.byId;
    return {
        maps: state.import.projects.data,
        total: state.import.projects.total,
        imported: state.import.projects.processed,
        isImporting: state.import.projects.isImporting,
        projects: state.projectsList.allIds.map(_ => projectsById[_]),
        isLoading: (ownProps && ownProps.isLoading) || state.projectsList.isLoading
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        importActions: bindActionCreators(importActions, dispatch),
        projectsActions: bindActionCreators(projectsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectImportGrid);