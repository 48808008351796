import React from "react";
import { DirectionalHint, Icon, TooltipDelay, TooltipHost } from "office-ui-fabric-react";
import "./FieldLabel.css";

type Props = {
    label: string;
    title: string;
}

const FieldLabel = (props: Props) => {
    const { label, title } = props;
    return <div className="field-info-icon">
        {label}
        <TooltipHost
            calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
            tooltipProps={{
                onRenderContent: () => <>{title}</>
            }}
            delay={TooltipDelay.zero}>
            <Icon iconName="Info" onClick={(e) => e.stopPropagation()} />
        </TooltipHost>
    </div>
}

export default FieldLabel;