import React from "react";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react";

type Props = {
    isGenerateDisabled: boolean;
    onGenerateClick: () => void;
    onDismiss: () => void;
}

const AIDigestSelectedDataFooter = (props: Props) => {
    const { isGenerateDisabled, onGenerateClick, onDismiss } = props;

    return <div className="panel-buttons">
        <PrimaryButton
            text="Generate"
            disabled={isGenerateDisabled}
            onClick={onGenerateClick}
        />
        <DefaultButton
            text="Cancel"
            onClick={onDismiss}
        />
    </div>
}

export default AIDigestSelectedDataFooter;