import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from "../../../store/integration/SmartsheetStore";
import { ApplicationState } from "../../../store/index";
import { IConnectionsState } from "../../../store/integration/common";
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import OAuthConnectionsList from '../OAuthConnectionsList';
import { IConnectionInfo } from '../../../entities/Metadata';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    sourceType: SourceType.Smartsheet;
    onSelect: (connection: IConnectionInfo) => void;
};

type StateProps = { connections: IConnectionsState; };
type Props = StateProps & typeof actionCreators & OwnProps;

const SmartsheetConnectionsList = (props: Props) => {
    React.useEffect(() => {
        props.loadConnections();
    }, []);
    
    return <OAuthConnectionsList
        disabled={props.connections.isLoading || !!props.disabled}
        sourceType={props.sourceType}
        connections={props.connections.data}
        newConnectionUrl="/api/integration/smartsheet/auth"
        getRefreshConnectionUrl={buildRefreshConnectionUrl}
        onReceivedConnectionData={props.loadConnections}
        onConnectionSelect={props.onSelect}
        onConnectionRemove={props.removeConnection}
        onConnectionRename={props.renameConnection}
    />;

    function buildRefreshConnectionUrl(connectionId: string) {
        return `/api/integration/smartsheet/auth/connection/${connectionId}`;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.smartsheet.connections };
}

export default connect(mapStateToProps, actionCreators)(SmartsheetConnectionsList);
