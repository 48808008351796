import * as React from 'react';
import { IPersonaProps, IBasePicker, Autofill, TooltipHost, TooltipDelay } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import { post } from '../../../fetch-interceptor';
import InputErrorMessage from "./inputErrorMessage";
import { getPersonInfoImageUrl, notUndefined } from '../../../components/utils/common';
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { Validator } from "../../../validation";
import PPMXPeoplePicker from "./PPMXPeoplePicker";
import { IFindProps } from './EntityPickerInput';

type Props = IFormInputProps<PersonInfo | PersonInfo[], Autofill> & IFindProps & {
    multichoice?: boolean;
    onBlur?: () => void;
    buildSecondaryText?: (persona: PersonInfo) => string | null | undefined;
    autoExpand?: boolean;
};
type State = {
    value?: PersonInfo | PersonInfo[] | null;
    personas: IPersonaProps[];
}

const validator = Validator.new().person().build();

export default class PersonPickerInput extends React.Component<Props, State> implements IFormInputComponent {
    private _picker?: IBasePicker<IPersonaProps>;

    constructor(props: Props) {
        super(props);
        this.state = {
            value: this.props.value,
            personas: this._toPersonasList(this.props.value, this.props.multichoice)
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.value != this.props.value) {
            this.setState({
                value: nextProps.value,
                personas: this._toPersonasList(nextProps.value, nextProps.multichoice)
            });
        }
    }

    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    public render(): JSX.Element {
        const { personas } = this.state;
        const { readOnly, multichoice, inputProps, className, autoExpand } = this.props;

        return <>
            <div className={`person-picker ${multichoice ? 'multichoice' : ''}`} onClick={this._onClick}>
                {<PPMXPeoplePicker
                    disabled={this.props.disabled}
                    inputProps={inputProps}
                    onBlur={this.props.onBlur}
                    readOnly={readOnly}
                    selectedItems={personas}
                    onResolveSuggestions={this._onFilterChanged}
                    getTextFromItem={this._getTextFromItem}
                    className={`ms-PeoplePicker ${className || ""}`}
                    componentRef={_ => _ && (this._picker = _)}
                    onChange={readOnly ? undefined : this._onChange}
                    autoExpand={autoExpand} />}
            </div>
            <InputErrorMessage text={this._getErrorMessage(this.state.value)} onErrorRender={this.props.onErrorRender} />
        </>
    }

    private _toPersonasList(value: PersonInfo | PersonInfo[] | undefined | null, multichoice?: boolean): IPersonaProps[] {
        let personas: IPersonaProps[] = [];
        if (value) {
            if (multichoice) {
                if (Array.isArray(value)) {
                    personas = value.map(_ => this._getPersona(_));
                }
            } else {
                let persona = validator.isValid(value) ? this._getPersona(value as PersonInfo) : undefined;
                if (persona) {
                    personas.push(persona);
                }
            }
        }
        return personas;
    }

    private _onClick = () => {
        this.focus();
    }

    private _onFilterChanged = (filterText: string, currentPersonas?: IPersonaProps[], limitResults?: number): Promise<IPersonaProps[]> => {
        return post<PersonInfo[]>(this.props.searchUrl, { ...this.props.filter, name: filterText })
            .then(_ => _.filter(__ => !((currentPersonas || []).some(___ => ___.id == __.id))))
            .then(_ => _.map(this._getPersona));
    }

    private _getPersona = (info: PersonInfo): IPersonaPropsWithOrigin => {
        const formatArray = (arr: string[] | undefined | null) => arr && !!arr.length ? `${arr[0]}${(arr.length > 1 ? (` (+${arr.length - 1})`) : "")}` : undefined;

        return {
            id: info.id,
            text: info.fullName,
            imageUrl: getPersonInfoImageUrl(info),
            onRenderSecondaryText: this.props.buildSecondaryText
                ? undefined
                : (_: IPersonaPropsWithOrigin) => <TooltipHost
                    tooltipProps={{
                        maxWidth: "454px",
                        onRenderContent: () => <div>
                            {_.origin.role && <div><b>Role:</b> {_.origin.role}</div>}
                            {_.origin.skills && <div><b>Skills:</b> {_.origin.skills.join(', ')}</div>}
                            {_.origin.tags && <div>{_.origin.tags.join(' ')}</div>}
                        </div>
                    }}
                    closeDelay={10}
                    delay={TooltipDelay.long}>
                    {_.secondaryText}
                </TooltipHost>,
            secondaryText: (this.props.buildSecondaryText
                ? this.props.buildSecondaryText(info)
                : [info.role || undefined, formatArray(info.skills), formatArray(info.tags)].filter(notUndefined).join(' | ')) || undefined,
            origin: info
        };
    }

    private _getPersonInfo(persona?: IPersonaPropsWithOrigin): PersonInfo | null {
        if (!persona || !persona.id) {
            return null;
        }
        return persona.origin;
    }

    private _getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    private _onChange = (items?: IPersonaPropsWithOrigin[]): void => {
        let value: PersonInfo | PersonInfo[] | null = null;
        if (this.props.multichoice) {
            if (items) {
                value = items.map(_ => this._getPersonInfo(_)!);
            }
        } else {
            value = this._getPersonInfo(items && items.length ? items[items.length - 1] : undefined) ?? null;
        }
        this.setState({
            value: value,
            personas: this._toPersonasList(value, this.props.multichoice)
        });

        if (this.props.onChanged) {
            this.props.onChanged(value);
        }

        if (this.props.onEditComplete && this._validate(value)) {
            this.props.onEditComplete(value || null);
        }
        this.focus();
    }

    private _validate = (value?: PersonInfo | PersonInfo[] | null) => {
        return this.props.validator == undefined || this.props.validator.isValid(value);
    }

    private _getErrorMessage = (value?: PersonInfo | PersonInfo[] | null) => {
        return this.props.validator && this.props.validator.getErrorMessage(value);
    }

    focus(): void {
        this._picker && this._picker.focusInput();
    }
}

interface IPersonaPropsWithOrigin extends IPersonaProps { origin: PersonInfo; }

export type PersonInfo = {
    id: string;
    fullName: string;
    imageId?: string;
    role?: string | null;
    tags?: string[] | null;
    skills?: string[] | null;
    email?: string | null;
    logonAccount?: string | null;
}