import React from "react";
import './AiDetailsDescription.css';

const AiDetailsDescription = () => {
    return <div className="project-details-description">
        <span>The more context you provide, the more precise the output is. E.g. </span>
        <span className="italic-description">
            Implementation of a company-wide CRM software to enhance our customer management processes, following the Agile methodology.
        </span>
    </div>
}

export default AiDetailsDescription;