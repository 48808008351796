import * as React from 'react';
import { connect } from 'react-redux';
import ConnectControl from './ConnectControl';
import { ApplicationState } from "../../store/index";
import { ISourceInfo, ppmxTaskConnectionId } from "../../entities/common";
import { IProjectInfo, actionCreators } from "../../store/integration/PPMXTimeStore";
import { ILinkDto, IListStore } from '../../store/integration/common';
import { Link, MessageBar, MessageBarType } from 'office-ui-fabric-react';

export interface PpmxTimeLinkInfo {
    projectId?: string;
    projectName?: string;
}

export type PpmxTimeConnectActions = {
    deleteLink: (connectionId: string) => void;
    linkToPpmxTimeProject: (settings: ILinkDto<PpmxTimeLinkInfo>) => void;
}

type OwnProps = {
    readonly: boolean;
    actions: PpmxTimeConnectActions;
    dismissPanel: () => void;
    sourceInfo?: ISourceInfo;
}

type StateProps = {
    projects: IListStore<IProjectInfo>;
    timePortalUrl: string;
}
type Props = OwnProps & StateProps & typeof actionCreators;

class PpmxTimeConnectControl extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    componentWillMount() {
        this.props.loadProjects();
    }

    public render() {
        const { sourceInfo, readonly, projects, timePortalUrl } = this.props;

        return projects.error && ~projects.error?.indexOf("Tanant not found")
            ? <MessageBar messageBarType={MessageBarType.blocked} isMultiline={true}>
                PPM Express Time tenant is not registered yet. Please 
                <Link target="_blank" href={`${timePortalUrl}/signup`}>
                    sign up
                </Link> for PPM Express Time first.
            </MessageBar>
            : <ConnectControl<IProjectInfo>
                itemTypeLabel="project"
                allowNewItems
                readonly={readonly}
                items={projects}
                defaultConnectionId={ppmxTaskConnectionId}
                actions={{
                    createItemAndLink: this._createPpmxTimeProjectAndLink,
                    linkToItem: this._linkToPpmxTimeProject,
                    deleteLink: this.props.actions.deleteLink
                }}
                dismissPanel={this.props.dismissPanel}
                sourceInfo={sourceInfo}
                itemId={sourceInfo && sourceInfo.sourceData.projectId}
                itemName={sourceInfo && sourceInfo.sourceData.projectName}
            />;
            
    }

    private _linkToPpmxTimeProject = (linkData: ILinkDto<IProjectInfo>) => {
        const settings: ILinkDto<PpmxTimeLinkInfo> = {
            connectionId: linkData.connectionId,
            data: {
                projectId: linkData.data.id,
                projectName: linkData.data.name
            }
        };

        this.props.actions.linkToPpmxTimeProject(settings);
    }

    private _createPpmxTimeProjectAndLink = (connectionId: string, projectName: string) => {
        const settings: ILinkDto<PpmxTimeLinkInfo> = {
            connectionId: connectionId,
            data: {
                projectName: projectName
            }
        };

        this.props.actions.linkToPpmxTimeProject(settings);
    }

}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps) {
    return {
        projects: state.ppmxTime.projects,
        timePortalUrl: state.time.portalUrl
    };
}

export default connect(mapStateToProps, actionCreators)(PpmxTimeConnectControl);