
import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { BillingPlan, GetPlanConfig, GetPriceString } from '../common';
import { BadgeProps } from "./CurrentPlanBadge";
import { PlanCard } from './PlanCard';

type RegularCardProps = {
    isCurrent: boolean;
    plan: BillingPlan;
    disabled: boolean;
    onChange: (plan: BillingPlan) => void;
    onSwitchTrial: (plan: BillingPlan) => void;
    onBuy: (plan: BillingPlan) => void;
    badgeProps: BadgeProps;
};

export const RegularPlanCard = (props: RegularCardProps) => {
    const { plan, isCurrent, disabled, badgeProps } = props;
    const onChange = React.useCallback(() => props.onChange(plan), [plan]);
    const onSwitchTrial = React.useCallback(() => props.onSwitchTrial(plan), [plan]);
    const onBuy = React.useCallback(() => props.onBuy(plan), [plan]);
    const isChange = isCurrent && !badgeProps.isTrial;
    const config = GetPlanConfig(plan);

    const onPriceRender = React.useCallback(() => <div className='plan-price-container'>
        <div className='plan-price'>{GetPriceString(config.monthlyPrice)}</div>
        <div className='plan-price-description'>
            <span>per user / month {config.text}</span>
        </div>
    </div>, [config.monthlyPrice, config.text]);

    const onRenderButtons = React.useCallback(() => <>
        {
            <DefaultButton
                disabled={disabled}
                className={`${!isCurrent && badgeProps.isTrial && !disabled ? '' : 'invisible'}`}
                onClick={onSwitchTrial}
                text="Switch trial" />
        }
        <DefaultButton
            disabled={disabled}
            primary={true}
            onClick={isChange ? onChange : onBuy}
            text={isChange ? "Manage Subscription" : "Buy now"} />
    </>, [isChange, disabled, isCurrent, badgeProps.isTrial])

    return <PlanCard
        isCurrent={isCurrent}
        productType={plan.productType}
        name={plan.name}
        features={plan.featuresDescriptions}
        description={plan.description}
        iconName={plan.iconName}
        badgeProps={badgeProps}
        disabled={disabled}
        onPriceRender={onPriceRender}
        onRenderButtons={onRenderButtons}
    />;
}
