import * as React from 'react';
import { connect } from 'react-redux';
import { ComboBox, IComboBoxOption, Spinner, SpinnerSize, PrimaryButton } from 'office-ui-fabric-react';
import { ApplicationState } from "../../../store/index";
import { IOffice365UserInfo, actionCreators, IO365UserLinkInfo, IPlannerConnectionState } from "../../../store/integration/Office365Store";
import { ILinkDto, IListStore, validateConnection, loadDataAfterConnectionValidation } from "../../../store/integration/common";
import OverlayComponent from "../../common/OverlayComponent";
import Office365ConnectionSelect from '../Office365/Office365ConnectionSelect';
import { ISelectableOption } from 'office-ui-fabric-react/lib/utilities/selectableOption/SelectableOption.types';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { ConnectionField } from '../ConnectionField';
import { buildUserDisplayInfo } from './common';

type OwnProps = {
    actions: {
        linkToUser: (linkData: ILinkDto<IOffice365UserInfo>) => void;
        deleteUserLink: (connectionId: string) => void;
    };
    connectionId?: string;
    sourceData?: IO365UserLinkInfo;
    dismissPanel?: () => void;
}

type StateProps = { 
    users: IListStore<IOffice365UserInfo>;
    connections: IPlannerConnectionState;
}
type Props = OwnProps & StateProps & typeof actionCreators;

type State = {
    connectionId: string | undefined;
    userId: string | undefined;
    displayName: string | undefined;
    email: string | undefined;
    users: IComboBoxOption[];
}

class O365ConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            connectionId: props.connectionId,
            userId: props.sourceData?.userId,
            displayName: props.sourceData?.displayName,
            email: props.sourceData?.email,
            users: []
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.users !== nextProps.users) {
            const users: IComboBoxOption[] = [];
            let displayName: string | undefined;
            let email: string | undefined;

            for (const user of nextProps.users.entities) {
                if (user.id === this.state.userId) {
                    displayName = user.displayName;
                    email = user.mail;
                }

                users.push({
                    key: user.id,
                    text: buildUserDisplayInfo(user.displayName, user.mail),
                    disabled: user.isAlreadyLinked
                });
            }

            this.setState({ displayName, email, users });
        }
        
        loadDataAfterConnectionValidation(
            this.props.connections, 
            nextProps.connections, 
            this.state.connectionId,
            this.props.loadUsers);
    }

    render() {
        const { sourceData } = this.props;
        const { displayName, email, connectionId } = this.state;
        
        const isLinked = !!sourceData?.userId;
        const userDisplayInfo = displayName && email && buildUserDisplayInfo(displayName, email)
            || sourceData && buildUserDisplayInfo(sourceData.displayName, sourceData.email);
        
        const isValid = validateConnection(this.props.connections, connectionId);

        return <>
            <div key="select-connection-container" className="with-top-margin">
                <Office365ConnectionSelect
                    sourceType={SourceType.O365User} 
                    disabled={isLinked}
                    connectionId={this.state.connectionId}
                    onChange={this._onConnectionChange} />
            </div>
            <div key="select-user-container" className="with-top-margin">
                <OverlayComponent isOverlayed={!connectionId || isValid?.isValidating !== false || !!isValid?.validationError}>
                    <ConnectionField
                        isLinked={isLinked}
                        value={userDisplayInfo}
                        label="Resource">
                            <ComboBox
                                disabled={isLinked || !connectionId || this.props.users.isLoading}
                                placeholder="Select resource"
                                selectedKey={this.state.userId}
                                useComboBoxAsMenuWidth
                                allowFreeform
                                options={this.state.users}
                                onRenderItem={this.onRenderItem}
                                onChange={(e, _) => this.setState({ userId: _ && _.key as string })}
                                errorMessage={isValid?.isValidating === false && !isValid?.validationError ? this._getComboboxValidationMessage() : undefined} />
                    </ConnectionField>
                    {this.props.users.isLoading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
                    {
                        !isLinked && <PrimaryButton text="Link resource"
                            disabled={this.state.userId === undefined}
                            className="with-top-margin"
                            onClick={this._linkToUser} />
                    }
                    {
                        isLinked && <PrimaryButton text="Delete resource link"
                            className="with-top-margin"
                            onClick={this._deleteUserLink} />
                    }
                </OverlayComponent>
            </div>
        </>
    }

    private _onConnectionChange = (connectionId?: string) => {
        const isLinked = !!this.props.sourceData?.userId;
        if (isLinked) {
            return;
        }

        this.setState({
            connectionId: connectionId,
            userId: undefined,
            displayName: undefined
        });
    }

    private _linkToUser = () => {
        const { users, actions, dismissPanel } = this.props;
        const { connectionId, userId } = this.state;
        if (!connectionId || !userId) {
            return;
        }

        const user = users.entities.find(_ => _.id === this.state.userId);
        if (!user) {
            return;
        }

        actions.linkToUser({ connectionId, data: user });
        dismissPanel && dismissPanel();
    }

    private _deleteUserLink = () => {
        const { connectionId } = this.state;
        if (!connectionId) return;

        this.props.actions.deleteUserLink(connectionId);
        this.setState({ userId: undefined, displayName: undefined, email: undefined });
    }

    private _getComboboxValidationMessage = () => {
        if (!this.state.connectionId || this.props.users.isLoading) {
            return undefined;
        }

        return this.props.users.error
            ? this.props.users.error
            : !this.state.userId
                ? `Please select resource from list`
                : undefined;
    }

    private onRenderItem = (props?: ISelectableOption, defaultRender?: (props?: ISelectableOption) => JSX.Element | null): JSX.Element | null => {
        if (!defaultRender) {
            return null;
        }

        let element = defaultRender(props);
        if (!props || !props.disabled) {
            return element;
        }
        return React.createElement("div", { title: "Already linked" }, element);
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { 
        users: state.office365.users,
        connections: state.office365.connections
    };
}

export default connect(mapStateToProps, actionCreators)(O365ConnectControl);