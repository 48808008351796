import { Action, Reducer } from 'redux';
import { getSignalRConnection } from '../components/utils/signalr';
import { HubConnection } from '@microsoft/signalr';

export type SignalRState = { connection: HubConnection | null };

type CreateConnectionAction = { type: 'SIGNALR_CREATE_CONNECTION' }
export type RoadmapUpdatedAction = { type: 'SIGNALR_ROADMAP_UPDATED', roadmapId: string }

type KnownAction = CreateConnectionAction | RoadmapUpdatedAction;

const initialState: SignalRState = {
    connection: null
};

export enum EventType {
    RoadmapUpdated = "RoadmapUpdated",
    ProjectSyncFinished = "ProjectSyncFinished"
}

export const reducer: Reducer<SignalRState> = (state = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SIGNALR_CREATE_CONNECTION": {
            const connection = getSignalRConnection("/events");

            return {
                ...state,
                connection
            }
        }
        case "SIGNALR_ROADMAP_UPDATED":
            state.connection?.send("SendRoadmapUpdated", action.roadmapId);
            return state;
        default:
            return state;
    }
};