import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { SourceTypeMap } from "../../../../../store/ExternalEpmConnectStore";
import { Field } from "../../../../../entities/Metadata";
import BaseLinkedSystem from '../BaseLinkedSystem';
import { EntityType } from '../../../../../entities/common';

export default class LinkedSystem extends React.Component<IListViewFieldColumn<ProjectInfo>> {
    public render() {
        const { field, entity } = this.props;
        return <BaseLinkedSystem
            field={field}
            sourceInfos={entity.sourceInfos}
            taskProgresses={entity.calculation.taskProgresses}
            entityType={EntityType.Project}
        />
    }

    public static getValue(entity: ProjectInfo, field: Field): string | undefined {
        return entity.sourceInfos.length
            ? SourceTypeMap[entity.sourceInfos[0].type]
            : undefined;
    }

    public static getSearchValue(entity: ProjectInfo, field: Field): string[] | undefined {
        return entity.sourceInfos?.map(_ => SourceTypeMap[_.type]);
    }
}