import * as React from 'react';
import { DetailsList, IColumn, IDetailsListProps, DetailsListBase } from 'office-ui-fabric-react';
import { MIN_COLUMN_WIDTH, SortDirection } from '../../entities/common';
import { arraysEqual } from '../utils/common';

// dirty hack to override column min width politics of DetailsList
// details: https://github.com/microsoft/fluentui/issues/9287
const updateComposedComponentRef = (DetailsListBase.prototype as any)._updateComposedComponentRef;
(DetailsListBase.prototype as any)._updateComposedComponentRef = function (self: any) {
    if (self) {
        self._onColumnResized = function (resizingColumn: IColumn, newWidth: number, resizingColumnIndex: number) {
            const newCalculatedWidth = Math.max(MIN_COLUMN_WIDTH, newWidth);
            if (self.props.onColumnResize) {
                self.props.onColumnResize(resizingColumn, newCalculatedWidth, resizingColumnIndex);
            }

            self._rememberCalculatedWidth(resizingColumn, newCalculatedWidth);
            self._adjustColumns(self.props, true, resizingColumnIndex);
            self._forceListUpdates();
        }

    }
    
    updateComposedComponentRef.call(DetailsListBase.prototype, self);
}

export default class DetailsListWrapper extends React.Component<IDetailsListProps> {

    componentDidMount() {
        // todo: remove it after bug 3608 of office-ui-fabric-react will be fixed
        window.dispatchEvent(new Event('resize'));
    }

    render() {
        return <DetailsList {...this.props} />;
    }

    componentWillReceiveProps(props: IDetailsListProps) {
        if (this.props.getKey && props.getKey &&
            !arraysEqual(this.props.items.map(_ => this.props.getKey!(_)), props.items.map(_ => props.getKey!(_)))) {
            //items hKeys changed, so we need to preserve selection
            this._preserveSelection(this.props, props);
        }
    }

    private _preserveSelection(prevProps: IDetailsListProps | undefined, nextProps: IDetailsListProps) {
        if (prevProps?.selection && nextProps.getKey && nextProps.selection) {
            const { selection, items, getKey } = nextProps;
            const prevSelectionIds = (prevProps?.selection?.getSelection() ?? []).map((_: any) => _.id);

            selection.setChangeEvents(false);
            selection?.setItems(items, false);
            prevSelectionIds.forEach((_: any) => {
                const item = items.find(__ => __.id === _);
                if (!item) { return; }
                const key = getKey!(item);
                selection.setKeySelected(key, true, true);
            });
            selection.setChangeEvents(true);
        }
    }
}

export const getOrderBy = (fieldName: string, column: IColumn) => ({
    fieldName: fieldName,
    direction: !column.isSorted || (column.isSorted && column.isSortedDescending) ? SortDirection.ASC : SortDirection.DESC
})