import * as React from 'react';
import { ISubentity } from "../../../entities/Subentities";
import { Dictionary } from '../../../entities/common';
import { GenericPanelProps, UpdatePanel } from './UpdatePanel';
import * as Metadata from '../../../entities/Metadata';

export type Props<TSubentity extends ISubentity> = GenericPanelProps<TSubentity> & {
    buildNewEntity?: () => TSubentity;
    onComplete: (entity: TSubentity, callback?: (entity: TSubentity) => void) => void;
}

const CreatePanel = <TSubentity extends ISubentity>(props: Props<TSubentity>) => {
    const entity = React.useMemo(() => {
        const raw = props.buildNewEntity?.() ?? { attributes: {} } as TSubentity;
        const system = (props.fields instanceof Function ? props.fields(raw) : props.fields)
            .filter(Metadata.isRequired).reduce((a, f) => ({ ...a, [f.name]: undefined }), {});
        return { ...raw, attributes: { ...system, ...raw.attributes } };
    }, []);
    const onComplete = React.useCallback(
        (id: string, changes: Dictionary<unknown>) => props.onComplete({ ...entity, attributes: { ...entity.attributes, ...changes } }),
        [props.onComplete]);

    const subentityTypeLabel = props.subentityTypeLabel instanceof Function ? props.subentityTypeLabel(entity) : props.subentityTypeLabel;
    const header = React.useMemo(
        () => ({
            ...props.header,
            secondaryText: `Fill in the fields below to create new ${subentityTypeLabel}`
        }),
        [props.header, subentityTypeLabel]);
    return <UpdatePanel
        {...props}
        subentityTypeLabel={subentityTypeLabel}
        header={header}
        operation="Create"
        entity={entity}
        onComplete={onComplete}
    />;
}

export default CreatePanel;