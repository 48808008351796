import * as React from "react";
import { SourceType } from "../../../../store/ExternalEpmConnectStore";
import { ISourceInfo } from "../../../../entities/common";
import VSTSConfigureConnectionPanel, { VSTSTabs } from "../../../integration/Vsts/VSTSConfigureConnectionPanel";
import { IVSTSSourceData } from "../../../../store/integration/VSTSStore";
import JiraConfigureConnectionPanel, { JiraTabs } from "../../../integration/Jira/JiraConfigureConnectionPanel";
import { IJiraSourceData } from "../../../../store/integration/JiraStore";
import SpoMappingPanel from "../../../integration/Spo/SpoMappingPanel";
import MondayComConfigureConnectionPanel, { MondayComTabs } from "../../../integration/MondayCom/MondayComConfigureConnectionPanel";
import { IMondayComBaseSourceData } from "../../../../store/integration/MondayComStore";
import SmartsheetMappingPanel from "../../../integration/Smartsheet/SmartsheetMappingPanel";
import { ISmartsheetProjectSourceData } from "../../../../store/integration/SmartsheetStore";
import P4WMappingPanel from "../../../integration/P4W/P4WMappingPanel/P4WMappingPanel";

export const supported = [SourceType.VSTS, SourceType.Jira, SourceType.Spo, SourceType.MondayCom, SourceType.Smartsheet, SourceType.P4W];

type Props = {
    sourceInfo: ISourceInfo;
    onDismiss: () => void;
}

export default class ImportKeyDateSettings extends React.Component<Props> {
    public render() {
        const { sourceInfo } = this.props;
        return <>
            {sourceInfo.type === SourceType.VSTS && this._renderVSTS()}
            {sourceInfo.type === SourceType.Jira && this._renderJira()}
            {sourceInfo.type === SourceType.Spo && this._renderSpo()}
            {sourceInfo.type === SourceType.MondayCom && this._renderMondayCom()}
            {sourceInfo.type === SourceType.Smartsheet && this._renderSmartsheet()}
            {sourceInfo.type === SourceType.P4W && this._renderP4W()}
        </>;
    }

    private _renderVSTS() {
        const { sourceInfo, onDismiss } = this.props;
        const sourceData = sourceInfo.sourceData as IVSTSSourceData;
        return <VSTSConfigureConnectionPanel
            projectId={sourceData.projectId}
            enableMapping={sourceData.workItems.length === 1}
            connectionId={sourceInfo.connectionId}
            onDismiss={onDismiss}
            initialTab={VSTSTabs.ImportTask}
        />
    }

    private _renderJira() {
        const { sourceInfo, onDismiss } = this.props;
        const sourceData = sourceInfo.sourceData as IJiraSourceData;
        return <JiraConfigureConnectionPanel
            projectId={sourceData.projects[0]?.id}
            enableMapping={sourceData.issues.length === 1}
            connectionId={sourceInfo.connectionId}
            onDismiss={onDismiss}
            initialTab={JiraTabs.ImportTask}            
        />;
    }

    private _renderSpo() {
        const { sourceInfo, onDismiss } = this.props;
        return <SpoMappingPanel
            connectionId={sourceInfo.connectionId}
            onDismiss={onDismiss}
        />;
    }

    private _renderMondayCom() {
        const { sourceInfo, onDismiss } = this.props;
        const sourceData = sourceInfo.sourceData as IMondayComBaseSourceData;
        return <MondayComConfigureConnectionPanel
            connectionId={sourceInfo.connectionId}
            itemBoardId={sourceData.boardId}
            subItemBoardId={sourceData.subItemsBoardId}
            onDismiss={onDismiss}
            initialTab={MondayComTabs.ImportTask}            
        />;
    }

    private _renderSmartsheet() {
        const { sourceInfo, onDismiss } = this.props;
        const sourceData = sourceInfo.sourceData as ISmartsheetProjectSourceData;
        return <SmartsheetMappingPanel
            connectionId={sourceInfo.connectionId}
            itemSheetId={sourceData.sheetId}          
            onDismiss={onDismiss}
        />;
    }

    private _renderP4W() {
        const { sourceInfo, onDismiss } = this.props;
        return <P4WMappingPanel
            connectionId={sourceInfo.connectionId}
            itemProjectId={sourceInfo.sourceData.projectId}
            onDismiss={onDismiss}
        />;
    }
}