import * as React from 'react';
import { SourceType } from "../../store/ExternalEpmConnectStore";
import { Dictionary, ISourceInfo, SourceInfo } from "../../entities/common";
import { Integrations } from '../../store/Tenant';
import { RendersMap, TabConnectControl } from './TabConnectControl';
import SystemsList from './SystemsList';

type Props = {
    rendersMap: RendersMap;
    integrations: Integrations;
    readonly: boolean;
    actions: Dictionary<Dictionary<any>>;
    onDismiss: () => void;
    sourceInfos: ISourceInfo[];
    hiddenOptions?: SourceType[];
    openCalendlyPanel?: () => void;
};

const externalSystems = [SourceType.File, SourceType.VSTS, SourceType.Jira, SourceType.O365Planner,
    SourceType.Spo, SourceType.MondayCom, SourceType.Smartsheet, SourceType.MPPFile, SourceType.P4W
];

const LinkTab = (props: Props) => {
    const connectedSystems = React.useMemo(() => 
        SourceInfo.getSources(props.sourceInfos).filter(_ => !props.hiddenOptions?.includes(_.type)).map(_ => _.type),
        [props.sourceInfos]
    );
    const avaliableExternalSystems = externalSystems.filter(_ => !props.hiddenOptions?.includes(_));
    const systems = React.useMemo(() => avaliableExternalSystems.map(_ => ({ 
        type: _, 
        isConnected: connectedSystems.indexOf(_) > -1,
        isUnavailable: props.integrations && props.integrations.isDisabled(_) && _ !== SourceType.MPPFile
    })), [connectedSystems]);
    const [system, setSystem] = React.useState<SourceType>(connectedSystems[0] ?? avaliableExternalSystems[0]);
    return <>
        <SystemsList selectedSystem={system} systems={systems} onSelect={setSystem} />
        {system !== undefined && <TabConnectControl
            {...props}
            connector={system}
            rendersMap={props.rendersMap}
            integrations={props.integrations}
        />}
    </>;
}
export default LinkTab;