import * as React from "react";
import { Dictionary } from "../../entities/common";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import { FormatType, FieldInfo } from "../../entities/Metadata";
import { ISettingsEditComponent, IBaseFieldInfo, FieldActionTypes } from "./FieldPanel";
import { FieldTypeDrowdown } from "./FieldTypeDrowdown";

type Props = {
    settings: Dictionary<any>;
    isNative: boolean;
    actionType: FieldActionTypes;
    onChange: (isValid: boolean) => void;
}

type State = {
    format: number;
    options: IDropdownOption[];
}

const NoneOption = {
    key: -1,
    text: "None",
    data: { 
        iconName: 'PPMXFieldInteger',
        description: "For whole numbers"
    }
};

export class NumberSettingsEdit extends React.Component<Props, State> implements ISettingsEditComponent {
    constructor(props: Props) {
        super(props);
        this.state = {
            format: props.settings.format ?? NoneOption.key,
            options: [NoneOption, ...Object.keys(FormatType)
                .filter(k => typeof FormatType[k as any] === "number")
                .map(k => {
                    const key = FormatType[k as any] as any as FormatType;
                    return { key: key, text: k, data: getFormatTypeDescriptor(key) };
                })]
        }
    }

    public render() {
        const { format, options } = this.state;
        const { isNative, actionType } = this.props;

        return <FieldTypeDrowdown
            label='Display Format'
            selectedKey={format}
            disabled={isNative && actionType == FieldActionTypes.Edit}
            onChange={this._onFormatChange}
            defaultSelectedKeys={[0]}
            placeholder='Select format'
            options={options} />
    }

    public save(fieldInfo: FieldInfo) {
        this._applyChanges(fieldInfo);
    }

    public update(fieldInfo: IBaseFieldInfo) {
        this._applyChanges(fieldInfo);
    }

    private _applyChanges(fieldInfo: IBaseFieldInfo | FieldInfo) {
        const { format } = this.state;

        fieldInfo.settings = {
            ...fieldInfo.settings,
            format
        };
    }

    private _onFormatChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (!option) return;

        this.setState({ format: option.key as number }, () => {
            this.props.onChange(true);
        });
    }
}

const formatTypeDescriptorMap = {
    [FormatType.Cost]: {
        iconName: "PPMXFieldFormatCost",
        description: 'Numerical data prefixed with a currency symbol'
    },
    [FormatType.Days]: {
        iconName: 'PPMXFieldFormatDays',
        description: 'Numerical data in days'
    },
    [FormatType.Duration]: {
        iconName: 'PPMXFieldFormatDuration',
        description: 'Numerical data in hours'
    },
    [FormatType.Percent]: {
        iconName: 'PPMXFieldFormatPercent',
        description: 'Numerical data in percent'
    }
}

function getFormatTypeDescriptor(type: FormatType) {
    return formatTypeDescriptorMap[type];
}