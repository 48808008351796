import React from 'react';
import GroupedDraggableList, { IDraggableItem } from '../GroupedDraggableList';

const DEFAULT_GROUP = "default";

type Props = {
    items: IDraggableItem[];
    itemClassName?: string;
    onItemRender: (item: any, index: number) => JSX.Element | (JSX.Element | null)[];
    onChanged?: (items: any[], changedPositionItem: any) => void;
    isItemDraggable?: (item: any, index: number) => boolean;
    getKey?: (item: IDraggableItem, index: number) => string | number;
};

const DraggableList = (props: Props) => (
    <GroupedDraggableList
        {...props}
        groupedItems={[{
            group: DEFAULT_GROUP,
            items: props.items,
        }]}
        onItemRender={(item, _, ind) => props.onItemRender(item, ind)}
        onChanged={props.onChanged ? (groupedItems, _, changedPosItem) => props.onChanged!(groupedItems[0].items, changedPosItem) : undefined}
        isItemDraggable={props.isItemDraggable ? (item, _, ind) => props.isItemDraggable!(item, ind) : undefined}
        getKey={props.getKey ? (item, _, ind) => props.getKey!(item, ind) : undefined}
    />
);

export default DraggableList;
