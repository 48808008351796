import React from 'react';
import { Label, Toggle } from 'office-ui-fabric-react';
import PPMXSharePeoplePicker, { IUserProps } from '../../../common/inputs/PPMXSharePeoplePicker';
import Spinner from '../../../common/Spinner';
import { LeaveFeedback } from '../../../common/ai/leaveFeedback';
import './AIDigestShareControl.css';
import { AiDigest } from '../common';
import FieldLabel from '../../../integration/FieldLabel';

type Props = {
    projectId: string;
    aiDigest: AiDigest;
    selectedUsers: IUserProps[];
    htmlViewSelected: boolean;
    isLoading: boolean;
    setDigestHtmlView: (isSelected: boolean) => void;
    setSelectedUsers: (users: IUserProps[]) => void;
}

const AIDigestShareControl = (props: Props) => {
    const { aiDigest, selectedUsers, projectId, htmlViewSelected, isLoading, setSelectedUsers, setDigestHtmlView } = props;

    return <div className='share-card'>
        {isLoading 
            ? <Spinner text={"Generating AI Project Digest"} />
            : aiDigest.errorMessage
                ? <div className='error-message'>{aiDigest.errorMessage}</div>
                : <>
                    <div className='share-field'>
                        <Label>
                            <FieldLabel 
                                label='Recipients'
                                title='Select an existing PPM Express user or input any email address to send a project digest. 
                                    Please note that sending a project digest will not send a tenant invitation.'/>
                        </Label>
                        <PPMXSharePeoplePicker 
                            pickerPlaceholder='Name or email address' 
                            selectedUsers={selectedUsers} 
                            setSelectedUsers={setSelectedUsers}
                            maxUsersCount={10} />
                    </div>
                    <div className='share-card-container'>
                        {htmlViewSelected
                            ? <div className='generated-html-digest' dangerouslySetInnerHTML={{ __html: aiDigest.html ?? "" }}></div>
                            : <div className='generated-digest'>{aiDigest.digest}</div>}
                        <div className='share-card-footer'>
                            <div className='share-card-footer-controls'>
                                <Toggle
                                    label="Format as HTML"
                                    disabled={isLoading}
                                    checked={htmlViewSelected}
                                    onChange={(e, _) => setDigestHtmlView(_!)}
                                />
                                <LeaveFeedback 
                                    subject="AI Project Digest"
                                    url={`api/ai/project/${projectId}/digest/feedback`} 
                                    disabled={isLoading || !!aiDigest.errorMessage}
                                    data={{
                                        request: aiDigest.prompt,
                                        response: htmlViewSelected ? aiDigest.html : aiDigest.digest,
                                        isHtml: htmlViewSelected,
                                    }}/>
                            </div>
                            <div className='html-toggle-info'>If enabled, data included in project digest will be sent to Open AI for formatting.</div>
                        </div>
                    </div>
                </>
        }
    </div>
}

export default AIDigestShareControl;