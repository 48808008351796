import * as React from "react";
import { ISourceInfo } from "../../entities/common";
import { SourceType } from "../../store/ExternalEpmConnectStore";
import SystemsList from "../integration/SystemsList";
import { Integrations } from "../../store/Tenant";

type Props = {
    selected: SourceType;
    sources: SourceType[];
    sourceInfos: ISourceInfo[];
    integrations?: Integrations;
    onChanged: (sourceType: SourceType) => void;
}

const ExternalSystemChoice = (props: Props) => {
    const systems = React.useMemo(() => props.sources.map(_ => ({
        type: _,
        isDisabled: props.sourceInfos && props.sourceInfos.length > 0 && props.sourceInfos.every(__ => __.type !== _),
        isConnected: props.sourceInfos && props.sourceInfos.some(__ => __.type === _),
        isUnavailable: props.integrations && props.integrations.isDisabled(_)
    })), [props.sources, props.sourceInfos]);
    return <SystemsList selectedSystem={props.selected} systems={systems} onSelect={props.onChanged} />;
}
export default ExternalSystemChoice;