import React, { useEffect, useState } from "react";
import { notEmpty, toDateTime } from "../../utils/common";
import TimeTrackingCellValue from "./TimeTrackingCellValue";
import { CreateTimeTrackingEntryAttr, TimeTrackingEntry, TimeTrackingLine, TimelineToCreateTimeEntry } from "../../../store/TimeTrackingStore";
import { IScaleTimelineSegment } from "../../common/timeline/TimelineSegment";
import TimeTrackingEditCell from "./TimeTrackingEditCell";
import SelectionExt from "../../../components/common/SelectionExt";
import { Icon } from "office-ui-fabric-react";
import { getDaysBetweenDates } from "../../common/timeline/utils";

interface OwnProps {
    timeTrackingLine: TimeTrackingLine;
    segment: IScaleTimelineSegment;
    onEditCompleted: (entries: ModifiedTimeEntry[]) => void;
    onEditStarted: () => void;

    onKeyDown?: React.KeyboardEventHandler;
    onClick?: React.MouseEventHandler;
    componentRef?: React.LegacyRef<HTMLDivElement>;

    selection?: SelectionExt;
    selectionIndex?: number;

    isLocked: boolean;
    isReadonly: boolean;
    isDayOff: boolean;
}

type ModifiedTimeEntry = {
    id?: string,
    attributes: CreateTimeTrackingEntryAttr
};



type Props = OwnProps;

export default function TimeTrackingCell(props: Props) {
    const { timeTrackingLine, segment, onKeyDown, onClick, componentRef, selection, selectionIndex, isLocked, isDayOff } = props;
    const { onEditStarted, onEditCompleted } = props;

    const [isEditMode, setIsEditMode] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
     
    useEffect(() => {
        const onSelectionChanged = () => {
            setIsSelected(selection!.isIndexSelected(selectionIndex!));
        };

        if (selectionIndex !== undefined) {
            selection?.AddEventHandler(onSelectionChanged, selectionIndex);
        }

        return () => {
            selection?.RemoveEventHandler(onSelectionChanged);
        };
    }, [selection, selectionIndex]);

    const entries = timeTrackingLine.TimeEntries.filter(_ => _.attributes.Date >= segment.startDate && _.attributes.Date <= segment.finishDate);
    const reported = entries.reduce((sum, cur) => sum + cur.attributes.Duration!, 0);

    const isReadonly = props.isReadonly
        || isLocked
        || (!reported && (
            timeTrackingLine.attributes.TaskDetails?.isArchived
            || timeTrackingLine.attributes.TaskDetails?.isDeleted))
        || false;

    const isEditable = !isReadonly;

    const [shownValue, setShownValue] = useState(reported);

    useEffect(() => {
        setShownValue(reported);
    }, [timeTrackingLine.TimeEntries]);

    const className = [
        "time-tracking-cell",
        "fill-space",
        isSelected ? "selected" : undefined,
        isEditMode ? "edit-mode" : undefined,
        isReadonly ? "readonly" : undefined
    ]
        .filter(notEmpty)
        .join(" ");
    
    const startEditing = () => {
        setIsEditMode(true);
        onEditStarted();
    };

    return (
        <div
            tabIndex={isEditable ? 0 : undefined}
            ref={componentRef}
            data-selection-index={selectionIndex}
            onClick={onClick}
            onDoubleClick={
                isEditable
                    ? () => {
                        startEditing();
                      }
                    : undefined
            }
            onKeyDown={
                isEditable
                    ? e => {
                        if (!isEditMode) {
                            if (e.key === "Enter" || !Number.isNaN(Number(e.key))) {
                                startEditing();
                            }
                            else if (e.key === "Delete" || e.key === "Backspace") {

                                if (entries.some(_ => _.attributes.Duration)) {

                                    const emptyDuration = 0;

                                    const modifiedEntries = _applyDuration(entries, emptyDuration, segment, timeTrackingLine);
                                    onEditCompleted(modifiedEntries);

                                    setShownValue(emptyDuration);
                                }
                            }
                        }

                        onKeyDown?.(e)
                    }
                    : onKeyDown
            }
            className={className}>
            <div className={`cell-content fill-space columns-${1}`}>
                {isLocked &&
                    <Icon
                    iconName="LockSolid"
                    className="lock" />}
                {isEditMode ? (
                    <TimeTrackingEditCell
                        value={shownValue}
                        onEditCompleted={value => {

                            if (value !== shownValue) {
                                
                                const modifiedEntries = _applyDuration(entries, value ?? 0, segment, timeTrackingLine);
                                onEditCompleted(modifiedEntries);
                            }
                            else {
                                onEditCompleted([]);
                            }

                            setIsEditMode(false);
                            setShownValue(value ?? 0);
                        }}
                    />
                ) : (
                        <TimeTrackingCellValue
                            value={shownValue}
                            title={isLocked ? "Locked" : "Reported"}
                            isDayOff={isDayOff} />
                )}
            </div>
        </div>
    );
}

const _applyDuration = (
    entries: TimeTrackingEntry[],
    duration: number,
    segment: IScaleTimelineSegment,
    timeTrackingLine: TimeTrackingLine): ModifiedTimeEntry[] => {
    
    //modify only if there is reported time. otherwise create new
    const modifiedEntries: ModifiedTimeEntry[] = entries.filter(_ => _.attributes.Duration);
                            
    const days = getDaysBetweenDates(segment.startDate, segment.finishDate);

    duration = (duration || 0) / days.length;

    if (!modifiedEntries.length) {
        days.forEach(date => {

            const entryDate = date.toDateOnlyString();

            if (modifiedEntries.some(_ => toDateTime(_.attributes.Date)?.toDateOnlyString() === entryDate)) {
                return;
            }

            modifiedEntries.push({
                attributes: TimelineToCreateTimeEntry(timeTrackingLine, entryDate, duration)
            });
        });
    }

    modifiedEntries.forEach(_ => (_.attributes.Duration = duration));
    
    return modifiedEntries;
}
