import * as React from 'react';
import { EntityType, Dictionary, ServerEntityType, LARGE_PANEL_WIDTH, IWithName, IExtensibleEntity } from '../../entities/common';
import * as Metadata from '../../entities/Metadata';
import {
    ActionButton, CheckboxVisibility, CommandBar, DefaultButton, IColumn, IconButton, ISearchBox, Overlay, PanelType, ScrollablePane, ScrollbarVisibility,
    SearchBox, TooltipHost, ITooltipHostProps, StickyPositionType, Sticky, Icon, Spinner as FabricSpinner, SpinnerSize,
    IDetailsHeaderProps, IRenderFunction, IDetailsRowProps
} from 'office-ui-fabric-react';
import { IsEmptyObject, notUndefined, toDictionaryByName, waitForFinalEvent } from '../utils/common';
import DetailsListWrapper from '../common/DetailsListWrapper';
import { ViewService } from '../../services/ViewService';
import { post } from '../../fetch-interceptor';
import Spinner from '../common/Spinner';
import { ProjectAttrs } from '../../store/ProjectsListStore';
import { nameof, namesof } from '../../store/services/metadataService';
import { DisplayFieldService } from '../common/DisplayFieldService';
import { FieldsService } from '../common/FieldsService';
import { EntityDescriptor } from '../../store/RoadmapsListStore';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { IKeyDateAttrs, ITaskAttrs } from '../../entities/Subentities';
import { ProgramAttrs } from '../../store/ProgramsListStore';
import { IdeaAttrs } from '../../store/IdeasListStore';
import { IObjectiveAttrs } from '../../store/ObjectivesListStore';
import { BaseFilterAttribute } from '../common/FilterAttributes/BaseFilterAttribute';
import ExpandablePanel from '../common/ExpandablePanel';
import EntityName from '../views/list/columns/EntityName';
import { PersonInfo } from './inputs/PersonPickerInput';
import { ResultsNotFoundPlaceholder } from './sectionsControl/SectionPlaceholder';
import { PPMFeatures, Subscription } from '../../store/Tenant';

enum EntityStatus {
    Added,
    Add,
    Undo,
    Processing
}
type EntityInfo<TAttrs = Dictionary<unknown>> = {
    id: string;
    uniqueId?: string;
    entityType: ServerEntityType;
    attributes: TAttrs;
    externalData: Dictionary<unknown>;
    imageId?: string;
};
type Suggestion = { entity: EntityInfo, parentEntities: EntityInfo[] };
type Filter = Dictionary<unknown>;

type InitialFilter = {
    AssignedTo?: PersonInfo[];
    Date?: {
        from?: Date;
        to?: Date
    },
    Projects?: { id: string }[];

}

type ParentableEntityTypes = { [k: number]: ServerEntityType };

interface OwnProps {
    title: string;
    subTitle: string;

    addButtonText?: string;

    disabled?: boolean;
    onDismiss: () => void;
    onImport: (descriptor: EntityDescriptor, callback: () => void, entity: IExtensibleEntity) => void;
    onUndoImport: (type: ServerEntityType, externalId: string, callback: () => void) => void;
    isEntityImported: (externalEntityId: string) => boolean;

    selectedEntityType?: ServerEntityType;
    supportedEntityTypes?: Array<ServerEntityType | undefined>;
    showMyWorkItems?: boolean;
    exceptIds?: string[];

    initialFilter?: InitialFilter;
    showStatusColumn?: (type: ServerEntityType) => boolean;
    showParentColumn?: (type: ServerEntityType) => boolean;
}


type StoreProps = {
    fieldsMaps: Dictionary<Dictionary<Metadata.Field>>;
    subscription: Subscription;
};

type Props = OwnProps & StoreProps;

type State = {
    suggestions: Suggestion[];
    isLoading?: boolean;
    searchText: string;
    type?: ServerEntityType;
    showFilter?: boolean;
    filter: Filter;
    importedEntitiesIds: string[];
    inProcessEntitiesIds: string[];
    parentableEntityTypes: ParentableEntityTypes;
    tabMap: EntityTab[];
    exceptIds?: string[];
}
const timeDelay = 600;

const defaultFilter: Filter = {};

class SearchItemsImportPanel extends React.Component<Props, State> {
    private _searchBoxInput?: HTMLInputElement;
    private debouncedOnSearch: () => void;

    constructor(props: Props) {
        super(props);
        this.debouncedOnSearch = waitForFinalEvent(this._onSearch, timeDelay, `${props.title}-items-import-search`);

        const filter = buildFilter(props.initialFilter);
        const tabMap = this.getTabMap();

        this.state = {
            searchText: '',
            suggestions: [],
            type: props.selectedEntityType ?? tabMap[0].type,
            filter: filter,
            importedEntitiesIds: [],
            inProcessEntitiesIds: [],
            parentableEntityTypes: getParentableEntityTypes(props.supportedEntityTypes),
            showFilter: !IsEmptyObject(filter),
            tabMap: tabMap,
            exceptIds: props.exceptIds
        };
    }

    render() {
        const { onDismiss } = this.props;
        const { suggestions, showFilter, type, isLoading } = this.state;

        return (
            <ExpandablePanel
                className="search-item-import-panel"
                isLightDismiss
                type={PanelType.custom}
                customWidth={LARGE_PANEL_WIDTH}
                isOpen
                onDismiss={onDismiss}
                onRenderHeader={this._onRenderHeader}>
                <SearchBox
                    autoFocus
                    placeholder="Search item by Name, GUID or ID"
                    disabled={this.props.disabled}
                    value={this.state.searchText}
                    componentRef={this._componentRef}
                    onChange={(e: any, v: string) => this.setState({ searchText: v }, this.debouncedOnSearch)}
                    onClear={this._clear}
                    onEscape={this._dismiss}
                    onFocus={() => isLoading === undefined && this.debouncedOnSearch()}
                />
                <CommandBar className="header-command-bar commandbar-with-buttons"
                    items={
                        this.state.tabMap.length > 1
                            ? this.state.tabMap
                                .map(_ => ({
                                    key: _.key,
                                    text: _.label,
                                    onRender: () => <DefaultButton className={`item ${this.state.type === _.type ? "selected" : ""}`}
                                        onClick={() => this._onTabChange(_.type)}>
                                        {_.label}
                                    </DefaultButton>
                                }))
                            : []}
                    farItems={[type !== undefined ? {
                        key: "filter",
                        text: "Filter",
                        onRender: () => <DefaultButton className="filter-button"
                            onClick={this._onFilterClick}
                            iconProps={{ iconName: this._isFilterEmpty() ? "Filter" : "ClearFilter" }}>
                            {this._isFilterEmpty() ? "Filter" : "Clear Filter"}
                        </DefaultButton>
                    } : undefined].filter(notUndefined)}
                />
                {showFilter && this._renderFilter()}
                <div data-is-scrollable={true} className="list-container">
                    {isLoading === false && suggestions.length === 0
                        ? <div className="placeholder">
                            <ResultsNotFoundPlaceholder
                                clearFilter={
                                    this._isFilterEmpty() && !this.state.searchText
                                        ? undefined
                                        : () => {
                                            this.setState({ searchText: "" });
                                            this._onFilterClick();
                                        }
                                } />
                        </div>
                        : <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <DetailsListWrapper checkboxVisibility={CheckboxVisibility.hidden}
                                items={suggestions}
                                columns={this._getColumns()}
                                onRenderRow={(props: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null) => {
                                    const row = defaultRender?.(props);
                                    const item = props.item as Suggestion;
                                    const status = this._getItemStatus(item);
                                    if (row && (status === EntityStatus.Added || status === EntityStatus.Undo)) {
                                        return React.cloneElement(row, { className: "item-added" });
                                    }
                                    return row || null;
                                }}
                                disableSelectionZone
                                onRenderDetailsHeader={this._onRenderDetailsHeader}
                                className='not-clickable'
                            />
                        </ScrollablePane>}
                    {this.state.isLoading && <Overlay><Spinner /></Overlay>}
                </div>
            </ExpandablePanel>
        );
    }

    private _onRenderHeader = (): JSX.Element => {
        return <div className="ms-Panel-header" >
            <div className="ms-Panel-headerText">{this.props.title}</div>
            <div className='ms-Panel-secondaryText'>{this.props.subTitle}</div>
        </div>;
    }

    private _renderFilter = (): JSX.Element | null => {
        const { type, filter } = this.state;
        if (!type) {
            return null;
        }
        const fieldInfos = this.getFilterFields(type);
        const fieldsMap = this.props.fieldsMaps[toEntityType(type)];
        const fields = fieldInfos.fakeFields.concat(fieldInfos.fieldNames.map(_ => fieldsMap[_])).filter(notUndefined);

        return <div className="filter-attributes-container align-center">
            {fields.map(_ => <FilterAttribute key={_.id}
                field={_}
                value={filter[_.name]}
                onEditComplete={(newValue) => {
                    const newFilter = { ...filter, [_.name]: newValue };
                    if (newValue === null) {
                        delete newFilter[_.name];
                    }
                    this.setState({ filter: newFilter }, this.debouncedOnSearch)
                }} />)}
        </div>;
    }

    private _onSearch = () => {
        const { searchText, type, filter } = this.state;
        this.setState({ isLoading: true });
        post<Suggestion[]>(`api/search/importitems`, { name: searchText, entityType: type, attributes: this.toServerAttributes(filter, type) })
            .then(_ => this.setState({ suggestions: _ || [], isLoading: false }))
            .catch(() => this.setState({ suggestions: [], isLoading: false }));
    }

    private _isFilterEmpty = (): boolean => !Object.keys(this.state.filter).length;

    private _onFilterClick = () => {
        if (this._isFilterEmpty()) {
            this.setState({ showFilter: !this.state.showFilter });
        } else {
            this.setState({ filter: { ...defaultFilter } }, this.debouncedOnSearch);
        }
    }

    private _clear = () => {
        this.setState({
            suggestions: [],
            searchText: ''
        });
    }

    private _dismiss = () => {
        this._clear();
        this._searchBoxInput && this._searchBoxInput.blur();
    }

    private _componentRef = (searchBox: ISearchBox | null) => {
        const search = searchBox as any;
        if (search?._rootElement) {
            this._searchBoxInput = search._inputElement.current;
        }
    }

    private _renderNameColumn = (name: keyof Suggestion, entityExtractor: (item: Suggestion) => EntityInfo | undefined) => (item: Suggestion) => {
        const entity = entityExtractor(item);
        if (entity) {
            const entityType = toEntityType(entity.entityType);
            if (!!~Object.keys(this.props.fieldsMaps).indexOf(entityType)) {
                const column = ViewService.buildColumn(nameof<IWithName>("Name"), this.props.fieldsMaps[entityType], undefined, true, entityType, undefined, true);
                if (column) {
                    const cell = customRenders[entityType]
                        ? customRenders[entityType](entity)
                        : column.onRender!(entity, undefined, column);
                    if (this.state.parentableEntityTypes[entity.entityType]) {
                        const type = this.getFilterType(entity.entityType);

                        return <div className="full-width align-center column-content-flex">
                            <div className="shrinkable-child-in-flex">{cell}</div>
                            <IconButton iconProps={{ iconName: "Filter" }}
                                title={`Filter ${ServerEntityType[type]}s by ${ServerEntityType[entity.entityType]} "${entity.attributes.Name}"`}
                                onClick={() => this._filterBy(entity)} />
                        </div>
                    }
                    return cell;
                }
            }
        }
        return null;
    }

    private _getColumns = (): IColumn[] => {

        const totalWidth = 550;
        const statusWidth = 70;

        let nameWidth = (totalWidth - statusWidth) / 2;
        const parentWidth = nameWidth;

        let showParentColumn = true;
        let showStatusColumn = true;

        if (this.state.type) {
         
            if (this.props.showParentColumn?.(this.state.type) === false) {
                nameWidth += parentWidth;
                showParentColumn = false;
            }
            
            if (this.props.showStatusColumn?.(this.state.type) === false) {
                nameWidth += statusWidth;
                showStatusColumn = false;
            }
        }

        const result = [
            {
                minWidth: nameWidth,
                maxWidth: nameWidth,
                key: "entity-name",
                name: "Name",
                fieldName: "entityName",
                onRender: this._renderNameColumn("entity", _ => _.entity)
            }
        ];

        if (showParentColumn) {
            result.push({
                minWidth: parentWidth,
                maxWidth: parentWidth,
                key: "parent-entity-name",
                name: "From",
                fieldName: "parent",
                onRender: this._renderNameColumn("parentEntities", this._getParent)
            });
        }

        if (showStatusColumn) {

            const addButtonText = this.props.addButtonText || "Add";

            result.push(
                {
                    minWidth: statusWidth,
                    maxWidth: statusWidth,
                    key: "status",
                    name: "Status",
                    fieldName: "status",
                    onRender: (item: Suggestion, index?: number, column?: IColumn) => {
                        const status = this._getItemStatus(item);
                        if (status === EntityStatus.Add) {
                            return <ActionButton iconProps={{ iconName: 'Add' }}
                                title={addButtonText}
                                onClick={() => this._onImport(item)}>
                                {addButtonText}
                            </ActionButton>;
                        }
                        if (status === EntityStatus.Added) {
                            return <div className="align-center status-cell">
                                <Icon className="main-color font-14" iconName="LocationDot" />
                                <div className="font-14 label">Added</div>
                            </div>;
                        }
                        if (status === EntityStatus.Processing) {
                            return <div className="align-center status-cell spinner-status">
                                <FabricSpinner size={SpinnerSize.small} title="Processing" />
                            </div>;
                        }
                        if (status === EntityStatus.Undo) {
                            return <ActionButton iconProps={{ iconName: 'Remove' }}
                                title='Remove'
                                onClick={() => this._onUndoImport(item)}>
                                Undo
                            </ActionButton>;
                        }
                        return null;
                    }
                }
            );
        }

        return result;
    }

    private getFilterType(entityType: ServerEntityType) {
        return !this.state.type || this.state.type === entityType
            ? this.state.parentableEntityTypes[entityType]
                ? this.state.parentableEntityTypes[entityType] === this.state.type
                    ? entityType
                    : this.state.parentableEntityTypes[entityType]
                : entityType
            : this.state.type;
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private _filterBy = (entity: EntityInfo) => {
        const type = this.getFilterType(entity.entityType);

        if (!_tabMap.find(_ => _.type === type)) {
            return;
        }

        let filter = this.state.filter;

        if (this.state.type !== type) {
            filter = defaultFilter;
            this.setState({ type, searchText: '' });
        }

        const fieldsInfo = this.getFilterFields(type);
        const fieldsMap = this.props.fieldsMaps[toEntityType(type)]
        const fieldLabel = ServerEntityType[entity.entityType];

        const field = fieldsInfo?.fakeFields?.find(_ => Metadata.getLabel(_) === fieldLabel)
            || fieldsInfo?.fieldNames
                .map(_ => {
                    const f = fieldsMap[_];
                    return f && Metadata.getLabel(f) && f.type === Metadata.FieldType[fieldLabel] ? f : undefined;
                })
                .filter(notUndefined)[0];

        if (field) {
            this.setState({
                showFilter: true,
                filter: { ...filter, [field.name]: [{ id: entity.id, name: entity.attributes.Name }] }
            }, this.debouncedOnSearch);
        }
    }

    private _onTabChange = (type?: ServerEntityType) => {
        const { filter } = this.state;
        const newFilter: Filter = { ...defaultFilter };

        const oldEntityType = this.state.type;
        const newEntityType = type;
        if (newEntityType !== undefined && oldEntityType !== undefined) {
            const newTypeFieldsMap = this.props.fieldsMaps[toEntityType(newEntityType)];
            const newTypeFieldInfos = this.getFilterFields(newEntityType);
            const newTypeFields = newTypeFieldInfos.fakeFields.concat(newTypeFieldInfos.fieldNames.map(_ => newTypeFieldsMap[_])).filter(notUndefined);

            const oldTypeFieldsMap = this.props.fieldsMaps[toEntityType(oldEntityType)];
            const oldTypeFieldInfos = this.getFilterFields(oldEntityType);
            const oldTypeFields = oldTypeFieldInfos.fakeFields.concat(oldTypeFieldInfos.fieldNames.map(_ => oldTypeFieldsMap[_])).filter(notUndefined);

            Object.keys(filter).forEach(fieldName => {
                const oldTypeField = oldTypeFields.find(_ => _.name === fieldName);
                const newTypeField = oldTypeField
                    ? newTypeFields.find(_ => Metadata.getLabel(_) === Metadata.getLabel(oldTypeField) && _.type === oldTypeField.type)
                    : undefined;

                if (oldTypeField && newTypeField) {
                    newFilter[newTypeField.name] = filter[oldTypeField.name];
                }
            });
        }

        this.setState({ type, filter: newFilter }, this.debouncedOnSearch);
    }

    private toServerAttributes(filter: Filter, type?: ServerEntityType): unknown {
        if (!type) {
            return undefined;
        }
        const fieldsMap = this.props.fieldsMaps[toEntityType(type)];
        const fieldInfos = this.getFilterFields(type);
        fieldInfos.fakeFields.forEach(_ => fieldsMap[_.name] = _);

        const serverFilter = {};
        Object.keys(filter).forEach(fieldName => {
            const field = fieldsMap[fieldName];
            const value = filter[fieldName];
            if (value !== undefined) {
                serverFilter[fieldName] = this.getFilterValueId(field.type, value);
            }
        });

        if (this.props.showMyWorkItems) {
            serverFilter[showMyWorkFakeField.name] = true;
        }

        if (serverFilter[exceptIdsFakeField.name]) {
            serverFilter[exceptIdsFakeField.name] = this.state.exceptIds;
        }

        return serverFilter;
    }

    private getFilterValueId(fieldType: Metadata.FieldType, value: unknown): unknown {
        switch (fieldType) {
            case Metadata.FieldType.Resource:
            case Metadata.FieldType.User:
            case Metadata.FieldType.Portfolio:
            case Metadata.FieldType.Program:
            case Metadata.FieldType.Project:
            case Metadata.FieldType.Challenge:
            case Metadata.FieldType.Idea:
            case Metadata.FieldType.Objective:
                return (value as { id: string }[]).map(_ => _.id);
            
            default:
                return value;
        }
    }

    private _getParent = (_: Suggestion): EntityInfo | undefined => _.parentEntities?.[0];

    private _onImport = (suggesion: Suggestion) => {
        const entity = suggesion.entity;
        const parent = this._getParent(suggesion);

        this.setState({
            importedEntitiesIds: [...this.state.importedEntitiesIds, entity.id],
            inProcessEntitiesIds: [...this.state.inProcessEntitiesIds, entity.id]
        });
        const descriptor: EntityDescriptor = {
            entityType: entity.entityType,
            id: entity.id,
            name: entity.attributes.Name as string,
            uniqueId: entity.uniqueId,
            parentEntityType: parent?.entityType,
            parentId: parent?.id,
            parentName: parent?.attributes.Name as string
        };
        this.props.onImport(descriptor, () => this._removeFromInProcessEntitiesIds(entity.id), entity);
    }

    private _onUndoImport = (item: Suggestion) => {
        this.setState({
            inProcessEntitiesIds: [...this.state.inProcessEntitiesIds, item.entity.id]
        });
        this.props.onUndoImport(
            item.entity.entityType,
            item.entity.id,
            () => this._removeFromInProcessEntitiesIds(item.entity.id))
    }

    private _removeFromInProcessEntitiesIds =
        (entityId: string) => this.setState({ inProcessEntitiesIds: this.state.inProcessEntitiesIds.filter(_ => _ !== entityId) });

    private _getItemStatus = (item: Suggestion): EntityStatus => {
        return !!~this.state.inProcessEntitiesIds.indexOf(item.entity.id)
            ? EntityStatus.Processing
            : !this.props.isEntityImported(item.entity.id)
                ? EntityStatus.Add
                : !!~this.state.importedEntitiesIds.indexOf(item.entity.id)
                    ? EntityStatus.Undo
                    : EntityStatus.Added
    }

    private getFilterFields = (entityType: ServerEntityType): { fakeFields: Metadata.Field[], fieldNames: string[] } => {

        switch (entityType) {
            case ServerEntityType.Program:
                
                return {
                    fakeFields: [dateFakeField],
                    fieldNames: [
                        Subscription.contains(this.props.subscription, PPMFeatures.PortfolioManagement)
                            ? nameof<ProgramAttrs>("Portfolio")
                            : undefined,
                        nameof<ProgramAttrs>("Manager"),
                        nameof<ProgramAttrs>("Tags"),
                    ]
                        .filter(notUndefined)
                };
                
            case ServerEntityType.Project:
                return {
                    fakeFields: [dateFakeField],
                    fieldNames: [
                        Subscription.contains(this.props.subscription, PPMFeatures.PortfolioManagement)
                            ? nameof<ProjectAttrs>("Portfolio")
                            : undefined,
                        Subscription.contains(this.props.subscription, PPMFeatures.PortfolioManagement)
                            ? nameof<ProjectAttrs>("Program")
                            : undefined,
                        nameof<ProjectAttrs>("Manager"),
                        nameof<ProjectAttrs>("Tags"),
                    ]
                        .filter(notUndefined)
                };
            
            case ServerEntityType.KeyDate:
                return {
                    fakeFields: [
                        Subscription.contains(this.props.subscription, PPMFeatures.PortfolioManagement)
                            ? portfolioFakeField
                            : undefined,
                        Subscription.contains(this.props.subscription, PPMFeatures.PortfolioManagement)
                            ? programFakeField
                            : undefined,
                        projectFakeField]
                        .filter(notUndefined),
                    fieldNames: namesof<IKeyDateAttrs>(["Type", "AssignedTo", "Date", "Tags"])
                };
            
            case ServerEntityType.Task:
                return {
                    fakeFields: [
                        projectFakeField,
                        dateFakeField,
                        this.state.exceptIds?.length
                            ? exceptIdsFakeField
                            : undefined
                    ]
                    .filter(notUndefined),
                    fieldNames: namesof<ITaskAttrs>(["Type", "AssignedTo", "Tags"])
                };
            case ServerEntityType.Objective:
                return {
                    fakeFields: [dateFakeField],
                    fieldNames: namesof<IObjectiveAttrs>(["Parent", "Tags"])
                };
            case ServerEntityType.Idea:
                return {
                    fakeFields: [dateFakeField],
                    fieldNames: namesof<IdeaAttrs>(["Challenge", "Tags"])
                };
            
            default:
                throw new Error(`Unsupported entity type ${entityType}`);
        }
    }

    private getTabMap = (): EntityTab[] => {
        const disabledEntityTypes: ServerEntityType[] = [];

        if (!Subscription.contains(this.props.subscription, PPMFeatures.PortfolioManagement)) {
            disabledEntityTypes.push(ServerEntityType.Program);
        }

        if (!Subscription.contains(this.props.subscription, PPMFeatures.ProjectManagement)) {
            disabledEntityTypes.push(ServerEntityType.Project);
            disabledEntityTypes.push(ServerEntityType.Task);
        }

        if (!Subscription.contains(this.props.subscription, PPMFeatures.Ideation)) {
            disabledEntityTypes.push(ServerEntityType.Idea);
        }

        if (!Subscription.contains(this.props.subscription, PPMFeatures.OKR)) {
            disabledEntityTypes.push(ServerEntityType.Objective);
        }

        return _tabMap
            .filter(_ => _.type === undefined || !disabledEntityTypes.includes(_.type))
            .filter(_ => !this.props.supportedEntityTypes?.length || this.props.supportedEntityTypes.includes(_.type));
    }
}


const buildFilter = (initialFilter?: InitialFilter): Filter => {

    const result = { ...defaultFilter };

    if (initialFilter) {
        if (initialFilter.AssignedTo) {
            result["AssignedTo"] = initialFilter.AssignedTo;
        }

        if (initialFilter.Date) {
            result[dateFakeField.name] = {
                from: initialFilter.Date.from?.toDateOnlyString(),
                to: initialFilter.Date.to?.toDateOnlyString()
            }
        }

        if (initialFilter.Projects) {
            result[projectFakeField.name] = initialFilter.Projects;
        }
    }

    return result;
}

function mapStateToProps(state: ApplicationState): StoreProps {
    const fieldsMaps = {};

    Object.keys(state.fields).forEach(type => {
        fieldsMaps[type] = toDictionaryByName(state.fields[type].allIds.map(_ => state.fields[type].byId[_]))
    });

    return {
        fieldsMaps: fieldsMaps,
        subscription: state.tenant.subscription
    };
}

export default connect(mapStateToProps)(SearchItemsImportPanel)

const toEntityType = (serverEntityType: ServerEntityType) => EntityType[ServerEntityType[serverEntityType]]
const getParentableEntityTypes = (supportedEntityTypes?: Array<ServerEntityType | undefined>) => {

    supportedEntityTypes ||= _tabMap.map(_ => _.type);

    const map: Map<ServerEntityType, ServerEntityType[]> = new Map([
        [ServerEntityType.Portfolio, [ServerEntityType.Program]],
        [ServerEntityType.Program, [ServerEntityType.Project]],
        [ServerEntityType.Project, [ServerEntityType.KeyDate, ServerEntityType.Task]],
        [ServerEntityType.Challenge, [ServerEntityType.Idea]],
        [ServerEntityType.Objective, [ServerEntityType.Objective]]
    ]);
    
    const result: { [k: number]: ServerEntityType } = {};

    map.forEach((childred, parent) => {

        if (supportedEntityTypes!.includes(parent)) {
            const child = childred.filter(_ => supportedEntityTypes!.includes(_))[0];

            if (child) {
                result[parent] = child;
            }
        }
    })

    return result;
};


type EntityTab = {
    type?: ServerEntityType;
    key: string;
    label: string;
}

const _tabMap: EntityTab[] = [
    { type: undefined, key: "0", label: "All" },
    { type: ServerEntityType.Program, key: "1", label: "Program" },
    { type: ServerEntityType.Project, key: "2", label: "Project" },
    { type: ServerEntityType.KeyDate, key: "4", label: "Key Date" },
    { type: ServerEntityType.Task, key: "3", label: "Task" },
    { type: ServerEntityType.Idea, key: "5", label: "Idea" },
    { type: ServerEntityType.Objective, key: "6", label: "Objective" },
]

const portfolioFakeField: Metadata.Field = {
    name: "Portfolio-Fake-d0faee55",
    label: "Portfolio",
    id: "d0faee55",
    isFake: true,
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Portfolio,
    group: Metadata.FieldGroup.SystemFields,
}
const programFakeField: Metadata.Field = {
    name: "Program-Fake-55761692",
    label: "Program",
    id: "55761692",
    isFake: true,
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Program,
    group: Metadata.FieldGroup.SystemFields,
}
const projectFakeField: Metadata.Field = {
    name: "Project-Fake-c5033dfe",
    label: "Project",
    id: "c5033dfe",
    isFake: true,
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Project,
    group: Metadata.FieldGroup.SystemFields,
}
const dateFakeField: Metadata.Field = {
    name: "Date-Fake-9a1dcba1",
    label: "Date",
    id: "9a1dcba1",
    isFake: true,
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Date,
    group: Metadata.FieldGroup.SystemFields,
}

const showMyWorkFakeField: Metadata.Field = {
    name: "ShowMyWork-Fake-db4a18ac2df5",
    label: "ShowMyWork",
    id: "db4a18ac2df5",
    isFake: true,
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Flag,
    group: Metadata.FieldGroup.SystemFields,
}

const exceptIdsFakeField: Metadata.Field = {
    name: "ExceptIds-Fake-78848f5575ea",
    label: "Hide Added",
    id: "78848f5575ea",
    isFake: true,
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Flag,
    group: Metadata.FieldGroup.SystemFields,
}

type FilterAttributeProps = { field: Metadata.Field, value: unknown, onEditComplete: (data: unknown) => void }
class FilterAttribute extends React.Component<FilterAttributeProps>{
    render() {
        const { field, value } = this.props;

        return <>
            <BaseFilterAttribute hasValue={!!value}
                label={Metadata.getLabel(field)}
                getLabelExtraDetails={this._getLabelExtraDetails}>
                {DisplayFieldService.buildFieldMultiSelectInput(field, value, this._onEditComplete, { inputProps: { autoFocus: true }, autoExpand: true })}
            </BaseFilterAttribute>
        </>;
    }

    private _getLabelExtraDetails = () => {
        const { field, value } = this.props;

        const values = value ? FieldsService.getFieldDisplayValues(field, value) : [];
        let suffix: string = values;
        let count: number = 0;
        if (field.type === Metadata.FieldType.Date) {
            suffix = (values as string[]).filter(_ => !!_).join(' - ');
        } else if (Array.isArray(values)) {
            suffix = values[0];
            count = values.length;
        }
        return { suffix, count };
    }

    private _onEditComplete = (value: any) => {
        const newValue = (Array.isArray(value) && !value.length) ||
            (value.hasOwnProperty("from") && value.from === undefined && value.hasOwnProperty("to") && value.to === undefined) ? undefined : value;
        this.props.onEditComplete(newValue);
    }
}

const customRenders = {
    [EntityType.Program]: (entity: EntityInfo<ProgramAttrs>) => <EntityName
        name={entity.attributes.Name}
        startDate={entity.attributes.StartDate}
        finishDate={entity.attributes.FinishDate}
        withDates={true}
        isTimelineView={true}
        imageClassName='prog-logo'
        imageId={entity.imageId}
        sourceType={EntityName._buildSourceType(entity)}
    />,
    [EntityType.Project]: (entity: EntityInfo<ProjectAttrs>) => <EntityName
        name={entity.attributes.Name}
        startDate={entity.attributes.StartDate}
        finishDate={entity.attributes.FinishDate}
        withDates={true}
        isTimelineView={true}
        imageClassName='proj-logo'
        imageId={entity.imageId}
        sourceType={EntityName._buildSourceType(entity)}
    />
}