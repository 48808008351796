import * as React from 'react';
import { StatusCategoryClassMap, KeyDate, KeyDateType } from '../../entities/Subentities';
import { ITimelineBaseline } from '../common/timeline/TimelineBaseline';
import { IRow } from "../common/timeline/TimelineList";
import { MarkerType } from '../common/timeline/TimelineMarker';
import { toDate } from '../utils/common';
import { Field } from '../../entities/Metadata';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import { EntityType, StatusCategory } from '../../entities/common';

export const KeyDateTypeToMarkerTypeMap: { [i: number]: MarkerType } = {
    [KeyDateType.KeyDate]: MarkerType.KeyDate,
    [KeyDateType.Milestone]: MarkerType.Milestone,
    [KeyDateType.Release]: MarkerType.Release,
}

export function buildTimelineItem(keyDate: KeyDate, keyDateFields: Field[]): IRow {
    const baselines: ITimelineBaseline[] = [];
    const baselineDueDate = toDate(keyDate.baseline?.date);
    if (baselineDueDate) {
        baselines.push({
            key: keyDate.id,
            finishDate: baselineDueDate.getBeginOfDay(),
            isMilestone: true
        });
    }

    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.KeyDate, keyDateFields)!;
    const statusCategory = statusDescriptor.getCategoryOrDefault(keyDate.attributes.Status, StatusCategory.NA);
    return {
        entity: keyDate,
        key: keyDate.id,
        markers: [{
            key: keyDate.id,
            date: toDate(keyDate.attributes.Date)?.getBeginOfDay()!,
            className: StatusCategoryClassMap[statusCategory],
            markerType: KeyDateTypeToMarkerTypeMap[keyDate.attributes.Type]
        }].filter(__ => !!__.date),
        segments: [],
        baselines: baselines
    };
}