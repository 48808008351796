import * as React from "react";
import { UserState } from '../../../../store/User';
import { PPMFeatures, Subscription, TenantState, TimeTrackingGlobalSettings, actionCreators } from '../../../../store/Tenant';
import { CommonOperations, contains } from '../../../../store/permissions';
import LabellableComponent from '../../../common/LabellableComponent';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { bindActionCreators } from 'redux';
import Link from '../../../common/Link';
import { Toggle } from 'office-ui-fabric-react';
import { AlertDialog } from '../../../common/AlertDialog';

type OwnProps = {};
type StateProps = {
    tenant: TenantState;
    user: UserState;
    timeTrackingSettings: TimeTrackingGlobalSettings;    
    canManage: boolean;
    isPrivateProjectExists: boolean;
}
type ActionProps = {
    tenantActions: typeof actionCreators;
}
type Props = StateProps & OwnProps & ActionProps;

function FeaturesSettings(props: Props) {
    const { tenantActions, timeTrackingSettings, canManage, isPrivateProjectExists, tenant } = props;

    const [showPrivateProjectExistsMessage, setShowPrivateProjectExistsMessage] = React.useState(false);
    const onFeatureChange = (feature: PPMFeatures, checked?: boolean) => {
        if (isPrivateProjectExists && !checked) {
            setShowPrivateProjectExistsMessage(true);
            return;
        }

        const newFeatures = checked 
            ? tenant.subscription.features | feature
            : tenant.subscription.features & ~feature;
        tenantActions.updateFeatures(newFeatures);        
    }

    return <div className="features-settings">
        {Subscription.contains({features: tenant.subscription.allFeatures}, PPMFeatures.PrivateProjects) && 
            <div className="grid-item">
                <LabellableComponent
                    className="field-container"
                    label="Private Projects" 
                    description={<span>
                        If enabled, the option to create a private project will appear under the 'New' drop-down menu on the main pages. 
                        Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/522610" target="_blank">article</Link> for more details.
                    </span>}>  
                    <div className="field-value">
                        <Toggle
                            disabled={!canManage}
                            checked={Subscription.contains(tenant.subscription, PPMFeatures.PrivateProjects)}
                            onChange={(e, checked) => onFeatureChange(PPMFeatures.PrivateProjects, checked)}
                        />
                    </div>
                </LabellableComponent>
            </div>}
        {Subscription.contains({features: tenant.subscription.allFeatures}, PPMFeatures.TimeTracking) && 
            <div className="grid-item">
                <LabellableComponent
                    className="field-container"
                    label="Time Tracking" 
                    description={(
                        <span>
                            Enable Time Tracking feature to let users report and monitor time spent on tasks and projects. Time tracking improves transparency, accountability, and efficiency in project and resource management.
                            <br />
                            <br />
                            Once enabled, take a moment to define Time Tracking settings for your users by the <Link href='/timeTrackingSettings'>link</Link>.
                        </span>)}>  
                        <div className="field-value">
                            <Toggle
                                disabled={!canManage}
                                checked={timeTrackingSettings.newTimeTrackingEnabled}
                                onChange={(e, checked) => tenantActions.updateTimeTrackingSettings({...timeTrackingSettings, newTimeTrackingEnabled: !!checked})}/>              
                        </div>
                </LabellableComponent>
            </div>}
        {showPrivateProjectExistsMessage && 
            <AlertDialog
                dialogContentProps={{
                    title: 'Private Project exists',
                    subText: `Private Projects feature cannot be turned off because at least one private project exists. \
All private projects should be deleted prior to turning Private Projects feature off.`
                }}
                onDismiss={() => setShowPrivateProjectExistsMessage(false)} />}
    </div>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {    
    const projects = state.projectsList.allIds.map(_ => state.projectsList.byId[_]);
    return {
        tenant: state.tenant,
        user: state.user,
        timeTrackingSettings: state.tenant.timeTracking.globalSettings,
        canManage: contains(state.user.permissions.common, CommonOperations.Administrate),
        isPrivateProjectExists: projects.filter(_ => _.isPrivate).length > 0
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        tenantActions: bindActionCreators(actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(FeaturesSettings);