import * as React from 'react';
import { connect } from 'react-redux';
import { actionCreators } from "../../../store/integration/SpoStore";
import { ApplicationState } from "../../../store/index";
import { IConnectionsState } from "../../../store/integration/common";
import SpoConnectionEdit from './SpoConnectionEdit';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import BasicAuthConnectionsList from "../BasicAuthConnectionsList";
import { IConnectionInfo } from '../../../entities/Metadata';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onSelect: (connection: IConnectionInfo) => void;
};

type StateProps = { connections: IConnectionsState; };
type Props = StateProps & typeof actionCreators & OwnProps;

const SpoConnectionsList = (props: Props) => {
    React.useEffect(() => {
        props.loadConnections();
    }, []);
    return <BasicAuthConnectionsList
        disabled={props.connections.isLoading || !!props.disabled}
        sourceType={SourceType.Spo}
        connections={props.connections.data}
        onConnectionSelect={props.onSelect}
        onConnectionRemove={props.removeConnection}
        onConnectionRename={props.renameConnection}
        renderAdd={(onCreated: (connectionId: string) => void, onDismiss: () => void) =>
            <SpoConnectionEdit onCreated={onCreated} onDismiss={onDismiss} />}
        renderEdit={(connectionId: string, onDismiss: () => void) =>
            <SpoConnectionEdit connectionId={connectionId} onDismiss={onDismiss} />}
    />;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.spo.connections };
}

export default connect(mapStateToProps, actionCreators)(SpoConnectionsList);
