import { Action, Reducer } from 'redux';
import { get, post, remove } from '../../fetch-interceptor';
import * as Metadata from "../../entities/Metadata";
import { AppThunkAction } from '../';
import { IConnectionsState, IImportResult, IImportProjectMap, IImportResourceMap, IListStore, IApiResult, ExternalFieldInfo, MapConfig, 
    TaskImportSettings, IEntityStore, ConnectionsState } from "./common";
import { ProjectsImportActions, ResourcesImportActions, Utils } from "./ImportStore";
import { defaultCatch, catchApiError } from "../utils";
import { IDeletionResult } from '../services/storeHelper';
import { RemovedProjectsSourceInfosAction } from '../ProjectsListStore';
import { RemovedPortfoliosSourceInfosAction } from '../PortfoliosListStore';
import { RemovedResourcesSourceInfosAction } from '../ResourcesListStore';
import { Dictionary } from '../../entities/common';
import { FieldsService } from '../../components/common/FieldsService';

export enum SmartsheetFieldType {
    AbstractDateTime = "AbstractDateTime",
    Checkbox = "Checkbox",
    ContactList = "ContactList",
    Date = "Date",
    DateTime = "DateTime",
    Duration = "Duration",
    MultiContactList = "MultiContactList",
    MultiPickList = "MultiPickList",
    PickList = "PickList",
    Predecessor = "Predecessor",
    TextNumber = "TextNumber",
}

export const SmartsheetFieldToPpmxFieldsMap: { [i: string]: MapConfig } = {
    [SmartsheetFieldType.TextNumber]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Decimal, Metadata.FieldType.Integer, Metadata.FieldType.Hyperlink], label: "Text Number" },
    [SmartsheetFieldType.Date]: { types: [Metadata.FieldType.Date, Metadata.FieldType.DateTime], label: "Date" },
    [SmartsheetFieldType.AbstractDateTime]: { types: [Metadata.FieldType.Date, Metadata.FieldType.DateTime], label: "Abstract Date Time" },
    [SmartsheetFieldType.DateTime]: { types: [Metadata.FieldType.Date, Metadata.FieldType.DateTime], label: "Date Time" },
    [SmartsheetFieldType.Checkbox]: { types: [Metadata.FieldType.Flag, Metadata.FieldType.Text], label: "Boolean" },
    [SmartsheetFieldType.PickList]: { types: [Metadata.FieldType.Text], label: "PickList" },
    [SmartsheetFieldType.MultiPickList]: {
        types: [Metadata.FieldType.Text],
        label: "Multi PickList",
        validateField: (ppmxField: Metadata.Field) => ({ isValid: FieldsService.isDropDown(ppmxField) || FieldsService.isTag(ppmxField) })
    },
    [SmartsheetFieldType.Predecessor]: { types: [Metadata.FieldType.Task], label: "Predecessor" },
    [SmartsheetFieldType.Duration]: { types: [Metadata.FieldType.Integer, Metadata.FieldType.Decimal], label: "Duration" },
    [SmartsheetFieldType.MultiContactList]: {
        types: [Metadata.FieldType.Resource, Metadata.FieldType.User, Metadata.FieldType.Text],
        label: "Multiple persons",
        validateField: (ppmxField: Metadata.Field, ppmxFieldType: Partial<Metadata.FieldType>, label: string) => {
            const message = !ppmxField.settings?.multichoice
                ? `Unable to match field types single choice "${Metadata.fieldTypeMap[ppmxFieldType]}" and "${label}"`
                : undefined
            return { isValid: !message, message };
        }
    },
    [SmartsheetFieldType.ContactList]: { types: [Metadata.FieldType.Resource, Metadata.FieldType.User, Metadata.FieldType.Text], label: "Person" }

}

export interface ISmartsheetProjectSourceData {
    sheetId: number;
    sheetName: string;
    workspaceId: number;
    workspaceName: string;
    sheetUrl: string;
}

export interface ISmartsheetProgramSourceData {
    workspaceId: number;
    workspaceName: string;
    workspaceUrl: string;
}

export type ISmartsheetUserLinkInfo = {
    userId: string;
    userEmail: string;
    userTitle: string;
    displayName: string;
}

export interface ISmartsheetSheet {
    sheetId: number;
    sheetName: string;
    workspaceId: number;
    workspaceName: string;
    sheetUrl: string;
    isAlreadyLinked: boolean;
}

export interface ISmartsheetUser {
    id: string;
    name: string;
    email: string;
    title: string;
    displayName: string;
    isAlreadyLinked: boolean;
}

export interface ISmartsheetWorkspace {
    id: string;
    name: string;
}

export interface ISmartsheetWorkspaceInfo {
    workspaceId: number;
    workspaceName: string;
    workspaceUrl: string;
}

export interface ISmartsheetConnectionState extends IConnectionsState { }

export interface SmartsheetState {
    sheets: IListStore<ISmartsheetSheet>;
    connections: ISmartsheetConnectionState;
    fields: IListStore<ExternalFieldInfo>;
    users: IListStore<ISmartsheetUser>;
    sheetsByWorkspaceMap: Dictionary<ISmartsheetSheet[]>;
    workspaces: ISmartsheetWorkspace[];
    workspacesInfo: IListStore<ISmartsheetWorkspaceInfo>;
    taskImportSettings: IEntityStore<TaskImportSettings>,
    defaultTaskSettings: IEntityStore<TaskImportSettings>,
    fieldsBySheetId: IEntityStore<ExternalFieldInfo[]>;
}

export const actionCreators = {
    loadSheets: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_SHEETS' });
        get<ISmartsheetSheet[]>(`api/integration/smartsheet/projects`, { connectionId })
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_SHEETS', sheets: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_SET_SHEETS_ERROR', error: `Unable to load Smartsheet sheets: ${_}` })));
    },
    loadWorkspaces: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_WORKSPACES' });
        get<ISmartsheetWorkspaceInfo[]>(`api/integration/smartsheet/workspaces`, { connectionId })
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_WORKSPACES', workspacesInfo: _}))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_SET_WORKSPACES_ERROR', error: `Unable to load Smartsheet workspaces: ${_}`})));
    },
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_CONNECTIONS' });
        get<Metadata.IConnectionInfo[]>(`api/integration/smartsheet/connection`)
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_CONNECTIONS', connections: _ }))
            .catch(defaultCatch(dispatch));
    },
    renameConnection: (connectionId: string, title: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/smartsheet/connection/${connectionId}/rename`, { title })
            .then(_ => dispatch({ type: 'SMARTSHEET_CONNECTION_REFRESHED', connection: _.data }))
            .catch(defaultCatch(dispatch));
    },
    removeConnection: (id: string, callback?: () => void):
        AppThunkAction<KnownAction | RemovedPortfoliosSourceInfosAction | RemovedProjectsSourceInfosAction | RemovedResourcesSourceInfosAction> => (dispatch, getState) => {
            dispatch({ type: 'SMARTSHEET_CONNECTION_OPERATION_EXECUTING' });
            remove<IDeletionResult>(`api/integration/smartsheet/connection/${id}`)
                .then(_ => {
                    if (_.isDeleted) {
                        dispatch({ type: 'SMARTSHEET_CONNECTION_REMOVED', id: _.id });
                        dispatch({ type: "REMOVED_PORTFOLIOS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_PROJECTS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_RESOURCES_SOURCE_INFOS", connectionId: _.id });
                        callback?.();
                    } else {
                        dispatch({ type: 'SMARTSHEET_CONNECTION_OPERATION_ERROR', error: _.message || null });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    importSheets: (connectionId: string, maps: IImportProjectMap<ISmartsheetSheet>[]): AppThunkAction<ProjectsImportActions> => (dispatch, getState) => {
        const entityIds = maps.map(_ => `${_.linkingData.sheetId}`);
        dispatch({ type: 'IMPORT_PROJECTS_STARTED', entityIds });

        Utils.batchSend(maps,
            batch => post<IImportResult[]>('api/integration/smartsheet/import/projects', { connectionId, maps: batch })
                .then(_ => dispatch({ type: 'IMPORT_PROJECTS_BATCH_FINISHED', results: _ })))
            .then(() => dispatch({ type: 'IMPORT_PROJECTS_FINISHED' }))
            .catch(defaultCatch(dispatch));
    },
    importResources: (connectionId: string, maps: IImportResourceMap<ISmartsheetUser>[]): AppThunkAction<ResourcesImportActions> => (dispatch, getState) => {
        dispatch({ type: 'IMPORT_RESOURCES_STARTED' });
        Utils.sendByChunks(maps, chunk => post<IImportResult[]>('api/integration/smartsheet/import/resources', { connectionId, maps: chunk }))
            .then(_ => dispatch({ type: 'IMPORT_RESOURCES_FINISHED', results: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadFields: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_FIELDS' });
        get<ExternalFieldInfo[]>('api/integration/smartsheet/fields')
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_SET_FIELDS_ERROR', error: `Unable to load Smartsheet fields: ${_}` })));
    },
    loadConnectionConfiguration: (connectionId: string, boardId: number, subBoardId?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_CONNECTION_CONFIGURATION' });
        get<IConnectionConfiguration>(`api/integration/smartsheet/connectionConfiguration/${connectionId}/${boardId}`, { subBoardId })
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load Smartsheet connection configuration: ${_}` })));
    },
    updateConnectionConfiguration: (connectionId: string, boardId: number, configuration: IConnectionConfiguration): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(({ type: 'SMARTSHEET_UPDATE_CONNECTION_CONFIGURATION' }));
        post<IConnectionConfiguration>(`api/integration/smartsheet/connectionConfiguration/${connectionId}/${boardId}`, configuration)
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load Smartsheet mapping: ${_}` })));
    },
    loadDefaultTaskMappings: (connectionId: string, sheetId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_DEFAULT_TASK_MAPPINGS' });
        get<TaskImportSettings>(`api/integration/smartsheet/defaultTaskMappings/${connectionId}`, { sheetId })
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_DEFAULT_TASK_MAPPINGS', mappings: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_LOAD_DEFAULT_TASK_MAPPINGS_ERROR', error: `Unable to load Smartsheet default mappings settings: ${_}` })));
    },
    loadUsers: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_USERS' });
        get<ISmartsheetUser[]>(`api/integration/smartsheet/resources`, { connectionId })
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_USERS', users: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_SET_USERS_ERROR', error: `Unable to load Smartsheet resources: ${_}` })));
    },
    loadSheetFields: (connectionId: string, sheetId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_LOAD_ITEMBOARD_FIELDS' });
        get<ExternalFieldInfo[]>(`api/integration/smartsheet/fields/${connectionId}`, { sheetId })
            .then(_ => dispatch({ type: 'SMARTSHEET_RECEIVED_ITEMBOARD_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_SET_ITEMBOARD_FIELDS_ERROR', error: `Unable to load Smartsheet task sheet fields: ${_}` })));
    },
    verifyConnection: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SMARTSHEET_VERIFY_CONNECTION', connectionId });
        get(`api/integration/smartsheet/verify-connection`, { connectionId })
            .then(_ => dispatch({ type: 'SMARTSHEET_CONNECTION_VERIFIED', connectionId }))
            .catch(catchApiError(_ => dispatch({ type: 'SMARTSHEET_CONNECTION_VERIFICATION_ERROR', connectionId: connectionId, error: _ })));
    }
};

export interface IConnectionConfiguration {
    taskImportSettings: TaskImportSettings
}

export type LoadConnections = {
    type: "SMARTSHEET_LOAD_CONNECTIONS";
}
type ConnectionOperationExecution = {
    type: "SMARTSHEET_CONNECTION_OPERATION_EXECUTING";
}
type ConnectionRefreshed = {
    type: "SMARTSHEET_CONNECTION_REFRESHED";
    connection: Metadata.IConnectionInfo;
}
type SetConnectionOperationError = {
    type: "SMARTSHEET_CONNECTION_OPERATION_ERROR";
    error: string | null;
}
type RemovedConnection = {
    type: "SMARTSHEET_CONNECTION_REMOVED",
    id: string
}
export type ReceivedConnections = {
    type: "SMARTSHEET_RECEIVED_CONNECTIONS";
    connections: Metadata.IConnectionInfo[];
}
type LoadSheets = {
    type: "SMARTSHEET_LOAD_SHEETS";
}
type LoadWorkspaces = {
    type: "SMARTSHEET_LOAD_WORKSPACES"
}
type ReceivedSheets = {
    type: "SMARTSHEET_RECEIVED_SHEETS";
    sheets: ISmartsheetSheet[];
}
type ReceivedWorkspaces = {
    type: "SMARTSHEET_RECEIVED_WORKSPACES";
    workspacesInfo: ISmartsheetWorkspaceInfo[];
}
type LoadUsers = {
    type: "SMARTSHEET_LOAD_USERS";
}
type ReceivedUsers = {
    type: "SMARTSHEET_RECEIVED_USERS";
    users: ISmartsheetUser[];
}
type SetSheetsError = { type: "SMARTSHEET_SET_SHEETS_ERROR", error: string | null }
type SetWorkspacesError = { type: "SMARTSHEET_SET_WORKSPACES_ERROR", error: string | null }
type SetUsersError = { type: "SMARTSHEET_SET_USERS_ERROR", error: string | null }

type LoadFields = { type: "SMARTSHEET_LOAD_FIELDS" }
type ReceivedFields = { type: "SMARTSHEET_RECEIVED_FIELDS", fields: ExternalFieldInfo[] }
type SetFieldsError = { type: "SMARTSHEET_SET_FIELDS_ERROR", error: string | null }

type LoadConnectionConfiguration = { type: "SMARTSHEET_LOAD_CONNECTION_CONFIGURATION" }
type UpdateConnectionConfiguration = { type: "SMARTSHEET_UPDATE_CONNECTION_CONFIGURATION" }
type ReceivedConnectionConfiguration = { type: "SMARTSHEET_RECEIVED_CONNECTION_CONFIGURATION", config: IConnectionConfiguration }
type LoadConnectionConfigurationError = { type: "SMARTSHEET_LOAD_CONNECTION_CONFIGURATION_ERROR", error: string | null }

type LoadDefaultTaskMapping = { type: "SMARTSHEET_LOAD_DEFAULT_TASK_MAPPINGS" }
type ReceivedDefaultTaskMapping = { type: "SMARTSHEET_RECEIVED_DEFAULT_TASK_MAPPINGS", mappings: TaskImportSettings }
type SetDefaultTaskMappingError = { type: "SMARTSHEET_LOAD_DEFAULT_TASK_MAPPINGS_ERROR", error: string | null }

type LoadItemSheetFields = { type: "SMARTSHEET_LOAD_ITEMBOARD_FIELDS" }
type ReceivedItemSheetFields = { type: "SMARTSHEET_RECEIVED_ITEMBOARD_FIELDS", fields: ExternalFieldInfo[] }
type SetItemSheetFieldsError = { type: "SMARTSHEET_SET_ITEMBOARD_FIELDS_ERROR", error: string | null }

type VerifyConnection = { type: 'SMARTSHEET_VERIFY_CONNECTION', connectionId: string }
type ConnectionVerified = { type: 'SMARTSHEET_CONNECTION_VERIFIED', connectionId: string }
type ConnectionVerificationError = { type: 'SMARTSHEET_CONNECTION_VERIFICATION_ERROR', connectionId: string, error: string | null }

type KnownAction = LoadSheets
    | LoadWorkspaces
    | ReceivedSheets
    | ReceivedWorkspaces
    | LoadConnections
    | RemovedConnection
    | SetConnectionOperationError
    | ConnectionOperationExecution
    | ReceivedConnections
    | LoadUsers
    | ReceivedUsers
    | SetSheetsError
    | SetWorkspacesError
    | SetUsersError
    | ConnectionRefreshed
    | LoadFields
    | ReceivedFields
    | SetFieldsError
    | LoadConnectionConfiguration
    | UpdateConnectionConfiguration
    | ReceivedConnectionConfiguration
    | LoadConnectionConfigurationError
    | LoadDefaultTaskMapping
    | ReceivedDefaultTaskMapping
    | SetDefaultTaskMappingError
    | LoadItemSheetFields
    | ReceivedItemSheetFields
    | SetItemSheetFieldsError
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError;

const initState: SmartsheetState = {
    connections: {
        isLoading: false,
        isProcessing: false,
        error: null,
        data: [],
        connectionsVerification: { }
    },
    sheets: {
        entities: [],
        isLoading: false,
        error: null
    },
    users: {
        entities: [],
        isLoading: false,
        error: null
    },
    fields: {
        entities: [],
        isLoading: false,
        error: null,
    },
    workspaces: [],
    workspacesInfo: {
        entities: [],
        isLoading: false,
        error: null
    },
    sheetsByWorkspaceMap: {},
    taskImportSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {},
        error: null
    },
    defaultTaskSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {},
        error: null
    },
    fieldsBySheetId: {
        entities: [],
        isLoading: false,
        isProcessing: false,
        error: null,
    },    
};

export const reducer: Reducer<SmartsheetState> = (state: SmartsheetState = initState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SMARTSHEET_LOAD_SHEETS":
            return {
                ...state,
                workspaces: initState.workspaces,
                sheetsByWorkspaceMap: initState.sheetsByWorkspaceMap,
                sheets: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "SMARTSHEET_RECEIVED_SHEETS":
            {
                const sheetsMap: Dictionary<ISmartsheetSheet[]> = {};
                const workspacesMap = new Map();
                action.sheets.forEach(_ => {
                    sheetsMap[_.workspaceId] = [...(sheetsMap[_.workspaceId] || []), _];
                    workspacesMap.set(_.workspaceId, { id: _.workspaceId, name: _.workspaceName });
                });

                return {
                    ...state,
                    workspaces: Array.from(workspacesMap.values()),
                    sheetsByWorkspaceMap: sheetsMap,
                    sheets: {
                        entities: action.sheets,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'SMARTSHEET_SET_SHEETS_ERROR':
            {
                return {
                    ...state,
                    sheets: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case "SMARTSHEET_LOAD_WORKSPACES":
            return {
                ...state,
                workspacesInfo: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            };
        case "SMARTSHEET_RECEIVED_WORKSPACES":
            {
                return {
                    ...state,
                    workspacesInfo: {
                        isLoading: false,
                        entities: action.workspacesInfo,
                        error: null
                    }
                }
            }
        case 'SMARTSHEET_SET_WORKSPACES_ERROR':
            {
                return {
                    ...state,
                    workspacesInfo: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case "SMARTSHEET_LOAD_CONNECTIONS":
            return {
                ...state,
                connections: {
                    isLoading: true,
                    data: Array.of<Metadata.IConnectionInfo>(),
                    connectionsVerification: { },
                    isProcessing: false,
                    error: null
                },
            };
        case 'SMARTSHEET_RECEIVED_CONNECTIONS':
            return {
                ...state,
                connections: {
                    data: action.connections,
                    connectionsVerification: { },
                    isLoading: false,
                    isProcessing: false,
                    error: null
                }
            }
        case "SMARTSHEET_CONNECTION_REFRESHED":
            {
                return {
                    ...state,
                    connections: {
                        data: state.connections.data.map(_ => _.id === action.connection.id ? action.connection : _),
                        connectionsVerification: state.connections.connectionsVerification,
                        isLoading: false,
                        isProcessing: false,
                        error: null
                    }
                }
            }
        case 'SMARTSHEET_CONNECTION_REMOVED':
            return {
                ...state,
                connections: {
                    data: state.connections.data.filter(_ => _.id !== action.id),
                    connectionsVerification: ConnectionsState.remove(state.connections, action.id),
                    isLoading: false,
                    isProcessing: false,
                    error: null
                }
            }
        case 'SMARTSHEET_CONNECTION_OPERATION_EXECUTING':
            return {
                ...state,
                connections: {
                    data: state.connections.data,
                    connectionsVerification: state.connections.connectionsVerification,
                    isLoading: false,
                    isProcessing: true,
                    error: null
                }
            }
        case 'SMARTSHEET_CONNECTION_OPERATION_ERROR':
            return {
                ...state,
                connections: {
                    data: state.connections.data,                    
                    connectionsVerification: state.connections.connectionsVerification,
                    isLoading: false,
                    isProcessing: false,
                    error: action.error
                }
            }
        case "SMARTSHEET_LOAD_USERS":
            return {
                ...state,
                users: {
                    entities: [],
                    isLoading: true,
                    error: null
                }
            }
        case "SMARTSHEET_RECEIVED_USERS":
            return {
                ...state,
                users: {
                    isLoading: false,
                    entities: action.users,
                    error: null
                }
            }
        case 'SMARTSHEET_SET_USERS_ERROR':
            return {
                ...state,
                users: {
                    entities: [],
                    isLoading: false,
                    error: action.error
                }
            }
        case 'SMARTSHEET_LOAD_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'SMARTSHEET_RECEIVED_FIELDS':
            {
                return {
                    ...state,
                    fields: {
                        entities: action.fields,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'SMARTSHEET_SET_FIELDS_ERROR':
            {
                return {
                    ...state,
                    fields: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case "SMARTSHEET_RECEIVED_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    taskImportSettings: { ...initState.taskImportSettings, entities: action.config.taskImportSettings },
                }
            }
        case "SMARTSHEET_LOAD_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    taskImportSettings: { ...initState.taskImportSettings, isLoading: true }
                }
            }
        case "SMARTSHEET_UPDATE_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    taskImportSettings: { ...state.taskImportSettings, isProcessing: true }
                }
            }
        case 'SMARTSHEET_LOAD_CONNECTION_CONFIGURATION_ERROR':
            {
                return {
                    ...state,
                    progressCalculationSettings: {
                        error: action.error
                    }
                }
            }
        case 'SMARTSHEET_LOAD_DEFAULT_TASK_MAPPINGS':
            {
                return {
                    ...state,
                    defaultTaskSettings: {
                        ...initState.defaultTaskSettings,
                        isLoading: true
                    }
                }
            }
        case "SMARTSHEET_RECEIVED_DEFAULT_TASK_MAPPINGS":
            {
                return {
                    ...state,
                    defaultTaskSettings: {
                        ...initState.defaultTaskSettings,
                        entities: action.mappings
                    }
                }
            }
        case 'SMARTSHEET_LOAD_DEFAULT_TASK_MAPPINGS_ERROR':
            {
                return {
                    ...state,
                    defaultTaskSettings: {
                        ...initState.defaultTaskSettings,
                        error: action.error
                    }
                }
            }
        case 'SMARTSHEET_LOAD_ITEMBOARD_FIELDS':
            {
                return {
                    ...state,
                    fieldsBySheetId: {
                        ...initState.fieldsBySheetId,
                        isLoading: true
                    }
                }
            }
        case 'SMARTSHEET_RECEIVED_ITEMBOARD_FIELDS':
            {
                return {
                    ...state,
                    fieldsBySheetId: {
                        ...initState.fieldsBySheetId,
                        entities: action.fields
                    }
                }
            }
        case 'SMARTSHEET_SET_ITEMBOARD_FIELDS_ERROR':
            {
                return {
                    ...state,
                    fieldsBySheetId: {
                        ...initState.fieldsBySheetId,
                        error: action.error
                    }
                }
            }
        case "SMARTSHEET_VERIFY_CONNECTION":
            {
                return {
                    ...state,
                    connections: {
                        ...state.connections,
                        connectionsVerification: ConnectionsState.setVerificationStarting(state.connections, action.connectionId)
                    }
                }
            }
        case "SMARTSHEET_CONNECTION_VERIFIED":
            {
                return {
                    ...state,
                    connections: {
                        ...state.connections,
                        connectionsVerification: ConnectionsState.setVerificationFinished(state.connections, action.connectionId)
                    }
                }
            }
        case "SMARTSHEET_CONNECTION_VERIFICATION_ERROR":
            {
                return {
                    ...state,
                    connections: {
                        ...state.connections,
                        connectionsVerification: ConnectionsState.setVerificationFailed(state.connections, action.connectionId, action.error)
                    }
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
};