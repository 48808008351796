import * as React from 'react';
import { PanelType, IconButton, ActionButton, IPanelProps, Toggle } from 'office-ui-fabric-react';
import { DraggableList } from "../../../DraggableList";
import * as Metadata from "../../../../../entities/Metadata";
import { CreateGroupPanel, GroupInfo } from '../../../CreateGroupPanel';
import RemoveDialog from '../../../RemoveDialog';
import { GroupFormatter } from '../../../formatters/GroupFormatter';
import ExpandablePanel from '../../../ExpandablePanel';
import { EntityTypeLabels } from '../../../../../entities/common';

interface IProps {
    groups: Metadata.Group[];
    onDismiss: () => void;
    allowCreateGroups?: boolean;
    allowEditGroups?: boolean;
    reorderGroups?: (groups: Metadata.Group[]) => void;
    saveGroup: (group: GroupInfo) => void;
    removeGroup: (group: Metadata.Group) => void;
    entityName: EntityTypeLabels;
    removeDialog?: {
        messageBuilder?: (group: Metadata.Group) => string;
        contentBuilder?: (group: Metadata.Group) => JSX.Element;
    }
    hideIsDefault?: boolean;
    allowDeleteLastItem?: boolean;
    hideToggle?: boolean;
    itemClassName?: string;
}

interface IState {
    showGroupPanel?: boolean;
    removeGroup?: Metadata.Group;
    editGroup?: Metadata.Group;
    orderedGroups: Metadata.Group[];
}

export default class GroupSelectionPanel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = this._buildState(props);
    }

    componentWillReceiveProps(newProps: IProps) {
        if (newProps.groups.length != this.props.groups.length) {
            this.setState(this._buildState(newProps));
        } else {
            this.setState({ orderedGroups: this.state.orderedGroups.map(_ => newProps.groups.find(group => group.id == _.id)!) });
        }
    }

    public render() {
        const { removeGroup } = this.state;
        const { entityName, hideIsDefault } = this.props;
        return <>
            {this.state.showGroupPanel
                ? <CreateGroupPanel value={this.state.editGroup}
                    entityName={this.props.entityName}
                    onChange={this._onChangeGroup}
                    onDismiss={() => this.setState({ showGroupPanel: false })}
                    hideIsDefault={hideIsDefault} />
                : <ExpandablePanel isOpen={true}
                    isLightDismiss={true}
                    type={PanelType.custom}
                    customWidth="400px"
                    onRenderHeader={this._onRenderHeader}
                    onDismiss={this.onDismiss}>
                    <DraggableList
                        items={this.state.orderedGroups}
                        onItemRender={this._onItemRender}
                        onChanged={this.props.reorderGroups ? this._onOrderChanged : undefined}
                        itemClassName={this.props.itemClassName} />
                    {this.props.allowCreateGroups !== undefined &&
                        <ActionButton iconProps={{ iconName: 'Add' }}
                            text={`New ${entityName.singular}`}
                            className="new-group"
                            disabled={!this.props.allowCreateGroups}
                            onClick={() => this.setState({ showGroupPanel: true, editGroup: undefined })} />}
                </ExpandablePanel>}
            {removeGroup && <RemoveDialog
                onClose={() => this.setState({ removeGroup: undefined })}
                onComplete={() => this._removeGroup(removeGroup)}
                confirmButtonProps={{ text: "Delete" }}
                dialogContentProps={{
                    title: `Delete ${entityName.singular}`,
                    subText: this.props.removeDialog?.messageBuilder
                        ? this.props.removeDialog.messageBuilder(removeGroup)
                        : `Are you sure you want to delete ${entityName.singular} "${removeGroup.name}"`
                }} >
                {this.props.removeDialog?.contentBuilder?.(removeGroup)}
            </RemoveDialog>}
        </>;
    }

    private onDismiss = () => {
        //nail, need to prevent panel dismiss after click on dialog layer
        if (!this.state.removeGroup) {
            this.props.onDismiss();
        }
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null =>
        <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure {this.props.entityName.plural}</p>
            <div className='ms-Panel-secondaryText'>
                {this.props.reorderGroups
                    ? (`Turn on ${this.props.entityName.singular?.toLowerCase()} that you want to make visible on the page.`
                        + ` Drag and drop to reorder. Create new or edit existing ones.`)
                    : `Create new ${this.props.entityName.plural?.toLowerCase()} or edit existing ones.`}
            </div>
        </div>;

    private _onItemRender = (_: Metadata.Group, index: number) => {
        return (
            <div className={`cb-wrap ${this.props.hideToggle ? 'without-toggle' : ''}`} >
                <GroupFormatter value={_} markDefault={_.isDefault} />
                {this.props.allowEditGroups !== undefined
                    ? <div className="align-center">
                        <IconButton
                            menuIconProps={{ iconName: 'Edit' }}
                            title="Edit"
                            className='menu'
                            disabled={!this.props.allowEditGroups}
                            onClick={() => this.setState({ showGroupPanel: true, editGroup: _ })} />
                        <IconButton
                            menuIconProps={{ iconName: 'Delete' }}
                            title="Delete"
                            className='menu'
                            disabled={!this.props.allowEditGroups || (!this.props.allowDeleteLastItem && this.props.groups.length === 1)}
                            onClick={() => this.setState({ removeGroup: _ })} />
                    </div>
                    : null}
                {!this.props.hideToggle && <Toggle checked={_.isShown} onChange={(e, c) => this._toggleGroup(_, c)} />}
            </div>
        );
    }

    private _onOrderChanged = (orderedGroups: Metadata.Group[], group: Metadata.Group) => {
        this.setState({ orderedGroups });
        this.props.reorderGroups!(orderedGroups);
    }

    private _buildState(props: IProps) {
        return { orderedGroups: props.groups };
    }

    private _onChangeGroup = (group: GroupInfo) => {
        this.props.saveGroup({ ...group, index: group.id ? this.state.orderedGroups.findIndex(_ => _.id == group.id) : undefined });
    }

    private _toggleGroup = (group: Metadata.Group, isShown?: boolean) => {
        this.props.saveGroup({ ...group, isShown, index: this.state.orderedGroups.indexOf(group) });
    }

    private _removeGroup = (group: Metadata.Group) => {
        this.setState({ removeGroup: undefined });
        this.props.removeGroup(group);
    }
}