import * as React from "react";
import { Icon, Link } from 'office-ui-fabric-react';

type Props = {
    name: string;
    iconName: string;
    href: string;
}

const ResourceCard = (props: Props) => <Link href={props.href} target="_blank"
    className="card resource-card">
    <Icon iconName={props.iconName} />
    <div className="name">{props.name}</div>
</Link>
export default ResourceCard;