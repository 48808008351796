import * as React from 'react';
import { IColumn, SearchBox } from 'office-ui-fabric-react';
import { Field } from '../../entities/Metadata';
import { Dictionary, EntityType, IExtensibleEntity } from '../../entities/common';
import LabellableComponent from '../common/LabellableComponent';
import { waitForFinalEvent } from '../utils/common';
import { ViewService } from '../../services/ViewService';
import { DisplayFieldService } from '../common/DisplayFieldService';
import { FieldsService } from '../common/FieldsService';
import SelectionPanel, { SelectionType, IViewItem } from '../common/SelectionPanel';
import { ISubentity } from '../../entities/Subentities';
import { defaultCatch } from '../../store/utils';

const debounceDelay = 600;

type SelectedItem = {
    entityId: string;
    subentityId: string;
}

type ViewItem = IViewItem & {
    item: IExtensibleEntity | ISubentity;
    parent?: IExtensibleEntity;
}

type OwnProps = {
    multichoice?: boolean;
    header: {
        title: string;
        description: string;
    };
    onDismiss: () => void;
    onSelectionComplete: (selected: SelectedItem[]) => void;
    load: () => Promise<IExtensibleEntity[]>;
    getName: (entity: IExtensibleEntity) => string;
    getSubentities: (entity: IExtensibleEntity) => ISubentity[];
    onRenderNameColumn: (entity: IExtensibleEntity) => JSX.Element;
    subentityType: EntityType;
    subentityNameLabel: string;
    entityNameLabel: string;
    subEntityNameField: Field;
    filters?: {
        byFields?: Field[];
        excludeIds?: string[];
        disabledIds?: string[];
    };
}
type Props = OwnProps;
type State = {
    isLoading: boolean;
    allItems: ViewItem[];
    disabledIds?: string[];
    subentityNameColumn: IColumn | null;
}

export default class SubEntitySelectionPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            allItems: [],
            subentityNameColumn: null
        };
    }

    componentWillMount() {
        const { filters, subEntityNameField, load } = this.props;
        load().then(entities => {
            const allItems = this._buildViewItems(entities);
            this.setState({
                allItems,
                isLoading: false,
                disabledIds: filters?.disabledIds
                    ? allItems.filter(_ => _.isSubentity).map(_ => _.item as ISubentity)
                        .filter(_ => !!~filters.disabledIds!.indexOf(_.id) || _.externalId && !!~filters.disabledIds!.indexOf(_.externalId)).map(_ => _.id)
                    : undefined,
                subentityNameColumn: ViewService.buildColumn(subEntityNameField.name, { Name: this.props.subEntityNameField }, undefined, false,
                    this.props.subentityType, undefined, true)
            });
        })
        .catch(defaultCatch());
    }
    public render() {
        const { filters, onDismiss, onSelectionComplete, header, multichoice } = this.props;
        const { subentityNameColumn, allItems, disabledIds, isLoading } = this.state;
        return <SelectionPanel
            isLoading={isLoading}
            multichoice={multichoice}
            header={header}
            selectionType={SelectionType.Subentity}
            onDismiss={onDismiss}
            nameColumnIconName="PPMXProject"
            onRenderNameColumn={(item: ViewItem) => {
                if (item.isSubentity) {
                    return subentityNameColumn?.onRender!(item.item, undefined, subentityNameColumn);
                }
                return this.props.onRenderNameColumn(item.item)
            }}
            allItems={allItems}
            sortItems={(items: ViewItem[]) => [...items]
                .sort((a, b) => (a.isSubentity === b.isSubentity
                    ? a.isSubentity ? 0 : (a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
                    : a.isSubentity ? 1 : -1))}
            onSelectionComplete={(selectedItem: ViewItem[]) => {
                const items: SelectedItem[] = selectedItem
                    .filter(_ => _.isSubentity && _.parentId)
                    .map(_ => ({
                        entityId: (_.item as ISubentity).externalData["ImportedFromId"] ?? _.parentId!,
                        subentityId: (_.item as ISubentity).externalData["ImportedFromId"] ? (_.item as ISubentity).externalId! : _.id
                    }));
                onSelectionComplete(items);
                onDismiss();
            }}
            onRenderFilters={this._renderFilters}
            applyFilters={this._applyFilters}
            filters={{
                disabledIds: disabledIds,
                excludeIds: filters?.excludeIds,
            }}
        ></SelectionPanel>;
    }

    private _buildViewItems(entities: IExtensibleEntity[]): ViewItem[] {
        return entities.reduce((cum, entity) => ([
            ...cum,
            ...[{
                id: entity.id,
                name: this.props.getName(entity),
                isSubentity: false,
                item: entity,
            }],
            ...(this.props.getSubentities(entity).map(subentity => ({
                id: subentity.id,
                name: subentity.attributes[this.props.subEntityNameField.name],
                parentId: entity.id,
                isSubentity: true,
                item: subentity,
                parent: entity
            })))
        ]), []);
    }

    private _renderFilters = (filterValue: Dictionary<any>, saveFilter: (key: string, value: any) => void): JSX.Element | null => {
        const { entityNameLabel, subEntityNameField, filters, subentityNameLabel } = this.props;

        return <>
            <div className="grid-item">
                <LabellableComponent label={`Search by ${entityNameLabel}`}
                    className="field-container">
                    <div className="field-value">
                        <SearchBox
                            value={filterValue["entityName"]}
                            onChange={waitForFinalEvent((e, v: string) => saveFilter("entityName", v), debounceDelay, 'search')}
                            onClear={() => saveFilter("entityName", undefined)}
                        />
                    </div>
                </LabellableComponent>
            </div>
            {subEntityNameField && <div className="grid-item">
                <LabellableComponent label={`Search by ${subentityNameLabel}`}
                    className="field-container">
                    <div className="field-value">
                        <SearchBox
                            value={filterValue[subEntityNameField.name]}
                            onChange={waitForFinalEvent((e, v: string) => saveFilter(subEntityNameField.name, v), debounceDelay, 'search')}
                            onClear={() => saveFilter(subEntityNameField.name, undefined)}
                        />
                    </div>
                </LabellableComponent>
            </div>}
            {filters?.byFields?.map(field => <div className="grid-item">
                <LabellableComponent label={`Search by ${field.label || field.name}`}
                    className="field-container">
                    <div className="field-value">
                        {DisplayFieldService.buildFieldMultiSelectInput(field, filterValue[field.name], changed => saveFilter(field.name, changed))}
                    </div>
                </LabellableComponent>
            </div>)}
        </>;
    }

    private _applyFilters = (item: ViewItem, filterValues: Dictionary<any>): boolean => {
        const { subEntityNameField, filters } = this.props;
        const result = (!filterValues["entityName"] || item.parent && !!~this.props.getName(item.parent).toLowerCase().indexOf(filterValues["entityName"].toLowerCase()))
            && (!filterValues[subEntityNameField.name]
                || FieldsService.compareFieldValues(subEntityNameField, item.item.attributes[subEntityNameField.name], filterValues[subEntityNameField.name]))
            && (!filters?.byFields || filters?.byFields?.every(field => !filterValues[field.name]
                || (Array.isArray(filterValues[field.name]) && filterValues[field.name].length === 0)
                || FieldsService.compareFieldValues(field, item.item.attributes[field.name], filterValues[field.name])));
        return !!result;
    }
}