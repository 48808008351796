import { get, post, remove } from './../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { defaultCatch } from "./utils";

export type IODataTokenInfo = {
    id: string,
    title: string,
    isEnabled: boolean,
}

export type INewODataTokenInfo = IODataTokenInfo & { token: string }

export interface INewODataTokenInfoState {
    isLoading: boolean,
    tokenInfo?: INewODataTokenInfo,
}

export interface ODataTokensState {
    isLoading: boolean;
    tokens: IODataTokenInfo[];
    newToken: INewODataTokenInfoState;
}

const unloadedState: ODataTokensState = {
    isLoading: false,
    tokens: [],
    newToken: { isLoading: false }
};

interface ReceiveODataTokensAction {
    type: 'RECEIVED_ODATA_TOKENS';
    tokens: IODataTokenInfo[];
}
interface ReceiveNewODataTokenAction {
    type: 'RECEIVED_NEW_ODATA_TOKEN';
    tokenInfo: INewODataTokenInfo;
}

interface RequestODataTokensAction {
    type: 'REQUEST_ODATA_TOKENS';
}
interface RequestNewODataTokenAction {
    type: 'REQUEST_NEW_ODATA_TOKEN';
}
interface ResetNewODataTokenAction {
    type: 'RESET_NEW_ODATA_TOKEN';
}

type KnownAction = RequestODataTokensAction |
                   ReceiveODataTokensAction |
                   ReceiveNewODataTokenAction |
                   RequestNewODataTokenAction |
                   ResetNewODataTokenAction;

export const actionCreators = {
    createOdataToken: (title: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<INewODataTokenInfo>(`api/odata/tokens`, { title })
            .then(data => { dispatch({ type: 'RECEIVED_NEW_ODATA_TOKEN', tokenInfo: data }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_NEW_ODATA_TOKEN' });
    },
    resetNewOdataToken: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_NEW_ODATA_TOKEN' });
    },
    getOdataTokens: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<IODataTokenInfo[]>(`api/odata/tokens`)
            .then(data => { dispatch({ type: 'RECEIVED_ODATA_TOKENS', tokens: data }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_ODATA_TOKENS' });
    },
    revokeToken: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<IODataTokenInfo[]>(`api/odata/tokens`, {ids: ids})
            .then(data => { dispatch({ type: 'RECEIVED_ODATA_TOKENS', tokens: data }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_ODATA_TOKENS' });
    },
}

export const reducer: Reducer<ODataTokensState> = (state: ODataTokensState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'REQUEST_ODATA_TOKENS':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVED_ODATA_TOKENS':
            return {
                ...state,
                tokens:action.tokens,
                isLoading: false
            };
        case 'REQUEST_NEW_ODATA_TOKEN':
            return {
                ...state,
                newToken: { isLoading: true }
            }
        case 'RECEIVED_NEW_ODATA_TOKEN':
            return {
                ...state,
                tokens: [...state.tokens, { id: action.tokenInfo.id, title: action.tokenInfo.title, isEnabled: action.tokenInfo.isEnabled }],
                newToken: {
                    tokenInfo: action.tokenInfo,
                    isLoading: false
                },
            };
        case 'RESET_NEW_ODATA_TOKEN':
            return {
                ...state,
                newToken: { isLoading: false }
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};