import * as React from 'react';
import { Field, FieldGroup } from '../../../../entities/Metadata';
import EmptyGroupMessage from '../EmptyGroupMessage';
import SectionTitle from '../SectionTitle';
import { FieldsList, useFilterFields } from '../FieldsList';
import GroupedDisabledFields, { resultsNotFoundMessage } from '../GroupedDisabledFields';
import { GroupedFieldsConfigurationSection } from './FieldsInThisViewSection';

type Props = Omit<GroupedFieldsConfigurationSection, 'onChange'> & {
    fields: Field[];
    disabledFields: Field[];
    isGroupEmpty: (group: FieldGroup) => boolean;
    onChange?: (changedDisabledFields: Field[]) => void;
};

const AvailableFieldsSection = (props: Props) => {
    const { disabledFields, newFields, filter, flattenGroups, fieldActions, isGroupEmpty } = props;

    const filterFields = useFilterFields(filter);
    const filteredDisabledFields = React.useMemo(() => filterFields(disabledFields), [filterFields, disabledFields]);

    const onDisabledFieldsEmptyMessageRender = React.useCallback((allFieldsEnabled: boolean) => (
        <EmptyGroupMessage message={allFieldsEnabled ? `All fields from this group are enabled` : 'No fields have been created yet'} />
    ), []);
    
    const onDisabledFieldsEmptyGroupMessageRender = React.useCallback((group: FieldGroup) =>
        onDisabledFieldsEmptyMessageRender(isGroupEmpty(group)),
    [isGroupEmpty, onDisabledFieldsEmptyMessageRender]);
    
    const renderFlattenedDisabledFieldsEmptyGroupMessage = React.useCallback(() => 
        onDisabledFieldsEmptyMessageRender(filteredDisabledFields.length === 0 && !filter),
    [onDisabledFieldsEmptyMessageRender, filteredDisabledFields, filter]);

    return <>
        <SectionTitle title="Available fields" />
        {!flattenGroups ? (
            <GroupedDisabledFields
                {...props}
                disabledFields={filteredDisabledFields}
                newFieldNames={newFields}
                groupsExpanded={!!filter}
                hideEmptyGroups={!!filter}
                fieldActions={fieldActions}
                onEmptyGroupMessageRender={onDisabledFieldsEmptyGroupMessageRender}
            />
        ) : (filteredDisabledFields.length > 0 || !!filter) ? (
            <FieldsList
                {...props}
                selected={[]}
                fields={filteredDisabledFields}
                newFields={newFields}
                nonDraggable
                showShadowOnNonDraggableHover
                emptyMessages={{
                    notFound: <EmptyGroupMessage message={resultsNotFoundMessage} />
                }}
            />
        ) : renderFlattenedDisabledFieldsEmptyGroupMessage()}
    </>;
}

export default AvailableFieldsSection;
