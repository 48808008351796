import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption, Link } from "office-ui-fabric-react";
import { onRenderField, IChoiceGroupOptionExt } from './PrimaryScheduleSection';
import { TaskCalculationModeTypes } from '../../../components/views/list/columns/task/Mode';

export const NewPpmxTasksCalculationModeSection = (props: {
    readonly?: boolean,
    value?: boolean,
    onChange: (value: boolean) => void
}) => {
    const options: IChoiceGroupOptionExt[] = [
        {
            text: TaskCalculationModeTypes.Auto,
            value: TaskCalculationModeTypes.Auto,
            key: TaskCalculationModeTypes.Auto,
            description: <>
                Horizontal and vertical (roll-up) calculation for progress-related fields is performed automatically.
            </>
        },
        {
            text: TaskCalculationModeTypes.Manual,
            value: TaskCalculationModeTypes.Manual,
            key: TaskCalculationModeTypes.Manual,
            description: <>
                Horizontal and vertical (roll-up) calculation for progress-related fields is performed on-demand.
            </>
        },
    ].map(opt => ({ ...opt, onRenderField }));

    return <div className="project-calculation-mode">
        <div className='section-description'>
            Select default mode for new Tasks in PPM Express schedule.
            Please refer to <Link href='https://help.ppm.express/94162-portfolio-and-project-management/2082039-project-settings#section-6' target='_blank'>this article</Link> for
            more details about mode for PPM Express Tasks.
        </div>
        <ChoiceGroup label='Mode'
            className="horizontal"
            disabled={props.readonly}
            defaultSelectedKey={props.value ? TaskCalculationModeTypes.Auto : TaskCalculationModeTypes.Manual}
            options={options}
            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) =>
                props.onChange(option?.value === TaskCalculationModeTypes.Auto)}
        />
    </div>;
}