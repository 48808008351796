import * as React from "react";
import { Dictionary } from "../../../entities/common";
import { FieldType } from "../../../entities/Metadata";
import * as Metadata from '../../../entities/Metadata';
import { PermissionsFilterAttribute } from "../FilterAttributes/PermissionsFilterAttribute";
import { UsersFromListFilterAttribute } from "../FilterAttributes/UsersFromListFilterAttribute";
import { IUser } from "../../../store/UsersListStore";

type Props = {
    hideCollaborate?: boolean;
    filter: Dictionary<unknown>;
    users: IUser[];
    onFilterChanged: (newFilter: Dictionary<unknown>) => void;
}

const ShareFilters = (props: Props) => {
    const { filter, onFilterChanged } = props;
    const permissionEdit = "Edit";
    const permissionCollaborate = "Collaborate";

    const userFilterName: string = "user";
    const permissionsFilterName: string = "permissions";

    const onUserFilter = React.useCallback((newValue) => {
        const newFilter = { ...filter, [userFilterName]: newValue };
        if (newValue == null) {
            delete newFilter[userFilterName];
        }
        onFilterChanged(newFilter);
    }, [filter]);

    const onPermissionsFilter = React.useCallback((newValue) => {
        const newFilter = { ...filter, [permissionsFilterName]: newValue };
        if (newValue == null || !Object.keys(newValue).length) {
            delete newFilter[permissionsFilterName];
        }
        onFilterChanged(newFilter);
    }, [filter]);

    const _buildUserFilter = (): JSX.Element => {
        return <UsersFromListFilterAttribute
            value={filter[userFilterName] as IUser[]}
            label="User"
            users={props.users}
            onEditComplete={onUserFilter} />
    }

    const _buildPermissionsFilter = (): JSX.Element => {
        return <PermissionsFilterAttribute
            title="Permissions"
            permissions={props.hideCollaborate ? [permissionEdit] : [permissionCollaborate, permissionEdit]}
            value={filter[permissionsFilterName] as Dictionary<boolean>}
            onEditComplete={onPermissionsFilter} />
    }

    return <div className="filter-attributes-container align-center">
        {_buildUserFilter()}
        {_buildPermissionsFilter()}
    </div>
}

export default ShareFilters;

