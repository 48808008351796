import * as React from 'react';
import { Iteration, iterationStateConfigMap, buildIterationIconName, IIterationAttrs } from "../../../entities/Subentities";
import { Icon } from 'office-ui-fabric-react';
import { FormatDate } from '../../utils/common';
import { SourceType_ } from '../../../store/ExternalEpmConnectStore';
import { Field, getLabel } from '../../../entities/Metadata';
import { ApplicationState } from '../../../store';
import { EntityType } from '../../../entities/common';
import { connect } from 'react-redux';

type OwnProps = { iteration: Iteration };
type StoreProps = { fields: Field[] }
type Props = OwnProps & StoreProps;

class IterationTooltipContent extends React.Component<Props> {
    render() {
        const { iteration } = this.props;
        const config = iteration.attributes.State != undefined ? iterationStateConfigMap[iteration.attributes.State] : undefined;
        const iconName = buildIterationIconName(iteration);
        const sourceIconName = iteration.sourceType
            ? SourceType_.getIconName(iteration.sourceType)
            : undefined;
        const startDate = iteration.attributes.StartDate
            ? FormatDate(iteration.attributes.StartDate)
            : "-";
        const finishDate = iteration.attributes.FinishDate
            ? FormatDate(iteration.attributes.FinishDate)
            : "-";
        return (
            <div className="timeline-tooltip">
                <div className="header">
                    <Icon className={"logo " + iconName} iconName={iconName} />
                    <div className="title">
                        <div className="overflow-text" title={iteration.attributes.Name}>
                            {iteration.attributes.Name}
                        </div>
                        {sourceIconName && <div title={`Linked to ${SourceType_.getName(iteration.sourceType!)}`}>
                            <Icon className={"logo " + sourceIconName} iconName={sourceIconName} />
                        </div>}
                    </div>
                </div>

                <div className="content">
                    {this.renderField("State", <div className="align-center">
                        <div className="iteration-state">
                            {config && <div className={`status ${config.cssClassName}`}>
                                <Icon iconName={config.iconName} className="status-icon" />
                                <span className="status-label">{config.title}</span>
                            </div>}
                        </div>
                    </div>)}
                    <div className="item align-center">
                        <div className="label">Type</div>
                        <div className="align-center">
                            <div className="completed">Iteration</div>
                        </div>
                    </div>
                    {this.renderField("StartDate", <div>{startDate}</div>)}
                    {this.renderField("FinishDate", <div>{finishDate}</div>)}
                </div>
            </div>
        );
    }

    private renderField = (name: keyof IIterationAttrs, valueElement: JSX.Element): JSX.Element | null => {
        const field = this.props.fields.find(_ => _.name === name);
        if (!field) {
            return null;
        }

        return (
            <div className="item align-center">
                <div className="label">{getLabel(field)}</div>
                {valueElement}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    let fields = state.fields[EntityType.Iteration];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
    };
}

export default connect(mapStateToProps)(IterationTooltipContent);