import './BillingManage.css';
import * as React from 'react';
import { get, post } from '../../../../fetch-interceptor';
import { DefaultButton, Overlay } from 'office-ui-fabric-react';
import Spinner from '../../../common/Spinner';
import { SubscriptionInfo } from '../common';
import { defaultCatch, catchApiError } from '../../../../store/utils';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { UserState } from '../../../../store/User';
import { actionCreators, Subscription } from '../../../../store/Tenant';
import { actionCreators as MetadataActionCreators } from '../../../../store/MetadataStore';
import * as Notifications from '../../../../store/NotificationsStore';
import { bindActionCreators } from 'redux';
import DeleteAccountDialog from '../DeleteAccountDialog'
import { GroupedPlansList } from '../GroupedPlansList'
import { SubscriptionCards } from '../SubscriptionCards';
import { CommonOperations, contains } from '../../../../store/permissions';

type StateProps = {
    user: UserState;
    siteName?: string;
}
type ActionProps = {
    notifications: typeof Notifications.actionCreators,
    defaultCatch: (error: any) => void;
    tenantActions: typeof actionCreators;
    metadataActions: typeof MetadataActionCreators;
}
type Props = StateProps & ActionProps;

type State = {
    subscription?: SubscriptionInfo;
    isSubscriptionLoading: boolean;
    showDeleteAccountDialog: boolean;
}

class BillingManage extends React.Component<Props, State> {
    private _chargebee: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            isSubscriptionLoading: false,
            showDeleteAccountDialog: false
        };

        this._chargebee = this.props.siteName
            ? (window as any).Chargebee?.init({ site: this.props.siteName })
            : undefined;
    }

    componentDidMount() {
        get<SubscriptionInfo>('api/billing/subscription')
            .then(_ => this.setState({ subscription: _, isSubscriptionLoading: false }))
            .catch(this.props.defaultCatch);

        this.setState({ isSubscriptionLoading: true });
    }

    public render() {
        const { permissions } = this.props.user;
        const { subscription, isSubscriptionLoading, showDeleteAccountDialog } = this.state;
        if (!subscription) {
            return <Spinner />;
        }

        const canManage = contains(permissions.common, CommonOperations.BillingManage);
        return <div className='billing-manage'>
            {
                subscription && <SubscriptionCards subscription={subscription} />
            }
            <div className='buttons-container'>
                <DefaultButton
                    primary={true}
                    className='simplified-button'
                    disabled={Subscription.isDirect(subscription) || !this._chargebee}
                    text='Manage Billing Details'
                    onClick={this._onManageBilling} />
                {
                    contains(permissions.common, CommonOperations.Administrate) && <DefaultButton
                        iconProps={{ iconName: 'Delete' }}
                        className='simplified-button'
                        text='Delete Account'
                        onClick={() => this.setState({ showDeleteAccountDialog: true })} />
                }
            </div>
            <GroupedPlansList
                chargebee={this._chargebee}
                canManage={canManage}
                subscription={subscription}
                onSuccessfulCheckout={this._onSubscriptionChange}
                onSwitchTrialConfirmed={this._onSwitchTrialConfirmed}
            />
            {
                showDeleteAccountDialog && <DeleteAccountDialog
                    onDismiss={() => this.setState({ showDeleteAccountDialog: false })}
                />
            }
            {(isSubscriptionLoading) && <Overlay><Spinner /></Overlay>}
        </div>
    }

    private _onManageBilling = () => {
        this._chargebee.setPortalSession(() => {
            return post('api/billing/portal', {})
                .catch(catchApiError(this._onCatchApiError));
        });

        const cbPortal = this._chargebee.createChargebeePortal();
        cbPortal.open({ close: () => this._chargebee.logout() });
    }

    private _onSwitchTrialConfirmed = (planId: string) => {
        post<SubscriptionInfo>(`api/billing/switchtrial/${planId}`, {})
            .then(this._setSubscription)
            .catch(catchApiError(this._onCatchApiError))
            .finally(() => this.setState({ isSubscriptionLoading: false }))

        this.setState({ isSubscriptionLoading: true });
    }

    private _onSubscriptionChange = () => {
        post<SubscriptionInfo>('api/billing/refresh', {})
            .then(this._setSubscription)
            .catch(catchApiError(this._onCatchApiError))
            .finally(() => this.setState({ isSubscriptionLoading: false }))

        this.setState({ isSubscriptionLoading: true });
    }

    private _setSubscription = (subscription: SubscriptionInfo) => {
        this.setState({ subscription: subscription });
        this.props.tenantActions.updateSubscription({ ...subscription, plan: subscription.planName });
        this.props.metadataActions.loadMetadata();
    }

    private _onCatchApiError = (message: string) => {
        this.props.notifications.pushNotification({
            message: message,
            type: Notifications.NotificationType.Error
        });
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        user: state.user,
        siteName: state.chargebee.siteName
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        defaultCatch: defaultCatch(dispatch),
        notifications: bindActionCreators(Notifications.actionCreators, dispatch),
        tenantActions: bindActionCreators(actionCreators, dispatch),
        metadataActions: bindActionCreators(MetadataActionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(BillingManage);