import React, { useState, useRef } from 'react';
import {
    PersonaSize, SearchBox, Callout, DirectionalHint, ActionButton, SpinnerSize, Spinner, Pivot, PivotItem, KeyCodes, PersonaCoin, ISearchBox, getTheme
} from 'office-ui-fabric-react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { get } from "../../fetch-interceptor";
import Link from '../common/Link';
import { waitForFinalEvent, getPersonInfoImageUrl, notBoolean } from "../utils/common";
import ProjectLogo from "../project/ProjectLogo";
import Logo from "../common/Logo";
import { ISourceInfo } from "../../entities/common";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { PPMFeatures, Subscription } from '../../store/Tenant';
import PrivateProjectIcon from '../common/PrivateProjectIcon';

interface ISuggestion {
    id: string;
    identifier: string;
    name: string;
    url: string;
    type: SearchContext;
    imageId?: string;
    sourceInfos: ISourceInfo[];
    isPrivate: boolean;
}

interface ICounts {
    [context: number]: number;
}

type SearchConfig = {
    pivots: JSX.Element[];
    renderGroups: (groups: SuggestionMap) => JSX.Element[];
    tabMap: { [context: number]: string };
}

type StateProps = {
    hasPortfolioManagement: boolean;
    hasProjectManagement: boolean;
    hasIdeation: boolean;
}

type Props = RouteComponentProps<{}> & StateProps;

const GlobalSearch = ({ hasPortfolioManagement, hasProjectManagement, hasIdeation, history }: Props) => {
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
    const [counts, setCounts] = useState<ICounts>({});
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedTab, setSelectedTab] = useState(0);
    const [type, setType] = useState<SearchContext>(SearchContext.None);
    const searchBoxRootRef = useRef<HTMLDivElement | null>(null);
    const searchBoxInputRef = useRef<HTMLInputElement | null>(null);
    const urlFactoryRef = useRef(new UrlFactory());
    const theme = getTheme();

    React.useEffect(() => {
        if (showSuggestions) {
            setIsLoading(true);
            waitForFinalEvent(onChange, 400, 'global-search')();
        }
    }, [searchText, type, showSuggestions]);

    const config: SearchConfig = {
        pivots: [
            hasPortfolioManagement && <PivotItem headerText="Portfolio" itemKey="1" key="1" />,
            hasPortfolioManagement && <PivotItem headerText="Program" itemKey="2" key="2" />,
            hasProjectManagement && <PivotItem headerText="Project" itemKey="3" key="3" />,
            <PivotItem headerText="Resource" itemKey="4" key="4" />,
            hasIdeation && <PivotItem headerText="Idea" itemKey="5" key="5" />
        ].filter(notBoolean),
        renderGroups: (groups: SuggestionMap) => [
            hasPortfolioManagement && renderGroup(SearchContext.Portfolio, groups, 'Portfolio'),
            hasPortfolioManagement && renderGroup(SearchContext.Program, groups, 'Program'),
            hasProjectManagement && renderGroup(SearchContext.Project, groups, 'Projects'),
            renderGroup(SearchContext.Resource, groups, 'Resources'),
            hasIdeation && renderGroup(SearchContext.Idea, groups, 'Ideas')
        ].filter(notBoolean),
        tabMap: {
            [SearchContext.None]: "0",
            [SearchContext.Portfolio]: "1",
            [SearchContext.Program]: "2",
            [SearchContext.Project]: "3",
            [SearchContext.Resource]: "4",
            [SearchContext.Idea]: "5",
        }
    };

    return (
        <div className="global-search">
            <SearchBox
                role="search"
                placeholder="Global Search"
                onKeyDown={onKeyDown}
                value={searchText}
                componentRef={componentRef}
                onClick={() => setShowSuggestions(true)}
                onChange={(e: any, v: string) => { setSearchText(v); setShowSuggestions(true) }}
                onClear={clear}
                onEscape={dismiss}
                onFocus={() => setShowSuggestions(true)}
                onSearch={() => onSearch(selectedIndex)}
                clearButtonProps={{ hidden: true }}
                styles={{ clearButton: { display: 'none' } }}
            />
            {showSuggestions &&
                <Callout
                    className="global-search-suggestions"
                    target={searchBoxRootRef.current}
                    calloutWidth={418}
                    isBeakVisible={false}
                    gapSpace={8}
                    preventDismissOnScroll={true}
                    directionalHint={DirectionalHint.topRightEdge}
                    directionalHintForRTL={DirectionalHint.topRightEdge}
                    onDismiss={dismiss}>
                    {renderSuggestions()}
                </Callout>
            }
        </div>
    );

    function onChange() {
        setIsLoading(true);

        let context = type;
        if (context !== SearchContext.None) {
            context |= SearchContext.Strict;
        } else {
            const url = history.location.pathname;
            context = urlFactoryRef.current.getEntityTypeByUrl(url);
        }

        get<ISearchResult>(`api/search?filter=${encodeURIComponent(searchText)}&context=${context}`)
            .then(data => {
                const newSuggestions = Object.keys(data).map(key => data[key]).reduce((prev, curr) => prev.concat(curr.entities.map<ISuggestion>(_ => ({
                    id: _.id,
                    identifier: _.identifier,
                    name: _.name,
                    url: urlFactoryRef.current[_.type](_.id),
                    type: _.type,
                    imageId: _.imageId,
                    isPrivate: _.isPrivate,
                    sourceInfos: _.sourceInfos
                }))), Array.of<ISuggestion>());

                const newCounts: ICounts = {};
                Object.keys(data).forEach((key: string) => newCounts[SearchContext[key as any] as any] = data[key].count);
                setSuggestions(newSuggestions);
                setCounts(newCounts);
                setSelectedIndex(-1);
                setIsLoading(false);
            })
            .catch(() => {
                setSuggestions([]);
                setCounts({});
                setSelectedIndex(-1);
                setIsLoading(false);
            });
    };

    function onKeyDown(ev: React.KeyboardEvent<HTMLInputElement>) {
        switch (ev.which) {
            case KeyCodes.tab:
                onTabChange(`${(selectedTab + 1) % Object.keys(config.tabMap).length}`);
                ev.preventDefault();
                break;
            case KeyCodes.up:
                if (selectedIndex > 0) {
                    setSelectedIndex(selectedIndex - 1);
                }
                ev.preventDefault();
                ev.stopPropagation();
                break;
            case KeyCodes.down:
                const items = getSuggestions();
                if (selectedIndex < items.length - 1) {
                    setSelectedIndex(selectedIndex + 1);
                }
                break;
            default:
                break;
        }
    };

    function onSearch(index: number) {
        if (index !== -1) {
            const items = getSuggestions();
            history.push(items[index].url);
            dismiss();
        }
    };

    function renderSuggestions() {
        return [<Pivot key="header" selectedKey={`${selectedTab}`} headersOnly={true} onLinkClick={_ => _ && onTabChange(_.props.itemKey)}>
            <PivotItem headerText="All" itemKey={config.tabMap[SearchContext.None]} />
            {
                config.pivots
            }
        </Pivot>,
        renderList()];
    };

    function onTabChange(tabKey?: string) {
        if (tabKey === undefined) return;

        const tabMap = config.tabMap;
        const type = +((Object.keys(tabMap).find(key => tabMap[key] === tabKey) as SearchContext | undefined) ?? SearchContext.None);

        setSelectedTab(+tabKey);
        setSelectedIndex(-1);
        setType(type);
    };

    function renderList() {
        if (isLoading) {
            return <Spinner key="spinner" size={SpinnerSize.medium} label="Loading..." />;
        }

        const items = getSuggestions();
        if (items.length) {
            if (type === SearchContext.None) {
                const groups = groupByType(items);
                return <div key="suggestions-list" className="suggestions-list">
                    {
                        config.renderGroups(groups)
                    }
                </div>;
            }

            return <div key="suggestions-list" className="suggestions-list">
                {items.map((_, index) => renderSuggestion(_, index))}
            </div>;
        }

        return <NoResult key="no-result" />;
    };

    function renderGroup(searchContext: SearchContext, groups: SuggestionMap, title: string) {
        return !!groups[searchContext] &&
            <div className="suggestions-group" key={searchContext}>
                <div className="title" onClick={() => onTabChange(config.tabMap[searchContext])}>{title} ({counts[searchContext]})</div>
                {groups[searchContext].map(_ => renderSuggestion(_.suggestion, _.index))}
            </div>;
    };

    function getSuggestions() {
        return type === SearchContext.None ? suggestions : suggestions.filter(_ => _.type === type);
    };

    function renderSuggestion(suggestion: ISuggestion, index: number) {
        return <ActionButton
            key={suggestion.id}
            styles={getSuggestionStyles(suggestion, index)}
            className="global-search-suggestion"
            onMouseMove={() => setSelectedIndex(index)}
            onMouseLeave={() => setSelectedIndex(-1)}
            onClick={() => dismiss()}>
            <Link href={suggestion.url} tabIndex={-1} usePropagation={true}>
                <div style={{ height: 40 }}>{renderIcon(suggestion)}</div>
                <div className="title-container">
                    <div className="title ellipsis" title={suggestion.name}>{suggestion.name}</div>
                    {suggestion.identifier && <div className="secondary">ID {suggestion.identifier}</div>}
                </div>
                {suggestion.isPrivate && <PrivateProjectIcon />}
            </Link>
        </ActionButton>;
    };

    function getSuggestionStyles(suggestion: ISuggestion, index: number) {
        const { semanticColors } = theme;
        return {
            root: {
                backgroundColor: index === selectedIndex
                    ? semanticColors.menuItemBackgroundHovered
                    : "transparent"
            }
        };
    };

    function renderIcon(suggestion: ISuggestion) {
        if (suggestion.type === SearchContext.Project) {
            return <ProjectLogo imageId={suggestion.imageId} />;
        }
        if (suggestion.type === SearchContext.Resource) {
            return <PersonaCoin size={PersonaSize.size32} text={suggestion.name} imageUrl={getPersonInfoImageUrl(suggestion)} />;
        }

        const classMap = {
            [SearchContext.Portfolio]: "pf-logo",
            [SearchContext.Program]: "prog-logo",
            [SearchContext.Idea]: "idea-logo"
        };
        return <Logo imageId={suggestion.imageId} className={classMap[suggestion.type] || ""} />;
    };

    function componentRef(searchBox: ISearchBox | null) {
        const search = searchBox as any;
        if (search && search._rootElement) {
            searchBoxRootRef.current = search._rootElement.current;
            searchBoxInputRef.current = search._inputElement.current;
        }
    };

    function clear() {
        setShowSuggestions(false);
        setSuggestions([]);
        setSearchText('');
        setSelectedIndex(-1);
        setSelectedTab(0);
        setType(SearchContext.None);
    };

    function dismiss() {
        clear();
        searchBoxInputRef.current && searchBoxInputRef.current.blur();
    };
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
    hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
    hasProjectManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.ProjectManagement),
    hasIdeation: Subscription.contains(state.tenant.subscription, PPMFeatures.Ideation),
});

export default withRouter<{}>(connect(mapStateToProps)(GlobalSearch));

const NoResult = () => <div className="no-result"><span>No result</span></div>;

class UrlFactory {
    [id: number]: (id: string) => string;

    constructor() {
        this[SearchContext.Portfolio] = (id: string) => `/portfolio/${id}`;
        this[SearchContext.Program] = (id: string) => `/program/${id}`;
        this[SearchContext.Project] = (id: string) => `/project/${id}`;
        this[SearchContext.Resource] = (id: string) => `/resource/${id}`;
        this[SearchContext.Idea] = (id: string) => `/idea/${id}`;
    }

    getEntityTypeByUrl(url: string): SearchContext {
        if (~url.indexOf('project')) { return SearchContext.Project; }
        if (~url.indexOf('program')) { return SearchContext.Program; }
        if (~url.indexOf('portfolio')) { return SearchContext.Portfolio; }
        if (~url.indexOf('resource')) { return SearchContext.Resource; }
        if (~url.indexOf('idea')) { return SearchContext.Idea; }

        return SearchContext.None;
    }
}

function groupByType(suggestions: ISuggestion[]) {
    const dic = new SuggestionMap();
    suggestions.forEach((_, i) => {
        const item = { suggestion: _, index: i };
        if (dic[_.type]) {
            dic[_.type].push(item);
        } else {
            dic[_.type] = [item];
        }
    });
    return dic;
};

export class SuggestionMap {
    [type: number]: { suggestion: ISuggestion; index: number; }[];
}

interface ISearchResult {
    [context: string]: IContextSearchResult;
}

interface IContextSearchResult {
    count: number;
    entities: ISearchEntity[];
}

interface ISearchEntity {
    id: string;
    identifier: string;
    name: string;
    isPrivate: boolean;
    type: SearchContext;

    imageId?: string;
    sourceInfos: ISourceInfo[];
}

enum SearchContext {
    None = 0,
    Project = 1,
    Program = 2,
    Portfolio = 4,
    Resource = 8,
    Idea = 16,
    Strict = 32
}