import { DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { AiWarning } from "../../../common/ai/warning";
import { LeaveFeedback } from "../../../common/ai/leaveFeedback";

export interface AIScheduleTask {
    id: string;
    name: string;
    outlineLevel: number;
    parentId: string | undefined;
    parentIds: string[];
    isParent: boolean;
    startDate: Date;
    finishDate: Date;
    duration: number;   
    rank: number;
}

export type AISchedule  = {
    errorMessage?: string;
    tasks: AIScheduleTask[];
    prompt?: string;
}

type Props = {
    projectId: string;
    scheduleResponse: AISchedule,
    isScheduleLoading: boolean;
    tasksSelected: boolean;
    onTasksCreate: () => void;
    onCancel: () => void;
    onBack: () => void;
}

const AIScheduleTasksPreviewFooter = (props: Props) => {
    const { projectId, scheduleResponse, isScheduleLoading, tasksSelected, onTasksCreate, onCancel, onBack } = props;
    return <>
        <div className='panel-buttons'>
            <PrimaryButton
                text="Create Tasks"
                disabled={!!scheduleResponse.errorMessage || isScheduleLoading || !tasksSelected}
                onClick={onTasksCreate} />
            <DefaultButton text="Cancel" onClick={onCancel} />
            <DefaultButton text='Back' onClick={onBack} />
            <div className="align-right">
                <LeaveFeedback 
                    subject="PPM AI Schedule"
                    disabled={isScheduleLoading || !!scheduleResponse.errorMessage}
                    url={`api/ai/project/${projectId}/schedule/feedback`}
                    data={{
                        request: scheduleResponse.prompt,
                        response: JSON.stringify(scheduleResponse.tasks),
                    }}
                />
            </div>
        </div>
        <AiWarning />   
    </>
}

export default AIScheduleTasksPreviewFooter;