import * as React from 'react';
import { EntityType } from "../../../entities/common";
import TagLink from '../TagLink';

export class TagFormatter extends React.Component<{ value?: string[], withNavigation: boolean, entityType?: EntityType }>{
    render() {
        const { value, withNavigation, entityType } = this.props;
        if (!value) {
            return null;
        }

        return <div className="tags" title={value?.join(" ")}>
            {value.map((item, index) => <TagLink key={index} value={item} entityType={entityType} withoutNavigation={!withNavigation} />)}
        </div>;
    }
}
