import * as React from "react";
import { TextField } from "office-ui-fabric-react";
import {TimeTrackingAdministrativeCategory, TimeTrackingAdministrativeCategorySave, TimeTrackingAdministrativeCategoryType, TimeTrackingAdministrativeCategoryTypeLabelsMap } from "../../../store/Tenant";
import { Validator } from "../../../validation";
import { ItemCreation } from "../../common/ItemCreation";
import LabellableComponent from "../../common/LabellableComponent";
import FlagInput from "../../../components/common/inputs/FlagInput";
import { PPMXColorPicker } from "../../../components/common/PPMXColorPicker";
import DropdownInput from "../../common/inputs/DropdownInput";

type Props = {
    existingCategories: TimeTrackingAdministrativeCategory[];
    category?: TimeTrackingAdministrativeCategorySave;
    onSave: (entity: TimeTrackingAdministrativeCategorySave) => void;
    onDismiss: () => void;
};

const maxDescriptionLength = 5000;
const descriptionValidator = Validator.new().maxLength(maxDescriptionLength).build();

export default function AdministrativeCategoryEdit(props: Props) {

    const [isDirty, setIsDirty] = React.useState(false);
    const [category, setCategory] = React.useState<TimeTrackingAdministrativeCategorySave>(
        props.category
            ? { ...props.category }
            : {
                title: "",
                description: "",
                isEnabled: true,
                color: "",
                type: TimeTrackingAdministrativeCategoryType.NonProjectTime
            }
    );

    const titleValidator = React.useMemo(() => Validator.new().unique(props.existingCategories.filter(_ => _.id !== category?.id).map(_ => _.title)).required().build(), [category]);

    const canManage = true;
    return (
        <ItemCreation
            onDismiss={props.onDismiss}
            header={{
                showNameEditor: false,
                text: category.id ? "Edit Administrative Category" : "New Administrative Category"               
            }}
            isDirty={isDirty}
            commands={[
                {
                    primary: true,
                    disabled: !canManage || !titleValidator.isValid(category.title),
                    text: category.id ? "Save" : "Create",
                    onClick: () => {
                        props.onSave(category);
                        props.onDismiss();
                    }
                },
                {
                    text: "Cancel",
                    onClick: props.onDismiss
                }
            ]}>
            <div className="panel-area">
                <div className="grid-item">
                    <LabellableComponent className="field-container required" label="Category Name">
                        <TextField
                            value={category.title}
                            onGetErrorMessage={_ => titleValidator.getErrorMessage(_)}
                            onChange={(e, _) => {
                                setIsDirty(true);
                                setCategory({ ...category, title: _ || "" });
                            }}
                            disabled={!canManage}
                            onRenderSuffix={() => <PPMXColorPicker
                                color={category.color}
                                onChange={color => {setCategory({ ...category, color: color || "" })}}
                                hideRemoveColorButton
                            />}
                        />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent className="field-container" label="Description">
                        <TextField
                            value={category.description}
                            onGetErrorMessage={_ => descriptionValidator.getErrorMessage(_)}
                            onChange={(e, _) => {
                                setIsDirty(true);
                                setCategory({ ...category, description: _ || "" });
                            }}
                            disabled={!canManage}
                            multiline                           
                        />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent className="field-container" label="Enabled">
                        <FlagInput
                            value={category.isEnabled}
                            onChanged={_ => {
                                setIsDirty(true);
                                setCategory({
                                    ...category,
                                    isEnabled: _
                                });
                            }}
                            disabled={!canManage}
                        />
                    </LabellableComponent>
                </div>

                <div className="grid-item">
                    <LabellableComponent className="field-container" label="Type">
                        <DropdownInput
                            value={category.type}
                            inputProps={{
                                options: [
                                    {
                                        key: TimeTrackingAdministrativeCategoryType.NonProjectTime,
                                        text: TimeTrackingAdministrativeCategoryTypeLabelsMap[TimeTrackingAdministrativeCategoryType.NonProjectTime]
                                    },
                                    {                                       
                                        key: TimeTrackingAdministrativeCategoryType.NotWorkingTime,
                                        text: TimeTrackingAdministrativeCategoryTypeLabelsMap[TimeTrackingAdministrativeCategoryType.NotWorkingTime]
                                    }]
                            }}
                            onChanged={_ => {
                                setIsDirty(true);
                                setCategory({
                                    ...category,
                                    type: _
                                });
                            }}
                            disabled={!canManage}
                        />
                    </LabellableComponent>
                </div>
            </div>
        </ItemCreation>
    );
};