import React from "react";
import { DefaultButton } from "office-ui-fabric-react";

const HeaderHelpButton = () => (
    <DefaultButton
        title="Help"
        className="nav-header-button icon-button"
        // icon is not centered itself
        iconProps={{ iconName: "PPMXQuestionMarkInCircle", style: { fontSize: 17.3 } }}
        menuProps={{
            items: [
                {
                    key: "documentation",
                    name: "Documentation",
                    target: "_blank",
                    href: "https://help.ppm.express/89245-getting-started-with-ppm-express",
                    iconProps: { iconName: "Documentation" },
                },
                {
                    key: "whatsnew",
                    name: "What's New",
                    target: "_blank",
                    iconProps: { iconName: "FavoriteStar" },
                    href: "https://help.ppm.express/89154-ppm-express/release-notes-ppm-express",
                },
            ],
            calloutProps: {
                className: "nav-header-button-callout",
            }
        }}
    />
);

export default HeaderHelpButton;
