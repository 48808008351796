import { IControlConfiguration, ISectionUIControlProps } from '../../../interfaces/ISectionUIControlProps';
import * as Metadata from '../../../../../entities/Metadata';
import { Dictionary, EntityType, IWarning } from "../../../../../entities/common";
import { ProjectAttrs } from '../../../../../store/ProjectsListStore';
import { IControlWarningsTypeMap } from '../../../../../store/warningsTypeMapsStore';
import { IInputProps } from '../../../interfaces/IInputProps';
import { ColumnGroupedFieldNames } from '../../../../field/ConfigureFields/GroupedFieldsConfiguration';
import { StatusCalculationTypes } from '../../../../../store/Tenant';
import { buildStatusAttributeName } from '../statusesControl/StatusSelectionPanel';
import { buildStatusDescriptionAttributeName } from '../StatusesControl';

interface ISettings {
    fields: ColumnGroupedFieldNames;
}

interface IDataContext {
    entityId: string;
    entityType: EntityType;
    readOnlyFields?: string[];
    warningsTypeMap?: IControlWarningsTypeMap;
    hiddenFields?: string[];
}

interface IActions {
    refreshEntity: () => void;
    updateUIControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void;

    onEditComplete: (fieldName: string, fieldValue: any, extra: Dictionary<any>) => void;
}

interface IFieldAreaEntity {
    attributes: Dictionary<any>;
    warnings: IWarning[];
}

type ICustomElementRender = Dictionary<(props: IInputProps, state: ProjectAttrs, field: Metadata.Field) => JSX.Element>;

export type IFieldsAreaConfiguration = IControlConfiguration<IActions, {}, IDataContext>;

export type FieldsAreaProps = ISectionUIControlProps<IActions, ISettings, IFieldAreaEntity, ICustomElementRender, {}, IDataContext>;

export type ColumnGroupedFieldsProps = FieldsAreaProps & {
    fields: Metadata.Field[];
    className?: string;
};


export function buildReadOnlyStatusFieldsNames(
    statusCalculation: StatusCalculationTypes, statuses: string[], isEntityStatusCalculationEnabled: boolean): string[] {

    if (isStatusesEditable(statusCalculation, !isEntityStatusCalculationEnabled) || !statuses) {
        return [];
    }

    return statuses
        .map(_ => ([buildStatusAttributeName(_), buildStatusDescriptionAttributeName(_)]))
        .reduce((prev, cur) => ([...prev, ...cur]), []);
}

export function isStatusesEditable(statusCalculation: StatusCalculationTypes, statusCalculationDisabled: boolean) {
    return statusCalculation === StatusCalculationTypes.Manual || statusCalculationDisabled;
}