import { JsonHubProtocol, HubConnection, HubConnectionState, HubConnectionBuilder, LogLevel, IHttpConnectionOptions, HttpTransportType } from '@microsoft/signalr';

const startSignalRConnection = (connection: HubConnection) => {
    connection.start()
        .then(_ => {
            console.assert(connection.state === HubConnectionState.Connected);
            console.log('SignalR connection established', connection.baseUrl);
        })
        .catch(error => {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.error('SignalR Connection Error: ', error);
            setTimeout(() => startSignalRConnection(connection), 5000);
        });
};

const isDev = process.env.NODE_ENV === 'development';

export const getSignalRConnection = (url: string) => {
    const options: IHttpConnectionOptions = isDev
        ? {
            logger: LogLevel.Warning,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
        }
        : {
            logger: LogLevel.Error,
        };

    console.log('SignalR: Creating new connection.');

    const connection = new HubConnectionBuilder()
        .withUrl(url, options)
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(LogLevel.Information)
        .build();
    connection.serverTimeoutInMilliseconds = 60000;

    // re-establish the connection if connection dropped
    connection.onclose(error => {
        console.assert(connection.state === HubConnectionState.Disconnected);
        if (!!error) {
            console.log('SignalR: connection was closed due to error.', error);
        } else {
            console.log('SignalR: connection was closed.');
        }
    });

    connection.onreconnecting(error => {
        console.assert(connection.state === HubConnectionState.Reconnecting);
        console.log('SignalR: connection lost due. Reconnecting...', error);
    });

    connection.onreconnected(connectionId => {
        console.assert(connection.state === HubConnectionState.Connected);
        console.log('SignalR: connection reestablished. Connected with connectionId', connectionId);
    });

    startSignalRConnection(connection);

    return connection;
};