import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../../../store/index";
import { ExternalFieldInfo, MappingType } from '../../../../store/integration/common';
import { actionCreators, IConnectionConfiguration, P4WFieldToPpmxFieldsMap } from '../../../../store/integration/P4WStore';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import { EntityType } from '../../../../entities/common';
import { get } from "../../../../fetch-interceptor";
import { Link, PivotItem } from 'office-ui-fabric-react';
import ConfigureConnectionPanel from '../../ConfigureConnectionPanel';
import MappingPanelControl from '../../MappingPanelControl/MappingPanelControl';
import './P4WMappingPanel.css'

type OwnProps = {
    onDismiss: () => void;
    connectionId: string;
    itemProjectId: string;
    initialTab?: string;
}

type StateProps = {
    configuration: IConnectionConfiguration;
    p4WProjectFields: ExternalFieldInfo[];
    p4WTasksFields: ExternalFieldInfo[];
    isProjectLoading: boolean;
    isTasksLoading: boolean;
    error: string | null;
}
type Props = OwnProps & StateProps & {
    P4WActionCreators: typeof actionCreators;
};

const P4WMappingPanel = (props: Props) => {
    const { connectionId, itemProjectId, P4WActionCreators } = props;
    React.useEffect(() => {
        P4WActionCreators.loadTasksFields(connectionId, itemProjectId);
        P4WActionCreators.loadProjectFields(connectionId, itemProjectId);
        P4WActionCreators.loadConnectionConfiguration(connectionId, itemProjectId);
    }, [props.connectionId]);

    function _onGetExternalProjectFieldOptions(field: ExternalFieldInfo) {
        return get<{ options: string[] | number[] | null }>(`api/integration/P4W/field/${field.internalName}/project/options`, { connectionId, ProjectId: itemProjectId })
            .then(data => data.options);
    }

    function _onGetExternalTaskFieldOptions(field: ExternalFieldInfo) {
        return get<{ options: string[] | number[] | null }>(`api/integration/P4W/field/${field.internalName}/task/options`, { connectionId, ProjectId: itemProjectId })
            .then(data => data.options);
    }

    function _getPivots() {
        return [
            <PivotItem headerText="Tasks Fields Mapping" className="pivot-content" itemKey="tasks-fields" key="tasksFields">
                <MappingPanelControl
                    onRenderHeader={() =>
                        <div className="mapping-header">Select fields for mapping to be displayed in Schedule section.
                            The settings are unique per project.
                            Please refer to <Link href='https://help.ppm.express/project-for-the-web-connection/1726263#section-1' target='_blank'>this article</Link> on how to configure mapping.</div>
                    }
                    isLoading={props.isTasksLoading}
                    error={props.error}
                    onDismiss={props.onDismiss}
                    connector={SourceType.P4W}
                    entityType={EntityType.Task}
                    externalFields={props.p4WTasksFields}
                    typesMap={P4WFieldToPpmxFieldsMap}
                    mapping={props.configuration.taskImportSettings["Task"]}
                    showTransformationScript={true}
                    updateMapping={mapping => {
                        props.configuration.taskImportSettings["Task"] = mapping;
                        props.P4WActionCreators.updateConnectionConfiguration(connectionId, itemProjectId, props.configuration);
                    }}
                    mappingTypes={[MappingType.ToPpmx]}
                    onGetExternalFieldOptions={_onGetExternalTaskFieldOptions}
                    />
            </PivotItem>,
            <PivotItem headerText="Project Fields Mapping" className="pivot-content" itemKey="project-fields" key="projectFields">
                <MappingPanelControl
                    onRenderHeader={() =>
                        <div className="mapping-header">Select fields for mapping to be displayed in Project Details section.
                            The settings will be applied for all linked projects within the same connection.
                            Please refer to <Link href='https://help.ppm.express/project-for-the-web-connection/1726263#section-0' target='_blank'>this article</Link> on how to configure mapping.</div>
                    }
                    isLoading={props.isProjectLoading}
                    error={props.error}
                    onDismiss={props.onDismiss}
                    connector={SourceType.P4W}
                    entityType={EntityType.Project}
                    externalFields={props.p4WProjectFields}
                    typesMap={P4WFieldToPpmxFieldsMap}
                    mapping={props.configuration.projectImportSettings}
                    showTransformationScript={true}
                    updateMapping={mapping => {
                        props.configuration.projectImportSettings = mapping;
                        props.P4WActionCreators.updateConnectionConfiguration(connectionId, itemProjectId, props.configuration);
                    }}
                    mappingTypes={[MappingType.ToPpmx]}
                    onGetExternalFieldOptions={_onGetExternalProjectFieldOptions}
                />
            </PivotItem>
        ];
    }

    return <ConfigureConnectionPanel
        onRenderHeader={() => <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure Connection</p>
            <div className="ms-Panel-secondaryText">
                Set up rules for project and task fields mapping.
            </div>
        </div>}
        onDismiss={props.onDismiss}
        initialTab={props.initialTab}
        sourceType={SourceType.P4W}
        pivots={_getPivots()} />
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    return {
        configuration: {
            taskImportSettings: state.p4w.taskImportSettings.entities,
            projectImportSettings: state.p4w.projectImportSettings.entities,
        },
        p4WProjectFields: state.p4w.projectFields.entities || [],
        p4WTasksFields: state.p4w.tasksFields.entities || [],
        error: state.p4w.projectFields.error ||
            state.p4w.tasksFields.error ||
            state.p4w.taskImportSettings.error ||
            state.p4w.projectImportSettings.error,
        isProjectLoading: state.p4w.projectFields.isLoading ||
            state.p4w.projectImportSettings.isLoading ||
            state.p4w.projectImportSettings.isProcessing,
        isTasksLoading: state.p4w.tasksFields.isLoading ||
            state.p4w.taskImportSettings.isLoading ||
            state.p4w.taskImportSettings.isProcessing,
    }
}

const mergeActionCreators = (dispatch: any) => {
    return {
        P4WActionCreators: bindActionCreators(actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(P4WMappingPanel);