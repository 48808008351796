import * as React from 'react';
import { Toggle } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { actionCreators } from "../../store/ProjectsListStore";
import EntityCreation from '../common/extensibleEntity/EntityCreation';
import { EntityType, entityTypeLabelMap } from '../../entities/common';
import { ApplicationState } from '../../store';
import { TenantState, ProductType } from '../../store/Tenant';
import LabellableComponent from '../common/LabellableComponent';

interface OwnProps {
    onDismiss: () => void;
    openOnComplete: boolean;
    showConfigureConnectionsOption: boolean;
    isPrivateProject?: boolean;
}

interface StateProps {
    tenant: TenantState
}

type ProjectCreationProps = typeof actionCreators & OwnProps & StateProps;

const ProjectCreation = (props: ProjectCreationProps) => {
    const [configureConnections, setConfigureConnections] = React.useState(props.showConfigureConnectionsOption);
    return <EntityCreation
        entityType={EntityType.Project}
        entityTypeLabel={props.isPrivateProject ? entityTypeLabelMap[EntityType.PrivateProject].singular : undefined}
        onDismiss={props.onDismiss}
        onSave={(name, layoutId) => props.createProject(name, layoutId, !!props.isPrivateProject, configureConnections, props.openOnComplete)
        }>
        {props.showConfigureConnectionsOption && <div className="grid-item">
            <LabellableComponent label='' className="field-container">
                <Toggle className="two-column"
                    checked={configureConnections}
                    onChange={(e: any, c?: boolean) => setConfigureConnections(!!c)}
                    label="Configure Project Connections" onText='Yes' offText='No' />
            </LabellableComponent>
        </div>}
    </EntityCreation>;
}

function mapStateToProps(state: ApplicationState) {
    return {
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, actionCreators)(ProjectCreation)