import { Middleware } from "redux";
import { ActionTypes, TenantActions, ThemeType } from "../Tenant";
import { ApplicationState } from "..";

const themeMiddleware: Middleware<{}, ApplicationState> = (store) => (next) => (action: TenantActions) => {
    if (action.type === ActionTypes.SET_THEME) {
        document.body.setAttribute('data-theme', ThemeType[action.themeType]);
    }

    return next(action);
};

export default themeMiddleware;
