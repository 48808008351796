import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { PPMFeatures } from '../../../../store/Tenant';
import { FeaturesList } from './FeaturesList';

type Props = {
    newFeatures: number;
    lostFeatures: number;
    newPlanName: string;
    oldPlanName: string;
}

export const SwitchTrialMessage = (props: Props) => {
    const { newFeatures, lostFeatures, newPlanName, oldPlanName } = props;
    return <>
        {
            newFeatures === PPMFeatures.None && lostFeatures === PPMFeatures.None &&
            <span>
                Are you sure that you want to switch to {newPlanName} ?
            </span>
        }
        {
            newFeatures !== PPMFeatures.None &&
            <>
                <div>Ready to switch to {newPlanName} ?</div>
                <div className='with-top-margin'>Let's give it a try! You will get access to new functionality listed below:</div>
                <FeaturesList features={newFeatures} isNew={true} />
            </>
        }
        {
            lostFeatures !== PPMFeatures.None &&
            <>
                Are you sure that you want to switch to {newPlanName} ?
                <MessageBar className='with-top-margin' messageBarType={MessageBarType.warning}>
                    Please note that you won't get access to some of the functionality listed below.
                </MessageBar>
                <FeaturesList features={lostFeatures} isNew={false} />
                <MessageBar messageBarType={MessageBarType.warning}>
                    Also, please note that you will lose any content created with features available under {oldPlanName}
                </MessageBar>
            </>
        }
    </>
}