import * as React from 'react';
import { IBasePickerProps, BasePicker, IPickerItemProps } from "office-ui-fabric-react";
import FabricCrutches from '../../../components/utils/FabricCrutches';
import { isCancel } from "../../../store/utils";

class PPMXBasePicker<T, P extends PPMXPickerProps<T>> extends BasePicker<T, P> {

    // This is protected because we may expect the backspace key to work differently in a different kind of picker.
    // This lets the subclass override it and provide it's own onBackspace.
    protected onBackspace(ev: React.KeyboardEvent<HTMLElement>) {
        const items: IPickerItemProps<T>[] = this.state.items;
        if (
            (items.length && !this.input.current) ||
            (this.input.current && !this.input.current.isValueSelected && this.input.current.cursorLocation === 0)
        ) {
            if (this.selection.getSelectedCount() > 0) {
                this.removeItems(this.selection.getSelection());
            } else if (items.length > 0) {
                const lastItem: IPickerItemProps<T> = items[items.length - 1];
                if (!lastItem.disabled) {
                    this.removeItem(lastItem);
                }
            }
        }
    }

    componentWillMount() {
        if (this.props.autoExpand && !this.state.items.length) {
            this.onEmptyInputFocus();
            this.setState({ suggestionsVisible: true });
        }
    }
}

export interface PPMXPickerProps<T> extends IBasePickerProps<T> {
    readOnly?: boolean;
    autoExpand?: boolean;
    adjustOptionsListWidth?: boolean;
    disabledEmptyInputFocus?: boolean;
}

export default class PPMXPicker<T> extends React.Component<PPMXPickerProps<T>> {
    private _picker = React.createRef<PPMXBasePicker<T, PPMXPickerProps<T>>>();

    render() {
        const { disabled, readOnly, inputProps, disabledEmptyInputFocus } = this.props;
        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;

        return <PPMXBasePicker
            {...this.props}
            disabled={isDisabled}
            readOnly={isReadOnly}
            ref={this._picker}
            inputProps={{
                ...inputProps,
                readOnly: isReadOnly,
                disabled: isDisabled
            }}
            onRenderItem={this.props.onRenderItem ? (props) => this.props.onRenderItem!({ ...props, disabled: isDisabled || isReadOnly }) : undefined}
            onResolveSuggestions={(isReadOnly || isDisabled) ? () => [] : this._onResolveSuggestions}
            onEmptyInputFocus={(isReadOnly || isDisabled || disabledEmptyInputFocus) ? undefined : this._onEmptyInputFocus}
        />;
    }

    private _onEmptyInputFocus = (selectedItems: T[]) => {
        if (this.props.adjustOptionsListWidth !== false) {
            FabricCrutches.fixPickerWidth(this._picker.current);
        }
        return this.props.onResolveSuggestions("", selectedItems);
    }

    private _onResolveSuggestions = (filter: string, selectedItems?: T[]): T[] | PromiseLike<T[]> => {
        if (this.props.adjustOptionsListWidth !== false) {
            FabricCrutches.fixPickerWidth(this._picker.current);
        }
        const result = this.props.onResolveSuggestions(filter, selectedItems);
        if (Array.isArray(result)) {
            return result;
        }

        return result.then(null, error => {
            if (isCancel(error)) {
                return [];
            }

            throw error;
        });
    }
}