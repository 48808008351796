import * as React from 'react';
import { connect } from 'react-redux';
import ConnectionSelect, { ConnectionVerifier } from "../ConnectionSelect";
import { actionCreators } from "../../../store/integration/VSTSStore";
import { IConnectionsState } from "../../../store/integration/common";
import { ApplicationState } from "../../../store/index";
import VSTSConnectionEdit from "./VSTSConnectionEdit";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SourceType } from '../../../store/ExternalEpmConnectStore';

type OwnProps = {
    connectionId?: string;
    disabled?: boolean;
    onChange: (connectionId?: string) => void;
};

type StateProps = { connections: IConnectionsState };
type Props = StateProps & typeof actionCreators & OwnProps;

interface IState {
    refreshId: string | undefined;
    isAdd: boolean;
}

class VSTSConnectionSelect extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        this.state = { refreshId: undefined, isAdd: false };
    }

    public render() {
        const { connectionId, connections, disabled } = this.props;
        
        return <>
            <ConnectionVerifier
                connections={connections}
                connectionId={connectionId}
                verifyConnection={this.props.verifyConnection}>
                <ConnectionSelect
                    key="connection-select"
                    disabled={connections.isLoading || !!disabled}
                    sourceType={SourceType.VSTS}
                    connectionId={connectionId}
                    connections={connections.data}
                    onConnectionChange={_ => { 
                        this.props.onChange(_?.id);
                        if (_?.id) {
                            this.props.verifyConnection(_?.id)
                        }
                    }}
                    onConnectionRemove={id => this.props.removeConnection(id, this.props.onChange)}
                    onConnectionRename={(_, __) => this.props.renameConnection(_, __)}
                    onRefreshClick={(_) => this.setState({ refreshId: _ })}
                    onNewConnectionClick={() => this.setState({ isAdd: true })}
                />
            </ConnectionVerifier>
            { connections.isLoading && <Spinner key="spinner" size={SpinnerSize.small} className="connection-select-spinner" /> }
            { this.state.isAdd && 
                <VSTSConnectionEdit 
                    key="connection-adding" 
                    onCreated={this.props.onChange} 
                    onDismiss={() => this.setState({ isAdd: false })} /> }
            { this.state.refreshId && 
                <VSTSConnectionEdit 
                    key="connection-refreshing"
                    connectionId={this.state.refreshId}
                    onDismiss={() => this.setState({ refreshId: undefined })} />}
        </>;
    }

    componentWillMount() {
        this.props.loadConnections();
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.vsts.connections };
}

export default connect(mapStateToProps, actionCreators)(VSTSConnectionSelect);