import * as React from 'react';
import { IComboBoxOption, TextField, ComboBox, IComboBox, ITextField } from 'office-ui-fabric-react';
import { IMappedProject, IImportProjectState } from "../../../store/integration/ImportStore";
import { EditorProps } from 'react-data-grid';

const onRenderItem = (props?: IComboBoxOption, defaultRender?: (props?: IComboBoxOption) => JSX.Element | null) => {
    if (!props || !defaultRender) return null;

    return <div key={props.key} title={props.text}>{defaultRender(props)}</div>;
}

const ProjectEditor = (props: EditorProps<IImportProjectState>) => {
    const onChangeText = (value?: string) => {
        props.onRowChange({ ...props.row, project: { name: value ? value : '', id: undefined } });
    }

    const onChange = (e?: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            props.onRowChange({ ...props.row, project: projects.find(_ => _.id == option.key)! }, true);
        } else {
            onChangeText(value);
        }
    }

    const comboBoxRef = React.useRef<IComboBox>(null);
    const textFieldRef = React.useRef<ITextField>(null);
    React.useLayoutEffect(() => {
        comboBoxRef.current?.focus(true);
        textFieldRef.current?.focus();
        textFieldRef.current?.select();
    }, []);

    const { project, projects } = props.row;
    if (projects.length) {
        const options = projects.map(_ => ({ key: _.id!, text: _.name }));
        return <ComboBox
            componentRef={comboBoxRef}
            selectedKey={project.id}
            text={project.name}
            allowFreeform={true}
            autoComplete="on"
            options={options}
            onChange={onChange}
            useComboBoxAsMenuWidth={true}
            onRenderItem={onRenderItem}
        />;
    }

    return <TextField
        componentRef={textFieldRef}
        value={project.name}
        onChange={(e: any, v) => onChangeText(v)}
        onBlur={() => props.onClose(true)}
    />;
}

export default ProjectEditor;