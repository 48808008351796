import { Quantization, ITimeframe } from '../../../entities/common';
import * as utils from './utils';

interface IFormattedDate {
    start: Date,
    finish: Date,
    dateFormatted: string;
    offsetUnits?: number;
}

interface IScaleBuilder {
    pieceWidthUnits: number;
    handler: (start: Date, finish: Date) => IFormattedDate[];
}

export interface IScale {
    dates: IFormattedDate[],
    pieceWidthUnits: number;
    totalWidthUnits: number;
}

export interface ITimelineInfo extends ITimeframe {
    duration: number;
}

export interface IScaleOffset {
    left: number;
    isOutOfScale: boolean;
}

export function timeframesEqual(a: Partial<ITimeframe> | undefined, b: Partial<ITimeframe> | undefined): boolean {
    return a?.start?.getTime() == b?.start?.getTime() && a?.end?.getTime() == b?.end?.getTime();
}

export function calculatePieceOffsetPercent(startDate: Date, finishDate: Date, innerDate: Date) {
    innerDate = innerDate < startDate ? startDate : innerDate;
    innerDate = innerDate > finishDate ? finishDate : innerDate;
    return utils.diffDays(innerDate, startDate) / utils.diffDays(finishDate, startDate);
}

export function getOffset(scale: IScale, date: Date, totalWidth: number): IScaleOffset {
    const coef = totalWidth / scale.totalWidthUnits;

    let isOutOfScale = false;
    let offsetUnits = getOffsetUnits(scale, date)
    if (offsetUnits == undefined) {
        isOutOfScale = true;
        let firstItem = scale.dates[0];
        if (date < firstItem.start) {
            offsetUnits = firstItem.offsetUnits || 0;
        }

        let lastItem = scale.dates[scale.dates.length - 1];
        if (date > lastItem.finish) {
            offsetUnits = (lastItem.offsetUnits || 0) + scale.pieceWidthUnits;
        }
    }
    return { left: (offsetUnits || 0) * coef, isOutOfScale };
}

export function shiftDate(scale: IScale, date: Date, shift: number, totalWidth: number): Date {
    const daysInPeriod = Math.round((scale.dates[scale.dates.length - 1].finish.getTime() - scale.dates[0].start.getTime()) / (60 * 60 * 24 * 1000));
    return date.clone().addDays(Math.round(shift / totalWidth * daysInPeriod));
}

function getOffsetUnits(scale: IScale, date: Date): number | undefined {
    for (const item of scale.dates) {
        if (date >= item.start && date <= item.finish) {
            return (item.offsetUnits || 0) + calculatePieceOffsetPercent(item.start, item.finish, date) * scale.pieceWidthUnits;
        }
    }
}

export const quantizationConfigMap: { [key: string]: { title: string } } = {
    [Quantization.days]: { title: "Days" },
    [Quantization.weeks]: { title: "Weeks" },
    [Quantization.months]: { title: "Months" },
    [Quantization.quarters]: { title: "Quarters" },
    [Quantization.years]: { title: "Years" }
}

export class TimelineScale {
    private static map: { [key: string]: IScaleBuilder } = {
        [Quantization.days]: { pieceWidthUnits: 40, handler: TimelineScale.qDays },
        [Quantization.weeks]: { pieceWidthUnits: 40, handler: TimelineScale.qWeeks },
        [Quantization.months]: { pieceWidthUnits: 30, handler: TimelineScale.qMonths },
        [Quantization.quarters]: { pieceWidthUnits: 30, handler: TimelineScale.qQuarters },
        [Quantization.years]: { pieceWidthUnits: 30, handler: TimelineScale.qYears }
    }
        
    private static qDays(start: Date, finish: Date): IFormattedDate[] {        
        const dates: IFormattedDate[] = [],
            currentDate = start.clone().trimHours();

        while (currentDate < finish) {
            const item = currentDate.clone();
            currentDate.addDays(1);
            dates.push({
                start: item,
                finish: currentDate.clone().addDays(-1).getEndOfDay(),
                dateFormatted: utils.formatDate('M dd, D', item)
            });
        }

        return dates;
    }

    private static qWeeks(start: Date, finish: Date): IFormattedDate[] {
        const dates: IFormattedDate[] = [],
            startDayOfWeek = start.getDay(),
            currentDate = start.clone().addDays(-startDayOfWeek).trimHours();

        while (currentDate < finish) {
            const item = currentDate.clone();
            currentDate.addDays(7);
            dates.push({
                start: item,
                finish: currentDate.clone().addDays(-1).getEndOfDay(),
                dateFormatted: utils.formatDate('M dd', item)
            });
        }

        return dates;
    }

    private static qMonths(start: Date, finish: Date): IFormattedDate[] {
        const dates: IFormattedDate[] = [];
        let currentDate = new Date(start.getFullYear(), start.getMonth(), 1);

        while (currentDate < finish) {
            const item = currentDate.clone();
            currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

            dates.push({
                start: item,
                finish: currentDate.clone().addDays(-1).getEndOfDay(),
                dateFormatted: utils.formatDate('M yy', item)
            });

        }

        return dates;
    }

    private static qQuarters(start: Date, finish: Date): IFormattedDate[] {
        const dates: IFormattedDate[] = [];
        const startQuarter = Math.floor(start.getMonth() / 3);
        let currentDate = new Date(start.getFullYear(), startQuarter * 3, 1);

        while (currentDate < finish) {
            const item = currentDate.clone();
            currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
            dates.push({
                start: item,
                finish: currentDate.clone().addDays(-1).getEndOfDay(),
                dateFormatted: 'Q' + (Math.floor(item.getMonth() / 3) + 1) + ' ' + utils.formatDate('yy', item)
            });
        }

        return dates;
    }

    private static qYears(start: Date, finish: Date): IFormattedDate[] {
        const dates: IFormattedDate[] = [];
        let currentDate = new Date(start.getFullYear(), 0, 1);

        while (currentDate < finish) {
            const item = currentDate.clone();
            currentDate = new Date(currentDate.getFullYear() + 1, 0, 1);
            dates.push({
                start: item,
                finish: currentDate.clone().addDays(-1).getEndOfDay(),
                dateFormatted: utils.formatDate('yy', item)
            });
        }

        return dates;
    }

    private static applyOffsets(pieceWidthUnits: number, dates: IFormattedDate[]): IScale {
        for (let index = 0; index < dates.length; index++) {
            dates[index].offsetUnits = index * pieceWidthUnits;
        }

        return {
            dates: dates,
            pieceWidthUnits: pieceWidthUnits,
            totalWidthUnits: pieceWidthUnits * dates.length
        };
    }

    public static build(timeline: ITimelineInfo | ITimeframe, quantization?: Quantization, multiplier?: number): { quantization: Quantization, scale: IScale } {
        if (quantization === undefined || quantization === null) {
            const days = (timeline as ITimelineInfo).duration ?? utils.diffDays(timeline.start, timeline.end);
            if (days <= 14) {
                quantization = Quantization.days;
            }
            else if (days <= 90) {
                quantization = Quantization.weeks;
            }
            else if (days <= 365) {
                quantization = Quantization.months;
            }
            else if (days <= 1095) {
                quantization = Quantization.quarters;
            }
            else {
                quantization = Quantization.years;
            }
        }

        const builder = TimelineScale.map[quantization];
        return { quantization, scale: TimelineScale.applyOffsets(builder.pieceWidthUnits * (multiplier ?? 1), builder.handler(timeline.start, timeline.end)) };
    }
}