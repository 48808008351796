import './GroupedDropdown.css';
import * as React from "react";
import { Dropdown, IDropdownOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { EntityChevron } from "../extensibleEntity/EntityGroupHeader";
import { Dictionary } from '../../../entities/common';

export type DropdownOptionsGroup = {
    text: string;
    items: IDropdownOption[];
};
type GroupOrOption = DropdownOptionsGroup | IDropdownOption;
export type GroupedDropdownOptions = (GroupOrOption)[];

export const GroupedDropdown = (props: {
    selectedKey: any;
    disabled: boolean;
    onGetErrorMessage: () => string | undefined;
    groupedOptions: GroupedDropdownOptions;
    onChange: (e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => void;
}) => {
    const { selectedKey, groupedOptions, disabled, onChange, onGetErrorMessage } = props;

    const selectedGroup = React.useMemo(() => groupedOptions.find(_ => (_ as DropdownOptionsGroup)?.items?.find(_ => _.key === selectedKey)), [groupedOptions, selectedKey]);
    const [expandedGroups, setExpandedGroups] = React.useState<Dictionary<boolean>>(selectedGroup ? { [selectedGroup.text]: true} : {});

    const options = React.useMemo(() => {
        const options: IDropdownOption[] = [];
        groupedOptions.forEach((option: GroupOrOption) => {
            if ((option as DropdownOptionsGroup).items) {
                const isGroupExpanded = expandedGroups[option.text];

                options.push({ key: option.text, text: option.text, itemType: SelectableOptionMenuItemType.Header });
                ((option as DropdownOptionsGroup).items as IDropdownOption[]).forEach((subOption: IDropdownOption) => {
                    options.push({ key: subOption.key, text: subOption.text, hidden: !isGroupExpanded });
                });
            }
            else {
                options.push({ key: (option as IDropdownOption).key, text: (option as IDropdownOption).text });
            }
        });
        return options;
    }, [expandedGroups, groupedOptions]);

    const groupKeysMap = groupedOptions.filter(_ => _ as DropdownOptionsGroup).reduce((acc, curr) => ({ ...acc, [curr.text]: true }), {} as { [key: string]: boolean });

    const handleHeaderClick = (optionKey: string) => {
        setExpandedGroups({ ...expandedGroups, [optionKey]: !expandedGroups[optionKey] });
    };

    const _onRenderTitle = (props?: IDropdownOption[]): JSX.Element | null => {
        if (!props || !Array.isArray(props)) {
            return <span />;
        }
        return selectedGroup
            ? <>{`${selectedGroup?.text} - ${props[0].text}`}</>
            : <>{props[0].text}</>;
    }

    return (
        <Dropdown
            selectedKey={selectedKey}
            options={options}
            calloutProps={{ className: "grouped-dropdown" }}
            disabled={disabled}
            errorMessage={onGetErrorMessage()}
            onRenderTitle={_onRenderTitle}
            onRenderOption={(option, defaultRender) => {
                if (option?.key && groupKeysMap[option?.key]) {
                    return (
                        <div className="align-center" onClick={() => handleHeaderClick(option.key as string)}>
                            <EntityChevron
                                title={option.text}
                                isCollapsed={!expandedGroups[option.key]}
                                onClick={() => handleHeaderClick(option.key as string)}
                            />
                            {option.text}
                        </div>
                    );
                }

                return defaultRender!(option);
            }}
            onChange={onChange}
        />
    );
};