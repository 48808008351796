import { StoreHelper } from "../services/storeHelper";
import { ICardSubView, SortDirection } from "../../entities/Metadata";
import { IViewsState, ICardSortOption, DEFAULT_LIST_SUBVIEW } from "../views";
import { nameof } from "../services/metadataService";
import { IRoadmapAttrs } from "./common";

const cardActiveSubView: ICardSubView = {
    id: '4c1beea6-83e0-4841-af0c-081b989bee31',
    name: 'Summary View',
    isBuiltIn: false,
    isNotSaved: false,
    showCosts: false,
    showWork: false
};

// 'Newest on Top' sort option index
const DEFAULT_CARD_SORT_OPTION_INDEX = 2;
const cardSortOptions: ICardSortOption[] = [
    {
        id: "fa2a5ed6-976d-4f42-88d7-959fbaccb0cb",
        orderBy: {
            fieldName: nameof<IRoadmapAttrs>("Name"),
            direction: SortDirection.ASC,
        },
        label: 'Name: A – Z',
        iconName: 'PPMXSortAZ'
    },
    {
        id: "42ef4eb7-851f-43a2-b555-1fc5fe01ae6c",
        orderBy: {
            fieldName: nameof<IRoadmapAttrs>("Name"),
            direction: SortDirection.DESC
        },
        label: 'Name: Z – A',
        iconName: 'PPMXSortZA'
    },
    {
        id: "81ef73fa-f456-4be0-8f60-8fc14bc2ff89",
        orderBy: {
            fieldName: nameof<IRoadmapAttrs>("CreatedDate"),
            direction: SortDirection.DESC,
        },
        label: 'Newest on Top',
        iconName: 'PPMXSortNewest'
    },
    {
        id: "726b9fd7-2a79-46ec-b53e-5f71d6e28c40",
        orderBy: {
            fieldName: nameof<IRoadmapAttrs>("CreatedDate"),
            direction: SortDirection.ASC,
        },
        label: 'Oldest on Top',
        iconName: 'PPMXSortOldest'
    }
];

const DEFAULT_LIST_SORT = {
    fieldName: nameof<IRoadmapAttrs>("Name"),
    direction: SortDirection.ASC
};

export const VIEWS_DEFAULTS: IViewsState = {
    card: {
        subViews: [
            cardActiveSubView
        ],
        sortBy: {
            active: cardSortOptions[DEFAULT_CARD_SORT_OPTION_INDEX],
            options: cardSortOptions
        }
    },
    list: {
        fakeFields: [],
        selectedByDefault: [],
        subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
        sortBy: DEFAULT_LIST_SORT
    },
    timeline: {
        subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
        selectedByDefault: [],
        sortBy: DEFAULT_LIST_SORT
    },
    activeViewType: 'card'
};