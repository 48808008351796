import * as React from 'react';
import { bindActionCreators } from 'redux';
import { PanelType, DefaultButton, MessageBar, MessageBarType, IPanelProps } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { actionCreators } from "../../store/UsersListStore";
import { LicenseUtils, IPermissions, LicenseType, ResourceOperations, toggle, IResourcePermissions, contains } from '../../store/permissions';
import { HelpInfo } from './PermissionsEdit';
import { CommonPermissions, isCommonPermissionChecked, ObjectivesAccessLevel, OperationArg } from './CommonPermissions';
import { ResourcePermissions } from './ResourcePermissions';
import { getResourcePermissionsChecked } from './ResourcePermsEdit';
import { EntityType } from '../../entities/common';
import { LayoutService } from '../utils/LayoutService';
import DirtyPanel from '../common/DirtyPanel';

type OwnProps = {
    ids: string[];
    counters: { [i: number]: number };
    onDismiss: () => void;
}
type ActionProps = {
    userActions: typeof actionCreators;
}
type Props = OwnProps & ActionProps;
const keepCurrentLayoutOption = { key: LayoutService.DefaultLayoutId, text: "Keep current profile view", title: "Keep current profile view" };
const cancelCommandLabel = "Cancel";
const primaryCommandLabel = "Save";

const PermissionsBulkEditPanelImpl = (props: Props) => {
    const [addPermissions, setAddPermissions] = React.useState<IPermissions>(LicenseUtils.buildEmptyPermissions(LayoutService.DefaultLayoutId));
    const [removePermissions, setRemovePermissions] = React.useState<IPermissions>(LicenseUtils.buildEmptyPermissions(LayoutService.DefaultLayoutId));
    const [isDirty, setIsDirty] = React.useState(false);

    const { counters, userActions, onDismiss } = props;
    const license = counters[LicenseType.Viewer] ? LicenseType.Viewer : LicenseType.Regular;
    const regularPluralSuffix = counters[LicenseType.Regular] === 1 ? '' : 's';
    const viewerPluralSuffix = counters[LicenseType.Viewer] === 1 ? '' : 's';

    return <DirtyPanel
        className="user-edit"
        isLightDismiss
        type={PanelType.custom}
        customWidth="400px"
        isOpen
        isDirty={isDirty}
        primaryCommandLabel={primaryCommandLabel}
        cancelCommandLabel={cancelCommandLabel}
        onDismiss={onDismiss}
        onRenderHeader={() => <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Bulk edit permissions</p>
            <div className='ms-Panel-secondaryText'>Define permissions that will be applied to
                {counters[LicenseType.Regular] && counters[LicenseType.Viewer]
                    ? ` ${counters[LicenseType.Regular]} selected User${regularPluralSuffix} and ${counters[LicenseType.Viewer]} Team Member${viewerPluralSuffix}`
                    : ''}
                {counters[LicenseType.Regular] && !counters[LicenseType.Viewer]
                    ? ` ${counters[LicenseType.Regular]} selected User${regularPluralSuffix}`
                    : ''}
                {!counters[LicenseType.Regular] && counters[LicenseType.Viewer]
                    ? ` ${counters[LicenseType.Viewer]} selected Team Member${viewerPluralSuffix}`
                    : ''}
            </div>
        </div>}
        onRenderFooterContent={() => <div className="commands">
            <DefaultButton primary text={primaryCommandLabel} iconProps={{ iconName: "Save" }} onClick={_save} />
            <DefaultButton text={cancelCommandLabel} onClick={props.onDismiss} />
        </div>}>
        <div className="permissions-edit">
            <div>
                <HelpInfo>
                    If no changes are made, permissions for selected users will remain as is. Change the selection to grant / revoke permissions for selected users.
                </HelpInfo>
                {counters[LicenseType.Regular] && license !== LicenseType.Regular
                    && <MessageBar messageBarType={MessageBarType.warning}>The list of permissions is limited here according to the Team Members license.</MessageBar>}
                <CommonPermissions
                    licenseType={license}
                    disabled={_ => _.objectives === ObjectivesAccessLevel.View && contains(addPermissions.objective.global, ResourceOperations.Update)}
                    indeterminate={arg => {
                        const isAdd = isCommonPermissionChecked(arg, addPermissions);
                        const isRemove = isCommonPermissionChecked(arg, removePermissions);
                        return !isAdd && !isRemove;
                    }}
                    checked={arg => isCommonPermissionChecked(arg, addPermissions)}
                    onChange={(arg) => {
                        const isAdd = isCommonPermissionChecked(arg, addPermissions);
                        const isRemove = isCommonPermissionChecked(arg, removePermissions);

                        setAddPermissions({ ...addPermissions, ..._toggleCommonPermissions(arg, addPermissions, !isAdd && !isRemove) });
                        setRemovePermissions({ ...removePermissions, ..._toggleCommonPermissions(arg, removePermissions, isAdd && !isRemove) });
                        setIsDirty(true);
                    }}
                />
                <ResourcePermissions
                    license={license}
                    permissions={addPermissions}
                    extraLayoutOption={keepCurrentLayoutOption}
                    onChange={(change, entityType) => {
                        const addResourcePerms = _getResourcePemissions(addPermissions, entityType);
                        const removeResourcePerms = _getResourcePemissions(removePermissions, entityType);
                        const changedPerms = _getResourcePemissions(change, entityType);
                        if (!addResourcePerms || !removeResourcePerms || !changedPerms) {
                            return;
                        }

                        addResourcePerms.layoutId = changedPerms.layoutId;

                        const availableOperations = _getAvailableOperations(entityType);
                        const operations = [ResourceOperations.Create, ResourceOperations.Read, ResourceOperations.Collaborate, ResourceOperations.Update];
                        for (const resourceOperations of operations) {
                            const isAdd = getResourcePermissionsChecked(addResourcePerms)[resourceOperations];
                            const isRemove = getResourcePermissionsChecked(removeResourcePerms)[resourceOperations];
                            const changed = getResourcePermissionsChecked(changedPerms)[resourceOperations];
                            if (isAdd === changed) {
                                continue;
                            }

                            const shouldAdd = !!(!isAdd && !isRemove);
                            const shouldRemove = !!(isAdd && !isRemove);
                            addResourcePerms.global = toggle(addResourcePerms.global, resourceOperations, shouldAdd);
                            removeResourcePerms.global = toggle(removeResourcePerms.global, resourceOperations, shouldRemove);

                            if (shouldRemove && contains(availableOperations, resourceOperations)) {
                                removeResourcePerms.global = toggle(removeResourcePerms.global, _getUpperOperations(resourceOperations), true);
                            }
                        }

                        setAddPermissions({ ...addPermissions });
                        setRemovePermissions({ ...removePermissions });
                        setIsDirty(true);
                    }}
                    hideGranularEntityPermissions
                    disabled={(entityType, resourceOperations) => {
                        const removeResourcePerms = _getResourcePemissions(removePermissions, entityType);
                        if (!removeResourcePerms) {
                            return undefined;
                        }

                        const removePerms = getResourcePermissionsChecked(removeResourcePerms);

                        const availableOperations = _getAvailableOperations(entityType);
                        if (resourceOperations === ResourceOperations.Update) {
                            return contains(availableOperations, ResourceOperations.Collaborate) && removePerms[ResourceOperations.Collaborate]
                                || contains(availableOperations, ResourceOperations.Read) && removePerms[ResourceOperations.Read];
                        }

                        if (resourceOperations === ResourceOperations.Collaborate) {
                            return contains(availableOperations, ResourceOperations.Read) && removePerms[ResourceOperations.Read];
                        }
                        return undefined;
                    }}
                    indeterminate={(entityType, resourceOperations) => {
                        const addResourcePerms = _getResourcePemissions(addPermissions, entityType);
                        const removeResourcePerms = _getResourcePemissions(removePermissions, entityType);
                        if (!addResourcePerms || !removeResourcePerms) {
                            return undefined;
                        }

                        const isAdd = getResourcePermissionsChecked(addResourcePerms, true)[resourceOperations];
                        const isRemove = getResourcePermissionsChecked(removeResourcePerms)[resourceOperations];
                        return !isAdd && !isRemove;
                    }}
                />
            </div>
        </div>
    </DirtyPanel>;

    function _getAvailableOperations(entityType: EntityType): ResourceOperations {
        switch (entityType) {
            case EntityType.Roadmap:
                return ResourceOperations.Create | ResourceOperations.Read | ResourceOperations.Update;
            case EntityType.Challenge:
                return ResourceOperations.Create | ResourceOperations.Collaborate | ResourceOperations.Update;
            default:
                return ResourceOperations.Create | ResourceOperations.Read | ResourceOperations.Collaborate | ResourceOperations.Update;
        }
    }

    function _getUpperOperations(resourceOperations: ResourceOperations) {
        let result = ResourceOperations.None;
        if (contains(resourceOperations, ResourceOperations.Read)) {
            result |= ResourceOperations.Collaborate | ResourceOperations.Update;
        }
        if (contains(resourceOperations, ResourceOperations.Collaborate)) {
            result |= ResourceOperations.Update;
        }
        return result;
    }

    function _getResourcePemissions(permissions: Partial<IPermissions>, entityType: EntityType): IResourcePermissions | undefined {
        switch (entityType) {
            case EntityType.Portfolio:
                return permissions.portfolio;
            case EntityType.Program:
                return permissions.program;
            case EntityType.Project:
                return permissions.project;
            case EntityType.Roadmap:
                return permissions.roadmap;
            case EntityType.Challenge:
                return permissions.challenge;
            default:
                return undefined;
        }
    }

    function _toggleCommonPermissions(arg: OperationArg, permissions: IPermissions, c?: boolean): Partial<IPermissions> {
        const change: Partial<IPermissions> = {};
        if (arg.operation !== undefined) {
            change.common = toggle(permissions.common, arg.operation, c);
        }
        if (arg.objectives) {
            change.objective = {
                ...permissions.objective,
                global: toggle(permissions.objective.global,
                    arg.objectives === ObjectivesAccessLevel.View
                        ? ResourceOperations.Create | ResourceOperations.Read | ResourceOperations.Collaborate
                        : ResourceOperations.Create | ResourceOperations.Read | ResourceOperations.Collaborate | ResourceOperations.Update,
                    c)
            };
        }
        return change;
    }

    function _save() {
        userActions.bulkSavePermissions(props.ids, addPermissions, removePermissions);
        onDismiss();
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(actionCreators, dispatch)
    }
}
export const PermissionsBulkEditPanel = connect(null, mergeActionCreators)(PermissionsBulkEditPanelImpl);