import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { KeyDate, KeyDateTypeMap, buildKeyDateIconName, IKeyDateAttrs, KeyDateType } from "../../../entities/Subentities";
import { ProjectInfo } from "../../../store/ProjectsListStore";
import { Link } from 'office-ui-fabric-react';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import CategoryColorStatusView from '../../views/list/columns/CategoryColorStatusView';
import { Field, getLabel } from '../../../entities/Metadata';
import { FormatDate } from '../../utils/common';
import { entityLogoConfig, EntityType, mapServerEntityType, StatusCategory } from '../../../entities/common';
import { FormatterLogoItems } from '../formatters/PortfolioFormatter';
import Logo from '../Logo';
import { Portfolio } from '../../../store/PortfoliosListStore';
import { Program } from '../../../store/ProgramsListStore';
import EntityName from '../../views/list/columns/EntityName';
import * as StatusDescriptorFactory from '../../../entities/StatusDescriptorFactory';

type OwnProps = {
    keyDate: KeyDate,
    createdFromType: EntityType;
    project?: ProjectInfo,
    portfolio?: Portfolio,
    program?: Program,
    onClick?: () => void,
    showFrom?: boolean
};
type StoreProps = {
    keyDateFields: Field[];
    taskFields: Field[];
};
type Props = OwnProps & StoreProps;

const KeyDateTooltipContent = (props: Props) => {
    const { keyDateFields, taskFields, project, keyDate, portfolio, program, onClick, showFrom } = props;
    
    if (!keyDate) {
        return null;
    }
    
    const statusDescriptor = createStatusDescriptor();
    if (!statusDescriptor) {
        return null;
    }
        
    const statusOption = statusDescriptor.getOptionOrDefault(keyDate.attributes.Status, StatusCategory.NA);
    const iconName = buildKeyDateIconName(keyDate.attributes.Type, statusOption.category);
    const parentEntity = project
        ? { id: project.id, name: project.attributes.Name, imageId: project.imageId, entityType: EntityType.Project }
        : program
            ? { id: program.id, name: program.attributes.Name, imageId: program.imageId, entityType: EntityType.Program }
            : portfolio
                ? { id: portfolio.id, name: portfolio.attributes.Name, imageId: portfolio.imageId, entityType: EntityType.Portfolio }
                : undefined;
    const keyDateUrl = parentEntity ? `/${parentEntity.entityType}/${parentEntity?.id}?keydate=${keyDate.id}` : undefined;

    return (
        <div className={`timeline-tooltip ${onClick ? 'clickable' : ''}`} onClick={onClick}>
            <div className="header">
                <EntityName
                    name={keyDate.attributes.Name}
                    isTimelineView={true}
                    url={keyDateUrl}
                    replaceCurrentUrl={true}
                    className={`${keyDate.attributes.IsComplete ? 'crossed-out' : ''}`}
                    iconName={iconName}
                    sourceType={EntityName._buildSourceType(keyDate, "Key Date")} />
                <CategoryColorStatusView
                    statusOption={statusOption}
                />
            </div>

            <div className="content">
                {renderField("Type", <div className="type">{KeyDateTypeMap[keyDate.attributes.Type]}</div>)}
                {renderField("Date", <div>{FormatDate(keyDate.attributes.Date)}</div>)}
                {renderField("IsComplete",
                    keyDate.attributes.IsComplete
                        ? <div className="completed">yes</div>
                        : <div className="non-completed">no</div>)}
                {parentEntity && <div className="item align-center">
                    <div className="label">{entityLogoConfig[parentEntity.entityType]?.label}</div>
                    <FormatterLogoItems
                        entityType={parentEntity.entityType}
                        entities={[parentEntity]}
                        cssClass={entityLogoConfig[parentEntity.entityType]?.cssClass}
                        target="_blank"
                        withNavigation />
                </div>}
                {showFrom && keyDate.sourceType === SourceType.Ppmx && !!keyDate.externalData["ImportedFromId"] &&
                    <div className="item align-center">
                        <div className="label">From</div>
                        <Link href={keyDate.externalData["NameLink"]} className="pf-box" target="_blank">
                            <Logo className={entityLogoConfig[mapServerEntityType[keyDate.externalData["ImportedFromType"]]!]?.cssClass}
                                text={keyDate.externalData["ImportedFromName"]} />
                            <span className="overflow-text">{keyDate.externalData["ImportedFromName"]}</span>
                        </Link>
                    </div>}
            </div>
        </div>
    );

    function createStatusDescriptor() {
        if (keyDate.attributes.Type === KeyDateType.Milestone) {
            if (props.createdFromType === EntityType.Task) {
                return StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Task, taskFields);
            }
            if (props.createdFromType === EntityType.KeyDate) {
                return StatusDescriptorFactory.createStatusDescriptorFor(EntityType.KeyDate, keyDateFields);
            }
            return undefined;
        }
        return StatusDescriptorFactory.createStatusDescriptorFor(EntityType.KeyDate, keyDateFields);
    }

    function renderField(name: keyof IKeyDateAttrs, valueElement: JSX.Element): JSX.Element | null {
        const field = findFieldWithName(name);
        if (!field) {
            return null;
        }

        return <div className="item align-center">
            <div className="label">{getLabel(field)}</div>
            {valueElement}
        </div>;
    }

    function findFieldWithName(name: string) {
        return props.keyDateFields.find(_ => _.name === name);
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const keyDateFields = state.fields[EntityType.KeyDate];
    const taskFields = state.fields[EntityType.Task];
    return {
        keyDateFields: keyDateFields.allIds.map(_ => keyDateFields.byId[_]),
        taskFields: taskFields.allIds.map(_ => taskFields.byId[_]),
    };
}

export default connect(mapStateToProps)(KeyDateTooltipContent);