import * as React from 'react';
import { connect } from 'react-redux';
import { EntityType, Dictionary } from '../../entities/common';
import { CreatePanel } from '../common/SubentityPanel';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../store/RoadmapsListStore';
import * as Metadata from '../../entities/Metadata';
import { namesof } from '../../store/services/metadataService';
import { ISubentity } from '../../entities/Subentities';
import { validators, rendersBuilder } from '../field/Fields';
import { IRoadmapAttrs, RoadmapStage } from '../../store/roadmap/common';
import { UserState } from '../../store/User';

export interface RoadmapSubentityWrapper extends ISubentity {
    id: string;
    attributes: IRoadmapAttrs & Dictionary<any>;
}

interface OwnProps {
    onDismiss: () => void;
    openOnComplete?: boolean;
}

type StoreProps = {
    fields: Metadata.Field[];
    user: UserState;
};

type Props = StoreProps & OwnProps & typeof actionCreators;

class RoadmapCreation extends React.Component<Props> {
    public render() {
        return <CreatePanel<RoadmapSubentityWrapper>
            subentityTypeLabel="Roadmap"
            subentityType={EntityType.Roadmap}
            displayFields={namesof<IRoadmapAttrs>(['Name', 'Manager', 'Description', 'StartDate', 'FinishDate', 'Tags'])}
            fields={this.props.fields}
            customFieldValidatorBuilder={validators}
            uiControlElementsCustomRender={rendersBuilder()}
            onComplete={this._onComplete}
            onDismiss={this.props.onDismiss}
            buildNewEntity={this._buildNewEntity}
        />;
    }

    private _onComplete = (entity: RoadmapSubentityWrapper) => {
        const { createRoadmap, openOnComplete } = this.props;
        createRoadmap(entity, openOnComplete);
    }

    private _buildNewEntity = () => {
        const { user } = this.props;
        return {
            attributes: {
                Manager: [{ id: user.id, fullName: user.name, imageId: user.imageId }],
                Stage: RoadmapStage.Draft
            }
        } as RoadmapSubentityWrapper;
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Roadmap];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]).filter(_ => !_.isReadonly),
        user: state.user
    };
}

export default connect(mapStateToProps, actionCreators)(RoadmapCreation);