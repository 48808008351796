import { IConnectionsState, IApiResult, IListStore, IImportResult, IImportProjectMap, IImportResourceMap, IFieldMapping, MapConfig, IResourceSourceData, TaskImportSettings, 
    ExternalFieldInfo, IEntityStore, ConnectionsState } from "./common";
import * as Metadata from "../../entities/Metadata";
import { get, post, remove } from "../../fetch-interceptor";
import { defaultCatch, catchApiError } from "../utils";
import { AppThunkAction, AppThunkAction as IAppThunkAction } from "../index";
import { Reducer, Action } from 'redux';
import { ProjectsImportActions, Utils, ResourcesImportActions } from "./ImportStore";
import { IDeletionResult } from '../services/storeHelper';
import { RemovedPortfoliosSourceInfosAction } from '../PortfoliosListStore';
import { RemovedProjectsSourceInfosAction } from '../ProjectsListStore';
import { RemovedResourcesSourceInfosAction } from '../ResourcesListStore';

export enum P4WFieldType {
    Boolean = 'BooleanType',
    Custom = 'CustomType',
    File = 'FileType',
    MultiSelectPicklist = 'MultiSelectPicklistType',
    Image = 'ImageType',
    EntityName = 'EntityNameType',
    ManagedProperty = 'ManagedPropertyType',
    BigInt = 'BigIntType',
    Virtual = 'VirtualType',
    CalendarRules = 'CalendarRulesType',
    String = 'StringType',
    Status = 'StatusType',
    Uniqueidentifier = 'UniqueidentifierType',
    Picklist = 'PicklistType',
    Customer = 'CustomerType',
    DateTime = 'DateTimeType',
    State = 'StateType',
    Double = 'DoubleType',
    Integer = 'IntegerType',
    Decimal = 'DecimalType',
    Memo = 'MemoType',
    Money = 'MoneyType',
    //Owner = 'OwnerType', it is remapped to User type
    PartyList = 'PartyListType',
    Lookup = 'LookupType',
    //fake fields
    Type = 'Type',
    Label = 'Label',
    Resource = 'Resource',
    User = 'User',
    Bucket = 'Bucket'
}

export const P4WFieldToPpmxFieldsMap: { [i: string]: MapConfig } = {
    [P4WFieldType.BigInt]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Decimal], label: "Big Integer" },
    [P4WFieldType.Integer]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Decimal, Metadata.FieldType.Integer, Metadata.FieldType.Flag], label: "Integer" },
    [P4WFieldType.Decimal]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Decimal, Metadata.FieldType.Integer], label: "Decimal" },
    [P4WFieldType.Double]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Decimal, Metadata.FieldType.Integer], label: "Double" },
    [P4WFieldType.Money]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Decimal], label: "Money" },
    [P4WFieldType.DateTime]: { types: [Metadata.FieldType.Date, Metadata.FieldType.DateTime], label: "Date" },

    [P4WFieldType.Resource]: { types: [Metadata.FieldType.User, Metadata.FieldType.Resource], label: "Resource" },
    [P4WFieldType.Label]: { types: [Metadata.FieldType.Text], label: "Label" },
    [P4WFieldType.Memo]: { types: [Metadata.FieldType.Text], label: "Memo" },
    [P4WFieldType.Bucket]: { types: [Metadata.FieldType.Text], label: "Bucket" },
    [P4WFieldType.String]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Hyperlink], label: "String" },
    [P4WFieldType.Boolean]: { types: [Metadata.FieldType.Flag], label: "Boolean" },
    [P4WFieldType.Type]: { types: [Metadata.FieldType.Text], label: "Type" },
    [P4WFieldType.Uniqueidentifier]: { types: [Metadata.FieldType.Text], label: "Uniqueidentifier" },
    [P4WFieldType.User]: { types: [Metadata.FieldType.User, Metadata.FieldType.Resource], label: "User" },

    [P4WFieldType.Picklist]: { types: [Metadata.FieldType.Text], label: "PickList"},
    [P4WFieldType.Lookup]: { types: [Metadata.FieldType.Text, Metadata.FieldType.Resource, Metadata.FieldType.User], label: "Lookup" },
    [P4WFieldType.MultiSelectPicklist]: { types: [Metadata.FieldType.Text], label: "Multiple choice" },

    [P4WFieldType.State]: { types: [Metadata.FieldType.Text], label: "State" },
    [P4WFieldType.Status]: { types: [Metadata.FieldType.Text], label: "Status" },
}

export interface IP4WResourceLinkInfo extends IResourceSourceData {
    resourceId: string,
    email: string
}

export interface IP4WConnectionState extends IConnectionsState {
    refreshInfo: IConnectionRefreshInfo | null;
    createdConnectionId?: string;
}

export interface IConnectionRefreshInfo {
    url: string;
    account: string;
    accountTitle: string;
    token: string;
    pssToken?: string;
}

export interface IP4WResource {
    id: string;
    displayName: string;
    email: string;
    isAlreadyLinked: boolean;
}

export interface IP4WResourceLinkInfo extends IResourceSourceData {
    id: string;
    displayName: string;
    email: string;
}

export interface IP4WProject {
    id: string;
    name: string;
    isAlreadyLinked: boolean;
}

export interface P4WState {
    connections: IP4WConnectionState;
    projects: IListStore<IP4WProject>;
    resources: IListStore<IP4WResource>;
    taskImportSettings: IEntityStore<TaskImportSettings>,
    projectImportSettings: IEntityStore<IFieldMapping[]>;
    projectFields: IEntityStore<ExternalFieldInfo[]>;
    tasksFields: IEntityStore<ExternalFieldInfo[]>;
}

export interface IConnectionConfiguration {
    taskImportSettings: TaskImportSettings;
    projectImportSettings: IFieldMapping[];
}

export type LoadConnections = { type: "P4W_LOAD_CONNECTIONS" }
export type ReceivedConnections = { type: "P4W_RECEIVED_CONNECTIONS"; connections: Metadata.IConnectionInfo[]; }
type ConnectionCreatingOrRefreshing = { type: "P4W_CONNECTION_CREATING_OR_REFRESHING", id?: string }
type ConnectionCreatedOrRefreshed = { type: "P4W_CONNECTION_CREATED_OR_REFRESHED", connection: Metadata.IConnectionInfo, isRefreshing: boolean }
type RemovedConnection = { type: "P4W_CONNECTION_REMOVED", id: string }
type ReceivedConnectionRefreshInfo = { type: "P4W_RECEIVED_CONNECTION_REFRESH_INFO", info: IConnectionRefreshInfo }
type ConnectionOperationError = { type: "P4W_CONNECTION_OPERATION_ERROR", error: string | null }
type LoadConnectionConfiguration = { type: "P4W_LOAD_CONNECTION_CONFIGURATION" }
type UpdateConnectionConfiguration = { type: "P4W_UPDATE_CONNECTION_CONFIGURATION" }
type ReceivedConnectionConfiguration = { type: "P4W_RECEIVED_CONNECTION_CONFIGURATION", config: IConnectionConfiguration }
type LoadConnectionConfigurationError = { type: "P4W_LOAD_CONNECTION_CONFIGURATION_ERROR", error: string | null }

type VerifyConnection = { type: 'P4W_VERIFY_CONNECTION', connectionId: string }
type ConnectionVerified = { type: 'P4W_CONNECTION_VERIFIED', connectionId: string }
type ConnectionVerificationError = { type: 'P4W_CONNECTION_VERIFICATION_ERROR', connectionId: string, error: string | null }

type ConnectionAction = LoadConnections
    | ReceivedConnections
    | ConnectionCreatingOrRefreshing
    | ConnectionCreatedOrRefreshed
    | ReceivedConnectionRefreshInfo
    | RemovedConnection
    | ConnectionOperationError
    | VerifyConnection
    | ConnectionVerified
    | ConnectionVerificationError;

type LoadProjects = { type: "P4W_LOAD_PROJECTS" }
type ReceivedProjects = { type: "P4W_RECEIVED_PROJECTS", projects: IP4WProject[] }

type LoadProjectFields = { type: "P4W_LOAD_PROJECT_FIELDS" }
type ReceivedProjectFields = { type: "P4W_RECEIVED_PROJECT_FIELDS", fields: ExternalFieldInfo[] }
type SetProjectFieldsError = { type: "P4W_SET_PROJECT_FIELDS_ERROR", error: string | null }
type LoadTasksFields = { type: "P4W_LOAD_TASKS_FIELDS" }
type ReceivedTasksFields = { type: "P4W_RECEIVED_TASKS_FIELDS", fields: ExternalFieldInfo[] }
type SetTasksFieldsError = { type: "P4W_SET_TASKS_FIELDS_ERROR", error: string | null }

type LoadResources = { type: "P4W_LOAD_RESOURCES" }
type ReceivedResources = { type: "P4W_RECEIVED_RESOURCES", resources: IP4WResource[] }
type SetProjectsError = { type: "P4W_SET_PROJECTS_ERROR", error: string | null }
type SetResourcesError = { type: "P4W_SET_RESOURCES_ERROR", error: string | null }
type EntitiesAction = LoadProjects | ReceivedProjects | LoadResources | ReceivedResources | SetProjectsError | SetResourcesError
    | LoadTasksFields | ReceivedTasksFields | SetTasksFieldsError
    | LoadProjectFields | ReceivedProjectFields | SetProjectFieldsError
    | LoadConnectionConfiguration | UpdateConnectionConfiguration | ReceivedConnectionConfiguration | LoadConnectionConfigurationError;

type KnownAction = ConnectionAction | EntitiesAction;

export interface IP4WConnectionInfo {
    id?: string;
    url: string;
    account?: string;
    name?: string;
    token?: string;
    pssToken?: string;
}

export const actionCreators = {
    loadConnections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_LOAD_CONNECTIONS' });
        get<Metadata.IConnectionInfo[]>(`api/integration/p4w/connection`)
            .then(_ => dispatch({ type: 'P4W_RECEIVED_CONNECTIONS', connections: _ }))
            .catch(defaultCatch(dispatch));
    },
    createOrRefreshConnection: (data: IP4WConnectionInfo): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_CONNECTION_CREATING_OR_REFRESHING' });
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/p4w/connection`, data)
            .then(_ => {
                if (_.errorMessage) {
                    dispatch({ type: 'P4W_CONNECTION_OPERATION_ERROR', error: _.errorMessage });
                } else {
                    dispatch({ type: 'P4W_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: !!data.id });
                }
            })
            .catch(defaultCatch(dispatch));
    },
    renameConnection: (connectionId: string, title: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IApiResult<Metadata.IConnectionInfo>>(`api/integration/p4w/connection/${connectionId}/rename`, { title })
            .then(_ => dispatch({ type: 'P4W_CONNECTION_CREATED_OR_REFRESHED', connection: _.data, isRefreshing: true }))
            .catch(defaultCatch(dispatch));
    },
    removeConnection: (id: string, callback?: () => void):
        AppThunkAction<KnownAction | RemovedPortfoliosSourceInfosAction | RemovedProjectsSourceInfosAction | RemovedResourcesSourceInfosAction> => (dispatch, getState) => {
            dispatch({ type: 'P4W_CONNECTION_CREATING_OR_REFRESHING' });
            remove<IDeletionResult>(`api/integration/p4w/connection/${id}`)
                .then(_ => {
                    if (_.isDeleted) {
                        dispatch({ type: 'P4W_CONNECTION_REMOVED', id: _.id });
                        dispatch({ type: "REMOVED_PORTFOLIOS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_PROJECTS_SOURCE_INFOS", connectionId: _.id });
                        dispatch({ type: "REMOVED_RESOURCES_SOURCE_INFOS", connectionId: _.id });
                        callback?.();
                    } else {
                        dispatch({ type: 'P4W_CONNECTION_OPERATION_ERROR', error: _.message || null });
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    loadConnectionConfiguration: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_LOAD_CONNECTION_CONFIGURATION' });
        get<IConnectionConfiguration>(`api/integration/p4w/connectionConfiguration/${connectionId}/${projectId}`)
            .then(_ => dispatch({ type: 'P4W_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'P4W_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load Project for Web connection configuration: ${_}` })));
    },
    updateConnectionConfiguration: (connectionId: string, projectId: string, configuration: IConnectionConfiguration): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(({ type: 'P4W_UPDATE_CONNECTION_CONFIGURATION' }));
        post<IConnectionConfiguration>(`api/integration/p4w/connectionConfiguration/${connectionId}/${projectId}`, configuration)
            .then(_ => dispatch({ type: 'P4W_RECEIVED_CONNECTION_CONFIGURATION', config: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'P4W_LOAD_CONNECTION_CONFIGURATION_ERROR', error: `Unable to load Project for Web mapping: ${_}` })));
    },
    loadProjectFields: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_LOAD_PROJECT_FIELDS' });
        get<ExternalFieldInfo[]>(`api/integration/p4w/fields/${connectionId}/project`, { projectId })
            .then(_ => dispatch({ type: 'P4W_RECEIVED_PROJECT_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'P4W_SET_PROJECT_FIELDS_ERROR', error: `Unable to load Project for Web project fields: ${_}` })));
    },
    loadTasksFields: (connectionId: string, projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_LOAD_TASKS_FIELDS' });
        get<ExternalFieldInfo[]>(`api/integration/p4w/fields/${connectionId}/task`, { projectId })
            .then(_ => dispatch({ type: 'P4W_RECEIVED_TASKS_FIELDS', fields: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'P4W_SET_TASKS_FIELDS_ERROR', error: `Unable to load Project for Web task project fields: ${_}` })));
    },
    setError: (error: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_CONNECTION_OPERATION_ERROR', error });
    },
    cleanError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_CONNECTION_OPERATION_ERROR', error: null });
    },
    loadRefreshInfo: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_LOAD_CONNECTIONS' });
        get<IConnectionRefreshInfo>(`api/integration/p4w/connection/${connectionId}/refresh`)
            .then(_ => dispatch({ type: 'P4W_RECEIVED_CONNECTION_REFRESH_INFO', info: _ }))
            .catch(defaultCatch(dispatch));
    },
    loadProjects: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_LOAD_PROJECTS' });
        get<IP4WProject[]>(`api/integration/p4w/projects`, { connectionId })
            .then(_ => dispatch({ type: 'P4W_RECEIVED_PROJECTS', projects: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'P4W_SET_PROJECTS_ERROR', error: `Unable to load Project for the Web projects: ${_}` })));
    },
    loadResources: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_LOAD_RESOURCES' });
        get<IP4WResource[]>(`api/integration/p4w/resources`, { connectionId })
            .then(_ => dispatch({ type: 'P4W_RECEIVED_RESOURCES', resources: _ }))
            .catch(catchApiError(_ => dispatch({ type: 'P4W_SET_RESOURCES_ERROR', error: `Unable to load  Project for the Web resources: ${_}` })));
    },
    importProjects: (connectionId: string, maps: IImportProjectMap<IP4WProject>[]): AppThunkAction<ProjectsImportActions> => (dispatch, getState) => {
        const entityIds = maps.map(_ => _.linkingData.id);
        dispatch({ type: 'IMPORT_PROJECTS_STARTED', entityIds });

        Utils.batchSend(maps,
            batch => post<IImportResult[]>('api/integration/p4w/import/projects', { connectionId, maps: batch })
                .then(_ => dispatch({ type: 'IMPORT_PROJECTS_BATCH_FINISHED', results: _ })))
            .then(() => dispatch({ type: 'IMPORT_PROJECTS_FINISHED' }))
            .catch(defaultCatch(dispatch));
    },
    importResources: (connectionId: string, maps: IImportResourceMap<IP4WResource>[]): AppThunkAction<ResourcesImportActions> => (dispatch, getState) => {
        dispatch({ type: 'IMPORT_RESOURCES_STARTED' });
        Utils.sendByChunks(maps, chunk => post<IImportResult[]>('api/integration/p4w/import/resources', { connectionId, maps: chunk }))
            .then(_ => dispatch({ type: 'IMPORT_RESOURCES_FINISHED', results: _ }))
            .catch(defaultCatch(dispatch));
    },
    verifyConnection: (connectionId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'P4W_VERIFY_CONNECTION', connectionId });
        get(`api/integration/p4w/verify-connection`, { connectionId })
            .then(_ => dispatch({ type: 'P4W_CONNECTION_VERIFIED', connectionId }))
            .catch(catchApiError(_ => dispatch({ type: 'P4W_CONNECTION_VERIFICATION_ERROR', connectionId: connectionId, error: _ })));
    }
}
const initState: P4WState = {
    connections: {
        isLoading: false,
        isProcessing: false,
        data: [],
        refreshInfo: null,
        error: null,
        connectionsVerification: { }
    },
    projects: {
        entities: [],
        isLoading: false,
        error: null
    },
    resources: {
        entities: [],
        isLoading: false,
        error: null
    },
    taskImportSettings: {
        isLoading: false,
        isProcessing: false,
        entities: {},
        error: null
    },
    projectImportSettings: {
        isLoading: false,
        isProcessing: false,
        entities: [],
        error: null
    },
    projectFields: {
        entities: [],
        isLoading: false,
        isProcessing: false,
        error: null,
    },    
    tasksFields: {
        entities: [],
        isLoading: false,
        isProcessing: false,
        error: null,
    },    
}

const connectionsReducer: Reducer<IP4WConnectionState> = (state: IP4WConnectionState, incomingAction: Action) => {
    const action = incomingAction as ConnectionAction;
    switch (action.type) {
        case 'P4W_LOAD_CONNECTIONS':
            {
                return {
                    ...state,
                    isLoading: true,
                    connectionsVerification: { }
                }
            }
        case 'P4W_RECEIVED_CONNECTIONS':
            {
                return {
                    ...state,
                    data: action.connections,
                    isLoading: false
                }
            }
        case 'P4W_CONNECTION_CREATING_OR_REFRESHING':
            {
                return {
                    ...state,
                    isProcessing: true,
                    connectionsVerification: ConnectionsState.remove(state, action.id)
                }
            }
        case "P4W_CONNECTION_CREATED_OR_REFRESHED":
            {
                return {
                    ...state,
                    data: action.isRefreshing
                        ? state.data.map(_ => _.id === action.connection.id ? action.connection : _)
                        : state.data.concat(action.connection),
                    error: null,
                    createdConnectionId: action.isRefreshing ? undefined : action.connection.id,
                    isProcessing: false
                }
            }
        case "P4W_CONNECTION_REMOVED":
            {
                return {
                    ...state,
                    data: state.data.filter(_ => _.id !== action.id),
                    connectionsVerification: ConnectionsState.remove(state, action.id),
                    error: null,
                    isProcessing: false
                }
            }
        case "P4W_RECEIVED_CONNECTION_REFRESH_INFO":
            {
                return {
                    ...state,
                    isLoading: false,
                    refreshInfo: action.info
                }
            }
        case "P4W_CONNECTION_OPERATION_ERROR":
            {
                return {
                    ...state,
                    error: action.error,
                    p4wProjectFields: { isLoading: false, entities: [] },
                    p4wTasksFields: { isLoading: false, entities: [] },
                    isProcessing: false
                }
            }
        case "P4W_VERIFY_CONNECTION":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationStarting(state, action.connectionId)
                }
            }
        case "P4W_CONNECTION_VERIFIED":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFinished(state, action.connectionId)
                }
            }
        case "P4W_CONNECTION_VERIFICATION_ERROR":
            {
                return {
                    ...state,
                    connectionsVerification: ConnectionsState.setVerificationFailed(state, action.connectionId, action.error)
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}

const entitiesReducer: Reducer<P4WState> = (state: P4WState, incomingAction: Action) => {
    const action = incomingAction as EntitiesAction;
    switch (action.type) {
        case 'P4W_LOAD_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'P4W_RECEIVED_PROJECTS':
            {
                return {
                    ...state,
                    projects: {
                        entities: action.projects,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'P4W_SET_PROJECTS_ERROR':
            {
                return {
                    ...state,
                    projects: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'P4W_LOAD_RESOURCES':
            {
                return {
                    ...state,
                    resources: {
                        entities: [],
                        isLoading: true,
                        error: null
                    }
                }
            }
        case 'P4W_RECEIVED_RESOURCES':
            {
                return {
                    ...state,
                    resources: {
                        entities: action.resources,
                        isLoading: false,
                        error: null
                    }
                }
            }
        case 'P4W_SET_RESOURCES_ERROR':
            {
                return {
                    ...state,
                    resources: {
                        entities: [],
                        isLoading: false,
                        error: action.error
                    }
                }
            }
        case 'P4W_LOAD_PROJECT_FIELDS':
            {
                return {
                    ...state,
                    projectFields: {
                        ...initState.projectFields,
                        isLoading: true
                    }
                }
            }
        case 'P4W_RECEIVED_PROJECT_FIELDS':
            {
                return {
                    ...state,
                    projectFields: {
                        ...initState.projectFields,
                        entities: action.fields
                    }
                }
            }
        case 'P4W_SET_PROJECT_FIELDS_ERROR':
            {
                return {
                    ...state,
                    projectFields: {
                        ...initState.projectFields,
                        error: action.error
                    }
                }
            }
        case 'P4W_LOAD_TASKS_FIELDS':
            {
                return {
                    ...state,
                    tasksFields: {
                        ...initState.tasksFields,
                        isLoading: true
                    }
                }
            }
        case 'P4W_RECEIVED_TASKS_FIELDS':
            {
                return {
                    ...state,
                    tasksFields: {
                        ...initState.tasksFields,
                        entities: action.fields
                    }
                }
            }
        case 'P4W_SET_TASKS_FIELDS_ERROR':
            {
                return {
                    ...state,
                    tasksFields: {
                        ...initState.tasksFields,
                        error: action.error
                    }
                }
            }
        case "P4W_LOAD_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    taskImportSettings: { ...initState.taskImportSettings, isLoading: true },
                    projectImportSettings: { ...initState.projectImportSettings, isLoading: true }
                }
            }
        case "P4W_UPDATE_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    taskImportSettings: { ...initState.taskImportSettings, isProcessing: true },
                    projectImportSettings: { ...initState.projectImportSettings, isProcessing: true }
                }
            }
        case "P4W_RECEIVED_CONNECTION_CONFIGURATION":
            {
                return {
                    ...state,
                    taskImportSettings: { ...initState.taskImportSettings, entities: action.config.taskImportSettings, isLoading: false },
                    projectImportSettings: { ...initState.projectImportSettings, entities: action.config.projectImportSettings, isLoading: false },
                }
            }
        case 'P4W_LOAD_CONNECTION_CONFIGURATION_ERROR':
            {
                return {
                    ...state,
                    taskImportSettings: { ...initState.taskImportSettings, error: action.error, isLoading: false },
                    projectImportSettings: { ...initState.projectImportSettings, error: action.error, isLoading: false },
                }
            }
        default:
            const exhaustiveCheck: never = action;
    }
    return state;
}

export const reducer: Reducer<P4WState> = (state: P4WState = initState, incomingAction: Action) => {
    return {
        ...entitiesReducer(state, incomingAction),
        connections: connectionsReducer(state.connections, incomingAction)
    }
}