import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from "../../../store/index";
import { IProjectInfo, ProjectInfo } from '../../../store/ProjectsListStore';
import { Dictionary, EntityType } from '../../../entities/common';
import { FormatterLogoItems } from './PortfolioFormatter';
import { PPMFeatures, Subscription } from '../../../store/Tenant';

type OwnProps = {
    project?: ProjectInfo | IProjectInfo | IProjectInfo[];
    withNavigation?: boolean;
    target?: string;
};
type StateProps = {
    projectsById: Dictionary<ProjectInfo>;
    subscription: Subscription;
}
type Props = OwnProps & StateProps;

class ProjectFormatter extends React.PureComponent<Props>{
    constructor(props: Props) {
        super(props);
        this.state = { showAll: false };
    }
    render() {
        const { project, withNavigation, projectsById, target, subscription } = this.props;

        if (!project) {
            return null;
        }
        const entities =
            !!(project as ProjectInfo).attributes
                ? [{ id: (project as ProjectInfo).id, name: (project as ProjectInfo).attributes.Name }]
                : (Array.isArray(project) ? project : [project as IProjectInfo])
                    .map(_ => ({ ..._, imageId: projectsById[_.id]?.imageId }));

        return <FormatterLogoItems
            entityType={EntityType.Project}
            entities={entities}
            withNavigation={withNavigation && Subscription.contains(subscription, PPMFeatures.PortfolioManagement)}
            target={target}
            cssClass="proj-logo" />
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        projectsById: state.projectsList.byId,
        subscription: state.tenant.subscription
    }
}

export default connect(mapStateToProps, {})(ProjectFormatter);