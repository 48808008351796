import * as React from 'react';
import { ISourceInfo } from '../../../../entities/common';
import { IDropdownOption, Dropdown, IDropdownProps, IRenderFunction, IconButton } from 'office-ui-fabric-react';
import { SourceTypeMap, SourceType } from '../../../../store/ExternalEpmConnectStore';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { UserState } from '../../../../store/User';
import { CommonOperations, contains } from '../../../../store/permissions';
import ImportKeyDateSettings, { supported } from './ImportKeyDateSettings';
import { suppressEvent } from '../../../utils/common';
import Link from '../../../common/Link';

type OwnProps = {
    selected: ISourceInfo,
    sources: ISourceInfo[],
    onChange: (connection: ISourceInfo) => void,
    helpLinks?: { [key in SourceType]?: string | undefined },
}
type StateProps = { user: UserState }
type Props = OwnProps & StateProps;

const ConnectionSelect = (props: Props) => {
    const [isConfigure, setIsConfigure] = React.useState(false);

    return <>
        <Dropdown label='Connection:'
            className="connections-select"
            selectedKey={props.selected.connectionId}
            options={props.sources.map(_ => ({ key: _.connectionId, text: SourceTypeMap[_.type], data: _ }))}
            onRenderCaretDown={(dpprops?: IDropdownProps, defaultRender?: IRenderFunction<IDropdownProps>) => {
                if (!props || !defaultRender) {
                    return null;
                }
                const canManage = contains(props.user.permissions.common, CommonOperations.ConnectionManage);
                const helpLink = props.helpLinks?.[props.selected.type];
                return <>
                    {!!~supported.indexOf(props.selected.type) && canManage &&
                        <IconButton
                            className="connection-settings"
                            title="Key Dates Import Settings"
                            iconProps={{ iconName: "Settings" }}
                            onClick={suppressEvent(_ => setIsConfigure(true))} />}
                    {helpLink &&
                        <span onClick={e => e.stopPropagation()}>
                            <Link href={helpLink} target="_blank">
                                <IconButton iconProps={{ iconName: "Help" }} />
                            </Link>
                        </span>}
                    {defaultRender(dpprops)}
                </>
            }}
            onChange={(e: any, option?: IDropdownOption) => props.onChange(option!.data as ISourceInfo)} />
        {
            isConfigure && !!props.selected && <ImportKeyDateSettings
                sourceInfo={props.selected}
                onDismiss={() => setIsConfigure(false)} />
        }
    </>
}

function mapStateToProps(state: ApplicationState) {
    return { user: state.user };
}

export default connect(mapStateToProps, {})(ConnectionSelect);