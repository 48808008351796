import * as React from 'react';
import { IErrorProps } from '../store/ErrorStore';

type OwnProps = {
    className?: string;
    onButtonsRender?: () => JSX.Element | null;
    onIconRender?: () => JSX.Element | null;
    onRenderFooter?: () => JSX.Element | null;
    children?: React.ReactNode;
}

type Props = OwnProps & IErrorProps;

const ErrorPage = (props: Props) => {
    const { className, title, description, children } = props;
    return <div className={`error-page ${className || ''}`}>
        <div className='error-container'>
            <div className="error">
                {
                    props.onIconRender
                        ? props.onIconRender()
                        : <div className="error-icon-container"><span className="error-icon"></span></div>
                }
                <div className="error-title">{title}</div>
                {description && <div className="error-description">{description}</div>}
                {children && <div className="error-description">{children}</div>}
                {props.onButtonsRender?.()}
            </div>
        </div>
        {props.onRenderFooter && <div className='error-page-footer'>{props.onRenderFooter()}</div>}
    </div>;
}

export default ErrorPage;