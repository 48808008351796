import { get, post, remove } from './../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { RouterAction } from 'react-router-redux';
import { IEntityStore, StoreHelper, IDeletionResult } from './services/storeHelper';
import { IUserInfo, Impact } from "../entities/common";
import { defaultCatch } from "./utils";

export interface StrategicPriority {
    id: string;
    name: string;
    description: string;
    importance: Impact;
    createdBy?: IUserInfo;
    isActive: boolean;
    isEditable: boolean;
}

export interface IStrategicPriorityInfo {
    id: string;
    name: string;
}

export interface PrioritiesAlignment {
    alignments: PriorityAlignment[];
    alignmentScore: number;
    isStrategicPrioritiesUpdated: boolean;
}

export interface PriorityAlignment {
    strategicPriority: IStrategicPriorityInfo;
    impact: Impact;
    description: string;
}

export interface IWithPrioritiesAlignment{
    prioritiesAlignment: PrioritiesAlignment
}

export interface StrategicPrioritiesState extends IEntityStore<StrategicPriority> {
    isLoading: boolean;
    deletionResult?: IDeletionResult[];
 }

const unloadedState: StrategicPrioritiesState = {
    byId: {},
    allIds: [],
    isLoading: false,
};

interface LoadStrategicPrioritiesAction {
    type: 'LOAD_STRATEGIC_PRIORITIES';
}

interface StrategicPrioritiesLoadedAction {
    type: 'STRATEGIC_PRIORITIES_LOADED';
    strategicPriorities: StrategicPriority[];
}

interface ReceivedDeleteStrategicPrioritiesResultAction {
    type: 'RECEIVED_REMOVE_STRATEGIC_PRIORITIES_RESULT';
    deletionResult?: IDeletionResult[];
}

export interface SaveStrategicPrioritySuccessAction {
    type: 'SAVE_STRATEGIC_PRIORITY_SUCCESS';
    strategicPriority: StrategicPriority;
}

type KnownAction = LoadStrategicPrioritiesAction
    | StrategicPrioritiesLoadedAction
    | ReceivedDeleteStrategicPrioritiesResultAction
    | SaveStrategicPrioritySuccessAction;

export const defaultActionCreators = {
    loadStrategicPriorities: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<StrategicPriority[]>(`api/strategicPriority`)
            .then(data => dispatch({ type: 'STRATEGIC_PRIORITIES_LOADED', strategicPriorities: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_STRATEGIC_PRIORITIES' });
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_STRATEGIC_PRIORITIES_RESULT" });
    },
    removeStrategicPriorities: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<IDeletionResult>(`api/strategicPriority/${ids[0]}`)
                .then(data => dispatch({ type: "RECEIVED_REMOVE_STRATEGIC_PRIORITIES_RESULT", deletionResult: [data] }))
                .catch(defaultCatch(dispatch))
            : post<IDeletionResult[]>(`api/strategicPriority/bulkDelete`, { ids })
                .then(data => dispatch({ type: "RECEIVED_REMOVE_STRATEGIC_PRIORITIES_RESULT", deletionResult: data }))
                .catch(defaultCatch(dispatch));
    },
    saveStrategicPriority: (strategicPriority: StrategicPriority): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<StrategicPriority>(`api/strategicPriority`, strategicPriority)
            .then(data => {
                dispatch(<SaveStrategicPrioritySuccessAction>{ type: "SAVE_STRATEGIC_PRIORITY_SUCCESS", strategicPriority: data });
            })
            .catch(defaultCatch(dispatch));
    }
};

export const defaultReducer: Reducer<StrategicPrioritiesState> = (state: StrategicPrioritiesState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'LOAD_STRATEGIC_PRIORITIES':
            return {
                ...state,
                isLoading: true
            };
        case 'STRATEGIC_PRIORITIES_LOADED':
            return {
                ...state,
                ...StoreHelper.create(action.strategicPriorities),
                isLoading: false
            };
        case 'RECEIVED_REMOVE_STRATEGIC_PRIORITIES_RESULT':
            let newState = { ...state };
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'SAVE_STRATEGIC_PRIORITY_SUCCESS':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.strategicPriority)
            };
        default: const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};


export const reducer: Reducer<StrategicPrioritiesState> = (state: StrategicPrioritiesState, incomingAction: Action) => {
    state = state || unloadedState;
    return {
        ...defaultReducer(state, incomingAction),
    }
}

export const actionCreators = {
    ...defaultActionCreators,
};