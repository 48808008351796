import * as React from 'react';
import { Link as FabricLink, LinkBase } from 'office-ui-fabric-react';
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ILinkProps {
    href: string;
    className?: string;
    title?: string;
    tabIndex?: number;
    replaceCurrentUrl?: boolean;
    draggable?: boolean;
    target?: string;
    onFocus?: React.FocusEventHandler<HTMLAnchorElement | HTMLElement | HTMLButtonElement | LinkBase>;
    usePropagation?: boolean;
}

class Link extends React.Component<ILinkProps & RouteComponentProps<{}>> {
    private readonly TARGET_BLANK = "_blank";
    
    public render() {
        return <FabricLink
            tabIndex={this.props.tabIndex}
            title={this.props.title}
            href={this.props.href}
            target={this.props.target}
            className={this.props.className}
            onFocus={this.props.onFocus}
            onClick={this.props.target === this.TARGET_BLANK ? undefined : this._onClick}
            draggable={this.props.draggable}>
            {this.props.children}
        </FabricLink>;
    }

    private _onClick = (e: any) => {
        e.preventDefault();
        if (e.ctrlKey || !this.props.usePropagation) {
            e.stopPropagation();
        }
        e.ctrlKey
            ? window.open(this.props.href, this.TARGET_BLANK) 
            : this.props.replaceCurrentUrl
                ? this.props.history.replace(this.props.href)
                : this.props.history.push(this.props.href);
    }
}

export default withRouter<ILinkProps>(Link);