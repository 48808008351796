import * as React from 'react';
import { connect } from 'react-redux';
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { TenantState, actionCreators } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import TimezoneSelector from './TimezoneSelector';
import { Dropdown, IDropdownOption, Label } from 'office-ui-fabric-react';
import { FormatDate } from '../../utils/common';
import LabellableComponent from '../../common/LabellableComponent';
import langmap from 'langmap';

type OwnProps = {};
type StateProps = {
    user: UserState;
    tenant: TenantState;
}

type Props = StateProps & OwnProps & typeof actionCreators;
const GlobalSettings = (props: Props) => {

    const [timezone, setTimezone] = React.useState(props.tenant.globalSettings?.timezone);
    const [cultureName, setCultureName] = React.useState(props.tenant.globalSettings?.cultureName);

    const exampleDate = React.useMemo(() => { try { return FormatDate(new Date(), undefined, cultureName) } catch { return undefined } }, [cultureName]);
    const cultures = React.useMemo(() => {
        const list = Object.keys(langmap).map<IDropdownOption>(_ => ({ key: _, text: langmap[_].englishName, isSelected: cultureName === _ }));
        return list
            //remove duplicates
            .filter((item, index) => item.isSelected || list.findIndex(_ => _.text === item.text) === index)
            .sort((a, b) => a.text > b.text ? 1 : -1);
    }, []);

    const _saveTimeZone = (timeZoneValue: string) => {
        setTimezone(timeZoneValue);
        props.updateGlobalSettings(timeZoneValue, cultureName);
    }

    const _saveCultureName = (cultureValue: string) => {
        setCultureName(cultureValue);
        props.updateGlobalSettings(timezone, cultureValue);
    }

    const canManage = contains(props.user.permissions.common, CommonOperations.Administrate);
    return <div className="sync-settings settings-fields-holder">
        <div className="settings-row">
            <TimezoneSelector
                label="Tenant timezone"
                disabled={!canManage}
                value={timezone ?? ""}
                onChange={(value: string) => _saveTimeZone(value)}
            />
        </div>
        <div className="settings-row">
            <LabellableComponent className={`field-container}`}
                label="Regional format"
                description= "The dates will be displayed in the corresponding regional format in the PPM Express user interface and when exported to CSV.">
                <Dropdown
                    disabled={!canManage}
                    selectedKey={cultureName}
                    onChange={(e, o) => _saveCultureName('' + o?.key)}
                    options={cultures}
                />
                {exampleDate && <Label className="field-description">Example: {exampleDate}</Label>}
            </LabellableComponent>
        </div>
    </div >;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, actionCreators)(GlobalSettings);