import * as React from "react";
import { SourceType } from "../../store/ExternalEpmConnectStore";
import { O365LinkType } from "../../store/ProjectsListStore";
import { Dictionary, ISourceInfo } from "../../entities/common";
import O365GroupConnectControl from './Office365/Group/O365GroupConnectControl';
import TeamsChannelConnectControl from "./TeamsChannelConnectControl";
import { Integrations, PPMIntegrations } from "../../store/Tenant";
import { RendersMap, TabConnectControl } from "./TabConnectControl";
import PpmxTimeConnectControl from "./PpmxTimeConnectControl";
import SystemsList from "./SystemsList";
import { notUndefined } from "../utils/common";

type Props = {
    selected?: SourceType;
    context: any;
    readonly: boolean;
    actions: Dictionary<Dictionary<any>>;
    onDismiss: () => void;
    sourceInfos: ISourceInfo[];
    visibleOptions?: SourceType[];
    hiddenCollaborativeOptions?: SourceType[];
    integrations: Integrations;
    openCalendlyPanel?: () => void;
};

export const WorkTogetherTab = (props: Props) => {
    const _isDisabled = (sourceType: SourceType) => {
        const sourceInfo = props.sourceInfos.find(_ => _.type === sourceType);
        if (!sourceInfo) return false;

        if (sourceType === SourceType.O365Group) {
            return sourceInfo.sourceData.type === O365LinkType.PlanWithGroup;
        }
        if (sourceType === SourceType.O365TeamsChannel) {
            return false;
        }
        if (sourceType === SourceType.PpmxTime) {
            return false;
        }
        return true;
    }

    const _rendersMap: RendersMap = {
        [SourceType.O365Group]: {
            render: _ => <O365GroupConnectControl {..._} key="group-connect" allowNewItems context={props.context} />,
            helpUrl: "https://help.ppm.express/89413-ppm-express-connections/522445-office365-group-connection"
        },
        [SourceType.O365TeamsChannel]: {
            render: _ => <TeamsChannelConnectControl {..._} key="teamschannel-connect" />
        },
        [SourceType.PpmxTime]: {
            render: _ => <PpmxTimeConnectControl {..._} key="time-connect" />
        }
    }

    const options = [
        { type: SourceType.O365Group },
        { type: SourceType.O365TeamsChannel },
        props.integrations.has(PPMIntegrations.TimeTracker)
            ? { type: SourceType.PpmxTime, text: "Time" }
            : undefined,
    ]
        .filter(notUndefined)
        .filter(_ => !props.visibleOptions || ~props.visibleOptions.indexOf(_.type))
        .filter(_ => !props.hiddenCollaborativeOptions || !~props.hiddenCollaborativeOptions.indexOf(_.type));
    const systems = React.useMemo(() => options.map(_ => ({
        type: _.type,
        text: _.text,
        isDisabled: _isDisabled(_.type),
        isConnected: props.sourceInfos.some(__ => __.type === _.type),
        isUnavailable: props.integrations.isDisabled(_.type)
    })), [options, props.sourceInfos]);
    const [system, setSystem] = React.useState<SourceType>(props.selected ?? options[0].type);
    return <>
        <SystemsList selectedSystem={system}
            systems={systems}
            onSelect={setSystem} />
        {system !== undefined && <TabConnectControl
            {...props}
            connector={system}
            rendersMap={_rendersMap}
        />}
    </>;
}
export default WorkTogetherTab;