import * as React from 'react';
import './CreateGroupPanel.css';
import { Validator } from "../../../validation";
import { Label, Toggle } from 'office-ui-fabric-react';
import * as Metadata from "../../../entities/Metadata";
import { ItemCreation } from '../ItemCreation';
import { PPMXColorPalette } from '../PPMXColorPicker/PPMXColorPicker';
import { EntityTypeLabels } from '../../../entities/common';

export type GroupInfo = { id?: string, isShown?: boolean, isDefault?: boolean, index?: number; } & Metadata.IBaseGroupInfo;

type Props = {
    value?: Metadata.Group;
    onDismiss: () => void;
    onChange: (_: GroupInfo) => void;
    entityName: EntityTypeLabels;
    hideIsDefault?: boolean;
}
type State = { name?: string, color?: string, isDefault: boolean }

export class CreateGroupPanel extends React.Component<Props, State> {
    private validator: Validator = Validator.new().required().build();

    constructor(props: Props) {
        super(props);

        this.state = this._buildState(props);
    }

    public render() {
        const { color, name, isDefault } = this.state;

        return (
            <ItemCreation onDismiss={this.props.onDismiss}
                header={{
                    text: `${this.props.value ? 'Configure' : 'Create'} ${this.props.entityName.singular}`,
                    secondaryText: `Configure ${this.props.entityName.singular.toLowerCase()} display options`,
                    nameEditorLabel: `${this.props.entityName.singular} Name`,
                    onChanged: (value: string) => this.setState({ name: value }),
                    validator: this.validator,
                    value: name
                }}
                commands={[
                    {
                        primary: true,
                        text: `${this.props.value ? 'Save' : 'Create'} ${this.props.entityName.singular}`,
                        onClick: this._onSave,
                        disabled: !this.validator.isValid(name) || !this.validator.isValid(color) || !this.isChanged()
                    }, {
                        text: 'Cancel',
                        onClick: this.props.onDismiss
                    }
                ]}>
                <div className="panel-area create-group">
                    <div className="grid-item ">
                        <Label required={true} >Please pick a color for the {this.props.entityName.singular.toLowerCase()}</Label>
                        <PPMXColorPalette
                            color={color}
                            onChange={this._onChangeColor}
                            columnCount={12}
                            cellSize={16}
                        />
                    </div>
                    {!this.props.hideIsDefault && <>
                        <div className="grid-item ">
                            <Label>Is Default</Label>
                            <Toggle disabled={this.props.value?.isDefault} checked={isDefault} onChange={(e, checked) => this.setState({ isDefault: !!checked })} />
                        </div>
                    </>}
                </div>
            </ItemCreation>
        );
    }

    private _onChangeColor = (value?: string) => {
        this.setState({ color: value });
    }

    private _onSave = () => {
        const { name, color, isDefault } = this.state;

        if (!name || !this.validator.isValid(name) || !color || !this.validator.isValid(color)) {
            return;
        }

        const id = this.props.value?.id;
        const isShown = this.props.value ? this.props.value.isShown : true;

        this.props.onChange({ id, name, color: color, isShown, isDefault });
        this.props.onDismiss();
    }

    private isChanged = (): boolean => {
        const { name, color, isDefault } = this.state;
        const oldValue = this.props.value
        if (name === oldValue?.name && color === oldValue?.color && isDefault == oldValue?.isDefault) {
            return false;
        }
        return true;
    }

    private _buildState(props: Props): State {
        const name = props.value?.name;
        const isDefault = !!props.value?.isDefault;
        return {
            name,
            color: props.value?.color,
            isDefault
        };
    }
}