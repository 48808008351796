import * as React from 'react';
import * as NotificationsStore from "../../../store/NotificationsStore";
import { Dictionary } from '../../../entities/common';
import { ExtensionInfo } from '../../../store/ExtensionStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export type OwnProps = {
    onDismiss: () => void;
    info: ExtensionInfo;
    context: Dictionary<any>;
    urlParams?: Dictionary<string>;
};

type ActionProps = {
    notificationActions: typeof NotificationsStore.actionCreators;
};

type Props = OwnProps & ActionProps;

const ExtensionNavigate = (props: Props) => {
    const { onDismiss, context, urlParams, notificationActions } = props;
    const urlStr = props.info.url;
    React.useEffect(() => {
        if (!urlStr) {
            notificationActions.pushNotification({ message: `Extension is not configured well.`, type: NotificationsStore.NotificationType.Error });
            onDismiss();
            return;
        }

        const url = new URL(urlStr);
        if (urlParams) {
            for (const key in urlParams) {
                if (urlParams.hasOwnProperty(key)) {
                    url.searchParams.set(key, urlParams[key]);
                }
            }
        }
        if (context) {
            for (const key in context) {
                if (context.hasOwnProperty(key)) {
                    url.searchParams.set(key, context[key]);
                }
            }
        }

        window.open(url.toString(), '_blank');
        onDismiss();
    }, [urlStr, context, urlParams, notificationActions, onDismiss]);

    return null;
};

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        notificationActions: bindActionCreators(NotificationsStore.actionCreators, dispatch)
    };
}

export default connect(undefined, mergeActionCreators)(ExtensionNavigate);
