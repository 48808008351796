import * as React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "office-ui-fabric-react";
import { withRouter, RouteComponentProps } from "react-router-dom";

type SidebarLinkOwnProps = SidebarLinkContentsProps & {
    disabled?: boolean;
    to: string;
    matchPath?: string;
    secondary?: boolean;
};

type SidebarLinkProps = SidebarLinkOwnProps & RouteComponentProps<{}>;

const SidebarLink = (props: SidebarLinkProps) => {
    const secondaryClassName = props.secondary ? "secondary" : "";
    return props.disabled ? (
        <span className={`nav-link ${secondaryClassName} disabled`} title={`Sorry, you don't have the permission to view ${props.text}`}>
            <SidebarLinkContents {...props} />
        </span>
    ) : (
        <NavLink
            title={props.text}
            className={`nav-link ${secondaryClassName}`}
            activeClassName="active"
            to={props.to}
            isActive={props.matchPath ? (m, l) => !!m || l.pathname.startsWith(props.matchPath!) : undefined}
        >
            <SidebarLinkContents {...props} />
        </NavLink>
    );
};

type SidebarLinkContentsProps = {
    iconName: string;
    showDetailed: boolean;
    text: string;
};

const SidebarLinkContents = ({ iconName, showDetailed, text }: SidebarLinkContentsProps) => (
    <div className="linkClass icon-container">
        <Icon iconName={iconName} />
        {showDetailed && <span className="title">{text}</span>}
    </div>
);

export default withRouter<SidebarLinkOwnProps>(SidebarLink);
