import React from 'react';
import { IconButton } from 'office-ui-fabric-react';

type Props = {
    title: string;
    color: string;
    typeName: string;
    onAdd: () => void;
};

const CategoryHeader = (props: Props) => {
    const { title, color, onAdd, typeName } = props;
    return (
        <div className="align-center flex-space-between category-header">
            <div className="align-center">
                <div className="category-color" style={{ backgroundColor: color }}></div>
                <div className="category-name">{title}</div>
            </div>
            <IconButton
                title={`Add ${typeName}`}
                menuIconProps={{ iconName: 'CircleAddition' }}
                onClick={onAdd} />
        </div>
    );
};

export default CategoryHeader;
