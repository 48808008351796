import './SummaryNotificationSettings.css';
import * as React from 'react';
import * as analytics from '../../../../analytics';
import { ChoiceGroup, IChoiceGroupOption, Checkbox, mapEnumByName, DayOfWeek, Toggle } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { UserState } from '../../../../store/User';
import { CommonOperations, contains } from '../../../../store/permissions';
import { TenantState, actionCreators } from '../../../../store/Tenant';
import { ApplicationState } from '../../../../store';
import TimeSelector from '../../../common/TimeSelector';
import { notUndefined } from '../../../utils/common';
import { useDidMountEffect } from '../../../utils/effects';

type OwnProps = {};
type StateProps = {
    user: UserState;
    tenant: TenantState;
}
type Props = StateProps & OwnProps & typeof actionCreators;

const dayOptions = mapEnumByName(DayOfWeek, (name, value) => ({ text: name!, key: value?.toString() || "", value: value }))!.filter(notUndefined)

const NotificationSettings = (props: Props) => {
    const canManage = contains(props.user.permissions.common, CommonOperations.ScheduleManage);
    const [isUserNotificationsEnabled, setIsUserNotificationsEnabled] = React.useState(props.tenant.healthReportingSettings!.isUserNotificationsEnabled);
    const [dailySchedule, setDailyCheckSchedule] = React.useState(props.tenant.healthReportingSettings!.dailySchedule);
    const [weeklySchedule, setWeeklyCheckSchedule] = React.useState(props.tenant.healthReportingSettings!.weeklySchedule);

    useDidMountEffect(() => {
        props.updateSyncSchedule(props.tenant.syncSettings!.schedule,
            props.tenant.syncSettings!.alertDelay,
            props.tenant.syncSettings!.alertRecipientIds,
            { isUserNotificationsEnabled, dailySchedule, weeklySchedule });
    }, [isUserNotificationsEnabled, dailySchedule, weeklySchedule]);

    const onIsEnabledChange = React.useCallback(
        (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
            setIsUserNotificationsEnabled(checked);
            analytics.trackToggle("User email notifications updated", props.user, checked);
        },
        []);

    const onDailyTimeChange = React.useCallback(
        (times: any[]) => setDailyCheckSchedule({ ...dailySchedule, times: times }),
        [dailySchedule]);

    const onDailyCheckWeekDayChanged = React.useCallback((ev: React.FormEvent<HTMLInputElement>, checked: boolean) => {
        const value = parseInt(ev.currentTarget.name);
        const days = [...dailySchedule.days];
        const idx = days.indexOf(value);
        if (checked) {
            if (idx === -1) days.push(value);
        }
        else {
            if (idx !== -1) days.splice(idx, 1);
        }

        setDailyCheckSchedule({ ...dailySchedule, days: days });
    }, [dailySchedule]);

    const onWeeklyTimeChange = React.useCallback(
        (times: any[]) => setWeeklyCheckSchedule({ ...weeklySchedule, times: times }),
        [weeklySchedule]);

    const onWeeklyCheckWeekDayChanged = React.useCallback(
        (ev: React.FormEvent<HTMLElement>, item: IChoiceGroupOption) =>
            setWeeklyCheckSchedule({ ...weeklySchedule, days: [parseInt(item.key)] }),
        [weeklySchedule]);

    const disabled = !canManage;
    return <div className="sync-settings notification-settings">
        <Toggle
            className='user-email-notifications-toggle'
            label='User Email Notifications'
            checked={isUserNotificationsEnabled}
            onChange={onIsEnabledChange}
            disabled={disabled}
        />
        <TimeSelector
            label="Daily notification schedule"
            times={dailySchedule.times}
            onChange={onDailyTimeChange}
            disabled={disabled}
        />
        <ul className="cb-list cb-in-row">
            {
                dayOptions.map(item => <li key={item.key}>
                    <div className="cb-wrap">
                        <Checkbox
                            disabled={disabled}
                            label={item.text}
                            checked={dailySchedule.days.indexOf(item.value) !== -1}
                            onChange={onDailyCheckWeekDayChanged}
                            name={item.key} />
                    </div>
                </li>)
            }
        </ul>
        <br />
        <TimeSelector
            disabled={disabled}
            label="Weekly notification schedule"
            times={weeklySchedule.times}
            onChange={onWeeklyTimeChange}
        />
        <ChoiceGroup
            disabled={disabled}
            selectedKey={"" + (weeklySchedule.days.length > 0 ? weeklySchedule.days[0] : "1")}
            onChange={onWeeklyCheckWeekDayChanged}
            className="with-cb-in-row"
            options={dayOptions} />
    </div>;
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        user: state.user,
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, actionCreators)(NotificationSettings);