import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { IGroupInfo } from '../../entities/Metadata';
import { ungroupedGroup } from '../common/inputs/GroupDropdown';

type TaskIconEntity = {
    attributes?: { Group?: IGroupInfo },
    externalData: { NameIcon?: string },
    isAutoMode?: boolean,
    hierarchy?: { isParent: boolean }
}

const DefaultSummaryIconColor: string = '#6a6b6d';

export default class TaskIcon extends React.Component<{ entity: TaskIconEntity, milestoneIconName?: string, summaryIcon?: boolean }> {
    render() {
        const { entity, summaryIcon, milestoneIconName } = this.props;
        if (milestoneIconName) {
            return <div className={`logo align-center ${milestoneIconName}`} >
                <Icon className={milestoneIconName} iconName={milestoneIconName} />
                {summaryIcon && entity.hierarchy?.isParent && <SummaryTaskChevronIcon  />}
            </div>;
        }
        if (entity.externalData.NameIcon) {
            return <div className="logo task-logo align-center">
                <span className="icon" style={{ backgroundImage: `url(${entity.externalData.NameIcon})` }} />
                {summaryIcon && entity.hierarchy?.isParent && <SummaryTaskChevronIcon color={DefaultSummaryIconColor} />}
            </div>;
        }
        const color = entity.attributes?.Group?.color || ungroupedGroup.color;
        return <BaseTaskIcon color={color} percent={.5} isAutoMode={entity.isAutoMode} isParent={entity.hierarchy?.isParent} summaryIcon={summaryIcon} />
    }
}

export class BaseTaskIcon extends React.Component<{ color?: string, percent: number, isAutoMode?: boolean, isParent?: boolean, summaryIcon?: boolean }> {
    render() {
        const { percent, isAutoMode, isParent, summaryIcon } = this.props;
        
        const color = this.props.color || ungroupedGroup.color;
        const shadeColor = calculateShadeColor(color, percent);
        return (
            <div className="logo task-logo align-center" style={{ backgroundColor: shadeColor }}>
                <Icon iconName="StatusCircleCheckmark"
                    className={`icon ${isAutoMode ? 'auto-mode' : ''}`}
                    styles={{ root: { background: color, color: shadeColor } }} />
                {summaryIcon && isParent && <SummaryTaskChevronIcon color={color} />}
            </div>
        );
    }
}

const SummaryTaskChevronIcon = (props: { color?: string }) =>
    <Icon iconName="Down" className={'expandable'} styles={{ root: { color: props.color } }} />;

export function calculateShadeColor(color: string | undefined, percent: number, withAlpha?: boolean) {
    if (color == undefined)
        return undefined;
    const f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
    if (withAlpha) {
        return `rgba(${R}, ${G}, ${B}, ${percent})`;
    }
    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
}