import React, { useState } from "react";
import { PanelType } from "office-ui-fabric-react";
import StatusHistory, { Props as StatusHistoryProps }from "./StatusHistory";
import StatusEdit, { StatusEditProps } from "./StatusEdit";
import StatusView, { StatusViewProps } from "./StatusView";
import ExpandablePanel from "../../../ExpandablePanel";

type Props = {
    descriptionPlaceholder?: string;
    onDismiss: () => void;
    view: StatusViewProps;
    edit?: StatusEditProps;
    history: StatusHistoryProps;
}

const StatusPanel = (props: Props) => {
    const { view, edit, history } = props;
    const [showHasChangesWarning, setShowHasChangesWarning] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const onFormChanged = () => {
        setIsDirty(true);
    }

    const onDismiss = (ev: React.SyntheticEvent<HTMLElement>) => {
        if (isDirty && ev.currentTarget.classList.contains("ms-Overlay")) {
            setShowHasChangesWarning(true);
            ev.preventDefault();
            return;
        }
        props.onDismiss();
    }

    const onRenderHeader = () => (
        <Header label={history.label} description={props.descriptionPlaceholder} />
    );

    return <ExpandablePanel
        className="status-panel"
        isLightDismiss
        type={PanelType.custom}
        customWidth="400px"
        isOpen
        onRenderHeader={onRenderHeader}
        onDismiss={onDismiss}>
        {
            edit
                ? <StatusEdit {...edit} {...view} {...history}
                    onClose={props.onDismiss}
                    onFormChanged={onFormChanged}
                    showHasChangesWarning={showHasChangesWarning} />
                : <StatusView {...view} {...history} />
        }
        <StatusHistory {...history} />
    </ExpandablePanel>
}

export default StatusPanel;

type HeaderProps = {
    label: string;
    description?: string;
}

const Header = (props: HeaderProps) => {
    const { label, description } = props;
    return <div className="ms-Panel-header">
        <p className="ms-Panel-headerText">{label}</p>
        <div className='ms-Panel-secondaryText'>{description}</div>
    </div>;
}
