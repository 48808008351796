import * as React from "react";
import { Icon } from "office-ui-fabric-react";

interface Props {
    solid?: boolean;
}

export const PrivateProjectIcon = (props: Props) => <Icon
    className="private-entity-icon"
    iconName={props.solid ? "LockSolid" : "Lock"}
    title="This Project is Private"
/>
export default PrivateProjectIcon;