import * as React from "react";
import { Dictionary, Impact, ImpactConfigMap } from "../../entities/common";
import { FieldInfo } from "../../entities/Metadata";
import { ISettingsEditComponent, IBaseFieldInfo } from "./FieldPanel";

type Props = {
    settingsDefaults?: Dictionary<any>;
}

export const impactEnumSliderDefaultSettings = {
    editControl: 'EnumSlider',
    options: {
        [Impact.NA]: ImpactConfigMap[Impact.NA].title,
        [Impact.Low]: ImpactConfigMap[Impact.Low].title,
        [Impact.Normal]: ImpactConfigMap[Impact.Normal].title,
        [Impact.Moderate]: ImpactConfigMap[Impact.Moderate].title,
        [Impact.Strong]: ImpactConfigMap[Impact.Strong].title,
        [Impact.Extreme]: ImpactConfigMap[Impact.Extreme].title
    },
    className: 'impact',
    views: {
        list: {
            componentPath: 'Impact',
            maxWidth: 104,
            minWidth: 104
        }
    }
}

export class EnumSliderSettingsEdit extends React.Component<Props> implements ISettingsEditComponent {
    public render() {
        return null;
    }

    public save(fieldInfo: FieldInfo) {
        this._applyChanges(fieldInfo);
    }

    public update(fieldInfo: IBaseFieldInfo) {
        this._applyChanges(fieldInfo);
    }

    private _applyChanges(fieldInfo: IBaseFieldInfo | FieldInfo) {
        fieldInfo.settings = {
            ...this.props.settingsDefaults,
            ...fieldInfo.settings
        };
    }
}
