import * as React from 'react';
import { Toggle } from 'office-ui-fabric-react';
import { MinMax, CalculationSettings, GenericCalculationThresholds, StatusCalculationTypes } from '../../store/Tenant';
import { ThresholdsRange } from "./ThresholdsRange";
import { FormatType } from '../../entities/Metadata';
import { contains } from '../../store/permissions';

type Props = {
    data: CalculationSettings<GenericCalculationThresholds>;
    onChange?: (data: CalculationSettings<GenericCalculationThresholds>) => void;
    thresholdTooltip: string;
}

export class GenericStatusCalculationSettings extends React.Component<Props> {
    public render() {
        const { data, onChange, thresholdTooltip } = this.props;
        const isAuto = contains(data.statusCalculation, StatusCalculationTypes.Auto);
        return <>
            <div className="status-calculation-toggle align-center">
                <span className="label">Calculate statuses automatically</span>
                <Toggle className="toggle"
                    disabled={!onChange}
                    checked={isAuto}
                    onChanged={onChange ? (checked: boolean) => {
                        onChange({ ...data, statusCalculation: checked ? StatusCalculationTypes.Auto : StatusCalculationTypes.Manual });
                    } : undefined}
                    onText='On' offText='Off' styles={{ root: { marginBottom: -3 } }} />
            </div>
            {isAuto && <>
                <div>Specify values below to define status calculation rules</div>
                <div className="card-list">
                    <ThresholdsRange title="Proximity / Lateness Impact"
                        tooltip={thresholdTooltip}
                        minLabel="At Risk"
                        maxLabel="Critical"
                        format={FormatType.Days}
                        range={{ min: -100, max: 100 }}
                        value={data.thresholds.minMax}
                        onChange={onChange ? (v: MinMax<number | null>) => this._set("minMax", v) : undefined} />
                </div>
            </>
            }
        </>;
    }

    private _set = (propName: keyof GenericCalculationThresholds, value: MinMax<number | null>): void => {
        this.props.onChange!({
            ...this.props.data,
            thresholds: { ...this.props.data.thresholds, [propName]: value }
        });
    }
}