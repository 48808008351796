import * as React from 'react';
import { connect } from 'react-redux';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import { ApplicationState } from '../../../store';
import { IFieldMapping, IFieldMappingKeyMap, MappingType, ExternalFieldInfo } from '../../../store/integration/common';
import { Field, FieldType } from "../../../entities/Metadata";
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import { FieldMappingService } from "../../common/DisplayFieldService";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { EntityType } from '../../../entities/common';
import { namesof } from '../../../store/services/metadataService';
import { ITaskAttrs } from '../../../entities/Subentities';
import OverlayComponent from '../../common/OverlayComponent';
import './MappingPanelControl.css'
import MappingControl from '../MappingControl/MappingControl';
import { ProjectAttrs } from '../../../store/ProjectsListStore';

type OwnProps = {
    connector: SourceType;
    entityType: EntityType;
    externalFields: ExternalFieldInfo[];
    typesMap: { [externalFieldMap: string]: { types: Partial<FieldType>[], label: string } };
    mapping: IFieldMapping[] | null;
    updateMapping: (mapping: IFieldMapping[]) => void;
    mappingTypes: MappingType[];
    showTransformationScript?: boolean;
    onDismiss: () => void;
    isLoading: boolean;
    error: string | null;
    disabled?: boolean;
    typeName?: string;
    onRenderHeader?: () => JSX.Element;
    onGetExternalFieldOptions?: (field: ExternalFieldInfo) => Promise<string[] | number[] | null>
    panelWidth?: string;
}

type StateProps = {
    ppmxfields: Field[];
    user: UserState;
}

const notConfigurableFields = namesof<ITaskAttrs>(["Group", "Predecessor"]);

const MappingPanelControl = (props: OwnProps & StateProps) => {
    const { externalFields, ppmxfields, typesMap, onDismiss, mappingTypes, user, disabled, isLoading } = props;
    const [mapping, setMapping] = React.useState<IFieldMappingKeyMap[]>([]);
    React.useEffect(() => setMapping(FieldMappingService.buildIdMap(props.mapping, FieldMappingService.getDefaultMapping(mappingTypes))), [props.mapping]);

    const isReadOnly = !contains(user.permissions.common, CommonOperations.ConnectionManage) || !!disabled;

    return <div className="mapping-panel-conent">
        <div className='scrollable-content'>
            {props.onRenderHeader && props.onRenderHeader()}
            <OverlayComponent isOverlayed={!isLoading && isReadOnly}>
                <MappingControl {...props}
                    mapping={mapping}
                    fieldPanelWidth={props.panelWidth ?? "600px"}
                    ppmxfields={ppmxfields}
                    onMappingUpdated={_ => setMapping(_)} />
            </OverlayComponent>
        </div>

        <div className="commands">
            {
                !isReadOnly && <PrimaryButton
                    disabled={!FieldMappingService.validateMapping(mapping, externalFields, ppmxfields, typesMap)
                        || FieldMappingService.isSame(props.mapping || [], mapping)
                    }
                    text="Save Mapping"
                    onClick={() => {
                        const newMapping: IFieldMapping[] = [];
                        if (FieldMappingService.validateMapping(mapping, externalFields, ppmxfields, typesMap, newMapping)) {
                            props.updateMapping(newMapping);
                        }
                    }} />
            }
            <DefaultButton text="Cancel" onClick={onDismiss} />
        </div>
    </div>
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const fieldsState = state.fields[ownProps!.entityType];
    const fields = fieldsState.allIds.map(_ => fieldsState.byId[_]);
    return {
        ppmxfields: fields.filter(_ => isConfigurableField(_, ownProps!.connector)),
        user: state.user
    };
}

function isConfigurableField(field: Field, sourceType: SourceType): boolean {
    if (notConfigurableFields.some(_ => _ === field.name)) {
        return false;
    }

    if (sourceType == SourceType.P4W) {
        const attributes = namesof<ProjectAttrs>(["Portfolio", "Program"]);
        return !attributes.some(_ => _ === field.name);
    }

    return true;
}

export default connect(mapStateToProps)(MappingPanelControl);