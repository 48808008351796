
import React from "react";
import { nameof } from "../../store/services/metadataService";
import { TimeTrackingEntry, TimeTrackingEntryAttr } from "../../store/TimeTrackingStore";
import { IInputProps } from "../common/interfaces/IInputProps";
import * as Metadata from "../../entities/Metadata";
import { IValidator, Validator } from "../../validation";
import DatePickerInput from "../common/inputs/DatePickerInput";
import { toDateTime } from "../utils/common";
import { TimeTrackingAdministrativeCategory, TimeTrackingGlobalSettings } from "../../store/Tenant";
import TextInput from "../common/inputs/TextInput";
import DropdownInput from "../common/inputs/DropdownInput";
import { IDropdownOption } from "office-ui-fabric-react";

export const validators = (timeTrackingSettings: TimeTrackingGlobalSettings,) => {
    return {
        [nameof<TimeTrackingEntryAttr>("Date")]:
            () => Validator
                .new()
                .required()
                .date()
                .dateIsGreaterThenOrEqual(toDateTime(timeTrackingSettings.timeReportingLock), "Period is locked")
                .build()
    }
}

export const rendersBuilder = (
    entry: TimeTrackingEntry,
    timeTrackingSettings: TimeTrackingGlobalSettings,
    administrativeCategories: TimeTrackingAdministrativeCategory[]) => {
    return {
        [nameof<TimeTrackingEntryAttr>("Date")]: (
            props: IInputProps,
            state: any,
            field: Metadata.Field,
            validator?: IValidator
        ) => {

            return (
                <div>
                    <DatePickerInput
                        {...props}
                        validator={validator}
                        minDate={toDateTime(timeTrackingSettings.timeReportingLock)}/>
                </div>
            );
        },

        [nameof<TimeTrackingEntryAttr>("Duration")]: (
            props: IInputProps,
            state: any,
            field: Metadata.Field,
            validator?: IValidator
        ) => {

            return (
                <div>
                    <TextInput
                        {...props}
                        validator={validator}
                        format={Metadata.FormatType.Duration}
                        allowForceUpdate
                        onEditComplete={value => {

                            const duration = Number(value);

                            if (!Number.isNaN(duration)) {

                                const fixedDuration = FixMinAndIncrementDuration(
                                    duration,
                                    timeTrackingSettings.minReportingDurationMinutes,
                                    timeTrackingSettings.reportingIncrementMinutes);
                                
                                if (duration !== fixedDuration) {
                                
                                    setTimeout(() => {
                                        props.onChanged?.(fixedDuration);
                                        props.onEditComplete?.(fixedDuration);
                                    });
                                }
                            }
                            
                            props.onChanged?.(value);
                            props.onEditComplete?.(value);
                        }} />
                </div>
            );
        },

        [nameof<TimeTrackingEntryAttr>("AdministrativeCategory")]: (
            props: IInputProps,
            state: any,
            field: Metadata.Field,
            validator?: IValidator
        ) => {
            const options: IDropdownOption[] = administrativeCategories
                .filter(_ => _.isEnabled || _.id === entry.attributes.AdministrativeCategory)
                .map(_ => ({
                    key: _.id,
                    text: _.title
                }));

            return (
                <div>
                    <DropdownInput
                        {...props}
                        inputProps={{ options: options }}
                        onEditComplete={value => {
                            props.onChanged?.(value);
                            props.onEditComplete?.(value);
                        }}
                    />
                </div>
            );
        }
    }
}

export const FixMinAndIncrementDuration = (durationHours: number, minMinutes: number, incrementMinutens: number): number => {

    minMinutes = minMinutes || 1;
    incrementMinutens = incrementMinutens || 1;

    let durationMinutes = Math.round(durationHours * 60);

    if (durationMinutes < minMinutes) {
        durationMinutes = minMinutes;
    }
    else  if (durationMinutes % incrementMinutens > 0) {
        durationMinutes = incrementMinutens * Math.ceil(durationMinutes / incrementMinutens);   
    }

    return durationMinutes / 60;
}
