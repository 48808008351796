import * as React from 'react';
import { IObjectWithKey } from 'office-ui-fabric-react';
import { HUNDRED_PCT, roundToHundreds } from '../../../utils/common';
import NumberInput from '../../../common/inputs/NumberInput';
import { IFormInputComponent } from '../../../common/interfaces/IFormInputComponent';
import { Validator } from '../../../../validation';
import { ViewType } from '../ResourceUsageGrid';
import { TimeType } from './CellMenu';
import { HoursData } from './CellViewMode';

export interface ISelectableCellData extends IObjectWithKey {
    entityId: string;
    start: Date;
    finish: Date;
}

const MAX_ALLOCATION_MULTIPLIER_PER_RESOURCE = 10000;

type SummaryData = {
    hours: HoursData;
    fullFteCapacityHours: number;
    hasOverage: boolean;
    hasLocalOverage: boolean;
    hasProposedOverage: boolean;
    hasLocalProposedOverage: boolean;
}

type EditModeProps = {
    viewType: ViewType,
    summaryData: SummaryData,
    maxCapacityHours: number,
    editTimeType: TimeType,
    onEditComplete: (_: number | null) => void,
    onChangeSummaryData: (summaryData: SummaryData) => void
}

const EditMode = (props: EditModeProps) => {
    const { viewType, summaryData, maxCapacityHours, editTimeType, onEditComplete, onChangeSummaryData } = props;
        let value: number | undefined = undefined;
        if (editTimeType === 'plan') {
            value = viewType === ViewType.FTE 
                ? summaryData.hours.planFte 
                : viewType === ViewType.Percent 
                    ? summaryData.hours.planPercent 
                    : roundToHundreds(summaryData.hours.plan);
        } else if (editTimeType === 'actual') {
            value = viewType === ViewType.FTE 
                ? summaryData.hours.actualFte 
                : viewType === ViewType.Percent 
                    ? summaryData.hours.actualPercent 
                    : roundToHundreds(summaryData.hours.actual);
        }
        return <div onClick={(e) => { e.stopPropagation(); }} className='value-wrap'>
            <NumberInput
                inputRef={(_: IFormInputComponent | null) => _?.focus()}
                validator={Validator.new().required().decimal().min(0).max(
                    MAX_ALLOCATION_MULTIPLIER_PER_RESOURCE * (viewType === ViewType.FTE
                        ? ((summaryData.hours.capacity / summaryData.fullFteCapacityHours) || 1)
                        : viewType === ViewType.Percent ? HUNDRED_PCT : (summaryData.hours.capacity || maxCapacityHours))
                ).build()}
                selectOnFocus
                value={value}
                resetInvalidOnBlur
                onEditComplete={(_: number | null) => {
                    if(_ !== null) {
                        const newSummaryData: SummaryData = { 
                            hasOverage: false, 
                            hasLocalOverage: false, 
                            hasProposedOverage: false, 
                            hasLocalProposedOverage: false, 
                            hours: summaryData.hours, 
                            fullFteCapacityHours: summaryData.fullFteCapacityHours 
                        };
                        if (viewType === ViewType.FTE) {
                            newSummaryData.hours = {
                                ...summaryData.hours,
                                planFte: editTimeType === 'plan' ? _ : summaryData.hours.planFte,
                                actualFte: editTimeType === 'actual' ? _ : summaryData.hours.actualFte,
                            };
                        } else if (viewType === ViewType.Percent) {
                            newSummaryData.hours = {
                                ...summaryData.hours,
                                planPercent: editTimeType === 'plan' ? _ : summaryData.hours.planPercent,
                                actualPercent: editTimeType === 'actual' ? _ : summaryData.hours.actualPercent
                            };
                        } else {
                            newSummaryData.hours = {
                                ...summaryData.hours,
                                plan: editTimeType === 'plan' ? _ : summaryData.hours.plan,
                                actual: editTimeType === 'actual' ? _ : summaryData.hours.actual
                            };
                        }
                        onChangeSummaryData(newSummaryData);
                    }

                    onEditComplete(_);
                }} />
            <div className="unit">{viewType === ViewType.FTE ? 'FTE' : viewType === ViewType.Percent ? '%' : 'hours'}</div>
        </div>;
}

export default EditMode;
