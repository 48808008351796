import * as React from 'react';
import { shallowCompare, IObjectWithKey, memoizeFunction } from 'office-ui-fabric-react';
import { Resource, createResourceCalendar, DailyUsage, filterByDatesUsage, ResourceUsage, findUsage } from '../../../../store/ResourcesListStore';
import { arraysEqual, HUNDRED_PCT, notEmpty, roundToHundreds, toDate } from '../../../utils/common';
import { getWorkingHoursBetweenDates } from '../../../common/timeline/utils';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { CalendarDataSet } from '../../../../store/CalendarStore';
import { Dictionary, entityLogoConfig, EntityType, mapServerEntityType, MaybeDate, Quantization, ServerEntityType } from '../../../../entities/common';
import { ViewType } from '../ResourceUsageGrid';
import SelectionExt from '../../../common/SelectionExt';
import { TimeType } from './CellMenu';
import EditMode from './CellEditMode';
import ViewMode, { SummaryData } from './CellViewMode';

export interface ISelectableCellData extends IObjectWithKey {
    entityId: string;
    start: Date;
    finish: Date;
}

interface StateProps {
    calendar: CalendarDataSet;
    resourcePlanningLevel: EntityType;
}
interface OwnProps {
    entityId?: string;
    entityType: EntityType;
    selection?: SelectionExt;
    selectionIndex?: number;
    onClick?: React.MouseEventHandler;
    onKeyDown?: React.KeyboardEventHandler;
    onKeyPress?: React.KeyboardEventHandler;
    onFocus?: React.FocusEventHandler;
    viewType: ViewType;
    resource: Resource;
    quantization: Quantization;
    prevStartDate: Date | undefined;
    startDate: Date;
    finishDate: Date;
    onEditComplete?: (value: number | null, timeType: TimeType) => void;
    onEditStart?: () => void;
    componentRef?: React.LegacyRef<HTMLDivElement>;
    timeType: TimeType[];
}
type HoursData = {
    capacity: number;
    plan: number;
    actual: number;
    availability: number;
    planPercent: number | undefined;
    actualPercent: number | undefined;
    availabilityPercent: number | undefined;
    planFte: number | undefined;
    actualFte: number | undefined;
    availabilityFte: number | undefined;
};

type Props = StateProps & OwnProps;
interface State {
    isSelected?: boolean;
    summaryData: SummaryData;
    prevHours: HoursData | undefined;
    editTimeType?: TimeType;
}

const calculateSummaryDataImpl = (startDate: Date, finishDate: Date, props: Props): SummaryData => {
    const summary = calculateSummary(props.resource, startDate, finishDate, props.calendar, props.entityType, props.resourcePlanningLevel);
    const plan = props.entityId
        ? (summary.committedByEntity[props.entityId] ?? 0) + (summary.proposedByEntity[props.entityId] ?? 0)
        : (summary.committedWork + summary.proposedWork);
    const actual = props.entityId
        ? (summary.actualByEntity[props.entityId] ?? 0)
        : summary.actualWork;
    const capacity = summary.capacityHours;
    const availability = summary.availability;
    const fullFteCapacityHours = summary.fullFteCapacityHours;
    
    return {
        hours: {
            plan,
            actual,
            capacity,
            availability,
            planPercent: capacity ? Math.round(plan / capacity * HUNDRED_PCT) : undefined,
            actualPercent: capacity ? Math.round(actual / capacity * HUNDRED_PCT) : undefined,
            availabilityPercent: capacity ? Math.round(availability / capacity * HUNDRED_PCT) : undefined,
            planFte: summary.fullFteCapacityHours ? roundToHundreds(plan / fullFteCapacityHours) : undefined,
            actualFte: summary.fullFteCapacityHours ? roundToHundreds(actual / fullFteCapacityHours) : undefined,
            availabilityFte: summary.fullFteCapacityHours ? roundToHundreds(availability / fullFteCapacityHours) : undefined
        },
        fullFteCapacityHours,
        hasOverage: summary.overage > 0,
        hasLocalOverage: summary.hasLocalOverage,
        hasProposedOverage: props.entityId
            ? ((summary.proposed_overage_byEntity[props.entityId] ?? 0) > 0)
            : (summary.proposedWork > summary.availability),
        hasLocalProposedOverage: props.entityId ? summary.proposed_hasLocalOverage_byEntity[props.entityId] : false
    }
}

const calculateSummaryData = (props: Props): SummaryData => calculateSummaryDataImpl(props.startDate, props.finishDate, props);
const calculatePrevHours = (props: Props): HoursData | undefined => props.prevStartDate 
    ? calculateSummaryDataImpl(props.prevStartDate, props.startDate.clone().addDays(-1), props).hours
    : undefined;
const calculateIsSelected = (props: Props): boolean | undefined => props.selectionIndex !== undefined ? props.selection?.isIndexSelected(props.selectionIndex) : undefined

const getMaxCapacityHours = (quantization: Quantization): number => {
    return {
        [Quantization.days]: 8,
        [Quantization.weeks]: 40,
        [Quantization.months]: 160,
        [Quantization.quarters]: 480,
        [Quantization.years]: 1920
    }[quantization] ?? 0;
}

class ResourceUsageCell extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this._buildState(props);

        if (props.selectionIndex !== undefined) {
            this.props.selection?.AddEventHandler(this._onSelectionChanged, props.selectionIndex);
        }
    }

    componentWillUnmount() {
        this.props.selection?.RemoveEventHandler(this._onSelectionChanged);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.selectionIndex !== nextProps.selectionIndex) {
            if (this.props.selectionIndex !== undefined) {
                nextProps.selection?.RemoveEventHandler(this._onSelectionChanged);
            }
            if (nextProps.selectionIndex !== undefined) {
                nextProps.selection?.AddEventHandler(this._onSelectionChanged, nextProps.selectionIndex);
            }
        }

        if (this.props.resource !== nextProps.resource
            || this.props.prevStartDate?.getTime() !== nextProps.prevStartDate?.getTime()
            || this.props.startDate.getTime() !== nextProps.startDate.getTime()
            || this.props.finishDate.getTime() !== nextProps.finishDate.getTime()
            || this.props.calendar !== nextProps.calendar
            || this.props.quantization !== nextProps.quantization
            || !arraysEqual(this.props.timeType, nextProps.timeType)
        ) {
            this.setState(this._buildState(nextProps));
        }
    }

    private _onSelectionChanged = () => {
        const isSelected = this.props.selection!.isIndexSelected(this.props.selectionIndex!);
        if (this.state.isSelected !== isSelected) {
            this.setState({ isSelected });
        }
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return !shallowCompare(this.state, nextState)
            || !shallowCompare(this._getPropsToCompare(this.props), this._getPropsToCompare(nextProps));
    }

    private _getPropsToCompare(props: Props) {
        return {
            resource: props.resource,
            startDate: props.startDate.getTime(),
            finishDate: props.finishDate.getTime(),
            calendar: props.calendar,
            viewType: props.viewType,
            quantization: props.quantization
        }
    }

    render() {
        const { selectionIndex, viewType, onClick, onFocus, onKeyDown, onKeyPress, timeType, quantization } = this.props;
        const { editTimeType, isSelected, summaryData, prevHours} = this.state;

        const isEditable = selectionIndex !== undefined;
        const isEditMode = editTimeType !== undefined;

        const className = [
            'resource-usage-cell',
            summaryData.hours.capacity ? undefined : 'no-capacity',
            ViewType[viewType],
            isSelected ? 'selected' : undefined,
            summaryData.hasLocalProposedOverage && !isEditMode ? 'has-local-proposed-overage' : undefined,
            summaryData.hasLocalOverage && !isEditMode ? 'has-local-overage' : undefined,
            summaryData.hasProposedOverage && !isEditMode ? 'has-proposed-overage' : undefined,
            summaryData.hasOverage && !isEditMode ? 'has-overage' : undefined,
            isEditMode ? 'edit-mode' : undefined,
            'fill-space'
        ].filter(notEmpty).join(' ');

        return <div
            tabIndex={isEditable ? 0 : undefined}
            ref={this.props.componentRef}
            data-selection-index={selectionIndex}
            onClick={onClick}
            onFocus={onFocus}
            onDoubleClick={isEditable ? () => this._startEditing() : undefined}
            onKeyDown={onKeyDown}
            onKeyPress={isEditable ? e => {
                if (!isEditMode && (e.key === 'Enter' || e.charCode >= 48 && e.charCode <= 57)) {
                    this._startEditing();
                }
                onKeyPress?.(e)
            } : undefined}
            className={className}>
            <div className={`cell-content fill-space columns-${timeType.length}`}>
                {isEditMode 
                    ? <EditMode 
                        editTimeType={editTimeType!}
                        summaryData={summaryData}
                        maxCapacityHours={getMaxCapacityHours(quantization)}
                        viewType={viewType}
                        onChangeSummaryData={_ => this.setState({summaryData: _})}
                        onEditComplete={this._onEditComplete}/>
                    : <ViewMode _startEditing={this._startEditing}  
                        summaryData={summaryData}
                        prevHours={prevHours}
                        maxCapacityHours={getMaxCapacityHours(quantization)}
                        viewType={viewType}
                        timeType={timeType}/>}
            </div>
        </div>;
    }

    private _startEditing = (editTimeType?: TimeType) => {
        if (editTimeType === undefined) {
            const { timeType } = this.props;
            if (timeType.includes('plan') && !timeType.includes('actual')) {
                editTimeType = 'plan';
            } else if (!timeType.includes('plan') && timeType.includes('actual')) {
                editTimeType = 'actual';
            }
        }

        if (editTimeType === undefined || !this.props.onEditStart) {
            return;
        }

        this.props.onEditStart?.();
        this.setState({ editTimeType });
    }

    private _onEditComplete = (_: number | null) => {
        this.props.onEditComplete?.(_, this.state.editTimeType!);
        this.setState({ editTimeType: undefined });
    }

    private _buildState(props: Props): State {
        return {
            isSelected: calculateIsSelected(props),
            summaryData: calculateSummaryData(props),
            prevHours: calculatePrevHours(props)
        };
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        calendar: state.calendar,
        resourcePlanningLevel: mapServerEntityType[state.tenant.resourcePlanningSettings?.resourcePlanningLevel!]!
    };
}

export default connect(mapStateToProps)(ResourceUsageCell);

export interface ISummaryInfo {
    fullFteCapacityHours: number,
    capacityHours: number,
    committedWork: number,
    proposedWork: number,
    actualWork: number,
    committedByEntity: Dictionary<number>,
    proposedByEntity: Dictionary<number>,
    actualByEntity: Dictionary<number>,
    availability: number,
    overage: number,
    hasLocalOverage: boolean,

    proposed_overage: number,
    proposed_hasLocalOverage: boolean,
    proposed_overage_byEntity: Dictionary<number>,
    proposed_hasLocalOverage_byEntity: Dictionary<boolean>,
}

export function calculateSummary(resource: Resource, startDate: Date, finishDate: Date, calendar: CalendarDataSet, entityType: EntityType, resourcePlanningLevel: EntityType)
    : ISummaryInfo {
    return calculateSummaryMemoized(resource, startDate.getTime(), finishDate.getTime(), calendar, entityType, resourcePlanningLevel);
}

const calculateSummaryMemoized = memoizeFunction((resource: Resource, startDate: number, finishDate: number, calendar: CalendarDataSet, entityType: EntityType,
    resourcePlanningLevel: EntityType) => calculateSummaryImpl(resource, new Date(startDate), new Date(finishDate), calendar, entityType, resourcePlanningLevel));

const getWorkingHoursBetweenDatesMemoized = memoizeFunction((startDate: Date, endDate: Date, calendar: CalendarDataSet) => 
    getWorkingHoursBetweenDates(startDate, endDate, calendar));

const createResourceCalendarMemoized = memoizeFunction((resource: Resource, calendar: CalendarDataSet) => 
    createResourceCalendar(resource, calendar));

function calculateSummaryImpl(resource: Resource, startDate: Date, finishDate: Date, calendar: CalendarDataSet, entityType: EntityType, resourcePlanningLevel: EntityType)
    : ISummaryInfo {
    const resourceCalendar = createResourceCalendarMemoized(resource, calendar);
    const fullFteCapacityHours = getWorkingHoursBetweenDatesMemoized(startDate, finishDate, calendar);
    const fullResourceCapacityHours = getWorkingHoursBetweenDatesMemoized(startDate, finishDate, resourceCalendar);
    const maxUnitsMultiplier = (resource.attributes.MaxUnitsPct ?? 0) / HUNDRED_PCT;
    const projectWorkMultiplier = (HUNDRED_PCT - (resource.attributes.NonProjectWorkPct ?? 0)) / HUNDRED_PCT;
    const capacityHours = roundToHundreds(fullResourceCapacityHours * maxUnitsMultiplier * projectWorkMultiplier);

    const checkEntityType = (usage: DailyUsage) => EntityType[ServerEntityType[usage.entityType]] === entityType
        || entityType === EntityType.Idea && EntityType[ServerEntityType[usage.entityType]] === resourcePlanningLevel;

    const plan = filterByDatesUsage(resource.usage, startDate, finishDate)
        .filter(_ => checkEntityType(_))
        .reduce((cum, _) => {
            const dailyKey = `${_.date.getTime()}`;
            const dailyPlan = cum.dailyPlan[dailyKey] ?? { date: _.date, committed: 0, proposed: 0, proposedByEntity: {} };
            dailyPlan.committed = dailyPlan.committed + (_.committed ? _.plannedHours : 0);
            dailyPlan.proposed = dailyPlan.proposed + (!_.committed ? _.plannedHours : 0);
            dailyPlan.proposedByEntity[_.entityId] = (dailyPlan.proposedByEntity[_.entityId] ?? 0) + (!_.committed ? _.plannedHours : 0);
            return {
                committedByEntity: { ...cum.committedByEntity, [_.entityId]: (cum.committedByEntity[_.entityId] ?? 0) + (_.committed ? _.plannedHours : 0) },
                proposedByEntity: { ...cum.proposedByEntity, [_.entityId]: (cum.proposedByEntity[_.entityId] ?? 0) + (!_.committed ? _.plannedHours : 0) },
                actualByEntity: { ...cum.actualByEntity, [_.entityId]: (cum.actualByEntity[_.entityId] ?? 0) + _.actualHours },
                dailyPlan: { ...cum.dailyPlan, [dailyKey]: dailyPlan }
            };
        }, {
            committedByEntity: {} as Dictionary<number>,
            proposedByEntity: {} as Dictionary<number>,
            actualByEntity: {} as Dictionary<number>,
            dailyPlan: {} as Dictionary<{ date: Date, committed: number, proposed: number, proposedByEntity: Dictionary<number> }>
        });

    const committedWork = roundToHundreds(Object.keys(plan.committedByEntity).reduce((cum, _) => cum + plan.committedByEntity[_], 0));
    const proposedWork = roundToHundreds(Object.keys(plan.proposedByEntity).reduce((cum, _) => cum + plan.proposedByEntity[_], 0));
    const actualWork = roundToHundreds(Object.keys(plan.actualByEntity).reduce((cum, _) => cum + plan.actualByEntity[_], 0));
    const availability = Math.max(0, capacityHours - committedWork);
    const overage = committedWork > capacityHours ? roundToHundreds(committedWork - capacityHours) : 0;
    const proposed_overage = (committedWork + proposedWork) > capacityHours ? roundToHundreds(committedWork + proposedWork - capacityHours) : 0;

    const proposedState = Object.keys(plan.proposedByEntity)
        .reduce((cum, entityId) => {
            const proposedOverageByEntity = (committedWork + plan.proposedByEntity[entityId]) > capacityHours
                ? roundToHundreds(committedWork + plan.proposedByEntity[entityId] - capacityHours)
                : 0;
            const hasLocalProposedOverageByEntity = overage > 0
                ? true
                : !!Object.keys(plan.dailyPlan)
                    .find(_ => roundToHundreds(plan.dailyPlan[_].committed + plan.dailyPlan[_].proposedByEntity[entityId])
                        > roundToHundreds(maxUnitsMultiplier * projectWorkMultiplier * plan.dailyPlan[_].date.getWorkingHours(resourceCalendar)));
            return {
                proposedOverageByEntity: { ...cum.proposedOverageByEntity, [entityId]: proposedOverageByEntity },
                hasLocalProposedOverageByEntity: { ...cum.hasLocalProposedOverageByEntity, [entityId]: hasLocalProposedOverageByEntity },
            };
        }, { proposedOverageByEntity: {} as Dictionary<number>, hasLocalProposedOverageByEntity: {} as Dictionary<boolean> });

    return {
        fullFteCapacityHours,
        capacityHours,
        committedWork,
        proposedWork,
        actualWork,
        committedByEntity: plan.committedByEntity,
        proposedByEntity: plan.proposedByEntity,
        actualByEntity: plan.actualByEntity,
        availability,
        overage,
        hasLocalOverage: overage > 0
            ? true
            : !!Object.keys(plan.dailyPlan)
                .find(_ => roundToHundreds(plan.dailyPlan[_].committed)
                    > roundToHundreds(maxUnitsMultiplier * projectWorkMultiplier * plan.dailyPlan[_].date.getWorkingHours(resourceCalendar))),
        proposed_overage,
        proposed_hasLocalOverage: proposed_overage > 0
            ? true
            : !!Object.keys(plan.dailyPlan)
                .find(_ => roundToHundreds(plan.dailyPlan[_].committed + plan.dailyPlan[_].proposed)
                    > roundToHundreds(maxUnitsMultiplier * projectWorkMultiplier * plan.dailyPlan[_].date.getWorkingHours(resourceCalendar))),
        proposed_overage_byEntity: proposedState.proposedOverageByEntity,
        proposed_hasLocalOverage_byEntity: proposedState.hasLocalProposedOverageByEntity
    };
}

export const hasOutsideAllocation = memoizeFunction((usage: ResourceUsage | undefined, startDate: MaybeDate, finishDate: MaybeDate, entityId: string) => {
    const start = toDate(startDate)?.getBeginOfDay().getTime();
    const finish = toDate(finishDate)?.getEndOfDay().getTime();
    if (!startDate || !finishDate) {
        return false;
    }
    return !!findUsage(usage, entityId, _ => !!_.plannedHours && (!!start && (_.date.getTime() < start) || (!!finish && _.date.getTime() > finish)));
});

export const allocationWarning = (entityType: EntityType) => ({
    type: "allocation",
    text: `Resource allocation is outside the overall ${entityLogoConfig[entityType]?.label} dates range. 
    Please consider changing resource allocation or ${entityLogoConfig[entityType]?.label} dates.`
});