import React from "react";
import { StatusCategory, statusCategoryMap } from "../../../../entities/common";
import CategoryColorStatusView from "./CategoryColorStatusView";
import { ColoredStatusViewProps } from "./ColoredStatusView";


type Props = Omit<ColoredStatusViewProps, 'value'> & {
    value: StatusCategory | undefined;
};
const ColorStatusView = (props: Props) => {
    const { value } = props;
    const config = value !== undefined ? statusCategoryMap[value] : undefined;
    return (
        <CategoryColorStatusView
            {...props}
            statusOption={config && value !== undefined
                ? {
                    name: config.title,
                    color: config.color,
                    category: value,
                }
                : undefined
            }
        />
    );
};

export const ColorStatusColumn = (props: Props) => <div className="color-status-wrapper">
    <ColorStatusView {...props} />
</div>

export default ColorStatusView;