import * as React from 'react';
import * as Metadata from "../../../entities/Metadata";
import BaselineRow from './BaselineRow';

type Props = {
    field: Metadata.Field;
    number?: number | null;
    baseline?: number | null;
    variance?: number | null;
}

const BaselineNumberRow = (props: Props) => <BaselineRow
    label={Metadata.getLabel(props.field)}
    value={props.number?.toString()}
    baseline={props.baseline?.toString()}
    variance={props.variance?.toString()}
    isRed={props.number !== undefined && props.number !== null
        && props.baseline !== undefined && props.baseline !== null
        && props.number > props.baseline} />
export default BaselineNumberRow;