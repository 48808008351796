import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PanelType } from "office-ui-fabric-react/lib/components/Panel";
import { DefaultButton, IButton, IconButton } from 'office-ui-fabric-react/lib/components/Button';
import { IValidator, Validator } from "../../validation";
import { IRefObject, Overlay } from 'office-ui-fabric-react';
import { DirtyFormMessageBar } from './DirtyFormMessageBar';
import { REGULAR_MAX_LENGTH } from './inputs/TextInput';
import ExpandablePanel from './ExpandablePanel';
import Spinner from './Spinner';

export interface ICommand {
    primary?: boolean;
    right?: boolean;
    iconName?: string;
    text: string;
    title?: string;
    onClick: () => void;
    disabled?: boolean;
    componentRef?: IRefObject<IButton>;
}

export interface IHeader {
    text: string | JSX.Element;
    secondaryText?: string | JSX.Element;
    showNameEditor?: boolean;
    disableNameEditor?: boolean;
    nameEditorLabel?: string;
    onChanged?: (newValue: any) => void;
    validator?: IValidator;
    value?: string;
    suffixRender?: (entity: any) => JSX.Element | undefined;
}

interface IItemCreationProps {
    onDismiss: (ev: React.SyntheticEvent<HTMLElement>) => void;
    commands?: ICommand[];
    header: IHeader;
    className?: string;
    customWidth?: string;
    isDirty?: boolean;
    showSpinner?: boolean;
}

export const ItemCreation = (props: React.PropsWithChildren<IItemCreationProps>) => {
    const [showHasChangesWarning, setShowHasChangesWarning] = React.useState(false);

    const _onDismiss = React.useCallback((ev: React.SyntheticEvent<HTMLElement>) => {
        if (props.isDirty && ev?.currentTarget?.classList?.contains("ms-Overlay")) {
            setShowHasChangesWarning(true);
            ev.preventDefault();
            return;
        }
        props.onDismiss(ev);
    }, [props.isDirty, props.onDismiss, setShowHasChangesWarning]);

    const _onRenderHeader = React.useCallback((): JSX.Element | null => {
        return <div className="ms-Panel-header">
            {!!props.header.text && <div className="ms-Panel-headerText">{props.header.text}</div>}
            {!!props.header.secondaryText && <div className='ms-Panel-secondaryText'>{props.header.secondaryText}</div>}
        </div>;
    }, [props.header.text, props.header.secondaryText]);

    const _onRenderFooterContent = React.useCallback((): JSX.Element | null => {
        const primaryCommand = props.commands!.find(_ => _.primary);
        const cancelCommand = props.commands!.find(_ => !_.primary);
        return (
            <>
                {showHasChangesWarning && <DirtyFormMessageBar primaryCommandLabel={primaryCommand?.text} cancelCommandLabel={cancelCommand?.text} />}
                <div className="commands justify">
                    <div>
                        {props
                            .commands!.filter(_ => !_.right)
                            .map(_ => (
                                <DefaultButton
                                    componentRef={_.componentRef}
                                    primary={_.primary}
                                    disabled={props.showSpinner || _.disabled}
                                    key={_.text}
                                    text={_.text}
                                    iconProps={{ iconName: _.iconName }}
                                    onClick={_.onClick}
                                    title={_.title}
                                />
                            ))}
                    </div>
                    {props
                        .commands!.filter(_ => _.right)
                        .map(_ => (
                            <IconButton
                                componentRef={_.componentRef}
                                primary={_.primary}
                                disabled={props.showSpinner || _.disabled}
                                key={_.text}
                                text={_.text}
                                iconProps={{ iconName: _.iconName }}
                                onClick={_.onClick}
                                title={_.title}
                                style={{ color: 'red' }}
                            />
                        ))}
                </div>
            </>
        );
    }, [props.commands, props.showSpinner, showHasChangesWarning]);

    const _onChange = React.useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        props.header.onChanged?.(newValue);
    }, [props.header.onChanged]);

    return <ExpandablePanel
        isLightDismiss={!props.showSpinner}
        type={PanelType.custom}
        customWidth={props.customWidth || "400px"}
        className={props.className}
        isOpen
        onDismiss={_onDismiss}
        onRenderHeader={_onRenderHeader}
        onRenderFooterContent={!!props.commands?.length ? _onRenderFooterContent : undefined}>
        {props.header.showNameEditor !== false && <TextField
            autoFocus
            onChange={_onChange}
            label={props.header.nameEditorLabel}
            validateOnFocusOut
            onGetErrorMessage={(value) => Validator.new().maxLength(REGULAR_MAX_LENGTH).build().getErrorMessage(value)
                || props.header.validator?.getErrorMessage(value)}
            value={props.header.value}
            maxLength={REGULAR_MAX_LENGTH}
            required
            disabled={props.header.disableNameEditor}
        />}
        {props.children}
        {props.showSpinner && <Overlay><Spinner /></Overlay>}
    </ExpandablePanel>
}