import React from 'react'
import { TimeTrackingEntrySourceType, TimeTrackingLine } from '../../store/TimeTrackingStore'
import { IMaybeTotal, WithEntityType } from './TimeTrackingGrid'
import EntityName from '../views/list/columns/EntityName';
import { EntityType } from '../../entities/common';
import TimeTrackingAdministrativeCategoryEntityName from './TimeTrackingAdministrativeCategoryEntityName';
import { Field } from '../../entities/Metadata';
import { EntityRowMenuColumnKey } from '../common/extensibleEntity/EntityTimelineList';
import { Icon, TooltipHost } from 'office-ui-fabric-react';
import TimeTrackingTaskTooltip from './tooltips/entity/TimeTrackingTaskTooltip';
import TimeTrackingAdminCategoryTooltip from './tooltips/entity/TimeTrackingAdminCategoryTooltip';
import Link from '../common/Link';
import { default as TaskTitle } from '../views/list/columns/task/Title';

type Props = {
    timeTrackingLine: TimeTrackingLine & IMaybeTotal & WithEntityType;
    defaultRender: () => JSX.Element;
    field: Field;
}

export default function TimeTrackingLineEntityName(props: Props) {

    const { timeTrackingLine, defaultRender, field } = props;

    if (field.id === EntityRowMenuColumnKey) {
        return defaultRender();
    }

    if (timeTrackingLine.isTotal) {
        return <div className='total'>
            {defaultRender()}
        </div>
    }

    if (timeTrackingLine.attributes.IsAdministrative) {

        if (timeTrackingLine.entityType === EntityType.Project) {
            return (
                <EntityName
                    name={timeTrackingLine.attributes.Project.name}
                    iconName="PPMXAdministrativeTime"
                    imageClassName="administrative-time-logo"
                />
            );
        }
    
        if (timeTrackingLine.entityType === EntityType.Task) {
            
            return (
            
                <TooltipHost
                    tooltipProps={{
                        onRenderContent: () => <TimeTrackingAdminCategoryTooltip categoryId={timeTrackingLine.attributes.Task.id} />,
                        maxWidth: "440px"
                    }}>

                    <TimeTrackingAdministrativeCategoryEntityName categoryId={timeTrackingLine.attributes.Task.id} />
            
                </TooltipHost>);
        }
    }

    if (timeTrackingLine.entityType === EntityType.Project) {

        return (
            <div className='align-center'>
                <EntityName
                    name={timeTrackingLine.attributes.Project.name}
                    imageClassName={
                        `proj-logo 
                        ${timeTrackingLine.attributes.ProjectDetails?.isArchived && 'archived'}
                        ${timeTrackingLine.attributes.ProjectDetails?.isDeleted && 'deleted'}`}
                    imageId={timeTrackingLine.attributes.ProjectDetails?.imageId}>
                </EntityName>
            
                <Link className='navigate-external' target='_blank' href={`/project/${timeTrackingLine.attributes.Project.id}`}>
                    <Icon iconName="NavigateExternalInline" />
                </Link>
            </div>
        );
    }

    if (timeTrackingLine.entityType === EntityType.Task) {
        
        const isDeleted = timeTrackingLine.attributes.TaskDetails?.isDeleted || timeTrackingLine.attributes.ProjectDetails?.isDeleted;
        const isArchived = timeTrackingLine.attributes.TaskDetails?.isArchived || timeTrackingLine.attributes.ProjectDetails?.isArchived;

        if (timeTrackingLine.attributes.IsCustomEvent) {
            return <div className='align-center'>
                <EntityName
                    name={timeTrackingLine.attributes.Task.name}
                    className={`
                    ${isArchived ? 'archived' : ''}
                    ${isDeleted ? 'deleted' : ''}`
                    }
                
                    renderLogo={() => <div className='logo task-logo align-center custom-time-entry-logo'>

                        <Icon iconName="StatusCircleCheckmark" className='icon' />
                        <Icon className='person' iconName='PPMXPerson' />

                    </div>}>
       
                </EntityName>

                {
                    !!timeTrackingLine.attributes.IsSuggestion &&
                    <Icon iconName="PPMXSmartSuggestion" className='smart-suggestion' title='Smart Suggestion' />
                }

                {
                    !!timeTrackingLine.TimeEntries.some(_ => _.sourceInfos?.some(si => si.type === TimeTrackingEntrySourceType.O365)) &&
                    <Icon iconName="M365Logo" className='imported-from-m365' title='Imported from M365' />
                }
            </div>
        }

        const taskUrl = `/project/task/${timeTrackingLine.attributes.Task.uniqueId ?? timeTrackingLine.attributes.Task.id}`;

        const taskName = timeTrackingLine.attributes.Task.name;
        
        const task = timeTrackingLine.attributes.TaskDetails;

        return (

            <TooltipHost
                tooltipProps={{
                    onRenderContent: () => {

                        if (!task) {
                            return null;
                        }

                        return <TimeTrackingTaskTooltip task={task} />
                    },
                    maxWidth: "440px"
                }}>

                <div className='align-center'>
                    <TaskTitle
                        entity={task || { attributes: {}, externalData: {} } as any}
                        name={taskName}
                        className={`
                            ${isArchived ? 'archived' : ''}
                            ${isDeleted ? 'deleted' : ''}`}
                        field={field}
                        isTimelineView
                        disableNavigation />
                    
                    {
                        !!timeTrackingLine.attributes.IsSuggestion &&
                        <Icon iconName="PPMXSmartSuggestion" className='smart-suggestion' title='Smart Suggestion' />
                    }

                    {
                        !!taskUrl &&
                        <Link className='navigate-external' target='_blank' href={taskUrl} title='View task'>
                            <Icon iconName="NavigateExternalInline" />
                        </Link>
                    }
                </div>
            </TooltipHost>
        );
    }

    return defaultRender();
}