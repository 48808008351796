import * as React from "react";
import { connect } from "react-redux";
import { inIframe } from "../utils/common";
import { ApplicationState } from "../../store/index";
import { Subscription } from "../../store/Tenant";
import HeaderLogoSidebarButton from "./HeaderLogoSidebarButton";
import NavigationHeaderActions from "./NavigationHeaderActions";
import { NavigationProps } from "./Breadcrumbs";

type OwnProps = NavigationProps & {
    allowChangeAccount?: boolean;
};

type StateProps = {
    subscription: Subscription;
};

type Props = OwnProps & StateProps;

const NavigationHeader = ({ route, router, allowChangeAccount }: Props) => (
    <div className={`nav-header ${inIframe() ? "teamsApp" : ""}`}>
        <HeaderLogoSidebarButton route={route} router={router} />
        <NavigationHeaderActions route={route} router={router} allowChangeAccount={!!allowChangeAccount} />
    </div>
);

const mapStateToProps = (state: ApplicationState): StateProps => ({
    subscription: state.tenant.subscription,
});

export default connect(mapStateToProps)(NavigationHeader);
