import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Dropdown, IDropdownOption, MessageBar, MessageBarType, Icon, IconButton, ActionButton, Overlay, ComboBox, ISelectableOption } from 'office-ui-fabric-react';
import { ApplicationState } from '../../../store';
import { IFieldMappingDto, MappingType, ExternalFieldInfo, IFieldMappingKeyMap } from '../../../store/integration/common';
import { Field, FieldInfo, FieldType, getLabel } from "../../../entities/Metadata";
import Spinner from "../../common/Spinner";
import FieldPanel from "../../field/FieldPanel";
import { SourceTypeMap, SourceType } from '../../../store/ExternalEpmConnectStore';
import * as FieldsStore from "../../../store/fields";
import { FieldMappingService } from "../../common/DisplayFieldService";
import { UserState } from '../../../store/User';
import { CommonOperations, contains } from '../../../store/permissions';
import { EntityType } from '../../../entities/common';
import { TransformationPanel } from '../TransformationPanel';
import { upperFirstSort } from '../../utils/common';
import { PPMXFieldComboBox } from './PPMXFieldComboBox';

type OwnProps = {
    entityType: EntityType;
    connector: SourceType;

    ppmxfields: Field[];
    externalFields: ExternalFieldInfo[];

    typesMap: { [externalFieldType: string]: { types: Partial<FieldType>[], label: string } };
    mapping: IFieldMappingKeyMap[];
    mappingTypes: MappingType[];
    onMappingUpdated: (mapping: IFieldMappingKeyMap[]) => void;
    onGetExternalFieldOptions?: (field: ExternalFieldInfo) => Promise<string[] | number[] | null>

    fieldPanelWidth: string;
    showTransformationScript?: boolean;

    isLoading: boolean;
    error: string | null;
    disabled?: boolean;
}

type StateProps = {
    ppmxFieldCreationInfo?: FieldsStore.FieldCreationInfo;
    user: UserState;
}

type Props = OwnProps & StateProps & {
    fieldsActions: ReturnType<typeof FieldsStore.actionCreators.forEntity>
}

type State = {
    mapping: IFieldMappingKeyMap[];
    showFieldPanel: boolean;
    isCreatingPpmxField: boolean;
    createdFieldRowId?: number;
    showTransformMap?: string;
    allowManageFields: boolean;
}

class MappingControl extends React.Component<Props, State> {
    private directions: any[] = [];

    constructor(props: Props) {
        super(props);

        this.state = {
            mapping: props.mapping,
            showFieldPanel: false,
            isCreatingPpmxField: false,
            allowManageFields: contains(props.user.permissions.common, CommonOperations.ConfigurationManage)
        }

        this.directions = [
            { key: MappingType.ToPpmx, text: "To PPM Express", data: { icon: "PPMXArrowLeft" } },
            { key: MappingType.FromPpmx, text: `To ${SourceTypeMap[this.props.connector]}`, data: { icon: "PPMXArrowRight" } },
        ]
            .filter(_ => ~props.mappingTypes.indexOf(_.key));
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.mapping != nextProps.mapping) {
            this.setState({ mapping: nextProps.mapping });
        }

        if (this.props.user !== nextProps.user) {
            this.setState({ allowManageFields: contains(nextProps.user.permissions.common, CommonOperations.ConfigurationManage) });
        }

        if (nextProps.ppmxFieldCreationInfo) {
            const created = !!this.props.ppmxFieldCreationInfo && this.props.ppmxFieldCreationInfo.isProcessing && !nextProps.ppmxFieldCreationInfo.isProcessing;
            if (created && this.state.createdFieldRowId !== undefined) {
                const updatedMapping = [...this.state.mapping];
                const updatedMappingKeyMap = updatedMapping[this.state.createdFieldRowId];
                const ppmxField = nextProps.ppmxfields.find(_ => _.id == nextProps.ppmxFieldCreationInfo!.createdFieldId);
                if (ppmxField) {
                    updatedMappingKeyMap.mapping = { ...updatedMappingKeyMap.mapping, ppmxField: ppmxField.name };
                    nextProps.onMappingUpdated(updatedMapping);
                }
            }

            const isCreatingPpmxField = !(this.props.ppmxFieldCreationInfo && this.props.ppmxFieldCreationInfo.isProcessing) && nextProps.ppmxFieldCreationInfo.isProcessing;
            this.setState({ isCreatingPpmxField });
        }
    }

    public render() {
        return (
            <div className="mapping-wrap">
                {
                    this.props.isLoading && !this.props.error
                        ? <Overlay><Spinner /></Overlay>
                        : this._onRenderMapping()
                }
                {
                    this.state.showFieldPanel &&
                    <FieldPanel
                        key='field_panel'
                        entityType={this.props.entityType}
                        actions={{ saveField: this._onSaveField }}
                        allowManageFields={this.state.allowManageFields}
                        onDismiss={() => this.setState({ showFieldPanel: false })}
                        customWidth={this.props.fieldPanelWidth}
                        showSpinner={this.state.isCreatingPpmxField}
                    />}
            </div>
        );
    }

    private _onSaveField = (field: FieldInfo) => {
        this.props.fieldsActions.saveField(field);
        this.setState({ showFieldPanel: false });
    }

    private _onRenderMapping = (): JSX.Element => {
        const selectedPpmxfields: string[] = [];
        const selectedExternalFields: string[] = [];

        this.state.mapping.forEach(_ => {
            const map = _.mapping;
            if (map.ppmxField) {
                selectedPpmxfields.push(map.ppmxField);
            }
            if (map.externalField) {
                selectedExternalFields.push(map.externalField);
            }
        });

        return (
            <div>
                <div className={"mapping" + (this.directions.length === 1 ? " single-direction" : "")}>
                    <div className="header align-center">
                        <div className="mapping-field">PPM Express field</div>
                        <div className="mapping-mappingtype"></div>
                        <div className="mapping-expression">{SourceTypeMap[this.props.connector]} field</div>
                    </div>
                    {
                        this.state.mapping.map((_, index) => this._renderRow(
                            _,
                            index,
                            selectedPpmxfields,
                            selectedExternalFields))
                    }
                </div>
                <div className="mapping-commands-container">
                    <ActionButton iconProps={{ iconName: 'Add' }}
                        text="Add Mapping Fields"
                        className="new-button"
                        onClick={() => {
                            this.addOrUpdateRow(this.state.mapping.length, FieldMappingService.getDefaultMapping(this.props.mappingTypes))
                        }} />
                </div>
                {
                    this.props.error &&
                    <p>
                        <MessageBar
                            messageBarType={MessageBarType.severeWarning}
                            isMultiline={true}>
                            {this.props.error}
                        </MessageBar>
                    </p>
                }
            </div>
        );
    }

    private _renderRow = (map: IFieldMappingKeyMap, rowNumber: number, selectedPpmxFields: string[], selectedExternalFields: string[]): JSX.Element => {
        const { disabled } = this.props;
        
        if (disabled || map.mapping.isReadonly) {
            const ppmxField = this.props.ppmxfields.find(_ => _.name == map.mapping.ppmxField);
            const externalField = this.props.externalFields.find(_ => _.internalName == map.mapping.externalField);
            return <div className="row" key={map.key}>
                <div className="align-center">
                    <ComboBox
                        disabled={true}
                        text={ppmxField && getLabel(ppmxField)}
                        className="mapping-field"
                        dropdownWidth={300} />
                    {this._renderDirection(rowNumber, map.mapping.mappingType)}
                    <ComboBox
                        disabled={true}
                        text={externalField?.name}
                        title={externalField?.name}
                        className="mapping-expression"
                        dropdownWidth={300} />
                </div>
            </div>;
        }

        const spf = selectedPpmxFields.filter(f => map.mapping.mappingType === MappingType.ToPpmx && f !== map.mapping.ppmxField);
        const sef = selectedExternalFields.filter(f => map.mapping.mappingType === MappingType.FromPpmx && f !== map.mapping.externalField);

        const externalOptions: ISelectableOption[] = this.props.externalFields
            .filter(_ => map.mapping.mappingType === MappingType.FromPpmx ? !_.isReadOnly : true)
            .filter(_ => sef.indexOf(_.internalName) === -1)
            .map((_) => ({ key: _.internalName, text: _.name }))
            .sort((a, b) => upperFirstSort(a.text, b.text));

        const { externalFields, typesMap, ppmxfields } = this.props;
        const { isValidRow, errorMessage } = FieldMappingService.validateRow(map.mapping, externalFields, ppmxfields, typesMap);
        const { showTransformMap } = this.state;

        const externalField = externalFields.find(_ => _.internalName === map.mapping.externalField)!;
        const ppmxField = ppmxfields.find(_ => _.name === map.mapping.ppmxField)!;

        return (
            <div className="row" key={map.key} >
                <div className="align-center">
                    <PPMXFieldComboBox
                        fields={this.props.ppmxfields
                            //exclude ppmx lookup fields from mapping ToPpmx
                            .filter(_ => map.mapping.mappingType === MappingType.ToPpmx ? !_.isReadonly : true)
                            .filter(_ => spf.indexOf(_.name) === -1)}
                        selected={map.mapping.ppmxField}
                        allowManageFields={this.state.allowManageFields}
                        isValid={isValidRow}
                        rowNumber={rowNumber}
                        onChangeField={this.onChangePPMXField}
                        onAddNewField={this._onAddNewField}
                    />
                    {this._renderDirection(rowNumber, map.mapping.mappingType)}
                    <ComboBox
                        options={externalOptions}
                        selectedKey={map.mapping.externalField}
                        allowFreeform={true}
                        placeholder="Select field"
                        className={"mapping-expression" + (!isValidRow ? " error" : "")}
                        onChange={(e, o) => this.onChangeExternalField(rowNumber, o)}
                        dropdownWidth={300} />
                    {
                        this.props.showTransformationScript &&
                        <IconButton menuIconProps={{ iconName: 'Settings' }}
                            title="Show Transformation Rules"
                            className={'menu' + (this._isTransformatonDisabled(ppmxField, externalField) ? " disabled" : "")}
                            disabled={this._isTransformatonDisabled(ppmxField, externalField)}
                            onClick={() => this.toggleShowTransform(map)} />
                    }
                    <IconButton key="remove"
                        menuIconProps={{ iconName: 'Delete' }}
                        title="Remove"
                        className='menu'
                        onClick={() => this.removeRow(rowNumber)} />
                </div>
                {
                    showTransformMap == map.key && map.mapping.mappingType != null &&
                    <TransformationPanel value={map.mapping.transform}
                        ppmxField={ppmxField}
                        externalFieldValuesSuggesions={this.props.onGetExternalFieldOptions?.(externalField)}
                        mappingType={map.mapping.mappingType}
                        onOk={(value) => {
                            this.setState({ showTransformMap: undefined });
                            this.onChangeTransform(rowNumber, value)
                        }}
                        onDismiss={() => this.setState({ showTransformMap: undefined })}
                    />
                }
                <div className="error-message">
                    {errorMessage}
                </div>
            </div>
        );
    }

    private _onAddNewField = (rowNumber: number) => {
        this.setState({ showFieldPanel: true, createdFieldRowId: rowNumber });
    }

    private _renderDirection = (rowNumber: number, selectedMappingType?: MappingType) => {
        if (this.directions.length === 1) {
            if (selectedMappingType == this.directions[0].key) {
                return (
                    <div className="mapping-direction">
                        <Icon iconName={this.directions[0].key == MappingType.ToPpmx ? "ChromeBack" : "ChromeBackMirrored"} />
                    </div>
                );
            }
        }

        return <Dropdown
            placeholder="Select field"
            options={this.directions}
            selectedKey={selectedMappingType}
            onRenderOption={this._renderOptionWithIcon}
            onChange={(e, o) => this.onChangeMappingType(rowNumber, e, o)}
            className="mapping-mappingtype" />
    }

    private _isTransformatonDisabled = (ppmxField: Field, externalField: ExternalFieldInfo): boolean => {
        return !ppmxField || !externalField || !FieldMappingService.isTransformationPossible(ppmxField, externalField, this.props.typesMap)
    }

    private addOrUpdateRow = (index: number, mappingUpdate: Partial<IFieldMappingDto>) => {
        const newMapping = [...this.state.mapping];

        if (index < newMapping.length) {
            newMapping[index].mapping = { ...newMapping[index].mapping, ...mappingUpdate };
        } else {
            newMapping.push({ key: FieldMappingService.getNewKey(), mapping: { ...FieldMappingService.getDefaultMapping(this.props.mappingTypes), ...mappingUpdate } });
        }

        this.setState({ mapping: newMapping });

        this.props.onMappingUpdated(newMapping);
    }

    private removeRow = (index: number) => {
        const newMapping = [...this.state.mapping];
        newMapping.splice(index, 1);

        this.setState({ mapping: newMapping });
        this.props.onMappingUpdated(newMapping);
    }

    private _renderOptionWithIcon = (option?: IDropdownOption): JSX.Element | null => {
        if (!option) {
            return null;
        }

        return <div style={{ whiteSpace: "nowrap" }}>
            {
                option.data &&
                option.data.icon &&
                <Icon
                    style={{ marginRight: '8px' }}
                    iconName={option.data.icon}
                    aria-hidden='true'
                    title={option.data.icon} />
            }
            <span>{option.text}</span>
        </div>;
    }

    private onChangePPMXField = (rowNumber: number, key?: string) => {
        this.addOrUpdateRow(rowNumber, { ppmxField: key, transform: undefined });
    }

    private onChangeExternalField = (rowNumber: number, option?: IDropdownOption) => {
        this.addOrUpdateRow(rowNumber, { externalField: option && option.key as string, transform: undefined });
    }

    private onChangeMappingType = (rowNumber: number, e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (!option) {
            return;
        }
        this.addOrUpdateRow(rowNumber, { mappingType: option.key as number, transform: undefined });
    }

    private toggleShowTransform = (map: IFieldMappingKeyMap) => {
        const { showTransformMap } = this.state;
        this.setState({ showTransformMap: showTransformMap == map.key ? undefined : map.key });
    }

    private onChangeTransform = (rowNumber: number, value?: string) => {
        this.addOrUpdateRow(rowNumber, { transform: value });
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        ppmxFieldCreationInfo: state.fields[ownProps.entityType].fieldCreationInfo,
        user: state.user
    }
}

function mergeActionCreators(dispatch: any, ownProps: OwnProps) {
    return {
        fieldsActions: bindActionCreators(FieldsStore.actionCreators.forEntity(ownProps.entityType), dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(MappingControl);
export type MappingControlProps = OwnProps;