import { EntityType, entityTypeLabelMap } from "../entities/common"

export class MenuTitleBuilder {
    private static getPluralEntityTypeLabel(pluralEntityTypeLabel: string | EntityType): string {
        return entityTypeLabelMap[pluralEntityTypeLabel]?.plural ?? pluralEntityTypeLabel;
    }

    public static readonly exportToPngTitle = (pluralEntityTypeLabel: string | EntityType) => `Export ${MenuTitleBuilder.getPluralEntityTypeLabel(pluralEntityTypeLabel)} into a screenshot in PNG format (up to 300 lines can be exported).`;
    public static readonly importFromCSVTitle = (pluralEntityTypeLabel: string | EntityType) => `Import ${MenuTitleBuilder.getPluralEntityTypeLabel(pluralEntityTypeLabel)} from a CSV file`;
    public static readonly exportSelectedToCSVTitle = (pluralEntityTypeLabel: string | EntityType) => `Export the selected ${MenuTitleBuilder.getPluralEntityTypeLabel(pluralEntityTypeLabel)} to a CSV file`;
    public static readonly deleteSelectedTitle = (pluralEntityTypeLabel: string | EntityType) => `Delete the selected ${MenuTitleBuilder.getPluralEntityTypeLabel(pluralEntityTypeLabel)}`;
}
