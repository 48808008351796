import * as React from 'react';
import './GroupedFieldsConfiguration.css';
import { Field, FieldGroup } from '../../../../entities/Metadata';
import { usePrevious } from '../../../utils/effects';
import { EntityType } from '../../../../entities/common';
import NotFound from '../NotFound';
import { useFilterFields } from '../FieldsList';
import { IFieldActions } from '../../FieldPanel';
import { filterUnique, splitArray } from '../../../utils/common';
import FieldsInThisViewSection, { ColumnGroupedFieldNames, useFlattenColumnGroupedFieldNames } from './FieldsInThisViewSection';
import AvailableFieldsSection from './AvailableFieldsSection';

export type GroupedFieldsConfigurationProps = {
    fields: Field[];
    selected: ColumnGroupedFieldNames;
    entityType: EntityType;
    headerText?: string;
    secondaryText?: string;
    allowManageFields?: boolean;
    hideNewFieldButton?: boolean;
    fieldActions?: IFieldActions;
    showSpinner?: boolean;
    filter?: string;
    mandatoryFields?: string[];
    flattenFieldsInThisViewGroups?: boolean;
    flattenAvailableFieldGroups?: boolean;
    onDismiss: () => void;
    onChange?: (fields: ColumnGroupedFieldNames) => void;
};

const GroupedFieldsConfiguration = (props: GroupedFieldsConfigurationProps) => {
    const { fields, filter, flattenFieldsInThisViewGroups, flattenAvailableFieldGroups, onChange } = props;

    const selected = useOptimisticSelected(props);
    const [selectedFields, disabledFields] = useSplitFields(selected, fields);
    const newFields = useNewFieldsColumnGroupedHandler(props);
    
    const filteredSelectedFieldsCount = useCountFilteredFields(selectedFields, filter);
    const filteredDisabledFieldsCount = useCountFilteredFields(disabledFields, filter);

    const onDisabledFieldsChange = React.useCallback((changedDisabledFields: Field[]) => onChange?.({
        ...selected,
        column1: [...selected.column1, ...(changedDisabledFields.map(__ => __.name))],
    }), [selected, onChange]);

    const isAvailableFieldsGroupEmpty = React.useCallback((group: FieldGroup) => selectedFields.some(__ => __.group === group), [selectedFields]);
    
    if (filteredSelectedFieldsCount === 0 && filteredDisabledFieldsCount === 0) {
        return <NotFound />;
    }

    return <>
        <FieldsInThisViewSection
            {...props}
            selected={selected}
            selectedFields={selectedFields}
            newFields={newFields}
            flattenGroups={flattenFieldsInThisViewGroups}
        />
        <AvailableFieldsSection
            {...props}
            disabledFields={disabledFields}
            newFields={newFields}
            flattenGroups={flattenAvailableFieldGroups}
            isGroupEmpty={isAvailableFieldsGroupEmpty}
            onChange={onChange ? onDisabledFieldsChange : undefined}
        />
    </>;
}

type UseNewFieldColumnGroupedHandlerProps = {
    fields: Field[];
    selected: ColumnGroupedFieldNames;
    onChange?: (fields: ColumnGroupedFieldNames) => void;
};

export const useNewFieldsColumnGroupedHandler = ({ fields, selected, onChange }: UseNewFieldColumnGroupedHandlerProps) => {
    const [newFieldNames, setNewFieldName] = React.useState<string[]>([]);
    const newField = useNewField(fields);

    React.useEffect(() => {
        if (!newField) return;
        onChange?.({
            column1: [...selected.column1.filter(_ => fields.some(__ => __.name === _)), newField.name],
            column2: selected.column2,
        });
        setNewFieldName(newFieldNames.concat([newField.name]));
    }, [newField, fields, selected, onChange]);

    return newFieldNames;
};

const useSplitFields = (selected: ColumnGroupedFieldNames, fields: Field[]) => {
    const flattenedSelectedFieldNames = useFlattenColumnGroupedFieldNames(selected);
    return React.useMemo(
        () => splitArray(fields, _ => flattenedSelectedFieldNames.includes(_.name)),
        [fields, flattenedSelectedFieldNames]);
};

const useCountFilteredFields = (fields: Field[], filter: string | undefined) => {
    const filterFields = useFilterFields(filter);
    return React.useMemo(() => filterFields(fields).length, [fields, filterFields]);
};

const useNewField = (fields: Field[]) => {
    const oldFields = usePrevious(fields);
    return React.useMemo(() => {
        if (oldFields?.length && fields.length > oldFields.length) {
            const oldIds = oldFields.map(_ => _.id);
            return fields.find(_ => !oldIds.includes(_.id))!;
        }
        return undefined;
    }, [fields]);
};

const useOptimisticSelected = (props: GroupedFieldsConfigurationProps) => {
    const newlyCreatedField = useNewField(props.fields);
    return React.useMemo<ColumnGroupedFieldNames>(() => newlyCreatedField
        ? {
            ...props.selected,
            column1: filterUnique([...props.selected.column1, newlyCreatedField.name]),
        } : props.selected,
    [props.selected, newlyCreatedField]);
};

export default GroupedFieldsConfiguration;
