import * as React from 'react';
import { actionCreators, Resource } from "../../store/ResourcesListStore";
import { connect } from 'react-redux';
import { Panel, PanelType, DefaultButton, PrimaryButton, Persona, PersonaSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { getPersonInfoImageUrl } from '../utils/common';
import { SourceTypeMap } from '../../store/ExternalEpmConnectStore';

type OwnProps = {
    selected: Resource[];
    onDismiss: () => void;
    onComplete?: () => void;
}
type Props = OwnProps & typeof actionCreators;

interface State {
    targetId: string;
}

class MergePanel extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = {
            targetId: props.selected[0].id
        };
    }

    public render() {
        return <Panel
            isOpen={true}
            isLightDismiss={true}
            type={PanelType.custom}
            customWidth="400px"
            focusTrapZoneProps={{ disableFirstFocus: true }}
            onRenderHeader={this._onRenderHeader}
            onRenderFooterContent={this._onRenderFooterContent}
            onDismiss={this.props.onDismiss}>
            <MessageBar messageBarType={MessageBarType.warning}>
                Please choose one of the resources below all other selected resources will be merged to.
                </MessageBar>
            {this.props.selected.map(_ => this._renderResource(_))}
        </Panel>;
    }

    private _renderResource = (resource: Resource): JSX.Element =>
        <div key={resource.id} className={`res-tile ${this.state.targetId == resource.id ? 'checked' : ''}`}
            onClick={() => this.setState({ targetId: resource.id })}>
            <Persona size={PersonaSize.size40}
                imageUrl={getPersonInfoImageUrl(resource)}
                text={resource.name}
                secondaryText={`Email: ${resource.attributes.Email || '-'}`} />
            {resource.sourceInfos.map((_, i) => <div className="res-src" key={i}>{`Linked to ${SourceTypeMap[_.type]} user \"${_.sourceData.displayName}\"`}</div>)}
        </div>;

    private _onRenderHeader = (): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Merge Resources</p>
            <div className='ms-Panel-secondaryText'>Merge Resource records imported from multiple data sources that identify the same Resource.</div>
        </div>;
    }

    private _onRenderFooterContent = (): JSX.Element | null => {
        return <div className="commands">
            <PrimaryButton
                text="Merge"
                iconProps={{ iconName: "Save" }}
                onClick={() => {
                    this.props.merge(this.state.targetId, this.props.selected.map(_ => _.id));
                    this.props.onComplete?.();
                    this.props.onDismiss();
                }} />
            <DefaultButton
                text="Cancel"
                onClick={this.props.onDismiss} />
        </div>;
    }
}

export default connect(null, actionCreators)(MergePanel);