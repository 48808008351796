import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { ConnectionType, IPlannerConnectionState, actionCreators } from "../../../store/integration/Office365Store";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import OAuthConnectionCard from "./OAuthConnectionCard";
import { Office365Utils, PlannerPostMessageContext } from "../../integration/Office365/Office365Utils";
import { PostMessageDataBase } from "../../integration/PostMessageReceiver";
import * as Notifications from "../../../store/NotificationsStore";
import { bindActionCreators } from "redux";

type OwnProps = {}
type ActionProps = {
    notificationsActions: typeof Notifications.actionCreators,
    connectionActions: typeof actionCreators
}

type StateProps = { connections: IPlannerConnectionState };
type Props = StateProps & OwnProps & ActionProps;
const PlannerConnectionCard = (props: Props) => <OAuthConnectionCard
    system={SourceType.O365Planner}
    newConnectionUrl={"/api/integration/office365/auth"}
    newRestrictedConnectionUrl={Office365Utils.getNewRestrictedConnectionUrl(SourceType.O365Planner)}
    newConnectionTooltip={Office365Utils.getNewConnectionTooltip()}
    newRestrictedConnectionTooltip={Office365Utils.getNewRestrictedConnectionTooltip()}
    connections={props.connections.data.filter(_ => _.connectionType !== ConnectionType.AzureADSync)}
    onReceivedConnectionData={(data: PostMessageDataBase<PlannerPostMessageContext>) => {
        props.connectionActions.loadConnections();
        if (data.context?.scopeChanged) {
            props.notificationsActions.pushNotification({ message: Office365Utils.ScopeChangedMessage, type: Notifications.NotificationType.Warn });
        }
    }}
/>;
function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { connections: state.office365.connections }
}
export default connect(mapStateToProps, (dispatch): ActionProps =>
    ({
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        connectionActions: bindActionCreators(actionCreators, dispatch),
    })
)(PlannerConnectionCard);