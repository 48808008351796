import React from 'react';
import "./FlagFormatter.css";
import { Icon } from 'office-ui-fabric-react';

type FlagFormatterProps = {
    value?: boolean
} & FlagIconSetProps;

export type FlagIconSetProps = {
    yesIconName?: string,
    noIconName?: string,
}

export const FlagEyeIconSet: FlagIconSetProps = {
    yesIconName: 'View',
    noIconName: 'Hide3'
}

const FlagDefaultIconSet: FlagIconSetProps = {
    yesIconName: 'SkypeCircleCheck',
    noIconName: 'Blocked2'
}

export const FlagFormatter = (props: FlagFormatterProps) => {
    if (props.value) {
        return <div className='flag yes'>
            <Icon iconName={props.yesIconName ?? FlagDefaultIconSet.yesIconName} /> Yes
        </div>;
    }
    return <div className='flag no'>
        <Icon iconName={props.noIconName ?? FlagDefaultIconSet.noIconName} /> No
    </div>;
}
