import * as React from "react";
import ConnectionCard from './ConnectionCard';
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import PostMessageReceiver, { PostMessageData } from "../../../components/integration/PostMessageReceiver";
import ImportPanel from "../../import/project/ImportPanel";
import { IConnectionInfo } from "../../../entities/Metadata";
import { ApplicationState } from "../../../store";
import { canCreate, CommonOperations, contains } from "../../../store/permissions";
import { connect } from "react-redux";

type OwnProps = {
    system: SourceType;
    connections: IConnectionInfo[];
    newConnectionUrl: string;
    newRestrictedConnectionUrl?: string;
    onReceivedConnectionData: (data: PostMessageData) => void;
    newConnectionTooltip?: JSX.Element;
    newRestrictedConnectionTooltip?: JSX.Element;
}
type StoreProps = {
    canConnect: boolean;
    canImport: boolean;
}
type Props = OwnProps & StoreProps;
const OAuthConnectionCard = (props: Props) => {
    const target = `connectionDataReceived_${props.system}`;
    const onConnect = React.useCallback(() => PostMessageReceiver.openPopupWindow(props.newConnectionUrl, target), []);
    const onRestrictedConnect = props.newRestrictedConnectionUrl ? React.useCallback(() => PostMessageReceiver.openPopupWindow(props.newRestrictedConnectionUrl ?? '', target), []) : undefined;
    const [importFromConnectionId, setImportFromConnectionId] = React.useState<string | undefined>();
    const onDismissImport = React.useCallback(() => setImportFromConnectionId(undefined), []);
    const onImport = React.useCallback((connectionId: string) => setImportFromConnectionId(connectionId), []);
    return <>
        <PostMessageReceiver<PostMessageData>
            from={target}
            onMessageReceived={(_, data) => {
                props.onReceivedConnectionData(data);
                onImport(data.context?.connectionId);
            }} />
        <ConnectionCard {...props} onConnect={onConnect} onRestrictedConnect={onRestrictedConnect} onImport={onImport}/>
        {importFromConnectionId && <ImportPanel onDismiss={onDismissImport} integrations={[props.system]} connectionId={importFromConnectionId} />}
    </>;
}
function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        canConnect: contains(state.user.permissions.common, CommonOperations.ConnectionManage),
        canImport: canCreate(state.user.permissions.project),
    };
}
export default connect(mapStateToProps)(OAuthConnectionCard);
