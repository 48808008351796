import * as React from 'react';
import { PivotLinkSize, PivotLinkFormat, PivotItem, Pivot } from 'office-ui-fabric-react/lib/Pivot';
import { ComboBox, IComboBoxOption, Spinner, SpinnerSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as RoadmapsListStore from '../../store/RoadmapsListStore';
import * as ChallengesListStore from '../../store/ChallengesListStore';
import { ApplicationState } from '../../store';
import * as microsoftTeams from '@microsoft/teams-js'
import { UserState } from '../../store/User';
import { RouteComponentProps } from "react-router-dom";
import TenantCard from '../navigation/TenantCard';
import { Subscription, PPMFeatures } from '../../store/Tenant';
import { Roadmap } from '../../store/roadmap/common';
import { IBaseEntity } from '../../entities/common';
import './TeamsAppConfig.css'
import { notUndefined } from '../utils/common';

type TeamsAppConfigActions = {
    projectsListStore: typeof ProjectsListStore.actionCreators;
    programsListStore: typeof ProgramsListStore.actionCreators;
    portfoliosListStore: typeof PortfoliosListStore.actionCreators;
    roadmapsListStore: typeof RoadmapsListStore.actionCreators;
    challengesListStore: typeof ChallengesListStore.actionCreators;
};

type TeamsAppProps = {
    portfolios: PortfoliosListStore.Portfolio[];
    programs: ProgramsListStore.Program[];
    projects: ProjectsListStore.ProjectInfo[];
    roadmaps: Roadmap[];
    challenges: ChallengesListStore.Challenge[];
    projectsLoading: boolean;
    programsLoading: boolean;
    portfoliosLoading: boolean;
    roadmapsLoading: boolean;
    challengesLoading: boolean;
    user?: UserState;
    subscription: Subscription;
    tenantId: string;
    newTimeTrackingEnabled: boolean;
};

type TeamsAppState = {
    selectedEntity?: {
        type: string;
        id: string;
        name: string;
    };
    selectedPage?: {
        path: string;
        name: string;
    };

    hasPortfolioManagement: boolean;
    hasRoadmap: boolean;
    hasIdeation: boolean;
    hasTimeTracking: boolean;
};

type PivotPage = {
    key: string;
    name: string;
    description: string;
    path: string;
};

type OptionableEntity = IBaseEntity & {
    attributes: {
        Name: string
    };
};

class TeamsAppConfig extends React.Component<TeamsAppProps & TeamsAppConfigActions & RouteComponentProps<{}>, TeamsAppState> {

    constructor(props: TeamsAppProps & TeamsAppConfigActions & RouteComponentProps<{}>) {
        super(props);
        this.state = {
            hasPortfolioManagement: Subscription.contains(props.subscription, PPMFeatures.PortfolioManagement),
            hasRoadmap: Subscription.contains(props.subscription, PPMFeatures.Roadmap),
            hasIdeation: Subscription.contains(props.subscription, PPMFeatures.Ideation),
            hasTimeTracking: Subscription.contains(props.subscription, PPMFeatures.TimeTracking) && props.newTimeTrackingEnabled
        };
    }

    componentWillMount() {
        if (this.props.user?.id) {
            if (this.state.hasPortfolioManagement) {
                this.props.portfoliosListStore.requestPortfolios();
                this.props.programsListStore.requestPrograms();
            }
            this.props.projectsListStore.requestProjects();
            this.state.hasRoadmap && this.props.roadmapsListStore.requestRoadmaps();
            this.state.hasIdeation && this.props.challengesListStore.loadChallenges();
        }
        else {
            this.props.history.push("/setup/tenant");
        }
    }

    componentDidMount() {
        microsoftTeams.app.initialize().then(() => {
            microsoftTeams.pages.config.registerOnSaveHandler(this._onSaveHandler);
            microsoftTeams.pages.config.setValidityState(false);
        });
    }

    public render() {
        const { hasRoadmap, hasIdeation } = this.state;
        const pivotPages = this._getPivotPages();
        
        return (
            <div className="teamsApp-config">
                <Pivot className='entities' linkFormat={PivotLinkFormat.links} linkSize={PivotLinkSize.normal} hidden={!this.props.user} onLinkClick={(item?: PivotItem) => {
                    const page = item?.props.itemKey && pivotPages.find(_ => _.key === item?.props.itemKey);
                    if (page) {
                        this.setState({ selectedEntity: undefined, selectedPage: page });
                        microsoftTeams.pages.config.setValidityState(true);
                    } else {
                        this.setState({ selectedEntity: undefined, selectedPage: undefined });
                        microsoftTeams.pages.config.setValidityState(false);
                    }
                }}>
                    {this._renderEntitySelection("Portfolio", this._buildSortedOptions(this.props.portfolios), this.props.portfoliosLoading)}
                    {this._renderEntitySelection("Program", this._buildSortedOptions(this.props.programs), this.props.programsLoading)}
                    {this._renderEntitySelection("Project", this._buildSortedOptions(this.props.projects), this.props.projectsLoading)}
                    {hasRoadmap && this._renderEntitySelection("Roadmap", this._buildSortedOptions(this.props.roadmaps), this.props.roadmapsLoading)}
                    {hasIdeation && this._renderEntitySelection("Challenge", this._buildSortedOptions(this.props.challenges), this.props.challengesLoading)}
                    {this._renderPages(pivotPages)}
                </Pivot>
                {this.props.user && this.props.user.availableTenants && this.props.user.availableTenants.length > 1 && 
                    <div className='card-list'>
                        {this.props.user.availableTenants.map((viewModel, index) =>
                            <TenantCard key={viewModel.id} disabled={this.props.tenantId == viewModel.id} tenant={viewModel} relativePath='/teamsApp/config' />)}
                    </div>}
            </div>
        );
    }

    private _buildSortedOptions = (entities: OptionableEntity[]): IComboBoxOption[] => {
        return [...entities.map<IComboBoxOption>(_ => ({ key: _.id, text: _.attributes.Name }))]
            .sort((a, b) => a.text.localeCompare(b.text));
    }

    private _renderPages(pages: PivotPage[]) {
        return pages.map(page => <PivotItem key={page.key} headerText={page.name} itemKey={page.key}>
            <MessageBar messageBarType={MessageBarType.info}>
                {page.description}
            </MessageBar>
        </PivotItem>);
    }

    private _renderEntitySelection(entityName: string, options: IComboBoxOption[], loading: boolean) {
        return <PivotItem headerText={`${entityName}s`}>
            <ComboBox label={entityName}
                calloutProps={{ calloutMaxHeight: 250 }}
                disabled={loading}
                useComboBoxAsMenuWidth
                allowFreeform
                placeholder={`Select ${entityName.toLowerCase()}`}
                options={options}
                selectedKey={this.state.selectedEntity?.id}
                onChange={(e: any, option?: IComboBoxOption, index?: number, value?: string) => {
                    const selectedEntity = option ? {
                        id: option.key as string,
                        type: entityName.toLowerCase(),
                        name: option.text,
                    } : undefined;

                    this.setState({
                        selectedPage: undefined,
                        selectedEntity
                    });

                    microsoftTeams.pages.config.setValidityState(!!selectedEntity);
                }}
            />
            {loading && <Spinner size={SpinnerSize.small} className='field-spinner' />}
        </PivotItem>;
    }

    private _onSaveHandler = (saveEvent: any) => {
        let url = `${window.location.protocol}//${window.location.host}`;
        let suggestedDisplayName = 'PPM Express';

        const tenant = this.props.user?.availableTenants?.find(_ => _.id === this.props.tenantId);
        if (tenant) {
            url += `/@${tenant.url}`;
        }

        if (this.state.selectedPage) {
            url += `/${this.state.selectedPage.path}`;
            suggestedDisplayName = this.state.selectedPage.name;
        }
        else if (this.state.selectedEntity) {
            url += `/${this.state.selectedEntity.type}/${this.state.selectedEntity.id}`;
            suggestedDisplayName = this.state.selectedEntity.name;
        }

        microsoftTeams.pages.config.setConfig({ contentUrl: url, entityId: url, websiteUrl: url, suggestedDisplayName });
        saveEvent.notifySuccess();
    }

    private _getPivotPages = (): PivotPage[] => {
        return [
            {
                key: 'myspace',
                name: 'My Space',
                description: 'Add the My Space page to the Teams tab to have all your assignments (tasks, risks, issues, etc.) in one place.',
                path: 'myspace'
            },
            this.state.hasTimeTracking
                ? {
                    key: 'mytime',
                    name: 'My Time',
                    description: 'Add the My Time page to the Teams tab to track and manage your time in one place.',
                    path: 'mytime'
                }
                : undefined
        ].filter(notUndefined);
    }
}

function mapStateToProps(state: ApplicationState, ownProps: TeamsAppProps): TeamsAppProps {
    const projects = state.projectsList.byId;
    const programs = state.programs.byId;
    const portfolios = state.portfolios.byId;
    const roadmaps = state.roadmaps.byId;
    const challenges = state.challenges.byId;
    return {
        projects: state.projectsList.allIds.map(_ => projects[_]),
        programs: state.programs.allIds.map(_ => programs[_]),
        portfolios: state.portfolios.allIds.map(_ => portfolios[_]),
        roadmaps: state.roadmaps.allIds.map(_ => roadmaps[_]),
        challenges: state.challenges.allIds.map(_ => challenges[_]),
        projectsLoading: state.projectsList.isLoading,
        programsLoading: state.programs.isLoading,
        portfoliosLoading: state.portfolios.isLoading,
        roadmapsLoading: state.roadmaps.isLoading,
        challengesLoading: state.challenges.isLoading,
        user: state.user,
        tenantId: state.tenant.id,
        subscription: state.tenant.subscription,
        newTimeTrackingEnabled: state.tenant.timeTracking.globalSettings.newTimeTrackingEnabled
    };
}

function mergeActionCreators(dispatch: any) {
    return {
        projectsListStore: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        programsListStore: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        portfoliosListStore: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        roadmapsListStore: bindActionCreators(RoadmapsListStore.actionCreators, dispatch),
        challengesListStore: bindActionCreators(ChallengesListStore.actionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(TeamsAppConfig)
