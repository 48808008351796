import "./LetsStart.css";
import * as React from "react";
import { PPMFeatures, ProductType, Subscription } from "../../../store/Tenant";
import ActionCard from './ActionCard';
import BuildRoadmapActionCard from './BuildRoadmapActionCard';
import InvitePeopleActionCard from "./InvitePeopleActionCard";
import CreateEntityActionCard from "./CreateEntityActionCard";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { EntityType } from "../../../entities/common";
import { canCreate, canRead, LicenseType } from "../../../store/permissions";
import { UserState } from "../../../store/User";

const actionViewRoadmaps = (props: StoreProps) => <ActionCard
    iconName='PPMXQuickStartRoadmap'
    name='View Roadmaps'
    disabled={!canRead(props.user.permissions.roadmap)}
    path='/roadmaps'
/>;
const actionViewProjects = (props: StoreProps) => <ActionCard
    iconName='PPMXQuickStartProject'
    name='View Projects'
    disabled={!canRead(props.user.permissions.project)}
    path='/projects'
/>;
const actionSumbitIdea = (props: StoreProps) => <ActionCard
    iconName='PPMXQuickStartIdea'
    name='Submit Ideas'
    disabled={!Subscription.contains(props.subscription, PPMFeatures.Ideation)}
    path='/challenges'
/>;
const actionManageFeedback = (props: StoreProps) => <ActionCard
    iconName='PPMXQuickStartIdea'
    name='Manage Feedback and Ideas'
    disabled={!Subscription.contains(props.subscription, PPMFeatures.Ideation)}
    path='/challenges'
/>;
const actionViewMySpace = (props: StoreProps) => <ActionCard
    iconName='PPMXQuickStartMySpace'
    name='View My Space'
    path='/myspace'
/>;
const actionInvitePeople = (props: StoreProps) => <InvitePeopleActionCard
    key='invite-people--action'
    iconName='PPMXQuickStartInvite'
    name='Invite People'
/>;
const actionCreateProject = (props: StoreProps) => <CreateEntityActionCard
    name='Create Project'
    iconName='PPMXQuickStartProject'
    disabled={!canCreate(props.user.permissions.project)}
    entityType={EntityType.Project}
/>;
const actionCreatePortfolio = (props: StoreProps) => <CreateEntityActionCard
    name='Create Portfolio'
    iconName='PPMXQuickStartPortfolio'
    disabled={!canCreate(props.user.permissions.portfolio)}
    entityType={EntityType.Portfolio}
/>;
const actionCreateProgram = (props: StoreProps) => <CreateEntityActionCard
    name='Create Program'
    iconName='PPMXQuickStartProgram'
    disabled={!canCreate(props.user.permissions.program)}
    entityType={EntityType.Program}
/>;
const actionCreateChallenge = (props: StoreProps) => <CreateEntityActionCard
    name='Create Business Challenge'
    iconName='PPMXQuickStartChallenge'
    disabled={!canCreate(props.user.permissions.challenge)}
    entityType={EntityType.Challenge}
/>;
const actionBuildRoadmap = (props: StoreProps) => <BuildRoadmapActionCard
    key='build-roadmap-action'
    iconName='PPMXQuickStartRoadmap'
    name='Build Roadmap'
/>;

const actions = {
    [ProductType.Enterprise]: {
        ['teammember']: [actionViewRoadmaps, actionViewProjects, actionSumbitIdea, actionViewMySpace],
        ['regular']: [actionCreateProject, actionInvitePeople, actionCreatePortfolio, actionCreateProgram]
    },
    [ProductType.Portfolio]: {
        ['teammember']: [actionViewProjects, actionSumbitIdea, actionViewMySpace],
        ['regular']: [actionCreateProject, actionInvitePeople, actionCreatePortfolio, actionCreateProgram]
    },
    [ProductType.Product]: {
        ['teammember']: [actionViewRoadmaps, actionViewProjects, actionSumbitIdea, actionViewMySpace],
        ['regular']: [actionCreateProject, actionInvitePeople, actionBuildRoadmap, actionManageFeedback]
    },
    [ProductType.Work]: {
        ['regular']: [actionCreateProject, actionInvitePeople]
    },
    [ProductType.Resource]: {
        ['regular']: [actionCreateProject, actionInvitePeople]
    },
    [ProductType.Ideation]: {
        ['regular']: [actionCreateChallenge, actionInvitePeople]
    }
};

type StoreProps = {
    subscription: Subscription;
    user: UserState;
}
const LetsStart = (props: StoreProps) => {
    const userType = props.user.license === LicenseType.Regular ? 'regular' : 'teammember';

    return <div className="box lets-start">
        <h2>Let's Start!</h2>
        <div className="lets-start-list">
            {actions[props.subscription.productType]?.[userType]?.map((_: (props: StoreProps) => JSX.Element, i: number) => <React.Fragment key={i}>
                {_(props)}
            </React.Fragment>)}
        </div>
    </div>;
}
function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        subscription: state.tenant.subscription,
        user: state.user
    };
}
export default connect(mapStateToProps)(LetsStart);