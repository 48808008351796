import * as React from 'react';
import * as Metadata from '../../../entities/Metadata';
import { OnChangeEvent } from "../sectionsControl/uiControls/fieldsArea/FieldComponent";
import { orderNotSelected, filterUnique } from '../../utils/common';
import { Dictionary, EntityType } from '../../../entities/common';
import ShowAllFieldsToggle from './ShowAllFieldsToggle';
import FieldEditor, { EntityState, FieldConfig } from './FieldEditor';

type Props = {
    fields: Metadata.Field[];
    displayFields: string[];
    config: Dictionary<FieldConfig>;
    entity: EntityState;
    subentityType: EntityType;

    onChanged: OnChangeEvent;
}

const FieldsEdit = ({ fields, displayFields, config, entity, subentityType, onChanged }: Props) => {
    const [showAllFields, setShowAllFields] = React.useState(false);

    const fieldsNames = React.useMemo(
        () => filterUnique(displayFields.concat(fields.filter(Metadata.isRequired).map(_ => _.name))),
        [fields, displayFields]);

    const visibleFields = React.useMemo(
        () => orderNotSelected(
            fields.filter(_ => !(_.isNative && _.name === 'Name') && (showAllFields || ~fieldsNames.indexOf(_.name))),
            f => fieldsNames.indexOf(f.name),
            a => Metadata.getLabel(a)
        ),
        [fields, showAllFields, fieldsNames]);

    return <>
        {
            visibleFields.map(_ => <FieldEditor
                key={_.name}
                {...config[_.name]}
                field={_}
                entity={entity}
                onChanged={onChanged}
                subentityType={subentityType}
            />)
        }
        {
            (fieldsNames.length < fields.length) && <ShowAllFieldsToggle showAllFields={showAllFields} onChange={setShowAllFields} />
        }
    </>;
}

export default FieldsEdit;