import { Label, TextField } from 'office-ui-fabric-react';
import * as React from 'react';

type Props = {
    label: string | JSX.Element;
    isLinked: boolean;
    value?: string;
    className?: string;
};

export const ConnectionField = (props: React.PropsWithChildren<Props>) => (
    <>
        <Label className={props.className} style={{ textTransform: 'capitalize', paddingTop: '5px', paddingBottom: '5px' }}>
            {props.label}
        </Label>
        {
            props.isLinked
                ? <TextField
                    disabled
                    readOnly
                    title={props.value}
                    value={props.value ?? 'Not selected'} />
                : props.children
        }
    </>
);