import React from "react";
import DatePickerInput from "../../../common/inputs/DatePickerInput";
import TextInput from "../../../common/inputs/TextInput";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import LabellableFieldComponent from "../../../common/sectionsControl/uiControls/fieldsArea/LabellableFieldComponent";
import GroupDropdown from "../../../common/inputs/GroupDropdown";
import { Group, IGroupInfo } from "../../../../entities/Metadata";
import { Validator } from "../../../../validation";
import './AIScheduleDataInput.css';
import { AiDetailsDescription } from "../../../common/ai/detailsDescription";

export const defaultHierarchyLevel = 1;
const hierarchyLevelOptions: IDropdownOption[] = [
    { key: defaultHierarchyLevel, text: "1" },
    { key: 2, text: "2" },
    { key: 3, text: "3" },
    { key: 4, text: "4" },
    { key: 5, text: "5" },
];

export type Settings = {
    context: string,
    startDate: string
    finishDate?: string,
    hierarchyLevel: number,
    group: IGroupInfo
}

export type UpdatedSettings = {
    settings: Settings, 
    isValid: boolean
}

const projectDetailsValidator = Validator.new().required().build();

type Props = {
    settings: Settings,
    onSettingsChange: (_: UpdatedSettings) => void,
    groups: Group[];
    projectDescription: string | undefined;
}

const AIScheduleDataInput = (props: Props) => {
    const { settings, groups, projectDescription, onSettingsChange } = props;
    const contextInput = React.createRef<TextInput>();

    const validators: { start: Validator, finish: Validator, projectDetails: Validator } = React.useMemo(() => {
        let startDateValidator = Validator.new().required();
        let finishDateValidator = Validator.new();
        if(settings.finishDate){
            startDateValidator = startDateValidator.dateIsLessThenOrEqual(settings.finishDate);
            finishDateValidator = finishDateValidator.dateIsGreaterThenOrEqual(settings.startDate);
        }
        
        return { 
            start: startDateValidator.build(),
            finish: finishDateValidator.build(),
            projectDetails: projectDetailsValidator
        }
    }, [settings]);

    const updateSettings = React.useCallback((update: Partial<Settings>) => {
        const newSettings = {...settings, ...update};
        onSettingsChange({
            settings: newSettings, 
            isValid: validators.projectDetails.isValid(newSettings.context) 
                && validators.start.isValid(newSettings.startDate) 
                && validators.finish.isValid(newSettings.finishDate)
            })
    }, [onSettingsChange]);

    const onAddProjectDescriptionClick = React.useCallback(() => {
        if (!projectDescription) {
            return; 
        }

        const newDetails = settings.context?.length ? `${settings.context}\n${projectDescription}` : projectDescription!;
        updateSettings({context: newDetails });
        contextInput.current?.focus();
    }, [projectDescription, settings.context, contextInput]);

    return <div className="ai-schedule-data-input">
        <div className="panel-area">
            <div className="grid-item">
                <LabellableFieldComponent 
                    required
                    label="Context for AI Tasks Generation"
                    description="Describe the project or initiative in details for more precise task schedule generation."
                    labelActions={[{
                        key: "add-project-description",
                        onClick: onAddProjectDescriptionClick,
                        iconProps: {iconName: "AddNotes"},
                        title: "Add project description"
                    }]}>
                    <TextInput
                        value={settings.context}
                        showMoreMode
                        allowForceUpdate
                        inputProps={{
                            multiline: true,
                            placeholder: "E.g. Implementation of a company-wide CRM software...",
                            resizable: false,
                            borderless: false,
                            containerMaxHeight: 150
                        }}
                        onChanged={_ => updateSettings({context: _ || ""})}
                        validator={validators.projectDetails}
                        ref={contextInput}
                        inputRef={_ => _.focus()} />
                </LabellableFieldComponent>
            </div>
            <div className='grid-item'>
                <AiDetailsDescription />
            </div>
            <div className="grid-item half-width odd">
                <LabellableFieldComponent 
                    label="Start Date" 
                    required>
                    <DatePickerInput
                        value={settings.startDate}
                        maxDate={settings.finishDate}
                        validator={validators.start}
                        onChanged={_ => updateSettings({startDate: _ as string})} />
                </LabellableFieldComponent>
            </div>
            <div className="grid-item half-width">
                <LabellableFieldComponent 
                    label="Finish Date">
                    <DatePickerInput
                        minDate={settings.startDate}
                        value={settings.finishDate}
                        validator={validators.finish}
                        onChanged={_ => updateSettings({finishDate: _ as string})} />
                </LabellableFieldComponent>
            </div>
            <div className="grid-item">
                <LabellableFieldComponent 
                    label="Group">
                    <GroupDropdown
                        groups={groups}
                        value={settings.group}
                        dropdownInputProps={{
                            disabled: !groups.length,
                            onChanged: _ => updateSettings({group: _})
                        }}/>
                </LabellableFieldComponent>
            </div>
            <div className="grid-item">
                <LabellableFieldComponent 
                    label="Hierarchy Level"
                    description={<>
                        <div>By default, tasks are organized at level 1, creating a schedule with a flat hierarchy.</div>
                        <div>If you set the hierarchy level to 2 or higher, the schedule will have subtasks, and the hierarchy will go up to the specified level.</div>
                    </>}>
                    <Dropdown
                        selectedKey={settings.hierarchyLevel}
                        options={hierarchyLevelOptions}
                        onChange={(e, _) => updateSettings({hierarchyLevel: _!.key as number})} />
                </LabellableFieldComponent>
            </div>
        </div>
    </div>
}

export default AIScheduleDataInput;