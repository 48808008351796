import * as microsoftTeams from '@microsoft/teams-js'
import React from 'react';
import Spinner from '../common/Spinner';
const TeamsPostLogin = () => {
    React.useEffect(() => {
        microsoftTeams.app.initialize().then(() => microsoftTeams.authentication.notifySuccess())
    }, [])
    
    return <Spinner/>;
};
export default TeamsPostLogin;