import * as React from 'react';
import ConnectionsList from "./ConnectionsList";
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { IConnectionInfo } from '../../entities/Metadata';

type Props = {
    disabled: boolean;
    sourceType: SourceType,
    connections: IConnectionInfo[];
    onConnectionSelect: (connection: IConnectionInfo) => void;
    onConnectionRemove: (connectionId: string) => void;
    onConnectionRename: (connectionId: string, title: string) => void;
    grantPermissionsText?: string;
    onGrantPermissionsClick?: () => void;

    renderAdd: (onCreated: (connectionId: string) => void, onDismiss: () => void) => void;
    renderEdit: (connectionId: string, onDismiss: () => void) => void;
}

const BasicAuthConnectionsList = (props: Props) => {
    const [addConnection, setAddConnection] = React.useState<boolean>();
    const onDismissAddConnection = React.useCallback(() => setAddConnection(undefined), []);
    const onAddConnectionClicked = React.useCallback(() => setAddConnection(true), []);
    const [editConnection, setEditConnection] = React.useState<string>();
    const onDismissEditConnection = React.useCallback(() => setEditConnection(undefined), []);
    const onCreated = React.useCallback((connectionId: string) => {
        const connection = props.connections.find(_ => _.id == connectionId);
        connection && props.onConnectionSelect(connection);
    }, [props.connections]);
    return <>
        <ConnectionsList
            {...props}
            onEditClick={setEditConnection}
            onNewConnectionClick={onAddConnectionClicked}
        />
        {addConnection && props.renderAdd(onCreated, onDismissAddConnection)}
        {editConnection && props.renderEdit(editConnection, onDismissEditConnection)}
    </>;
}

export default BasicAuthConnectionsList;
