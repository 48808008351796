import { SubscriptionStatus, PPMFeatures, PPMIntegrations, ProductType, PaymentType, PaymentPeriod } from "../../../store/Tenant";
import { formatCurrency } from "../../utils/common";

export type BillingPlan = {
    id: string;
    name: string;
    description: string;
    featuresDescriptions: FeaturesDescription[];
    productType: ProductType;
    paymentType: PaymentType;
    viewersStairsteps: Range[];
    features: number;
    price: number;
    paymentPeriod: PaymentPeriod;
    defaultUserCount: number;
    iconName?: string;
}

export type FeaturesDescription = { 
    text: string;
    hint?: string;
    marked?: boolean;
}

export type Range = {
    from: number;
    to: number;
    price: number;
}

export type SubscriptionInfo = {
    subscriptionId: string;

    planId: string;
    planName: string;
    planIconName?: string;
    productType: ProductType;
    paymentType: PaymentType;
    paymentPeriod: PaymentPeriod;

    isEnterprise: boolean;

    status: SubscriptionStatus;
    isFree: boolean;

    endDate: string;
    startDate: string;

    usersLimit: number;
    viewersLimit: number;

    dataSyncCountLimit: number;

    isTrial: boolean;
    price?: number;

    gracePeriodDuration: number;

    utilization: {
        usersAllocated: number;
        viewersAllocated: number;
    }

    features: PPMFeatures;
    allFeatures: PPMFeatures;
    integrations: PPMIntegrations;
}

const MONTHS_IN_A_YEAR = 12;

const PaymentPeriodTitles = {
    [PaymentPeriod.Day]: "Daily Payment",
    [PaymentPeriod.Week]: "Weekly Payment",
    [PaymentPeriod.Month]: "Monthly Payment",
    [PaymentPeriod.Year]: "Annual Payment"
}

const ProductTypeTitles = {
    [ProductType.Resource]: 'Resource Management',
    [ProductType.Portfolio]: 'Portfolio Management',
    [ProductType.Product]: 'Product Management',
    [ProductType.Work]: 'Project Management'
}

export function GetPaymentPeriodTitle(subscription: SubscriptionInfo) {
    return PaymentPeriodTitles[subscription.paymentPeriod] || 'Payment';
}

export function GetProductTypeTitle(productType: ProductType) {
    return ProductTypeTitles[productType];
}

export function GetPlanConfig(plan: BillingPlan) {    
    switch (plan.paymentPeriod) {
        case PaymentPeriod.Year: return { monthlyPrice: plan.price / MONTHS_IN_A_YEAR, text: 'billed yearly' }
        case PaymentPeriod.Month: return { monthlyPrice: plan.price }
        case PaymentPeriod.Day: return { monthlyPrice: plan.price * daysInThisMonth(), text: 'billed daily' }
        default: return { monthlyPrice: 0 }
    }
}

export function GetPriceString(price: number) {
    return formatCurrency(price, 'USD') + " USD";
}

const daysInThisMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}