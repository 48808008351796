import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store/index";
import { RouteTitle } from './common';

type RouterProps = RouteComponentProps<{ id: string }>;
type OwnProps = React.PropsWithChildren<{ title: RouteTitle | undefined }>
type StateProps = { state: ApplicationState; }
type Props = OwnProps & StateProps & RouterProps;


const DocumentTitle = (props: Props) => {    
    const title = getTitle(props);
    React.useEffect(() => {
        const documnetTitle =  title ? `PPM Express - ${title}` : 'PPM Express';
        if (documnetTitle !== document.title) {
            document.title = documnetTitle;
        }
    }, [title]);

    return <>{props.children}</>;
}

function getTitle(props: Props) {
    if (typeof props.title == "string") {
        return props.title;
    }

    if (typeof props.title == "function") {
        return props.title(props.state, props.match.params.id);
    }

    return '';
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps & RouterProps): StateProps {
    return {
        state: state
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(DocumentTitle));