import React, { useMemo } from "react";
import "./SelectionModeCommandBar.css"
import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react";
import { notUndefined } from "../../utils/common";
import { EntityTypeLabels, entityTypeLabelMap } from "../../../entities/common";

type Props = {
    entityTypeLabels?: EntityTypeLabels;
    selectedCount?: number;
    items: ICommandBarItemProps[] | undefined;
    onCancel: () => void;
}

const SelectionModeCommandBar = (props: Props) => {
    const { items, selectedCount, onCancel } = props;

    if (!items?.length && selectedCount === undefined) {
        return null;
    }

    const labels = getEntityTypeLabels(props.entityTypeLabels);

    const itemsWithCountItem = useMemo(() => {
        const selectedText = `${selectedCount} ${(selectedCount! > 1 ? labels.plural : labels.singular).toLowerCase()} selected`;
        return [
            selectedCount !== undefined ? {
                key: 'selected-count',
                className: 'selected-count-menu-item',
                text: selectedText,
                title: selectedText,
            } : undefined,
            ...(items ?? [])
        ].filter(notUndefined);
    }, [selectedCount, items]);

    return (
        <CommandBar
            style={{ width: '100%' }}
            styles={{
                root: { padding: 0, borderRadius: '2px' }
            }}
            overflowButtonProps={{ menuProps: { items: [], isBeakVisible: false } }}
            className="header-command-bar selection-mode-command-bar"
            items={itemsWithCountItem}
            farItems={[
                {
                    key: 'cancel',
                    text: 'Cancel',
                    iconProps: { iconName: 'Cancel' },
                    buttonStyles: { root: { paddingRight: '4px' } },
                    onClick: onCancel,
                }
            ]}
        />
    );
}

const getEntityTypeLabels = (entityTypeLabels: EntityTypeLabels | undefined): EntityTypeLabels => entityTypeLabels ?? { singular: "item", plural: "items" }

export default SelectionModeCommandBar;
