import * as React from 'react';
import { connect } from 'react-redux';
import { Checkbox, MessageBar, MessageBarType, Link, IComboBoxOption } from "office-ui-fabric-react";
import ConnectControl, { TabType } from '../../ConnectControl';
import Office365ConnectionSelect from '../Office365ConnectionSelect';
import { ApplicationState } from "../../../../store/index";
import { Dictionary, ISourceInfo } from "../../../../entities/common";
import { IPlanInfo, actionCreators, GroupInfo, IPlannerConnectionState, ConnectionType } from "../../../../store/integration/Office365Store";
import { SourceType } from "../../../../store/ExternalEpmConnectStore";
import { O365GroupContext } from '../Group/O365GroupConnectControl';
import { IListStore, ILinkDto, validateConnection, loadDataAfterConnectionValidation } from '../../../../store/integration/common';
import { Office365Utils } from '../Office365Utils';
import { InfoMessages } from '../../../utils/common';
import PlanGroupSelection from './PlanGroupSelection';
import FieldLabel from '../../FieldLabel';
import SwitchPrimaryScheduleConfirmation from '../../SwitchPrimaryScheduleConfirmation';

type OwnProps = {
    readonly: boolean;
    allowNewItems: boolean;
    showPlanGroupConnection: boolean;
    actions: Dictionary<any>;
    dismissPanel: () => void;
    connectToGroupEnabled: boolean;
    sourceInfo?: ISourceInfo;
    allowMultipleConnectionsToOnePlan?: boolean;
    redirectTo?: (type: SourceType, context: O365GroupContext) => void;
    showSwitchPrimaryDialog?: boolean;
    setAsPrimary?: boolean;
}
type StateProps = {
    plans: IListStore<IPlanInfo>;
    groups: IListStore<GroupInfo>;
    connections: IPlannerConnectionState;
}
type Props = OwnProps & StateProps & typeof actionCreators;

type State = {
    groupPreFilter: {
        showGroupSelector?: boolean;
        groupId?: string;
    };
    groupConnect: {
        connect: boolean;
        groupName?: string;
        groupId?: string;
    };
    groupForNewPlan:
    {
        showGroupSelector?: boolean;
        groupId?: string;
        connectionId?: string;
    };
    selectedConnectionId?: string;
    showSwitchPrimaryScheduleConfirm: boolean;
    linkData?: {
        dto?: ILinkDto<IPlanInfo>;
        connectionId?: string;
        planName?: string;
    }
}

class O365PlannerPlanConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            groupPreFilter: {},
            groupConnect: {
                connect: this.props.connectToGroupEnabled
            },
            groupForNewPlan: {},
            showSwitchPrimaryScheduleConfirm: false,
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!this.props.sourceInfo && nextProps.sourceInfo && this.state.groupConnect.connect) {
            this.props.allowNewItems && this.props.redirectTo!(SourceType.O365Group, {
                groupId: this.state.groupConnect.groupId,
                groupName: this.state.groupConnect.groupName,
                connectionId: nextProps.sourceInfo.connectionId
            });
        }

        const { selectedConnectionId } = this.state;
        const selectedConnectionType = Office365Utils.GetConnectionType(this.props.connections, selectedConnectionId);
        selectedConnectionType != null
            && [ConnectionType.Full, ConnectionType.RestrictedPlannerSync].includes(selectedConnectionType)
            && loadDataAfterConnectionValidation(
                this.props.connections,
                nextProps.connections,
                selectedConnectionId,
                (_) => {
                    this.props.loadPlans(_);
                    this._updateConnectToGroup(selectedConnectionId);
                    if (this.state.groupPreFilter.showGroupSelector || this.state.groupForNewPlan.showGroupSelector) {
                        this._loadGroups(_);
                    }
                });
    }

    private _loadGroups(connectionId: string | undefined) {
        if (connectionId) {
            const selectedConnectionType = Office365Utils.GetConnectionType(this.props.connections, connectionId);
            if (selectedConnectionType === ConnectionType.Full) {
                this.props.loadMyGroups(connectionId);
            }
        }
        this.setState({ groupForNewPlan: { ...this.state.groupForNewPlan, connectionId: connectionId } });
    }

    public render() {
        const { sourceInfo, dismissPanel, readonly, allowNewItems, allowMultipleConnectionsToOnePlan } = this.props;
        const { showSwitchPrimaryScheduleConfirm } = this.state;
        const isLinked = !!sourceInfo;
        return <>
            <ConnectControl<IPlanInfo>
                itemTypeLabel="plan"
                readonly={readonly}
                isConnectionValid={(connectionId) => this._validateConnection(this.props.connections, connectionId)}
                allowNewItems={allowNewItems}
                allowMultipleConnectionsToOneItem={allowMultipleConnectionsToOnePlan}
                items={this.props.plans}
                actions={{
                    createItemAndLink: this._createPlanAndLink,
                    linkToItem: this._linkToPlan,
                    deleteLink: this.props.actions.deleteLink
                }}
                dismissPanel={dismissPanel}
                notCloseAfterLink={this.props.showSwitchPrimaryDialog || this.state.groupConnect.connect}
                sourceInfo={sourceInfo}
                itemId={sourceInfo?.sourceData?.planId ||
                    (this.state.groupPreFilter.groupId && this.props.plans.entities.length === 1 ? this.props.plans.entities[0]?.id : undefined)}
                itemName={sourceInfo?.sourceData?.title}
                renderConnectControl={(connectionId, disabled, onChange) =>
                    <Office365ConnectionSelect
                        connectionId={connectionId}
                        disabled={disabled}
                        sourceType={SourceType.O365Planner}
                        onChange={_ => {
                            onChange(_);
                            this.setState({
                                groupPreFilter: { ...this.state.groupPreFilter, groupId: undefined },
                                selectedConnectionId: _,
                                groupForNewPlan: { ...this.state.groupForNewPlan, groupId: undefined, connectionId: undefined }
                            });
                        }}
                        validateConnection={(connections, cId) => this._validateConnection(connections, cId)}
                    />}
                renderExistingConnectedItemPreOptions={connectionId => this._renderPlanConnectPreOptions(connectionId, sourceInfo)}
                renderExistingConnectedItemOptions={connectionId => this._renderPlanConnectOptions(false, connectionId, sourceInfo)}
                renderNewConnectedItemOptions={connectionId => this._renderPlanConnectOptions(true, connectionId, sourceInfo)}
                renderNewItemDisabledMessage={connectionId => this._renderRestrictedScopeDisabledMessage(connectionId)}
                onTabCahnged={(tabType) => {
                    const isNewTab = tabType === TabType.CreateItem;
                    this.setState({ groupForNewPlan: { ...this.state.groupForNewPlan, showGroupSelector: isNewTab } });
                    if (isNewTab && this.state.groupForNewPlan.connectionId != this.state.selectedConnectionId) {
                        const validation = validateConnection(this.props.connections, this.state.selectedConnectionId);
                        if (validation && !validation.validationError) {
                            this._loadGroups(this.state.selectedConnectionId);
                        }
                    }
                }}
            />
            {showSwitchPrimaryScheduleConfirm && <SwitchPrimaryScheduleConfirmation
                from={SourceType.Ppmx} to={SourceType.O365Planner}
                onDismiss={() => this.setState({ showSwitchPrimaryScheduleConfirm: false, linkData: undefined })}
                onClick={(isPrimary) => {
                    if (this.state.linkData?.dto) {
                        this.props.actions.linkToItem({ ...this.state.linkData.dto, setAsPrimary: isPrimary });
                    } else {
                        this.props.actions.createItemAndLink(this.state.linkData?.connectionId, this.state.linkData?.planName, this.state.groupForNewPlan.groupId, isPrimary);
                    }
                    !this.state.groupConnect.connect && dismissPanel();
                }}/>
            }
        </>;
    }

    private _validateConnection(
        connections: IPlannerConnectionState,
        connectionId: string | undefined
    ): { isValidating?: boolean; validationError?: string | React.ReactNode | null; } | undefined {
        const connectionType = Office365Utils.GetConnectionType(this.props.connections, connectionId);
        if (connectionType === ConnectionType.AzureADSync) {
            return {
                isValidating: undefined,
                validationError:
                    <MessageBar messageBarType={MessageBarType.warning}>
                        {InfoMessages.linkProjectByAzureAdConnection}
                    </MessageBar>
            };
        }
        return validateConnection(connections, connectionId);
    }
    private _updateConnectToGroup(connectionId: string | undefined) {
        if (this.props.connectToGroupEnabled && this.props.showPlanGroupConnection) {
            const connectionType = Office365Utils.GetConnectionType(this.props.connections, connectionId);
            this.setState({ groupConnect: { ...this.state.groupConnect, connect: connectionType === ConnectionType.Full }, selectedConnectionId: connectionId });
        }
    }

    private _renderRestrictedScopeDisabledMessage(connectionId: string | undefined): React.ReactNode | React.ReactNode[] | null {
        const connectionType = Office365Utils.GetConnectionType(this.props.connections, connectionId);
        return connectionType === ConnectionType.RestrictedPlannerSync &&
            <MessageBar messageBarType={MessageBarType.warning}>
                Creating a plan is not possible using a restricted connection. To create a plan please choose a regular connection or request full access permissions.
            </MessageBar>
    }

    private _renderPlanConnectPreOptions(selectedConnectionId?: string, sourceInfo?: ISourceInfo) {
        return sourceInfo?.sourceData?.planId || !selectedConnectionId
            || Office365Utils.GetConnectionType(this.props.connections, selectedConnectionId) === ConnectionType.RestrictedPlannerSync
            ? null
            : <>
                <MessageBar messageBarType={MessageBarType.info}>
                    Can't find your plan? Try <Link disabled={this.props.groups.isLoading} onClick={() => {
                        if (!this.state.groupPreFilter.showGroupSelector) {
                            this._loadGroups(selectedConnectionId);
                            this.setState({ groupPreFilter: { ...this.state.groupPreFilter, showGroupSelector: true } });
                        }
                    }
                    }>select plan's Office365 group</Link> first
                </MessageBar>
                {this.state.groupPreFilter.showGroupSelector && this._renderGroupForExistingPlan(sourceInfo)}
            </>;
    }

    private _renderGroupForExistingPlan(sourceInfo?: ISourceInfo) {
        const { groupPreFilter } = this.state;
        const { groups } = this.props;
        const groupName = groups.entities.find(_ => _.id === groupPreFilter.groupId)?.title ?? sourceInfo?.sourceData.title;

        return <PlanGroupSelection
            isLinked={!!sourceInfo}
            fieldLabel="Group"
            value={groupName}
            selectedKey={groupPreFilter.groupId}
            placeholder="Select group"
            isLoading={groups.isLoading}
            disabled={groups.isLoading}
            options={groups.entities?.map(_ => ({ key: _.id, text: _.title }))}
            onChange={(e, option?: IComboBoxOption) => {
                const groupId = option?.key as string | undefined;
                this.setState({ groupPreFilter: { ...groupPreFilter, groupId } });
                this.props.loadPlans(this.state.selectedConnectionId!, groupId);
            }} />
    }

    private _renderGroupForNewPlan(sourceInfo?: ISourceInfo) {
        const { groupForNewPlan } = this.state;
        const { groups } = this.props;
        const groupName = groups.entities.find(_ => _.id === groupForNewPlan.groupId)?.title;

        return <PlanGroupSelection
            isLinked={!!sourceInfo}
            fieldLabel={<FieldLabel 
                label='Group' 
                title='The plan will be created in the selected group. If the group is not selected a new one will be created for this plan.' />}
            value={groupName}
            selectedKey={groupForNewPlan.groupId}
            placeholder="Add to existing group"
            isLoading={groups.isLoading}
            disabled={groups.isLoading}
            options={groups.entities?.map(_ => ({ key: _.id, text: _.title }))}
            onChange={(e, option?: IComboBoxOption) => {
                const groupId = option?.key as string | undefined;
                this.setState({ groupForNewPlan: { ...groupForNewPlan, groupId } });
            }} 
        />
    }

    private _renderPlanConnectOptions(isNewTab: boolean, selectedConnectionId?: string, sourceInfo?: ISourceInfo) {
        const disableByRestrictedScope = !isNewTab && Office365Utils.GetConnectionType(this.props.connections, selectedConnectionId) === ConnectionType.RestrictedPlannerSync;
        const disabled = this.props.readonly || !selectedConnectionId || !this.props.connectToGroupEnabled || disableByRestrictedScope;
        return !this.props.showPlanGroupConnection || sourceInfo?.sourceData
            ? null
            : <div>
                {isNewTab && this._renderGroupForNewPlan(sourceInfo)}
                <Checkbox
                    styles={{ root: { marginTop: 6 } }}
                    checked={this.state.groupConnect.connect}
                    disabled={disabled || !!(sourceInfo && sourceInfo.sourceData && sourceInfo.sourceData)}
                    onChange={(e: any, c?: boolean) => this.setState({ groupConnect: { ...this.state.groupConnect, connect: !!c } })}
                    label="Connect to plan group"
                    title="Select this option to create Office 365 connection and link the plan group to your project."
                />
            </div>
    }

    private _linkToPlan = (linkDto: ILinkDto<IPlanInfo>) => {
        if (this.state.groupConnect.connect) {
            this.setState({ groupConnect: { ...this.state.groupConnect, groupId: linkDto.data.owner } });
        }

        if (this.props.showSwitchPrimaryDialog) {
            this.setState({ linkData: { dto: linkDto }, showSwitchPrimaryScheduleConfirm: true });
            return;
        }

        this.props.actions.linkToItem({ ...linkDto, setAsPrimary: !!this.props.setAsPrimary });
    }

    private _createPlanAndLink = (connectionId: string, planName: string) => {
        const groupName = this.props.groups.entities.find(_ => _.id === this.state.groupForNewPlan.groupId)?.title ?? planName;
        if (this.state.groupConnect.connect) {
            this.setState({ groupConnect: { ...this.state.groupConnect, groupName: groupName } });
        }

        if (this.props.showSwitchPrimaryDialog) {
            this.setState({ linkData: { connectionId, planName }, showSwitchPrimaryScheduleConfirm: true });
            return;
        }

        this.props.actions.createItemAndLink(connectionId, planName, this.state.groupForNewPlan.groupId, this.props.setAsPrimary);
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        plans: state.office365.plans,
        groups: state.office365.myGroups,
        connections: state.office365.connections
    };
}

export default connect(mapStateToProps, actionCreators)(O365PlannerPlanConnectControl);