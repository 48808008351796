import * as React from 'react';
import * as analytics from '../../analytics';
import { Panel, PanelType, IPanelProps, Pivot, PivotItem } from 'office-ui-fabric-react';
import { PANEL_CUSTOM_WIDTH } from "../../entities/common";
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as Notifications from "../../store/NotificationsStore";
import WorkTogetherTab from "../integration/WorkTogetherTab";
import { SourceType } from "../../store/ExternalEpmConnectStore";
import { ApplicationState } from '../../store';
import { Integrations } from '../../store/Tenant';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CalendlyPanel from '../common/CalendlyPanel';
import LinkTab from '../integration/LinkTab';
import { TeamsChannelLink } from '../integration/TeamsChannelConnectControl';
import { ILinkDto } from '../../store/integration/common';
import { O365GroupLinkInfo } from '../../store/ProjectsListStore';
import { ISpoProject } from '../../store/integration/SpoStore';
import { IPlanInfo } from '../../store/integration/Office365Store';
import SpoConnectControl from '../integration/Spo/SpoConnectControl';
import VSTSConnectControl, { IVSTSLinkData, VSTSConnectionType } from '../integration/Vsts/VSTSConnectControl';
import FileConnectControl from '../integration/FileConnectControl';
import JiraConnectControl, { JiraConnectionType } from '../integration/Jira/JiraConnectControl';
import O365PlanConnectControl from '../integration/Office365/Planner/O365PlannerPlanConnectControl';
import { ConnectProps, RendersMap } from '../integration/TabConnectControl';
import { IMondayComBaseSourceData } from '../../store/integration/MondayComStore';
import MondayComConnectControl from '../integration/MondayCom/MondayComConnectControl';
import { ISmartsheetProgramSourceData } from '../../store/integration/SmartsheetStore';
import SmartsheetProgramConnectControl from '../integration/Smartsheet/SmartsheetProgramConnectControl';
import { UserState } from '../../store/User';
import { IP4WProject } from '../../store/integration/P4WStore';
import P4WConnectControl from '../integration/P4W/P4WConnectControl';
import { IJiraLinkInfo } from './../../store/integration/JiraStore';

type OwnProps = {
    entity: ProgramsListStore.Program
    onDismiss: () => void;
    visibleTypes?: SourceType[];
}
type StateProps = {
    user: UserState;
    integrations: Integrations;
}
type Props = OwnProps & StateProps & {
    programsActions: typeof ProgramsListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
};

type State = {
    selectedTab: string;
    selectedType?: SourceType;
    context?: any;
    isCalendlyPanelOpen?: boolean;
}

export class ExternalEpmConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { selectedTab: "link", selectedType: props.visibleTypes?.find(props.integrations.isEnabled) }
    }

    public render() {
        const linkProps = {
            ...this.props,
            rendersMap: this._rendersMap,
            readonly: !this.props.entity.isEditable,
            sourceInfos: this.props.entity.sourceInfos,
            actions: this.epmConnectControlActionsBuilder(),
            hiddenOptions: [SourceType.MPPFile],
            hiddenCollaborativeOptions: [SourceType.PpmxTime],
            openCalendlyPanel: () => { this.setState({ isCalendlyPanelOpen: true }) },
            entityName: "Program"
        }

        return this.state.isCalendlyPanelOpen ? this._renderCalendlyPanel() : this._renderConfigureConnection(linkProps);
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure Connections</p>
            <div className='ms-Panel-secondaryText'>Connect to external systems to setup collaboration process.</div>
        </div>;
    }

    private _renderCalendlyPanel = (): JSX.Element => {
        return <CalendlyPanel onDismiss={() => this.setState({ isCalendlyPanelOpen: false })}> </CalendlyPanel>
    }

    private _renderConfigureConnection = (linkProps: any): JSX.Element => {
        return <Panel
            isLightDismiss
            type={PanelType.custom}
            customWidth={PANEL_CUSTOM_WIDTH}
            className="connect-panel"
            isOpen={true}
            onDismiss={this.props.onDismiss}
            onRenderHeader={this._onRenderHeader}>
            <div className="entity-linking">
                <Pivot selectedKey={this.state.selectedTab} onLinkClick={_ => _ && this.setState({ selectedTab: _.props.itemKey! })}>
                    <PivotItem itemKey="link" headerText="Link to Project" itemIcon="PPMXLinkProject">
                        <LinkTab {...linkProps} />
                    </PivotItem>
                    <PivotItem itemKey="work-together" headerText="Work Together" itemIcon="PPMXWorkTogether">
                        <WorkTogetherTab
                            {...linkProps}
                            selected={this.state.selectedType}
                            context={this.state.context} />
                    </PivotItem>
                </Pivot>
            </div>
        </Panel>;
    }

    private epmConnectControlActionsBuilder = () => {
        return {
            [SourceType.O365Group]: {
                linkToO365Group: this.linkToO365Group,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365Group),
                showLinkEngagementsOption: false
            },
            [SourceType.O365TeamsChannel]: {
                linkToTeamsChannel: this.linkToTeamsChannel,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365TeamsChannel)
            },
            [SourceType.O365Planner]: {
                linkToItem: this.linkToPlannerPlan,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365Planner)
            },
            [SourceType.Jira]: {
                linkToJiraProject: this.linkToJiraProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.Jira)
            },
            [SourceType.File]: {
                linkToFile: this.linkToFile,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.File)
            },
            [SourceType.VSTS]: {
                linkToVSTSProject: this.linkToVSTSProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.VSTS)
            },
            [SourceType.Spo]: {
                linkToPoProject: this.linkToPoProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.Spo)
            },
            [SourceType.MondayCom]: {
                linkToMondayComBoard: this.linkToMondayComBoard,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.MondayCom)
            },
            [SourceType.Smartsheet]: {
                linkToSmartsheetWorkspace: this.linkToSmartsheetWorkspace,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.Smartsheet)
            },
            [SourceType.P4W]: {
                linkToP4WProject: this.linkToP4WProject,
                deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.P4W)
            }
        };
    }

    private linkToO365Group = (linkData: ILinkDto<O365GroupLinkInfo>) => {
        this.props.programsActions.linkToO365Group(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to o365 group has been started.` });
        analytics.trackLink('Linked Program to o365 group', this.props.user);
    }

    private linkToTeamsChannel = (linkData: ILinkDto<TeamsChannelLink>) => {
        this.props.programsActions.linkToTeamsChannel(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to teams channel has been started.` });
        analytics.trackLink('Linked Program to teams channel', this.props.user);
    }

    private deleteLink = (connectionId: string, type: SourceType) => {
        this.props.programsActions.deleteProgramToExternalSystemLink(this.props.entity.id, connectionId, type);
        this.props.notificationsActions.pushNotification({ message: `Unlinking program '${this.props.entity.attributes.Name}' has been started.` });
    }

    private linkToFile = (fileUrl: string) => {
        this.props.programsActions.linkToFile(this.props.entity.id, fileUrl);
        analytics.trackLink('Linked Program to file', this.props.user);
    }

    private linkToPlannerPlan = (linkData: ILinkDto<IPlanInfo>) => {
        this.props.programsActions.linkToPlannerPlan(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to plan in Planner has been started.` });
        analytics.trackLink('Linked Program to Planner plan', this.props.user);
    }

    private linkToJiraProject = (linkData: ILinkDto<IJiraLinkInfo>) => {
        this.props.programsActions.linkToJiraProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to Jira project has been started.` });
        analytics.trackLink('Linked Program to Jira project', this.props.user);
    }

    private linkToVSTSProject = (linkData: ILinkDto<IVSTSLinkData>) => {
        this.props.programsActions.linkToVSTSProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to Azure DevOps project has been started.` });
        analytics.trackLink('Linked Program to Azure DevOps project', this.props.user);
    }

    private linkToPoProject = (linkData: ILinkDto<ISpoProject>) => {
        this.props.programsActions.linkToPoProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to project in Project Online has been started.` });
        analytics.trackLink('Linked Program to Project Online project', this.props.user);
    }

    private linkToMondayComBoard = (linkData: ILinkDto<IMondayComBaseSourceData>) => {
        this.props.programsActions.linkToMondayComBoard(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to board in Monday.com has been started.` });
        analytics.trackLink('Linked Program to Monday.com board', this.props.user);
    }

    private linkToSmartsheetWorkspace = (linkData: ILinkDto<ISmartsheetProgramSourceData>) => {
        this.props.programsActions.linkToSmartsheetWorkspace(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to workspace in Smartsheet has been started.` });
        analytics.trackLink('Linked Program to Smartsheet workspace', this.props.user);
    }

    private linkToP4WProject = (linkData: ILinkDto<IP4WProject>) => {
        this.props.programsActions.linkToP4WProject(this.props.entity.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking program '${this.props.entity.attributes.Name}' to project in Project for the Web has been started.` });
        analytics.trackLink('Linked Program to Project for the Web project', this.props.user);
    }

    private _renderFile(props: ConnectProps) {
        return <FileConnectControl key="file" {...props} />;
    }

    private _renderPlanner = (props: ConnectProps) => {
        return <O365PlanConnectControl
            {...props}
            key="planner"
            allowNewItems={false}
            allowMultipleConnectionsToOnePlan
            showPlanGroupConnection={false}
            connectToGroupEnabled={false} />;
    }

    private _renderJira(props: ConnectProps) {
        return <JiraConnectControl
            {...props}
            key="jira"
            allowMultipleConnectionsToOneProject
            connectionType={JiraConnectionType.Program}
            allowConfigureConnection={false} />;
    }

    private _renderVSTS(props: ConnectProps) {
        return <VSTSConnectControl
            {...props}
            key="vsts"
            connectionType={VSTSConnectionType.Program}
            allowMultipleConnectionsToOneProject
            disableConfigureConnection />;
    }

    private _renderSpo(props: ConnectProps) {
        return <SpoConnectControl
            {...props}
            key="spo"
            allowNewItems={false}
            allowMultipleConnectionsToOneProject
            disableConfigureMapping />;
    }

    private _renderP4W(props: ConnectProps) {
        return <P4WConnectControl
            {...props}
            key="p4w"
            allowNewItems={false}
            allowMultipleConnectionsToOneProject
            disableConfigureMapping />;
    }

    private _renderMondayCom(props: ConnectProps) {
        return <MondayComConnectControl
            {...props}
            key="mondaycom"
            allowMultipleConnectionsToOneProject
            disableConfigureConnection />;
    }

    private _renderSmartsheet(props: ConnectProps) {
        return <SmartsheetProgramConnectControl
            {...props}
            key="smartsheet" />;
    }

    private _rendersMap: RendersMap = {
        [SourceType.File]: { render: this._renderFile },
        [SourceType.O365Planner]: { render: this._renderPlanner, quickNavigation: true, epmEntityName: "plan" },
        [SourceType.Jira]: { render: this._renderJira, quickNavigation: true },
        [SourceType.VSTS]: { render: this._renderVSTS, quickNavigation: true },
        [SourceType.Spo]: { render: this._renderSpo, quickNavigation: true },
        [SourceType.MondayCom]: { render: this._renderMondayCom, quickNavigation: true, epmEntityName: "board" },
        [SourceType.Smartsheet]: { render: this._renderSmartsheet, quickNavigation: true, epmEntityName: "Workspace" },
        [SourceType.P4W]: { render: this._renderP4W, quickNavigation: true }
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        integrations: new Integrations(state.tenant.subscription.integrations)
    }
}

function mergeActionCreators(dispatch: any) {
    return {
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ExternalEpmConnectControl);