import { IFilter, BaseFilterValue, Filter, IFilterHelper, FilterAttribute, Field, getLabel, IEntityFilterHelper, IActiveFilter, Layout } from "../../entities/Metadata";
import { IUserInfo, Dictionary, StatusCategory, statusCategoryMap } from "../../entities/common";
import { Portfolio, PortfolioAttrs } from "../PortfoliosListStore";
import { DisplayFieldService, InputsService } from "../../components/common/DisplayFieldService";
import { FieldsService } from "../../components/common/FieldsService";
import * as React from "react";
import { Option, OptionsPickerProps } from "../../components/common/inputs/OptionsPicker";
import * as PortfolioUtils from "./utils";
import { namesof } from '../services/metadataService';
import { buildLayoutFilterHelper } from "../project/filters";
import { HUNDRED_PCT } from "../../components/utils/common";

export interface PortfolioFilterValue extends BaseFilterValue {
    percentComplete?: { from: number, to: number },
    projectStatuses?: string[]
    layoutId?: string[];
}

export class ActiveFilter {
    private readonly _filter: IFilter<PortfolioFilterValue>;

    constructor(name?: string) {
        this._filter = Filter.empty(name);
    }

    public withManager(userInfo: IUserInfo): ActiveFilter {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        this._filter.value.attributes['Manager'] = [userInfo];
        this._filter.attributeNames.push({ name: 'Manager', type: "attributes" });
        return this;
    }

    public withTag(tag: string): ActiveFilter {
        if (!this._filter.value.attributes) {
            this._filter.value.attributes = new Dictionary<any[]>();
        }
        const attrName = 'Tags';
        this._filter.value.attributes[attrName] = [tag];
        this._filter.attributeNames.push({ name: attrName, type: "attributes" });
        return this;
    }

    public withAttributes(attrNames: string[]) {
        const attributes = this._filter.attributeNames.filter(_ => _.type === "attributes");
        attrNames.forEach(name => {
            const attribute = attributes.find(attr => attr.name === name);
            if (!attribute) {
                this._filter.attributeNames.push({ name: name, type: "attributes" });
            }
        });
        return this;
    }

    public build(): IFilter<PortfolioFilterValue> {
        return this._filter;
    }
}

export type FilterHelperProps = {
    fields: Field[];
    layouts: Layout[];
}

export class FilterHelper implements IEntityFilterHelper<Portfolio> {
    public getFilterAttributes = (fields: Field[]): FilterAttribute<PortfolioFilterValue>[] => {
        const attrs = fields.map(_ => ({ type: "attributes", value: _, name: _.name, displayName: getLabel(_) }) as FilterAttribute<PortfolioFilterValue>);
        attrs.push({ type: "percentComplete", name: "percentComplete", displayName: "% Complete", value: undefined });
        attrs.push({ type: "projectStatuses", name: "projectStatuses", displayName: "Project Statuses", value: undefined });
        attrs.push({ type: "layoutId", name: "layoutId", displayName: "Layout", value: undefined });
        return attrs;
    }

    private attributes: IFilterHelper<PortfolioFilterValue, Portfolio> = {
        buildFilterElement: (attr: FilterAttribute<PortfolioFilterValue>,
            filter: IFilter<PortfolioFilterValue>,
            onFilterEditComplete: (type: string | number, name: string, value: any) => void
        ): JSX.Element | null => {
            const field: Field = attr.value;

            return DisplayFieldService.buildFieldMultiSelectInput(field,
                filter.value?.attributes?.[field.name],
                changed => onFilterEditComplete(attr.type, field.name, changed));
        },
        removeFilterAttribute: (attrName: string, typeValue: any) => {
            const newValue: Dictionary<any> = {};
            Object.keys(typeValue).forEach(vk => {
                if (vk !== attrName) {
                    newValue[vk] = typeValue[vk];
                }
            });
            return newValue;
        },
        setAttributeValue: (attrName: string, value: any, oldValue: any) => {
            if (!oldValue) {
                oldValue = {};
            }
            const tmpValue = Object.assign({}, oldValue);
            tmpValue[attrName] = value;
            return tmpValue;
        },
        getAttributeValues: (value: any): string[] => FieldsService.getAttributeDisplayValues(this._props.fields, value),
        validateItem: (item: Portfolio, filterValue: any, attributes: any[]): boolean => {
            if (!filterValue) {
                return false;
            }
            for (const key in filterValue) {
                if (filterValue.hasOwnProperty(key)) {
                    if (filterValue[key] === undefined || (Array.isArray(filterValue[key]) && filterValue[key].length === 0)) {
                        continue;
                    }

                    const attribute = attributes.find(_ => _.name === key);
                    if (!attribute) {
                        return false;
                    }
                    const field = attribute.value;
                    const value = item.attributes[key];

                    if (!FieldsService.compareFieldValues(field, value, filterValue[key])) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    private percentComplete: IFilterHelper<PortfolioFilterValue, Portfolio> =
        {
            buildFilterElement: (
                attr: FilterAttribute<PortfolioFilterValue>,
                filter: IFilter<PortfolioFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const percentCompleteValue: { from: number, to: number } = filter.value.percentComplete || { from: 0, to: 0 };
                const propsFrom = {
                    key: "from-slider",
                    onEditComplete: (value: number) => {
                        const newValue = Object.assign({}, percentCompleteValue);
                        newValue.from = value;
                        if (newValue.from > newValue.to) {
                            newValue.to = newValue.from;
                        }
                        onFilterEditComplete(attr.type, "percentComplete", newValue);
                    },
                    value: percentCompleteValue.from,
                    min: 0,
                    max: 100,
                    step: 10,
                    label: "From"
                };

                const propsTo = {
                    key: "to-slider",
                    onEditComplete: (value: number) => {
                        const newValue = Object.assign({}, percentCompleteValue);
                        newValue.to = value;
                        if (newValue.to < newValue.from) {
                            newValue.from = newValue.to;
                        }
                        onFilterEditComplete(attr.type, "percentComplete", newValue);
                    },
                    value: percentCompleteValue.to,
                    min: 0,
                    max: 100,
                    step: 10,
                    label: "To"
                }
                return React.createElement("div",
                    undefined,
                    [InputsService.buildSlider(propsFrom), InputsService.buildSlider(propsTo)]);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return undefined;
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                if (value) {
                    return [value.from.toString(), value.to.toString()];
                }

                return [];
            },
            validateItem: (item: Portfolio, filterValue: any): boolean => {
                if (!filterValue) {
                    return true;
                }

                const { total, completed } = PortfolioUtils.getProjectsMetrics(item.calculation);
                const percentCompleteAttr = total ? completed / total * HUNDRED_PCT : 0;
                const { from, to } = filterValue;

                return from <= percentCompleteAttr && to >= percentCompleteAttr;
            }
        }
    private projectStatuses: IFilterHelper<PortfolioFilterValue, Portfolio> =
        {
            buildFilterElement: (
                attr: FilterAttribute<PortfolioFilterValue>,
                filter: IFilter<PortfolioFilterValue>,
                onFilterEditComplete: (type: string | number, name: string, value: any) => void
            ): JSX.Element | null => {
                const statusOptions: Option[] = Object.keys(StatusCategory)
                    .filter(_ => !Number.isNaN(Number(_)))
                    .map(_ => ({ key: _, text: statusCategoryMap[_].title }));

                const selectedItems: Option[] = [];

                const value = <string[]>filter.value.projectStatuses || [];

                value.forEach(_ => {
                    const opt = statusOptions.find(__ => __.key === _);
                    if (opt !== undefined) {
                        selectedItems.push(opt);
                    }
                });

                const inputProps: Partial<OptionsPickerProps> = {
                    onChange: (opts?: Option[]) => {
                        onFilterEditComplete(attr.type,
                            "projectStatuses",
                            opts ? opts.map(_ => _.key) : undefined);
                    },
                    onResolveSuggestions: (_filter: string, _selectedItems?: Option[]) => {
                        let res: Option[] =
                            statusOptions.filter(_ => _.text.toLowerCase().indexOf(_filter.toLowerCase()) !== -1);
                        if (_selectedItems) {
                            res = res.filter((opt: Option) => _selectedItems.find(_ => _.key === opt.key) === undefined);
                        }
                        return Promise.resolve(res);
                    },
                    selectedItems: selectedItems
                }
                return InputsService.buildOptionsPicker(inputProps);
            },
            removeFilterAttribute: (attrName: string, typeValue: any) => {
                return [];
            },
            setAttributeValue: (attrName: string, value: any, oldValue: any) => {
                return value;
            },
            getAttributeValues: (value: any): string[] => {
                return value ? (<number[]>value).map((_: number) => statusCategoryMap[_].title) : [];
            },
            validateItem: (item: Portfolio, filterValue: any): boolean => {
                const value = <number[]>filterValue || [];
                if (value.length === 0) {
                    return true;
                }
                for (const val of value) {
                    const color = StatusCategory[val];
                    if (item.calculation.projectStatuses[color]) {
                        return true;
                    }
                }

                return false;
            }
        }

    private layoutId = buildLayoutFilterHelper<PortfolioFilterValue, Portfolio>(() => this._props.layouts)

    private readonly _props: FilterHelperProps
    constructor(props: FilterHelperProps) {
        this._props = props;
    }

    public newFilter = (name: string): IActiveFilter => new ActiveFilter(name).withAttributes(namesof<PortfolioAttrs>(["OverallStatus", "Manager"]));
    public helpersMap: { [K: string]: IFilterHelper<BaseFilterValue, Portfolio> } =
        {
            "attributes": this.attributes,
            "percentComplete": this.percentComplete,
            "projectStatuses": this.projectStatuses,
            "layoutId": this.layoutId
        }
}
