import * as React from 'react';
import { IRow } from "../common/timeline/TimelineList";
import * as TimelineList from '../common/timeline/TimelineList';
import * as utils from "../common/timeline/utils";
import { ITask, PredecessorType } from '../../entities/Subentities';
import { EntityGroup } from "../common/extensibleEntity/EntityGroupHeader";
import { Dictionary, IExtensibleEntity, MaybeDate } from "../../entities/common";
import TaskIcon, { calculateShadeColor } from './TaskIcon';
import { PersonaSize, Persona } from 'office-ui-fabric-react';
import { adjustProgress, getPersonInfoImageUrl, HUNDRED_PCT, toDate } from '../utils/common';
import TaskTooltipContent from './TaskTooltipContent';
import TaskGroupTooltipContent, { GroupSummary } from './TaskGroupTooltipContent';
import { ITimelineMarker } from '../common/timeline/TimelineMarker';
import { Visibility, ITimelineSegment } from '../common/timeline/TimelineSegment';
import { ITimelineRelation, TimelineEntityType, TimelineRelationType } from '../common/timeline/TimelineRelation';
import { CalendarDataSet } from '../../store/CalendarStore';
import { ungroupedGroup } from '../common/inputs/GroupDropdown';
import { ITimelineBaseline } from '../common/timeline/TimelineBaseline';

const taskColorLightnessPercent: number = .2;
const taskShadeColorLightnessPercent: number = .5;
const baselineColorLightnessPercent: number = .7;

function buildSegments(key: string, minDate: Date, maxDate: Date, color?: string, lightnessPercent?: number): ITimelineSegment[] {
    if (!color) {
        color = ungroupedGroup.color;
    }
    const shadeColor = calculateShadeColor(color, lightnessPercent ?? taskShadeColorLightnessPercent);

    return [{
        key: key,
        startDate: minDate.getBeginOfDay(),
        finishDate: maxDate.getEndOfDay(),
        datesVisibility: Visibility.OnHover,
        className: `start-to-finish`,
        style: { backgroundColor: shadeColor, borderColor: color }
    }];
}

export type TaskRelation = { relatedId: string, type: PredecessorType, isReverse?: boolean };
export function buildTimelineItem(task: ITask, tasksMap: Dictionary<ITask>, children?: TaskRelation[]): IRow {
    const startDate = toDate(task.attributes.StartDate),
        finishDate = toDate(task.attributes.DueDate);
    const isMilestone = task.attributes.IsMilestone;

    let segments: ITimelineSegment[] = [];
    const markers: ITimelineMarker[] = [];
    let relations: ITimelineRelation[] = [];
    const baselines: ITimelineBaseline[] = [];
    if (startDate || finishDate) {
        const taskColor = getTaskColor(task);
        if (isMilestone) {
            if (finishDate) {
                markers.push({
                    key: task.id,
                    date: finishDate.getBeginOfDay(),
                    label: task.attributes.Name,
                    datesVisibility: Visibility.OnHover,
                    style: { backgroundColor: taskColor }
                });
            }
        }
        else {
            segments = [...segments, ...buildSegments(task.id, startDate ?? finishDate!, finishDate ?? startDate!, taskColor)];
        }

        if (children?.length) {
            relations = children
                .filter(_ => !!tasksMap[_.relatedId])
                .map(_ => ({ child: buildTimelineItem(tasksMap[_.relatedId], tasksMap), type: _.type, isReverse: _.isReverse }))
                .filter(_ => _.child.markers[0] || _.child.segments[0])
                .reduce<ITimelineRelation[]>((acc, _) => ([
                        ...acc,
                        {
                            key: `${task.id}_${_.child.key}`,
                            type: toRelationType(_.type),
                            parent: !!markers[0]
                                ? { ...markers[0], type: TimelineEntityType.Marker, key: task.id }
                                : { ...segments[0], type: TimelineEntityType.Segment, key: task.id },
                            child: !!_.child.markers[0]
                                ? { ..._.child.markers[0], type: TimelineEntityType.Marker, key: _.child.key }
                                : { ..._.child.segments[0], type: TimelineEntityType.Segment, key: _.child.key },
                            isReverse: _.isReverse
                        } as ITimelineRelation
                    ]), []);
        }
    }
    if (segments.length) {
        segments[segments.length - 1].addContent = true;
    }

    const baselineStartDate = toDate(task.baseline?.startDate);
    const baselineDueDate = toDate(task.baseline?.dueDate);
    if (isMilestone) {
        if (baselineDueDate) {
            baselines.push({
                key: task.id,
                finishDate: baselineDueDate.getBeginOfDay(),
                isMilestone: true
            });
        }
    } else if (baselineStartDate || baselineDueDate) {
        baselines.push({
            key: task.id,
            startDate: (baselineStartDate || baselineDueDate!).getBeginOfDay(),
            finishDate: (baselineDueDate || baselineStartDate!).getEndOfDay(),
        });
    }

    return {
        key: task.id,
        entity: task,
        markers,
        segments,
        relations,
        baselines
    };
}

function toRelationType(predecessorType: PredecessorType): TimelineRelationType {
    switch (predecessorType) {
        case PredecessorType.FinishToStart: return TimelineRelationType.RightToLeft
        case PredecessorType.FinishToFinish: return TimelineRelationType.RightToRight
        case PredecessorType.StartToFinish: return TimelineRelationType.LeftToRight
        case PredecessorType.StartToStart: return TimelineRelationType.LeftToLeft
        default: return TimelineRelationType.RightToLeft;
    }
}

function getTaskColor(task: ITask): string {
    const color = task.attributes.Group?.color || ungroupedGroup.color;
    return calculateShadeColor(color, taskColorLightnessPercent)!;
}

const leafTasksFilter = (_: ITask): boolean => !_.hierarchy.isParent;

export function calculateTaskGroupSummaryUsingAverageProgress(tasks: ITask[], calendar: CalendarDataSet, tasksFilter?: (task: ITask) => boolean): GroupSummary {
    const leafTasks = tasks.filter(_ => tasksFilter?.(_) ?? leafTasksFilter(_));
    const leafsSum = leafTasks.reduce<{ effort: number, progress: number, storyPoints: number }>((prev, task, index, arr) => {
        return {
            effort: prev.effort + (task.attributes.Effort || 0),
            progress: prev.progress + (task.attributes.Progress || 0),
            storyPoints: prev.storyPoints + (task.attributes.StoryPoints || 0),
        }
    }, { effort: 0, progress: 0, storyPoints: 0 });

    const tasksCountAndDates = calculateTasksCountAndDates(tasks, calendar);
    const progress = !leafTasks.length ? 0 : leafsSum.progress / leafTasks.length;

    return {
        ...tasksCountAndDates,
        progress: adjustProgress(progress),
        effort: leafTasks.every(_ => _.attributes.Effort === undefined) ? "N/A" : leafsSum.effort,
        storyPoints: leafTasks.every(_ => _.attributes.StoryPoints === undefined) ? "N/A" : leafsSum.storyPoints
    }
}

export function calculateTaskGroupSummaryUsingEffort(tasks: ITask[], calendar: CalendarDataSet): GroupSummary {
    const leafsSum = tasks.filter(leafTasksFilter).reduce<{ effort: number, storyPoints: number, completedWork: number }>((prev, task, index, arr) => {
        return {
            effort: prev.effort + (task.attributes.Effort || 0),
            storyPoints: prev.storyPoints + (task.attributes.StoryPoints ?? 0),
            completedWork: prev.completedWork + (task.attributes.CompletedWork || 0)
        }
    }, { effort: 0, completedWork: 0, storyPoints: 0 });

    const progress = !leafsSum.effort ? 0 : (HUNDRED_PCT * leafsSum.completedWork / leafsSum.effort);
    const tasksCountAndDates = calculateTasksCountAndDates(tasks, calendar);

    return {
        ...tasksCountAndDates,
        progress: adjustProgress(progress),
        effort: leafsSum.effort,
        storyPoints: leafsSum.storyPoints
    }
}

export function calculateTaskGroupSummaryUsingStoryPoints(tasks: ITask[], calendar: CalendarDataSet): GroupSummary {
    const leafsSum = tasks.filter(leafTasksFilter)
        .reduce<{ effort: number, storyPoints: number, completedStoryPoints: number }>(
            (prev, task) => ({
                effort: prev.effort + (task.attributes.Effort ?? 0),
                storyPoints: prev.storyPoints + (task.attributes.StoryPoints ?? 0),
                completedStoryPoints: prev.completedStoryPoints + (task.attributes.CompletedStoryPoints ?? 0)
            }),
            { effort: 0, storyPoints: 0, completedStoryPoints: 0 });

    const progress = !leafsSum.storyPoints ? 0 : (HUNDRED_PCT * leafsSum.completedStoryPoints / leafsSum.storyPoints);
    const tasksCountAndDates = calculateTasksCountAndDates(tasks, calendar);

    return {
        ...tasksCountAndDates,
        progress: adjustProgress(progress),
        effort: leafsSum.effort,
        storyPoints: leafsSum.storyPoints
    }
}

function calculateTasksCountAndDates(tasks: ITask[], calendar: CalendarDataSet): { startDate: Date | undefined, dueDate: Date | undefined, duration: number,
    tasksCounts: { new: number, inProgress: number, complete: number, total: number } } {
        
    const sum = tasks.reduce<{ new: number, inProgress: number, complete: number }>((prev, task, index, arr) => {
        return {
            inProgress: task.attributes.Progress !== 0 && task.attributes.Progress !== HUNDRED_PCT ? (prev.inProgress + 1) : prev.inProgress,
            new: task.attributes.Progress === 0 ? (prev.new + 1) : prev.new,
            complete: task.attributes.Progress === HUNDRED_PCT ? (prev.complete + 1) : prev.complete,
        }
    }, { new: 0, inProgress: 0, complete: 0 });

    const dates: MaybeDate[] = [...tasks.map(_ => ([_.attributes.StartDate, _.attributes.DueDate])).reduce((a, b) => a.concat(b), [])];
    const { minDate, maxDate } = utils.minMax(dates);
    const duration = minDate && maxDate && utils.getWorkingDaysBetweenDates(minDate.getBeginOfDay(), maxDate.getBeginOfDay(), calendar);

    return {
        startDate: minDate?.getBeginOfDay(),
        dueDate: maxDate?.getBeginOfDay(),
        duration: duration ?? 0,
        tasksCounts: {
            new: sum.new,
            inProgress: sum.inProgress,
            complete: sum.complete,
            total: tasks.length
        }
    }
}

export function estimateTaskEffort(task: ITask, calendar: CalendarDataSet): number | undefined {
    const { minDate, maxDate } = utils.minMax([task.attributes.StartDate, task.attributes.DueDate]);
    if (minDate && maxDate) {
        return utils.getWorkingHoursBetweenDates(minDate, maxDate, calendar);
    }
}

type GroupRowEntityAttrs = { group: EntityGroup, summary: GroupSummary }
type GroupRowEntity = IExtensibleEntity & { attributes: GroupRowEntityAttrs; }

const groupRowType = "group";

export function getGroupRow(group: EntityGroup): TimelineList.IRow {
    let segments: ITimelineSegment[] = [];
    const summary: GroupSummary = group.data as GroupSummary;

    if (summary.startDate && summary.dueDate && summary.startDate !== summary.dueDate) {
        segments = buildSegments(group.key, summary.startDate, summary.dueDate, group.color);
    }

    const entity: GroupRowEntity = {
        id: '', attributes: {
            group,
            summary
        }
    };
    return {
        key: group.key,
        rowType: groupRowType,
        entity,
        segments,
        markers: []
    }
}
export function renderSegmentTooltipContent(_: TimelineList.IRow, segment: ITimelineSegment, openTask: (task: ITask) => void): JSX.Element | undefined {
    return _.rowType === groupRowType
        ? <TaskGroupTooltipContent
            group={{ name: (_.entity as GroupRowEntity).attributes.group.name, color: (_.entity as GroupRowEntity).attributes.group.color }}
            summary={(_.entity as GroupRowEntity).attributes.summary}
        />
        : <TaskTooltipContent 
            task={_.entity as ITask}
            onClick={() => openTask(_.entity as ITask)} />;
}
export function renderSegmentContent(_: TimelineList.IRow, segment: ITimelineSegment): JSX.Element | undefined {
    if (_.rowType === groupRowType) {
        const groupEntity: GroupRowEntity = _.entity as GroupRowEntity;
        const { total, complete } = groupEntity.attributes.summary.tasksCounts;
        return <TimelineProgress progress={groupEntity.attributes.summary.progress} color={groupEntity.attributes.group.color ?? ungroupedGroup.color}>
            <div className="subitems-counter">{complete} / {total} <span className="extra">task{total != 1 ? 's' : ''}</span></div>
        </TimelineProgress>;
    }

    const task = _.entity as ITask;
    const assignee = task.attributes.AssignedTo?.[0] || undefined;

    return <TimelineProgress progress={task.attributes.Progress} color={getTaskColor(task)}>
        <div className="timeline-task-icon-wrapper">
            {
                assignee
                    ? <Persona size={PersonaSize.size16} imageUrl={getPersonInfoImageUrl(assignee)} text={assignee.fullName} hidePersonaDetails />
                    : <TaskIcon entity={task} />
            }
        </div>
    </TimelineProgress>;
}

export const TimelineProgress: React.FunctionComponent<{ progress: number | undefined, color: string | undefined }> = props => {
    const { progress, color, children } = props;
    return <div
        className={`progress ${progress === 0 ? 'empty' : ''}`}
        style={{
            width: `${Math.max(Math.min(progress || 0, 100), 0)}%`,
            backgroundColor: color,
            borderColor: color
        }}>
        {children}
    </div>
}