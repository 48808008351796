import * as React from 'react';
import { Checkbox, Icon } from 'office-ui-fabric-react';
import { suppressEvent } from '../../utils/common';

export type EntityGroup = { key: string, name: string, color: string, count: number, data?: any, hideMarker?: boolean }
type Props = {
    group: EntityGroup;
    isCollapsed: boolean;
    isSelected?: boolean;
    onToggleCollapse: (e: any) => void;
    onToggleSelect?: (e: any) => void
    onGroupNameClick?: (e: any) => void;
    renderCounter?: (group: EntityGroup) => JSX.Element | null;
    renderContent?: () => JSX.Element | null;
}

export default function EntityGroupHeader(props: Props) {
    const { group, isCollapsed, isSelected, onToggleCollapse, onToggleSelect, onGroupNameClick, renderContent, renderCounter } = props;
    return (
        <div className="group-header align-center">
            {onToggleSelect && <Checkbox checked={!!isSelected} onChange={onToggleSelect} />}
            <GroupChevron isCollapsed={isCollapsed}
                title={isCollapsed ? 'Expand' : 'Collapse'}
                color={group.color}
                onClick={onToggleCollapse} />
            {renderContent
                ? renderContent()
                : <span className={`bg-text group-name ${onGroupNameClick ? 'clickable' : ''}`}
                    style={{ color: group.color }}
                    title={onGroupNameClick ? "Manage groups" : undefined}
                    onClick={onGroupNameClick}>{group.name}
                </span>
            }
            {renderCounter ? renderCounter(group) : <span className="subitems-counter" title={`${group.count} item${group.count === 1 ? '' : 's'}`}>{group.count}</span>}
        </div>
    );
}

export function EntityChevron(props: { isCollapsed?: boolean, title?: string, className?: string, onClick: (e: any) => void }) {
    const { className, title, isCollapsed, onClick } = props;
    
    return <div className={`clickable ${className} chevron-wrapper`}
        title={title}
        onClick={suppressEvent(onClick)}
        data-selection-disabled>
        <Icon className='chevron-icon' iconName={isCollapsed ? 'ChevronRight' : 'ChevronDown'} data-selection-disabled />
    </div>
}

export class GroupChevron extends React.Component<{ isCollapsed?: boolean, title?: string, className?: string, color?: string; onClick: (e: any) => void }> {
    render() {
        const { className, title, color, isCollapsed, onClick } = this.props;

        const style: any = {};
        style[isCollapsed ? "borderLeftColor" : "borderTopColor"] = color ? "white" : undefined;

        return (
            <div className={`clickable circle ${className}`}
                title={title}
                style={{ borderColor: color, backgroundColor: color }}
                onClick={(e) => {
                    onClick(e);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                data-selection-disabled>
                <div className={isCollapsed ? 'arrow-right' : 'arrow-down'} style={style} data-selection-disabled />
            </div>
        );
    }
}