import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { UserState, isInReadonlyMode } from '../../store/User';
import { getInitials, getRTL, IconButton } from 'office-ui-fabric-react';
import { ILayoutActions, LayoutService } from '../utils/LayoutService';
import { EntityType, IWarning, Dictionary, ISourceInfo } from '../../entities/common';
import { Integrations, TenantState } from '../../store/Tenant';
import { notUndefined } from '../utils/common';
import { LayoutsState } from '../../store/layouts';
import PersistEntityViewMenu from '../common/PersistEntityViewMenu';
import EntityHeader from '../common/EntityHeader';
import { SummaryEntityWarning } from '../common/warnings/SummaryEntityWarning';
import Logo from '../common/Logo';
import ExternalEpmResourceConnectControl, { rendersMap } from './ExternalEpmResourceConnectControl';
import { Resource } from '../../store/ResourcesListStore';
import { SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';
import { ResourceTypeViewView } from '../views/list/columns/resource/Type';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import { Layout } from '../../entities/Metadata';

interface IActions {
    ExternalEpmResourceConnectControl?: Dictionary<any>;
    updateImage?: (image: File) => void;
    removeImage?: () => void;
    layoutActions?: ILayoutActions;
}

type OwnProps = {
    entity: Resource;
    warnings?: IWarning[];
    warningsTypeMap?: string[];
    actions: IActions;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
    layouts: LayoutsState;
    isLinking: boolean;
    integrations: Integrations;
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

const ResourceHeader = (props: Props) => {
    const [isCofigurationPanelOpen, setIsCofigurationPanelOpen] = React.useState(false);
    const [connector, setConnector] = React.useState<SourceType>();
    const [layoutToApply, setLayoutToApply] = React.useState<Layout>();

    const { entity, tenant, actions, layouts, user, warnings, integrations } = props;
    const isRTL = getRTL();

    const moreMenuItems = [
        entity.canConfigure && actions.layoutActions?.applyLayout
            ? {
                ...LayoutService.buildApplyLayoutMenuItem(layouts, (layout) => setLayoutToApply(layout), entity.layoutId),
                disabled: !entity.isEditable
            }
            : undefined,
    ].filter(notUndefined);

    const sources = integrations.getAvailable(rendersMap.types, entity.sourceInfos);
    const showLinks = !!props.actions.ExternalEpmResourceConnectControl;
    const readonlyMode = isInReadonlyMode(user, tenant);

    const _getLinkButtonTip = (sourceInfo: ISourceInfo) => {
        if (sourceInfo.type === SourceType.O365User) {
            return `Linked to Office 365 user \"${sourceInfo.sourceData.displayName}\"`;
        }

        if (sourceInfo.type === SourceType.Jira) {
            return `Linked to Jira user \"${sourceInfo.sourceData.displayName}\"`;
        }

        if (sourceInfo.type === SourceType.VSTS) {
            return `Linked to Azure DevOps user \"${sourceInfo.sourceData.displayName}\"`;
        }

        if (sourceInfo.type === SourceType.Spo) {
            return `Linked to Project Online resource \"${sourceInfo.sourceData.displayName}\"`;
        }

        if (sourceInfo.type === SourceType.MondayCom) {
            return `Linked to Monday.com resource \"${sourceInfo.sourceData.displayName}\"`;
        }

        if (sourceInfo.type === SourceType.Smartsheet) {
            return `Linked to Smartsheet resource \"${sourceInfo.sourceData.displayName}\"`;
        }

        if (sourceInfo.type === SourceType.MPPFile) {
            return `Linked to Project Desktop resource \"${sourceInfo.sourceData.displayName}\"`;
        }

        if (sourceInfo.type === SourceType.P4W) {
            return `Linked to Project for the Web resource \"${sourceInfo.sourceData.displayName}\"`;
        }

        return "Linked";
    };

    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className="res-logo" imageId={entity.imageId}
                text={getInitials(props.entity.name, isRTL)}
                onChanged={!entity.isEditable ? undefined : actions.updateImage}
                onRemove={!entity.isEditable ? undefined : actions.removeImage} />}
            leftSide={<>
                <ResourceTypeViewView type={entity.attributes.ResourceType} />
                {props.warningsTypeMap && <SummaryEntityWarning warnings={warnings || []}
                    message="There might be some data inconsistency in your resource. For more details please review items marked with warning icons below."
                    commonWarningTypes={props.warningsTypeMap} />}
            </>}
            buttons={<>
                {showLinks &&
                    entity.sourceInfos.map((source, index) => <span className="linked-system" key={`link-${index}-${source.type}`}><IconButton
                        iconProps={{
                            iconName: SourceType_.getIconName(source.type)
                        }}
                        title={_getLinkButtonTip(source)}
                        className="item"
                        disabled={!entity.isEditable || props.isLinking}
                        onClick={() => {
                            setIsCofigurationPanelOpen(true);
                            setConnector(source.type);
                        }} /></span>
                    )
                }
                {showLinks && !!sources.length && <IconButton
                    iconProps={{ iconName: 'PPMXLink' }}
                    className="connect-launcher"
                    title="Connect"
                    disabled={!entity.isEditable || props.isLinking}
                    onClick={() => {
                        setIsCofigurationPanelOpen(true);
                        setConnector(undefined);
                    }} />}
                {!!moreMenuItems.length && <IconButton
                    menuIconProps={{ iconName: 'More' }}
                    className='menu'
                    text='More'
                    menuProps={{ items: moreMenuItems, className: 'section-menu' }} />}
            </>}
            views={actions.layoutActions
                ? <PersistEntityViewMenu
                    layoutsState={layouts}
                    entity={entity}
                    entityType={EntityType.Resource}
                    readonlyMode={readonlyMode}
                    user={user}
                    viewLayout={actions.layoutActions?.viewLayout}
                    saveLayout={entity.isEditable ? actions.layoutActions?.saveLayout : undefined}
                    savePinnedViews={entity.canConfigure
                        ? actions.layoutActions?.updateEntityPinnedViews
                        : undefined}
                    saveEntityLayout={entity.canConfigure || readonlyMode
                        ? actions.layoutActions?.updateEntityLayout
                        : undefined}
                    deleteLayout={actions.layoutActions?.deleteLayout}
                />
                : undefined}
        ></EntityHeader>
        {
            isCofigurationPanelOpen && actions.ExternalEpmResourceConnectControl &&
            <ExternalEpmResourceConnectControl
                sourceInfos={entity.sourceInfos}
                sources={sources}
                integrations={props.integrations}
                initialConnector={connector}
                actions={actions.ExternalEpmResourceConnectControl}
                onDismiss={() => {
                    setIsCofigurationPanelOpen(false);
                    setConnector(undefined);
                }}
            />
        }
        {
            layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => actions.layoutActions!.applyLayout!(layoutToApply)}
                onDismiss={() => setLayoutToApply(undefined)}
                entityType={EntityType.Resource}
                layoutName={layoutToApply!.name}
            />
        }
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        isLinking: state.resources.isLinking,
        layouts: state.layouts[EntityType.Resource],
        integrations: new Integrations(state.tenant.subscription.integrations)
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(ResourceHeader));