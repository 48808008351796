import React, { useState } from 'react';
import { connect } from 'react-redux';
import Logo from "../Logo";
import { Portfolio } from '../../../store/PortfoliosListStore';
import { ApplicationState } from "../../../store/index";
import Link from "../Link";
import { IPortfolioInfo } from '../../../store/ProjectsListStore';
import { Dictionary, EntityType, IEntityInfo, IWithImage } from '../../../entities/common';
import { PPMFeatures, Subscription } from '../../../store/Tenant';

type OwnProps = {
    portfolio?: IPortfolioInfo | IPortfolioInfo[];
    withNavigation?: boolean;
    target?: string;
};
type StateProps = {
    portfoliosById: Dictionary<Portfolio>;
    subscription: Subscription;
}
type Props = OwnProps & StateProps;

export const shortListLimit = 5;

class PortfolioFormatter extends React.PureComponent<Props>{
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { portfolio, withNavigation, portfoliosById, target, subscription } = this.props;

        if (!portfolio) {
            return null;
        }
        const entities = (Array.isArray(portfolio) ? portfolio : [portfolio])
            .map(_ => ({ ..._, imageId: portfoliosById[_.id]?.imageId }));

        return <FormatterLogoItems
            entityType={EntityType.Portfolio}
            entities={entities}
            withNavigation={withNavigation && Subscription.contains(subscription, PPMFeatures.PortfolioManagement)}
            target={target}
            cssClass="pf-logo" />
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        portfoliosById: state.portfolios.byId,
        subscription: state.tenant.subscription
    }
}

export default connect(mapStateToProps, {})(PortfolioFormatter);

export const FormatterLogoItems = (props: {
    entityType: EntityType,
    entities: (IEntityInfo & IWithImage)[],
    withNavigation?: boolean,
    target?: string,
    cssClass?: string
}) => {
    if (props.entities.length === 1) {
        const entity = props.entities[0];
        return props.withNavigation
            ? <Link href={`/${props.entityType}/${entity.id}`} className="pf-box" target={props.target}>
                <Logo className={props.cssClass} text={entity.name} imageId={entity.imageId} />
                <span className="overflow-text">{entity.name}</span>
            </Link>
            : <div className="pf-box">
                <Logo className={props.cssClass} text={entity.name} imageId={entity.imageId} />
                <span>{entity.name}</span>
            </div>;
    }

    const [showAll, setShowAll] = useState(false);
    return (<div className="pf-box">
        {(showAll || props.entities.length <= shortListLimit ? props.entities : props.entities.slice(0, shortListLimit))
            .map((p, index) => props.withNavigation
                ? <Link key={index} href={`/${props.entityType}/${p.id}`} title={p.name} target={props.target}>
                    <Logo className={props.cssClass} imageId={p.imageId} />
                </Link>
                : <Logo key={index} text={p.name} className={props.cssClass} imageId={p.imageId} />)}
        {!showAll && props.entities.length > shortListLimit
            && <span className="res-cut" onClick={(e) => { setShowAll(true); e.stopPropagation(); }} title="Show More"
            >+{props.entities.length - shortListLimit}</span>}
    </div>);
}
