import './ChoiseList.css'; 
import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';

type Item = {
    name: string;
    description: string;
    iconName?: string;
    selected?: boolean;
}

const ChoiseItem = ({ name, description, iconName, selected, onClick }: Item & { onClick: () => void; }) => {
    return <div className={`choise-item ${selected ? 'selected' : ''}`} onClick={onClick}>
        <Icon iconName={iconName} />
        <div className='choise-item-text'>
            <div className='choise-item-header'>
                <div className='overflow-text'>{name}</div>
                {selected && <Icon iconName='SkypeCircleCheck' />}
            </div>
            <div className='choise-item-description overflow-text'>{description}</div>
        </div>
    </div>
}

export const ChoiseList = <T extends Item>({ items, onClick }: { items: T[], onClick: (item: T) => void }) => {
    return <div className='choise-list'>
        {
            items.map(_ => <ChoiseItem {..._} key={_.name} onClick={() => onClick(_)} />)
        }
    </div>
}