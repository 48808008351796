import "./PPMXFieldComboBox.css";
import * as React from 'react';
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { Field, getLabel } from "../../../../entities/Metadata";
import { upperFirstSort } from '../../../utils/common';
import { AddNewFieldButton } from "./AddNewFieldButton";
import FieldOption from "../../../field/FieldOption";

type Props = {
    fields: Field[];
    selected?: string;
    isValid: boolean;
    rowNumber: number;
    allowManageFields: boolean;
    onAddNewField: (rowNumber: number) => void;
    onChangeField: (rowNumber: number, key?: string) => void
}

const calloutProps = { className: "ppmx-field-combobox-callout" }

export const PPMXFieldComboBox = ({ fields, selected, isValid, rowNumber, allowManageFields, onAddNewField, onChangeField }: Props) => {
    const onAddNewFieldClick = React.useCallback(() => onAddNewField(rowNumber), [rowNumber, onAddNewField]);
    const onRenderLowerContent = React.useCallback(
        () => <AddNewFieldButton onClick={onAddNewFieldClick} allowManageFields />,
        [rowNumber, onAddNewField, allowManageFields]);
    const options = React.useMemo(
        () => fields
            .map<IComboBoxOption>(_ => ({ key: _.name, text: getLabel(_), data: _ }))
            .sort((a, b) => upperFirstSort(a.text, b.text)),
        [fields.length]);
    const onFieldChange = React.useCallback((_: any, o?: IComboBoxOption) => onChangeField(rowNumber, o?.key as string), [rowNumber]);
    return <ComboBox
        calloutProps={calloutProps}
        options={options}
        selectedKey={selected}
        allowFreeform={true}
        placeholder="Select field"
        className={"mapping-field" + (!isValid ? " error" : "")}
        onChange={onFieldChange}
        dropdownWidth={300}
        onRenderOption={FieldOption}
        onRenderLowerContent={onRenderLowerContent} />
}