import * as React from 'react';
import * as analytics from '../../analytics';
import { DefaultButton, ActionButton, Icon, IconButton, IContextualMenuItem, ContextualMenuItemType, IContextualMenuProps, IIconProps } from 'office-ui-fabric-react';
import RemoveDialog from './RemoveDialog';
import { ISelectableItem, NEW_ID } from '../../entities/Metadata';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { upperFirstSort } from '../utils/common';
import { isInReadonlyMode } from '../../store/User';

interface IPreItem {
    key: string;
    name: string;
}

export interface IProps<T extends ISelectableItem> {
    iconProps?: IIconProps;
    disabled?: boolean;
    title?: string;
    preItems?: {
        active?: IPreItem,
        items: IPreItem[];
        onChange: (item?: IPreItem) => void;
    };
    useSeparators?: boolean;
    entityLabel?: string;
    activeItem?: T;
    items: T[];
    onActiveItemChanged: (id: string) => void;
    onItemEditClick?: (id: string) => void;
    onItemRemoveClick?: (id: string) => void;
    onItemAddClick?: () => void;
    onItemCopyClick?: (_: T) => void;
    canManageConfiguration?: boolean;

    itemType: string;
}

type StateProps = { isReadOnlyMode: boolean; };

type Props<T extends ISelectableItem> = StateProps & IProps<T>;

class ListMenuItemSelector<T extends ISelectableItem> extends React.Component<Props<T>, { itemToRemove?: T }> {
    constructor(props: Props<T>) {
        super(props);
        this.state = {};
    }
    public render() {
        const { iconProps, preItems, activeItem, itemType, onItemRemoveClick, disabled, title } = this.props;
        const { itemToRemove } = this.state;
        const text = (activeItem?.name || `Select ${itemType}`) + (preItems?.active?.name ? ` [${preItems?.active?.name}]` : '');
        return <>
            {!!itemToRemove && !!onItemRemoveClick && <RemoveDialog
                key="remove-details-view-dialog"
                onClose={() => this.setState({ itemToRemove: undefined })}
                onComplete={() => onItemRemoveClick(itemToRemove.id)}
                confirmButtonProps={{ text: "Delete" }}
                dialogContentProps={{
                    title: `Delete ${itemType}`,
                    subText: `Are you sure you want to delete ${itemType} "${itemToRemove.name}" ?`
                }} />}
            <DefaultButton
                disabled={disabled}
                iconProps={iconProps}
                title={title}
                className={`dropdown-button filter-btn ${activeItem?.isNotSaved ? "not-saved" : ""}`}
                text={text}
                menuProps={this._buildMenuItemsProps()} />
        </>;
    }

    private _buildMenuItemsProps = (): IContextualMenuProps => {
        const { preItems, activeItem, items, itemType, useSeparators, entityLabel = "item", canManageConfiguration, isReadOnlyMode,
            onActiveItemChanged, onItemEditClick, onItemRemoveClick, onItemAddClick, onItemCopyClick } = this.props;
        let menuItems: IContextualMenuItem[] = [];
        if (preItems?.items.length) {
            if (useSeparators) {
                menuItems.push({ key: `pre-${entityLabel}`, text: `Pre-${entityLabel}`, itemType: ContextualMenuItemType.Header });
            }
            menuItems = [...menuItems, ...preItems.items.map<IContextualMenuItem>(_ => ({
                key: _.key,
                text: _.name,
                iconProps: { iconName: _.key === preItems.active?.key ? 'CheckMark' : undefined },
                onClick: () => { preItems.onChange(_.key === preItems.active?.key ? undefined : _); return true; }
            }))];
        }

        if (items.length) {
            const renderMenuItems = (menuItem: T, item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => {
                return <SelectableItem
                    text={menuItem.name}
                    title={`${menuItem.name} (${menuItem.isPublic ? "public" : "private"})`}
                    isSelected={menuItem.id === activeItem?.id}
                    dismissMenu={dismissMenu}
                    onClick={() => {
                        if (menuItem.id !== this.props.activeItem?.id) {
                            onActiveItemChanged(menuItem.id);
                        }
                    }}
                    onEditClick={!menuItem.isBuiltIn && (!menuItem.isPublic || canManageConfiguration) && onItemEditClick && !(isReadOnlyMode && menuItem.id !== NEW_ID)
                        ? () => onItemEditClick(menuItem.id)
                        : undefined}
                    onDeleteClick={!menuItem.isBuiltIn && (!menuItem.isPublic || canManageConfiguration) && onItemRemoveClick && !(isReadOnlyMode && menuItem.id !== NEW_ID)
                        ? () => { this.setState({ itemToRemove: menuItem }); }
                        : undefined}
                    onCopyClick={!menuItem.isBuiltIn && !(!menuItem.isPublic || canManageConfiguration) && onItemCopyClick && !isReadOnlyMode
                        ? () => onItemCopyClick(menuItem)
                        : undefined}
                />
            }

            const builtInItems = items.filter(_ => _.isBuiltIn);

            if (builtInItems.length) {
                if (useSeparators) {
                    menuItems.push({ key: `default-${entityLabel}`, text: `Default ${entityLabel}`, itemType: ContextualMenuItemType.Header });
                }

                const contextualMenuItems = builtInItems.map<IContextualMenuItem>(_ => ({
                    key: _.id || 'new-item',
                    onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => renderMenuItems(_, item, dismissMenu)
                }));

                menuItems = [...menuItems, ...contextualMenuItems];
            }

            const notBuiltInItems = items.filter(_ => !_.isBuiltIn)?.sort((a, b) => upperFirstSort(a.name, b.name));

            if (notBuiltInItems.length) {
                if (useSeparators) {
                    menuItems.push({ key: `custom-${entityLabel}`, text: `Custom ${entityLabel}`, itemType: ContextualMenuItemType.Header });
                }

                const contextualMenuItems = notBuiltInItems.map<IContextualMenuItem>(_ => ({
                    key: _.id || 'new-item',
                    onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => renderMenuItems(_, item, dismissMenu)
                }));

                menuItems = [...menuItems, ...contextualMenuItems];
            }
        }

        if (onItemAddClick) {
            if (menuItems.length) {
                menuItems.push({
                    key: 'divider_items',
                    itemType: ContextualMenuItemType.Divider
                });
            }
            menuItems = [...menuItems, {
                key: 'add-button',
                iconProps: { iconName: 'PPMXAdd' },
                text: `New ${itemType}`,
                onClick: () => {
                    onItemAddClick();
                    return true;
                }
            }];
        }

        return {
            items: menuItems,
            gapSpace: 10,
            useTargetAsMinWidth: true,
            calloutProps: {
                className: "header-callout"
            }
        };
    }
}

export default function <T extends ISelectableItem>() {
    function mapStateToProps(state: ApplicationState, ownProps: IProps<T>): StateProps {
        return {
            isReadOnlyMode: isInReadonlyMode(state.user, state.tenant),
        };
    }

    return connect(mapStateToProps, {})(ListMenuItemSelector as new (props: Props<T>) => ListMenuItemSelector<T>);
}

type SelectableItemProps = {
    text: string;
    title?: string;
    isSelected: boolean;
    onClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    onCopyClick?: () => void;
    dismissMenu: (ev?: any, dismissAll?: boolean) => void;
}

export const SelectableItem = (props: React.PropsWithChildren<SelectableItemProps>) => <div className="ms-ContextualMenu-link align-center">
        <ActionButton
            className='selectable-item'
            text={props.text}
            title={props.title ?? props.text}
            onClick={() => {
                props.onClick?.();
                props.dismissMenu();
            }}
            onRenderIcon={() => <Icon className="ms-Icon" iconName={props.isSelected ? 'CheckMark' : ''} />}
        >{props.children}</ActionButton>
        {(props.onEditClick || props.onDeleteClick) &&
            <div className="align-center actions">
                {props.onEditClick &&
                    <IconButton
                        menuIconProps={{ iconName: 'Edit' }}
                        title="Edit"
                        className='menu'
                        onClick={() => { props.onEditClick!(); props.dismissMenu(); }} />}
                {props.onDeleteClick &&
                    <IconButton
                        menuIconProps={{ iconName: 'Delete' }}
                        title="Delete"
                        className='menu'
                        onClick={() => { props.onDeleteClick!(); props.dismissMenu(); }} />}
                {props.onCopyClick && <IconButton
                    menuIconProps={{ iconName: 'Copy' }}
                    title="Copy"
                    className='menu'
                    onClick={() => { props.onCopyClick!(); props.dismissMenu(); }} />}
            </div>}
    </div>;