import * as React from 'react';
import * as Metadata from '../../entities/Metadata';
import { EntityType, StatusCategory } from '../../entities/common';
import { connect } from 'react-redux';
import { IObjectiveAttrs, Objective, objectiveStatesMap, OKRValueType } from '../../store/ObjectivesListStore';
import { nameof } from '../../store/services/metadataService';
import { ApplicationState } from '../../store/index';
import Logo from "../common/Logo";
import ResourceFormatter from '../common/formatters/ResourceFormatter';
import { OKRValueFormatter, PeriodFormatter, ProgressFormatter } from '../common/formatters/OkrFormatters';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';

type OwnProps = { entity: Objective };
type StoreProps = { fields: Metadata.Field[] }
type Props = OwnProps & StoreProps;

const ObjectiveTooltipContent = (props: Props) => {
    const { entity } = props;
    const statusField = props.fields.find(_ => _.name == nameof<IObjectiveAttrs>("Status"))!;    
    const objectiveStatusDescriptor = StatusDescriptorFactory.createStatusDescriptor(statusField);
    const statusOption = objectiveStatusDescriptor.getOptionOrDefault(entity.attributes.Status, StatusCategory.NA);

    return (
        <div className="timeline-tooltip">
            <div className="header">
                <Logo className={`objective ${objectiveStatesMap[entity.attributes.State].cssClassName}`} />
                <div className="title"><div className="overflow-text" title={entity.attributes.Name}>{entity.attributes.Name}</div></div>
                <CategoryColorStatusView
                    statusOption={statusOption}
                    title={Metadata.getLabel(statusField)}
                />
            </div>
            <div className="content">
                {renderField("Period", <div><PeriodFormatter start={entity.attributes.StartDate} finish={entity.attributes.FinishDate} /></div>)}
                {renderField("Scope", <div>{entity.attributes.Scope}</div>)}
                {renderField("Progress", <div><ProgressFormatter value={entity.attributes.Progress} /></div>)}
                {entity.attributes.ValueType != OKRValueType.Flag && renderField("StartValue", <div><OKRValueFormatter value={entity.attributes.StartValue} type={entity.attributes.ValueType} /></div>)}
                {entity.attributes.ValueType != OKRValueType.Flag && renderField("TargetValue", <div><OKRValueFormatter value={entity.attributes.TargetValue} type={entity.attributes.ValueType} /></div>)}
                {renderField("CurrentValue", <div><OKRValueFormatter value={entity.attributes.CurrentValue} type={entity.attributes.ValueType} /></div>)}
                {renderField("Manager", <div className="align-center"><ResourceFormatter resource={entity.attributes.Manager} /></div>)}
            </div>
        </div>
    );

    function renderField(name: keyof IObjectiveAttrs | 'Period', valueElement: JSX.Element): JSX.Element | null {
        const field = props.fields.find(_ => _.name === name);

        return (
            <div className="item align-center">
                <div className="label">{field ? Metadata.getLabel(field) : name}</div>
                {valueElement}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    let fields = state.fields[EntityType.Objective];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
    };
}

export default connect(mapStateToProps)(ObjectiveTooltipContent);