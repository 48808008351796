import React from "react";
import { StatusTrendType, trendIconMap } from "../../../../entities/common";
import { abbreviateNumber } from "../../../utils/common";
import { Icon } from "office-ui-fabric-react";

export type ColoredStatusViewProps = {
    trend?: StatusTrendType;
    className?: string;
    itemsCount?: number;
    title?: string;
    onClick?: (e: any) => void;
};

const ColoredStatusView = ({ value, className, onClick, itemsCount, trend }: {
    value: {
        title?: string,
        name: string;
        color?: string;
    }
} & ColoredStatusViewProps) => {
    const expr: string | undefined = itemsCount !== undefined ? abbreviateNumber(itemsCount) : undefined;
    return <div className={`color-status ${className ?? ''} ${onClick ? 'clickable' : ''} ${itemsCount && itemsCount > 0 ? 'with-bookmark' : ''}`}
        onClick={onClick}
        title={value.title}>
        <div className="align-center status-wrapper status" style={{ backgroundColor: value.color }}>
            {trend !== undefined && <Icon iconName={trendIconMap[trend]} className="status-icon" />}
            <span className="status-label overflow-text">{value.name + (expr ? ` (${expr})` : "")}</span>
            {itemsCount && itemsCount > 0 && <span className="bookmark"></span>}
        </div>
    </div>
}

export default ColoredStatusView;