import { IScale } from "./TimelineScale";
import * as React from 'react';

export default class TimelineCanvas extends React.Component<{ timelineWidth: number, timelineHeight?: number, className?: string }> {
    public render() {
        return <div className={`timeline-canvas ${this.props.className || ''}`} style={{ width: this.props.timelineWidth, height: this.props.timelineHeight }}>
            {this.props.children}
        </div>;
    }
}

export interface IStretch {
    scale: IScale;
    timelineWidth: number;
}