import * as React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import {
    Icon, IconButton, TextField, DetailsListLayoutMode, CheckboxVisibility, IColumn, Selection, IObjectWithKey, Overlay, MessageBar, MessageBarType,
    IDetailsHeaderProps, IRenderFunction, Sticky, StickyPositionType, ITooltipHostProps, TooltipHost, IContextualMenuItem, CommandBar
} from 'office-ui-fabric-react';
import * as Tenant from '../../store/Tenant';
import { UserState } from "../../store/User";
import { contains, CommonOperations } from "../../store/permissions";
import DetailsListWrapper from "../common/DetailsListWrapper";
import { IOrderBy } from "../../store/views";
import { SortService } from "../../services/SortService";
import { actionCreators, IODataTokenInfo, INewODataTokenInfoState } from "../../store/OdataTokensListStore";
import { ItemCreation } from "../common/ItemCreation";
import { Validator } from "../../validation";
import Spinner from "../common/Spinner";
import RemoveDialog from "../common/RemoveDialog";
import { copyToClipboard } from "../utils/common";
import { nameof } from "../../store/services/metadataService";
import { Dictionary, SortDirection } from "../../entities/common";

type OwnProps = {}

type StateProps = {
    tenant: Tenant.TenantState;
    user: UserState;
    tokens: IODataTokenInfo[];
    newToken: INewODataTokenInfoState;
}

interface IODataTokenColumn extends IColumn { fieldName: keyof IODataTokenInfo; }

type Props = StateProps & OwnProps & typeof actionCreators;
type State = {
    canManage: boolean,
    columns: IColumn[];
    sortBy: IOrderBy;
    tokens: IODataTokenInfo[];
    selectedCount: number;
    isCreate: boolean;
    showRemoveDialog: boolean;
    newTokenName?: string;
    copied: Dictionary<NodeJS.Timeout>;
}

const validator = Validator.new().required().build();

const COPIED_CHECKMARK_DURATION = 1000;
class OdataTokensList extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);

        const sortBy: IOrderBy = {
            fieldName: nameof<IODataTokenInfo>("title"),
            direction: SortDirection.ASC
        };

        const canManage = contains(props.user.permissions.common, CommonOperations.ODataManage);
        this.state = {
            canManage: canManage,
            columns: this._buildColumns(sortBy, canManage),
            sortBy: sortBy,
            tokens: [],
            selectedCount: 0,
            isCreate: false,
            showRemoveDialog: false,
            copied: {}
        }

        this._selection = new Selection({
            onSelectionChanged: () => this.setState({
                selectedCount: this._selection.getSelectedCount(),
            }),
            getKey: (_: IObjectWithKey) => (_ as IODataTokenInfo).id
        });
    }

    componentWillMount() {
        this.props.getOdataTokens();
    }

    componentWillUnmount() {
        const { copied } = this.state;
        Object.keys(copied).forEach(_ => clearTimeout(copied[_]));
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.tokens !== nextProps.tokens) {
            this.setState({ tokens: nextProps.tokens.map(_ => _).sort(SortService.getSimpleComparer(this.state.sortBy)) });
        }
    }

    render() {
        const { canManage, columns, selectedCount, tokens } = this.state;
        return (
            <div className="odata-management">
                <TextField
                    label="OData feed URL"
                    readOnly={true}
                    value={this.props.tenant.reporting.odataUrl}
                    onRenderSuffix={_ => this.renderCopyButton('odataUrl', this.props.tenant.reporting.odataUrl)}
                    className="input-with-copy-button odata-url" />
                {
                    this.state.isCreate && this.renderPanel()
                }
                <div className="entities-list">
                    <CommandBar className="header-command-bar" items={this._getCommands()} />
                    <DetailsListWrapper
                        layoutMode={DetailsListLayoutMode.justified}
                        items={tokens}
                        columns={columns}
                        selection={this._selection}
                        checkboxVisibility={canManage ? CheckboxVisibility.always : CheckboxVisibility.hidden}
                        onColumnHeaderClick={this._onColumnHeaderClick}
                        onRenderDetailsHeader={this._onRenderDetailsHeader} />
                </div>
                {this.state.showRemoveDialog && <RemoveDialog
                    onClose={() => this.setState({ showRemoveDialog: false })}
                    onComplete={() => {
                        this.props.revokeToken(this._getSelected().map(_ => _.id));
                        this.setState({ showRemoveDialog: false });
                    }}
                    dialogContentProps={{
                        title: `Revoke token${selectedCount > 1 ? "s" : ""}`,
                        subText: `Are you sure to revoke ${selectedCount} token${selectedCount > 1 ? "s" : ""}?`
                    }}
                    confirmButtonProps={{ text: "Revoke" }} />}
            </div>
        );
    }

    private _getCommands = (): IContextualMenuItem[] => {
        const { selectedCount, canManage } = this.state;

        return [
            {
                key: 'addRow',
                name: "Create access token",
                iconProps: { iconName: 'Add' },
                disabled: !canManage,
                onClick: () => { this.setState({ isCreate: true }); }
            },
            {
                key: "revoke",
                name: `Revoke Selected`,
                iconProps: { iconName: 'Blocked2' },
                disabled: !canManage || selectedCount === 0,
                onClick: () => { this.setState({ showRemoveDialog: true }); }
            }
        ];
    }

    private renderPanel = () => {
        return (
            <ItemCreation
                onDismiss={() => {
                    this.setState({ isCreate: false, newTokenName: undefined });
                    this.props.resetNewOdataToken();
                }}
                header={{
                    text: "Create OData Token",
                    secondaryText: "Configure OData Token to access your data from the reporting tools (Power BI, etc.)",
                    nameEditorLabel: "Token Name",
                    onChanged: ((newName: string) => {
                        this.setState({ newTokenName: newName });
                    }),
                    validator: validator,
                    value: this.state.newTokenName
                }}
                commands={[
                    {
                        primary: true,
                        text: "Generate token",
                        onClick: this.createNewToken,
                        disabled: !(validator.isValid(this.state.newTokenName)) ||
                            (!!this.props.newToken.tokenInfo && !!this.props.newToken.tokenInfo.token)
                    }
                ]}>
                {this.props.newToken.isLoading && <Overlay><Spinner /></Overlay>}
                {
                    this.isNewTokenAvailable() &&
                    <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
                        You have successfully added a new token. Copy the token now! You will not be able to see it again.
                    </MessageBar>
                }
                {
                    this.isNewTokenAvailable() &&
                    <TextField
                        readOnly={true}
                        value={this.props.newToken.tokenInfo?.token}
                        onRenderSuffix={this.props.newToken.tokenInfo && (_ => this.renderCopyButton(this.props.newToken.tokenInfo!.id, this.props.newToken.tokenInfo!.token))}
                        className="input-with-copy-button new-token-value" />}
            </ItemCreation>
        );
    }

    private _onRenderDetailsHeader(props: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    private isNewTokenAvailable = () => {
        return !this.props.newToken.isLoading &&
            this.props.newToken.tokenInfo &&
            this.props.newToken.tokenInfo.token;
    }

    private createNewToken = () => {
        if (this.state.newTokenName) {
            this.props.createOdataToken(this.state.newTokenName);
        }
    }

    private renderCopyButton = (key: string, value: string): JSX.Element | null => {
        const { copied } = this.state;

        return (
            <IconButton
                iconProps={{ iconName: copied[key] ? "CheckMark" : "Copy" }}
                title="Copy to clipboard"
                onClick={() => copyToClipboard(value, () => {
                    const timer = setTimeout(() => {
                        delete copied[key];
                        this.setState({ copied: copied });
                    }, COPIED_CHECKMARK_DURATION);

                    if (copied[key]) {
                        clearTimeout(copied[key]);
                    }

                    this.setState({ copied: { ...copied, [key]: timer } });
                })}
            />
        );
    }

    private _buildColumns = (sortBy: IOrderBy, canManage: boolean): IColumn[] => {
        return [
            {
                key: "title",
                fieldName: "title",
                name: "Title",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true
            }, {
                key: "isEnabled",
                fieldName: "isEnabled",
                name: "Enabled",
                minWidth: 100,
                maxWidth: 500,
                isResizable: true,
                onRender: (item: any, index?: number, column?: IColumn, defaultRender?: () => JSX.Element) => {
                    return item.isEnabled ? <Icon iconName="Accept" /> : null;
                }
            }
        ].map(_ => ({
            ..._,
            isSorted: _.fieldName === sortBy.fieldName,
            isSortedDescending: _.fieldName === sortBy.fieldName && sortBy.direction === SortDirection.DESC
        }));
    }

    private _onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: IODataTokenColumn) => {
        if (!column) {
            return;
        }
        
        const sortBy = SortService.getColumnOrderBy(column, this.state.sortBy);
        this.setState({
            sortBy: sortBy,
            tokens: this.state.tokens.map(_ => _).sort(SortService.getSimpleComparer(sortBy)),
            columns: this._buildColumns(sortBy, this.state.canManage)
        });
    }

    private _getSelected() {
        return this._selection.getSelection().map(_ => _ as IODataTokenInfo);
    }
}

function mapStateToProps(state: ApplicationState, ownProp: OwnProps) {
    return {
        tokens: state.odataTokens.tokens,
        newToken: state.odataTokens.newToken,
        tenant: state.tenant,
        user: state.user
    };
}

export default connect(mapStateToProps, actionCreators)(OdataTokensList);