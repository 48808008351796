import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IEntityCardProps } from '../../common/interfaces/IEntity';
import { FormatDate } from "../../utils/common";
import { Dictionary, IUserInfo } from "../../../entities/common";
import * as Metadata from "../../../entities/Metadata";
import CardMetric, { CardMetricConfig } from "./CardMetric";
import ResourceFormatter from '../../common/formatters/ResourceFormatter';
import { ApplicationState } from '../../../store';
import { UserState } from '../../../store/User';
import { IRoadmapAttrs, Roadmap, RoadmapStage, roadmapStageMap } from '../../../store/roadmap/common';
import Logo from '../../common/Logo';
import { ActiveFilter } from '../../../store/roadmap/filters';

export type RoadmapCardState = {};
type OwnProps = {
    onMenuRender: (entity: Roadmap) => JSX.Element | null;
    fields: Dictionary<Metadata.Field>;
} & IEntityCardProps<Roadmap, RoadmapCardState>;

type StateProps = { user: UserState };

type PortfolioCardProps = OwnProps & StateProps & RouteComponentProps<{}>;

class RoadmapCard extends React.Component<PortfolioCardProps> {
    constructor(props: PortfolioCardProps) {
        super(props);
    }

    public render() {
        const { entity } = this.props;
        const attrs = entity.attributes;
        return (
            <div className="card flex-card roadmap-card" onClick={this._onClick}>
                <div className={"content" + (attrs.Stage === RoadmapStage.Draft ? " draft" : "")}>
                    <div className="first-row">
                        <div style={{ "float": "left" }}>
                            <Logo className="roadmap-logo"></Logo>
                        </div>
                        <div className="info">
                            <div className="title overflow-text" title={attrs.Name}>{attrs.Name}</div>
                        </div>
                    </div>
                    <div className="metrics">
                        {
                            this.renderMetrics(attrs)
                        }
                    </div>
                    <div className="separator"></div>
                    <div className="row big">
                        <div className="title-container">
                            <Icon iconName="PPMXRoadmap" className="card-icon" />
                            <span className="title">Details</span>
                        </div>
                        <div className="align-center">
                            <span className="description">{attrs.Description ? attrs.Description : "-"}</span>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="row">
                        <div className="align-center">
                            <Icon iconName="PPMXCardManager" className="card-icon" />
                            <span className="title">Manager</span>
                        </div>
                        {
                            attrs.Manager && attrs.Manager.length > 0
                                ? <div className="align-center">
                                    <ResourceFormatter resource={attrs.Manager} onClick={this.goToRoadmaps} />
                                </div>
                                : <div className="align-center">
                                    <span>(Manager is not assigned yet)</span>
                                </div>
                        }
                    </div>
                    <div className="separator"></div>
                    <div className="actions">
                        {this.props.onMenuRender(entity)}
                    </div>
                </div>
            </div>
        );
    }

    private goToRoadmaps = (e: any, resource: IUserInfo) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.history.push(`/roadmaps`, new ActiveFilter("Custom").withManager(resource).build());
    }

    private renderMetrics(attrs: IRoadmapAttrs): JSX.Element[] {
        const stageConfig = roadmapStageMap[attrs.Stage];
        const metrics: { config: CardMetricConfig, key: string }[] = [
            {
                config: {
                    title: "Start Date",
                    value: FormatDate(attrs.StartDate)
                },
                key: "start-date"
            },
            {
                config: {
                    title: "Finish Date",
                    value: FormatDate(attrs.FinishDate)
                },
                key: "finish-date"
            },
            {
                config: {
                    title: "Stage",
                    value: (stageConfig && stageConfig.title) || attrs.Stage,
                    valueClassName: `colorfull ${(stageConfig && stageConfig.cssClassName) ? stageConfig.cssClassName : ''}`
                },
                key: "stage"
            }
        ];

        return metrics.map(_ => <CardMetric config={_.config} key={_.key} />);
    }

    private _onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const { entity } = this.props;

        if (e.ctrlKey) {
            window.open(`/roadmap/${entity.id}`, '_blank')
        } else {
            this.props.history.push(`/roadmap/${entity.id}`)
        }
    }
}

function mapStateToProps(state: ApplicationState): StateProps {
    return { user: state.user };
}

export default withRouter<OwnProps>(connect(mapStateToProps)(RoadmapCard));