import * as React from 'react';
import { connect } from 'react-redux';
import { PrimaryButton, Dialog, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { UserState, actionCreators } from "../../store/User";
import { ApplicationState } from "../../store/index";
import { RouteComponentProps, withRouter } from "react-router-dom";

type OwnProps = {};
type StateProps = { user: UserState };
type UserSignUpProps = StateProps & typeof actionCreators & OwnProps & RouteComponentProps<{}>;

class UserSignUpDialog extends React.Component<UserSignUpProps> {
    public render() {
        const { user } = this.props;
        return !user.isSignedUp && <Dialog
            hidden={false}
            modalProps={{ isBlocking: true, className: "sign-up" }}
            dialogContentProps={{ showCloseButton: false }}>
            <h1>Welcome to PPM Express! <span className="icon waving-hand"></span><span className="icon party-popper"></span></h1>
            <p tabIndex={1} style={{ outlineColor: "white" }}>
                By signing up, you agree to our <a href="https://ppm.express/terms-of-use" target="_blank">Terms of Use</a>
                <br /> and <a href="https://ppm.express/privacy-policy" target="_blank">Privacy Policy</a>
            </p>
            <div className="dialog-actions">
                <PrimaryButton disabled={user.isInProcessing} onClick={() => { this.props.signUp(this.props.location.state) }} text='Sign up'>
                    {user.isInProcessing && <Spinner size={SpinnerSize.medium} />}
                </PrimaryButton>
            </div>
        </Dialog>;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return { user: state.user };
}

export default withRouter<OwnProps>(connect(mapStateToProps, actionCreators)(UserSignUpDialog));