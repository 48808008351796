import axios, { AxiosResponse } from "axios";
import { Dictionary } from "../entities/common";
import { ActionTypes, UpdateErrorInfoAction, ErrorState } from "./ErrorStore";
import { NotEnoughPermissionsMessage } from "./permissions";

type ErrorResponse = null | string | {
    errorMessage: string | null;
    errors: { fieldName: string, message: string }[] | null;
};

const HTTP_STATUS_CODE = {
    UNAUTHORIZED: 401,
    BADREQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    UNHANDLED_SERVER_ERROR: 500
};

export function defaultCatch(dispatch?: any): (error: any) => void {
    return error => {
        if (isCancel(error)) {
            return;
        }

        if (error.response) {
            const response = error.response as AxiosResponse<ErrorResponse>;

            if (handleUnauthorized(response.status)) {
                return;
            }

            response.data !== undefined && console.error(response.data);

            if (dispatch) {
                const action: UpdateErrorInfoAction = {
                    type: ActionTypes.UPDATE_ERROR_INFO,
                    error: getErrorInfo(response.status, response.data)
                }

                dispatch(action);
            }
            return;
        }

        console.error(error);
    };
}

export function getErrorInfo(statusCode: number, errorResponse?: ErrorResponse): ErrorState {
    switch (statusCode) {
        case HTTP_STATUS_CODE.BADREQUEST: return {
            title: "Bad Request",
            description: buildErrorDescription(errorResponse) ?? "Oops! Something went wrong."
        }
        case HTTP_STATUS_CODE.FORBIDDEN: return {
            title: "Access Denied",
            description: buildErrorDescription(errorResponse) ?? NotEnoughPermissionsMessage
        }
        case HTTP_STATUS_CODE.NOT_FOUND: return {
            title: "Not Found",
            description: "PPM Express couldn't find the item requested. It may have been deleted or moved."
        }
    }

    return {
        title: statusCode.toString(),
        description: "Oops! Something went wrong."
    }
}

function buildErrorDescription(errorResponse: ErrorResponse | undefined): string | null | undefined {
    if (!errorResponse) {
        return null;
    }

    if (typeof errorResponse === 'string') {
        return errorResponse;
    }

    if (errorResponse.errorMessage) {
        return errorResponse.errorMessage;
    }

    if (errorResponse.errors?.length) {
        return errorResponse.errors
            .map(_ => `${_.fieldName}: ${_.message}`)
            .join('; ');
    }
}

export function catchApiError(callback: (error: string) => void): (reason: any) => void {
    return reason => {
        if (isCancel(reason)) {
            return;
        }

        if (reason.response) {
            const response = reason.response as AxiosResponse<ErrorResponse>;

            if (handleUnauthorized(response.status)) {
                return;
            }

            if (response.status === HTTP_STATUS_CODE.UNHANDLED_SERVER_ERROR
                || response.status === HTTP_STATUS_CODE.BADREQUEST
                || response.status === HTTP_STATUS_CODE.FORBIDDEN) {
                if (typeof response.data === "string" || !response.data) {
                    callback("Unexpected error has occurred.");
                } else {
                    callback(response.data.errorMessage || "Something went wrong.");
                }
            }
            return;
        }

        console.error(reason);
    }
}

function handleUnauthorized(responseStatus: number) {
    if (responseStatus === HTTP_STATUS_CODE.UNAUTHORIZED) {
        window.location.reload();
        return true;
    }

    return false;
}

export function isCancel(error: any) {
    return axios.isCancel(error);
}

export function debounce<T extends (...args: any[]) => any, R = T extends (...args: infer U) => infer Y ? (key: string, ...args: U) => Y : never>(func: T, wait: number = 2000): R {
    let timeouts: Dictionary<any> = {};
    return ((key: string, ...args: any[]) => {
        timeouts[key] && clearTimeout(timeouts[key]);
        let timeout = setTimeout(() => {
            delete timeouts[key];
            func(...args);
        }, wait);

        timeouts[key] = timeout;
    }) as any as R;
}

const isObject = (obj: any) => toString.call(obj) === '[object Object]';

export function mergeDeep<T extends {}, U extends {}>(target: T, source: U): T & U {
    const newTarget = { ...target };
    Object.keys(source).forEach(key => {
        const targetValue = target[key];
        const sourceValue = source[key];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
            newTarget[key] = sourceValue;
        } else if (isObject(targetValue) && isObject(sourceValue)) {
            newTarget[key] = mergeDeep(targetValue, sourceValue);
        } else {
            newTarget[key] = sourceValue;
        }
    });

    return newTarget as T & U;
}

export const mergeDefault = <T extends {}>(defaults: Partial<T>, target: T) => mergeDeep(defaults, target); 