import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ExpandablePanel from "../../common/ExpandablePanel";
import {
    CheckboxVisibility,
    IColumn,
    IDetailsHeaderProps,
    IDetailsRowProps,
    IRenderFunction,
    ITooltipHostProps,
    Overlay,
    PanelType,
    ScrollablePane,
    ScrollbarVisibility,
    SearchBox,
    Spinner,
    Sticky,
    StickyPositionType,
    TooltipHost,
    Spinner as FabricSpinner,
    Icon,
    SpinnerSize,
    ActionButton,
    IGroup,
    IDetailsGroupDividerProps,
    IconButton,
    DefaultButton,
    IDropdownOption,
} from "office-ui-fabric-react";
import { EXTRA_LARGE_PANEL_WIDTH, ITimeframe } from "../../../entities/common";
import { ResultsNotFoundPlaceholder } from "../../common/sectionsControl/SectionPlaceholder";
import DetailsListWrapper from "../../common/DetailsListWrapper";
import EntityName from "../../views/list/columns/EntityName";
import { cancellablePost, CancellablePromiseWrapper } from "../../../fetch-interceptor";
import { DebouncedAction, formatValue, groupBy, IsEmptyObject, toDateTime } from "../../utils/common";
import * as Metadata from "../../../entities/Metadata";
import { EditableCell, EditorProps, FormatterProps } from "../../import/common/EditableCell";
import TextInput from "../../common/inputs/TextInput";

import "./TimeTrackingO365Connector.css";
import { FixMinAndIncrementDuration } from "../Fields";
import { TimeTrackingEntryProject } from "../../../store/TimeTrackingStore";
import DropdownInput, { TextOption } from "../../common/inputs/DropdownInput";
import { FilterAttribute } from "../../common/FilterAttributes/FilterAttribute";
import { TimeTrackingGlobalSettings } from "../../../store/Tenant";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { catchApiError } from "../../../store/utils";
import {
    GetSuggestionTypeByLabel,
    GetSuggestionTypeLabel,
    Office365TimeTrackingConnectorReaders
} from "../../../store/integration/PersonalOffice365Store";
import EntityPickerInput, { IFindResult } from "../../common/inputs/EntityPickerInput";
import Link from "../../common/Link";
import { formatDate } from "../../common/timeline/utils";

type OwnProps = {
    projects: TimeTrackingEntryProject[];
    project?: TimeTrackingEntryProject;

    onDismiss: () => void;
    onImport: (entity: Suggestion, callback: () => void) => void;
    isEntityImported: (entity: Suggestion) => ImportedSuggestionProps | undefined;

    timeFrame: ITimeframe;
};

type StateProps = {
    timeTrackingSettings: TimeTrackingGlobalSettings;
};

type Props = OwnProps & StateProps;

enum EntityStatus {
    Added,
    Add,
    Processing,
}

type Suggestion = {
    id: string;
    name: string;
    date: Date;
    duration: number;
    project: TimeTrackingEntryProject | undefined;
    reader: Office365TimeTrackingConnectorReaders;
};

type ImportedSuggestionProps = {
    project: TimeTrackingEntryProject;
    duration: number;
};

type Filter = {
    searchText?: string;
    date?: {
        from: string;
        to: string;
    };

    readers?: string[];
    hideAdded?: boolean;
};

function TimeTrackingImportFromM365(props: Props) {
    const { onDismiss, isEntityImported, timeFrame, projects, onImport, timeTrackingSettings, project } = props;

    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

    const [processingSuggestions, setProcessingSuggestions] = useState<string[]>([]);
    const processingSuggestionsRef = useRef<string[]>([]);
    //to track the latest changes in callbacks
    processingSuggestionsRef.current = processingSuggestions;

    const [filter, setFilter] = useState<Filter>({});

    const { isLoading, loadingError, loadSuggestions } = useLoadSuggestions(timeFrame, timeTrackingSettings, project, setSuggestions, isEntityImported);

    useEffect(() => {
        loadSuggestions(filter);
    }, []);

    const debouncedFiltering = useMemo(
        () =>
            new DebouncedAction((filters: Filter[]) => {
                if (filters?.length) {
                    const lastFitler = filters[filters.length - 1];
                    loadSuggestions(lastFitler);
                }
            }).callAction,
        [loadSuggestions]
    );

    const onFilterChange = (changes: Partial<Filter>) => {
        const newFilter = { ...filter, ...changes };
        setFilter(newFilter);

        if (changes.hideAdded === undefined) {
            debouncedFiltering(newFilter);
        }
    };

    const clearFilter = () => {
        setFilter({});
        debouncedFiltering({});
    };

    const getItemStatus = (suggestion: Suggestion) => {
        if (processingSuggestions?.includes(suggestion.id)) {
            return EntityStatus.Processing;
        }

        if (isEntityImported(suggestion)) {
            return EntityStatus.Added;
        }

        return EntityStatus.Add;
    };

    const getColumns = useCallback(
        () =>
            _getColumns(
                projects,
                timeTrackingSettings,
                suggestion => {
                    setProcessingSuggestions([...processingSuggestionsRef.current, suggestion.id]);
                    onImport(suggestion, () => {
                        setProcessingSuggestions(processingSuggestionsRef.current.filter(_ => _ !== suggestion.id));
                    });
                },
                suggestion => {
                    const newSuggestions = suggestions.map(_ => {
                        if (_.id === suggestion.id) {
                            return { ...suggestion };
                        }

                        return _;
                    });

                    setSuggestions(newSuggestions);
                },
                getItemStatus
            ),
        [projects, suggestions, processingSuggestions, isEntityImported]
    );

    const visibleSuggestions = suggestions.filter(_ => !filter.hideAdded || getItemStatus(_) !== EntityStatus.Added);
    const groups = useDateGroups(visibleSuggestions);

    return (
        <ExpandablePanel
            className={`search-item-import-panel import-o365-tt-suggestions ${suggestions?.length ? "has-items" : ""}`}
            isLightDismiss
            type={PanelType.custom}
            customWidth={EXTRA_LARGE_PANEL_WIDTH}
            isOpen
            onDismiss={onDismiss}
            onRenderHeader={() => (
                <div className="ms-Panel-header">
                    <div className="ms-Panel-headerText">Import from Microsoft 365</div>
                    <div className="ms-Panel-secondaryText">
                        Import Microsoft 365 Activities as PPM Express Time Entries.
                        Please refer to <Link target="_blank" href="https://help.ppm.express/time-tracking/how-to-import-microsoft-365-activities-as-ppm-express-time-entries">the article</Link> for more details.
                    </div>
                </div>
            )}>
            <SearchBox
                placeholder="Search activity by Name"
                value={filter.searchText || ""}
                onChange={(e: any, v: string) => onFilterChange({ searchText: v })}
            />

            <div className="align-center">
                <Filters timeFrame={timeFrame} filter={filter} onFilterChange={onFilterChange}/>
                {!IsEmptyObject(filter) && (
                    <DefaultButton
                        className="filter-button"
                        iconProps={{ iconName: "ClearFilter" }}
                        text="Clear Filter"
                        onClick={clearFilter}
                    />
                )}
            </div>
            <div data-is-scrollable={true} className="list-container">
                {!!loadingError ? (
                    <div className="error-message">{loadingError}</div>
                ) : (
                    <>
                        {isLoading === false && visibleSuggestions.length === 0 ? (
                            <div className="placeholder">
                                <ResultsNotFoundPlaceholder clearFilter={clearFilter} />
                            </div>
                        ) : (
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                <DetailsListWrapper
                                    checkboxVisibility={CheckboxVisibility.hidden}
                                    items={visibleSuggestions}
                                    //it starts jumping with virtualizating on small screens due to grouping
                                    onShouldVirtualize={() => false}
                                    groups={groups}
                                    groupProps={{
                                        onRenderHeader: (renderProps: IDetailsGroupDividerProps) => (
                                            <div className="group-bydate-header">
                                                <IconButton
                                                    title={!renderProps.group?.isCollapsed ? "Expand" : "Collapse"}
                                                    iconProps={{
                                                        iconName: renderProps.group?.isCollapsed
                                                            ? "ChevronUp"
                                                            : "ChevronDown",
                                                    }}
                                                    onClick={e => {
                                                        renderProps.onToggleCollapse?.(renderProps.group!);
                                                        e.preventDefault();
                                                    }}
                                                />
                                                <div className="group-name">{renderProps.group?.name}</div>
                                            </div>
                                        ),
                                    }}
                                    columns={getColumns()}
                                    onRenderRow={(
                                        detailsRowProps: IDetailsRowProps,
                                        defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null
                                    ) => {
                                        const row = defaultRender?.(detailsRowProps);
                                        const item = detailsRowProps.item as Suggestion;
                                        const status = getItemStatus(item);
                                        if (row && status === EntityStatus.Added) {
                                            return React.cloneElement(row, { className: "item-added" });
                                        }
                                        return row || null;
                                    }}
                                    disableSelectionZone
                                    onRenderDetailsHeader={_onRenderDetailsHeader}
                                    className="not-clickable"
                                />
                            </ScrollablePane>
                        )}
                    </>
                )}
                {isLoading && (
                    <Overlay>
                        <Spinner />
                    </Overlay>
                )}
            </div>
        </ExpandablePanel>
    );
}

const useDateGroups = (suggestions: Suggestion[]) => {
    return useMemo(() => {
        const byDate = groupBy(suggestions, _ => formatDate("M dd, D", _.date!));

        const groupsByDay: IGroup[] = [];
        for (const [date, suggestionsByDate] of Object.entries<Suggestion[]>(byDate)) {
            groupsByDay.push({
                key: date,
                name: date,
                count: suggestionsByDate.length,
                startIndex: suggestions.indexOf(suggestionsByDate[0]),
            });
        }

        return groupsByDay;
    }, [suggestions.map(_ => _.id).join()]);
};

const useLoadSuggestions = (
    timeFrame: ITimeframe,
    timeTrackingSettings: TimeTrackingGlobalSettings,
    project: TimeTrackingEntryProject | undefined,
    onLoaded: (suggestion: Suggestion[]) => void,
    isEntityImported: (entity: Suggestion) => ImportedSuggestionProps | undefined
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState<string>();

    const loadSuggestions = useCallback(
        (filter: Filter) => {
            setIsLoading(true);
            setLoadingError("");

            _loadSuggestions(timeFrame.start, timeFrame.end, filter)
                .then(data => {
                    data.forEach(_ => {
                        const isImported = isEntityImported(_);
                        if (isImported) {
                            _.duration = isImported.duration;
                            _.project = isImported.project;
                        }
                        else {
                            _.duration = _.duration && FixMinAndIncrementDuration(_.duration, timeTrackingSettings.minReportingDurationMinutes, timeTrackingSettings.reportingIncrementMinutes);
                            _.project = project;
                        }
                    });

                    onLoaded(data);
                    setIsLoading(false);
                })
                .catch(_ => {
                    catchApiError(apiError => setLoadingError(apiError))(_);
                    setIsLoading(false);
                    onLoaded([]);
                });
        },
        [isEntityImported]
    );

    return { isLoading, loadingError, loadSuggestions };
};

const Filters = (props: { timeFrame: ITimeframe, filter: Filter, onFilterChange: (change: Partial<Filter>) => void }): JSX.Element => {
    const { timeFrame, filter, onFilterChange } = props;
    return (
        <div className="filter-attributes-container align-center">
            <FilterAttribute
                key="date"
                field={{
                    ..._fakeDateField,
                    settings: {
                        ..._fakeDateField.settings,
                        minValue: timeFrame.start,
                        maxValue: timeFrame.end
                    }
                }}
                value={filter.date}
                onEditComplete={newValue => {
                    onFilterChange({ date: newValue as any });
                }}
            />

            <FilterAttribute
                key="type"
                field={_fakeTypeField}
                value={filter.readers}
                onEditComplete={(newValue: string[]) => {
                    onFilterChange({ readers: newValue });
                }}
            />

            <FilterAttribute
                key="hideAdded"
                field={_fakeHideAddedField}
                value={filter.hideAdded}
                onEditComplete={(newValue: boolean) => {
                    if(newValue !== !!filter.hideAdded){
                        onFilterChange({ hideAdded: newValue });
                    }
                }}
            />
        </div>
    );
};

const _fakeDateField: Metadata.Field = {
    id: "dateField",
    type: Metadata.FieldType.Date,
    name: "Date",
    label: "Date",
    group: Metadata.FieldGroup.SystemFields,
    isNative: false,
    isReadonly: false,
    isSystem: false,
    isCustom: false,
};

const _fakeTypeField: Metadata.Field = {
    id: "typeField",
    type: Metadata.FieldType.Text,
    name: "Type",
    label: "Type",
    group: Metadata.FieldGroup.SystemFields,
    isNative: false,
    isReadonly: false,
    isSystem: false,
    isCustom: false,
    settings: {
        editControl: "Dropdown",
        options: [
            Office365TimeTrackingConnectorReaders.CompletedOutlookCalendarEmails,
            Office365TimeTrackingConnectorReaders.OutlookTasks,
            Office365TimeTrackingConnectorReaders.SentOutlookEmails,
            Office365TimeTrackingConnectorReaders.OutlookCalendarEvents,
            Office365TimeTrackingConnectorReaders.TentativeCalendarEvents,
            Office365TimeTrackingConnectorReaders.RecentDocuments
        ].map(reader => {
            return { name: GetSuggestionTypeLabel(reader) };
        })
    },
};

const _fakeHideAddedField: Metadata.Field = {
    id: "hideAddedField",
    name: "hideAddedField",
    label: "Hide Added",
    isNative: false,
    isReadonly: false,
    isCustom: false,
    isSystem: false,
    type: Metadata.FieldType.Flag,
    group: Metadata.FieldGroup.SystemFields,
}

let _loadSuggestionInProgress: CancellablePromiseWrapper<Suggestion[]>;

const _loadSuggestions = (start: Date, end: Date, filter: Filter): Promise<Suggestion[]> => {
    return new Promise<Suggestion[]>((resolve, reject) => {
        const postFilter: any = {
            start: start.getBeginOfDay().toISOString(),
            end: end.getEndOfDay().toISOString(),
            searchText: filter.searchText,
        };

        if (filter.date?.from) {
            start = toDateTime(filter.date.from)!.getBeginOfDay();
            postFilter.start = start.toISOString();

            if (filter.date.to) {
                postFilter.end = toDateTime(filter.date.to)!.getEndOfDay().toISOString();
            }
            else {
                postFilter.end = start.getEndOfDay().toISOString()
            }
        }
        else if (filter.date?.to) {
            postFilter.start = toDateTime(filter.date.to)!.getBeginOfDay().toISOString();
            postFilter.end = toDateTime(filter.date.to)!.getEndOfDay().toISOString();
        }

        if (filter.readers?.length) {
            postFilter.readers = filter.readers.reduce(
                (prev, current) => prev | GetSuggestionTypeByLabel(current),
                Office365TimeTrackingConnectorReaders.None
            );
        }

        _loadSuggestionInProgress?.cancelTokenSource.cancel();

        _loadSuggestionInProgress = cancellablePost<Suggestion[]>(
            `api/timetracking/integration/office365/suggestions`,
            postFilter
        );

        _loadSuggestionInProgress.promise
            .then(data => {
                data.forEach(_ => {
                    _.date = new Date(_.date);
                });

                const byDay = groupBy(data, _ => `${_.date.toDateOnlyString()}_${_.name}_${_.reader}`);
                const suggestions: Suggestion[] = [];

                for (const [_, suggestionsByDate] of Object.entries<Suggestion[]>(byDay)) {
                    suggestions.push(suggestionsByDate[0]);
                }

                resolve(suggestions);
            })
            .catch(error => error.message !== "canceled" && reject(error));
    });
};

const _onRenderDetailsHeader = (
    props: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
): JSX.Element => {
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />,
            })}
        </Sticky>
    );
};

const _getColumns = (
    projects: TimeTrackingEntryProject[],
    timeTrackingSettings: TimeTrackingGlobalSettings,
    onImport: (suggestion: Suggestion) => void,
    onSuggestionEdit: (suggestion: Suggestion) => void,
    getSuggestionStatus: (suggestion: Suggestion) => EntityStatus
): IColumn[] => {
    const timeWidth = 60;
    const statusWidth = 60;
    const nameWidth = 365;

    const result: IColumn[] = [
        {
            minWidth: nameWidth,
            maxWidth: nameWidth,
            key: "name",
            name: "Name",
            fieldName: "entityName",
            onRender: (entity: Suggestion) => (
                <EntityName
                    name={entity.name}
                    isTimelineView={true}
                    icon={
                        <div className={`logo ${Office365TimeTrackingConnectorReaders[entity.reader].toString()}`}>
                            <Icon iconName={_getIcon(entity.reader)} title={GetSuggestionTypeLabel(entity.reader)} />
                        </div>
                    }
                />
            ),
        },
        {
            minWidth: timeWidth,
            maxWidth: timeWidth,
            key: "time",
            name: "Time",
            fieldName: "time",
            onRender: (entity: Suggestion) => (
                <EditableCell
                    item={entity}
                    value={entity.duration}
                    allowOutsideClick
                    readonly={getSuggestionStatus(entity) === EntityStatus.Added}
                    formatter={_durationFormatter}
                    editor={_durationEditorBuilder(timeTrackingSettings)}
                    onEditComplete={(value: number) => {
                        onSuggestionEdit({ ...entity, duration: value });
                    }}
                />
            ),
        },
        {
            minWidth: nameWidth,
            maxWidth: nameWidth,
            key: "project",
            name: "Project",
            fieldName: "project",
            onRender: (entity: Suggestion) => (
                <ProjectEditableCell
                    entity={entity}
                    isAdded={getSuggestionStatus(entity) === EntityStatus.Added}
                    projects={projects}
                    onSuggestionEdit={onSuggestionEdit}
                />
            ),
        },
        {
            minWidth: statusWidth,
            maxWidth: statusWidth,
            key: "status",
            name: "Status",
            fieldName: "status",
            onRender: (item: Suggestion, index?: number, column?: IColumn) => {
                const status = getSuggestionStatus(item);
                if (status === EntityStatus.Add) {
                    const isValid = !!item.duration && !!item.project;
    
                    return (
                        <ActionButton
                            iconProps={{ iconName: "Add" }}
                            title={"Add"}
                            disabled={!isValid}
                            onClick={() => onImport(item)}>
                            Add
                        </ActionButton>
                    );
                }
                if (status === EntityStatus.Added) {
                    return (
                        <div className="align-center status-cell">
                            <Icon className="main-color font-14" iconName="LocationDot" />
                            <div className="font-14 label">Added</div>
                        </div>
                    );
                }
                if (status === EntityStatus.Processing) {
                    return (
                        <div className="align-center status-cell spinner-status">
                            <FabricSpinner size={SpinnerSize.small} title="Processing" />
                        </div>
                    );
                }
                return null;
            },
        }
    ];

    return result;
};

const _getIcon = (reader: Office365TimeTrackingConnectorReaders): string => {
    switch (reader) {
        case Office365TimeTrackingConnectorReaders.RecentDocuments:
            return "PPMXO365Document";
        case Office365TimeTrackingConnectorReaders.OutlookCalendarEvents:
            return "PPMXO365OutlookCalendarEvent";
        case Office365TimeTrackingConnectorReaders.TentativeCalendarEvents:
            return "PPMXO365TentativeCalendarEvent";
        case Office365TimeTrackingConnectorReaders.SentOutlookEmails:
            return "PPMXO365SentOutlookEmail";
        case Office365TimeTrackingConnectorReaders.CompletedOutlookCalendarEmails:
            return "PPMXO365CompletedOutlookEmail";
        case Office365TimeTrackingConnectorReaders.OutlookTasks:
            return "PPMXO365ToDoTask";

        default:
            throw new Error(`Not supported reader ${reader}`);
    }
};

const _durationFormatter = (props: FormatterProps<Suggestion, number>): JSX.Element => {
    return <div className="formatted-editable-value">{formatValue(props.value, Metadata.FormatType.Duration)}</div>;
};

const _durationEditorBuilder =
    (timeTrackingSettings: TimeTrackingGlobalSettings) => (props: EditorProps<Suggestion, number>) => {
        return (
            <TextInput
                value={props.value ? props.value.toString() : ""}
                inputRef={props.inputRef}
                selectOnFocus
                format={Metadata.FormatType.Duration}
                onEditComplete={value => {
                    let duration = Number(value);

                    if (duration) {
                        duration = FixMinAndIncrementDuration(
                            duration,
                            timeTrackingSettings.minReportingDurationMinutes,
                            timeTrackingSettings.reportingIncrementMinutes
                        );
                    }

                    props.onEditComplete?.(duration || 0);
                }}
            />
        );
    };

type ProjectEditableCellProps = {
    entity: Suggestion;
    projects: TimeTrackingEntryProject[];
    onSuggestionEdit: (suggestion: Suggestion) => void;
    isAdded: boolean;
};

const ProjectEditableCell = (props: ProjectEditableCellProps) => {
    const { entity, onSuggestionEdit, projects, isAdded } = props;
    const editableCellRef = useRef<EditableCell<unknown, unknown>>();
    const [isPickerShow, setIsPickerShow] = useState(false);

    const projectsList = useMemo(() => {
        return entity.project?.id && !projects.some(_ => _.id === entity.project?.id)
            ? [...projects, entity.project]
            : projects;
    }, [projects, entity.project]);

    

    return (
        <EditableCell
            item={entity}
            value={entity.project}
            readonly={isAdded}
            ref={_ => (editableCellRef.current = _ as any)}
            formatter={(formatterProps: EditorProps<Suggestion, TimeTrackingEntryProject | null | undefined>) => (
                <div className="formatted-editable-value" title={formatterProps.value?.name}>
                    {formatterProps.value?.name || "Select Project"}
                </div>
            )}
            allowOutsideClick={isPickerShow}
            editor={(editorProps: EditorProps<Suggestion, TimeTrackingEntryProject | null | undefined>) => {
                const typeToSearchProjectId = "typeToSearchProjectId";

                const onEditComplete = (project: TimeTrackingEntryProject | undefined | null) => {
                    editorProps.onChange(project);

                    if (project) {
                        editorProps.onEditComplete(project);
                    } else {
                        //to set empty value a moment after props.onChange(project)
                        setTimeout(() => editorProps.onEditComplete());
                    }
                };

                if (isPickerShow) {
                    return (
                        <EntityPickerInput
                            searchUrl={`api/project/find`}
                            inputRef={editorProps.inputRef}
                            inputProps={{ placeholder: "Type to search" }}
                            onBlur={() => {
                                editorProps.onEditComplete();
                                setIsPickerShow(false);
                            }}
                            onEditComplete={(_: IFindResult[] | null) => {
                                const project = _ && _[0];

                                onEditComplete(project);
                                setIsPickerShow(false);
                            }}
                        />
                    );
                }

                return (
                    <DropdownInput
                        value={editorProps.value?.id || ""}                        
                        onEditComplete={(projectId: string) => {
                            if (projectId === typeToSearchProjectId) {
                                setIsPickerShow(true);

                                //re-focus when entity picker is shown
                                setTimeout(() => editableCellRef.current?.focus());
                            } else {
                                onEditComplete(projectsList.find(_ => _.id === projectId));
                            }
                        }}
                        inputRef={editorProps.inputRef}                        
                        inputProps={{
                            calloutProps: { preventDismissOnResize: true, preventDismissOnLostFocus: true },
                            options: [
                                {
                                    key: "",
                                    text: "",
                                },
                                ...projectsList.map(_ => ({
                                    key: _.id,
                                    text: _.name,
                                })),
                                {
                                    key: typeToSearchProjectId,
                                    text: "Click here to search globally",
                                },
                            ],
                            onRenderOption: (item: IDropdownOption) => {
                                return item?.key === typeToSearchProjectId
                                    ? (<span className="import-o365-tt-suggestions-global-search">{item.text}</span>)
                                    : TextOption(item);
                            }
                        }}
                    />
                );
            }}
            onEditComplete={(value: TimeTrackingEntryProject) => {
                onSuggestionEdit({ ...entity, project: value });
            }}
        />
    );
};

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        timeTrackingSettings: state.tenant.timeTracking.globalSettings,
    };
}

export default connect(mapStateToProps)(TimeTrackingImportFromM365);