import * as React from 'react';
import { SourceType, SourceTypeMap } from '../../store/ExternalEpmConnectStore';
import { ISourceInfo, Dictionary } from '../../entities/common';
import { Integrations, ProductType } from '../../store/Tenant';
import { Link, MessageBar, MessageBarType, PrimaryButton } from 'office-ui-fabric-react';
import NotAllowedMessage from './NotAllowedMessage';

export type ConnectProps = {
    actions: any;
    dismissPanel: () => void;
    readonly: boolean;
    sourceInfo?: ISourceInfo;
}

export type RendersMap = {
    [key in SourceType]?: {
        render: (props: ConnectProps) => JSX.Element;
        helpUrl?: string;
        quickNavigation?: boolean;
        epmEntityName?: string;
    }
}

type Props = {
    connector: SourceType;
    integrations: Integrations;
    readonly: boolean;
    actions: Dictionary<Dictionary<any>>;
    onDismiss: () => void;
    openCalendlyPanel?: () => void;
    sourceInfos: ISourceInfo[];
    rendersMap: RendersMap;
    entityName?: string;
}

const EnterpriseLink = <Link href="/billing" target="_blank">{ProductType.getTitle(ProductType.Enterprise)} Plan</Link>;
export class TabConnectControl extends React.Component<Props> {

    public render() {
        const { sourceInfos, connector, integrations, actions } = this.props;
        const sourceInfo = sourceInfos.find(_ => _.type === connector);

        if (integrations.isDisabled(connector) && sourceInfo !== undefined) {
            return <>
                <NotAllowedMessage connector={connector} />
                <PrimaryButton text="Delete project link"
                    className="with-top-margin"
                    disabled={sourceInfo.syncStatus.inProgress}
                    title={sourceInfo.syncStatus.inProgress ? "Sync in progress." : undefined}
                    onClick={() => {
                        actions[connector].deleteLink(sourceInfo?.connectionId);
                        this.props.onDismiss();
                    }} />
            </>;
        }

        return integrations.isEnabled(connector) || connector === SourceType.File
            ? this.renderConnectionDetails()
            : this.renderDemoInfo();
    }

    private renderDemoInfo(): JSX.Element {
        const { connector } = this.props;
        const message = connector === SourceType.MPPFile
            ? <>On Projects Plan you can<Link href="https://help.ppm.express/ppm-express-project-publisher/2344121" target='_blank'>import tasks from MPP file to PPM Express Tasks.</Link> 
            <Link href=" https://help.ppm.express/ppm-express-project-publisher/1635949 " target='_blank'>Publishing MPP tasks as a linked project in PPM Express</Link> is available in{EnterpriseLink}.</>
            : <>Connection with {SourceTypeMap[connector]} is available in{EnterpriseLink}.</>
        return <>
            <MessageBar messageBarType={MessageBarType.info} className='demo-info'>
                {message}
            </MessageBar>
            <div className='buttons-container'>
                <PrimaryButton onClick={this.props.openCalendlyPanel}>Request Trial</PrimaryButton>
            </div>
        </>

    }

    private renderConnectionDetails(): JSX.Element {
        const { connector, onDismiss, actions, readonly, rendersMap, entityName, sourceInfos } = this.props;
        const render = rendersMap[connector];
        const sourceInfo = sourceInfos.find(_ => _.type === connector);
        const connectorName = SourceTypeMap[connector];
        const epmEntityName = !!render?.epmEntityName ? render.epmEntityName : "project";

        return <>
            {render?.quickNavigation && !!entityName
                ? <div className="linking-navigation-configure"> <MessageBar>Connection on {entityName} level is for quick navigation to {connectorName} {epmEntityName}. No data is synchronized from {connectorName} to PPM Express {entityName}.</MessageBar></div>
                : !!render?.helpUrl && <div><p>Check out <Link href={render?.helpUrl} target="_blank">this video</Link> on how to configure connection with {connectorName}</p></div>}
            {render?.render({
                actions: actions[connector],
                dismissPanel: onDismiss,
                readonly: readonly,
                sourceInfo: sourceInfo
            })}
        </>
    }
}