import React, { useCallback, useState } from "react";
import "./ConfigureRoadmapTooltipPanel.css";
import { Panel, PanelType, Pivot, PivotItem } from "office-ui-fabric-react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { Field, FieldType } from "../../../entities/Metadata";
import { Dictionary, EntityType } from "../../../entities/common";
import { ControlSettingsUpdate } from "../../../store/services/metadataService";
import RoadmapTooltipPivotContent, { PivotKeys } from "../RoadmapTooltipPivotContent";

export type TooltipFieldSettings = {
    fieldNames: string[];
};

export type TooltipFakeField = {
    id: string;
    name: string;
    label: string;
    type: FieldType;
    settings?: Dictionary<any>;
};

export type PivotDefaultSettings = {
    defaultViewFields: string[];
    fakeFields?: TooltipFakeField[];
    excludedFields?: string[];
};

export const TOOLTIP_SETTINGS = "tooltipSettings";

const pivotNames: Record<PivotKeys, string> = {
    lane: "Lane",
    bar: "Bar",
    keydate: "Key Date",
};

type OwnProps = {
    settings?: Record<PivotKeys, TooltipFieldSettings>;
    onChanged?: (settings: ControlSettingsUpdate) => void;
    onDismiss: () => void;
};

type StateProps = {
    fields: Field[];
};

type Props = OwnProps & StateProps;

const ConfigureRoadmapTooltipPanel = (props: Props) => {
    const { fields, settings, onDismiss } = props;
    const [selectedPivot, setSelectedPivot] = useState('lane');
    const [filter, setFilter] = useState('');

    const onRenderHeader = () => (
        <div className="ms-Panel-header config-tooltip-header">
            <p className="ms-Panel-headerText">Configure tooltip</p>
        </div>
    );

    const onChanged = useCallback((pivotKey: string, fieldNames: string[]) => {
        props.onChanged?.({
            propertyPath: [TOOLTIP_SETTINGS, pivotKey],
            value: { fieldNames },
        });
    }, [props.onChanged]);

    const pivotItems = Object.keys(pivotNames).map((pivotKey) => {
        return (
            <PivotItem
                key={pivotKey}
                itemKey={pivotKey}
                headerText={pivotNames[pivotKey]}
            >
                <RoadmapTooltipPivotContent
                    pivotKey={pivotKey}
                    fields={pivotKey !== 'lane' ? fields : []}
                    settings={settings?.[pivotKey]}
                    onChanged={onChanged}
                    filter={filter}
                    onFilter={setFilter}
                />
            </PivotItem>
        );
    });

    const renderContent = () => {
        return (
            <Pivot
                selectedKey={selectedPivot}
                onLinkClick={(_) => {
                    _?.props.itemKey && setSelectedPivot(_.props.itemKey);
                }}
            >
                {pivotItems}
            </Pivot>
        );
    };

    return (
        <Panel
            className="configure-tooltip-panel"
            isLightDismiss
            isOpen
            onRenderHeader={onRenderHeader}
            onDismiss={onDismiss}
            type={PanelType.custom}
            customWidth="400px"
        >
            {renderContent()}
        </Panel>
    );
};

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const fields = state.fields[EntityType.RoadmapItem];
    return {
        fields: fields.allIds.map(_ => fields.byId[_])
    };
}

export default connect(mapStateToProps)(ConfigureRoadmapTooltipPanel);
