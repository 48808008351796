import * as React from 'react';
import { DayOfWeek, mapEnumByName } from 'office-ui-fabric-react';
import { Validator } from '../../../validation';
import { ItemCreation } from '../../common/ItemCreation';
import DatePickerInput from '../../common/inputs/DatePickerInput';
import { CalendarException, splitToDays } from '../../../store/CalendarStore';
import { notUndefined, toDate } from '../../utils/common';
import LabellableComponent from '../../common/LabellableComponent';
import { getDeleteButtonCommand } from '../../common/SubentityPanel';
import OptionsPicker, { Option } from '../../common/inputs/OptionsPicker';
import NumberInput from '../../common/inputs/NumberInput';
import { dateRangeOverlaps, getDaysBetweenDates, getDuration } from '../../common/timeline/utils';
import TextInput from '../../common/inputs/TextInput';

type OwnProps = {
    isResource?: boolean,
    exception?: CalendarException,
    onDismiss: () => void,
    onDelete?: (id: string) => void,
    onEditComplete: (_: CalendarException) => void,
    exceptions: CalendarException[],
    readOnly?: boolean
}
type State = {
    name: string;
    description: string,
    start?: Date,
    end?: Date,
    days: DayOfWeek[],
    expectedHrs: number
}
type Props = OwnProps;
const days_per_week = 7;
const _dayOptions: Option[] = mapEnumByName(DayOfWeek, (name, value) => ({ text: name!, key: value! }))!.filter(notUndefined);

const validators: { [key: string]: Validator } = {
    start: Validator.new().required().build(),
    end: Validator.new().required().build(),
    name: Validator.new().required().build(),
    expectedHrs: Validator.new().decimal().min(0).max(24).required().build()
}

export class CalendarExceptionEdit extends React.Component<Props, State> {
    private readonly _dayValidator: Validator;
    constructor(props: Props) {
        super(props);

        this._dayValidator = Validator.new().customDate(
            () => !datesOverlap(this.props.exceptions.filter(_ => _ !== props.exception), { start: this.state.start, end: this.state.end, days: this.state.days }),
            "An exception for the selected day already exists")
            .build();

        this.state = {
            name: props.exception?.name ?? "",
            description: props.exception?.description ?? "",
            start: props.exception?.start,
            end: props.exception?.end,
            days: props.exception?.days || [],
            expectedHrs: props.exception?.expectedHrs ?? 0
        };
    }

    public render() {
        const { readOnly, exception, onDelete } = this.props;
        return <ItemCreation
            onDismiss={this.props.onDismiss}
            header={{
                text: `${exception ? (readOnly ? "View" : "Edit") : "New"} Exception`,
                secondaryText: `${this.props.exception ? (readOnly ? "View" : "Edit") : "Create and define"} calendar exception ${this.props.exception
                    ? "of" : "for"} the ${this.props.isResource ? "resource" : "organization"}`,
                nameEditorLabel: "Title",
                disableNameEditor: readOnly,
                value: this.state.name,
                onChanged: _ => this.setState({ name: _ }),
                validator: validators.name
            }}
            commands={[
                readOnly ? undefined : {
                    primary: true,
                    text: `${exception ? "Save" : "Create"} Exception`,
                    onClick: this._save,
                    disabled: !this._isFormValid()
                },
                {
                    text: readOnly ? 'Close' : 'Cancel',
                    onClick: this.props.onDismiss
                },
                !readOnly && exception && onDelete
                    ? getDeleteButtonCommand(() => onDelete(exception.id!))
                    : undefined
                ].filter(notUndefined)}>
            <div className="panel-area">
                <div className="grid-item half-width odd">
                    <LabellableComponent label="Start" className="field-container">
                        <DatePickerInput
                            value={this.state.start?.toDateOnlyString()}
                            disabled={readOnly}
                            validator={validators.start}
                            onChanged={(value) => this._setDates(value, true)} />
                    </LabellableComponent>
                </div>
                <div className="grid-item half-width">
                    <LabellableComponent label="End" className="field-container">
                        <DatePickerInput
                            value={this.state.end?.toDateOnlyString()}
                            disabled={readOnly}
                            validator={validators.end}
                            onChanged={(value) => this._setDates(value, false)} />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent label="Days of Week" className="field-container"
                        description="You can specify days of the week current calendar exception is relevant to, if the exception takes longer than one week">
                        <OptionsPicker
                            disabled={readOnly}
                            className={readOnly ? 'disabled-picker' : ''}
                            readOnly={readOnly || !this.state.start || !this.state.end || getDaysBetweenDates(this.state.start, this.state.end).length <= days_per_week}
                            onChange={items => this.setState({ days: items?.map(_ => _.key as DayOfWeek) ?? [] })}
                            selectedItems={_dayOptions.filter(_ => this.state.days.includes(_.key as DayOfWeek))}
                            onResolveSuggestions={(filter: string, selected?: Option[]) => _dayOptions.filter(_ => !selected?.includes(_))} />
                        <div className="error-message">{this._dayValidator.getErrorMessage(new Date())}</div>
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent label="Expected Hours" className="field-container"
                        description={<div>Indicate the expected work hours per day during the exception period.<br />If <b>no work</b> is scheduled for the day, set the Expected Hours to 0.</div>}>
                        <NumberInput
                            value={this.state.expectedHrs}
                            disabled={readOnly}
                            validator={validators.expectedHrs}
                            onChanged={expectedHrs => this.setState({ expectedHrs })} />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent label="Description" className="field-container">
                        <TextInput
                            inputProps={{ multiline: true }}
                            disabled={readOnly}
                            value={this.state.description}
                            onChanged={_ => this.setState({ description: _! })} />
                    </LabellableComponent>
                </div>
            </div>
            {this.props.children}
        </ItemCreation>;
    }

    private _setDates = (value: string | Date | undefined, isStart: boolean) => {
        const date = toDate(value);

        let start = isStart ? date : this.state.start;
        let end = isStart ? this.state.end : date;

        if (date && isStart && (!end || (end.getTime() < date.getTime()))) {
            end = date;
        }

        if (date && !isStart && (!start || (start.getTime() > date.getTime()))) {
            start = date;
        }

        let days = this.state.days;

        if (start && end) {
            const allDaysInRange = getDaysBetweenDates(start, end).splice(0, days_per_week).map(_ => _.getDay() as DayOfWeek);
            allDaysInRange.sort();
            const isMultiweek = allDaysInRange.length >= days_per_week
                && this.state.start && this.state.end && getDuration(this.state.start, this.state.end) >= days_per_week;

            days = isMultiweek ? days : allDaysInRange;
        }

        this.setState({ start, end, days });
    }

    private _isFormValid(): boolean {
        return Validator.isValid(validators, this.state)
            && this._dayValidator.isValid(this.state.start);
    }

    private _save = () => {
        let exception: CalendarException = {
            id: this.props.exception?.id,
            name: this.state.name,
            start: this.state.start!,
            end: this.state.end!,
            days: this.state.days,
            description: this.state.description,
            expectedHrs: this.state.expectedHrs,
        };
        this.props.onEditComplete(exception);
        this.props.onDismiss();
    }
}

function datesOverlap(exceptions: CalendarException[], data: { start: Date | undefined, end: Date | undefined, days: DayOfWeek[] }): boolean {
    if (!data.start || !data.end) {
        return false;
    }

    const existingExceptionsDaysMap = splitToDays(exceptions.filter(_ => dateRangeOverlaps(_.start, _.end, data.start!, data.end!)))
        .reduce((cum, cur) => ({ ...cum, [cur.date.getTime()]: true }), {});
    const newExceptionDays = splitToDays([{
        start: data.start,
        end: data.end,
        days: data.days,
        expectedHrs: 0
    }]);
    return !!newExceptionDays.find(_ => !!existingExceptionsDaysMap[_.date.getTime()]);
}