import './FieldsList.css';
import React, { createRef, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as Metadata from '../../../../entities/Metadata';
import { DraggableList, NonDraggableList } from '../../../common/DraggableList';
import { arraysEqual, notEmpty, orderNotSelected, toDictionaryByName } from '../../../utils/common';
import FieldPanel, { IFieldActions } from '../../FieldPanel';
import ManageFieldCard from '../ManageFieldCard';
import ReactDOM from 'react-dom';
import { Dictionary, EntityType } from '../../../../entities/common';
import NotFound from '../NotFound';
import { ViewService } from '../../../../services/ViewService';

type Props = {
    fields: Metadata.Field[];
    selected: string[];
    allowManageFields?: boolean;
    entityType: EntityType;
    fieldActions?: IFieldActions;
    onChange?: (fields: Metadata.Field[]) => void;
    filter?: string;
    newFields?: string[];
    mandatoryFields?: string[];
    className?: string;
    nonDraggable?: boolean;
    emptyMessages?: {
        notFound?: React.ReactNode;
        noFields?: React.ReactNode;
    }
    showShadowOnNonDraggableHover?: boolean;
}

export const FieldsList = (props: Props) => {
    const selected = useMemo(() =>
        (props.mandatoryFields?.length
            ? [...props.mandatoryFields, ...props.selected.filter(_ => !props.mandatoryFields?.includes(_))]
            : props.selected)
            .filter(_ => props.fields.some(__ => __.name === _)),
        [props.selected, props.mandatoryFields]);
    const [orderedFields, setOrderedFields] = useState(getOrdered(props.fields, selected));
    const mandatoryFields = useMemo(() => props.fields.filter(_ => ViewService.isMandatory(_, props.mandatoryFields)), [props.fields, props.mandatoryFields]);

    const getSelectedIndex = useGetSelectedIndex(selected);

    const onChange = useCallback((field: Metadata.Field, checked: boolean, selectedIndex?: number) => {
        const fieldNames = selected.filter(_ => _ !== field.name);
        if (checked) {
            fieldNames.splice(selectedIndex!, 0, field.name);
        }

        const fieldsMap = toDictionaryByName(props.fields);
        const fields = fieldNames.map(_ => fieldsMap[_]).filter(notEmpty);
        props.onChange!(fields);
    }, [props.onChange, selected]);

    const onOrderChanged = useCallback((changedOrder: Metadata.Field[], field: Metadata.Field) => {
        const reordered = mandatoryFields.length ? [...mandatoryFields, ...changedOrder] : changedOrder;
        // for smooth fields reorder
        setOrderedFields(reordered);
        const index = getSelectedIndex(field, reordered)
        // automatically turn on only fields which dragged in selected area
        if (index < props.selected.length) {
            onChange(field, true, index);
        }
    }, [selected, getSelectedIndex, onChange]);

    const refs = useRef<Dictionary<RefObject<HTMLDivElement>>>({});
    const onItemRender = useCallback((field: Metadata.Field) =>
        <ManageFieldCard
            ref={refs.current[field.name] || (refs.current[field.name] = createRef())}
            selected={selected.includes(field.name)}
            field={field}
            isNew={!!props.newFields?.includes(field.name)}
            allowManage={props.allowManageFields}
            index={getSelectedIndex(field, orderedFields)}
            onChange={ViewService.isMandatory(field, props.mandatoryFields) || !props.onChange ? undefined : onChange}
            hideToggle={ViewService.isMandatory(field, props.mandatoryFields)}
            actions={props.fieldActions}
            onEditClick={onEditClick}
        />,
        [props.allowManageFields, orderedFields, selected, props.newFields, props.mandatoryFields, props.onChange, getSelectedIndex]);

    useEffect(() => {
        const newOrder = getOrdered(props.fields, selected);
        if (!arraysEqual(orderedFields, newOrder)) {
            setOrderedFields(newOrder);
        }
    }, [props.fields, selected]);

    useScrollToNewField(props.newFields, selected, refs);

    const isItemDraggable = useCallback(() => !props.filter, [!!props.filter]);
    const filterFields = useFilterFields(props.filter);
    const filtered = useMemo(() => filterFields(orderedFields), [filterFields, orderedFields]);

    const [editField, setEditField] = useState<Metadata.Field | null>(null);
    const onEditClick = useCallback((field) => setEditField(field), []);
    const resetEditing = useCallback(() => setEditField(null), []);

    const filteredMandatories = useMemo(() => filtered.filter(_ => ViewService.isMandatory(_, props.mandatoryFields)), [filtered, props.mandatoryFields]);
    const filteredNotMandatories = useMemo(() => filtered.filter(_ => !ViewService.isMandatory(_, props.mandatoryFields)), [filtered, props.mandatoryFields]);

    return (
        <div className={`configure-fields ${props.className}`}>
            {!!filteredMandatories.length && <NonDraggableList
                items={filteredMandatories}
                onItemRender={onItemRender} />}
            {props.nonDraggable ? (
                <NonDraggableList
                    items={filteredNotMandatories}
                    onItemRender={onItemRender}
                    showShadowOnHover={props.showShadowOnNonDraggableHover}
                />
            ) : (
                <DraggableList
                    items={filteredNotMandatories}
                    onItemRender={onItemRender}
                    isItemDraggable={isItemDraggable}
                    onChanged={props.onChange ? onOrderChanged : undefined}
                    getKey={getKey}
                />
            )}
            {
                !filtered.length &&
                <>
                    {props.filter?.length
                        ? props.emptyMessages?.notFound ?? <NotFound />
                        : props.emptyMessages?.noFields ?? <span>No fields have been created yet</span>
                    }
                </> 
            }
            {
                // editing can't be made in the field card because the drag and drop issue
                // react dnd (used for drag and drop) doesn't allow to drag and drop component inside other draggable
                // that means select field option reorder won't work if panel declared inside the card which is draggable
                editField && props.fieldActions && (
                    <FieldPanel
                        entityType={props.entityType}
                        field={editField}
                        allowManageFields={props.allowManageFields}
                        actions={props.fieldActions}
                        onDismiss={resetEditing}
                    />
                )
            }
        </div>
    );
}

export const useFilterFields = (filter: string | undefined) => {
    const lowerCaseFilter = filter?.toLowerCase();
    return useCallback((fields: Metadata.Field[]) => lowerCaseFilter
        ? fields.filter(_ => Metadata.getLabel(_).toLowerCase().includes(lowerCaseFilter))
        : fields,
    [lowerCaseFilter]);
}

export const useScrollToNewField = (newFields: string[] | undefined, selected: string[], refs: React.MutableRefObject<Dictionary<React.RefObject<HTMLDivElement>>>) => {
    const [scrollIntoView, setScrollIntoView] = useState('');

    useEffect(() => {
        // scroll last new field into view
        if (!newFields) return;
        const lastNewField = newFields[newFields.length - 1];
        if (selected.includes(lastNewField)) {
            setScrollIntoView(lastNewField);
        }
    }, [selected.length, newFields?.length]);

    useLayoutEffect(() => {
        if (!scrollIntoView || !refs.current[scrollIntoView]?.current) return;
        const node = ReactDOM.findDOMNode(refs.current[scrollIntoView].current) as HTMLElement;
        node?.scrollIntoView(false);
    }, [scrollIntoView]);
}

export const useGetSelectedIndex = (selected: string[]) => useCallback((f: Metadata.Field, fields: Metadata.Field[]) => {
    let index = 0;
    for (const field of fields) {
        if (field.id === f.id) {
            break;
        }
        if (selected && selected.includes(field.name)) {
            index++;
        }
    }
    return index;
}, [selected])

export const getKey = (f: Metadata.Field) => f.id;

export const getOrdered = (fields: Metadata.Field[], selected: string[]) => {
    return orderNotSelected(fields,
        (selected ? f => selected.indexOf(f.name) : f => 0),
        a => Metadata.getLabel(a),
        true);
}
