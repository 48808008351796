import { StoreHelper } from "../services/storeHelper";
import { ICardSubView, SortDirection} from "../../entities/Metadata";
import { IViewsState, ICardSortOption, ITimelineViewState } from "../views";

const cardActiveSubView: ICardSubView = {
    id: '8e20079e-e40d-4b08-bb07-2ca65bb35d3c',
    name: 'Summary View',
    isBuiltIn: false,
    isNotSaved: false,
    showCosts: false,
    showWork: false
};

// 'Newest on Top' sort option index
const DEFAULT_CARD_SORT_OPTION_INDEX = 2;
const cardSortOptions: ICardSortOption[] = [
    {
        id: "755d9668-8f57-4110-af8f-2577162bb91c",
        orderBy: {
            fieldName: 'Name',
            direction: SortDirection.ASC,
        },
        label: 'Name: A – Z',
        iconName: 'PPMXSortAZ'
    },
    {
        id: "ea0efd4f-3887-4c86-9cd2-84bae5645c83",
        orderBy: {
            fieldName: 'Name',
            direction: SortDirection.DESC
        },
        label: 'Name: Z – A',
        iconName: 'PPMXSortZA'
    },
    {
        id: "5529587f-cb41-425c-8e5b-b0a1df55d8ef",
        orderBy: {
            fieldName: 'CreatedDate',
            direction: SortDirection.DESC,
        },
        label: 'Newest on Top',
        iconName: 'PPMXSortNewest'
    },
    {
        id: "10c4832d-a278-4591-a0e5-9bec6d03f12a",
        orderBy: {
            fieldName: 'CreatedDate',
            direction: SortDirection.ASC,
        },
        label: 'Oldest on Top',
        iconName: 'PPMXSortOldest'
    }
];

export const VIEWS_DEFAULTS: IViewsState = {
    card: {
        subViews: [
            cardActiveSubView
        ],
        sortBy: {
            active: cardSortOptions[DEFAULT_CARD_SORT_OPTION_INDEX],
            options: cardSortOptions
        }
    },
    list: {
        fakeFields: [],
        selectedByDefault: [],
        subViews: StoreHelper.create([]),
        sortBy: {
            fieldName: "Name",
            direction: SortDirection.ASC
        }
    },
    timeline: undefined as any as ITimelineViewState
}