import React from "react";
import { AIScheduleTask as AiTask } from "../AISchedulePanelFooters/AIScheduleTasksPreviewFooter";
import Spinner from "../../../common/Spinner";
import { HierarchDetailsListProps, HierarchyDetailsList } from "../../../common/extensibleEntity/EntityDetailsList";
import { ITask, ITaskAttrs } from "../../../../entities/Subentities";
import { EntityType } from "../../../../entities/common";
import { Views } from "../../../../store/services/viewSaver";
import { namesof } from "../../../../store/services/metadataService";
import { Field, toMap } from "../../../../entities/Metadata";
import { IOrderBy } from "../../../../store/views";
import { SectionHierarchyManager } from "../../../utils/SectionHierarchyManager";
import { TITLE_FIELD_ID } from "../../../../store/tasks";
import { SortService } from "../../../../services/SortService";
import { IObjectWithKey, Selection } from 'office-ui-fabric-react';
import './AIScheduleTasksPreview.css';

type Props = {
    isLoading: boolean;
    errorMessage: string | undefined;
    aiTasks: AiTask[];
    fields: Field[];
    onSelectionChanged: (ids: string[]) => void;
}

const toTask = (_: AiTask): ITask =>({ 
    id: _.id,
    attributes: {
        Name: _.name,
        Duration: _.duration
    },
    hierarchy: {
        outlineLevel: _.outlineLevel,
        parentId: _.parentId,
        isParent: _.isParent,
        rank: _.rank
    },
    externalData: { }
}) as ITask;

const AIScheduleTasksPreview = (props: Props) => {
    const { isLoading, errorMessage, aiTasks, fields, onSelectionChanged } = props;
    
    const selection = React.useMemo(() => {
        return new Selection<ITask & IObjectWithKey>({
            getKey: _ => _.id,
            onSelectionChanged: () => { 
                onSelectionChanged(selection.getSelection().map(_ => _.id));
            }
        });
    }, [onSelectionChanged]);

    const hierarchy = React.useMemo(() => {
        return new SectionHierarchyManager<ITask>({
            fieldId: TITLE_FIELD_ID,
            getItemId: (entity) => entity.id,
            getItemParentId: (entity) => entity.hierarchy.parentId
        });
    }, []);

    React.useEffect(() => {
        if (aiTasks.length) {
            const tasks = aiTasks.map(toTask);
            hierarchy.setItems(tasks.filter(_ => !_.hierarchy.parentId), tasks, true);
            tasks.filter(_ => _.hierarchy.isParent).forEach(_ => hierarchy.expand(_.id));
            selection.setItems(tasks, false);
            selection.setAllSelected(true);
        }
    }, [aiTasks]);

    const comparerBuilder = (orderBy: IOrderBy) => {
        const fieldsMap = toMap(fields);
        return SortService.getComparer(fieldsMap, orderBy);
    }

    const listProps: HierarchDetailsListProps<ITask> = {
        entityType: EntityType.Task,
        fields: fields,
        displayFields: namesof<ITaskAttrs>(["Name"]),
        controlSettings: { viewType: Views.List },
        hierarchy: hierarchy,
        selection: selection,
        getKey: _ => _.id,
        comparerBuilder: comparerBuilder,
    };

    return <div className="schedule-preview-panel"> {
        isLoading
            ? <Spinner text={"Generating Tasks"} />
            : errorMessage 
                ? <div className='error-message'>{errorMessage}</div>
                : <>
                    <div className="selected-tasks-counter">Selected {selection.getSelectedCount()} from {selection.getItems().length}</div>
                    <HierarchyDetailsList {...listProps} />
                </>
    } </div>
}

export default AIScheduleTasksPreview;