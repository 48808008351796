import { post, remove } from '../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { IEntityStore, StoreHelper, partialUpdate } from './services/storeHelper';
import { EntityType, IWithResourcePlan, IExtensibleEntity, IScalableTimeframe } from "../entities/common";
import { defaultCatch } from "./utils";
import {
    ChangeUsageLoadingStateAction, UpdateResourcesUsageAction, CreateResourceSuccessAction, UsageUpdateModel, ResourceUsage, ResourcePlanTotalsModel
} from './ResourcesListStore';

export interface UpdateEntityResourcePlanAction {
    namespace: string,
    type: "UPDATE_ENTITY_RESOURCE_PLAN";
    entityId: string;
    resourcePlanTotals: ResourcePlanTotalsModel;
    add?: string[];
    remove?: string[];
}

type KnownAction = UpdateEntityResourcePlanAction;

export function ResourcePlanOperationsFactory<
    TTypeInfo extends IExtensibleEntity & IWithResourcePlan,
    TListState extends IEntityStore<TTypeInfo>
>(namespace: EntityType) {
    const actionCreators = {
        addResources: (entityId: string, resourceIds: string[], timeframe: IScalableTimeframe):
            AppThunkAction<KnownAction | ChangeUsageLoadingStateAction | UpdateResourcesUsageAction> => (dispatch, getState) => {
                post<UsageUpdateModel>(`api/resourcePlan/${namespace}/${entityId}/resource`, { ids: resourceIds, timeframe })
                    .then(data => {
                        dispatch({ namespace, type: "UPDATE_ENTITY_RESOURCE_PLAN", entityId, resourcePlanTotals: data.resourcePlanTotals, add: resourceIds });
                        dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });
                        dispatch({ type: "USAGE_LOADING", isLoading: false });
                    })
                    .catch(defaultCatch(dispatch));

                dispatch({ type: "USAGE_LOADING", isLoading: true });
            },
        replaceResource: (entityId: string, prevId: string, nextId: string, keepRate: boolean, sumUpPlanHours: boolean, timeframe: IScalableTimeframe, sumUpActualHours?: boolean)
            : AppThunkAction<KnownAction | ChangeUsageLoadingStateAction | UpdateResourcesUsageAction> => (dispatch, getState) => {
                post<UsageUpdateModel>(`api/resourcePlan/${namespace}/${entityId}/resource/replace`, { prevId, nextId, keepRate, sumUpPlanHours, sumUpActualHours, timeframe })
                    .then(data => {
                        dispatch({ namespace, type: "UPDATE_ENTITY_RESOURCE_PLAN", entityId, resourcePlanTotals: data.resourcePlanTotals, add: [nextId], remove: [prevId] });
                        dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });
                        dispatch({ type: "USAGE_LOADING", isLoading: false });
                    })
                    .catch(defaultCatch(dispatch));

                dispatch({ type: "USAGE_LOADING", isLoading: true });
            },
        removeResources: (entityId: string, resourceIds: string[], timeframe: IScalableTimeframe): AppThunkAction<KnownAction | ChangeUsageLoadingStateAction | UpdateResourcesUsageAction> =>
            (dispatch, getState) => {
                remove<UsageUpdateModel>(`api/resourcePlan/${namespace}/${entityId}/resource`, { ids: resourceIds, timeframe })
                    .then(data => {
                        dispatch({ namespace, type: "UPDATE_ENTITY_RESOURCE_PLAN", entityId, resourcePlanTotals: data.resourcePlanTotals, remove: resourceIds });
                        dispatch({ type: "UPDATE_RESOURCE_USAGE", data: data.usages });
                        dispatch({ type: "USAGE_LOADING", isLoading: false });
                    })
                    .catch(defaultCatch(dispatch));

                dispatch({ type: "USAGE_LOADING", isLoading: true });
            },
        createResourceAndAdd: (entityId: string, name: string, layoutId: string, timeframe: IScalableTimeframe, auto?: boolean, startDate?: Date, finishDate?: Date):
            AppThunkAction<KnownAction | ChangeUsageLoadingStateAction | CreateResourceSuccessAction> => (dispatch, getState) => {
                post<UsageUpdateModel>(`api/resourcePlan/${namespace}/${entityId}/resource/new`, { name, layoutId, timeframe, auto, startDate, finishDate })
                    .then(data => {
                        dispatch(<CreateResourceSuccessAction>{ type: 'CREATE_RESOURCE_SUCCESS', resource: data.resource, isNotSetActiveEntity: true });
                        dispatch({ namespace, type: 'UPDATE_ENTITY_RESOURCE_PLAN', entityId, resourcePlanTotals: data.resourcePlanTotals, add: [data.resource!.id] });
                        dispatch({ type: "USAGE_LOADING", isLoading: false });
                    })
                    .catch(defaultCatch(dispatch));

                dispatch({ type: "USAGE_LOADING", isLoading: true });
            },
    };

    const reducer: Reducer<TListState> = (state: TListState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;
        if (action.namespace !== namespace) {
            return state;
        }

        switch (action.type) {
            case 'UPDATE_ENTITY_RESOURCE_PLAN':
                {
                    return StoreHelper.applyHandler(state, action.entityId,
                        (entity: TTypeInfo) => {
                            let resourceIds = entity.resourceIds;
                            if (action.add) {
                                resourceIds = [...resourceIds, ...action.add].filter((val, idx, self) => self.indexOf(val) === idx);
                            }
                            if (action.remove) {
                                resourceIds = resourceIds.filter(_ => !~action.remove!.indexOf(_))
                            }
                            return partialUpdate(entity, {
                                resourceIds,
                                attributes: {
                                    ...entity.attributes,
                                    PlannedWork: action.resourcePlanTotals.plannedWork,
                                    ActualWork: action.resourcePlanTotals.actualWork,
                                    EstimatedCost: action.resourcePlanTotals.estimatedCost,
                                    ActualCost: action.resourcePlanTotals.actualCost,
                                    EstimatedCharge: action.resourcePlanTotals.estimatedCharge,
                                    PlannedResourcesCount: action.resourcePlanTotals.plannedResourcesCount,
                                    CommittedResourcesCount: action.resourcePlanTotals.committedResourcesCount,
                                }
                            } as unknown as Partial<TTypeInfo>);
                        });
                }
            default:
                return state;
        }
    };

    return {
        actionCreators,
        reducer
    };
}