import React, { useEffect, useMemo } from 'react'
import { Resource, actionCreators as resourceActionCreators } from '../../store/ResourcesListStore';
import { actionCreators as timeTrackingActions } from "../../store/TimeTrackingStore";
import { Section } from '../../entities/Metadata';
import { ApplicationState } from '../../store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { DetailsSpinner } from '../common/Spinner';
import { SectionsContainer } from '../common/sectionsControl/SectionsContainer';
import { actionCreators as CalendarActionCreators } from "../../store/CalendarStore";
import ResourceHeader from '../resource/ResourceHeader';

enum UIControls {
    Summary = "ResourceTimeSummaryControl",
    TimeTracking = "TimeTrackingControl"
}

const _sections: Section[] = [
    {
        id: "7c3adb33-3df4-4441-a3f6-5f765f118692",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "Summary",
        settings: { "iconName": "PPMXSectionSummary" },
        uiControls: [
            {
                id: "a8a4f007-c595-41df-be96-cc9e2423a97d",
                type: UIControls.Summary,
                settings: {}
            }
        ]
    },
    {
        id: "c55ffb89-1537-4631-8b99-52655890bcb1",
        isOpen: true,
        isSelected: true,
        isAccessible: true,
        name: "Resource Time",
        settings: { iconName: "Clock" },
        uiControls: [
            {
                id: "6fe49cb6-63ba-4243-b7f2-27f7d3d64e4a",
                type: UIControls.TimeTracking,
                settings: {}
            }
        ]
    }
];

type StateProps = {
    resource?: Resource;
    isLoading: boolean;
};
type ActionProps = {
    resourceActions: typeof resourceActionCreators;
    timeTrackingActions: typeof timeTrackingActions;
    calendarActions: typeof CalendarActionCreators;
};

type Props = StateProps & ActionProps & RouteComponentProps<{ id: string }>;

function ResourceTime(props: Props) {

    const { resource, isLoading } = props;

    useEffect(() => {
        props.calendarActions.load();
    }, []);

    useEffect(() => {
        props.resourceActions.loadResource(props.match.params.id);
        props.timeTrackingActions.loadReportedSummary(props.match.params.id);
    }, [props.match.params.id]);

    const sections = useMemo(() => _sections.filter(_ => !isLoading || !_.uiControls.some(c => c.type === UIControls.TimeTracking)), [isLoading]);

    return (
        <DetailsSpinner isLoading={isLoading}>
            {resource && <ResourceHeader
                entity={resource}
                actions={{}}
            />}
            {resource && <SectionsContainer
                hideSideBar
                entity={{
                    id: resource.id,
                    resource: resource,
                    resourceCalendar: resource.usage?.calendar,
                    work: resource.work,
                    sections: sections
                } as any}
            />
            }
        </DetailsSpinner>
    );
}

function mapStateToProps(state: ApplicationState, ownProp: RouteComponentProps<{ id: string }>): StateProps {

    const resource = state.resources.activeEntity && state.resources.activeEntity.id === ownProp.match.params.id
        ? state.resources.activeEntity
        : undefined;

    return {
        isLoading: state.resources.isLoading,
        resource: resource,
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        timeTrackingActions: bindActionCreators(timeTrackingActions, dispatch),
        resourceActions: bindActionCreators(resourceActionCreators, dispatch),
        calendarActions: bindActionCreators(CalendarActionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(ResourceTime);
