import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { useDebounce } from "../utils/effects";

interface IProps {
    disabled: boolean;
    votesCount: number;
    isVoted: boolean;
    onChange: (isVoted: boolean) => void;
}

export const VotesToggle = (props: IProps) => {
    const { disabled, votesCount, isVoted, onChange } = props;
    return <div
        title={disabled ? 'Voting is currently disabled. You can vote for the idea once it is moved to Active stage' : (isVoted ? "Click to un-like" : "Click to like")}
        className={`votes-toggle ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? undefined : useDebounce(_ => onChange(!isVoted), 500)}>
        <Icon iconName={isVoted ? 'LikeSolid' : 'Like'} className={disabled ? 'disabled' : ''} />
        <div className="title">{isVoted ? "Liked" : "Like"}</div>
        <div className="progress-tip">{votesCount}</div>
    </div>;
}

export default (VotesToggle);