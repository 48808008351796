import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IContextualMenuItem, arraysEqual, CommandBar, CommandBarButton, Icon, ICommandBarItemProps } from 'office-ui-fabric-react';
import { ApplicationState } from '../../../../store';
import * as ResourcesListStore from '../../../../store/ResourcesListStore';
import { UserState } from '../../../../store/User';
import { CommonOperations, contains } from '../../../../store/permissions';
import { ControlSpiner } from '../../../../components/common/Spinner';
import { IListProps } from '../../../../components/common/extensibleEntity/EntityDetailsList';
import { TimelineGroupingProps, IRow, ScaleChange, TimelineChange } from '../../../../components/common/timeline/TimelineList';
import { IExtensibleEntity, Dictionary, Quantization, ITimeframe, EntityType, OriginScale, IWithResourcePlan, updateCheckboxOptions, IScalableTimeframe, entityTypeLabelMap } from '../../../../entities/common';
import { OwnProps as ListMenuProps } from '../../../../components/common/extensibleEntity/ListMenu';
import { TimelineScale, IScale } from '../../../../components/common/timeline/TimelineScale';
import { ResourceUsageCell, ISelectableCellData } from '../ResourceUsageCell';
import MarqueeSelectionWrapper from '../../../../components/common/MarqueeSelectionWrapper';
import EntityTimelineList, { ITimelineProps } from '../../../../components/common/extensibleEntity/EntityTimelineList';
import { ScaleRenderMode } from '../../../../components/common/timeline/TimelineBody';
import { ITimelineSegment, IScaleTimelineSegment } from '../../../../components/common/timeline/TimelineSegment';
import TotalTooltipContent from '../totalTooltipContent';
import UtilizationTooltipContent, { OwnProps as CellTooltipProps } from '../UtilizationTooltipContent';
import { RowMenu } from '../../../../components/common/extensibleEntity/RowMenu';
import SelectionExt from '../../../common/SelectionExt';
import {
    ResourceUsageMenu, buildSetRateCommand, TimeType, timeTypes, SetTimeAction, buildAllocateCommands, buildAutoAllocateCommand, buildSetBillingCodeCommand
} from '../ResourceUsageCell';
import { notUndefined, distinct, formatFieldValue, HUNDRED_PCT, DebouncedAction } from '../../../utils/common';
import { CalculatedField, Field, FieldGroup, FieldType, FormatType } from '../../../../entities/Metadata';
import RemoveDialog from '../../../common/RemoveDialog';
import { IOrderBy } from '../../../../store/views';
import { ShiftAllocationPanel } from '../ShiftAllocationPanel';
import { max, minMax } from '../../../common/timeline/utils';
import { nameof } from '../../../../store/services/metadataService';
import { MyWorkAttrs } from '../../../../store/MyWorkStore';
import { LocationState } from 'history';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ActiveFilter, MyWorkParentInfo } from '../../../../store/mywork/filters';
import { AllocatePanel } from '../AllocatePanel';
import { TextFormatter } from '../../../common/formatters/TextFormatter';

export type ShiftPlanContext = { entityId: string, resourceId: string, days: number };

export enum ViewType {
    Hours,
    Percent,
    FTE,
    Chart
}

const duration_days = 210;
export const defaultTimeframe: ITimeframe = {
    start: new Date().getMonthStartDate(),
    end: new Date().getMonthStartDate().addDays(duration_days).getMonthEndDate()
}

export interface IMaybeTotal { isTotal?: true };
export const totalRow: IExtensibleEntity & IMaybeTotal = {
    id: 'totalRow',
    attributes: {},
    isTotal: true
};
export const SCALE_MULTIPLIER_PER_TIMETYPE_COLUMN = 1.5;

export type OwnProps = {
    entityType: EntityType;
    byResource?: boolean;
    entitiesMap: Dictionary<IExtensibleEntity & IWithResourcePlan>;
    scrollable?: boolean;
    entitiesIds: string[];
    selectedEntityIds?: string[];
    viewType?: ViewType;
    isLoading: boolean;
    menuProps: ListMenuProps;
    listProps: IListProps & Partial<ITimelineProps>;
    timelineGrouping?: TimelineGroupingProps;
    isEditable?: ((entity: IExtensibleEntity) => boolean);
    extractRowIds: (entity: IExtensibleEntity) => { entityIds: string[], resourceIds: string[]; isTotalRow?: boolean; };
    extractCellIds: (cell: ISelectableCellData) => { entityId: string, resourceId: string; };
    renderTotalSegmentContent?: (row: IRow, segment: ITimelineSegment, timeType: TimeType[]) => JSX.Element;
    cellTooltipProps?: Partial<CellTooltipProps>;
    buildRowMenuItems?: (entity: IExtensibleEntity) => IContextualMenuItem[];
    onTimeframeChanged?: (timeframe: ITimeframe) => void;

    timeType: TimeType[];
    onTimeTypeChange: (timeType: TimeType[]) => void;

    actualTimeDisabled?: boolean;
    commitDisabled?: boolean;
}

type ActionProps = {
    resourceActions: typeof ResourcesListStore.actionCreators;
}
type StoreProps = {
    user: UserState;
    resources: ResourcesListStore.ResourcesState,
    parentToChildrenMap: Dictionary<MyWorkParentInfo[]>;
}
type Props = OwnProps & ActionProps & StoreProps & RouteComponentProps<{}>;
type CellIndexesByRowByDateMap = Dictionary<Dictionary<number>>;

enum CommittingType {
    Commit = 1,
    Uncommit = 2
}

interface State {
    cellIndexMap: CellIndexesByRowByDateMap;
    cellsInRow: number;
    totalCells: number;
    quantization: Quantization;
    timeframe: ITimeframe;
    isCommitting?: CommittingType;
    isShiftPlanPanelOpen?: boolean;
    isAllocatePanelOpen?: boolean;
    isSetActualPanelOpen?: boolean;
}

const calculateSum = (
    resources: ResourcesListStore.Resource[],
    entityIds: string[],
    attributeSelector: (usage: ResourcesListStore.EntityResourceUsageAttrs) => number,
    resourceAction?: (resource: ResourcesListStore.Resource, usageSum: number) => number) => {
    resourceAction ??= (_, s) => s;
    return resources.reduce((sum, resource) => {
        const sumByResource = entityIds.reduce((acc, entityId) => {
            const entityUsages = resource.usage?.byEntity[entityId];
            return acc + (entityUsages ? resourceAction!(resource, attributeSelector(entityUsages.attributes)) : 0);
        }, 0);
        return sum + sumByResource;
    }, 0);
}

// copy is on server side in Consts.cs
export const bookingTypeField: CalculatedField = {
    id: 'dbc93574-04d8-40c6-b349-4c012e218435',
    type: FieldType.Text,
    name: "dbc93574-04d8-40c6-b349-4c012e218435",
    label: 'Booking Type',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) => {
        if (resources.length !== 1 || entityIds.length !== 1) {
            return undefined;
        }

        for (const entityId of entityIds) {
            const result = resources[0].usage?.byEntity[entityId]?.attributes.bookingType;
            if (result !== undefined) {
                return result;
            }
        }
        return undefined;
    },
}

export const chargeRateField: CalculatedField = {
    id: 'c8ec6cd0-0d5e-4697-997c-717bd1f91efd',
    type: FieldType.Decimal,
    name: "c8ec6cd0-0d5e-4697-997c-717bd1f91efd",
    label: 'Charge Rate',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) => {
        if (resources.length !== 1 || entityIds.length !== 1) {
            return undefined;
        }
        return resources[0].usage?.byEntity[entityIds[0]]?.attributes.chargeRate;
    }
}

export const billingCodeField: CalculatedField = {
    id: '853870ab-0132-4696-9185-69eb6763600a',
    type: FieldType.Text,
    name: "853870ab-0132-4696-9185-69eb6763600a",
    label: 'Billing Code',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) => {
        if (resources.length !== 1 || entityIds.length !== 1) {
            return undefined;
        }

        return resources[0].usage?.byEntity[entityIds[0]]?.attributes.billingCode;
    }
};

const estimatedCostField: CalculatedField = {
    id: '07c2b4a1-96f1-43f7-a6da-6bed0177e3a5',
    type: FieldType.Decimal,
    name: "07c2b4a1-96f1-43f7-a6da-6bed0177e3a5",
    label: 'Estimated Cost',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) =>
        calculateSum(
            resources,
            entityIds,
            attrs => attrs.totalPlanned,
            (resource, totalPlanned) => (resource.attributes.StandardCost ?? 0) * totalPlanned)
}

const actualCostField: CalculatedField = {
    id: "e8147ff7-ac12-410b-8b0c-30729d270ba1",
    type: FieldType.Decimal,
    name: "e8147ff7-ac12-410b-8b0c-30729d270ba1",
    label: 'Actual Cost',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) =>
        calculateSum(
            resources,
            entityIds,
            attrs => attrs.totalActual,
            (resource, totalActual) => (resource.attributes.StandardCost ?? 0) * totalActual)
}

const estimatedChargeField: CalculatedField = {
    id: '9e96cde8-8e21-40bb-b73d-5d8bf434ea61',
    type: FieldType.Decimal,
    name: "9e96cde8-8e21-40bb-b73d-5d8bf434ea61",
    label: 'Estimated Charge',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) =>
        calculateSum(
            resources,
            entityIds,
            attrs => attrs.estimatedCharge)
}

export const totalPlannedField: CalculatedField = {
    id: '97225117-647f-4d35-8186-6bd4eb761f32',
    type: FieldType.Decimal,
    name: '97225117-647f-4d35-8186-6bd4eb761f32',
    label: 'Total Planned',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) =>
        calculateSum(
            resources,
            entityIds,
            attrs => attrs.totalPlanned)
}

const totalActualField: CalculatedField = {
    id: 'ef26842f-995f-4095-991e-a17c70d204d2',
    type: FieldType.Decimal,
    name: 'ef26842f-995f-4095-991e-a17c70d204d2',
    label: 'Total Actual',
    isNative: true,
    isCustom: false,
    isReadonly: true,
    isSystem: true,
    group: FieldGroup.SystemFields,
    calculate: (resources: ResourcesListStore.Resource[], entityIds: string[]) =>
        calculateSum(
            resources,
            entityIds,
            attrs => attrs.totalActual)
}

type BaseCalculatedFieldFormatterProps = {
    resources: ResourcesListStore.ResourcesState,
    entityIds: string[],
    resourceIds: string[],
    className?: string,
    user: UserState
}
type CalculatedFieldFormatterProps = BaseCalculatedFieldFormatterProps & {
    calculatedField: CalculatedField,
    formatType: FormatType,
}

const CalculatedFieldFormatter = React.memo((props: CalculatedFieldFormatterProps) => {
    const { user, calculatedField, formatType, resources, resourceIds, className, entityIds } = props;
    const value = React.useMemo(
        () => calculatedField.calculate(resourceIds.map(_ => resources.byId[_]).filter(notUndefined), entityIds),
        [resourceIds, resources, entityIds]);

    return <span className={className}>
        {value !== undefined ? formatFieldValue(value, formatType, user) : null}
    </span>;
})

export const defaultTimeType: TimeType[] = ['plan'];

class ResourceUsageGrid extends React.Component<Props, State>{
    private _cellSelection: SelectionExt;
    private _cellByIndexMap: Dictionary<any> = {};
    private _cellPositionToIndexMap: Dictionary<number | undefined> = {};
    private _isCellSelectionStarted: boolean = false;
    private _isCellEditingStarted: boolean = false;

    private readonly _debouncedSetPlan: DebouncedAction<ResourcesListStore.ResourceUsageUpdate[]>;
    private readonly _debouncedSetActual: DebouncedAction<ResourcesListStore.ResourceUsageUpdate[]>;

    constructor(props: Props) {
        super(props);
        const timeframe = { ...defaultTimeframe, ...props.listProps.userTimeframe };

        this._debouncedSetPlan = new DebouncedAction(
            (data: ResourcesListStore.ResourceUsageUpdate[][]) => {
                if (data) {
                    const usage = data.reduce<ResourcesListStore.ResourceUsageUpdate[]>((acc, item) => [...acc, ...item], []);
                    this.props.resourceActions.updateUsage(this.props.entityType, usage, convertToDateOnlyTimeframe(this.state.timeframe), false);
                }
            }
        )
        this._debouncedSetActual = new DebouncedAction(
            (data: ResourcesListStore.ResourceUsageUpdate[][]) => {
                if (data) {
                    const usage = data.reduce<ResourcesListStore.ResourceUsageUpdate[]>((acc, item) => [...acc, ...item], []);
                    this.props.resourceActions.updateUsage(this.props.entityType, usage, convertToDateOnlyTimeframe(this.state.timeframe), true);
                }
            }
        )

        const scale = TimelineScale.build(timeframe, props.listProps.userQuantization);
        const cellIndexMap = this._buildCellIndexMap(props.entitiesIds, scale.scale);
        this._cellSelection = new SelectionExt({
            onSelectionChanged: () => {
                if (!!this.props.selectedEntityIds?.length) {
                    this.props.listProps.selection?.setAllSelected(false)
                }
            }
        });
        this._cellSelection.setItems(cellIndexMap.endToEndItems, true);

        this.state = {
            cellIndexMap: cellIndexMap.map,
            cellsInRow: Object.keys(cellIndexMap.map).length > 0 ? Object.keys(cellIndexMap.map[Object.keys(cellIndexMap.map)[0]]).length : 0,
            totalCells: cellIndexMap.endToEndItems.length,
            quantization: scale.quantization,
            timeframe
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.selectedEntityIds && (!this.props.selectedEntityIds || !arraysEqual(nextProps.selectedEntityIds, this.props.selectedEntityIds))) {
            this._cellSelection.setAllSelected(false);
        }

        const newEntitiesIds = nextProps.entitiesIds;
        const oldEntitiesIds = this.props.entitiesIds;
        if (!arraysEqual(oldEntitiesIds, newEntitiesIds)) {
            const scale = TimelineScale.build(this.state.timeframe, this.state.quantization, nextProps.timeType.length);
            const cellIndexMap = this._buildCellIndexMap(newEntitiesIds, scale.scale);
            this.setState({
                cellIndexMap: cellIndexMap.map,
                cellsInRow: Object.keys(cellIndexMap.map).length > 0 ? Object.keys(cellIndexMap.map[Object.keys(cellIndexMap.map)[0]]).length : 0,
                totalCells: cellIndexMap.endToEndItems.length
            });
            this._cellSelection.setItems(cellIndexMap.endToEndItems, true);
        }
    }

    render() {
        const { user, isLoading, timelineGrouping, isEditable } = this.props;
        const { quantization, cellsInRow, totalCells } = this.state;

        const viewType = this.props.viewType ?? ViewType.Hours;
        const isWholeGridReadOnly = !contains(user.permissions.common, CommonOperations.ResourceManage) || isEditable === undefined;
        
        const menuProps = this._buildMenuProps(isEditable);
        const listProps: IListProps & Partial<ITimelineProps> = this._buildListProps();

        let cellPosition = 0;
        return <>
            <ControlSpiner isLoading={isLoading} className="show-over">
                <ResourceUsageMenu quantization={quantization}
                    menuProps={menuProps}
                    availableTimeTypes={this.props.actualTimeDisabled ? ['plan', 'capacity'] : ['plan', 'actual', 'availability', 'capacity']}
                    timeType={this.props.timeType}
                    onTimeTypeChange={(timeType, checked) => this.props.onTimeTypeChange(timeTypes
                        .map(_ => _.type)
                        .filter(_ => _ === timeType ? checked : this.props.timeType.includes(_)))
                    }
                />
                <MarqueeSelectionWrapper selection={this._cellSelection} isDraggingConstrainedToRoot={true}
                    isEnabled={!isWholeGridReadOnly}
                    onSelectionStarted={() => {
                        this._isCellSelectionStarted = true;
                        this._clearRectangle();
                    }}
                    onSelectionStopped={() => {
                        this._isCellSelectionStarted = false;
                    }}
                    rootProps={this.props.scrollable ? { 'data-is-scrollable': true } as any : undefined}
                    className="entities-list-body marquee-root"
                >
                    <EntityTimelineList key="timeline"
                        {...listProps}
                        {...updateCheckboxOptions(listProps, menuProps.selectionMode?.items)}
                        buildRow={listProps.buildRow!}
                        initialTimeframe={defaultTimeframe}
                        userQuantization={quantization}
                        scaleRenderMode={ScaleRenderMode.Cell}
                        timelineGrouping={timelineGrouping}   
                        onScaleChange={this._onScaleChange}                     
                        renderSegmentContent={(row: IRow, segment: IScaleTimelineSegment) => {
                            const { entityIds, resourceIds, isTotalRow } = this.props.extractRowIds(row.entity);
                            if (isTotalRow) {
                                return this.props.renderTotalSegmentContent?.(row, segment, this.props.timeType);
                            }

                            const idx = isEditable?.(row.entity) ? this.state.cellIndexMap[row.key]?.[segment.startDate.getTime()] : undefined;
                            cellPosition++;
                            const position = cellPosition;
                            this._cellPositionToIndexMap[position] = idx;
                            return <ResourceUsageCell
                                timeType={this.props.timeType}
                                resource={this.props.resources.byId[resourceIds[0]]}
                                entityId={entityIds[0]}
                                entityType={this.props.entityType}
                                selection={this._cellSelection}
                                selectionIndex={idx}
                                componentRef={idx !== undefined ? (_) => _ && (this._cellByIndexMap[idx] = _) : undefined}
                                onClick={idx !== undefined ? (e) => {
                                    e.stopPropagation();
                                    this._clearRectangle();
                                    this._navigateToCellByIndex(idx, e.shiftKey);
                                } : undefined}
                                onKeyDown={idx !== undefined ? (e) => {
                                    let nextPosition = undefined;
                                    if (e.key === 'ArrowLeft') {
                                        nextPosition = position - 1;
                                    }
                                    else if (e.key === 'ArrowRight') {
                                        nextPosition = position + 1;
                                    }
                                    else if (e.key === 'ArrowUp') {
                                        nextPosition = position - cellsInRow;
                                    }
                                    else if (e.key === 'ArrowDown') {
                                        nextPosition = position + cellsInRow;
                                    }

                                    if (nextPosition !== undefined && nextPosition < totalCells && this._cellPositionToIndexMap[nextPosition] !== undefined) {
                                        if (!e.shiftKey) {
                                            this._clearRectangle();
                                            this._navigateToCellByIndex(this._cellPositionToIndexMap[nextPosition]!, e.shiftKey);
                                        }
                                        else {
                                            this._selectRectangle(nextPosition, position, cellsInRow);
                                        }
                                        e.preventDefault();
                                    }

                                    if (e.key === 'Tab') {
                                        const _nextPosition = e.shiftKey ? position - 1 : position + 1;
                                        if (_nextPosition !== undefined && _nextPosition < totalCells && this._cellPositionToIndexMap[_nextPosition] !== undefined) {
                                            this._clearRectangle();
                                            this._navigateToCellByIndex(this._cellPositionToIndexMap[_nextPosition]!, false);
                                            e.preventDefault();
                                        }
                                    }
                                } : undefined}
                                onKeyPress={idx !== undefined ? (e) => {
                                    if (e.key === 'Enter' && this._isCellEditingStarted) {
                                        this._cellByIndexMap[idx]?.focus();
                                    }
                                } : undefined}
                                onEditStart={idx !== undefined ? () => {
                                    this._isCellEditingStarted = true;
                                } : undefined}
                                onEditComplete={idx !== undefined ? (value: number | null, timeType: TimeType) => {
                                    if (value !== null) {
                                        (timeType === 'actual' ? this._setActual : this._setPlan)(
                                            value,
                                            viewType === ViewType.Chart ? ViewType.Hours : viewType,
                                            [(this._cellSelection.getItems() as ISelectableCellData[]).find(_ => _.key === idx)!]);
                                    }

                                    this._isCellEditingStarted = false;
                                } : undefined}
                                viewType={viewType}
                                quantization={quantization}
                                prevStartDate={segment.prevStartDate}
                                startDate={segment.startDate}
                                finishDate={segment.finishDate} />;
                        }}
                        renderSegmentTooltipContent={(row, segment) => {
                            if (this._isCellSelectionStarted || this._isCellEditingStarted) {
                                return undefined;
                            }

                            const { entityIds, resourceIds, isTotalRow } = this.props.extractRowIds(row.entity);
                            if (isTotalRow) {
                                return <TotalTooltipContent entityType={this.props.entityType} startDate={segment.startDate} finishDate={segment.finishDate}
                                    entityId={entityIds.length === 1 ? entityIds[0] : undefined}
                                    resources={resourceIds.map(_ => this.props.resources.byId[_]).filter(notUndefined)} />;
                            }

                            const selectedCells = this._cellSelection.getSelectedIndices();
                            const idx = this.state.cellIndexMap[row.key]?.[segment.startDate.getTime()];
                            if (selectedCells.length > 1 && idx !== undefined && !!~selectedCells.indexOf(idx)) {
                                const cellsFromSingleResourceSelected = this._cellSelection.getSelection()
                                    .map(_ => this.props.extractCellIds(_ as ISelectableCellData).resourceId)
                                    .filter(distinct).length === 1;
                                return <CellMenu cell={undefined} quantization={this.state.quantization}
                                    setPlan={this._setPlan} setActual={!this.props.actualTimeDisabled ? this._setActual : undefined}
                                    viewAssignments={!this.props.actualTimeDisabled && cellsFromSingleResourceSelected ? this._viewAssignments : undefined} />;
                            }

                            return <UtilizationTooltipContent
                                {...this.props.cellTooltipProps}
                                resource={this.props.resources.byId[resourceIds[0]]}
                                entityId={entityIds[0]}
                                entityType={this.props.entityType}
                                startDate={segment.startDate} finishDate={segment.finishDate}
                                onMenuRender={isEditable?.(row.entity)
                                    ? () => <CellMenu cell={{ entityId: row.entity.id, start: segment.startDate, finish: segment.finishDate }}
                                        quantization={this.state.quantization} setPlan={this._setPlan} setActual={!this.props.actualTimeDisabled ? this._setActual : undefined}
                                        viewAssignments={!this.props.actualTimeDisabled ? this._viewAssignments : undefined} />
                                    : undefined} />
                        }}
                        onItemMenuRender={item => {
                            let commands = this.props.buildRowMenuItems?.(item) || [];
                            if (isEditable?.(item)) {
                                commands = [
                                    this._buildAllocateCommand(),
                                    this._buildSetActualCommand(),
                                    this._buildShiftAllocationCommand(),
                                    this._buildSetRateCommandIfCanManageBudget(),
                                    buildSetBillingCodeCommand( this._setBillingCode),
                                    this._buildCommitUncommitCommand(item),
                                    ...commands
                                ].filter(notUndefined);
                            }

                            return commands.length
                                ? <RowMenu
                                    commands={commands}
                                    selection={this.props.listProps.selection}
                                    item={item}
                                />
                                : null;
                        }}
                    />
                </MarqueeSelectionWrapper>
                {this.state.isShiftPlanPanelOpen && <ShiftAllocationPanel
                    entityType={this.props.entityType}
                    byResource={this.props.byResource}
                    rows={this._getShiftingRows()}
                    onComplete={this._shiftPlan}
                    onDismiss={() => this.setState({ isShiftPlanPanelOpen: false })}
                />}
                {this.state.isAllocatePanelOpen && <AllocatePanel
                    isActual={false}
                    entityType={this.props.entityType}
                    byResource={this.props.byResource}
                    rows={this._getSelectedRows()}
                    onComplete={this._allocate}
                    onDismiss={() => this.setState({ isAllocatePanelOpen: false })}
                />}
                {this.state.isSetActualPanelOpen && <AllocatePanel
                    isActual={true}
                    entityType={this.props.entityType}
                    byResource={this.props.byResource}
                    rows={this._getSelectedRows()}
                    onComplete={this._allocate}
                    onDismiss={() => this.setState({ isSetActualPanelOpen: false })}
                />}
            </ControlSpiner>
            {this._renderCommitDialog()}
        </>;
    }

    private _onScaleChange = (timelineChange: TimelineChange) => {
        const { timeframe } = this.state;
        const { newState, origin } = timelineChange;
        const { quantization, scale } = newState;

        const newTimeline = {
            start: origin?.timeframe?.start || scale.dates[0].start,
            end: origin?.timeframe?.end || scale.dates[scale.dates.length - 1].finish
        };

        const isTimeframeChanged = timeframe.start.getBeginOfDay().getTime() !== newTimeline.start.getBeginOfDay().getTime()
            || timeframe.end.getBeginOfDay().getTime() !== newTimeline.end.getBeginOfDay().getTime();

        if (isTimeframeChanged || quantization !== this.state.quantization) {
            if (isTimeframeChanged) {
                const newTimeframe = { start: newTimeline.start.getBeginOfDay(), end: newTimeline.end.getBeginOfDay() };
                this.setState({
                    timeframe: newTimeframe
                });
                this.props.onTimeframeChanged?.(newTimeframe);
            }

            const { map, endToEndItems } = this._buildCellIndexMap(this.props.entitiesIds, scale);
            this.setState({
                cellIndexMap: map,
                cellsInRow: Object.keys(map).length > 0 ? Object.keys(map[Object.keys(map)[0]]).length : 0,
                totalCells: endToEndItems.length,
                quantization
            });

            this._cellSelection.setItems(endToEndItems, true);
            this._cellByIndexMap = {};
            this._cellPositionToIndexMap = {};
            this._clearRectangle();
        }
        this.props.listProps.onScaleChange?.(timelineChange);
    }

    private _isCommitted = (entity: IExtensibleEntity): boolean => {
        const rowIds = this.props.extractRowIds(entity);
        return !!ResourcesListStore.firstUsage(this.props.resources.byId[rowIds.resourceIds[0]]?.usage, rowIds.entityIds[0])?.committed;
    }

    private _renderCommitDialog = () => {
        if (!this.state.isCommitting) {
            return null;
        }

        const { resources, entityType } = this.props;
        const cells = (this._cellSelection.getItems() as ISelectableCellData[]);
        const cellsBySelectedRows = this.props.selectedEntityIds!.map(_ => Object.keys(this.state.cellIndexMap[_!] || {}).map(__ => this.state.cellIndexMap[_!][__]))
            .reduce((prev, cur) => ([...prev, ...cur]), []).map(__ => cells.find(_ => _.key === __)!);

        const commits = cellsBySelectedRows.map<ResourcesListStore.ResourceUsageCommit>(_ => this.props.extractCellIds(_))
            .filter(distinct);
        const resourceIds = commits.map(_ => _.resourceId).filter(distinct);
        const ids = commits.map(_ => _.entityId).filter(distinct);

        const isCommited = this.state.isCommitting === CommittingType.Uncommit;
        const title = `${isCommited ? 'Uncommit' : 'Commit'} Resource${resourceIds.length > 1 ? 's' : ''}`;
        const subText = isCommited
            ? (`Are you sure you want to uncommit` +
                ` ${resourceIds.length === 1 ? `resource "${resources.byId[commits[0].resourceId].attributes.Name}"` : `${resourceIds.length} resources`}` +
                ` from ${ids.length === 1 ? `the ${entityTypeLabelMap[entityType].singular}` : `${ids.length} entities`} and change ${resourceIds.length === 1 ? 'its' : 'their'} state to proposed?`)
            : (`Are you sure you want to commit` +
                ` ${resourceIds.length === 1 ? ` resource "${resources.byId[commits[0].resourceId].attributes.Name}"` : `${resourceIds.length} resources`}` +
                ` to ${ids.length === 1 ? `the ${entityTypeLabelMap[entityType].singular}` : `${ids.length} ${entityTypeLabelMap[entityType].plural}`}?`);

        return <RemoveDialog dialogContentProps={{ title, subText }} confirmButtonProps={{ text: isCommited ? 'Uncommit' : 'Commit' }}
            onClose={() => this.setState({ isCommitting: undefined })} onComplete={() => this._onCommit(!isCommited)} />;
    }

    private _onCommit = (commit: boolean) => {
        const cellsBySelectedRows = this._getCellsBySelectedRows();
        const commits = cellsBySelectedRows?.map<ResourcesListStore.ResourceUsageCommit>(_ => this.props.extractCellIds(_))
            .filter((value, index, self) => self.indexOf(value) === index);

        if (commits) {
            this.props.resourceActions.commitUsage(this.props.entityType, commits, commit, convertToDateOnlyTimeframe(this.state.timeframe));
        }
    }

    private _setRate = (rate: number) => {
        const cellsBySelectedRows = this._getCellsBySelectedRows();
        const rates = cellsBySelectedRows?.map<ResourcesListStore.ResourceUsageRate>(_ => ({ ...this.props.extractCellIds(_), rate }))
            .filter((value, index, self) => self.indexOf(value) === index);

        if (rates) {
            this.props.resourceActions.setRate(this.props.entityType, rates);
        }
    }

    private _setBillingCode = (billingCode: string) => {
        const cellsBySelectedRows = this._getCellsBySelectedRows();
        const billingCodes = cellsBySelectedRows?.map<ResourcesListStore.ResourceUsageBillingCode>(_ => ({ ...this.props.extractCellIds(_), billingCode }))
            .filter((value, index, self) => self.indexOf(value) === index);

        if (billingCodes) {
            this.props.resourceActions.setBillingCode(this.props.entityType, billingCodes);
        }
    }

    private _setPlan = (value: number | true, viewType?: ViewType, cells?: ISelectableCellData[]) => {
        const usages = this._getUsages(value, viewType, cells);
        this._debouncedSetPlan.callAction(usages);
    }

    private _setActual = (value: number | true, viewType?: ViewType, cells?: ISelectableCellData[]) => {
        const usages = this._getUsages(value, viewType, cells);
        this._debouncedSetActual.callAction(usages);
    }

    private _viewAssignments = (cells: ISelectableCellData[]) => {
        const selectedCells = (cells ?? this._cellSelection.getSelection() as ISelectableCellData[])
        const cellsIds = selectedCells.map(_ => this.props.extractCellIds(_));
        const resourceIds = cellsIds.map(_ => _.resourceId).filter(distinct);

        const entityIds = cellsIds.map(_ => _.entityId).filter(distinct);
        const entities = entityIds.map(_ => ({ id: _, name: this.props.entitiesMap[_].attributes.Name }));
        const entitiesWithExtraParents = entityIds.reduce((prev, _) => [...prev, ...(this.props.parentToChildrenMap[_] ?? [])], entities)

        const { minDate, maxDate } = minMax([...selectedCells.map(_ => _.start), ...selectedCells.map(_ => _.finish)]);
        const filterState: LocationState =
        {
            filters: {
                [EntityType.MyWork]: {
                    prefilterKey: undefined,
                    filter: new ActiveFilter(entities.length === 1 ? entities[0].name : "Custom")
                        .withParents(entitiesWithExtraParents)
                        .withValues([{
                            name: nameof<MyWorkAttrs>("StartDate"),
                            value: { to: maxDate!.toDateOnlyString() }
                        },
                        {
                            name: nameof<MyWorkAttrs>("DueDate"),
                            value: { from: minDate!.toDateOnlyString() }
                        }])
                        .build()
                }
            }
        };
        this.props.history.push({
            pathname: `/resource/${resourceIds[0]}/workload`,
            state: filterState
        });
    }

    private _getShiftingRows = (): { resource: ResourcesListStore.Resource, entity: IExtensibleEntity & IWithResourcePlan }[] => {
        return (this.props.listProps.selection!.getSelection() as IExtensibleEntity[])
            .map(_ => {
                const { entityIds, resourceIds } = this.props.extractRowIds(_);
                const resource = this.props.resources.byId[resourceIds[0]];
                const entity = this.props.entitiesMap[entityIds[0]];
                if (!resource || !entity || !ResourcesListStore.findUsage(resource.usage, entity.id, usage => !!usage.plannedHours)) {
                    return undefined;
                }

                return { resource, entity };
            })
            .filter(notUndefined);
    };

    private _getSelectedRows = (): { resource: ResourcesListStore.Resource, entity: IExtensibleEntity & IWithResourcePlan }[] => {
        return (this.props.listProps.selection!.getSelection() as IExtensibleEntity[])
            .map(_ => {
                const { entityIds, resourceIds } = this.props.extractRowIds(_);
                const resource = this.props.resources.byId[resourceIds[0]];
                const entity = this.props.entitiesMap[entityIds[0]];
                if (!resource || !entity) {
                    return undefined;
                }

                return { resource, entity };
            })
            .filter(notUndefined);
    };

    private _shiftPlan = (startDate: Date, daysShift: number, useCalendar: boolean) => {
        const usage = this._getShiftingRows()
            .map<ResourcesListStore.ResourceUsageUpdate | undefined>(row => {
                const usages = ResourcesListStore.filterUsage(row.resource.usage, row.entity.id, _ => _.plannedHours !== 0 && _.date.getTime() >= startDate.getTime());
                const maxPlannedDate = max(usages.map(_ => _.date));
                if (!maxPlannedDate) {
                    return undefined;
                }
                return {
                    resourceId: row.resource.id,
                    entityId: row.entity.id,
                    startDate,
                    finishDate: maxPlannedDate,
                    daysShift,
                    useCalendar
                };
            }).filter(notUndefined);
        this.props.resourceActions.updateUsage(this.props.entityType, usage, convertToDateOnlyTimeframe(this.state.timeframe));
    }

    private _allocate = (start: Date, finish: Date, value: number | true, viewType?: ViewType, isActual?: boolean, valuePerDay?: boolean) => {
        const auto = value === true ? value : undefined;
        const valueNumber = value === true ? undefined : value;
        const usage = this._getSelectedRows()
            .map<ResourcesListStore.ResourceUsageUpdate | undefined>(_ => ({
                resourceId: _.resource.id,
                entityId: _.entity.id,
                startDate: start,
                finishDate: finish.getBeginOfDay(),
                auto,
                valuePerDay,
                fte: viewType === ViewType.FTE ? valueNumber : undefined,
                percent: viewType === ViewType.Percent && valueNumber !== undefined
                    ? valueNumber / HUNDRED_PCT * (this.props.resources.byId[_.resource.id].attributes.MaxUnitsPct ?? 0) / HUNDRED_PCT
                    * (HUNDRED_PCT - (this.props.resources.byId[_.resource.id].attributes.NonProjectWorkPct ?? 0)) / HUNDRED_PCT
                    : undefined,
                hours: viewType === ViewType.Hours ? valueNumber : undefined
            })).filter(notUndefined);
        this.props.resourceActions.updateUsage(this.props.entityType, usage, convertToDateOnlyTimeframe(this.state.timeframe), isActual);
    }

    private _getUsages = (value: number | true, timeType?: ViewType, cells?: ISelectableCellData[]): ResourcesListStore.ResourceUsageUpdate[] => {
        const auto = value === true ? value : undefined;
        const hoursNumber = value === true ? undefined : value;
        return (cells ?? this._cellSelection.getSelection() as ISelectableCellData[])
            .map<ResourcesListStore.ResourceUsageUpdate>(_ => {
                const { resourceId, entityId } = this.props.extractCellIds(_);
                return {
                    resourceId: resourceId,
                    entityId: entityId,
                    startDate: _.start,
                    finishDate: _.finish.getBeginOfDay(),
                    auto,
                    fte: timeType === ViewType.FTE ? hoursNumber : undefined,
                    percent: timeType === ViewType.Percent && hoursNumber !== undefined
                        ? hoursNumber / HUNDRED_PCT * (this.props.resources.byId[resourceId].attributes.MaxUnitsPct ?? 0) / HUNDRED_PCT
                        * (HUNDRED_PCT - (this.props.resources.byId[resourceId].attributes.NonProjectWorkPct ?? 0)) / HUNDRED_PCT
                        : undefined,
                    hours: timeType === ViewType.Hours ? hoursNumber : undefined
                };
            });
    }

    private _buildListProps = (): IListProps & Partial<ITimelineProps> => {
        return {
            ...this.props.listProps,
            scaleMultiplier: this.props.timeType.length + SCALE_MULTIPLIER_PER_TIMETYPE_COLUMN,
            fields: [
                ...this.props.listProps.fields,
                bookingTypeField,
                chargeRateField,
                billingCodeField,
                estimatedCostField,
                estimatedChargeField,
                totalPlannedField,
                totalActualField,
                actualCostField
            ],
            isFieldFake: (field: Field) => this.props.listProps.isFieldFake?.(field) || false,
            onItemRender: (entity: IExtensibleEntity, index: number, field: Field, defaultRender) => {
                if (field.id === bookingTypeField.id) {
                    const { resource, entityId, isTotalRow } = this._extractRowInfo(entity);
                    return isTotalRow ? <></> : <ResourceBookingType resource={resource} entityId={entityId} />;
                }

                if (field.id === billingCodeField.id) {
                    const { resource, entityId, isTotalRow } = this._extractRowInfo(entity);
                    return isTotalRow ? <></> : <ResourceBillingCode resource={resource} entityId={entityId} />;
                }

                const { user, extractRowIds, resources, listProps: { onItemRender } } = this.props;
                const { entityIds, resourceIds, isTotalRow } = extractRowIds(entity);
                const calculatedFieldProps: BaseCalculatedFieldFormatterProps = {
                    user,
                    resources,
                    entityIds,
                    resourceIds,
                    className: isTotalRow ? 'usage-total' : undefined
                };

                if (!isTotalRow && field.id === chargeRateField.id) {
                    return <CalculatedFieldFormatter calculatedField={chargeRateField} formatType={FormatType.Cost} {...calculatedFieldProps} />
                }
                if (field.id === estimatedCostField.id) {
                    return <CalculatedFieldFormatter calculatedField={estimatedCostField} formatType={FormatType.Cost} {...calculatedFieldProps} />
                }
                if (field.id === estimatedChargeField.id) {
                    return <CalculatedFieldFormatter calculatedField={estimatedChargeField} formatType={FormatType.Cost} {...calculatedFieldProps} />
                }
                if (field.id === totalPlannedField.id) {
                    return <CalculatedFieldFormatter calculatedField={totalPlannedField} formatType={FormatType.Duration} {...calculatedFieldProps} />
                }
                if (field.id === totalActualField.id) {
                    return <CalculatedFieldFormatter calculatedField={totalActualField} formatType={FormatType.Duration} {...calculatedFieldProps} />
                }
                if (field.id === actualCostField.id) {
                    return <CalculatedFieldFormatter calculatedField={actualCostField} formatType={FormatType.Cost} {...calculatedFieldProps} />
                }

                return onItemRender ? onItemRender(entity, index, field, defaultRender) : defaultRender();
            },
            extractEntityForSorting: (row: IRow, orderBy: IOrderBy | IOrderBy[] | undefined) => {
                const arr = Array.isArray(orderBy) ? orderBy : orderBy ? [orderBy] : [];
                const { extractRowIds, resources } = this.props;
                let result = row.entity;
                [
                    bookingTypeField,
                    chargeRateField,
                    billingCodeField,
                    estimatedCostField,
                    estimatedChargeField,
                    totalPlannedField,
                    totalActualField,
                    actualCostField
                ].forEach(extendWithCalculatedField);

                return result;

                function extendWithCalculatedField(field: CalculatedField) {
                    if (!arr.find(_ => _.fieldName === field.name)) {
                        return;
                    }
                    const rowIds = extractRowIds(row.entity);
                    result = {
                        ...result,
                        attributes: {
                            ...result.attributes,
                            [field.name]: field.calculate(rowIds.resourceIds.map(_ => resources.byId[_]).filter(notUndefined), rowIds.entityIds)
                        }
                    };
                }
            }
        };
    }

    private _buildMenuProps = (isEditable: ((entity: IExtensibleEntity) => boolean) | undefined) : ListMenuProps => {
        const { menuProps, selectedEntityIds } = this.props;
        const selectedEntities = this.props.listProps.selection!.getSelection() as IExtensibleEntity[];
        let newMenuProps: ListMenuProps = {
            ...menuProps,
            commands: menuProps.commands,
            selectionMode: menuProps.selectionMode
                ? {
                    ...menuProps.selectionMode,
                    items: isEditable
                        ? [
                            this._buildAllocateCommand(),
                            this._buildSetActualCommand(),
                            this._buildShiftAllocationCommand(),
                            this._buildSetRateCommandIfCanManageBudget(),
                            buildSetBillingCodeCommand(this._setBillingCode),
                            this._buildCommitCommand(selectedEntities),
                            this._buildUncommitCommand(selectedEntities),
                            ...menuProps.selectionMode.items,
                        ].filter(notUndefined)
                        : menuProps.selectionMode.items
                }
                : undefined
        };

        if ('fields' in this.props.menuProps) {
            newMenuProps = {
                ...newMenuProps,
                fields: [
                    ...(newMenuProps as { fields: Field[]; }).fields,
                    bookingTypeField,
                    chargeRateField,
                    billingCodeField,
                    estimatedCostField,
                    estimatedChargeField,
                    totalPlannedField,
                    totalActualField,
                    actualCostField
                ]
            } as ListMenuProps;
        }
        return newMenuProps;
    }

    private _buildShiftAllocationCommand() {
        return !!this._getShiftingRows().length ? {
            key: 'shift',
            text: 'Shift Allocation',
            iconProps: { iconName: 'Switch' },
            onClick: () => this.setState({ isShiftPlanPanelOpen: true }),
        } : undefined;
    }

    private _buildSetActualCommand() {
        return !this.props.actualTimeDisabled ? {
            key: 'setActual',
            text: 'Set Actual',
            iconProps: { iconName: 'UserGauge' },
            onClick: () => this.setState({ isSetActualPanelOpen: true })
        } : undefined;
    }

    private _buildAllocateCommand() {
        return {
            key: 'allocate',
            text: 'Allocate',
            iconProps: { iconName: 'UserGauge' },
            onClick: () => this.setState({ isAllocatePanelOpen: true })
        };
    }

    private _buildCommitUncommitCommand(item: IExtensibleEntity) {
        const isCommitted = this._isCommitted(item);
        return !this.props.commitDisabled ? {
            key: 'commit',
            name: isCommitted ? 'Uncommit' : 'Commit',
            iconProps: { iconName: 'Commitments' },
            onClick: () => this.setState({ isCommitting: isCommitted ? CommittingType.Uncommit : CommittingType.Commit })
        } : undefined;
    }

    private _buildCommitCommand(selectedEntities: IExtensibleEntity[]) {
        return (!this.props.commitDisabled && selectedEntities.some(_ => !this._isCommitted(_))) ? {
            key: 'commit',
            name: 'Commit',
            iconProps: { iconName: 'Commitments' },
            onClick: () => this.setState({ isCommitting: CommittingType.Commit })
        } : undefined;
    }

    private _buildUncommitCommand(selectedEntities: IExtensibleEntity[]) {
        return (!this.props.commitDisabled && selectedEntities.some(_ => this._isCommitted(_))) ? {
            key: 'uncommit',
            name: 'Uncommit',
            iconProps: { iconName: 'Commitments' },
            onClick: () => this.setState({ isCommitting: CommittingType.Uncommit })
        } : undefined;
    }

    private _buildSetRateCommandIfCanManageBudget(): IContextualMenuItem | undefined {
        const canManageBudget = contains(this.props.user.permissions.common, CommonOperations.BudgetManage);
        return canManageBudget ? buildSetRateCommand(this._setRate) : undefined;
    }

    private _getCellsBySelectedRows(): ISelectableCellData[] | undefined {
        const cells = (this._cellSelection.getItems() as ISelectableCellData[]);
        return this.props.selectedEntityIds
            ?.map(_ => Object.keys(this.state.cellIndexMap[_] || {})
                .map(__ => this.state.cellIndexMap[_][__]))
            .reduce((prev, cur) => ([...prev, ...cur]), [])
            .map(__ => cells.find(_ => _.key === __)!);
    }

    private _buildCellIndexMap(entitiesIds: string[], scale: IScale): { map: CellIndexesByRowByDateMap, endToEndItems: ISelectableCellData[] } {
        return entitiesIds.map((entityId, rowIndex) =>
        ({
            entityId,
            datesWithIndex: scale.dates.map((_, index) => ({ date: _, endToEndIndex: rowIndex * scale.dates.length + index }))
        }))
            .reduce((prev, cur) => ({
                map: {
                    ...prev.map,
                    [cur.entityId]: cur.datesWithIndex.reduce((_prev, _cur) => ({ ..._prev, [_cur.date.start.getTime()]: _cur.endToEndIndex }), {})
                },
                endToEndItems: [
                    ...prev.endToEndItems,
                    ...cur.datesWithIndex.map<ISelectableCellData>(_ => ({ key: _.endToEndIndex, entityId: cur.entityId, start: _.date.start, finish: _.date.finish }))]
            }), { map: {}, endToEndItems: [] });
    }

    private _navigateToCellByIndex = (idx: number, preserveSelection: boolean) => {
        this._cellByIndexMap[idx]?.focus();
        this._selectCellByIndex(idx, preserveSelection);
    }

    private _rectangleStartPosition?: number;
    private _prevRectangleSelectedIdx?: number[]

    private _clearRectangle = () => {
        this._rectangleStartPosition = undefined;
        this._prevRectangleSelectedIdx = undefined;
    }

    private _selectRectangle = (position: number, prevPosition: number, itemsInRow: number) => {
        if (this._cellPositionToIndexMap[position] !== undefined) {
            this._cellByIndexMap[this._cellPositionToIndexMap[position]!]?.focus();
        }

        if (this._rectangleStartPosition === undefined) {
            this._rectangleStartPosition = prevPosition;
        }
        const indexes = this._calculateRectangle(position, this._rectangleStartPosition, itemsInRow)
            .map(_ => this._cellPositionToIndexMap[_])
            .filter(notUndefined);

        this._cellSelection.setChangeEvents(false);
        this._prevRectangleSelectedIdx?.forEach(_ => {
            this._cellSelection.setIndexSelected(_, false, true);
        });
        this._prevRectangleSelectedIdx = [];
        indexes.forEach(_ => {
            if (!this._cellSelection.isIndexSelected(_)) {
                this._prevRectangleSelectedIdx?.push(_);
                this._cellSelection.setIndexSelected(_, true, true);
            }
        });
        this._cellSelection.setChangeEvents(true);
    }

    private _calculateRectangle = (positionStart: number, positionEnd: number, itemsInRow: number): number[] => {
        const result: number[] = [];

        const leftTopCorner = Math.floor(Math.min(positionStart, positionEnd) / itemsInRow) * itemsInRow + Math.min(positionEnd % itemsInRow, positionStart % itemsInRow);
        const rowsCount = Math.abs(Math.floor(positionEnd / itemsInRow) - Math.floor(positionStart / itemsInRow)) + 1;
        const columnsCount = Math.abs(positionEnd % itemsInRow - positionStart % itemsInRow) + 1;

        for (let i = 0; i < rowsCount; i++) {
            for (let j = 0; j < columnsCount; j++) {
                result.push(leftTopCorner + i * itemsInRow + j)
            }
        }

        return result;
    }

    private _selectCellByIndex = (idx: number, preserveSelection: boolean) => {
        this._cellSelection.setChangeEvents(false);
        const select = !this._cellSelection.isIndexSelected(idx);
        if (!preserveSelection) {
            this._cellSelection.setAllSelected(false);
        }
        this._cellSelection.setIndexSelected(idx, select, true);
        this._cellSelection.setChangeEvents(true);
    }


    private _extractRowInfo = (entity: IExtensibleEntity) => {
        const { entityIds, resourceIds, isTotalRow } = this.props.extractRowIds(entity);
        const resource = this.props.resources.byId[resourceIds[0]];
        const entityId = entityIds[0];
        return { resource, entityId, isTotalRow };
    }
}

function extractParentToChildrenMap(ownProps: OwnProps, state: ApplicationState) {
    const parentToChildrenMap = {};
    if (ownProps.entityType === EntityType.Portfolio) {
        state.portfolios.allIds.forEach(portfolioId => {
            const projectsSet = new Set(state.portfolios.byId[portfolioId]?.projectIds);
            const programsSet = new Set(state.portfolios.byId[portfolioId]?.programIds);
            state.portfolios.byId[portfolioId]?.programIds.forEach(programId => {
                state.programs.byId[programId]?.projectIds.forEach(projectId => projectsSet.add(projectId));
            });

            parentToChildrenMap[portfolioId] = [
                ...Array.from(programsSet).map(_ => ({ id: _, name: state.programs.byId[_]?.attributes.Name })),
                ...Array.from(projectsSet).map(_ => ({ id: _, name: state.projectsList.byId[_]?.attributes.Name }))
            ];
        });
    }
    if (ownProps.entityType === EntityType.Program) {
        state.programs.allIds.forEach(programId => {
            parentToChildrenMap[programId] = state.programs.byId[programId].projectIds.map(projectId =>
                ({ id: projectId, name: state.projectsList.byId[projectId]?.attributes.Name })) ?? [];
        });
    }
    return parentToChildrenMap;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const parentToChildrenMap = extractParentToChildrenMap(ownProps, state);

    return {
        user: state.user,
        resources: state.resources,
        parentToChildrenMap
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        resourceActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch),
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, mergeActionCreators)(ResourceUsageGrid));

export type ViewAssignmentsAction = (cells?: ISelectableCellData[]) => void;

const CellMenu = (props: {
    cell: ISelectableCellData | undefined, quantization: Quantization, setPlan: SetTimeAction, setActual?: SetTimeAction,
    viewAssignments?: ViewAssignmentsAction
}): JSX.Element => {
    const { cell, quantization, setPlan, setActual, viewAssignments } = props;
    const [mode, setMode] = React.useState<undefined | 'plan' | 'actual'>(undefined);

    const action = mode === 'plan' ? setPlan : mode === 'actual' ? setActual : undefined;
    const text = mode === 'plan' ? 'Allocated' : 'Actual';

    const buildSetTimeBtn = (timeLabel: string, btnAction: () => void): ICommandBarItemProps => ({
        key: timeLabel,
        onRender: () => <CommandBarButton iconProps={{ iconName: 'UserGauge' }} text={timeLabel} onClick={btnAction}
            onRenderText={(_, defaultRender) => <div style={{ display: 'flex' }}>{defaultRender?.(_)}<Icon iconName="ChevronRight" />
            </div>} />
    });

    return <CommandBar className="header-command-bar" items={mode === undefined
        ? [
            buildSetTimeBtn('Allocate', () => setMode('plan')),
            setActual ? buildSetTimeBtn('Set Actual', () => setMode('actual')) : undefined,
            viewAssignments ? {
                key: 'viewAssigments',
                onRender: () => <CommandBarButton iconProps={{ iconName: 'PPMXSectionActionItems' }} text="View Assignments" onClick={() => viewAssignments(cell && [cell])} />
            } : undefined
        ].filter(notUndefined)
        : [
            { key: 'back', iconOnly: true, iconProps: { iconName: 'Back' }, onClick: () => setMode(undefined) },
            buildAutoAllocateCommand(cell && [cell], action!, text),
            buildAllocateCommands(ViewType.Hours, cell && [cell], quantization, action!, text)!,
            buildAllocateCommands(ViewType.Percent, cell && [cell], quantization, action!, text)!,
            buildAllocateCommands(ViewType.FTE, cell && [cell], quantization, action!, text)!
        ]} styles={{ root: { padding: "unset", minWidth: 440 } }} />;
}

type ResourceUsageCellProps = {
    resource: ResourcesListStore.Resource;
    entityId: string;
};

export const ResourceBookingType = (props: ResourceUsageCellProps) => {
    const committed = bookingTypeField.calculate([props.resource], [props.entityId]);
    return committed !== undefined
        ? <div className="resource-type">
            <div className={`align-center status ${committed ? "Green" : "Gray"}`}>
                {committed ? "Committed" : "Proposed"}
            </div>
        </div>
        : null;
}

const ResourceBillingCode = (props: ResourceUsageCellProps) => {
    const billingCode = billingCodeField.calculate([props.resource], [props.entityId]);
    return billingCode !== undefined ? <TextFormatter value={billingCode} /> : null;
}

export const onScaleChanged = (resourceIds: string[], callback: (resourceIds: string[], timeframe: IScalableTimeframe) => void,
    timelineChange: TimelineChange): void => {
    const { prevState, newState, origin, prevOrigin } = timelineChange;

    const newTimeline = {
        start: origin?.timeframe?.start || newState.scale.dates[0].start,
        end: origin?.timeframe?.end || newState.scale.dates[newState.scale.dates.length - 1].finish
    };

    const prevTimeline = prevState
        ? {
            start: prevOrigin?.timeframe?.start || prevState.scale.dates[0].start,
            end: prevOrigin?.timeframe?.end || prevState.scale.dates[prevState.scale.dates.length - 1].finish
        }
        : undefined;

    if (!prevTimeline
        || newTimeline.start.getTime() !== prevTimeline.start.getTime()
        || newTimeline.end.getTime() !== prevTimeline.end.getTime()) {
            callback(resourceIds, convertToDateOnlyTimeframe(newTimeline));
        }
}

export const convertToDateOnlyTimeframe = (timeframe: ITimeframe): IScalableTimeframe => ({
    startDate: timeframe.start.toDateOnlyString(),
    endDate: timeframe.end.toDateOnlyString()
})