import * as React from 'react';
import { IconButton, Selection, IContextualMenuItem, DirectionalHint, IObjectWithKey } from 'office-ui-fabric-react';

export interface RowMenuProps {
    commands: IContextualMenuItem[];
    item?: any;
    selection?: Selection;
    renderOnlyMenu?: boolean;
}

export const RowMenu = (props: RowMenuProps) => {
    const { selection, commands, item, renderOnlyMenu } = props;
    const menu = <IconButton
        onMenuClick={() => {
            if (!selection)
                return;

            const itemKey = selection.getKey((item as IObjectWithKey));

            if (!selection.isKeySelected(itemKey)) {
                selection.setAllSelected(false);
                selection.setKeySelected(itemKey, true, true);
            }
        }}
        menuIconProps={{ iconName: 'PPMXMore' }}
        menuProps={{
            directionalHint: DirectionalHint.bottomRightEdge,
            items: commands
        }}
    />;
    return commands.length ?
        renderOnlyMenu
            ? <div className="menu">{menu}</div>
            : menu
        : <></>;
}