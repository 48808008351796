import React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { TimeTrackingAdministrativeCategory } from '../../store/Tenant';
import EntityName from '../views/list/columns/EntityName';
import { Icon } from 'office-ui-fabric-react';

type OwnProps = {
    categoryId: string;
    onClick?: () => void;
}

type StateProps = {
    category?: TimeTrackingAdministrativeCategory;
}

type Props = OwnProps & StateProps;

function TimeTrackingAdministrativeCategoryEntityName(props: Props) {

    const { category, categoryId, onClick } = props;
 
    return (
        <EntityName
            name={category?.title ?? categoryId}
            isTimelineView={true}
            onClick={onClick}
            className={`administrative-time-name ${onClick && "hover-underline"}`}

            renderLogo={() => <div className={`logo administrative-time-logo ${category && !category.isEnabled && "inactive"}`} style={{ backgroundColor: `${category?.color}29`}}>
                <Icon iconName='Clock' style={{color: category?.color}} />
            </div>}
        />
    );
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps { 
    return {
        category: state.tenant.timeTracking.administrativeCategories.find(_ => _.id === ownProps.categoryId),
    };
}

export default connect(mapStateToProps)(TimeTrackingAdministrativeCategoryEntityName);
