import * as React from 'react';
import { IDropdownOption } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import { IFormInputComponent } from "../interfaces/IFormInputComponent";

type ToggleOptionInputProps = IFormInputProps<string | number>

export default class ToggleOptionInput extends React.Component<ToggleOptionInputProps> implements IFormInputComponent {
    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    public render(): JSX.Element {
        const { inputProps, disabled, readOnly, className } = this.props;
        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;
        const options: IDropdownOption[] = inputProps?.options || [];
        return (
            <div className={`${className || ''} toggle-option-selection`}>
                {
                    options.map(_ => <div key={_.key}
                        className={`align-center option ${isReadOnly || isDisabled ? '' : 'clickable'} ${_.data?.className || ''} ${_.key == this.props.value ? (`${_.data?.activeClassName || ''} active`) : ""}`}
                        onClick={isReadOnly || isDisabled ? undefined : () => this._onChange(_)}>
                        <span className="label status-label">{_.text}</span>
                    </div>)
                }
            </div>
        );
    }

    private _onChange = (opt: IDropdownOption) => {
        this.props.onChanged?.(opt.key);
        this.props.onEditComplete?.(opt.key);
    }

    focus(): void {}
}