import { Action, Reducer } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TimeState {
    ppmxToken: string;
    portalUrl: string;
}

const INIT_STATE = {
    ppmxToken: '',
    portalUrl: '',
}

export enum ActionTypes {
    UPDATE_TIME = 'UPDATE_TIME'
}

type UpdateTimeAction = { type: ActionTypes.UPDATE_TIME, time: TimeState }
export type TimeActions = UpdateTimeAction;

export const reducer: Reducer<TimeState> = (state: TimeState = INIT_STATE, incomingAction: Action) => {
    const action = incomingAction as TimeActions;
    switch (action.type) {
        case ActionTypes.UPDATE_TIME: return action.time;
        //default:
        //    const exhaustiveCheck: never = action;
    }
    return state;
};