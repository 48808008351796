import { Dictionary, IWithDuration } from "../../entities/common";
import { CalendarDataSet } from '../../store/CalendarStore';
import { getWorkingDaysBetweenDates } from '../common/timeline/utils';
import { nameof } from '../../store/services/metadataService';
import { toDate } from "./common";
import { DictatedDates, LoadedTask, TaskScheduleCalculator } from "../task/Fields";
import { IPredecessorInfo, ITaskAttrs } from "../../entities/Subentities";

export function applyPredecessors(data: IWithDuration, predecessors: IPredecessorInfo[] | undefined, tasksMap: Dictionary<LoadedTask>, calendar: CalendarDataSet)
    : Partial<IWithDuration> | undefined {
    const dictatedDates = TaskScheduleCalculator.GetDictatedDates(predecessors, tasksMap, calendar);

    if (dictatedDates.startDate && dictatedDates.dueDate && data.Duration) {
        const dictatedDueDateByStartDateAndDuration = calculateDueDate(dictatedDates.startDate, data.Duration, calendar);
        if (dictatedDueDateByStartDateAndDuration >= dictatedDates.dueDate) {
            dictatedDates.dueDate = dictatedDueDateByStartDateAndDuration;
        }
        else {
            dictatedDates.startDate = calculateStartDate(dictatedDates.dueDate, data.Duration, calendar);
        }
    }

    if (!!dictatedDates?.startDate || !!dictatedDates?.dueDate) {
        const patch = {};
        if (dictatedDates?.startDate) {
            patch[nameof<ITaskAttrs>("StartDate")] = dictatedDates.startDate;
        }
        if (dictatedDates?.dueDate) {
            patch[nameof<ITaskAttrs>("DueDate")] = dictatedDates.dueDate;
        }
        return { ...patch, ...handleDuration(data, patch, calendar, true) };
    }
}

export function handleDuration(data: IWithDuration, update: Partial<IWithDuration>, calendar: CalendarDataSet, preserveDuration?: boolean,
    dictatedDates?: DictatedDates): Partial<IWithDuration> {
    const extra: Partial<IWithDuration> = {};
    if (nameof<IWithDuration>("StartDate") in update) {
        const newStartDate = toDate(update.StartDate);
        const newDueDate = toDate(update.DueDate);
        const dueDate = toDate(data.DueDate);
        const durationDays = data.Duration;
        if (!newStartDate) {
            extra.Duration = null;
        } else if (newDueDate) {
            extra.Duration = getWorkingDaysBetweenDates(newStartDate, newDueDate, calendar);
        } else if (durationDays !== undefined && durationDays !== null && (preserveDuration || !dueDate)) {
            extra.DueDate = calculateDueDate(newStartDate, durationDays, calendar).toDateOnlyString();
        } else if (dueDate) {
            extra.Duration = getWorkingDaysBetweenDates(newStartDate, dueDate, calendar);
        }
        return extra;
    }
    if (nameof<IWithDuration>("DueDate") in update) {
        const startDate = toDate(data.StartDate);
        const newDueDate = toDate(update.DueDate);
        const durationDays = data.Duration;
        if (!newDueDate) {
            extra.Duration = null;
        } else if (durationDays !== undefined && durationDays !== null && (preserveDuration || !startDate)) {
            extra.StartDate = calculateStartDate(newDueDate, durationDays, calendar).toDateOnlyString();
        } else if (startDate) {
            extra.Duration = getWorkingDaysBetweenDates(startDate, newDueDate, calendar);
        }
        return extra;
    }
    if (nameof<IWithDuration>("Duration") in update) {
        const startDate = toDate(data.StartDate);
        const dueDate = toDate(data.DueDate);
        const durationDays = data.Duration;
        const newDurationDays = update.Duration;
        if (newDurationDays === undefined || newDurationDays === null) {
            if (startDate && dictatedDates?.dueDate && !dictatedDates?.startDate) {
                extra.StartDate = null;
            } else if (startDate) {
                extra.DueDate = null;
            }
        } else if (!startDate && !dueDate) {
            return extra;
        } else if (startDate && !dictatedDates?.dueDate) {
            extra.DueDate = calculateDueDate(startDate, newDurationDays, calendar).toDateOnlyString();
        } else if (dueDate && !dictatedDates?.startDate) {
            extra.StartDate = calculateStartDate(dueDate, newDurationDays, calendar).toDateOnlyString();
        } else if (startDate && dueDate && dictatedDates?.startDate && dictatedDates?.dueDate) {
            const oldDuration = durationDays ?? getWorkingDaysBetweenDates(startDate, dueDate, calendar);
            if (newDurationDays > oldDuration) {
                shiftStartDate(dueDate, newDurationDays, dictatedDates.startDate, calendar, extra);
            } else {
                shiftDueDate(startDate, newDurationDays, dictatedDates.dueDate, calendar, extra);
            }
        } else if (dueDate && dictatedDates?.startDate) {
            shiftStartDate(dueDate, newDurationDays, dictatedDates.startDate, calendar, extra);
        } else if (startDate && dictatedDates?.dueDate) {
            shiftDueDate(startDate, newDurationDays, dictatedDates.dueDate, calendar, extra);
        } 
    }
    return extra;
}

function shiftDueDate(startDate: Date, newDurationDays: number, dictatedDueDate: Date, calendar: CalendarDataSet, extra: Partial<IWithDuration>) {
    const newDueDate = calculateDueDate(startDate, newDurationDays, calendar);
    if (newDueDate < dictatedDueDate) {
        extra.StartDate = calculateStartDate(dictatedDueDate, newDurationDays, calendar).toDateOnlyString();
        extra.DueDate = dictatedDueDate;
    } else {
        extra.DueDate = newDueDate;
    }
}

function shiftStartDate(dueDate: Date, newDurationDays: number, dictatedStartDate: Date, calendar: CalendarDataSet, extra: Partial<IWithDuration>) {
    const newStartDate = calculateStartDate(dueDate, newDurationDays, calendar);
    if (newStartDate < dictatedStartDate) {
        extra.StartDate = dictatedStartDate;
        extra.DueDate = calculateDueDate(dictatedStartDate, newDurationDays, calendar).toDateOnlyString();
    } else {
        extra.StartDate = newStartDate;
    }
}

export function calculateDueDate(startDate: Date, durationDays: number, calendar: CalendarDataSet): Date {
    durationDays = Math.ceil(Math.abs(durationDays));
    return startDate.clone().addWorkingDays(
        durationDays
            ? startDate.isWorkingDay(calendar)
                ? durationDays - 1
                : durationDays
            : 0, calendar);
}

export function calculateStartDate(dueDate: Date, durationDays: number, calendar: CalendarDataSet): Date {
    durationDays = Math.ceil(Math.abs(durationDays));
    return dueDate.clone().addWorkingDays(
        durationDays
            ? dueDate.isWorkingDay(calendar)
                ? -1 * (durationDays - 1)
                : -1 * durationDays
            : 0, calendar);
}