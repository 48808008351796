import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as CalendarStore from '../../store/CalendarStore';
import { DetailsSpinner } from '../common/Spinner';
import { CommonOperations, contains } from '../../store/permissions';
import SettingsPage, { Section } from './SettingsPage';
import { WorkWeekSettings } from './resourceManagement/WorkWeekSettings';
import { CalendarExceptionsList } from './resourceManagement/CalendarExceptionsList';
import * as UserStore from '../../store/User';
import * as ResourcesListStore from '../../store/ResourcesListStore';
import { SectionControlPlaceholder } from '../common/sectionsControl/SectionPlaceholder';
import WorkWeek from './resourceManagement/WorkWeek';
import Link from '../common/Link';

type StateProps = {
    globalCalendar: CalendarStore.CalendarState;
    resource?: ResourcesListStore.Resource;
    user: UserStore.UserState;
    isResourceUpdating: boolean;
    isLoading: boolean;
};

type ActionProps = {
    globalCalendarActions: typeof CalendarStore.actionCreators;
    userActions: typeof UserStore.actionCreators;
    resourcesActions: typeof ResourcesListStore.actionCreators;
}

type Props = StateProps & ActionProps;

const CalendarSettings = (props: Props) => {

    const { resource, resourcesActions, isResourceUpdating, user } = props;
    const currentResourceId = user.resource?.id;
    const permissions = user.permissions;

    React.useEffect(() => {
        props.globalCalendarActions.load();
        props.userActions.loadResource();
    }, []);

    React.useEffect(() => {
        if (currentResourceId) {
            resourcesActions.loadResource(currentResourceId);
        }
    }, [currentResourceId]);

    const noResource = <SectionControlPlaceholder
        key="no-resource"
        iconName="Error"
        title="Resource not found">
        <div className="align-center">
            <div>
                The resource associated with the current user is not found. Please refer to <Link href="https://help.ppm.express/89502-ppm-express-how-to-articles/2423281" target="_blank">this article</Link> for more details.
            </div>
        </div>
    </SectionControlPlaceholder>

    const sections: Section[] = [
        {
            key: "globalWorkWeekSettings",
            name: "Global work week",
            icon: "CalendarWorkWeek",
            tooltip: <div>Define the number of working hours per day at the organizational level.Global Work Week settings are used to calculate resource capacity (unless personal work week settings are applied to a resource) and are considered in PPM Express tasks.Please note that managing this setting requires the 'Manage Resources' permission.<br />
                Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/621569-calendar" target="_blank">article</Link> for more details.</div>,
            onRenderBody: () => <div>
                <WorkWeekSettings
                    disabled={!contains(permissions.common, CommonOperations.ResourceManage)}
                    workDayExpectedHrs={props.globalCalendar.workDayExpectedHrs}
                    save={workDayExpectedHrs => props.globalCalendarActions.save({ workDayExpectedHrs })}
                /></div>
        },
        {
            key: "globalExceptionsList",
            name: "Global Calendar Exceptions",
            icon: "PrimaryCalendar",
            className: "with-grid",
            tooltip: <div>Define calendar exceptions (national/international holidays, company holidays, etc.) to ensure that work is not scheduled on days when your organization is not operating. Please note that managing this setting requires the 'Manage Resources' permission.<br />
                Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/621569-calendar" target="_blank">article</Link> for more details.</div>,
            onRenderBody: () => <div>
                <CalendarExceptionsList
                    disabled={!contains(permissions.common, CommonOperations.ResourceManage)}
                    isUpdating={!!props.globalCalendar.updating}
                    exceptions={props.globalCalendar.exceptions}
                    save={model => props.globalCalendarActions.save(model)}
                    onExportToFile={props.globalCalendarActions.exportExceptionsToFile}
                    onImportFromFile={props.globalCalendarActions.importExceptionsFromFile}
                />
            </div>
        },
        {
            key: "myWorkWeekSettings",
            name: "My work week",
            icon: "CalendarWorkWeek",
            tooltip: <div>Specify personal work week settings for this resource. If defined, they will override the global work week settings. Please note that managing this setting requires the 'Manage Resources' permission.<br />
                Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/621569-calendar" target="_blank">article</Link> for more details.</div>,
            onRenderBody: () => <div>
                {user.hasResource === false ? noResource : resource ? <WorkWeek resource={resource} /> : <></>}
            </div>
        },
        {
            key: "myExceptionsList",
            name: "My Calendar Exceptions",
            icon: "PrimaryCalendar",
            className: "with-grid",
            tooltip: <div>Specify calendar exceptions for this resource (vacation, sickness leave, etc.). If calendar exceptions are set for the same date as global calendar exceptions, they will take precedence over the tenant-wide settings for this resource.<br />
                Please refer to the <Link href="https://help.ppm.express/89495-ppm-express-settings/621569-calendar" target="_blank">article</Link> for more details.</div>,
            onRenderBody: () =>
                <div>
                    {user.hasResource === false ? noResource : resource ? <CalendarExceptionsList
                        isUpdating={isResourceUpdating}
                        exceptions={resource.usage?.calendar.exceptions || []}
                        save={model => resourcesActions.updateResourceCalendar(resource.id, model)}
                        onExportToFile={(fields, ids) => resourcesActions.exportExceptionsToFile(resource.id, fields, ids)}
                        onImportFromFile={(file) => resourcesActions.importExceptionsFromFile(resource.id, file)}
                    /> : <></>}
                </div>
        }];

    return <DetailsSpinner isLoading={props.isLoading}>
        {!props.isLoading && <SettingsPage title="Calendar Settings" sections={sections} />}
    </DetailsSpinner>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        globalCalendar: state.calendar,
        user: state.user,
        resource: state.resources.activeEntity,
        isResourceUpdating: state.resources.isLoadingUsage,
        isLoading: state.user.hasResource === undefined || state.calendar.isLoading
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(UserStore.actionCreators, dispatch),
        resourcesActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch),
        globalCalendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(CalendarSettings);