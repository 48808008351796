import "./ConnectionsList.css";
import * as React from "react";
import { ActionButton, DirectionalHint, TooltipDelay, TooltipHost } from "office-ui-fabric-react";
import { IConnectionInfo } from "../../../entities/Metadata";
import { CommonOperations, contains } from "../../../store/permissions";
import { connect } from "react-redux";
import ConnectionRemoveDialog from "../ConnectionRemoveDialog";
import { SourceType } from "../../../store/ExternalEpmConnectStore";
import { inIframe } from '../../utils/common';
import { ApplicationState } from "../../../store";
import ConnectionBox from "../ConnectionBox";

type OwnProps = {
    disabled: boolean;
    sourceType: SourceType,
    connections: IConnectionInfo[];
    onConnectionSelect: (connection: IConnectionInfo) => void;
    onConnectionRemove: (connectionId: string) => void;
    onConnectionRename: (connectionId: string, title: string) => void;
    onEditClick: (connectionId: string) => void;

    grantPermissionsText?: string;
    onGrantPermissionsClick?: () => void;

    hasUpgradePremissions?: (connectionId: string) => boolean;
    onUpgradePremissionsClick?: (connectionId: string) => void;

    newConnectionTooltip?: JSX.Element;
    onNewConnectionClick: () => void;

    newRestrictedConnectionTooltip?: JSX.Element;
    onNewRestrictedConnectionClick?: () => void;
    
    newAzureADConnectionTooltip?: JSX.Element;
    onNewAzureADConnectionClick?: () => void;
}

type StoreProps = {
    canManage: boolean;
};
type Props = OwnProps & StoreProps;

const ConnectionsList = (props: Props) => {
    const [connectionToRemove, setConnectionToRemove] = React.useState<IConnectionInfo>();
    const isInIframe = inIframe();
    return (
        <div className="connections-list-wrapper">
            <div className="title">Connections</div>
            <div className="description">Select the connection or add a new one</div>
            <div className="connections-list">
                {props.connections.map(_ => <ConnectionBox
                    key={_.id}
                    connection={_}
                    canManage={props.canManage}
                    onEdit={() => props.onEditClick(_.id)}
                    onSelect={() => props.onConnectionSelect(_)}
                    onRename={(newName: string) => props.onConnectionRename(_.id, newName)}
                    onDelete={() => setConnectionToRemove(_)}
                    onUpdrade={props.hasUpgradePremissions?.(_.id) ? () => props.onUpgradePremissionsClick?.(_.id) : undefined}
                />)}
            </div>
            {!isInIframe && props.onNewAzureADConnectionClick 
                && <TooltipHost
                    content={props.newAzureADConnectionTooltip}
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter, isBeakVisible: false, gapSpace: 10 }}
                    className='border'>
                    <ActionButton iconProps={{ iconName: "Add" }} disabled={!props.canManage} onClick={props.onNewAzureADConnectionClick}>
                        Add new Azure Active Directory connection
                    </ActionButton>
                </TooltipHost>}
            {!isInIframe && props.onNewRestrictedConnectionClick
                && <TooltipHost
                    content={props.newRestrictedConnectionTooltip}
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter, isBeakVisible: false, gapSpace: 10 }}
                    className='border'>
                    <ActionButton iconProps={{ iconName: "Add" }} disabled={!props.canManage} onClick={props.onNewRestrictedConnectionClick}>
                        Add new restricted connection
                    </ActionButton>
                </TooltipHost>}
            {!isInIframe &&<TooltipHost
                    content={props.newConnectionTooltip}
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter, isBeakVisible: false, gapSpace: 10 }}
                    className='border'>
                    <ActionButton iconProps={{ iconName: "Add" }} disabled={!props.canManage} onClick={props.onNewConnectionClick}>
                        Add new connection
                    </ActionButton>
                </TooltipHost>}
            {!isInIframe && props.onGrantPermissionsClick && <ActionButton iconProps={{ iconName: "Unlock" }} disabled={!props.canManage} onClick={props.onGrantPermissionsClick}>
                {props.grantPermissionsText || "Grant Permissions"}
            </ActionButton>}
            {connectionToRemove && props.onConnectionRemove
                && <ConnectionRemoveDialog
                    connection={connectionToRemove}
                    sourceType={props.sourceType}
                    onClose={() => setConnectionToRemove(undefined)}
                    onRemove={() => props.onConnectionRemove!(connectionToRemove.id)} />}
        </div>
    );
}
function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        canManage: contains(state.user.permissions.common, CommonOperations.ConnectionManage)
    };
}
export default connect(mapStateToProps)(ConnectionsList);