import { IViewsState, ICardViewState, DEFAULT_LIST_SUBVIEW } from "../views";
import { StoreHelper } from "../services/storeHelper";
import { SortDirection } from "../../entities/Metadata";

export const VIEWS_DEFAULTS: IViewsState = {
    card: undefined as any as ICardViewState,
    list: {
        selectedByDefault: [],
        fakeFields: [],
        subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
        sortBy: {
            fieldName: "Name",
            direction: SortDirection.ASC
        }
    },
    timeline: {
        selectedByDefault: [],
        subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
        sortBy: {
            fieldName: "Name",
            direction: SortDirection.ASC
        }
    }
}