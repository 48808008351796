import * as React from 'react';
import { Panel, PanelType, IPanelProps } from 'office-ui-fabric-react';
import { Dictionary, ISourceInfo, PANEL_CUSTOM_WIDTH } from "../../entities/common";
import WorkTogetherTab from "../integration/WorkTogetherTab";
import { SourceType } from "../../store/ExternalEpmConnectStore";
import { ApplicationState } from '../../store';
import { Integrations } from '../../store/Tenant';
import { connect } from 'react-redux';
import CalendlyPanel from '../common/CalendlyPanel';

type OwnProps = {
    readonly: boolean;
    actions: Dictionary<any>;
    sourceInfos: ISourceInfo[]; 
    onDismiss: () => void;
    visibleTypes?: SourceType[];
}
type StateProps = { integrations: Integrations; }
type Props = OwnProps & StateProps;

type State = {
    selectedTab: string;
    selectedType?: SourceType;
    context?: any;
    isCalendlyPanelOpen?: boolean;
}

export class ExternalEpmConnectControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { selectedTab: "link", selectedType: props.visibleTypes?.find(props.integrations.isEnabled) }
    }

    public render() {
        return this.state.isCalendlyPanelOpen ? this._renderCalendlyPanel() : this._renderConfigureConnection();
    }

    private _onRenderHeader = (props?: IPanelProps): JSX.Element | null => {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Configure Connections</p>
            <div className='ms-Panel-secondaryText'>Connect to external systems to setup collaboration process.</div>
        </div>;
    }

    private _renderCalendlyPanel = (): JSX.Element => {
        return <CalendlyPanel onDismiss={() => this.setState({ isCalendlyPanelOpen: false })}> </CalendlyPanel>
    }

    private _renderConfigureConnection = (): JSX.Element => {
        return <Panel
            isLightDismiss
            type={PanelType.custom}
            customWidth={PANEL_CUSTOM_WIDTH}
            className="connect-panel"
            isOpen={true}
            onDismiss={this.props.onDismiss}
            onRenderHeader={this._onRenderHeader}>
            <WorkTogetherTab
                {...this.props}
                selected={this.state.selectedType}
                context={this.state.context}
                visibleOptions={this.props.visibleTypes}
                hiddenCollaborativeOptions={[SourceType.PpmxTime]}
                openCalendlyPanel={() => this.setState({ isCalendlyPanelOpen: true })} />
        </Panel>;
    }
}


function mapStateToProps(state: ApplicationState): StateProps {
    return {
        integrations: new Integrations(state.tenant.subscription.integrations)
    }
}

export default connect(mapStateToProps, {})(ExternalEpmConnectControl);