import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import CalendlyRequest from '../../common/CalendlyRequest';

type StateProps = {
    calendlyEventPath: string
}

type Props = StateProps & RouteComponentProps<{}>;

const RequestCall = (props: Props) => {
    return <CalendlyRequest
        calendlyEventName='RequestCall'
        calendlyEventPath={props.calendlyEventPath}
        title={<>Rather Have Us Show You How It Would Work For <u>Your Company?</u></>}
        description={<>Book a fully personalized 30 minute call with our team where we’ll discuss:</>}
        details={<>
            <p>
                ✔️ Your objectives and requirements
            </p>
            <p>
                ✔️ How it would work for <u>YOUR</u> use cases.
            </p>
            <p>
                ✔️ How it would integrate with your systems
            </p>
            <p>
                ✔️ Show all features and scenarios that fit your requirements
            </p>
            <p>
                Plus – we will answer any questions you might have.
            </p>
            <p>
                <b>Interested? Book a free call with our team here 👉</b>
            </p>
            <p>
                Let’s help you get started fast!
            </p>
        </>}
        skipText='No thanks'
    />;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        calendlyEventPath: state.analytics.calendlyRequestCall,
    }
}

export default withRouter<{}>(connect(mapStateToProps, {})(RequestCall));