import { Action, Reducer } from 'redux';
import { get } from "../fetch-interceptor";
import { AppThunkAction } from "./";
import { Dictionary } from "../entities/common";
import { defaultCatch } from "./utils";
import { warningService } from "./services/warningService";

export interface IControlWarningsTypeMap {
    byKey: Dictionary<string[]>;
    all: string[];
}

export type SectionWarningsTypeMap = Dictionary<IControlWarningsTypeMap>
export type EntityWarningsTypeMap = {
    common: string[],
    targeted: Dictionary<SectionWarningsTypeMap>,
    dependencies: Dictionary<string[]>
}
export type ServerWarningTypeMap = {
    common: string[],
    targeted: Dictionary<Dictionary<Dictionary<string[]>>>,
    dependencies: Dictionary<string[]>
}
export interface WarningsTypeMapState {
    isLoading: boolean;
    maps: Dictionary<EntityWarningsTypeMap>;
}
const unloadedState: WarningsTypeMapState = {
    isLoading: false,
    maps: {}
}

type LoadWarningsTypeMapAction = { type: 'LOAD_WARNINGS_TYPE_MAP', entity: string }
type WarningsTypeMapLoadedAction = { type: 'WARNINGS_TYPE_MAP_LOADED'; entity: string, map: ServerWarningTypeMap }

type KnownAction = LoadWarningsTypeMapAction | WarningsTypeMapLoadedAction;

export const actionCreators = {
    loadWarningsTypeMap: (entity: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!getState().warningsTypeMaps.maps[entity]) {
            get<ServerWarningTypeMap>(`api/metadata/${entity}/warningsTypeMap`)
                .then(_ => { dispatch({ type: 'WARNINGS_TYPE_MAP_LOADED', entity: entity, map: _ }); })
                .catch(defaultCatch(dispatch));

            dispatch({ type: 'LOAD_WARNINGS_TYPE_MAP', entity: entity });
        }
    }
}

export const reducer: Reducer<WarningsTypeMapState> = (state: WarningsTypeMapState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_WARNINGS_TYPE_MAP':
            return {
                ...state,
                isLoading: true
            }
        case 'WARNINGS_TYPE_MAP_LOADED':
            let newState = {
                ...state,
                maps: { ...state.maps },
                isLoading: false
            };
            newState.maps[action.entity] = warningService.convert(action.map);
            return newState;
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}