import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { IFieldMapping, ExternalFieldInfo, MappingType } from '../../../store/integration/common';
import { actionCreators, spoFieldToPpmxFieldsMap } from '../../../store/integration/SpoStore';
import { SourceType } from '../../../store/ExternalEpmConnectStore';
import MappingPanel from "../MappingPanel";
import { EntityType } from '../../../entities/common';
type OwnProps = {
    onDismiss: () => void;
    connectionId: string;
}
type StateProps = {
    error: string | null,
    mapping: IFieldMapping[] | null,
    spoProjectFields: ExternalFieldInfo[],
    isLoading: boolean
}
type Props = OwnProps & StateProps & typeof actionCreators;

class SpoMappingPanel extends React.Component<Props> {
    componentWillMount() {
        this.props.loadMapping(this.props.connectionId);
        this.props.loadSpoProjectFields(this.props.connectionId);
    }

    public render() {
        return <MappingPanel
            isLoading={this.props.isLoading}
            error={this.props.error}
            onDismiss={this.props.onDismiss}
            connector={SourceType.Spo}
            entityType={EntityType.Project}
            externalFields={this.props.spoProjectFields}
            typesMap={spoFieldToPpmxFieldsMap}
            mapping={this.props.mapping}
            showTransformationScript={true}
            updateMapping={mapping => {
                this.props.updateMapping(this.props.connectionId, mapping);
                this.props.onDismiss();
            }}
            mappingTypes={[MappingType.ToPpmx, MappingType.FromPpmx]}
        />;
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        error: state.spo.connections.error,
        mapping: state.spo.connections.mapping.entities,
        spoProjectFields: state.spo.connections.spoProjectFields.entities,
        isLoading: state.spo.connections.spoProjectFields.isLoading || state.spo.connections.mapping.isLoading
    }
}

export default connect(mapStateToProps, actionCreators)(SpoMappingPanel);