import "./FormCall.css";
import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';

type Props = {
    onCLick: () => void;
}

export const FormCall = (props: Props) => {
    return <div
        onClick={props.onCLick}
        className='form-call-link'>
        <FromCallWrapper />
        <FromCallWrapper />
    </div>;
}

const FromCallWrapper = () => {
    return <div className="form-call-wrapper">
        <div className="form-call-btn">
            <i className="form-call-phone-icon">
                <Icon iconName='PPMXFormCall' />
            </i>
        </div>
    </div>
}