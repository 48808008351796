import "./PlansList.css";
import * as React from 'react';
import { SubscriptionInfo, BillingPlan } from '../common';
import { EnterprisePlanCard, RegularPlanCard } from '../PlanCard';
import { PaymentPeriod, PaymentType, Subscription } from '../../../../store/Tenant';
import { SwitchTrialDialog } from "../SwitchTrialDialog";
import Checkout from "../Checkout";
import ContactUsDialog from "../ContactUsDialog";

type Props = {
    paymentPeriod: PaymentPeriod;
    plans: BillingPlan[];
    subscription: SubscriptionInfo;
    chargebee: any;
    canManage: boolean;
    onSwitchTrialConfirmed: (planId: string) => void;
    onSuccessfulCheckout: () => void;
}

export const PlansList = (props: Props) => {
    const { subscription, chargebee, canManage, plans, paymentPeriod } = props;
    const badgeProps = {
        isExpired: Subscription.isExpired(subscription),
        isTrial: subscription.isTrial
    };

    const [showContactUsDialogPlan, setShowContactUsDialogPlan] = React.useState<BillingPlan>();
    const [checkoutPlan, setCheckoutPlan] = React.useState<BillingPlan>();
    const [switchTrialToPlan, setSwitchTrialToPlan] = React.useState<BillingPlan>();

    const visiblePlans = plans.filter(_ => _.paymentPeriod === paymentPeriod || _.paymentType === PaymentType.Direct);
    return <div className='plans-list'>
        {
            visiblePlans.map(_ => <div key={_.id} className="plan-card-wrapper">
                {
                    _.paymentType === PaymentType.Direct
                        ? <EnterprisePlanCard
                            plan={_}
                            badgeProps={badgeProps}
                            isCurrent={_.id == subscription.planId}
                            onShowContactUsDialog={setShowContactUsDialogPlan}
                        />
                        :
                        <RegularPlanCard
                            plan={_}
                            badgeProps={badgeProps}
                            isCurrent={_.id === subscription.planId}
                            disabled={!canManage || !chargebee || Subscription.isDirect(subscription)}
                            onChange={setCheckoutPlan}
                            onBuy={setCheckoutPlan}
                            onSwitchTrial={setSwitchTrialToPlan}
                        />
                }
            </div>)
        }
        {
            switchTrialToPlan && <SwitchTrialDialog
                plan={switchTrialToPlan}
                subscription={subscription}
                onDismiss={() => setSwitchTrialToPlan(undefined)}
                onConfirm={() => props.onSwitchTrialConfirmed(switchTrialToPlan.id)}
            />
        }
        {
            !!checkoutPlan && <Checkout
                chargebee={chargebee}
                plan={checkoutPlan}
                subscription={subscription}
                onDismiss={() => setCheckoutPlan(undefined)}
                onSuccessful={props.onSuccessfulCheckout}
            />
        }
        {
            !!showContactUsDialogPlan && <ContactUsDialog
                plan={showContactUsDialogPlan}
                onDismiss={() => setShowContactUsDialogPlan(undefined)}
            />
        }
    </div>
}