import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { SectionsContainer } from '../common/sectionsControl/SectionsContainer';
import * as Metadata from '../../entities/Metadata';
import { Dictionary, EntityType, IWarning } from "../../entities/common";
import { DetailsSpinner } from "../common/Spinner";
import { bindActionCreators } from 'redux';
import { ViewTypeViews, buildViewTypeSelect } from '../common/ViewTypeSelect';
import { notUndefined } from '../utils/common';
import { validators } from '../field/Fields';
import { rendersBuilder, rendersInlineBuilder } from '../objective/Fields';
import { LayoutService } from '../utils/LayoutService';
import * as ContributingProjectsControl from '../common/sectionsControl/uiControls/ContributingProjectsControl';
import * as SubObjectivesControl from '../common/sectionsControl/uiControls/SubObjectivesControl';
import { nameof } from '../../store/services/metadataService';
import { isInReadonlyMode, UserState } from '../../store/User';
import { Objective, ObjectiveCalculationType, actionCreators, OKRState, IObjectiveAttrs, OKRValueType, OKRDirection } from '../../store/ObjectivesListStore';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { buildTimelineItem, renderSegmentContent } from './timeline';
import * as TimelineControl from '../common/sectionsControl/uiControls/TimelineControl';
import ObjectiveHeader from './ObjectiveHeader';
import { IFieldsAreaConfiguration } from '../common/sectionsControl/uiControls/fieldsArea/common';

type StateProps = {
    user: UserState;
    entity?: Objective;
    sections: Metadata.Section[];
    isLoading: boolean;
    fields: Metadata.Field[];
    isReadonlyMode: boolean;
}

type ActionProps = {
    objectivesActions: typeof actionCreators;
};

type Props = RouteComponentProps<{ id: string }>
    & StateProps
    & ActionProps;

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    ContributingProjectsControl: ContributingProjectsControl.IConfiguration;
    SubObjectivesControl: SubObjectivesControl.IConfiguration;
    TimelineControl: TimelineControl.IConfiguration;
}

const ObjectiveGrowthStartValueWarning = {
    type: "ObjectiveWarning_GrowthStartValue",
    text: "For correct progress calculation Target Value should be more than Start Value if Growth direction is selected."
};
const ObjectiveReductionStartValueWarning = {
    type: "ObjectiveWarning_ReductionStartValue",
    text: "For correct progress calculation Start Value should be more than Target Value if Reduction direction is selected."
};

class ObjectiveDetails extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sectionsState: {}
        };
    }

    componentWillMount() {
        this.props.objectivesActions.loadObjective(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.match.params.id != this.props.match.params.id) {
            this.props.objectivesActions.loadObjective(nextProps.match.params.id);
        }
    }

    public render() {
        const { entity, isLoading, sections } = this.props;

        const warnings: IWarning[] = [
            entity?.attributes.CalculationType === ObjectiveCalculationType.Calculated
                && entity?.attributes.Direction === OKRDirection.Growth
                && entity?.attributes.StartValue > entity?.attributes.TargetValue
                ? ObjectiveGrowthStartValueWarning : undefined,
            entity?.attributes.CalculationType === ObjectiveCalculationType.Calculated
                && entity?.attributes.Direction === OKRDirection.Reduction
                && entity?.attributes.StartValue < entity?.attributes.TargetValue
                ? ObjectiveReductionStartValueWarning : undefined
        ].filter(notUndefined);

        return <DetailsSpinner isLoading={isLoading}>
            {entity && <ObjectiveHeader 
                entity={entity}
            />}
            {entity &&
                <SectionsContainer
                    entity={entity}
                    warnings={warnings}
                    entityType={EntityType.Objective}
                    getSections={() => sections}
                    controlsConfig={this._buildControlsConfigurations()}
                />}
        </DetailsSpinner>
    }

    private _buildControlsConfigurations = (): IConfiguration => ({
        ['TimelineControl']: {
            datacontext: {
                timeline: {
                    item: buildTimelineItem(this.props.entity!, this.props.fields),
                    displayToday: true,
                    startDate: this.props.entity!.attributes.StartDate,
                    finishDate: this.props.entity!.attributes.FinishDate,
                    renderSegmentContent: (row) => renderSegmentContent(row, this.props.fields)
                }
            }
        },
        ['SubObjectivesControl']: {
            ...buildViewTypeSelect(ViewTypeViews.SubObjectivesControl),
            actions: {
                updateUIControl: this.updateUIControl
            },
            inlineEditProps:{
                customFieldValidator: validators,
                elementCustomRender: rendersInlineBuilder()
            }
        },
        ['ContributingProjectsControl']: {
            ...buildViewTypeSelect(ViewTypeViews.ContributingProjectsControl),
            actions: {
                updateUIControl: this.updateUIControl
            }
        },
        ['FieldsArea']: {
            editProps: {
                customFieldValidator: validators,
                elementCustomRender: rendersBuilder()
            },
            datacontext: {
                entityId: this.props.entity!.id,
                entityType: EntityType.Objective,
                readOnlyFields: this.props.entity!.attributes.State !== OKRState.Open
                    ? this.props.fields.map(_ => _.name)
                    : [
                        this.props.entity!.attributes.CalculationType === ObjectiveCalculationType.Manual ? undefined : nameof<IObjectiveAttrs>("Progress"),
                        this.props.entity!.attributes.CalculationType === ObjectiveCalculationType.KR_Rollup ? nameof<IObjectiveAttrs>("CurrentValue") : undefined,
                    ].filter(notUndefined),
                hiddenFields: this.props.entity!.attributes.ValueType === OKRValueType.Flag
                    ? [nameof<IObjectiveAttrs>("Direction"), nameof<IObjectiveAttrs>("StartValue"), nameof<IObjectiveAttrs>("TargetValue")]
                    : this.props.entity!.attributes.CalculationType !== ObjectiveCalculationType.Calculated
                        ? [nameof<IObjectiveAttrs>("Direction")]
                        : [],
                warningsTypeMap: {
                    byKey: { [nameof<IObjectiveAttrs>("Progress")]: [ObjectiveGrowthStartValueWarning.type, ObjectiveReductionStartValueWarning.type] },
                    all: [nameof<IObjectiveAttrs>("Progress")]
                }
            },
            actions: {
                refreshEntity: this.refreshEntity,
                updateUIControl: this.updateUIControl,

                onEditComplete: this.onEditComplete
            }
        },
        ['ObjectiveSummaryControl']: {
            datacontext: {
                sections: this.props.sections
            }
        }
    });

    private updateUIControl = (sectionId: string, uiControlId: string, settings: Dictionary<any>) => {
        return this.props.isReadonlyMode
            ? this.props.objectivesActions.updateUIControlOnClient(sectionId, uiControlId, settings)
            : this.props.objectivesActions.updateUIControl(sectionId, uiControlId, settings);
    }
    
    private refreshEntity = () => {
        this.props.objectivesActions.loadObjective(this.props.entity!.id);
        this.props.objectivesActions.loadSubObjectives(this.props.entity!.id);
    }

    private onEditComplete = (fieldName: string, fieldValue: any, extra: Dictionary<any>): void => {
        if (!fieldName) {
            return;
        }

        this.props.objectivesActions.updateObjectiveAttributes(this.props.entity!.id, { [fieldName]: fieldValue, ...extra });
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{ id: string }>): StateProps {
    const fields = state.fields[EntityType.Objective];
    return {
        user: state.user,
        entity: state.objectives.activeEntity && state.objectives.activeEntity.id == ownProps.match.params.id ? state.objectives.activeEntity : undefined,
        sections: LayoutService.getDefault(state.layouts[EntityType.Objective]).sections,
        isLoading: state.objectives.isLoading,
        fields: fields.allIds.map(_ => fields.byId[_]),
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

export default connect(mapStateToProps, (dispatch): ActionProps =>
({
    objectivesActions: bindActionCreators(actionCreators, dispatch)
}))(ObjectiveDetails);