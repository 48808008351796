import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { Field } from "../../../../../entities/Metadata";
import CalculationFinancialsTotals, { CalculationFinancialsTotals as CalculationFinancialsTotalsHelper} from "../CalculationFinancialsTotals";
import { Program } from "../../../../../store/ProgramsListStore";

export default class BudgetTotals extends React.Component<IListViewFieldColumn<Program>> {
    public render() {
        return <CalculationFinancialsTotals {...this.props} entity={this.props.entity.calculation.costs} />;
    }

    public static getValue(entity: Program, field: Field): number | undefined {
        return CalculationFinancialsTotalsHelper.getValue(entity.calculation.costs, field);
    }

    public static getSearchValue(entity: Program, field: Field) {
        return BudgetTotals.getValue(entity, field);
    }
}