import * as React from 'react';
import { ActionButton, ITextField, TextField } from 'office-ui-fabric-react';
import { Validator } from '../../../validation';

type InlineAddInputEntityType = {
    key: any;
    label: string;
}
type InlineAddInputProps = {
    types: InlineAddInputEntityType[];
    onSave: (name: string, key: any) => void;
    setIsEditorSelected?: (value: boolean) => void;
}
const InlineAddInput = (props: InlineAddInputProps) => {
    const [editor, setEditor] = React.useState<InlineAddInputEntityType>();
    const [autofocus, setAutofocus] = React.useState<InlineAddInputEntityType>();
    const onSave = React.useCallback((value) => {
        props.onSave(value, editor?.key);
    }, [props.onSave, editor]);

    const onCancel = React.useCallback(() => {
        setEditor(undefined);
        props.setIsEditorSelected?.(false);
        setAutofocus(editor);
    }, [editor]);

    return editor
        ? <TextEditor 
            required
            keepOpenOnSave
            label={`${editor.label} Name`}
            onSave={onSave}
            onCancel={onCancel}
        />
        : <>
            {props.types.map(item =>
                <ActionButton
                    key={item.label}
                    autoFocus={autofocus?.key === item.key}
                    iconProps={{ iconName: "Add" }}
                    onKeyDown={e => { if (e.key !== 'Enter') { setEditor(item); props.setIsEditorSelected?.(true); } }}
                    onClick={() => { setEditor(item); props.setIsEditorSelected?.(true);} }>Add {item.label}</ActionButton>)}
        </>
}
export default InlineAddInput;

export const TextEditor = (props: {required?: boolean, value?: string, label?: string, keepOpenOnSave?: boolean, onSave: (name: string) => void, onCancel: () => void}) => {
    const [value, setValue] = React.useState<string>(props.value || "");
    const validator = React.useMemo(() => !!props.required 
        ? Validator.new().required().build()
        : Validator.new().build(), [props.required]);    
    const onEditComplete = (closeOnSave?: boolean) => {
        if (validator.isValid(value)) {
            props.onSave(value);
        } else if (closeOnSave) {
            props.onCancel()
        }
        setValue("");
    }

    const textBox = React.useRef<ITextField>(null);
    React.useEffect(() => {
        //HACK: TimelineSegment RND component render in some way steals focus (no carret) from textbox.
        textBox.current?.setSelectionRange(-1, -1);
    }, [props.onSave, textBox])

    return <TextField
        value={value}
        componentRef={textBox}
        autoFocus={true}
        placeholder={props.label}
        onChange={(_, newValue) => setValue(newValue || "")}
        onKeyDown={e => { if (e.key === 'Escape') { props.onCancel(); setValue(""); } }}
        onKeyPress={e => { if (e.key === 'Enter') { onEditComplete(!props.keepOpenOnSave) } }}
        onBlur={() => onEditComplete(true)} />;
}

type InlineInputItem = {
    label: string;
    iconName: string;
    isVisible: boolean;
    disabled?: boolean;
    title?: string;
    onClick: () => void;
}

type InlineInputProps = {
    items: InlineInputItem[];
    inlineAddInputProps: InlineAddInputProps;
}

export const InlineInput = (props: InlineInputProps) => {
    const { inlineAddInputProps, items } = props;
    const [isEditorSelected, setIsEditorSelected] = React.useState(false);
    return <div>
        <InlineAddInput 
            types={inlineAddInputProps.types} 
            onSave={inlineAddInputProps.onSave}
            setIsEditorSelected={setIsEditorSelected} />
        {!isEditorSelected &&
            items.map((_, index) =>
                _.isVisible && <ActionButton 
                    key={index}
                    disabled={_.disabled}
                    title={_.title}
                    iconProps={{ iconName: _.iconName }}
                    onClick={_.onClick}>
                    {_.label}
                </ActionButton>
            )
        }
    </div>
}