import { StoreHelper } from "../services/storeHelper";
import { ICardSubView, SortDirection } from "../../entities/Metadata";
import { ICardSortOption, IViewsState, DEFAULT_LIST_SUBVIEW } from "../views";
import { UserState } from '../User';
import { CommonOperations, contains } from '../permissions';
import { notUndefined } from '../../components/utils/common';

const cardActiveSubView: ICardSubView = {
    id: '3c26dbd7-a38f-4efb-879b-c9cd2d317642',
    name: 'Summary View',
    isBuiltIn: false,
    isNotSaved: false,
    showCosts: false
};

// 'Newest on Top' sort option index
const DEFAULT_CARD_SORT_OPTION_INDEX = 2;
const cardSortOptions: ICardSortOption[] = [
    {
        id: "f00fd81f-1f6b-4e26-b444-b0390ac0203e",
        orderBy: {
            fieldName: 'Name',
            direction: SortDirection.ASC,
        },
        label: 'Name: A – Z',
        iconName: 'PPMXSortAZ'
    },
    {
        id: "d327bc04-7c10-469f-bf85-51131abbfc2b",
        orderBy: {
            fieldName: 'Name',
            direction: SortDirection.DESC
        },
        label: 'Name: Z – A',
        iconName: 'PPMXSortZA'
    },
    {
        id: "87bb5ef6-8a0f-4dcb-822c-59e8c1594ff8",
        orderBy: {
            fieldName: 'CreatedDate',
            direction: SortDirection.DESC,
        },
        label: 'Newest on Top',
        iconName: 'PPMXSortNewest'
    },
    {
        id: "4405f249-138d-4212-b919-077b2cd405ef",
        orderBy: {
            fieldName: 'CreatedDate',
            direction: SortDirection.ASC
        },
        label: 'Oldest on Top',
        iconName: 'PPMXSortOldest'
    }
];

const TIMELINE_DEFAULT_SORT = {
    fieldName: "Name",
    direction: SortDirection.ASC
}

export function getViewsDefaults(user: UserState): IViewsState {
    const canManageBudget = contains(user.permissions.common, CommonOperations.BudgetManage);

    return {
        card: {
            subViews: [
                cardActiveSubView,
                canManageBudget
                    ? {
                        id: '5e7660a7-17b4-426d-a7e4-a155a2e54007',
                        name: 'Cost Details',
                        isBuiltIn: false,
                        isNotSaved: false,
                        showCosts: true
                    }
                    : undefined
            ].filter(notUndefined),
            sortBy: {
                active: cardSortOptions[DEFAULT_CARD_SORT_OPTION_INDEX],
                options: cardSortOptions
            }
        },
        list: {
            fakeFields: [],
            selectedByDefault: [],
            subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
            sortBy: {
                fieldName: "Name",
                direction: SortDirection.ASC
            }
        },
        timeline: {
            subViews: StoreHelper.create([DEFAULT_LIST_SUBVIEW]),
            selectedByDefault: [],
            sortBy: TIMELINE_DEFAULT_SORT
        },
        activeViewType: 'timeline'
    };
}