import * as React from 'react';
import TimelineCanvas, { IStretch } from "./TimelineCanvas";
import { ResizeEnable } from "react-rnd";
import { RelationsAlignmentManager } from "./RelationsAlignmentManager";
import { ITimelineMarker, TimelineMarker } from './TimelineMarker';
import { ITimelineSegment, TimelineSegment, IScaleTimelineSegment, IBaseTimelineElement } from './TimelineSegment';
import { ITimelineRelation, TimelineRelation } from './TimelineRelation';
import { IRow, RowPositionMap, Shift } from './TimelineList';
import { getOffset } from './TimelineScale';
import { arraysEqual, IContextualMenuItem } from 'office-ui-fabric-react';
import { distinctByKey, sortNumber } from '../../utils/common';
import { Dictionary } from '../../../entities/common';
import { ITimelineBaseline, TimelineBaseline } from './TimelineBaseline';

export interface ICanvasInnerComponentProps<T> extends IStretch {
    data: T;
    onClick?: (data: T, ev?: React.MouseEvent) => void;
    onDoubleClick?: (data: T, ev?: React.MouseEvent<HTMLElement>) => void;
    onDrag?: (x?: number, y?: number) => void;
    onSideShift?: (x: number, side: 'left' | 'right') => void;
    renderContent?: (data: T) => JSX.Element | undefined;
    renderTooltipContent?: (data: T) => JSX.Element | undefined;
    onChange?: (data: T, change: Partial<T>, rowShift?: RowShift) => void;
    dragAxis?: (data: T) => "x" | "y" | "both" | "none" | undefined;
    enableResizing?: (data: T) => ResizeEnable | undefined;
    resolvePosition?: (data: T, change: Partial<T>, verticalOffset?: number) => RowShift | undefined;
    largeMode?: boolean;
    isSelected?: boolean;
    hideCommandPanel?: boolean;
    resetSelection?: () => void;
    buildCommandsMenu?: (data: T) => IContextualMenuItem[] | undefined;
}

export interface RowShift {
    targetRow: IRow;
    addNewLine: boolean;
    overlappedSegments?: ITimelineSegment[]
    overlappedMarkers?: ITimelineMarker[]
}

export enum ScaleRenderMode {
    None = -1,
    Line = 0,
    Cell = 1
}

type TimelineBodyProps = {
    segments: ITimelineSegment[];
    ghostSegments?: ITimelineSegment[];
    markers: ITimelineMarker[];
    ghostMarkers?: ITimelineMarker[];
    baselines?: ITimelineBaseline[];
    displayToday: boolean;
    largeMode?: boolean;
    scaleRenderMode?: ScaleRenderMode;
    relations?: ITimelineRelation[];
    rowPositionMap?: RowPositionMap;
    currentRowPosition?: number;
    relationsAlignmentManager?: RelationsAlignmentManager;
    availableSpaceMap?: Dictionary<Dictionary<number>>;
    renderSegmentContent?: (segment: ITimelineSegment) => JSX.Element | undefined;
    renderMarkerContent?: (segment: ITimelineMarker) => JSX.Element | undefined;
    renderSegmentTooltipContent?: (segment: ITimelineSegment) => JSX.Element | undefined;
    renderMarkerTooltipContent?: (marker: ITimelineMarker) => JSX.Element | undefined;
    onSegmentClick?: (segment: ITimelineSegment, ev?: React.MouseEvent<HTMLElement>) => void;
    onSegmentDoubleClick?: (segment: ITimelineSegment, ev?: React.MouseEvent<HTMLElement>) => void;
    onMarkerClick?: (marker: ITimelineMarker, ev?: React.MouseEvent<HTMLElement>) => void;
    onMarkerDoubleClick?: (marker: ITimelineMarker, ev?: React.MouseEvent<HTMLElement>) => void;
    onRelationClick?: (marker: ITimelineRelation, ev?: React.MouseEvent<HTMLElement>) => void;
    onSideShift?: (shift: Shift) => void;
    resolveSegmentPosition?: (data: ITimelineSegment, change: Partial<ITimelineSegment>, verticalOffset: number) => RowShift | undefined;
    resolveMarkerPosition?: (data: ITimelineMarker, change: Partial<ITimelineMarker>, verticalOffset: number) => RowShift | undefined;
    onSegmentChange?: (segment: ITimelineSegment, data: Partial<ITimelineSegment>, rowShift?: RowShift) => void;
    onMarkerChange?: (marker: ITimelineMarker, data: Partial<ITimelineMarker>, rowShift?: RowShift) => void;
    segmentDragAxis?: (data: ITimelineSegment) => "x" | "y" | "both" | "none" | undefined;
    segmentEnableResizing?: (data: ITimelineSegment) => ResizeEnable | undefined;
    markerDragAxis?: (data: ITimelineMarker) => "x" | "y" | "both" | "none" | undefined;
    resetSelection?: () => void;
    buildSegmentCommands?: (data: ITimelineSegment) => IContextualMenuItem[] | undefined;
    buildMarkerCommands?: (data: ITimelineMarker) => IContextualMenuItem[] | undefined;
    buildRelationCommands?: (data: ITimelineRelation) => IContextualMenuItem[] | undefined;
    checkItemSelection?: (data: IBaseTimelineElement | ITimelineRelation) => boolean;
    hideItemCommandPanel?: (data: IBaseTimelineElement | ITimelineRelation) => boolean;
} & IStretch;

interface ITimelineBodyState {
    segments: ITimelineSegment[];
    markers: ITimelineMarker[];
    relations?: ITimelineRelation[];
}

export class TimelineBody extends React.Component<TimelineBodyProps, ITimelineBodyState> {
    constructor(props: TimelineBodyProps) {
        super(props);
        this.state = this._buildState(props);
    }

    componentWillReceiveProps(props: TimelineBodyProps) {
        if (!arraysEqual(props.segments, this.props.segments)
            || !arraysEqual(props.markers, this.props.markers)
            || props.scale !== this.props.scale
            || props.timelineWidth !== this.props.timelineWidth) {
            this.setState(this._buildState(props));
        }
    }

    public render() {
        const { relations, rowPositionMap, relationsAlignmentManager, availableSpaceMap, currentRowPosition, ghostMarkers, ghostSegments, baselines } = this.props;
        const { segments, markers } = this.state;
        const scaleRenderMode = this.props.scaleRenderMode ?? ScaleRenderMode.Line;
        return <TimelineCanvas {...this.props} className={`${this.props.largeMode ? 'large-mode' : ''}`}>
            {scaleRenderMode != ScaleRenderMode.None && this.props.scale.dates.map((_, idx) => {
                if (scaleRenderMode === ScaleRenderMode.Line) {
                    return <TimelineMarker
                        key={`bg-line ${_.start.getTime()}`}
                        data={{ key: '', date: _.start, line: true, className: 'bg-line' }}
                        scale={this.props.scale}
                        largeMode={this.props.largeMode}
                        timelineWidth={this.props.timelineWidth} />;
                }
                const data: IScaleTimelineSegment = {
                    key: '',
                    startDate: _.start.getBeginOfDay(),
                    finishDate: _.finish.getEndOfDay(),
                    className: 'bg-cell',
                    prevStartDate: idx > 0 ? this.props.scale.dates[idx - 1].start : undefined
                };
                return <TimelineSegment
                    key={`bg-cell ${_.start.getTime()}`}
                    data={data}
                    scale={this.props.scale}
                    largeMode={this.props.largeMode}
                    timelineWidth={this.props.timelineWidth}
                    renderContent={this.props.renderSegmentContent}
                    renderTooltipContent={this.props.renderSegmentTooltipContent}
                    onClick={this.props.onSegmentClick}
                    onDoubleClick={this.props.onSegmentDoubleClick}
                    resetSelection={this.props.resetSelection}
                    buildCommandsMenu={this.props.buildSegmentCommands}
                    isSelected={this.props.checkItemSelection?.(data)}
                    hideCommandPanel={this.props.hideItemCommandPanel?.(data)}
                />
            })}
            {(!!segments.length || !!ghostSegments?.length || !!baselines?.length) && <div className="timeline-container">
                {
                    segments
                        .map((_, i) => <TimelineSegment
                            key={`segment${i}`}
                            data={_}
                            onDrag={(x, y) => this.props.onSideShift?.({ key: _.key, type: 'both', x, y })}
                            onSideShift={(x, side) => this.props.onSideShift?.({ key: _.key, x, type: side })}
                            scale={this.props.scale}
                            largeMode={this.props.largeMode}
                            timelineWidth={this.props.timelineWidth}
                            renderContent={this.props.renderSegmentContent}
                            renderTooltipContent={this.props.renderSegmentTooltipContent}
                            onClick={this.props.onSegmentClick}
                            onDoubleClick={this.props.onSegmentDoubleClick}
                            onChange={this.props.onSegmentChange}
                            resolvePosition={this.props.resolveSegmentPosition}
                            enableResizing={this.props.segmentEnableResizing}
                            dragAxis={this.props.segmentDragAxis}
                            resetSelection={this.props.resetSelection}
                            buildCommandsMenu={this.props.buildSegmentCommands}
                            isSelected={this.props.checkItemSelection?.(_)}
                            hideCommandPanel={this.props.hideItemCommandPanel?.(_)}
                        />)
                }
                {
                    ghostSegments?.map((_) => <TimelineSegment
                        key={_.key}
                        data={{ ..._, datesVisibility: undefined, style: { backgroundColor: 'rgba(120, 128, 174, 0.16)', border: '1px dashed rgba(120, 128, 174, 0.6)' } }}
                        scale={this.props.scale}
                        largeMode={this.props.largeMode}
                        timelineWidth={this.props.timelineWidth}
                    />)
                }
                {
                    baselines?.map((_) => <TimelineBaseline
                        key={_.key}
                        data={_}
                        scale={this.props.scale}
                        largeMode={this.props.largeMode}
                        timelineWidth={this.props.timelineWidth}
                    />)
                }
            </div>}
            {
                this.props.displayToday &&
                <TimelineMarker
                    data={{ key: 'today', date: new Date().getBeginOfDay(), line: true, label: 'Today' }}
                    scale={this.props.scale}
                    largeMode={this.props.largeMode}
                    timelineWidth={this.props.timelineWidth} />
            }
            {
                markers
                    .map((_) => <TimelineMarker
                        key={_.key}
                        data={_}
                        onDrag={(x, y) => this.props.onSideShift?.({ key: _.key, type: 'both', x, y })}
                        scale={this.props.scale}
                        largeMode={this.props.largeMode}
                        timelineWidth={this.props.timelineWidth}
                        renderContent={this.props.renderMarkerContent}
                        renderTooltipContent={this.props.renderMarkerTooltipContent}
                        onClick={this.props.onMarkerClick}
                        onDoubleClick={this.props.onMarkerDoubleClick}
                        onChange={this.props.onMarkerChange}
                        resolvePosition={this.props.resolveMarkerPosition}
                        dragAxis={this.props.markerDragAxis}
                        resetSelection={this.props.resetSelection}
                        buildCommandsMenu={this.props.buildMarkerCommands}
                        isSelected={this.props.checkItemSelection?.(_)}
                        hideCommandPanel={this.props.hideItemCommandPanel?.(_)}
                    />)
            }
            {
                ghostMarkers?.map((_) => <TimelineMarker
                    key={_.key}
                    data={{ ..._, datesVisibility: undefined, style: { backgroundColor: 'rgba(120, 128, 174, 0.16)', border: '1px dashed rgba(120, 128, 174, 0.6)' } }}
                    scale={this.props.scale}
                    largeMode={this.props.largeMode}
                    timelineWidth={this.props.timelineWidth}
                />)
            }
            {
                rowPositionMap && relationsAlignmentManager && currentRowPosition !== undefined &&
                relations && distinctByKey(relations, "key").map((_, index) => <TimelineRelation
                    key={_.key}
                    data={_}
                    rowPositionMap={rowPositionMap}
                    currentRowPosition={currentRowPosition}
                    relationsAlignmentManager={relationsAlignmentManager}
                    availableSpaceMap={availableSpaceMap}
                    scale={this.props.scale}
                    largeMode={this.props.largeMode}
                    timelineWidth={this.props.timelineWidth}
                    buildCommandsMenu={this.props.buildRelationCommands}
                    isSelected={this.props.checkItemSelection?.(_)}
                    hideCommandPanel={this.props.hideItemCommandPanel?.(_)}
                    onClick={this.props.hideItemCommandPanel ? this.props.onRelationClick : undefined}
                />)
            }
        </TimelineCanvas>;
    }

    private _buildState(props: TimelineBodyProps): ITimelineBodyState {
        const segments = this._arrangeSegmentsOrder(props.segments).map(_ => ({ ..._, left: getOffset(props.scale, _.startDate, props.timelineWidth).left }));
        const markers = this._arrangeMarkersOrder(props.markers).map(_ => ({ ..._, left: getOffset(props.scale, _.date, props.timelineWidth).left }));
        const horizontalPositionMap = [...markers.map(_ => _.left), ...segments.map(_ => _.left)].sort(sortNumber);
        const state = {
            segments: segments.map(_ => ({ ..._, availableSpace: TimelineBody.getAvailableSpace(_.left, horizontalPositionMap) })),
            markers: markers.map(_ => ({ ..._, availableSpace: TimelineBody.getAvailableSpace(_.left, horizontalPositionMap) }))
        };

        if (this.props.availableSpaceMap !== undefined && props.currentRowPosition !== undefined) {
            this.props.availableSpaceMap[props.currentRowPosition] = state.markers
                .filter(_ => _.availableSpace !== undefined)
                .reduce((map, _) => ({...map, [_.key]: _.availableSpace }), {});
        }

        return state;
    }

    private static getAvailableSpace(left: number, horizontalPositionMap?: number[]) {
        const nextElementLeft = horizontalPositionMap?.find(__ => __ > left);
        return nextElementLeft !== undefined ? nextElementLeft - left : undefined;
    }

    private _arrangeSegmentsOrder(segments: ITimelineSegment[]): ITimelineSegment[] {
        const items = [...segments];

        items.sort((a, b) => {
            if (a.startDate.getTime() < b.startDate.getTime())
                return -1;
            if (a.startDate.getTime() > b.startDate.getTime())
                return 1;
            if (a.finishDate.getTime() > b.finishDate.getTime())
                return -1;
            if (a.finishDate.getTime() < b.finishDate.getTime())
                return 1;
            return 0;
        });

        return items;
    }

    private _arrangeMarkersOrder(markers: ITimelineMarker[]): ITimelineMarker[] {
        const items = [...markers];

        items.sort((a, b) => {
            if (a.date.getTime() < b.date.getTime())
                return -1;
            if (a.date.getTime() > b.date.getTime())
                return 1;
            return 0;
        });

        return items;
    }
}
