import * as React from 'react';
import { Insights, StatusCalculationTypes } from "../../../store/Tenant";
import { IInsightsData } from "../../../entities/common";
import { Toggle } from "office-ui-fabric-react";
import { nameof } from "../../../store/services/metadataService";
import { contains } from "../../../store/permissions";

export const AutoCalculationSection = (props: {
    readonly?: boolean,
    projectInsights: IInsightsData,
    onChange: (insights: Partial<IInsightsData>) => void,
    tenantInsights: Insights
}) => {
    const _onItemRender = (item: any) => {
        return <li key={item.id}>
            <div className="cb-wrap">
                <div className="title" title={item.label}>{item.label}</div>
                <Toggle
                    checked={!props.projectInsights[item.id]}
                    disabled={item.disabled}
                    onChange={(e: any, c?: boolean) => props.onChange({ [item.id]: !c })}
                />
            </div>
        </li>;
    }

    const toggles =
        [
            {
                id: nameof<IInsightsData>("statusCalculationDisabled"),
                label: "Project Statuses",
                disabled: props.readonly || !contains(props.tenantInsights.project.statusCalculation, StatusCalculationTypes.Auto),
            },
            {
                id: nameof<IInsightsData>("keyDateStatusCalculationDisabled"),
                label: "Key Dates Statuses",
                disabled: props.readonly || !contains(props.tenantInsights.keyDate.statusCalculation, StatusCalculationTypes.Auto),
            },
            {
                id: nameof<IInsightsData>("taskStatusCalculationDisabled"),
                label: "Tasks Statuses",
                disabled: props.readonly || !contains(props.tenantInsights.task.statusCalculation, StatusCalculationTypes.Auto),
            },
            {
                id: nameof<IInsightsData>("warningsCalculationDisabled"),
                label: "Warnings",
                disabled: props.readonly || props.tenantInsights.projectWarningsCalculationDisabled,
            },
        ];

    return <div className="calculation-automatically">
        <div className='section-description'>
            Select the statuses that should be calculated automatically and whether warnings should be displayed.
        </div>
        <ul className="scrollable-content cb-list">
            {toggles.map(_onItemRender)}
        </ul>
    </div>;
}
