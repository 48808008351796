import { IContextualMenuItem } from "office-ui-fabric-react";
import { LayoutsState } from "../../store/layouts";
import { IUpdateLayoutInfo, IUpdateSectionInfo, IWithSections, Layout, Section } from "../../entities/Metadata";
import { Dictionary } from "../../entities/common";

export interface ILayoutActions {
    viewLayout: (layout?: Layout) => void;
    applyLayout?: (layout: Layout) => void;
    updateEntityLayout?: (updates: Dictionary<IUpdateSectionInfo>) => void;
    updateEntityPinnedViews?: (pinnedViews: string[]) => void;
    saveLayout?: (layoutId: string | undefined, sections: Section[], update: IUpdateLayoutInfo, callback?: (layoutId: string) => void) => void;
    deleteLayout?: (layout: Layout) => void;
}

export class LayoutService {
    public static getDefault(layouts: LayoutsState): Layout {
        const allLayouts = layouts.allIds.map(_ => layouts.byId[_]);
        return allLayouts.find((_: Layout) => _.isDefault) || allLayouts[0];
    }

    public static DefaultLayoutId = '00000000-0000-0000-0000-000000000000';

    static buildApplyLayoutMenuItem(
        layouts: LayoutsState,
        applyLayout: (layout: Layout) => void,
        currentLayoutId?: string): IContextualMenuItem;

    static buildApplyLayoutMenuItem(
        layouts: LayoutsState,
        applyLayout: undefined,
        currentLayoutId?: string): undefined;

    public static buildApplyLayoutMenuItem(
        layouts: LayoutsState,
        applyLayout: ((layout: Layout) => void) | undefined,
        currentLayoutId?: string): IContextualMenuItem | undefined {
        return applyLayout
            ? {
                key: 'apply-layout',
                iconProps: { iconName: 'ActivateOrders' },
                text: 'Apply Layout',
                subMenuProps: {
                    calloutProps: {
                        className: "header-callout"
                    },
                    items: layouts.allIds.filter(_ => !layouts.byId[_].isView).map<IContextualMenuItem>(_ => ({
                        key: _,
                        name: `${layouts.byId[_].name}${layouts.byId[_].isDefault ? ' (Default)' : ''}`,
                        canCheck: true,
                        checked: _ === currentLayoutId,
                        onClick: () => applyLayout(layouts.byId[_])
                    }))
                }
            }
            : undefined;
    }

    public static updateSections(entity: IWithSections, updates: Dictionary<IUpdateSectionInfo>): void {
        const sortedSections = this.getSortedSectionIdUpdatePairs(updates);
        for (const [sectionId, updateSectionInfo] of sortedSections) {
            const section = entity.sections.find(_ => _.id === sectionId);
            if (!section) {
                break;
            }

            this.applySectionUpdate(entity.sections, section, updateSectionInfo);
        }

        this.sortSections(entity);
    }

    private static getSortedSectionIdUpdatePairs(updates: Dictionary<IUpdateSectionInfo>): [string, IUpdateSectionInfo][] {
        const sortedTuples: [string, IUpdateSectionInfo][] = [];
        for (const sectionId in updates) {
            sortedTuples.push([sectionId, updates[sectionId]]);
        }
        sortedTuples.sort((a, b) => a[1].index! - b[1].index!);
        return sortedTuples;
    }

    private static applySectionUpdate(sections: Section[], section: Section, updateSectionInfo: IUpdateSectionInfo) {
        if (updateSectionInfo.isSelected !== undefined) {
            if (updateSectionInfo.isSelected) {
                section.isSelected = true;
                this.removeSectionFromLayoutSections(sections, section);
                sections.splice(
                    updateSectionInfo.index !== undefined
                        ? updateSectionInfo.index
                        : sections.filter(_ => _.isSelected).length,
                    0,
                    section);
            } else {
                section.isSelected = false;
            }
        }

        if (updateSectionInfo.index !== undefined) {
            this.removeSectionFromLayoutSections(sections, section);
            sections.splice(updateSectionInfo.index, 0, section);
        }
    }

    private static removeSectionFromLayoutSections(sections: Section[], section: Section) {
        const removeSectionIndex = sections.findIndex(_ => _.id === section.id);
        if (removeSectionIndex > -1) {
            sections.splice(removeSectionIndex, 1);
        }
    }

    private static sortSections(entity: IWithSections): void {
        entity.sections.sort((a, b) => Number(b.isSelected) - Number(a.isSelected));
    }
}
