import "./QuickStart.css";
import * as React from 'react';
import LetsStart from "../LetsStart";
import MoreResources from "../MoreResources";
import RequestDemo from "./RequestDemo";
import ContactUs from "./ContactUs";
import HelpGuide from "./HelpGuide";
import WhatsNew from "../WhatsNew";
import VideoBox from "../VideoBox";
import AiInsights from "../AiInsights";

type Props = {
    isTrial: boolean;
    hasPartnersSupport: boolean;
    hasAiInsights: boolean;
}
const QuickStartTeamMember = (props: Props) => <>
    {props.hasAiInsights && <div className="row"><AiInsights /></div>}
    <div className="row">
        <div className="column double">
            <div className="column">
                <LetsStart />
            </div>
        </div>
        {props.hasPartnersSupport
            ? undefined
            : props.isTrial
                ? <RequestDemo big />
                : <ContactUs big />}
    </div>
    <div className="row withVideo">
        <VideoBox text="PPM Express Overview" href="https://player.vimeo.com/video/685714660" />
        <VideoBox text="Overview for Team Members" href="https://player.vimeo.com/video/686620891" />
        <HelpGuide big />
    </div>
    <div className="row">
        <div className="column double">
            <div className="column">
                <MoreResources />
            </div>
        </div>
        <WhatsNew />
    </div>
</>;
export default QuickStartTeamMember;
