import * as React from "react";
import { connect } from 'react-redux';
import ActionCard from './ActionCard';
import { actionCreators } from '../../../store/RoadmapsListStore';
import { ApplicationState } from "../../../store";
import { RoadmapSubentityWrapper } from "../../roadmap/RoadmapCreation";
import { EntityType, ServerEntityType } from "../../../entities/common";
import { Field } from '../../../entities/Metadata';
import { UserState } from '../../../store/User';
import { canCreate } from "../../../store/permissions";

type OwnProps = {
    name: string;
    iconName: string;
}
type StoreProps = {
    fields: Field[];
    user: UserState;
}
type Props = OwnProps & StoreProps & typeof actionCreators;
const BuildRoadmapActionCard = (props: Props) => <ActionCard
    iconName={props.iconName}
    name={props.name}
    disabled={!canCreate(props.user.permissions.roadmap)}
    action={() => {
        const { user } = props;
        const roadmap = {
            attributes: {
                Name: "New Roadmap",
                Manager: [{ id: user.id, fullName: user.name, imageId: user.imageId }]
            }
        } as RoadmapSubentityWrapper;
        props.createRoadmap(roadmap, true, ServerEntityType.Project);
    }}
/>;
function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    const fields = state.fields[EntityType.Roadmap];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]).filter(_ => !_.isReadonly),
        user: state.user
    };
}

export default connect(mapStateToProps, actionCreators)(BuildRoadmapActionCard);
