import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import BackButton from '../navigation/BackButton';
import { TimeState } from '../../store/TimeStore';
import { copyToClipboard } from '../utils/common';
import { IconButton, TextField, MessageBar, MessageBarType } from 'office-ui-fabric-react';

type TimeSettingsOwnProps = RouteComponentProps<{}>;
type TimeSettingsStateProps = {
    time: TimeState;
}

type TimeSettingsProps = TimeSettingsStateProps & TimeSettingsOwnProps;

class TimeSettings extends React.Component<TimeSettingsProps> {
    constructor(props: TimeSettingsProps) {
        super(props);
    }

    public render() {
        return <div className="time-settings">
            <div className="commands">
                <div className="page-navigation">
                    <BackButton />
                </div>
                <h2>PPM Express | Time integration settings</h2>
                <TextField
                    readOnly={true}
                    label="Access Token"
                    value={this.props.time.ppmxToken}
                    onRenderSuffix={_ => this._renderCopyButton(this.props.time.ppmxToken)}
                    className="input-with-copy-button new-token-value" />
                <MessageBar messageBarType={MessageBarType.info}>
                    Please use this token in PPM Express | Time to connect to PPM Express
                </MessageBar>
            </div>
        </div>;
    }

    private _renderCopyButton = (value: string): JSX.Element | null => {
        return (
            <IconButton
                iconProps={{ iconName: 'Copy' }}
                title="Copy to clipboard"
                onClick={() => copyToClipboard(value)}
            />
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps?: TimeSettingsOwnProps): TimeSettingsStateProps {
    return {
        time: state.time
    };
}

export default connect(mapStateToProps)(TimeSettings);