import * as React from 'react';
import { getProjectTasksPath, ProjectInfo } from '../../../../../store/ProjectsListStore';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { SystemIcon } from '../summaryControl/LinkedSystemIcons';
import { ppmxTaskConnectionId } from '../../../../../entities/common';
import { getTasksMetrics } from '../../../../../store/project/utils';
import { SourceType, SourceType_, SourceTypeMap } from '../../../../../store/ExternalEpmConnectStore';

type OwnProps = { entity: ProjectInfo, useUrl?: boolean }
type Props = OwnProps & RouteComponentProps<{}>;

class TasksIcon extends React.Component<Props> {
    render() {
        const { entity } = this.props;

        return (
            <SystemIcon iconName={SourceType_.getIconName(SourceType.Ppmx)}
                title={`Manage ${SourceTypeMap[SourceType.Ppmx]} tasks`}
                showMetrics
                url={getProjectTasksPath(entity)}
                metrics={getTasksMetrics(entity.sourceInfos, entity.calculation.taskProgresses, ppmxTaskConnectionId)} />
        );
    }
}

export default withRouter<OwnProps>(TasksIcon)