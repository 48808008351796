import "./SystemsList.css";
import * as React from 'react';
import { ActionButton, Icon } from 'office-ui-fabric-react';
import { SourceType, SourceType_ } from "../../../store/ExternalEpmConnectStore";

type Props = {
    selectedSystem: SourceType;
    systems: { type: SourceType, isConnected?: boolean, isDisabled?: boolean, isUnavailable?: boolean, text?: string }[];
    onSelect: (system: SourceType) => void;
}

const SystemsList = (props: Props) => <div className="system-connect-buttons-list">
    {props.systems.map(_ => {
        const isDisabledClass = _.isDisabled ? 'disabled' : '';
        const isSelectedClass = _.type === props.selectedSystem ? 'selected' : '';
        const isUnavailableClass = _.isUnavailable ? 'unavailable' : '';
        return <ActionButton key={_.type.toString()}
            disabled={_.isDisabled}
            className={`system-connect-button ${isDisabledClass} ${isSelectedClass} ${isUnavailableClass}`}
            iconProps={{ iconName: SourceType_.getIconName(_.type), className: "system-logo" }}
            onClick={() => props.onSelect(_.type)}>
            <div className="connection-details">
                <div className="system-name">{_.text ?? SourceType_.getName(_.type)}</div>
                {_.isConnected === undefined ? undefined : !_.isConnected
                    ? <div className="connection-status">Not Linked</div>
                    : <div className="connection-status ok">Linked <Icon iconName="Accept" /></div>}
            </div>
        </ActionButton>
    })}
</div>
export default SystemsList;