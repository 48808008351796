import * as React from 'react';
import { getRTL, IColumnResizeDetails, KeyCodes, Layer, Icon, IconButton, CheckboxVisibility, IColumn, Checkbox } from 'office-ui-fabric-react';
import { IScale } from "./TimelineScale";
import TimelineHeader from "./TimelineHeader";
import TimeframeSelector from "./TimeframeSelector";
import { Dictionary, Quantization, ITimeframe, MIN_COLUMN_WIDTH } from '../../../entities/common';
import QuantizationSelector from './QuantizationSelector';
import { notUndefined } from '../../utils/common';
import { CHECKBOX_COLUMN_WIDTH } from '../extensibleEntity/EntityDetailsList';

export interface State {
    columnResizeDetails?: IColumnResizeDetails;
    isSizing?: boolean;
}

const SIZER_WIDTH = 16;

const MOUSEDOWN_PRIMARY_BUTTON = 0; // for mouse down event we are using ev.button property, 0 means left button
const MOUSEMOVE_PRIMARY_BUTTON = 1; // for mouse move event we are using ev.buttons property, 1 means left button

const NO_COLUMNS: IColumn[] = [];

type Props = {
    key: string;

    columns: IColumn[];
    columnsWidths: Dictionary<number>;

    onColumnResized: (resizingColumn: IColumn, newWidth: number, resizingColumnIndex: number) => void;

    checkboxVisibility?: CheckboxVisibility;

    isAllSelected?: boolean;
    toggleAllSelected?: () => void;

    showTreeExpandColumn?: boolean;
    sticky: boolean;

    isAllExpanded?: boolean;
    toggleAllExpanded: () => void;

    renderCellContent?: (column: IColumn) => JSX.Element | undefined;
    onColumnHeaderClick?: (column: IColumn) => void;

    timelineWidth: number;
    onChangeTimeframe: (_: Partial<ITimeframe> | undefined, resetQuantization?: boolean) => void;
    scale: IScale;
    timeline: ITimeframe;
    userTimeframe: Partial<ITimeframe> | undefined;
    displayToday: boolean;

    quantization: Quantization;
    onQuantizationChange: (_: Quantization) => void;
    renderTimelineHeaderFirstRow?: () => JSX.Element | undefined;
    isDayOff?: (date: Date) => boolean;    
}

const DEFAULT_TH_LEFT_PADDING = 12;

export class THeader extends React.Component<Props, State> {
    private _rootElement: HTMLElement | undefined;

    private stickyThs: HTMLTableHeaderCellElement[] = [];
    private ths: HTMLTableHeaderCellElement[] = [];

    constructor(props: Props) {
        super(props);
        this.state = {
            columnResizeDetails: undefined
        };
    }

    componentDidMount() {
        // We need to use native on this to avoid MarqueeSelection from handling the event before us.
        this._rootElement!.addEventListener('mousedown', this._onRootMouseDown);
        this._rootElement!.addEventListener('keydown', this._onRootKeyDown);
    }

    componentWillUnmount() {
        this._rootElement!.removeEventListener("mousedown", this._onRootMouseDown);
        this._rootElement!.removeEventListener("keydown", this._onRootKeyDown);
    }

    public render() {
        const {
            sticky, timelineWidth, scale, timeline, userTimeframe, checkboxVisibility, quantization, onChangeTimeframe, onQuantizationChange, onColumnHeaderClick
        } = this.props;
        const { isSizing, columnResizeDetails } = this.state;
        return <thead ref={this._onRootRef} onMouseMove={this._onRootMouseMove}>
            <tr key='timeline-header-row'>
                {checkboxVisibility !== CheckboxVisibility.hidden && <th
                    key='checkbox-column'
                    className={`checkbox-column ${checkboxVisibility === CheckboxVisibility.onHover ? 'visible-onhover' : ''}`}>
                    <Checkbox
                        checked={this.props.isAllSelected}
                        onChange={this.props.toggleAllSelected} />
                </th>}
                {this.props.showTreeExpandColumn && <th
                    key='tree-column'
                    className="tree-column">
                    <IconButton
                        title={this.props.isAllExpanded ? 'Collapse All' : 'Expand All'}
                        iconProps={{ iconName: this.props.isAllExpanded ? 'ChevronUp' : 'ChevronDown' }}
                        onClick={this.props.toggleAllExpanded} />
                </th>}
                {this.props.columns.map((_, i) => [
                    <th
                        key={_.key}
                        ref={(_: HTMLTableHeaderCellElement) => (sticky ? this.stickyThs : this.ths)[i] = _}
                        style={this._getHeaderStyles(_, i)}
                        className={`${_.isSorted ? 'sorted' : ''} ${_.className || ''} ${_.isResizable ? 'isResizable' : ''}`}
                        onClick={sticky && onColumnHeaderClick ? () => onColumnHeaderClick(_) : undefined}>
                        {_.iconName && <Icon iconName={_.iconName} className="column-icon" />}
                        <div style={{ display: 'inline' }}>{this.props.renderCellContent?.(_) ?? _.name}</div>
                        <Icon iconName={_.isSortedDescending === true ? "SortDown" : "SortUp"} style={{ visibility: !_.isSorted ? 'hidden' : undefined }} />
                    </th>,
                    sticky && _.isResizable ? <th
                        data-sizer-index={i}
                        key={`${_.key}_sizer`}
                        aria-hidden={true}
                        role="button"
                        data-is-focusable={false}
                        onClick={stopPropagation}
                        onBlur={this._onSizerBlur}
                        className={`cellSizer ${columnResizeDetails && columnResizeDetails.columnIndex === i ? 'cellIsResizing' : ''}`} />
                        : undefined
                ]).filter(notUndefined)}
                <th key='timeline-column-header' className="timeline-cell">
                    <div className="timeline-column-header-first-row">
                        {this.props.renderTimelineHeaderFirstRow?.() ?? (
                            <>
                                <TimeframeSelector
                                    scale={scale}
                                    timeline={timeline}
                                    userTimeframe={userTimeframe}
                                    onChangeTimeframe={onChangeTimeframe}
                                />
                                <QuantizationSelector value={this.props.quantization} onChange={onQuantizationChange} />
                            </>
                        )}
                    </div>
                    <div className="timeline-canvas-wrap">
                        <TimelineHeader
                            scale={scale}
                            timelineWidth={timelineWidth}
                            displayToday={this.props.displayToday}
                            onScaleItemClick={sticky && quantization !== Quantization.days ? (_) => onChangeTimeframe(_, true) : undefined}
                            isDayOff={this.props.isDayOff} />
                    </div>
                </th>
            </tr>
            {isSizing && (
                <Layer>
                    <div className="sizingOverlay" onMouseMove={this._onSizerMouseMove} onMouseUp={this._onSizerMouseUp} />
                </Layer>
            )}
        </thead>;
    }

    private _onRootRef = (root: any): void => this._rootElement = root;

    private _getHeaderStyles = (column: IColumn, index: number): React.CSSProperties => {
        const { checkboxVisibility } = this.props;

        const padding = 20;
        const styles: React.CSSProperties = this.props.columnsWidths[column.key]
            ? {
                minWidth: this.props.columnsWidths[column.key],
                maxWidth: this.props.columnsWidths[column.key],
            }
            : {
                minWidth: column.minWidth + padding,
            };
        
        if (index === 0) {
            return {
                ...styles,
                paddingLeft: getTimelineRowCellIndentation(checkboxVisibility)
            };
        }

        return styles;
    }

    private _onRootMouseDown = (ev: MouseEvent): void => {
        const columnIndexAttr = (ev.target as HTMLElement).getAttribute('data-sizer-index');
        const columnIndex = Number(columnIndexAttr);

        if (columnIndexAttr === null || ev.button !== MOUSEDOWN_PRIMARY_BUTTON) {
            // Ignore anything except the primary button.
            return;
        }

        this.setState({
            columnResizeDetails: {
                columnIndex: columnIndex,
                columnMinWidth: this.props.columnsWidths[this.props.columns[columnIndex].key],
                originX: ev.clientX
            }
        });

        ev.preventDefault();
        ev.stopPropagation();
    };

    private _onRootMouseMove = (ev: React.MouseEvent<HTMLElement>): void => {
        const { columnResizeDetails, isSizing } = this.state;
        if (columnResizeDetails && !isSizing && ev.clientX !== columnResizeDetails.originX) {
            this.setState({ isSizing: true });
        }
    };

    private _onRootKeyDown = (ev: KeyboardEvent): void => {
        const { columnResizeDetails, isSizing } = this.state;
        const { columns = NO_COLUMNS, onColumnResized } = this.props;

        const columnIndexAttr = (ev.target as HTMLElement).getAttribute('data-sizer-index');

        if (!columnIndexAttr || isSizing) {
            return;
        }

        const columnIndex = Number(columnIndexAttr);

        if (!columnResizeDetails) {
            if (ev.which === KeyCodes.enter) {
                this.setState({
                    columnResizeDetails: {
                        columnIndex: columnIndex,
                        columnMinWidth: this.props.columnsWidths[this.props.columns[columnIndex].key]
                    }
                });

                ev.preventDefault();
                ev.stopPropagation();
            }
        } else {
            let increment: number | undefined;

            if (ev.which === KeyCodes.enter) {
                this.setState({
                    columnResizeDetails: undefined
                });

                ev.preventDefault();
                ev.stopPropagation();
            } else if (ev.which === KeyCodes.left) {
                increment = getRTL() ? 1 : -1;
            } else if (ev.which === KeyCodes.right) {
                increment = getRTL() ? -1 : 1;
            }

            if (increment) {
                if (!ev.shiftKey) {
                    increment *= 10;
                }

                this.setState({
                    columnResizeDetails: {
                        ...columnResizeDetails,
                        columnMinWidth: columnResizeDetails.columnMinWidth + increment
                    }
                });

                if (onColumnResized) {
                    onColumnResized(columns[columnIndex], columnResizeDetails.columnMinWidth + increment, columnIndex);
                }

                ev.preventDefault();
                ev.stopPropagation();
            }
        }
    };

    /**
    * mouse move event handler in the header
    * it will set isSizing state to true when user clicks on the sizer and moves the mouse.
    * 
    * @private
    * @param {React.MouseEvent} ev (mouse move event)
    */
    private _onSizerMouseMove = (ev: React.MouseEvent<HTMLElement>): void => {
        const {
            // use buttons property here since ev.button in some edge case is not upding well during the move.
            // but firefox doesn't support it, so we set the default value when it is not defined.
            buttons
        } = ev;
        const { onColumnResized, columns = NO_COLUMNS } = this.props;
        const { columnResizeDetails } = this.state;

        if (buttons !== undefined && buttons !== MOUSEMOVE_PRIMARY_BUTTON) {
            // cancel mouse down event and return early when the primary button is not pressed
            this._onSizerMouseUp(ev);
            return;
        }

        if (onColumnResized) {
            let movement = ev.clientX - columnResizeDetails!.originX!;

            if (getRTL()) {
                movement = -movement;
            }

            onColumnResized(
                columns[columnResizeDetails!.columnIndex],
                columnResizeDetails!.columnMinWidth + movement,
                columnResizeDetails!.columnIndex
            );
        }
    };

    private _onSizerBlur = (): void => {
        const { columnResizeDetails } = this.state;

        if (columnResizeDetails) {
            this.setState({
                columnResizeDetails: undefined,
                isSizing: false
            });
        }
    };

    /**
     * mouse up event handler in the header
     * clear the resize related state.
     * This is to ensure we can catch double click event
     *
     * @private
     * @param {React.MouseEvent} ev (mouse up event)
     */
    private _onSizerMouseUp = (ev: React.MouseEvent<HTMLElement>): void => {
        this.setState({
            columnResizeDetails: undefined,
            isSizing: false
        });
    };
}

function stopPropagation(ev: React.MouseEvent<HTMLElement>): void {
    ev.stopPropagation();
}

const CELL_PADDING = 20
export function calculateColumnWidth(column: IColumn) {
    return (column.minWidth || MIN_COLUMN_WIDTH) + CELL_PADDING;
}

export function calculateOriginWidth(newWidth: number) {
    return newWidth - CELL_PADDING;
}

export function getRowIndentation(checkboxVisibility: CheckboxVisibility | undefined): number {
    return checkboxVisibility === CheckboxVisibility.hidden ? CHECKBOX_COLUMN_WIDTH : 0;
}

export function getTimelineRowCellIndentation(checkboxVisibility: CheckboxVisibility | undefined) {
    return getRowIndentation(checkboxVisibility) + DEFAULT_TH_LEFT_PADDING;
}
