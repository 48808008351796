import * as React from 'react';
import { IListViewFieldColumn } from "../../../../services/ViewService";
import { Dictionary } from "../../../../entities/common";
import * as StageDescriptorFactory from '../../../../entities/StageDescriptorFactory';
import CategoryColorStageView from './CategoryColorStageView';

type ColorStageProps = IListViewFieldColumn<{
    attributes: Dictionary<any>;
    onClick?: () => void;
}>;

const ColorStage = (props: ColorStageProps) => {
    const { field, entity, className } = props;

    const stageValue = entity.attributes[field.name];
    const stageDescriptor = StageDescriptorFactory.createStageDescriptor(field);
    const stageOption = stageDescriptor.getOption(stageValue);

    if (!field) {
        return <span className="overflow-text">
            Invalid column configuration
        </span>;
    }

    if (!field.settings) {
        return <span className={"bold-font overflow-text" + (className ? ` ${className}` : "")}>{stageValue}</span>;
    }

    if (!stageOption) {
        return <span></span>;
    }

    return (
        <div className="color-stage-wrapper">
            <CategoryColorStageView className={className} stageOption={stageOption} />
        </div>
    );
}

export default ColorStage;