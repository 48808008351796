import React from 'react';
import { Icon, IconButton } from 'office-ui-fabric-react';
import { DraggableOption } from '../../SelectSettingsEdit/OptionEdit';
import { IColorOption } from '../../../../entities/common';

type Props = {
    isDefault?: boolean;
    option: DraggableOption<IColorOption>;
    iconName: string;
    onEditClick?: () => void;
    onRemove?: (optionId: string) => void;
};

const CategoryColorOption = (props: Props) => {
    const { isDefault, option, iconName, onEditClick, onRemove } = props;
    const { name, color } = option;

    return (
        <div className="category-color-option align-center">
            <div className="align-center color-box" style={{ backgroundColor: color }} title={''}>
                <Icon iconName={iconName} />
            </div>
            <div className="title">
                <div className="title-text overflow-text" title={name}>{name}</div>
                {isDefault && <div className='title-default'>(Default)</div>}
            </div>
            <div className="align-center option-actions">
                {onEditClick && (
                    <IconButton
                        menuIconProps={{ iconName: 'Edit' }}
                        title='Edit'
                        className='menu'
                        onClick={onEditClick} />
                )}
                {onRemove && (
                    <IconButton
                        menuIconProps={{ iconName: 'Delete' }}
                        title='Remove'
                        className='menu'
                        onClick={() => onRemove(option.id)} />
                )}
            </div>
        </div>
    );
};

export default CategoryColorOption;
