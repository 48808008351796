import * as React from 'react';
import { Overlay, PrimaryButton, Label, Link, DefaultButton, TooltipHost, Icon, TooltipDelay } from 'office-ui-fabric-react';
import { IProgressCalculationSettings } from "../../../store/integration/MondayComStore";
import OptionsPicker, { Option } from "../../common/inputs/OptionsPicker";
import Spinner from "../../common/Spinner";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { PPMFeatures, Subscription } from '../../../store/Tenant';
import { IExternalTypeWithStatus } from '../../../store/integration/common';

type OwnProps = {
    types: IExternalTypeWithStatus[];
    progressCalculationSettings: IProgressCalculationSettings;
    readOnly: boolean;
    error: string | null,
    isLoading: boolean,
    isProcessing: boolean;
    onDismiss: () => void;
    onSave: (settings: IProgressCalculationSettings) => void,
}
type StateProps = {
    subscription: Subscription;
}
type Props = OwnProps & StateProps;

const MondayComProgressCalculationSettings = (props: Props) => {
    const [types, setTypes] = React.useState<Option[]>([]);
    const [selectedTypes, setSelectedTypes] = React.useState<Option[]>([]);
    const [selectedStatusAutoCalculationTypes, setSelectedStatusAutoCalculationTypes] = React.useState<Option[]>([]);
    
    React.useMemo(() => setTypes(props.types.map(toOption)), [props.types]);
    React.useEffect(() => {
        const progressOptions = props.types
            .map(toOption)
            .filter(_ => props.progressCalculationSettings.types.indexOf(_.key as string) !== -1);
        setSelectedTypes(progressOptions);

        const autoCalculationOptions = props.types
            .map(toOption)
            .filter(_ => props.progressCalculationSettings.autoStatusCalculationTypes.indexOf(_.key as string) !== -1);
        setSelectedStatusAutoCalculationTypes(autoCalculationOptions);
    }, [props.types, props.progressCalculationSettings]);
    const _isSettingsChanged = React.useCallback(() => isSettingsChanged(props.progressCalculationSettings, types, selectedTypes, selectedStatusAutoCalculationTypes), 
        [props.progressCalculationSettings, types, selectedTypes, selectedStatusAutoCalculationTypes]);
    
    const { isLoading, isProcessing, error, readOnly, subscription } = props;
    const isMLEnabled = Subscription.contains(subscription, PPMFeatures.ML);
    if (isLoading) {
        return <Overlay><Spinner /></Overlay>;
    }
    if (error) {
        return <div className="error-message">{error}</div>;
    }
    
    return (
        <div className="connection-settings">
            <div className='scrollable-content'>
                <div className="item">
                    <Label>
                        Item types included in Project Progress calculation
                        <TooltipHost
                                tooltipProps={{
                                onRenderContent: () => <>
                                    By default, project progress is calculated by Task. <br />
                                    Please refer to the {articleLink("selectedTypes")} for more details.
                                </>
                            }}
                            delay={TooltipDelay.zero} >
                            <Icon iconName="Info" onClick={(e) => e.stopPropagation()} className="message-icon"/>
                        </TooltipHost>
                    </Label>
                    <OptionsPicker 
                        selectedItems={selectedTypes}
                        onChange={(value?: Option[]) => setSelectedTypes(value || [])}
                        onResolveSuggestions={(filter: string) => {
                            let res: Option[] = types.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                            if (selectedTypes.length > 0) {
                                res = res.filter((opt: Option) => selectedTypes.find(_ => _.key === opt.key) === undefined);
                            }
                            return Promise.resolve(res);
                        }} />
                </div>
                {isMLEnabled && <div className='item'>
                    <Label>
                        Item types included in Project Status auto-calculation
                        <TooltipHost
                            tooltipProps={{
                                onRenderContent: () => <>
                                    Project Schedule and Resources Statuses will be calculated based on the item types selected below.<br />
                                    Please refer to the {articleLink("selectedStatusAutoCalculationTypes")} for more details.
                                </>
                            }}
                            delay={TooltipDelay.zero} >
                            <Icon iconName="Info" onClick={(e) => e.stopPropagation()} className="message-icon" />
                        </TooltipHost>
                    </Label>
                    <OptionsPicker 
                        selectedItems={selectedStatusAutoCalculationTypes}
                        onChange={(value?: Option[]) => setSelectedStatusAutoCalculationTypes(value || [])}
                        onResolveSuggestions={(filter: string) => {
                            let res: Option[] = types.filter(_ => _.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
                            if (selectedStatusAutoCalculationTypes.length > 0) {
                                res = res.filter((opt: Option) => selectedStatusAutoCalculationTypes.find(_ => _.key === opt.key) === undefined);
                            }
                            return Promise.resolve(res);
                        }} />
                </div>}                    
                {readOnly && <Overlay />}
            </div>
            <div className="commands">
                {!readOnly && <PrimaryButton text="Save"
                        disabled={!_isSettingsChanged()}
                        onClick={() => saveMapping(props, selectedTypes, selectedStatusAutoCalculationTypes)} />}
                <DefaultButton text={readOnly ? "Close" : "Cancel"} onClick={props.onDismiss} />
            </div>
            {isProcessing && <Overlay><Spinner /></Overlay>}
        </div>
    );
}

const toOption = (_: IExternalTypeWithStatus) => ({ text: _.name, key: _.id });

const saveMapping = (props: Props, selectedTypes: Option[], selectedStatusAutoCalculationTypes: Option[]) => {
    const types = selectedTypes.map(_ => _.key as string);
    const autoStatusCalculationTypes = selectedStatusAutoCalculationTypes.map(_ => _.key as string);

    props.onSave({ types, autoStatusCalculationTypes });
}

const articleLink = (propName: "selectedTypes" | "selectedStatusAutoCalculationTypes")  => {
    const href = propName === "selectedTypes"
        ? "https://help.ppm.express/mondaycom-connection/1423979"
        : propName === "selectedStatusAutoCalculationTypes"
            ? "https://help.ppm.express/94162-portfolio-and-project-management/563277"
            : undefined;

    return (
        <Link styles={() => ({ root: { paddingLeft: "unset!important" } })}
            href={href}
            target="_blank">
            article
        </Link>
    );
}

const isSettingsChanged = (propsSettings: IProgressCalculationSettings, types: Option[], selectedTypes: Option[], selectedStatusAutoCalculationTypes: Option[],): boolean => {
    const oldSelectedTypes = types.filter(_ => propsSettings.types.indexOf(_.key as string) !== -1);
    const oldSelectedStatusAutoCalculationTypes = types.filter(_ => propsSettings.autoStatusCalculationTypes.indexOf(_.key as string) !== -1);

    const pairs = [
        { old: oldSelectedTypes, new: selectedTypes },
        { old: oldSelectedStatusAutoCalculationTypes, new: selectedStatusAutoCalculationTypes }
    ];

    for (const pair of pairs) {
        const oldSettings = pair.old;
        const newSettings = pair.new;

        if (newSettings.length !== oldSettings.length) {
            return true;
        }

        for (const oldSelectedType of oldSettings) {
            if (!newSettings.some(_ => _.key === oldSelectedType.key)) {
                return true;
            }
        }
    }

    return false;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription
    };
}

export default connect(mapStateToProps)(MondayComProgressCalculationSettings);