import { RouteComponentProps } from 'react-router-dom';
import * as ArchivedProjectsListStore from '../../store/ArchivedProjectsListStore';
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as Notifications from "../../store/NotificationsStore";
import * as CalendarStore from "../../store/CalendarStore";
import * as LayoutsStore from '../../store/layouts';
import * as WarningsTypeMapsStore from "../../store/warningsTypeMapsStore";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../store';
import { ActionProps, ProjectDetails, StateProps } from './ProjectDetails';
import { actionCreators as ProjectsListStoreActionCreators } from '../../store/ProjectsListStore';
import { EntityType } from '../../entities/common';
import { Integrations, PPMFeatures, Subscription } from '../../store/Tenant';
import { isInReadonlyMode } from '../../store/User';

const entityName = EntityType.Project;
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        projectsActions: bindActionCreators(ArchivedProjectsListStore.actionCreators, dispatch) as unknown as typeof ProjectsListStoreActionCreators,
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        warningsTypeMapsActions: bindActionCreators(WarningsTypeMapsStore.actionCreators, dispatch),
        layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch)
    }
}

export default connect(
    (state: ApplicationState, ownProp: RouteComponentProps<{ id: string }>): StateProps => {
        const entity = state.archivedProjectsList.activeEntity && state.archivedProjectsList.activeEntity.id === ownProp.match.params.id
            ? state.archivedProjectsList.activeEntity
            : undefined;
        
        return {
            user: state.user,
            entity,
            fields: state.fields,
            isLoading: state.archivedProjectsList.isLoading || !!state.layouts[entityName].isApplyingLayout,
            isUpdatingSections: state.projectsList.isUpdatingSections,
            layouts: state.layouts[entityName],
            projectReports: state.tenant.reporting.projectReports.subPacks,
            insights: state.tenant.insights,
            timePortalUrl: state.time.portalUrl,
            integrations: new Integrations(state.tenant.subscription.integrations),
            isReadonlyMode: isInReadonlyMode(state.user, state.tenant, entity),
            hasArchiveProjects: Subscription.contains(state.tenant.subscription, PPMFeatures.ArchiveProjects),
            hasPortfolioManagement: Subscription.contains(state.tenant.subscription, PPMFeatures.PortfolioManagement),
            hasIdeation: Subscription.contains(state.tenant.subscription, PPMFeatures.Ideation),
            isAiInsightsLoading: false,
            aiEnabled: state.tenant.aiInsights.aiEnabled,
            newTimeTrackingEnabled: state.tenant.timeTracking.globalSettings.newTimeTrackingEnabled
        };
    },
    mergeActionCreators
)(ProjectDetails);