import "./ProgressFormatter.css"
import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { FormatType } from '../../../entities/Metadata';
import { PROGRESS_COMPLETED } from '../../../entities/common';
import { formatValue } from '../../utils/common';

export const ProgressFormatter = (valueExtractor: (props: unknown) => number | undefined) => (props: unknown) => {
    const progress = valueExtractor(props);
    if (progress == null){
        return null;
    }
    return <div className="progress-formatter align-center">
        <ProgressIcon progress={progress} />
        <div className="value">{formatValue(progress, FormatType.Percent)}</div>
    </div>;
}

export const ProgressIcon = (props: { progress: number; }) => {
    const { progress } = props;
    const isComplete = progress === PROGRESS_COMPLETED;
    return <div className={`progress circle-icon ${isComplete ? "complete" : ""}`} 
                style={{ backgroundImage: `linear-gradient(to top, var(--brand-green-color) ${progress}%, white ${progress}%)` }}>
        {isComplete && <Icon iconName={"PPMXTaskProgress"} className="complete" />}
    </div>;
}